import { ConsoleSqlOutlined, ContactsFilled } from '@ant-design/icons';
import HTTP from '../../../Libs/http';
import { COMPLIANCE_TASK_DETAILS_DATA, FILE_SIZE, COMPLIANCE_UPCOMING_TASK, GET_INTERIM_DETAILS, COMPLIANCE_UPCOMING_FILE_RESET, COMPLIANCE_UPCOMING_FILE, COMPLIANCE_TASK_CALENDER_VIEW, COMPLIANCE_CURRENT_TASK, COMPLIANCE_FILTER_SHOW, COMPLIANCE_VIEW } from '../../types/index'


const initialState = {
    complianceTaskDetails: {
        compliancefilter: [],
        compliancefiltershow: [],
        currentCompliance: [],
        upcomingTask: [],
        InterimDetails: [],
        fileData: '',
        filesizeValue: 0

    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case COMPLIANCE_TASK_DETAILS_DATA:
            {
                return {
                    ...state,
                    complianceTaskDetails: {
                        ...state.complianceTaskDetails,
                        compliancefilter: payload[1],
                    },
                };
            }
        case COMPLIANCE_TASK_CALENDER_VIEW:
            {
                return {
                    ...state,
                    complianceTaskDetails: {
                        ...state.complianceTaskDetails,
                        compliancefiltershow: payload[1],
                    },
                };
            }
        case COMPLIANCE_CURRENT_TASK:
            {
                return {
                    ...state,
                    complianceTaskDetails: {
                        ...state.complianceTaskDetails,
                        currentCompliance: payload[1],
                    },
                };
            }
        case COMPLIANCE_UPCOMING_FILE:
            {
                console.log(payload[0], ' payload[0],');
                return {
                    ...state,
                    complianceTaskDetails: {
                        ...state.complianceTaskDetails,
                        fileData: payload[0],
                    },
                };
            }
        case COMPLIANCE_UPCOMING_FILE_RESET:
            {
                console.log(payload[0], ' payload[0],');
                return {
                    ...state,
                    complianceTaskDetails: {
                        ...state.complianceTaskDetails,
                        fileData: payload,
                    },
                };
            }
        case COMPLIANCE_UPCOMING_TASK:
            {
                return {
                    ...state,
                    complianceTaskDetails: {
                        ...state.complianceTaskDetails,
                        upcomingTask: payload[1],
                    },
                };
            }
        case FILE_SIZE:
            {
                return {
                    ...state,
                    complianceTaskDetails: {
                        ...state.complianceTaskDetails,
                        filesizeValue: payload[1],
                    },
                };
            }

        case GET_INTERIM_DETAILS:
            {
                return {
                    ...state,
                    complianceTaskDetails: {
                        ...state.complianceTaskDetails,
                        InterimDetails: payload[1],
                    },
                };
            }


        default:
            return state;
    }
}