import React, { useEffect, useState, useRef, Fragment, useCallback, useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Route, Switch, BrowserRouter, Link, useLocation, useHistory } from 'react-router-dom';
import { Collapse, Input, Modal, Table, List, Row, Tooltip, DatePicker, Form, Popconfirm, Card, Select, Tabs, Tree, Space, Button, Checkbox, Drawer } from 'antd';
import { PlayCircleOutlined, DownloadOutlined, DoubleRightOutlined, CloseCircleOutlined, ArrowUpOutlined, CloudUploadOutlined, DoubleLeftOutlined, DeleteOutlined, PlusOutlined, CloseOutlined, FullscreenOutlined, FilterOutlined, UpOutlined, EyeOutlined, ControlOutlined, DownOutlined } from '@ant-design/icons';
import SimpleReactValidator from "simple-react-validator";
import DataTable from "react-data-table-component";
import Swal from 'sweetalert2';
import EncryptDecrypt from '../../Libs/Encrypt&Decrypt';
import { GetGroupListData, FetchEmails, emailsave, verifypassword, updateblockoption } from '../../Store/Action/Master/CV_CertificateMailMapping';
import { compliancefilters, GetOnOccurrenceCompliances, onoccurrencetransaction, startonoccurrencecompliance } from '../../Store/Action/Transcations/EXE_OnOccurrenceCompliance';
import { constant, filter, indexOf, merge } from 'lodash';
import { UploadFile1 } from '../../Store/Action/Transcations/Exe_ComplianceTaskDetails';
import { InfoCircleOutlined, PlusCircleOutlined, SearchOutlined, FilterFilled, ExclamationCircleTwoTone, PlusSquareTwoTone } from '@ant-design/icons';
import { getFilterDataValues, UploadFile, removedocument, Downloadfile, getInterimDetails, updateStatus, saveStatus, getCalenderView, getCurrentTaskDetails, getUpcomigTaskDetails, fileListTemp } from '../../Store/Action/Transcations/Exe_ComplianceTaskDetails';
import { GetRemarksData } from './../../Store/Action/Transcations/Taskrecall';
import { getremarkhistory } from '../../Store/Action/Transcations/ComplianceApproval';
import { Toaster } from '../../Libs/Toaster';
import { REMARKS_MODAL_RESET } from '../../Store/types';
import { toast } from 'react-toastify';
// import Calendar from 'react-awesome-calendar';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment/moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import EXE_OnOccurrenceCompliance from '../../Store/Reducer/Transactions/EXE_OnOccurrenceCompliance';
import { FILE_SIZE, RESET_REMARK_HISTORY } from '../../Store/types';
import ScrollToTop from "react-scroll-to-top";
import ScrollButton from '../Scrollbar';
import url, { getUrl } from './../../Libs/URL';
import { HomeRedirect } from './../../Libs/country';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import Stickicon from "./Stickyicon";
const homepage = HomeRedirect();
const urls = getUrl();

const _ = require("lodash");

const mapStateToProps = (state) => ({
    // onoccurrencecompliances: state.EXE_OnOccurrenceCompliance.onoccurrencecompliance,
    filterrecall: state.recallfilters.recallfilters,
    complianceTaskDetail: state.EXE_ComplianceTaskDetails.complianceTaskDetails,
    nextRecords: state.ComplianceApproval.complianceApproval.nextrecord,
    remarklist: state.ComplianceApproval.complianceApproval.remarkmodaldata,
    fileDatas: state.EXE_ComplianceTaskDetails.complianceTaskDetails.fileData,
    filesizeValues: state.EXE_ComplianceTaskDetails.complianceTaskDetails.filesizeValue


});


const ComplianceTaskDetails = ({
    compliancefilters,
    FetchEmails,
    emailsave,
    verifypassword,
    onoccurrencetransaction,
    getremarkhistory,
    UploadFile,
    Downloadfile,
    fileDatas,
    getInterimDetails,
    fileListTemp,
    removedocument,
    UploadFile1,
    getCalenderView,
    filesizeValues,
    remarklist,
    GetOnOccurrenceCompliances,
    nextRecords,
    startonoccurrencecompliance,
    updateStatus,
    saveStatus,
    getFilterDataValues,
    getCurrentTaskDetails,
    getUpcomigTaskDetails,
    GetRemarksData,
    filterrecall: { recallremarks },
    complianceTaskDetail: { compliancefilter, compliancefiltershow, upcomingTask, InterimDetails, currentCompliance, },
    // onoccurrencecompliances: { compliancefilter, compliancefiltershow, complianceview }
}) => {
    console.log(nextRecords, 'nextRecords');
    console.log(filesizeValues, 'filesizeValues');
    const { Panel } = Collapse;
    const { Option } = Select;
    const { Search, TextArea } = Input;
    const [tab, setTab] = useState();
    console.log(tab, 'TABTAB');
    const [nextDatas, setNextData] = useState([]);
    console.log(nextDatas, 'nextDatas');
    const location = useLocation();
    const historynew = useHistory();
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const is_modal_true = useSelector((state) => state.recallfilters.is_modal_true)
    const [uploadModal, setUploadModal] = useState(false);
    const [searchingValue, setSearchingValue] = useState([])
    console.log(searchingValue, 'searchingValue');
    const [show, setShow] = useState(false);
    let [sno, setSNo] = useState(0);
    const [all, setAll] = useState("All");
    console.log(show, 'show show');
    const [defaultRemarks, setDefaultRemarks] = useState();
    const [eventValue, setEventValue] = useState(0);
    console.log(eventValue, 'eventValue');
    const [showMore, setShowMore] = useState(false);
    let [counts, setCount] = useState(0);
    const [edit, setEdit] = useState(false);
    const [update, setUpdate] = useState(false);
    const currentDate = new Date().toJSON().slice(0, 10);
    const [FormShow, setFormShow] = useState(false);
    const [updatedArray, setUpdatedArray] = useState([]);
    const [updatedArray1, setUpdatedArray1] = useState([]);
    console.log(updatedArray1, 'updatedArray1');
    const [searchText, setSearchText] = useState('');
    const [tempFilterArray, setTempFilterArray] = useState([]);
    const [isModalOpenremark, setIsModalOpenremark] = useState(false);
    const [titleDynamic, setTitleDynamic] = useState([]);
    const [passvisible, Setpassvisible] = useState(false);
    console.log(passvisible, 'passvisible');
    console.log(filesizeValues, 'filesizeValues');
    const [index, setIndex] = useState();
    const [date, setDate] = useState(new Date());
    const searchInput = useRef(null);
    const [key, setkey] = useState();
    const [attachedFile, setAttachedFile] = useState([]);
    console.log(attachedFile, 'attachedFile');
    const [attachedFile1, setAttachedFile1] = useState([]);

    const [searchedValue, setSearchedValue] = useState({
        complianceTask: '',
        domain: '',
        startDate: '',
        dueDate: '',
        days: '',
        timeLine: '',
        status: ''
    })
    const myRef1 = useRef(null);
    const myRef2 = useRef(null);
    const myRef3 = useRef(null);
    const myRef4 = useRef(null);
    const myRef5 = useRef(null);
    const myRef6 = useRef(null);
    const myRef7 = useRef(null);

    const setFocus = (ref) => {
        setTimeout(() => {
            ref.current.focus();
        });
    };

    const [checked, setChecked] = useState({
        All: false,
        periodical: false,
        flexi: false,
        review: false,
        on_occurrence: false,
        one_time: false

    });
    console.log(checked, 'checked');
    const [filterValues, setFilterValues] = useState(
        {
            All: '',
            periodical: '',
            flexi: '',
            review: '',
            on_occurrence: '',
            one_time: ''
        }
    )
    const [filterValues1, setFilterValues1] = useState(
        {
            All: '',
            periodical: '',
            flexi: '',
            review: '',
            on_occurrence: '',
            one_time: ''
        }
    );
    const [checked1, setChecked1] = useState({
        All: false,
        periodical: false,
        flexi: false,
        review: false,
        on_occurrence: false,
        one_time: false

    });

    const resetfilter1 = () => {
        setFilterValues1({
            ...filterValues,
            All: '',
            periodical: '',
            flexi_review: '',
            review: '',
            on_occurrence: '',
            one_time: ''
        });
        setChecked1({
            ...checked,
            All: false,
            periodical: false,
            flexi: false,
            review: false,
            on_occurrence: false,
            one_time: false
        });
    };
    const [status, setStatus] = useState({
        overDue: false,
        inprogress: false,
        recall: false
    });
    const columnsremark = [
        {
            title: 'Sno',
            dataIndex: false,
            width: '10px',
            align: 'center',
            render: (text, record, index) => {
                return <p>{(page - 1) * 10 + index + 1}</p>;
            }
        },
        {
            title: 'Activity Date',
            dataIndex: "activity_on",
            key: "activity_on",
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return <p>{record.activity_on}</p>;
            }
        },
        {
            title: 'User Type',
            dataIndex: false,
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return record.action === "Submitted" ? <p>Assignee</p> : <p>Approver</p>;
            }
        },
        {
            title: 'Reviewer Report',
            dataIndex: false,
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return <a href={`${urls + '/compliance_format/' + record.documents}`} target="_blank" >
                    {record.documents}
                </a>;

            }
        },
        {
            title: 'Remarks',
            dataIndex: "remarks",
            key: "remarks",
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return <p>{record.remarks}</p>;
            }
        }
    ]
    const [type, setType] = useState("")
    console.log(type, 'type555555');
    const [searchedColumn, setSearchedColumn] = useState('');
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [documentNo, setDocumentNo] = useState("");
    const [tempList, setTempList] = useState([]);
    const [dataList, setDataList] = useState([]);
    const [filteredArray, setFilteredArray] = useState([]);
    const [filteredArrayState, setFilteredArrayState] = useState([]);
    const [showValueModal, setShowValueModal] = useState(false);
    const [particularevent, setParticularevent] = useState({
        data: '',
        value: false
    });
    const wrapperRef = useRef(null);
    const [showDropDown, setshowDropDown] = useState(false);
    const [passwordata, Setpassword] = useState({
        passvalue: '',
        sp_id: '',
        active_status: ''
    });
    console.log(showDropDown, 'showDropDown');
    const [completionDate, setCompletionDate] = useState("");
    console.log(completionDate, 'completionDate');
    const [valueModal, setValueModal] = useState({
        unit: '',
        recall: '',
        address: '',
        dayType: '',
        compliance_history_id: '',
        compliance_task: '',
        compliance_frequency: '',
        uploaded_document: [],
        document_issued_date: '',
        document_reference_number: '',
        validity_date: '',
        next_due_date: '',
        status: '',
        remarks: '',
        action: '',
        description: "",
        documentData: [],
        completion_dadue_datete: "",
        document_size: 0,
        unit_id: null,
        approve_status: "",
        actiondata: "",
        start_date: "",
        domain_id: null,
        history_count: '',
        recall_history_count: '',
        occurrence_remarks: '',
        due_date: ''
    });
    console.log(valueModal, 'valuemodal');
    const paramid = location.pathname;
    const [checkdata, setCheckdata] = useState([])
    const [checkdata1, setCheckdata1] = useState([])
    console.log(upcomingTask, 'check data')
    const { TabPane } = Tabs;
    var [count1, setCount1] = useState(1);
    const [titledata, Settitledata] = useState()
    console.log(titledata, 'titledata');
    const currentdate = new Date();
    const [today, setToday] = useState(moment(currentdate).format('DD-MMM-YYYY'))
    let [count, setcount] = useState(0)
    let [count2, setcount2] = useState(0)
    const formValidator = useRef(new SimpleReactValidator());
    const formValidator1 = useRef(new SimpleReactValidator());
    // const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue');
    const sessionParsedValue = JSON.parse(sessionValue);
    const sessionArr = sessionParsedValue.entity_info;

    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [remarksModal, setRemarksModal] = useState(false);
    const [eventData, setEventData] = useState([]);
    const localizer = momentLocalizer(moment);
    const validator = useRef(new SimpleReactValidator());
    const [showdata, Setshowdata] = useState({
        domain: '',
        unit: '',
        frequency: '',
        category: '',
        act: '',
        division: '',
        // legal_entity: '',
        legal_entity: localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? '' : localStorage.getItem('SelectedEntityid')

    });
    console.log(showdata, 'SHOWDATA');
    const validator1 = useRef(new SimpleReactValidator());
    const validator2 = useRef(new SimpleReactValidator());
    const [fileData, setFileData] = useState([]);
    const [valueForFileUpload, setValueForFileUpload] = useState({
        d_id: '',
        u_id: '',
        start_date: '',
        file_info: [],
    });
    const [fileupload, setFileUpload] = useState([]);
    const [fileupload1, setFileUpload1] = useState([]);
    const [fileSize, setFileSize] = useState(0.00);
    console.log(fileSize, 'fileSize');
    const [currentMonth, setCurrentMonth] = useState(moment(new Date()).startOf('month'));
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    });
    const [modalVisible, setModaVisible] = useState(false);
    const [taskvalue, setTaskvalue] = useState();
    const [division, setDivision] = useState([]);
    const [frequency, setfrequency] = useState([]);
    const [category, setCategoryList] = useState([]);
    const [tempcategory, setTempCategoryList] = useState([]);
    console.log(tempcategory, 'tempcategory');
    const [units, setUnits] = useState([]);
    const [tempunits, settempUnit] = useState([]);

    const [domain, setDomain] = useState([]);
    const [legalentity, setLegalentity] = useState();
    const [modalData, setModalData] = useState({
        password: '',
    });
    // const [compliancedata, setComplianceData] = useState({
    //     startdate: '',
    //     remarks: ''
    // });
    const [tempvalue, setTempvalue] = useState([]);
    const [remarks, SetRemarks] = useState(null);
    console.log(remarks, 'fileupload rema');
    const [remarksvalue, Setremarksvalue] = useState();
    const [page, setPage] = useState(1);
    const [currentDetails, setCurrentDetails] = useState([])
    const [upcomingDetails, setUpcomingDetails] = useState([])
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const dispatch = useDispatch();
    const [calendarDate, setCalendarDate] = useState(null);
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);
    console.log(currentDetails, 'currentDetails');
    const RemarksColumn = [
        {
            title: 'Sno',
            dataIndex: false,
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1;
            }
        },
        {
            title: 'Description',
            dataIndex: 'r_description',
            key: 'r_description',
            width: '200px',
            ellipsis: true,
            render: (text, record) => <a onClick={() => {
                // setModalData({
                //     ...modalData,
                //     password: text
                // })
                // setComplianceData[{
                //     ...compliancedata
                // }]
                SetRemarks(record.r_description);
                setRemarksModal(false);
                dispatch({
                    type: REMARKS_MODAL_RESET,
                    payload: false
                })
            }}>{record.r_description}</a>,
        },
    ];
    console.log(dataList, 'dataList');
    const handleCancelremark = () => {
        setIsModalOpenremark(false);
    };
    const showModalremark = () => {
        setIsModalOpenremark(true);
    };
    useEffect(() => {
        if (currentCompliance && currentCompliance.current_compliances) {
            let arrayTemp = [];
            for (let i in currentCompliance && currentCompliance.current_compliances) {
                let split = currentCompliance && currentCompliance.current_compliances && currentCompliance && currentCompliance.current_compliances[i].ageing.split(" ")
                let finalsplit = { days: '', timeline: '' }
                for (let i in split) {

                    if (split[i] !== '' && Number(split[i])) {

                        console.log(split[i], 'Number(split[i])')

                        finalsplit.days = split[i]

                        finalsplit.timeline = split[Number(i) + 1]

                        console.log(finalsplit, 'kkksksksksksksksksksksk')

                    }

                }
                let entity = {
                    address: currentCompliance && currentCompliance.current_compliances[i].address,
                    ageing: currentCompliance && currentCompliance.current_compliances[i].ageing,
                    approve_status: currentCompliance && currentCompliance.current_compliances[i].approve_status,
                    assigned_on: currentCompliance && currentCompliance.current_compliances[i].assigned_on,
                    category_name: currentCompliance && currentCompliance.current_compliances[i].category_name,
                    comp_history_count: currentCompliance && currentCompliance.current_compliances[i].comp_history_count,
                    compfielaw_url: currentCompliance && currentCompliance.current_compliances[i].compfielaw_url,
                    compliance_description: currentCompliance && currentCompliance.current_compliances[i].compliance_description,
                    compliance_download_url: currentCompliance && currentCompliance.current_compliances[i].compliance_download_url,
                    compliance_file_name: currentCompliance && currentCompliance.current_compliances[i].compliance_file_name,
                    compliance_history_id: currentCompliance && currentCompliance.current_compliances[i].compliance_history_id,
                    compliance_id: currentCompliance && currentCompliance.current_compliances[i].compliance_id,
                    compliance_name: currentCompliance && currentCompliance.current_compliances[i].compliance_name,
                    compliance_status: currentCompliance && currentCompliance.current_compliances[i].compliance_status,
                    compliance_task_frequency: currentCompliance && currentCompliance.current_compliances[i].compliance_task_frequency,
                    division_name: currentCompliance && currentCompliance.current_compliances[i].division_name,
                    document_reference_number: currentCompliance && currentCompliance.current_compliances[i].document_reference_number,
                    document_size: currentCompliance && currentCompliance.current_compliances[i].document_size,
                    domain_id: currentCompliance && currentCompliance.current_compliances[i].domain_id,
                    domain_name: currentCompliance && currentCompliance.current_compliances[i].domain_name,
                    due_date: currentCompliance && currentCompliance.current_compliances[i].due_date,
                    duration_type: currentCompliance && currentCompliance.current_compliances[i].duration_type,
                    extend_due_date: currentCompliance && currentCompliance.current_compliances[i].extend_due_date,
                    file_names: currentCompliance && currentCompliance.current_compliances[i].file_names,

                    interim_count: currentCompliance && currentCompliance.current_compliances[i].interim_count,
                    is_active: currentCompliance && currentCompliance.current_compliances[i].is_active,
                    is_ctcy_task: currentCompliance && currentCompliance.current_compliances[i].is_ctcy_task,
                    next_due_date: currentCompliance && currentCompliance.current_compliances[i].next_due_date,
                    Days: `${finalsplit.days ? finalsplit.days : 0}  ${finalsplit.timeline ? finalsplit.timeline : 'day(s)'}`,
                    occurrence_remarks: currentCompliance && currentCompliance.current_compliances[i].occurrence_remarks,
                    penal_consequences: currentCompliance && currentCompliance.current_compliances[i].penal_consequences,
                    comp_name: `${currentCompliance && currentCompliance.current_compliances[i].assigned_on} ${currentCompliance && currentCompliance.current_compliances[i].compliance_name} ${currentCompliance && currentCompliance.current_compliances[i].statu}`,
                    recall_status: currentCompliance && currentCompliance.current_compliances[i].recall_status,
                    recallremarks: currentCompliance && currentCompliance.current_compliances[i].recallremarks,
                    remarks: currentCompliance && currentCompliance.current_compliances[i].remarks,
                    remarks_count: currentCompliance && currentCompliance.current_compliances[i].remarks_count,
                    TimeLine: (moment(currentCompliance && currentCompliance.current_compliances[i].due_date).isSameOrAfter(today)) ? "Left" : "Over Due",
                    start_date: currentCompliance && currentCompliance.current_compliances[i].start_date,
                    statu: currentCompliance && currentCompliance.current_compliances[i].statu,
                    system_remarks: currentCompliance && currentCompliance.current_compliances[i].system_remarks,
                    tz_date: currentCompliance && currentCompliance.current_compliances[i].tz_date,
                    tz_name: currentCompliance && currentCompliance.current_compliances[i].tz_name,
                    unit_id: currentCompliance && currentCompliance.current_compliances[i].unit_id,
                    unit_name: currentCompliance && currentCompliance.current_compliances[i].unit_name,
                    validity_date: currentCompliance && currentCompliance.current_compliances[i].validity_date,
                    validity_settings_days: currentCompliance && currentCompliance.current_compliances[i].validity_settings_days,
                    uniqueId: Math.floor(Math.random() * 100)
                };
                arrayTemp.push(entity);
            }
            var merged = [].concat.apply([], arrayTemp);
            setUpdatedArray([...merged]);

        }
    }, [currentCompliance && currentCompliance.current_compliances]);


    useEffect(() => {
        if (updatedArray && updatedArray.length > 0) {

            if (showMore == true) {
                let tempArr = updatedArray1.concat(updatedArray);
                setUpdatedArray1(tempArr);
                // setDataList([dataList,statutoryUnitList && statutoryUnitList.statutoryListByUnit])
            }
            else {
                setUpdatedArray1(updatedArray);
            }

        }
        else {
            setTempList([]);
        }
    }, [updatedArray]);







    useEffect(() => {
        if (valueModal.compliance_history_id !== '' && dataList.length > 0) {
            let filterData = _.filter(dataList, { compliance_history_id: valueModal.compliance_history_id });
            console.log(filterData, 'filterData');
            setValueModal({
                ...valueModal,
                interim_count: filterData && filterData.length > 0 && filterData[0].interim_count
            });
        }
    }, [dataList]);
    useEffect(() => {
        tab != 2 && setShowValueModal(false);
    }, [tab]);

    useEffect(() => {
        if (showdata.legal_entity != '' && compliancefilter) {
            let filteredArr = _.filter(compliancefilter && compliancefilter.categories_list, { le_id: showdata.legal_entity });
            setCategoryList(filteredArr);
            setTempCategoryList(filteredArr);
        }

    }, [showdata.legal_entity, compliancefilter]);

    useEffect(() => {
        if (fileDatas !== '') {
            if (fileDatas == 'FileUploadSuccess') {
                if (update == true) {
                    const payload1 = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "SaveInterimComplianceHistory",
                                {
                                    "legal_entity_id": showdata && showdata.legal_entity,
                                    "compliance_history_id": valueModal?.compliance_history_id,
                                    "documents": fileupload.length > 0 ? fileupload : null,
                                    "uploaded_documents": null,
                                    "remarks": remarks,
                                    "file_size": 0
                                }
                            ]
                        }
                    ];
                    const payload2 = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetCurrentComplianceDetail",
                                {
                                    "le_id": showdata.legal_entity,
                                    "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                                    "current_start_count": 0,
                                    "cal_view": null,
                                    "cal_date": null,
                                    "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                                    "division_id": showdata.division != '' ? Number(showdata.division) : null,
                                    "category_id": showdata.category != '' ? Number(showdata.category) : null,
                                    "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                                }
                            ]
                        }
                    ];


                    updateStatus({
                        payload: payload1,
                        paramid: paramid,
                        setCompletionDate: setCompletionDate,
                        setFileUpload: setFileUpload,
                        valueModal: valueModal,
                        setValueModal: setValueModal,
                        SetRemarks: SetRemarks,
                        setFileSize: setFileSize,

                        // setShowValueModal: setShowValueModal,
                        // nextData: nextDatas,
                        payload2: payload2
                    });
                }
                else {
                    console.log('aishuuuu');
                    const pay = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "UpdateComplianceDetail",
                                {
                                    "le_id": showdata.legal_entity,
                                    "compliance_history_id": valueModal.compliance_history_id,
                                    "documents": fileupload && fileupload.length > 0 ? fileupload : null,
                                    "uploaded_documents": null,
                                    "removed_documents": [],
                                    "completion_date": (valueModal.dayType == 'hours') && (valueModal.compliance_frequency == 'On Occurrence') ? moment(completionDate && completionDate._d).format('DD-MMM-YYYY HH:mm') : moment(completionDate && completionDate._d).format('DD-MMM-YYYY'),
                                    "validity_date": (valueModal.validity_date !== null && valueModal.validity_date !== '') && (valueModal.compliance_frequency !== 'On Occurrence') ? moment(valueModal.validity_date).format('DD-MMM-YYYY') : null,
                                    "next_due_date": (valueModal.next_due_date !== null) && (valueModal.compliance_frequency !== 'On Occurrence') ? valueModal.next_due_date : null,
                                    "remarks": remarks !== '' ? remarks : null,
                                    "file_size": 0,
                                    "tz_date": moment(new Date()).format('DD-MMM-YYYY hh:mm'),
                                    "doc_ref_num": valueModal.next_due_date !== null ? valueModal.next_due_date : null,
                                    "password": moment(valueModal.due_date).isBefore(moment(completionDate && completionDate._d).format('DD-MMM-YYYY')) ? passwordata.passvalue : '',
                                    "pwd_validate": moment(valueModal.due_date).isBefore(moment(completionDate && completionDate._d).format('DD-MMM-YYYY')) ? true : false
                                }
                            ]
                        }
                    ];
                    const payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetCurrentComplianceDetail",
                                {
                                    "le_id": showdata.legal_entity,
                                    "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                                    "current_start_count": 0,
                                    "cal_view": null,
                                    "cal_date": null,
                                    "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                                    "division_id": showdata.division != '' ? Number(showdata.division) : null,
                                    "category_id": showdata.category != '' ? Number(showdata.category) : null,
                                    "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                                }
                            ]
                        }
                    ];
                    console.log(pay, 'pay55555');
                    saveStatus({
                        payload: pay,
                        paramid: paramid,
                        setValueModal: setValueModal,
                        setCompletionDate: setCompletionDate,
                        SetRemarks: SetRemarks,
                        valueModal: valueModal,
                        nextData: nextDatas,
                        payload2: payload,
                        setShowValueModal: setShowValueModal,
                        Setpassvisible: Setpassvisible
                    });
                }

            }
        }
    }, [fileDatas]);

    useEffect(() => {
        showValueModal && setFileSize(0);
    }, [showValueModal]);
    const compliancetaskfilter = (e, type, searchedArray) => {
        let filterTable = updatedArray1

        if (searchingValue['complianceTask']) {
            filterTable = filterTable.filter((item) => {
                if ((item["comp_name"].toLowerCase().includes(searchingValue['complianceTask'].toLowerCase())) || (item["comp_name"].toLowerCase().includes(searchingValue['complianceTask'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['domain']) {
            filterTable = filterTable.filter((item) => {
                if ((item["domain_name"].toLowerCase().includes(searchingValue['domain'].toLowerCase())) || (item["domain_name"].toLowerCase().includes(searchingValue['domain'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['startDate']) {
            filterTable = filterTable.filter((item) => {
                if ((item["start_date"].toLowerCase().includes(searchingValue['startDate'].toLowerCase())) || (item["start_date"].toLowerCase().includes(searchingValue['startDate'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['dueDate']) {
            filterTable = filterTable.filter((item) => {
                if ((item["due_date"].toLowerCase().includes(searchingValue['dueDate'].toLowerCase())) || (item["due_date"].toLowerCase().includes(searchingValue['dueDate'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['days']) {
            filterTable = filterTable.filter((item) => {
                if ((item["Days"].toLowerCase().includes(searchingValue['days'].toLowerCase())) || (item["Days"].toLowerCase().includes(searchingValue['days'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['status']) {
            filterTable = filterTable.filter((item) => {
                if ((item["compliance_status"].toLowerCase().includes(searchingValue['status'].toLowerCase())) || (item["compliance_status"].toLowerCase().includes(searchingValue['status'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['timeLine']) {
            filterTable = filterTable.filter((item) => {
                if ((item["TimeLine"].toLowerCase().includes(searchingValue['timeLine'].toLowerCase())) || (item["TimeLine"].toLowerCase().includes(searchingValue['timeLine'].toLowerCase()))) {
                    return true;
                }
            })
        }
        // if (type == "Compliance_Task") {
        //     console.log('testtt');
        //     filterTable = updatedArray1.filter((o) => {
        //         if (all == "All") {
        //             if (o["comp_name"].toLowerCase().includes(e.toLowerCase())) {
        //                 return true;
        //             }
        //         } else {
        //             if (
        //                 o["comp_name"].toLowerCase().includes(e.toLowerCase()) &&
        //                 o["compliance_task_frequency"].toLowerCase().includes(all.toLowerCase())
        //             ) {
        //                 return true;
        //             }


        //             console.log(filterTable, 'filterTable');
        //         }
        //     });
        // } else if (type == "Domain") {
        //     filterTable = updatedArray1.filter((o) => {
        //         if (all == "All") {
        //             if (o["domain_name"].toLowerCase().includes(e.toLowerCase())) {
        //                 return true;
        //             }
        //         } else {
        //             if (
        //                 o["domain_name"].toLowerCase().includes(e.toLowerCase()) &&
        //                 o["compliance_task_frequency"].toLowerCase().includes(all.toLowerCase())
        //             ) {
        //                 return true;
        //             }
        //         }
        //     });
        // } else if (type == "Start Date") {
        //     filterTable = updatedArray1.filter((o) => {
        //         if (all == "All") {
        //             if (o["start_date"].toLowerCase().includes(e.toLowerCase())) {
        //                 return true;
        //             }
        //         } else {
        //             if (
        //                 o["start_date"].toLowerCase().includes(e.toLowerCase()) &&
        //                 o["compliance_task_frequency"].toLowerCase().includes(all.toLowerCase())
        //             ) {
        //                 return true;
        //             }
        //         }
        //     });
        // }
        // else if (type == "TimeLine") {
        //     filterTable = updatedArray1.filter((o) => {
        //         if (all == "All") {
        //             if (o["TimeLine"].toLowerCase().includes(e.toLowerCase())) {
        //                 return true;
        //             }
        //         } else {
        //             if (
        //                 o["TimeLine"].toLowerCase().includes(e.toLowerCase()) &&
        //                 o["compliance_task_frequency"].toLowerCase().includes(all.toLowerCase())
        //             ) {
        //                 return true;
        //             }
        //         }
        //     });
        // }
        // else if (type == "compliance_status") {
        //     filterTable = updatedArray1.filter((o) => {
        //         if (all == "All") {
        //             if (o["compliance_status"].toLowerCase().includes(e.toLowerCase())) {
        //                 return true;
        //             }
        //         } else {
        //             if (
        //                 o["compliance_status"].toLowerCase().includes(e.toLowerCase()) &&
        //                 o["compliance_task_frequency"].toLowerCase().includes(all.toLowerCase())
        //             ) {
        //                 return true;
        //             }
        //         }
        //     });
        // }
        // else if (type == "Due Date") {
        //     filterTable = updatedArray1.filter((o) => {
        //         if (all == "All") {
        //             if (o["due_date"].toLowerCase().includes(e.toLowerCase())) {
        //                 return true;
        //             }
        //         } else {
        //             if (
        //                 o["due_date"].toLowerCase().includes(e.toLowerCase()) &&
        //                 o["compliance_task_frequency"].toLowerCase().includes(all.toLowerCase())
        //             ) {
        //                 return true;
        //             }
        //         }
        //     });
        // }
        // else if (type == "Days") {
        //     filterTable = updatedArray1.filter((o) => {
        //         if (all == "All") {
        //             if (o["Days"].toLowerCase().includes(e.toLowerCase())) {
        //                 return true;
        //             }
        //         } else {
        //             if (
        //                 o["Days"].toLowerCase().includes(e.toLowerCase()) &&
        //                 o["compliance_task_frequency"].toLowerCase().includes(all.toLowerCase())
        //             ) {
        //                 return true;
        //             }
        //         }
        //     });
        // }
        console.log(filterTable, 'filterTable5555555');
        let totalList = [];
        let arr = [];
        let countrylisttempdata = [];
        let tempSetArray = [];
        let parId = [];
        if (filterTable && filterTable.length > 0) {
            // assigneename &&
            //     assigneename.length > 0 &&
            //     assigneename.map(function (user) {
            //         if (arr.indexOf(user.assignee_name) < 0)
            //             arr.push({
            //                 assignee_name: user.assignee_name,
            //                 unit_name: user.unit_name,
            //             });
            //     });

            let tempArray = [];
            filterTable &&
                filterTable.map((subMenuList, childIndex) => {
                    {
                        let split = subMenuList && subMenuList.ageing.split(" ")
                        let finalsplit = { days: '', timeline: '' }
                        for (let i in split) {

                            if (split[i] !== '' && Number(split[i])) {

                                console.log(split[i], 'Number(split[i])')

                                finalsplit.days = split[i]

                                finalsplit.timeline = split[Number(i) + 1]

                                console.log(finalsplit, 'kkksksksksksksksksksksk')

                            }

                        }
                        let entity = {
                            address: subMenuList.address,
                            ageing: subMenuList.ageing,
                            approve_status: subMenuList.approve_status,
                            assigned_on: subMenuList.assigned_on,
                            category_name: subMenuList.category_name,
                            comp_history_count: subMenuList.comp_history_count,
                            compfielaw_url: subMenuList.compfielaw_url,
                            compliance_description: subMenuList.compliance_description,
                            compliance_download_url: subMenuList.compliance_download_url,
                            compliance_file_name: subMenuList.compliance_file_name,
                            compliance_history_id: subMenuList.compliance_history_id,
                            compliance_id: subMenuList.compliance_id,
                            comp_name: `${subMenuList.assigned_on} ${subMenuList.compliance_name} ${subMenuList.statu}`,
                            compliance_name: subMenuList.compliance_name,
                            compliance_status: subMenuList.compliance_status,
                            Days: `${finalsplit.days ? finalsplit.days : 0}  ${finalsplit.timeline ? finalsplit.timeline : 'day(s)'}`,
                            compliance_task_frequency: subMenuList.compliance_task_frequency,
                            division_name: subMenuList.division_name,
                            document_reference_number: subMenuList.document_reference_number,
                            document_size: subMenuList.document_size,
                            domain_id: subMenuList.domain_id,
                            domain_name: subMenuList.domain_name,
                            TimeLine: (moment(subMenuList.due_date).isSameOrAfter(today)) ? "Left" : "Over Due",
                            due_date: subMenuList.due_date,
                            duration_type: subMenuList.duration_type,
                            extend_due_date: subMenuList.extend_due_date,
                            file_names: subMenuList.file_names,
                            interim_count: subMenuList.interim_count,
                            is_active: subMenuList.is_active,
                            is_ctcy_task: subMenuList.is_ctcy_task,
                            next_due_date: subMenuList.next_due_date,
                            occurrence_remarks: subMenuList.occurrence_remarks,
                            penal_consequences: subMenuList.penal_consequences,
                            recall_status: subMenuList.recall_status,
                            recallremarks: subMenuList.recallremarks,
                            remarks: subMenuList.remarks,
                            remarks_count: subMenuList.remarks_count,
                            start_date: subMenuList.start_date,
                            statu: subMenuList.statu,
                            system_remarks: subMenuList.system_remarks,
                            tz_date: subMenuList.tz_date,
                            tz_name: subMenuList.tz_name,
                            unit_id: subMenuList.unit_id,
                            unit_name: subMenuList.unit_name,
                            validity_date: subMenuList.validity_date,
                            validity_settings_days: subMenuList.validity_settings_days,
                            uniqueId: Math.floor(Math.random() * 100)
                        };
                        tempArray.push(entity);
                        // unit_name.push(subMenuList.unit_name);

                    }
                });
            console.log(tempArray, 'tempArray');
            setDataList(tempArray);
            // titleDynamic[index] = pmenu;
            // countrylisttempdata.push(totalList);
            tempSetArray.push(tempArray);

            console.log([...new Set([].concat.apply([], parId))], "parIdId");
            setTitleDynamic([...titleDynamic]);
            var merged = [].concat.apply([], tempSetArray);
            // setTemp(countrylisttempdata);
            // setMergedArray(merged);
            setTempFilterArray([...new Set([].concat.apply([], parId))]);
        } else {
            console.log('96777');
            // setMergedArray([]);
            // setTemp([]);
            setTitleDynamic([]);
            setTempFilterArray([]);
            setDataList([]);
        }
    };

    const compliancetaskfilter1 = (e, type) => {
        let filterTable;
        if (type == "Compliance_Task") {
            console.log('testtt');
            filterTable = checkdata1.filter((o) => {
                if (all == "All") {
                    if (o["statu"].toLowerCase().includes(e.toLowerCase())) {
                        return true;
                    }
                } else {
                    if (
                        o["statu"].toLowerCase().includes(e.toLowerCase()) &&
                        o["frequency"].toLowerCase().includes(all.toLowerCase())
                    ) {
                        return true;
                    }


                    console.log(filterTable, 'filterTable');
                }
            });
        } else if (type == "Domain") {
            filterTable = checkdata1.filter((o) => {
                if (all == "All") {
                    if (o["domain_name"].toLowerCase().includes(e.toLowerCase())) {
                        return true;
                    }
                } else {
                    if (
                        o["domain_name"].toLowerCase().includes(e.toLowerCase()) &&
                        o["frequency"].toLowerCase().includes(all.toLowerCase())
                    ) {
                        return true;
                    }
                }
            });
        } else if (type == "Start Date") {
            filterTable = checkdata1.filter((o) => {
                if (all == "All") {
                    if (o["start_date"].toLowerCase().includes(e.toLowerCase())) {
                        return true;
                    }
                } else {
                    if (
                        o["start_date"].toLowerCase().includes(e.toLowerCase()) &&
                        o["frequency"].toLowerCase().includes(all.toLowerCase())
                    ) {
                        return true;
                    }
                }
            });
        }
        else if (type == "Due Date") {
            filterTable = checkdata1.filter((o) => {
                if (all == "All") {
                    if (o["due_date"].toLowerCase().includes(e.toLowerCase())) {
                        return true;
                    }
                } else {
                    if (
                        o["due_date"].toLowerCase().includes(e.toLowerCase()) &&
                        o["frequency"].toLowerCase().includes(all.toLowerCase())
                    ) {
                        return true;
                    }
                }
            });
        }

        console.log(filterTable, 'filterTable5555555');
        let totalList = [];
        let arr = [];
        let countrylisttempdata = [];
        let tempSetArray = [];
        let parId = [];
        if (filterTable && filterTable.length > 0) {
            // assigneename &&
            //     assigneename.length > 0 &&
            //     assigneename.map(function (user) {
            //         if (arr.indexOf(user.assignee_name) < 0)
            //             arr.push({
            //                 assignee_name: user.assignee_name,
            //                 unit_name: user.unit_name,
            //             });
            //     });

            let tempArray = []
            filterTable &&
                filterTable.map((subMenuList, childIndex) => {
                    {

                        let entityArray = {
                            "address": subMenuList.address,
                            "assigned_on": subMenuList.assigned_on,
                            "category": subMenuList.category,
                            "compfielaw_url": subMenuList.compfielaw_url,
                            "compliance_description": subMenuList.compliance_description,
                            "compliance_name": subMenuList.compliance_name,
                            "domain_name": subMenuList.domain_name,
                            "due_date": subMenuList.due_date,
                            "frequency": subMenuList.frequency,
                            "division": subMenuList.division,
                            "penal_consequences": subMenuList.penal_consequences,
                            "start_date": subMenuList.start_date,
                            "statu": subMenuList.statu,
                            "unit_name": subMenuList.unit_name,
                            "upcoming_format_file_name": subMenuList.upcoming_format_file_name,
                            'childId': childIndex,
                            // 'parentId': i,
                            'indexNo': count1
                        }
                        tempArray.push(entityArray);
                        // unit_name.push(subMenuList.unit_name);

                    }
                });
            console.log(tempArray, 'tempArray');
            setCheckdata(tempArray)
        } else {
            console.log('96777');
            // setMergedArray([]);
            // setTemp([]);
            setCheckdata([])
        }
    };

    const onFinish = () => {
        setShowValueModal(false);
        const res = [];
        const keys = Object.keys(filterValues);
        console.log(filterValues, 'filterValues');
        keys.forEach(key => {
            if (filterValues[key] === true) {
                if (key == 'on_occurrence') {
                    key = 'on occurrence';
                }
                if (key == 'one_time') {
                    key = 'One Time';
                }
                if (key == 'flexi') {
                    key = 'Flexi Review';
                }
                res.push(key);
            }

            /*
            Vibin Committed Code
            console.log(filterValues[key], 'filterValues[key]');
            filterValues[key] === true && res.push(
                key == 'on_occurrence' ? 'On Occurrence' : key == 'flexi' ? 'Flexi Review' : key
            )
            */
            // filterValues[key] === true && res.push(
            //     key
            // )
        });
        console.log(res, 'SSSSS');
        let filterTable;
        let temp = [];
        let a;
        if (res[0] == "All" || res.length == 0) {
            filterTable = updatedArray1;
            // settotalRecord(filterTable.length);
        } else {
            for (let i in res) {
                a = updatedArray1.filter((o) =>
                    Object.keys(o).some((k) =>
                        String(o[k]).toLowerCase().includes(res[i].toLowerCase())
                    )
                );

                console.log(temp, a, 'LLLLLLLL');
                Array.prototype.push.apply(temp, a);


            }
            filterTable = temp;
        }
        if (searchingValue['complianceTask']) {
            filterTable = filterTable.filter((item) => {
                if ((item["comp_name"].toLowerCase().includes(searchingValue['complianceTask'].toLowerCase())) || (item["comp_name"].toLowerCase().includes(searchingValue['complianceTask'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['domain']) {
            filterTable = filterTable.filter((item) => {
                if ((item["domain_name"].toLowerCase().includes(searchingValue['domain'].toLowerCase())) || (item["domain_name"].toLowerCase().includes(searchingValue['domain'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['startDate']) {
            filterTable = filterTable.filter((item) => {
                if ((item["start_date"].toLowerCase().includes(searchingValue['startDate'].toLowerCase())) || (item["start_date"].toLowerCase().includes(searchingValue['startDate'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['dueDate']) {
            filterTable = filterTable.filter((item) => {
                if ((item["due_date"].toLowerCase().includes(searchingValue['dueDate'].toLowerCase())) || (item["due_date"].toLowerCase().includes(searchingValue['dueDate'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['days']) {
            filterTable = filterTable.filter((item) => {
                if ((item["Days"].toLowerCase().includes(searchingValue['days'].toLowerCase())) || (item["Days"].toLowerCase().includes(searchingValue['days'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['status']) {
            filterTable = filterTable.filter((item) => {
                if ((item["compliance_status"].toLowerCase().includes(searchingValue['status'].toLowerCase())) || (item["compliance_status"].toLowerCase().includes(searchingValue['status'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['timeLine']) {
            filterTable = filterTable.filter((item) => {
                if ((item["TimeLine"].toLowerCase().includes(searchingValue['timeLine'].toLowerCase())) || (item["TimeLine"].toLowerCase().includes(searchingValue['timeLine'].toLowerCase()))) {
                    return true;
                }
            })
        }
        let totalList = [];
        let arr = [];
        let countrylisttempdata = [];
        let tempSetArray = [];
        let parId = [];
        console.log(filterTable, 'filterTable');
        if (filterTable && filterTable.length > 0) {
            // assigneename &&
            //     assigneename.length > 0 &&
            //     assigneename.map(function (user) {
            //         if (arr.indexOf(user.assignee_name) < 0)
            //             arr.push({
            //                 assignee_name: user.assignee_name,
            //                 unit_name: user.unit_name,
            //             });
            //     });
            // const res = []
            // const keys = Object.keys(filterValues)
            // keys.forEach(key => {
            //     filterValues[key] === true && res.push(
            //         key
            //     )
            // })
            // console.log(res, 'SSSSS')
            // let filterTable;
            // let temp = []
            // let a
            // if (res[0] == "All" || res.length == 0) {
            //     filterTable = updatedArray;
            //     // settotalRecord(filterTable.length);
            // } else {
            //     for (let i in res) {
            //         a = updatedArray.filter((o) =>
            //             Object.keys(o).some((k) =>
            //                 String(o[k]).toLowerCase().includes(res[i].toLowerCase())
            //             )
            //         );

            //         console.log(temp, a, 'LLLLLLLL')
            //         Array.prototype.push.apply(temp, a);


            //     }
            //     filterTable = temp
            //     console.log(filterTable, 'filterTable');
            //     // settotalRecord(filterTable.length);
            // }

            // let totalList = [];
            // let arr = [];
            // let countrylisttempdata = [];
            // let tempSetArray = [];
            // let parId = [];
            // if (filterTable && filterTable.length > 0) {
            // assigneename &&
            //     assigneename.length > 0 &&
            //     assigneename.map(function (user) {
            //         if (arr.indexOf(user.assignee_name) < 0)
            //             arr.push({
            //                 assignee_name: user.assignee_name,
            //                 unit_name: user.unit_name,
            //             });
            //     });
            let tempArray = [];
            filterTable &&
                filterTable.map((subMenuList, childIndex) => {

                    {
                        let split = subMenuList && subMenuList.ageing.split(" ")

                        let finalsplit = { days: '', timeline: '' }

                        for (let i in split) {

                            if (split[i] !== '' && Number(split[i])) {

                                console.log(split[i], 'Number(split[i])')

                                finalsplit.days = split[i]

                                finalsplit.timeline = split[Number(i) + 1]

                                console.log(finalsplit, 'kkksksksksksksksksksksk')

                            }
                        }

                        let entity = {
                            address: subMenuList.address,
                            ageing: subMenuList.ageing,
                            approve_status: subMenuList.approve_status,
                            assigned_on: subMenuList.assigned_on,
                            category_name: subMenuList.category_name,
                            comp_history_count: subMenuList.comp_history_count,
                            compfielaw_url: subMenuList.compfielaw_url,
                            compliance_description: subMenuList.compliance_description,
                            compliance_download_url: subMenuList.compliance_download_url,
                            compliance_file_name: subMenuList.compliance_file_name,
                            compliance_history_id: subMenuList.compliance_history_id,
                            compliance_id: subMenuList.compliance_id,
                            compliance_name: subMenuList.compliance_name,
                            compliance_status: subMenuList.compliance_status,
                            compliance_task_frequency: subMenuList.compliance_task_frequency,
                            division_name: subMenuList.division_name,
                            document_reference_number: subMenuList.document_reference_number,
                            document_size: subMenuList.document_size,
                            domain_id: subMenuList.domain_id,
                            domain_name: subMenuList.domain_name,
                            due_date: subMenuList.due_date,
                            duration_type: subMenuList.duration_type,
                            comp_name: `${subMenuList.assigned_on} ${subMenuList.compliance_name} ${subMenuList.statu}`,
                            Days: `${finalsplit.days ? finalsplit.days : 0}  ${finalsplit.timeline ? finalsplit.timeline : 'day(s)'}`,
                            extend_due_date: subMenuList.extend_due_date,
                            TimeLine: (moment(subMenuList.due_date).isSameOrAfter(today)) ? "Left" : "Over Due",
                            file_names: subMenuList.file_names,
                            interim_count: subMenuList.interim_count,
                            is_active: subMenuList.is_active,
                            is_ctcy_task: subMenuList.is_ctcy_task,
                            next_due_date: subMenuList.next_due_date,
                            occurrence_remarks: subMenuList.occurrence_remarks,
                            penal_consequences: subMenuList.penal_consequences,
                            recall_status: subMenuList.recall_status,
                            recallremarks: subMenuList.recallremarks,
                            remarks: subMenuList.remarks,
                            remarks_count: subMenuList.remarks_count,
                            start_date: subMenuList.start_date,
                            statu: subMenuList.statu,
                            system_remarks: subMenuList.system_remarks,
                            tz_date: subMenuList.tz_date,
                            tz_name: subMenuList.tz_name,
                            unit_id: subMenuList.unit_id,
                            unit_name: subMenuList.unit_name,
                            validity_date: subMenuList.validity_date,
                            validity_settings_days: subMenuList.validity_settings_days,
                            uniqueId: Math.floor(Math.random() * 100)
                        };
                        tempArray.push(entity);
                        // unit_name.push(subMenuList.unit_name);

                    }
                });
            // titleDynamic[index] = pmenu;
            // countrylisttempdata.push(totalList);
            tempSetArray.push(tempArray);
            // parId.push(unit_name);
            setDataList(tempArray);
            setCurrentDetails(tempArray);
            console.log([...new Set([].concat.apply([], parId))], "parIdId");
            setTitleDynamic([...titleDynamic]);
            var merged = [].concat.apply([], tempSetArray);
            // setTemp(countrylisttempdata);
            // setMergedArray(merged);
            setTempFilterArray([...new Set([].concat.apply([], parId))]);
        } else {
            // setMergedArray([]);
            // setTemp([]);
            setTitleDynamic([]);
            setTempFilterArray([]);
            setDataList([]);
            setCurrentDetails([]);
        }


        setshowDropDown(false)
    }
    const onFinish1 = () => {

        const res = []
        const keys = Object.keys(filterValues1)

        keys.forEach(key => {
            if (filterValues1[key] === true) {
                if (key == 'on_occurrence') {
                    key = 'on occurrence'
                }
                if (key == 'one_time') {
                    key = 'One Time';
                }
                if (key == 'flexi_review') {
                    key = 'Flexi Review'
                }
                res.push(key)
            }


        })
        console.log(res, 'SSSSS')
        let filterTable;
        let temp = []
        let a
        if (res[0] == "All" || res.length == 0) {
            filterTable = checkdata1;
            // settotalRecord(filterTable.length);
        } else {
            for (let i in res) {
                a = checkdata1.filter((o) =>
                    Object.keys(o).some((k) =>
                        String(o[k]).toLowerCase().includes(res[i].toLowerCase())
                    )
                );

                console.log(temp, a, 'LLLLLLLL')
                Array.prototype.push.apply(temp, a);


            }
            filterTable = temp
        }

        let totalList = [];
        let arr = [];
        let countrylisttempdata = [];
        let tempSetArray = [];
        let parId = [];
        if (filterTable && filterTable.length > 0) {

            let tempArray = []
            filterTable &&
                filterTable.map((subMenuList, childIndex) => {
                    {

                        let entityArray = {
                            "address": subMenuList.address,
                            "assigned_on": subMenuList.assigned_on,
                            "category": subMenuList.category,
                            "compfielaw_url": subMenuList.compfielaw_url,
                            "compliance_description": subMenuList.compliance_description,
                            "compliance_name": subMenuList.compliance_name,
                            "domain_name": subMenuList.domain_name,
                            "due_date": subMenuList.due_date,
                            "frequency": subMenuList.frequency,
                            "division": subMenuList.division,
                            "penal_consequences": subMenuList.penal_consequences,
                            "start_date": subMenuList.start_date,
                            "statu": subMenuList.statu,
                            "unit_name": subMenuList.unit_name,
                            "upcoming_format_file_name": subMenuList.upcoming_format_file_name,
                            'childId': childIndex,
                            // 'parentId': i,
                            'indexNo': count1
                        }
                        tempArray.push(entityArray);
                        // unit_name.push(subMenuList.unit_name);

                    }
                });
            // titleDynamic[index] = pmenu;
            // countrylisttempdata.push(totalList);
            tempSetArray.push(tempArray);
            // parId.push(unit_name);
            setCheckdata(tempArray)

            var merged = [].concat.apply([], tempSetArray);
            // setTemp(countrylisttempdata);
            // setMergedArray(merged);
            // setTempFilterArray([...new Set([].concat.apply([], parId))]);
        } else {
            // setMergedArray([]);
            // setTemp([]);
            // setTitleDynamic([]);
            // setTempFilterArray([]);
            // setDataList([])
            // setCurrentDetails([])
            setCheckdata([])
        }


        setshowDropDown(false)
    }
    const resetfilter = () => {
        setFilterValues({
            ...filterValues,
            All: '',
            periodical: '',
            flexi_review: '',
            review: '',
            on_occurrence: '',
            one_time: ''
        });
        setChecked({
            ...checked,
            All: false,
            periodical: false,
            flexi_review: false,
            review: false,
            on_occurrence: false,
            one_time: false
        });
    };


    useEffect(() => {
        if (recallremarks && is_modal_true ) {
            let temp = [];
            for (let i = 0; i < recallremarks.length; i++) {
                if (recallremarks[i].r_description != '') {
                    temp.push(recallremarks[i]);
                }
                Setremarksvalue(temp);
            }
            if (temp && temp.length == 0) {
                Toaster.error("No Remarks Available");
                dispatch({
                    type: REMARKS_MODAL_RESET,
                    payload: false
                })
            } else {
                setRemarksModal(true);
                // dispatch({
                //     type: RESET_REMARK_HISTORY
                // });
            }
        }

    }, [recallremarks]);
    //   useEffect(() => {
    //     if (list && list.length > 0) {
    //         let uniqueObjArray = [...new Map(list && list.length && list.map((item) => [item["unit_id"], item])).values()];
    //         const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
    //             return item.unit_id
    //         })
    //         let tempArr = []
    //         for (let i in uniqueIds) {
    //             let filteredArr = _.filter(list, { unit_id: uniqueIds[i] })
    //             tempArr.push(filteredArr)

    //             setFilteredArray([...filteredArray, tempArr])
    //         }
    //     }
    // }, [list])
    console.log(moment(completionDate).format('DD-MMM-YYYY'), 'currentDetails');
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const tablehandleChange = (pagination, filters, sorter) => {
        setSortedInfo(sorter);
        setFilteredInfo(filters);
    };

    const onsubmit = () => {
        setUpdate(false);
        setAddFormSubmit1(false);
        Setpassword({
            ...passwordata,
            passvalue: ''
        });
        console.log(valueModal.start_date, 'valueModal.due_date');
        if (completionDate != '') {
            let date = moment(completionDate && completionDate._d).format('DD-MMM-YYYY');
            if (moment(valueModal.start_date).isSameOrBefore(date)) {

                if (moment(valueModal.due_date).isBefore(date)) {
                    if (completionDate != '') {
                        Swal.fire({
                            title: 'Are You Sure?',
                            icon: 'info',
                            text: 'Task will move to Delayed Complied Status,do you still want to proceed',
                            showCancelButton: true,
                            confirmButtonText: 'Yes',
                            cancelButtonText: 'No',
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                Setpassvisible(true);
                                // setShowValueModal(false)

                                // Swal.fire('Closed.!', 'Closed...', 'success');
                            }
                        });
                    }

                    else {
                        Toaster.error("Task Completion Date / Document Issued Date Required");
                    }
                }
                else {
                    if (completionDate != '') {


                        if (fileupload.length == 0) {
                            const pay = [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request": [
                                        "UpdateComplianceDetail",
                                        {
                                            "le_id": showdata.legal_entity,
                                            "compliance_history_id": valueModal.compliance_history_id,
                                            "documents": fileupload && fileupload.length > 0 ? fileupload : null,
                                            "uploaded_documents": null,
                                            "removed_documents": [],
                                            // (valueModal.dayType == 'hours') && (valueModal.compliance_frequency == 'On Occurrence') ? 'DD-MMM-YYYY HH:mm' : 'DD-MMM-YYYY'
                                            "completion_date": (valueModal.dayType == 'hours') && (valueModal.compliance_frequency == 'On Occurrence') ? moment(completionDate && completionDate._d).format('DD-MMM-YYYY HH:mm') : moment(completionDate && completionDate._d).format('DD-MMM-YYYY'),
                                            "validity_date": (valueModal.validity_date !== null && valueModal.validity_date !== '') && (valueModal.compliance_frequency !== 'On Occurrence') ? moment(valueModal.validity_date).format('DD-MMM-YYYY') : null,
                                            "next_due_date": (valueModal.next_due_date !== null) && (valueModal.compliance_frequency !== 'On Occurrence') ? valueModal.next_due_date : null,
                                            "remarks": remarks,
                                            "file_size": 0,
                                            "tz_date": moment(new Date()).format('DD-MMM-YYYY hh:mm'),
                                            "doc_ref_num": valueModal.document_reference_number != '' ? valueModal.document_reference_number : null,
                                            "password": passwordata.passvalue,
                                            "pwd_validate": false
                                        }
                                    ]
                                }
                            ];
                            const payload = [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request": [
                                        "GetCurrentComplianceDetail",
                                        {
                                            "le_id": showdata.legal_entity,
                                            "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                                            "current_start_count": 0,
                                            "cal_view": null,
                                            "cal_date": null,
                                            "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                                            "division_id": showdata.division != '' ? Number(showdata.division) : null,
                                            "category_id": showdata.category != '' ? Number(showdata.category) : null,
                                            "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                                        }
                                    ]
                                }
                            ];
                            //    if(passwordata.passvalue != ''){
                            saveStatus({
                                payload: pay,
                                paramid: paramid,
                                setValueModal: setValueModal,
                                setCompletionDate: setCompletionDate,
                                SetRemarks: SetRemarks,
                                valueModal: valueModal,
                                nextData: nextDatas,
                                payload2: payload,
                                setShowValueModal: setShowValueModal,
                                Setpassvisible: Setpassvisible
                            });
                            // }else{
                            //     Toaster.error('Password Required')
                            // }

                        }
                        if (fileupload.length > 0) {
                            UploadFile1({
                                payload: [
                                    authtoken,
                                    {
                                        "session_token": authtoken,
                                        "request": [
                                            "UploadComplianceTaskFileNew",
                                            {
                                                "le_id": showdata && showdata.legal_entity,
                                                "c_id": 1,
                                                "selected_task_info": [
                                                    {
                                                        "d_id": Number(valueModal.domain_id),
                                                        "u_id": Number(valueModal.unit_id),
                                                        "start_date": valueModal.start_date,
                                                        "file_info": fileupload,
                                                        "compliance_history_id": Number(valueModal.compliance_history_id)
                                                    }
                                                ],
                                                "type": "",
                                                "password": "",
                                                "pwd_validate": false

                                            }
                                        ]
                                    }


                                ],
                                paramid: paramid
                            });
                        }

                    }
                    else {
                        Toaster.error("Task Completion Date / Document Issued Date Required");
                    }
                }
            }
            else {
                toast.error('Task Completion Date/Document Issued Date must be Greater than or equal to Start Date');
            }
        }

        else {
            Toaster.error("Task Completion Date / Document Issued Date Required");
        }
    };
    const Cancelmodal = () => {
        // setAddFormSubmit1(false)
        setShowValueModal(false);
        // setValueModal({
        //     ...valueModal,
        //     action: '',
        //     remarks: '',
        //     validity_date: '',
        //     next_due_date: ''
        // })
        // setEdit(false)

    };
    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') == null || localStorage.getItem('SelectedEntityid') == 'null') {

        } else {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'));
            if (localStorage.getItem('SelectedEntityid')) {
                // let mail = _.filter(compliancefilter, { legal_entity_id: localStorage.getItem('SelectedEntityid') })
                // setMail(compliancefilter.user_units)

                setLegalentity(localStorage.getItem('SelectedEntityid'));
            }
        }
    }, [localStorage.getItem('SelectedEntityid'), compliancefilter, entityid]);
    useEffect(() => {
        if (dataList && dataList.length > 0) {
            console.log(currentCompliance && currentCompliance.current_compliances, 'currentCompliance && currentCompliance.current_compliances ');
            console.log(dataList, 'dataList');
            if (showMore == true) {
                let tempArr = tempList.concat(dataList);
                setTempList(tempArr);
                // setDataList([dataList,statutoryUnitList && statutoryUnitList.statutoryListByUnit])
            }
            else {
                setTempList(dataList);
            }

        }
        else {
            setTempList([]);
        }
    }, [dataList]);





    console.log(currentCompliance && currentCompliance.current_compliances, 'currentCompliance && currentCompliance.current_compliances');
    useEffect(() => {


        if (currentCompliance && currentCompliance.current_compliances && currentCompliance.current_compliances.length > 0) {
            let temp = [];
            for (let i in currentCompliance && currentCompliance.current_compliances) {
                let split = currentCompliance && currentCompliance.current_compliances && currentCompliance && currentCompliance.current_compliances[i].ageing.split(" ")

                let finalsplit = { days: '', timeline: '' }
                for (let i in split) {

                    if (split[i] !== '' && Number(split[i])) {

                        console.log(split[i], 'Number(split[i])')

                        finalsplit.days = split[i]

                        finalsplit.timeline = split[Number(i) + 1]

                        console.log(finalsplit, 'kkksksksksksksksksksksk')

                    }

                }
                let entity = {
                    comp_name: `${currentCompliance && currentCompliance.current_compliances[i].assigned_on}${currentCompliance && currentCompliance.current_compliances[i].compliance_name}${currentCompliance && currentCompliance.current_compliances[i].statu}`,
                    address: currentCompliance && currentCompliance.current_compliances[i].address,
                    ageing: currentCompliance && currentCompliance.current_compliances[i].ageing,
                    approve_status: currentCompliance && currentCompliance.current_compliances[i].approve_status,
                    assigned_on: currentCompliance && currentCompliance.current_compliances[i].assigned_on,
                    category_name: currentCompliance && currentCompliance.current_compliances[i].category_name,
                    comp_history_count: currentCompliance && currentCompliance.current_compliances[i].comp_history_count,
                    compfielaw_url: currentCompliance && currentCompliance.current_compliances[i].compfielaw_url,
                    compliance_description: currentCompliance && currentCompliance.current_compliances[i].compliance_description,
                    compliance_download_url: currentCompliance && currentCompliance.current_compliances[i].compliance_download_url,
                    compliance_file_name: currentCompliance && currentCompliance.current_compliances[i].compliance_file_name,
                    compliance_history_id: currentCompliance && currentCompliance.current_compliances[i].compliance_history_id,
                    compliance_id: currentCompliance && currentCompliance.current_compliances[i].compliance_id,
                    compliance_name: currentCompliance && currentCompliance.current_compliances[i].compliance_name,
                    compliance_status: currentCompliance && currentCompliance.current_compliances[i].compliance_status,
                    compliance_task_frequency: currentCompliance && currentCompliance.current_compliances[i].compliance_task_frequency,
                    division_name: currentCompliance && currentCompliance.current_compliances[i].division_name,
                    document_reference_number: currentCompliance && currentCompliance.current_compliances[i].document_reference_number,
                    document_size: currentCompliance && currentCompliance.current_compliances[i].document_size,
                    domain_id: currentCompliance && currentCompliance.current_compliances[i].domain_id,
                    domain_name: currentCompliance && currentCompliance.current_compliances[i].domain_name,
                    due_date: currentCompliance && currentCompliance.current_compliances[i].due_date,
                    Days: `${finalsplit.days ? finalsplit.days : 0}  ${finalsplit.timeline ? finalsplit.timeline : 'day(s)'}`,
                    duration_type: currentCompliance && currentCompliance.current_compliances[i].duration_type,
                    extend_due_date: currentCompliance && currentCompliance.current_compliances[i].extend_due_date,
                    file_names: currentCompliance && currentCompliance.current_compliances[i].file_names,
                    interim_count: currentCompliance && currentCompliance.current_compliances[i].interim_count,
                    is_active: currentCompliance && currentCompliance.current_compliances[i].is_active,
                    is_ctcy_task: currentCompliance && currentCompliance.current_compliances[i].is_ctcy_task,
                    next_due_date: currentCompliance && currentCompliance.current_compliances[i].next_due_date,
                    occurrence_remarks: currentCompliance && currentCompliance.current_compliances[i].occurrence_remarks,
                    penal_consequences: currentCompliance && currentCompliance.current_compliances[i].penal_consequences,
                    recall_status: currentCompliance && currentCompliance.current_compliances[i].recall_status,
                    recallremarks: currentCompliance && currentCompliance.current_compliances[i].recallremarks,
                    remarks: currentCompliance && currentCompliance.current_compliances[i].remarks,
                    remarks_count: currentCompliance && currentCompliance.current_compliances[i].remarks_count,
                    TimeLine: (moment(currentCompliance && currentCompliance.current_compliances[i].due_date).isSameOrAfter(today)) ? "Left" : "Over Due",
                    start_date: currentCompliance && currentCompliance.current_compliances[i].start_date,
                    statu: currentCompliance && currentCompliance.current_compliances[i].statu,
                    system_remarks: currentCompliance && currentCompliance.current_compliances[i].system_remarks,
                    tz_date: currentCompliance && currentCompliance.current_compliances[i].tz_date,
                    tz_name: currentCompliance && currentCompliance.current_compliances[i].tz_name,
                    unit_id: currentCompliance && currentCompliance.current_compliances[i].unit_id,
                    unit_name: currentCompliance && currentCompliance.current_compliances[i].unit_name,
                    validity_date: currentCompliance && currentCompliance.current_compliances[i].validity_date,
                    validity_settings_days: currentCompliance && currentCompliance.current_compliances[i].validity_settings_days,
                    uniqueId: Math.floor(Math.random() * 100)
                };
                temp.push(entity);
            }
            setDataList(temp);

        }
    }, [currentCompliance]);



    console.log(tempList, 'tempList');
    useEffect(() => {

        if (filterValues.flexi == true || filterValues.on_occurrence == true || filterValues.one_time == true || filterValues.periodical == true || filterValues.review == true) {
            let temparr = [];
            let temparr2 = [];
            let temparr3 = [];
            let temparr4 = [];
            let temparr5 = [];
            if (filterValues.flexi == true) {
                temparr = _.filter(tempList, { compliance_task_frequency: 'Flexi Review' });
            }
            if (filterValues.periodical == true) {
                temparr2 = _.filter(tempList, { compliance_task_frequency: 'Periodical' });
            }
            if (filterValues.review == true) {
                temparr3 = _.filter(tempList, { compliance_task_frequency: "Review" });
            }
            if (filterValues.on_occurrence == true) {
                temparr4 = _.filter(tempList, { compliance_task_frequency: "On Occurrence" });
            }
            if (filterValues.one_time == true) {
                temparr5 = _.filter(tempList, { compliance_task_frequency: "One Time" });
            }
            let mergedArray = [].concat.apply([], [temparr, temparr2, temparr3, temparr4, temparr5]);
            console.log(mergedArray, 'mergedArray');
            if (mergedArray.length > 0) {
                let temp = [];
                let temp2 = [];
                let temp3 = [];
                let temp4 = [];
                let finalTemp = [];
                if (mergedArray && mergedArray.length > 0) {
                    let recallData = _.filter(mergedArray, { compliance_status: "Recall" });
                    temp4 = recallData;
                    for (let i in mergedArray) {
                        if (mergedArray[i].compliance_status != 'Recall') {
                            temp.push(mergedArray[i]);
                        }
                    }
                    if (temp && temp.length > 0) {
                        for (let j in temp) {
                            // if ((moment(temp[j].due_date).format('DD-MMM-YYYY')) < (moment(new Date()).format('DD-MMM-YYYY'))) {
                            //     console.log(temp[j], 'temp[j]');
                            //     temp2.push(temp[j])
                            // }
                            if (moment(temp[j].due_date).isSameOrAfter(today)) {
                                temp2.push(temp[j]);
                            }

                            else {
                                temp3.push(temp[j]);
                            }
                        }
                    }



                    if (temp4 && temp4.length > 0) {
                        finalTemp.push({
                            "status": "Recall Compliance",
                            "child": temp4
                        });
                    }
                    if (temp3 && temp3.length > 0) {
                        finalTemp.push({
                            "status": "Over Due Compliance",
                            "child": temp3
                        });
                    }
                    if (temp2 && temp2.length > 0) {
                        finalTemp.push({
                            "status": "Inprogress Compliance",
                            "child": temp2
                        });
                    }
                    setCurrentDetails(finalTemp);
                }
                else {
                    setCurrentDetails([]);
                }
            }
        }

        else {




            let temp = [];
            let temp2 = [];
            let temp3 = [];
            let temp4 = [];
            let finalTemp = [];
            if (tempList && tempList.length > 0) {
                let recallData = _.filter(tempList, { compliance_status: "Recall" });
                temp4 = recallData;
                for (let i in tempList) {
                    if (tempList[i].compliance_status != 'Recall') {
                        temp.push(tempList[i]);
                    }
                }
                if (temp && temp.length > 0) {
                    for (let j in temp) {
                        // if ((moment(temp[j].due_date).format('DD-MMM-YYYY')) < (moment(new Date()).format('DD-MMM-YYYY'))) {
                        //     console.log(temp[j], 'temp[j]');
                        //     temp2.push(temp[j])
                        // }
                        if (moment(temp[j].due_date).isSameOrAfter(today)) {
                            temp2.push(temp[j]);
                        }

                        else {
                            temp3.push(temp[j]);
                        }
                    }
                }



                if (temp4 && temp4.length > 0) {
                    finalTemp.push({
                        "status": "Recall Compliance",
                        "child": temp4
                    });
                }
                if (temp3 && temp3.length > 0) {
                    finalTemp.push({
                        "status": "Over Due Compliance",
                        "child": temp3
                    });
                }
                if (temp2 && temp2.length > 0) {
                    finalTemp.push({
                        "status": "Inprogress Compliance",
                        "child": temp2
                    });
                }
                setCurrentDetails(finalTemp);
            }
            else {
                setCurrentDetails([]);
            }
        }
    }, [tempList]);
    useEffect(() => {
        formValidator.current.showMessages();
        formValidator1.current.showMessages();

    }, []);
    const [fullscreen, setfullscreen] = useState(false);
    console.log(fullscreen, 'fullscreenfullscreen');

    // const fullscreenMode = () => {
    //     let topBar = document.getElementById('top-nav');
    //     let leftbar = document.getElementsByClassName('left-sidebar');
    //     let leftnone = leftbar[0];
    //     let pageWrapper = document.getElementById('page-wrapper');
    //     if (fullscreen == false) {
    //         document.documentElement.requestFullscreen();
    //         topBar.style.display = 'none';
    //         leftnone.style.display = 'none';
    //         pageWrapper.style.padding = '0';
    //         pageWrapper.style.marginLeft = '0';
    //         setfullscreen(true);
    //     } else {
    //         document.exitFullscreen();
    //         topBar.style.display = 'block';
    //         leftnone.style.display = 'block';
    //         pageWrapper.style.marginLeft = '65px';
    //         pageWrapper.style.paddingTop = '70px';
    //         setfullscreen(false);
    //     }
    // };

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }

    useEffect(() => {
        if (compliancefiltershow) {
            let temp = [];
            if (compliancefiltershow.widget_data && compliancefiltershow.widget_data.length) {
                let arr = compliancefiltershow.widget_data[0].CurrentMonth.split('-');
                arr.pop();
                // arr.toString("-")
                for (let i in compliancefiltershow.widget_data[0].data) {
                    if (compliancefiltershow.widget_data[0].data[i].overdue > 0
                    ) {
                        let date = new Date(`${arr[0]}-${arr[1]}-${compliancefiltershow.widget_data[0].data[i].date}`).toString();
                        temp.push(
                            {
                                id: "over Due",
                                title: compliancefiltershow.widget_data[0].data[i].overdue,
                                allDay: true,
                                start: date,
                                end: date,
                                color: '#f32d2b',
                                entity: showdata.legal_entity,
                                frequency: showdata.frequency,
                                domain_id: showdata.domain,
                                division: showdata.division,
                                category: showdata.category,
                                unit: showdata.unit,
                                tooltip: `Over Due ${compliancefiltershow.widget_data[0].data[i].overdue}`


                            },
                        );
                    }
                    if (compliancefiltershow.widget_data[0].data[i].inprogress > 0
                    ) {
                        let date = new Date(`${arr[0]}-${arr[1]}-${compliancefiltershow.widget_data[0].data[i].date}`).toString();
                        temp.push(
                            {
                                id: "Inprogress",
                                title: compliancefiltershow.widget_data[0].data[i].inprogress,
                                allDay: true,
                                start: date,
                                end: date,
                                frequency: showdata.frequency,
                                domain_id: showdata.domain,
                                division: showdata.division,
                                category: showdata.category,
                                unit: showdata.unit,
                                color: 'rgb(255 174 44)',
                                entity: showdata.legal_entity,
                                tooltip: `Inprogress ${compliancefiltershow.widget_data[0].data[i].inprogress}`
                            },
                        );
                    }
                    if (compliancefiltershow.widget_data[0].data[i].upcoming > 0
                    ) {
                        let date = new Date(`${arr[0]}-${arr[1]}-${compliancefiltershow.widget_data[0].data[i].date}`).toString();
                        temp.push(
                            {
                                id: "Upcoming",
                                title: compliancefiltershow.widget_data[0].data[i].upcoming,
                                allDay: true,
                                start: date,
                                frequency: showdata.frequency,
                                domain_id: showdata.domain,
                                division: showdata.division,
                                category: showdata.category,
                                unit: showdata.unit,
                                end: date,
                                color: 'rgb(42 125 153)',
                                entity: showdata.legal_entity,
                                tooltip: `Upcoming ${compliancefiltershow.widget_data[0].data[i].upcoming}`
                            },
                        );
                    }
                    if (compliancefiltershow.widget_data[0].data[i].duedate > 0
                    ) {
                        let date = new Date(`${arr[0]}-${arr[1]}-${compliancefiltershow.widget_data[0].data[i].date}`).toString();
                        temp.push(
                            {
                                id: "Due Date",
                                title: compliancefiltershow.widget_data[0].data[i].duedate,
                                allDay: true,
                                start: date,
                                end: date,
                                frequency: showdata.frequency,
                                domain_id: showdata.domain,
                                division: showdata.division,
                                category: showdata.category,
                                unit: showdata.unit,
                                color: 'rgb(149 46 154)',
                                entity: showdata.legal_entity,
                                tooltip: `Due Date ${compliancefiltershow.widget_data[0].data[i].duedate}`
                            },
                        );
                    }
                    if (compliancefiltershow.widget_data[0].data[i].taskrecall > 0
                    ) {
                        let date = new Date(`${arr[0]}-${arr[1]}-${compliancefiltershow.widget_data[0].data[i].date}`).toString();
                        temp.push(
                            {
                                id: "Task Recall",
                                title: compliancefiltershow.widget_data[0].data[i].taskrecall,
                                frequency: showdata.frequency,
                                allDay: true,
                                start: date,
                                end: date,
                                color: '#da6d25',
                                entity: showdata.legal_entity,
                                domain_id: showdata.domain,
                                division: showdata.division,
                                category: showdata.category,
                                unit: showdata.unit,
                                tooltip: `Task Recall ${compliancefiltershow.widget_data[0].data[i].taskrecall}`

                            },
                        );
                    }


                }
            }
            setEventData(temp);
        }

    }, [compliancefiltershow]);
    const onsubmitData = () => {
        setUpdatedArray1([])
        setUpdatedArray([])
        setFormShow(true);
        if (formValidator.current.allValid()) {

            setExitCollapse(true);
            setFilterModalVisible(false);
            setFilterTaskAcc(false);
            setShow(true);
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetCalendarView",
                        {
                            "le_id": showdata.legal_entity,
                            "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                            "cal_date": null,
                            "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                            "div_id": showdata.division != '' ? Number(showdata.division) : null,
                            "cat_id": showdata.category != '' ? Number(showdata.category) : null,
                            "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                        }
                    ]
                }
            ];
            console.log(payload, 'showdata payload');

            if (showdata.legal_entity != '') {
                getCalenderView({
                    payload: payload,
                    paramid: paramid
                });
            }
            setTab('1');

        }
    };
    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "complianceTaskFilters",
                        {
                            "le_id": showdata.legal_entity
                        }
                    ]
                }
            ];
            if (showdata.legal_entity != '') {
                getFilterDataValues({
                    payload: payload,
                    paramid: paramid
                });
            }
        }
    }, [isAuth, showdata.legal_entity]);

    useEffect(() => {
        if ((domain && domain.length === 1) && (showdata.legal_entity != '')) {
            let temp = [];
            for (let i in compliancefilter.user_units) {
                if (compliancefilter.user_units[i].d_ids.includes(Number(domain[0].d_id))) {
                    temp.push(compliancefilter.user_units[i]);
                }
            }
            // let filteredArr = _.filter(compliancefilter && compliancefilter.user_units, { division_id: data })
            settempUnit(temp);
            setUnits(temp);
        }
    }, [domain, showdata.legal_entity]);

    useEffect(() => {
        if (compliancefilter) {
            setDomain(compliancefilter && compliancefilter.domains_list);
            setfrequency(compliancefilter && compliancefilter.frequency_list);
            setCategoryList(compliancefilter && compliancefilter.categories_list);
            setDivision(compliancefilter && compliancefilter.divisions_list);
            setUnits(compliancefilter && compliancefilter.user_units);
        }
    }, [compliancefilter]);

    useEffect(() => {
        validator.current.showMessages();
        validator1.current.showMessages();
        validator2.current.showMessages();
    }, []);

    const OnCompliance = (data) => {
        const lasttransaction = [
            authtoken,
            {
                "session_token": authtoken,
                "request":
                    [
                        "OnOccurrenceLastTransaction",
                        {
                            "le_id": entityid,
                            "compliance_id": data.compliance_id,
                            "unit_id": data.unit_id
                        }
                    ]
            }
        ];
        onoccurrencetransaction({
            payload: lasttransaction,
            paramid: paramid
        });
    };

    const handleCancel = () => {
        setRemarksModal(false);
        dispatch({
            type: REMARKS_MODAL_RESET,
            payload: false
        })
    };


    const OnSubmited = () => {
        setAddFormSubmit1(true);
        if (validator1.current.allValid()) {
            startonoccurrencecompliance({
                payload: [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "StartOnOccurrenceCompliance",
                            {
                                "le_id": entityid,
                                "ac_id": tempvalue.ac_id,
                                "compliance_id": tempvalue.compliance_id,
                                "start_date": taskvalue && taskvalue[0].startdate ? taskvalue[0].startdate : taskvalue[1].startdate,
                                "unit_id": tempvalue.unit_id,
                                "duration": tempvalue.complete_within_days,
                                "remarks": taskvalue && taskvalue[0].remarks ? taskvalue[0].remarks : taskvalue[1].remarks,
                                "password": modalData.password
                            }
                        ]
                    }
                ],
                paramid: paramid
            });
            setModaVisible(false);
        }
    };

    const passwordcancel = () => {
        setModaVisible(false);
        Setpassvisible(false);
        setModalData({
            ...modalData,
            password: ""
        });
    };
    const onSelectEvent = useCallback((calEvent) => {
        console.log(calEvent, 'calEvent');
        setType("");
        setChecked({
            All: false,
            periodical: false,
            flexi: false,
            review: false,
            on_occurrence: false,
            one_time: false
        });
        setTempList([]);
        setEventValue(calEvent && calEvent.title);
        setCalendarDate(moment(calEvent && calEvent.start).format('DD-MMM-YYYY'));
        setType(calEvent && calEvent.id);
        setCount1(0);
        setCount(0);
        setShowMore(false);
        if (calEvent && calEvent.id == 'over Due') {
            setTab('2');
            setParticularevent({
                data: "over Due",
                value: true
            });
            setStatus({
                overDue: true,
                inprogress: false,
                recall: false
            });
            console.log(calEvent && calEvent.domain, ' calEvent && calEvent.domain');
            console.log(showdata.frequency, ' showdata.frequency');
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetCurrentComplianceDetail",
                        {
                            "le_id": calEvent && calEvent.entity,
                            "current_start_count": 0,
                            "cal_view": "OVERDUE",
                            "cal_date": moment(calEvent && calEvent.start).format('DD-MMM-YYYY'),
                            "d_id": calEvent && calEvent.domain_id !== '' ? Number(calEvent && calEvent.domain_id) : null,
                            "unit_id": calEvent && calEvent.unit !== '' ? Number(calEvent && calEvent.unit) : null,
                            "division_id": calEvent && calEvent.division !== '' ? Number(calEvent && calEvent.division) : null,
                            "category_id": calEvent && calEvent.category !== '' ? Number(calEvent && calEvent.category) : null,
                            "frequency_id": calEvent && calEvent.frequency !== '' ? Number(calEvent && calEvent.frequency) : null,
                        }
                    ]
                }
            ];

            getCurrentTaskDetails({

                payload: payload,
                paramid: paramid
            });
        }
        else if (calEvent && calEvent.id == 'Task Recall') {
            setStatus({
                overDue: false,
                inprogress: false,
                recall: true
            });
            setTab('2');
            setParticularevent({
                data: "Task Recall",
                value: true
            });
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetCurrentComplianceDetail",
                        {
                            "le_id": calEvent && calEvent.entity,
                            "current_start_count": 0,
                            "cal_view": "TASKRECALL",
                            "cal_date": moment(calEvent && calEvent.start).format('DD-MMM-YYYY'),
                            "d_id": calEvent && calEvent.domain_id !== '' ? Number(calEvent && calEvent.domain_id) : null,
                            "unit_id": calEvent && calEvent.unit !== '' ? Number(calEvent && calEvent.unit) : null,
                            "division_id": calEvent && calEvent.division !== '' ? Number(calEvent && calEvent.division) : null,
                            "category_id": calEvent && calEvent.category !== '' ? Number(calEvent && calEvent.category) : null,
                            "frequency_id": calEvent && calEvent.frequency !== '' ? Number(calEvent && calEvent.frequency) : null,
                        }
                    ]
                }
            ];

            getCurrentTaskDetails({

                payload: payload,
                paramid: paramid
            });
        }
        else if (calEvent && calEvent.id == 'Due Date') {
            setStatus({
                overDue: true,
                inprogress: true,
                recall: false
            });
            setTab('2');
            setParticularevent({
                data: "Due Date",
                value: true
            });
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetCurrentComplianceDetail",
                        {
                            "le_id": calEvent && calEvent.entity,
                            "current_start_count": 0,
                            "cal_view": "DUEDATE",
                            "cal_date": moment(calEvent && calEvent.start).format('DD-MMM-YYYY'),
                            "d_id": calEvent && calEvent.domain_id !== '' ? Number(calEvent && calEvent.domain_id) : null,
                            "unit_id": calEvent && calEvent.unit !== '' ? Number(calEvent && calEvent.unit) : null,
                            "division_id": calEvent && calEvent.division !== '' ? Number(calEvent && calEvent.division) : null,
                            "category_id": calEvent && calEvent.category !== '' ? Number(calEvent && calEvent.category) : null,
                            "frequency_id": calEvent && calEvent.frequency !== '' ? Number(calEvent && calEvent.frequency) : null,
                        }
                    ]
                }
            ];

            getCurrentTaskDetails({

                payload: payload,
                paramid: paramid
            });
        }
        else if (calEvent && calEvent.id == 'Inprogress') {
            setTab('2');
            setParticularevent({
                data: "Inprogress",
                value: true
            });
            // setParticularevent(true)
            setStatus({
                overDue: true,
                inprogress: true,
                recall: false
            });
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetCurrentComplianceDetail",
                        {
                            "le_id": calEvent && calEvent.entity,
                            "current_start_count": 0,
                            "cal_view": "INPROGRESS",
                            "cal_date": moment(calEvent && calEvent.start).format('DD-MMM-YYYY'),
                            "d_id": calEvent && calEvent.domain_id !== '' ? Number(calEvent && calEvent.domain_id) : null,
                            "unit_id": calEvent && calEvent.unit !== '' ? Number(calEvent && calEvent.unit) : null,
                            "division_id": calEvent && calEvent.division !== '' ? Number(calEvent && calEvent.division) : null,
                            "category_id": calEvent && calEvent.category !== '' ? Number(calEvent && calEvent.category) : null,
                            "frequency_id": calEvent && calEvent.frequency !== '' ? Number(calEvent && calEvent.frequency) : null,
                        }
                    ]
                }
            ];

            getCurrentTaskDetails({

                payload: payload,
                paramid: paramid
            });
        }
        else if (calEvent && calEvent.id == 'Upcoming') {
            setTab('3');
            setCheckdata([]);
            Settitledata([]);
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetUpcomingComplianceDetail",
                        {
                            "le_id": calEvent && calEvent.entity,
                            "upcoming_start_count": 0,
                            "cal_view": "UPCOMING",
                            "cal_date": moment(calEvent && calEvent.start).format('DD-MMM-YYYY'),
                            "d_id": calEvent && calEvent.domain_id !== '' ? Number(calEvent && calEvent.domain_id) : null,
                            "unit_id": calEvent && calEvent.unit !== '' ? Number(calEvent && calEvent.unit) : null,
                            "division_id": calEvent && calEvent.division !== '' ? Number(calEvent && calEvent.division) : null,
                            "category_id": calEvent && calEvent.category !== '' ? Number(calEvent && calEvent.category) : null,
                            "frequency_id": calEvent && calEvent.frequency !== '' ? Number(calEvent && calEvent.frequency) : null,
                        }
                    ]
                }
            ];

            getUpcomigTaskDetails({

                payload: payload,
                paramid: paramid
            });

        }
    }, []);

    const showRemarksModal = () => {
        GetRemarksData({
            payload:
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetRemarksData",
                            {}
                        ]
                    }
                ],
            paramid: paramid
        });
        dispatch({
            type: REMARKS_MODAL_RESET,
            payload: true
        })
    };

    const onInputChange = e => {
        const { value } = e.target;
        const re = /^[A-Z0-9a-z _ .,-]+$/;
        if (value === "" || re.test(value)) {
            SetRemarks(value);
        }
    };

    const onInputChange1 = e => {
        const { value } = e.target;
        const re = /^[A-Z0-9a-z- _.,/)(]+$/;
        if (value === "" || re.test(value)) {
            setValueModal({
                ...valueModal,
                document_reference_number: value
            });
        }
    };

    const { views } = useMemo(() => ({
        views: {
            month: true,

        },


    }), []);
    const homescreen = () => {
        let getUrlDetails = homepage;
        let tempval = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_url;
        let tempkey = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_key;
        localStorage.setItem('formkey', tempkey);
        historynew.push(tempval);
        // window.location.href = homepage;
        document.getElementById('homehover').style.color = "blue";
    };
    const now = new Date();
    const events = [
        {
            id: "over Due",
            title: '5',
            allDay: true,
            start: now,
            end: now,
        },
        {
            id: "Inprogress",
            title: 'All Day Event',
            allDay: true,
            start: 11 / 12 / 2022,
            end: 11 / 12 / 2022,
        },
    ];

    useEffect(() => {
        if (upcomingTask && upcomingTask.upcoming_compliances && upcomingTask.upcoming_compliances.length > 0) {
            let temp = checkdata;
            for (let i in upcomingTask.upcoming_compliances) {
                temp.push(upcomingTask.upcoming_compliances[i]);
            }
            setCheckdata([...temp])
            setCheckdata1([...temp])
        }
    }, [upcomingTask]);

    useEffect(() => {
        // setTempeditdata(editdata)
        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (checkdata) {
            console.log(checkdata, 'checkdata');
            const ab = checkdata.map(function (item) {
                if ((array.indexOf(item.unit_name) < 0))
                    array.push(item.unit_name);
            });
            array.map((data, i) => {
                // let array2 = [];
                let temp = [];
                let entityArray = [];
                if (data) {
                    checkdata.map((child, childIndex) => {
                        if (data === child.unit_name) {
                            entityArray = {
                                "address": child.address,
                                "assigned_on": child.assigned_on,
                                "category": child.category,
                                "compfielaw_url": child.compfielaw_url,
                                "compliance_description": child.compliance_description,
                                "compliance_name": child.compliance_name,
                                "domain_name": child.domain_name,
                                "due_date": child.due_date,
                                "frequency": child.frequency,
                                "division": child.division,
                                "penal_consequences": child.penal_consequences,
                                "start_date": child.start_date,
                                "statu": child.statu,
                                "unit_name": child.unit_name,
                                "upcoming_format_file_name": child.upcoming_format_file_name,
                                'childId': childIndex,
                                'parentId': i,
                                'indexNo': count1
                            };
                            temp.push(entityArray);
                            console.log(temp, 'TEMPPPPPPP');
                            { <span hidden>{count1 = count1 + 1}</span>; }
                        }
                    });
                }

                let checkarray = [];
                let listArray_One_time = _.filter(temp, { frequency: "One Time" });
                console.log(listArray_One_time, 'listArray_One_time');
                if (listArray_One_time && listArray_One_time.length > 0) {
                    for (let i in listArray_One_time) {
                        checkarray.push(listArray_One_time[i]);
                    }
                }
                let listArray_periodical = _.filter(temp, { frequency: "Periodical" });
                if (listArray_periodical && listArray_periodical.length > 0) {
                    for (let i in listArray_periodical) {
                        checkarray.push(listArray_periodical[i]);
                    }
                }
                let listArray_Review = _.filter(temp, { frequency: "Review" });
                if (listArray_Review && listArray_Review.length > 0) {
                    for (let i in listArray_Review) {
                        checkarray.push(listArray_Review[i]);
                    }
                }
                let listArray_Flexi_Review = _.filter(temp, { frequency: "Flexi Review" });
                if (listArray_Flexi_Review && listArray_Flexi_Review.length > 0) {
                    for (let i in listArray_Flexi_Review) {
                        checkarray.push(listArray_Flexi_Review[i]);
                    }
                }
                let listArray_On_Occurrence = _.filter(temp, { frequency: "On Occurrence" });
                if (listArray_On_Occurrence && listArray_On_Occurrence.length > 0) {
                    for (let i in listArray_On_Occurrence) {
                        checkarray.push(listArray_On_Occurrence[i]);
                    }
                }

                datavalue = {
                    index: i,
                    parent: data,
                    child: checkarray
                };
                finalarray.push(datavalue);
            });
            Settitledata(finalarray);
            // getData()
        }

    }, [checkdata]);
    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        if (e) {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex);
        } else {
            setSelectedKeys([]);
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex);
        }
    };
    const handleReset = (clearFilters, handleSearch, confirm, dataIndex, setSelectedKeys) => {
        handleSearch('', confirm, dataIndex, setSelectedKeys);
        clearFilters();
        setSearchText('');
    };
    const submitpassword = () => {
        setAddFormSubmit1(true);

        if (formValidator1.current.allValid()) {
            if (fileupload.length == 0) {
                const pay = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "UpdateComplianceDetail",
                            {
                                "le_id": showdata.legal_entity,
                                "compliance_history_id": valueModal.compliance_history_id,
                                "documents": fileupload && fileupload.length > 0 ? fileupload : null,
                                "uploaded_documents": null,
                                "removed_documents": [],
                                "completion_date": (valueModal.dayType == 'hours') && (valueModal.compliance_frequency == 'On Occurrence') ? moment(completionDate && completionDate._d).format('DD-MMM-YYYY HH:mm') : moment(completionDate && completionDate._d).format('DD-MMM-YYYY'),
                                "validity_date": (valueModal.validity_date !== null && valueModal.validity_date !== '') && (valueModal.compliance_frequency !== 'On Occurrence') ? moment(valueModal.validity_date).format('DD-MMM-YYYY') : null,
                                "next_due_date": (valueModal.next_due_date !== null) && (valueModal.compliance_frequency !== 'On Occurrence') ? valueModal.next_due_date : null,
                                "remarks": remarks,
                                "file_size": 0,
                                "tz_date": moment(new Date()).format('DD-MMM-YYYY hh:mm'),
                                "doc_ref_num": valueModal.document_reference_number != '' ? valueModal.document_reference_number : null,
                                "password": passwordata.passvalue,
                                "pwd_validate": true
                            }
                        ]
                    }
                ];
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetCurrentComplianceDetail",
                            {
                                "le_id": showdata.legal_entity,
                                "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                                "current_start_count": 0,
                                "cal_view": null,
                                "cal_date": null,
                                "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                                "division_id": showdata.division != '' ? Number(showdata.division) : null,
                                "category_id": showdata.category != '' ? Number(showdata.category) : null,
                                "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                            }
                        ]
                    }
                ];
                saveStatus({
                    payload: pay,
                    paramid: paramid,
                    setValueModal: setValueModal,
                    setCompletionDate: setCompletionDate,
                    SetRemarks: SetRemarks,
                    valueModal: valueModal,
                    nextData: nextDatas,
                    setShowValueModal: setShowValueModal,
                    payload2: payload,
                    Setpassvisible: Setpassvisible
                });
            }
            if (fileupload.length > 0) {
                UploadFile1({
                    payload: [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "UploadComplianceTaskFileNew",
                                {
                                    "le_id": showdata && showdata.legal_entity,
                                    "c_id": 1,
                                    "selected_task_info": [
                                        {
                                            "d_id": Number(valueModal.domain_id),
                                            "u_id": Number(valueModal.unit_id),
                                            "start_date": valueModal.start_date,
                                            "file_info": fileupload,
                                            "compliance_history_id": Number(valueModal.compliance_history_id)
                                        }
                                    ],
                                    "type": "",
                                    "password": "",
                                    "pwd_validate": false

                                }
                            ]
                        }


                    ],









                    paramid: paramid
                });
            }

        }

    };
    const showValue = () => {
        setShowValueModal(true);
    };
    useEffect(() => {
        if (nextRecords.length > 0) {
            setValueModal({
                ...valueModal,
                domain_id: nextRecords && nextRecords[0].domain_id,
                start_date: nextRecords && nextRecords[0].start_date,
                uploaded_document: nextRecords && nextRecords[0].uploaded_documents,
                actiondata: nextRecords && nextRecords[0].action,
                approve_status: nextRecords && nextRecords[0].approve_status,
                action: nextRecords && nextRecords[0].compliance_status,
                unit_id: nextRecords && nextRecords[0].unit_id,
                due_date: nextRecords && nextRecords[0].due_date,
                completion_date: nextRecords && nextRecords[0].completion_date,
                interim_count: nextRecords && nextRecords[0].interim_count,
                remarks: nextRecords && nextRecords[0].remarks,
                compliance_description: nextRecords && nextRecords[0].compliance_description,
                description: nextRecords && nextRecords[0].compliance_description,
                compliance_history_id: nextRecords && nextRecords[0].compliance_history_id,
                unit: nextRecords && nextRecords[0].unit_name,
                address: nextRecords && nextRecords[0].address,
                recall: nextRecords && nextRecords[0].recallremarks,
                document_size: nextRecords && nextRecords[0].document_size,
                compliance_task: nextRecords && nextRecords[0].compliance_name,
                compliance_frequency: nextRecords && nextRecords[0].compliance_task_frequency,
                document_issued_date: nextRecords && nextRecords[0].completion_date,
                document_reference_number: nextRecords && nextRecords[0].document_reference_number,
                next_due_date1: nextRecords && nextRecords[0].next_due_date != null ? nextRecords && nextRecords[0].next_due_date : null,
                next_due_date: nextRecords && nextRecords[0].next_due_date != null ? nextRecords && nextRecords[0].next_due_date : nextRecords && nextRecords[0].due_date,
                history_count: nextRecords && nextRecords[0].remarks_count,
                uniqueId: nextRecords && nextRecords[0].uniqueId,
                occurrence_remarks: nextRecords && nextRecords[0].occurrence_remarks,
                status: nextRecords && nextRecords[0].compliance_status,
                address: nextRecords && nextRecords[0].address

            });
            setAttachedFile(nextRecords && nextRecords[0].compliance_download_url);
            setAttachedFile1(nextRecords && nextRecords[0].compliance_download_url);
            setValueForFileUpload({
                d_id: nextRecords && nextRecords[0].domain_id,
                u_id: nextRecords && nextRecords[0].unit_id,
                start_date: nextRecords && nextRecords[0].start_date,
            });
            showValue();
            setCompletionDate('');
            SetRemarks('');
            setFileSize(0);
            setFileUpload([]);
            dispatch({
                type: FILE_SIZE,
                payload: 0,
            });
        }
        else {
            setShowValueModal(false);
            setFileSize(0);

        }

    }, [nextRecords]);
    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div className='d-flex' style={{ position: 'relative' }}>
                <Input className='form-control'
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    value={selectedKeys[0]}
                    autoFocus={'on'}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}
                />
                <CloseCircleOutlined className='table-filter-reset' onClick={() => handleReset(clearFilters, handleSearch, confirm, dataIndex, setSelectedKeys)} />

            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    //     // color: filtered ? '#1890ff' : undefined,
                    //     fontWeight: 'bold',
                    //     // backgroundColor:'blue',
                    //     fontSize: '15px',
                    //     color: 'black'
                }}
            />
            // <FileSearchOutlined 
            // style={{
            //     // color: filtered ? '#1890ff' : undefined,
            //     fontWeight:'bold',
            //     // backgroundColor:'blue',
            //     // fontSize:'20px',
            //     color:'black'
            // }}
            // />
            // <i class="bi bi-search"></i>
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });
    // const CustomToolbar=()=>{
    //     return (
    //         <div className="toolbar-container">

    //           <div className="back-next-buttons">
    //             <button className="btn btn-back">
    //               <BackIcon/>
    //             </button>
    //             <label className='label-date'>Aug-Sept 2016</label>
    //           </div>

    //           <div className="filter-container">
    //             <ButtonGroup>
    //               <Button className="bg-filter-off"><span className="label-filter-off">Day</span></Button>
    //               <Button className="bg-filter-off"><span className="label-filter-off">Week</span></Button>
    //               <Button className="bg-filter-off"><span className="label-filter-off">Month</span></Button>
    //               <Button className="bg-filter-off"><span className="label-filter-off">Year</span></Button>
    //             </ButtonGroup>


    //           </div>
    //         </div >
    //       )
    // }
    const CustomToolbar = (e) => {
        let date1 = new Date();
        let month = new Date(
            date1.getFullYear(),
            date1.getMonth() + 5,
            5);

        return (

            <div className="col-md-12">

                <div className="row">

                    <div className="col-md-4">
                        {(moment(e.date).format('MMM') == moment(new Date()).format('MMM')) ? false :
                            <Button icon={<DoubleLeftOutlined />}
                                onClick={() => {
                                    let date1 = e.date;
                                    let date = new Date(
                                        date1.getFullYear(),
                                        date1.getMonth() - 1,
                                        1);

                                    let startMonth = moment(date).startOf('month');
                                    const payload = [
                                        authtoken,
                                        {
                                            "session_token": authtoken,
                                            "request": [
                                                "GetCalendarView",
                                                {
                                                    "le_id": showdata.legal_entity,
                                                    "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                                                    "cal_date": ((moment((currentMonth && currentMonth._d)).format('DD-MMM-YYYY')) === (moment(startMonth._d).format('DD-MMM-YYYY'))) ? null : moment(startMonth._d).format('DD-MMM-YYYY'),
                                                    "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                                                    "div_id": showdata.division != '' ? Number(showdata.division) : null,
                                                    "cat_id": showdata.category != '' ? Number(showdata.category) : null,
                                                    "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                                                }
                                            ]
                                        }
                                    ];
                                    if (showdata.legal_entity != '') {
                                        getCalenderView({
                                            payload: payload,
                                            paramid: paramid
                                        });
                                    }
                                    setDate(date);


                                }}>



                            </Button>}

                    </div>

                    <div className="col-md-4 text-center align-self-center">

                        <label ><b>{e.label.replace(" ", " - ")}</b></label>

                    </div>

                    <div className="col-md-4" >
                        {(moment(e.date).format('MMM') == moment(month).format('MMM')) ? false :
                            <Button
                                onClick={() => {
                                    let date1 = e.date;
                                    let date = new Date(
                                        date1.getFullYear(),
                                        date1.getMonth() + 1,
                                        1);

                                    let startMonth = moment(date).startOf('month');

                                    const payload = [
                                        authtoken,
                                        {
                                            "session_token": authtoken,
                                            "request": [
                                                "GetCalendarView",
                                                {
                                                    "le_id": showdata.legal_entity,
                                                    "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                                                    "cal_date": ((moment((currentMonth && currentMonth._d)).format('DD-MMM-YYYY')) === (moment(startMonth._d).format('DD-MMM-YYYY'))) ? null : moment(startMonth._d).format('DD-MMM-YYYY'),
                                                    "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                                                    "div_id": showdata.division != '' ? Number(showdata.division) : null,
                                                    "cat_id": showdata.category != '' ? Number(showdata.category) : null,
                                                    "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                                                }
                                            ]
                                        }
                                    ];
                                    if (showdata.legal_entity != '') {
                                        getCalenderView({
                                            payload: payload,
                                            paramid: paramid
                                        });
                                    }
                                    setDate(date);


                                }}
                                icon={<DoubleRightOutlined />} style={{ float: 'right' }}>



                            </Button>


                        }
                    </div>

                </div>



            </div>

        );

    };

    const showmore = () => {
        setShowMore(true);
        setCount(counts + 100);

        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetCurrentComplianceDetail",
                    {
                        "le_id": showdata && showdata.legal_entity,
                        "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                        "current_start_count": counts + 100,
                        "cal_view": type == 'over Due' ? "OVERDUE" : type == 'Task Recall' ? "TASKRECALL" : type == 'Inprogress' || type == 'Due Date' ? "INPROGRESS" : null,
                        "cal_date": calendarDate,
                        "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                        "division_id": showdata.division != '' ? Number(showdata.division) : null,
                        "category_id": showdata.category != '' ? Number(showdata.category) : null,
                        "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                    }
                ]
            }
        ];
        console.log(payload, 'payload565555555');
        getCurrentTaskDetails({

            payload: payload,
            paramid: paramid
        });
    };


    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };
    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };
    // useEffect(() => {
    //     if (fileupload.length > 0) {
    //         let temp = [];
    //         let temp2 = attachedFile;
    //         let temp3 = [];
    //         for (let i in fileupload) {
    //             temp.push({
    //                 file_name: fileupload[i].file_name,
    //                 file_size: fileupload[i].file_size,
    //                 file_content: null
    //             });
    //             temp2.push(fileupload[i].file_name);
    //             temp3.push(fileupload[i].file_name);
    //         }
    //         setFileUpload1(temp);
    //         setAttachedFile(temp2);
    //         setAttachedFileName(temp3);
    //     }
    // }, [fileupload]);
    useEffect(() => {
        if (fileupload && fileupload.length > 0) {
            const filePayload = [
                authtoken,
                {
                    "session_token": authtoken,
                    // "request": [
                    //     "UploadComplianceTask_temp_upload",
                    //     {
                    //         "le_id": showdata.legal_entity,
                    //         "c_id": 1,
                    //         "d_id": valueForFileUpload.d_id,
                    //         "u_id": valueForFileUpload.u_id,
                    //         "start_date": valueForFileUpload.start_date,
                    //         "file_info": results,
                    //         "compliance_history_id": Number(valueModal.compliance_history_id),
                    //     }
                    // ]
                    "request": [
                        "UploadComplianceTask_temp_upload",
                        {
                            "le_id": showdata.legal_entity,
                            "c_id": 1,
                            "d_id": valueForFileUpload.d_id,
                            "u_id": valueForFileUpload.u_id,
                            "start_date": valueForFileUpload.start_date,
                            "file_info": fileupload,
                            "compliance_history_id": Number(valueModal.compliance_history_id),
                        }
                    ]
                }
            ];
            console.log(filePayload, 'filePayload555');
            UploadFile({
                payload: filePayload,
                paramid: paramid,
                setFileUpload: setFileUpload,
                setFileSize: setFileSize
            });
        }
    }, [fileupload]);
    useEffect(() => {
        console.log(filesizeValues, 'filesizeValues');
        if (filesizeValues !== 0 && filesizeValues !== undefined) {

            if (fileupload.length > 0) {
                let temp = [];
                let fileTotalSize = [];
                for (let i in fileupload) {
                    temp.push(Number(fileupload[i].file_size));
                }
                fileTotalSize = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue;
                }, 0);
                var sizeInMB = fileTotalSize + filesizeValues && filesizeValues.file_size;
                console.log(fileTotalSize, 'fileTotalSize5555');
                console.log(sizeInMB, 'sizeInMB33333');
                setFileSize(sizeInMB);
            }
            else {

                setFileSize(filesizeValues && filesizeValues.file_size)
            }
        }
        else {
            if (fileupload.length > 0) {
                let temp = [];
                let fileTotalSize = [];
                for (let i in fileupload) {
                    temp.push(Number(fileupload[i].file_size));
                }
                fileTotalSize = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue;
                }, 0);
                var sizeInMB = fileTotalSize + valueModal.document_size;
                console.log(fileTotalSize, 'fileTotalSize');
                console.log(sizeInMB, 'sizeInMB');
                setFileSize(sizeInMB);
            }
            else if (attachedFile.length == 0) {
                setFileSize(0)
            }
            else if (valueModal.document_size !== null) {
                console.log('testttt');
                setFileSize(valueModal.document_size);
            }
        }

    }, [fileupload, valueModal.document_size, attachedFile, filesizeValues]);

    const uploadFileFormat = (size, name, content) => {
        var result = {
            'file_size': size,
            'file_name': name,
            'file_content': content,

        };
        // setFileSize(size)

        return result;
    };

    const convert_to_base64 = (file, name, size, callback) => {
        var reader = new FileReader();
        if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
            var binary = "";
            reader.onload = function (readerEvt) {
                var bytes = new Uint8Array(readerEvt.target.result);
                var length = bytes.byteLength;
                for (var i = 0; i < length; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }
                callback(EncryptDecrypt.encryptdata(binary), name, size);
            };
            reader.readAsArrayBuffer(file);
        } else {
            reader.onload = function (readerEvt) {
                var binaryString = readerEvt.target.result;
                callback(EncryptDecrypt.encryptdata(binaryString), name, size);
            };
            reader.readAsBinaryString(file);
        }
    };
    const SavepastRecordOnChange = (e) => {
        let arr = []
        if (e.target.name === 'file') {
            var results = [];
            const fileRegex = /^[a-zA-Z0-9-_& ,.+#()[-\]{}$^=%@]*$/;
            let FILE_TYPE = [
                "doc", "docx", "rtf", "pdf", "txt", "png", "jpeg", "gif", "csv", "xls", "xlsx",
                "rar", "tar", "gz", "ppt", "pptx", "jpg", "bmp", "odt", "odf", "ods", "xml"
            ];
            let files = e.target.files;
            let file_max_size = 26214400;
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                var file_name = file.name;
                const f_name = file_name.substring(0, file_name.lastIndexOf('.'));

                var file_extension = file_name.substring(file_name.lastIndexOf('.') + 1).toLowerCase();
                console.log(file_extension, 'file_extension');
                if (FILE_TYPE.includes(file_extension) == true) {
                    if (fileRegex.test(f_name) === false) {
                        return;
                    }
                    let file_size = file.size;
                    if (file_size > file_max_size) {
                        toast.error("Maximum filesize limit exceed");
                        return;
                    }
                    if (file) {

                        convert_to_base64(file, file_name, file_size, function (file_content, name, size) {
                            var fN = name.substring(0, name.lastIndexOf('.'));
                            var fE = name.substring(name.lastIndexOf('.') + 1);
                            var uniqueId = Math.floor(Math.random() * 90000) + 10000;
                            var f_Name = fN + '-' + uniqueId + '.' + fE;

                            var result = uploadFileFormat(size, f_Name, file_content);
                            setFileData(result);
                            results.push(result);

                            let temp1 = [];
                            let temp = [];
                            if (results && results.length > 0) {
                                for (let i in results) {
                                    temp1.push(results[i]);

                                }
                                setFileUpload([...temp1]);

                                arr = results && results.map((item) => {
                                    return item.file_name
                                })
                            }
                            console.log(arr, 'arr555');
                            setAttachedFile([...attachedFile, ...arr])
                            // else {
                            //     for (let i in results) {
                            //         temp.push(results[i]);
                            //     }
                            //     setFileUpload([...temp]);
                            // }


                        });
                    }

                } else {
                    Toaster.error('Invalid File Format');

                }
                console.log(arr, 'arr');

            }
        }
        React.forwardRef(({ onClick, ...rest }, ref) => {
            return (
                <>
                    <div className="form-check pb-5" style={{ backgroundColor: '' }}>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            style={{ height: '20px', width: '20px' }}
                            ref={ref}
                            onClick={onClick}
                            {...rest}
                        />
                        <label className="form-check-label" id="booty-check" />
                    </div>
                </>
            );
        });
    };
    const columns = [
        {
            title: "#",
            render: (row, record, index) => {
                if (record.frequency == 'Periodical') {
                    return <p><span style={{ padding: '5px', display: 'block' }}>{index + 1}</span>
                        <i style={{ color: "green" }} className="bi bi-square-fill" title={record.frequency}></i>
                    </p>;
                } else if (record.frequency == 'FlexiReview') {
                    return <p><span style={{ padding: '5px', display: 'block' }}>{index + 1}</span>
                        <i style={{ color: "pink" }} className="bi bi-square-fill" title={record.frequency}></i></p>;
                } else if (record.frequency == 'Review') {
                    return <p><span style={{ padding: '5px', display: 'block' }}>{index + 1}</span>
                        <i style={{ color: "orange" }} className="bi bi-square-fill" title={record.frequency}></i></p>;
                }
                else if (record.frequency == 'On Occurrence') {
                    return <p><span style={{ padding: '5px', display: 'block' }}>{index + 1}</span>
                        <i style={{ color: "yellow" }} className="bi bi-square-fill" title={record.frequency}></i></p>;
                } else {
                    return <p><span style={{ padding: '5px', display: 'block' }}>{index + 1}</span>
                        <i style={{ color: "red" }} className="bi bi-square-fill" title={record.frequency}></i></p>;
                }
            },
            width: '30px',
            align: 'center',
            filters: [
                {
                    text: <span><i style={{ color: "green" }} className="bi bi-square-fill"></i> Periodical</span>,
                    value: 'Periodical',
                },
                {
                    text: <span><i style={{ color: "pink" }} className="bi bi-square-fill"></i> Flexi Review</span>,
                    value: 'FlexiReview',
                },
                {
                    text: <span><i style={{ color: "orange" }} className="bi bi-square-fill"></i> Review</span>,
                    value: 'Review',
                },
                {
                    text: <span><i style={{ color: "yellow" }} className="bi bi-square-fill"></i> On Occurrence</span>,
                    value: 'On Occurrence',
                },
                {
                    text: <span><i style={{ color: "red" }} className="bi bi-square-fill"></i> One Time</span>,
                    value: 'One Time',
                },
            ],
            onFilter: (value, record) => record.frequency == value,
            //: 'tree',
        },
        {
            title: "Compliance Task",
            dataIndex: 'compliance_name',
            key: 'compliance_name',
            ...getColumnSearchProps('compliance_name', 'Compliance Task'),
            render: (row, record) => {
                console.log(record, 'recordrecord');
                const content = (
                    <table style={{ display: 'block', width: '500px' }}  >
                        <tbody>
                            <tr>
                                <th style={{ border: "1px solid #636262" }}>Unit Name</th>
                                <td style={{ border: "1px solid #636262" }}>{record.unit_name}</td>
                            </tr>
                            <tr>
                                <th style={{ border: "1px solid #636262" }}>Address</th>
                                <td style={{ border: "1px solid #636262" }}>{record.address}</td>
                            </tr>
                            <tr>
                                <th style={{ border: "1px solid #636262" }}>Division</th>
                                <td style={{ border: "1px solid #636262" }}>{record.division_name}</td>
                            </tr>
                            <tr>
                                <th style={{ border: "1px solid #636262" }}>Category</th>
                                <td style={{ border: "1px solid #636262" }}>{record.category_name}</td>
                            </tr>
                            <tr>
                                <th style={{ border: "1px solid #636262" }}>Act / Rules</th>
                                <td style={{ border: "1px solid #636262" }}>{record.statu}</td>
                            </tr>
                            <tr>
                                <th style={{ border: "1px solid #636262" }}>Compliance Description</th>
                                <td style={{ border: "1px solid #636262" }}>{record.compliance_description}</td>
                            </tr>
                            <tr style={{ borderRight: '1px solid grey' }}>
                                <th style={{ border: "1px solid #636262" }}>Penal Consequences</th>
                                <td style={{ border: "1px solid #636262" }}>{record.penal_consequences}</td>
                            </tr>
                            {console.log(record, 'recorddddddddddd')}
                        </tbody>
                    </table>
                );
                return (
                    <>
                        <span>{record.statu}</span>
                        <div>
                            <div className="mb-0">
                                <Tooltip title={content}>
                                    <InfoCircleOutlined style={{ color: '#5DAAE3' }} />
                                </Tooltip>{record.compliance_name}
                            </div>
                            <span style={{ fontSize: '12px' }}>Assigned on : {record.assigned_on}</span>
                        </div>

                    </>
                );
            },
            width: '250px',
            // render: (compliance_task) => <a href="javascript:;" onClick={() => { showModal() }}>{compliance_task}</a>,
            // sorter: (a, b) => { return a.statu.localeCompare(b.statu) },
        },
        {
            title: "Domain",
            dataIndex: 'domain_name',
            key: 'domain_name',
            width: '135px',
            ...getColumnSearchProps('domain_name', 'Domain'),


        },
        {
            title: "Start Date",
            dataIndex: 'start_date',
            key: 'start_date',
            ellipsis: true,
            width: '60px',
            ...getColumnSearchProps('start_date', 'Start Date'),

        },

        {
            title: "Due Date",
            dataIndex: 'due_date',
            key: 'due_date',
            width: '60px',
            ...getColumnSearchProps('due_date', 'Due Date'),
            // render: (text, record) => {
            //     return moment(record.due_date).format('DD-MMM-YYYY')
            // }

        },
        {
            title: "Format",
            dataIndex: 'compliance_file_name',
            align: 'center',
            key: 'compliance_file_name',
            width: '40px',
            render: (row, record) => {
                console.log(record, 'record');
                let file = record.upcoming_format_file_name && record.upcoming_format_file_name.length > 0 && record.upcoming_format_file_name[0];
                return (
                    <>
                        {
                            record.upcoming_format_file_name && record.upcoming_format_file_name.length > 0 ?
                                <>
                                    <Tooltip title='Download Format'>
                                        <DownloadOutlined
                                            onClick={() => {
                                                console.log(file, 'file');
                                            }}
                                        />
                                    </Tooltip>
                                </> : ''
                        }
                    </>
                );
            }



        },


    ];
    const columns2 = [
        // {
        //     title: <Fragment>
        //         <input type="checkbox" name="allchecked" />
        //     </Fragment>,
        //     dataIndex: false,
        //     key: false,
        //     ellipsis: true,
        //     width: '7px',
        //     align: 'center',
        //     render: (text, record) => {
        //         return (
        //             <Fragment>
        //                 <input type="checkbox"
        //                     name={'checked' + record.parentId}
        //                     onClick={(e) => {
        //                         let checked = e.target.checked;
        //                         addSelectedDataValues(
        //                             checked,
        //                             record.compliance_history_id,
        //                         );
        //                     }}
        //                 />
        //             </Fragment>
        //         )
        //     }
        // },
        {
            title: "#",
            render: (row, record, index) => {
                if (record.compliance_task_frequency == 'Periodical') {
                    return <Tooltip title={record && record.compliance_task_frequency
                    }><p><span style={{ padding: '5px', display: 'block' }}>{index + 1}</span><i style={{ color: "green" }} class="bi bi-square-fill"></i></p></Tooltip>;
                } else if (record.compliance_task_frequency == 'FlexiReview') {
                    return <Tooltip title={record && record.compliance_task_frequency
                    }><p><span style={{ padding: '5px', display: 'block' }}>{index + 1}</span><i style={{ color: "pink" }} class="bi bi-square-fill"></i></p></Tooltip>;
                } else if (record.compliance_task_frequency == 'Review') {
                    return <Tooltip title={record && record.compliance_task_frequency
                    }><p><span style={{ padding: '5px', display: 'block' }}>{index + 1}</span><i style={{ color: "orange" }} class="bi bi-square-fill"></i></p></Tooltip>;
                } else if (record.compliance_task_frequency == 'On Occurrence') {
                    return <Tooltip title={record && record.compliance_task_frequency
                    }><p><span style={{ padding: '5px', display: 'block' }}>{index + 1}</span><i style={{ color: "yellow" }} class="bi bi-square-fill"></i></p></Tooltip>;
                } else {
                    return <Tooltip title={record && record.compliance_task_frequency
                    }><p><span style={{ padding: '5px', display: 'block' }}>{index + 1}</span><i style={{ color: "red" }} class="bi bi-square-fill"></i></p></Tooltip>;
                }
            },
            width: '30px',
            align: 'center',
            filters: [
                {
                    text: <span><i style={{ color: "green" }} class="bi bi-square-fill"></i> Periodical</span>,
                    value: 'Periodical',
                },
                {
                    text: <span><i style={{ color: "pink" }} class="bi bi-square-fill"></i> Flexi Review</span>,
                    value: 'FlexiReview',
                },
                {
                    text: <span><i style={{ color: "orange" }} class="bi bi-square-fill"></i> Review</span>,
                    value: 'Review',
                },
                {
                    text: <span><i style={{ color: "yellow" }} class="bi bi-square-fill"></i> On Occurrence</span>,
                    value: 'On Occurrence',
                },
                {
                    text: <span><i style={{ color: "red" }} class="bi bi-square-fill"></i> One Time</span>,
                    value: 'One Time',
                },
            ],
            onFilter: (value, record) => record.compliance_task_frequency == value,
            // onFilter: (value, record) => record.compliance_task_frequency == value,
            //: 'tree',
            // ...getColumnSearchProps('compliance_frequency'), record.compliance_task_frequency.startsWith(value)
        },
        {
            title: "Compliance Task",
            dataIndex: 'compliance_name',
            key: 'compliance_name',
            ...getColumnSearchProps('compliance_name', 'Compliance Task'),


            // selector: (row) => <><p>{row.statu}</p><br></br><p>{row.compliance_name}</p></>,
            render: (row, record) => {
                const content = (
                    <table style={{ width: '500px' }}>
                        <tbody>
                            <tr>
                                <th style={{ border: "1px solid #636262" }}>Unit Name</th>
                                <td style={{ border: "1px solid #636262" }}>{record.unit_name}</td>
                            </tr>
                            <tr>
                                <th style={{ border: "1px solid #636262" }}>Address</th>
                                <td style={{ border: "1px solid #636262" }}>{record.address}</td>
                            </tr>
                            <tr>
                                <th style={{ border: "1px solid #636262" }}>Division</th>
                                <td style={{ border: "1px solid #636262" }}>{record.division_name}</td>
                            </tr>
                            <tr>
                                <th style={{ border: "1px solid #636262" }}>Category</th>
                                <td style={{ border: "1px solid #636262" }}>{record.category_name}</td>
                            </tr>
                            <tr>
                                <th style={{ border: "1px solid #636262" }}>Act / Rules</th>
                                <td style={{ border: "1px solid #636262" }}>{record.statu}</td>
                            </tr>
                            <tr>
                                <th style={{ border: "1px solid #636262" }}>Compliance Description</th>
                                <td style={{ border: "1px solid #636262" }}>{record.compliance_description}</td>
                            </tr>
                            <tr style={{ borderRight: '1px solid grey' }}>
                                <th style={{ border: "1px solid #636262" }}>Penal Consequences</th>
                                <td style={{ border: "1px solid #636262" }}>{record.penal_consequences}</td>
                            </tr>
                        </tbody>
                    </table>
                );
                return (
                    <>
                        <span style={{ cursor: 'pointer', color: record.compliance_status == 'Recall' ? 'brown' : record.compliance_status == 'Rectify' ? 'red' : 'black' }}
                            onClick={() => {
                                console.log(record, 'recordrecord');
                                setShowValueModal(true);
                                setValueModal({
                                    ...valueModal,
                                    domain_id: record.domain_id,
                                    start_date: record.start_date,
                                    uploaded_document: record.uploaded_documents,
                                    actiondata: record.action,
                                    approve_status: record.approve_status,
                                    unit_id: record.unit_id,
                                    completion_date: record.completion_date,
                                    remarks: record.remarks,
                                    description: record.compliance_description,
                                    compliance_history_id: record.compliance_history_id,
                                    unit: record.unit_name,
                                    address: record.address,
                                    due_date: record.due_date,
                                    recall: record.recallremarks,
                                    compliance_task: record.compliance_name,
                                    compliance_frequency: record.compliance_task_frequency,
                                    document_issued_date: record.completion_date,
                                    document_reference_number: record.document_reference_number,
                                    next_due_date: record.next_due_date != null ? record.next_due_date : record.due_date,
                                    next_due_date1: record.next_due_date != null ? record.next_due_date : null,
                                    history_count: record.history_count,
                                    occurrence_remarks: record.occurrence_remarks,
                                    status: record.compliance_status
                                });
                            }}
                        // onClick={() => {
                        //     showValue()
                        //     setValueModal({
                        //         compliance_history_id: record.compliance_history_id,
                        //         unit: record.unit_name,
                        //         compliance_task: record.compliance_name,
                        //         compliance_frequency: record.compliance_frequency,
                        //         document_issued_date: record.completion_date,
                        //         document_reference_number: record.document_reference_number,
                        //         next_due_date: record.next_due_date,
                        //         address: record.address,
                        //         compliance_description: record.compliance_description,
                        //         uploaded_document: record.upload_docs,
                        //         interim_details: record.interim_docs,
                        //         remarks: record.remarks

                        //     })
                        // }}
                        >{record.statu}</span>
                        <div>
                            <div className="mt-2 mb-2" onClick={() => {
                                setShowValueModal(true);
                                setValueForFileUpload({
                                    d_id: record.domain_id,
                                    u_id: record.unit_id,
                                    start_date: record.start_date,
                                });
                                setValueModal({
                                    ...valueModal,
                                    domain_id: record.domain_id,
                                    start_date: record.start_date,
                                    uploaded_document: record.uploaded_documents,
                                    actiondata: record.action,
                                    approve_status: record.approve_status,
                                    unit_id: record.unit_id,
                                    completion_date: record.completion_date,
                                    remarks: record.remarks,
                                    description: record.compliance_description,
                                    compliance_history_id: record.compliance_history_id,
                                    unit: record.unit_name,
                                    address: record.address,
                                    due_date: record.due_date,
                                    recall: record.recallremarks,
                                    compliance_task: record.compliance_name,
                                    compliance_frequency: record.compliance_task_frequency,
                                    document_issued_date: record.completion_date,
                                    document_reference_number: record.document_reference_number,
                                    next_due_date: record.next_due_date != null ? record.next_due_date : record.due_date,
                                    next_due_date1: record.next_due_date != null ? record.next_due_date : null,
                                    history_count: record.history_count,
                                    occurrence_remarks: record.occurrence_remarks,
                                    status: record.compliance_status
                                });
                            }}>
                                <Tooltip title={content}>
                                    {/* <Button style={{ border: 0 }}> */}
                                    <span style={{ marginRight: '3px' }}>
                                        <i class="bi-info-circle-fill" style={{ color: '#5DAAE3' }}></i>
                                    </span>
                                    {/* </Button> */}
                                    <span style={{ color: record.compliance_status == 'Recall' ? 'brown' : record.compliance_status == 'Rectify' ? 'red' : 'black' }}></span> </Tooltip> <a >{record.compliance_name}</a>
                            </div>
                        </div>

                    </>
                );
            },
            width: '150px',
            // render: (compliance_task) => <a href="javascript:;" onClick={() => { showModal() }}>{compliance_task}</a>,
            // sorter: (a, b) => { return a.statu.localeCompare(b.statu) },
        },
        {
            title: "Domain",
            dataIndex: false,
            key: false,
            width: '140px',
            ...getColumnSearchProps('domain_name', 'Domain'),
            render: (row, record) => {

                return (<p style={{ cursor: 'pointer', color: record.compliance_status == 'Recall' ? 'brown' : record.compliance_status == 'Rectify' ? 'red' : 'black' }} onClick={() => {
                    setShowValueModal(true);
                    setValueForFileUpload({
                        d_id: record.domain_id,
                        u_id: record.unit_id,
                        start_date: record.start_date,
                    });
                    setValueModal({
                        ...valueModal,
                        domain_id: record.domain_id,
                        start_date: record.start_date,
                        uploaded_document: record.uploaded_documents,
                        actiondata: record.action,
                        approve_status: record.approve_status,
                        unit_id: record.unit_id,
                        completion_date: record.completion_date,
                        remarks: record.remarks,
                        description: record.compliance_description,
                        compliance_history_id: record.compliance_history_id,
                        unit: record.unit_name,
                        address: record.address,
                        due_date: record.due_date,
                        recall: record.recallremarks,
                        compliance_task: record.compliance_name,
                        compliance_frequency: record.compliance_task_frequency,
                        document_issued_date: record.completion_date,
                        document_reference_number: record.document_reference_number,
                        next_due_date: record.next_due_date != null ? record.next_due_date : record.due_date,
                        next_due_date1: record.next_due_date != null ? record.next_due_date : null,
                        history_count: record.history_count,
                        occurrence_remarks: record.occurrence_remarks,
                        status: record.compliance_status
                    });
                }}>{record.domain_name}</p>
                );
            },


        },
        {
            title: "Start Date",
            dataIndex: 'start_date',
            key: 'start_date',
            // ellipsis: true,
            width: '100px',
            align: 'center',
            ...getColumnSearchProps('start_date', 'Start Date'),
            render: (row, record) => {

                return (<p
                    style={{ cursor: 'pointer', color: record.compliance_status == 'Recall' ? 'brown' : record.compliance_status == 'Rectify' ? 'red' : 'black' }}
                    onClick={() => {
                        setShowValueModal(true);
                        setValueForFileUpload({
                            d_id: record.domain_id,
                            u_id: record.unit_id,
                            start_date: record.start_date,
                        });
                        setValueModal({
                            ...valueModal,
                            domain_id: record.domain_id,
                            start_date: record.start_date,
                            uploaded_document: record.uploaded_documents,
                            actiondata: record.action,
                            due_date: record.due_date,
                            approve_status: record.approve_status,
                            unit_id: record.unit_id,
                            completion_date: record.completion_date,
                            remarks: record.remarks,
                            description: record.compliance_description,
                            compliance_history_id: record.compliance_history_id,
                            unit: record.unit_name,
                            address: record.address,
                            recall: record.recallremarks,
                            compliance_task: record.compliance_name,
                            compliance_frequency: record.compliance_task_frequency,
                            document_issued_date: record.completion_date,
                            document_reference_number: record.document_reference_number,
                            next_due_date: record.next_due_date != null ? record.next_due_date : record.due_date,
                            history_count: record.history_count,
                            occurrence_remarks: record.occurrence_remarks,
                            status: record.compliance_status
                        });
                    }}>{moment(record.start_date).format('DD-MMM-YYYY')}</p>
                );
            },


        },

        {
            title: "Due Date",
            dataIndex: 'due_date',
            key: 'due_date',
            width: '100px',
            align: 'center',
            ...getColumnSearchProps('due_date', 'Due Date'),
            // render: (text, record) => {
            //     return moment(record.due_date).format('DD-MMM-YYYY')
            // }
            render: (row, record) => {

                return (<p
                    style={{ cursor: 'pointer', color: record.compliance_status == 'Recall' ? 'brown' : record.compliance_status == 'Rectify' ? 'red' : 'black' }}
                    onClick={() => {
                        setShowValueModal(true);
                        setValueForFileUpload({
                            d_id: record.domain_id,
                            u_id: record.unit_id,
                            start_date: record.start_date,
                        });
                        setValueModal({
                            ...valueModal,
                            domain_id: record.domain_id,
                            start_date: record.start_date,
                            uploaded_document: record.uploaded_documents,
                            actiondata: record.action,
                            approve_status: record.approve_status,
                            due_date: record.due_date,
                            unit_id: record.unit_id,
                            completion_date: record.completion_date,
                            remarks: record.remarks,
                            description: record.compliance_description,
                            compliance_history_id: record.compliance_history_id,
                            unit: record.unit_name,
                            address: record.address,
                            recall: record.recallremarks,
                            compliance_task: record.compliance_name,
                            compliance_frequency: record.compliance_task_frequency,
                            document_issued_date: record.completion_date,
                            document_reference_number: record.document_reference_number,
                            next_due_date: record.next_due_date != null ? record.next_due_date : record.due_date,
                            history_count: record.history_count,
                            occurrence_remarks: record.occurrence_remarks,
                            status: record.compliance_status
                        });
                    }}
                >{record.due_date}</p>
                );
            },

        },
        {
            title: "Days",
            dataIndex: 'due_date',
            key: 'due_date',
            width: '80px',
            ...getColumnSearchProps('due_date', 'Days'),
            // render: (text, record) => {
            //     return moment(record.due_date).format('DD-MMM-YYYY')
            // }
            render: (row, record) => {
                let split = record && record.ageing.split(" ");
                console.log(split, 'split8888888888');
                return (<p
                    style={{ cursor: 'pointer', color: record.compliance_status == 'Recall' ? 'brown' : 'red' }}
                    onClick={() => {
                        setShowValueModal(true);
                        setValueForFileUpload({
                            d_id: record.domain_id,
                            u_id: record.unit_id,
                            start_date: record.start_date,
                        });
                        setValueModal({
                            ...valueModal,
                            domain_id: record.domain_id,
                            start_date: record.start_date,
                            uploaded_document: record.uploaded_documents,
                            actiondata: record.action,
                            due_date: record.due_date,
                            approve_status: record.approve_status,
                            unit_id: record.unit_id,
                            completion_date: record.completion_date,
                            remarks: record.remarks,
                            description: record.compliance_description,
                            compliance_history_id: record.compliance_history_id,
                            unit: record.unit_name,
                            address: record.address,
                            recall: record.recallremarks,
                            compliance_task: record.compliance_name,
                            compliance_frequency: record.compliance_task_frequency,
                            document_issued_date: record.completion_date,
                            document_reference_number: record.document_reference_number,
                            next_due_date: record.next_due_date != null ? record.next_due_date : record.due_date,
                            history_count: record.history_count,
                            occurrence_remarks: record.occurrence_remarks,
                            status: record.compliance_status
                        });
                    }}
                >{split && split.length == 6 ? split[2] : split[3]} {split && split.length == 6 ? split[3] : split[4]}</p>
                );
            },

        },
        {
            title: "Time Line",
            dataIndex: 'due_date',
            key: 'due_date',
            width: '100px',
            ...getColumnSearchProps('due_date', 'Time Line'),
            // render: (text, record) => {
            //     return moment(record.due_date).format('DD-MMM-YYYY')
            // }
            render: (row, record) => {

                return (<p
                    style={{ cursor: 'pointer', color: record.compliance_status == 'Recall' ? 'brown' : record.compliance_status == 'Rectify' ? 'red' : 'black' }}
                    onClick={() => {
                        setShowValueModal(true);
                        setValueForFileUpload({
                            d_id: record.domain_id,
                            u_id: record.unit_id,
                            start_date: record.start_date,
                        });
                        setValueModal({
                            ...valueModal,
                            domain_id: record.domain_id,
                            start_date: record.start_date,
                            uploaded_document: record.uploaded_documents,
                            actiondata: record.action,
                            approve_status: record.approve_status,
                            unit_id: record.unit_id,
                            due_date: record.due_date,
                            completion_date: record.completion_date,
                            remarks: record.remarks,
                            description: record.compliance_description,
                            compliance_history_id: record.compliance_history_id,
                            unit: record.unit_name,
                            address: record.address,
                            recall: record.recallremarks,
                            compliance_task: record.compliance_name,
                            compliance_frequency: record.compliance_task_frequency,
                            document_issued_date: record.completion_date,
                            document_reference_number: record.document_reference_number,
                            next_due_date: record.next_due_date != null ? record.next_due_date : record.due_date,
                            history_count: record.history_count,
                            occurrence_remarks: record.occurrence_remarks,
                            status: record.compliance_status
                        });
                    }}
                >{(moment(record.due_date).isSameOrAfter(today)) ? "Left" : "Over Due"}</p>
                );
            },

        },
        {
            title: "Status",
            dataIndex: 'due_date',
            key: 'due_date',
            width: '80px',
            ...getColumnSearchProps('due_date', 'Status'),
            // render: (text, record) => {
            //     return moment(record.due_date).format('DD-MMM-YYYY')
            // }
            render: (row, record) => {

                return (<p
                    style={{ cursor: 'pointer', color: record.compliance_status == 'Recall' ? 'brown' : record.compliance_status == 'Rectify' ? 'red' : 'black' }}
                    onClick={() => {
                        setShowValueModal(true);
                        setValueForFileUpload({
                            d_id: record.domain_id,
                            u_id: record.unit_id,
                            start_date: record.start_date,
                        });
                        setValueModal({
                            ...valueModal,
                            domain_id: record.domain_id,
                            start_date: record.start_date,
                            uploaded_document: record.uploaded_documents,
                            actiondata: record.action,
                            approve_status: record.approve_status,
                            unit_id: record.unit_id,
                            due_date: record.due_date,
                            completion_date: record.completion_date,
                            remarks: record.remarks,
                            description: record.compliance_description,
                            compliance_history_id: record.compliance_history_id,
                            unit: record.unit_name,
                            address: record.address,
                            recall: record.recallremarks,
                            compliance_task: record.compliance_name,
                            compliance_frequency: record.compliance_task_frequency,
                            document_issued_date: record.completion_date,
                            document_reference_number: record.document_reference_number,
                            next_due_date: record.next_due_date != null ? record.next_due_date : record.due_date,
                            history_count: record.history_count,
                            occurrence_remarks: record.occurrence_remarks,
                            status: record.compliance_status
                        });
                    }}
                >{record.compliance_status}</p>
                );
            },

        },
        {
            title: "Format",
            dataIndex: 'compliance_file_name',
            key: 'compliance_file_name',
            align: 'center',
            width: '60px',
            render: (row, record) => {
                console.log(record, 'record2');
                let file = record.compliance_file_name && record.compliance_file_name.length > 0 && record.compliance_file_name[0];
                return (
                    <div className='text-center'>
                        {
                            record.compliance_file_name && record.compliance_file_name.length > 0 ?
                                <>
                                    <Tooltip title='Download Format'>
                                        <DownloadOutlined
                                            onClick={() => {
                                                fileListTemp({
                                                    payload: [
                                                        authtoken,
                                                        {
                                                            "session_token": authtoken,
                                                            "request": [
                                                                "File_List_Temp",
                                                                {
                                                                    "le_id": showdata && showdata.legal_entity,
                                                                    "compliance_history_id": Number(record.compliance_history_id),
                                                                    "u_id": Number(record.unit_id)
                                                                }
                                                            ]
                                                        }
                                                    ],
                                                    paramid: paramid
                                                });
                                                window.open(urls + '' + file);
                                            }}
                                        />
                                    </Tooltip>
                                </> : ''
                        }
                    </div>
                );
            }






        },


    ];
    const download = (item) => {
        const downloadpayload =
            [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "DownloadFile_temp",
                        {
                            "le_id": showdata.legal_entity,
                            "c_id": 1,
                            "d_id": valueForFileUpload.d_id,
                            "u_id": valueForFileUpload.u_id,
                            "start_date": valueForFileUpload.start_date,
                            "compliance_history_id": valueModal.compliance_history_id,
                            "upload_type": 1,
                            "file_name": item,
                        }
                    ]
                    // "request": [
                    //     "DownloadFile_temp",
                    //     {
                    //         "le_id": "zppX",
                    //         "c_id": 1,
                    //         "d_id": 1,
                    //         "u_id": 38781,
                    //         "start_date": "08-Mar-2023 12:00",
                    //         "file_name": "Registers 1-28726-21192-59458.pdf",
                    //         "upload_type": 2,
                    //         "compliance_history_id": 1432
                    //     }
                    // ]

                }
            ];
        console.log(downloadpayload, 'downloadpayload55555');
        Downloadfile({
            payload: downloadpayload,
            paramid: paramid
        });
        // if (item) {
        //     window.open('https:/' + item);
        // }
    };
    const Remove = (index) => {
        console.log(fileupload, 'fileupload');
        console.log(index, 'test123544');
        let temp = fileupload;
        // for (let i in fileupload) {
        const removepayload =
            [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetFileSize",
                        {
                            "le_id": showdata.legal_entity,
                            "c_id": 1,
                            "d_id": valueForFileUpload.d_id,
                            "u_id": valueForFileUpload.u_id,
                            "start_date": valueForFileUpload.start_date,
                            "file_name": index,
                            "remove_type": 1,
                            "compliance_history_id": valueModal.compliance_history_id,
                        }
                    ]
                }
                // "GetFileSize",
                // {
                //    "le_id":"zplW",
                //    "c_id":1,
                //    "d_id":1,
                //    "u_id":39019,
                //    "start_date":"13-Dec-2022 00:00",
                //    "file_name":"csv_csv_146175a067a842fda86b589c2ec94012_invalid_invalid-30635.csv",
                //    "remove_type":1,
                //    "compliance_history_id":214
                // }


            ];
        console.log(removepayload, 'removepayload');
        removedocument({
            payload: removepayload,
            paramid: paramid
        });

        for (let i in fileupload) {
            if (fileupload[i]['file_name'] == index) {
                fileupload.splice(i, 1);
            }
        }


        // }
        console.log(index, 'index');
        // if (attachedFile.includes(index)) {
        //     attachedFile.splice(index, 1);
        // }
        if (attachedFile.length > 0) {
            for (let i in attachedFile) {
                if (attachedFile[i] == index) {
                    attachedFile.splice(i, 1);
                }
            }
        }
        // setFileUpload([...fileupload]);
        setAttachedFile([...attachedFile])

    };

    return (
        <div className="page-wrapper" id='page-wrapper'>
            {/* {document.body.scrollHeight > document.body.clientHeight ?
                <div className='back-to-top'>
                    <ScrollButton />
                </div> : ''}
            <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} /> */}
            <div className='back-to-top'>
                <ScrollButton />
            </div>

            <div className="page-titles py-0 page-title-sticky">
                <div className="row py-1">
                    <nav className="col-lg-5 col-md-6 col-12 align-self-center" aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 d-flex align-items-center">
                            <li className="breadcrumb-item">
                                <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i>
                                </span>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                <span style={{ fontSize: '16px' }}>Transaction</span>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: "large", fontWeight: 'bold' }}>
                                <span>Compliance Task Details</span>
                            </li>
                        </ol>
                    </nav>
                    <div className="col-lg-7 text-end">
                        {/* <Button 
                className="mx-1"
                style={{
                    display: filterTaskAcc ? "none" : "initial",
                  }}
                 
                shape="round" disabled>

                            {"Total compliance"} : {compliancefiltershow.total_count ? compliancefiltershow.total_count : '0'}
                            </Button>
                            <Button 
                className="mx-1"
                style={{
                    display: filterTaskAcc ? "none" : "initial",
                  }}
                type="primary"
                shape="round" disabled>

                            {/* {"Total Units"} : {tempshowdata ? tempshowdata.length : '0'} 
                            </Button> */}
                        <Button shape={'round'} className='mx-1' style={{ display: tab == '2' ? 'initial' : 'none' }} disabled>Over Due : <span> &nbsp; {currentCompliance?.overdue_count}</span></Button>
                        <Button shape={'round'} className='mx-1' style={{ display: tab == '2' ? 'initial' : 'none' }} disabled>In-progress : <span> &nbsp; {currentCompliance?.inprogress_count}</span></Button>
                        <Button shape={'round'} className='mx-1' style={{ display: tab == '2' ? 'initial' : 'none' }} disabled>Task Recall : <span> &nbsp; {currentCompliance && currentCompliance.recall_count && (type == 'Task Recall' || type == '') ? currentCompliance.recall_count : 0}</span></Button>
                        <Button shape={'round'} className='mx-1' style={{ display: tab == '3' ? 'initial' : 'none' }} disabled>Upcoming Compliance : <span> &nbsp; {upcomingTask?.total_count}</span> </Button>

                        <Button
                            className="mx-1"
                            type="primary"
                            shape="round"
                            style={{
                                display: filterTaskAcc ? "none" : "initial",
                            }}
                            onClick={filterHandle}
                        >
                            <FilterOutlined /> Filter
                        </Button>

                        <button
                            onClick={(e) => {
                                fullscreenMode();
                            }}
                            className="bg-transparent text-black ms-1 full-mode fullscreenradius"
                        >
                            <Tooltip
                                placement="left"
                                title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}
                            >
                                <FullscreenOutlined />
                            </Tooltip>
                        </button>
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-lg-12">
                        <div style={{ borderRadius: '0px' }} className={localStorage.getItem('currentTheme')}>
                            <Collapse accordion defaultActiveKey={['1']}
                                style={{ display: exitCollapse ? "none" : "block" }}>
                                <Panel header="Compliance Task Details" key="1">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-4" >
                                                {/* <div className="col-md-8" > */}
                                                {/* <div className="mb-3"> */}
                                                {/* <label>Legal Entity:</label> */}
                                                <label htmlFor=""><b>Legal Entity : </b></label><span style={{ color: "red" }}>*</span><br />
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <div className="form-group">
                                                        <Select

                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Select Legal Entity"
                                                            onChange={(data, value) => {
                                                                if (data !== undefined) {
                                                                    // Setlegalstate(true) category
                                                                    // setCurrentEntity(data)
                                                                    Setshowdata({
                                                                        ...showdata,
                                                                        legal_entity: data,
                                                                        domain: '',
                                                                        division: '',
                                                                        category: '',
                                                                        unit: '',
                                                                        frequency: ''

                                                                    });
                                                                    setDomain('');
                                                                }
                                                                else {
                                                                    Setshowdata({
                                                                        ...showdata,
                                                                        legal_entity: '',
                                                                        domain: '',
                                                                        division: '',
                                                                        category: '',
                                                                        unit: '',
                                                                        frequency: ''

                                                                    });
                                                                    setDomain('');
                                                                }
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={showdata.legal_entity || undefined}
                                                            style={{ width: '100%', marginTop: "5px" }}
                                                        >
                                                            {sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
                                                                return (
                                                                    <Option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {formValidator.current.message(
                                                            'legal_entity',
                                                            showdata.legal_entity,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${FormShow ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Required',
                                                                }
                                                            })}
                                                    </div> :
                                                    <p className='text-wrap-report' style={{ width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>
                                                }
                                                {/* </div> */}
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Domain: </b></label><br />
                                                    {((domain && domain.length === 1) && (showdata.legal_entity != '')) ?
                                                        <p style={{ marginTop: '10px' }} value={domain && domain.length > 0 && domain[0].d_name}>{domain && domain.length > 0 && domain[0].d_name}</p>
                                                        :
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            disabled={showdata.legal_entity ? false : true}
                                                            name="domain"
                                                            id="domain"
                                                            placeholder="Enter domain"
                                                            onChange={(data) => {
                                                                if (data !== undefined) {
                                                                    let temp = [];
                                                                    for (let i in compliancefilter.user_units) {
                                                                        if (compliancefilter.user_units[i].d_ids.includes(Number(data))) {
                                                                            temp.push(compliancefilter.user_units[i]);
                                                                        }
                                                                    }
                                                                    // let filteredArr = _.filter(compliancefilter && compliancefilter.user_units, { division_id: data })
                                                                    settempUnit(temp);
                                                                    setUnits(temp);
                                                                    Setshowdata({
                                                                        ...showdata,
                                                                        domain: data,
                                                                        division: '',
                                                                        category: '',
                                                                        unit: '',
                                                                        frequency: ''
                                                                        // unit: '',
                                                                        // frequency: '',
                                                                        // asignee: '',
                                                                        // due_month: '',
                                                                        // act: ''

                                                                    });
                                                                }
                                                                else {
                                                                    settempUnit(compliancefilter && compliancefilter.user_units);
                                                                    setUnits(compliancefilter && compliancefilter.user_units);
                                                                    Setshowdata({
                                                                        ...showdata,
                                                                        domain: '',
                                                                        division: '',
                                                                        category: '',
                                                                        unit: '',
                                                                        frequency: ''
                                                                        // unit: '',
                                                                        // frequency: '',
                                                                        // asignee: '',
                                                                        // due_month: '',
                                                                        // act: ''

                                                                    });
                                                                }
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={showdata.domain || undefined}
                                                            style={{ width: '100%', marginTop: "5px" }}
                                                        // disabled={showdata.legal_entity === "" ? true : false}
                                                        >
                                                            {domain && domain.length > 0 && domain.map((item, i) => {
                                                                return (
                                                                    <Option key={item.d_id}>
                                                                        {item.d_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    }
                                                    {/* {formValidator.current.message(
                                                        'domain',
                                                        showdata.domain,
                                                        ['required'],
                                                        {
                                                            className: `invalid-feedback ${FormShow ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Domain Required',
                                                            }
                                                        })} */}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Division: </b></label><br />
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Division"
                                                        disabled={showdata.legal_entity ? false : true}
                                                        onChange={(data) => {
                                                            let filteredArr = _.filter(tempunits, { division_id: Number(data) });
                                                            setUnits(filteredArr);
                                                            let filteredArr1 = _.filter(compliancefilter && compliancefilter.categories_list, { div_id: Number(data) });
                                                            setCategoryList(filteredArr1);
                                                            if (data !== undefined) {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    division: data,
                                                                    category: '',
                                                                    unit: '',
                                                                    frequency: ''
                                                                });
                                                            }
                                                            else {
                                                                setUnits(tempunits);
                                                                setCategoryList(tempcategory);
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    division: '',
                                                                    category: '',
                                                                    unit: '',
                                                                    frequency: ''
                                                                });
                                                            }
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={showdata.division || undefined}
                                                        style={{ width: '100%', marginTop: "5px" }}
                                                    // disabled={showdata.legal_entity === "" ? true : false}
                                                    >
                                                        {division && division.length > 0 && division.map((item, i) => {
                                                            return (
                                                                <Option key={item.div_id}>
                                                                    {item.div_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Category: </b></label>
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Category"
                                                        disabled={showdata.legal_entity ? false : true}
                                                        onChange={(data) => {
                                                            if (data !== undefined) {
                                                                let filteredArr = _.filter(tempunits, { category_id: Number(data) });
                                                                setUnits(filteredArr);
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    category: data,
                                                                    unit: ''
                                                                });
                                                            } else {
                                                                setUnits(tempunits);
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    category: '',
                                                                    unit: ''
                                                                });
                                                            }
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={showdata.category || undefined}
                                                        style={{ width: '100%', marginTop: "5px" }}
                                                    >
                                                        {category && category.length > 0 && category.map((item, i) => {
                                                            let filteredArr = _.filter(compliancefilter && compliancefilter.divisions_list, { div_id: item.div_id });
                                                            return (
                                                                <Option key={item.cat_id}>
                                                                    {/* {item.cat_name} */}
                                                                    {`${filteredArr[0].div_name}-${item.cat_name}`}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">

                                                <div className="form-group">

                                                    <label htmlFor=""><b>Unit: </b></label>
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Unit"
                                                        disabled={showdata.legal_entity ? false : true}
                                                        onChange={(data) => {
                                                            console.log(data, 'showdata');
                                                            if (data !== undefined) {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    unit: data
                                                                });
                                                            }
                                                            else {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    unit: ''
                                                                });
                                                            }
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={showdata.unit || undefined}

                                                        style={{ width: '100%', marginTop: "5px" }}
                                                    >
                                                        {units && units.length > 0 && units.map((item, i) => {
                                                            return (
                                                                <Option key={item.unit_id}>
                                                                    {item.unit_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    {/* {formValidator.current.message(
                                                        'date',
                                                        showdata.due_month,
                                                        ['required'],
                                                        {
                                                            className: `invalid-feedback ${FormShow ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Due Month Required',
                                                            }
                                                        })} */}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Frequency: </b></label>
                                                    <Select
                                                        allowClear={false}
                                                        size="default"
                                                        disabled={showdata.legal_entity ? false : true}
                                                        defaultValue={"All"}
                                                        placeholder="Enter Frequency"
                                                        onChange={(data) => {
                                                            if (data !== undefined) {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    frequency: data ? data : ''
                                                                });
                                                            }
                                                            else {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    frequency: ''
                                                                });
                                                            }
                                                        }}
                                                        // showSearch
                                                        // optionFilterProp="children"
                                                        // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={showdata.frequency}
                                                        style={{ width: '100%', marginTop: "5px" }}
                                                    // disabled={showdata.legal_entity === "" ? true : false}
                                                    >
                                                        <Option key=''>All</Option>
                                                        {frequency && frequency.length > 0 && frequency.map((item, i) => {
                                                            return (
                                                                <Option key={item.frequency_id}>
                                                                    {item.frequency_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"col-md-12 mt-3 popupbtncolour " + localStorage.getItem('currentTheme')}>
                                        {/* <Link to="" style={{ marginLeft: '40%' }} onClick={()=>{setAccordionTrue(true)}} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Show</Link> */}
                                        {/* <a href={() => false} style={{ marginLeft: '40%' }} onClick={gettaskrecalllist} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Show</a> */}
                                        <Button shape="round" className={
                                            "addbutton " + localStorage.getItem("currentTheme")
                                        }
                                            style={{ marginLeft: '46%', marginTop: "5px" }}
                                            size='default'
                                            icon={<EyeOutlined />} onClick={onsubmitData}
                                        >Show
                                        </Button>
                                    </div>
                                </Panel>
                            </Collapse>
                            <Modal
                                onCancel={setcancelFilter}
                                footer={false}
                                title="Compliance Task Details"
                                className={"add-service-prv remark-header cons-report " + localStorage.getItem("currentTheme")}
                                open={filterModalVisible} maskClosable={false}>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-4" >
                                            {/* <div className="col-md-8" > */}
                                            {/* <div className="mb-3"> */}
                                            {/* <label>Legal Entity:</label> */}
                                            <label htmlFor=""><b>Legal Entity : </b></label><span style={{ color: "red" }}>*</span><br />
                                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                <div className="form-group">
                                                    <Select
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Select Legal Entity"
                                                        onChange={(data, value) => {
                                                            if (data !== undefined) {
                                                                // Setlegalstate(true) category
                                                                // setCurrentEntity(data)
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    legal_entity: data,
                                                                    domain: '',
                                                                    division: '',
                                                                    category: '',
                                                                    unit: '',
                                                                    frequency: ''

                                                                });
                                                                setDomain('');
                                                            }
                                                            else {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    legal_entity: '',
                                                                    domain: '',
                                                                    division: '',
                                                                    category: '',
                                                                    unit: '',
                                                                    frequency: ''

                                                                });
                                                                setDomain('');
                                                            }
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={showdata.legal_entity || undefined}
                                                        style={{ width: '100%', marginTop: "5px" }}
                                                    >
                                                        {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                            return (
                                                                <Option key={item.le_id}>
                                                                    {item.le_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    {formValidator.current.message(
                                                        'legal_entity',
                                                        showdata.legal_entity,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${FormShow ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Legal Entity Required',
                                                            }
                                                        })}
                                                </div> :
                                                <p className='text-wrap-report' style={{ width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>
                                            }
                                            {/* </div> */}
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor=""><b>Domain: </b></label><br />
                                                {((domain && domain.length === 1) && (showdata.legal_entity != '')) ?
                                                    <p style={{ marginTop: '10px' }} value={domain && domain.length > 0 && domain[0].d_name}>{domain && domain.length > 0 && domain[0].d_name}</p>
                                                    :
                                                    <Select
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        allowClear={true}
                                                        size="default"
                                                        disabled={showdata.legal_entity ? false : true}
                                                        name="domain"
                                                        id="domain"
                                                        placeholder="Enter domain"
                                                        onChange={(data) => {
                                                            if (data !== undefined) {
                                                                let temp = [];
                                                                for (let i in compliancefilter.user_units) {
                                                                    if (compliancefilter.user_units[i].d_ids.includes(Number(data))) {
                                                                        temp.push(compliancefilter.user_units[i]);
                                                                    }
                                                                }
                                                                // let filteredArr = _.filter(compliancefilter && compliancefilter.user_units, { division_id: data })
                                                                settempUnit(temp);
                                                                setUnits(temp);
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    domain: data,
                                                                    division: '',
                                                                    category: '',
                                                                    unit: '',
                                                                    frequency: ''
                                                                    // unit: '',
                                                                    // frequency: '',
                                                                    // asignee: '',
                                                                    // due_month: '',
                                                                    // act: ''

                                                                });
                                                            }
                                                            else {
                                                                settempUnit(compliancefilter && compliancefilter.user_units);
                                                                setUnits(compliancefilter && compliancefilter.user_units);
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    domain: '',
                                                                    division: '',
                                                                    category: '',
                                                                    unit: '',
                                                                    frequency: ''
                                                                    // unit: '',
                                                                    // frequency: '',
                                                                    // asignee: '',
                                                                    // due_month: '',
                                                                    // act: ''

                                                                });
                                                            }
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={showdata.domain || undefined}
                                                        style={{ width: '100%', marginTop: "5px" }}
                                                    // disabled={showdata.legal_entity === "" ? true : false}
                                                    >
                                                        {domain && domain.length > 0 && domain.map((item, i) => {
                                                            return (
                                                                <Option key={item.d_id}>
                                                                    {item.d_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                }
                                                {/* {formValidator.current.message(
                                                        'domain',
                                                        showdata.domain,
                                                        ['required'],
                                                        {
                                                            className: `invalid-feedback ${FormShow ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Domain Required',
                                                            }
                                                        })} */}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor=""><b>Division: </b></label><br />
                                                <Select
                                                    allowClear={true}
                                                    size="default"
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    placeholder="Enter Division"
                                                    disabled={showdata.legal_entity ? false : true}
                                                    onChange={(data) => {
                                                        let filteredArr = _.filter(tempunits, { division_id: Number(data) });
                                                        setUnits(filteredArr);
                                                        let filteredArr1 = _.filter(compliancefilter && compliancefilter.categories_list, { div_id: Number(data) });
                                                        setCategoryList(filteredArr1);
                                                        if (data !== undefined) {
                                                            Setshowdata({
                                                                ...showdata,
                                                                division: data,
                                                                category: '',
                                                                unit: '',
                                                                frequency: ''
                                                            });
                                                        }
                                                        else {
                                                            setUnits(tempunits);
                                                            setCategoryList(tempcategory);
                                                            Setshowdata({
                                                                ...showdata,
                                                                division: '',
                                                                category: '',
                                                                unit: '',
                                                                frequency: ''
                                                            });
                                                        }
                                                    }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    value={showdata.division || undefined}
                                                    style={{ width: '100%', marginTop: "5px" }}
                                                // disabled={showdata.legal_entity === "" ? true : false}
                                                >
                                                    {division && division.length > 0 && division.map((item, i) => {
                                                        return (
                                                            <Option key={item.div_id}>
                                                                {item.div_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor=""><b>Category: </b></label>
                                                <Select
                                                    allowClear={true}
                                                    size="default"
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    placeholder="Enter Category"
                                                    disabled={showdata.legal_entity ? false : true}
                                                    onChange={(data) => {
                                                        if (data !== undefined) {
                                                            let filteredArr = _.filter(tempunits, { category_id: Number(data) });
                                                            setUnits(filteredArr);
                                                            Setshowdata({
                                                                ...showdata,
                                                                category: data,
                                                                unit: ''
                                                            });
                                                        } else {
                                                            setUnits(tempunits);
                                                            Setshowdata({
                                                                ...showdata,
                                                                category: '',
                                                                unit: ''
                                                            });
                                                        }
                                                    }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    value={showdata.category || undefined}
                                                    style={{ width: '100%', marginTop: "5px" }}
                                                >
                                                    {category && category.length > 0 && category.map((item, i) => {
                                                        let filteredArr = _.filter(compliancefilter && compliancefilter.divisions_list, { div_id: item.div_id });
                                                        return (
                                                            <Option key={item.cat_id}>
                                                                {/* {item.cat_name} */}
                                                                {`${filteredArr[0].div_name}-${item.cat_name}`}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">

                                            <div className="form-group">

                                                <label htmlFor=""><b>Unit: </b></label>
                                                <Select
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Enter Unit"
                                                    disabled={showdata.legal_entity ? false : true}
                                                    onChange={(data) => {
                                                        console.log(data, 'showdata');
                                                        if (data !== undefined) {
                                                            Setshowdata({
                                                                ...showdata,
                                                                unit: data
                                                            });
                                                        }
                                                        else {
                                                            Setshowdata({
                                                                ...showdata,
                                                                unit: ''
                                                            });
                                                        }
                                                    }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    value={showdata.unit || undefined}

                                                    style={{ width: '100%', marginTop: "5px" }}
                                                >
                                                    {units && units.length > 0 && units.map((item, i) => {
                                                        return (
                                                            <Option key={item.unit_id}>
                                                                {item.unit_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                                {/* {formValidator.current.message(
                                                        'date',
                                                        showdata.due_month,
                                                        ['required'],
                                                        {
                                                            className: `invalid-feedback ${FormShow ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Due Month Required',
                                                            }
                                                        })} */}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor=""><b>Frequency: </b></label>
                                                <Select
                                                    allowClear={false}
                                                    size="default"
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    disabled={showdata.legal_entity ? false : true}
                                                    defaultValue={"All"}
                                                    placeholder="Enter Frequency"
                                                    onChange={(data) => {
                                                        if (data !== undefined) {
                                                            Setshowdata({
                                                                ...showdata,
                                                                frequency: data ? data : ''
                                                            });
                                                        }
                                                        else {
                                                            Setshowdata({
                                                                ...showdata,
                                                                frequency: ''
                                                            });
                                                        }
                                                    }}
                                                    // showSearch
                                                    // optionFilterProp="children"
                                                    // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    value={showdata.frequency}
                                                    style={{ width: '100%', marginTop: "5px" }}
                                                // disabled={showdata.legal_entity === "" ? true : false}
                                                >
                                                    <Option key=''>All</Option>
                                                    {frequency && frequency.length > 0 && frequency.map((item, i) => {
                                                        return (
                                                            <Option key={item.frequency_id}>
                                                                {item.frequency_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-md-12 mt-3 popupbtncolour " + localStorage.getItem('currentTheme')}>
                                    {/* <Link to="" style={{ marginLeft: '40%' }} onClick={()=>{setAccordionTrue(true)}} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Show</Link> */}
                                    {/* <a href={() => false} style={{ marginLeft: '40%' }} onClick={gettaskrecalllist} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Show</a> */}
                                    <Button shape="round" className={
                                        "addbutton " + localStorage.getItem("currentTheme")
                                    }
                                        style={{ marginLeft: '46%', marginTop: "5px" }}
                                        size='default'
                                        icon={<EyeOutlined />} onClick={onsubmitData}
                                    >Show
                                    </Button>
                                </div>
                            </Modal>

                            {show == true ?
                                <>
                                    <div className="card p-2 rounded-3 mt-0">

                                        <Tabs type="card"
                                            defaultActiveKey='1'
                                            className={`${localStorage.getItem("currentTheme") + 's5'}`}
                                            onChange={(key) => {
                                                console.log(key, 'tabbbbbbb');
                                                setChecked({
                                                    All: false,
                                                    periodical: false,
                                                    flexi: false,
                                                    review: false,
                                                    on_occurrence: false,
                                                    one_time: false
                                                });
                                                setChecked1({
                                                    All: false,
                                                    periodical: false,
                                                    flexi: false,
                                                    review: false,
                                                    on_occurrence: false,
                                                    one_time: false
                                                });
                                                setFilterValues({
                                                    All: '',
                                                    periodical: '',
                                                    flexi: '',
                                                    review: '',
                                                    on_occurrence: '',
                                                    one_time: ''
                                                })
                                                setFilterValues1({
                                                    All: '',
                                                    periodical: '',
                                                    flexi: '',
                                                    review: '',
                                                    on_occurrence: '',
                                                    one_time: ''
                                                })
                                                setTab(key);
                                                setshowDropDown(false)
                                                if (key == 1) {
                                                    setCount(0);
                                                    setCheckdata([])
                                                    setCheckdata1([])
                                                    setType("");
                                                    setDate(new Date());
                                                    const payload = [
                                                        authtoken,
                                                        {
                                                            "session_token": authtoken,
                                                            "request": [
                                                                "GetCalendarView",
                                                                {
                                                                    "le_id": showdata.legal_entity,
                                                                    "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                                                                    "cal_date": null,
                                                                    "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                                                                    "div_id": showdata.division != '' ? Number(showdata.division) : null,
                                                                    "cat_id": showdata.category != '' ? Number(showdata.category) : null,
                                                                    "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                                                                }
                                                            ]
                                                        }
                                                    ];
                                                    if (showdata.legal_entity != '') {
                                                        getCalenderView({
                                                            payload: payload,
                                                            paramid: paramid
                                                        });
                                                    }
                                                    setTempList([]);
                                                    setEventValue(0);
                                                    setCalendarDate(null);
                                                    setshowDropDown(false);

                                                }
                                                if (key == 2) {
                                                    setType("");
                                                    setCount(0);
                                                    setCheckdata([])
                                                    setCheckdata1([])
                                                    setStatus({
                                                        overDue: true,
                                                        inprogress: true,
                                                        recall: true
                                                    });
                                                    const payload = [
                                                        authtoken,
                                                        {
                                                            "session_token": authtoken,
                                                            "request": [
                                                                "GetCurrentComplianceDetail",
                                                                {
                                                                    "le_id": showdata.legal_entity,
                                                                    "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                                                                    "current_start_count": 0,
                                                                    "cal_view": null,
                                                                    "cal_date": null,
                                                                    "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                                                                    "division_id": showdata.division != '' ? Number(showdata.division) : null,
                                                                    "category_id": showdata.category != '' ? Number(showdata.category) : null,
                                                                    "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                                                                }
                                                            ]
                                                        }
                                                    ];

                                                    getCurrentTaskDetails({

                                                        payload: payload,
                                                        paramid: paramid
                                                    });
                                                    setTempList([]);
                                                    setEventValue(0);
                                                    setCalendarDate(null);
                                                }
                                                if (key == 3) {
                                                    setType("");

                                                    setCount(0);
                                                    setEventValue(0);
                                                    const payload = [
                                                        authtoken,
                                                        {
                                                            "session_token": authtoken,
                                                            "request": [
                                                                "GetUpcomingComplianceDetail",
                                                                {
                                                                    "le_id": showdata.legal_entity,
                                                                    "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                                                                    "upcoming_start_count": 0,
                                                                    "cal_view": null,
                                                                    "cal_date": null,
                                                                    "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                                                                    "division_id": showdata.division != '' ? Number(showdata.division) : null,
                                                                    "category_id": showdata.category != '' ? Number(showdata.category) : null,
                                                                    "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                                                                }
                                                            ]
                                                        }
                                                    ];

                                                    getUpcomigTaskDetails({

                                                        payload: payload,
                                                        paramid: paramid
                                                    });

                                                }


                                            }}
                                            //  onChange={() => {
                                            //     setCategory({
                                            //         compfie: '',
                                            //         compie_litigation: '',
                                            //         compfie_vendor: ''
                                            //     })
                                            // }}
                                            activeKey={tab}

                                        >

                                            <TabPane



                                                tab={
                                                    <span
                                                        style={{ "margin-top": "-16px", padding: "0px 50px", fontSize: "16px" }}
                                                    >
                                                        <b>Calendar View</b>
                                                    </span>
                                                }
                                                key="1">
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="card">
                                                                <Calendar

                                                                    style={{ height: '600pt' }}
                                                                    tooltipAccessor={(e) => {
                                                                        return e.tooltip;
                                                                    }}
                                                                    views={views}

                                                                    // components={(e) => {
                                                                    //     Event(e)
                                                                    // }}
                                                                    components={{

                                                                        toolbar: CustomToolbar,


                                                                    }}
                                                                    eventPropGetter={(event, start, end, isSelected) => {
                                                                        var backgroundColor = event.color;
                                                                        var style = {
                                                                            backgroundColor: backgroundColor,
                                                                            borderRadius: '25px',

                                                                            float: 'left',
                                                                            fontSize: '8px !important',
                                                                            height: '18px',
                                                                            textAlign: 'center',
                                                                            lineHeight: '18px',
                                                                            // verticalAlign: 'middle',
                                                                            horizontalAlign: 'middle',
                                                                            marginLeft: '5px',
                                                                            width: '40px',
                                                                            // opacity: 0.8,
                                                                            // color: 'black',
                                                                            // border: '0px',
                                                                            // display: 'block'
                                                                        };
                                                                        return {
                                                                            style: style
                                                                        };
                                                                    }}
                                                                    date={date}
                                                                    events={eventData}
                                                                    startAccessor="start"
                                                                    showAllEvents='true'
                                                                    endAccessor="end"
                                                                    defaultDate={moment().toDate()}
                                                                    localizer={localizer}
                                                                    eventOrder="horizontal"
                                                                    defaultView='month'
                                                                    onSelectEvent={onSelectEvent}
                                                                    onNavigate={(date) => {
                                                                        let startMonth = moment(date).startOf('month');
                                                                        const payload = [
                                                                            authtoken,
                                                                            {
                                                                                "session_token": authtoken,
                                                                                "request": [
                                                                                    "GetCalendarView",
                                                                                    {
                                                                                        "le_id": showdata.legal_entity,
                                                                                        "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                                                                                        "cal_date": ((moment((currentMonth && currentMonth._d)).format('DD-MMM-YYYY')) === (moment(startMonth._d).format('DD-MMM-YYYY'))) ? null : moment(startMonth._d).format('DD-MMM-YYYY'),
                                                                                        "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                                                                                        "div_id": showdata.division != '' ? Number(showdata.division) : null,
                                                                                        "cat_id": showdata.category != '' ? Number(showdata.category) : null,
                                                                                        "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                                                                                    }
                                                                                ]
                                                                            }
                                                                        ];
                                                                        console.log(payload, 'payload555555');
                                                                        if (showdata.legal_entity != '') {

                                                                            getCalenderView({
                                                                                payload: payload,
                                                                                paramid: paramid
                                                                            });
                                                                        }
                                                                        setDate(date);
                                                                    }}


                                                                />
                                                                <div className="col-md-12 mt-4 text-center">
                                                                    <div className="row">

                                                                        <div style={{ fontSize: '13px', fontWeight: 'bold' }}>
                                                                            <div style={{ display: 'inline-block', marginRight: '15px' }}>
                                                                                <div style={{ background: ' #da6d25', height: '20px', width: '20px', borderRadius: '25px', float: 'left', marginRight: '5px' }}></div>
                                                                                <div style={{ float: 'left' }}>Task Recall</div>
                                                                            </div>
                                                                            <div style={{ display: 'inline-block', marginRight: '15px' }}>
                                                                                <div style={{ background: 'rgb(149 46 154)', height: '20px', width: '20px', borderRadius: '25px', float: 'left', marginRight: '5px' }}></div>
                                                                                <div style={{ float: 'left' }}>Due Date</div>
                                                                            </div>
                                                                            <div style={{ display: 'inline-block', marginRight: '15px' }}>
                                                                                <div style={{ background: 'rgb(255 174 44)', height: '20px', width: '20px', borderRadius: '25px', float: 'left', marginRight: '5px' }}></div>
                                                                                <div style={{ float: 'left' }}>Inprogress</div>
                                                                            </div>
                                                                            <div style={{ display: 'inline-block', marginRight: '15px' }}>
                                                                                <div style={{ background: '#f32d2b', height: '20px', width: '20px', borderRadius: '25px', float: 'left', marginRight: '5px' }}></div>
                                                                                <div style={{ float: 'left' }}>Over Due</div>
                                                                            </div>
                                                                            <div style={{ display: 'inline-block', marginRight: '15px' }}>
                                                                                <div style={{ background: '  rgb(42 125 153)', height: '20px', width: '20px', borderRadius: '25px', float: 'left', marginRight: '5px' }}></div>
                                                                                <div style={{ float: 'left' }}>Upcoming</div>
                                                                            </div>

                                                                            &nbsp; </div>


                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <Divider /> */}


                                                <div>


                                                </div>

                                            </TabPane>


                                            <TabPane
                                                // disabled={tabValue.compfie_litigation == false ? true : false}

                                                tab={

                                                    <span style={{ padding: "0px 50px", fontSize: "16px" }}
                                                        onClick={() => {
                                                            setDataList([]);
                                                        }}     >


                                                        <b>Current Tasks {tab == '2' ? `: ${moment(new Date()).format('DD-MMM-YYYY')} (UTC)` : false}</b>
                                                    </span>
                                                }
                                                key="2">
                                                <div className="col-md-12">
                                                    {/* <div className="card p-4 py-2 mb-0 rounded user-mapping1 bg-white">
                                                    <div className="d-flex justify-content-between">
                                                        <div></div>
                                                        <div>
                                                            <i style={{ color: "red" }} className="bi bi-square-fill"></i>{" "}
                                                            <label>One Time</label>
                                                        </div>
                                                        <div>
                                                            <i style={{ color: "green" }} className="bi bi-square-fill"></i>{" "}
                                                            <label>Periodical</label>
                                                        </div>
                                                        <div>
                                                            <i style={{ color: "orange" }} className="bi bi-square-fill"></i>{" "}
                                                            <label>Review</label>
                                                        </div>
                                                        <div>
                                                            <i style={{ color: "pink" }} className="bi bi-square-fill"></i>{" "}
                                                            <label>Flexi Review</label>
                                                        </div>
                                                        <div>
                                                            <i style={{ color: "yellow" }} className="bi bi-square-fill"></i>{" "}
                                                            <label>On Occurrence</label>
                                                        </div>
                                                        <div></div>
                                                    </div>
                                                </div> */}

                                                    <div
                                                        className={
                                                            localStorage.getItem("currentTheme") +
                                                            " ant-table-wrapper service-provider-table"
                                                        } style={{ width: '100%' }}
                                                    >
                                                        <div className="row">

                                                            <div className="ant-table ant-table-bordered ant-table-fixed-header" style={{ width: showValueModal == true ? '58%' : '98%', height: showValueModal == true ? '750px' : '100%', overflowY: showValueModal == true ? 'scroll' : '', marginLeft: '12px' }}>
                                                                <div className="ant-table-container">
                                                                    <div className="ant-table-content taskrecalltable service-table">

                                                                        <table className="table-fixed" >
                                                                            <thead className="ant-table-thead" style={{ top: (showValueModal == true) ? 0 : '107px' }}>
                                                                                <tr>
                                                                                    <th className="ant-table-cell" style={{ width: "5px" }}>
                                                                                        <div className="d-flex justify-content-center align-items-center">
                                                                                            <span className="me-1">Sno </span>
                                                                                            <div className="position-relative" ref={wrapperRef}>
                                                                                                <button onClick={(e) => { setshowDropDown(current => !current); }}>
                                                                                                    <FilterFilled />
                                                                                                </button>

                                                                                                {/* <button className="col-form-label" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                              <FilterFilled />
                                            </button> */}
                                                                                                {showDropDown ? (
                                                                                                    <Form onFinish={onFinish} className='bg-white position-absolute text-start shadow-lg' style={{ borderRadius: '15px', top: '32px', width: '165px' }}>

                                                                                                        <Form.Item className="mt-1" name={'periodical'} >
                                                                                                            &nbsp;<Checkbox id={'periodical'} value={'periodical'} checked={checked.periodical} onChange={(e) => {
                                                                                                                e.target.checked ? setChecked({
                                                                                                                    ...checked,
                                                                                                                    periodical: true
                                                                                                                }) : setChecked({
                                                                                                                    ...checked,
                                                                                                                    periodical: false
                                                                                                                });
                                                                                                                console.log(e.target.checked);
                                                                                                                setFilterValues({
                                                                                                                    ...filterValues,
                                                                                                                    periodical: e.target.checked
                                                                                                                });
                                                                                                            }} />&nbsp; <label className="fw-normal pointer user-select-none" htmlFor="periodical"><i style={{ color: 'green' }} className="bi bi-square-fill"></i> Periodical</label>
                                                                                                        </Form.Item>
                                                                                                        <Form.Item name={'flexi'}>
                                                                                                            &nbsp;<Checkbox id="flexi" value={'flexi'} checked={checked.flexi_review} onChange={(e) => {
                                                                                                                e.target.checked ? setChecked({
                                                                                                                    ...checked,
                                                                                                                    flexi_review: true
                                                                                                                }) : setChecked({
                                                                                                                    ...checked,
                                                                                                                    flexi_review: false
                                                                                                                });
                                                                                                                console.log(e, e.target.value);
                                                                                                                setFilterValues({
                                                                                                                    ...filterValues,
                                                                                                                    flexi_review: e.target.checked
                                                                                                                });
                                                                                                            }} /> &nbsp;<label className="fw-normal pointer user-select-none" htmlFor="flexi"><i style={{ color: "pink" }} className="bi bi-square-fill"></i> Flexi Review</label>
                                                                                                        </Form.Item>
                                                                                                        <Form.Item name={'Review'}>
                                                                                                            &nbsp;<Checkbox id="Review" value={'Review'} checked={checked.review} onChange={(e) => {
                                                                                                                e.target.checked ? setChecked({
                                                                                                                    ...checked,
                                                                                                                    review: true
                                                                                                                }) : setChecked({
                                                                                                                    ...checked,
                                                                                                                    review: false
                                                                                                                });
                                                                                                                console.log(e, e.target.value);
                                                                                                                setFilterValues({
                                                                                                                    ...filterValues,
                                                                                                                    review: e.target.checked
                                                                                                                });
                                                                                                            }} /> &nbsp;<label className="fw-normal pointer user-select-none" htmlFor="Review"><i style={{ color: "orange" }} className="bi bi-square-fill"></i> Review</label>
                                                                                                        </Form.Item>
                                                                                                        <Form.Item name={'On Occurrence'}>
                                                                                                            &nbsp;<Checkbox id="On-Occurrence" value={'On Occurrence'} checked={checked.on_occurrence} onChange={(e) => {
                                                                                                                e.target.checked ? setChecked({
                                                                                                                    ...checked,
                                                                                                                    on_occurrence: true
                                                                                                                }) : setChecked({
                                                                                                                    ...checked,
                                                                                                                    on_occurrence: false
                                                                                                                });
                                                                                                                console.log(e, e.target.value);
                                                                                                                setFilterValues({
                                                                                                                    ...filterValues,
                                                                                                                    on_occurrence: e.target.checked
                                                                                                                });
                                                                                                            }} /> &nbsp;<label className="fw-normal pointer user-select-none" htmlFor="On-Occurrence"><i style={{ color: "yellow" }} className="bi bi-square-fill"></i> OnOccurrence</label>
                                                                                                        </Form.Item>
                                                                                                        <Form.Item className="mb-1" name={'One Time'}>
                                                                                                            &nbsp;<Checkbox id="One-Time" value={'One Time'} checked={checked.one_time} onChange={(e) => {
                                                                                                                e.target.checked ? setChecked({
                                                                                                                    ...checked,
                                                                                                                    one_time: true
                                                                                                                }) : setChecked({
                                                                                                                    ...checked,
                                                                                                                    one_time: false
                                                                                                                });
                                                                                                                console.log(e, e.target.value);
                                                                                                                setFilterValues({
                                                                                                                    ...filterValues,
                                                                                                                    one_time: e.target.checked
                                                                                                                });
                                                                                                            }} /> &nbsp;<label className="fw-normal pointer user-select-none" htmlFor="One-Time"><i style={{ color: "red" }} className="bi bi-square-fill"></i> Onetime</label>
                                                                                                        </Form.Item>
                                                                                                        <Form.Item className="submitButtonFrm" >
                                                                                                            <Button style={{ borderRadius: '10px' }} className="statistic" type="primary" htmlType="submit">OK</Button>
                                                                                                            <a className="link ms-2" onClick={() => { resetfilter(); }}>Reset</a>
                                                                                                        </Form.Item>
                                                                                                    </Form>
                                                                                                ) : false}
                                                                                            </div>


                                                                                            {/* <button className="col-form-label" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <FilterFilled />
                                                                    </button> */}
                                                                                            {/* <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                        <li onClick={(e) => { frequencyfilter('All') }}><a className="dropdown-item"><b>All</b></a></li>
                                                                        <li onClick={(e) => { frequencyfilter('periodical') }}><a className="dropdown-item" style={{ background: "green", color: "white" }}>Periodical</a></li>
                                                                        <li onClick={(e) => { frequencyfilter('flexi') }}><a className="dropdown-item" style={{ background: "pink", color: "black" }}>FlexiReview</a></li>
                                                                        <li onClick={(e) => { frequencyfilter('Review') }}><a className="dropdown-item" style={{ background: "orange", color: "black" }}>Review</a></li>
                                                                        <li onClick={(e) => { frequencyfilter('On Occurrence') }}><a className="dropdown-item" style={{ background: "yellow", color: "black" }}>OnOccurrence</a></li>
                                                                        <li onClick={(e) => { frequencyfilter('One Time') }}><a className="dropdown-item" style={{ background: "red", color: "white" }}>Onetime</a></li>
                                                                    </ul> */}
                                                                                        </div>
                                                                                        {/* <div id="filter" style={{position: 'relative'}}>
                                            <div style={{position: 'absolute',marginLeft: '33px', cursor: 'pointer' }} > # 
                                          <FilterFilled style={{ marginLeft: '8px' }} /></div>
                                          <Select  style={{ width: "80px", opacity: 0 }} value={all}
                                          
                                            onChange={(data) => {
                                              frequencyfilter(data);
                                            }}
                                          >
                                            <Option l value="All">All</Option>
                                            <Option value="periodical" style={{ background: "green", color: "white" }}>
                                              Periodical
                                            </Option>
                                            <Option
                                              value="flexi"
                                              style={{
                                                background: "pink",
                                                color: "black",
                                              }}
                                            >
                                              FlexiReview
                                            </Option>
                                            <Option
                                              value="Review"
                                              style={{
                                                background: "orange",
                                                color: "black",
                                              }}
                                            >
                                              Review
                                            </Option>
                                            <Option
                                              value="On Occurrence"
                                              style={{
                                                background: "yellow",
                                                color: "black",
                                              }}
                                            >
                                              OnOccurrence
                                            </Option>
                                            <Option
                                              value="One Time"
                                              style={{
                                                background: "red",
                                                color: "white",
                                              }}
                                            >
                                              Onetime
                                            </Option>
                                          </Select>
                                          </div> */}
                                                                                    </th>
                                                                                    {/* <th
                                          className="ant-table-cell"
                                          style={{ width: "20px" }}
                                        >
                                          {/* Action {' '} */}
                                                                                    {/* <input
                                                            type="checkbox"
                                                            title="Click here to approve all"
                                                            name="allchecked"
                                                            className={"allChecked"}
                                                            onClick={(e) => {
                                                                let checked = e.target.checked;
                                                                addAllSelectedDataValues(checked);
                                                            }}
                                                        /> */}
                                                                                    {/* </th> */}
                                                                                    <th
                                                                                        className="ant-table-cell"
                                                                                        style={{ width: "288px" }}
                                                                                    >
                                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                                            <p
                                                                                                className="p-0 mb-0"
                                                                                                style={{
                                                                                                    marginLeft: "36%",
                                                                                                    display: "inline",
                                                                                                    color: searchingValue['complianceTask'] ? 'red' : ''
                                                                                                }}
                                                                                            >
                                                                                                Compliance Task
                                                                                            </p>{" "}
                                                                                            <Popconfirm
                                                                                                afterVisibleChange={() => {
                                                                                                    setFocus(myRef1);
                                                                                                }}

                                                                                                placement="bottom"
                                                                                                title={
                                                                                                    // <Input
                                                                                                    //     ref={myRef1}
                                                                                                    //     placeholder="Compliance Task"
                                                                                                    //     autoFocus={'on'}
                                                                                                    //     onKeyUp={(e) => {
                                                                                                    //         compliancetaskfilter(
                                                                                                    //             e.target.value,
                                                                                                    //             "Compliance_Task"
                                                                                                    //         );
                                                                                                    //     }}
                                                                                                    // />
                                                                                                    <div className='position-relative'>
                                                                                                        <Input
                                                                                                            ref={myRef1}
                                                                                                            placeholder="Compliance Task"
                                                                                                            autoFocus={'on'}
                                                                                                            onChange={(e) => {
                                                                                                                setSearchedValue({
                                                                                                                    ...searchedValue,
                                                                                                                    complianceTask: e.target.value
                                                                                                                });
                                                                                                                searchingValue['complianceTask'] = e.target.value
                                                                                                            }}
                                                                                                            value={searchedValue.complianceTask || undefined}
                                                                                                            onKeyUp={(e) => {
                                                                                                                compliancetaskfilter(
                                                                                                                    e.target.value,
                                                                                                                    "Compliance_Task",
                                                                                                                    'complianceTask'

                                                                                                                );
                                                                                                            }}
                                                                                                        />
                                                                                                        <CloseCircleOutlined onClick={() => {
                                                                                                            myRef1.current.value = "";
                                                                                                            setSearchedValue({
                                                                                                                ...searchedValue,
                                                                                                                complianceTask: ''
                                                                                                            });
                                                                                                            searchingValue['complianceTask'] = ''
                                                                                                            compliancetaskfilter(
                                                                                                                '',
                                                                                                                "Compliance_Task",
                                                                                                                'complianceTask'

                                                                                                            );
                                                                                                            // inputRef.current.input.value = "";
                                                                                                            // console.log(inputRef.current.,'inputRef');
                                                                                                        }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                                    </div>
                                                                                                }
                                                                                            >
                                                                                                <SearchOutlined
                                                                                                    style={{ float: 'right', marginTop: '4px' }}
                                                                                                />
                                                                                            </Popconfirm>
                                                                                        </div>
                                                                                        {/* <Input placeholder='Compliance Task' onKeyUp={(e) => {
                                                                              compliancetaskfilter(e.target.value, 'Compliance_Task')
                                                                          }} /> */}
                                                                                    </th>
                                                                                    {/* <th className='ant-table-cell' style={{ width: '10%' }}>Assignee <Input placeholder='Assignee' onKeyUp={(e) => {
                                                                              compliancetaskfilter(e.target.value, 'Assignee')
                                                                          }} /></th> */}
                                                                                    <th
                                                                                        className="ant-table-cell"
                                                                                        style={{ width: "139px" }}
                                                                                    >
                                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                                            <p
                                                                                                className="mb-0"
                                                                                                style={{
                                                                                                    // marginLeft: "36%",
                                                                                                    display: "inline",
                                                                                                    color: searchingValue['domain'] ? 'red' : ''
                                                                                                }}
                                                                                            >
                                                                                                Domain
                                                                                            </p>
                                                                                            <Popconfirm
                                                                                                afterVisibleChange={() => {
                                                                                                    setFocus(myRef2);
                                                                                                }}
                                                                                                placement="bottom"
                                                                                                title={
                                                                                                    // <Input
                                                                                                    //     ref={myRef2}
                                                                                                    //     placeholder="Domain"
                                                                                                    //     autoFocus={'on'}
                                                                                                    //     onKeyUp={(e) => {
                                                                                                    //         compliancetaskfilter(
                                                                                                    //             e.target.value,
                                                                                                    //             "Domain"
                                                                                                    //         );
                                                                                                    //     }}
                                                                                                    // />
                                                                                                    <div className='position-relative'>
                                                                                                        <Input
                                                                                                            ref={myRef2}
                                                                                                            placeholder="Domain"
                                                                                                            autoFocus={'on'}
                                                                                                            onChange={(e) => {
                                                                                                                setSearchedValue({
                                                                                                                    ...searchedValue,
                                                                                                                    domain: e.target.value
                                                                                                                });
                                                                                                                searchingValue['domain'] = e.target.value
                                                                                                            }}
                                                                                                            value={searchedValue.domain || undefined}
                                                                                                            onKeyUp={(e) => {
                                                                                                                compliancetaskfilter(
                                                                                                                    e.target.value,
                                                                                                                    "Domain",
                                                                                                                    'domain'
                                                                                                                );
                                                                                                            }}
                                                                                                        />
                                                                                                        <CloseCircleOutlined onClick={() => {
                                                                                                            myRef2.current.value = "";
                                                                                                            setSearchedValue({
                                                                                                                ...searchedValue,
                                                                                                                domain: ''
                                                                                                            });
                                                                                                            searchingValue['domain'] = ''
                                                                                                            compliancetaskfilter(
                                                                                                                '',
                                                                                                                "Domain",
                                                                                                                'domain'

                                                                                                            );
                                                                                                            // inputRef.current.input.value = "";
                                                                                                            // console.log(inputRef.current.,'inputRef');
                                                                                                        }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                                    </div>
                                                                                                }
                                                                                            >
                                                                                                <SearchOutlined
                                                                                                    style={{ float: 'right', marginTop: '4px' }}
                                                                                                />
                                                                                            </Popconfirm>
                                                                                        </div>
                                                                                        {/* <Input placeholder='Compliance Status' onKeyUp={(e) => {
                                                                              compliancetaskfilter(e.target.value, 'Compliance_Status')
                                                                          }} /> */}
                                                                                    </th>
                                                                                    <th
                                                                                        className="ant-table-cell"
                                                                                        style={{ width: "85px" }}
                                                                                    >
                                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                                            <p className="mb-0"
                                                                                                style={{
                                                                                                    // marginLeft: "36%",
                                                                                                    display: "inline",
                                                                                                    color: searchingValue['startDate'] ? 'red' : ''
                                                                                                }}
                                                                                            >
                                                                                                Start Date
                                                                                            </p>{" "}
                                                                                            <Popconfirm
                                                                                                afterVisibleChange={() => {
                                                                                                    setFocus(myRef3);
                                                                                                }}
                                                                                                placement="bottom"
                                                                                                title={
                                                                                                    // <Input
                                                                                                    //     ref={myRef3}
                                                                                                    //     placeholder="Start Date"
                                                                                                    //     autoFocus={'on'}
                                                                                                    //     onKeyUp={(e) => {
                                                                                                    //         compliancetaskfilter(
                                                                                                    //             e.target.value,
                                                                                                    //             "Start Date"
                                                                                                    //         );
                                                                                                    //     }}
                                                                                                    // />
                                                                                                    <div className='position-relative'>
                                                                                                        <Input
                                                                                                            ref={myRef3}
                                                                                                            placeholder="Start Date"
                                                                                                            autoFocus={'on'}
                                                                                                            onChange={(e) => {
                                                                                                                setSearchedValue({
                                                                                                                    ...searchedValue,
                                                                                                                    startDate: e.target.value
                                                                                                                });
                                                                                                                searchingValue['startDate'] = e.target.value
                                                                                                            }}
                                                                                                            value={searchedValue.startDate || undefined}
                                                                                                            onKeyUp={(e) => {
                                                                                                                compliancetaskfilter(
                                                                                                                    e.target.value,
                                                                                                                    "Start Date",
                                                                                                                    'startDate'
                                                                                                                );
                                                                                                            }}
                                                                                                        />
                                                                                                        <CloseCircleOutlined onClick={() => {
                                                                                                            myRef3.current.value = "";
                                                                                                            setSearchedValue({
                                                                                                                ...searchedValue,
                                                                                                                startDate: ''
                                                                                                            });
                                                                                                            compliancetaskfilter(
                                                                                                                '',
                                                                                                                "Start Date",
                                                                                                                'startDate'

                                                                                                            );
                                                                                                            searchingValue['complianceTask'] = ''
                                                                                                            // inputRef.current.input.value = "";
                                                                                                            // console.log(inputRef.current.,'inputRef');
                                                                                                        }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                                    </div>
                                                                                                }
                                                                                            >
                                                                                                <SearchOutlined
                                                                                                    style={{ float: 'right', marginTop: '4px' }}
                                                                                                />
                                                                                            </Popconfirm>
                                                                                        </div>

                                                                                        {/* <Input placeholder='Due Date' onKeyUp={(e) => {
                                                                              compliancetaskfilter(e.target.value, 'Due_Date')
                                                                          }} /> */}
                                                                                    </th>

                                                                                    <th
                                                                                        className="ant-table-cell"
                                                                                        style={{ width: "78px" }}
                                                                                    >
                                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                                            <p className="mb-0"
                                                                                                style={{
                                                                                                    // marginLeft: "36%",
                                                                                                    display: "inline",
                                                                                                    color: searchingValue['dueDate'] ? 'red' : ''
                                                                                                }}
                                                                                            >
                                                                                                Due Date
                                                                                            </p>
                                                                                            <Popconfirm
                                                                                                afterVisibleChange={() => {
                                                                                                    setFocus(myRef4);
                                                                                                }}
                                                                                                placement="bottom"
                                                                                                title={
                                                                                                    // <Input
                                                                                                    //     ref={myRef4}
                                                                                                    //     placeholder="Due Date"
                                                                                                    //     autoFocus={'on'}
                                                                                                    //     onKeyUp={(e) => {
                                                                                                    //         compliancetaskfilter(
                                                                                                    //             e.target.value,
                                                                                                    //             "Due Date"
                                                                                                    //         );
                                                                                                    //     }}
                                                                                                    // />
                                                                                                    <div className='position-relative'>
                                                                                                        <Input
                                                                                                            ref={myRef4}
                                                                                                            placeholder="Due Date"
                                                                                                            autoFocus={'on'}
                                                                                                            onChange={(e) => {
                                                                                                                setSearchedValue({
                                                                                                                    ...searchedValue,
                                                                                                                    dueDate: e.target.value
                                                                                                                });
                                                                                                                searchingValue['dueDate'] = e.target.value
                                                                                                            }}
                                                                                                            value={searchedValue.dueDate || undefined}
                                                                                                            onKeyUp={(e) => {
                                                                                                                compliancetaskfilter(
                                                                                                                    e.target.value,
                                                                                                                    "Due Date",
                                                                                                                    'dueDate'
                                                                                                                );
                                                                                                            }}
                                                                                                        />
                                                                                                        <CloseCircleOutlined onClick={() => {
                                                                                                            myRef4.current.value = "";
                                                                                                            setSearchedValue({
                                                                                                                ...searchedValue,
                                                                                                                dueDate: ''
                                                                                                            });
                                                                                                            searchingValue['dueDate'] = ''
                                                                                                            compliancetaskfilter(
                                                                                                                '',
                                                                                                                "Due Date",
                                                                                                                'dueDate'

                                                                                                            );
                                                                                                            // inputRef.current.input.value = "";
                                                                                                            // console.log(inputRef.current.,'inputRef');
                                                                                                        }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                                    </div>
                                                                                                }
                                                                                            >
                                                                                                <SearchOutlined
                                                                                                    style={{ float: 'right', marginTop: '4px' }}
                                                                                                />
                                                                                            </Popconfirm>
                                                                                        </div>


                                                                                        {/* <Input placeholder='Completion Date' onKeyUp={(e) => {
                                                                              compliancetaskfilter(e.target.value, 'Completion_Date')
                                                                          }} /> */}
                                                                                    </th>
                                                                                    <th
                                                                                        className="ant-table-cell"
                                                                                        style={{ width: "78px" }}
                                                                                    >
                                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                                            <p className="mb-0"
                                                                                                style={{
                                                                                                    // marginLeft: "36%",
                                                                                                    display: "inline",
                                                                                                    color: searchingValue['days'] ? 'red' : ''
                                                                                                }}
                                                                                            >
                                                                                                Days
                                                                                            </p>{" "}
                                                                                            <Popconfirm
                                                                                                afterVisibleChange={() => {
                                                                                                    setFocus(myRef5);
                                                                                                }}
                                                                                                placement="bottom"
                                                                                                title={
                                                                                                    // <Input
                                                                                                    //     ref={myRef5}
                                                                                                    //     placeholder="Days"
                                                                                                    //     autoFocus={'on'}
                                                                                                    //     onKeyUp={(e) => {
                                                                                                    //         compliancetaskfilter(
                                                                                                    //             e.target.value,
                                                                                                    //             "Days"
                                                                                                    //         );
                                                                                                    //     }}
                                                                                                    // />
                                                                                                    <div className='position-relative'>
                                                                                                        <Input
                                                                                                            ref={myRef5}
                                                                                                            placeholder="Days"
                                                                                                            autoFocus={'on'}
                                                                                                            onChange={(e) => {
                                                                                                                setSearchedValue({
                                                                                                                    ...searchedValue,
                                                                                                                    days: e.target.value
                                                                                                                });
                                                                                                                searchingValue['days'] = e.target.value
                                                                                                            }}
                                                                                                            value={searchedValue.days || undefined}
                                                                                                            onKeyUp={(e) => {
                                                                                                                compliancetaskfilter(
                                                                                                                    e.target.value,
                                                                                                                    "Days",
                                                                                                                    'days'
                                                                                                                );
                                                                                                            }}
                                                                                                        />
                                                                                                        <CloseCircleOutlined onClick={() => {
                                                                                                            myRef5.current.value = "";
                                                                                                            setSearchedValue({
                                                                                                                ...searchedValue,
                                                                                                                days: ''
                                                                                                            });
                                                                                                            searchingValue['days'] = ''
                                                                                                            compliancetaskfilter(
                                                                                                                '',
                                                                                                                "Days",
                                                                                                                'days'

                                                                                                            );
                                                                                                            // inputRef.current.input.value = "";
                                                                                                            // console.log(inputRef.current.,'inputRef');
                                                                                                        }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                                    </div>
                                                                                                }
                                                                                            >
                                                                                                <SearchOutlined
                                                                                                    style={{ float: 'right', marginTop: '4px' }}
                                                                                                />
                                                                                            </Popconfirm>
                                                                                        </div>

                                                                                        {/* <Input placeholder='Due Date' onKeyUp={(e) => {
                                                                              compliancetaskfilter(e.target.value, 'Due_Date')
                                                                          }} /> */}
                                                                                    </th>
                                                                                    <th className="ant-table-cell"
                                                                                        style={{ width: "90px" }}>
                                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                                            <p className="mb-0"
                                                                                                style={{
                                                                                                    // marginLeft: "36%",
                                                                                                    display: "inline",
                                                                                                    color: searchingValue['timeLine'] ? 'red' : ''
                                                                                                }}
                                                                                            >
                                                                                                Time Line
                                                                                            </p>
                                                                                            <Popconfirm
                                                                                                afterVisibleChange={() => {
                                                                                                    setFocus(myRef6);
                                                                                                }}
                                                                                                placement="bottom"
                                                                                                title={
                                                                                                    // <Input
                                                                                                    //     ref={myRef6}
                                                                                                    //     placeholder="Timeline"
                                                                                                    //     autoFocus={'on'}
                                                                                                    //     onKeyUp={(e) => {
                                                                                                    //         compliancetaskfilter(
                                                                                                    //             e.target.value,
                                                                                                    //             "TimeLine"
                                                                                                    //         );
                                                                                                    //     }}
                                                                                                    // />
                                                                                                    <div className='position-relative'>
                                                                                                        <Input
                                                                                                            ref={myRef6}
                                                                                                            placeholder="Timeline"
                                                                                                            autoFocus={'on'}
                                                                                                            onChange={(e) => {
                                                                                                                setSearchedValue({
                                                                                                                    ...searchedValue,
                                                                                                                    timeLine: e.target.value
                                                                                                                });
                                                                                                                searchingValue['timeLine'] = e.target.value
                                                                                                            }}
                                                                                                            value={searchedValue.timeLine || undefined}
                                                                                                            onKeyUp={(e) => {
                                                                                                                compliancetaskfilter(
                                                                                                                    e.target.value,
                                                                                                                    "TimeLine",
                                                                                                                    'timeLine'
                                                                                                                );
                                                                                                            }}
                                                                                                        />
                                                                                                        <CloseCircleOutlined onClick={() => {
                                                                                                            myRef6.current.value = "";
                                                                                                            setSearchedValue({
                                                                                                                ...searchedValue,
                                                                                                                timeLine: ''
                                                                                                            });
                                                                                                            searchingValue['timeLine'] = ''
                                                                                                            compliancetaskfilter(
                                                                                                                '',
                                                                                                                "TimeLine",
                                                                                                                'timeLine'

                                                                                                            );
                                                                                                            // inputRef.current.input.value = "";
                                                                                                            // console.log(inputRef.current.,'inputRef');
                                                                                                        }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                                    </div>
                                                                                                }
                                                                                            >
                                                                                                <SearchOutlined
                                                                                                    style={{ float: 'right', marginTop: '4px' }}
                                                                                                />
                                                                                            </Popconfirm>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th className="ant-table-cell"
                                                                                        style={{ width: "90px" }}>
                                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                                            <p className="mb-0"
                                                                                                style={{
                                                                                                    // marginLeft: "36%",
                                                                                                    display: "inline",
                                                                                                    color: searchingValue['Status'] ? 'red' : ''
                                                                                                }}
                                                                                            >
                                                                                                Status
                                                                                            </p>
                                                                                            <Popconfirm
                                                                                                afterVisibleChange={() => {
                                                                                                    setFocus(myRef7);
                                                                                                }}
                                                                                                placement="bottom"
                                                                                                title={
                                                                                                    // <Input
                                                                                                    //     ref={myRef7}
                                                                                                    //     placeholder="Status"
                                                                                                    //     autoFocus={'on'}
                                                                                                    //     onKeyUp={(e) => {
                                                                                                    //         compliancetaskfilter(
                                                                                                    //             e.target.value,
                                                                                                    //             "compliance_status"
                                                                                                    //         );
                                                                                                    //     }}
                                                                                                    // />
                                                                                                    <div className='position-relative'>
                                                                                                        <Input
                                                                                                            ref={myRef7}
                                                                                                            placeholder="Status"
                                                                                                            autoFocus={'on'}
                                                                                                            onChange={(e) => {
                                                                                                                setSearchedValue({
                                                                                                                    ...searchedValue,
                                                                                                                    status: e.target.value
                                                                                                                });
                                                                                                                searchingValue['status'] = e.target.value
                                                                                                            }}
                                                                                                            value={searchedValue.status || undefined}
                                                                                                            onKeyUp={(e) => {
                                                                                                                compliancetaskfilter(
                                                                                                                    e.target.value,
                                                                                                                    "status",
                                                                                                                    "status",

                                                                                                                );
                                                                                                            }}
                                                                                                        />
                                                                                                        <CloseCircleOutlined onClick={() => {
                                                                                                            myRef7.current.value = "";
                                                                                                            setSearchedValue({
                                                                                                                ...searchedValue,
                                                                                                                status: ''
                                                                                                            });
                                                                                                            compliancetaskfilter(
                                                                                                                '',
                                                                                                                "status",
                                                                                                                "status"

                                                                                                            );
                                                                                                            searchingValue['status'] = ''
                                                                                                            // inputRef.current.input.value = "";
                                                                                                            // console.log(inputRef.current.,'inputRef');
                                                                                                        }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                                    </div>
                                                                                                }
                                                                                            >
                                                                                                <SearchOutlined
                                                                                                    style={{ float: 'right', marginTop: '4px' }}
                                                                                                />
                                                                                            </Popconfirm>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th className="ant-table-cell"
                                                                                        style={{ width: "50px" }}>
                                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                                            <p className="mb-0"
                                                                                                style={{
                                                                                                    marginLeft: "12%",
                                                                                                    display: "inline",
                                                                                                }}
                                                                                            >
                                                                                                Format
                                                                                            </p>

                                                                                        </div>
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className="ant-table-tbody">



                                                                                {currentDetails && currentDetails.length > 0 ? (
                                                                                    currentDetails.map((itemdata, i) => {

                                                                                        return (
                                                                                            <Fragment key={i}>
                                                                                                <tr className="ant-table-row ant-table-row-level-0">
                                                                                                    <td
                                                                                                        colSpan={12}
                                                                                                        className="ant-table-cell"
                                                                                                    >
                                                                                                        <b>
                                                                                                            {
                                                                                                                itemdata.status

                                                                                                            }
                                                                                                        </b>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                {itemdata.child && itemdata.child.length > 0 && itemdata.child.map(
                                                                                                    (items, l) => {
                                                                                                        const content = (
                                                                                                            <table style={{ display: 'block', width: '500px' }}  >
                                                                                                                <tbody>
                                                                                                                    <tr>
                                                                                                                        <th style={{ border: "1px solid #636262" }}>Unit Name</th>
                                                                                                                        <td style={{ border: "1px solid #636262" }}>{items.unit_name}</td>
                                                                                                                    </tr>
                                                                                                                    <tr>
                                                                                                                        <th style={{ border: "1px solid #636262" }}>Address</th>
                                                                                                                        <td style={{ border: "1px solid #636262" }}>{items.address}</td>
                                                                                                                    </tr>
                                                                                                                    <tr>
                                                                                                                        <th style={{ border: "1px solid #636262" }}>Division</th>
                                                                                                                        <td style={{ border: "1px solid #636262" }}>{items.division_name}</td>
                                                                                                                    </tr>
                                                                                                                    <tr>
                                                                                                                        <th style={{ border: "1px solid #636262" }}>Category</th>
                                                                                                                        <td style={{ border: "1px solid #636262" }}>{items.category_name}</td>
                                                                                                                    </tr>
                                                                                                                    <tr>
                                                                                                                        <th style={{ border: "1px solid #636262" }}>Act / Rules</th>
                                                                                                                        <td style={{ border: "1px solid #636262" }}>{items.statu}</td>
                                                                                                                    </tr>
                                                                                                                    <tr>
                                                                                                                        <th style={{ border: "1px solid #636262" }}>Compliance Description</th>
                                                                                                                        <td style={{ border: "1px solid #636262" }}>{items.compliance_description}</td>
                                                                                                                    </tr>
                                                                                                                    <tr>
                                                                                                                        <th style={{ border: "1px solid #636262" }}>Penal Consequences</th>
                                                                                                                        <td style={{ border: "1px solid #636262" }}>{items.penal_consequences}</td>
                                                                                                                    </tr>

                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                        );
                                                                                                        let split = items && items.ageing.split(" ");
                                                                                                        let finalsplit = { days: '', timeline: '' };
                                                                                                        for (let i in split) {
                                                                                                            if (split[i] !== '' && Number(split[i])) {
                                                                                                                console.log(split[i], 'Number(split[i])');
                                                                                                                finalsplit.days = split[i];
                                                                                                                finalsplit.timeline = split[Number(i) + 1];
                                                                                                                console.log(finalsplit, 'kkksksksksksksksksksksk');
                                                                                                            }

                                                                                                            // console.log(split, finalsplit,Number(split[i]),'kkksksksksksksksksksksk')
                                                                                                        }
                                                                                                        console.log(finalsplit, 'split2222222222');
                                                                                                        let file = items.compliance_file_name && items.compliance_file_name.length > 0 && items.compliance_file_name[0];
                                                                                                        return (
                                                                                                            <tr
                                                                                                                className="ant-table-row ant-table-row-level-0"
                                                                                                                key={l}
                                                                                                                style={{
                                                                                                                    backgroundColor:
                                                                                                                        ((valueModal && valueModal.compliance_history_id == items.compliance_history_id && (showValueModal == true)) && (localStorage.getItem("currentTheme") == 'theme_four'))
                                                                                                                            ? '#ffff66' : ((valueModal && valueModal.compliance_history_id == items.compliance_history_id && (showValueModal == true)) && (localStorage.getItem("currentTheme") == 'theme_three')) ? '#DDA0DD' : ((valueModal && valueModal.compliance_history_id == items.compliance_history_id && (showValueModal == true)) && (localStorage.getItem("currentTheme") == 'theme_one')) ? '#F0F8FF' : ((valueModal && valueModal.compliance_history_id == items.compliance_history_id && (showValueModal == true)) && (localStorage.getItem("currentTheme") == 'theme_two')) ? '#D3D3D3' : ''
                                                                                                                }}
                                                                                                            >
                                                                                                                <td className="ant-table-cell">
                                                                                                                    <center>
                                                                                                                        <span style={{ cursor: 'pointer', color: items.compliance_status == 'Recall' ? 'brown' : items.compliance_status == 'Rectify' ? 'red' : 'black' }}>{count + 1}</span>
                                                                                                                        <span
                                                                                                                            hidden
                                                                                                                        >
                                                                                                                            {
                                                                                                                                (count =
                                                                                                                                    count +
                                                                                                                                    1)
                                                                                                                            }
                                                                                                                        </span>
                                                                                                                        {items.compliance_task_frequency ==
                                                                                                                            "Periodical" ? (
                                                                                                                            <p>
                                                                                                                                <i
                                                                                                                                    style={{
                                                                                                                                        color:
                                                                                                                                            "green",
                                                                                                                                    }}
                                                                                                                                    title={
                                                                                                                                        items.compliance_task_frequency
                                                                                                                                    }
                                                                                                                                    className="bi bi-square-fill"
                                                                                                                                ></i>
                                                                                                                            </p>
                                                                                                                        ) : items.compliance_task_frequency ==
                                                                                                                            "Flexi Review" ? (
                                                                                                                            <p>
                                                                                                                                <i
                                                                                                                                    style={{
                                                                                                                                        color:
                                                                                                                                            "pink",
                                                                                                                                    }}
                                                                                                                                    title={
                                                                                                                                        items.compliance_task_frequency
                                                                                                                                    }
                                                                                                                                    className="bi bi-square-fill"
                                                                                                                                ></i>
                                                                                                                            </p>
                                                                                                                        ) : items.compliance_task_frequency ==
                                                                                                                            "Review" ? (
                                                                                                                            <p>
                                                                                                                                <i
                                                                                                                                    style={{
                                                                                                                                        color:
                                                                                                                                            "orange",
                                                                                                                                    }}
                                                                                                                                    title={
                                                                                                                                        items.compliance_task_frequency
                                                                                                                                    }
                                                                                                                                    className="bi bi-square-fill"
                                                                                                                                ></i>
                                                                                                                            </p>
                                                                                                                        ) : items.compliance_task_frequency ==
                                                                                                                            "On Occurrence" ? (
                                                                                                                            <p>
                                                                                                                                <i
                                                                                                                                    style={{
                                                                                                                                        color:
                                                                                                                                            "yellow",
                                                                                                                                    }}
                                                                                                                                    title={
                                                                                                                                        items.compliance_task_frequency
                                                                                                                                    }
                                                                                                                                    className="bi bi-square-fill"
                                                                                                                                ></i>
                                                                                                                            </p>
                                                                                                                        ) : (
                                                                                                                            <p>
                                                                                                                                <i
                                                                                                                                    style={{
                                                                                                                                        color:
                                                                                                                                            "red",
                                                                                                                                    }}
                                                                                                                                    title={
                                                                                                                                        items.compliance_task_frequency
                                                                                                                                    }
                                                                                                                                    className="bi bi-square-fill"
                                                                                                                                ></i>
                                                                                                                            </p>
                                                                                                                        )}
                                                                                                                    </center>


                                                                                                                    <Fragment>
                                                                                                                        <center>
                                                                                                                            {/* <input
                                                                                                                            type="checkbox"
                                                                                                                            title="Click here to Task Recall"
                                                                                                                            name={
                                                                                                                                "checked"
                                                                                                                            }
                                                                                                                            onClick={(
                                                                                                                                e
                                                                                                                            ) => {
                                                                                                                                let checked =
                                                                                                                                    e
                                                                                                                                        .target
                                                                                                                                        .checked;
                                                                                                                                // addSelectedDataValues(
                                                                                                                                //     checked,
                                                                                                                                //     items.compliance_history_id,
                                                                                                                                //     items.parentId
                                                                                                                                // );
                                                                                                                            }}
                                                                                                                        /> */}
                                                                                                                        </center>
                                                                                                                    </Fragment>
                                                                                                                </td>
                                                                                                                <td className='pointer'>
                                                                                                                    <>




                                                                                                                        <span
                                                                                                                            style={{ cursor: 'pointer', color: items.compliance_status == 'Recall' ? 'brown' : items.compliance_status == 'Rectify' ? 'red' : 'black' }}
                                                                                                                            onClick={() => {
                                                                                                                                setNextData(itemdata.child[l + 1]);
                                                                                                                                console.log(items, 'itemsss');
                                                                                                                                showValue();
                                                                                                                                setCompletionDate("");
                                                                                                                                SetRemarks("");
                                                                                                                                setEdit(false);
                                                                                                                                setFileUpload([]);
                                                                                                                                setFileSize(0);
                                                                                                                                dispatch({
                                                                                                                                    type: FILE_SIZE,
                                                                                                                                    payload: 0,
                                                                                                                                });
                                                                                                                                setValueModal({
                                                                                                                                    ...valueModal,
                                                                                                                                    document_size: items.document_size,
                                                                                                                                    dayType: finalsplit && finalsplit.timeline == 'hour(s)' ? 'hours' : 'days',
                                                                                                                                    domain_id: items.domain_id,
                                                                                                                                    start_date: items.start_date,
                                                                                                                                    action: items.compliance_status,
                                                                                                                                    uploaded_document: items.uploaded_documents,
                                                                                                                                    actiondata: items.action,
                                                                                                                                    approve_status: items.approve_status,
                                                                                                                                    unit_id: items.unit_id,
                                                                                                                                    due_date: items.due_date,
                                                                                                                                    completion_date: items.completion_date,
                                                                                                                                    remarks: items.remarks,
                                                                                                                                    compliance_description: items.compliance_description,
                                                                                                                                    description: items.compliance_description,
                                                                                                                                    compliance_history_id: items.compliance_history_id,
                                                                                                                                    unit: items.unit_name,
                                                                                                                                    address: items.address,
                                                                                                                                    recall: items.recallremarks,
                                                                                                                                    compliance_task: items.compliance_name,
                                                                                                                                    compliance_frequency: items.compliance_task_frequency,
                                                                                                                                    document_issued_date: items.completion_date,
                                                                                                                                    document_reference_number: items.document_reference_number,
                                                                                                                                    next_due_date1: items.next_due_date != null ? items.next_due_date : null,
                                                                                                                                    next_due_date: items.next_due_date != null ? items.next_due_date : items.due_date,
                                                                                                                                    history_count: items.remarks_count,
                                                                                                                                    uniqueId: items.uniqueId,
                                                                                                                                    interim_count: items.interim_count,
                                                                                                                                    occurrence_remarks: items.occurrence_remarks,
                                                                                                                                    status: items.compliance_status,
                                                                                                                                    documentData: items.compliance_download_url,
                                                                                                                                    address: items.address

                                                                                                                                });
                                                                                                                                setAttachedFile(items.compliance_download_url);
                                                                                                                                setAttachedFile1(items.compliance_download_url);
                                                                                                                                setValueForFileUpload({
                                                                                                                                    d_id: items.domain_id,
                                                                                                                                    u_id: items.unit_id,
                                                                                                                                    start_date: items.start_date,
                                                                                                                                });
                                                                                                                            }}



                                                                                                                        // onClick={() => {
                                                                                                                        //     showValue()
                                                                                                                        //     setValueModal({
                                                                                                                        //         compliance_history_id: record.compliance_history_id,
                                                                                                                        //         unit: record.unit_name,
                                                                                                                        //         compliance_task: record.compliance_name,
                                                                                                                        //         compliance_frequency: record.compliance_frequency,
                                                                                                                        //         document_issued_date: record.completion_date,
                                                                                                                        //         document_reference_number: record.document_reference_number,
                                                                                                                        //         next_due_date: record.next_due_date,
                                                                                                                        //         address: record.address,
                                                                                                                        //         compliance_description: record.compliance_description,
                                                                                                                        //         uploaded_document: record.upload_docs,
                                                                                                                        //         interim_details: record.interim_docs,
                                                                                                                        //         remarks: record.remarks

                                                                                                                        //     })
                                                                                                                        // }}
                                                                                                                        >{items.statu}</span>
                                                                                                                        {/* <div>
                                                                                                                        <div className="mb-0">
                                                                                                                            <Tooltip title={content}>
                                                                                                                                {/* <i class="bi-info-circle-fill" style={{ color: '#5DAAE3' }}></i> */}
                                                                                                                        {/* <InfoCircleOutlined style={{ color: '#5DAAE3' }} />
                                                                                                                            </Tooltip>{items.compliance_name}
                                                                                                                        </div>
                                                                                                                        <span>Assigned on : {items.assigned_on}</span>
                                                                                                                    </div> */}



                                                                                                                        <div className="approve-task-reacall-tooltip">
                                                                                                                            <Tooltip placement="topRight" title={content} >  <span className='mx-1 text-primary'>
                                                                                                                                <i className="ri-information-fill" /></span> </Tooltip>
                                                                                                                            <span style={{ cursor: 'pointer', color: items.compliance_status == 'Recall' ? 'brown' : items.compliance_status == 'Rectify' ? 'red' : 'black' }} onClick={() => {
                                                                                                                                showValue();
                                                                                                                                setNextData(itemdata.child[l + 1]);
                                                                                                                                setCompletionDate("");
                                                                                                                                SetRemarks("");
                                                                                                                                setFileUpload([]);
                                                                                                                                setEdit(false);
                                                                                                                                setFileSize(0);
                                                                                                                                setValueModal({
                                                                                                                                    ...valueModal,
                                                                                                                                    domain_id: items.domain_id,
                                                                                                                                    document_size: items.document_size,
                                                                                                                                    start_date: items.start_date,
                                                                                                                                    action: items.compliance_status,
                                                                                                                                    dayType: finalsplit && finalsplit.timeline == 'hour(s)' ? 'hours' : 'days',
                                                                                                                                    due_date: items.due_date,
                                                                                                                                    uploaded_document: items.uploaded_documents,
                                                                                                                                    actiondata: items.action,
                                                                                                                                    address: items.address,
                                                                                                                                    approve_status: items.approve_status,
                                                                                                                                    unit_id: items.unit_id,
                                                                                                                                    interim_count: items.interim_count,
                                                                                                                                    completion_date: items.completion_date,
                                                                                                                                    remarks: items.remarks,
                                                                                                                                    description: items.description,
                                                                                                                                    compliance_description: items.compliance_description,
                                                                                                                                    compliance_history_id: items.compliance_history_id,
                                                                                                                                    unit: items.unit_name,
                                                                                                                                    compliance_task: items.compliance_name,
                                                                                                                                    compliance_frequency: items.compliance_task_frequency,
                                                                                                                                    document_issued_date: items.completion_date,
                                                                                                                                    document_reference_number: items.document_reference_number,
                                                                                                                                    next_due_date: items.next_due_date != null ? items.next_due_date : items.due_date,
                                                                                                                                    history_count: items.remarks_count,
                                                                                                                                    occurrence_remarks: items.occurrence_remarks,
                                                                                                                                    uniqueId: items.uniqueId
                                                                                                                                });
                                                                                                                                setAttachedFile(items.compliance_download_url);
                                                                                                                                setAttachedFile1(items.compliance_download_url);
                                                                                                                                setValueForFileUpload({
                                                                                                                                    d_id: items.domain_id,
                                                                                                                                    u_id: items.unit_id,
                                                                                                                                    start_date: items.start_date,
                                                                                                                                });
                                                                                                                                // window.scrollTo(0, 0);
                                                                                                                            }}>{items.compliance_name}</span> < br />
                                                                                                                            <span style={{ cursor: 'pointer', color: items.compliance_status == 'Recall' ? 'brown' : items.compliance_status == 'Rectify' ? 'red' : 'black', fontSize: '12px' }} >Assigned on : {items.assigned_on}</span>

                                                                                                                        </div>

                                                                                                                    </>
                                                                                                                </td>
                                                                                                                {/* <td>
                                                                                                                  {items.assignee_name}
                                                                                                              </td> */}
                                                                                                                <td className='pointer'>
                                                                                                                    <p
                                                                                                                        style={{ cursor: 'pointer', color: items.compliance_status == 'Recall' ? 'brown' : items.compliance_status == 'Rectify' ? 'red' : 'black' }}
                                                                                                                        onClick={() => {
                                                                                                                            showValue();
                                                                                                                            setNextData(itemdata.child[l + 1]);
                                                                                                                            setCompletionDate("");
                                                                                                                            SetRemarks("");
                                                                                                                            setEdit(false);
                                                                                                                            setFileSize(0);
                                                                                                                            setFileUpload([]);
                                                                                                                            setValueModal({
                                                                                                                                ...valueModal,
                                                                                                                                domain_id: items.domain_id,
                                                                                                                                start_date: items.start_date,
                                                                                                                                document_size: items.document_size,
                                                                                                                                interim_count: items.interim_count,
                                                                                                                                uploaded_document: items.uploaded_documents,
                                                                                                                                dayType: finalsplit && finalsplit.timeline == 'hour(s)' ? 'hours' : 'days',
                                                                                                                                actiondata: items.action,
                                                                                                                                action: items.compliance_status,
                                                                                                                                approve_status: items.approve_status,
                                                                                                                                unit_id: items.unit_id,
                                                                                                                                due_date: items.due_date,
                                                                                                                                completion_date: items.completion_date,
                                                                                                                                remarks: items.remarks,
                                                                                                                                compliance_description: items.compliance_description,
                                                                                                                                description: items.compliance_description,
                                                                                                                                compliance_history_id: items.compliance_history_id,
                                                                                                                                unit: items.unit_name,
                                                                                                                                address: items.address,
                                                                                                                                recall: items.recallremarks,
                                                                                                                                compliance_task: items.compliance_name,
                                                                                                                                compliance_frequency: items.compliance_task_frequency,
                                                                                                                                document_issued_date: items.completion_date,
                                                                                                                                document_reference_number: items.document_reference_number,
                                                                                                                                next_due_date1: items.next_due_date != null ? items.next_due_date : null,
                                                                                                                                next_due_date: items.next_due_date != null ? items.next_due_date : items.due_date,
                                                                                                                                history_count: items.remarks_count,
                                                                                                                                uniqueId: items.uniqueId,
                                                                                                                                occurrence_remarks: items.occurrence_remarks,
                                                                                                                                status: items.compliance_status,
                                                                                                                                address: items.address

                                                                                                                            });
                                                                                                                            setAttachedFile(items.compliance_download_url);
                                                                                                                            setAttachedFile1(items.compliance_download_url);
                                                                                                                            setValueForFileUpload({
                                                                                                                                d_id: items.domain_id,
                                                                                                                                u_id: items.unit_id,
                                                                                                                                start_date: items.start_date,
                                                                                                                            });
                                                                                                                            // window.scrollTo(0, 0);
                                                                                                                        }}>{
                                                                                                                            items.domain_name
                                                                                                                        }</p>
                                                                                                                </td>
                                                                                                                <td className='pointer'>
                                                                                                                    <center>
                                                                                                                        <p
                                                                                                                            style={{ cursor: 'pointer', color: items.compliance_status == 'Recall' ? 'brown' : items.compliance_status == 'Rectify' ? 'red' : 'black' }}
                                                                                                                            onClick={() => {
                                                                                                                                showValue();
                                                                                                                                setNextData(itemdata.child[l + 1]);
                                                                                                                                setCompletionDate("");
                                                                                                                                SetRemarks("");
                                                                                                                                setEdit(false);
                                                                                                                                setFileSize(0);
                                                                                                                                setFileUpload([]);
                                                                                                                                setValueModal({
                                                                                                                                    ...valueModal,
                                                                                                                                    domain_id: items.domain_id,
                                                                                                                                    start_date: items.start_date,
                                                                                                                                    document_size: items.document_size,
                                                                                                                                    interim_count: items.interim_count,
                                                                                                                                    uploaded_document: items.uploaded_documents,
                                                                                                                                    dayType: finalsplit && finalsplit.timeline == 'hour(s)' ? 'hours' : 'days',
                                                                                                                                    actiondata: items.action,
                                                                                                                                    action: items.compliance_status,
                                                                                                                                    approve_status: items.approve_status,
                                                                                                                                    unit_id: items.unit_id,
                                                                                                                                    due_date: items.due_date,
                                                                                                                                    completion_date: items.completion_date,
                                                                                                                                    remarks: items.remarks,
                                                                                                                                    compliance_description: items.compliance_description,
                                                                                                                                    description: items.compliance_description,
                                                                                                                                    compliance_history_id: items.compliance_history_id,
                                                                                                                                    unit: items.unit_name,
                                                                                                                                    address: items.address,
                                                                                                                                    recall: items.recallremarks,
                                                                                                                                    compliance_task: items.compliance_name,
                                                                                                                                    compliance_frequency: items.compliance_task_frequency,
                                                                                                                                    document_issued_date: items.completion_date,
                                                                                                                                    document_reference_number: items.document_reference_number,
                                                                                                                                    next_due_date1: items.next_due_date != null ? items.next_due_date : null,
                                                                                                                                    next_due_date: items.next_due_date != null ? items.next_due_date : items.due_date,
                                                                                                                                    history_count: items.remarks_count,
                                                                                                                                    uniqueId: items.uniqueId,
                                                                                                                                    occurrence_remarks: items.occurrence_remarks,
                                                                                                                                    status: items.compliance_status,
                                                                                                                                    address: items.address

                                                                                                                                });
                                                                                                                                setAttachedFile(items.compliance_download_url);
                                                                                                                                setAttachedFile1(items.compliance_download_url);
                                                                                                                                setValueForFileUpload({
                                                                                                                                    d_id: items.domain_id,
                                                                                                                                    u_id: items.unit_id,
                                                                                                                                    start_date: items.start_date,
                                                                                                                                });
                                                                                                                                // window.scrollTo(0, 0);

                                                                                                                            }}>{moment(items.start_date).format('DD-MMM-YYYY')}
                                                                                                                        </p>
                                                                                                                    </center>
                                                                                                                </td>

                                                                                                                <td className='pointer'>
                                                                                                                    <center>
                                                                                                                        <p
                                                                                                                            style={{ cursor: 'pointer', color: items.compliance_status == 'Recall' ? 'brown' : items.compliance_status == 'Rectify' ? 'red' : 'black' }}
                                                                                                                            onClick={() => {
                                                                                                                                showValue();
                                                                                                                                setNextData(itemdata.child[l + 1]);
                                                                                                                                setCompletionDate("");
                                                                                                                                SetRemarks("");
                                                                                                                                setEdit(false);
                                                                                                                                setFileUpload([]);
                                                                                                                                setFileSize(0);
                                                                                                                                setValueModal({
                                                                                                                                    ...valueModal,
                                                                                                                                    domain_id: items.domain_id,
                                                                                                                                    start_date: items.start_date,
                                                                                                                                    document_size: items.document_size,
                                                                                                                                    interim_count: items.interim_count,
                                                                                                                                    uploaded_document: items.uploaded_documents,
                                                                                                                                    dayType: finalsplit && finalsplit.timeline == 'hour(s)' ? 'hours' : 'days',
                                                                                                                                    actiondata: items.action,
                                                                                                                                    action: items.compliance_status,
                                                                                                                                    approve_status: items.approve_status,
                                                                                                                                    unit_id: items.unit_id,
                                                                                                                                    due_date: items.due_date,
                                                                                                                                    completion_date: items.completion_date,
                                                                                                                                    remarks: items.remarks,
                                                                                                                                    compliance_description: items.compliance_description,
                                                                                                                                    description: items.compliance_description,
                                                                                                                                    compliance_history_id: items.compliance_history_id,
                                                                                                                                    unit: items.unit_name,
                                                                                                                                    address: items.address,
                                                                                                                                    recall: items.recallremarks,
                                                                                                                                    compliance_task: items.compliance_name,
                                                                                                                                    compliance_frequency: items.compliance_task_frequency,
                                                                                                                                    document_issued_date: items.completion_date,
                                                                                                                                    document_reference_number: items.document_reference_number,
                                                                                                                                    next_due_date1: items.next_due_date != null ? items.next_due_date : null,
                                                                                                                                    next_due_date: items.next_due_date != null ? items.next_due_date : items.due_date,
                                                                                                                                    history_count: items.remarks_count,
                                                                                                                                    uniqueId: items.uniqueId,
                                                                                                                                    occurrence_remarks: items.occurrence_remarks,
                                                                                                                                    status: items.compliance_status,
                                                                                                                                    address: items.address

                                                                                                                                });
                                                                                                                                setAttachedFile(items.compliance_download_url);
                                                                                                                                setAttachedFile1(items.compliance_download_url);
                                                                                                                                setValueForFileUpload({
                                                                                                                                    d_id: items.domain_id,
                                                                                                                                    u_id: items.unit_id,
                                                                                                                                    start_date: items.start_date,
                                                                                                                                });
                                                                                                                                // window.scrollTo(0, 0);
                                                                                                                            }}>{items.due_date}
                                                                                                                        </p>
                                                                                                                    </center>
                                                                                                                </td>
                                                                                                                <td className='pointer'>

                                                                                                                    <center>
                                                                                                                        <p
                                                                                                                            style={{ cursor: 'pointer', color: items.compliance_status == 'Recall' ? 'brown' : items.compliance_status == 'Rectify' ? 'red' : 'black' }}
                                                                                                                            onClick={() => {
                                                                                                                                setShowValueModal(true);
                                                                                                                                setNextData(itemdata.child[l + 1]);
                                                                                                                                setValueForFileUpload({
                                                                                                                                    d_id: items.domain_id,
                                                                                                                                    u_id: items.unit_id,
                                                                                                                                    start_date: items.start_date,
                                                                                                                                });
                                                                                                                                setCompletionDate("");
                                                                                                                                SetRemarks("");
                                                                                                                                setEdit(false);
                                                                                                                                setFileUpload([]);
                                                                                                                                setFileSize(0);
                                                                                                                                setValueModal({
                                                                                                                                    ...valueModal,
                                                                                                                                    domain_id: items.domain_id,
                                                                                                                                    start_date: items.start_date,
                                                                                                                                    document_size: items.document_size,
                                                                                                                                    interim_count: items.interim_count,
                                                                                                                                    uploaded_document: items.uploaded_documents,
                                                                                                                                    dayType: finalsplit && finalsplit.timeline == 'hour(s)' ? 'hours' : 'days',
                                                                                                                                    actiondata: items.action,
                                                                                                                                    action: items.compliance_status,
                                                                                                                                    approve_status: items.approve_status,
                                                                                                                                    unit_id: items.unit_id,
                                                                                                                                    due_date: items.due_date,
                                                                                                                                    completion_date: items.completion_date,
                                                                                                                                    remarks: items.remarks,
                                                                                                                                    compliance_description: items.compliance_description,
                                                                                                                                    description: items.compliance_description,
                                                                                                                                    compliance_history_id: items.compliance_history_id,
                                                                                                                                    unit: items.unit_name,
                                                                                                                                    address: items.address,
                                                                                                                                    recall: items.recallremarks,
                                                                                                                                    compliance_task: items.compliance_name,
                                                                                                                                    compliance_frequency: items.compliance_task_frequency,
                                                                                                                                    document_issued_date: items.completion_date,
                                                                                                                                    document_reference_number: items.document_reference_number,
                                                                                                                                    next_due_date1: items.next_due_date != null ? items.next_due_date : null,
                                                                                                                                    next_due_date: items.next_due_date != null ? items.next_due_date : items.due_date,
                                                                                                                                    history_count: items.remarks_count,
                                                                                                                                    uniqueId: items.uniqueId,
                                                                                                                                    occurrence_remarks: items.occurrence_remarks,
                                                                                                                                    status: items.compliance_status,
                                                                                                                                    address: items.address

                                                                                                                                });
                                                                                                                                setAttachedFile(items.compliance_download_url);
                                                                                                                                setAttachedFile1(items.compliance_download_url);
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span
                                                                                                                                style={{ cursor: 'pointer', color: items.compliance_status == 'Recall' ? 'brown' : items.compliance_status == 'Rectify' ? 'red' : (moment(items.due_date).isSameOrBefore(today)) ? 'red' : 'black' }}
                                                                                                                            // style={{ color: (moment(items.due_date).isSameOrBefore(today)) ? 'red' : (finalsplit.days && (items.compliance_status !== 'Inprogress')) ? 'red' : 'black' }}
                                                                                                                            > {items.Days}</span>
                                                                                                                            {/* {split && split.length == 6 ? split[2] :  split[3]} {split && split.length == 6 ? split[3] : split[4]} */}
                                                                                                                        </p>
                                                                                                                    </center>
                                                                                                                </td>
                                                                                                                <td className='pointer'>
                                                                                                                    <p
                                                                                                                        style={{ cursor: 'pointer', color: items.compliance_status == 'Recall' ? 'brown' : items.compliance_status == 'Rectify' ? 'red' : 'black' }}
                                                                                                                        onClick={() => {
                                                                                                                            setShowValueModal(true);
                                                                                                                            setNextData(itemdata.child[l + 1]);
                                                                                                                            setValueForFileUpload({
                                                                                                                                d_id: items.domain_id,
                                                                                                                                u_id: items.unit_id,
                                                                                                                                start_date: items.start_date,
                                                                                                                            });
                                                                                                                            setCompletionDate("");
                                                                                                                            SetRemarks("");
                                                                                                                            setEdit(false);
                                                                                                                            setFileUpload([]);
                                                                                                                            setFileSize(0);
                                                                                                                            setValueModal({
                                                                                                                                ...valueModal,
                                                                                                                                domain_id: items.domain_id,
                                                                                                                                start_date: items.start_date,
                                                                                                                                document_size: items.document_size,
                                                                                                                                interim_count: items.interim_count,
                                                                                                                                uploaded_document: items.uploaded_documents,
                                                                                                                                dayType: finalsplit && finalsplit.timeline == 'hour(s)' ? 'hours' : 'days',
                                                                                                                                actiondata: items.action,
                                                                                                                                action: items.compliance_status,
                                                                                                                                approve_status: items.approve_status,
                                                                                                                                unit_id: items.unit_id,
                                                                                                                                due_date: items.due_date,
                                                                                                                                completion_date: items.completion_date,
                                                                                                                                remarks: items.remarks,
                                                                                                                                compliance_description: items.compliance_description,
                                                                                                                                description: items.compliance_description,
                                                                                                                                compliance_history_id: items.compliance_history_id,
                                                                                                                                unit: items.unit_name,
                                                                                                                                address: items.address,
                                                                                                                                recall: items.recallremarks,
                                                                                                                                compliance_task: items.compliance_name,
                                                                                                                                compliance_frequency: items.compliance_task_frequency,
                                                                                                                                document_issued_date: items.completion_date,
                                                                                                                                document_reference_number: items.document_reference_number,
                                                                                                                                next_due_date1: items.next_due_date != null ? items.next_due_date : null,
                                                                                                                                next_due_date: items.next_due_date != null ? items.next_due_date : items.due_date,
                                                                                                                                history_count: items.remarks_count,
                                                                                                                                uniqueId: items.uniqueId,
                                                                                                                                occurrence_remarks: items.occurrence_remarks,
                                                                                                                                status: items.compliance_status,
                                                                                                                                address: items.address

                                                                                                                            });
                                                                                                                            setAttachedFile(items.compliance_download_url);
                                                                                                                            setAttachedFile1(items.compliance_download_url);
                                                                                                                        }}
                                                                                                                    >{items.TimeLine}</p>
                                                                                                                </td>
                                                                                                                <td className='pointer'>
                                                                                                                    <p
                                                                                                                        style={{ cursor: 'pointer', color: items.compliance_status == 'Recall' ? 'brown' : items.compliance_status == 'Rectify' ? 'red' : 'black' }}
                                                                                                                        onClick={() => {
                                                                                                                            setShowValueModal(true);
                                                                                                                            setNextData(itemdata.child[l + 1]);
                                                                                                                            setValueForFileUpload({
                                                                                                                                d_id: items.domain_id,
                                                                                                                                u_id: items.unit_id,
                                                                                                                                start_date: items.start_date,
                                                                                                                            });
                                                                                                                            setCompletionDate("");
                                                                                                                            SetRemarks("");
                                                                                                                            setEdit(false);
                                                                                                                            setFileUpload([]);
                                                                                                                            setFileSize(0);
                                                                                                                            setValueModal({
                                                                                                                                ...valueModal,
                                                                                                                                domain_id: items.domain_id,
                                                                                                                                start_date: items.start_date,
                                                                                                                                document_size: items.document_size,
                                                                                                                                uploaded_document: items.uploaded_documents,
                                                                                                                                dayType: finalsplit && finalsplit.timeline == 'hour(s)' ? 'hours' : 'days',
                                                                                                                                actiondata: items.action,
                                                                                                                                approve_status: items.approve_status,
                                                                                                                                action: items.compliance_status,
                                                                                                                                unit_id: items.unit_id,
                                                                                                                                due_date: items.due_date,
                                                                                                                                interim_count: items.interim_count,
                                                                                                                                completion_date: items.completion_date,
                                                                                                                                remarks: items.remarks,
                                                                                                                                compliance_description: items.compliance_description,
                                                                                                                                description: items.compliance_description,
                                                                                                                                compliance_history_id: items.compliance_history_id,
                                                                                                                                unit: items.unit_name,
                                                                                                                                address: items.address,
                                                                                                                                recall: items.recallremarks,
                                                                                                                                compliance_task: items.compliance_name,
                                                                                                                                compliance_frequency: items.compliance_task_frequency,
                                                                                                                                document_issued_date: items.completion_date,
                                                                                                                                document_reference_number: items.document_reference_number,
                                                                                                                                next_due_date1: items.next_due_date != null ? items.next_due_date : null,
                                                                                                                                next_due_date: items.next_due_date != null ? items.next_due_date : items.due_date,
                                                                                                                                history_count: items.remarks_count,
                                                                                                                                uniqueId: items.uniqueId,
                                                                                                                                occurrence_remarks: items.occurrence_remarks,
                                                                                                                                status: items.compliance_status,
                                                                                                                                address: items.address

                                                                                                                            });
                                                                                                                            setAttachedFile(items.compliance_download_url);
                                                                                                                            setAttachedFile1(items.compliance_download_url);
                                                                                                                        }}
                                                                                                                    >{items.compliance_status}</p>
                                                                                                                </td>
                                                                                                                <td className='text-center'>
                                                                                                                    {
                                                                                                                        items.compliance_file_name && items.compliance_file_name.length > 0 ?
                                                                                                                            <>
                                                                                                                                <Tooltip title='Download Format'>
                                                                                                                                    <DownloadOutlined
                                                                                                                                        onClick={() => {
                                                                                                                                            fileListTemp({
                                                                                                                                                payload: [
                                                                                                                                                    authtoken,
                                                                                                                                                    {
                                                                                                                                                        "session_token": authtoken,
                                                                                                                                                        "request": [
                                                                                                                                                            "File_List_Temp",
                                                                                                                                                            {
                                                                                                                                                                "le_id": showdata && showdata.legal_entity,
                                                                                                                                                                "compliance_history_id": Number(items.compliance_history_id),
                                                                                                                                                                "u_id": Number(items.unit_id)
                                                                                                                                                            }
                                                                                                                                                        ]
                                                                                                                                                    }
                                                                                                                                                ],
                                                                                                                                                paramid: paramid
                                                                                                                                            });
                                                                                                                                            window.open(urls + file);
                                                                                                                                        }}
                                                                                                                                    />
                                                                                                                                </Tooltip>
                                                                                                                            </> : ''
                                                                                                                    }
                                                                                                                </td>

                                                                                                            </tr>
                                                                                                        );
                                                                                                    }
                                                                                                )}
                                                                                            </Fragment>
                                                                                        );

                                                                                    })
                                                                                ) : (
                                                                                    <tr >
                                                                                        <td colSpan="100%" style={{ paddingTop: '15px', padding: '40px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                                                    </tr>
                                                                                )}

                                                                                {/* ) : (
                                                            <tr>
                                                                <td
                                                                    colSpan={7}
                                                                    style={{
                                                                        textAlign: "center",
                                                                        fontSize: "14px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    No Compliance Available
                                                                </td>
                                                            </tr>
                                                        )} */}
                                                                            </tbody>


                                                                        </table>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {showValueModal == true && uploadModal == false ?
                                                                <div id='unit-modal' style={{ width: '40%' }}>
                                                                    <Card className='approval-unit-details' style={{ borderRadius: "15px", height: '750px', overflowY: 'scroll', borderColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ' }}>
                                                                        <span style={{ marginLeft: '95%' }}>
                                                                            <CloseCircleOutlined onClick={Cancelmodal} style={{ fontSize: '20px', color: 'red' }} /></span>

                                                                        <div className="col-md-12 d-flex" >
                                                                            <div>
                                                                                <button
                                                                                    type="button"
                                                                                    class="btn btn-light-primary text-primary btn-circle btn-xl me-3 mb-1 pe-none"
                                                                                >
                                                                                    <i
                                                                                        class="fas fa-list-alt"
                                                                                    ></i>
                                                                                </button>
                                                                            </div>
                                                                            <div>
                                                                                <p className='mb-1'><b>Unit</b></p>
                                                                                <h4 className='fw-bolder'> {valueModal.unit}&nbsp;<Tooltip title={valueModal.address} >
                                                                                    <InfoCircleOutlined style={{ color: '#5DAAE3', fontSize: '14px' }} />
                                                                                </Tooltip></h4>
                                                                            </div>
                                                                        </div>
                                                                        <div className="container py-2">
                                                                            <div className="row">
                                                                                <div className="col-12 ModalCard border  p-2 bg-light mb-2">
                                                                                    <p className="mb-1"><b>Compliance Task:</b></p>
                                                                                    <h5>{valueModal.compliance_task}&nbsp;<Tooltip placement='topLeft' title={valueModal.description} >
                                                                                        <InfoCircleOutlined style={{ color: '#5DAAE3', fontSize: '14px' }} />
                                                                                    </Tooltip></h5>
                                                                                </div>
                                                                                <div className="col-12  ModalCard border p-2 bg-light mb-2">
                                                                                    <p className="mb-1"><b>Compliance Frequency:</b></p>
                                                                                    <h5>{valueModal.compliance_frequency}</h5>
                                                                                </div>
                                                                                {/* <div className="col-4 ModalCard  border p-2 bg-light mb-2">
                            <p className="mb-1"><b>Document Reference Number:</b></p>
                            <h5>{valueModal.document_reference_number}</h5>
                        </div> */}
                                                                                <div className="col-12 ModalCard border  p-2 bg-light mb-2">
                                                                                    <p className="mb-1"><b>Upload Documents:</b></p>
                                                                                    {/* {valueModal.uploaded_document != null ?
                                            valueModal.uploaded_document.length > 0 && valueModal.uploaded_document.map((item, i) => {
                                                return ( */}
                                                                                    <div className="d-flex align-items-end">
                                                                                        <div>
                                                                                            <label htmlFor="file-upload" className='btn btn-primary upload-btn p-1 d-flex justify-content-center align-items-center'><CloudUploadOutlined title='No file chosen' /></label>
                                                                                            <input
                                                                                                id="file-upload"
                                                                                                type="file"
                                                                                                // accept='.csv'
                                                                                                multiple
                                                                                                name="file"
                                                                                                className={'d-none file-upload-ctcy'}
                                                                                                title="No file chosen"
                                                                                                onChange={(e) => {

                                                                                                    SavepastRecordOnChange(e);
                                                                                                }
                                                                                                }
                                                                                                disabled={valueModal.status == 'Recall' ? true : false}
                                                                                            />
                                                                                            <p>(Maximum 25MB per file/task)</p>
                                                                                            <p> Upload Date  :{moment(currentdate).format("DD-MMM-YYYY")} </p>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12 ModalCard border  p-2 bg-light mb-2">
                                                                                    <p className="mb-1"><b>Uploaded File Size:</b></p>
                                                                                    <h5>
                                                                                        {fileSize === 0 ? `0.00 MB` : `${(fileSize / 1048576).toFixed(2)} MB`}
                                                                                    </h5>
                                                                                </div>
                                                                                <div className="col-12 ModalCard border  p-2 bg-light mb-2">
                                                                                    <p className="mb-1"><b>Attached Documents:</b></p>
                                                                                    <h5>
                                                                                        {/* {fileupload && fileupload.length > 0 && fileupload.map((item, i) => {

                                                                                            return <p>{item.file_name}    <Button type="primary" class='addbutton'
                                                                                                icon={<DownloadOutlined />} size='small'
                                                                                                onClick={(e) => {
                                                                                                    download(item.file_name)
                                                                                                }}
                                                                                                style={{ marginTop: '2%', borderRadius: '15px' }}  >
                                                                                            </Button> &nbsp;
                                                                                                <Button type="primary" style={{ borderRadius: '15px' }} class='addbutton'
                                                                                                    icon={<CloseOutlined />} size='small'
                                                                                                    onClick={(e) => {
                                                                                                        Remove(item.file_name)
                                                                                                        setFileSize(0)
                                                                                                    }}
                                                                                                >
                                                                                                </Button></p>
                                                                                        })} */}
                                                                                        {attachedFile && attachedFile.length > 0 && attachedFile.map((item) => {

                                                                                            return <p style={{ wordBreak: 'break-all' }}>{item}    <Button type="primary" class='addbutton'
                                                                                                icon={<DownloadOutlined />} size='small'
                                                                                                onClick={(e) => {

                                                                                                    console.log(attachedFile1, 'attachedFile');
                                                                                                    if (attachedFile1 && attachedFile1.length > 0 && attachedFile1.includes(item) == true) {
                                                                                                        download(item);
                                                                                                    }
                                                                                                    else {

                                                                                                        const downloadpayload =
                                                                                                            [
                                                                                                                authtoken,
                                                                                                                {
                                                                                                                    "session_token": authtoken,
                                                                                                                    "request": [
                                                                                                                        "DownloadFile_temp",
                                                                                                                        {
                                                                                                                            "le_id": showdata.legal_entity,
                                                                                                                            "c_id": 1,
                                                                                                                            "d_id": valueForFileUpload.d_id,
                                                                                                                            "u_id": valueForFileUpload.u_id,
                                                                                                                            "start_date": valueForFileUpload.start_date,
                                                                                                                            "upload_type": 2,
                                                                                                                            "compliance_history_id": valueModal.compliance_history_id,
                                                                                                                            "file_name": item
                                                                                                                        }
                                                                                                                    ]


                                                                                                                }
                                                                                                            ]
                                                                                                        console.log(downloadpayload, 'downloadpayload985655');
                                                                                                        Downloadfile({
                                                                                                            payload: downloadpayload,
                                                                                                            paramid: paramid
                                                                                                        });

                                                                                                    }

                                                                                                }}
                                                                                                style={{ marginTop: '2%', borderRadius: '15px' }}  >
                                                                                            </Button> &nbsp;
                                                                                                <Button type="primary" style={{ borderRadius: '15px' }} class='addbutton'
                                                                                                    icon={<CloseOutlined />} size='small'
                                                                                                    onClick={(e) => {
                                                                                                        Remove(item);
                                                                                                        // setFileSize(0);
                                                                                                    }}
                                                                                                >
                                                                                                </Button></p>;
                                                                                        })}
                                                                                    </h5>
                                                                                </div>
                                                                                <div className="col-md-12 ModalCard border  p-2 bg-light mb-2">
                                                                                    <p className="mb-1"><b>Task Completion Date / Document Issued Date: </b><span style={{ color: "red" }}>*</span></p>
                                                                                    <h5>
                                                                                        <DatePicker allowClear={false}
                                                                                            // format={'DD-MMM-YYYY'}
                                                                                            className='form-control'
                                                                                            value={completionDate ? completionDate : ''}
                                                                                            style={{ width: '40%' }}
                                                                                            name="validity_date" id=""
                                                                                            showTime={valueModal.dayType == 'hours' ? true : false}

                                                                                            format={(valueModal.dayType == 'hours') && (valueModal.compliance_frequency == 'On Occurrence') ? 'DD-MMM-YYYY HH:mm' : 'DD-MMM-YYYY'}
                                                                                            disabledDate={(current) => {
                                                                                                return current > Date.now();
                                                                                            }}
                                                                                            onKeyDown={(event) => {
                                                                                                event.preventDefault();
                                                                                            }}
                                                                                            onChange={(dateString) => {
                                                                                                setCompletionDate(
                                                                                                    dateString
                                                                                                );
                                                                                            }}
                                                                                            disabled={valueModal.status == 'Recall' ? true : false}
                                                                                        />

                                                                                    </h5>
                                                                                </div>
                                                                                <div className="col-md-12 d-flex align-items-center ModalCard border p-2 bg-light mb-2" >
                                                                                    <p className="mb-1 me-1"><b>Document Reference Number:</b></p>
                                                                                    <Input class="form-control"
                                                                                        disabled={valueModal.status == 'Recall' ? true : false}
                                                                                        style={{ "width": "80%", display: 'block' }}
                                                                                        value={valueModal.document_reference_number}
                                                                                        onChange={onInputChange1}
                                                                                    // onChange={(e) => {
                                                                                    //     const value = e.target.value;
                                                                                    //     const re = /^[A-Za-z0-9()-_.,/]{0,500}$/;
                                                                                    //     if (value === "" || re.test(value)) {
                                                                                    //         // SetRemarks(value)
                                                                                    //         setDocumentNo(value)
                                                                                    //     }
                                                                                    // }}
                                                                                    />
                                                                                </div>
                                                                                {valueModal.next_due_date !== null && valueModal.compliance_frequency !== 'On Occurrence' ?
                                                                                    <div className="col-md-12 d-flex align-items-center ModalCard border p-2 bg-light mb-2" >
                                                                                        <p className="mb-1 me-1"><b>Next Due Date: </b></p>
                                                                                        <p className="mb-1 me-2">
                                                                                            {valueModal.next_due_date}
                                                                                        </p>
                                                                                    </div> : ''}

                                                                                <div className="col-md-12 d-flex align-items-center ModalCard border p-2 bg-light mb-2" >
                                                                                    <p className="mb-1 me-1"><b>Status: </b></p>
                                                                                    <p className="mb-1 me-1">
                                                                                        {valueModal.status}
                                                                                    </p>
                                                                                </div>
                                                                                {valueModal.next_due_date !== null && valueModal.compliance_frequency !== 'On Occurrence' ?
                                                                                    <div className="col-md-12 d-flex align-items-center ModalCard border p-2 bg-light mb-2" >
                                                                                        {valueModal.status !== 'Recall' ?
                                                                                            <>
                                                                                                <p className="mb-1 me-1"><b>Validity Date: </b></p>

                                                                                                {edit === false ?
                                                                                                    <a href={() => false}>
                                                                                                        <span
                                                                                                            onClick={() => {
                                                                                                                setEdit(true);
                                                                                                            }}
                                                                                                            className="btn btn-sm btn-light-info text-info  d-flex align-items-center justify-content-center"
                                                                                                        >
                                                                                                            <i className="ri-edit-line"></i>Edit
                                                                                                        </span>
                                                                                                    </a> :
                                                                                                    <h5>
                                                                                                        <DatePicker allowClear={false} format={'DD-MMM-YYYY'} placeholder={'DD-MMM-YYYY'} className='form-control'
                                                                                                            // value={valueModal ? completionDate : ''}
                                                                                                            style={{ width: '100%' }}
                                                                                                            name="validity_date" id=""
                                                                                                            // disabledDate={(current) => {
                                                                                                            //     var startdate = moment(valueModal.next_due_date);
                                                                                                            //     startdate = startdate.add(180, "days");
                                                                                                            //     startdate = startdate.format("DD-MM-YYYY");
                                                                                                            //     var startdate1 = moment(valueModal.next_due_date);
                                                                                                            //     startdate1 = startdate1.subtract(180, "days");
                                                                                                            //     startdate1 = startdate1.format("DD-MM-YYYY");
                                                                                                            //     console.log(startdate, 'newdate')
                                                                                                            //     // console.log(moment(moment(valueModal.next_due_date).format('DD-MM-YYYY')).add(180, "days"), 'disabledatedisabledate')
                                                                                                            //     return  current 
                                                                                                            // }}
                                                                                                            onKeyDown={(event) => {
                                                                                                                event.preventDefault();
                                                                                                            }}
                                                                                                            onChange={(dateString) => {
                                                                                                                setValueModal({
                                                                                                                    ...valueModal,
                                                                                                                    validity_date: dateString
                                                                                                                });
                                                                                                            }}
                                                                                                        />
                                                                                                    </h5>
                                                                                                }

                                                                                            </> : <p className="mb-1 me-1"><b>Validity Date: </b></p>}





                                                                                    </div> : false}
                                                                                {valueModal.compliance_frequency == 'On Occurrence' ?
                                                                                    <div className="col-md-12 d-flex align-items-center ModalCard border p-2 bg-light mb-2" >
                                                                                        <p className="mb-1 me-1"><b>On Occurrence Remarks: </b></p>
                                                                                        <p className="mb-1 me-1">
                                                                                            {valueModal.occurrence_remarks}
                                                                                        </p>
                                                                                    </div> : ''}
                                                                                {valueModal.status == 'Recall' ?
                                                                                    <div className="col-md-12 d-flex align-items-center ModalCard border p-2 bg-light mb-2" >
                                                                                        <p className="mb-1 me-1"><b>Recall Remarks:</b></p>
                                                                                        <p className="mb-1 me-1">{valueModal.recall}</p>
                                                                                        {valueModal.history_count != 0 ?
                                                                                            <>
                                                                                                <Tooltip title="Click to View Remarks History" ><InfoCircleOutlined style={{ color: '#5DAAE3', marginLeft: '5px' }} onClick={(e) => {
                                                                                                    showModalremark();
                                                                                                    // setToolTip([valueModal])
                                                                                                    const remarkapi = [
                                                                                                        authtoken,
                                                                                                        {
                                                                                                            "session_token": authtoken,
                                                                                                            "request": [
                                                                                                                "GetComplianceRemarksHistoryData",
                                                                                                                {
                                                                                                                    "legal_entity_id": showdata.legal_entity,
                                                                                                                    "unit_id": Number(valueModal.unit_id),
                                                                                                                    "compliance_history_id": Number(valueModal.compliance_history_id)
                                                                                                                }
                                                                                                            ]
                                                                                                        }
                                                                                                    ];
                                                                                                    getremarkhistory({
                                                                                                        payload: remarkapi,
                                                                                                        paramid: paramid
                                                                                                    });
                                                                                                }
                                                                                                } /></Tooltip></> : ''
                                                                                        }
                                                                                    </div> : ''}
                                                                                {valueModal.remarks !== null ?
                                                                                    <div className="col-md-12 d-flex align-items-center ModalCard border p-2 bg-light mb-2" >
                                                                                        <p className="mb-1 me-1"><b>Remarks:</b></p>
                                                                                        <p className="mb-1 me-1">
                                                                                            {valueModal.remarks}
                                                                                            {valueModal.history_count != 0 ?
                                                                                                <>
                                                                                                    <Tooltip title="click to view remark history" ><InfoCircleOutlined style={{ color: '#5DAAE3', marginLeft: '5px' }} onClick={(e) => {
                                                                                                        showModalremark();
                                                                                                        // setToolTip([valueModal])
                                                                                                        const remarkapi = [
                                                                                                            authtoken,
                                                                                                            {
                                                                                                                "session_token": authtoken,
                                                                                                                "request": [
                                                                                                                    "GetComplianceRemarksHistoryData",
                                                                                                                    {
                                                                                                                        "legal_entity_id": showdata.legal_entity,
                                                                                                                        "unit_id": Number(valueModal.unit_id),
                                                                                                                        "compliance_history_id": Number(valueModal.compliance_history_id)
                                                                                                                    }
                                                                                                                ]
                                                                                                            }
                                                                                                        ];
                                                                                                        console.log(remarkapi, 'remarkapi2454555555555556');
                                                                                                        getremarkhistory({
                                                                                                            payload: remarkapi,
                                                                                                            paramid: paramid
                                                                                                        });
                                                                                                    }
                                                                                                    } /></Tooltip></> : ''
                                                                                            }
                                                                                        </p>
                                                                                    </div> : ''}
                                                                                {valueModal.status == 'Recall' ? '' :
                                                                                    <div className="col-md-12 border ModalCard p-2 bg-light mb-2">
                                                                                        <p className="mb-1"><b>Remarks:</b></p>
                                                                                        <textarea class="form-control" maxLength={500} onChange={onInputChange} rows={1} style={{ width: '80%', display: 'inline' }}
                                                                                            value={remarks}
                                                                                        // onChange={(e) => {
                                                                                        //     const value = e.target.value;
                                                                                        //     console.log(value,'valuevalue');
                                                                                        //     const re = /^[A-Za-z0-9_ .,-]{0,500}$/;
                                                                                        //     if (value === "" || re.test(value)) {
                                                                                        //         console.log(value,'valuevaluevaluevalue');
                                                                                        //         SetRemarks(value)
                                                                                        //     }
                                                                                        // }}
                                                                                        />
                                                                                        <span className='mx-1 text-info'
                                                                                            onClick={() => { showRemarksModal(); }}>
                                                                                            <PlusCircleOutlined />
                                                                                        </span><br />
                                                                                        <span>{`(max 500 characters)`}</span>
                                                                                    </div>
                                                                                }
                                                                                <div className="row">
                                                                                    <div className="col-md-12 text-center p-2 mb-2">
                                                                                        {
                                                                                            valueModal.status !== 'Recall' ? <div className='d-flex align-items-center'>
                                                                                                <Button type="primary" shape="round" className={'addbutton me-1 ' + localStorage.getItem('currentTheme')}
                                                                                                    // style={{ background: "#198754", borderColor: "#198754", marginLeft: '25%' }}
                                                                                                    size='default'
                                                                                                    onClick={() => {
                                                                                                        setUpdate(true);
                                                                                                        if (fileupload && fileupload.length > 0 || remarks !== '') {
                                                                                                            if (fileupload.length == 0) {
                                                                                                                const payload = [
                                                                                                                    authtoken,
                                                                                                                    {
                                                                                                                        "session_token": authtoken,
                                                                                                                        "request": [
                                                                                                                            "SaveInterimComplianceHistory",
                                                                                                                            {
                                                                                                                                "legal_entity_id": showdata && showdata.legal_entity,
                                                                                                                                "compliance_history_id": valueModal?.compliance_history_id,
                                                                                                                                "documents": fileupload.length > 0 ? fileupload : null,
                                                                                                                                "uploaded_documents": null,
                                                                                                                                "remarks": remarks,
                                                                                                                                "file_size": 0
                                                                                                                            }
                                                                                                                        ]
                                                                                                                        // "request": [
                                                                                                                        //     "SaveInterimComplianceHistory",
                                                                                                                        //     {
                                                                                                                        //         "legal_entity_id": showdata && showdata.legal_entity,
                                                                                                                        //         "compliance_history_id": valueModal.compliance_history_id,
                                                                                                                        //         "documents": fileupload.length > 0 ? fileupload : null,
                                                                                                                        //         "uploaded_documents": null,
                                                                                                                        //         "remarks": remarks,
                                                                                                                        //         "file_size": 0
                                                                                                                        //     }
                                                                                                                        // ]
                                                                                                                    }
                                                                                                                ];
                                                                                                                const payload2 = [
                                                                                                                    authtoken,
                                                                                                                    {
                                                                                                                        "session_token": authtoken,
                                                                                                                        "request": [
                                                                                                                            "GetCurrentComplianceDetail",
                                                                                                                            {
                                                                                                                                "le_id": showdata.legal_entity,
                                                                                                                                "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                                                                                                                                "current_start_count": 0,
                                                                                                                                "cal_view": null,
                                                                                                                                "cal_date": null,
                                                                                                                                "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                                                                                                                                "division_id": showdata.division != '' ? Number(showdata.division) : null,
                                                                                                                                "category_id": showdata.category != '' ? Number(showdata.category) : null,
                                                                                                                                "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                                                                                                                            }
                                                                                                                        ]
                                                                                                                    }
                                                                                                                ];


                                                                                                                updateStatus({
                                                                                                                    payload: payload,
                                                                                                                    paramid: paramid,
                                                                                                                    setCompletionDate: setCompletionDate,
                                                                                                                    setFileUpload: setFileUpload,
                                                                                                                    valueModal: valueModal,
                                                                                                                    setValueModal: setValueModal,
                                                                                                                    SetRemarks: SetRemarks,
                                                                                                                    setFileSize: setFileSize,

                                                                                                                    // setShowValueModal: setShowValueModal,
                                                                                                                    // nextData: nextDatas,
                                                                                                                    payload2: payload2
                                                                                                                });
                                                                                                            }

                                                                                                            if (fileupload.length > 0) {
                                                                                                                UploadFile1({
                                                                                                                    payload: [
                                                                                                                        authtoken,
                                                                                                                        {
                                                                                                                            "session_token": authtoken,
                                                                                                                            "request": [
                                                                                                                                "UploadInterimTaskFile",
                                                                                                                                {
                                                                                                                                    "le_id": showdata && showdata.legal_entity,
                                                                                                                                    "c_id": 1,
                                                                                                                                    "selected_task_info": [
                                                                                                                                        {
                                                                                                                                            "d_id": Number(valueModal.domain_id),
                                                                                                                                            "u_id": Number(valueModal.unit_id),

                                                                                                                                            "file_info": fileupload,
                                                                                                                                            "start_date": valueModal.start_date,
                                                                                                                                            "compliance_history_id": Number(valueModal.compliance_history_id)

                                                                                                                                        }
                                                                                                                                    ],

                                                                                                                                }
                                                                                                                            ]
                                                                                                                        }


                                                                                                                    ],









                                                                                                                    paramid: paramid
                                                                                                                });
                                                                                                            }

                                                                                                        }
                                                                                                        else {
                                                                                                            Toaster.error("Please give either a valid remarks Or upload a document to save");
                                                                                                        }


                                                                                                    }}
                                                                                                >
                                                                                                    Update Interim Status
                                                                                                </Button>
                                                                                                <Button shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                                                                                    onsubmit
                                                                                                    icon={<PlayCircleOutlined />} size='default'
                                                                                                    onClick={onsubmit

                                                                                                    }
                                                                                                >
                                                                                                    Submit
                                                                                                </Button>
                                                                                                {valueModal.interim_count != 0 ?
                                                                                                    <Tooltip title='Click here to View Interim Details'>
                                                                                                        {/* <ArrowUpOutlined style={{ marginLeft: '20px' }} onClick={() => {
                                                                                                        const payload = [
                                                                                                            authtoken,
                                                                                                            {
                                                                                                                "session_token": authtoken,
                                                                                                                "request": [
                                                                                                                    "GetInterimComplianceDetails",
                                                                                                                    {
                                                                                                                        "legal_entity_id": showdata.legal_entity,
                                                                                                                        "compliance_history_id": Number(valueModal.compliance_history_id)
                                                                                                                    }
                                                                                                                ]
                                                                                                            }
                                                                                                        ]
                                                                                                        console.log(payload, 'remarkapi2454555555555556');
                                                                                                        setUploadModal(true)
                                                                                                        getInterimDetails({
                                                                                                            payload: payload,
                                                                                                            paramid: paramid
                                                                                                        })
                                                                                                    }} /> */}

                                                                                                        <i

                                                                                                            onClick={() => {
                                                                                                                const payload = [
                                                                                                                    authtoken,
                                                                                                                    {
                                                                                                                        "session_token": authtoken,
                                                                                                                        "request": [
                                                                                                                            "GetInterimComplianceDetails",
                                                                                                                            {
                                                                                                                                "legal_entity_id": showdata.legal_entity,
                                                                                                                                "compliance_history_id": Number(valueModal.compliance_history_id)
                                                                                                                            }
                                                                                                                        ]
                                                                                                                    }
                                                                                                                ];
                                                                                                                console.log(payload, 'remarkapi2454555555555556');
                                                                                                                setUploadModal(true);
                                                                                                                getInterimDetails({
                                                                                                                    payload: payload,
                                                                                                                    paramid: paramid
                                                                                                                });
                                                                                                            }} className="ri-arrow-up-circle-line" style={{ marginLeft: '16px', color: 'blue', fontSize: '27px' }} ></i>

                                                                                                    </Tooltip> : ''}
                                                                                            </div> : false
                                                                                        }



                                                                                    </div>
                                                                                </div>




                                                                            </div>
                                                                        </div>
                                                                    </Card>
                                                                </div> : uploadModal == true ?
                                                                    <div style={{ width: '40%' }}>
                                                                        <Card className='approval-unit-details' style={{ borderRadius: "15px", height: '750px', overflowY: 'scroll', borderColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ' }}>
                                                                            <span style={{ marginLeft: '95%' }}>
                                                                                <CloseCircleOutlined onClick={() => {
                                                                                    setUploadModal(false);
                                                                                    setShowValueModal(true);
                                                                                }} style={{ fontSize: '20px', color: 'red' }} /></span>
                                                                            <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                                                <div className='ant-table-container'>
                                                                                    <div className='ant-table-content userprivclass1'></div>
                                                                                    <table>
                                                                                        <colgroup>
                                                                                            <col style={{ width: '10px' }}></col>
                                                                                        </colgroup>
                                                                                        <thead className="ant-table-thead" >
                                                                                            <tr>
                                                                                                <th className={'ant-table-cell'} style={{ width: '10%' }}>#</th>
                                                                                                <th className={'ant-table-cell'} style={{ width: '30%' }}>Documents</th>
                                                                                                <th className={'ant-table-cell'} style={{ width: '20%' }}>Remarks</th>
                                                                                                <th className={'ant-table-cell'} style={{ width: '10%' }}>Updated On</th>

                                                                                            </tr>
                                                                                        </thead>

                                                                                        <tbody className='ant-table-tbody'>
                                                                                            {InterimDetails && InterimDetails.length > 0 && InterimDetails.map((item) => {
                                                                                                let splitData = item.documents && item.documents.split('||');
                                                                                                console.log(splitData, 'splitData');
                                                                                                return (
                                                                                                    <tr>
                                                                                                        <td><center>{sno + 1}</center></td>
                                                                                                        <td>
                                                                                                            {splitData && splitData.length > 0 && splitData.map((items) => {
                                                                                                                return <tr><center>
                                                                                                                    <a href='javascipt:;' onClick={() => {

                                                                                                                        const downloadpayload =
                                                                                                                            [
                                                                                                                                authtoken,
                                                                                                                                {
                                                                                                                                    "session_token": authtoken,
                                                                                                                                    "request": [
                                                                                                                                        "DownloadInterimFile",
                                                                                                                                        {
                                                                                                                                            "le_id": showdata.legal_entity,
                                                                                                                                            "c_id": 1,
                                                                                                                                            "d_id": valueForFileUpload.d_id,
                                                                                                                                            "u_id": valueForFileUpload.u_id,
                                                                                                                                            "start_date": valueForFileUpload.start_date,
                                                                                                                                            "file_name": items,
                                                                                                                                        }
                                                                                                                                    ]
                                                                                                                                    // "request": [
                                                                                                                                    // "DownloadFile_temp",
                                                                                                                                    // {
                                                                                                                                    // "le_id": "zppX",
                                                                                                                                    // "c_id": 1,
                                                                                                                                    // "d_id": 1,
                                                                                                                                    // "u_id": 38781,
                                                                                                                                    // "start_date": "08-Mar-2023 12:00",
                                                                                                                                    // "file_name": "Registers 1-28726-21192-59458.pdf",
                                                                                                                                    // "upload_type": 2,
                                                                                                                                    // "compliance_history_id": 1432
                                                                                                                                    // }
                                                                                                                                    // ]

                                                                                                                                }
                                                                                                                            ];
                                                                                                                        console.log(downloadpayload, 'downloadpayload');
                                                                                                                        Downloadfile({
                                                                                                                            payload: downloadpayload,
                                                                                                                            paramid: paramid
                                                                                                                        });
                                                                                                                    }}> {
                                                                                                                            items
                                                                                                                        }
                                                                                                                    </a>

                                                                                                                </center></tr>;
                                                                                                            })}
                                                                                                        </td>
                                                                                                        <td><center>
                                                                                                            {
                                                                                                                item.remarks
                                                                                                            }
                                                                                                        </center></td>

                                                                                                        <td><center>
                                                                                                            {
                                                                                                                item.activity_on
                                                                                                            }
                                                                                                        </center></td>
                                                                                                        <span hidden>
                                                                                                            {
                                                                                                                (sno =
                                                                                                                    sno + 1)
                                                                                                            }
                                                                                                        </span>
                                                                                                    </tr>
                                                                                                );
                                                                                            })}

                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>

                                                                            </div>
                                                                        </Card>
                                                                    </div >
                                                                    : false}

                                                        </div>
                                                    </div>

                                                    {
                                                        eventValue == 0 ?


                                                            ((currentCompliance && currentCompliance.overdue_count) + (currentCompliance && currentCompliance.inprogress_count) + (currentCompliance && currentCompliance.recall_count)) == (updatedArray1.length)
                                                                // || 
                                                                // ((checked.All == true || checked.periodical == true || checked.one_time == true || checked.on_occurrence == true || checked.flexi_review
                                                                //     == true || checked.review == true)
                                                                //     ) 
                                                                ?
                                                                false : <div style={{ marginTop: '2%' }}>
                                                                    <div className='text-center'>
                                                                        <Button className="btn btn-success" shape='round' onClick={showmore} >Show More <i> <DownOutlined /> </i></Button>
                                                                    </div>
                                                                </div>
                                                            : ((eventValue == updatedArray1.length)
                                                                //  || ((checked.All == true || checked.periodical == true || checked.one_time == true || checked.on_occurrence == true || checked.flexi_review
                                                                //     == true || checked.review == true)) 
                                                                ?
                                                                "" : <div style={{ marginTop: '2%' }} className='text-center'>
                                                                    <Button className="btn btn-success" shape='round' onClick={showmore} >Show More <i> <DownOutlined /> </i></Button>
                                                                </div>)
                                                    }
                                                </div>



                                                <div>


                                                </div>

                                            </TabPane>


                                            <TabPane
                                                // disabled={tabValue.compfie_vendor == false ? true : false}
                                                tab={
                                                    <span style={{ padding: "0px 50px", fontSize: "16px" }}>


                                                        <b>Upcoming Compliance Tasks</b>
                                                    </span>
                                                }
                                                key="3">

                                                <div className="col-md-12">

                                                    <div
                                                        className={
                                                            localStorage.getItem("currentTheme") +
                                                            " ant-table-wrapper service-provider-table"
                                                        } style={{ width: '100%' }}
                                                    >
                                                        <div className="row">
                                                            <div
                                                                className={
                                                                    localStorage.getItem("currentTheme") +
                                                                    " ant-table-wrapper service-provider-table"
                                                                } style={{ width: '100%' }}
                                                            >
                                                                <div className="row">

                                                                    <div className="ant-table ant-table-bordered ant-table-fixed-header " style={{ width: showValueModal == true ? '58%' : '98%', height: showValueModal == true ? '750px' : '100%', overflowY: showValueModal == true ? 'scroll' : '', marginLeft: '12px' }}>
                                                                        <div className="ant-table-container service-provider-table">
                                                                            <div className="ant-table-content taskrecalltable service-table">

                                                                                <table className="table-fixed" >
                                                                                    <thead className="ant-table-thead">
                                                                                        <tr>
                                                                                            <th className="ant-table-cell" style={{ width: "5px" }}>
                                                                                                <div className="d-flex justify-content-center align-items-center">
                                                                                                    <span className="me-1">Sno </span>
                                                                                                    <div className="position-relative" ref={wrapperRef}>
                                                                                                        <button onClick={(e) => { setshowDropDown(current => !current) }}>
                                                                                                            <FilterFilled />
                                                                                                        </button>

                                                                                                        {/* <button className="col-form-label" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                              <FilterFilled />
                                            </button> */}
                                                                                                        {showDropDown ? (
                                                                                                            <Form onFinish={onFinish1} className='bg-white position-absolute text-start shadow-lg' style={{ borderRadius: '15px', top: '32px', width: '165px' }}>

                                                                                                                <Form.Item className="mt-1" name={'periodical'} >
                                                                                                                    &nbsp;<Checkbox id={'periodical'} value={'periodical'} checked={checked1.periodical} onChange={(e) => {
                                                                                                                        e.target.checked ? setChecked1({
                                                                                                                            ...checked1,
                                                                                                                            periodical: true
                                                                                                                        }) : setChecked1({
                                                                                                                            ...checked1,
                                                                                                                            periodical: false
                                                                                                                        })
                                                                                                                        console.log(e.target.checked)
                                                                                                                        setFilterValues1({
                                                                                                                            ...filterValues1,
                                                                                                                            periodical: e.target.checked
                                                                                                                        })
                                                                                                                    }} />&nbsp; <label className="fw-normal pointer user-select-none" htmlFor="periodical"><i style={{ color: 'green' }} className="bi bi-square-fill"></i> Periodical</label>
                                                                                                                </Form.Item>
                                                                                                                <Form.Item name={'flexi'}>
                                                                                                                    &nbsp;<Checkbox id="flexi" value={'flexi'} checked={checked1.flexi_review} onChange={(e) => {
                                                                                                                        e.target.checked ? setChecked1({
                                                                                                                            ...checked1,
                                                                                                                            flexi_review: true
                                                                                                                        }) : setChecked1({
                                                                                                                            ...checked1,
                                                                                                                            flexi_review: false
                                                                                                                        })
                                                                                                                        console.log(e, e.target.value)
                                                                                                                        setFilterValues1({
                                                                                                                            ...filterValues1,
                                                                                                                            flexi_review: e.target.checked
                                                                                                                        })
                                                                                                                    }} /> &nbsp;<label className="fw-normal pointer user-select-none" htmlFor="flexi"><i style={{ color: "pink" }} className="bi bi-square-fill"></i> Flexi Review</label>
                                                                                                                </Form.Item>
                                                                                                                <Form.Item name={'Review'}>
                                                                                                                    &nbsp;<Checkbox id="Review" value={'Review'} checked={checked1.review} onChange={(e) => {
                                                                                                                        e.target.checked ? setChecked1({
                                                                                                                            ...checked1,
                                                                                                                            review: true
                                                                                                                        }) : setChecked1({
                                                                                                                            ...checked1,
                                                                                                                            review: false
                                                                                                                        })
                                                                                                                        console.log(e, e.target.value)
                                                                                                                        setFilterValues1({
                                                                                                                            ...filterValues1,
                                                                                                                            review: e.target.checked
                                                                                                                        })
                                                                                                                    }} /> &nbsp;<label className="fw-normal pointer user-select-none" htmlFor="Review"><i style={{ color: "orange" }} className="bi bi-square-fill"></i> Review</label>
                                                                                                                </Form.Item>
                                                                                                                <Form.Item name={'On Occurrence'}>
                                                                                                                    &nbsp;<Checkbox id="On-Occurrence" value={'On Occurrence'} checked={checked1.on_occurrence} onChange={(e) => {
                                                                                                                        e.target.checked ? setChecked1({
                                                                                                                            ...checked1,
                                                                                                                            on_occurrence: true
                                                                                                                        }) : setChecked1({
                                                                                                                            ...checked1,
                                                                                                                            on_occurrence: false
                                                                                                                        })
                                                                                                                        console.log(e, e.target.value)
                                                                                                                        setFilterValues1({
                                                                                                                            ...filterValues1,
                                                                                                                            on_occurrence: e.target.checked
                                                                                                                        })
                                                                                                                    }} /> &nbsp;<label className="fw-normal pointer user-select-none" htmlFor="On-Occurrence"><i style={{ color: "yellow" }} className="bi bi-square-fill"></i> OnOccurrence</label>
                                                                                                                </Form.Item>
                                                                                                                <Form.Item className="mb-1" name={' One Time'}>
                                                                                                                    &nbsp;<Checkbox id="One-Time" value={'One Time'} checked={checked1.one_time} onChange={(e) => {
                                                                                                                        e.target.checked ? setChecked1({
                                                                                                                            ...checked1,
                                                                                                                            one_time: true
                                                                                                                        }) : setChecked1({
                                                                                                                            ...checked1,
                                                                                                                            one_time: false
                                                                                                                        })
                                                                                                                        console.log(e, e.target.value)
                                                                                                                        setFilterValues1({
                                                                                                                            ...filterValues1,
                                                                                                                            one_time: e.target.checked
                                                                                                                        })
                                                                                                                    }} /> &nbsp;<label className="fw-normal pointer user-select-none" htmlFor="One-Time"><i style={{ color: "red" }} className="bi bi-square-fill"></i> Onetime</label>
                                                                                                                </Form.Item>
                                                                                                                <Form.Item className="submitButtonFrm" >
                                                                                                                    <Button style={{ borderRadius: '10px' }} className="statistic" type="primary" htmlType="submit">OK</Button>
                                                                                                                    <a className="link ms-2" onClick={() => { resetfilter1() }}>Reset</a>
                                                                                                                </Form.Item>
                                                                                                            </Form>
                                                                                                        ) : false}
                                                                                                    </div>


                                                                                                    {/* <button className="col-form-label" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <FilterFilled />
                                                                    </button> */}
                                                                                                    {/* <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                        <li onClick={(e) => { frequencyfilter('All') }}><a className="dropdown-item"><b>All</b></a></li>
                                                                        <li onClick={(e) => { frequencyfilter('periodical') }}><a className="dropdown-item" style={{ background: "green", color: "white" }}>Periodical</a></li>
                                                                        <li onClick={(e) => { frequencyfilter('flexi') }}><a className="dropdown-item" style={{ background: "pink", color: "black" }}>FlexiReview</a></li>
                                                                        <li onClick={(e) => { frequencyfilter('Review') }}><a className="dropdown-item" style={{ background: "orange", color: "black" }}>Review</a></li>
                                                                        <li onClick={(e) => { frequencyfilter('On Occurrence') }}><a className="dropdown-item" style={{ background: "yellow", color: "black" }}>OnOccurrence</a></li>
                                                                        <li onClick={(e) => { frequencyfilter('One Time') }}><a className="dropdown-item" style={{ background: "red", color: "white" }}>Onetime</a></li>
                                                                    </ul> */}
                                                                                                </div>
                                                                                                {/* <div id="filter" style={{position: 'relative'}}>
                                            <div style={{position: 'absolute',marginLeft: '33px', cursor: 'pointer' }} > # 
                                          <FilterFilled style={{ marginLeft: '8px' }} /></div>
                                          <Select  style={{ width: "80px", opacity: 0 }} value={all}
                                          
                                            onChange={(data) => {
                                              frequencyfilter(data);
                                            }}
                                          >
                                            <Option l value="All">All</Option>
                                            <Option value="periodical" style={{ background: "green", color: "white" }}>
                                              Periodical
                                            </Option>
                                            <Option
                                              value="flexi"
                                              style={{
                                                background: "pink",
                                                color: "black",
                                              }}
                                            >
                                              FlexiReview
                                            </Option>
                                            <Option
                                              value="Review"
                                              style={{
                                                background: "orange",
                                                color: "black",
                                              }}
                                            >
                                              Review
                                            </Option>
                                            <Option
                                              value="On Occurrence"
                                              style={{
                                                background: "yellow",
                                                color: "black",
                                              }}
                                            >
                                              OnOccurrence
                                            </Option>
                                            <Option
                                              value="One Time"
                                              style={{
                                                background: "red",
                                                color: "white",
                                              }}
                                            >
                                              Onetime
                                            </Option>
                                          </Select>
                                          </div> */}
                                                                                            </th>
                                                                                            {/* <th
                                          className="ant-table-cell"
                                          style={{ width: "20px" }}
                                        >
                                          {/* Action {' '} */}
                                                                                            {/* <input
                                                            type="checkbox"
                                                            title="Click here to approve all"
                                                            name="allchecked"
                                                            className={"allChecked"}
                                                            onClick={(e) => {
                                                                let checked = e.target.checked;
                                                                addAllSelectedDataValues(checked);
                                                            }}
                                                        /> */}
                                                                                            {/* </th> */}
                                                                                            <th
                                                                                                className="ant-table-cell"
                                                                                                style={{ width: "288px" }}
                                                                                            >
                                                                                                <div className="d-flex justify-content-between align-items-center">
                                                                                                    <p
                                                                                                        className="p-0 mb-0"
                                                                                                        style={{
                                                                                                            marginLeft: "36%",
                                                                                                            display: "inline",
                                                                                                        }}
                                                                                                    >
                                                                                                        Compliance Task
                                                                                                    </p>{" "}
                                                                                                    <Popconfirm
                                                                                                        placement="bottom"
                                                                                                        title={
                                                                                                            <Input
                                                                                                                placeholder="Compliance Task"
                                                                                                                autoFocus={'on'}
                                                                                                                onKeyUp={(e) => {
                                                                                                                    compliancetaskfilter1(
                                                                                                                        e.target.value,
                                                                                                                        "Compliance_Task"
                                                                                                                    );
                                                                                                                }}
                                                                                                            />
                                                                                                        }
                                                                                                    >
                                                                                                        <SearchOutlined
                                                                                                            style={{ float: 'right', marginTop: '4px' }}
                                                                                                        />
                                                                                                    </Popconfirm>
                                                                                                </div>
                                                                                                {/* <Input placeholder='Compliance Task' onKeyUp={(e) => {
                                                                              compliancetaskfilter(e.target.value, 'Compliance_Task')
                                                                          }} /> */}
                                                                                            </th>
                                                                                            {/* <th className='ant-table-cell' style={{ width: '10%' }}>Assignee <Input placeholder='Assignee' onKeyUp={(e) => {
                                                                              compliancetaskfilter(e.target.value, 'Assignee')
                                                                          }} /></th> */}
                                                                                            <th
                                                                                                className="ant-table-cell"
                                                                                                style={{ width: "139px" }}
                                                                                            >
                                                                                                <div className="d-flex justify-content-between align-items-center">
                                                                                                    <p
                                                                                                        className="mb-0"
                                                                                                        style={{
                                                                                                            // marginLeft: "2px",
                                                                                                            display: "inline",
                                                                                                        }}
                                                                                                    >
                                                                                                        Domain
                                                                                                    </p>
                                                                                                    <Popconfirm
                                                                                                        placement="bottom"
                                                                                                        title={
                                                                                                            <Input
                                                                                                                placeholder="Domain"
                                                                                                                autoFocus={'on'}
                                                                                                                onKeyUp={(e) => {
                                                                                                                    compliancetaskfilter1(
                                                                                                                        e.target.value,
                                                                                                                        "Domain"
                                                                                                                    );
                                                                                                                }}
                                                                                                            />
                                                                                                        }
                                                                                                    >
                                                                                                        <SearchOutlined
                                                                                                            style={{ float: 'right', marginTop: '4px' }}
                                                                                                        />
                                                                                                    </Popconfirm>
                                                                                                </div>
                                                                                                {/* <Input placeholder='Compliance Status' onKeyUp={(e) => {
                                                                              compliancetaskfilter(e.target.value, 'Compliance_Status')
                                                                          }} /> */}
                                                                                            </th>
                                                                                            <th
                                                                                                className="ant-table-cell"
                                                                                                style={{ width: "85px" }}
                                                                                            >
                                                                                                <div className="d-flex justify-content-between align-items-center">
                                                                                                    <p className="mb-0"
                                                                                                        style={{
                                                                                                            marginLeft: "20%",
                                                                                                            display: "inline",
                                                                                                        }}
                                                                                                    >
                                                                                                        Start Date
                                                                                                    </p>{" "}
                                                                                                    <Popconfirm
                                                                                                        placement="bottom"
                                                                                                        title={
                                                                                                            <Input
                                                                                                                placeholder="Start Date"
                                                                                                                autoFocus={'on'}
                                                                                                                onKeyUp={(e) => {
                                                                                                                    compliancetaskfilter1(
                                                                                                                        e.target.value,
                                                                                                                        "Start Date"
                                                                                                                    );
                                                                                                                }}
                                                                                                            />
                                                                                                        }
                                                                                                    >
                                                                                                        <SearchOutlined
                                                                                                            style={{ float: 'right', marginTop: '4px' }}
                                                                                                        />
                                                                                                    </Popconfirm>
                                                                                                </div>

                                                                                                {/* <Input placeholder='Due Date' onKeyUp={(e) => {
                                                                              compliancetaskfilter(e.target.value, 'Due_Date')
                                                                          }} /> */}
                                                                                            </th>

                                                                                            <th
                                                                                                className="ant-table-cell"
                                                                                                style={{ width: "78px" }}
                                                                                            >
                                                                                                <div className="d-flex justify-content-between align-items-center">
                                                                                                    <p className="mb-0"
                                                                                                        style={{
                                                                                                            marginLeft: "12%",
                                                                                                            display: "inline",
                                                                                                        }}
                                                                                                    >
                                                                                                        Due Date
                                                                                                    </p>
                                                                                                    <Popconfirm
                                                                                                        placement="bottom"
                                                                                                        title={
                                                                                                            <Input
                                                                                                                placeholder="Due Date"
                                                                                                                autoFocus={'on'}
                                                                                                                onKeyUp={(e) => {
                                                                                                                    compliancetaskfilter1(
                                                                                                                        e.target.value,
                                                                                                                        "Due Date"
                                                                                                                    );
                                                                                                                }}
                                                                                                            />
                                                                                                        }
                                                                                                    >
                                                                                                        <SearchOutlined
                                                                                                            style={{ float: 'right', marginTop: '4px' }}
                                                                                                        />
                                                                                                    </Popconfirm>
                                                                                                </div>


                                                                                                {/* <Input placeholder='Completion Date' onKeyUp={(e) => {
                                                                              compliancetaskfilter(e.target.value, 'Completion_Date')
                                                                          }} /> */}
                                                                                            </th>


                                                                                            <th className="ant-table-cell"
                                                                                                style={{ width: "50px" }}>
                                                                                                <div className="d-flex justify-content-between align-items-center">
                                                                                                    <p className="mb-0"
                                                                                                        style={{
                                                                                                            marginLeft: "12%",
                                                                                                            display: "inline",
                                                                                                        }}
                                                                                                    >
                                                                                                        Format
                                                                                                    </p>

                                                                                                </div>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody className="ant-table-tbody">



                                                                                        {titledata && titledata.length > 0 ? (
                                                                                            titledata.map((itemdata, i) => {

                                                                                                return (
                                                                                                    <Fragment key={i}>
                                                                                                        <tr className="ant-table-row ant-table-row-level-0">
                                                                                                            <td
                                                                                                                colSpan={12}
                                                                                                                className="ant-table-cell"
                                                                                                            >
                                                                                                                <Tooltip title={itemdata.child && itemdata.child[0].address}> <b>
                                                                                                                    {
                                                                                                                        itemdata.parent

                                                                                                                    }
                                                                                                                </b>
                                                                                                                </Tooltip>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        {itemdata.child && itemdata.child.length > 0 && itemdata.child.map(
                                                                                                            (items, l) => {
                                                                                                                const content = (
                                                                                                                    <table style={{ display: 'block', width: '500px' }}  >
                                                                                                                        <tbody>
                                                                                                                            <tr>
                                                                                                                                <th style={{ border: "1px solid #636262" }}>Unit Name</th>
                                                                                                                                <td style={{ border: "1px solid #636262" }}>{items.unit_name}</td>
                                                                                                                            </tr>
                                                                                                                            <tr>
                                                                                                                                <th style={{ border: "1px solid #636262" }}>Address</th>
                                                                                                                                <td style={{ border: "1px solid #636262" }}>{items.address}</td>
                                                                                                                            </tr>
                                                                                                                            <tr>
                                                                                                                                <th style={{ border: "1px solid #636262" }}>Division</th>
                                                                                                                                <td style={{ border: "1px solid #636262" }}>{items.division_name}</td>
                                                                                                                            </tr>
                                                                                                                            <tr>
                                                                                                                                <th style={{ border: "1px solid #636262" }}>Category</th>
                                                                                                                                <td style={{ border: "1px solid #636262" }}>{items.category_name}</td>
                                                                                                                            </tr>
                                                                                                                            <tr>
                                                                                                                                <th style={{ border: "1px solid #636262" }}>Act / Rules</th>
                                                                                                                                <td style={{ border: "1px solid #636262" }}>{items.statu}</td>
                                                                                                                            </tr>
                                                                                                                            <tr>
                                                                                                                                <th style={{ border: "1px solid #636262" }}>Compliance Description</th>
                                                                                                                                <td style={{ border: "1px solid #636262" }}>{items.compliance_description}</td>
                                                                                                                            </tr>
                                                                                                                            <tr>
                                                                                                                                <th style={{ border: "1px solid #636262" }}>Penal Consequences</th>
                                                                                                                                <td style={{ border: "1px solid #636262" }}>{items.penal_consequences}</td>
                                                                                                                            </tr>

                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                );
                                                                                                                // let split = items && items.ageing.split(" ")
                                                                                                                // let finalsplit = { days: '', timeline: '' }
                                                                                                                // for (let i in split) {
                                                                                                                //     if (split[i] !== '' && Number(split[i])) {
                                                                                                                //         console.log(split[i], 'Number(split[i])')
                                                                                                                //         finalsplit.days = split[i]
                                                                                                                //         finalsplit.timeline = split[Number(i) + 1]
                                                                                                                //         console.log(finalsplit, 'kkksksksksksksksksksksk')
                                                                                                                //     }

                                                                                                                //     // console.log(split, finalsplit,Number(split[i]),'kkksksksksksksksksksksk')
                                                                                                                // }
                                                                                                                // console.log(finalsplit, 'split2222222222')
                                                                                                                let file = items.compliance_file_name && items.compliance_file_name.length > 0 && items.compliance_file_name[0]
                                                                                                                return (
                                                                                                                    <tr
                                                                                                                        className="ant-table-row ant-table-row-level-0"
                                                                                                                        key={l}

                                                                                                                    >
                                                                                                                        <td className="ant-table-cell">
                                                                                                                            <center>
                                                                                                                                <span style={{ cursor: 'pointer', color: items.compliance_status == 'Recall' ? 'brown' : items.compliance_status == 'Rectify' ? 'red' : 'black' }}>{count2 + 1}</span>
                                                                                                                                <span
                                                                                                                                    hidden
                                                                                                                                >
                                                                                                                                    {
                                                                                                                                        (count2 =
                                                                                                                                            count2 +
                                                                                                                                            1)
                                                                                                                                    }
                                                                                                                                </span>
                                                                                                                                {items.frequency ==
                                                                                                                                    "Periodical" ? (
                                                                                                                                    <p>
                                                                                                                                        <i
                                                                                                                                            style={{
                                                                                                                                                color:
                                                                                                                                                    "green",
                                                                                                                                            }}
                                                                                                                                            title={
                                                                                                                                                items.frequency
                                                                                                                                            }
                                                                                                                                            className="bi bi-square-fill"
                                                                                                                                        ></i>
                                                                                                                                    </p>
                                                                                                                                ) : items.frequency ==
                                                                                                                                    "Flexi Review" ? (
                                                                                                                                    <p>
                                                                                                                                        <i
                                                                                                                                            style={{
                                                                                                                                                color:
                                                                                                                                                    "pink",
                                                                                                                                            }}
                                                                                                                                            title={
                                                                                                                                                items.frequency
                                                                                                                                            }
                                                                                                                                            className="bi bi-square-fill"
                                                                                                                                        ></i>
                                                                                                                                    </p>
                                                                                                                                ) : items.frequency ==
                                                                                                                                    "Review" ? (
                                                                                                                                    <p>
                                                                                                                                        <i
                                                                                                                                            style={{
                                                                                                                                                color:
                                                                                                                                                    "orange",
                                                                                                                                            }}
                                                                                                                                            title={
                                                                                                                                                items.frequency
                                                                                                                                            }
                                                                                                                                            className="bi bi-square-fill"
                                                                                                                                        ></i>
                                                                                                                                    </p>
                                                                                                                                ) : items.frequency ==
                                                                                                                                    "On Occurrence" ? (
                                                                                                                                    <p>
                                                                                                                                        <i
                                                                                                                                            style={{
                                                                                                                                                color:
                                                                                                                                                    "yellow",
                                                                                                                                            }}
                                                                                                                                            title={
                                                                                                                                                items.frequency
                                                                                                                                            }
                                                                                                                                            className="bi bi-square-fill"
                                                                                                                                        ></i>
                                                                                                                                    </p>
                                                                                                                                ) : (
                                                                                                                                    <p>
                                                                                                                                        <i
                                                                                                                                            style={{
                                                                                                                                                color:
                                                                                                                                                    "red",
                                                                                                                                            }}
                                                                                                                                            title={
                                                                                                                                                items.frequency
                                                                                                                                            }
                                                                                                                                            className="bi bi-square-fill"
                                                                                                                                        ></i>
                                                                                                                                    </p>
                                                                                                                                )}
                                                                                                                            </center>


                                                                                                                            <Fragment>
                                                                                                                                <center>
                                                                                                                                    {/* <input
                                                                                                                            type="checkbox"
                                                                                                                            title="Click here to Task Recall"
                                                                                                                            name={
                                                                                                                                "checked"
                                                                                                                            }
                                                                                                                            onClick={(
                                                                                                                                e
                                                                                                                            ) => {
                                                                                                                                let checked =
                                                                                                                                    e
                                                                                                                                        .target
                                                                                                                                        .checked;
                                                                                                                                // addSelectedDataValues(
                                                                                                                                //     checked,
                                                                                                                                //     items.compliance_history_id,
                                                                                                                                //     items.parentId
                                                                                                                                // );
                                                                                                                            }}
                                                                                                                        /> */}
                                                                                                                                </center>
                                                                                                                            </Fragment>
                                                                                                                        </td>
                                                                                                                        <td className='pointer'>
                                                                                                                            <>


                                                                                                                                <span

                                                                                                                                >{items.statu}</span>




                                                                                                                                <div className="approve-task-reacall-tooltip">
                                                                                                                                    <Tooltip placement="topRight" title={content} > <span className='mx-1 text-primary'>
                                                                                                                                        <i className="ri-information-fill" /></span> </Tooltip>
                                                                                                                                    <span
                                                                                                                                    // style={{ cursor: 'pointer', color: items.compliance_status == 'Recall' ? 'brown' : items.compliance_status == 'Rectify' ? 'red' : 'black' }} onClick={() => {
                                                                                                                                    //     showValue()
                                                                                                                                    //     setNextData(itemdata.child[l + 1])
                                                                                                                                    //     setCompletionDate("")
                                                                                                                                    //     SetRemarks("")
                                                                                                                                    //     setFileUpload([])
                                                                                                                                    //     setEdit(false)
                                                                                                                                    //     setFileSize(0)
                                                                                                                                    //     setValueModal({
                                                                                                                                    //         ...valueModal,
                                                                                                                                    //         domain_id: items.domain_id,
                                                                                                                                    //         document_size: items.document_size,
                                                                                                                                    //         start_date: items.start_date,
                                                                                                                                    //         action: items.compliance_status,
                                                                                                                                    //         due_date: items.due_date,
                                                                                                                                    //         uploaded_document: items.uploaded_documents,
                                                                                                                                    //         actiondata: items.action,
                                                                                                                                    //         address: items.address,
                                                                                                                                    //         approve_status: items.approve_status,
                                                                                                                                    //         unit_id: items.unit_id,
                                                                                                                                    //         interim_count: items.interim_count,
                                                                                                                                    //         completion_date: items.completion_date,
                                                                                                                                    //         remarks: items.remarks,
                                                                                                                                    //         description: items.description,
                                                                                                                                    //         compliance_description: items.compliance_description,
                                                                                                                                    //         compliance_history_id: items.compliance_history_id,
                                                                                                                                    //         unit: items.unit_name,
                                                                                                                                    //         compliance_task: items.compliance_name,
                                                                                                                                    //         compliance_frequency: items.compliance_task_frequency,
                                                                                                                                    //         document_issued_date: items.completion_date,
                                                                                                                                    //         document_reference_number: items.document_reference_number,
                                                                                                                                    //         next_due_date: items.next_due_date != null ? items.next_due_date : items.due_date,
                                                                                                                                    //         history_count: items.remarks_count,
                                                                                                                                    //         occurrence_remarks: items.occurrence_remarks,
                                                                                                                                    //         uniqueId: items.uniqueId
                                                                                                                                    //     })
                                                                                                                                    //     setAttachedFile(items.compliance_download_url)
                                                                                                                                    //     setValueForFileUpload({
                                                                                                                                    //         d_id: items.domain_id,
                                                                                                                                    //         u_id: items.unit_id,
                                                                                                                                    //         start_date: items.start_date,
                                                                                                                                    //     })
                                                                                                                                    // window.scrollTo(0, 0);
                                                                                                                                    // }}
                                                                                                                                    >{items.compliance_name}</span> < br />
                                                                                                                                    <span style={{ fontSize: '12px' }}>Assigned on : {items.assigned_on}</span>

                                                                                                                                </div>

                                                                                                                            </>
                                                                                                                        </td>
                                                                                                                        {/* <td>
                                                                                                                  {items.assignee_name}
                                                                                                              </td> */}
                                                                                                                        <td className='pointer'>
                                                                                                                            <p
                                                                                                                            >{
                                                                                                                                    items.domain_name
                                                                                                                                }</p>
                                                                                                                        </td>
                                                                                                                        <td className='pointer'>
                                                                                                                            <center>
                                                                                                                                <p


                                                                                                                                >{moment(items.start_date).format('DD-MMM-YYYY')}
                                                                                                                                </p>
                                                                                                                            </center>
                                                                                                                        </td>

                                                                                                                        <td className='pointer'>
                                                                                                                            <center>
                                                                                                                                <p
                                                                                                                                >{items.due_date}
                                                                                                                                </p>
                                                                                                                            </center>
                                                                                                                        </td>



                                                                                                                        <td className='text-center'>
                                                                                                                            {
                                                                                                                                items.compliance_file_name && items.compliance_file_name.length > 0 ?
                                                                                                                                    <>
                                                                                                                                        <Tooltip title='Download Format'>
                                                                                                                                            <DownloadOutlined
                                                                                                                                                onClick={() => {
                                                                                                                                                    fileListTemp({
                                                                                                                                                        payload: [
                                                                                                                                                            authtoken,
                                                                                                                                                            {
                                                                                                                                                                "session_token": authtoken,
                                                                                                                                                                "request": [
                                                                                                                                                                    "File_List_Temp",
                                                                                                                                                                    {
                                                                                                                                                                        "le_id": showdata && showdata.legal_entity,
                                                                                                                                                                        "compliance_history_id": Number(items.compliance_history_id),
                                                                                                                                                                        "u_id": Number(items.unit_id)
                                                                                                                                                                    }
                                                                                                                                                                ]
                                                                                                                                                            }
                                                                                                                                                        ],
                                                                                                                                                        paramid: paramid
                                                                                                                                                    })
                                                                                                                                                    window.open(urls + file)
                                                                                                                                                }}
                                                                                                                                            />
                                                                                                                                        </Tooltip>
                                                                                                                                    </> : ''
                                                                                                                            }
                                                                                                                        </td>

                                                                                                                    </tr>
                                                                                                                );
                                                                                                            }
                                                                                                        )}
                                                                                                    </Fragment>
                                                                                                );

                                                                                            })
                                                                                        ) : (
                                                                                            <tr >
                                                                                                <td colSpan="100%" style={{ paddingTop: '15px', padding: '40px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                                                            </tr>
                                                                                        )}

                                                                                        {/* ) : (
                                                            <tr>
                                                                <td
                                                                    colSpan={7}
                                                                    style={{
                                                                        textAlign: "center",
                                                                        fontSize: "14px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    No Compliance Available
                                                                </td>
                                                            </tr>
                                                        )} */}
                                                                                    </tbody>


                                                                                </table>

                                                                            </div>
                                                                        </div>
                                                                    </div>




                                                                </div>
                                                            </div>




                                                            {
                                                                // upcomingTask.map((list) => {
                                                                // return <>
                                                                // <Table
                                                                //     size={'small'}
                                                                //     columns={columns}
                                                                //     locale={{ emptyText: <b className='text-dark'>{'No compliance available'}</b> }}
                                                                //     dataSource={upcomingTask?.upcoming_compliances}
                                                                //     bordered
                                                                //     // title={() => {
                                                                //     //     return list.parent
                                                                //     // }}
                                                                //     // {...tableProps}
                                                                //     // scroll={{ x: 1500, y: 1000 }}
                                                                //     pagination={false}


                                                                // />





                                                                //     </>

                                                                // })
                                                            }

                                                            {/* <p style={{ marginTop: '2%' }}>Total Upcoming Compliance : {upcomingTask && upcomingTask.total_count}</p> */}

                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                </div>

                                            </TabPane>

                                        </Tabs>
                                    </div>
                                </>


                                : ''
                            }

                        </div>
                    </div>


                </div >
            </div >
            {show == true && tab != 1 ?
                <>
                    <Stickicon />
                </>
                : ''}
            <Modal title="" open={false} footer={null} cancelText={false} onCancel={Cancelmodal} maskClosable={false} >
                {/* <div className="row"> */}
                <div className="col-md-12 d-flex" >
                    <div>
                        <button
                            type="button"
                            className="btn btn-light-primary text-primary btn-circle btn-xl me-3 mb-1 pe-none"
                        >
                            <i
                                className="fas fa-list-alt"
                            ></i>
                        </button>
                    </div>
                    <div>
                        <p className='mb-1'><b>Unit Name</b></p>
                        <h4 className='fw-bolder'> {valueModal.unit}&nbsp;<Tooltip title={valueModal.address} >
                            <InfoCircleOutlined style={{ color: '#5DAAE3', fontSize: '14px' }} />
                        </Tooltip></h4>
                    </div>
                </div>
                <div className="container py-2">
                    <div className="row">
                        <div className="col-6 ModalCard border  p-2 bg-light mb-2">
                            <p className="mb-1"><b>Compliance Task:</b></p>
                            <h5>{valueModal.compliance_task}&nbsp;<Tooltip placement='topLeft' title={valueModal.description} >
                                <InfoCircleOutlined style={{ color: '#5DAAE3', fontSize: '14px' }} />
                            </Tooltip></h5>
                        </div>
                        <div className="col-6  ModalCard border p-2 bg-light mb-2">
                            <p className="mb-1"><b>Compliance Frequency:</b></p>
                            <h5>{valueModal.compliance_frequency}</h5>
                        </div>
                        {/* <div className="col-4 ModalCard  border p-2 bg-light mb-2">
                            <p className="mb-1"><b>Document Reference Number:</b></p>
                            <h5>{valueModal.document_reference_number}</h5>
                        </div> */}
                        <div className="col-12 ModalCard border  p-2 bg-light mb-2">
                            <p className="mb-1"><b>Uploaded Documents:</b></p>
                            {/* {valueModal.uploaded_document != null ?
                                            valueModal.uploaded_document.length > 0 && valueModal.uploaded_document.map((item, i) => {
                                                return ( */}
                            <div className='d-flex justify-content-between pb-1'>
                                <input type="file" multiple name="file"
                                    // accept='.csv'
                                    disabled={valueModal.status == 'Recall' ? true : false}
                                    onChange={(e) => {
                                        SavepastRecordOnChange(e);
                                    }}
                                    id="" />
                            </div>
                        </div>

                        <div className="col-md-12 ModalCard border  p-2 bg-light mb-2">
                            <p className="mb-1"><b>Task Completion Date / Document Issued Date: </b><span style={{ color: "red" }}>*</span></p>
                            <h5> <DatePicker allowClear={false}
                                // format={'DD-MMM-YYYY'}
                                className='form-control'
                                value={completionDate ? completionDate : ''}
                                style={{ width: '40%' }}
                                showTime={valueModal.dayType == 'hours' ? true : false}

                                format={(valueModal.dayType == 'hours') && (valueModal.compliance_frequency == 'On Occurrence') ? 'DD-MMM-YYYY HH:mm' : 'DD-MMM-YYYY'}
                                name="validity_date" id=""
                                disabledDate={(current) => {
                                    return current > Date.now();
                                }}
                                onKeyDown={(event) => {
                                    event.preventDefault();
                                }}
                                onChange={(dateString) => {
                                    setCompletionDate(
                                        dateString
                                    );
                                }}
                                disabled={valueModal.status == 'Recall' ? true : false}
                            />

                            </h5>
                        </div>

                        <div className="col-md-6 d-flex align-items-center ModalCard border p-2 bg-light mb-2" >
                            <p className="mb-1 me-1"><b>Document Reference Number:</b></p>
                            <Input class="form-control"
                                disabled={valueModal.status == 'Recall' ? true : false}
                                style={{ "width": "80%", display: 'block' }}
                                value={valueModal.document_reference_number}
                                onChange={onInputChange1}
                            // onChange={(e) => {
                            //     const value = e.target.value;
                            //     const re = /^[A-Za-z0-9()-_.,/]{0,500}$/;
                            //     if (value === "" || re.test(value)) {
                            //         // SetRemarks(value)
                            //         setDocumentNo(value)
                            //     }
                            // }}
                            />
                        </div>
                        <div className="col-6 ModalCard border  p-2 bg-light mb-2">
                            <p className="mb-1"><b>Attached Documents:</b></p>
                            <h5>
                                {fileupload && fileupload.length > 0 && fileupload.map((item, i) => {

                                    return <p style={{ wordBreak: 'break-all' }}>{item.file_name}    <Button type="primary" class='fa fa-download text-primary c-pointer'
                                        icon={<DownloadOutlined />} size='small'
                                        onClick={(e) => {
                                            download(item.file_name);
                                        }}
                                        style={{ marginTop: '2%' }}  >
                                    </Button> &nbsp;
                                        <Button type="primary" class='fa fa-times text-primary removeicon'
                                            icon={< CloseOutlined />} size='small'
                                            onClick={(e) => { Remove(item.file_name); }}
                                        >
                                        </Button></p>;
                                })}
                            </h5>        </div>

                        <div className="col-md-6 d-flex align-items-center ModalCard border p-2 bg-light mb-2" >
                            <p className="mb-1 me-1"><b>Validity Date : </b></p>
                            <p className="mb-1 me-1">{valueModal.completion_date}</p>
                        </div>

                        <div className="col-md-6 d-flex align-items-center ModalCard border p-2 bg-light mb-2" >
                            <p className="mb-1 me-1"><b>Status: </b></p>
                            <p className="mb-1 me-1">{valueModal.status}</p>
                        </div>
                        {valueModal.status == 'Recall' ?
                            <div className="col-md-6 d-flex align-items-center ModalCard border p-2 bg-light mb-2" >
                                <p className="mb-1 me-1"><b>Recall Remarks</b></p>
                                <p className="mb-1 me-1">{valueModal.recall}</p>
                                {valueModal.recall_history_count != 0 ?
                                    <>
                                        <Tooltip title="click to view remark history" ><InfoCircleOutlined style={{ color: '#5DAAE3', marginLeft: '5px' }} onClick={(e) => {
                                            // showModalremark()
                                            // setToolTip([valueModal])
                                            // const remarkapi = [
                                            //     authtoken,
                                            //     {
                                            //         "session_token": authtoken,
                                            //         "request": [
                                            //             "GetComplianceRemarksHistoryData",
                                            //             {
                                            //                 "legal_entity_id": entityid,
                                            //                 "unit_id": Number(valueModal.unit_id),
                                            //                 "compliance_history_id": Number(valueModal.compliance_history_id)
                                            //             }
                                            //         ]
                                            //     }
                                            // ]
                                            // getremarkhistory({
                                            //     payload: remarkapi,
                                            //     paramid: paramid
                                            // })
                                        }
                                        } /></Tooltip></> : ''
                                }
                            </div> : ''}

                        {valueModal.next_due_date !== null ?
                            <div className="col-md-6 d-flex align-items-center ModalCard border p-2 bg-light mb-2" >
                                <p className="mb-1 me-1"><b>Next Due Date : </b></p>
                                <p className="mb-1 me-1">{valueModal.next_due_date}</p>
                            </div> : ''}
                        <div className="col-md-6 border ModalCard p-2 bg-light mb-2">
                            <p className="mb-1"><b>Remarks:</b></p>
                            <textarea class="form-control" maxLength={500} onChange={onInputChange} rows={1} style={{ width: '80%', display: 'inline' }}
                                value={remarks}
                            // onChange={(e) => {
                            //     const value = e.target.value;
                            //     console.log(value,'valuevalue');
                            //     const re = /^[A-Za-z0-9_ .,-]{0,500}$/;
                            //     if (value === "" || re.test(value)) {
                            //         console.log(value,'valuevaluevaluevalue');
                            //         SetRemarks(value)
                            //     }
                            // }}
                            />
                            <span className='mx-1 text-info'
                                onClick={() => { showRemarksModal(); }}>
                                <PlusCircleOutlined />
                            </span>
                        </div>
                        <div className="col-md-12 text-center p-2 mb-2">
                            {
                                valueModal.status !== 'Recall' ? <div className={'popupbtncolour ' + localStorage.getItem('currentTheme')}>
                                    <Button type="primary" shape="round" className={'addbutton me-1 ' + localStorage.getItem('currentTheme')}
                                        // style={{ background: "#198754", borderColor: "#198754", marginLeft: '25%' }}
                                        size='default'
                                        onClick={() => {
                                            if (fileupload && fileupload.length > 0 || remarks !== '') {
                                                const payload = [
                                                    authtoken,
                                                    {
                                                        "session_token": authtoken,
                                                        "request": [
                                                            "SaveInterimComplianceHistory",
                                                            {
                                                                "legal_entity_id": showdata && showdata.legal_entity,
                                                                "compliance_history_id": valueModal.compliance_history_id,
                                                                "documents": fileupload.length > 0 ? fileupload : null,
                                                                "uploaded_documents": null,
                                                                "remarks": remarks,
                                                                "file_size": 0
                                                            }
                                                        ]
                                                    }
                                                ];
                                                const payload2 = [
                                                    authtoken,
                                                    {
                                                        "session_token": authtoken,
                                                        "request": [
                                                            "GetCurrentComplianceDetail",
                                                            {
                                                                "le_id": showdata.legal_entity,
                                                                "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                                                                "current_start_count": 0,
                                                                "cal_view": null,
                                                                "cal_date": null,
                                                                "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                                                                "division_id": showdata.division != '' ? Number(showdata.division) : null,
                                                                "category_id": showdata.category != '' ? Number(showdata.category) : null,
                                                                "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                                                            }
                                                        ]
                                                    }
                                                ];


                                                updateStatus({
                                                    payload: payload,
                                                    paramid: paramid,
                                                    setCompletionDate: setCompletionDate,
                                                    setFileUpload: setFileUpload,
                                                    valueModal: valueModal,
                                                    setValueModal: setValueModal,
                                                    SetRemarks: SetRemarks,
                                                    setFileSize: setFileSize,

                                                    // setShowValueModal: setShowValueModal,
                                                    // nextData: nextDatas,
                                                    payload2: payload2

                                                });
                                            }
                                            else {
                                                Toaster.error("Please give either a valid remarks Or upload document to save");
                                            }


                                        }}
                                    >
                                        Update Interim Status
                                    </Button>
                                    <Button shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                        onsubmit
                                        icon={<PlayCircleOutlined />} size='default'
                                        onClick={onsubmit

                                        }
                                    >
                                        Submit
                                    </Button>
                                </div> : false
                            }

                        </div>


                    </div>
                </div>

            </Modal>

            <Modal title="Remarks List" open={remarksModal}
                onCancel={handleCancel}
                className={" add-service-prv remarksClass " + localStorage.getItem('currentTheme')} footer={false} maskClosable={false}>
                <Table
                    // className='userprivclass'
                    size={'small'}
                    columns={RemarksColumn}
                    dataSource={remarksvalue && remarksvalue.length > 0 ? remarksvalue : ''}
                    bordered
                    pagination={false}
                />
            </Modal>
            <Modal autoFocus={false} open={passvisible} className='usr_modal_class modelradius' footer={null} onCancel={passwordcancel} maskClosable={false}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="mb-6">
                            <label className="control-label"> <b>Password </b><span style={{ "color": "red" }}>*</span></label>
                            <input
                                autoFocus={true}
                                maxLength='20'
                                type="password"
                                id="password"
                                autoComplete='off'
                                name='passwordvalue'
                                placeholder="Enter password"
                                onChange={(e) => {
                                    Setpassword({
                                        ...passwordata,
                                        passvalue: e.target.value

                                    });
                                }}
                                value={passwordata.passvalue}
                                style={{ "margin-top": "5px", width: '100%' }}
                                className="form-control"
                            />
                            {formValidator1.current.message(
                                'Password',
                                passwordata.passvalue,
                                ['required'],
                                {
                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Password Required'
                                    }
                                })}
                        </div>
                    </div>

                </div>
                <br />
                <div className={"form-actions popupbtncolour " + localStorage.getItem('currentTheme')}>
                    <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                        style={{ marginLeft: '33%' }}
                        icon={<PlayCircleOutlined />} size='default'
                        onClick={submitpassword}
                    >Submit
                    </Button>

                    {/* <button
                        className="btn btn-light-danger rounded-pill px-4 ms-2 text-danger waves-effect text-start"
                        onClick={passwordcancel}
                    >Cancel
                    </button> */}
                </div>
            </Modal>
            {valueModal.remarks != "" || null ?
                <Modal title="Remarks History" className={' remarksClass  add-service-prv ' + localStorage.getItem("currentTheme")} open={isModalOpenremark} footer={null} onCancel={handleCancelremark} maskClosable={false}>

                    <Table
                        size={'small'}
                        className={localStorage.getItem("currentTheme")}
                        columns={columnsremark}
                        dataSource={remarklist.remarks_history}
                        bordered
                        pagination={false}
                    />
                </Modal> : ""}
            {/* <Modal visible={uploadModal}
                title="Uploaded Documents"
                className={" add-service-prv " + localStorage.getItem('currentTheme')}
                footer={null}
            // onCancel={modalOnChane}
            >
                <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper'}>
                    <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                        <div className='ant-table-container'>
                            <div className='ant-table-content  userprivclass1'></div>
                            <table>
                                <colgroup>
                                    <col style={{ width: '10px' }}></col>
                                </colgroup>
                                <thead className="ant-table-thead" >
                                    <tr>
                                        <th className={'ant-table-cell'} style={{ width: '20px' }}>#</th>
                                        <th className={'ant-table-cell'} style={{ width: '30px' }}>Documents</th>
                                        <th className={'ant-table-cell'} style={{ width: '50px' }}>Remarks</th>
                                        <th className={'ant-table-cell'} style={{ width: '30px' }}>Updated On</th>

                                    </tr>
                                </thead>

                                <tbody className='ant-table-tbody'>
                                    {InterimDetails && InterimDetails.length > 0 && InterimDetails.map((item) => {
                                        let splitData = item.documents && item.documents.split('||')
                                        console.log(splitData, 'splitData');
                                        return (
                                            <tr>
                                                <td><center>{sno + 1}</center></td>
                                                <td>
                                                    {splitData && splitData.length > 0 && splitData.map((items) => {
                                                        return <tr><center>
                                                            <a href='javascipt:;' onClick={() => {

                                                                const downloadpayload =
                                                                    [
                                                                        authtoken,
                                                                        {
                                                                            "session_token": authtoken,
                                                                            "request": [
                                                                                "DownloadInterimFile",
                                                                                {
                                                                                    "le_id": showdata.legal_entity,
                                                                                    "c_id": 1,
                                                                                    "d_id": valueForFileUpload.d_id,
                                                                                    "u_id": valueForFileUpload.u_id,
                                                                                    "start_date": valueForFileUpload.start_date,
                                                                                    "file_name": items,
                                                                                }
                                                                            ]
                                                                            // "request": [
                                                                            //     "DownloadFile_temp",
                                                                            //     {
                                                                            //         "le_id": "zppX",
                                                                            //         "c_id": 1,
                                                                            //         "d_id": 1,
                                                                            //         "u_id": 38781,
                                                                            //         "start_date": "08-Mar-2023 12:00",
                                                                            //         "file_name": "Registers 1-28726-21192-59458.pdf",
                                                                            //         "upload_type": 2,
                                                                            //         "compliance_history_id": 1432
                                                                            //     }
                                                                            // ]

                                                                        }
                                                                    ]
                                                                console.log(downloadpayload, 'downloadpayload');
                                                                Downloadfile({
                                                                    payload: downloadpayload,
                                                                    paramid: paramid
                                                                });
                                                            }}>   {
                                                                    items
                                                                }
                                                            </a>

                                                        </center></tr>
                                                    })}
                                                </td>
                                                <td><center>
                                                    {
                                                        item.remarks
                                                    }
                                                </center></td>

                                                <td><center>
                                                    {
                                                        item.activity_on
                                                    }
                                                </center></td>
                                                <span hidden>
                                                    {
                                                        (sno =
                                                            sno + 1)
                                                    }
                                                </span>
                                            </tr>
                                        )
                                    })}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div >
            </Modal > */}
        </div >

    );
};

export default connect(mapStateToProps, {
    compliancefilters,
    emailsave,
    GetGroupListData,
    FetchEmails,
    verifypassword,
    updateblockoption,
    GetOnOccurrenceCompliances,
    onoccurrencetransaction,
    startonoccurrencecompliance,
    GetRemarksData,
    getCalenderView,
    getCurrentTaskDetails,
    UploadFile1,
    getUpcomigTaskDetails,
    getFilterDataValues,
    UploadFile,
    Downloadfile,
    removedocument,
    updateStatus,
    saveStatus,
    getremarkhistory,
    getInterimDetails,
    fileListTemp
})(ComplianceTaskDetails);
