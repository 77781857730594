import HTTP from '../../../src/Libs/http'
import { Toaster } from '../../../src/Libs/Toaster'
import url, { getUrl } from './../../../src/Libs/URL'
import EncryptDecrypt from './../../../src/Libs/Encrypt&Decrypt'
import { decode as base64_decode , encode as base64_encode} from 'base-64';
import alertmessage from '../../../src/Libs/Altermessage';
import { TOGGLE_COMMON_LOADER, PROFILE, UPDATEPROFILE } from './../../Store/types/index'
const urls = getUrl()

export const UserProfile = ({ payload, executeCancel, paramid, formkey }) => async dispatch => {
    try {
        console.log(formkey, 'formkeyformkey');
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = formkey
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();
            } else {
                dispatch({
                    type: PROFILE,
                    payload: decryptData[1],
                });
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const UpdateProfile = ({ payload, executeCancel, paramid, formkey }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = formkey
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();
            }else if(decryptData[0] == 'Invalid Mobile Number'){
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error('Invalid Mobile Number'); 
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            } else {
                Toaster.success("Record Updated successfully")
                // setTimeout(() => {
                //     window.location.reload(false);
                // }, 2000);
                // dispatch({
                //     type: UPDATEPROFILE,
                //     payload: decryptData[1],
                // });
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const Updatepassword = ({ payload, executeCancel, paramid, formkey }) => async dispatch => {
    try {
        console.log(urls,'geturls')
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = formkey
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json',
                'Accept': '*/*'
            },
            
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/login`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });

            if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();
            }else if(decryptData[0] === 'InvalidCurrentPassword'){
                dispatch({
                    type: TOGGLE_COMMON_LOADER,
                    payload: true
                });
                Toaster.error('Invalid Current Password')
            }
             else {
                if (decryptData[0] === 'ChangePasswordSuccess') {
                    alertmessage.sweetalertPassword();
                } else if (decryptData[0] == 'Your password matches with history of last five records, please use different one') {
                    Toaster.error('Your password matches with history of last five records, please use different one')
                } else {
                    dispatch({
                        type: TOGGLE_COMMON_LOADER
                    });
                    Toaster.success(decryptData[0])

                }
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const UpdatepasswordLogin = ({ payload, executeCancel, paramid, formkey }) => async dispatch => {
    try {
        console.log(urls,'geturls')
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = formkey
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json',
                'Accept': '*/*'
            },
            
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: url.Login + `/api/login`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });

            if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();
            }else if(decryptData[0] === 'InvalidCurrentPassword'){
                dispatch({
                    type: TOGGLE_COMMON_LOADER,
                    payload: true
                });
                Toaster.error('Invalid Current Password')
            }
             else {
                if (decryptData[0] === 'ChangePasswordSuccess') {
                    alertmessage.sweetalertPassword();
                } else if (decryptData[0] == 'Your password matches with history of last five records, please use different one') {
                    Toaster.error('Your password matches with history of last five records, please use different one')
                } else {
                    dispatch({
                        type: TOGGLE_COMMON_LOADER
                    });
                    Toaster.success(decryptData[0])

                }
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};