import CryptoJS from 'crypto-js';

export default class EncryptDecrypt {

    static encryptdata(encryptpayload, formkey) {
        const data = encryptpayload;
        const key = formkey; //url.encryptionKey atob
       const fkey = CryptoJS.enc.Utf8.parse(key);
    //const fkey = key;
        const fiv = CryptoJS.enc.Base64.parse('rpH384l8rT02vnH4rpH3');
        const enc = CryptoJS.AES.encrypt(JSON.stringify(data), fkey, {
            iv: fiv,
            mode: CryptoJS.mode.CTR,
            padding: CryptoJS.pad.NoPadding,
        });
        const final = enc.ciphertext.toString(CryptoJS.enc.Base64);
        const finalString = final.toString(CryptoJS.enc.Base64);

        return finalString
    }

    static decryptdata(decryptpayload,formskey){
            const fkey = CryptoJS.enc.Utf8.parse(formskey);
            const fiv = CryptoJS.enc.Base64.parse('rpH384l8rT02vnH4rpH3');
            let finalString = decryptpayload
    
            const option = {
              iv: fiv,
              mode: CryptoJS.mode.CTR,
              padding: CryptoJS.pad.NoPadding
            };
            const cipherObj = CryptoJS.lib.CipherParams.create({
              ciphertext: CryptoJS.enc.Base64.parse(finalString)
            });
            const decrypt = CryptoJS.AES.decrypt(cipherObj, fkey, option);
            const finalDecrypt = decrypt.toString(CryptoJS.enc.Utf8);
            return finalDecrypt
        
    }
}