import React, { useEffect, useState, useRef, Fragment, useCallback, useMemo } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux';
import { Route, Switch, BrowserRouter, Link, useLocation, useHistory } from 'react-router-dom';
import { Collapse, Input, Modal, Table, List, Tooltip, DatePicker, Tabs, Tree, Space, Button, Checkbox, Drawer, Upload, Select, Radio, Col, Row } from 'antd';
import { PlayCircleOutlined, DownloadOutlined, DoubleRightOutlined, InfoCircleTwoTone, CloseOutlined, DoubleLeftOutlined, DeleteOutlined, PlusOutlined, FullscreenOutlined, FilterOutlined, UpOutlined, InboxOutlined, EyeOutlined, CloudUploadOutlined, InfoCircleFilled, TrademarkOutlined } from '@ant-design/icons';
import SimpleReactValidator from "simple-react-validator";
import DataTable from "react-data-table-component";
import Swal from 'sweetalert2';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import EncryptDecrypt from '../../Libs/Encrypt&Decrypt'
import { GetGroupListData, FetchEmails, emailsave, verifypassword, updateblockoption } from '../../Store/Action/Master/CV_CertificateMailMapping';
import { constant, filter, indexOf, merge } from 'lodash';
import { InfoCircleOutlined, PlusCircleOutlined, SearchOutlined, FilterFilled, ExclamationCircleTwoTone, PlusSquareTwoTone } from '@ant-design/icons';
import { getFilterDataValues, getShowdata, getTabledata, EasyUploadFile, downloadfiletemp1, removedocument, saveuploadStatus, downloadfiletemp, RemarkHistory } from '../../Store/Action/Transcations/EXE_EasyUpload';
import { GetRemarksData } from '../../Store/Action/Transcations/Taskrecall'
import { Toaster } from '../../Libs/Toaster';
// import { Downloadfile } from '../../Store/Action/T,ranscations/ComplianceApproval';
import { toast } from 'react-toastify';
// import Calendar from 'react-awesome-calendar';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment/moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { EASY_FILTER_MODAl, IND_FILE, RESET_REMARK_HISTORY, SHOW_DATA_RESET } from '../../Store/types';
import ScrollButton from '../Scrollbar';
import ScrollToTop from "react-scroll-to-top";
import { HomeRedirect } from './../../Libs/country'
const homepage = HomeRedirect()

const _ = require("lodash");

const mapStateToProps = (state) => ({
    filterrecall: state.recallfilters.recallfilters,
    complianceTaskDetail: state.EXE_Easyupload.complianceTaskDetails,

})


const CV_Easyupload = ({
    downloadfiletemp,
    downloadfiletemp1,
    removedocument,
    getCalenderView,
    updateStatus,
    saveStatus,
    getFilterDataValues,
    getCurrentTaskDetails,
    getUpcomigTaskDetails,
    GetRemarksData,
    getShowdata,
    getTabledata,
    RemarkHistory,
    EasyUploadFile,
    saveuploadStatus,
    filterrecall: { recallremarks },
    complianceTaskDetail: { compliancefilter, complianceshowdata, compliancetabledata, remarks_history_data },
    // onoccurrencecompliances: { compliancefilter, compliancefiltershow, complianceview }
}) => {
    console.log(remarks_history_data, 'compliancremarks_history_dataeshowdata');
    const is_invalid = useSelector((state) => state.EXE_Easyupload.is_invalid)
    const indiv_file = useSelector((state) => state.EXE_Easyupload.indiv_file)
    const easymodal_filter = useSelector((state) => state.EXE_Easyupload.easymodal_filter)
    console.log(indiv_file, 'indiv_file')
    console.log(easymodal_filter, 'easymodal_filter')
    // const remark_history_data = useSelector((state) => state.EXE_Easyupload.complianceTaskDetails.remarks_history_data)
    const { Panel } = Collapse;
    const { Option } = Select;
    const { Search, TextArea } = Input;
    const historynew = useHistory();
    const { Dragger } = Upload;
    const [tab, setTab] = useState()
    const fileuploads = useSelector((state) => state.EXE_Easyupload.fileupload)
    console.log(fileuploads, 'fileuploads')
    const location = useLocation();
    const [show, setShow] = useState(false)
    console.log(show, 'showP')
    const [showMore, setShowMore] = useState(false)
    const [counts, setCount] = useState(0)
    const [FormShow, setFormShow] = useState(false);
    const [modalDatas, setModalDatas] = useState(false)
    const [modalDatas1, setModalDatas1] = useState(false)
    console.log(modalDatas, 'modalDatas')
    const [showstat, setShowstat] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [passvisible, Setpassvisible] = useState(false);
    const [date, setDate] = useState(new Date())
    const [documentRemarks, setDocumentRemarks] = useState([])
    const [modalValue, setModalValue] = useState({
        le_id: '',
        domain_id: '',
        start_date: '',
        compliance_history_id: '',
        c_id: '',
        unit_id: '',
        rowId: ''
    })
    const [modalValue1, setModalValue1] = useState({
        le_id: '',
        domain_id: '',
        start_date: '',
        compliance_history_id: '',
        c_id: '',
        unit_id: '',
        rowId: '',
        document: []
    })
    console.log(modalValue1, 'modalValue1')
    const searchInput = useRef(null);
    const [status, setStatus] = useState({
        overDue: false,
        inprogress: false,
        recall: false
    })
    const [searchedColumn, setSearchedColumn] = useState('');
    const [childId, setChildId] = useState()
    const [monthPrview, setMonthPrview] = useState({
        yearvalue: moment().format('YYYY'),
        monthvalue: moment().format('MMM').toUpperCase()
    })
    // console.log(remark_history_data, 'remark_history_data ');
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [documentNo, setDocumentNo] = useState("")
    const [tempList, setTempList] = useState([])
    const [showValueModal, setShowValueModal] = useState(false)
    const [particularevent, setParticularevent] = useState({
        data: '',
        value: false
    })
    const [passwordata, Setpassword] = useState({
        passvalue: '',
        sp_id: '',
        active_status: ''
    })
    const [payloadArray, setPayloadArray] = useState([])
    console.log(payloadArray, 'payloadArray');
    const [updateRemarks, setUpdateRemarks] = useState([])
    console.log(updateRemarks, "updateRemarks");
    const [checkBoxValue, setCheckBoxValue] = useState([])
    const [checkBoxValue1, setCheckBoxValue1] = useState([])
    const [remarksHistoryModal, setRemarksHistoryModal] = useState(false)
    console.log(checkBoxValue1, checkBoxValue, 'checkBoxValue1');
    const [completionDate, setCompletionDate] = useState("")
    const [valueModal, setValueModal] = useState({
        unit: '',
        recall: '',
        address: '',
        compliance_history_id: '',
        compliance_task: '',
        compliance_frequency: '',
        uploaded_document: [],
        document_issued_date: '',
        document_reference_number: '',
        validity_date: '',
        next_due_date: '',
        status: '',
        remarks: '',
        action: '',
        description: "",
        completion_date: "",
        unit_id: null,
        approve_status: "",
        actiondata: "",
        start_date: "",
        domain_id: null,
        history_count: '',
        occurrence_remarks: '',
    })
    const paramid = location.pathname;
    const [checkdata, setCheckdata] = useState([])
    const { TabPane } = Tabs;
    var [count1, setCount1] = useState(1);
    const currentdate = new Date();
    const [today, setToday] = useState(moment(currentdate).format('DD-MMM-YYYY'))
    let [count, setcount] = useState(0)
    const formValidator = useRef(new SimpleReactValidator());
    const formValidator1 = useRef(new SimpleReactValidator());
    // const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [remarksModal1, setRemarksModal1] = useState(false);
    const [remarksModal, setRemarksModal] = useState(false);
    console.log(remarksModal, 'remarksModal');
    const [eventData, setEventData] = useState([])
    const localizer = momentLocalizer(moment);
    const validator = useRef(new SimpleReactValidator());
    const [uid, setUId] = useState([])
    console.log(is_invalid, "is_invalid");
    const [isRemarksEmpty, setIsRemarksEmpty] = useState(false)

    const [showdata, Setshowdata] = useState({
        domain: '',
        unit: [],
        frequency: '',
        category: '',
        act: '',
        division: '',
        // legal_entity: '',
        legal_entity: '',
        task_category: '',
        tasksub_category: '',
        domainName: '',
        unit_name: ''
    })
    console.log(showdata, "showdata123");

    const validator1 = useRef(new SimpleReactValidator());
    const validator2 = useRef(new SimpleReactValidator());
    const [fileData, setFileData] = useState([])
    const [valueForFileUpload, setValueForFileUpload] = useState({
        d_id: '',
        u_id: '',
        start_date: '',
        file_info: [],
    })
    const [fileupload, setFileUpload] = useState({})
    const [fileupload1, setFileUpload1] = useState([])
    console.log(fileupload, fileupload1, "fileupload");
    const [currentMonth, setCurrentMonth] = useState(moment(new Date()).startOf('month'))
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    const [modalVisible, setModaVisible] = useState(false)
    const [taskvalue, setTaskvalue] = useState()
    const [division, setDivision] = useState([])
    const [frequency, setfrequency] = useState([])
    const [category, setCategoryList] = useState([])
    const [units, setUnits] = useState([])
    const [domain, setDomain] = useState([])
    console.log(domain, "domaindomain");
    const [legalentity, setLegalentity] = useState()
    const [taskcategory, setTaskcategory] = useState([])
    console.log(category, "taskcategory");
    const [tasksubcategory, setTasksubcategory] = useState([])
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setfilteredArrayState] = useState([])
    console.log(filteredArrayState, 'filteredArrayState');
    const [titledata, Settitledata] = useState([])
    const [finaldata, Setfinaldata] = useState([])
    console.log(finaldata, "finaldatafinaldata");
    const [monthValue, setmonthValue] = useState(moment().format('MMM').toUpperCase());
    const [isSelectedMonth, setIsSelectedMonth] = useState(false)
    const [complianceID, setComplianceID] = useState([]);
    console.log(complianceID, 'complianceID');
    const [downloadclick, setDownloadClick] = useState(false);

    const [modalData, setModalData] = useState({
        password: '',
    })

    const [tempvalue, setTempvalue] = useState([])

    const [remarks, SetRemarks] = useState({
        remarks: "",
    });

    const handlecancelHistory = () => {
        setRemarksHistoryModal(false)
    }

    const handlecategory = () => {
        // setCategoryList(compliancefilter && compliancefilter.categories_list && compliancefilter.categories_list.concat(compliancefilter?.divisions_list))
        console.log(compliancefilter.divisions_list, 'setCategoryList(compliancefilter && compliancefilter.categories_list && compliancefilter.categories_list.concat(compliancefilter?.divisions_list))')

        compliancefilter.divisions_list ? compliancefilter.divisions_list.map((items) => {

            let x = _.filter(compliancefilter.categories_list, { div_id: items.div_id })
            let y = []
            x.map((ele) => {
                let entity = {
                    bg_id: items.bg_id,
                    cat_id: ele.cat_id,
                    div_name: items.div_name,
                    le_id: items.le_id,
                    div_id: items.div_id,
                    cat_name: ele.cat_name,
                    category_name: `${items.div_name} - ${ele.cat_name}`
                }
                y.push(entity)
            })
            console.log(y, 'spositive')

            setCategoryList(y)

        }) : setCategoryList([])


    }
    console.log(remarks, "remarksremarks");
    const [remarksvalue, Setremarksvalue] = useState();
    const [page, setPage] = useState(1);
    const [currentDetails, setCurrentDetails] = useState([])
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const dispatch = useDispatch()
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    console.log(entityid, 'filterMentityidodalVisible')
    const [exitCollapse, setExitCollapse] = useState(false);

    const d = new Date();
    let month = d.getMonth();
    let year = d.getFullYear();

    // useEffect(() => {

    //     if (domain && domain.length > 0) {
    //         Setshowdata({
    //             ...showdata,
    //             domain: domain && domain.length > 0 && domain[0].d_id

    //         })
    //     }

    // }, [domain])


    console.log(monthPrview.yearvalue, 'monthPrview.yearvalue');
    console.log(filteredArrayState, 'filterArr');

    // useEffect(() => {
    //     if (checkBoxValue1.every((items) => items == 0)) {
    //         setFileUpload({})
    //     }
    // }, [checkBoxValue1])

    useEffect(() => {
        setFileUpload1(Object.keys(fileupload))
    }, [fileupload])

    // useEffect(() => {
    //     if (Object.keys(fileupload).length > 0) {
    //         let temp = []
    //         for (let i in fileupload) {
    //             temp.push({
    //                 "file_size": fileupload[i].file_size,
    //                 "file_name": fileupload[i].file_name,
    //                 "file_content": null,
    //                 "type": "",
    //                 "compliance_history_id": modalValue.compliance_history_id
    //             })
    //         }
    //     }
    // }, [fileupload]) // RemarksHistoryColumn
    const RemarksColumn = [
        {
            title: 'S.No',
            dataIndex: false,
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        {
            title: 'Description',
            dataIndex: 'r_description',
            key: 'r_description',
            width: '200px',
            ellipsis: true,
            render: (text, record) => <a onClick={() => {
                console.log(record, "recordrecord");
                // setModalData({
                //     ...modalData,
                //     password: text
                // })
                // setComplianceData[{
                //     ...compliancedata
                // }]
                // SetRemarks({
                //     ...remarks,
                //     remarks: record.r_description,
                // });
                updateRemarks[childId] = record.r_description
                setUpdateRemarks([...updateRemarks])
                checkBoxValue1[childId] = 1
                setCheckBoxValue1([...checkBoxValue1])
                setRemarksModal1(false)

            }}>{record.r_description}</a>,
        },
    ]

    const RemarksHistoryColumn = [
        {
            title: '#',
            dataIndex: false,
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        {
            title: 'Activity Date',
            dataIndex: 'activity_on',
            key: 'activity_on',
            width: '200px',
            // ellipsis: true,
            render: (text, record) => <span>{moment(record.activity_on).format('YYYY-MM-DD hh:mm:ss')}</span>,
        },
        {
            title: 'User Type',
            dataIndex: 'user_category_id',
            key: 'user_category_id',
            width: '200px',
            // ellipsis: true,
            render: (text, record) => <>{text == 1 || text == 3 ? 'Assignee' : text == 2 ? 'Concorrur' : 'Approver'}</>
            //     console.log(record, "recordrecord");
            //     // setModalData({
            //     //     ...modalData,
            //     //     password: text
            //     // })
            //     // setComplianceData[{
            //     //     ...compliancedata
            //     // }]
            //     // SetRemarks({
            //     //     ...remarks,
            //     //     remarks: record.r_description,
            //     // });
            //     updateRemarks[childId] = record.r_description
            //     setUpdateRemarks([...updateRemarks])
            //     setRemarksModal1(false)

            // }}>{record.r_description}</a>
        },
        {
            title: 'Reviewer Report',
            dataIndex: 'activity_on',
            key: 'activity_on',
            width: '200px',
            // ellipsis: true,
            render: (text, record) => <></>
            //     console.log(record, "recordrecord");
            //     // setModalData({
            //     //     ...modalData,
            //     //     password: text
            //     // })
            //     // setComplianceData[{
            //     //     ...compliancedata
            //     // }]
            //     // SetRemarks({
            //     //     ...remarks,
            //     //     remarks: record.r_description,
            //     // });
            //     updateRemarks[childId] = record.r_description
            //     setUpdateRemarks([...updateRemarks])
            //     setRemarksModal1(false)

            // }}>{record.r_description}</a>
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
            key: 'remarks',
            width: '350px',
            // ellipsis: true,
            render: (text, record) => <span>{record.remarks}</span>
        }
    ]
    useEffect(() => {
        dispatch({
            type: RESET_REMARK_HISTORY
        })
    }, [])
    useEffect(() => {
        if (remarksModal == true) {
            if (recallremarks && recallremarks.length > 0) {
                let temp = [];
                for (let i = 0; i < recallremarks.length; i++) {
                    if (recallremarks[i].r_description != '') {
                        temp.push(recallremarks[i])
                    }
                    Setremarksvalue(temp)
                }
                if (temp && temp.length == 0) {
                    Toaster.error("No Remarks Available")
                }
                else {
                    setRemarksModal1(true);
                    dispatch({
                        type: RESET_REMARK_HISTORY
                    })
                }
            }
        }
    }, [recallremarks, remarksModal])



    const Cancelmodal = () => {
        // setAddFormSubmit1(false)
        setShowValueModal(false);
        // setValueModal({
        //     ...valueModal,
        //     action: '',
        //     remarks: '',
        //     validity_date: '',
        //     next_due_date: ''
        // })
        // setEdit(false)

    }

    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') == null || localStorage.getItem('SelectedEntityid') == 'null') {

        } else {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
            if (localStorage.getItem('SelectedEntityid')) {
                setLegalentity(localStorage.getItem('SelectedEntityid'))
            }
        }
    }, [localStorage.getItem('SelectedEntityid'), compliancefilter, entityid])


    // useEffect(() => {
    //     if (localStorage.getItem('SelectedEntity') != "All Legal Entity") {
    //         setShow(true)
    //     }
    // }, [])

    useEffect(() => {
        if (entityid != 'null') {
            console.log('checkinnnnn')
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetEasyUploadFilters",
                        {
                            "le_id": entityid
                        }
                    ]
                }
            ]
            entityid != null && getFilterDataValues({
                payload: payload,
                paramid: paramid
            })

        }
    }, [localStorage.getItem('SelectedEntityid') != null, showdata.legal_entity])






    useEffect(() => {
        formValidator.current.showMessages()
        formValidator1.current.showMessages()

    }, [])
    const [fullscreen, setfullscreen] = useState(false);
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }

    const dates = new Date()

    const onShow = () => {
        setTimeout(() => {
            let x = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
            let monthBtn = document.getElementsByClassName('month-btn');
            console.log(monthBtn, 'monthBtn')
            // let temp = x[dates.getUTCMonth()]
            let a = document.getElementsByClassName(x[dates.getUTCMonth()])
            dispatch({
                type: EASY_FILTER_MODAl,
                payload: false
            })
            a[0].classList.add('active')
        }, 500)
        dispatch({
            type: EASY_FILTER_MODAl,
            payload: false
        })

    }

    const onsubmitData = () => {
        setFormShow(true)
        if (formValidator.current.allValid()) {
            // if ((complianceshowdata && complianceshowdata.max_year) < monthPrview.yearvalue) 
            setExitCollapse(true);
            dispatch({
                type: EASY_FILTER_MODAl,
                payload: false
            })
            setFilterTaskAcc(false);
            // setFilterTaskAcc
            setShow(true)

            setMonthPrview({
                yearvalue: moment().format('YYYY'),
                monthvalue: moment().format('MMM').toUpperCase()
            })

            const payload1 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetEasyUploadRecords",
                        {
                            "legal_entity_id": showdata.legal_entity,
                            "domain_id": showdata.domain ? Number(showdata.domain) : 0,
                            "division_id": showdata.division ? Number(showdata.division) : 0,
                            "category_id": showdata.category ? Number(showdata.category) : 0,
                            "unit_ids": showdata.unit ? showdata.unit : [0],
                            "frequency_id": showdata.frequency ? Number(showdata.frequency) : 0,
                            "task_category_id": showdata.task_category ? Number(showdata.task_category) : 0,
                            "task_sub_category_id": showdata.tasksub_category ? Number(showdata.tasksub_category) : 0,
                            "completion_date": `${moment().format('MMM').toUpperCase()}-${moment().format('YYYY')}`


                        }
                    ]
                }
            ]
            console.log(payload1, 'payload1');
            if (showdata.legal_entity != '') {
                getTabledata({
                    payload: payload1,
                    paramid: paramid
                })
                onShow()
            }
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetYearWiseRecords",
                        {
                            "legal_entity_id": showdata.legal_entity,
                            "domain_id": showdata.domain ? Number(showdata.domain) : 0,
                            "division_id": showdata.division ? Number(showdata.division) : 0,
                            "category_id": showdata.category ? Number(showdata.category) : 0,
                            "unit_ids": showdata.unit ? showdata.unit : [0],
                            "frequency_id": showdata.frequency ? Number(showdata.frequency) : 0,
                            "task_category_id": showdata.task_category ? Number(showdata.task_category) : 0,
                            "task_sub_category_id": showdata.tasksub_category ? Number(showdata.tasksub_category) : 0,
                            "year": moment().format('YYYY')
                        }
                    ]
                }
            ]
            console.log(payload, 'payload5555555555');
            if (showdata.legal_entity != '') {
                getShowdata({
                    payload: payload,
                    paramid: paramid
                })
            }
            // }

            setTimeout(() => {
                var ele = document.getElementsByName("chk");
                for (var i = 0; i < ele.length; i++) {
                    if (ele[i].type == "checkbox") ele[i].checked = false;
                }
            }, 500)
        }
    }

    console.log(complianceshowdata.task_status, 'complianceshowdata.task_status')

    const Monthwisechange = (monthType, e, monthVal) => {
        setmonthValue(monthType)
        let monthBtn = document.getElementsByClassName('month-btn');
        for (let i = 1; i <= monthBtn.length; i++) {
            (monthType != monthValue) && monthBtn[i - 1].classList.remove('active')

        }
        setIsSelectedMonth(true)


        e && e.preventDefault();
        // if ((complianceshowdata && complianceshowdata.max_year) < monthPrview.yearvalue) {
        const payload1 = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetEasyUploadRecords",
                    {
                        "legal_entity_id": showdata.legal_entity,
                        "domain_id": showdata.domain ? Number(showdata.domain) : 0,
                        "division_id": showdata.division ? Number(showdata.division) : 0,
                        "category_id": showdata.category ? Number(showdata.category) : 0,
                        "unit_ids": showdata.unit ? showdata.unit : [0],
                        "frequency_id": showdata.frequency ? Number(showdata.frequency) : 0,
                        "task_category_id": showdata.task_category ? Number(showdata.task_category) : 0,
                        "task_sub_category_id": showdata.tasksub_category ? Number(showdata.tasksub_category) : 0,
                        "completion_date": `${monthType}-${monthPrview.yearvalue}`

                    }
                ]
            }
        ]
        if (showdata.legal_entity != '') {
            getTabledata({
                payload: payload1,
                paramid: paramid
            })
        }
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetYearWiseRecords",
                    {
                        "legal_entity_id": showdata.legal_entity,
                        "domain_id": showdata.domain ? Number(showdata.domain) : 0,
                        "division_id": showdata.division ? Number(showdata.division) : 0,
                        "category_id": showdata.category ? Number(showdata.category) : 0,
                        "unit_ids": showdata.unit ? showdata.unit : [0],





                        "frequency_id": showdata.frequency ? Number(showdata.frequency) : 0,
                        "task_category_id": showdata.task_category ? Number(showdata.task_category) : 0,
                        "task_sub_category_id": showdata.tasksub_category ? Number(showdata.tasksub_category) : 0,
                        "year": `${monthPrview.yearvalue}`

                    }
                ]
            }
        ]
        if (showdata.legal_entity != '') {
            getShowdata({
                payload: payload,
                paramid: paramid
            })
            // }
        }
    }

    // useEffect(() => {
    //     if (isAuth && showdata.legal_entity!='') {
    //         const payload = [
    //             authtoken,
    //             {
    //                 "session_token": authtoken,
    //                 "request": [
    //                     "GetEasyUploadFilters",
    //                     {
    //                         "le_id": entityid
    //                     }
    //                 ]
    //             }
    //         ]
    //         if (showdata.legal_entity != '' && entityid!=null) {
    //             getFilterDataValues({
    //                 payload: payload,
    //                 paramid: paramid
    //             })
    //         }
    //     }
    // }, [isAuth, showdata.legal_entity])

    useEffect(() => {
        if (compliancefilter) {
            setDomain(compliancefilter && compliancefilter.domains_list)
            setfrequency(compliancefilter && compliancefilter.frequency_list)
            // setCategoryList(compliancefilter && compliancefilter.categories_list && compliancefilter.categories_list.concat(compliancefilter?.divisions_list))
            setDivision(compliancefilter && compliancefilter.divisions_list)
            setUnits(compliancefilter && compliancefilter.user_units)
            let listtasksub = _.filter(compliancefilter.task_sub_category_list, { task_category_id: Number(showdata.task_category) });
            setTasksubcategory(listtasksub)
            let uniq_task = _.uniqBy(compliancefilter && compliancefilter.task_category_list, function (e) {
                return e.task_category_id;
            });
            setTaskcategory(uniq_task)

        }
    }, [compliancefilter, showdata.task_category])


    console.log(compliancefilter, 'compliancefilter.domains_list');

    useEffect(() => {
        validator.current.showMessages()
        validator1.current.showMessages()
        validator2.current.showMessages()
    }, [])

    const handleCancel = () => {
        setRemarksModal1(false)
    };

    const passwordcancel = () => {
        setModaVisible(false)
        Setpassvisible(false);
        setModalData({
            ...modalData,
            password: ""
        })
    }


    const showRemarksModal = (recordChildId) => {
        // console.log(item,'item');
        setChildId(recordChildId)
        GetRemarksData({
            payload:
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetRemarksData",
                            {}
                        ]
                    }
                ],
            paramid: paramid
        })
    };

    const onInputChange = e => {
        const { value } = e.target;
        const re = /^[A-Z0-9a-z _ .,-]+$/;
        if (value === "" || re.test(value)) {
            SetRemarks(value)
        }
    }

    useEffect(() => {


    }, [showdata.unit])

    const onInputChange1 = e => {
        const { value } = e.target;
        const re = /^[A-Z0-9a-z- _.,/)(]+$/;
        if (value === "" || re.test(value)) {
            setDocumentNo(value)
        }
    }

    const homescreen = () => {
        let getUrlDetails = homepage;
        let tempval = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_url;
        let tempkey = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_key;
        localStorage.setItem('formkey', tempkey);
        historynew.push(tempval);
        // window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }


    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        setSelectedKeys(e.target.value ? [e.target.value] : [])
        confirm({ closeDropdown: false });
        // setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };



    const onMonthPrvChange = () => {
        setFilteredArray([])
        setmonthValue(moment().format('MMM').toUpperCase())
        setfilteredArrayState([])
        let a = document.getElementsByClassName(monthValue)
        if (a && a.length > 0) {
            a[0].classList.remove('active')
        }

        if ((complianceshowdata && complianceshowdata.max_year - 7) < monthPrview.yearvalue) {

            setMonthPrview({
                ...monthPrview,
                yearvalue: monthPrview.yearvalue - 1
            })

            const payload1 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetEasyUploadRecords",
                        {
                            "legal_entity_id": showdata.legal_entity,
                            "domain_id": showdata.domain ? Number(showdata.domain) : 0,
                            "division_id": showdata.division ? Number(showdata.division) : 0,
                            "category_id": showdata.category ? Number(showdata.category) : 0,
                            "unit_ids": showdata.unit ? showdata.unit : [0],
                            "frequency_id": showdata.frequency ? Number(showdata.frequency) : 0,
                            "task_category_id": showdata.task_category ? Number(showdata.task_category) : 0,
                            "task_sub_category_id": showdata.tasksub_category ? Number(showdata.tasksub_category) : 0,
                            "completion_date": `${monthPrview.monthvalue}-${monthPrview.yearvalue - 1}`
                        }
                    ]
                }
            ]
            if (showdata.legal_entity != '') {
                getTabledata({
                    payload: payload1,
                    paramid: paramid
                })
            }
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetYearWiseRecords",
                        {
                            "legal_entity_id": showdata.legal_entity,
                            "domain_id": showdata.domain ? Number(showdata.domain) : 0,
                            "division_id": showdata.division ? Number(showdata.division) : 0,
                            "category_id": showdata.category ? Number(showdata.category) : 0,
                            "unit_ids": showdata.unit ? showdata.unit : [0],
                            "frequency_id": showdata.frequency ? Number(showdata.frequency) : 0,
                            "task_category_id": showdata.task_category ? Number(showdata.task_category) : 0,
                            "task_sub_category_id": showdata.tasksub_category ? Number(showdata.tasksub_category) : 0,
                            "year": `${Number(monthPrview.yearvalue) - 1}`
                        }
                    ]
                }
            ]
            if (showdata.legal_entity != '') {
                getShowdata({
                    payload: payload,
                    paramid: paramid
                })
            }

        }
    }

    const onMonthNxtChange = () => {
        console.log(monthPrview.yearvalue, 'monthPrview.yearvalue');
        setmonthValue(moment().format('MMM').toUpperCase())
        setFilteredArray([])
        setfilteredArrayState([])
        let a = document.getElementsByClassName(monthValue)
        if (a && a.length > 0) {
            a[0].classList.remove('active')
        }

        if ((complianceshowdata && complianceshowdata.max_year) > monthPrview.yearvalue || complianceshowdata && complianceshowdata.max_year == '') {
            // yearvalue: moment().format('YYYY'),
            // monthvalue: moment().format('MMM').toUpperCase()
            setMonthPrview({
                ...monthPrview,
                yearvalue: Number(monthPrview.yearvalue) + 1
            })
            const payload1 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetEasyUploadRecords",
                        {
                            "legal_entity_id": showdata.legal_entity,
                            "domain_id": showdata.domain ? Number(showdata.domain) : 0,
                            "division_id": showdata.division ? Number(showdata.division) : 0,
                            "category_id": showdata.category ? Number(showdata.category) : 0,
                            "unit_ids": showdata.unit ? showdata.unit : [0],
                            "frequency_id": showdata.frequency ? Number(showdata.frequency) : 0,
                            "task_category_id": showdata.task_category ? Number(showdata.task_category) : 0,
                            "task_sub_category_id": showdata.tasksub_category ? Number(showdata.tasksub_category) : 0,
                            "completion_date": `${monthPrview.monthvalue}-${monthPrview.yearvalue + 1}`
                        }
                    ]
                }
            ]
            console.log(payload1, 'payload1');
            if (showdata.legal_entity != '') {
                getTabledata({
                    payload: payload1,
                    paramid: paramid
                })
            }
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetYearWiseRecords",
                        {
                            "legal_entity_id": showdata.legal_entity,
                            "domain_id": showdata.domain ? Number(showdata.domain) : 0,
                            "division_id": showdata.division ? Number(showdata.division) : 0,
                            "category_id": showdata.category ? Number(showdata.category) : 0,
                            "unit_ids": showdata.unit ? showdata.unit : [0],
                            "frequency_id": showdata.frequency ? Number(showdata.frequency) : 0,
                            "task_category_id": showdata.task_category ? Number(showdata.task_category) : 0,
                            "task_sub_category_id": showdata.tasksub_category ? Number(showdata.tasksub_category) : 0,
                            "year": `${monthPrview.yearvalue + 1}`
                        }
                    ]
                }
            ]
            if (showdata.legal_entity != '') {
                getShowdata({
                    payload: payload,
                    paramid: paramid
                })
            }
        }
    }

    useEffect(() => {
        let x = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
        // let monthBtn = document.getElementsByClassName('month-btn');
        console.log(monthValue, 'ssssxacx')
        // let temp = x[dates.getUTCMonth()]
        let a = document.getElementsByClassName(monthValue)
        if (a && a.length > 0) {
            a[0].classList.add('active')
        }
        // if(isSelectedMonth){
        //     a[0].classList.remove('active')
        // }


    }, [onMonthPrvChange, onMonthNxtChange, monthValue])

    const uploadFileFormat = (size, name, content, type, fn) => {
        var result = {
            'file_size': parseInt(size),
            'file_name': name,
            'file_content': content,
            'type': fn
        };

        return result;
    }

    const modalcancel = () => {
        setModalDatas(false)
        setModalDatas1(false)
    }


    const convert_to_base64 = (file, name, size, filest, callback) => {
        var reader = new FileReader();
        if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
            var binary = "";
            reader.onload = (fileContent) => {
                // e.preventDefault()
                console.log(fileContent.target.result, 'pppppppsspsps');
                var bytes = new Uint8Array(fileContent.target.result);
                var length = bytes.byteLength;
                for (var i = 0; i < length; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }
                callback(EncryptDecrypt.encryptdata(binary), name, size);

            }
            // reader.onload = function (readerEvt) {
            //     var bytes = new Uint8Array(readerEvt.target.result);
            //     var length = bytes.byteLength;
            //     for (var i = 0; i < length; i++) {
            //         binary += String.fromCharCode(bytes[i]);
            //     }
            //     callback(EncryptDecrypt.encryptdata(binary), name, size);
            // }
            reader.readAsArrayBuffer(filest);
        } else {
            reader.onload = function (readerEvt) {
                var binaryString = readerEvt.target.result;
                callback(EncryptDecrypt.encryptdata(binaryString), name, size);
            };
            reader.readAsBinaryString(filest);
        }
    }

    const filterHandle = () => {
        setFilterTaskAcc(true);
        dispatch({
            type: EASY_FILTER_MODAl,
            payload: true
        })
    };
    const setcancelFilter = () => {
        // setFilterModalVisible(false); 
        dispatch({
            type: EASY_FILTER_MODAl,
            payload: false
        })
        setFilterTaskAcc(false);
    };

    // const SavepastRecordOnChange = (e) => {
    //     // if (e.target.name === 'file') {
    //     var results = [];
    //     const fileRegex = /^[a-zA-Z0-9-_& ,.+#()[-\]{}$^=%@]*$/
    //     let files = e.fileList
    //     let file_max_size = 26214400
    //     for (var i = 0; i < files.length; i++) {
    //         var file = files[i];
    //         var file_name = file.name;
    //         // const f_name = file_name.substring(0, file_name.lastIndexOf('.'));
    //         // if (fileRegex.test(f_name) === false) {
    //         //     return;
    //         // }
    //         let file_size = file[i].size;
    //         // if (file_size > file_max_size) {
    //         //     toast.error("Maximum filesize limit exceed")
    //         //     return;
    //         // }
    //         if (file) {
    //             convert_to_base64(file, file_name, file_size, function (file_content, name, size) {
    //                 var fN = name.substring(0, name.lastIndexOf('.'));
    //                 var fE = name.substring(name.lastIndexOf('.') + 1);
    //                 var uniqueId = Math.floor(Math.random() * 90000) + 10000;
    //                 var f_Name = fN + '-' + uniqueId + '.' + fE;
    //                 var type =""

    //                 var result = uploadFileFormat(file_size, f_Name, file_content,type);
    //                 setFileData(result)
    //                 results.push(result);


    //                 let temp1 = fileupload
    //                 let temp = []
    //                 if (results && results.length == 1) {
    //                     for (let i in results) {
    //                         temp1.push(results[i])
    //                     }
    //                     setFileUpload([...temp1])
    //                 }
    //                 else {
    //                     for (let i in results) {
    //                         temp.push(results[i])
    //                     }
    //                     setFileUpload([...temp])
    //                 }


    //                 const filePayload = [
    //                     authtoken,
    //                     {
    //                         "session_token": authtoken,
    //                         "request": [
    //                             "UploadComplianceTask_temp_upload",
    //                             {
    //                                 "le_id": showdata.legal_entity,
    //                                 "c_id": showdata.country ? Number(showdata.country) : 0,
    //                                 "d_id": showdata.domain ? Number(showdata.domain) : 0,
    //                                 "u_id": showdata.unit ? Number(showdata.unit) : 0,
    //                                 "start_date": "11-Jan-2023",
    //                                 "file_info": results,
    //                                 "compliance_history_id": 4
    //                             }

    //                         ]
    //                     }
    //                 ]
    //                 EasyUploadFile({
    //                     payload: filePayload,
    //                     paramid: paramid
    //                 })

    //             });
    //         }
    //     }
    //     // }
    //     React.forwardRef(({ onClick, ...rest }, ref) => {
    //         return (
    //             <>
    //                 <div className="form-check pb-5" style={{ backgroundColor: '' }}>
    //                     <input
    //                         type="checkbox"
    //                         className="form-check-input"
    //                         style={{ height: '20px', width: '20px' }}
    //                         ref={ref}
    //                         onClick={onClick}
    //                         {...rest}
    //                     />
    //                     <label className="form-check-label" id="booty-check" />
    //                 </div>
    //             </>
    //         )
    //     })
    // }
    // const SavepastRecordOnChange = (e, items, tempid, UID) => {
    //     documentRemarks[items.childId] = 1
    //     setDocumentRemarks([...documentRemarks])
    //     if (e.target.name === 'file') {
    //         var results = [];
    //         const fileRegex = /^[a-zA-Z0-9-_& ,.+#()[-\]{}$^=%@]*$/
    //         let FILE_TYPE = [
    //             "doc", "docx", "rtf", "pdf", "txt", "png", "jpeg", "gif", "csv", "xls", "xlsx",
    //             "rar", "tar", "gz", "ppt", "pptx", "jpg", "bmp", "odt", "odf", "ods", "xml"
    //         ];
    //         let files = e.target.files
    //         let file_max_size = 26214400
    //         for (var i = 0; i < files.length; i++) {
    //             var file = files[i];
    //             var file_name = file.name;
    //             const f_name = file_name.substring(0, file_name.lastIndexOf('.'));
    //             if (fileRegex.test(f_name) === false) {
    //                 toast.error("File Name have not allowed special charaters")

    //                 return;
    //             }
    //             else {
    //                 var file_extension = file_name.substring(file_name.lastIndexOf('.') + 1).toLowerCase();
    //                 if (FILE_TYPE.includes(file_extension) == true) {
    //                     let file_size = file.size;
    //                     if (file_size > file_max_size) {
    //                         toast.error("Maximum filesize limit exceed")
    //                         return false;
    //                     } else {
    //                         if (file) {
    //                             convert_to_base64(file, file_name, file_size, function (file_content, name, size) {
    //                                 var fN = name.substring(0, name.lastIndexOf('.'));
    //                                 var fE = name.substring(name.lastIndexOf('.') + 1);
    //                                 var uniqueId = Math.floor(Math.random() * 90000) + 10000;
    //                                 var f_Name = fN + '-' + uniqueId + '.' + fE;
    //                                 var type = ""
    //                                 var result = uploadFileFormat(size, f_Name, file_content, type);
    //                                 setFileData(result)
    //                                 results.push(result);

    //                                 console.log(results, 'FileResults')


    //                                 let temp1 = []
    //                                 let temp = []
    //                                 let setTemp = []
    //                                 // if (results && results.length == 1) { 

    //                                 console.log(fileupload1.indexOf(UID.toString()), 'fileupload1.includes(UID)')

    //                                 if (fileupload1.indexOf(UID.toString()) != -1) {
    //                                     setTemp = fileupload[UID]

    //                                     console.log(setTemp, 'SEBJHBDJUJ')

    //                                     for (let i in results) {
    //                                         setTemp.push(results[i])
    //                                     }
    //                                     setFileUpload({
    //                                         ...fileupload,
    //                                         [UID]: ([...new Set(setTemp)])
    //                                     })

    //                                 } else {
    //                                     console.log(setTemp, 'SEBJHddddddddddddddddddddddBDJUJ')

    //                                     for (let i in results) {
    //                                         temp1.push(results[i])
    //                                     }

    //                                     setFileUpload({
    //                                         ...fileupload,
    //                                         [UID]: ([...new Set(temp1)])
    //                                     })

    //                                 }
    //                                 // setTimeout(() => {

    //                                 // if (is_invalid == false) {
    //                                 // if (Object.keys(fileupload).length > 0) {

    //                                 // }

    //                                 // setFileUpload({
    //                                 //    [UID] : [...temp1]
    //                                 //     })
    //                                 // }
    //                                 // }, 5000)
    //                                 // }
    //                                 // else {
    //                                 //     for (let i in results) {
    //                                 //         temp.push(results[i])
    //                                 //     }
    //                                 //     setTimeout(() => {
    //                                 //         if (is_invalid == false) {
    //                                 //             // setFileUpload([...temp])
    //                                 //         }
    //                                 //     }, 5000)
    //                                 // }
    //                                 console.log(temp1, 'tempSek')
    //                                 // if (temp1.length == 0 && updateRemarks[items.childId] == null) {
    //                                 //     checkBoxValue1[items.childId] = 0
    //                                 //     setCheckBoxValue1([...checkBoxValue1])
    //                                 // }


    //                                 // console.log(filePayload, 'filePayload');

    //                             });
    //                         }
    //                     }
    //                 } else {
    //                     Toaster.error('Invalid File Format')
    //                 }
    //             }
    //         }
    //     }
    //     React.forwardRef(({ onClick, ...rest }, ref) => {
    //         return (
    //             <>
    //                 <div className="form-check pb-5">
    //                     <input
    //                         type="checkbox"
    //                         className="form-check-input"
    //                         style={{ height: '20px', width: '20px' }}
    //                         ref={ref}
    //                         onClick={onClick}
    //                         {...rest}
    //                     />
    //                     <label className="form-check-label" id="booty-check" />
    //                 </div>
    //             </>
    //         )
    //     })
    // }

    const props = {
        name: "file",
        multiple: true,
        action: null,
        defaultFileList: 0,
        listType: 0
    };


    const handlePasting = (e, regex) => {

        var regex = regex;
        var key = e.clipboardData.getData('text')
        if (!regex.test(key)) {
            e.preventDefault();
            Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
            return false;
        }
        // e.clipboardData.getData('text')
        // Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
        // e.preventDefault();
        // return false;
    }
    const download = (item) => {
        const downloadpayload =
            [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "DownloadFile_temp",
                        {
                            "le_id": showdata.legal_entity,
                            "c_id": 1,
                            "d_id": valueForFileUpload.d_id,
                            "u_id": valueForFileUpload.u_id,
                            "start_date": valueForFileUpload.start_date,
                            "compliance_history_id": valueModal.compliance_history_id,
                            "upload_type": 2,

                            "file_name": item,
                        }
                    ]
                }
            ]
        //     getdownloadpath({
        //     payload: downloadpayload,
        //     paramid: paramid
        // });
        // if (item) {
        //     window.open('https:/' + item);
        // }
    }
    const Remove = (index) => {

        let temp = fileupload
        for (let i in fileupload) {
            const removepayload =
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "RemoveFile_temp",
                            {
                                "le_id": showdata.legal_entity,
                                "c_id": 1,
                                "selected_task_info": [
                                    {
                                        "d_id": valueForFileUpload.d_id,
                                        "u_id": valueForFileUpload.u_id,
                                        "start_date": valueForFileUpload.start_date,
                                        "compliance_history_id": valueModal.compliance_history_id,
                                        "file_info": [
                                            {
                                                "file_size": fileupload.file_size,
                                                "file_name": fileupload.file_name,
                                                "file_content": null
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            removedocument({
                payload: removepayload,
                paramid: paramid
            })


            if (fileupload[i]['file_name'] == index) {
                fileupload.splice(i, 1)
            }

        }
        // setFileUpload([...fileupload])

    }
    console.log(compliancetabledata, 'compliancetabledata');

    useEffect(() => {
        if (compliancetabledata && compliancetabledata.comp_list && compliancetabledata.comp_list.length > 0) {
            let uniqueObjArray = [...new Map(compliancetabledata.comp_list && compliancetabledata.comp_list.length > 0 && compliancetabledata.comp_list.map((item) => [item["unit_name"], item])).values()];
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.unit_name
            })
            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(compliancetabledata && compliancetabledata.comp_list && compliancetabledata.comp_list.length > 0 && compliancetabledata.comp_list, { unit_name: uniqueIds[i] })
                tempArr.push(filteredArr)
            }
            setFilteredArray([...filteredArray, tempArr])
        }
    }, [compliancetabledata.comp_list])

    useEffect(() => {
        if (filteredArray && filteredArray.length > 0) {
            filteredArray && filteredArray.map((item, i) => {
                return (
                    setfilteredArrayState(item)
                )
            })
        }
    }, [filteredArray])

    console.log(compliancetabledata, 'compliancetabledata');

    useEffect(() => {
        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (compliancetabledata && compliancetabledata.comp_list) {
            const ab = compliancetabledata && compliancetabledata.comp_list.length > 0 && compliancetabledata.comp_list.map(function (item) {
                if ((array.indexOf(item.unit_name) < 0))
                    if (item.unit_name != '') {
                        array.push(item.unit_name)
                    }
            })
            array.map((data, i) => {
                let temp = [];
                let entityArray = [];
                let datavalue = [];
                if (data) {
                    compliancetabledata && compliancetabledata.comp_list.length > 0 && compliancetabledata.comp_list.map((child, childIndex) => {
                        console.log(child, 'subchild')
                        if (data == child.unit_name) {
                            entityArray = {
                                "act_name": child.act_name,
                                "approval_documents": child.approval_documents,
                                "approve_status": child.approve_status,
                                "compliance_history_id": child.compliance_history_id,
                                "compliance_task": child.compliance_task,
                                "concurrence_status": child.concurrence_status,
                                "concurrer_documents": child.concurrer_documents,
                                "documents": child.documents,
                                "domain_id": child.domain_id,
                                "due_date": child.due_date,
                                "extn_due_date": child.extn_due_date,
                                "recall_status": child.recall_status,
                                "remarks": child.remarks,
                                "start_date": child.start_date,
                                "task_group_name": child.task_group_name,
                                "task_status": child.task_status,
                                "task_sub_group_name": child.task_sub_group_name,
                                "tz_name": child.tz_name,
                                "unit_id": child.unit_id,
                                "unit_name": child.unit_name,
                                'childId': childIndex,

                                'parentId': i,
                                'is_not_applicable': checkBoxValue[childIndex]
                            }
                            temp.push(entityArray)
                            updateRemarks[childIndex] = null //child.remarks
                            checkBoxValue[childIndex] = child.is_not_applicable
                            checkBoxValue1[childIndex] = 0
                            documentRemarks[childIndex] = 0
                        }
                    })
                }
                datavalue = {
                    index: i,
                    parent: data,
                    child: temp
                }
                finalarray.push(datavalue)
            })
            Setfinaldata(finalarray)
            setUpdateRemarks([...updateRemarks])
            setCheckBoxValue([...checkBoxValue])
            setCheckBoxValue1([...checkBoxValue1])
            setDocumentRemarks([...documentRemarks])
        }
    }, [compliancetabledata.comp_list])

    console.log(finaldata, 'finaldata');

    console.log(checkBoxValue, 'checkBoxValue');

    const downloadselectAllCheckBox = (checked) => {
        var ele = document.getElementsByName("checked");
        let tempAllIds = [];
        if (checked == true) {
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == "checkbox") {
                    ele[i].checked = true;
                }
            }
            let tempArr = []
            let ids =
                compliancetabledata &&
                compliancetabledata.comp_list &&
                compliancetabledata.comp_list.length > 0 &&
                compliancetabledata.comp_list.map((item) => {
                    console.log(item, 'itemitem');
                    tempArr.push({
                        "compliance_history_id": item.compliance_history_id,
                        "remarks": updateRemarks[item.childId] ? updateRemarks[item.childId] : item.remarks,
                        "documents": [],
                        "uploaded_documents": null,
                        "decreased_size": 0,
                        "removed_documents": [],
                        "is_not_applicable": checkBoxValue[item.childId] ? checkBoxValue[item.childId] : item.is_not_applicable
                    })
                    return item.compliance_history_id;
                });

            // tempArr.push({
            //     "compliance_history_id": item.compliance_history_id,
            //     "remarks": updateRemarks[item.childId],
            //     "documents": [],
            //     "uploaded_documents": null,
            //     "decreased_size": 0,
            //     "removed_documents": [],
            //     "is_not_applicable": checkBoxValue[item.childId]
            // })
            console.log(tempArr, 'tempArr');
            setPayloadArray(tempArr)

            setComplianceID(ids);

            compliancetabledata && compliancetabledata.comp_list.length > 0 && compliancetabledata.comp_list.map((child, childIndex) => {
                console.log(child, 'dchildissue')

                checkBoxValue1[childIndex] = 1

            })

            setCheckBoxValue1([...checkBoxValue1])

        } else {
            var ele = document.getElementsByName("checked");
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == "checkbox") ele[i].checked = false;
            }
            setPayloadArray([])
            compliancetabledata && compliancetabledata.comp_list.length > 0 && compliancetabledata.comp_list.map((child, childIndex) => {

                checkBoxValue1[childIndex] = 0

            })

            setCheckBoxValue1([...checkBoxValue1])

            setComplianceID([]);
        }
    };
    const downloadcheckBoxOnChange = (e, item) => {

        // if(e.target.checked == false){

        // }
        if (e == true) {
            checkBoxValue1[item.childId] = 1
            setCheckBoxValue1([...checkBoxValue1])
            let tempArr = [];
            if (payloadArray.length > 0) {
                for (let i in payloadArray) {
                    if (payloadArray[i].compliance_history_id === item.compliance_history_id) {
                        payloadArray.splice(i, 1)
                    }
                }
            }
            tempArr.push({
                "compliance_history_id": item.compliance_history_id,
                "remarks": updateRemarks[item.childId],
                "documents": [],
                "uploaded_documents": null,
                "decreased_size": 0,
                "removed_documents": [],
                "is_not_applicable": checkBoxValue[item.childId]
            })

            setPayloadArray([...payloadArray, ...tempArr])
        }
        else {
            checkBoxValue1[item.childId] = 0
            setCheckBoxValue1([...checkBoxValue1])
            updateRemarks[item.childId] = ''
            setUpdateRemarks([...updateRemarks])
            for (let i in payloadArray) {
                if (payloadArray[i].compliance_history_id === item.compliance_history_id) {
                    payloadArray.splice(i, 1)
                }
            }
            setPayloadArray([...payloadArray])
            console.log('fileeeeeeeeeeeeeeeeeeeeeee')
            setFileUpload({
                ...fileupload,
                [item.compliance_history_id]: []
            })

        }


        let temp = complianceID;
        let tempIds = [];
        if (temp.includes(Number(item.compliance_history_id)) == true) {
            for (let i in temp) {
                if (temp[i] == Number(item.compliance_history_id)) {
                    temp.splice(i, 1);
                }
            }
            setComplianceID([...temp]);
        } else {
            tempIds.push(Number(item.compliance_history_id));
            setComplianceID([...complianceID, ...tempIds]);
        }
    };

    console.log(payloadArray, 'payloadArray');

    // useEffect(()=>{
    //     if(payloadArray&& payloadArray.length > 0){
    //         let isRemarksEmpty = payloadArray.some(element => Object.values(element).some(val => val === null || val === ""))
    //     }

    // },[payloadArray])

    useEffect(() => {
        if (payloadArray && payloadArray.length > 0) {
            console.log(('hiiii'));
            function hasNull(element, index, array) {
                return element.remarks === null || element.remarks === '' || element.remarks === undefined;
            }
            setIsRemarksEmpty(payloadArray.some(hasNull))
        }
    }, [payloadArray])

    console.log(isRemarksEmpty, 'isRemarksEmpty');
    console.log(payloadArray, 'payloadArray');

    const uncheckApp = () => {

        document.getElementById('NAall').checked = false


    }


    // Final Submit
    const finalSubmit = () => {

        setDownloadClick(true)

        if (checkBoxValue && checkBoxValue.length > 0) {
            if (updateRemarks && updateRemarks.length > 0) {
                let temp = false

                // if (isRemarksEmpty === false) {
                for (let i in checkBoxValue1) {
                    if (checkBoxValue1[i] == 1) {
                        if (updateRemarks[i] == "" || updateRemarks[i] == null) {
                            temp = false
                            break
                        } else {
                            temp = true
                        }
                    }
                }
                if (checkBoxValue.includes(1)) {
                    if (temp) {

                        Swal.fire({
                            title: 'Are You Sure?',
                            icon: 'info',
                            text: 'This occurrence of task has been marked as Not applicable. Once approved, it will be shown in Task Recall process only',
                            showCancelButton: true,
                            confirmButtonText: 'Yes',
                            cancelButtonText: 'No',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                if (formValidator1.current.allValid()) {


                                    const pay = [
                                        authtoken,
                                        {
                                            "session_token": authtoken,
                                            "request": [
                                                "SaveEasyUploadTask",
                                                {
                                                    "selected_compliance": payloadArray,
                                                    "legal_entity_id": showdata.legal_entity
                                                }
                                            ]
                                        }
                                    ]
                                    const payload1 = [
                                        authtoken,
                                        {
                                            "session_token": authtoken,
                                            "request": [
                                                "GetEasyUploadRecords",
                                                {
                                                    "legal_entity_id": showdata.legal_entity,
                                                    "domain_id": showdata.domain ? Number(showdata.domain) : 0,
                                                    "division_id": showdata.division ? Number(showdata.division) : 0,
                                                    "category_id": showdata.category ? Number(showdata.category) : 0,
                                                    "unit_ids": showdata.unit ? showdata.unit : [0],

                                                    "frequency_id": showdata.frequency ? Number(showdata.frequency) : 0,
                                                    "task_category_id": showdata.task_category ? Number(showdata.task_category) : 0,
                                                    "task_sub_category_id": showdata.tasksub_category ? Number(showdata.tasksub_category) : 0,
                                                    "completion_date": `${monthValue}-${monthPrview.yearvalue}`

                                                }
                                            ]
                                        }
                                    ]
                                    const payload2 = [
                                        authtoken,
                                        {
                                            "session_token": authtoken,
                                            "request": [
                                                "GetYearWiseRecords",
                                                {
                                                    "legal_entity_id": showdata.legal_entity,
                                                    "domain_id": showdata.domain ? Number(showdata.domain) : 0,
                                                    "division_id": showdata.division ? Number(showdata.division) : 0,
                                                    "category_id": showdata.category ? Number(showdata.category) : 0,
                                                    "unit_ids": showdata.unit ? showdata.unit : [0],
                                                    "frequency_id": showdata.frequency ? Number(showdata.frequency) : 0,
                                                    "task_category_id": showdata.task_category ? Number(showdata.task_category) : 0,
                                                    "task_sub_category_id": showdata.tasksub_category ? Number(showdata.tasksub_category) : 0,
                                                    "year": `${monthPrview.yearvalue}`

                                                }
                                            ]
                                        }
                                    ]
                                    setTimeout(() => {

                                        uncheckApp()
                                    }, 500)
                                    saveuploadStatus({
                                        payload: pay,
                                        paramid: paramid,
                                        payload1: payload1,
                                        payload2: payload2,
                                        setCheckBoxValue1: setCheckBoxValue1
                                    })

                                    // if (showdata.legal_entity != '') {
                                    //     getTabledata({
                                    //         payload: payload1,
                                    //         paramid: paramid
                                    //     })
                                    // }

                                    // if (showdata.legal_entity != '') {
                                    //     getShowdata({
                                    //         payload: payload,
                                    //         paramid: paramid
                                    //     })
                                    // }
                                }


                            }
                        });
                    } else {
                        Toaster.error('Please enter remarks')
                    }
                } else {

                    if (temp) {
                        document.getElementById('NAall').checked = false

                        const pay = [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request": [
                                    "SaveEasyUploadTask",
                                    {
                                        "selected_compliance": payloadArray,
                                        "legal_entity_id": showdata.legal_entity
                                    }
                                ]
                            }
                        ]
                        const payload1 = [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request": [
                                    "GetEasyUploadRecords",
                                    {
                                        "legal_entity_id": showdata.legal_entity,
                                        "domain_id": showdata.domain ? Number(showdata.domain) : 0,
                                        "division_id": showdata.division ? Number(showdata.division) : 0,
                                        "category_id": showdata.category ? Number(showdata.category) : 0,
                                        "unit_ids": showdata.unit ? showdata.unit : [0],

                                        "frequency_id": showdata.frequency ? Number(showdata.frequency) : 0,
                                        "task_category_id": showdata.task_category ? Number(showdata.task_category) : 0,
                                        "task_sub_category_id": showdata.tasksub_category ? Number(showdata.tasksub_category) : 0,
                                        "completion_date": `${monthValue}-${monthPrview.yearvalue}`

                                    }
                                ]
                            }
                        ]
                        const payload2 = [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request": [
                                    "GetYearWiseRecords",
                                    {
                                        "legal_entity_id": showdata.legal_entity,
                                        "domain_id": showdata.domain ? Number(showdata.domain) : 0,
                                        "division_id": showdata.division ? Number(showdata.division) : 0,
                                        "category_id": showdata.category ? Number(showdata.category) : 0,
                                        "unit_ids": showdata.unit ? showdata.unit : [0],
                                        "frequency_id": showdata.frequency ? Number(showdata.frequency) : 0,
                                        "task_category_id": showdata.task_category ? Number(showdata.task_category) : 0,
                                        "task_sub_category_id": showdata.tasksub_category ? Number(showdata.tasksub_category) : 0,
                                        "year": `${monthPrview.yearvalue}`

                                    }
                                ]
                            }
                        ]
                        saveuploadStatus({
                            payload: pay,
                            paramid: paramid,
                            payload1: payload1,
                            payload2: payload2,
                            setCheckBoxValue1: setCheckBoxValue1

                        })

                        // if (showdata.legal_entity != '') {
                        //     getTabledata({
                        //         payload: payload1,
                        //         paramid: paramid
                        //     })
                        // }

                        // if (showdata.legal_entity != '') {
                        //     getShowdata({
                        //         payload: payload,
                        //         paramid: paramid
                        //     })
                        // }
                    } else {
                        Toaster.error("Please enter remarks")
                    }
                }
            }
            else {
                Toaster.error("Please enter remarks")
            }
            // }
        }
        else {
            // setAddFormSubmit1(true)
            if (updateRemarks && updateRemarks.length > 0) {
                if (isRemarksEmpty === false) {
                    const pay = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "SaveEasyUploadTask",
                                {
                                    "selected_compliance": payloadArray,
                                    "legal_entity_id": showdata.legal_entity
                                }
                            ]
                        }
                    ]
                    const payload1 = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetEasyUploadRecords",
                                {
                                    "legal_entity_id": showdata.legal_entity,
                                    "domain_id": showdata.domain ? Number(showdata.domain) : 0,
                                    "division_id": showdata.division ? Number(showdata.division) : 0,
                                    "category_id": showdata.category ? Number(showdata.category) : 0,
                                    "unit_ids": showdata.unit ? showdata.unit : [0],
                                    "frequency_id": showdata.frequency ? Number(showdata.frequency) : 0,
                                    "task_category_id": showdata.task_category ? Number(showdata.task_category) : 0,
                                    "task_sub_category_id": showdata.tasksub_category ? Number(showdata.tasksub_category) : 0,
                                    "completion_date": `${monthValue}-${monthPrview.yearvalue}`

                                }
                            ]
                        }
                    ]
                    const payload2 = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetYearWiseRecords",
                                {
                                    "legal_entity_id": showdata.legal_entity,
                                    "domain_id": showdata.domain ? Number(showdata.domain) : 0,
                                    "division_id": showdata.division ? Number(showdata.division) : 0,
                                    "category_id": showdata.category ? Number(showdata.category) : 0,
                                    "unit_ids": showdata.unit ? showdata.unit : [0],

                                    "frequency_id": showdata.frequency ? Number(showdata.frequency) : 0,
                                    "task_category_id": showdata.task_category ? Number(showdata.task_category) : 0,
                                    "task_sub_category_id": showdata.tasksub_category ? Number(showdata.tasksub_category) : 0,
                                    "year": `${monthPrview.yearvalue}`

                                }
                            ]
                        }
                    ]
                    saveuploadStatus({
                        payload: pay,
                        paramid: paramid,
                        payload1: payload1,
                        payload2: payload2,
                        setCheckBoxValue1: setCheckBoxValue1
                    })
                    // if (showdata.legal_entity != '') {
                    //     getTabledata({
                    //         payload: payload1,
                    //         paramid: paramid
                    //     })
                    // }

                    // if (showdata.legal_entity != '') {
                    //     getShowdata({
                    //         payload: payload,
                    //         paramid: paramid
                    //     })
                    // }
                }
                else {
                    Toaster.error("Please enter remarks")
                }
            }
        }
    }
    const selectAllCheckbox = (checked) => {
        var ele = document.getElementsByName("chk");
        let tempAllIds = [];
        if (checked == true) {
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == "checkbox") {
                    ele[i].checked = true;
                    checkBoxValue[i] = 1
                }
            }
            setCheckBoxValue([...checkBoxValue])
        } else {
            var ele = document.getElementsByName("chk");
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == "checkbox") ele[i].checked = false;
                checkBoxValue[i] = 0
            }
            setCheckBoxValue([...checkBoxValue])
        }
    };

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != "All Legal Entity") {
            Setshowdata({
                ...showdata,
                legal_entity: localStorage.getItem('SelectedEntityid')
            })
        }
    }, [localStorage.getItem('SelectedEntity') != "All Legal Entity"])


    // console.log(domain.length,'dommm');



    return (
        <div className="page-wrapper" id='page-wrapper'>
            <div className='back-to-top'>
                <ScrollButton />
            </div>
            <div className="page-titles py-1 page-title-sticky">
                <div className="row d-flex">
                    <div className="col-lg-6 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span style={{ fontSize: '16px' }}>Transaction</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                    <span>Easy Upload</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-lg-6 text-end">
                        {checkBoxValue1.includes(1) == true ? (
                            <Button
                                type="primary"
                                shape="round"
                                className="addbutton mx-1"
                                icon={<PlayCircleOutlined />}
                                onClick={finalSubmit}
                            >
                                Submit Selected
                            </Button>
                        ) : (
                            false
                        )}

                        <Button type="primary"
                            disabled
                            shape="round"
                            className="addbutton mx-1"
                        >Total compliance :&nbsp;{compliancetabledata && compliancetabledata.comp_list &&
                            compliancetabledata.comp_list.length > 0 ? compliancetabledata.comp_list.length : 0}</Button>
                        <Tooltip title={'Advanced Filter'}>
                            <Button
                                className="mx-1"
                                type="primary"
                                shape="round"
                                style={{
                                    display: filterTaskAcc ? "none" : "initial",
                                }}
                                onClick={filterHandle}
                            >
                                <FilterOutlined /> Filter
                            </Button>
                        </Tooltip>

                        <button
                            onClick={(e) => {
                                fullscreenMode();
                            }}
                            className="bg-transparent text-black ms-1 full-mode fullscreenradius"
                        >

                            <Tooltip
                                placement="left"
                                title={is_Fullscreen ? "Exit Full Screen Mode" : "Full Screen Mode"}
                            >
                                <FullscreenOutlined />
                            </Tooltip>
                        </button>
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-lg-12">
                        <div style={{ borderRadius: '0px' }} className={localStorage.getItem('currentTheme')}>
                            <Collapse accordion defaultActiveKey={['1']}
                                style={{ display: exitCollapse ? "none" : "block" }}>
                                <Panel header="Easy Upload" key="1">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-4" >
                                                <label htmlFor=""><b>Legal Entity: </b></label><span style={{ color: "red" }}> *</span><br />
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Select Legal Entity"
                                                            onChange={(data, value) => {
                                                                console.log(category, 'negative')
                                                                if (data !== undefined) {
                                                                    setCurrentEntity(data)
                                                                    Setshowdata({
                                                                        ...showdata,
                                                                        legal_entity: data ? data : '',
                                                                        domain: '',
                                                                        division: '',
                                                                        category: '',
                                                                        unit: '',
                                                                        frequency: '',
                                                                        domainName: ''
                                                                    })
                                                                    setDomain('')
                                                                    handlecategory()
                                                                }
                                                                else {
                                                                    setCurrentEntity(localStorage.getItem('SelectedEntityid'))
                                                                    Setshowdata({
                                                                        legal_entity: '',
                                                                        domain: '',
                                                                        unit: [],
                                                                        frequency: '',
                                                                        category: '',
                                                                        act: '',
                                                                        division: '',
                                                                        task_category: '',
                                                                        tasksub_category: '',
                                                                        domainName: '',
                                                                        unit_name: ''
                                                                    })
                                                                    setDomain('')
                                                                    setUnits([])
                                                                    setCategoryList([])
                                                                    setDivision([])
                                                                    setTaskcategory([])
                                                                    setTasksubcategory([])
                                                                }

                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={showdata.legal_entity || undefined}
                                                            style={{ width: '100%', marginTop: "5px" }}
                                                        >
                                                            {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                return (
                                                                    <Option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {formValidator.current.message(
                                                            'legal_entity',
                                                            showdata.legal_entity,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${FormShow ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Required',
                                                                }
                                                            })}
                                                    </div> :
                                                    <p style={{ width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>
                                                }
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Domain: </b></label><br />
                                                    {((domain && domain.length === 1) && (showdata.legal_entity != '')) ?
                                                        <p style={{ marginTop: '10px' }} value={domain && domain.length > 0 && domain[0].d_name}>{domain && domain.length > 0 && domain[0].d_name}</p>
                                                        :
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            name="domain"
                                                            id="domain"
                                                            disabled={showdata.legal_entity !== '' ? false : true}
                                                            placeholder="Enter domain"
                                                            onChange={(data, value) => {
                                                                if (data !== undefined) {
                                                                    Setshowdata({
                                                                        ...showdata,
                                                                        domain: data,
                                                                        domainName: value.children
                                                                    })
                                                                    let a = units.filter((items) => items.d_ids == data)
                                                                    setUnits(a)
                                                                }
                                                                else {
                                                                    Setshowdata({
                                                                        ...showdata,
                                                                        domain: '',
                                                                        domainName: '',
                                                                        division: '',
                                                                        category: '',
                                                                        unit: '',
                                                                        unit_name: '',
                                                                        frequency: '',
                                                                        task_category: '',
                                                                        tasksub_category: ''
                                                                    })
                                                                }
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={showdata.domainName || undefined}
                                                            style={{ width: '100%', marginTop: "5px" }}
                                                        >
                                                            {domain && domain.length > 0 && domain.map((item, i) => {
                                                                return (
                                                                    <Option key={item.d_id}>
                                                                        {item.d_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    }

                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Division: </b></label><br />
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        disabled={showdata.legal_entity !== '' ? false : true}
                                                        placeholder="Enter Division"
                                                        onChange={(data) => {
                                                            if (data !== undefined) {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    division: data
                                                                })
                                                                // let filCat = _.filter(compliancefilter && compliancefilter.categories_list, { div_id: Number(data) })
                                                                // setCategoryList(filCat)
                                                                let a = units.filter((items) => items.division_id == data)
                                                                console.log(data, units, a, 'div_idsddiv_id')
                                                                setUnits(a)
                                                            }
                                                            else {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    division: '',
                                                                    category: '',
                                                                    unit: '',
                                                                    unit_name: '',
                                                                    frequency: '',
                                                                    task_category: '',
                                                                    tasksub_category: ''
                                                                })
                                                            }
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={showdata.division || undefined}
                                                        style={{ width: '100%', marginTop: "5px" }}
                                                    >
                                                        {division && division.length > 0 && division.map((item, i) => {
                                                            return (
                                                                <Option key={item.div_id}>
                                                                    {item.div_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Category: </b></label>
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Category"
                                                        disabled={showdata.legal_entity !== '' ? false : true}
                                                        onChange={(data) => {
                                                            console.log(data, 'catehh')
                                                            if (data !== undefined) {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    category: data
                                                                })
                                                            }
                                                            else {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    category: '',
                                                                    unit: '',
                                                                    unit_name: '',
                                                                    frequency: '',
                                                                    task_category: '',
                                                                    tasksub_category: ''
                                                                })
                                                            }
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={showdata.category || undefined}

                                                        style={{ width: '100%', marginTop: "5px" }}
                                                    >
                                                        {category && category.length > 0 && category.map((item, i) => {
                                                            console.log(item, category, 'negative')
                                                            return (
                                                                <Option key={item.cat_id}>
                                                                    {item.category_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">

                                                <div className="form-group">

                                                    <label htmlFor=""><b>Vendor: </b></label>
                                                    <Select
                                                        getPopupContainer={trigger => trigger.parentNode}

                                                        mode="multiple"
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Vendors"
                                                        disabled={showdata.legal_entity !== '' ? false : true}
                                                        maxTagCount='responsive'
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onPaste={(e) => {
                                                            handlePasting(e, /^[a-z 0-9]+$/gi)
                                                        }}
                                                        onChange={(value, data) => {
                                                            if (data == undefined) {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    unit: '',
                                                                    unit_name: '',
                                                                    frequency: '',
                                                                    task_category: '',
                                                                    tasksub_category: ''
                                                                })
                                                            } else {
                                                                let temp = []
                                                                let temp1 = []
                                                                for (let i in value) {
                                                                    temp1.push(`${Number(value[i])}`)
                                                                    temp.push(Number(value[i]))
                                                                }
                                                                console.log(temp, "temptemptemp");
                                                                let temp_name = []
                                                                for (let i in data.children) {
                                                                    temp_name.push(data.children[i])
                                                                }
                                                                console.log(temp_name, "temp_nametemp_name");

                                                                Setshowdata({
                                                                    ...showdata,
                                                                    unit: temp,
                                                                    unit_name: temp1
                                                                })


                                                                setFormShow(false)
                                                            }
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={showdata.unit_name ? showdata.unit_name : undefined}


                                                        style={{ width: '100%', marginTop: "5px" }}
                                                    >
                                                        {units && units.length > 0 && units.map((item, i) => {
                                                            <Option defaultValue >None Selected</Option>
                                                            return (
                                                                <Option key={item.unit_id}>
                                                                    {item.unit_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>

                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Frequency: </b></label>
                                                    <Select
                                                        allowClear={false}
                                                        size="default"
                                                        defaultValue={"All"}
                                                        disabled={showdata.legal_entity !== '' ? false : true}
                                                        placeholder="Enter Frequency"
                                                        onChange={(data) => {
                                                            if (data !== undefined) {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    frequency: data
                                                                })
                                                            }
                                                            else {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    frequency: '',
                                                                    task_category: '',
                                                                    tasksub_category: ''
                                                                })
                                                            }
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        value={showdata.frequency || undefined}
                                                        style={{ width: '100%', marginTop: "5px" }}
                                                    >
                                                        <Option key=''>All</Option>
                                                        {frequency && frequency.length > 0 && frequency.map((item, i) => {
                                                            return (
                                                                <Option key={item.frequency_id}>
                                                                    {item.frequency_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mt-2">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor=""><b>Task Category: </b></label>
                                                            <Select
                                                                allowClear={false}
                                                                size="default"
                                                                defaultValue={"All"}
                                                                disabled={showdata.legal_entity !== '' ? false : true}
                                                                placeholder="Enter Task Category"
                                                                onChange={(data) => {
                                                                    if (data !== undefined) {
                                                                        Setshowdata({
                                                                            ...showdata,
                                                                            task_category: data != 'x' ? data : '',
                                                                            tasksub_category: ''
                                                                        })
                                                                    }
                                                                    else {
                                                                        Setshowdata({
                                                                            ...showdata,
                                                                            task_category: '',
                                                                            tasksub_category: ''
                                                                        })
                                                                    }
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                // value={showdata.task_category || undefined}
                                                                style={{ width: '100%', marginTop: "5px" }}
                                                            >
                                                                <Option key='x'>All</Option>
                                                                {taskcategory && taskcategory.length > 0 && taskcategory.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.task_category_id}>
                                                                            {item.task_category_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor=""><b>Task Sub Category: </b></label>
                                                            <Select
                                                                allowClear={false}
                                                                size="default"
                                                                defaultValue={"All"}
                                                                placeholder="Enter Task Sub Category"
                                                                disabled={showdata.legal_entity !== '' ? false : true}
                                                                onChange={(data) => {
                                                                    if (data !== undefined) {
                                                                        Setshowdata({
                                                                            ...showdata,
                                                                            tasksub_category: data != 'y' ? data : ''
                                                                        })

                                                                    }
                                                                    else {
                                                                        Setshowdata({
                                                                            ...showdata,
                                                                            tasksub_category: ''
                                                                        })
                                                                    }
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                value={showdata.tasksub_category || undefined}
                                                                style={{ width: '100%', marginTop: "5px" }}
                                                            >
                                                                <Option key='y'>All</Option>
                                                                {tasksubcategory && tasksubcategory.length > 0 && tasksubcategory.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.task_sub_category_id}>
                                                                            {item.task_sub_category_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"col-md-12 mt-2 popupbtncolour mb-2 " + localStorage.getItem('currentTheme')}>

                                        <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: '46%', marginTop: "5px" }}
                                            size='default'
                                            onClick={onsubmitData} icon={<EyeOutlined />}
                                        >Show
                                        </Button>
                                    </div>
                                </Panel>
                            </Collapse>
                            <Modal
                                onCancel={setcancelFilter}
                                footer={false}
                                title="Easy Upload"
                                className={
                                    "add-service-prv remark-header cons-report " +
                                    localStorage.getItem("currentTheme")
                                }
                                open={easymodal_filter}
                                maskClosable={false}
                            >
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-4" >
                                            <label htmlFor=""><b>Legal Entity: </b></label><span style={{ color: "red" }}> *</span><br />
                                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                <div className="form-group">
                                                    <Select
                                                        getPopupContainer={trigger => trigger.parentNode}

                                                        allowClear={true}
                                                        size="default"
                                                        disabled={showdata.legal_entity !== '' ? false : true}
                                                        placeholder="Select Legal Entity"
                                                        onChange={(data, value) => {
                                                            console.log(category, 'negative')
                                                            if (data !== undefined) {
                                                                setCurrentEntity(data)
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    legal_entity: data ? data : '',
                                                                    domain: '',
                                                                    division: '',
                                                                    category: '',
                                                                    unit: '',
                                                                    frequency: '',
                                                                    domainName: ''
                                                                })
                                                                setDomain('')

                                                                handlecategory()

                                                            }
                                                            else {
                                                                setCurrentEntity(localStorage.getItem('SelectedEntityid'))
                                                                Setshowdata({
                                                                    domain: '',
                                                                    unit: [],
                                                                    frequency: '',
                                                                    category: '',
                                                                    act: '',
                                                                    division: '',
                                                                    task_category: '',
                                                                    tasksub_category: '',
                                                                    domainName: '',
                                                                    unit_name: ''
                                                                })

                                                            }
                                                            setDomain('')
                                                            setUnits([])
                                                            setCategoryList([])
                                                            setDivision([])
                                                            setTaskcategory([])
                                                            setTasksubcategory([])
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={showdata.legal_entity || undefined}
                                                        style={{ width: '100%', marginTop: "5px" }}
                                                    >
                                                        {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                            return (
                                                                <Option key={item.le_id}>
                                                                    {item.le_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    {formValidator.current.message(
                                                        'legal_entity',
                                                        showdata.legal_entity,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${FormShow ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Legal Entity Required',
                                                            }
                                                        })}
                                                </div> :
                                                <p style={{ width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>
                                            }
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor=""><b>Domain: </b></label><br />
                                                {((domain && domain.length === 1) && (showdata.legal_entity != '')) ?
                                                    <p style={{ marginTop: '10px' }} value={domain && domain.length > 0 && domain[0].d_name}>{domain && domain.length > 0 && domain[0].d_name}</p>
                                                    :
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        disabled={showdata.legal_entity !== '' ? false : true}
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        name="domain"
                                                        id="domain"
                                                        placeholder="Enter domain"
                                                        onChange={(data, value) => {
                                                            if (data !== undefined) {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    domain: data,
                                                                    domainName: value.children
                                                                })
                                                                let a = units.filter((items) => items.d_ids == data)
                                                                setUnits(a)
                                                            }
                                                            else {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    domain: '',
                                                                    domainName: '',
                                                                    division: '',
                                                                    category: '',
                                                                    unit: '',
                                                                    unit_name: '',
                                                                    frequency: '',
                                                                    task_category: '',
                                                                    tasksub_category: ''

                                                                })
                                                            }
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={showdata.domainName || undefined}
                                                        style={{ width: '100%', marginTop: "5px" }}
                                                    >
                                                        {domain && domain.length > 0 && domain.map((item, i) => {
                                                            return (
                                                                <Option key={item.d_id}>
                                                                    {item.d_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                }

                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor=""><b>Division: </b></label><br />
                                                <Select
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    allowClear={true}
                                                    size="default"
                                                    disabled={showdata.legal_entity !== '' ? false : true}
                                                    placeholder="Enter Division"
                                                    onChange={(data) => {
                                                        if (data !== undefined) {
                                                            Setshowdata({
                                                                ...showdata,
                                                                division: data
                                                            })
                                                            // let filCat = _.filter(compliancefilter && compliancefilter.categories_list, { div_id: Number(data) })
                                                            // setCategoryList(filCat)
                                                            let a = units.filter((items) => items.division_id == data)
                                                            console.log(data, units, a, 'div_idsddiv_id')
                                                            setUnits(a)
                                                        }
                                                        else {
                                                            Setshowdata({
                                                                ...showdata,
                                                                division: '',
                                                                category: '',
                                                                unit: '',
                                                                unit_name: '',
                                                                frequency: '',
                                                                task_category: '',
                                                                tasksub_category: ''
                                                            })
                                                        }
                                                    }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    value={showdata.division || undefined}
                                                    style={{ width: '100%', marginTop: "5px" }}
                                                >
                                                    {division && division.length > 0 && division.map((item, i) => {
                                                        return (
                                                            <Option key={item.div_id}>
                                                                {item.div_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 mt-2">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor=""><b>Category: </b></label>
                                                <Select
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Enter Category"
                                                    disabled={showdata.legal_entity !== '' ? false : true}
                                                    onChange={(data) => {
                                                        console.log(data, 'catehh')
                                                        if (data !== undefined) {
                                                            Setshowdata({
                                                                ...showdata,
                                                                category: data
                                                            })
                                                        }
                                                        else {
                                                            Setshowdata({
                                                                ...showdata,
                                                                category: '',
                                                                unit: '',
                                                                unit_name: '',
                                                                frequency: '',
                                                                task_category: '',
                                                                tasksub_category: ''
                                                            })
                                                        }
                                                    }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    value={showdata.category || undefined}

                                                    style={{ width: '100%', marginTop: "5px" }}
                                                >
                                                    {category && category.length > 0 && category.map((item, i) => {
                                                        console.log(item, category, 'negative')
                                                        return (
                                                            <Option key={item.cat_id}>
                                                                {item.category_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">

                                            <div className="form-group">

                                                <label htmlFor=""><b>Vendor: </b></label>
                                                <Select
                                                    getPopupContainer={trigger => trigger.parentNode}

                                                    mode="multiple"
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Enter Vendors"
                                                    disabled={showdata.legal_entity !== '' ? false : true}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z 0-9]+$/gi)
                                                    }}
                                                    onChange={(value, data) => {
                                                        if (data == undefined) {
                                                            Setshowdata({
                                                                ...showdata,
                                                                unit: '',
                                                                unit_name: '',
                                                                frequency: '',
                                                                task_category: '',
                                                                tasksub_category: ''
                                                            })
                                                        } else {
                                                            let temp = []
                                                            let temp1 = []
                                                            for (let i in value) {
                                                                temp1.push(`${Number(value[i])}`)
                                                                temp.push(Number(value[i]))
                                                            }
                                                            console.log(temp, "temptemptemp");
                                                            let temp_name = []
                                                            for (let i in data.children) {
                                                                temp_name.push(data.children[i])
                                                            }
                                                            console.log(temp_name, "temp_nametemp_name");

                                                            Setshowdata({
                                                                ...showdata,
                                                                unit: temp,
                                                                unit_name: temp1
                                                            })


                                                            setFormShow(false)
                                                        }
                                                    }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    value={showdata.unit_name ? showdata.unit_name : undefined}


                                                    style={{ width: '100%', marginTop: "5px" }}
                                                >
                                                    {units && units.length > 0 && units.map((item, i) => {
                                                        <Option defaultValue >None Selected</Option>
                                                        return (
                                                            <Option key={item.unit_id}>
                                                                {item.unit_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>

                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor=""><b>Frequency: </b></label>
                                                <Select
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    allowClear={false}
                                                    size="default"
                                                    defaultValue={"All"}
                                                    disabled={showdata.legal_entity !== '' ? false : true}
                                                    placeholder="Enter Frequency"
                                                    onChange={(data) => {
                                                        if (data !== undefined) {
                                                            Setshowdata({
                                                                ...showdata,
                                                                frequency: data
                                                            })
                                                        }
                                                        else {
                                                            Setshowdata({
                                                                ...showdata,
                                                                frequency: '',
                                                                task_category: '',
                                                                tasksub_category: ''
                                                            })
                                                        }
                                                    }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    value={showdata.frequency || undefined}
                                                    style={{ width: '100%', marginTop: "5px" }}
                                                >
                                                    <Option key=''>All</Option>
                                                    {frequency && frequency.length > 0 && frequency.map((item, i) => {
                                                        return (
                                                            <Option key={item.frequency_id}>
                                                                {item.frequency_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor=""><b>Task Category: </b></label>
                                                        <Select
                                                            getPopupContainer={trigger => trigger.parentNode}
                                                            // allowClear={true}
                                                            size="default"
                                                            defaultValue={"All"}

                                                            disabled={showdata.legal_entity !== '' ? false : true}
                                                            placeholder="Enter Task Category"
                                                            onChange={(data, value) => {
                                                                console.log(value, data, 'tasjk')
                                                                if (data !== undefined) {
                                                                    Setshowdata({
                                                                        ...showdata,
                                                                        task_category: data != 'x' ? data : '',
                                                                        tasksub_category: ''
                                                                    })
                                                                }
                                                                else {
                                                                    Setshowdata({
                                                                        ...showdata,
                                                                        task_category: '',
                                                                        tasksub_category: ''
                                                                    })
                                                                }
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            // value={showdata.task_category || undefined}
                                                            style={{ width: '100%', marginTop: "5px" }}
                                                        >
                                                            <Option key='x' >All</Option>
                                                            {taskcategory && taskcategory.length > 0 && taskcategory.map((item, i) => {
                                                                return (
                                                                    <Option key={item.task_category_id}>
                                                                        {item.task_category_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor=""><b>Task Sub Category: </b></label>
                                                        <Select
                                                            getPopupContainer={trigger => trigger.parentNode}
                                                            // allowClear={true}
                                                            size="default"
                                                            defaultValue={"All"}
                                                            disabled={showdata.legal_entity !== '' ? false : true}
                                                            Setshowdata placeholder="Enter Task Sub Category"
                                                            onChange={(data) => {
                                                                if (data !== undefined) {
                                                                    Setshowdata({
                                                                        ...showdata,
                                                                        tasksub_category: data != 'y' ? data : ''
                                                                    })
                                                                }
                                                                else {
                                                                    Setshowdata({
                                                                        ...showdata,
                                                                        tasksub_category: ''
                                                                    })
                                                                }
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            value={showdata.tasksub_category || undefined}
                                                            style={{ width: '100%', marginTop: "5px" }}
                                                        >
                                                            <Option key='y'>All</Option>
                                                            {tasksubcategory && tasksubcategory.length > 0 && tasksubcategory.map((item, i) => {
                                                                return (
                                                                    <Option key={item.task_sub_category_id}>
                                                                        {item.task_sub_category_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-md-12 mt-2 popupbtncolour mb-2 " + localStorage.getItem('currentTheme')}>

                                    <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: '46%', marginTop: "5px" }}
                                        size='default'
                                        onClick={onsubmitData} icon={<EyeOutlined />}
                                    >Show
                                    </Button>
                                </div>
                            </Modal>

                            {show == true ?


                                <>


                                    <div className='card px-2 py-1 p-0 mt-1 mb-2' style={{ background: '#e3e2e2' }}>
                                        <div className="row">
                                            <div className="col-md-9 ">
                                                <div className="date-filter d-flex  align-items-center">
                                                    <div className={localStorage.getItem("currentTheme") + ' ant-btn label-block h-100'} >
                                                        <h4 className='text-white mb-0 d-flex'>
                                                            <span className='rounded-circle arr-btn' onClick={onMonthPrvChange} style={{ visibility: monthPrview.yearvalue == (Number(year) - 7) ? 'hidden' : 'visible' }}> <i class="ri-arrow-left-s-line "></i></span>
                                                            <span className='px-2'> {monthPrview && monthPrview.yearvalue}</span>
                                                            <span className='rounded-circle arr-btn' style={{ visibility: monthPrview.yearvalue == year ? 'hidden' : 'visible' }} onClick={onMonthNxtChange}><i class="ri-arrow-right-s-line "></i></span>
                                                        </h4>

                                                    </div>
                                                    <div style={{ marginLeft: '5%' }}>
                                                        <div className='ps-2'>

                                                            <label className='h5'>Due for the month of</label>

                                                            <div className="d-flex align-items-center month-wrapper" style={{ cursor: 'pointer' }}>
                                                                <a className={`${complianceshowdata && complianceshowdata.task_status && complianceshowdata.task_status[0]}` + ' h3 month-btn JAN'} onClick={(e) => {
                                                                    Monthwisechange('JAN', e, 1)
                                                                    setComplianceID([]);
                                                                    setCheckBoxValue1([])
                                                                    setCheckBoxValue([])
                                                                    setfilteredArrayState([])
                                                                }} >Jan</a>
                                                                < a className={`${complianceshowdata && complianceshowdata.task_status && complianceshowdata.task_status[1]} ` + ' h3 month-btn FEB'} onClick={(e) => {
                                                                    // ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC' ]
                                                                    Monthwisechange('FEB', e, 2)
                                                                    setComplianceID([]);
                                                                    setCheckBoxValue1([])
                                                                    setCheckBoxValue([])
                                                                    setfilteredArrayState([])
                                                                }}>Feb</a>
                                                                < a className={`${complianceshowdata && complianceshowdata.task_status && complianceshowdata.task_status[2]}` + ' h3 month-btn MAR'} onClick={(e) => {
                                                                    Monthwisechange('MAR', e, 3)
                                                                    setComplianceID([]);
                                                                    setCheckBoxValue1([])
                                                                    setCheckBoxValue([])
                                                                    setfilteredArrayState([])
                                                                }}>Mar</a>
                                                                < a className={`${complianceshowdata && complianceshowdata.task_status && complianceshowdata.task_status[3]}` + ' h3 month-btn APR'} onClick={(e) => {
                                                                    Monthwisechange('APR', e, 4)
                                                                    setComplianceID([]);
                                                                    setCheckBoxValue1([])
                                                                    setCheckBoxValue([])
                                                                    setfilteredArrayState([])
                                                                }}>Apr</a>
                                                                < a className={`${complianceshowdata && complianceshowdata.task_status && complianceshowdata.task_status[4]}` + ' h3 month-btn MAY'} onClick={(e) => {
                                                                    Monthwisechange('MAY', e, 5)
                                                                    setComplianceID([])
                                                                    setCheckBoxValue1([])
                                                                    setCheckBoxValue([])
                                                                    setfilteredArrayState([])
                                                                }}>May</a>
                                                                < a className={`${complianceshowdata && complianceshowdata.task_status && complianceshowdata.task_status[5]}` + ' h3 month-btn JUN'} onClick={(e) => {
                                                                    Monthwisechange('JUN', e, 6)
                                                                    setComplianceID([]);
                                                                    setCheckBoxValue1([])
                                                                    setCheckBoxValue([])
                                                                    setfilteredArrayState([])
                                                                }}>Jun</a>
                                                                < a className={`${complianceshowdata && complianceshowdata.task_status && complianceshowdata.task_status[6]}` + ' h3 month-btn JUL'} onClick={(e) => {
                                                                    Monthwisechange('JUL', e, 7)
                                                                    setComplianceID([]);
                                                                    setCheckBoxValue1([])
                                                                    setCheckBoxValue([])
                                                                    setfilteredArrayState([])
                                                                }}>Jul</a>
                                                                < a className={`${complianceshowdata && complianceshowdata.task_status && complianceshowdata.task_status[7]}` + ' h3 month-btn AUG'} onClick={(e) => {
                                                                    Monthwisechange('AUG', e, 8)
                                                                    setComplianceID([]);
                                                                    setCheckBoxValue1([])
                                                                    setCheckBoxValue([])
                                                                    setfilteredArrayState([])
                                                                }}>Aug</a>
                                                                < a className={`${complianceshowdata && complianceshowdata.task_status && complianceshowdata.task_status[8]}` + ' h3 month-btn SEP'} onClick={(e) => {
                                                                    Monthwisechange('SEP', e, 9)
                                                                    setComplianceID([]);
                                                                    setCheckBoxValue1([])
                                                                    setCheckBoxValue([])
                                                                    setfilteredArrayState([])
                                                                }}>Sep</a>
                                                                < a className={`${complianceshowdata && complianceshowdata.task_status && complianceshowdata.task_status[9]}` + ' h3 month-btn OCT'} onClick={(e) => {
                                                                    Monthwisechange('OCT', e, 10)
                                                                    setComplianceID([]);
                                                                    setCheckBoxValue1([])
                                                                    setCheckBoxValue([])
                                                                    setfilteredArrayState([])
                                                                }}>Oct</a>
                                                                < a className={`${complianceshowdata && complianceshowdata.task_status && complianceshowdata.task_status[10]}` + ' h3 month-btn NOV'} onClick={(e) => {
                                                                    Monthwisechange('NOV', e, 11)
                                                                    setComplianceID([]);
                                                                    setCheckBoxValue1([])
                                                                    setCheckBoxValue([])
                                                                    setfilteredArrayState([])
                                                                }}>Nov</a>
                                                                < a className={`${complianceshowdata && complianceshowdata.task_status && complianceshowdata.task_status[11]}` + ' h3 month-btn DEC'} onClick={(e) => {
                                                                    Monthwisechange('DEC', e, 12)
                                                                    setComplianceID([]);
                                                                    setCheckBoxValue1([])
                                                                    setCheckBoxValue([])
                                                                    setfilteredArrayState([])
                                                                }}>Dec</a>

                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>

                                            </div>
                                            <div className='col-md-3 text-center'>
                                                <div className="p-1  record-upload">
                                                    <span className="text-white h5 fw-bold "><b>Upload {monthValue && monthValue == 'JAN' ? "DEC" : monthValue && monthValue == 'FEB' ? "JAN" : monthValue && monthValue == 'MAR' ? 'FEB' : monthValue && monthValue == 'APR' ? 'MAR' : monthValue && monthValue == 'MAY' ? 'APR' : monthValue && monthValue == 'JUN' ? 'MAY' : monthValue && monthValue == 'JUL' ? "JUN" : monthValue && monthValue == "AUG" ? "JUL" : monthValue && monthValue == "SEP" ? "AUG" : monthValue && monthValue == "OCT" ? "SEP" : monthValue && monthValue == "NOV" ? "OCT" : monthValue && monthValue == 'DEC' ? "NOV" : ''}
                                                        &nbsp;{monthValue && monthValue == 'JAN' ? monthPrview.yearvalue - 1 : monthPrview.yearvalue} records</b></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='ant-table-wrapper'>
                                        <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper'}>
                                            <div className='ant-table ant-table-bordered ant-table-fixed-header service-provider-table'>
                                                <div className='ant-table-container'>
                                                    <div className='ant-table-content userprivclass1'>
                                                        <table>
                                                            <colgroup>
                                                                <col style={{ width: '10px' }}></col>
                                                            </colgroup>
                                                            <thead className="ant-table-thead">
                                                                <tr>
                                                                    {console.log(checkBoxValue1, 'checkBoxValue1')}
                                                                    <th className={'ant-table-cell'} style={{ width: '25px' }}> <p style={{ marginBottom: '4px' }}> S.no </p>
                                                                        <input
                                                                            className='px-1'
                                                                            type="checkbox"
                                                                            disabled={filteredArrayState && filteredArrayState.length ? false : true}
                                                                            // checked={((complianceID && complianceID.length > 0 && complianceID.length) === (compliancetabledata && compliancetabledata.comp_list.length > 0 && compliancetabledata.comp_list.length)) ? true : false}
                                                                            // checked={((checkBoxValue1.includes(0) == true) && (filteredArrayState.length > 0))  ? false : filteredArrayState && filteredArrayState.length < 0 ? false : true}
                                                                            checked={(((checkBoxValue1?.includes(0)) || finaldata?.length == 0) ? false : true)}
                                                                            // checked={
                                                                            //     filterValue.ConsolidatedReportList
                                                                            //         .length == complianceID.length
                                                                            //         ? true
                                                                            //         : false
                                                                            // }
                                                                            onChange={(e) => {
                                                                                downloadselectAllCheckBox(
                                                                                    e.target.checked
                                                                                );

                                                                            }}
                                                                        /> </th>
                                                                    <th className={'ant-table-cell'} style={{ width: '25%' }}>Act</th>
                                                                    <th className={'ant-table-cell'} style={{ width: '25%' }}>Compliance Task</th>
                                                                    <th className={'ant-table-cell'} style={{ width: '8%' }}><center>Due Date</center></th>
                                                                    <th className={'ant-table-cell'} style={{ width: '5%' }}>
                                                                        <p style={{ marginBottom: '2px' }}>Not Applicable</p>
                                                                        <input type="checkbox" name="" id="NAall"
                                                                            checked={checkBoxValue && checkBoxValue.length <= 0 && filteredArrayState.length == 0 ? false : checkBoxValue.includes(0) ? false : true}
                                                                            onChange={(e) => {
                                                                                selectAllCheckbox(
                                                                                    e.target.checked
                                                                                );
                                                                            }}
                                                                            disabled={filteredArrayState && filteredArrayState.length ? false : true}
                                                                        />
                                                                    </th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '15%' }}><center>Documents</center></th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '15%' }}>Remarks</th>


                                                                </tr>
                                                            </thead>
                                                            {filteredArrayState && filteredArrayState.length && finaldata.length > 0 ? filteredArrayState.map((item, i) => {
                                                                console.log(item, 'item');
                                                                return (
                                                                    <tbody className='ant-table-tbody' key={i}>

                                                                        {finaldata && finaldata.map((itemdata, i) => {

                                                                            let data = _.find(itemdata.child, { unit_name: item[0].unit_name })
                                                                            console.log(itemdata.child && itemdata.child.length, "childLength");
                                                                            if (data != undefined) {

                                                                                if (data.unit_name == itemdata.parent) {
                                                                                    return <Fragment key={i}>
                                                                                        <tr className='ant-table-row ant-table-row-level-0'>
                                                                                            <td colSpan={8} className='ant-table-cell' ><b style={{ backgroundColor: "lightyellow" }}>{itemdata.parent}</b></td>
                                                                                        </tr>

                                                                                        {itemdata.child.map((items, itemskj) => {
                                                                                            console.log(items, 'ant-table-row ant-table-row-level-0')

                                                                                            if (item[0].unit_name == items.unit_name) {
                                                                                                return <tr key={itemskj}>
                                                                                                    <td className="ant-table-cell">
                                                                                                        <center>
                                                                                                            {count + 1} <br />
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                name="checked"

                                                                                                                checked={checkBoxValue1[items.childId] == 1 ? true : false}
                                                                                                                onChange={(e) => { downloadcheckBoxOnChange(e.target.checked, items); }} />
                                                                                                        </center>
                                                                                                    </td>
                                                                                                    <td className='ant-table-cell'>{items.act_name}</td>
                                                                                                    <td className='ant-table-cell'>{items.compliance_task} {items.remarks != null ? <Tooltip title={items.recall_status == 0 ? 'Rectify' : 'Recalled'}> <TrademarkOutlined style={{ cursor: 'pointer', color: items.recall_status == 0 ? 'red' : 'blue' }} /> </Tooltip> : <></>}</td>
                                                                                                    <td className='ant-table-cell'><center>{items.due_date}</center></td>
                                                                                                    <td><center><input type="checkbox" name="chk"
                                                                                                        // value={checkValue} 
                                                                                                        // defaultValue={checkBoxValue[items.childId]}
                                                                                                        // key={checkBoxValue[items.childId]}
                                                                                                        value={checkBoxValue[items.childId]}

                                                                                                        id=""
                                                                                                        onChange={(e) => {
                                                                                                            checkBoxValue[items.childId] = e.target.checked === true ? 1 : 0
                                                                                                            setCheckBoxValue([...checkBoxValue])


                                                                                                            // checkBoxValue1[items.childId] = e.target.checked === true ? 1 : 0
                                                                                                            // setCheckBoxValue1([...checkBoxValue1])
                                                                                                            // downloadcheckBoxOnChange(true, items)
                                                                                                        }}
                                                                                                        checked={checkBoxValue[items.childId] == 0 ? false : true}

                                                                                                    /></center></td>
                                                                                                    <td className='ant-table-cell'>
                                                                                                        <span>
                                                                                                            <div className="d-flex justify-content-center align-items-center">
                                                                                                                {/* <label for={items.compliance_history_id} */}
                                                                                                                {/* className='btn btn-primary w85 p-1 d-flex justify-content-center align-items-center'> */}
                                                                                                                {/* <CloudUploadOutlined className='me-1' /> {fileupload[items.compliance_history_id] && fileupload[items.compliance_history_id].length > 0 ? `${fileupload[items.compliance_history_id].length} file(s) uploaded` : 'Drop file here or click to upload'} </label>
                                                                                                                {/* <span className='position-absolute'>fjh</span> */}
                                                                                                                {/* <input className='d-none' type="file" multiple name="file" style={{
                                                                                                                    maxWidth: '140px', marginTop: '6px'
                                                                                                                    //  overflow : 'hidden',textOverflow: 'ellipsis',// whiteSpace: 'nowrap'
                                                                                                                }} */}
                                                                                                                {/* // className="mt-1"
                                                                                                                    onChange={(e) => {
                                                                                                                        SavepastRecordOnChange(e, items, items.childId, items.compliance_history_id)
                                                                                                                        checkBoxValue1[items.childId] = 1
                                                                                                                        setCheckBoxValue1([...checkBoxValue1])
                                                                                                                        downloadcheckBoxOnChange(true, items)
                                                                                                                    }}
                                                                                                                    id={items.compliance_history_id} */}
                                                                                                                {/* /> */}
                                                                                                                {/* {items.remarks != null ? */}
                                                                                                                <Dragger beforeUpload={(file, fileList) => {
                                                                                                                    console.log(file, fileList,'before upload')
                                                                                                                    // if (fileList.length > 1) {
                                                                                                                    //     return false; // Prevent upload if multiple files are added
                                                                                                                    // }
                                                                                                                    // return true;
                                                                                                                }} multiple customRequest={null} showUploadList="false" fileList={null} onChange={(e) => {
                                                                                                                    documentRemarks[items.childId] = 1
                                                                                                                    setDocumentRemarks([...documentRemarks])

                                                                                                                    var results = [];
                                                                                                                    const fileRegex = /^[a-zA-Z0-9-_& ,.+#()[-\]{}$^=%@]*$/
                                                                                                                    let FILE_TYPE = [
                                                                                                                        "doc", "docx", "rtf", "pdf", "txt", "png", "jpeg", "gif", "csv", "xls", "xlsx",
                                                                                                                        "rar", "tar", "gz", "ppt", "pptx", "jpg", "bmp", "odt", "odf", "ods", "xml"
                                                                                                                    ];
                                                                                                                    let files = e.file
                                                                                                                    let file_max_size = 26214400
                                                                                                                    let x = [...new Set(e.fileList)]
                                                                                                                    console.log(x, 'pois')
                                                                                                                    console.log(files, 'log.console,,files')
                                                                                                                    
                                                                                                                        console.log('log.console')
                                                                                                                        var file = e.file;
                                                                                                                        var file_name = file.name;
                                                                                                                        const f_name = file_name.substring(0, file_name.lastIndexOf('.'));
                                                                                                                        if (fileRegex.test(f_name) === false) {
                                                                                                                            toast.error("File Name have not allowed special charaters")

                                                                                                                            return;
                                                                                                                        }
                                                                                                                        else {
                                                                                                                            var file_extension = file_name.substring(file_name.lastIndexOf('.') + 1).toLowerCase();
                                                                                                                            if (FILE_TYPE.includes(file_extension) == true) {
                                                                                                                                let file_size = file.size;
                                                                                                                                if (file_size > file_max_size) {
                                                                                                                                    toast.error("Maximum filesize limit exceed")
                                                                                                                                    return false;
                                                                                                                                } else {
                                                                                                                                    if (file) {
                                                                                                                                        convert_to_base64(file, file_name, file_size, e.file.originFileObj, function (file_content, name, size) {
                                                                                                                                            var fN = name.substring(0, name.lastIndexOf('.'));
                                                                                                                                            var fE = name.substring(name.lastIndexOf('.') + 1);
                                                                                                                                            var uniqueId = Math.floor(Math.random() * 90000) + 10000;
                                                                                                                                            var f_Name = fN + '-' + uniqueId + '.' + fE;
                                                                                                                                            var type = file.type
                                                                                                                                            var result = uploadFileFormat(size, f_Name, file_content, fE, type);
                                                                                                                                            setFileData(result)
                                                                                                                                            results.push(result);

                                                                                                                                            console.log(results, 'FileResults')


                                                                                                                                            let temp1 = []
                                                                                                                                            let temp = []
                                                                                                                                            let setTemp = []
                                                                                                                                            // if (results && results.length == 1) { 

                                                                                                                                            // console.log(fileupload1.indexOf(UID.toString()), 'fileupload1.includes(UID)')

                                                                                                                                            if (fileupload1.indexOf(items.compliance_history_id.toString()) != -1) {
                                                                                                                                                setTemp = fileupload[items.compliance_history_id]

                                                                                                                                                console.log(setTemp, 'SEBJHBDJUJ')

                                                                                                                                                // for (let i in results) {
                                                                                                                                                setTemp.push(results[0])
                                                                                                                                                // }
                                                                                                                                                setFileUpload({
                                                                                                                                                    ...fileupload,
                                                                                                                                                    [items.compliance_history_id]: setTemp
                                                                                                                                                })
                                                                                                                                                const filePayload1 = [

                                                                                                                                                    authtoken,

                                                                                                                                                    {

                                                                                                                                                        "session_token": authtoken,

                                                                                                                                                        "request": [

                                                                                                                                                            "UploadComplianceTask_temp_upload",

                                                                                                                                                            {

                                                                                                                                                                "le_id": showdata.legal_entity,

                                                                                                                                                                "c_id": 1,

                                                                                                                                                                "d_id": items.domain_id ? Number(items.domain_id) : 0,

                                                                                                                                                                "u_id": items.unit_id,

                                                                                                                                                                "start_date": items.start_date,

                                                                                                                                                                "file_info": setTemp,

                                                                                                                                                                "compliance_history_id": items.compliance_history_id

                                                                                                                                                            }

                                                                                                                                                        ]

                                                                                                                                                    }

                                                                                                                                                ]




                                                                                                                                                EasyUploadFile({

                                                                                                                                                    payload: filePayload1,

                                                                                                                                                    paramid: paramid

                                                                                                                                                })
                                                                                                                                            } else {
                                                                                                                                                console.log(setTemp, 'SEBJHddddddddddddddddddddddBDJUJ')


                                                                                                                                                temp1.push(results[0])


                                                                                                                                                const filePayload = [

                                                                                                                                                    authtoken,

                                                                                                                                                    {

                                                                                                                                                        "session_token": authtoken,

                                                                                                                                                        "request": [

                                                                                                                                                            "UploadComplianceTask_temp_upload",

                                                                                                                                                            {

                                                                                                                                                                "le_id": showdata.legal_entity,

                                                                                                                                                                "c_id": 1,

                                                                                                                                                                "d_id": items.domain_id ? Number(items.domain_id) : 0,

                                                                                                                                                                "u_id": items.unit_id,

                                                                                                                                                                "start_date": items.start_date,

                                                                                                                                                                "file_info": temp1,

                                                                                                                                                                "compliance_history_id": items.compliance_history_id

                                                                                                                                                            }

                                                                                                                                                        ]

                                                                                                                                                    }

                                                                                                                                                ]




                                                                                                                                                EasyUploadFile({

                                                                                                                                                    payload: filePayload,

                                                                                                                                                    paramid: paramid

                                                                                                                                                })

                                                                                                                                                setFileUpload({
                                                                                                                                                    ...fileupload,
                                                                                                                                                    [items.compliance_history_id]: temp1
                                                                                                                                                })

                                                                                                                                            }


                                                                                                                                            // setTimeout(() => {

                                                                                                                                            // if (is_invalid == false) {
                                                                                                                                            // if (Object.keys(fileupload).length > 0) {

                                                                                                                                            // }

                                                                                                                                            // setFileUpload({
                                                                                                                                            //    [UID] : [...temp1]
                                                                                                                                            //     })
                                                                                                                                            // }
                                                                                                                                            // }, 5000)
                                                                                                                                            // }
                                                                                                                                            // else {
                                                                                                                                            //     for (let i in results) {
                                                                                                                                            //         temp.push(results[i])
                                                                                                                                            //     }
                                                                                                                                            //     setTimeout(() => {
                                                                                                                                            //         if (is_invalid == false) {
                                                                                                                                            //             // setFileUpload([...temp])
                                                                                                                                            //         }
                                                                                                                                            //     }, 5000)
                                                                                                                                            // }
                                                                                                                                        })
                                                                                                                                    }
                                                                                                                                }

                                                                                                                                checkBoxValue1[items.childId] = 1
                                                                                                                                setCheckBoxValue1([...checkBoxValue1])
                                                                                                                                downloadcheckBoxOnChange(true, items)
                                                                                                                            } else {
                                                                                                                                Toaster.error('Invalid File Format')
                                                                                                                            }
                                                                                                                        }
                                                                                                                  
                                                                                                                }
                                                                                                                } >

                                                                                                                    <p>{fileupload[items.compliance_history_id] && fileupload[items.compliance_history_id].length > 0 ? `${fileupload[items.compliance_history_id].length} file(s) uploaded` : 'Drop file here or click to upload'}</p>
                                                                                                                </Dragger>
                                                                                                                <span className='px-1' style={{ visibility: items.remarks != null ? 'visible' : 'hidden' }}>
                                                                                                                    <Tooltip title={'Click to view Reviewer Report'}>
                                                                                                                        <InfoCircleFilled className='text-danger' onClick={(e) => {
                                                                                                                            console.log('chkeddddd')
                                                                                                                            const filePayload = [
                                                                                                                                authtoken,
                                                                                                                                {
                                                                                                                                    "session_token": authtoken,
                                                                                                                                    "request": [
                                                                                                                                        "getReviewerDocuments",
                                                                                                                                        {
                                                                                                                                            "legal_entity_id": showdata.legal_entity,
                                                                                                                                            "unit_id": items.unit_id,
                                                                                                                                            "compliance_history_id": items.compliance_history_id
                                                                                                                                        }
                                                                                                                                    ]
                                                                                                                                }
                                                                                                                            ]
                                                                                                                            // const payload = [
                                                                                                                            //     "getReviewerDocuments",
                                                                                                                            //     {
                                                                                                                            //         "legal_entity_id": entityid,
                                                                                                                            //         "unit_id": items.u_id,
                                                                                                                            //         "compliance_history_id": items.compliance_history_id
                                                                                                                            //     }
                                                                                                                            // ]

                                                                                                                            RemarkHistory({
                                                                                                                                payload: filePayload,
                                                                                                                                paramid: paramid
                                                                                                                            })
                                                                                                                            setRemarksHistoryModal(true)


                                                                                                                        }} />
                                                                                                                    </Tooltip>
                                                                                                                </span>
                                                                                                                {/* :
                                                                                                                    <></>

                                                                                                                } */}


                                                                                                                {(fileupload && Object.keys(fileupload).length > 0 && fileupload[items.compliance_history_id] && fileupload[items.compliance_history_id].length > 0 && documentRemarks[items.childId] == 1) && checkBoxValue1[items.childId] == 1 ?
                                                                                                                    <span className='ms-2'><Tooltip title='Click to View Document History'> <InfoCircleTwoTone onClick={() => {
                                                                                                                        setModalDatas(true)
                                                                                                                        setModalValue({
                                                                                                                            le_id: items.le_id,
                                                                                                                            domain_id: items.domain_id,
                                                                                                                            start_date: items.start_date,
                                                                                                                            compliance_history_id: items.compliance_history_id,
                                                                                                                            c_id: items.c_id,
                                                                                                                            unit_id: items.unit_id,
                                                                                                                            rowId: items.childId
                                                                                                                        })
                                                                                                                    }} /> </Tooltip></span> : items.documents.length > 0 ?
                                                                                                                        <span className='ms-2'><Tooltip title='Click to View Document'>
                                                                                                                            <InfoCircleTwoTone onClick={() => {
                                                                                                                                setModalDatas1(true)
                                                                                                                                setModalValue1({
                                                                                                                                    le_id: items.le_id,
                                                                                                                                    domain_id: items.domain_id,
                                                                                                                                    start_date: items.start_date,
                                                                                                                                    compliance_history_id: items.compliance_history_id,
                                                                                                                                    c_id: items.c_id,
                                                                                                                                    unit_id: items.unit_id,
                                                                                                                                    rowId: items.childId,
                                                                                                                                    document: items.documents
                                                                                                                                })
                                                                                                                            }} /> </Tooltip></span> : ''}


                                                                                                                {/* <Dragger multiple onChange={(e) => {
                                                                                                                    SavepastRecordOnChange(e)
                                                                                                                }}> */}

                                                                                                                {/* </Dragger>  */}




                                                                                                            </div>

                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td className='ant-table-cell'>
                                                                                                        <div class="d-flex align-items-end">
                                                                                                            <Input
                                                                                                                name="remarks"
                                                                                                                className="form-control"
                                                                                                                placeholder="Enter Remarks"
                                                                                                                // value={remarks.remarks || undefined}
                                                                                                                maxLength={500}
                                                                                                                id="remarks"
                                                                                                                cols="5"
                                                                                                                rows="1"
                                                                                                                onKeyPress={(event) => {
                                                                                                                    if (! /^[A-Z0-9a-z _ .,-]+$/.test(event.key)) {
                                                                                                                        event.preventDefault();
                                                                                                                    }
                                                                                                                }}
                                                                                                                // defaultValue={updateRemarks[items.childId]}
                                                                                                                // key={updateRemarks[items.childId]}
                                                                                                                value={updateRemarks[items.childId]}
                                                                                                                onChange={(e) => {
                                                                                                                    updateRemarks[items.childId] = e.target.value
                                                                                                                    setUpdateRemarks([...updateRemarks])
                                                                                                                    if (e.target.value == '') {
                                                                                                                        checkBoxValue1[items.childId] = 0
                                                                                                                        setCheckBoxValue1([...checkBoxValue1])
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        checkBoxValue1[items.childId] = 1
                                                                                                                        setCheckBoxValue1([...checkBoxValue1])
                                                                                                                        downloadcheckBoxOnChange(true, items)
                                                                                                                    }
                                                                                                                }}
                                                                                                            >

                                                                                                            </Input>
                                                                                                            <span className="ms-1 text-info d-flex align-items-center justify-content-center"
                                                                                                                style={{ float: "right" }}
                                                                                                                onClick={() => {
                                                                                                                    setRemarksModal(true)

                                                                                                                    showRemarksModal(items.childId);
                                                                                                                }}
                                                                                                            >
                                                                                                                <PlusCircleOutlined />
                                                                                                            </span>

                                                                                                        </div>

                                                                                                    </td>
                                                                                                    <span hidden>{count = count + 1}</span>
                                                                                                </tr>
                                                                                            }
                                                                                        })
                                                                                        }
                                                                                    </Fragment>
                                                                                }

                                                                            }
                                                                        })
                                                                        }
                                                                    </tbody>

                                                                )
                                                            }) :
                                                                <tr>
                                                                    <td colSpan="100%" style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                                </tr>
                                                            }
                                                        </table>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </>


                                : ''
                            }

                        </div>
                    </div>


                </div >
            </div >



            <Modal title={'Remarks History'} open={remarksHistoryModal} className={' remarksClass  add-service-prv ' + localStorage.getItem("currentTheme")} footer={false} onCancel={handlecancelHistory} maskClosable={false}>

                <Table size={'small'}
                    columns={RemarksHistoryColumn}
                    dataSource={remarks_history_data && remarks_history_data.length > 0 ? remarks_history_data : ''}
                    bordered
                    pagination={false} />

            </Modal>


            <Modal title="Remarks List" open={remarksModal1}
                onCancel={handleCancel}
                className={' remarksClass  add-service-prv ' + localStorage.getItem("currentTheme")} footer={false} maskClosable={false}>
                <div >
                    <Table
                        size={'small'}
                        columns={RemarksColumn}
                        dataSource={remarksvalue && remarksvalue.length > 0 && remarksvalue}
                        bordered
                        pagination={false}
                    />
                </div>
            </Modal>

            <Modal open={modalDatas}
                title="Document History"
                className={" add-service-prv " + localStorage.getItem('currentTheme')}
                footer={null}
                onCancel={modalcancel}
                maskClosable={false}
            >
                {console.log(fileupload[modalValue.compliance_history_id], 'fileupload[modalValue.compliance_history_id]')}
                {/* <Table className={`stats ${localStorage.getItem("currentTheme")}`}
                    columns={columns1}
                    dataSource={modalData}
                    pagination={false}
                /> */}
                <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper ec-remark-table'}>
                    <div className='ant-table ant-table-bordered'>
                        <div className='ant-table-container'>
                            <table>
                                <colgroup>
                                    <col style={{ width: '10px' }}></col>
                                </colgroup>
                                <thead className="ant-table-thead " >
                                    <tr>
                                        <th className={'ant-table-cell'} style={{ width: '300px' }}>Documents</th>
                                        <th className={'ant-table-cell'} style={{ width: '50px' }}>Uploaded By</th>
                                        <th className={'ant-table-cell'} style={{ width: '30px' }}>Remove</th>

                                    </tr>
                                </thead>
                                <tbody className='ant-table-tbody'>
                                    {Object.keys(fileupload).length > 0 && fileupload[modalValue.compliance_history_id] && fileupload[modalValue.compliance_history_id].map((item, i) => {
                                        console.log(item, 'itemitemitem');
                                        return <tr>
                                            <td className='ant-table-cell'>
                                                <center>
                                                    <a href='javascript:;' onClick={() => {
                                                        const downloadpayload =
                                                            [
                                                                authtoken,
                                                                {
                                                                    "session_token": authtoken,
                                                                    "request": [
                                                                        "DownloadFile_temp",
                                                                        {
                                                                            "le_id": showdata.legal_entity,
                                                                            "c_id": 1,
                                                                            "d_id": modalValue.domain_id ? Number(modalValue.domain_id) : 0,
                                                                            "u_id": modalValue.unit_id,
                                                                            "start_date": modalValue.start_date,
                                                                            "file_name": item.file_name,
                                                                            "type": "",
                                                                            "upload_type": 2,
                                                                            "compliance_history_id": modalValue.compliance_history_id
                                                                        }
                                                                        // "request": [
                                                                        //     "DownloadFile_temp",
                                                                        //     {
                                                                        //         "le_id": "zp5Z",
                                                                        //         "c_id": 1,
                                                                        //         "d_id": 30,
                                                                        //         "u_id": 41038,
                                                                        //         "start_date": "17-Mar-2023",
                                                                        //         "file_name": "43eb362054ee4188933dcdfdf1159c72-19176.csv",
                                                                        //         "type": "",
                                                                        //         "upload_type": 2,
                                                                        //         "compliance_history_id": 42
                                                                        //     }
                                                                        // ]
                                                                    ]
                                                                }
                                                            ]
                                                        console.log(downloadpayload, 'downloadpayload');
                                                        downloadfiletemp({
                                                            payload: downloadpayload,
                                                            paramid: paramid,
                                                            content: item.file_content
                                                        });
                                                    }}>{item.file_name}</a>

                                                </center>
                                            </td>
                                            <td><center>Assignee</center></td>
                                            <td><center><CloseOutlined onClick={() => {
                                                const removepayload =
                                                    [
                                                        authtoken,
                                                        {
                                                            "session_token": authtoken,
                                                            "request": [
                                                                "RemoveFile_temp",
                                                                {
                                                                    "le_id": showdata.legal_entity,
                                                                    "c_id": 1,
                                                                    "selected_task_info": [
                                                                        {
                                                                            "compliance_history_id": modalValue.compliance_history_id,
                                                                            "d_id": showdata.domain ? Number(showdata.domain) : 0,
                                                                            "u_id": modalValue.unit_id,
                                                                            "start_date": modalValue.start_date,
                                                                            "file_info": [
                                                                                {
                                                                                    "file_size": item.file_size,
                                                                                    "file_name": item.file_name,
                                                                                    "file_content": null,
                                                                                    "type": "",
                                                                                    "compliance_history_id": modalValue.compliance_history_id
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                }






                                                                // "request": [
                                                                //     "DownloadFile_temp",
                                                                //     {
                                                                //         "le_id": "zp5Z",
                                                                //         "c_id": 1,
                                                                //         "d_id": 30,
                                                                //         "u_id": 41038,
                                                                //         "start_date": "17-Mar-2023",
                                                                //         "file_name": "43eb362054ee4188933dcdfdf1159c72-19176.csv",
                                                                //         "type": "",
                                                                //         "upload_type": 2,
                                                                //         "compliance_history_id": 42
                                                                //     }
                                                                // ]
                                                            ]
                                                        }
                                                    ]
                                                console.log(removepayload, 'removepayload');
                                                removedocument({
                                                    payload: removepayload,
                                                    paramid: paramid
                                                });
                                                fileupload[modalValue.compliance_history_id].splice(i, 1)
                                                if (fileupload[modalValue.compliance_history_id] && fileupload[modalValue.compliance_history_id].length == 0 && updateRemarks[modalValue.rowId] == null) {
                                                    checkBoxValue1[modalValue.rowId] = 0
                                                    setCheckBoxValue1([...checkBoxValue1])
                                                    fileupload1.splice(fileupload1.indexOf(modalValue.compliance_history_id), 1)
                                                    setFileUpload1(fileupload1)
                                                    if (fileupload[modalValue.compliance_history_id]) {
                                                        delete fileupload[modalValue.compliance_history_id]
                                                        setFileUpload(fileupload)
                                                    }
                                                    setModalDatas(false)
                                                }
                                            }} style={{ color: 'red' }} /></center></td>
                                        </tr>
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal open={modalDatas1}
                title="Document History"
                className={" add-service-prv " + localStorage.getItem('currentTheme')}
                footer={null}
                onCancel={modalcancel}
                maskClosable={false}
            >
                {console.log(fileupload[modalValue.compliance_history_id], 'fileupload[modalValue.compliance_history_id]')}
                {/* <Table className={`stats ${localStorage.getItem("currentTheme")}`}
                    columns={columns1}
                    dataSource={modalData}
                    pagination={false}
                /> */}
                <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper ec-remark-table'}>
                    <div className='ant-table ant-table-bordered'>
                        <div className='ant-table-container'>
                            <table>
                                <colgroup>
                                    <col style={{ width: '10px' }}></col>
                                </colgroup>
                                <thead className="ant-table-thead " >
                                    <tr>
                                        <th className={'ant-table-cell'} style={{ width: '300px' }}>Documents</th>
                                        <th className={'ant-table-cell'} style={{ width: '50px' }}>Uploaded By</th>
                                        <th className={'ant-table-cell'} style={{ width: '30px' }}>Remove</th>

                                    </tr>
                                </thead>
                                <tbody className='ant-table-tbody'>
                                    {modalValue1 && modalValue1.document && modalValue1.document.length > 0 && modalValue1.document.map((item, i) => {
                                        console.log(item, 'itemitemitem');
                                        return <tr>
                                            <td className='ant-table-cell'>
                                                <center>
                                                    <a href='javascript:;' onClick={() => {
                                                        const downloadpayload =
                                                            [
                                                                authtoken,
                                                                {
                                                                    "session_token": authtoken,
                                                                    "request": [
                                                                        "DownloadFile",
                                                                        {
                                                                            "le_id": showdata.legal_entity,
                                                                            "c_id": 1,
                                                                            "d_id": modalValue1.domain_id ? Number(modalValue1.domain_id) : 0,
                                                                            "u_id": modalValue1.unit_id,
                                                                            "start_date": modalValue1.start_date,
                                                                            "file_name": item
                                                                        }
                                                                    ]


                                                                }
                                                            ]
                                                        console.log(downloadpayload, 'downloadpayload');
                                                        downloadfiletemp1({
                                                            payload: downloadpayload,
                                                            paramid: paramid,
                                                            // content: item.file_content
                                                        });
                                                    }}>{item}</a>

                                                </center>
                                            </td>
                                            <td><center>Assignee</center></td>
                                            <td><center><CloseOutlined onClick={() => {
                                                const removepayload =
                                                    [
                                                        authtoken,
                                                        {
                                                            "session_token": authtoken,
                                                            // "request": [
                                                            //     "RemoveFile_temp",
                                                            //     {
                                                            //         "le_id": showdata.legal_entity,
                                                            //         "c_id": 1,
                                                            //         "selected_task_info": [
                                                            //             {
                                                            //                 "compliance_history_id": modalValue.compliance_history_id,
                                                            //                 "d_id": showdata.domain ? Number(showdata.domain) : 0,
                                                            //                 "u_id": modalValue.unit_id,
                                                            //                 "start_date": modalValue.start_date,
                                                            //                 "file_info": [
                                                            //                     {
                                                            //                         "file_size": item.file_size,
                                                            //                         "file_name": item.file_name,
                                                            //                         "file_content": null,
                                                            //                         "type": "",
                                                            //                         "compliance_history_id": modalValue.compliance_history_id
                                                            //                     }
                                                            //                 ]
                                                            //             }
                                                            //         ]
                                                            //     }






                                                            //     // "request": [
                                                            //     //     "DownloadFile_temp",
                                                            //     //     {
                                                            //     //         "le_id": "zp5Z",
                                                            //     //         "c_id": 1,
                                                            //     //         "d_id": 30,
                                                            //     //         "u_id": 41038,
                                                            //     //         "start_date": "17-Mar-2023",
                                                            //     //         "file_name": "43eb362054ee4188933dcdfdf1159c72-19176.csv",
                                                            //     //         "type": "",
                                                            //     //         "upload_type": 2,
                                                            //     //         "compliance_history_id": 42
                                                            //     //     }
                                                            //     // ]
                                                            // ]

                                                            "request": [
                                                                "GetFileSize",
                                                                {
                                                                    "le_id": showdata.legal_entity,
                                                                    "c_id": 1,
                                                                    "d_id": modalValue1.domain_id ? Number(modalValue1.domain_id) : 0,
                                                                    "u_id": modalValue1.unit_id,
                                                                    "start_date": modalValue1.start_date,
                                                                    "file_name": item,
                                                                    "remove_type": 1,
                                                                    "compliance_history_id": modalValue1.compliance_history_id
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                console.log(removepayload, 'removepayload');
                                                removedocument({
                                                    payload: removepayload,
                                                    paramid: paramid
                                                });
                                                modalValue1 && modalValue1.document && modalValue1.document.length > 0 && modalValue1.document.map((items, i) => {
                                                    if (items == item) {
                                                        modalValue1.document.splice(i, 1)
                                                        // setModalValue1({
                                                        //     ...modalvalue1,

                                                        // })
                                                    }
                                                })
                                            }} style={{ color: 'red' }} /></center></td>
                                        </tr>
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Modal>

        </div >

    )
}

export default connect(mapStateToProps, {
    emailsave,
    GetGroupListData,
    FetchEmails,
    verifypassword,
    updateblockoption,
    GetRemarksData,
    getFilterDataValues,
    EasyUploadFile,
    saveuploadStatus,
    RemarkHistory,
    getShowdata,
    getTabledata,
    downloadfiletemp,
    downloadfiletemp1,
    removedocument
})(CV_Easyupload)
