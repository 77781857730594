import { CVMONTHWISEREPORT, COMPLIANCE_CERTIFICATE_FILTER, SEARCH_COMPLIANCE, CERTIFICATE_FILE, DOWNLOAD_COMPLIANCE, LABEL_LIST, DETAILS_MODAL, MAPPING_DETAILS, COMPLIANCE_STATUS_MODAL, COMPLIANCE_CERTIFICATE_TABLE, TOGGLE_COMMON_LOADER, MONTHWISEREPORTTABLE, INVALID_FILE } from './../../types/index'
const initialState = {
    complianceCertificate: {
        FilterData: [],
        tabledata: [],
        complianceStatusModal: [],
        detailsModal: [],
        MappingDetails: [],
        labelList: [],
        searchData: [],
        downloadFile: [],
        fileData: '',
        invalidFile: ''
    }
}
export default function (state = initialState, action) {

    const { type, payload } = action;
    switch (type) {
        case COMPLIANCE_CERTIFICATE_FILTER:
            {
                console.log(payload, "payload");
                return {
                    ...state,
                    complianceCertificate: {
                        ...state.complianceCertificate,
                        FilterData: payload,
                    },
                };
            }
        case COMPLIANCE_CERTIFICATE_TABLE:
            {
                console.log(payload, "payload");
                return {
                    ...state,
                    complianceCertificate: {
                        ...state.complianceCertificate,
                        tabledata: payload,
                    },
                };
            }
        case COMPLIANCE_STATUS_MODAL:
            {
                console.log(payload, "payload");
                return {
                    ...state,
                    complianceCertificate: {
                        ...state.complianceCertificate,
                        complianceStatusModal: payload,
                    },
                };
            }
        case DETAILS_MODAL:
            {
                console.log(payload, "payload");
                return {
                    ...state,
                    complianceCertificate: {
                        ...state.complianceCertificate,
                        detailsModal: payload,
                    },
                };
            }
        case MAPPING_DETAILS:
            {
                console.log(payload, "payload");
                return {
                    ...state,
                    complianceCertificate: {
                        ...state.complianceCertificate,
                        MappingDetails: payload,
                    },
                };
            }
        case LABEL_LIST:
            {
                console.log(payload, "payload");
                return {
                    ...state,
                    complianceCertificate: {
                        ...state.complianceCertificate,
                        labelList: payload,
                    },
                };
            }
        case SEARCH_COMPLIANCE:
            {
                console.log(payload, "payload");
                return {
                    ...state,
                    complianceCertificate: {
                        ...state.complianceCertificate,
                        searchData: payload,
                    },
                };
            }
        case DOWNLOAD_COMPLIANCE:
            {
                console.log(payload, "payload");
                return {
                    ...state,
                    complianceCertificate: {
                        ...state.complianceCertificate,
                        downloadFile: payload,
                    },
                };
            }

        case INVALID_FILE:
            {
                console.log(payload, "payload");
                return {
                    ...state,
                    complianceCertificate: {
                        ...state.complianceCertificate,
                        invalidFile: payload,
                    },
                };
            }
            case CERTIFICATE_FILE:
                {
                    console.log(payload, "payload");
                    return {
                        ...state,
                        complianceCertificate: {
                            ...state.complianceCertificate,
                            fileData: payload[0],
                        },
                    };
                }
        default:
            return state;
    }
}