import React from 'react'
import moment from 'moment';
import { Switch, Tag } from 'antd';
import { Collapse, Select, Input, DatePicker, Popconfirm, Card, Pagination, Spin, Popover, Drawer, Space, Button, Table, Tooltip, Modal, Descriptions, Empty } from 'antd';
import DataTable from "react-data-table-component";
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import ScrollToTop from 'react-scroll-to-top';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import { ArrowRightOutlined, FullscreenOutlined, CloseCircleOutlined, CloseOutlined, DownOutlined, BookTwoTone, ArrowLeftOutlined, PlayCircleOutlined, SearchOutlined, FilterFilled, FilterOutlined, ExclamationCircleFilled, UpOutlined, InfoCircleTwoTone, InfoCircleFilled } from '@ant-design/icons';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation, Link,useHistory } from 'react-router-dom';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { getRemarksHistoryData } from '../../Store/Action/Dashboard/RiskChart';
import { getdownloadpath } from '../../Store/Action/Transcations/ComplianceApproval';
import { getComparativechart, getCommonData, getTimeZoneData, getComparativeTableData } from '../../Store/Action/Dashboard/ComparativeTrendChart';
import { getComplianceTrendchart, getComplianceTrendShowMoreTableData, getComplianceTrendTableData } from '../../Store/Action/Dashboard/ComplianceTrend';
import { toast } from 'react-toastify';
import { HomeRedirect, CountrySplit } from './../../Libs/country'
// import "core-js/stable";
// import "regenerator-runtime/runtime";
import url, { getUrl } from './../../Libs/URL'
import ScrollButton from '../Scrollbar';
const homepage = HomeRedirect()
const uniq_country_data = CountrySplit()
const urls = getUrl()

// import "core-js/stable";
// import "regenerator-runtime/runtime";

const mapStateToProps = (state) => ({
    data: state.ComplianceTrend.complianceTrendChart.chartData,
    commonData: state.ComparativeTrend.comparativeTrendChart.Data,
    dataYears: state.ComparativeTrend.comparativeTrendChart.yearData,
    // data: state.ComparativeTrend.comparativeTrendChart.chartData,
    time: state.ComplianceTrend.complianceTrendChart.time,
    timeZoneData: state.ComplianceTrend.complianceTrendChart.timeZoneData,
    tableValue: state.ComparativeTrend.comparativeTrendChart.tableData,
    remarksHistoryData: state.RiskChart.riskChart.remarksHistoryData,
    showMoreData: state.ComplianceTrend.complianceTrendChart.showMoreData,
    TableValue: state.ComplianceTrend.complianceTrendChart.tableData,


})

const ComparativeTrend = ({
    getFilterValue,
    getComparativechart,
    getCommonData,
    data,
    commonData,
    time,
    getTimeZoneData,
    timeZoneData,
    getComparativeTableData,
    tableValue,
    dataYears,
    getComplianceTrendchart,
    getRemarksHistoryData,
    remarksHistoryData,
    getdownloadpath,
    getComplianceTrendShowMoreTableData,
    showMoreData,
    getComplianceTrendTableData,
    TableValue
    // tableData
}) => {
    console.log(data, 'data12345');
    console.log(timeZoneData, 'timeZoneData');
    const _ = require("lodash");
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [dataValue, setDataValue] = useState([])
    console.log(dataValue, 'dataValue');
    const [notcomplieddata, setNotcomplieddata] = useState([])
    const [delayedData, setDelayedData] = useState([])
    const [toggleButton, setToggleButton] = useState('Month')
    const [yearData, setYearData] = useState([])
    const [taskDistributionTable, setTaskDistributionTable] = useState([])
    var [finalcount, setFinalcount] = useState(1)

    const [filterData, setFilterData] = useState({
        country: '',
        domain: '',
        countryArr: [],
        legalentity: '',
        division: '',
        unit: '',
        business: '',
        category: '',
        unit: '',
        leIds: ''
    })
    console.log(filterData, 'filterData');
    const { Panel } = Collapse;
    const [chartId, setChartId] = useState()
    const [field, setfield] = useState({
        country: [],
        domain: [],
        legalEntity: [],
        business: [],

    })
    const [historymodal, sethistorymodal] = useState(false)
    const [remarksModalData, setRemarksModalData] = useState({
        le_id: '',
        c_id: '',
        d_id: '',
        u_id: '',
        start_date: '',
    })
    const [tempcriticalityTable, setTempcriticalityTable] = useState([])

    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const dispatch = useDispatch();
    const [type, setType] = useState('')
    const [legalEntityArray, setLegalEntityArray] = useState([])
    const [columnState, setCoulmnState] = useState(false)
    const [open, setOpen] = useState(false)
    const [table, setTable] = useState(false)
    const [clickState, setClickState] = useState(false)
    var [indexValue, setIndexValue] = useState(1)
    const [division, setDivision] = useState([])
    const [category, setCategory] = useState([])
    const [fieldType, setFieldType] = useState("")
    console.log(fieldType, 'fieldType');
    const [newCountryDomain, setNewCountryDomain] = useState([])

    const [unit, setUnit] = useState([])
    const chart = useRef(null);
    const { Option } = Select;
    const [year, setYear] = useState(moment().year())
    const [prevYear, setPrevYear] = useState(moment().year() - 4)
    const [timeZone, setTimeZone] = useState("Afica/Accara")
    const [chartType, setChartType] = useState("all")
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const sessionvalue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionvalue)
    const [entityData, setEntityData] = useState([])
    const TimeZoneValue = sessionParsedValue.login_response
    const sessionArr = sessionParsedValue.entity_info
    console.log(commonData, 'commonData');
    const location = useLocation();
    const [paramid, setParamId] = useState("")
    const [generatedOn, setGeneratedOn] = useState(false)
    const [cIds, setCIds] = useState([])
    let [count, setCount] = useState()
    console.log(count, 'count');
    const [showmoreCount, setShowMoreCount] = useState(0)
    const [searchingValue, setSearchingValue] = useState([])
    const [searchedValue, setSearchedValue] = useState({
        bgGroup: '',
        legalEntity: '',
        division: '',
        compliance: '',
        criticality: '',
        assignedTo: '',
        overdue: ''
    })
    const [chartMonth, setChartMonth] = useState(1)

    const [criticalityValue, setCriticalityValue] = useState()
    const myRef1 = useRef(null);
    const myRef2 = useRef(null);
    const myRef3 = useRef(null);
    const myRef4 = useRef(null);
    const myRef5 = useRef(null);
    const myRef6 = useRef(null);
    const myRef7 = useRef(null);
    const [filterDataNames, setFilterDataNames] = useState({
        country: '',
        domain: '',
        legalentity: '',
        division: '',
        unit: '',
        business: '',
        category: '',
        unit: ''
    })
    const [go, setGo] = useState(false)
    const [domainName, setDomainName] = useState()
    const { OptGroup } = Select;
    const [domainListData, setDomainListData] = useState([])
    const [divisionListData, setDivisionListData] = useState([])
    const [categoryListData, setCategoryListData] = useState([])
    const [unitListData, setUnitListData] = useState([])
    const [bgIds, setBgIds] = useState([])
    const [groupCheckBox, setGroupCheckBox] = useState(true)
    const [checkBox, setCheckBox] = useState(true)
    console.log(checkBox, 'checkBox');
    const [countryDomain, setCountryDomain] = useState([])
    const [dIds, setDIds] = useState([])
    const country_info = sessionParsedValue.country_info
    const [errorField, setErrorField] = useState('')
    const [fieldTypeClick, setFieldTypeClick] = useState("")
    const [textArray, setTextArray] = useState([])
    console.log(textArray, 'textArray');
    const [tableData, setTableData] = useState([])
    const [filteredInfo, setFilteredInfo] = useState({});
    const searchInput = useRef(null);
    const [searchText, setSearchText] = useState({
        data: '',
        index: 0
    });
    const [searchedColumn, setSearchedColumn] = useState('');
    const [countryData, setCountryData] = useState([])
    const [domainData, setDomainData] = useState([])
    console.log(domainData, 'domainData');
    const [countryName, setCountryName] = useState()
    const [tempArr, setTempArr] = useState([])
    const [chartData, setChartData] = useState([])
    console.log(chartData, 'chartData');
    const [tempData, setTempData] = useState([])
    const [resultArray, setResultArray] = useState([])
    const [filteredCountries, setFilteredCountries] = useState([])
    const sidefilterData = localStorage.getItem('filterChange')
    const parsedSideFilterData = JSON.parse(sidefilterData)
    const sideFilterState = localStorage.getItem('filterState')
    const [countValue, setCountValue] = useState(0)
    const [sortingpurpose, setSortingpurpose] = useState([])
    const isCountryValueTrue = localStorage.getItem('countryState')
    const historynew = useHistory()

    console.log(tempData, 'tempData');
    console.log(field, 'fieldddd');
    console.log(data.compliance_data, 'data.compliance_data');

    const setFocus = (ref) => {
        setTimeout(() => {
            ref.current.focus();
        });
    };

    useEffect(() => {
        if (commonData && commonData.d_info && commonData.d_info.length > 0) {
            let tempArr = []
            let tempIds = []
            let textArr = []
            let cNames = []
            let uniqueCountry = []
            let countryName;
            // setDomainData(commonData.d_info)
            for (let i in commonData.d_info) {
                tempIds.push(commonData.d_info[i].d_id)
                tempArr.push({
                    'd_id': commonData.d_info[i].d_id,
                    'c_id': commonData.d_info[i].c_id,
                    "month_from": commonData.d_info[i].month_from,
                    "month_to": commonData.d_info[i].month_to
                })
                uniqueCountry = _.uniqBy(commonData && commonData.countries, function (e) {
                    return e.c_id;
                });
                setCountryData(uniqueCountry)
                let id = uniqueCountry && uniqueCountry.length > 0 && uniqueCountry.map((item, i) => {
                    return item.c_id
                })
                setCIds(id)
                console.log(uniqueCountry, 'uniqueCountry');
                countryName = _.find(uniqueCountry, { c_id: Number(commonData.d_info[i].c_id) })

                cNames.push(commonData.d_info[i].d_name)

            }
            // var arr = []
            var obj = {}
            uniqueCountry.forEach(function (a) {
                var arr2 = []
                commonData.d_info.forEach(function (b) {
                    var key = a.c_name
                    if (a.c_id == b.c_id) {
                        arr2.push(b.d_name)
                        obj[key] = arr2.join()
                    }
                });
            });
            let temp = []
            for (let m in obj) {
                console.log(obj[m], 'objmm');
                // let sm = `${m} - ${obj[m]}`
                let expectedCountryDomain = `[bold]${m}[/] - ${obj[m]}`
                temp.push(expectedCountryDomain)
            }
            let newCountryDomain = temp.join("[bold] | [/]")
            // setTextArray(newCountryDomain)
            setDIds(tempIds)
            setCountryDomain(tempArr)
        }


    }, [commonData])

    useEffect(() => {
        var obj = {}
        countryData.forEach(function (a) {
            var arr2 = []
            domainData.forEach(function (b) {
                var key = a.c_name
                if (a.c_id == b.c_id) {
                    arr2.push(b.d_name)
                    obj[key] = arr2.join()
                }
            });
        });
        let temp = []
        for (let m in obj) {
            console.log(obj[m], 'objmm');
            // let sm = `${m} - ${obj[m]}`
            let expectedCountryDomain = `[bold]${m}[/] - ${obj[m]}`
            temp.push(expectedCountryDomain)
        }
        let newCountryDomain = temp.join("[bold] | [/]")
        setTextArray(newCountryDomain)

    }, [countryData, domainData])

    const historycancel = () => {
        sethistorymodal(false);
    }

    console.log(tableData, 'tableDataa');

    useEffect(() => {
        let newCountry = []
        let newDomain = []

        for (let j in filterData.country) {
            let filterCountry = _.filter(commonData.countries, { c_id: Number(filterData.country[j]) })
            // temp2.push(filterDomain)
            for (let i in filterCountry) {
                newCountry.push(filterCountry[i])
            }

        }
        // for (let k in filterData.domain) {
        //     let filterDomain = _.filter(commonData.d_info, { d_id: Number(filterData.domain[k]) })
        //     // temp2.push(filterDomain)
        //     for (let l in filterDomain) {
        //         newDomain.push(filterDomain[l])
        //     }

        // }
        const uniqueCountry = _.uniqBy(newCountry && newCountry, function (e) {
            return e.c_id;
        });
        setCountryData(uniqueCountry)
        // setDomainData(newDomain)
        console.log(newCountry, 'newCountry');
        console.log(newDomain, 'newDomain');
        console.log(filterData.country, 'filterData.country');
        console.log(filterData.domain, 'filterData.domain');

    }, [filterData.country, filterData.domain])

    // useEffect(() => {
    //     if (chartType == 'complied') {
    //         if (dataValue && dataValue.length > 0) {
    //             let counName = _.find(uniq_country_data, { c_id: Number(dataValue && dataValue[0].filter_id) })
    //             setCountryName(counName && counName.c_name)
    //         }
    //     }
    //     if (chartType == 'notComplied') {
    //         if (notcomplieddata && notcomplieddata.length > 0) {
    //             let counName = _.find(uniq_country_data, { c_id: Number(notcomplieddata && notcomplieddata[0].filter_id) })
    //             setCountryName(counName && counName.c_name)
    //         }
    //     }
    //     if (chartType == 'delayedComplied') {
    //         if (delayedData && delayedData.length > 0) {
    //             let counName = _.find(uniq_country_data, { c_id: Number(delayedData && delayedData[0].filter_id) })
    //             setCountryName(counName && counName.c_name)
    //         }
    //     }

    // }, [dataValue, notcomplieddata, delayedData, uniq_country_data])

    useEffect(() => {
        let newDom = []
        for (let i in commonData.d_info) {
            newDom.push({
                "d_id": commonData.d_info[i].d_id,
                "d_name": commonData.d_info[i].d_name,
                "is_active": commonData.d_info[i].is_active,
                "c_id": commonData.d_info[i].c_id,
                "month_from": commonData.d_info[i].month_from,
                "month_to": commonData.d_info[i].month_to,
                "cd_id": `${commonData.d_info[i].c_id}-${commonData.d_info[i].d_id}`
            })
        }

        let newDomain = []
        if (newDom && newDom.length > 0) {
            for (let k in filterData.domain) {
                console.log(filterData.domain[k], 'sssssss');
                let filterDomain = _.filter(newDom, { cd_id: `${filterData.domain[k]}` })
                console.log(filterDomain, 'filterDomain');
                // newDomain.push(filterDomain)
                for (let l in filterDomain) {
                    newDomain.push(filterDomain[l])
                }

            }
        }

        let newDomain1 = []
        if (newDomain && newDomain.length > 0) {
            for (let k in filterData.domain) {
                let filterDomain = _.filter(newDomain, { cd_id: `${filterData.domain[k]}` })
                // newDomain.push(filterDomain)
                for (let l in filterDomain) {
                    newDomain1.push(filterDomain[l])
                }

            }
            console.log(newDomain1, 'newDomain1');
        }
        setDomainData(newDomain)

        console.log(newDomain, 'newDomain');
        console.log(newDom, 'newDom');
    }, [commonData.d_info, filterData.domain])


    useEffect(() => {
        if (toggleButton === 'Year') {
            console.log(data.years, 'data.years');
            if (data && data.years && data.years.length > 0) {
                setYearData(data.years)
            }
        }
    }, [data.years, toggleButton])


    console.log(tableData, 'tableData');
    console.log(tableValue, 'tableValue');



    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        if (e) {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        } else {
            setSelectedKeys([])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        }
    };

    const handleReset = (clearFilters, handleSearch, confirm, dataIndex, setSelectedKeys) => {
        handleSearch('', confirm, dataIndex, setSelectedKeys)
        clearFilters();
        setSearchText('');
    };

    const clearFocus = (searchInput) => {
        setTimeout(() => searchInput.current?.select(), 100);
    }


    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
            >
                <Input className='form-control'
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    autoFocus={'on'}
                    value={selectedKeys[0]}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}

                />
                <CloseCircleOutlined className='table-filter-reset'
                    onClick={() => {
                        handleReset(clearFilters,
                            handleSearch, confirm, dataIndex, setSelectedKeys)
                        clearFocus(searchInput)
                    }
                    } />
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    //     // color: filtered ? '#1890ff' : undefined,
                    //     fontWeight: 'bold',
                    //     // backgroundColor:'blue',
                    //     fontSize: '15px',
                    //     color: 'black'
                }}
            />
            // <FileSearchOutlined 
            // style={{
            //     // color: filtered ? '#1890ff' : undefined,
            //     fontWeight:'bold',
            //     // backgroundColor:'blue',
            //     // fontSize:'20px',
            //     color:'black'
            // }}
            // />
            // <i class="bi bi-search"></i>
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });
    const [sortedInfo, setSortedInfo] = useState({});

    const handleChange = (pagination, filters, sorter) => {

        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const columns = [
        {
            title: "#",
            filterable: true,
            dataIndex: 'index',
            key: 'index',
            // render: (row, index, i) => {
            //     return (
            //         <><p>{(counts + 1)}</p>
            //             <span hidden>{counts = counts + 1}</span>
            //         </>)
            // },
            width: '10%',
            align: 'center'
        },
        ...((fieldType === 'LegalEntity' && go === true) || (fieldType === 'Category' && go === true) || (fieldType === 'Unit' && go === true) ?
            [] :
            [{
                title: "Business Group",
                ...getColumnSearchProps('bg_name', "Business Group"),
                dataIndex: 'bg_name',
                key: 'bg_name',
                ellipsis: false,
                width: '25%',
                filteredValue: filteredInfo.bg_name || null,
            },
            {
                title: 'Legal Entity',
                ...getColumnSearchProps('le_name', 'Legal Entity'),
                dataIndex: false,
                key: 'le_name',
                ellipse: false,
                width: '25%',
                filteredValue: filteredInfo.le_name || null,
                render: (record) => {
                    return <span title={record.le_name} className='cut-text'>
                        {record.le_name}</span>
                }
            }
            ]
        ),
        {
            title: 'Division',
            ...getColumnSearchProps('div_name', 'Division'),
            dataIndex: false,
            key: 'div_name',
            // width: '30%',
            ellipsis: false,
            filteredValue: filteredInfo.div_name || null,
            render: (record) => {
                return <span title={record.div_name} className='cut-text'>
                    {record.div_name}</span>
            }

        },
        {
            title: 'Compliance',
            ...getColumnSearchProps('comp_name', 'Compliance'),
            dataIndex: false,
            key: 'comp_name',
            width: '90%',
            ellipse: false,
            render: (row, record) => {
                console.log(record, 'record');
                if (record.compfielaw_url != '') {
                    const content = (
                        <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                            <tbody>
                                <tr>
                                    <th>Compliance Description</th>
                                    <td>{record.descp}</td>
                                </tr>
                                <tr>
                                    <th>Document Reference Number</th>
                                    <td>{record.doc_ref_num}</td>
                                </tr>
                            </tbody>
                        </table>
                    );
                    return (
                        <>
                            <div>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <Tooltip title={
                                                <table style={{ width: "100%", borderCollapse: 'collapse', border: '1px solid #636262', tableLayout: 'auto' }}>
                                                    <tbody>
                                                        <tr>
                                                            <th style={{ border: '1px solid #636262', marginLeft: '5%', marginRight: '5%' }}><center>Compliance Description</center></th>
                                                            <th style={{ border: '1px solid #636262' }}><center>Document Reference Number</center></th>

                                                        </tr>
                                                        <tr>
                                                            <td style={{ border: '1px solid #636262' }}><center>{record.descp ? record.descp : '-'}</center></td>
                                                            <td style={{ border: '1px solid #636262' }}><center>{record.doc_ref_num ? record.doc_ref_num : '-'}</center></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            }></Tooltip><ExclamationCircleFilled className='me-1' />
                                            {/* <Popover content={content} title="">
                                                <Button style={{ border: 0 }}>
                                                <i class="bi-info-circle-fill"></i>
                                                </Button>
                                                <ExclamationCircleFilled className='me-1' />
                                            </Popover> */}
                                        </div>
                                        <div className="col-md-10" style={{ marginTop: '2%' }}>
                                            <p title={record.div_name} className='cut-text'>
                                                <Tooltip style={{ marginRight: '20%' }} title='Compfie Law Knowlegde Portal'>
                                                    <a href={`${record.compfielaw_url}`}>
                                                        <BookTwoTone />
                                                    </a>
                                                </Tooltip>&nbsp;{record.comp_name}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </>
                    )
                }
                else if (record.remarks_count > 0) {
                    const content = (
                        <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                            <tbody>
                                <tr>
                                    <th>Compliance Description</th>
                                    <td>{record.descp}</td>
                                </tr>
                                <tr>
                                    <th>Document Reference Number</th>
                                    <td>{record.doc_ref_num}</td>
                                </tr>
                            </tbody>
                        </table>
                    );
                    return (
                        <>


                            <Tooltip title={
                                <table style={{ width: "100%", borderCollapse: 'collapse', border: '1px solid #636262', tableLayout: 'auto' }}>
                                    <tbody>
                                        <tr>
                                            <th style={{ border: '1px solid #636262', marginLeft: '5%', marginRight: '5%' }}><center>Compliance Description</center></th>
                                            <th style={{ border: '1px solid #636262' }}><center>Document Reference Number</center></th>

                                        </tr>
                                        <tr>
                                            <td style={{ border: '1px solid #636262' }}><center>{record.descp ? record.descp : '-'}</center></td>
                                            <td style={{ border: '1px solid #636262' }}><center>{record.doc_ref_num ? record.doc_ref_num : '-'}</center></td>
                                        </tr>
                                    </tbody>
                                </table>
                            }></Tooltip><ExclamationCircleFilled className='me-1' />
                            {/* <Popover content={content} title="">
                                                <Button style={{ border: 0 }}>
                                                <i class="bi-info-circle-fill"></i>
                                                </Button>
                                                <ExclamationCircleFilled className='me-1' />
                                            </Popover> */}

                            <span title={record.div_name}>
                                <Tooltip style={{ marginRight: '2%' }} title='Click to View Remarks History'>
                                    <a href="javascript:;"
                                        onClick={() => {
                                            console.log(record, 'reccc');
                                            setRemarksModalData({
                                                ...remarksModalData,
                                                le_id: record.le_id,
                                                c_id: Number(record.c_id),
                                                d_id: Number(record.d_id),
                                                u_id: Number(record.u_id),
                                                start_date: record.start_date
                                            })
                                            const payload = [
                                                authtoken,
                                                {
                                                    "session_token": authtoken,
                                                    "request": [
                                                        "GetComplianceRemarksHistoryData",
                                                        {
                                                            "legal_entity_id": record.le_id,
                                                            "unit_id": Number(record.u_id),
                                                            "compliance_history_id": Number(record.compliance_history_id)
                                                        }
                                                    ]
                                                }
                                            ]
                                            getRemarksHistoryData({
                                                payload: payload,
                                                paramid: paramid
                                            })
                                            sethistorymodal(true);

                                        }}>
                                        <InfoCircleTwoTone />
                                    </a>
                                </Tooltip>&nbsp;{record.comp_name}</span>

                        </>
                    )

                }
                else {
                    const content = (
                        <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                            <tbody>
                                <tr>
                                    <th>Compliance Description</th>
                                    <td>{record.descp}</td>
                                </tr>
                                <tr>
                                    <th>Document Reference Number</th>
                                    <td>{record.doc_ref_num}</td>
                                </tr>
                            </tbody>
                        </table>
                    );
                    return (
                        <>
                            <div>
                                <div>
                                    {/* <Popover content={content} title="">
                                    <Button style={{ border: 0 }}>
                                    <i class="bi-info-circle-fill"></i>
                                    <ExclamationCircleFilled className='me-1' />
                                    </Button>
                                </Popover> */}
                                    <Tooltip title={
                                        <table style={{ width: "100%", borderCollapse: 'collapse', border: '1px solid #636262', tableLayout: 'auto' }}>
                                            <tbody>
                                                <tr>
                                                    <th style={{ border: '1px solid #636262', marginLeft: '5%', marginRight: '5%' }}><center>Compliance Description</center></th>
                                                    <th style={{ border: '1px solid #636262' }}><center>Document Reference Number</center></th>

                                                </tr>
                                                <tr>
                                                    <td style={{ border: '1px solid #636262' }}><center>{record.descp ? record.descp : '-'}</center></td>
                                                    <td style={{ border: '1px solid #636262' }}><center>{record.doc_ref_num ? record.doc_ref_num : '-'}</center></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    }><ExclamationCircleFilled className='me-1' /></Tooltip>
                                    {record.comp_name}
                                </div>
                            </div>

                        </>
                    )
                }
            },
            filteredValue: filteredInfo.comp_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        {
            title: "Criticality",
            ...getColumnSearchProps('criticality', "Criticality"),
            dataIndex: 'criticality',
            key: 'criticality',
            ellipsis: false,
            // render: (row, record) => {
            //     return (
            //         <>
            //             <p>{record.criticality}</p>
            //         </>
            //     )
            // },
            width: '100%',
            align: 'center',
            filteredValue: filteredInfo.criticality || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,


        },
        {
            title: "Assigned To",
            ...getColumnSearchProps('assignee_name', "Assigned To"),
            dataIndex: 'assignee_name',
            key: 'assignee_name',
            ellipsis: false,
            // render: (row, record) => {
            //     return (
            //         <>
            //             <p>{record.assignee_name}</p>
            //         </>
            //     )
            // },
            width: '100%',
            filteredValue: filteredInfo.assignee_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        ...(chartType === 'complied' ?
            [] :
            [{
                title: chartType === "notComplied" ? "Over Due" : chartType === 'complied' ? '' : "Delayed By",
                ...getColumnSearchProps('ageing', 'Over Due'),
                dataIndex: 'ageing',
                key: 'ageing',
                width: 12,
                ellipsis: false,
                render: (row, record) => {
                    if (chartType === 'complied') {
                        return null
                    }
                    else {
                        return (
                            <>
                                <p>{record.ageing}</p>
                            </>
                        )
                    }
                },
                width: 13,
                align: 'center',
                filteredValue: filteredInfo.ageing || null,
                // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
                // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,


            }])
    ];

    const columns1 = [
        {
            title: "#",
            filterable: true,
            dataIndex: 'index',
            key: 'index',
            // render: (row, index, i) => {
            //     return (
            //         <><p>{(counts + 1)}</p>
            //             <span hidden>{counts = counts + 1}</span>
            //         </>)
            // },
            width: 7,
            align: 'center'
        },
        {
            title: 'Compliance',
            ...getColumnSearchProps('comp_name', 'Compliance'),
            dataIndex: false,
            key: 'comp_name',
            ellipse: true,
            render: (row, record) => {
                console.log(record, 'record');
                if (record.compfielaw_url != '') {
                    const content = (
                        <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                            <tbody>
                                <tr>
                                    <th>Compliance Description</th>
                                    <td>{record.descp}</td>
                                </tr>
                                <tr>
                                    <th>Document Reference Number</th>
                                    <td>{record.doc_ref_num}</td>
                                </tr>
                            </tbody>
                        </table>
                    );
                    return (
                        <>
                            <div>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <Popover content={content} title="">
                                                <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
                                            </Popover>
                                        </div>
                                        <div className="col-md-10" style={{ marginTop: '2%' }}>
                                            <p title={record.comp_name} className='cut-text'>
                                                <Tooltip style={{ marginRight: '20%' }} title='Compfie Law Knowlegde Portal'>
                                                    <a href={`${record.compfielaw_url}`}>
                                                        <BookTwoTone />
                                                    </a>
                                                </Tooltip>&nbsp;{record.comp_name}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </>
                    )
                }
                const content = (
                    <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                        <tbody>
                            <tr>
                                <th>Compliance Description</th>
                                <td>{record.descp}</td>
                            </tr>
                            <tr>
                                <th>Document Reference Number</th>
                                <td>{record.doc_ref_num}</td>
                            </tr>
                        </tbody>
                    </table>
                );
                return (
                    <>
                        <div>
                            <div className="mb-3">
                                <Popover content={content} title="">
                                    <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
                                </Popover>{record.comp_name}
                            </div>
                        </div>

                    </>
                )
            },
            width: 25,
            filteredValue: filteredInfo.comp_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        {
            title: "Criticality",
            ...getColumnSearchProps('criticality', "Criticality"),
            dataIndex: 'criticality',
            key: 'criticality',
            ellipsis: false,
            // render: (row, record) => {
            //     return (
            //         <>
            //             <p>{record.criticality}</p>
            //         </>
            //     )
            // },
            width: 13,
            align: 'center',
            filteredValue: filteredInfo.criticality || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,


        },
        {
            title: "Assigned To",
            ...getColumnSearchProps('assignee_name', "Assigned To"),
            dataIndex: false,
            key: 'assignee_name',
            ellipsis: false,
            render: (record) => {
                return <p title={record.assignee_name} className='cut-text'>
                    {record.assignee_name}</p>
            },
            // render: (row, record) => {
            //     return (
            //         <>
            //             <p>{record.assignee_name}</p>
            //         </>
            //     )
            // },
            width: 13,
            filteredValue: filteredInfo.assignee_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        }
    ]

    useEffect(() => {
        if (TableValue) {
            if (TableValue.complied && TableValue.complied.length > 0) {
                let sortedArray = _.orderBy(TableValue.complied, ['le_name'], ['asc'])
                console.log(sortedArray, 'sortedArray');
                setTableData(sortedArray)
                setTable(true)
            }
            else if (TableValue.delayed && TableValue.delayed.length > 0) {
                let sortedArray = _.orderBy(TableValue.delayed, ['le_name'], ['asc'])
                console.log(sortedArray, 'sortedArray');
                setTableData(sortedArray)
                setTable(true)

            }
            else if (TableValue.not_complied && TableValue.not_complied.length > 0) {
                let sortedArray = _.orderBy(TableValue.not_complied, ['le_name'], ['asc'])
                console.log(sortedArray, 'sortedArray');
                setTableData(sortedArray)
                setTable(true)

            }
        }
    }, [TableValue])

    useEffect(() => {
        if (tableData && tableData.length > 0) {
            let count = []
            for (let i in tableData) {
                for (let j in tableData[i].drill_compliances) {
                    let a = tableData[i].drill_compliances[j]
                    let data = Object.values(a)
                    count.push(data.length)

                    // count.push(data[i])
                    console.log(data, ' taskDistributionTable[i].ap_compliances');

                }
            }
            console.log(count, 'count');
            let countValues = count.reduce(function (accumulator, curValue) {
                return accumulator + curValue
            }, 0)
            setShowMoreCount(countValues)
            console.log(countValues, 'countValue9999');
        }

    }, [tableData])


    useEffect(() => {
        if (tableData && tableData.length > 0) {
            //1st logic (Not Complied)
            if (showMoreData && showMoreData.not_complied && showMoreData.not_complied.length > 0) {
                let newTable = tableData
                let mergedArray = []
                let tableDataLastObj = newTable[newTable.length - 1]
                let showMoreDataFirstObj = showMoreData.not_complied[0]
                console.log(tableDataLastObj, 'tableDataLastObj');
                console.log(showMoreDataFirstObj, 'showMoreDataFirstObj');
                if (tableDataLastObj && showMoreDataFirstObj) {
                    let previousLEUnit = tableDataLastObj.le_name + '-' + tableDataLastObj.u_name
                    let currentLEUnit = showMoreDataFirstObj.le_name + '-' + showMoreDataFirstObj.u_name
                    if (previousLEUnit == currentLEUnit) {
                        // let showMoreNew = _.filter(showMoreData.not_complied, { le_name: tableDataLastObj.le_name, u_name: tableDataLastObj.u_name })                   
                        let existDrill = tableDataLastObj.drill_compliances
                        let newDrill = showMoreDataFirstObj.drill_compliances
                        let exisObjKeys = _.keys(existDrill)
                        let newKeys = _.keys(newDrill)
                        let matchedArrayFilter = _.filter(showMoreData.not_complied, { u_name: tableDataLastObj.le_name })
                        let unMatchedArrayFilter = _.reject(showMoreData.not_complied, { u_name: tableDataLastObj.u_name })

                        if (exisObjKeys[exisObjKeys.length - 1] == newKeys[0]) {
                            let temp = {
                                [exisObjKeys[exisObjKeys.length - 1]]: existDrill[exisObjKeys[exisObjKeys.length - 1]].concat(newDrill[exisObjKeys[exisObjKeys.length - 1]])
                            }
                            delete newDrill[exisObjKeys[exisObjKeys.length - 1]]
                            existDrill = Object.assign(existDrill, temp)
                            console.log(existDrill, newDrill, 'vibinnnn');
                        } else {
                            console.log(existDrill, newDrill, 'vibinnnnelse');
                        }
                        let newAssign = Object.assign(existDrill, newDrill);
                        console.log(newAssign, 'newAssign');
                        tableData[tableData.length - 1].drill_compliances = newAssign
                        let merge = tableData.concat(unMatchedArrayFilter)
                        console.log(merge, 'merge');
                        setTableData(merge)

                    } else {
                        console.log('mismatchhh');
                        let rejectedData = _.reject(showMoreData.not_complied, { le_name: tableDataLastObj.le_name })
                        let mergedArray = newTable.concat(rejectedData)
                        setTableData(mergedArray)
                    }
                }
            }

            //Complied
            if (showMoreData && showMoreData.complied && showMoreData.complied.length > 0) {
                let newTable = tableData
                let mergedArray = []
                let tableDataLastObj = newTable[newTable.length - 1]
                let showMoreDataFirstObj = showMoreData.complied[0]
                console.log(tableDataLastObj, 'tableDataLastObj');
                console.log(showMoreDataFirstObj, 'showMoreDataFirstObj');
                // if (`${tableDataLastObj && tableDataLastObj.le_name}-${tableDataLastObj && tableDataLastObj.le_name}` === `${showMoreDataFirstObj && showMoreDataFirstObj.le_name}-${showMoreDataFirstObj && showMoreDataFirstObj.le_name}`) {
                //     let showMoreNew = _.filter(showMoreData.complied, { le_name: tableDataLastObj.le_name })
                //     let mergedArray = newTable.concat(showMoreNew)
                //     setTableData(mergedArray)

                // }
                if (tableDataLastObj && showMoreDataFirstObj) {
                    let previousLEUnit = tableDataLastObj.le_name + '-' + tableDataLastObj.u_name
                    let currentLEUnit = showMoreDataFirstObj.le_name + '-' + showMoreDataFirstObj.u_name
                    if (previousLEUnit == currentLEUnit) {
                        // let showMoreNew = _.filter(showMoreData.not_complied, { le_name: tableDataLastObj.le_name, u_name: tableDataLastObj.u_name })                   
                        let existDrill = tableDataLastObj.drill_compliances
                        let newDrill = showMoreDataFirstObj.drill_compliances
                        let exisObjKeys = _.keys(existDrill)
                        let newKeys = _.keys(newDrill)
                        let unMatchedArrayFilter = _.reject(showMoreData.complied, { u_name: tableDataLastObj.u_name })

                        if (exisObjKeys[exisObjKeys.length - 1] == newKeys[0]) {
                            let temp = {
                                [exisObjKeys[exisObjKeys.length - 1]]: existDrill[exisObjKeys[exisObjKeys.length - 1]].concat(newDrill[exisObjKeys[exisObjKeys.length - 1]])
                            }
                            delete newDrill[exisObjKeys[exisObjKeys.length - 1]]
                            existDrill = Object.assign(existDrill, temp)
                            console.log(existDrill, newDrill, 'vibinnnn');
                        } else {
                            console.log(existDrill, newDrill, 'vibinnnnelse');
                        }
                        let newAssign = Object.assign(existDrill, newDrill);
                        console.log(newAssign, 'newAssign');
                        tableData[tableData.length - 1].drill_compliances = newAssign
                        let merge = tableData.concat(unMatchedArrayFilter)
                        console.log(merge, 'merge');
                        setTableData(merge)

                    }
                    else {
                        let rejectedData = _.reject(showMoreData.complied, { le_name: tableDataLastObj.le_name })
                        let mergedArray = newTable.concat(rejectedData)
                        setTableData(mergedArray)
                    }
                }
            }

            //Delayed Complied
            if (showMoreData && showMoreData.delayed && showMoreData.delayed.length > 0) {
                let newTable = tableData
                let mergedArray = []
                let tableDataLastObj = newTable[newTable.length - 1]
                let showMoreDataFirstObj = showMoreData.delayed[0]
                console.log(tableDataLastObj, 'tableDataLastObj');
                console.log(showMoreDataFirstObj, 'showMoreDataFirstObj');
                // if (`${tableDataLastObj && tableDataLastObj.le_name}-${tableDataLastObj && tableDataLastObj.le_name}` === `${showMoreDataFirstObj && showMoreDataFirstObj.le_name}-${showMoreDataFirstObj && showMoreDataFirstObj.le_name}`) {
                //     let showMoreNew = _.filter(showMoreData.delayed, { le_name: tableDataLastObj.le_name })
                //     let mergedArray = newTable.concat(showMoreNew)
                //     setTableData(mergedArray)
                // }
                if (tableDataLastObj && showMoreDataFirstObj) {
                    let previousLEUnit = tableDataLastObj.le_name + '-' + tableDataLastObj.u_name
                    let currentLEUnit = showMoreDataFirstObj.le_name + '-' + showMoreDataFirstObj.u_name
                    if (previousLEUnit == currentLEUnit) {
                        // let showMoreNew = _.filter(showMoreData.not_complied, { le_name: tableDataLastObj.le_name, u_name: tableDataLastObj.u_name })                   
                        let existDrill = tableDataLastObj.drill_compliances
                        let newDrill = showMoreDataFirstObj.drill_compliances
                        let exisObjKeys = _.keys(existDrill)
                        let newKeys = _.keys(newDrill)
                        let unMatchedArrayFilter = _.reject(showMoreData.delayed, { u_name: tableDataLastObj.u_name })

                        if (exisObjKeys[exisObjKeys.length - 1] == newKeys[0]) {
                            let temp = {
                                [exisObjKeys[exisObjKeys.length - 1]]: existDrill[exisObjKeys[exisObjKeys.length - 1]].concat(newDrill[exisObjKeys[exisObjKeys.length - 1]])
                            }
                            delete newDrill[exisObjKeys[exisObjKeys.length - 1]]
                            existDrill = Object.assign(existDrill, temp)
                            console.log(existDrill, newDrill, 'vibinnnn');
                        } else {
                            console.log(existDrill, newDrill, 'vibinnnnelse');
                        }
                        let newAssign = Object.assign(existDrill, newDrill);
                        console.log(newAssign, 'newAssign');
                        tableData[tableData.length - 1].drill_compliances = newAssign
                        let merge = tableData.concat(unMatchedArrayFilter)
                        console.log(merge, 'merge');
                        setTableData(merge)

                    }
                    else {
                        let rejectedData = _.reject(showMoreData.delayed, { le_name: tableDataLastObj.le_name })
                        let mergedArray = newTable.concat(rejectedData)
                        setTableData(mergedArray)
                    }
                }
            }
        }

    }, [showMoreData])



    useEffect(() => {
        if (fieldTypeClick === 'BusinessGroup' && checkBox === false) {
            if (filterDataNames.business === '') {
                setErrorField('Business Group Required')
            }
        }

        if (fieldTypeClick === 'LegalEntity' && checkBox === false) {
            if (filterDataNames.legalentity === '') {
                setErrorField('Legal Entity Required')
            }
        }
        if (fieldTypeClick === 'Division' && checkBox === false) {
            if (filterDataNames.division === '') {
                setErrorField('Division Required')
            }
        }
        if (fieldTypeClick === 'Category' && checkBox === false) {
            if (filterDataNames.category === '') {
                setErrorField('Category Required')
            }
        }
        if (fieldTypeClick === 'Unit' && checkBox === false) {
            if (filterDataNames.unit === '') {
                {
                    localStorage.getItem('isVendor') == "true" ?
                        setErrorField('Vendor Required') :
                        setErrorField('Unit Required')
                }
            }
        }

    }, [fieldTypeClick, checkBox])

    useEffect(() => {
        if (fieldTypeClick === 'Country' && checkBox === false) {
            if (filterData.country.length === 0) {
                setErrorField('Country Required')
            }
            else {
                setErrorField('')

            }
        }
        if (fieldTypeClick === 'Domain' && checkBox === false) {
            if (filterData.domain.length === 0) {
                setErrorField('Domain Required')
            }
            else {
                setErrorField('')

            }
        }

    }, [fieldTypeClick, filterData, checkBox])

    useEffect(() => {
        if (checkBox === true) {
            setErrorField('')
        }
    }, [checkBox])

    const [fullscreen, setfullscreen] = useState(false);
    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }
    useEffect(() => {
        {
            var the_arr = location.pathname.split('/');
            the_arr.pop();
            let a = the_arr.join('/')
            setParamId(a)
        }
    }, []);
    console.log(data, 'datadatadata');
    console.log(go, 'goooooo');
    // useEffect(() => {
    //     if (tableData) {
    //         setTaskDistributionTable(tableData.r_drill_down_data)
    //     }

    // }, [tableData])
    // console.log(tableData, 'taskDistributionTable');



    console.log(field, 'fieldfield');
    console.log(commonData, ' commonDatacommonDatacommonData');

    useEffect(() => {
        // if (localStorage.getItem('SelectedEntity') == "All Legal Entity") {
        let id = sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {

            return item.le_id
        })
        setEntityData(id)

        // }
    }, [])

    console.log(data, 'data');

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') !== "All Legal Entity") {
            if (data && data.length > 0) {
                let temp = []
                let temp2 = []
                let temp3 = []
                console.log(data, 'data6767');
                for (let i in data) {
                    temp.push({
                        "filter_id": data && data.length > 0 && data[i].filter_id,
                        "month": data && data.length > 0 && data[i].chart_year,
                        "value": data && data.length > 0 && data[i].complied_compliances_count,
                        "total": data && data.length > 0 && data[i].total_compliances,
                        "valuecount": data && data.length > 0 && data[i].complied_compliances_count
                    })
                    temp2.push({
                        "filter_id": data && data.length > 0 && data[i].filter_id,
                        "month": data && data.length > 0 && data[i].chart_year,
                        "value": data && data.length > 0 && data[i].not_complied_count,
                        "total": data && data.length > 0 && data[i].total_compliances,
                        "valuecount": data && data.length > 0 && data[i].not_complied_count

                    })
                    temp3.push({
                        "filter_id": data && data.length > 0 && data[i].filter_id,
                        "month": data && data.length > 0 && data[i].chart_year,
                        "value": data && data.length > 0 && data[i].delayed_count,
                        "total": data && data.length > 0 && data[i].total_compliances,
                        "valuecount": data && data.length > 0 && data[i].delayed_count
                    })
                }
                // sortByMonth(temp);



                // function sortByMonth(arr) {
                //     console.log(arr, 'snehaaaaaaaa');
                //     var months = ["Jan 2022", "Feb 2022", "Mar 2022", "Apr 2022", "May 2022", "Jun 2022",
                //         "Jul 2022", "Aug 2022", "Sep 2022", "Aug 2022", "Nov 2022", "Dec 2022"];
                //     let data = arr.sort(function (a, b) {
                //         return months.indexOf(a.month
                //         )
                //             - months.indexOf(b.month
                //             );
                //     });
                //     console.log(data,'data5555');
                // }
                console.log(temp, 'temp12345');
                setDataValue(temp)
                setNotcomplieddata(temp2)
                setDelayedData(temp3)
            }
        }

    }, [data, localStorage.getItem('SelectedEntity')])
    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === "All Legal Entity") {
            if (data && data.length > 0) {
                let temparr = []
                let temparr2 = []
                let temp = []
                let temp2 = []
                let temp3 = []
                let notcompliedTemp = []
                let delayedTemp = []
                let totalTemp = []

                for (let i in data) {

                    let uniqueObjArray = [...new Map(data && data.length > 0 && data.map((item) => [item["chart_year"], item])).values()];
                    const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                        return item.chart_year
                    })
                    temp = uniqueIds
                    console.log(uniqueIds, 'uniqueIds');

                    // temp.push({
                    //     "month": data && data.length > 0 && data[i].chart_year,
                    //     "value": data && data.length > 0 && data[i].complied_compliances_count,
                    //     "total": data && data.length > 0 && data[i].total_compliances,
                    //     "values": data && data.length > 0 && data[i].complied_compliances_count
                    // })
                    // temp2.push({
                    //     "month": data && data.length > 0 && data[i].chart_year,
                    //     "value": data && data.length > 0 && data[i].not_complied_count,
                    //     "total": data && data.length > 0 && data[i].total_compliances,
                    //     "values": data && data.length > 0 && data[i].not_complied_count

                    // })
                    // temp3.push({
                    //     "month": data && data.length > 0 && data[i].chart_year,
                    //     "value": data && data.length > 0 && data[i].delayed_count,
                    //     "total": data && data.length > 0 && data[i].total_compliances,
                    //     "values": data && data.length > 0 && data[i].delayed_count
                    // })
                }
                for (let k in temp) {
                    if (data[k].chart_year == temp[k]) {
                        let filterValue = _.filter(data, { chart_year: temp[k] })
                        console.log(filterValue, 'aishhhhhhhhhh');
                        let compliedCount = filterValue.map((item, i) => {
                            return item.complied_compliances_count
                        })
                        let compliedCounts = compliedCount.reduce(function (accumulator, curValue) {
                            return accumulator + curValue
                        }, 0)
                        let notCount = filterValue.map((item, i) => {
                            return item.not_complied_count
                        })
                        let notcounts = notCount.reduce(function (accumulator, curValue) {
                            return accumulator + curValue
                        }, 0)
                        let deyaledcount = filterValue.map((item, i) => {
                            return item.delayed_count
                        })
                        let deyaledcounts = deyaledcount.reduce(function (accumulator, curValue) {
                            return accumulator + curValue
                        }, 0)
                        let totalcount = filterValue.map((item, i) => {
                            return item.total_compliances
                        })
                        let totalcounts = totalcount.reduce(function (accumulator, curValue) {
                            return accumulator + curValue
                        }, 0)
                        temp2.push(compliedCounts)
                        notcompliedTemp.push(notcounts)
                        delayedTemp.push(deyaledcounts)
                        totalTemp.push(totalcounts)


                    }
                }
                for (let v in temp) {
                    temparr.push({
                        "filter_id": data && data.length > 0 && data[v].filter_id,
                        "month": toggleButton === 'Month' ? data && data.length > 0 && data[v].chart_year : `${data && data.length > 0 && data[v].chart_year}`,
                        "value": temp2 && temp2.length > 0 && temp2[v],
                        "total": totalTemp && totalTemp.length > 0 && totalTemp[v],
                        "valuecount": temp2 && temp2.length > 0 && temp2[v],

                    })
                    temparr2.push({
                        "filter_id": data && data.length > 0 && data[v].filter_id,
                        "month": toggleButton === 'Month' ? data && data.length > 0 && data[v].chart_year : `${data && data.length > 0 && data[v].chart_year}`,
                        "value": notcompliedTemp && notcompliedTemp.length > 0 && notcompliedTemp[v],
                        "total": totalTemp && totalTemp.length > 0 && totalTemp[v],
                        "valuecount": notcompliedTemp && notcompliedTemp.length > 0 && notcompliedTemp[v],

                    })
                    temp3.push({
                        "filter_id": data && data.length > 0 && data[v].filter_id,
                        "month": toggleButton === 'Month' ? data && data.length > 0 && data[v].chart_year : `${data && data.length > 0 && data[v].chart_year}`,
                        "value": delayedTemp && delayedTemp.length > 0 && delayedTemp[v],
                        "total": totalTemp && totalTemp.length > 0 && totalTemp[v],
                        "valuecount": delayedTemp && delayedTemp.length > 0 && delayedTemp[v],

                    })
                }
                console.log(temparr, 'temparr');
                if (toggleButton === 'Month') {
                    sortByMonth(temparr);
                    function sortByMonth(arr) {
                        console.log(arr, 'arrrrrrrrr');
                        var months = ["Jan 2022", "Feb 2022", "Mar 2022", "Apr 2022", "May 2022", "Jun 2022",
                            "Jul 2022", "Aug 2022", "Sep 2022", "Aug 2022", "Nov 2022", "Dec 2022"];
                        arr.sort(function (a, b) {
                            return months.indexOf(a.month
                            )
                                - months.indexOf(b.month
                                );
                        });
                    }
                }
                console.log(temparr, 'complied');
                console.log(temparr2, 'notcomplied');
                console.log(temp3, 'delayedcomplied');
                console.log(totalTemp, 'totalcomplied');
                console.log(temp, 'temp');
                setDataValue(temparr)
                setNotcomplieddata(temparr2)
                setDelayedData(temp3)

                // setDataValue(temp)
                // setNotcomplieddata(temp2)
                // setDelayedData(temp3)
            }
        }


    }, [data, localStorage.getItem('SelectedEntity')])




    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetChartFilters",
                        {
                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                        }
                    ]
                }
            ]
            if (paramid != "") {
                getCommonData({
                    payload: payload,
                    paramid: paramid
                })
            }


        }
    }, [isAuth, authtoken, paramid, entityid])

    useEffect(() => {
        if (commonData && commonData.d_info && commonData.d_info.length > 0) {
            let domainNameArray = commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info.map((item, i) => {
                return item.d_name
            })
            setDomainName(domainNameArray)
            console.log(domainName, 'domainName');

        }

    }, [commonData])

    useEffect(() => {
        if (type === 'Group')
            setGroupCheckBox(checkBox)
    }, [type, checkBox])

    // useEffect(() => {
    //     if ((entityData && entityData.length > 0) && (commonData) && (dIds && dIds.length > 0) && (countryDomain && countryDomain.length > 0)) {
    //         const payload = [
    //             authtoken,
    //             {
    //                 "session_token": authtoken,
    //                 "request":
    //                     ["GetComparativeChart",

    //                         {
    //                             "c_ids": [
    //                                 commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
    //                             ],
    //                             "d_ids": dIds,
    //                             "filter_type": "Group",
    //                             "filter_ids": [
    //                                 1
    //                             ],
    //                             "le_ids": localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? entityData : [entityid],
    //                             "country_domains": countryDomain,
    //                             "year": `${year}`,
    //                             "chart_types": "Month"
    //                         }
    //                     ]
    //             }
    //         ]
    //         if (paramid != "") {
    //             getComparativechart({
    //                 payload: payload,
    //                 paramid: paramid
    //             })
    //         }
    //     }

    // }, [isAuth, entityid, commonData, entityData, year, countryDomain, dIds])

    useEffect(() => {
        if (JSON.parse(sideFilterState) === false && JSON.parse(isCountryValueTrue) === false) {
            if ((entityData && entityData.length > 0) && (commonData) && (dIds && dIds.length > 0) && (countryDomain && countryDomain.length > 0)) {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request":
                            ["GetComplianceTrend",

                                {
                                    "c_ids": [
                                        commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                    ],
                                    "d_ids": dIds,
                                    "filter_type": "Group",
                                    "filter_ids": [
                                        1
                                    ],
                                    "le_ids": localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? entityData : [entityid],
                                    "country_domains": countryDomain,
                                    "year": `${year}`,
                                    "chart_types": "Month"
                                }
                            ]
                    }
                ]
                if (paramid != "") {
                    getComplianceTrendchart({
                        payload: payload,
                        paramid: paramid
                    })
                }
            }
        }

    }, [isAuth, entityid, commonData, entityData, year, countryDomain, dIds, localStorage.getItem('filterState'), localStorage.getItem('countryState')])

    console.log(data, 'data');

    useEffect(() => {
        if (data && data.compliance_data && data.compliance_data.length > 0) {
            let temp = []
            let temp1 = []
            let temp3 = []
            for (let i in data.compliance_data) {
                let total = data.compliance_data[i].complied_count + data.compliance_data[i].delayed_compliance_count + data.compliance_data[i].not_complied_count
                if (toggleButton === 'Month') {
                    temp1.push({
                        'year': data.compliance_data[i].chart_month,
                        'complied_count': total != 0 ? (data.compliance_data[i].complied_count) / (total) * 100 : 0,
                        'delayed_compliance_count': total != 0 ? (data.compliance_data[i].delayed_compliance_count) / (total) * 100 : 0,
                        'not_complied_count': total != 0 ? (data.compliance_data[i].not_complied_count) / (total) * 100 : 0,
                        'total': total,
                        'compliedCount': data.compliance_data[i].complied_count,
                        'delayedCount': data.compliance_data[i].delayed_compliance_count,
                        'notCompliedCount': data.compliance_data[i].not_complied_count
                    })
                }
                else {
                    temp1.push({
                        'year': data.compliance_data[i].chart_year,
                        'complied_count': total != 0 ? (data.compliance_data[i].complied_count) / (total) * 100 : 0,
                        'delayed_compliance_count': total != 0 ? (data.compliance_data[i].delayed_compliance_count) / (total) * 100 : 0,
                        'not_complied_count': total != 0 ? (data.compliance_data[i].not_complied_count) / (total) * 100 : 0,
                        'total': total,
                        'compliedCount': data.compliance_data[i].complied_count,
                        'delayedCount': data.compliance_data[i].delayed_compliance_count,
                        'notCompliedCount': data.compliance_data[i].not_complied_count
                    })
                }
            }
            if (temp1 && temp1.length > 0) {
                for (let i in temp1) {
                    const removeZero = item => (
                        Object.keys(item)
                            .filter(key => item[key] !== 0)
                            .reduce((newObj, key) => {
                                newObj[key] = item[key]; return newObj;
                            }, {})); const result = removeZero(temp1[i]);
                    console.log(result, 'result');
                    temp.push(result)
                }
            }
            console.log(temp1, 'temp1temp1');
            console.log(temp, 'temp');
            setChartData(toggleButton === 'Month' ? temp : temp)
        }

    }, [data.compliance_data])

    useEffect(() => {
        if (data && data.compliance_data && data.compliance_data.length > 0) {
            let temp = []
            let temp1 = []
            let temp3 = []
            for (let i in data.compliance_data) {
                let total = data.compliance_data[i].complied_count + data.compliance_data[i].delayed_compliance_count + data.compliance_data[i].not_complied_count
                if (toggleButton === 'Month') {
                    temp1.push({
                        'year': data.compliance_data[i].chart_month,
                        'complied_count': total != 0 ? (data.compliance_data[i].complied_count) / (total) * 100 : 0,
                        'delayed_compliance_count': total != 0 ? (data.compliance_data[i].delayed_compliance_count) / (total) * 100 : 0,
                        'not_complied_count': total != 0 ? (data.compliance_data[i].not_complied_count) / (total) * 100 : 0,
                        'total': total,
                        'compliedCount': data.compliance_data[i].complied_count,
                        'delayedCount': data.compliance_data[i].delayed_compliance_count,
                        'notCompliedCount': data.compliance_data[i].not_complied_count
                    })
                }
                else {
                    temp1.push({
                        'year': data.compliance_data[i].chart_year,
                        'complied_count': total != 0 ? (data.compliance_data[i].complied_count) / (total) * 100 : 0,
                        'delayed_compliance_count': total != 0 ? (data.compliance_data[i].delayed_compliance_count) / (total) * 100 : 0,
                        'not_complied_count': total != 0 ? (data.compliance_data[i].not_complied_count) / (total) * 100 : 0,
                        'total': total,
                        'compliedCount': data.compliance_data[i].complied_count,
                        'delayedCount': data.compliance_data[i].delayed_compliance_count,
                        'notCompliedCount': data.compliance_data[i].not_complied_count
                    })
                }
            }
            if (temp1 && temp1.length > 0) {
                for (let i in temp1) {
                    const removeZero = item => (
                        Object.keys(item)
                            .filter(key => item[key] !== 0)
                            .reduce((newObj, key) => {
                                newObj[key] = item[key]; return newObj;
                            }, {})); const result = removeZero(temp1[i]);
                    console.log(result, 'result');
                    temp.push(result)
                }
            }
            console.log(temp1, 'temp1temp1');
            console.log(temp, 'temp');
            setTempArr(temp)
        }

    }, [data.compliance_data])

    useEffect(() => {
        if (tempArr && tempArr.length > 0) {
            if (toggleButton === 'Year') {
                tempArr.forEach(object => {
                    delete object['year'];
                });

                const results = tempArr.filter(element => {
                    if (Object.keys(element).length !== 0) {
                        return true;
                    }

                    return false;
                });
                setResultArray(results)

                console.log(tempArr, '12345');
                console.log(chartData, 'charttt');
                console.log(results, 'results');
            }
            else {
                tempArr.forEach(object => {
                    delete object['year'];
                });

                const results = tempArr.filter(element => {
                    if (Object.keys(element).length !== 0) {
                        return true;
                    }

                    return false;
                });
                console.log(results, 'results123');
                setResultArray(results)

            }
        }

    }, [tempArr, toggleButton])

    useEffect(() => {
        if (chartData && chartData.length > 0) {
            if (toggleButton === 'Month') {
                setTempData([
                    {
                        'year': chartData && chartData[0] && chartData[0].year,
                        'complied_count': chartData && chartData[0] && chartData[0].compliedCount,
                        'delayed_compliance_count': chartData && chartData[0] && chartData[0].delayed_compliance_count,
                        'not_complied_count': chartData && chartData[0] && chartData[0].not_complied_count,
                        'total': chartData && chartData[0] && chartData[0].total,
                        'compliedCount': chartData && chartData[0] && chartData[0].compliedCount,
                        'delayedCount': chartData && chartData[0] && chartData[0].delayedCount,
                        'notCompliedCount': chartData && chartData[0] && chartData[0].notCompliedCount
                    },
                    {
                        'year': chartData && chartData[1] && chartData[1].year,
                        'complied_count': chartData && chartData[1] && chartData[1].compliedCount,
                        'delayed_compliance_count': chartData && chartData[1] && chartData[1].delayed_compliance_count,
                        'not_complied_count': chartData && chartData[1] && chartData[1].not_complied_count,
                        'total': chartData && chartData[1] && chartData[1].total,
                        'compliedCount': chartData && chartData[1] && chartData[1].compliedCount,
                        'delayedCount': chartData && chartData[1] && chartData[1].delayedCount,
                        'notCompliedCount': chartData && chartData[1] && chartData[1].notCompliedCount
                    },
                    {
                        'year': chartData && chartData[2] && chartData[2].year,
                        'complied_count': chartData && chartData[2] && chartData[2].compliedCount,
                        'delayed_compliance_count': chartData && chartData[2] && chartData[2].delayed_compliance_count,
                        'not_complied_count': chartData && chartData[2] && chartData[2].not_complied_count,
                        'total': chartData && chartData[2] && chartData[2].total,
                        'compliedCount': chartData && chartData[2] && chartData[2].compliedCount,
                        'delayedCount': chartData && chartData[2] && chartData[2].delayedCount,
                        'notCompliedCount': chartData && chartData[2] && chartData[2].notCompliedCount
                    },
                    {
                        'year': chartData && chartData[3] && chartData[3].year,
                        'complied_count': chartData && chartData[3] && chartData[3].compliedCount,
                        'delayed_compliance_count': chartData && chartData[3] && chartData[3].delayed_compliance_count,
                        'not_complied_count': chartData && chartData[3] && chartData[3].not_complied_count,
                        'total': chartData && chartData[3] && chartData[3].total,
                        'compliedCount': chartData && chartData[3] && chartData[3].compliedCount,
                        'delayedCount': chartData && chartData[3] && chartData[3].delayedCount,
                        'notCompliedCount': chartData && chartData[3] && chartData[3].notCompliedCount
                    },
                    {
                        'year': chartData && chartData[4] && chartData[4].year,
                        'complied_count': chartData && chartData[4] && chartData[4].compliedCount,
                        'delayed_compliance_count': chartData && chartData[4] && chartData[4].delayed_compliance_count,
                        'not_complied_count': chartData && chartData[4] && chartData[4].not_complied_count,
                        'total': chartData && chartData[4] && chartData[4].total,
                        'compliedCount': chartData && chartData[4] && chartData[4].compliedCount,
                        'delayedCount': chartData && chartData[4] && chartData[4].delayedCount,
                        'notCompliedCount': chartData && chartData[4] && chartData[4].notCompliedCount
                    },
                    {
                        'year': chartData && chartData[5] && chartData[5].year,
                        'complied_count': chartData && chartData[5] && chartData[5].compliedCount,
                        'delayed_compliance_count': chartData && chartData[5] && chartData[5].delayed_compliance_count,
                        'not_complied_count': chartData && chartData[5] && chartData[5].not_complied_count,
                        'total': chartData && chartData[5] && chartData[5].total,
                        'compliedCount': chartData && chartData[5] && chartData[5].compliedCount,
                        'delayedCount': chartData && chartData[5] && chartData[5].delayedCount,
                        'notCompliedCount': chartData && chartData[5] && chartData[5].notCompliedCount
                    },
                    {
                        'year': chartData && chartData[6] && chartData[6].year,
                        'complied_count': chartData && chartData[6] && chartData[6].compliedCount,
                        'delayed_compliance_count': chartData && chartData[6] && chartData[6].delayed_compliance_count,
                        'not_complied_count': chartData && chartData[6] && chartData[6].not_complied_count,
                        'total': chartData && chartData[6] && chartData[6].total,
                        'compliedCount': chartData && chartData[6] && chartData[6].compliedCount,
                        'delayedCount': chartData && chartData[6] && chartData[6].delayedCount,
                        'notCompliedCount': chartData && chartData[6] && chartData[6].notCompliedCount
                    },
                    {
                        'year': chartData && chartData[7] && chartData[7].year,
                        'complied_count': chartData && chartData[7] && chartData[7].compliedCount,
                        'delayed_compliance_count': chartData && chartData[7] && chartData[7].delayed_compliance_count,
                        'not_complied_count': chartData && chartData[7] && chartData[7].not_complied_count,
                        'total': chartData && chartData[7] && chartData[7].total,
                        'compliedCount': chartData && chartData[7] && chartData[7].compliedCount,
                        'delayedCount': chartData && chartData[7] && chartData[7].delayedCount,
                        'notCompliedCount': chartData && chartData[7] && chartData[7].notCompliedCount
                    },
                    {
                        'year': chartData && chartData[8] && chartData[8].year,
                        'complied_count': chartData && chartData[8] && chartData[8].compliedCount,
                        'delayed_compliance_count': chartData && chartData[8] && chartData[8].delayed_compliance_count,
                        'not_complied_count': chartData && chartData[8] && chartData[8].not_complied_count,
                        'total': chartData && chartData[8] && chartData[8].total,
                        'compliedCount': chartData && chartData[8] && chartData[8].compliedCount,
                        'delayedCount': chartData && chartData[8] && chartData[8].delayedCount,
                        'notCompliedCount': chartData && chartData[8] && chartData[8].notCompliedCount
                    },
                    {
                        'year': chartData && chartData[9] && chartData[9].year,
                        'complied_count': chartData && chartData[9] && chartData[9].compliedCount,
                        'delayed_compliance_count': chartData && chartData[9] && chartData[9].delayed_compliance_count,
                        'not_complied_count': chartData && chartData[9] && chartData[9].not_complied_count,
                        'total': chartData && chartData[9] && chartData[9].total,
                        'compliedCount': chartData && chartData[9] && chartData[9].compliedCount,
                        'delayedCount': chartData && chartData[9] && chartData[9].delayedCount,
                        'notCompliedCount': chartData && chartData[9] && chartData[9].notCompliedCount
                    },
                    {
                        'year': chartData && chartData[10] && chartData[10].year,
                        'complied_count': chartData && chartData[10] && chartData[10].compliedCount,
                        'delayed_compliance_count': chartData && chartData[10] && chartData[10].delayed_compliance_count,
                        'not_complied_count': chartData && chartData[10] && chartData[10].not_complied_count,
                        'total': chartData && chartData[10] && chartData[10].total,
                        'compliedCount': chartData && chartData[10] && chartData[10].compliedCount,
                        'delayedCount': chartData && chartData[10] && chartData[10].delayedCount,
                        'notCompliedCount': chartData && chartData[10] && chartData[10].notCompliedCount
                    },
                    {
                        'year': chartData && chartData[11] && chartData[11].year,
                        'complied_count': chartData && chartData[11] && chartData[11].compliedCount,
                        'delayed_compliance_count': chartData && chartData[11] && chartData[11].delayed_compliance_count,
                        'not_complied_count': chartData && chartData[11] && chartData[11].not_complied_count,
                        'total': chartData && chartData[11] && chartData[11].total,
                        'compliedCount': chartData && chartData[11] && chartData[11].compliedCount,
                        'delayedCount': chartData && chartData[11] && chartData[11].delayedCount,
                        'notCompliedCount': chartData && chartData[11] && chartData[11].notCompliedCount
                    },
                ])
            }
            else {
                setTempData([
                    {
                        'year': "2018",
                        'complied_count': chartData && chartData[0] && chartData[0].compliedCount,
                        'delayed_compliance_count': chartData && chartData[0] && chartData[0].delayed_compliance_count,
                        'not_complied_count': chartData && chartData[0] && chartData[0].not_complied_count,
                        'total': chartData && chartData[0] && chartData[0].total,
                        'compliedCount': chartData && chartData[0] && chartData[0].compliedCount,
                        'delayedCount': chartData && chartData[0] && chartData[0].delayedCount,
                        'notCompliedCount': chartData && chartData[0] && chartData[0].notCompliedCount
                    },
                    {
                        'year': "2019",
                        'complied_count': chartData && chartData[1] && chartData[1].compliedCount,
                        'delayed_compliance_count': chartData && chartData[1] && chartData[1].delayed_compliance_count,
                        'not_complied_count': chartData && chartData[1] && chartData[1].not_complied_count,
                        'total': chartData && chartData[1] && chartData[1].total,
                        'compliedCount': chartData && chartData[1] && chartData[1].compliedCount,
                        'delayedCount': chartData && chartData[1] && chartData[1].delayedCount,
                        'notCompliedCount': chartData && chartData[1] && chartData[1].notCompliedCount
                    },
                    {
                        'year': "2020",
                        'complied_count': chartData && chartData[2] && chartData[2].compliedCount,
                        'delayed_compliance_count': chartData && chartData[2] && chartData[2].delayed_compliance_count,
                        'not_complied_count': chartData && chartData[2] && chartData[2].not_complied_count,
                        'total': chartData && chartData[2] && chartData[2].total,
                        'compliedCount': chartData && chartData[2] && chartData[2].compliedCount,
                        'delayedCount': chartData && chartData[2] && chartData[2].delayedCount,
                        'notCompliedCount': chartData && chartData[2] && chartData[2].notCompliedCount
                    },
                    {
                        'year': "2021",
                        'complied_count': chartData && chartData[3] && chartData[3].compliedCount,
                        'delayed_compliance_count': chartData && chartData[3] && chartData[3].delayed_compliance_count,
                        'not_complied_count': chartData && chartData[3] && chartData[3].not_complied_count,
                        'total': chartData && chartData[3] && chartData[3].total,
                        'compliedCount': chartData && chartData[3] && chartData[3].compliedCount,
                        'delayedCount': chartData && chartData[3] && chartData[3].delayedCount,
                        'notCompliedCount': chartData && chartData[3] && chartData[3].notCompliedCount
                    },
                    {
                        'year': "2022",
                        'complied_count': chartData && chartData[4] && chartData[4].compliedCount,
                        'delayed_compliance_count': chartData && chartData[4] && chartData[4].delayed_compliance_count,
                        'not_complied_count': chartData && chartData[4] && chartData[4].not_complied_count,
                        'total': chartData && chartData[4] && chartData[4].total,
                        'compliedCount': chartData && chartData[4] && chartData[4].compliedCount,
                        'delayedCount': chartData && chartData[4] && chartData[4].delayedCount,
                        'notCompliedCount': chartData && chartData[4] && chartData[4].notCompliedCount
                    },
                ])
            }
        }

    }, [chartData, toggleButton, data.compliance_data, year])

    // useEffect(() => {
    //     if (chartType === "Complied") {
    //         if (toggleButton === 'Month') {
    //             setTempData([
    //                 {
    //                     'year': chartData && chartData[0] && chartData[0].year,
    //                     'complied_count': chartData && chartData[0] && chartData[0].compliedCount,
    //                     'total': chartData && chartData[0] && chartData[0].total,
    //                     'compliedCount': chartData && chartData[0] && chartData[0].compliedCount,
    //                 },
    //                 {
    //                     'year': chartData && chartData[1] && chartData[1].year,
    //                     'complied_count': chartData && chartData[1] && chartData[1].compliedCount,
    //                     'total': chartData && chartData[1] && chartData[1].total,
    //                     'compliedCount': chartData && chartData[1] && chartData[1].compliedCount,
    //                 },
    //                 {
    //                     'year': chartData && chartData[2] && chartData[2].year,
    //                     'complied_count': chartData && chartData[2] && chartData[2].compliedCount,
    //                     'total': chartData && chartData[2] && chartData[2].total,
    //                     'compliedCount': chartData && chartData[2] && chartData[2].compliedCount,
    //                 },
    //                 {
    //                     'year': chartData && chartData[3] && chartData[3].year,
    //                     'complied_count': chartData && chartData[3] && chartData[3].compliedCount,
    //                     'total': chartData && chartData[3] && chartData[3].total,
    //                     'compliedCount': chartData && chartData[3] && chartData[3].compliedCount,
    //                 },
    //                 {
    //                     'year': chartData && chartData[4] && chartData[4].year,
    //                     'complied_count': chartData && chartData[4] && chartData[4].compliedCount,
    //                     'total': chartData && chartData[4] && chartData[4].total,
    //                     'compliedCount': chartData && chartData[4] && chartData[4].compliedCount,
    //                 },
    //                 {
    //                     'year': chartData && chartData[5] && chartData[5].year,
    //                     'complied_count': chartData && chartData[5] && chartData[5].compliedCount,
    //                     'total': chartData && chartData[5] && chartData[5].total,
    //                     'compliedCount': chartData && chartData[5] && chartData[5].compliedCount,
    //                 },
    //                 {
    //                     'year': chartData && chartData[6] && chartData[6].year,
    //                     'complied_count': chartData && chartData[6] && chartData[6].compliedCount,
    //                     'total': chartData && chartData[6] && chartData[6].total,
    //                     'compliedCount': chartData && chartData[6] && chartData[6].compliedCount,
    //                 },
    //                 {
    //                     'year': chartData && chartData[7] && chartData[7].year,
    //                     'complied_count': chartData && chartData[7] && chartData[7].compliedCount,
    //                     'total': chartData && chartData[7] && chartData[7].total,
    //                     'compliedCount': chartData && chartData[7] && chartData[7].compliedCount,
    //                     'notCompliedCount': chartData && chartData[7] && chartData[7].notCompliedCount
    //                 },
    //                 {
    //                     'year': chartData && chartData[8] && chartData[8].year,
    //                     'complied_count': chartData && chartData[8] && chartData[8].compliedCount,
    //                     'total': chartData && chartData[8] && chartData[8].total,
    //                     'compliedCount': chartData && chartData[8] && chartData[8].compliedCount,
    //                 },
    //                 {
    //                     'year': chartData && chartData[9] && chartData[9].year,
    //                     'complied_count': chartData && chartData[9] && chartData[9].compliedCount,
    //                     'total': chartData && chartData[9] && chartData[9].total,
    //                     'compliedCount': chartData && chartData[9] && chartData[9].compliedCount,
    //                 },
    //                 {
    //                     'year': chartData && chartData[10] && chartData[10].year,
    //                     'complied_count': chartData && chartData[10] && chartData[10].compliedCount,
    //                     'total': chartData && chartData[10] && chartData[10].total,
    //                     'compliedCount': chartData && chartData[10] && chartData[10].compliedCount,
    //                 },
    //                 {
    //                     'year': chartData && chartData[11] && chartData[11].year,
    //                     'complied_count': chartData && chartData[11] && chartData[11].compliedCount,
    //                     'total': chartData && chartData[11] && chartData[11].total,
    //                     'compliedCount': chartData && chartData[11] && chartData[11].compliedCount,
    //                 },
    //             ])
    //         }
    //         else {
    //             setTempData([
    //                 {
    //                     'year': chartData && chartData[1] && chartData[0].year,
    //                     'complied_count': chartData && chartData[0] && chartData[0].compliedCount,
    //                     'total': chartData && chartData[0] && chartData[0].total,
    //                     'compliedCount': chartData && chartData[0] && chartData[0].compliedCount,
    //                 },
    //                 {
    //                     'year': chartData && chartData[1] && chartData[1].year,
    //                     'complied_count': chartData && chartData[1] && chartData[1].compliedCount,
    //                     'total': chartData && chartData[1] && chartData[1].total,
    //                     'compliedCount': chartData && chartData[1] && chartData[1].compliedCount,
    //                 },
    //                 {
    //                     'year': chartData && chartData[2] && chartData[2].year,
    //                     'complied_count': chartData && chartData[2] && chartData[2].compliedCount,
    //                     'delayed_compliance_count': chartData && chartData[2] && chartData[2].delayed_compliance_count,
    //                     'not_complied_count': chartData && chartData[2] && chartData[2].not_complied_count,
    //                     'total': chartData && chartData[2] && chartData[2].total,
    //                     'compliedCount': chartData && chartData[2] && chartData[2].compliedCount,
    //                     'delayedCount': chartData && chartData[2] && chartData[2].delayedCount,
    //                     'notCompliedCount': chartData && chartData[2] && chartData[2].notCompliedCount
    //                 },
    //                 {
    //                     'year': chartData && chartData[3] && chartData[3].year,
    //                     'complied_count': chartData && chartData[3] && chartData[3].compliedCount,
    //                     'delayed_compliance_count': chartData && chartData[3] && chartData[3].delayed_compliance_count,
    //                     'not_complied_count': chartData && chartData[3] && chartData[3].not_complied_count,
    //                     'total': chartData && chartData[3] && chartData[3].total,
    //                     'compliedCount': chartData && chartData[3] && chartData[3].compliedCount,
    //                     'delayedCount': chartData && chartData[3] && chartData[3].delayedCount,
    //                     'notCompliedCount': chartData && chartData[3] && chartData[3].notCompliedCount
    //                 },
    //                 {
    //                     'year': chartData && chartData[4] && chartData[4].year,
    //                     'complied_count': chartData && chartData[4] && chartData[4].compliedCount,
    //                     'delayed_compliance_count': chartData && chartData[4] && chartData[4].delayed_compliance_count,
    //                     'not_complied_count': chartData && chartData[4] && chartData[4].not_complied_count,
    //                     'total': chartData && chartData[4] && chartData[4].total,
    //                     'compliedCount': chartData && chartData[4] && chartData[4].compliedCount,
    //                     'delayedCount': chartData && chartData[4] && chartData[4].delayedCount,
    //                     'notCompliedCount': chartData && chartData[4] && chartData[4].notCompliedCount
    //                 },
    //             ])
    //         }
    //     }
    //     else if (chartType === "delayedComplied") {
    //         if (toggleButton === 'Month') {
    //             setTempData([
    //                 {
    //                     'year': chartData && chartData[0] && chartData[0].year,
    //                     'complied_count': chartData && chartData[0] && chartData[0].compliedCount,
    //                     'delayed_compliance_count': chartData && chartData[0] && chartData[0].delayed_compliance_count,
    //                     'not_complied_count': chartData && chartData[0] && chartData[0].not_complied_count,
    //                     'total': chartData && chartData[0] && chartData[0].total,
    //                     'compliedCount': chartData && chartData[0] && chartData[0].compliedCount,
    //                     'delayedCount': chartData && chartData[0] && chartData[0].delayedCount,
    //                     'notCompliedCount': chartData && chartData[0] && chartData[0].notCompliedCount
    //                 },
    //                 {
    //                     'year': chartData && chartData[1] && chartData[1].year,
    //                     'complied_count': chartData && chartData[1] && chartData[1].compliedCount,
    //                     'delayed_compliance_count': chartData && chartData[1] && chartData[1].delayed_compliance_count,
    //                     'not_complied_count': chartData && chartData[1] && chartData[1].not_complied_count,
    //                     'total': chartData && chartData[1] && chartData[1].total,
    //                     'compliedCount': chartData && chartData[1] && chartData[1].compliedCount,
    //                     'delayedCount': chartData && chartData[1] && chartData[1].delayedCount,
    //                     'notCompliedCount': chartData && chartData[1] && chartData[1].notCompliedCount
    //                 },
    //                 {
    //                     'year': chartData && chartData[2] && chartData[2].year,
    //                     'complied_count': chartData && chartData[2] && chartData[2].compliedCount,
    //                     'delayed_compliance_count': chartData && chartData[2] && chartData[2].delayed_compliance_count,
    //                     'not_complied_count': chartData && chartData[2] && chartData[2].not_complied_count,
    //                     'total': chartData && chartData[2] && chartData[2].total,
    //                     'compliedCount': chartData && chartData[2] && chartData[2].compliedCount,
    //                     'delayedCount': chartData && chartData[2] && chartData[2].delayedCount,
    //                     'notCompliedCount': chartData && chartData[2] && chartData[2].notCompliedCount
    //                 },
    //                 {
    //                     'year': chartData && chartData[3] && chartData[3].year,
    //                     'complied_count': chartData && chartData[3] && chartData[3].compliedCount,
    //                     'delayed_compliance_count': chartData && chartData[3] && chartData[3].delayed_compliance_count,
    //                     'not_complied_count': chartData && chartData[3] && chartData[3].not_complied_count,
    //                     'total': chartData && chartData[3] && chartData[3].total,
    //                     'compliedCount': chartData && chartData[3] && chartData[3].compliedCount,
    //                     'delayedCount': chartData && chartData[3] && chartData[3].delayedCount,
    //                     'notCompliedCount': chartData && chartData[3] && chartData[3].notCompliedCount
    //                 },
    //                 {
    //                     'year': chartData && chartData[4] && chartData[4].year,
    //                     'complied_count': chartData && chartData[4] && chartData[4].compliedCount,
    //                     'delayed_compliance_count': chartData && chartData[4] && chartData[4].delayed_compliance_count,
    //                     'not_complied_count': chartData && chartData[4] && chartData[4].not_complied_count,
    //                     'total': chartData && chartData[4] && chartData[4].total,
    //                     'compliedCount': chartData && chartData[4] && chartData[4].compliedCount,
    //                     'delayedCount': chartData && chartData[4] && chartData[4].delayedCount,
    //                     'notCompliedCount': chartData && chartData[4] && chartData[4].notCompliedCount
    //                 },
    //                 {
    //                     'year': chartData && chartData[5] && chartData[5].year,
    //                     'complied_count': chartData && chartData[5] && chartData[5].compliedCount,
    //                     'delayed_compliance_count': chartData && chartData[5] && chartData[5].delayed_compliance_count,
    //                     'not_complied_count': chartData && chartData[5] && chartData[5].not_complied_count,
    //                     'total': chartData && chartData[5] && chartData[5].total,
    //                     'compliedCount': chartData && chartData[5] && chartData[5].compliedCount,
    //                     'delayedCount': chartData && chartData[5] && chartData[5].delayedCount,
    //                     'notCompliedCount': chartData && chartData[5] && chartData[5].notCompliedCount
    //                 },
    //                 {
    //                     'year': chartData && chartData[6] && chartData[6].year,
    //                     'complied_count': chartData && chartData[6] && chartData[6].compliedCount,
    //                     'delayed_compliance_count': chartData && chartData[6] && chartData[6].delayed_compliance_count,
    //                     'not_complied_count': chartData && chartData[6] && chartData[6].not_complied_count,
    //                     'total': chartData && chartData[6] && chartData[6].total,
    //                     'compliedCount': chartData && chartData[6] && chartData[6].compliedCount,
    //                     'delayedCount': chartData && chartData[6] && chartData[6].delayedCount,
    //                     'notCompliedCount': chartData && chartData[6] && chartData[6].notCompliedCount
    //                 },
    //                 {
    //                     'year': chartData && chartData[7] && chartData[7].year,
    //                     'complied_count': chartData && chartData[7] && chartData[7].compliedCount,
    //                     'delayed_compliance_count': chartData && chartData[7] && chartData[7].delayed_compliance_count,
    //                     'not_complied_count': chartData && chartData[7] && chartData[7].not_complied_count,
    //                     'total': chartData && chartData[7] && chartData[7].total,
    //                     'compliedCount': chartData && chartData[7] && chartData[7].compliedCount,
    //                     'delayedCount': chartData && chartData[7] && chartData[7].delayedCount,
    //                     'notCompliedCount': chartData && chartData[7] && chartData[7].notCompliedCount
    //                 },
    //                 {
    //                     'year': chartData && chartData[8] && chartData[8].year,
    //                     'complied_count': chartData && chartData[8] && chartData[8].compliedCount,
    //                     'delayed_compliance_count': chartData && chartData[8] && chartData[8].delayed_compliance_count,
    //                     'not_complied_count': chartData && chartData[8] && chartData[8].not_complied_count,
    //                     'total': chartData && chartData[8] && chartData[8].total,
    //                     'compliedCount': chartData && chartData[8] && chartData[8].compliedCount,
    //                     'delayedCount': chartData && chartData[8] && chartData[8].delayedCount,
    //                     'notCompliedCount': chartData && chartData[8] && chartData[8].notCompliedCount
    //                 },
    //                 {
    //                     'year': chartData && chartData[9] && chartData[9].year,
    //                     'complied_count': chartData && chartData[9] && chartData[9].compliedCount,
    //                     'delayed_compliance_count': chartData && chartData[9] && chartData[9].delayed_compliance_count,
    //                     'not_complied_count': chartData && chartData[9] && chartData[9].not_complied_count,
    //                     'total': chartData && chartData[9] && chartData[9].total,
    //                     'compliedCount': chartData && chartData[9] && chartData[9].compliedCount,
    //                     'delayedCount': chartData && chartData[9] && chartData[9].delayedCount,
    //                     'notCompliedCount': chartData && chartData[9] && chartData[9].notCompliedCount
    //                 },
    //                 {
    //                     'year': chartData && chartData[10] && chartData[10].year,
    //                     'complied_count': chartData && chartData[10] && chartData[10].compliedCount,
    //                     'delayed_compliance_count': chartData && chartData[10] && chartData[10].delayed_compliance_count,
    //                     'not_complied_count': chartData && chartData[10] && chartData[10].not_complied_count,
    //                     'total': chartData && chartData[10] && chartData[10].total,
    //                     'compliedCount': chartData && chartData[10] && chartData[10].compliedCount,
    //                     'delayedCount': chartData && chartData[10] && chartData[10].delayedCount,
    //                     'notCompliedCount': chartData && chartData[10] && chartData[10].notCompliedCount
    //                 },
    //                 {
    //                     'year': chartData && chartData[11] && chartData[11].year,
    //                     'complied_count': chartData && chartData[11] && chartData[11].compliedCount,
    //                     'delayed_compliance_count': chartData && chartData[11] && chartData[11].delayed_compliance_count,
    //                     'not_complied_count': chartData && chartData[11] && chartData[11].not_complied_count,
    //                     'total': chartData && chartData[11] && chartData[11].total,
    //                     'compliedCount': chartData && chartData[11] && chartData[11].compliedCount,
    //                     'delayedCount': chartData && chartData[11] && chartData[11].delayedCount,
    //                     'notCompliedCount': chartData && chartData[11] && chartData[11].notCompliedCount
    //                 },
    //             ])
    //         }

    //     }
    //     else {
    //         if (toggleButton === 'Month') {
    //             setTempData([
    //                 {
    //                     'year': chartData && chartData[0] && chartData[0].year,
    //                     'complied_count': chartData && chartData[0] && chartData[0].compliedCount,
    //                     'delayed_compliance_count': chartData && chartData[0] && chartData[0].delayed_compliance_count,
    //                     'not_complied_count': chartData && chartData[0] && chartData[0].not_complied_count,
    //                     'total': chartData && chartData[0] && chartData[0].total,
    //                     'compliedCount': chartData && chartData[0] && chartData[0].compliedCount,
    //                     'delayedCount': chartData && chartData[0] && chartData[0].delayedCount,
    //                     'notCompliedCount': chartData && chartData[0] && chartData[0].notCompliedCount
    //                 },
    //                 {
    //                     'year': chartData && chartData[1] && chartData[1].year,
    //                     'complied_count': chartData && chartData[1] && chartData[1].compliedCount,
    //                     'delayed_compliance_count': chartData && chartData[1] && chartData[1].delayed_compliance_count,
    //                     'not_complied_count': chartData && chartData[1] && chartData[1].not_complied_count,
    //                     'total': chartData && chartData[1] && chartData[1].total,
    //                     'compliedCount': chartData && chartData[1] && chartData[1].compliedCount,
    //                     'delayedCount': chartData && chartData[1] && chartData[1].delayedCount,
    //                     'notCompliedCount': chartData && chartData[1] && chartData[1].notCompliedCount
    //                 },
    //                 {
    //                     'year': chartData && chartData[2] && chartData[2].year,
    //                     'complied_count': chartData && chartData[2] && chartData[2].compliedCount,
    //                     'delayed_compliance_count': chartData && chartData[2] && chartData[2].delayed_compliance_count,
    //                     'not_complied_count': chartData && chartData[2] && chartData[2].not_complied_count,
    //                     'total': chartData && chartData[2] && chartData[2].total,
    //                     'compliedCount': chartData && chartData[2] && chartData[2].compliedCount,
    //                     'delayedCount': chartData && chartData[2] && chartData[2].delayedCount,
    //                     'notCompliedCount': chartData && chartData[2] && chartData[2].notCompliedCount
    //                 },
    //                 {
    //                     'year': chartData && chartData[3] && chartData[3].year,
    //                     'complied_count': chartData && chartData[3] && chartData[3].compliedCount,
    //                     'delayed_compliance_count': chartData && chartData[3] && chartData[3].delayed_compliance_count,
    //                     'not_complied_count': chartData && chartData[3] && chartData[3].not_complied_count,
    //                     'total': chartData && chartData[3] && chartData[3].total,
    //                     'compliedCount': chartData && chartData[3] && chartData[3].compliedCount,
    //                     'delayedCount': chartData && chartData[3] && chartData[3].delayedCount,
    //                     'notCompliedCount': chartData && chartData[3] && chartData[3].notCompliedCount
    //                 },
    //                 {
    //                     'year': chartData && chartData[4] && chartData[4].year,
    //                     'complied_count': chartData && chartData[4] && chartData[4].compliedCount,
    //                     'delayed_compliance_count': chartData && chartData[4] && chartData[4].delayed_compliance_count,
    //                     'not_complied_count': chartData && chartData[4] && chartData[4].not_complied_count,
    //                     'total': chartData && chartData[4] && chartData[4].total,
    //                     'compliedCount': chartData && chartData[4] && chartData[4].compliedCount,
    //                     'delayedCount': chartData && chartData[4] && chartData[4].delayedCount,
    //                     'notCompliedCount': chartData && chartData[4] && chartData[4].notCompliedCount
    //                 },
    //                 {
    //                     'year': chartData && chartData[5] && chartData[5].year,
    //                     'complied_count': chartData && chartData[5] && chartData[5].compliedCount,
    //                     'delayed_compliance_count': chartData && chartData[5] && chartData[5].delayed_compliance_count,
    //                     'not_complied_count': chartData && chartData[5] && chartData[5].not_complied_count,
    //                     'total': chartData && chartData[5] && chartData[5].total,
    //                     'compliedCount': chartData && chartData[5] && chartData[5].compliedCount,
    //                     'delayedCount': chartData && chartData[5] && chartData[5].delayedCount,
    //                     'notCompliedCount': chartData && chartData[5] && chartData[5].notCompliedCount
    //                 },
    //                 {
    //                     'year': chartData && chartData[6] && chartData[6].year,
    //                     'complied_count': chartData && chartData[6] && chartData[6].compliedCount,
    //                     'delayed_compliance_count': chartData && chartData[6] && chartData[6].delayed_compliance_count,
    //                     'not_complied_count': chartData && chartData[6] && chartData[6].not_complied_count,
    //                     'total': chartData && chartData[6] && chartData[6].total,
    //                     'compliedCount': chartData && chartData[6] && chartData[6].compliedCount,
    //                     'delayedCount': chartData && chartData[6] && chartData[6].delayedCount,
    //                     'notCompliedCount': chartData && chartData[6] && chartData[6].notCompliedCount
    //                 },
    //                 {
    //                     'year': chartData && chartData[7] && chartData[7].year,
    //                     'complied_count': chartData && chartData[7] && chartData[7].compliedCount,
    //                     'delayed_compliance_count': chartData && chartData[7] && chartData[7].delayed_compliance_count,
    //                     'not_complied_count': chartData && chartData[7] && chartData[7].not_complied_count,
    //                     'total': chartData && chartData[7] && chartData[7].total,
    //                     'compliedCount': chartData && chartData[7] && chartData[7].compliedCount,
    //                     'delayedCount': chartData && chartData[7] && chartData[7].delayedCount,
    //                     'notCompliedCount': chartData && chartData[7] && chartData[7].notCompliedCount
    //                 },
    //                 {
    //                     'year': chartData && chartData[8] && chartData[8].year,
    //                     'complied_count': chartData && chartData[8] && chartData[8].compliedCount,
    //                     'delayed_compliance_count': chartData && chartData[8] && chartData[8].delayed_compliance_count,
    //                     'not_complied_count': chartData && chartData[8] && chartData[8].not_complied_count,
    //                     'total': chartData && chartData[8] && chartData[8].total,
    //                     'compliedCount': chartData && chartData[8] && chartData[8].compliedCount,
    //                     'delayedCount': chartData && chartData[8] && chartData[8].delayedCount,
    //                     'notCompliedCount': chartData && chartData[8] && chartData[8].notCompliedCount
    //                 },
    //                 {
    //                     'year': chartData && chartData[9] && chartData[9].year,
    //                     'complied_count': chartData && chartData[9] && chartData[9].compliedCount,
    //                     'delayed_compliance_count': chartData && chartData[9] && chartData[9].delayed_compliance_count,
    //                     'not_complied_count': chartData && chartData[9] && chartData[9].not_complied_count,
    //                     'total': chartData && chartData[9] && chartData[9].total,
    //                     'compliedCount': chartData && chartData[9] && chartData[9].compliedCount,
    //                     'delayedCount': chartData && chartData[9] && chartData[9].delayedCount,
    //                     'notCompliedCount': chartData && chartData[9] && chartData[9].notCompliedCount
    //                 },
    //                 {
    //                     'year': chartData && chartData[10] && chartData[10].year,
    //                     'complied_count': chartData && chartData[10] && chartData[10].compliedCount,
    //                     'delayed_compliance_count': chartData && chartData[10] && chartData[10].delayed_compliance_count,
    //                     'not_complied_count': chartData && chartData[10] && chartData[10].not_complied_count,
    //                     'total': chartData && chartData[10] && chartData[10].total,
    //                     'compliedCount': chartData && chartData[10] && chartData[10].compliedCount,
    //                     'delayedCount': chartData && chartData[10] && chartData[10].delayedCount,
    //                     'notCompliedCount': chartData && chartData[10] && chartData[10].notCompliedCount
    //                 },
    //                 {
    //                     'year': chartData && chartData[11] && chartData[11].year,
    //                     'complied_count': chartData && chartData[11] && chartData[11].compliedCount,
    //                     'delayed_compliance_count': chartData && chartData[11] && chartData[11].delayed_compliance_count,
    //                     'not_complied_count': chartData && chartData[11] && chartData[11].not_complied_count,
    //                     'total': chartData && chartData[11] && chartData[11].total,
    //                     'compliedCount': chartData && chartData[11] && chartData[11].compliedCount,
    //                     'delayedCount': chartData && chartData[11] && chartData[11].delayedCount,
    //                     'notCompliedCount': chartData && chartData[11] && chartData[11].notCompliedCount
    //                 },
    //             ])
    //         }


    //     }
    // }, [chartData, toggleButton, data.compliance_data, year, chartType])

    console.log(tempData, 'tempData');
    console.log(resultArray, 'resultArray');
    console.log(chartData, 'chartData');

    useEffect(() => {
        if (chartType === "all") {
            if (tempData && tempData.length > 0) {
                am4core.useTheme(am4themes_animated);
                // console.log(dataValue && dataValue.length && dataValue[0].complied_count, 'dataValue && dataValue');
                //Compliance Status        

                var Comprativechart = am4core.create("Comprativechartdiv", am4charts.XYChart);
                // Comprativechart.height = '350'
                // Comprativechart.width = '450'
                Comprativechart.responsive.enabled = true;

                if (resultArray && resultArray.length > 0) {
                    // Add data
                    Comprativechart.data = tempData;

                    // Comprativechart.dateFormatter.inputDateFormat = "yyyy";
                    // var dateAxis = Comprativechart.xAxes.push(new am4charts.DateAxis());
                    // dateAxis.renderer.minGridDistance = 60;
                    // dateAxis.startLocation = 0.5;
                    // dateAxis.endLocation = 0.5;
                    // dateAxis.title.text = toggleButton === 'Month' ? "Month-Year" : "Year";

                    var dateAxis = Comprativechart.xAxes.push(new am4charts.CategoryAxis());
                    dateAxis.dataFields.category = "year";
                    dateAxis.title.text = "Year";
                    dateAxis.renderer.grid.template.location = 0;
                    dateAxis.renderer.minGridDistance = 20;
                    dateAxis.startLocation = 0.5;
                    // dateAxis.endLocation = 0.5;
                    dateAxis.title.text = toggleButton === 'Month' ? "Month-Year" : "Year";

                    // dateAxis.baseInterval = {
                    //     timeUnit: "year",
                    //     count: 1
                    // }



                    var comvalueAxis = Comprativechart.yAxes.push(new am4charts.ValueAxis());
                    comvalueAxis.tooltip.disabled = true;
                    comvalueAxis.title.text = "Compliance(%)";
                    comvalueAxis.min = 0;
                    comvalueAxis.max = 100;

                    var comseries = Comprativechart.series.push(new am4charts.LineSeries());
                    comseries.dataFields.categoryX = "year";
                    comseries.name = "Complied";
                    comseries.dataFields.valueY = "complied_count";
                    comseries.tooltipHTML = "<span style='font-size:14px; color:#000000;'>Complied: {complied_count.formatNumber('#.00')}% (<b>{compliedCount}</b> out of <b>{total})</b></span>";
                    comseries.tooltipText = "[#000]{compliedCount}[/]";
                    comseries.tooltip.background.fill = am4core.color("#FFF");
                    comseries.tooltip.getStrokeFromObject = true;
                    comseries.tooltip.background.strokeWidth = 3;
                    comseries.tooltip.getFillFromObject = false;
                    comseries.fillOpacity = 0.6;
                    comseries.strokeWidth = 2;
                    comseries.stacked = false;

                    var comseries2 = Comprativechart.series.push(new am4charts.LineSeries());
                    comseries2.name = "Delayed Compliance";
                    comseries2.dataFields.categoryX = "year";
                    comseries2.dataFields.valueY = "delayed_compliance_count";
                    comseries2.tooltipHTML = "<span style='font-size:14px; color:#000000;'>Delayed Compliance: {delayed_compliance_count.formatNumber('#.00')}% (<b>{delayedCount}</b> out of <b>{total})</b></span>";
                    comseries2.tooltip.background.fill = am4core.color("#FFF");
                    comseries2.tooltip.getFillFromObject = false;
                    comseries2.tooltip.getStrokeFromObject = true;
                    comseries2.tooltip.background.strokeWidth = 3;
                    comseries2.sequencedInterpolation = true;
                    comseries2.fillOpacity = 0.6;
                    comseries2.stacked = false;
                    comseries2.strokeWidth = 2;

                    var comseries3 = Comprativechart.series.push(new am4charts.LineSeries());
                    comseries3.name = "Not Complied";
                    // Mukilan
                    comseries3.dataFields.categoryX = "year";
                    comseries3.dataFields.valueY = "not_complied_count";
                    comseries3.tooltipHTML = "<span style='font-size:14px; color:#000000;'>Not Complied: {not_complied_count.formatNumber('#.00')}% (<b>{notCompliedCount}</b> out of <b>{total})</b></span>";
                    comseries3.tooltipText = "[#000]{notCompliedCount}[/]";
                    comseries3.tooltip.background.fill = am4core.color("#FFF");
                    comseries3.tooltip.getFillFromObject = false;
                    comseries3.tooltip.getStrokeFromObject = true;
                    comseries3.tooltip.background.strokeWidth = 3;
                    comseries3.sequencedInterpolation = true;
                    comseries3.fillOpacity = 0.6;
                    comseries3.defaultState.transitionDuration = 1000;
                    comseries3.stacked = false;
                    comseries3.strokeWidth = 2;

                    Comprativechart.cursor = new am4charts.XYCursor();
                    Comprativechart.cursor.xAxis = dateAxis;
                    Comprativechart.scrollbarX = new am4core.Scrollbar();

                    Comprativechart.legend = new am4charts.Legend();
                    // Comprativechart.legend.position = "top";

                    // axis ranges
                    var range = dateAxis.axisRanges.create();
                    range.date = new Date(2001, 0, 1);
                    range.endDate = new Date(2003, 0, 1);
                    range.axisFill.fill = Comprativechart.colors.getIndex(7);
                    range.axisFill.fillOpacity = 0.2;

                    range.label.text = "";
                    range.label.inside = true;
                    range.label.rotation = 90;
                    range.label.horizontalCenter = "right";
                    range.label.verticalCenter = "bottom";

                    var range2 = dateAxis.axisRanges.create();
                    range2.date = new Date(2007, 0, 1);
                    range2.grid.stroke = Comprativechart.colors.getIndex(7);
                    range2.grid.strokeOpacity = 0.6;
                    range2.grid.strokeDasharray = "5,2";


                    var title = Comprativechart.titles.create();
                    title.text = generatedOn == true ? `Generated on : ${timeZoneData.tz_name} ${timeZone}` : `Generated on : ${time} (UTC)`
                    title.disabled = false;
                    title.align = 'left'
                    title.marginTop = '2%'
                    title.marginLeft = '2%'
                    title.marginBottom = '2%'
                    var title1 = Comprativechart.titles.create();
                    title1.text =
                        ((toggleButton === 'Month' && fieldType == "LegalEntity" && go == true && type === '') ? `Comparative Trend of Legal Entity [bold]${filterDataNames.legalentity}[/] (${year})` : (
                            (toggleButton === 'Month' && fieldType == "Division" && go == true && type === '') ? `Comparative Trend of Division [bold]${filterDataNames.division}[/] (${year})` : (
                                (toggleButton === 'Month' && fieldType == "Category" && go == true && type === '') ? `Comparative Trend of Category [bold]${filterDataNames.category}[/] (${year})` : (
                                    (toggleButton === 'Month' && fieldType == "Unit" && go == true && type === '') ? `Comparative Trend of Business Group [bold]${filterDataNames.unit}[/] (${year})` : (
                                        (toggleButton === 'Month' && fieldType == "BusinessGroup" && go == true && type === '') ? `Comparative Trend of ${localStorage.getItem('isVendor') == "true" ? 'Vendor' : 'Unit'} [bold]${filterDataNames.business}[/] (${year})` : (
                                            (toggleButton === 'Month') ? `Comparative Trend of ${commonData && commonData.g_name} (${year})` :
                                                (toggleButton === 'Year' && fieldType == "LegalEntity" && go == true && type === '') ? `Comparative Trend of Legal Entity [bold]${filterDataNames.legalentity}[/] (${year})` : (
                                                    (toggleButton === 'Year' && fieldType == "Division" && go == true && type === '') ? `Comparative Trend of Division [bold]${filterDataNames.division}[/] (${year})` : (
                                                        (toggleButton === 'Year' && fieldType == "Category" && go == true && type === '') ? `Comparative Trend of Category [bold]${filterDataNames.category}[/] (${year})` : (
                                                            (toggleButton === 'Year' && fieldType == "Unit" && go == true && type === '') ? `Comparative Trend of ${localStorage.getItem('isVendor') == "true" ? 'Vendor' : 'Unit'} [bold]${filterDataNames.unit}[/] (${year})` : (
                                                                (toggleButton === 'Year' && fieldType == "BusinessGroup" && go == true && type === '') ? `Comparative Trend of Business Group [bold]${filterDataNames.business}[/] (${year})` :
                                                                    `Comparative Trend of ${commonData && commonData.g_name} (${yearData && yearData.length > 0 && yearData[0]} to ${yearData && yearData.length > 0 && yearData[yearData.length - 1]} )`
                                                            ))))))))
                        ))
                    title1.disabled = false;
                    title1.align = 'center'
                    title1.marginTop = '2%'
                    title1.marginLeft = '2%'
                    title1.fontSize = '17px'
                    title1.fontWeight = '400'

                    var label = Comprativechart.createChild(am4core.Label);
                    label.text = "Country-Domain";
                    label.fontSize = 14;
                    label.align = "left";
                    label.marginLeft = '2%';
                    label.marginBottom = '2%'
                    label.fontWeight = 'bold'

                    // "Series: [bold {color}]{name}[/]";


                    var label1 = Comprativechart.createChild(am4core.Label);
                    label1.text = `${textArray}`
                    label1.fontSize = 14;
                    label1.align = "left";
                    label1.marginLeft = '2%';
                    label1.marginBottom = '1%'

                }
                else {
                    var title3 = Comprativechart.createChild(am4core.Label)
                    title3.text = "No Data to Display"
                    title3.disabled = false;
                    title3.align = 'left'
                    title3.marginTop = '10%'
                    title3.paddingLeft = '43%'
                    title3.marginBottom = '50%'
                    // title3.fontWeight = '500'
                    title3.fontSize = '16'
                }

            }
        }
        else if (chartType === "complied") {

            var Comprativechart = am4core.create("Comprativechartdiv", am4charts.XYChart);
            // Comprativechart.height = '350'
            // Comprativechart.width = '450'
            Comprativechart.responsive.enabled = true;

            if (tempData && tempData.length > 0) {
                // Add data
                Comprativechart.data = tempData;

                // Comprativechart.dateFormatter.inputDateFormat = "yyyy";
                // var dateAxis = Comprativechart.xAxes.push(new am4charts.DateAxis());
                // dateAxis.renderer.minGridDistance = 60;
                // dateAxis.startLocation = 0.5;
                // dateAxis.endLocation = 0.5;
                // dateAxis.title.text = toggleButton === 'Month' ? "Month-Year" : "Year";

                var dateAxis = Comprativechart.xAxes.push(new am4charts.CategoryAxis());
                dateAxis.dataFields.category = "year";
                dateAxis.title.text = "Year";
                dateAxis.renderer.grid.template.location = 0;
                dateAxis.renderer.minGridDistance = 20;
                dateAxis.startLocation = 0.5;
                // dateAxis.endLocation = 0.5;
                dateAxis.title.text = toggleButton === 'Month' ? "Month-Year" : "Year";
                // dateAxis.baseInterval = {
                //     timeUnit: "year",
                //     count: 1
                // }

                if (toggleButton === 'Month') {

                    dateAxis.renderer.labels.template.events.on("hit", function (event) {
                        console.log('hiiiiii');
                        console.log(event, 'evee');
                        console.log(event.target.currentText, 'currrrr')
                        setClickState(true)
                        setCount(event.target.dataItem._dataContext.compliedCount)
                        setCriticalityValue('Complied')
                        // setCount(ev.target.dataItem.customValue)
                        // setTable(true)
                        const payload = [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request": [
                                    "GetComplianceTrendDrillDownData",
                                    {
                                        "d_ids": dIds
                                        ,
                                        "filter_type": fieldType != "" ? fieldType : "Group",
                                        "filter_ids": [
                                            fieldType == "LegalEntity" ? filterData.legalentity :
                                                fieldType == 'Division' ? Number(filterData.division) :
                                                    fieldType == 'Category' ? Number(filterData.category) :
                                                        fieldType == 'Unit' ? Number(filterData.unit) :
                                                            fieldType == 'BusinessGroup' ? Number(filterData.business) :
                                                                1
                                        ],
                                        "chart_year": toggleButton === 'Month' ? year : Number(event.target.dataItem.categoryX),
                                        "chart_month": (event.target.currentText === `Feb-${year}` ? 2 : (event.target.currentText === `Mar-${year}` ? 3 : (event.target.currentText === `Apr-${year}` ? 4 : (event.target.currentText === `May-${year}` ? 5 : (event.target.currentText === `Jun-${year}` ? 6 : (event.target.currentText === `Jul-${year}` ? 7 : (event.target.currentText === `Aug-${year}` ? 8 : (event.target.currentText === `Sep-${year}` ? 9 : (event.target.currentText === `Oct-${year}` ? 10 : (event.target.currentText === `Nov-${year}` ? 11 : (event.target.currentText === `Dec-${year}` ? 12 : 1))))))))))),
                                        "record_count": 0,
                                        "le_ids":
                                            filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                                        ,
                                        "country_domains": countryDomain,
                                        "compliance_status": "Complied"
                                    }
                                ]
                            }
                        ]
                        if (paramid != "") {
                            getComplianceTrendTableData({
                                payload: payload,
                                paramid: paramid
                            })
                        }
                    })
                    dateAxis.renderer.labels.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
                }
                else {
                    dateAxis.renderer.labels.template.events.on("hit", function (event) {
                        console.log('hiiiiii');
                        console.log(event, 'evee');
                        console.log(event.target.currentText, 'currrrr')
                        setCount(event.target.dataItem._dataContext.compliedCount)
                        // setCount(ev.target.dataItem.customValue)
                        setClickState(true)
                        setCriticalityValue('Complied')
                        // setTable(true)
                        const payload = [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request": [
                                    "GetComplianceTrendDrillDownData",
                                    {
                                        "d_ids": dIds
                                        ,
                                        "filter_type": fieldType != "" ? fieldType : "Group",
                                        "filter_ids": [
                                            fieldType == "LegalEntity" ? filterData.legalentity :
                                                fieldType == 'Division' ? Number(filterData.division) :
                                                    fieldType == 'Category' ? Number(filterData.category) :
                                                        fieldType == 'Unit' ? Number(filterData.unit) :
                                                            fieldType == 'BusinessGroup' ? Number(filterData.business) :
                                                                1
                                        ],
                                        "chart_year": toggleButton === 'Month' ? year : Number(event.target.dataItem.categoryX),
                                        "chart_month": (event.target.currentText === `Feb-${year}` ? 2 : (event.target.currentText === `Mar-${year}` ? 3 : (event.target.currentText === `Apr-${year}` ? 4 : (event.target.currentText === `May-${year}` ? 5 : (event.target.currentText === `Jun-${year}` ? 6 : (event.target.currentText === `Jul-${year}` ? 7 : (event.target.currentText === `Aug-${year}` ? 8 : (event.target.currentText === `Sep-${year}` ? 9 : (event.target.currentText === `Oct-${year}` ? 10 : (event.target.currentText === `Nov-${year}` ? 11 : (event.target.currentText === `Dec-${year}` ? 12 : 1))))))))))),
                                        "record_count": 0,
                                        "le_ids":
                                            filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                                        ,
                                        "country_domains": countryDomain,
                                        "compliance_status": "Complied"
                                    }
                                ]
                            }
                        ]
                        if (paramid != "") {
                            getComplianceTrendTableData({
                                payload: payload,
                                paramid: paramid
                            })
                        }
                    })
                    dateAxis.renderer.labels.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;

                }

                var comvalueAxis = Comprativechart.yAxes.push(new am4charts.ValueAxis());
                comvalueAxis.tooltip.disabled = true;
                comvalueAxis.title.text = "Compliance(%)";
                comvalueAxis.min = 0;
                comvalueAxis.max = 100;

                var comseries = Comprativechart.series.push(new am4charts.LineSeries());
                comseries.dataFields.categoryX = "year";
                comseries.name = "Complied";
                comseries.dataFields.valueY = "complied_count";
                comseries.tooltipHTML = "<span style='font-size:14px; color:#000000;'>Complied: {complied_count.formatNumber('#.00')}% (<b>{compliedCount}</b> out of <b>{total})</b></span>";
                comseries.tooltipText = "[#000]{compliedCount}[/]";
                comseries.tooltip.background.fill = am4core.color("#FFF");
                comseries.tooltip.getStrokeFromObject = true;
                comseries.tooltip.background.strokeWidth = 3;
                comseries.tooltip.getFillFromObject = false;
                comseries.fillOpacity = 0.6;
                comseries.strokeWidth = 2;
                comseries.stacked = false;

                Comprativechart.cursor = new am4charts.XYCursor();
                Comprativechart.cursor.xAxis = dateAxis;
                Comprativechart.scrollbarX = new am4core.Scrollbar();

                Comprativechart.legend = new am4charts.Legend();
                // Comprativechart.legend.position = "top";

                // axis ranges
                var range = dateAxis.axisRanges.create();
                range.date = new Date(2001, 0, 1);
                range.endDate = new Date(2003, 0, 1);
                range.axisFill.fill = Comprativechart.colors.getIndex(7);
                range.axisFill.fillOpacity = 0.2;

                range.label.text = "";
                range.label.inside = true;
                range.label.rotation = 90;
                range.label.horizontalCenter = "right";
                range.label.verticalCenter = "bottom";

                //  var range2 = dateAxis.axisRanges.create();
                //  range2.date = new Date(2007, 0, 1);
                //  range2.grid.stroke = Comprativechart.colors.getIndex(7);
                //  range2.grid.strokeOpacity = 0.6;
                //  range2.grid.strokeDasharray = "5,2";


                var title = Comprativechart.titles.create();
                title.text = generatedOn == true ? `Generated on : ${timeZoneData.tz_name} ${timeZone}` : `Generated on : ${time} (UTC)`
                title.disabled = false;
                title.align = 'left'
                title.marginTop = '2%'
                title.marginLeft = '2%'
                title.marginBottom = '2%'
                var title1 = Comprativechart.titles.create();
                title1.text =
                    ((toggleButton === 'Month' && fieldType == "LegalEntity" && go == true && type === '') ? `Complied of Legal Entity [bold]${filterDataNames.legalentity}[/] (${year})` : (
                        (toggleButton === 'Month' && fieldType == "Division" && go == true && type === '') ? `Complied of Division [bold]${filterDataNames.division}[/] (${year})` : (
                            (toggleButton === 'Month' && fieldType == "Category" && go == true && type === '') ? `Complied of Category [bold]${filterDataNames.category}[/] (${year})` : (
                                (toggleButton === 'Month' && fieldType == "Unit" && go == true && type === '') ? `Complied of Business Group [bold]${filterDataNames.unit}[/] (${year})` : (
                                    (toggleButton === 'Month' && fieldType == "BusinessGroup" && go == true && type === '') ? `Complied of Unit [bold]${filterDataNames.business}[/] (${year})` : (
                                        (toggleButton === 'Month') ? `Complied (${year})` :
                                            (toggleButton === 'Year' && fieldType == "LegalEntity" && go == true && type === '') ? `Complied of Legal Entity [bold]${filterDataNames.legalentity}[/] (${year})` : (
                                                (toggleButton === 'Year' && fieldType == "Division" && go == true && type === '') ? `Complied of Division [bold]${filterDataNames.division}[/] (${year})` : (
                                                    (toggleButton === 'Year' && fieldType == "Category" && go == true && type === '') ? `Complied of Category [bold]${filterDataNames.category}[/] (${year})` : (
                                                        (toggleButton === 'Year' && fieldType == "Unit" && go == true && type === '') ? `Complied of Unit [bold]${filterDataNames.unit}[/] (${year})` : (
                                                            (toggleButton === 'Year' && fieldType == "BusinessGroup" && go == true && type === '') ? `Complied of Business Group [bold]${filterDataNames.business}[/] (${year})` :
                                                                `Complied (${yearData && yearData.length > 0 && yearData[0]} to ${yearData && yearData.length > 0 && yearData[yearData.length - 1]} )`
                                                        ))))))))
                    ))
                title1.disabled = false;
                title1.align = 'center'
                title1.marginTop = '2%'
                title1.marginLeft = '2%'
                title1.fontSize = '17px'
                title1.fontWeight = '400'

                var label = Comprativechart.createChild(am4core.Label);
                label.text = "Country-Domain";
                label.fontSize = 14;
                label.align = "left";
                label.marginLeft = '2%';
                label.marginBottom = '2%'
                label.fontWeight = 'bold'

                // "Series: [bold {color}]{name}[/]";


                var label1 = Comprativechart.createChild(am4core.Label);
                label1.text = `${textArray}`
                label1.fontSize = 14;
                label1.align = "left";
                label1.marginLeft = '2%';
                label1.marginBottom = '1%'
            }
            else {
                var title3 = Comprativechart.createChild(am4core.Label)
                title3.text = "No Data to Display"
                title3.disabled = false;
                title3.align = 'left'
                title3.marginTop = '10%'
                title3.paddingLeft = '43%'
                title3.marginBottom = '50%'
                // title3.fontWeight = '500'
                title3.fontSize = '16'
            }
        }
        else if (chartType === "delayedComplied") {

            var Comprativechart = am4core.create("Comprativechartdiv", am4charts.XYChart);
            // Comprativechart.height = '350'
            // Comprativechart.width = '450'
            Comprativechart.responsive.enabled = true;
            // Add data
            if (tempData && tempData.length > 0) {
                Comprativechart.data = tempData;

                // Comprativechart.dateFormatter.inputDateFormat = "yyyy";
                // var dateAxis = Comprativechart.xAxes.push(new am4charts.DateAxis());
                // dateAxis.renderer.minGridDistance = 60;
                // dateAxis.startLocation = 0.5;
                // dateAxis.endLocation = 0.5;
                // dateAxis.title.text = toggleButton === 'Month' ? "Month-Year" : "Year";
                var dateAxis = Comprativechart.xAxes.push(new am4charts.CategoryAxis());
                dateAxis.dataFields.category = "year";
                dateAxis.title.text = "Year";
                dateAxis.renderer.grid.template.location = 0;
                dateAxis.renderer.minGridDistance = 20;
                dateAxis.startLocation = 0.5;
                // dateAxis.endLocation = 0.5;
                dateAxis.title.text = toggleButton === 'Month' ? "Month-Year" : "Year";
                // dateAxis.baseInterval = {
                //     timeUnit: "year",
                //     count: 1
                // }

                if (toggleButton === 'Month') {
                    dateAxis.renderer.labels.template.events.on("hit", function (event) {
                        console.log('hiiiiii');
                        console.log(event, 'evee');
                        console.log(event.target.currentText, 'currrrr')
                        setClickState(true)
                        setCriticalityValue("Delayed Compliance")
                        setCount(event.target.dataItem._dataContext.delayedCount)


                        // setCount(ev.target.dataItem.customValue)
                        // setTable(true)
                        const payload = [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request": [
                                    "GetComplianceTrendDrillDownData",
                                    {
                                        "d_ids": dIds
                                        ,
                                        "filter_type": fieldType != "" ? fieldType : "Group",
                                        "filter_ids": [
                                            fieldType == "LegalEntity" ? filterData.legalentity :
                                                fieldType == 'Division' ? Number(filterData.division) :
                                                    fieldType == 'Category' ? Number(filterData.category) :
                                                        fieldType == 'Unit' ? Number(filterData.unit) :
                                                            fieldType == 'BusinessGroup' ? Number(filterData.business) :
                                                                1
                                        ],
                                        "chart_year": toggleButton === 'Month' ? year : Number(event.target.dataItem.categoryX),
                                        "chart_month": (event.target.currentText === `Feb-${year}` ? 2 : (event.target.currentText === `Mar-${year}` ? 3 : (event.target.currentText === `Apr-${year}` ? 4 : (event.target.currentText === `May-${year}` ? 5 : (event.target.currentText === `Jun-${year}` ? 6 : (event.target.currentText === `Jul-${year}` ? 7 : (event.target.currentText === `Aug-${year}` ? 8 : (event.target.currentText === `Sep-${year}` ? 9 : (event.target.currentText === `Oct-${year}` ? 10 : (event.target.currentText === `Nov-${year}` ? 11 : (event.target.currentText === `Dec-${year}` ? 12 : 1))))))))))),
                                        "record_count": 0,
                                        "le_ids":
                                            filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                                        ,
                                        "country_domains": countryDomain,
                                        "compliance_status": "Delayed Compliance"
                                    }
                                ]
                            }
                        ]
                        if (paramid != "") {
                            getComplianceTrendTableData({
                                payload: payload,
                                paramid: paramid
                            })
                        }
                    })
                    dateAxis.renderer.labels.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
                }
                else {
                    dateAxis.renderer.labels.template.events.on("hit", function (event) {
                        console.log('hiiiiii');
                        console.log(event, 'evee');
                        console.log(event.target.currentText, 'currrrr')
                        // setCount(ev.target.dataItem.customValue)
                        setClickState(true)
                        setCriticalityValue("Delayed Compliance")
                        setCount(event.target.dataItem._dataContext.delayedCount)

                        // setTable(true)
                        const payload = [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request": [
                                    "GetComplianceTrendDrillDownData",
                                    {
                                        "d_ids": dIds
                                        ,
                                        "filter_type": fieldType != "" ? fieldType : "Group",
                                        "filter_ids": [
                                            fieldType == "LegalEntity" ? filterData.legalentity :
                                                fieldType == 'Division' ? Number(filterData.division) :
                                                    fieldType == 'Category' ? Number(filterData.category) :
                                                        fieldType == 'Unit' ? Number(filterData.unit) :
                                                            fieldType == 'BusinessGroup' ? Number(filterData.business) :
                                                                1
                                        ],
                                        "chart_year": toggleButton === 'Month' ? year : Number(event.target.dataItem.categoryX),
                                        "chart_month": (event.target.currentText === `Feb-${year}` ? 2 : (event.target.currentText === `Mar-${year}` ? 3 : (event.target.currentText === `Apr-${year}` ? 4 : (event.target.currentText === `May-${year}` ? 5 : (event.target.currentText === `Jun-${year}` ? 6 : (event.target.currentText === `Jul-${year}` ? 7 : (event.target.currentText === `Aug-${year}` ? 8 : (event.target.currentText === `Sep-${year}` ? 9 : (event.target.currentText === `Oct-${year}` ? 10 : (event.target.currentText === `Nov-${year}` ? 11 : (event.target.currentText === `Dec-${year}` ? 12 : 1))))))))))),
                                        "record_count": 0,
                                        "le_ids":
                                            filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                                        ,
                                        "country_domains": countryDomain,
                                        "compliance_status": "Delayed Compliance"
                                    }
                                ]
                            }
                        ]
                        if (paramid != "") {
                            getComplianceTrendTableData({
                                payload: payload,
                                paramid: paramid
                            })
                        }
                    })
                    dateAxis.renderer.labels.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;

                }

                var comvalueAxis = Comprativechart.yAxes.push(new am4charts.ValueAxis());
                comvalueAxis.tooltip.disabled = true;
                comvalueAxis.title.text = "Compliance(%)";
                comvalueAxis.min = 0;
                comvalueAxis.max = 100;

                var comseries2 = Comprativechart.series.push(new am4charts.LineSeries());
                comseries2.name = "Delayed Compliance";
                comseries2.dataFields.categoryX = "year";
                comseries2.dataFields.valueY = "delayed_compliance_count";
                comseries2.tooltipHTML = "<span style='font-size:14px; color:#000000;'>Delayed Compliance: {delayed_compliance_count.formatNumber('#.00')}% (<b>{delayedCount}</b> out of <b>{total})</b></span>";
                comseries2.tooltip.background.fill = am4core.color("#FFF");
                comseries2.tooltip.getFillFromObject = false;
                comseries2.tooltip.getStrokeFromObject = true;
                comseries2.tooltip.background.strokeWidth = 3;
                comseries2.sequencedInterpolation = true;
                comseries2.fillOpacity = 0.6;
                comseries2.stacked = false;
                comseries2.strokeWidth = 2;
                Comprativechart.cursor = new am4charts.XYCursor();
                Comprativechart.cursor.xAxis = dateAxis;
                Comprativechart.scrollbarX = new am4core.Scrollbar();

                Comprativechart.legend = new am4charts.Legend();
                // Comprativechart.legend.position = "top";

                // axis ranges
                var range = dateAxis.axisRanges.create();
                range.date = new Date(2001, 0, 1);
                range.endDate = new Date(2003, 0, 1);
                range.axisFill.fill = Comprativechart.colors.getIndex(7);
                range.axisFill.fillOpacity = 0.2;

                range.label.text = "";
                range.label.inside = true;
                range.label.rotation = 90;
                range.label.horizontalCenter = "right";
                range.label.verticalCenter = "bottom";

                //  var range2 = dateAxis.axisRanges.create();
                //  range2.date = new Date(2007, 0, 1);
                //  range2.grid.stroke = Comprativechart.colors.getIndex(7);
                //  range2.grid.strokeOpacity = 0.6;
                //  range2.grid.strokeDasharray = "5,2";


                var title = Comprativechart.titles.create();
                title.text = generatedOn == true ? `Generated on : ${timeZoneData.tz_name} ${timeZone}` : `Generated on : ${time} (UTC)`
                title.disabled = false;
                title.align = 'left'
                title.marginTop = '2%'
                title.marginLeft = '2%'
                title.marginBottom = '2%'
                var title1 = Comprativechart.titles.create();
                title1.text =
                    ((toggleButton === 'Month' && fieldType == "LegalEntity" && go == true && type === '') ? `Delayed Compliance of Legal Entity [bold]${filterDataNames.legalentity}[/] (${year})` : (
                        (toggleButton === 'Month' && fieldType == "Division" && go == true && type === '') ? `Delayed Compliance of Division [bold]${filterDataNames.division}[/] (${year})` : (
                            (toggleButton === 'Month' && fieldType == "Category" && go == true && type === '') ? `Delayed Compliance of Category [bold]${filterDataNames.category}[/] (${year})` : (
                                (toggleButton === 'Month' && fieldType == "Unit" && go == true && type === '') ? `Delayed Compliance of Business Group [bold]${filterDataNames.unit}[/] (${year})` : (
                                    (toggleButton === 'Month' && fieldType == "BusinessGroup" && go == true && type === '') ? `Delayed Compliance of Unit [bold]${filterDataNames.business}[/] (${year})` : (
                                        (toggleButton === 'Month') ? `Delayed Compliance (${year})` :
                                            (toggleButton === 'Year' && fieldType == "LegalEntity" && go == true && type === '') ? `Delayed Compliance of Legal Entity [bold]${filterDataNames.legalentity}[/] (${year})` : (
                                                (toggleButton === 'Year' && fieldType == "Division" && go == true && type === '') ? `Delayed Compliance of Division [bold]${filterDataNames.division}[/] (${year})` : (
                                                    (toggleButton === 'Year' && fieldType == "Category" && go == true && type === '') ? `Delayed Compliance of Category [bold]${filterDataNames.category}[/] (${year})` : (
                                                        (toggleButton === 'Year' && fieldType == "Unit" && go == true && type === '') ? `Delayed Compliance of Unit [bold]${filterDataNames.unit}[/] (${year})` : (
                                                            (toggleButton === 'Year' && fieldType == "BusinessGroup" && go == true && type === '') ? `Delayed Compliance of Business Group [bold]${filterDataNames.business}[/] (${year})` :
                                                                `Delayed Compliance (${yearData && yearData.length > 0 && yearData[0]} to ${yearData && yearData.length > 0 && yearData[yearData.length - 1]} )`
                                                        ))))))))
                    ))
                title1.disabled = false;
                title1.align = 'center'
                title1.marginTop = '2%'
                title1.marginLeft = '2%'
                title1.fontSize = '17px'
                title1.fontWeight = '400'

                var label = Comprativechart.createChild(am4core.Label);
                label.text = "Country-Domain";
                label.fontSize = 14;
                label.align = "left";
                label.marginLeft = '2%';
                label.marginBottom = '2%'
                label.fontWeight = 'bold'

                // "Series: [bold {color}]{name}[/]";


                var label1 = Comprativechart.createChild(am4core.Label);
                label1.text = `${textArray}`
                label1.fontSize = 14;
                label1.align = "left";
                label1.marginLeft = '2%';
                label1.marginBottom = '1%'
            }
            else {
                var title3 = Comprativechart.createChild(am4core.Label)
                title3.text = "No Data to Display"
                title3.disabled = false;
                title3.align = 'left'
                title3.marginTop = '10%'
                title3.paddingLeft = '43%'
                title3.marginBottom = '50%'
                // title3.fontWeight = '500'
                title3.fontSize = '16'
            }
        }
        else if (chartType === "notComplied") {
            var Comprativechart = am4core.create("Comprativechartdiv", am4charts.XYChart);
            // Comprativechart.height = '350'
            // Comprativechart.width = '450'
            Comprativechart.responsive.enabled = true;
            if (tempData && tempData.length > 0) {
                // Add data
                Comprativechart.data = tempData;

                // Comprativechart.dateFormatter.inputDateFormat = "yyyy";
                // var dateAxis = Comprativechart.xAxes.push(new am4charts.DateAxis());
                // dateAxis.renderer.minGridDistance = 60;
                // dateAxis.startLocation = 0.5;
                // dateAxis.endLocation = 0.5;
                // dateAxis.title.text = toggleButton === 'Month' ? "Month-Year" : "Year";
                var dateAxis = Comprativechart.xAxes.push(new am4charts.CategoryAxis());
                dateAxis.dataFields.category = "year";
                dateAxis.title.text = "Year";
                dateAxis.renderer.grid.template.location = 0;
                dateAxis.renderer.minGridDistance = 20;
                dateAxis.startLocation = 0.5;
                // dateAxis.endLocation = 0.5;
                dateAxis.title.text = toggleButton === 'Month' ? "Month-Year" : "Year";
                // dateAxis.baseInterval = {
                //     timeUnit: "year",
                //     count: 1
                // }
                if (toggleButton === 'Month') {
                    dateAxis.renderer.labels.template.events.on("hit", function (event) {
                        console.log('hiiiiii');
                        console.log(event, 'evee');
                        console.log(event.target.currentText, 'currrrr')
                        // setCount(ev.target.dataItem.customValue)
                        setCount(event.target.dataItem._dataContext.notCompliedCount)
                        setCriticalityValue("Not Complied")
                        setClickState(true)
                        setChartMonth(event.target.currentText === `Feb-${year}` ? 2 : (event.target.currentText === `Mar-${year}` ? 3 : (event.target.currentText === `Apr-${year}` ? 4 : (event.target.currentText === `May-${year}` ? 5 : (event.target.currentText === `Jun-${year}` ? 6 : (event.target.currentText === `Jul-${year}` ? 7 : (event.target.currentText === `Aug-${year}` ? 8 : (event.target.currentText === `Sep-${year}` ? 9 : (event.target.currentText === `Oct-${year}` ? 10 : (event.target.currentText === `Nov-${year}` ? 11 : (event.target.currentText === `Dec-${year}` ? 12 : 1)))))))))))
                        // setTable(true)
                        const payload = [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request": [
                                    "GetComplianceTrendDrillDownData",
                                    {
                                        "d_ids": dIds
                                        ,
                                        "filter_type": fieldType != "" ? fieldType : "Group",
                                        "filter_ids": [
                                            fieldType == "LegalEntity" ? filterData.legalentity :
                                                fieldType == 'Division' ? Number(filterData.division) :
                                                    fieldType == 'Category' ? Number(filterData.category) :
                                                        fieldType == 'Unit' ? Number(filterData.unit) :
                                                            fieldType == 'BusinessGroup' ? Number(filterData.business) :
                                                                1
                                        ],
                                        "chart_year": toggleButton === 'Month' ? year : Number(event.target.dataItem.categoryX),
                                        "chart_month": (event.target.currentText === `Feb-${year}` ? 2 : (event.target.currentText === `Mar-${year}` ? 3 : (event.target.currentText === `Apr-${year}` ? 4 : (event.target.currentText === `May-${year}` ? 5 : (event.target.currentText === `Jun-${year}` ? 6 : (event.target.currentText === `Jul-${year}` ? 7 : (event.target.currentText === `Aug-${year}` ? 8 : (event.target.currentText === `Sep-${year}` ? 9 : (event.target.currentText === `Oct-${year}` ? 10 : (event.target.currentText === `Nov-${year}` ? 11 : (event.target.currentText === `Dec-${year}` ? 12 : 1))))))))))),
                                        "record_count": 0,
                                        "le_ids":
                                            filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                                        ,
                                        "country_domains": countryDomain,
                                        "compliance_status": "Not Complied"
                                    }
                                ]
                            }
                        ]
                        if (paramid != "") {
                            getComplianceTrendTableData({
                                payload: payload,
                                paramid: paramid
                            })
                        }
                    })
                    dateAxis.renderer.labels.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
                }
                else {
                    dateAxis.renderer.labels.template.events.on("hit", function (event) {
                        console.log('hiiiiii');
                        console.log(event, 'evee');
                        console.log(event.target.currentText, 'currrrr')
                        setClickState(true)
                        setChartMonth(event.target.currentText === `Feb-${year}` ? 2 : (event.target.currentText === `Mar-${year}` ? 3 : (event.target.currentText === `Apr-${year}` ? 4 : (event.target.currentText === `May-${year}` ? 5 : (event.target.currentText === `Jun-${year}` ? 6 : (event.target.currentText === `Jul-${year}` ? 7 : (event.target.currentText === `Aug-${year}` ? 8 : (event.target.currentText === `Sep-${year}` ? 9 : (event.target.currentText === `Oct-${year}` ? 10 : (event.target.currentText === `Nov-${year}` ? 11 : (event.target.currentText === `Dec-${year}` ? 12 : 1)))))))))))
                        setCriticalityValue("Not Complied")
                        setCount(event.target.dataItem._dataContext.notCompliedCount)

                        // setCount(ev.target.dataItem.customValue)
                        // setTable(true)
                        const payload = [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request": [
                                    "GetComplianceTrendDrillDownData",
                                    {
                                        "d_ids": dIds
                                        ,
                                        "filter_type": fieldType != "" ? fieldType : "Group",
                                        "filter_ids": [
                                            fieldType == "LegalEntity" ? filterData.legalentity :
                                                fieldType == 'Division' ? Number(filterData.division) :
                                                    fieldType == 'Category' ? Number(filterData.category) :
                                                        fieldType == 'Unit' ? Number(filterData.unit) :
                                                            fieldType == 'BusinessGroup' ? Number(filterData.business) :
                                                                1
                                        ],
                                        "chart_year": toggleButton === 'Month' ? year : Number(event.target.dataItem.categoryX),
                                        "chart_month": (event.target.currentText === `Feb-${year}` ? 2 : (event.target.currentText === `Mar-${year}` ? 3 : (event.target.currentText === `Apr-${year}` ? 4 : (event.target.currentText === `May-${year}` ? 5 : (event.target.currentText === `Jun-${year}` ? 6 : (event.target.currentText === `Jul-${year}` ? 7 : (event.target.currentText === `Aug-${year}` ? 8 : (event.target.currentText === `Sep-${year}` ? 9 : (event.target.currentText === `Oct-${year}` ? 10 : (event.target.currentText === `Nov-${year}` ? 11 : (event.target.currentText === `Dec-${year}` ? 12 : 1))))))))))),
                                        "record_count": 0,
                                        "le_ids":
                                            filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                                        ,
                                        "country_domains": countryDomain,
                                        "compliance_status": "Not Complied"
                                    }
                                ]
                            }
                        ]
                        if (paramid != "") {
                            getComplianceTrendTableData({
                                payload: payload,
                                paramid: paramid
                            })
                        }
                    })
                    dateAxis.renderer.labels.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;

                }

                var comvalueAxis = Comprativechart.yAxes.push(new am4charts.ValueAxis());
                comvalueAxis.tooltip.disabled = true;
                comvalueAxis.title.text = "Compliance(%)";
                comvalueAxis.min = 0;
                comvalueAxis.max = 100;

                var comseries3 = Comprativechart.series.push(new am4charts.LineSeries());
                comseries3.name = "Not Complied";
                comseries3.dataFields.categoryX = "year";
                comseries3.dataFields.valueY = "not_complied_count";
                comseries3.tooltipHTML = "<span style='font-size:14px; color:#000000;'>Not Complied: {not_complied_count.formatNumber('#.00')}% (<b>{notCompliedCount}</b> out of <b>{total})</b></span>";
                comseries3.tooltipText = "[#000]{notCompliedCount}[/]";
                comseries3.tooltip.background.fill = am4core.color("#FFF");
                comseries3.tooltip.getFillFromObject = false;
                comseries3.tooltip.getStrokeFromObject = true;
                comseries3.tooltip.background.strokeWidth = 3;
                comseries3.sequencedInterpolation = true;
                comseries3.fillOpacity = 0.6;
                comseries3.defaultState.transitionDuration = 1000;
                comseries3.stacked = false;
                comseries3.strokeWidth = 2;

                Comprativechart.cursor = new am4charts.XYCursor();
                Comprativechart.cursor.xAxis = dateAxis;
                Comprativechart.scrollbarX = new am4core.Scrollbar();

                Comprativechart.legend = new am4charts.Legend();
                // Comprativechart.legend.position = "top";

                // axis ranges
                var range = dateAxis.axisRanges.create();
                range.date = new Date(2001, 0, 1);
                range.endDate = new Date(2003, 0, 1);
                range.axisFill.fill = Comprativechart.colors.getIndex(7);
                range.axisFill.fillOpacity = 0.2;

                range.label.text = "";
                range.label.inside = true;
                range.label.rotation = 90;
                range.label.horizontalCenter = "right";
                range.label.verticalCenter = "bottom";

                //  var range2 = dateAxis.axisRanges.create();
                //  range2.date = new Date(2007, 0, 1);
                //  range2.grid.stroke = Comprativechart.colors.getIndex(7);
                //  range2.grid.strokeOpacity = 0.6;
                //  range2.grid.strokeDasharray = "5,2";


                var title = Comprativechart.titles.create();
                title.text = generatedOn == true ? `Generated on : ${timeZoneData.tz_name} ${timeZone}` : `Generated on : ${time} (UTC)`
                title.disabled = false;
                title.align = 'left'
                title.marginTop = '2%'
                title.marginLeft = '2%'
                title.marginBottom = '2%'
                var title1 = Comprativechart.titles.create();
                title1.text =
                    ((toggleButton === 'Month' && fieldType == "LegalEntity" && go == true && type === '') ? `Not Complied of Legal Entity [bold]${filterDataNames.legalentity}[/] (${year})` : (
                        (toggleButton === 'Month' && fieldType == "Division" && go == true && type === '') ? `Not Complied of Division [bold]${filterDataNames.division}[/] (${year})` : (
                            (toggleButton === 'Month' && fieldType == "Category" && go == true && type === '') ? `Not Complied of Category [bold]${filterDataNames.category}[/] (${year})` : (
                                (toggleButton === 'Month' && fieldType == "Unit" && go == true && type === '') ? `Not Complied of Business Group [bold]${filterDataNames.unit}[/] (${year})` : (
                                    (toggleButton === 'Month' && fieldType == "BusinessGroup" && go == true && type === '') ? `Not Complied of Unit [bold]${filterDataNames.business}[/] (${year})` : (
                                        (toggleButton === 'Month') ? `Not Complied (${year})` :
                                            (toggleButton === 'Year' && fieldType == "LegalEntity" && go == true && type === '') ? `Not Complied of Legal Entity [bold]${filterDataNames.legalentity}[/] (${year})` : (
                                                (toggleButton === 'Year' && fieldType == "Division" && go == true && type === '') ? `Not Complied of Division [bold]${filterDataNames.division}[/] (${year})` : (
                                                    (toggleButton === 'Year' && fieldType == "Category" && go == true && type === '') ? `Not Complied of Category [bold]${filterDataNames.category}[/] (${year})` : (
                                                        (toggleButton === 'Year' && fieldType == "Unit" && go == true && type === '') ? `Not Complied of Unit [bold]${filterDataNames.unit}[/] (${year})` : (
                                                            (toggleButton === 'Year' && fieldType == "BusinessGroup" && go == true && type === '') ? `Not Complied of Business Group [bold]${filterDataNames.business}[/] (${year})` :
                                                                `Not Complied of ${commonData && commonData.g_name} (${yearData && yearData.length > 0 && yearData[0]} to ${yearData && yearData.length > 0 && yearData[yearData.length - 1]} )`
                                                        ))))))))
                    ))
                title1.disabled = false;
                title1.align = 'center'
                title1.marginTop = '2%'
                title1.marginLeft = '2%'
                title1.fontSize = '17px'
                title1.fontWeight = '400'

                var label = Comprativechart.createChild(am4core.Label);
                label.text = "Country-Domain";
                label.fontSize = 14;
                label.align = "left";
                label.marginLeft = '2%';
                label.marginBottom = '2%'
                label.fontWeight = 'bold'

                // "Series: [bold {color}]{name}[/]";


                var label1 = Comprativechart.createChild(am4core.Label);
                label1.text = `${textArray}`
                label1.fontSize = 14;
                label1.align = "left";
                label1.marginLeft = '2%';
                label1.marginBottom = '1%'
            }
            else {
                var title3 = Comprativechart.createChild(am4core.Label)
                title3.text = "No Data to Display"
                title3.disabled = false;
                title3.align = 'left'
                title3.marginTop = '10%'
                title3.paddingLeft = '43%'
                title3.marginBottom = '50%'
                // title3.fontWeight = '500'
                title3.fontSize = '16'
            }
        }
    }, [chartType, data.compliance_data, tempData, year, table, timeZone, resultArray, timeZoneData, localStorage.getItem('chart')])

    useEffect(() => {
        if (resultArray && resultArray.length === 0) {
            am4core.useTheme(am4themes_animated);
            var Compliancechart = am4core.create("nodata", am4charts.XYChart);
            var title3 = Compliancechart.createChild(am4core.Label)
            title3.text = "No Data to Display"
            title3.disabled = false;
            title3.align = 'left'
            title3.marginTop = '10%'
            title3.paddingLeft = '43%'
            title3.marginBottom = '50%'
            // title3.fontWeight = '500'
            title3.fontSize = '16'
        }

    }, [resultArray.length])

    // useEffect(() => {
    //     am4core.useTheme(am4themes_animated);
    //     //Compliance Status        
    //     // var compliancestatus = am4core.create("compliancestatusdiv", am4charts.XYChart);
    //     // compliancestatus.height = 510
    //     // // compliancestatus.width = '300'
    //     // // compliancestatus.width = '450'
    //     // compliancestatus.responsive.enabled = 'true'
    //     // compliancestatus.data = [
    //     //     {
    //     //         "year": "India",
    //     //         "Complied": 25,
    //     //         "Delayed_Compliance": 35,
    //     //         "In_Progress": 15,
    //     //         "Not_Complied": 25
    //     //     }]
    //     // compliancestatus.colors.list = [am4core.color("#0de580"), am4core.color("#3c78d8"), am4core.color("#f9c80e"), am4core.color("#fe4365"),];
    //     // var compliancestatuscategoryAxis = compliancestatus.yAxes.push(new am4charts.CategoryAxis());
    //     // compliancestatuscategoryAxis.dataFields.category = "year";
    //     // compliancestatuscategoryAxis.numberFormatter.numberFormat = "#";
    //     // compliancestatuscategoryAxis.renderer.inversed = true;
    //     // var compliancestatusvalueAxis = compliancestatus.xAxes.push(new am4charts.ValueAxis());
    //     // compliancestatus.legend = new am4charts.Legend();
    //     // // compliancestatus.legend.position = "top";
    //     // var legend = compliancestatus.children.push(new am4charts.Legend());
    //     // function makeSeries(name, fieldName, color) {
    //     //     var compliancestatusseries = compliancestatus.series.push(new am4charts.ColumnSeries3D());
    //     //     compliancestatusseries.dataFields.valueX = name;
    //     //     compliancestatusseries.dataFields.categoryY = "year";
    //     //     compliancestatusseries.dataFields.color = color;
    //     //     compliancestatusseries.name = fieldName;
    //     //     compliancestatusseries.stacked = true;
    //     //     compliancestatusseries.columns.template.propertyFields.fill = "color";
    //     //     compliancestatusseries.columns.template.tooltipText = "{name}, {valueX}";
    //     //     compliancestatusseries.columns.template.column3D.stroke = am4core.color("#fff");
    //     //     compliancestatusseries.columns.template.column3D.strokeOpacity = 0.2;
    //     //     compliancestatusseries.columns.template.column3D.height = '450'
    //     //     compliancestatusseries.columns.template.maxHeight = 40
    //     //     legend.data.push(compliancestatusseries);
    //     // }
    //     // makeSeries("Complied", "Complied", "#00FF00");
    //     // makeSeries("Delayed_Compliance", "Delayed Compliance", "#0000FF");
    //     // makeSeries("In_Progress", "In Progress", "#FFFF00");
    //     // makeSeries("Not_Complied", "Not Complied", "#FF0000");

    //     //Compliance Status Chart New
    //     // if (chartType == 'count') {
    //     am4core.useTheme(am4themes_animated);
    //     // console.log(dataValue && dataValue.length && dataValue[0].complied_count, 'dataValue && dataValue');
    //     //Compliance Status        
    //     var compliancestatus = am4core.create("compliancestatusdiv", am4charts.XYChart);
    //     compliancestatus.height = 510
    //     // compliancestatus.width = '300'
    //     // compliancestatus.width = '450'
    //     compliancestatus.responsive.enabled = 'true'
    //     compliancestatus.data = [{ "year": "India", "Complied": 25, "Delayed_Compliance": 35, "In_Progress": 15, "Not_Complied": 25 }, { "year": "Srilanka", "Complied": 10, "Delayed_Compliance": 15, "In_Progress": 36, "Not_Complied": 12 }, { "year": "Australia", "Complied": 32, "Delayed_Compliance": 51, "In_Progress": 16, "Not_Complied": 56 }]
    //     compliancestatus.colors.list = [am4core.color("#0de580"), am4core.color("#3c78d8"), am4core.color("#f9c80e"), am4core.color("#fe4365"),];
    //     var compliancestatuscategoryAxis = compliancestatus.yAxes.push(new am4charts.CategoryAxis());
    //     compliancestatuscategoryAxis.dataFields.category = "year";
    //     compliancestatuscategoryAxis.numberFormatter.numberFormat = "#";
    //     compliancestatuscategoryAxis.renderer.inversed = true;
    //     var compliancestatusvalueAxis = compliancestatus.xAxes.push(new am4charts.ValueAxis());
    //     compliancestatus.legend = new am4charts.Legend();
    //     // compliancestatus.legend.position = "top";
    //     var legend = compliancestatus.children.push(new am4charts.Legend());
    //     function makeSeries(name, fieldName, color) {
    //         var compliancestatusseries = compliancestatus.series.push(new am4charts.ColumnSeries3D());
    //         compliancestatusseries.dataFields.valueX = name;
    //         compliancestatusseries.dataFields.categoryY = "year";
    //         compliancestatusseries.dataFields.color = color;
    //         compliancestatusseries.name = fieldName;
    //         compliancestatusseries.stacked = true;
    //         compliancestatusseries.columns.template.propertyFields.fill = "color";
    //         compliancestatusseries.columns.template.tooltipText = "{name}: {valueX}";
    //         compliancestatusseries.columns.template.column3D.stroke = am4core.color("#fff");
    //         compliancestatusseries.columns.template.column3D.strokeOpacity = 0.2;
    //         compliancestatusseries.columns.template.column3D.height = '450'
    //         compliancestatusseries.columns.template.maxHeight = 40
    //         legend.data.push(compliancestatusseries);
    //     }
    //     makeSeries("Complied", "Complied", "#00FF00");
    //     makeSeries("Delayed_Compliance", "Delayed Compliance", "#0000FF");
    //     makeSeries("In_Progress", "In Progress", "#FFFF00");
    //     makeSeries("Not_Complied", "Not Complied", "#FF0000");

    //     var Comprativechart = am4core.create("Comprativechartdiv", am4charts.XYChart);
    //     // Comprativechart.height = '350'
    //     // Comprativechart.width = '450'
    //     Comprativechart.responsive.enabled = true;

    //     // Add data
    //     Comprativechart.data = tempData;

    //     Comprativechart.dateFormatter.inputDateFormat = "yyyy";
    //     var dateAxis = Comprativechart.xAxes.push(new am4charts.DateAxis());
    //     dateAxis.renderer.minGridDistance = 60;
    //     dateAxis.startLocation = 0.5;
    //     dateAxis.endLocation = 0.5;
    //     // dateAxis.baseInterval = {
    //     //     timeUnit: "month",
    //     //     count: 1
    //     // }

    //     var comvalueAxis = Comprativechart.yAxes.push(new am4charts.ValueAxis());
    //     comvalueAxis.tooltip.disabled = true;

    //     var comseries = Comprativechart.series.push(new am4charts.LineSeries());
    //     comseries.dataFields.dateX = "year";
    //     comseries.name = "Complied";
    //     comseries.dataFields.valueY = "complied_count";
    //     comseries.tooltipHTML = "<span style='font-size:14px; color:#000000;'><b>Complied: {valueY.value}</b></span>";
    //     comseries.tooltipText = "[#000]{valueY.value}[/]";
    //     comseries.tooltip.background.fill = am4core.color("#FFF");
    //     comseries.tooltip.getStrokeFromObject = true;
    //     comseries.tooltip.background.strokeWidth = 3;
    //     comseries.tooltip.getFillFromObject = false;
    //     comseries.fillOpacity = 0.6;
    //     comseries.strokeWidth = 2;
    //     comseries.stacked = false;

    //     var comseries2 = Comprativechart.series.push(new am4charts.LineSeries());
    //     comseries2.name = "Delayed Compliance";
    //     comseries2.dataFields.dateX = "year";
    //     comseries2.dataFields.valueY = "delayed_compliance_count";
    //     comseries2.tooltipHTML = "<span style='font-size:14px; color:#000000;'><b>Delayed Compliance: {valueY.value}</b></span>";
    //     comseries2.tooltipText = "[#000]{valueY.value}[/]";
    //     comseries2.tooltip.background.fill = am4core.color("#FFF");
    //     comseries2.tooltip.getFillFromObject = false;
    //     comseries2.tooltip.getStrokeFromObject = true;
    //     comseries2.tooltip.background.strokeWidth = 3;
    //     comseries2.sequencedInterpolation = true;
    //     comseries2.fillOpacity = 0.6;
    //     comseries2.stacked = false;
    //     comseries2.strokeWidth = 2;

    //     var comseries3 = Comprativechart.series.push(new am4charts.LineSeries());
    //     comseries3.name = "Not Complied";
    //     comseries3.dataFields.dateX = "year";
    //     comseries3.dataFields.valueY = "not_complied_count";
    //     comseries3.tooltipHTML = "<span style='font-size:14px; color:#000000;'><b>Not Complied: {valueY.value}</b></span>";
    //     comseries3.tooltipText = "[#000]{valueY.value}[/]";
    //     comseries3.tooltip.background.fill = am4core.color("#FFF");
    //     comseries3.tooltip.getFillFromObject = false;
    //     comseries3.tooltip.getStrokeFromObject = true;
    //     comseries3.tooltip.background.strokeWidth = 3;
    //     comseries3.sequencedInterpolation = true;
    //     comseries3.fillOpacity = 0.6;
    //     comseries3.defaultState.transitionDuration = 1000;
    //     comseries3.stacked = false;
    //     comseries3.strokeWidth = 2;

    //     Comprativechart.cursor = new am4charts.XYCursor();
    //     Comprativechart.cursor.xAxis = dateAxis;
    //     Comprativechart.scrollbarX = new am4core.Scrollbar();

    //     Comprativechart.legend = new am4charts.Legend();
    //     // Comprativechart.legend.position = "top";

    //     // axis ranges
    //     var range = dateAxis.axisRanges.create();
    //     range.date = new Date(2001, 0, 1);
    //     range.endDate = new Date(2003, 0, 1);
    //     range.axisFill.fill = Comprativechart.colors.getIndex(7);
    //     range.axisFill.fillOpacity = 0.2;

    //     range.label.text = "";
    //     range.label.inside = true;
    //     range.label.rotation = 90;
    //     range.label.horizontalCenter = "right";
    //     range.label.verticalCenter = "bottom";

    //     var range2 = dateAxis.axisRanges.create();
    //     range2.date = new Date(2007, 0, 1);
    //     range2.grid.stroke = Comprativechart.colors.getIndex(7);
    //     range2.grid.strokeOpacity = 0.6;
    //     range2.grid.strokeDasharray = "5,2";


    //     range2.label.text = "Motorcycle fee introduced";
    //     range2.label.inside = true;
    //     range2.label.rotation = 90;
    //     range2.label.horizontalCenter = "right";
    //     range2.label.verticalCenter = "bottom";

    //     //Compliance trend



    // }, [tempData])


    // useEffect(() => {
    //     if (toggleButton === 'Year') {
    //         let temp = []
    //         if (dataYears && dataYears.years) {
    //             for (let i in dataYears.years) {
    //                 temp.push(dataYears.years[i])
    //             }
    //             setYearData(temp)
    //         }
    //     }
    // }, [dataYears.years, toggleButton])


    const dataSource = [
        {
            key: '1',
            name: 'Rule 18',
            task: 'Trail Task117',
            description: 'test',
            p_cons: "Shall be punishable with imprisonment for a term which shall not be less than six months but which may extend to three years and with fine which shall not be less than fifty thousand rupees but which may extend to two lakh rupees - Section 63",
            address: '10 Downing Street',
            frequency: 'periodical',
            task_status: 'opted',
            trigger_before_days: 10,
            task: 'Trail Task119',
            summary: "Repeats every 1 Month(s) "
        },
        {
            key: '2',
            name: 'Rule 3',
            description: 'Online application can be submitted for one musical license covering all branches of pan India by paying prescribed fees. Music license is applicable for music played for public in TV, radio, live performances, DJ by internet or even copied in CD, DVD or pen drives/hard disc',
            address: '10 Downing Street',
            frequency: 'Review',
            task_status: 'Not opted',
            task: 'Trail Task',
            summary: "Repeats every 6 Month(s) ",
            trigger_before_days: 20,
            p_cons: "Section 23 - Imprisonment up to 3 months or with fine up to Rs.1000/- or with both. If the contravention is continued fine may extend to Rs.100/- for each day",
        },
        {
            key: '3',
            name: 'Rule 12',
            description: 'test',
            frequency: 'OnOccurence',
            address: '10 Downing Street',
            p_cons: "test",
            task: 'Trail Task55',
            task_status: 'opted',
            trigger_before_days: 25,
            summary: "Repeats every 1 Month(s) "
        },
    ];

    const homescreen = () => {
        historynew.push(homepage)
        // window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }
    const onClose = () => {
        setOpen(false);
    };


    useEffect(() => {
        if (JSON.parse(sideFilterState) === true && localStorage.getItem('filterChange') && go === false) {
            if (JSON.parse(isCountryValueTrue) === false) {
                let temp = []
                for (let i in parsedSideFilterData.c_ids) {
                    temp.push(`${parsedSideFilterData.c_ids[i]}`)
                }
                console.log(temp, 'temp');
                setFilterData({
                    ...filterData,
                    country: temp,
                    // countryArr:[`${parsedSideFilterData && parsedSideFilterData.c_ids}`],
                    domain: parsedSideFilterData && parsedSideFilterData.cd_ids,
                    domainIds: parsedSideFilterData && parsedSideFilterData.d_ids,
                })
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request":
                            ["GetComplianceTrend",
                                {
                                    "c_ids": parsedSideFilterData && parsedSideFilterData.c_ids,
                                    "d_ids": parsedSideFilterData && parsedSideFilterData.d_ids,
                                    "filter_type": parsedSideFilterData && parsedSideFilterData.filter_type,
                                    "filter_ids": parsedSideFilterData && parsedSideFilterData.filter_ids,
                                    "le_ids": parsedSideFilterData && parsedSideFilterData.le_ids,
                                    "country_domains": parsedSideFilterData && parsedSideFilterData.country_domains,
                                    "year": `${year}`,
                                    "chart_types": toggleButton === 'Month' ? "Month" : "Year",

                                }
                            ]
                    }
                ]
                if (paramid != "") {
                    getComplianceTrendchart({
                        payload: payload,
                        paramid: paramid
                    })
                }
            }
            else {
                setFilterData({
                    ...filterData,
                    country: [],
                    countryArr: [],
                    domain: [],
                    domainIds: [],
                })
            }
        }

    }, [go, paramid, localStorage.getItem('filterState'), localStorage.getItem('filterChange'), localStorage.getItem('countryState')])



    const filterChange = () => {
        setDataValue([])
        setNotcomplieddata([])
        setGo(true)
        setGeneratedOn(false)
        setDelayedData([])
        setChartData([])
        setTempData([])
        if (groupCheckBox === true && errorField === '') {
            if (fieldTypeClick === 'Country' || fieldTypeClick === 'Domain') {
                let obj = {
                    "c_ids": filterData.countryArr,
                    "cd_ids": filterData.domain,
                    "d_ids": filterData.domainIds,
                    "filter_type": "Group",
                    "filter_ids": fieldTypeClick === "Country" ? filterData.countryArr : cIds,
                    "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                    "country_domains": newCountryDomain,
                }
                localStorage.setItem('filterState', true)
                localStorage.setItem('filterChange', JSON.stringify(obj))
            }
            else {
                let obj = {}
                localStorage.setItem('filterState', false)
                localStorage.setItem('filterChange', JSON.stringify(obj))
            }
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetComplianceTrend",
                        {
                            "c_ids": filterData.countryArr,
                            "d_ids": filterData.domainIds,
                            "filter_type": ((fieldType != "") && (type === '')) ? fieldType : "Group",
                            "filter_ids": [
                                (fieldType == "LegalEntity" && type === '') ? filterData.legalentity :
                                    (fieldType == 'Division' && type === '') ? Number(filterData.division) :
                                        (fieldType == 'Category' && type === '') ? Number(filterData.category) :
                                            (fieldType == 'Unit' && type === '') ? Number(filterData.unit) :
                                                (fieldType == 'BusinessGroup' && type === '') ? Number(filterData.business) :
                                                    (fieldType != "" && type === 'Group') ? 1 :
                                                        1
                            ],

                            "le_ids":
                                filterData && filterData.leIds ? [filterData.leIds] : filterData.legalentity ? filterData.legalentity : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                            ,
                            "country_domains": newCountryDomain,
                            "year": `${year}`,
                            "chart_types": "Month"
                        }
                    ]
                }
            ]

            getComplianceTrendchart({
                payload: payload,
                paramid: paramid
            })
            setOpen(false);

        }
        else {
            // toast.error('Please select the filter')
            toast.error(errorField ? errorField : 'Please select the filter')
        }
    }




    console.log(dataValue, 'dataVallll');

    //reference chart for multiple chart

    // useEffect(() => {
    //     /**
    //      * ---------------------------------------
    //      * This demo was created using amCharts 4.
    //      *
    //      * For more information visit:
    //      * https://www.amcharts.com/
    //      *
    //      * Documentation is available at:
    //      * https://www.amcharts.com/docs/v4/
    //      * ---------------------------------------
    //      */

    //     am4core.useTheme(am4themes_animated);

    //     // Create chart instance
    //     var chart = am4core.create("chartdiv", am4charts.XYChart);

    //     // Add data
    //     chart.data = [{
    //         "date": new Date(2018, 0, 1),
    //         "value": 450,
    //         "value2": 362,
    //         "value3": 699
    //     }, {
    //         "date": new Date(2018, 0, 2),
    //         "value": 269,
    //         "value2": 450,
    //         "value3": 841
    //     }, {
    //         "date": new Date(2018, 0, 3),
    //         "value": 700,
    //         "value2": 358,
    //         "value3": 699
    //     }, {
    //         "date": new Date(2018, 0, 4),
    //         "value": 490,
    //         "value2": 367,
    //         "value3": 500
    //     }, {
    //         "date": new Date(2018, 0, 5),
    //         "value": 500,
    //         "value2": 485,
    //         "value3": 369
    //     }, {
    //         "date": new Date(2018, 0, 6),
    //         "value": 550,
    //         "value2": 354,
    //         "value3": 250
    //     }, {
    //         "date": new Date(2018, 0, 7),
    //         "value": 420,
    //         "value2": 350,
    //         "value3": 600
    //     }];

    //     // Create axes
    //     var categoryAxis = chart.xAxes.push(new am4charts.DateAxis());
    //     categoryAxis.renderer.grid.template.location = 0;
    //     categoryAxis.renderer.minGridDistance = 30;


    //     // Create series
    //     function createSeriesAndAxis(field, name, topMargin, bottomMargin) {
    //         var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    //         var series = chart.series.push(new am4charts.LineSeries());
    //         series.dataFields.valueY = field;
    //         series.dataFields.dateX = "date";
    //         series.name = name;
    //         series.tooltipText = "{dateX}: [b]{valueY}[/]";
    //         series.strokeWidth = 2;
    //         series.yAxis = valueAxis;

    //         valueAxis.renderer.line.strokeOpacity = 1;
    //         valueAxis.renderer.line.stroke = series.stroke;
    //         valueAxis.renderer.grid.template.stroke = series.stroke;
    //         valueAxis.renderer.grid.template.strokeOpacity = 0.1;
    //         valueAxis.renderer.labels.template.fill = series.stroke;
    //         valueAxis.renderer.minGridDistance = 20;
    //         valueAxis.align = "right";

    //         if (topMargin && bottomMargin) {
    //             valueAxis.marginTop = 10;
    //             valueAxis.marginBottom = 10;
    //         }
    //         else {
    //             if (topMargin) {
    //                 valueAxis.marginTop = 20;
    //             }
    //             if (bottomMargin) {
    //                 valueAxis.marginBottom = 20;
    //             }
    //         }

    //         var bullet = series.bullets.push(new am4charts.CircleBullet());
    //         bullet.circle.stroke = am4core.color("#fff");
    //         bullet.circle.strokeWidth = 2;
    //     }

    //     createSeriesAndAxis("value", "Series #1", false, true);
    //     createSeriesAndAxis("value2", "Series #2", true, true);
    //     createSeriesAndAxis("value3", "Series #3", true, false);

    //     chart.legend = new am4charts.Legend();
    //     chart.cursor = new am4charts.XYCursor();

    //     chart.leftAxesContainer.layout = "vertical";
    // }, [])

    // useEffect(() => {
    //     // if (dataValue && dataValue.length > 0) {
    //     console.log('test');
    //     if (table == false) {
    //         if (chartType == 'complied') {
    //             // Themes begin
    //             am4core.useTheme(am4themes_animated);
    //             // var total = (data && data.T_0_to_30_days_count) + (data && data.T_31_to_60_days_count) + (data && data.T_61_to_90_days_count) + (data && data.Above_90_days_count)
    //             // Create a container
    //             var container = am4core.create("container", am4core.Container);
    //             container.width = am4core.percent(100);
    //             container.height = am4core.percent(100);
    //             container.layout = "vertical";
    //             container.background.fill = am4core.color("#000");
    //             container.background.fillOpacity = 0.1;
    //             container.background.stroke = am4core.color("#000");
    //             container.background.strokeOpacity = 0.2;
    //             container.background.strokeWidth = 2;

    //             if (dataValue && dataValue.length > 0) {

    //                 function addChart(data) {
    //                     console.log(data, 'data');
    //                     var chart = container.createChild(am4charts.XYChart3D);
    //                     chart.exporting.menu = new am4core.ExportMenu();
    //                     chart.exporting.menu.items = [{
    //                         "label": "...",
    //                         "menu": [
    //                             {
    //                                 "label": "Image",
    //                                 "menu": [
    //                                     { "type": "png", "label": "PNG" },
    //                                     { "type": "jpg", "label": "JPG" },
    //                                     { "type": "svg", "label": "SVG" },
    //                                     { "type": "pdf", "label": "PDF" },
    //                                     { "type": "gif", "label": "GIF" }
    //                                 ]
    //                             }, {
    //                                 "label": "Data",
    //                                 "menu": [
    //                                     { "type": "csv", "label": "CSV" },
    //                                     { "type": "xlsx", "label": "XLSX" },
    //                                 ]
    //                             }, {
    //                                 "label": "Print", "type": "print"
    //                             }
    //                         ]
    //                     }];
    //                     chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
    //                     chart.exporting.menu.align = "right";
    //                     chart.exporting.menu.verticalAlign = "top";
    //                     // Add data
    //                     chart.data = data
    //                     chart.height = 550

    //                     // Create axes
    //                     // var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    //                     // // dateAxis.renderer.minGridDistance = 4
    //                     // dateAxis.title.text = "Month-Year";
    //                     // dateAxis.renderer.minGridDistance = 50;
    //                     // dateAxis.renderer.labels.template.text = `{month}`
    //                     // pieSeries.labels.template.text = "{category}: {value}";

    //                     var dateAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    //                     dateAxis.dataFields.category = "year";
    //                     dateAxis.renderer.opposite = false;
    //                     dateAxis.title.text = toggleButton === 'Month' ? "Month-Year" : "Year";

    //                     var range = dateAxis.axisRanges.create();
    //                     range.label.text = `{month}`;


    //                     // categoryAxis.renderer.labels.template.events.on("hit", function (event) {}



    //                     // dateAxis.renderer.labels.template.fontSize = 8;
    //                     // Create value axis
    //                     var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    //                     valueAxis.calculateTotals = true;
    //                     valueAxis.min = 0;
    //                     valueAxis.max = 100;
    //                     valueAxis.renderer.minGridDistance = 12;
    //                     valueAxis.title.text = "Compliance(%)";
    //                     // Create series
    //                     var lineSeries = chart.series.push(new am4charts.LineSeries());
    //                     lineSeries.dataFields.dateX = "year";
    //                     lineSeries.dataFields.valueY = "value";

    //                     console.log(lineSeries.dataItems, 'lineSeries.dataFields.value');

    //                     dateAxis.renderer.labels.template.events.on("hit", function (event) {
    //                         console.log('hiiiiii');
    //                         console.log(event, 'evee');
    //                         console.log(event.target.currentText, 'currrrr')
    //                         setClickState(true)
    //                         // setTable(true)
    //                         const payload = [
    //                             authtoken,
    //                             {
    //                                 "session_token": authtoken,
    //                                 "request": [
    //                                     "GetComparativeChartDrillDownData",
    //                                     {
    //                                         "c_ids": [
    //                                             filterData && filterData.country ? Number(filterData && filterData.country) : commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
    //                                         ],
    //                                         "d_ids": dIds,
    //                                         "filter_type": fieldType != "" ? fieldType : "Group",
    //                                         "filter_ids": [
    //                                             fieldType == "LegalEntity" ? filterData.legalentity :
    //                                                 fieldType == 'Division' ? Number(filterData.division) :
    //                                                     fieldType == 'Category' ? Number(filterData.category) :
    //                                                         fieldType == 'Unit' ? Number(filterData.unit) :
    //                                                             fieldType == 'BusinessGroup' ? Number(filterData.business) :
    //                                                                 1
    //                                         ],
    //                                         "year": `${year}`,
    //                                         "chart_month": (event.target.currentText === 'Feb' ? 2 : (event.target.currentText === 'Mar' ? 3 : (event.target.currentText === 'Apr' ? 4 : (event.target.currentText === 'May' ? 5 : (event.target.currentText === 'Jun' ? 6 : (event.target.currentText === 'Jul' ? 7 : (event.target.currentText === 'Aug' ? 8 : (event.target.currentText === 'Sep' ? 9 : (event.target.currentText === 'Oct' ? 10 : (event.target.currentText === 'Nov' ? 11 : (event.target.currentText === 'Dec' ? 12 : 1))))))))))),
    //                                         "record_count": 0,
    //                                         "le_ids":
    //                                             filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
    //                                         ,
    //                                         "country_domains": countryDomain,
    //                                         "compliance_status": "Complied"
    //                                     }
    //                                 ]
    //                             }
    //                         ]
    //                         if (paramid != "") {
    //                             getComparativeTableData({
    //                                 payload: payload,
    //                                 paramid: paramid
    //                             })
    //                         }
    //                     })
    //                     dateAxis.renderer.labels.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;



    //                     // lineSeries.name = countryName
    //                     lineSeries.name = ((fieldType == "LegalEntity" && go == true) ? filterDataNames.legalentity : (
    //                         (fieldType == "Division" && go == true) ? filterDataNames.division : (
    //                             (fieldType == "Category" && go == true) ? filterDataNames.category : (
    //                                 (fieldType == "Unit" && go == true) ? filterDataNames.unit : countryName
    //                             ))
    //                     ))
    //                     lineSeries.dataFields.value = "totalPercent";
    //                     lineSeries.calculatePercent = true;
    //                     lineSeries.calculateTotals = true;
    //                     lineSeries.tooltipText = "{valueX}"
    //                     lineSeries.strokeWidth = 2;
    //                     lineSeries.dataFields.valueXShow = "totalPercent";
    //                     lineSeries.dataItems.template.locations.categoryY = 0.5;
    //                     // Add simple bullet
    //                     var bullet = lineSeries.bullets.push(new am4charts.CircleBullet());
    //                     bullet.circle.stroke = am4core.color("#fff");
    //                     bullet.circle.strokeWidth = 2;
    //                     bullet.tooltipText = "{name}: {valueY.formatNumber('#.00')}[/]% : ({valuecount} out of {total}[/])";
    //                     chart.legend = new am4charts.Legend();


    //                     chart.exporting.dataFields = {
    //                         "month": "Month - Year",
    //                         "valuecount": `${countryName}_Complied`,
    //                         "total": `${countryName}_TotalCompliance`
    //                     }

    //                     chart.exporting.menu = new am4core.ExportMenu();
    //                     chart.exporting.menu.items = [{
    //                         "label": "...",
    //                         "menu": [
    //                             {
    //                                 "label": "Image",
    //                                 "menu": [
    //                                     { "type": "png", "label": "PNG" },
    //                                     { "type": "jpg", "label": "JPG" },
    //                                     { "type": "svg", "label": "SVG" },
    //                                     { "type": "pdf", "label": "PDF" },
    //                                     { "type": "gif", "label": "GIF" }
    //                                 ]
    //                             }, {
    //                                 "label": "Data",
    //                                 "menu": [
    //                                     { "type": "csv", "label": "CSV" },
    //                                     { "type": "xlsx", "label": "XLSX" },
    //                                 ]
    //                             }, {
    //                                 "label": "Print", "type": "print"
    //                             }
    //                         ]
    //                     }];
    //                     chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
    //                     var title = chart.titles.create();
    //                     title.text = generatedOn == true ? `Generated on : ${timeZoneData.tz_name} ${timeZone}` : `Generated on : ${time} (UTC)`
    //                     title.disabled = false;
    //                     title.align = 'left'
    //                     title.marginTop = '2%'
    //                     title.marginLeft = '2%'
    //                     title.marginBottom = '2%'

    //                     // var title1 = chart.titles.create();
    //                     // title1.text = 'Complied'
    //                     // title1.disabled = false;
    //                     // title1.align = 'center'
    //                     // title1.marginTop = '2%'
    //                     // title1.marginLeft = '2%'
    //                     // title1.fontWeight = 'bold'

    //                     var title1 = chart.titles.create();
    //                     // title1.text =
    //                     //     ((fieldType == "LegalEntity" && go == true && type === '') ? `Complied of Legal Entity [bold]${filterDataNames.legalentity}[/] (${year})` : (
    //                     //         (fieldType == "Division" && go == true && type === '') ? `Complied of Division [bold]${filterDataNames.division}[/] (${year})` : (
    //                     //             (fieldType == "Category" && go == true && type === '') ? `Complied of Category [bold]${filterDataNames.category}[/] (${year})` : (
    //                     //                 (fieldType == "BusinessGroup" && go == true && type === '') ? `Complied of Business Group [bold]${filterDataNames.business}[/] (${year})` : (
    //                     //                     (fieldType == "Unit" && go == true && type === '') ? `Complied of Unit [bold]${filterDataNames.unit}[/] (${year})` : `Complied (${year})`
    //                     //                 )))
    //                     //     ))
    //                     title1.text =
    //                         ((toggleButton === 'Month' && fieldType == "LegalEntity" && go == true && type === '') ? `Complied of [bold]${filterDataNames.legalentity}[/] (${year})` : (
    //                             (toggleButton === 'Month' && fieldType == "Division" && go == true && type === '') ? `Complied of [bold]${filterDataNames.division}[/] (${year})` : (
    //                                 (toggleButton === 'Month' && fieldType == "Category" && go == true && type === '') ? `Complied of [bold]${filterDataNames.category}[/] (${year})` : (
    //                                     (toggleButton === 'Month' && fieldType == "Unit" && go == true && type === '') ? `Complied of [bold]${filterDataNames.unit}[/] (${year})` : (
    //                                         (toggleButton === 'Month' && fieldType == "BusinessGroup" && go == true && type === '') ? `Complied of [bold]${filterDataNames.business}[/] (${year})` : (
    //                                             (toggleButton === 'Month') ? `Complied (${year})` :
    //                                                 (toggleButton === 'Year' && fieldType == "LegalEntity" && go == true && type === '') ? `Complied of [bold]${filterDataNames.legalentity}[/] (${year})` : (
    //                                                     (toggleButton === 'Year' && fieldType == "Division" && go == true && type === '') ? `Complied of [bold]${filterDataNames.division}[/] (${year})` : (
    //                                                         (toggleButton === 'Year' && fieldType == "Category" && go == true && type === '') ? `Complied of [bold]${filterDataNames.category}[/] (${year})` : (
    //                                                             (toggleButton === 'Year' && fieldType == "Unit" && go == true && type === '') ? `Complied of [bold]${filterDataNames.unit}[/] (${year})` : (
    //                                                                 (toggleButton === 'Year' && fieldType == "BusinessGroup" && go == true && type === '') ? `Complied of [bold]${filterDataNames.business}[/] (${year})` :
    //                                                                     `Complied ${commonData && commonData.g_name} (${yearData && yearData.length > 0 && yearData[0]} to ${yearData && yearData.length > 0 && yearData[yearData.length - 1]} )`
    //                                                             ))))))))
    //                         ))
    //                     title1.disabled = false;
    //                     title1.align = 'center'
    //                     title1.marginTop = '5%'
    //                     title1.marginLeft = '2%'
    //                     title1.fontSize = '15px'
    //                     title1.fontWeight = '400'
    //                     // title1.fontWeight = 'bold'

    //                     // <b><p>Country-Domain</p></b>
    //                     // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

    //                     var label = chart.createChild(am4core.Label);
    //                     label.text = "Country-Domain";
    //                     label.fontSize = 14;
    //                     label.align = "left";
    //                     label.marginLeft = '2%';
    //                     label.marginBottom = '2%'
    //                     label.fontWeight = 'bold'

    //                     var label1 = chart.createChild(am4core.Label);
    //                     label1.text = `${textArray}`
    //                     label1.fontSize = 14;
    //                     label1.align = "left";
    //                     label1.marginLeft = '2%';
    //                     label1.marginBottom = '5%'

    //                 }

    //                 addChart(dataValue);
    //             }

    //             else {
    //                 var title3 = container.createChild(am4core.Label)
    //                 title3.text = "No Data to Display"
    //                 title3.disabled = false;
    //                 title3.align = 'left'
    //                 title3.paddingTop = '45%'
    //                 title3.paddingLeft = '43%'
    //                 // title3.fontWeight = '500'
    //                 title3.fontSize = '16'
    //             }

    //         }
    //         else if (chartType == "notComplied") {
    //             // Themes begin
    //             am4core.useTheme(am4themes_animated);
    //             // var total = (data && data.T_0_to_30_days_count) + (data && data.T_31_to_60_days_count) + (data && data.T_61_to_90_days_count) + (data && data.Above_90_days_count)
    //             // Create a container
    //             var container = am4core.create("container", am4core.Container);
    //             container.width = am4core.percent(100);
    //             container.height = am4core.percent(100);
    //             container.layout = "vertical";
    //             container.background.fill = am4core.color("#000");
    //             container.background.fillOpacity = 0.1;
    //             container.background.stroke = am4core.color("#000");
    //             container.background.strokeOpacity = 0.2;
    //             container.background.strokeWidth = 2;

    //             if (notcomplieddata && notcomplieddata.length > 0) {
    //                 function addChart(data) {
    //                     var chart = container.createChild(am4charts.XYChart3D);
    //                     chart.exporting.menu = new am4core.ExportMenu();
    //                     chart.exporting.menu.items = [{
    //                         "label": "...",
    //                         "menu": [
    //                             {
    //                                 "label": "Image",
    //                                 "menu": [
    //                                     { "type": "png", "label": "PNG" },
    //                                     { "type": "jpg", "label": "JPG" },
    //                                     { "type": "svg", "label": "SVG" },
    //                                     { "type": "pdf", "label": "PDF" },
    //                                     { "type": "gif", "label": "GIF" }
    //                                 ]
    //                             }, {
    //                                 "label": "Data",
    //                                 "menu": [
    //                                     { "type": "csv", "label": "CSV" },
    //                                     { "type": "xlsx", "label": "XLSX" },
    //                                 ]
    //                             }, {
    //                                 "label": "Print", "type": "print"
    //                             }
    //                         ]
    //                     }];
    //                     chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
    //                     chart.exporting.menu.align = "right";
    //                     chart.exporting.menu.verticalAlign = "top";
    //                     // Add data
    //                     chart.data = data
    //                     // Create axes
    //                     var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    //                     dateAxis.renderer.minGridDistance = 1
    //                     dateAxis.title.text = "Month-Year";
    //                     // Create value axis
    //                     var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    //                     valueAxis.calculateTotals = true;
    //                     valueAxis.min = 0;
    //                     valueAxis.max = 100;
    //                     valueAxis.renderer.minGridDistance = 10;
    //                     valueAxis.title.text = "Compliance(%)";
    //                     // Create series
    //                     var lineSeries = chart.series.push(new am4charts.LineSeries());
    //                     lineSeries.dataFields.valueY = "value";
    //                     lineSeries.dataFields.dateX = "month";

    //                     lineSeries.name = ((fieldType == "LegalEntity" && go == true) ? filterDataNames.legalentity : (
    //                         (fieldType == "Division" && go == true) ? filterDataNames.division : (
    //                             (fieldType == "Category" && go == true) ? filterDataNames.category : (
    //                                 (fieldType == "Unit" && go == true) ? filterDataNames.unit : commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name
    //                             ))
    //                     ))

    //                     dateAxis.renderer.labels.template.events.on("hit", function (event) {
    //                         console.log('hiiiiii');
    //                         console.log(event, 'evee');
    //                         console.log(event.target.currentText, 'currrrr')
    //                         setClickState(true)
    //                         const payload = [
    //                             authtoken,
    //                             {
    //                                 "session_token": authtoken,
    //                                 "request": [
    //                                     "GetComparativeChartDrillDownData",
    //                                     {
    //                                         "c_ids": [
    //                                             filterData && filterData.country ? Number(filterData && filterData.country) : commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
    //                                         ],
    //                                         "d_ids": dIds,
    //                                         "filter_type": fieldType != "" ? fieldType : "Group",
    //                                         "filter_ids": [
    //                                             fieldType == "LegalEntity" ? filterData.legalentity :
    //                                                 fieldType == 'Division' ? Number(filterData.division) :
    //                                                     fieldType == 'Category' ? Number(filterData.category) :
    //                                                         fieldType == 'Unit' ? Number(filterData.unit) :
    //                                                             fieldType == 'BusinessGroup' ? Number(filterData.business) :
    //                                                                 1
    //                                         ],
    //                                         "year": `${year}`,
    //                                         "chart_month": (event.target.currentText === 'Feb' ? 2 : (event.target.currentText === 'Mar' ? 3 : (event.target.currentText === 'Apr' ? 4 : (event.target.currentText === 'May' ? 5 : (event.target.currentText === 'Jun' ? 6 : (event.target.currentText === 'Jul' ? 7 : (event.target.currentText === 'Aug' ? 8 : (event.target.currentText === 'Sep' ? 9 : (event.target.currentText === 'Oct' ? 10 : (event.target.currentText === 'Nov' ? 11 : (event.target.currentText === 'Dec' ? 12 : 1))))))))))),
    //                                         "record_count": 0,
    //                                         "le_ids":
    //                                             filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
    //                                         ,
    //                                         "country_domains": countryDomain,
    //                                         "compliance_status": "Not Complied"
    //                                     }
    //                                 ]
    //                             }
    //                         ]
    //                         if (paramid != "") {
    //                             getComparativeTableData({
    //                                 payload: payload,
    //                                 paramid: paramid
    //                             })
    //                         }
    //                     })
    //                     dateAxis.renderer.labels.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;

    //                     lineSeries.dataFields.value = "totalPercent";
    //                     lineSeries.calculatePercent = true;
    //                     lineSeries.calculateTotals = true;
    //                     lineSeries.tooltipText = "{valueX}"
    //                     lineSeries.strokeWidth = 2;
    //                     lineSeries.dataFields.valueXShow = "totalPercent";
    //                     // Add simple bullet
    //                     var bullet = lineSeries.bullets.push(new am4charts.CircleBullet());
    //                     bullet.circle.stroke = am4core.color("#fff");
    //                     bullet.circle.strokeWidth = 2;
    //                     bullet.tooltipText = "{name}: {valueY.formatNumber('#.00')}[/]% : ({valuecount} out of {total}[/]}";
    //                     chart.legend = new am4charts.Legend();

    //                     chart.exporting.dataFields = {
    //                         "month": "Month - Year",
    //                         "valuecount": `${countryName}_Not Complied`,
    //                         "total": `${countryName}_TotalCompliance`
    //                     }

    //                     chart.exporting.menu = new am4core.ExportMenu();
    //                     chart.exporting.menu.items = [{
    //                         "label": "...",
    //                         "menu": [
    //                             {
    //                                 "label": "Image",
    //                                 "menu": [
    //                                     { "type": "png", "label": "PNG" },
    //                                     { "type": "jpg", "label": "JPG" },
    //                                     { "type": "svg", "label": "SVG" },
    //                                     { "type": "pdf", "label": "PDF" },
    //                                     { "type": "gif", "label": "GIF" }
    //                                 ]
    //                             }, {
    //                                 "label": "Data",
    //                                 "menu": [
    //                                     { "type": "csv", "label": "CSV" },
    //                                     { "type": "xlsx", "label": "XLSX" },
    //                                 ]
    //                             }, {
    //                                 "label": "Print", "type": "print"
    //                             }
    //                         ]
    //                     }];
    //                     chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
    //                     var title = chart.titles.create();
    //                     title.text = generatedOn === true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} (UTC)}`
    //                     title.disabled = false;
    //                     title.align = 'left'
    //                     title.marginTop = '2%'
    //                     title.marginLeft = '2%'
    //                     title.marginBottom = '2%'

    //                     // var title1 = chart.titles.create();
    //                     // title1.text = 'Not Complied'
    //                     // title1.disabled = false;
    //                     // title1.align = 'center'
    //                     // title1.marginTop = '2%'
    //                     // title1.marginLeft = '2%'
    //                     // title1.fontWeight = 'bold'

    //                     var title1 = chart.titles.create();
    //                     title1.text =
    //                         ((toggleButton === 'Month' && fieldType == "LegalEntity" && go == true && type === '') ? `Not Complied of [bold]${filterDataNames.legalentity}[/] (${year})` : (
    //                             (toggleButton === 'Month' && fieldType == "Division" && go == true && type === '') ? `Not Complied of [bold]${filterDataNames.division}[/] (${year})` : (
    //                                 (toggleButton === 'Month' && fieldType == "Category" && go == true && type === '') ? `Not Complied of [bold]${filterDataNames.category}[/] (${year})` : (
    //                                     (toggleButton === 'Month' && fieldType == "Unit" && go == true && type === '') ? `Not Complied of [bold]${filterDataNames.unit}[/] (${year})` : (
    //                                         (toggleButton === 'Month' && fieldType == "BusinessGroup" && go == true && type === '') ? `Not Complied of [bold]${filterDataNames.business}[/] (${year})` : (
    //                                             (toggleButton === 'Month') ? `Not Complied (${year})` :
    //                                                 (toggleButton === 'Year' && fieldType == "LegalEntity" && go == true && type === '') ? `Not Complied of [bold]${filterDataNames.legalentity}[/] (${year})` : (
    //                                                     (toggleButton === 'Year' && fieldType == "Division" && go == true && type === '') ? `Not Complied of [bold]${filterDataNames.division}[/] (${year})` : (
    //                                                         (toggleButton === 'Year' && fieldType == "Category" && go == true && type === '') ? `Not Complied of [bold]${filterDataNames.category}[/] (${year})` : (
    //                                                             (toggleButton === 'Year' && fieldType == "Unit" && go == true && type === '') ? `Not Complied of [bold]${filterDataNames.unit}[/] (${year})` : (
    //                                                                 (toggleButton === 'Year' && fieldType == "BusinessGroup" && go == true && type === '') ? `Not Complied of [bold]${filterDataNames.business}[/] (${year})` :
    //                                                                     `Not Complied ${commonData && commonData.g_name} (${yearData && yearData.length > 0 && yearData[0]} to ${yearData && yearData.length > 0 && yearData[yearData.length - 1]} )`
    //                                                             ))))))))
    //                         ))
    //                     title1.disabled = false;
    //                     title1.align = 'center'
    //                     title1.marginTop = '5%'
    //                     title1.marginLeft = '2%'
    //                     title1.fontSize = '15px'

    //                     // title1.fontWeight = 'bold'

    //                     // <b><p>Country-Domain</p></b>
    //                     // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

    //                     var label = chart.createChild(am4core.Label);
    //                     label.text = "Country-Domain";
    //                     label.fontSize = 14;
    //                     label.align = "left";
    //                     label.marginLeft = '2%';
    //                     label.marginBottom = '2%'
    //                     label.fontWeight = 'bold'

    //                     var label1 = chart.createChild(am4core.Label);
    //                     label1.text = `${textArray}`
    //                     label1.fontSize = 14;
    //                     label1.align = "left";
    //                     label1.marginLeft = '2%';
    //                     label1.marginBottom = '5%'
    //                 }
    //                 addChart(notcomplieddata);
    //             }
    //             else {
    //                 var title3 = container.createChild(am4core.Label)
    //                 title3.text = "No Data to Display"
    //                 title3.disabled = false;
    //                 title3.align = 'left'
    //                 title3.paddingTop = '45%'
    //                 title3.paddingLeft = '43%'
    //                 // title3.fontWeight = '500'
    //                 title3.fontSize = '16'
    //             }
    //         }
    //         else if (chartType == "delayedComplied") {

    //             am4core.useTheme(am4themes_animated);
    //             // var total = (data && data.T_0_to_30_days_count) + (data && data.T_31_to_60_days_count) + (data && data.T_61_to_90_days_count) + (data && data.Above_90_days_count)
    //             // Create a container
    //             var container = am4core.create("container", am4core.Container);
    //             container.width = am4core.percent(100);
    //             container.height = am4core.percent(100);
    //             container.layout = "vertical";
    //             container.background.fill = am4core.color("#000");
    //             container.background.fillOpacity = 0.1;
    //             container.background.stroke = am4core.color("#000");
    //             container.background.strokeOpacity = 0.2;
    //             container.background.strokeWidth = 2;

    //             if (delayedData && delayedData.length > 0) {
    //                 function addChart(data) {
    //                     var chart = container.createChild(am4charts.XYChart3D);

    //                     chart.exporting.dataFields = {
    //                         "month": "Month - Year",
    //                         "valuecount": `${countryName}_Delayed Compliance`,
    //                         "total": `${countryName}_TotalCompliance`
    //                     }

    //                     chart.exporting.menu = new am4core.ExportMenu();
    //                     chart.exporting.menu.items = [{
    //                         "label": "...",
    //                         "menu": [
    //                             {
    //                                 "label": "Image",
    //                                 "menu": [
    //                                     { "type": "png", "label": "PNG" },
    //                                     { "type": "jpg", "label": "JPG" },
    //                                     { "type": "svg", "label": "SVG" },
    //                                     { "type": "pdf", "label": "PDF" },
    //                                     { "type": "gif", "label": "GIF" }
    //                                 ]
    //                             }, {
    //                                 "label": "Data",
    //                                 "menu": [
    //                                     { "type": "csv", "label": "CSV" },
    //                                     { "type": "xlsx", "label": "XLSX" },
    //                                 ]
    //                             }, {
    //                                 "label": "Print", "type": "print"
    //                             }
    //                         ]
    //                     }];
    //                     chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
    //                     chart.exporting.menu.align = "right";
    //                     chart.exporting.menu.verticalAlign = "top";
    //                     // Add data
    //                     chart.data = data
    //                     // Create axes
    //                     var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    //                     dateAxis.renderer.minGridDistance = 1
    //                     dateAxis.title.text = "Month-Year";
    //                     // Create value axis
    //                     var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    //                     valueAxis.calculateTotals = true;
    //                     // valueAxis.min = 0;
    //                     // valueAxis.max = 100;
    //                     // valueAxis.renderer.minGridDistance = 10;
    //                     valueAxis.min = 0;
    //                     valueAxis.max = 100;
    //                     valueAxis.renderer.minGridDistance = 10;
    //                     valueAxis.renderer.cellStartLocation = 0.2;
    //                     valueAxis.renderer.cellEndLocation = 0.6;
    //                     valueAxis.title.text = "Compliance(%)";
    //                     // Create series
    //                     var lineSeries = chart.series.push(new am4charts.LineSeries());
    //                     lineSeries.dataFields.valueY = "value";
    //                     lineSeries.dataFields.dateX = "month";
    //                     lineSeries.name = ((fieldType == "LegalEntity" && go == true) ? filterDataNames.legalentity : (
    //                         (fieldType == "Division" && go == true) ? filterDataNames.division : (
    //                             (fieldType == "Category" && go == true) ? filterDataNames.category : (
    //                                 (fieldType == "Unit" && go == true) ? filterDataNames.unit : commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name
    //                             ))
    //                     ))

    //                     dateAxis.renderer.labels.template.events.on("hit", function (event) {
    //                         console.log('hiiiiii');
    //                         console.log(event, 'evee');
    //                         console.log(event.target.currentText, 'currrrr')
    //                         setClickState(true)
    //                         const payload = [
    //                             authtoken,
    //                             {
    //                                 "session_token": authtoken,
    //                                 "request": [
    //                                     "GetComparativeChartDrillDownData",
    //                                     {
    //                                         "c_ids": [
    //                                             filterData && filterData.country ? Number(filterData && filterData.country) : commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
    //                                         ],
    //                                         "d_ids": dIds,
    //                                         "filter_type": fieldType != "" ? fieldType : "Group",
    //                                         "filter_ids": [
    //                                             fieldType == "LegalEntity" ? filterData.legalentity :
    //                                                 fieldType == 'Division' ? Number(filterData.division) :
    //                                                     fieldType == 'Category' ? Number(filterData.category) :
    //                                                         fieldType == 'Unit' ? Number(filterData.unit) :
    //                                                             fieldType == 'BusinessGroup' ? Number(filterData.business) :
    //                                                                 1
    //                                         ],
    //                                         "year": `${year}`,
    //                                         "chart_month": (event.target.currentText === 'Feb' ? 2 : (event.target.currentText === 'Mar' ? 3 : (event.target.currentText === 'Apr' ? 4 : (event.target.currentText === 'May' ? 5 : (event.target.currentText === 'Jun' ? 6 : (event.target.currentText === 'Jul' ? 7 : (event.target.currentText === 'Aug' ? 8 : (event.target.currentText === 'Sep' ? 9 : (event.target.currentText === 'Oct' ? 10 : (event.target.currentText === 'Nov' ? 11 : (event.target.currentText === 'Dec' ? 12 : 1))))))))))),
    //                                         "record_count": 0,
    //                                         "le_ids":
    //                                             filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
    //                                         ,
    //                                         "country_domains": countryDomain,
    //                                         "compliance_status": "Delayed Compliance"
    //                                     }
    //                                 ]
    //                             }
    //                         ]
    //                         if (paramid != "") {
    //                             getComparativeTableData({
    //                                 payload: payload,
    //                                 paramid: paramid
    //                             })
    //                         }
    //                     })
    //                     dateAxis.renderer.labels.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;

    //                     lineSeries.dataFields.value = "totalPercent";
    //                     lineSeries.calculatePercent = true;
    //                     lineSeries.calculateTotals = true;
    //                     lineSeries.tooltipText = "{valueX}"
    //                     lineSeries.strokeWidth = 2;
    //                     lineSeries.dataFields.valueXShow = "totalPercent";
    //                     // Add simple bullet
    //                     var bullet = lineSeries.bullets.push(new am4charts.CircleBullet());
    //                     bullet.circle.stroke = am4core.color("#fff");
    //                     bullet.circle.strokeWidth = 2;
    //                     bullet.tooltipText = "{name}: {valueY.formatNumber('#.00')}[/]% : ({valuecount} out of {total}[/]}";
    //                     chart.legend = new am4charts.Legend();

    //                     chart.exporting.menu = new am4core.ExportMenu();
    //                     chart.exporting.menu.items = [{
    //                         "label": "...",
    //                         "menu": [
    //                             {
    //                                 "label": "Image",
    //                                 "menu": [
    //                                     { "type": "png", "label": "PNG" },
    //                                     { "type": "jpg", "label": "JPG" },
    //                                     { "type": "svg", "label": "SVG" },
    //                                     { "type": "pdf", "label": "PDF" },
    //                                     { "type": "gif", "label": "GIF" }
    //                                 ]
    //                             }, {
    //                                 "label": "Data",
    //                                 "menu": [
    //                                     { "type": "csv", "label": "CSV" },
    //                                     { "type": "xlsx", "label": "XLSX" },
    //                                 ]
    //                             }, {
    //                                 "label": "Print", "type": "print"
    //                             }
    //                         ]
    //                     }];
    //                     chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
    //                     var title = chart.titles.create();
    //                     title.text = timeZoneData && timeZoneData.length > 0 ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} (UTC)`
    //                     title.disabled = false;
    //                     title.align = 'left'
    //                     title.marginTop = '2%'
    //                     title.marginLeft = '2%'
    //                     title.marginBottom = '2%'

    //                     // var title1 = chart.titles.create();
    //                     // title1.text = 'Delayed Compliance'
    //                     // title1.disabled = false;
    //                     // title1.align = 'center'
    //                     // title1.marginTop = '2%'
    //                     // title1.marginLeft = '2%'
    //                     // title1.fontWeight = 'bold'

    //                     var title1 = chart.titles.create();
    //                     title1.text =
    //                         ((toggleButton === 'Month' && fieldType == "LegalEntity" && go == true && type === '') ? `Delayed Compliance of [bold]${filterDataNames.legalentity}[/] (${year})` : (
    //                             (toggleButton === 'Month' && fieldType == "Division" && go == true && type === '') ? `Delayed Compliance of [bold]${filterDataNames.division}[/] (${year})` : (
    //                                 (toggleButton === 'Month' && fieldType == "Category" && go == true && type === '') ? `Delayed Compliance of [bold]${filterDataNames.category}[/] (${year})` : (
    //                                     (toggleButton === 'Month' && fieldType == "Unit" && go == true && type === '') ? `Delayed Compliance of [bold]${filterDataNames.unit}[/] (${year})` : (
    //                                         (toggleButton === 'Month' && fieldType == "BusinessGroup" && go == true && type === '') ? `Delayed Compliance of [bold]${filterDataNames.business}[/] (${year})` : (
    //                                             (toggleButton === 'Month') ? `Delayed Compliance (${year})` :
    //                                                 (toggleButton === 'Year' && fieldType == "LegalEntity" && go == true && type === '') ? `Delayed Compliance of [bold]${filterDataNames.legalentity}[/] (${year})` : (
    //                                                     (toggleButton === 'Year' && fieldType == "Division" && go == true && type === '') ? `Delayed Compliance of [bold]${filterDataNames.division}[/] (${year})` : (
    //                                                         (toggleButton === 'Year' && fieldType == "Category" && go == true && type === '') ? `Delayed Compliance of [bold]${filterDataNames.category}[/] (${year})` : (
    //                                                             (toggleButton === 'Year' && fieldType == "Unit" && go == true && type === '') ? `Delayed Compliance of [bold]${filterDataNames.unit}[/] (${year})` : (
    //                                                                 (toggleButton === 'Year' && fieldType == "BusinessGroup" && go == true && type === '') ? `Delayed Compliance of [bold]${filterDataNames.business}[/] (${year})` :
    //                                                                     `Delayed Compliance ${commonData && commonData.g_name} (${yearData && yearData.length > 0 && yearData[0]} to ${yearData && yearData.length > 0 && yearData[yearData.length - 1]} )`
    //                                                             ))))))))
    //                         ))
    //                     title1.disabled = false;
    //                     title1.align = 'center'
    //                     title1.marginTop = '5%'
    //                     title1.marginLeft = '2%'
    //                     title1.fontSize = '15px'

    //                     // title1.fontWeight = 'bold'

    //                     // <b><p>Country-Domain</p></b>
    //                     // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

    //                     var label = chart.createChild(am4core.Label);
    //                     label.text = "Country-Domain";
    //                     label.fontSize = 14;
    //                     label.align = "left";
    //                     label.marginLeft = '2%';
    //                     label.marginBottom = '2%'
    //                     label.fontWeight = 'bold'

    //                     var label1 = chart.createChild(am4core.Label);
    //                     label1.text = `${textArray}`
    //                     label1.fontSize = 14;
    //                     label1.align = "left";
    //                     label1.marginLeft = '2%';
    //                     label1.marginBottom = '5%'
    //                 }
    //                 addChart(delayedData);
    //             }
    //             else {
    //                 var title3 = container.createChild(am4core.Label)
    //                 title3.text = "No Data to Display"
    //                 title3.disabled = false;
    //                 title3.align = 'left'
    //                 title3.paddingTop = '45%'
    //                 title3.paddingLeft = '43%'
    //                 // title3.fontWeight = '500'
    //                 title3.fontSize = '16'
    //             }
    //         }
    //     }
    //     // }
    // }, [dataValue, table, timeZone, chartType, yearData, timeZoneData, localStorage.getItem('chart')])




    //Use effects for filter

    // useEffect(() => {
    //     if (localStorage.getItem('SelectedEntity') != "All Legal Entity" && commonData) {
    //         let arrunit = [];
    //         let totalListunit = []
    //         let countrylisttempdataunit = [];
    //         let entityArrayunit = []
    //         let tempArrayunit = []
    //         commonData && commonData.d_info && commonData.d_info.length > 0 && commonData && commonData.d_info && commonData.d_info.map((subunitlist) => {
    //             console.log(subunitlist, 'subunitlist-domain');
    //             if (country_info && country_info[0].c_id == subunitlist.c_id) {
    //                 entityArrayunit = {

    //                     'c_id': subunitlist.c_id,
    //                     'd_id': subunitlist.d_id,
    //                     'd_name': subunitlist.d_name,
    //                     'is_active': subunitlist.is_active,
    //                     'month_from': subunitlist.month_from,
    //                     'month_to': subunitlist.month_to,
    //                     'label': subunitlist.d_name,
    //                     'values': subunitlist.d_id

    //                 }
    //                 tempArrayunit.push(entityArrayunit)
    //                 console.log(entityArrayunit, 'entityArrayunit');
    //             }

    //         })
    //         totalListunit = {
    //             'label': country_info && country_info && country_info[0].c_id == null ? 'Others' : country_info && country_info[0].c_name,
    //             'options': tempArrayunit
    //         }

    //         countrylisttempdataunit.push(totalListunit)
    //         setDomainListData(countrylisttempdataunit)
    //     }
    // }, [commonData, localStorage.getItem('SelectedEntity') != "All Legal Entity"])




    useEffect(() => {
        if (filterData.domain) {
            let tempDom = []
            for (let i in filterData.domain) {
                let filteredDomain = _.find(domainData, { cd_id: `${filterData.domain[i]}` })
                if (filteredDomain) {
                    tempDom.push({
                        "d_id": filteredDomain.d_id,
                        "c_id": filteredDomain.c_id,
                        "month_from": filteredDomain.month_from,
                        "month_to": filteredDomain.month_to
                    })
                }
                console.log(filteredDomain, 'filteredDomain');
            }
            setNewCountryDomain(tempDom)
            console.log(tempDom, 'tempDom');
        }

    }, [domainData, filterData.domain])

    // // UseEffect for filters

    // useEffect(() => {
    //     if (commonData && JSON.parse(sideFilterState) === false) {
    //         let temp = []
    //         let temp1 = []
    //         let temp2 = []
    //         let temp3 = []
    //         if (JSON.parse(isCountryValueTrue) === false) {
    //             for (let i in commonData.d_info) {
    //                 temp3.push(commonData.d_info[i].d_id)
    //                 temp.push(`${commonData.d_info[i].c_id}-${commonData.d_info[i].d_id}`)
    //             }
    //             for (let j in uniq_country_data) {
    //                 temp1.push(`${uniq_country_data[j].c_id}`)
    //                 temp2.push(uniq_country_data[j].c_id)
    //             }
    //             setFilterData({
    //                 ...filterData,
    //                 country: temp1,
    //                 countryArr: temp2,
    //                 domain: temp,
    //                 domainIds: temp3
    //             })
    //         }
    //         else {
    //             setFilterData({
    //                 ...filterData,
    //                 country: [],
    //                 countryArr: [],
    //                 domain: [],
    //                 domainIds: []
    //             })
    //         }
    //     }
    // }, [commonData, uniq_country_data, localStorage.getItem('countryState'), localStorage.getItem('filterState')])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === 'All Legal Entity') {
            if (uniq_country_data && uniq_country_data.length > 0) {
                console.log('hii');
                console.log(uniq_country_data, 'uniq_country_data');
                setfield({
                    ...field,
                    country: uniq_country_data
                })
                if (JSON.parse(isCountryValueTrue) === false) {
                    let temp = []
                    let temp1 = []
                    let temp2 = []
                    let temp3 = []
                    for (let i in commonData.d_info) {
                        temp3.push(commonData.d_info[i].d_id)
                        temp.push(`${commonData.d_info[i].c_id}-${commonData.d_info[i].d_id}`)
                    }
                    for (let j in uniq_country_data) {
                        temp1.push(`${uniq_country_data[j].c_id}`)
                        temp2.push(uniq_country_data[j].c_id)
                    }
                    console.log(temp, 'temp');
                    console.log(temp3, 'temp3');
                    setFilterData({
                        ...filterData,
                        country: temp1,
                        countryArr: temp2,
                        domain: temp,
                        domainIds: temp3
                    })
                }
                else {
                    setFilterData({
                        ...filterData,
                        country: [],
                        countryArr: [],
                        domain: [],
                        domainIds: []
                    })
                }
            }
        }
        else {
            let filteredEntity = _.filter(commonData.le_did_infos, { le_id: localStorage.getItem('SelectedEntityid') })
            console.log(filteredEntity, 'filteredEntity');
            if (filteredEntity && filteredEntity.length > 0) {
                let filteredCountry = _.filter(uniq_country_data, { c_id: filteredEntity[0].c_id })

                setfield({
                    ...field,
                    country: filteredCountry
                })
                if (JSON.parse(isCountryValueTrue) === false) {
                    let temp = []
                    let temp1 = []
                    let temp2 = []
                    let temp3 = []
                    for (let i in commonData.d_info) {
                        temp3.push(commonData.d_info[i].d_id)
                        temp.push(`${commonData.d_info[i].c_id}-${commonData.d_info[i].d_id}`)
                    }
                    for (let j in filteredCountry) {
                        temp1.push(`${filteredCountry[j].c_id}`)
                        temp2.push(filteredCountry[j].c_id)
                    }
                    console.log(temp, 'temp');
                    console.log(temp3, 'temp3');
                    setFilterData({
                        ...filterData,
                        country: temp1,
                        countryArr: temp2,
                        domain: temp,
                        domainIds: temp3
                    })
                }
                else {
                    setFilterData({
                        ...filterData,
                        country: [],
                        countryArr: [],
                        domain: [],
                        domainIds: []
                    })
                }
            }
        }
    }, [commonData, uniq_country_data, localStorage.getItem('SelectedEntityid'), localStorage.getItem('SelectedEntity')])

    // useEffect(() => {
    //     if (commonData && JSON.parse(sideFilterState) === false) {
    //         let temp = []
    //         let temp1 = []
    //         let temp2 = []
    //         let temp3 = []
    //         if (JSON.parse(isCountryValueTrue) === false) {
    //             for (let i in commonData.d_info) {
    //                 temp3.push(commonData.d_info[i].d_id)
    //                 temp.push(`${commonData.d_info[i].c_id}-${commonData.d_info[i].d_id}`)
    //             }
    //             for (let j in field.country) {
    //                 temp1.push(`${field.country[j].c_id}`)
    //                 temp2.push(field.country[j].c_id)
    //             }
    //             setFilterData({
    //                 ...filterData,
    //                 country: temp1,
    //                 countryArr: temp2,
    //                 domain: temp,
    //                 domainIds: temp3
    //             })
    //         }
    //         else {
    //             setFilterData({
    //                 ...filterData,
    //                 country: [],
    //                 countryArr: [],
    //                 domain: [],
    //                 domainIds: []
    //             })
    //         }
    //     }
    // }, [commonData, field.country, localStorage.getItem('countryState'), localStorage.getItem('filterState')])


    useEffect(() => {
        if (filterData.country && filterData.country.length === 0) {
            localStorage.setItem('countryState', true)
        }
        else {
            localStorage.setItem('countryState', false)
        }
    }, [filterData.country])

    useEffect(() => {
        if (commonData) {
            if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                const uniqueIds = commonData.le_did_infos && commonData.le_did_infos.map((item) => {
                    return item.bg_id
                })
                setBgIds(uniqueIds)
            }
        }

    }, [commonData])

    //Country onChange

    console.log(filterData.country, 'counnnn');

    useEffect(() => {
        if (filterData.country) {
            let tempCountries = []
            let bgs = []
            let tempBgs = []
            let tempLe = []
            let filteredLe = []
            for (let i in filterData.country) {
                let filteredCountries = _.filter(uniq_country_data, { c_id: Number(filterData.country[i]) })
                console.log(filteredCountries, 'filteredCountries');
                tempCountries.push(...filteredCountries)
                if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                    let filteredBg = _.filter(commonData.bg_groups, { c_id: Number(filterData.country[i]) })
                    bgs.push(...filteredBg)
                }
                if (bgs && bgs.length > 0) {
                    tempBgs = _.uniqBy(bgs, function (e) {
                        return e.bg_id;
                    });
                }
                if (tempBgs && tempBgs.length > 0) {
                    if (bgIds.includes(null)) {
                        filteredLe = commonData.le_did_infos.filter(function (currentElement) {
                            return currentElement.bg_id === null || currentElement.bg_id === Number(tempBgs[0].bg_id);
                        });
                    }
                    else {
                        filteredLe = _.filter(commonData.le_did_infos, { bg_id: Number(tempBgs[0].bg_id) })
                    }
                }
                else if (commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0) {
                    tempLe = _.filter(commonData.le_did_infos, { c_id: Number(filterData.country[i]) })
                    filteredLe.push(...tempLe)
                }

            }

            if (tempBgs && tempBgs.length > 0) {

            }

            setFilteredCountries(tempCountries)
            console.log(tempCountries, 'tempCountries');
            console.log(tempBgs, 'tempBgs');
            setfield({
                ...field,
                business: tempBgs,
                legalEntity: filteredLe
            })
            // setLegalEntityArray(filteredEntity)
        }

    }, [filterData.country, uniq_country_data])

    //Domain onChange

    useEffect(() => {
        if (fieldTypeClick === 'Domain') {
            if (filterData.domainIds) {
                let tempBgs = []
                let tempLe = []
                let filteredLe = []
                for (let i in filterData.domainIds) {
                    if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                        let filteredBg = _.filter(commonData.bg_groups, { d_id: Number(filterData.domainIds[i]) })
                        tempBgs.push(...filteredBg)
                    }
                    console.log(tempBgs, 'tempBgs');
                    if (tempBgs && tempBgs.length > 0) {
                        if (bgIds.includes(null)) {
                            filteredLe = commonData.le_did_infos.filter(function (currentElement) {
                                return currentElement.bg_id === null || currentElement.bg_id === Number(tempBgs[0].bg_id);
                            });
                        }
                        else {
                            filteredLe = _.filter(commonData.le_did_infos, { bg_id: Number(tempBgs[0].bg_id) })
                        }
                    }
                    else if (commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0) {
                        filteredLe = commonData && commonData.le_did_infos.filter(item => item.d_ids.includes(Number(filterData.domainIds[i])))
                    }
                }
                console.log(filteredLe, 'filteredLe');
                setfield({
                    ...field,
                    business: tempBgs,
                    legalEntity: filteredLe
                })
            }
        }

    }, [filterData.domainIds, fieldTypeClick])


    useEffect(() => {
        if (field && field.legalEntity && field.legalEntity.length > 0) {
            setLegalEntityArray([field.legalEntity[0]])
        }

    }, [field.legalEntity])

    console.log(unit, 'unitttt');

    // Legal entity onChange

    useEffect(() => {
        if (((commonData) && (legalEntityArray && legalEntityArray.length > 0))) {
            console.log(field.legalEntity, 'field.legalEntity');
            if (commonData && commonData.div_infos && commonData.div_infos.length > 0) {
                let filteredDiv = [];
                let Divisions = _.filter(commonData.div_infos, { le_id: legalEntityArray[0].le_id })
                if (Divisions && Divisions.length > 0) {
                    filteredDiv = _.uniqBy(Divisions, function (e) {
                        return e.div_id;
                    });
                }
                console.log(filteredDiv, 'filteredDiv');
                if (filteredDiv && filteredDiv.length > 0) {
                    setDivision(filteredDiv)
                    let filteredCat = _.filter(commonData.cat_info, { div_id: Number(filteredDiv[0].div_id) })
                    let temp = []
                    if (filteredCat && filteredCat.length > 0) {
                        temp.push({
                            'bg_id': filteredCat[0].bg_id,
                            'c_id': filteredCat[0].c_id,
                            'cat_id': filteredCat[0].cat_id,
                            'cat_name': filteredCat[0].cat_name,
                            'd_id': filteredCat[0].d_id,
                            'div_id': filteredCat[0].div_id,
                            'le_id': filteredCat[0].le_id,
                            'div_name': filteredDiv[0].div_name
                        })
                        setCategory(temp)
                    }
                    // if (commonData && commonData.chart_units && commonData.chart_units.length > 0) {
                    //     let filteredUnit = _.filter(commonData.chart_units, { division_id: Number(filteredDiv[0].div_id) })
                    //     console.log(filteredUnit, 'filteredUnit');
                    //     setUnit(filteredUnit)
                    // }
                }
                // else {
                //     console.log('elsepart');
                //     if (commonData && commonData.chart_units && commonData.chart_units.length > 0) {
                //         console.log('trueee');
                //         let filteredUnit = _.filter(commonData.chart_units, { le_id: legalEntityArray[0].le_id })
                //         console.log(filteredUnit, 'filteredDivision');
                //         setUnit(filteredUnit)
                //     }
                // }
                if (commonData && commonData.chart_units && commonData.chart_units.length > 0) {
                    console.log('trueee');
                    let filteredUnit = _.filter(commonData.chart_units, { le_id: legalEntityArray[0].le_id })
                    console.log(filteredUnit, 'filteredDivision');
                    setUnit(filteredUnit)
                }
            }
        }

    }, [legalEntityArray, commonData])

    // Division,Category and Unit

    useEffect(() => {
        if (legalEntityArray && legalEntityArray.length > 0) {
            if (division) {
                let totalListunit = []
                let countrylisttempdataunit = [];
                let entityArrayunit = []
                let tempArrayunit = []
                division && division.length > 0 && division.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist');

                    if (legalEntityArray && legalEntityArray[0].le_id == subunitlist.le_id) {

                        entityArrayunit = {

                            'bg_id': subunitlist.bg_id,
                            'c_id': subunitlist.c_id,
                            'd_id': subunitlist.d_id,
                            'div_id': subunitlist.div_id,
                            'div_name': subunitlist.div_name,
                            'le_id': subunitlist.le_id,
                            'label': subunitlist.div_name,
                            'values': subunitlist.div_id

                        }
                        tempArrayunit.push(entityArrayunit)
                        console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                    }

                })
                totalListunit = {
                    'label': legalEntityArray && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray[0].le_name,
                    'options': tempArrayunit
                }

                console.log(totalListunit, 'totalListunit');

                countrylisttempdataunit.push(totalListunit)
                console.log(countrylisttempdataunit, 'countrylisttempdataunit');
                setDivisionListData(countrylisttempdataunit)
            }
            if (category) {
                let totalListunit = []
                let countrylisttempdataunit = [];
                let entityArrayunit = []
                let tempArrayunit = []
                category && category.length > 0 && category.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist-category');
                    if (legalEntityArray && legalEntityArray[0].le_id == subunitlist.le_id) {
                        entityArrayunit = {

                            'bg_id': subunitlist.bg_id,
                            'c_id': subunitlist.c_id,
                            'cat_id': subunitlist.cat_id,
                            'cat_name': subunitlist.cat_name,
                            'd_id': subunitlist.d_id,
                            'div_id': subunitlist.div_id,
                            'div_name': subunitlist.div_name,
                            'le_id': subunitlist.le_id,
                            'label': subunitlist.cat_name,
                            'values': subunitlist.cat_id

                        }
                        tempArrayunit.push(entityArrayunit)
                        console.log(entityArrayunit, 'entityArrayunit');
                    }

                })
                totalListunit = {
                    'label': legalEntityArray && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray[0].le_name,
                    'options': tempArrayunit
                }
                countrylisttempdataunit.push(totalListunit)
                setCategoryListData(countrylisttempdataunit)
            }
            if (unit) {
                let totalListunit = []
                let countrylisttempdataunit = [];
                let entityArrayunit = []
                let tempArrayunit = []
                unit && unit.length > 0 && unit.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist-unit');
                    if (legalEntityArray && legalEntityArray[0].le_id == subunitlist.le_id) {
                        entityArrayunit = {

                            'address': subunitlist.address,
                            'business_group_id': subunitlist.business_group_id,
                            'category_id': subunitlist.category_id,
                            'country_id': subunitlist.country_id,
                            'd_ids': subunitlist.d_ids,
                            'division_id': subunitlist.division_id,
                            'le_id': subunitlist.le_id,
                            'postal_code': subunitlist.postal_code,
                            'u_id': subunitlist.u_id,
                            'u_name': subunitlist.u_name,
                            'label': subunitlist.u_name,
                            'values': subunitlist.u_id

                        }
                        tempArrayunit.push(entityArrayunit)
                        console.log(entityArrayunit, 'entityArrayunit');
                    }

                })
                console.log(tempArrayunit, 'tempArrayunit');
                totalListunit = {
                    'label': legalEntityArray && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray[0].le_name,
                    'options': tempArrayunit
                }

                countrylisttempdataunit.push(totalListunit)
                setUnitListData(countrylisttempdataunit)
            }
        }
    }, [legalEntityArray, division, category, unit])

    //Domain Data 

    useEffect(() => {
        console.log('hellooo');
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        filteredCountries && filteredCountries.length > 0 && filteredCountries.map(function (user) {
            // console.log(list.unitlist, 'list.unitlist');
            if ((arrunit.indexOf(user.c_id) < 0))
                arrunit.push(user.c_id);
        });

        arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []
            if (pmenu || pmenu == null) {
                commonData.d_info && commonData.d_info.length > 0 && commonData.d_info.map((subunitlist) => {

                    if (pmenu == subunitlist.c_id) {
                        entityArrayunit = {

                            'c_id': subunitlist.c_id,
                            'd_id': `${subunitlist.c_id}-${subunitlist.d_id}`,
                            'd_name': subunitlist.d_name,
                            'is_active': subunitlist.is_active,
                            'month_from': subunitlist.month_from,
                            'month_to': subunitlist.month_to,
                            'label': subunitlist.d_name,
                            'values': subunitlist.d_id

                        }
                        tempArrayunit.push(entityArrayunit)
                    }
                })
            }
            let countryName = _.find(filteredCountries, { c_id: pmenu })
            console.log(countryName, 'countryName');
            totalListunit = {
                'label': countryName.c_name == null ? 'Others' : countryName.c_name,
                'options': tempArrayunit
            }
            console.log(totalListunit, 'unitListData')
            countrylisttempdataunit.push(totalListunit)
        })
        setDomainListData(countrylisttempdataunit)


    }, [filteredCountries, commonData])

    const HandelCountryChange = (obj) => {
        console.log(obj, 'objj');
        var numberArray = obj.map(Number);
        console.log(numberArray, 'numberArray');
        let temp5 = []
        let filterDomain = []
        let filteredBg = []
        let filteredLE = []
        let leTemp = []
        let bgTemp = []
        for (let j in obj) {
            filterDomain = _.filter(commonData.d_info, { c_id: Number(obj[j]) })
            // temp2.push(filterDomain)
            for (let i in filterDomain) {
                temp5.push(filterDomain[i])
            }

            if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                filteredBg = _.filter(commonData && commonData.bg_groups, { c_id: Number(obj[j]) })
                console.log(filteredBg, 'filteredBg');
                if (filteredBg && filteredBg.length > 0) {
                    for (let b in filteredBg) {
                        bgTemp.push(filteredBg[b])
                    }
                }
                else {
                    filteredLE = _.filter(commonData && commonData.le_did_infos, { c_id: Number(obj[j]) })
                    if (filteredLE && filteredLE.length > 0) {
                        for (let l in filteredLE) {
                            leTemp.push(filteredLE[l])
                        }
                    }
                }
                // if (bgIds.includes(null) === true) {
                //     filteredBg = _.filter(commonData && commonData.bg_groups, { c_id: Number(obj[j]) })
                //     console.log(filteredBg,'filteredBg1111');
                // }
                // else {
                //     filteredBg = _.filter(commonData && commonData.bg_groups, { c_id: Number(obj[j]) })
                //     console.log(filteredBg, 'filteredBg');
                //     for (let b in filteredBg) {
                //         bgTemp.push(filteredBg[b])
                //     }
                // }
            }

            if (commonData && commonData.le_did_infos) {
                filteredLE = _.filter(commonData && commonData.le_did_infos, { c_id: Number(obj[j]) })
                if (filteredLE && filteredLE.length > 0) {
                    for (let l in filteredLE) {
                        leTemp.push(filteredLE[l])
                    }
                    // leTemp = _.uniq(leTemp)
                }
            }

        }
        let id = []
        for (let i in temp5) {
            id.push(`${temp5[i].c_id}-${temp5[i].d_id}`)
        }
        let dIds = []
        for (let i in temp5) {
            dIds.push(temp5[i].d_id)
        }
        let bgIds1 = bgTemp && bgTemp.length > 0 && bgTemp.map((item, i) => {
            return item.bg_id
        })
        let leIds = leTemp && leTemp.length > 0 && leTemp.map((item, i) => {
            return item.le_id
        })
        let uniqueLe = _.uniq(leIds)
        console.log(uniqueLe, 'uniqueLe');
        console.log(leTemp, 'leTemp');
        console.log(bgTemp, 'bggg');
        console.log(bgIds, 'bgIds');
        setFieldTypeClick('Country')
        setType('')
        setFilterData({
            ...filterData,
            country: obj,
            countryArr: numberArray,
            domain: id,
            business: `${bgIds1}`,
            legalentity: leIds ? uniqueLe : [],
            domainIds: dIds
        })
        setFieldType('')
        setFilterDataNames({
            ...filterDataNames,
            country: obj,
            legalentity: '',
            division: '',
            unit: '',
            business: '',
            category: '',
            unit: ''
        })
    }

    console.log(filterData.legalentity, 'filterData.legalentity ');

    const HandelChange = (obj) => {
        console.log(obj, 'objj');
        let tempObj = []
        if (obj && obj.length) {
            for (let i in obj) {
                let splitData = obj[i].split("-")
                console.log(splitData[1], 'splitData');
                // tempObj = [...tempObj,...splitData[1]]
                tempObj.push(Number(splitData[1]))
            }
        }
        console.log(tempObj, 'tempObj');
        setFieldTypeClick('Domain')
        setType('')
        setFilterData({
            ...filterData,
            domain: obj,
            domainIds: tempObj
        })
        setFieldType('')
        setFilterDataNames({
            ...filterDataNames,
            domain: obj,
            legalentity: '',
            division: '',
            unit: '',
            business: '',
            category: '',
            unit: ''
        })
        let temp = []
        let bg = []
        let tempDiv = []
        let tempCat = []
        let tempUnit = []
        console.log(tempCat, 'tempCat');
        console.log(bg, 'Aishuuuu');
        console.log(temp, 'SnehaaaaaaaaAsss');

    }

    console.log(dataValue, 'dataValue');
    console.log(filterDataNames.legalentity, 'filterDataNames.legalentity');

    const showmore = () => {
        setCountValue(countValue + 100)
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetComplianceTrendDrillDownData",
                    {
                        "d_ids": dIds,
                        "filter_type": "Group",
                        "filter_ids": [1],
                        "chart_year": year,
                        "chart_month": chartMonth,
                        "record_count": countValue + 100,
                        "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                        "country_domains": countryDomain,
                        "compliance_status": criticalityValue
                    }
                ]
            }
        ]
        if (paramid != "") {
            getComplianceTrendShowMoreTableData({
                payload: payload,
                paramid: paramid
            })
        }
    }

    useEffect(() => {
        if (tableData && tableData.length > 0) {

            // search option customize
            let temp = []
            let temp1 = []
            {
                tableData && tableData.length > 0 &&
                    tableData.map((items) => {
                        let childDatas = []
                        let unitName = items.drill_compliances
                        console.log(unitName, 'unitName');
                        let keys = Object.keys(unitName);
                        let values = Object.values(unitName);
                        console.log(keys, 'keys');
                        console.log(values, 'values');
                        {
                            keys && keys.length > 0 && keys.map((item, i) => {
                                console.log(item, 'itemitemitemitemitemitemitem');
                                let tempArr = []
                                if (values && values.length > 0) {
                                    for (let m in values[i]) {
                                        console.log(m, 'inndexxx');
                                        console.log(values[i][m].comp_name, 'childDatas');
                                        tempArr.push({
                                            'u_name': items.u_name,
                                            'bg_name': items.bg_name,
                                            'le_name': items.le_name,
                                            'legal_entity_id': items.legal_entity_id,
                                            'div_name': items.div_name,
                                            'comp_name': values && values[i][m].comp_name,
                                            'criticality': values && values[i][m].criticality,
                                            'assignee_name': values && values[i][m].assignee_name,
                                            'descp': values && values[i][m].descp,
                                            'compfielaw_url': values && values[i][m].compfielaw_url,
                                            'ageing': values && values[i][m].ageing,
                                            'unitname': item,
                                            'index': finalcount,
                                            'le_id': items.legal_entity_id,
                                            'c_id': items.country_id,
                                            'd_id': items.domain_id,
                                            'u_id': items.unit_id,
                                            'start_date': items.start_date,
                                            'remarks_count': values && values[i][m].remarks_count,
                                            'compliance_history_id': values && values[i][m].compliance_history_id
                                        })
                                        finalcount = finalcount + 1
                                        console.log(count, 'countcountcountcount');
                                    }
                                    // temp1.concat(tempArr)
                                    Array.prototype.push.apply(temp1, tempArr)
                                    childDatas.push(tempArr)
                                }
                                console.log(childDatas, 'tempArr5557');
                                console.log(tempArr, 'tempArr5556666');
                                // temp.push([childDatas[i]])
                                // temp.push(childDatas[i])
                                // log
                            })
                            temp.push(childDatas)
                            console.log(temp, 'llllllllll');
                        }
                        setTempcriticalityTable(temp)
                    })
            }
            setSortingpurpose(temp1)
            console.log(temp1, 'temptemp');

            // criticalityTable.drill_compliances
        }

    }, [tableData])

    const compliancetaskfilter = (e, type, searchedArray) => {
        let filterTable = sortingpurpose

        if (searchingValue['business_group']) {
            filterTable = filterTable.filter((item) => {
                if ((item["bg_name"].toLowerCase().includes(searchingValue['business_group'].toLowerCase())) || (item["bg_name"].toLowerCase().includes(searchingValue['business_group'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['legal_entity']) {
            filterTable = filterTable.filter((item) => {
                if ((item["le_name"].toLowerCase().includes(searchingValue['legal_entity'].toLowerCase())) || (item["le_name"].toLowerCase().includes(searchingValue['legal_entity'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['division']) {
            filterTable = filterTable.filter((item) => {
                if ((item["div_name"].toLowerCase().includes(searchingValue['division'].toLowerCase())) || (item["div_name"].toLowerCase().includes(searchingValue['division'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['compliance']) {
            filterTable = filterTable.filter((item) => {
                if ((item["comp_name"].toLowerCase().includes(searchingValue['compliance'].toLowerCase())) || (item["comp_name"].toLowerCase().includes(searchingValue['compliance'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['criticality']) {
            filterTable = filterTable.filter((item) => {
                if ((item["criticality"].toLowerCase().includes(searchingValue['criticality'].toLowerCase())) || (item["criticality"].toLowerCase().includes(searchingValue['criticality'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['assignedTo']) {
            filterTable = filterTable.filter((item) => {
                if ((item["assignee_name"].toLowerCase().includes(searchingValue['assignedTo'].toLowerCase())) || (item["assignee_name"].toLowerCase().includes(searchingValue['assignedTo'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['overdue']) {
            filterTable = filterTable.filter((item) => {
                if ((item["ageing"].toLowerCase().includes(searchingValue['overdue'].toLowerCase())) || (item["ageing"].toLowerCase().includes(searchingValue['overdue'].toLowerCase()))) {
                    return true;
                }
            })
        }

        let arr = [];
        let filteredtemp = []
        let fiteredunitname = []

        if (filterTable && filterTable.length > 0) {
            filterTable &&
                filterTable.length > 0 &&
                filterTable.map(function (user) {
                    if (arr.indexOf(user.u_name) < 0)
                        arr.push({ u_name: user.u_name });
                });
            let uniqle = _.uniqBy(arr, function (e) {
                return e.u_name;
            });
            for (let k in uniqle) {
                let filteredObject = _.filter(filterTable, { u_name: uniqle[k].u_name })
                filteredtemp.push(filteredObject)
            }
            for (let k in filteredtemp) {
                let uniqle1 = _.uniqBy(filteredtemp[k], function (e) {
                    return e.unitname;
                });
                let uniq_unitname = uniqle1.map((item) => { return item.unitname })
                fiteredunitname.push(uniq_unitname)
            }
            let FinalfilteredObject = []
            for (let k in fiteredunitname) {
                let temp = []
                for (let j in fiteredunitname[k]) {
                    let filteredObject = _.filter(filteredtemp[k], { unitname: fiteredunitname[k][j] })
                    temp.push(filteredObject)
                }
                FinalfilteredObject.push(temp)
            }
            setTempcriticalityTable(FinalfilteredObject)
            console.log(FinalfilteredObject, 'FinalfilteredObject');
        } else {
            setTempcriticalityTable([])
        }
    };

    return (
        <div>
            {table == true ?
                <div className='back-to-top'>
                    <ScrollButton />
                </div> : ''}
            <div id='page-wrapper' className="page-wrapper">
                <div className="page-titles py-1 page-title-sticky">
                    <div>
                        <div className="row">
                            <div className="col-lg-6 col-sm-12" style={{ paddingLeft: '10px' }}>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ fontSize: '16px' }}>Dashboard</span>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                            <span style={{ fontSize: '16px' }}>Comparative Trend</span>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="col-lg-6 col-sm-12 d-flex py-sm-1 justify-content-md-end justify-content-sm-start full_screeen_icon_align" style={{ paddingRight: '10px' }}>
                                {table == false ?
                                    <>
                                        <Select className='me-2' style={{ width: '176px' }} value={chartType} onChange={(data) => {
                                            setChartType(data)
                                            setTable(false)
                                            setClickState(false)
                                        }}>
                                            <Option key='all'>All</Option>
                                            <Option key='complied'>Complied</Option>
                                            <Option key='notComplied'>Not Complied</Option>
                                            <Option key='delayedComplied'>Delayed Compliance</Option>
                                        </Select>
                                        <div className={localStorage.getItem('currentTheme')} style={{ textAlign: 'end' }}>
                                            <span className='togglecolour' style={{ fontSize: '18px' }}> <Switch type='success' style={{ backgroundColor: toggleButton == 'Month' ? 'green' : 'grey', fontSize: '18px', height: '32px', width: '75px' }} checked={toggleButton === 'Month' ? true : false} checkedChildren="Month" unCheckedChildren="Year"
                                                onChange={(e) => {
                                                    setToggleButton(e === false ? 'Year' : 'Month')
                                                    setDelayedData([])
                                                    setNotcomplieddata([])
                                                    setDataValue([])
                                                    if (entityData && entityData.length > 0) {
                                                        const payload = [
                                                            authtoken,
                                                            {
                                                                "session_token": authtoken,
                                                                "request":
                                                                    ["GetComplianceTrend",

                                                                        {
                                                                            "c_ids": [
                                                                                commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                                                            ],
                                                                            "d_ids": dIds,
                                                                            "filter_type": "Group",
                                                                            "filter_ids": [
                                                                                1
                                                                            ],
                                                                            "le_ids":
                                                                                localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid], "country_domains": countryDomain,
                                                                            "year": `${year}`,
                                                                            "chart_types": e === false ? 'Year' : 'Month'
                                                                        }
                                                                    ]
                                                            }
                                                        ]

                                                        getComplianceTrendchart({
                                                            payload: payload,
                                                            paramid: paramid
                                                        })

                                                    } else if (commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0) {
                                                        const payload = [
                                                            authtoken,
                                                            {
                                                                "session_token": authtoken,
                                                                "request":
                                                                    ["GetComplianceTrend",

                                                                        {
                                                                            "c_ids": [
                                                                                commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                                                            ],
                                                                            "d_ids": dIds,
                                                                            "filter_type": "Group",
                                                                            "filter_ids": [
                                                                                1
                                                                            ],
                                                                            "le_ids":
                                                                                localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                                                            "country_domains": countryDomain,
                                                                            "year": `${year}`,
                                                                            "chart_types": e === false ? 'Year' : 'Month'
                                                                        }
                                                                        // {
                                                                        //     "c_ids": [
                                                                        //         commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                                                        //     ],
                                                                        //     "d_ids": [
                                                                        //         commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                                                                        //     ],
                                                                        //     "filter_type": "Group",
                                                                        //     "filter_ids": [
                                                                        //         1
                                                                        //     ],
                                                                        //     "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [
                                                                        //         commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_id
                                                                        //     ],
                                                                        //     "country_domains": [
                                                                        //         {
                                                                        //             "d_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id,
                                                                        //             "c_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].c_id,
                                                                        //             "month_from": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_from,
                                                                        //             "month_to": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_to
                                                                        //         }
                                                                        //     ],
                                                                        //     "year": year,
                                                                        //     "chart_types": "Month"
                                                                        // }
                                                                    ]
                                                            }
                                                        ]

                                                        getComplianceTrendchart({
                                                            payload: payload,
                                                            paramid: paramid
                                                        })

                                                    }

                                                }}
                                            /></span>
                                        </div>

                                        <Button icon={<FilterOutlined />} className='addbutton ms-2' style={{ background: "#0096FF", borderRadius: '15px', marginLeft: '5px' }} onClick={() => {
                                            setOpen(true)
                                        }} size='default' >
                                            <Tooltip placement='top' title='Filter' getPopupContainer={(triggerNode) => {

                                                return triggerNode.parentNode;

                                            }} className='gotop'>Filter</Tooltip>
                                        </Button></> : <Button type="primary" shape="round" className='check me-1 addbutton' icon={<ArrowLeftOutlined />} size='default' onClick={() => {
                                            setTable(false)
                                            setClickState(false)
                                            setTableData([])
                                            setSearchingValue([])
                                        }}>
                                        <Tooltip placement='top'>Back</Tooltip>
                                    </Button>}
                                <div className='comparative'>
                                    <Button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                                        <Tooltip placement='left' title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                                    </Button>
                                </div>



                                {/* <button onClick={() => {
            setShowList(true)
            setAddType('add')
        }} className="icons twitter" style={{ border: 'none', margin: '0px' }}>
            <span className="tooltips" style={{ right: '105%' }}>Add</span>
            <span><i id={localStorage.getItem('currentTheme')}><PlusCircleOutlined /></i></span>
        </button> */}


                            </div>
                        </div>
                    </div>
                </div>
                {
                    table == true ?
                        <div className="container-fluid pt-0">


                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className="col-md-8">
                                        {/* <span style={{ marginTop: '2%', fontSize: '16px' }}><b style={{ marginTop: '2%', fontSize: '16px' }}>Compliance Trend of {commonData && commonData.g_name} for the year {yearValue}</b></span> */}
                                    </div>
                                    {/* <div className="col-md-4">
                                    <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", marginLeft: '70%' }}
                                        icon={<ArrowLeftOutlined />} size='default' onClick={() => {
                                            setTable(false)
                                        }}>
                                        <Tooltip title='Back'>Back</Tooltip>
                                    </Button>
                                </div> */}
                                </div>
                            </div><br />
                            {/* <Card className='rounded-3'> */}
                            {/* <h3>{criticalityValue != 'Complied' ? `${criticalityValue} compliance` : criticalityValue}</h3> */}
                            {/* <Tag style={{ height: '30px', width: criticalityValue != 'Complied' ? '25%' : '15%', fontSize: '17px', fontFamily: 'serif', textAlign: 'center' }} color="blue" >{criticalityValue != 'Complied' ? `${criticalityValue} compliance` : criticalityValue}</Tag> */}
                            {/* <Tag style={{ borderRadius: '15px', fontSize: '17px', fontFamily: 'serif', textAlign: 'center' }} color="blue" >{criticalityValue != 'Complied' ? `${criticalityValue} compliance` : criticalityValue}</Tag> */}
                            <div>
                                <div className="container-fluid pt-0">
                                    <div
                                        className={
                                            localStorage.getItem("currentTheme") +
                                            " ant-table-wrapper"
                                        }
                                        style={{ width: '100%' }}
                                    >
                                        <div className="row">

                                            <div className="ant-table ant-table-bordered ant-table-fixed-header service-provider-table"
                                                //  id='service-table-action'
                                                style={{ width: '100%', height: '100%' }}>
                                                <div className="ant-table-container">
                                                    <div className="ant-table-content taskrecalltable service-table">

                                                        <table className="table-fixed" >
                                                            <thead className="ant-table-thead" id='comp-approv-thead'
                                                            // style={{ top: is_Fullscreen ? (showValueModal == true ? 0 : '35px') : (showValueModal == true ? 0 : '107px') }}
                                                            >
                                                                <tr>
                                                                    <th
                                                                        className="ant-table-cell"
                                                                        style={{ width: "32px" }}
                                                                    >
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <p
                                                                                className={"p-0 mb-0"}
                                                                                style={{
                                                                                    // marginLeft: "36%",
                                                                                    display: "inline",
                                                                                    // color: searchingValue['compliance_task'] ? 'red' : ''
                                                                                }}
                                                                            >
                                                                                #
                                                                            </p>{" "}
                                                                        </div>

                                                                    </th>
                                                                    {(fieldType === 'BusinessGroup') || (fieldType === 'LegalEntity') || (fieldType === 'Division') || (fieldType === 'Category') || (fieldType === 'Unit') ? '' :
                                                                        <th
                                                                            className="ant-table-cell"
                                                                            style={{ width: "100px" }}
                                                                        >
                                                                            <div className="d-flex justify-content-between align-items-center">
                                                                                <p
                                                                                    className={"p-0 mb-0"}
                                                                                    style={{
                                                                                        // marginLeft: "36%",
                                                                                        display: "inline",
                                                                                        color: searchingValue['business_group'] ? 'red' : ''
                                                                                    }}
                                                                                >
                                                                                    Business Group
                                                                                </p>{" "}
                                                                                <Popconfirm
                                                                                    afterVisibleChange={() => {
                                                                                        setFocus(myRef1);
                                                                                    }}
                                                                                    placement="bottom"
                                                                                    title={
                                                                                        // <Input
                                                                                        //     ref={myRef1}
                                                                                        //     autoFocus={'on'}
                                                                                        //     placeholder="Compliance Tasks"
                                                                                        //     onKeyUp={(e) => {
                                                                                        //         compliancetaskfilter(
                                                                                        //             e.target.value,
                                                                                        //             "Compliance_Task"
                                                                                        //         );
                                                                                        //     }}
                                                                                        // />
                                                                                        <div className='position-relative'>
                                                                                            <Input
                                                                                                ref={myRef1}
                                                                                                placeholder="Business Group"
                                                                                                autoFocus={'on'}
                                                                                                onChange={(e) => {
                                                                                                    setSearchedValue({
                                                                                                        ...searchedValue,
                                                                                                        bgGroup: e.target.value
                                                                                                    });
                                                                                                    searchingValue['business_group'] = e.target.value
                                                                                                }}
                                                                                                value={searchedValue.bgGroup || undefined}
                                                                                                onKeyUp={(e) => {
                                                                                                    compliancetaskfilter(
                                                                                                        e.target.value,
                                                                                                        "business_group",
                                                                                                        "business_group"
                                                                                                    );

                                                                                                }}
                                                                                            />
                                                                                            <CloseCircleOutlined onClick={() => {
                                                                                                myRef1.current.value = "";
                                                                                                setSearchedValue({
                                                                                                    ...searchedValue,
                                                                                                    complianceTasks: ''
                                                                                                });
                                                                                                searchingValue['business_group'] = ''
                                                                                                compliancetaskfilter(
                                                                                                    '',
                                                                                                    "business_group",
                                                                                                    "business_group"

                                                                                                );

                                                                                            }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                        </div>
                                                                                    }
                                                                                >
                                                                                    <SearchOutlined
                                                                                        style={{ float: 'right', marginTop: '4px' }}
                                                                                    />
                                                                                </Popconfirm>
                                                                            </div>

                                                                        </th>
                                                                    }
                                                                    {(fieldType === 'LegalEntity') || (fieldType === 'Division') || (fieldType === 'Category') || (fieldType === 'Unit') ? '' :
                                                                        <th
                                                                            className="ant-table-cell"
                                                                            style={{ width: "80px" }}
                                                                        >
                                                                            <div className="d-flex justify-content-between align-items-center">
                                                                                <p
                                                                                    className={"p-0 mb-0"}
                                                                                    style={{
                                                                                        // marginLeft: "36%",
                                                                                        display: "inline",
                                                                                        color: searchingValue['legal_entity'] ? 'red' : ''
                                                                                    }}
                                                                                >
                                                                                    Legal Entity
                                                                                </p>{" "}
                                                                                <Popconfirm
                                                                                    afterVisibleChange={() => {
                                                                                        setFocus(myRef2);
                                                                                    }}
                                                                                    placement="bottom"
                                                                                    title={
                                                                                        // <Input
                                                                                        //     ref={myRef1}
                                                                                        //     autoFocus={'on'}
                                                                                        //     placeholder="Compliance Tasks"
                                                                                        //     onKeyUp={(e) => {
                                                                                        //         compliancetaskfilter(
                                                                                        //             e.target.value,
                                                                                        //             "Compliance_Task"
                                                                                        //         );
                                                                                        //     }}
                                                                                        // />
                                                                                        <div className='position-relative'>
                                                                                            <Input
                                                                                                ref={myRef2}
                                                                                                placeholder="Legal Entity"
                                                                                                autoFocus={'on'}
                                                                                                onChange={(e) => {
                                                                                                    setSearchedValue({
                                                                                                        ...searchedValue,
                                                                                                        legal_entity: e.target.value
                                                                                                    });
                                                                                                    searchingValue['legal_entity'] = e.target.value
                                                                                                }}
                                                                                                value={searchedValue.legal_entity || undefined}
                                                                                                onKeyUp={(e) => {
                                                                                                    compliancetaskfilter(
                                                                                                        e.target.value,
                                                                                                        "legal_entity",
                                                                                                        "legal_entity"
                                                                                                    );

                                                                                                }}
                                                                                            />
                                                                                            <CloseCircleOutlined onClick={() => {
                                                                                                myRef2.current.value = "";
                                                                                                setSearchedValue({
                                                                                                    ...searchedValue,
                                                                                                    legal_entity: ''
                                                                                                });
                                                                                                searchingValue['legal_entity'] = ''
                                                                                                compliancetaskfilter(
                                                                                                    '',
                                                                                                    "legal_entity",
                                                                                                    "legal_entity"

                                                                                                );

                                                                                            }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                        </div>
                                                                                    }
                                                                                >
                                                                                    <SearchOutlined
                                                                                        style={{ float: 'right', marginTop: '4px' }}
                                                                                    />
                                                                                </Popconfirm>
                                                                            </div>

                                                                        </th>
                                                                    }
                                                                    {(fieldType === 'Division') || (fieldType === 'Category') || (fieldType === 'Unit') ? '' :
                                                                        <th
                                                                            className="ant-table-cell"
                                                                            style={{ width: "80px" }}
                                                                        >
                                                                            <div className="d-flex justify-content-between align-items-center">
                                                                                <p
                                                                                    className={"p-0 mb-0"}
                                                                                    style={{
                                                                                        // marginLeft: "36%",
                                                                                        display: "inline",
                                                                                        color: searchingValue['division'] ? 'red' : ''
                                                                                    }}
                                                                                >
                                                                                    Division
                                                                                </p>{" "}
                                                                                <Popconfirm
                                                                                    afterVisibleChange={() => {
                                                                                        setFocus(myRef3);
                                                                                    }}
                                                                                    placement="bottom"
                                                                                    title={
                                                                                        // <Input
                                                                                        //     ref={myRef1}
                                                                                        //     autoFocus={'on'}
                                                                                        //     placeholder="Compliance Tasks"
                                                                                        //     onKeyUp={(e) => {
                                                                                        //         compliancetaskfilter(
                                                                                        //             e.target.value,
                                                                                        //             "Compliance_Task"
                                                                                        //         );
                                                                                        //     }}
                                                                                        // />
                                                                                        <div className='position-relative'>
                                                                                            <Input
                                                                                                ref={myRef3}
                                                                                                placeholder="Division"
                                                                                                autoFocus={'on'}
                                                                                                onChange={(e) => {
                                                                                                    setSearchedValue({
                                                                                                        ...searchedValue,
                                                                                                        division: e.target.value
                                                                                                    });
                                                                                                    searchingValue['division'] = e.target.value
                                                                                                }}
                                                                                                value={searchedValue.division || undefined}
                                                                                                onKeyUp={(e) => {
                                                                                                    compliancetaskfilter(
                                                                                                        e.target.value,
                                                                                                        "division",
                                                                                                        "division"
                                                                                                    );

                                                                                                }}
                                                                                            />
                                                                                            <CloseCircleOutlined onClick={() => {
                                                                                                myRef3.current.value = "";
                                                                                                setSearchedValue({
                                                                                                    ...searchedValue,
                                                                                                    division: ''
                                                                                                });
                                                                                                searchingValue['division'] = ''
                                                                                                compliancetaskfilter(
                                                                                                    '',
                                                                                                    "division",
                                                                                                    "division"

                                                                                                );

                                                                                            }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                        </div>
                                                                                    }
                                                                                >
                                                                                    <SearchOutlined
                                                                                        style={{ float: 'right', marginTop: '4px' }}
                                                                                    />
                                                                                </Popconfirm>
                                                                            </div>

                                                                        </th>}

                                                                    <th
                                                                        className="ant-table-cell"
                                                                        style={{ width: "180px" }}
                                                                    >
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <p
                                                                                className={"p-0 mb-0"}
                                                                                style={{
                                                                                    // marginLeft: "36%",
                                                                                    display: "inline",
                                                                                    color: searchingValue['compliance'] ? 'red' : ''
                                                                                }}
                                                                            >
                                                                                Compliance
                                                                            </p>{" "}
                                                                            <Popconfirm
                                                                                afterVisibleChange={() => {
                                                                                    setFocus(myRef4);
                                                                                }}
                                                                                placement="bottom"
                                                                                title={
                                                                                    // <Input
                                                                                    //     ref={myRef1}
                                                                                    //     autoFocus={'on'}
                                                                                    //     placeholder="Compliance Tasks"
                                                                                    //     onKeyUp={(e) => {
                                                                                    //         compliancetaskfilter(
                                                                                    //             e.target.value,
                                                                                    //             "Compliance_Task"
                                                                                    //         );
                                                                                    //     }}
                                                                                    // />
                                                                                    <div className='position-relative'>
                                                                                        <Input
                                                                                            ref={myRef4}
                                                                                            placeholder="Compliance"
                                                                                            autoFocus={'on'}
                                                                                            onChange={(e) => {
                                                                                                setSearchedValue({
                                                                                                    ...searchedValue,
                                                                                                    compliance: e.target.value
                                                                                                });
                                                                                                searchingValue['compliance'] = e.target.value
                                                                                            }}
                                                                                            value={searchedValue.compliance || undefined}
                                                                                            onKeyUp={(e) => {
                                                                                                compliancetaskfilter(
                                                                                                    e.target.value,
                                                                                                    "compliance",
                                                                                                    "compliance"
                                                                                                );

                                                                                            }}
                                                                                        />
                                                                                        <CloseCircleOutlined onClick={() => {
                                                                                            myRef4.current.value = "";
                                                                                            setSearchedValue({
                                                                                                ...searchedValue,
                                                                                                compliance: ''
                                                                                            });
                                                                                            searchingValue['compliance'] = ''
                                                                                            compliancetaskfilter(
                                                                                                '',
                                                                                                "compliance",
                                                                                                "compliance"

                                                                                            );

                                                                                        }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                    </div>
                                                                                }
                                                                            >
                                                                                <SearchOutlined
                                                                                    style={{ float: 'right', marginTop: '4px' }}
                                                                                />
                                                                            </Popconfirm>
                                                                        </div>

                                                                    </th>
                                                                    <th
                                                                        className="ant-table-cell"
                                                                        style={{ width: "80px" }}
                                                                    >
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <p
                                                                                className={"p-0 mb-0"}
                                                                                style={{
                                                                                    // marginLeft: "36%",
                                                                                    display: "inline",
                                                                                    color: searchingValue['criticality'] ? 'red' : ''
                                                                                }}
                                                                            >
                                                                                Criticality
                                                                            </p>{" "}
                                                                            <Popconfirm
                                                                                afterVisibleChange={() => {
                                                                                    setFocus(myRef5);
                                                                                }}
                                                                                placement="bottom"
                                                                                title={
                                                                                    // <Input
                                                                                    //     ref={myRef1}
                                                                                    //     autoFocus={'on'}
                                                                                    //     placeholder="Compliance Tasks"
                                                                                    //     onKeyUp={(e) => {
                                                                                    //         compliancetaskfilter(
                                                                                    //             e.target.value,
                                                                                    //             "Compliance_Task"
                                                                                    //         );
                                                                                    //     }}
                                                                                    // />
                                                                                    <div className='position-relative'>
                                                                                        <Input
                                                                                            ref={myRef5}
                                                                                            placeholder="Criticality"
                                                                                            autoFocus={'on'}
                                                                                            onChange={(e) => {
                                                                                                setSearchedValue({
                                                                                                    ...searchedValue,
                                                                                                    criticality: e.target.value
                                                                                                });
                                                                                                searchingValue['criticality'] = e.target.value
                                                                                            }}
                                                                                            value={searchedValue.criticality || undefined}
                                                                                            onKeyUp={(e) => {
                                                                                                compliancetaskfilter(
                                                                                                    e.target.value,
                                                                                                    "criticality",
                                                                                                    "criticality"
                                                                                                );

                                                                                            }}
                                                                                        />
                                                                                        <CloseCircleOutlined onClick={() => {
                                                                                            myRef5.current.value = "";
                                                                                            setSearchedValue({
                                                                                                ...searchedValue,
                                                                                                criticality: ''
                                                                                            });
                                                                                            searchingValue['criticality'] = ''
                                                                                            compliancetaskfilter(
                                                                                                '',
                                                                                                "criticality",
                                                                                                "criticality"

                                                                                            );

                                                                                        }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                    </div>
                                                                                }
                                                                            >
                                                                                <SearchOutlined
                                                                                    style={{ float: 'right', marginTop: '4px' }}
                                                                                />
                                                                            </Popconfirm>
                                                                        </div>

                                                                    </th>
                                                                    <th
                                                                        className="ant-table-cell"
                                                                        style={{ width: "80px" }}
                                                                    >
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <p
                                                                                className={"p-0 mb-0"}
                                                                                style={{
                                                                                    // marginLeft: "36%",
                                                                                    display: "inline",
                                                                                    color: searchingValue['assignedTo'] ? 'red' : ''
                                                                                }}
                                                                            >
                                                                                Assigned To
                                                                            </p>{" "}
                                                                            <Popconfirm
                                                                                afterVisibleChange={() => {
                                                                                    setFocus(myRef6);
                                                                                }}
                                                                                placement="bottom"
                                                                                title={
                                                                                    // <Input
                                                                                    //     ref={myRef1}
                                                                                    //     autoFocus={'on'}
                                                                                    //     placeholder="Compliance Tasks"
                                                                                    //     onKeyUp={(e) => {
                                                                                    //         compliancetaskfilter(
                                                                                    //             e.target.value,
                                                                                    //             "Compliance_Task"
                                                                                    //         );
                                                                                    //     }}
                                                                                    // />
                                                                                    <div className='position-relative'>
                                                                                        <Input
                                                                                            ref={myRef6}
                                                                                            placeholder="Assigned To"
                                                                                            autoFocus={'on'}
                                                                                            onChange={(e) => {
                                                                                                setSearchedValue({
                                                                                                    ...searchedValue,
                                                                                                    assignedTo: e.target.value
                                                                                                });
                                                                                                searchingValue['assignedTo'] = e.target.value
                                                                                            }}
                                                                                            value={searchedValue.assignedTo || undefined}
                                                                                            onKeyUp={(e) => {
                                                                                                compliancetaskfilter(
                                                                                                    e.target.value,
                                                                                                    "assignedTo",
                                                                                                    "assignedTo"
                                                                                                );

                                                                                            }}
                                                                                        />
                                                                                        <CloseCircleOutlined onClick={() => {
                                                                                            myRef6.current.value = "";
                                                                                            setSearchedValue({
                                                                                                ...searchedValue,
                                                                                                assignedTo: ''
                                                                                            });
                                                                                            searchingValue['assignedTo'] = ''
                                                                                            compliancetaskfilter(
                                                                                                '',
                                                                                                "assignedTo",
                                                                                                "assignedTo"

                                                                                            );

                                                                                        }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                    </div>
                                                                                }
                                                                            >
                                                                                <SearchOutlined
                                                                                    style={{ float: 'right', marginTop: '4px' }}
                                                                                />
                                                                            </Popconfirm>
                                                                        </div>

                                                                    </th>
                                                                    {criticalityValue === 'Complied' ? '' :
                                                                        <th
                                                                            className="ant-table-cell"
                                                                            style={{ width: "80px" }}
                                                                        >
                                                                            <div className="d-flex justify-content-between align-items-center">
                                                                                <p
                                                                                    className={"p-0 mb-0"}
                                                                                    style={{
                                                                                        // marginLeft: "36%",
                                                                                        display: "inline",
                                                                                        color: searchingValue['overdue'] ? 'red' : ''
                                                                                    }}
                                                                                >
                                                                                    {criticalityValue === 'Delayed Compliance' ? 'Delayed By' : 'Over Due'}
                                                                                </p>{" "}
                                                                                <Popconfirm
                                                                                    afterVisibleChange={() => {
                                                                                        setFocus(myRef7);
                                                                                    }}
                                                                                    placement="bottomLeft"
                                                                                    title={
                                                                                        // <Input
                                                                                        //     ref={myRef1}
                                                                                        //     autoFocus={'on'}
                                                                                        //     placeholder="Compliance Tasks"
                                                                                        //     onKeyUp={(e) => {
                                                                                        //         compliancetaskfilter(
                                                                                        //             e.target.value,
                                                                                        //             "Compliance_Task"
                                                                                        //         );
                                                                                        //     }}
                                                                                        // />
                                                                                        <div className='position-relative'>
                                                                                            <Input
                                                                                                ref={myRef7}
                                                                                                placeholder="Over Due"
                                                                                                autoFocus={'on'}
                                                                                                onChange={(e) => {
                                                                                                    setSearchedValue({
                                                                                                        ...searchedValue,
                                                                                                        overdue: e.target.value
                                                                                                    });
                                                                                                    searchingValue['overdue'] = e.target.value
                                                                                                }}
                                                                                                value={searchedValue.overdue || undefined}
                                                                                                onKeyUp={(e) => {
                                                                                                    compliancetaskfilter(
                                                                                                        e.target.value,
                                                                                                        "overdue",
                                                                                                        "overdue"
                                                                                                    );

                                                                                                }}
                                                                                            />
                                                                                            <CloseCircleOutlined onClick={() => {
                                                                                                myRef7.current.value = "";
                                                                                                setSearchedValue({
                                                                                                    ...searchedValue,
                                                                                                    overdue: ''
                                                                                                });
                                                                                                searchingValue['overdue'] = ''
                                                                                                compliancetaskfilter(
                                                                                                    '',
                                                                                                    "overdue",
                                                                                                    "overdue"

                                                                                                );

                                                                                            }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                        </div>
                                                                                    }
                                                                                >
                                                                                    <SearchOutlined
                                                                                        style={{ float: 'right', marginTop: '4px' }}
                                                                                    />
                                                                                </Popconfirm>
                                                                            </div>

                                                                        </th>
                                                                    }
                                                                </tr>
                                                            </thead>
                                                            <tr>
                                                                <td colSpan={8}>
                                                                    <Collapse defaultActiveKey={['0']}>
                                                                        {tempcriticalityTable && tempcriticalityTable.length > 0 &&
                                                                            tempcriticalityTable.map((items, i) => {
                                                                                // let unitName = items.drill_compliances
                                                                                // let keys = Object.keys(unitName);
                                                                                console.log(items, 'items5599');
                                                                                return (
                                                                                    <Panel style={{ marginBottom: '5px' }} header={`${items[0][0].le_name}-${items[0][0].u_name}`}>
                                                                                        <table >
                                                                                            <tbody className="ant-table-tbody">
                                                                                                {items.map((item, p) => {
                                                                                                    return (<>
                                                                                                        <tr className="ant-table-row ant-table-row-level-0">
                                                                                                            <td colSpan={8}>
                                                                                                                <h6 className='m-2 ml-2 mb-2'>{item[0].unitname}</h6>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        {item.map((childitem, m) => {
                                                                                                            return <tr className="ant-table-row ant-table-row-level-0">
                                                                                                                <td style={{ width: '50px' }} className="ant-table-cell border-end"><center>{childitem.index}</center></td>
                                                                                                                {(fieldType === 'BusinessGroup') || (fieldType === 'LegalEntity') || (fieldType === 'Division') || (fieldType === 'Category') || (fieldType === 'Unit') ? '' :
                                                                                                                    <td style={{ width: '160px' }} className="ant-table-cell border-end">{childitem.bg_name}</td>}
                                                                                                                {(fieldType === 'LegalEntity') || (fieldType === 'Division') || (fieldType === 'Category') || (fieldType === 'Unit') ? '' :
                                                                                                                    <td style={{ width: '133px' }}
                                                                                                                        className="ant-table-cell border-end">{childitem.le_name}</td>}
                                                                                                                {(fieldType === 'Division') || (fieldType === 'Category') || (fieldType === 'Unit') ? '' :
                                                                                                                    <td style={{ width: '133px' }}
                                                                                                                        className="ant-table-cell border-end">{childitem.div_name}</td>}
                                                                                                                <td style={{ width: '299px' }}
                                                                                                                    className="ant-table-cell border-end">
                                                                                                                    <Tooltip title={
                                                                                                                        <table style={{ width: "100%", borderCollapse: 'collapse', border: '1px solid #636262', tableLayout: 'auto' }}>
                                                                                                                            <tbody>
                                                                                                                                <tr>
                                                                                                                                    <th style={{ border: '1px solid #636262', marginLeft: '5%', marginRight: '5%' }}><center>Compliance Description</center></th>
                                                                                                                                    <th style={{ border: '1px solid #636262' }}><center>Document Reference Number</center></th>

                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <td style={{ border: '1px solid #636262' }}><center>{childitem.descp ? childitem.descp : '-'}</center></td>
                                                                                                                                    <td style={{ border: '1px solid #636262' }}><center>{childitem.doc_ref_num ? childitem.doc_ref_num : '-'}</center></td>
                                                                                                                                </tr>
                                                                                                                            </tbody>
                                                                                                                        </table>
                                                                                                                    }><InfoCircleFilled /></Tooltip>{childitem.remarks_count > 0 ? <a style={{ marginLeft: '5px' }} href="javascript:;"
                                                                                                                        onClick={() => {

                                                                                                                            setRemarksModalData({
                                                                                                                                ...remarksModalData,
                                                                                                                                le_id: childitem.le_id,
                                                                                                                                c_id: Number(childitem.c_id),
                                                                                                                                d_id: Number(childitem.d_id),
                                                                                                                                u_id: Number(childitem.u_id),
                                                                                                                                start_date: childitem.start_date
                                                                                                                            })
                                                                                                                            const payload = [
                                                                                                                                authtoken,
                                                                                                                                {
                                                                                                                                    "session_token": authtoken,
                                                                                                                                    "request": [
                                                                                                                                        "GetComplianceRemarksHistoryData",
                                                                                                                                        {
                                                                                                                                            "legal_entity_id": childitem.le_id,
                                                                                                                                            "unit_id": Number(childitem.u_id),
                                                                                                                                            "compliance_history_id": Number(childitem.compliance_history_id)
                                                                                                                                        }
                                                                                                                                    ]
                                                                                                                                }
                                                                                                                            ]
                                                                                                                            getRemarksHistoryData({
                                                                                                                                payload: payload,
                                                                                                                                paramid: paramid
                                                                                                                            })
                                                                                                                            sethistorymodal(true);

                                                                                                                        }}
                                                                                                                    ><Tooltip title='Click to View Remarks History'><InfoCircleFilled color='blue' /></Tooltip></a> : ''}
                                                                                                                    {childitem.compfielaw_url != '' ? <Tooltip style={{ marginRight: '20%' }} title='Compfie Law Knowlegde Portal'>
                                                                                                                        <a className='ml-3' style={{ marginLeft: '5px' }} href={`${childitem.compfielaw_url}`}>
                                                                                                                            <BookTwoTone />
                                                                                                                        </a>
                                                                                                                    </Tooltip> : ''}
                                                                                                                    <span style={{ marginLeft: '5px' }}>{childitem.comp_name}</span> </td>
                                                                                                                <td style={{ width: '143px' }} className="ant-table-cell border-end">{childitem.criticality}</td>
                                                                                                                <td style={{ width: '134px' }} className="ant-table-cell border-end">{childitem.assignee_name}</td>
                                                                                                                {criticalityValue === 'Complied' ? '' :
                                                                                                                    <td style={{ width: '127px' }} className="ant-table-cell border-end">{childitem.ageing}</td>}
                                                                                                            </tr>
                                                                                                        })}</>)
                                                                                                })}
                                                                                            </tbody>
                                                                                        </table>
                                                                                        {/* <Table
                                                                size={'small'} className='remark-header add-service-prv'
                                                                columns={columnState === true ? columns1 : columns}
                                                                dataSource={childDatas[i]}
                                                                bordered
                                                                scroll={{ x: 1000 }}
                                                                pagination={false}
                                                                onChange={handleChange}
                                                            /> */}

                                                                                        {/* </> */}
                                                                                        {/* ) */}
                                                                                        {/* // })} */}

                                                                                    </Panel>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Collapse>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {count == showmoreCount ? false :
                                <div className='text-center mt-2'>
                                    <Button shape='round' className='addbutton'
                                        onClick={showmore} icon={<DownOutlined />}
                                    >Show more records</Button>
                                </div>}
                            {/* {/* } */}
                            {/* </Card> */}


                            {/* <Table dataSource={dataSource} columns={columns} pagination={false}
                    className='userprivclass' /> */}
                        </div> :
                        <div className="container-fluid pt-1">
                            <div className="row" >
                                <div className="col-md-12">

                                </div>
                                {/* {data && data.length > 0 ? */}
                                <>
                                    <div className="col-md-12">

                                        {/* <div className="card">
                                                <div className='card-body'> */}

                                        <>
                                            {/* <Card style={{ borderRadius: '15px' }}>
                                                {resultArray && resultArray.length === 0 ?
                                                    <div id="nodata" style={{ width: "100%", height: "500px" }}></div> :
                                                    <div id="Comprativechartdiv" style={{ width: "100%", height: "500px" }}></div>}
                                            </Card> */}
                                            {((open === true) && (fieldTypeClick != '') && (go === false)) ? <Spin size='large' style={{ marginTop: '17%', marginLeft: '45%', marginBottom: '10%' }} /> :
                                                <Card style={{ borderRadius: '15px' }}>
                                                    {JSON.parse(isCountryValueTrue) === true || resultArray && resultArray.length === 0 ?
                                                        <p style={{ width: "100%", marginTop: '20%', marginLeft: '43%', height: "250px" }}>No Data to Display</p>
                                                        :
                                                        <div id="Comprativechartdiv" style={{ width: "100%", marginTop: '10px', height: "500px" }}></div>
                                                    }
                                                </Card>}
                                            {resultArray && resultArray.length > 0 ?
                                                <div className="col-md-12 mt-2">
                                                    <div className="row">
                                                        <div className="col-md-6"></div>
                                                        <div className="col-md-6 text-md-end text-lg-end" >
                                                            <label htmlFor=""><b>Time Zone :</b></label>
                                                            <Select
                                                                allowClear={false}
                                                                size="default"
                                                                onChange={(data) => {
                                                                    setTimeZone(data)
                                                                    setGeneratedOn(true)
                                                                    const payload = [
                                                                        authtoken,
                                                                        {
                                                                            "session_token": authtoken,
                                                                            "request": [
                                                                                "GetDateTime_ByZone",
                                                                                {
                                                                                    "tz_name": data
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                    getTimeZoneData({
                                                                        payload: payload,
                                                                        paramid: paramid
                                                                    })

                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                placeholder="Enter Time Zone"
                                                                style={{ marginLeft: '5%' }}
                                                            // value={compfie.user_Group}

                                                            >

                                                                {TimeZoneValue && TimeZoneValue.tz_list.length > 0 && TimeZoneValue.tz_list.map((item, i) => {

                                                                    return (

                                                                        <Option key={item.tz_name}>

                                                                            {item.tz_name}

                                                                        </Option>

                                                                    );

                                                                })}
                                                                {/* );
                                                })} */}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                </div> : ''}
                                        </>





                                        {/* </div>
                                            </div> */}
                                    </div>

                                </>
                                {/* <p style={{ marginTop: '18%', marginLeft: '40%', marginBottom: '18%' }}>No Data to Display </p>
                                 } */}
                                {/* {data && data.length > 0 ? */}
                                {toggleButton === 'Month' ?
                                    <div className="col-md-12 mt-2">
                                        <div className="row">
                                            <div className="col-md-2">
                                                {year === prevYear ? '' :
                                                    <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                        icon={<ArrowLeftOutlined />} size='default'
                                                        onClick={() => {
                                                            setYear(year - 1)
                                                            setTempArr([])
                                                            setResultArray([])
                                                            setTempData([])
                                                            setChartData([])
                                                        }}
                                                    >
                                                        Previous Year
                                                    </Button>}
                                            </div>
                                            <div className="col-md-8"></div>
                                            <div className="col-md-2 text-end">
                                                {year != moment().year() ?
                                                    <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                        size='default'
                                                        onClick={() => {
                                                            setYear(year + 1)
                                                            setTempArr([])
                                                            setResultArray([])
                                                            setTempData([])
                                                            setChartData([])
                                                        }}
                                                    >
                                                        Next Year<ArrowRightOutlined />
                                                    </Button>
                                                    : false}
                                            </div>
                                        </div>
                                    </div> : ''}
                                {/* : ''} */}

                            </div>
                        </div>
                }

                <Drawer title="FILTER BY" className={localStorage.getItem("currentTheme") + ' resize-drawer'} placement="right" closable={false} onClose={false} open={open}
                    extra={
                        <Space>
                            <CloseCircleOutlined style={{ marginTop: '2%', marginLeft: '75', fontSize: '140%' }} onClick={onClose} />
                        </Space>
                    }
                >
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4">
                                <label><b>Country</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Country"
                                    mode='multiple'
                                    maxTagCount='responsive'
                                    value={filterData.country ? filterData.country : undefined}
                                    // onChange={(data, values) => {
                                    //     setFieldTypeClick('Country')
                                    //     setFilterData({
                                    //         ...filterData,
                                    //         country: data,
                                    //         domain: ''
                                    //     })
                                    //     setFilterDataNames({
                                    //         ...filterDataNames,
                                    //         country: values.children,
                                    //         domain: '',
                                    //         legalentity: '',
                                    //         division: '',
                                    //         unit: '',
                                    //         business: '',
                                    //         category: '',
                                    //         unit: ''
                                    //     })
                                    //     setType('')

                                    // }
                                    // }
                                    onChange={(option) => HandelCountryChange(option)}
                                    onDeselect={(data) => {

                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {field && field.country &&
                                        field.country.length > 0 &&
                                        field.country.map((item, i) => {
                                            return (
                                                <Option key={item.c_id}>
                                                    {item.c_name}
                                                </Option>
                                            );
                                        })}

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label><b>Domain</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Domain"
                                    mode='multiple'
                                    maxTagCount='responsive'
                                    value={filterData.domain ? filterData.domain : undefined}
                                    // onChange={(data, values) => {
                                    //     console.log(data,'data-domain');
                                    //     let filteredBusinessGroup = _.filter(commonData && commonData.bg_groups, { d_id: Number(data) })
                                    //     let filteredDivision = _.filter(commonData && commonData.div_infos, { d_id: Number(data) })
                                    //     // let filteredCategory = _.filter(commonData && commonData.bg_groups,{d_id:Number(data)})
                                    //     // let filteredUnit = _.filter(commonData && commonData.bg_groups,{d_id:Number(data)})
                                    //     let filteredLegalEntity = commonData && commonData.le_did_infos.filter(item => item.d_ids.includes(Number(data)))
                                    //     let filteredCategory = _.filter(commonData.cat_info, { div_id: Number(filteredDivision[0].div_id) })
                                    //     let filteredUnit = _.filter(commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
                                    //     let temp = []
                                    //     temp.push({
                                    //         'bg_id': filteredCategory[0].bg_id,
                                    //         'c_id': filteredCategory[0].c_id,
                                    //         'cat_id': filteredCategory[0].cat_id,
                                    //         'cat_name': filteredCategory[0].cat_name,
                                    //         'd_id': filteredCategory[0].d_id,
                                    //         'div_id': filteredCategory[0].div_id,
                                    //         'le_id': filteredCategory[0].le_id,
                                    //         'div_name': division[0].div_name
                                    //     })
                                    //     setCategory(temp)
                                    //     setUnit(filteredUnit)
                                    //     setfield({
                                    //         ...field,
                                    //         legalEntity: filteredLegalEntity,
                                    //         business: filteredBusinessGroup
                                    //     })
                                    //     let temp1=[]
                                    //     for(let i in commonData.d_info){
                                    //         temp1.push(commonData.d_info[i].d_id)
                                    //     }
                                    //     setFilterData({
                                    //         ...filterData,
                                    //         domain: temp1
                                    //     })
                                    //     setFilterDataNames({
                                    //         ...filterDataNames,
                                    //         domain: values.children,
                                    //         legalentity: '',
                                    //         division: '',
                                    //         unit: '',
                                    //         business: '',
                                    //         category: '',
                                    //         unit: ''
                                    //     })
                                    //     setDivision(filteredDivision)
                                    //     // let temp = []
                                    //     // temp.push({
                                    //     //     'bg_id': filteredCategory[0].bg_id,
                                    //     //     'c_id': filteredCategory[0].c_id,
                                    //     //     'cat_id': filteredCategory[0].cat_id,
                                    //     //     'cat_name': filteredCategory[0].cat_name,
                                    //     //     'd_id': filteredCategory[0].d_id,
                                    //     //     'div_id': filteredCategory[0].div_id,
                                    //     //     'le_id': filteredCategory[0].le_id,
                                    //     //     'div_name': filteredDivision[0].div_name
                                    //     // })
                                    //     // setCategory(temp)
                                    //     // setUnit(filteredUnit)
                                    // }
                                    // }
                                    onChange={(option) => HandelChange(option)}
                                    // onDeselect={(data) => {
                                    //     console.log(data,'data-deselect');
                                    //     filterData && filterData.domain.splice(data,1)
                                    // }}
                                    style={{ width: '100%' }}
                                    disabled={filterData.country == '' ? true : false}
                                >
                                    {domainListData && domainListData.length > 0 &&
                                        domainListData.map((finalList_unitlist) => (
                                            <OptGroup label={finalList_unitlist.label}>
                                                {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                    <Option value={subUnitData.d_id}>{subUnitData.d_name}</Option>

                                                ))}
                                            </OptGroup>
                                        ))}

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-1">
                                <input type='checkbox' checked={checkBox === true ? true : false} onChange={() => {
                                    setType('Group')
                                    setCheckBox(!checkBox)
                                }}></input>
                            </div>
                            <div className="col-md-4">
                                <label><b>Group</b></label>
                            </div>
                            {/* <div className="col-md-2">

                        </div> */}
                            <div className="col-md-1">
                                {/* <input type='checkbox' onChange={(e) => {
                                if (e.target.value) {
                                    setFilterData({
                                        ...filterData,
                                        chartType: 'Consolidated'
                                    })
                                }
                            }}></input> */}
                            </div>
                            <div className="col-md-4">
                                {/* <label><b>Consolidated</b></label> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-6">

                                <label><b>From Date</b></label>
                                <DatePicker
                                    disabled={true}
                                    allowClear={() => {
                                        setFilterData({
                                            ...filterData,
                                            from_date: null,
                                        })
                                    }}
                                    // value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                    // value={consolidatedData.from_date}
                                    // disabled={consolidatedData.domainy == '' ? true : false}
                                    onChange={(date, dateString) => {

                                        console.log(dateString, 'dateString');
                                        setFilterData({
                                            ...filterData,
                                            from_date: dateString,
                                        })

                                    }}

                                />

                            </div>
                            <div className="col-md-6">

                                <label><b>To Date</b></label>

                                <DatePicker
                                    // value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                    // value={consolidatedData.from_date}
                                    // disabled={consolidatedData.domainy == '' ? true : false}
                                    // onChange={(date, dateString) => {

                                    //     console.log(dateString, 'dateString');
                                    //     setConsolidatedData({
                                    //         ...consolidatedData,
                                    //         from_date: dateString,
                                    //         to_date: moment(dateString).add(3, 'months')
                                    //     })

                                    // }}
                                    disabled={true}
                                    onChange={(date, dateString) => {

                                        console.log(dateString, 'dateString');
                                        if (dateString != undefined) {
                                            setFilterData({
                                                ...filterData,
                                                to_date: dateString,
                                            })
                                        }
                                        else {
                                            setFilterData({
                                                ...filterData,
                                                to_date: null,
                                            })
                                        }


                                    }}

                                />

                            </div>
                        </div>
                    </div>

                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldTypeClick == 'BusinessGroup' ? 'blue' : 'black' }}><b>Business Group</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Business Group"
                                    value={filterDataNames.business ? filterDataNames.business : undefined}
                                    onClick={() => {
                                        setFieldTypeClick('BusinessGroup')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                    }}
                                    onChange={(data, values) => {
                                        setType('')
                                        setErrorField('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        let filteredEntity = _.filter(commonData && commonData.le_did_infos, { bg_id: Number(data) })
                                        let filteredDivision = _.filter(commonData && commonData.div_infos, { bg_id: Number(data) })
                                        console.log(filteredDivision, 'filteredDivision');
                                        let uniqueDiv = []
                                        if (filteredDivision && filteredDivision.length > 0) {
                                            uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                                                return e.div_id;
                                            });
                                        }
                                        setDivision(uniqueDiv)
                                        let filteredCategory = _.filter(commonData && commonData.cat_info, { bg_id: Number(data) })
                                        let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
                                        setfield({
                                            ...field,
                                            legalEntity: filteredEntity
                                        })
                                        setFieldType('BusinessGroup')
                                        setFilterData({
                                            ...filterData,
                                            business: data,
                                        })
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            business: values.children,
                                            legalentity: '',
                                            division: '',
                                            unit: '',
                                            category: '',
                                            unit: ''
                                        })
                                        let temp = []
                                        temp.push({
                                            'bg_id': filteredCategory[0].bg_id,
                                            'c_id': filteredCategory[0].c_id,
                                            'cat_id': filteredCategory[0].cat_id,
                                            'cat_name': filteredCategory[0].cat_name,
                                            'd_id': filteredCategory[0].d_id,
                                            'div_id': filteredCategory[0].div_id,
                                            'le_id': filteredCategory[0].le_id,
                                            'div_name': filteredDivision[0].div_name
                                        })
                                        setCategory(temp)
                                        setUnit(filteredUnit)

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}
                                >
                                    {field && field.business && field.business.length && field.business.map((item, i) => {
                                        return (
                                            <Option key={item.bg_id}>
                                                {item.bg_name}
                                            </Option>
                                        );
                                    })}


                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldTypeClick == 'LegalEntity' ? 'blue' : 'black' }}><b>Legal Entity</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    value={filterDataNames.legalentity ? filterDataNames.legalentity : undefined}
                                    placeholder="Enter Legal Entity"
                                    onClick={() => {
                                        setFieldTypeClick('LegalEntity')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                    }}
                                    onChange={(data, values) => {
                                        setType('')
                                        setErrorField('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        // let filteredLegalEntity = _.filter(commonData && commonData.le_did_infos, { le_id: data })
                                        let filteredDivision = _.filter(commonData && commonData.div_infos, { le_id: data })
                                        let filteredCategory = _.filter(commonData && commonData.cat_info, { le_id: data })
                                        let filteredLegalEntity = _.filter(commonData && commonData.le_did_infos, { le_id: data })
                                        setLegalEntityArray(filteredLegalEntity)
                                        // setfield({
                                        //     ...field,
                                        //     legalEntity: filteredLegalEntity
                                        // })
                                        if (filteredDivision && filteredDivision.length > 0) {
                                            let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
                                            setUnit(filteredUnit)
                                        }
                                        else {
                                            let filteredUnit = _.filter(commonData && commonData.chart_units, { le_id: data })
                                            console.log(filteredUnit, 'filteredUnit555');
                                            setUnit(filteredUnit)
                                        }

                                        console.log(filteredDivision, 'filteredDivisionLeg');
                                        setFilterData({
                                            ...filterData,
                                            legalentity: data,
                                            leIds: data
                                        })
                                        setFieldType('LegalEntity')
                                        setGo(false)
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            legalentity: values.children,
                                            division: '',
                                            unit: '',
                                            category: '',
                                            unit: ''
                                        })
                                        let uniqueDiv = []
                                        if (filteredDivision && filteredDivision.length > 0) {
                                            uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                                                return e.div_id;
                                            });
                                        }
                                        setDivision(uniqueDiv)
                                        let temp = []
                                        temp.push({
                                            'bg_id': filteredCategory[0].bg_id,
                                            'c_id': filteredCategory[0].c_id,
                                            'cat_id': filteredCategory[0].cat_id,
                                            'cat_name': filteredCategory[0].cat_name,
                                            'd_id': filteredCategory[0].d_id,
                                            'div_id': filteredCategory[0].div_id,
                                            'le_id': filteredCategory[0].le_id,
                                            'div_name': filteredDivision[0].div_name
                                        })
                                        setCategory(temp)
                                        // setUnit(filteredUnit)
                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >

                                    {field && field.legalEntity && field.legalEntity.length && field.legalEntity.map((item, i) => {
                                        return (
                                            <Option key={item.le_id}>
                                                {item.le_name}
                                            </Option>
                                        );
                                    })}

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldTypeClick == 'Division' ? 'blue' : 'black' }}><b>Division</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Division"
                                    value={filterDataNames.division ? filterDataNames.division : undefined}
                                    onClick={() => {
                                        setFieldTypeClick('Division')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                    }}
                                    onChange={(data, values) => {
                                        setType('')
                                        setErrorField('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        let filteredDivision = _.find(commonData && commonData.div_infos, { div_id: Number(data) })
                                        let getLEname = _.find(commonData && commonData.le_did_infos, { le_id: filteredDivision.le_id })
                                        let filteredCategory = _.filter(commonData && commonData.cat_info, { div_id: Number(data) })
                                        let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(data) })
                                        setFilterData({
                                            ...filterData,
                                            division: data
                                        })

                                        // let subData = _.filter(commonData && commonData.cat_info, { div_id: Number(data) })
                                        console.log(filteredDivision, 'filteredDivision');
                                        // setDivisionObj(filteredDivision)
                                        // setLEObj(getLEname)
                                        // setCategory(subData)
                                        setFieldType('Division')
                                        setGo(false)
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            division: values.children,
                                            unit: '',
                                            category: '',
                                        })
                                        setUnit(filteredUnit)
                                        let temp = []
                                        temp.push({
                                            'bg_id': filteredCategory[0].bg_id,
                                            'c_id': filteredCategory[0].c_id,
                                            'cat_id': filteredCategory[0].cat_id,
                                            'cat_name': filteredCategory[0].cat_name,
                                            'd_id': filteredCategory[0].d_id,
                                            'div_id': filteredCategory[0].div_id,
                                            'le_id': filteredCategory[0].le_id,
                                            'div_name': filteredDivision.div_name
                                        })
                                        setCategory(temp)

                                        console.log(category, 'catStateeeee');

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >

                                    {divisionListData && divisionListData.length > 0 &&
                                        divisionListData.map((finalList_unitlist) => {
                                            if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist.options.length > 0) {
                                                return (
                                                    <OptGroup label={finalList_unitlist.label}>
                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                            <Option value={subUnitData.div_id}>{subUnitData.div_name}</Option>

                                                        ))}
                                                    </OptGroup>
                                                )
                                            }
                                            else {
                                                return null
                                            }

                                        }
                                        )}


                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldTypeClick == 'Category' ? 'blue' : 'black' }}><b>Category</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Category"
                                    value={filterDataNames.category ? filterDataNames.category : undefined}
                                    onClick={() => {
                                        setFieldTypeClick('Category')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                    }}
                                    onChange={(data, values) => {
                                        setType('')
                                        setErrorField('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        setFilterData({
                                            ...filterData,
                                            category: data,
                                            unit: '',
                                        })
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            category: values.children
                                        })
                                        let subData = _.filter(commonData && commonData.chart_units, { category_id: Number(data) })
                                        setUnit(subData)
                                        setFieldType('Category')
                                        setGo(false)

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >
                                    {categoryListData && categoryListData.length > 0 &&
                                        categoryListData.map((finalList_unitlist) => {
                                            console.log(finalList_unitlist, 'finalList_unitlist');
                                            if (finalList_unitlist.options && finalList_unitlist.options.length > 0) {
                                                return (
                                                    <OptGroup label={finalList_unitlist.label}>
                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                            <Option value={subUnitData.cat_id}>{`${subUnitData.div_name}-${subUnitData.cat_name}`}</Option>

                                                        ))}
                                                    </OptGroup>
                                                )
                                            }
                                            else {
                                                return null
                                            }
                                        }

                                        )}



                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                {localStorage.getItem('isVendor') == "true" ?
                                    <label style={{ color: fieldTypeClick == 'Unit' ? 'blue' : 'black' }}><b>Vendor</b></label> :
                                    <label style={{ color: fieldTypeClick == 'Unit' ? 'blue' : 'black' }}><b>Unit</b></label>
                                }
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder={localStorage.getItem('isVendor') == "true" ? "Enter Vendor" : "Enter Unit"}
                                    value={filterDataNames.unit ? filterDataNames.unit : undefined}
                                    onClick={() => {
                                        setFieldTypeClick('Unit')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                    }}
                                    onChange={(data, values) => {
                                        setType('')
                                        setErrorField('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        setFilterData({
                                            ...filterData,
                                            unit: data
                                        })
                                        setFieldType('Unit')
                                        setGo(false)
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            unit: values.children
                                        })

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >
                                    {unitListData && unitListData.length > 0 &&
                                        unitListData.map((finalList_unitlist) => {
                                            if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist.options.length > 0) {
                                                return (
                                                    <OptGroup label={finalList_unitlist.label}>
                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                            <Option value={subUnitData.u_id}>{subUnitData.u_name}</Option>

                                                        ))}
                                                    </OptGroup>
                                                )
                                            }
                                            else {
                                                return null
                                            }
                                        }

                                        )}



                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className={'popupbtncolour text-center ' + localStorage.getItem('currentTheme')}>
                        <Button shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ marginLeft: '15%', marginTop: '10%' }}
                            onClick={filterChange} icon={<ArrowRightOutlined />}
                            size='default' >
                            Go
                        </Button>
                    </div>
                    {/* <Button type="primary" className='addbutton' style={{ background: "#0096FF", marginLeft: '25%', marginTop: '10%' }}
                        onClick={() => {
                            const payload = [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request":
                                        ["GetTskDistributionStatusChart",
                                            {
                                                "c_ids": [
                                                    commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                                ],
                                                "d_ids": [
                                                    commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                                                ],
                                                "filter_type": "Group",
                                                "filter_ids": [
                                                    1
                                                ],
                                                "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [
                                                    commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_id
                                                ],
                                                "country_domains": [
                                                    {
                                                        "d_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id,
                                                        "c_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].c_id,
                                                        "month_from": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_from,
                                                        "month_to": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_to
                                                    }
                                                ],
                                                "chart_year": year
                                            }
                                        ]
                                }
                            ]
                            getCriticalitychart({
                                payload: payload,
                                paramid: paramid
                            })
                            setOpen(false)

                            setFieldType("")
                            setFilterData({
                                ...filterData,
                                country: '',
                                domain: '',
                                legalentity: '',
                                division: '',
                                unit: '',
                                business: '',
                                category: '',
                                unit: ''
                            })
                        }}
                        size='default' >
                        Reset
                    </Button> */}
                </Drawer>

                <Modal open={historymodal}
                    title="Remarks History"
                    className={" add-service-prv " + localStorage.getItem('currentTheme')}
                    footer={null}
                    onCancel={historycancel}
                    maskClosable={false}
                >
                    {/* <Table className={`stats ${localStorage.getItem("currentTheme")}`}
                  columns={history_columns}
                  dataSource={historydata && historydata.remarks_history}
                  pagination={false}
                /> */}
                    <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper'}>
                        <div className='ant-table ant-table-bordered'>
                            <div className='ant-table-container'>
                                <table>
                                    <colgroup>
                                        <col style={{ width: '10px' }}></col>
                                    </colgroup>
                                    <thead className="ant-table-thead " >
                                        <tr>
                                            <th className={'ant-table-cell'} style={{ width: '5px' }}>#</th>
                                            <th className={'ant-table-cell'} style={{ width: '10px' }}>Activity Date</th>
                                            <th className={'ant-table-cell'} style={{ width: '30px' }}>User Type</th>
                                            <th className={'ant-table-cell'} style={{ width: '200px' }}>Reviewer Report</th>
                                            <th className={'ant-table-cell'} style={{ width: '30px' }}>Remarks</th>
                                        </tr>
                                    </thead>
                                    <tbody className='ant-table-tbody'>
                                        {remarksHistoryData && remarksHistoryData.remarks_history && remarksHistoryData.remarks_history.length > 0 && remarksHistoryData.remarks_history.map((item, i) => {
                                            console.log(item, 'itemitemitem');
                                            let splittedDocs;
                                            if (item.documents != null) {
                                                let doc = item.documents;
                                                splittedDocs = doc.split('||')
                                            }
                                            console.log(splittedDocs, 'splittedDocs');
                                            return <tr>
                                                <td className='ant-table-cell'>
                                                    <center>
                                                        {i + 1}
                                                    </center>
                                                </td>
                                                <td><center>{item.activity_on}</center></td>
                                                <td><center>{((item.user_category_id == 5 || item.user_category_id == 6) ? 'Assignee' : ((item.user_category_id == 3 || item.user_category_id == 4) && (item.action == 'Rectified' || item.action == 'Concurrence Rejected' || item.action == 'Concurred')) ? 'Concurrer' : (item.user_category_id == 1 && (item.action == 'RectifyApproval' || item.action == 'Approval Rejected' || item.action == 'Approved') ||
                                                    (item.user_category_id == 3 || item.user_category_id == 4) && (item.action == 'Approval Rejected' || item.action == 'RectifyApproval' || item.action == 'Approved') ? 'Approver' : ''))}</center></td>
                                                <td>{splittedDocs && splittedDocs.length > 0 ?
                                                    splittedDocs.map((items, l) => {
                                                        return <tr><a href="javascript:;" style={{ wordBreak: 'break-all' }} onClick={() => {
                                                            const remarkapi = [
                                                                authtoken,
                                                                {
                                                                    "session_token": authtoken,
                                                                    "request": [
                                                                        "DownloadFile",

                                                                        {
                                                                            remarksModalData,
                                                                            "file_name": items
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                            console.log(remarkapi, "trueeee123");
                                                            if (entityid != "null" && entityid !== null) {
                                                                console.log("trueeee123");
                                                                getdownloadpath({
                                                                    payload: remarkapi,
                                                                    paramid: paramid,
                                                                })
                                                            }
                                                        }}>{items}</a></tr>
                                                    }) : ''}</td>
                                                <td><center>{item.remarks}</center></td>


                                            </tr>
                                        })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Modal>


            </div>
        </div >

    )


}

export default connect(mapStateToProps, {
    getCommonData,
    getComparativechart,
    getTimeZoneData,
    getComparativeTableData,
    getComplianceTrendchart,
    getRemarksHistoryData,
    getdownloadpath,
    getComplianceTrendShowMoreTableData,
    getComplianceTrendTableData
})(ComparativeTrend);
