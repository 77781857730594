import React from 'react'
import moment from 'moment';
import { Collapse, Select, Input, DatePicker, Card, Pagination, Popover, Spin, Popconfirm, Drawer, Button, Table, Tooltip, Modal, Descriptions, Empty, Space } from 'antd';

import DataTable from "react-data-table-component";
import { Fragment, useState, useEffect, useRef, useLayoutEffect } from 'react';
import { ArrowRightOutlined, CheckOutlined, CloseOutlined, ArrowLeftOutlined, FilterOutlined, UpOutlined, DownOutlined, FullscreenOutlined, CloseCircleOutlined, SearchOutlined, FilterFilled, PlayCircleOutlined } from '@ant-design/icons';
import ScrollToTop from 'react-scroll-to-top';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation, Link, useHistory } from 'react-router-dom';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { getCommonData, getTaskDistributionchart, getTaskDistributionTable, getTimeZoneData, getTaskDistributionShowMoreTableData } from '../../Store/Action/Dashboard/TaskDistribution';
import { toast } from 'react-toastify';
import url, { getUrl } from './../../Libs/URL'
import { HomeRedirect, CountrySplit } from './../../Libs/country'
import ScrollButton from '../Scrollbar';
const homepage = HomeRedirect()
const uniq_country_data = CountrySplit()
const urls = getUrl()

// import "core-js/stable";
// import "regenerator-runtime/runtime";

const mapStateToProps = (state) => ({
    data: state.TaskDistributionCharts.taskDistribution.chartData,
    time: state.TaskDistributionCharts.taskDistribution.time,
    commonData: state.TaskDistributionCharts.taskDistribution.Data,
    tableData: state.TaskDistributionCharts.taskDistribution.tableData,
    timeZoneData: state.TaskDistributionCharts.taskDistribution.timeZoneData,
    showMoreData: state.TaskDistributionCharts.taskDistribution.showMoreData

})

const TaskDistribution = ({
    getFilterValue,
    getCommonData,
    getTaskDistributionchart, getTaskDistributionTable,
    data,
    commonData,
    time,
    tableData,
    getTimeZoneData,
    timeZoneData,
    getTaskDistributionShowMoreTableData,
    showMoreData
}) => {
    console.log(tableData, 'tableDatatableData');
    console.log(timeZoneData, 'timeZoneData');
    const _ = require("lodash");
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [taskDistributionTable, setTaskDistributionTable] = useState([])
    const [newCountryDomain, setNewCountryDomain] = useState([])
    const [customTaskDisTable, setCustomTaskDis] = useState([])
    const [searchedValue, setSearchedValue] = useState({
        statutory: '',
        criticality: '',
        compliance_task: '',
        descp: '',
        p_cons: '',
        comp_frequency: '',
        repeats: '',
        trigger: ''
    })
    const myRef1 = useRef(null);
    const myRef2 = useRef(null);
    const myRef3 = useRef(null);
    const myRef4 = useRef(null);
    const myRef5 = useRef(null);
    const myRef6 = useRef(null);
    const myRef7 = useRef(null);
    const myRef8 = useRef(null);
    const [sortingpurpose, setSortingpurpose] = useState([])
    const [filterData, setFilterData] = useState({
        country: '',
        domain: '',
        legalentity: '',
        domainIds: '',
        division: '',
        unit: '',
        business: '',
        category: '',
        unit: '',
        countryArr: [],
        leIds: ''
    })
    const searchInput = useRef(null);
    const [searchText, setSearchText] = useState({
        data: '',
        index: 0
    });
    const [searchedColumn, setSearchedColumn] = useState('');
    const { Panel } = Collapse;
    const [chartId, setChartId] = useState()
    const [field, setfield] = useState({
        country: [],
        domain: [],
        legalEntity: [],
        business: [],

    })
    var [count, setCount] = useState(1)
    const [open, setOpen] = useState(false)
    const [showmoreCount, setShowMoreCount] = useState(0)
    const [table, setTable] = useState(false)
    const [division, setDivision] = useState([])
    const [category, setCategory] = useState([])
    const [fieldType, setFieldType] = useState("")
    const [unit, setUnit] = useState([])
    const chart = useRef(null);
    const { Option } = Select;
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const dispatch = useDispatch();
    const [year, setYear] = useState(moment().year())
    const [timeZone, setTimeZone] = useState("Afica/Accara")
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const sessionvalue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionvalue)
    const [entityData, setEntityData] = useState([])
    const TimeZoneValue = sessionParsedValue.login_response
    const sessionArr = sessionParsedValue.entity_info
    console.log(commonData, 'commonData');
    const location = useLocation();
    const [paramid, setParamId] = useState("")
    const [generatedOn, setGeneratedOn] = useState(false)
    const [go, setGo] = useState(false)
    const [filterDataNames, setFilterDataNames] = useState({
        country: '',
        domain: '',
        legalentity: '',
        division: '',
        unit: '',
        business: '',
        category: '',
        unit: ''
    })
    const country_info = sessionParsedValue.country_info
    const [criticalityValue, setCriticalityValue] = useState()
    const [domainName, setDomainName] = useState()
    const [countValue, setCountValue] = useState(0)
    const [dataCount, setDataCount] = useState()
    console.log(dataCount, 'dataCount');
    console.log(showmoreCount, 'showmoreCount');
    const { OptGroup } = Select;
    const [domainListData, setDomainListData] = useState([])
    const [divisionListData, setDivisionListData] = useState([])
    const [categoryListData, setCategoryListData] = useState([])
    const [unitListData, setUnitListData] = useState([])
    const [bgIds, setBgIds] = useState([])
    const [groupCheckBox, setGroupCheckBox] = useState(true)
    const [legalEntityArray, setLegalEntityArray] = useState([])
    const [checkBox, setCheckBox] = useState(true)
    const [textArray, setTextArray] = useState([])
    const [countryDomain, setCountryDomain] = useState([])
    const [dIds, setDIds] = useState([])
    const [type, setType] = useState('')
    const [errorField, setErrorField] = useState('')
    const [fieldTypeClick, setFieldTypeClick] = useState("")
    const [countryData, setCountryData] = useState([])
    const [domainData, setDomainData] = useState([])
    const sidefilterData = localStorage.getItem('filterChange')
    const parsedSideFilterData = JSON.parse(sidefilterData)
    const sideFilterState = localStorage.getItem('filterState')
    const [cIds, setCIds] = useState([])
    const [filteredCountries, setFilteredCountries] = useState([])
    const isCountryValueTrue = localStorage.getItem('countryState')
    const [newCustomisedValueArray, setNewCustomisedValueArray] = useState([])
    console.log(newCustomisedValueArray, 'newCustomisedValueArray')
    const [newCustomisedKeyArray, setNewCustomisedKeyArray] = useState([])
    const [searchingValue, setSearchingValue] = useState([])
    const historynew = useHistory()


    const setFocus = (ref) => {
        setTimeout(() => {
            ref.current.focus();
        });
    };

    console.log(parsedSideFilterData, 'parsedSideFilterData');
    console.log(sideFilterState, 'sideFilterState');


    useEffect(() => {
        var obj = {}
        countryData.forEach(function (a) {
            var arr2 = []
            domainData.forEach(function (b) {
                var key = a.c_name
                if (a.c_id == b.c_id) {
                    arr2.push(b.d_name)
                    obj[key] = arr2.join()
                }
            });
        });
        let temp = []
        for (let m in obj) {
            console.log(obj[m], 'objmm');
            // let sm = `${m} - ${obj[m]}`
            let expectedCountryDomain = `[bold]${m}[/] - ${obj[m]}`
            temp.push(expectedCountryDomain)
        }
        let newCountryDomain = temp.join("[bold] | [/]")
        setTextArray(newCountryDomain)

    }, [countryData, domainData])



    useEffect(() => {
        let newCountry = []
        let newDomain = []

        for (let j in filterData.country) {
            let filterCountry = _.filter(commonData.countries, { c_id: Number(filterData.country[j]) })
            // temp2.push(filterDomain)
            for (let i in filterCountry) {
                newCountry.push(filterCountry[i])
            }

        }
        // for (let k in filterData.domain) {
        //     let filterDomain = _.filter(commonData.d_info, { d_id: Number(filterData.domain[k]) })
        //     // temp2.push(filterDomain)
        //     for (let l in filterDomain) {
        //         newDomain.push(filterDomain[l])
        //     }

        // }
        const uniqueCountry = _.uniqBy(newCountry && newCountry, function (e) {
            return e.c_id;
        });
        setCountryData(uniqueCountry)
        // setDomainData(newDomain)
        console.log(newCountry, 'newCountry');
        console.log(newDomain, 'newDomain');
        console.log(filterData.country, 'filterData.country');
        console.log(filterData.domain, 'filterData.domain');

    }, [filterData.country, filterData.domainIds])

    useEffect(() => {
        let newDom = []
        for (let i in commonData.d_info) {
            newDom.push({
                "d_id": commonData.d_info[i].d_id,
                "d_name": commonData.d_info[i].d_name,
                "is_active": commonData.d_info[i].is_active,
                "c_id": commonData.d_info[i].c_id,
                "month_from": commonData.d_info[i].month_from,
                "month_to": commonData.d_info[i].month_to,
                "cd_id": `${commonData.d_info[i].c_id}-${commonData.d_info[i].d_id}`
            })
        }

        let newDomain = []
        if (newDom && newDom.length > 0) {
            for (let k in filterData.domain) {
                console.log(filterData.domain[k], 'sssssss');
                let filterDomain = _.filter(newDom, { cd_id: `${filterData.domain[k]}` })
                console.log(filterDomain, 'filterDomain');
                // newDomain.push(filterDomain)
                for (let l in filterDomain) {
                    newDomain.push(filterDomain[l])
                }

            }
        }
        setDomainData(newDomain)

        console.log(newDomain, 'newDomain');
        console.log(newDom, 'newDom');
    }, [commonData.d_info, filterData.domain])

    useEffect(() => {
        if (commonData && commonData.d_info && commonData.d_info.length > 0) {
            let tempArr = []
            let tempIds = []
            let textArr = []
            let cNames = []
            let uniqueCountry = []
            let countryName;
            // setDomainData(commonData.d_info)
            for (let i in commonData.d_info) {
                tempIds.push(commonData.d_info[i].d_id)
                tempArr.push({
                    'd_id': commonData.d_info[i].d_id,
                    'c_id': commonData.d_info[i].c_id,
                    "month_from": commonData.d_info[i].month_from,
                    "month_to": commonData.d_info[i].month_to
                })
                uniqueCountry = _.uniqBy(commonData && commonData.countries, function (e) {
                    return e.c_id;
                });
                setCountryData(uniqueCountry)
                let id = uniqueCountry && uniqueCountry.length > 0 && uniqueCountry.map((item, i) => {
                    return item.c_id
                })
                setCIds(id)
                console.log(uniqueCountry, 'uniqueCountry');
                countryName = _.find(uniqueCountry, { c_id: Number(commonData.d_info[i].c_id) })

                cNames.push(commonData.d_info[i].d_name)

            }
            // var arr = []
            var obj = {}
            uniqueCountry.forEach(function (a) {
                var arr2 = []
                commonData.d_info.forEach(function (b) {
                    var key = a.c_name
                    if (a.c_id == b.c_id) {
                        arr2.push(b.d_name)
                        obj[key] = arr2.join()
                    }
                });
            });
            let temp = []
            for (let m in obj) {
                console.log(obj[m], 'objmm');
                // let sm = `${m} - ${obj[m]}`
                let expectedCountryDomain = `[bold]${m}[/] - ${obj[m]}`
                temp.push(expectedCountryDomain)
            }
            let newCountryDomain = temp.join("[bold] | [/]")
            // setTextArray(newCountryDomain)
            setDIds(tempIds)
            setCountryDomain(tempArr)
        }


    }, [commonData])

    // useEffect(() => {
    //     if (taskDistributionTable && taskDistributionTable.length > 0) {
    //         setCustomTaskDis(taskDistributionTable)
    //     }
    // }, [taskDistributionTable])

    useEffect(() => {
        if (fieldTypeClick === 'BusinessGroup') {
            if (filterDataNames.business === '') {
                setErrorField('Business Group Required')
            }
        }

        if (fieldTypeClick === 'LegalEntity') {
            if (filterDataNames.legalentity === '') {
                setErrorField('Legal Entity Required')
            }
        }
        if (fieldTypeClick === 'Division') {
            if (filterDataNames.division === '') {
                setErrorField('Division Required')
            }
        }
        if (fieldTypeClick === 'Category') {
            if (filterDataNames.category === '') {
                setErrorField('Category Required')
            }
        }
        if (fieldTypeClick === 'Unit') {
            if (filterDataNames.unit === '') {
                setErrorField('Unit Required')
            }
        }

    }, [fieldTypeClick])

    useEffect(() => {
        if (fieldTypeClick === 'Country') {
            if (filterData.country.length === 0) {
                setErrorField('Country Required')
            }
            else {
                setErrorField('')

            }
        }
        if (fieldTypeClick === 'Domain') {
            if (filterData.domain.length === 0) {
                setErrorField('Domain Required')
            }
            else {
                setErrorField('')

            }
        }

    }, [fieldTypeClick, filterData])

    console.log(filterData, 'filterData');



    useEffect(() => {
        {

            var the_arr = location.pathname.split('/');

            the_arr.pop();

            let a = the_arr.join('/')

            setParamId(a)

        }

    }, []);
    console.log(generatedOn, 'generatedOn');
    console.log(timeZone, 'timeZone');

    useEffect(() => {
        if (tableData) {
            setTaskDistributionTable(tableData.r_drill_down_data)
        }

    }, [tableData])
    console.log(tableData, 'taskDistributionTable');


    console.log(field, 'fieldfield');
    console.log(commonData, ' commonDatacommonDatacommonData');

    useEffect(() => {
        if (commonData && commonData.d_info && commonData.d_info.length > 0) {
            let domainNameArray = commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info.map((item, i) => {
                return item.d_name
            })
            setDomainName(domainNameArray)
            console.log(domainName, 'domainName');

        }

    }, [commonData])

    useEffect(() => {
        // if (localStorage.getItem('SelectedEntity') == "All Legal Entity") {
        let id = sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {

            return item.le_id
        })
        setEntityData(id)

        // }
    }, [])



    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetChartFilters",
                        {
                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                        }
                    ]
                }
            ]
            if (paramid != "") {
                getCommonData({
                    payload: payload,
                    paramid: paramid
                })
            }


        }
    }, [isAuth, authtoken, paramid, entityid])

    useEffect(() => {
        if (JSON.parse(sideFilterState) === false && JSON.parse(isCountryValueTrue) === false) {
            if ((entityData && entityData.length > 0) && (commonData) && (dIds && dIds.length > 0) && (countryDomain && countryDomain.length > 0)) {
                console.log('sneha');
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request":
                            ["GetTskDistributionStatusChart",
                                {
                                    "c_ids": [
                                        commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                    ],
                                    "d_ids": dIds,
                                    "filter_type": "Group",
                                    "filter_ids": [
                                        1
                                    ],
                                    "le_ids": localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? entityData : [entityid],
                                    "country_domains": countryDomain,
                                    "chart_year": year
                                }
                            ]
                    }
                ]
                if (paramid != "") {
                    getTaskDistributionchart({
                        payload: payload,
                        paramid: paramid
                    })
                }
            }
        }

    }, [localStorage.getItem('filterState'), isAuth, entityData, year, commonData, dIds, countryDomain, year, localStorage.getItem('countryState')])

    useEffect(() => {
        if (filterData.country && filterData.country.length === 0) {
            localStorage.setItem('countryState', true)
        }
        else {
            localStorage.setItem('countryState', false)
        }
    }, [filterData.country])




    // useEffect(() => {
    //     if (localStorage.getItem('SelectedEntity') != '	All Legal Entity') {
    //         if ((commonData) && (dIds && dIds.length > 0) && (countryDomain && countryDomain.length > 0)) {
    //            console.log('aishuuu');
    //             const payload = [
    //                 authtoken,
    //                 {
    //                     "session_token": authtoken,
    //                     "request":
    //                         ["GetTskDistributionStatusChart",
    //                             {
    //                                 "c_ids": [
    //                                     commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
    //                                 ],
    //                                 "d_ids": dIds,
    //                                 "filter_type": "Group",
    //                                 "filter_ids": [
    //                                     1
    //                                 ],
    //                                 "le_ids": [entityid],
    //                                 "country_domains": countryDomain,
    //                                 "chart_year": year
    //                             }
    //                         ]
    //                 }
    //             ]
    //             if (paramid != "") {
    //                 getTaskDistributionchart({
    //                     payload: payload,
    //                     paramid: paramid
    //                 })
    //             }
    //         }
    //     }

    // }, [localStorage.getItem('SelectedEntity') != '	All Legal Entity', isAuth, entityid, commonData, year, countryDomain, dIds])

    const dataSource = [
        {
            key: '1',
            name: 'Rule 18',
            task: 'Trail Task117',
            description: 'test',
            p_cons: "Shall be punishable with imprisonment for a term which shall not be less than six months but which may extend to three years and with fine which shall not be less than fifty thousand rupees but which may extend to two lakh rupees - Section 63",
            address: '10 Downing Street',
            frequency: 'periodical',
            task_status: 'opted',
            trigger_before_days: 10,
            task: 'Trail Task119',
            summary: "Repeats every 1 Month(s) "
        },
        {
            key: '2',
            name: 'Rule 3',
            description: 'Online application can be submitted for one musical license covering all branches of pan India by paying prescribed fees. Music license is applicable for music played for public in TV, radio, live performances, DJ by internet or even copied in CD, DVD or pen drives/hard disc',
            address: '10 Downing Street',
            frequency: 'Review',
            task_status: 'Not opted',
            task: 'Trail Task',
            summary: "Repeats every 6 Month(s) ",
            trigger_before_days: 20,
            p_cons: "Section 23 - Imprisonment up to 3 months or with fine up to Rs.1000/- or with both. If the contravention is continued fine may extend to Rs.100/- for each day",
        },
        {
            key: '3',
            name: 'Rule 12',
            description: 'test',
            frequency: 'OnOccurence',
            address: '10 Downing Street',
            p_cons: "test",
            task: 'Trail Task55',
            task_status: 'opted',
            trigger_before_days: 25,
            summary: "Repeats every 1 Month(s) "
        },
    ];
    useEffect(() => {
        if (taskDistributionTable && taskDistributionTable.length > 0) {
            let count = []
            for (let i in taskDistributionTable) {
                for (let j in taskDistributionTable[i].ap_compliances) {
                    let a = taskDistributionTable[i].ap_compliances[j]
                    let data = Object.values(a)


                    count.push(data.length)

                    // count.push(data[i])
                    console.log(data, ' taskDistributionTable[i].ap_compliances');

                }
            }
            console.log(count, 'count');
            let countValues = count.reduce(function (accumulator, curValue) {
                return accumulator + curValue
            }, 0)
            setShowMoreCount(countValues)
            console.log(countValues, 'countValue9999');
        }

    }, [taskDistributionTable])
    console.log(showmoreCount, 'showmoreCount');
    useEffect(() => {
        if (showMoreData && showMoreData.r_drill_down_data && showMoreData.r_drill_down_data.length > 0) {
            let temp = []
            let temp2 = []
            console.log(showMoreData.r_drill_down_data, 'showMoreData.r_drill_down_data');
            for (let i in taskDistributionTable) {
                for (let j in showMoreData.r_drill_down_data) {
                    if (taskDistributionTable[i].level1_statutory_name == showMoreData.r_drill_down_data[j].level1_statutory_name) {
                        for (let s in taskDistributionTable[i].ap_compliances) {
                            for (let a in showMoreData.r_drill_down_data[j].ap_compliances) {
                                console.log(s, 'taskDistributionTable[i].ap_compliances[s]');
                                console.log(showMoreData.r_drill_down_data[j].ap_compliances[a], 'showMoreData.r_drill_down_data[j].ap_compliances[a]');
                                if (s == a) {
                                    let arr1 = taskDistributionTable[i].ap_compliances[s]
                                    let arr2 = showMoreData.r_drill_down_data[j].ap_compliances[a]
                                    let arr3 = arr1.concat(arr2)
                                    console.log(arr3, 'arr3');
                                    taskDistributionTable[i].ap_compliances[s] = arr3
                                    console.log(s, 'sneha');
                                }
                                else {
                                    taskDistributionTable[i].ap_compliances[a] = showMoreData.r_drill_down_data[j].ap_compliances[a]
                                }
                            }
                        }
                        temp2.push(taskDistributionTable[i].level1_statutory_name)
                    }
                }
            }
            console.log(temp2, 'temp2');
            for (let i in showMoreData.r_drill_down_data) {

                if ((temp2.includes(showMoreData.r_drill_down_data[i].level1_statutory_name)) == false) {
                    // let data = _.filter(showMoreData.r_drill_down_data, { level1_statutory_name: taskDistributionTable[j].level1_statutory_name })
                    console.log(showMoreData.r_drill_down_data[i], 'datadata');
                    temp.push(showMoreData.r_drill_down_data[i])
                }
            }
            setTaskDistributionTable([...taskDistributionTable, ...temp])
        }
    }, [showMoreData])

    // useEffect(() => {
    //     if(showMoreData && showMoreData.r_drill_down_data && showMoreData.r_drill_down_data.length > 0){
    //         for(let i in showMoreData.r_drill_down_data){
    //             for(let j in taskDistributionTable){
    //                 if(showMoreData.r_drill_down_data[i].level1_statutory_name === taskDistributionTable[j].level1_statutory_name){
    //                     const filterValue = _.find(showMoreData.r_drill_down_data,{level1_statutory_name:taskDistributionTable[j].level1_statutory_name})
    //                     console.log(filterValue,'filterValue');
    //                     let employee = {
    //                         ...taskDistributionTable[j].ap_compliances,
    //                         ...filterValue.ap_compliances
    //                     };
    //                     console.log(employee,'employee');
    //                 }
    //                 else{
    //                     setTaskDistributionTable(showMoreData.r_drill_down_data)
    //                 }

    //             }
    //         }
    //     }

    // },[showMoreData])


    console.log(showMoreData, 'showMoreData');
    console.log(taskDistributionTable, 'taskDistributionTable');

    // useEffect(() => {
    //     if (localStorage.getItem('SelectedEntity') != "All Legal Entity" && commonData) {
    //         let arrunit = [];
    //         let totalListunit = []
    //         let countrylisttempdataunit = [];
    //         let entityArrayunit = []
    //         let tempArrayunit = []
    //         commonData && commonData.d_info && commonData.d_info.length > 0 && commonData && commonData.d_info && commonData.d_info.map((subunitlist) => {
    //             console.log(subunitlist, 'subunitlist-domain');
    //             if (country_info && country_info[0].c_id == subunitlist.c_id) {
    //                 entityArrayunit = {

    //                     'c_id': subunitlist.c_id,
    //                     'd_id': subunitlist.d_id,
    //                     'd_name': subunitlist.d_name,
    //                     'is_active': subunitlist.is_active,
    //                     'month_from': subunitlist.month_from,
    //                     'month_to': subunitlist.month_to,
    //                     'label': subunitlist.d_name,
    //                     'values': subunitlist.d_id

    //                 }
    //                 tempArrayunit.push(entityArrayunit)
    //                 console.log(entityArrayunit, 'entityArrayunit');
    //             }

    //         })
    //         totalListunit = {
    //             'label': country_info && country_info && country_info[0].c_id == null ? 'Others' : country_info && country_info[0].c_name,
    //             'options': tempArrayunit
    //         }

    //         countrylisttempdataunit.push(totalListunit)
    //         setDomainListData(countrylisttempdataunit)
    //     }
    // }, [commonData, localStorage.getItem('SelectedEntity') != "All Legal Entity"])


    useEffect(() => {
        if (((filterData.domainIds) && (domainData && domainData.length > 0))) {
            let tempDom = []
            for (let i in filterData.domainIds) {
                let filteredDomain = _.find(domainData, { d_id: filterData.domainIds[i] })
                if (filteredDomain) {
                    tempDom.push({
                        "d_id": filteredDomain.d_id,
                        "c_id": filteredDomain.c_id,
                        "month_from": filteredDomain.month_from,
                        "month_to": filteredDomain.month_to
                    })
                }
                console.log(filteredDomain, 'filteredDomain');
            }
            setNewCountryDomain(tempDom)
            console.log(tempDom, 'tempDom');
        }

    }, [domainData, filterData.domainIds])



    // useEffect(() => {
    //     if (localStorage.getItem('SelectedEntity') === "All Legal Entity" && commonData) {
    //         let arrunit = [];
    //         let totalListunit = []
    //         let countrylisttempdataunit = [];
    //         let entityArrayunit = []
    //         let tempArrayunit = []
    //         commonData && commonData.d_info && commonData.d_info.length > 0 && commonData && commonData.d_info && commonData.d_info.map((subunitlist) => {
    //             console.log(subunitlist, 'subunitlist-domain');
    //             if (country_info && country_info[0].c_id == subunitlist.c_id) {
    //                 entityArrayunit = {

    //                     'c_id': subunitlist.c_id,
    //                     'd_id': subunitlist.d_id,
    //                     'd_name': subunitlist.d_name,
    //                     'is_active': subunitlist.is_active,
    //                     'month_from': subunitlist.month_from,
    //                     'month_to': subunitlist.month_to,
    //                     'label': subunitlist.d_name,
    //                     'values': subunitlist.d_id

    //                 }
    //                 tempArrayunit.push(entityArrayunit)
    //                 console.log(entityArrayunit, 'entityArrayunit');
    //             }

    //         })
    //         totalListunit = {
    //             'label': country_info && country_info && country_info[0].c_id == null ? 'Others' : country_info && country_info[0].c_name,
    //             'options': tempArrayunit
    //         }

    //         countrylisttempdataunit.push(totalListunit)
    //         setDomainListData(countrylisttempdataunit)
    //     }
    // }, [commonData, localStorage.getItem('SelectedEntity') === "All Legal Entity"])

    // // UseEffect for filters

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === 'All Legal Entity') {
            if (uniq_country_data && uniq_country_data.length > 0) {
                console.log('hii');
                console.log(uniq_country_data, 'uniq_country_data');
                setfield({
                    ...field,
                    country: uniq_country_data
                })
            }
        }
        else {
            let filteredEntity = _.filter(commonData.le_did_infos, { le_id: localStorage.getItem('SelectedEntityid') })
            console.log(filteredEntity, 'filteredEntity');
            if (filteredEntity && filteredEntity.length > 0) {
                let filteredCountry = _.filter(uniq_country_data, { c_id: filteredEntity[0].c_id })
                setfield({
                    ...field,
                    country: filteredCountry
                })
            }
        }
    }, [commonData, uniq_country_data, localStorage.getItem('SelectedEntityid'), localStorage.getItem('SelectedEntity')])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === 'All Legal Entity') {
            if (uniq_country_data && uniq_country_data.length > 0) {
                console.log('hii');
                console.log(uniq_country_data, 'uniq_country_data');
                setfield({
                    ...field,
                    country: uniq_country_data
                })
                if (JSON.parse(isCountryValueTrue) === false) {
                    let temp = []
                    let temp1 = []
                    let temp2 = []
                    let temp3 = []
                    for (let i in commonData.d_info) {
                        temp3.push(commonData.d_info[i].d_id)
                        temp.push(`${commonData.d_info[i].c_id}-${commonData.d_info[i].d_id}`)
                    }
                    for (let j in uniq_country_data) {
                        temp1.push(`${uniq_country_data[j].c_id}`)
                        temp2.push(uniq_country_data[j].c_id)
                    }
                    console.log(temp, 'temp');
                    console.log(temp3, 'temp3');
                    setFilterData({
                        ...filterData,
                        country: temp1,
                        countryArr: temp2,
                        domain: temp,
                        domainIds: temp3
                    })
                }
                else {
                    setFilterData({
                        ...filterData,
                        country: [],
                        countryArr: [],
                        domain: [],
                        domainIds: []
                    })
                }
            }
        }
        else {
            let filteredEntity = _.filter(commonData.le_did_infos, { le_id: localStorage.getItem('SelectedEntityid') })
            console.log(filteredEntity, 'filteredEntity');
            if (filteredEntity && filteredEntity.length > 0) {
                let filteredCountry = _.filter(uniq_country_data, { c_id: filteredEntity[0].c_id })

                setfield({
                    ...field,
                    country: filteredCountry
                })
                if (JSON.parse(isCountryValueTrue) === false) {
                    let temp = []
                    let temp1 = []
                    let temp2 = []
                    let temp3 = []
                    for (let i in commonData.d_info) {
                        temp3.push(commonData.d_info[i].d_id)
                        temp.push(`${commonData.d_info[i].c_id}-${commonData.d_info[i].d_id}`)
                    }
                    for (let j in filteredCountry) {
                        temp1.push(`${filteredCountry[j].c_id}`)
                        temp2.push(filteredCountry[j].c_id)
                    }
                    console.log(temp, 'temp');
                    console.log(temp3, 'temp3');
                    setFilterData({
                        ...filterData,
                        country: temp1,
                        countryArr: temp2,
                        domain: temp,
                        domainIds: temp3
                    })
                }
                else {
                    setFilterData({
                        ...filterData,
                        country: [],
                        countryArr: [],
                        domain: [],
                        domainIds: []
                    })
                }
            }
        }
    }, [commonData, uniq_country_data, localStorage.getItem('SelectedEntityid'), localStorage.getItem('SelectedEntity')])

    // useEffect(() => {
    //     if (commonData && JSON.parse(sideFilterState) === false) {
    //         let temp = []
    //         let temp1 = []
    //         let temp2 = []
    //         let temp3 = []
    //         if (JSON.parse(isCountryValueTrue) === false) {
    //             for (let i in commonData.d_info) {
    //                 temp3.push(commonData.d_info[i].d_id)
    //                 temp.push(`${commonData.d_info[i].c_id}-${commonData.d_info[i].d_id}`)
    //             }
    //             for (let j in field.country) {
    //                 temp1.push(`${field.country[j].c_id}`)
    //                 temp2.push(field.country[j].c_id)
    //             }
    //             setFilterData({
    //                 ...filterData,
    //                 country: temp1,
    //                 countryArr: temp2,
    //                 domain: temp,
    //                 domainIds: temp3
    //             })
    //         }
    //         else {
    //             setFilterData({
    //                 ...filterData,
    //                 country: [],
    //                 countryArr: [],
    //                 domain: [],
    //                 domainIds: []
    //             })
    //         }
    //     }
    // }, [commonData, field.country, localStorage.getItem('countryState'), localStorage.getItem('filterState')])

    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        filteredCountries && filteredCountries.length > 0 && filteredCountries.map(function (user) {
            // console.log(list.unitlist, 'list.unitlist');
            if ((arrunit.indexOf(user.c_id) < 0))
                arrunit.push(user.c_id);
        });

        arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []
            if (pmenu || pmenu == null) {
                commonData.d_info && commonData.d_info.length > 0 && commonData.d_info.map((subunitlist) => {

                    if (pmenu == subunitlist.c_id) {
                        entityArrayunit = {

                            'c_id': subunitlist.c_id,
                            'd_id': `${subunitlist.c_id}-${subunitlist.d_id}`,
                            'd_name': subunitlist.d_name,
                            'is_active': subunitlist.is_active,
                            'month_from': subunitlist.month_from,
                            'month_to': subunitlist.month_to,
                            'label': subunitlist.d_name,
                            'values': subunitlist.d_id

                        }
                        tempArrayunit.push(entityArrayunit)
                    }
                })
            }
            let countryName = _.find(filteredCountries, { c_id: pmenu })
            console.log(countryName, 'countryName');
            totalListunit = {
                'label': countryName.c_name == null ? 'Others' : countryName.c_name,
                'options': tempArrayunit
            }
            console.log(totalListunit, 'unitListData')
            countrylisttempdataunit.push(totalListunit)
        })
        setDomainListData(countrylisttempdataunit)


    }, [filteredCountries, commonData])

    const HandelCountryChange = (obj) => {
        console.log(obj, 'objj');
        var numberArray = obj.map(Number);
        console.log(numberArray, 'numberArray');
        let temp5 = []
        let filterDomain = []
        let filteredBg = []
        let filteredLE = []
        let leTemp = []
        let bgTemp = []
        for (let j in obj) {
            filterDomain = _.filter(commonData.d_info, { c_id: Number(obj[j]) })
            // temp2.push(filterDomain)
            for (let i in filterDomain) {
                temp5.push(filterDomain[i])
            }

            if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                filteredBg = _.filter(commonData && commonData.bg_groups, { c_id: Number(obj[j]) })
                console.log(filteredBg, 'filteredBg');
                if (filteredBg && filteredBg.length > 0) {
                    for (let b in filteredBg) {
                        bgTemp.push(filteredBg[b])
                    }
                }
                else {
                    filteredLE = _.filter(commonData && commonData.le_did_infos, { c_id: Number(obj[j]) })
                    if (filteredLE && filteredLE.length > 0) {
                        for (let l in filteredLE) {
                            leTemp.push(filteredLE[l])
                        }
                    }
                }
                // if (bgIds.includes(null) === true) {
                //     filteredBg = _.filter(commonData && commonData.bg_groups, { c_id: Number(obj[j]) })
                //     console.log(filteredBg,'filteredBg1111');
                // }
                // else {
                //     filteredBg = _.filter(commonData && commonData.bg_groups, { c_id: Number(obj[j]) })
                //     console.log(filteredBg, 'filteredBg');
                //     for (let b in filteredBg) {
                //         bgTemp.push(filteredBg[b])
                //     }
                // }
            }

            if (commonData && commonData.le_did_infos) {
                filteredLE = _.filter(commonData && commonData.le_did_infos, { c_id: Number(obj[j]) })
                if (filteredLE && filteredLE.length > 0) {
                    for (let l in filteredLE) {
                        leTemp.push(filteredLE[l])
                    }
                    // leTemp = _.uniq(leTemp)
                }
            }

        }
        let id = []
        for (let i in temp5) {
            id.push(`${temp5[i].c_id}-${temp5[i].d_id}`)
        }
        let dIds = []
        for (let i in temp5) {
            dIds.push(temp5[i].d_id)
        }
        let bgIds1 = bgTemp && bgTemp.length > 0 && bgTemp.map((item, i) => {
            return item.bg_id
        })
        let leIds = leTemp && leTemp.length > 0 && leTemp.map((item, i) => {
            return item.le_id
        })
        let uniqueLe = _.uniq(leIds)
        console.log(uniqueLe, 'uniqueLe');
        console.log(leTemp, 'leTemp');
        console.log(bgTemp, 'bggg');
        console.log(bgIds, 'bgIds');
        setFieldTypeClick('Country')
        setType('')
        setFilterData({
            ...filterData,
            country: obj,
            countryArr: numberArray,
            domain: id,
            business: `${bgIds1}`,
            legalentity: leIds ? uniqueLe : [],
            domainIds: dIds
        })
        setFieldType('')
        setFilterDataNames({
            ...filterDataNames,
            country: obj,
            legalentity: '',
            division: '',
            unit: '',
            business: '',
            category: '',
            unit: ''
        })
    }

    const HandelChange = (obj) => {
        console.log(obj, 'objj');
        let tempObj = []
        if (obj && obj.length) {
            for (let i in obj) {
                let splitData = obj[i].split("-")
                console.log(splitData[1], 'splitData');
                // tempObj = [...tempObj,...splitData[1]]
                tempObj.push(Number(splitData[1]))
            }
        }
        console.log(tempObj, 'tempObj');
        setFieldTypeClick('Domain')
        setType('')
        setFilterData({
            ...filterData,
            domain: obj,
            domainIds: tempObj
        })
        setFieldType('')
        setFilterDataNames({
            ...filterDataNames,
            domain: obj,
            legalentity: '',
            division: '',
            unit: '',
            business: '',
            category: '',
            unit: ''
        })
        let temp = []
        let bg = []
        let tempDiv = []
        let tempCat = []
        let tempUnit = []
        console.log(tempCat, 'tempCat');
        console.log(bg, 'Aishuuuu');
        console.log(temp, 'SnehaaaaaaaaAsss');

    }

    useEffect(() => {
        if (commonData) {
            if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                const uniqueIds = commonData.le_did_infos && commonData.le_did_infos.map((item) => {
                    return item.bg_id
                })
                setBgIds(uniqueIds)
            }
        }

    }, [commonData])

    //Country onChange

    console.log(filterData.country, 'counnnn');

    useEffect(() => {
        if (filterData.country) {
            let tempCountries = []
            let bgs = []
            let tempBgs = []
            let tempLe = []
            let filteredLe = []
            for (let i in filterData.country) {
                let filteredCountries = _.filter(uniq_country_data, { c_id: Number(filterData.country[i]) })
                console.log(filteredCountries, 'filteredCountries');
                tempCountries.push(...filteredCountries)
                if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                    let filteredBg = _.filter(commonData.bg_groups, { c_id: Number(filterData.country[i]) })
                    bgs.push(...filteredBg)
                }
                if (bgs && bgs.length > 0) {
                    tempBgs = _.uniqBy(bgs, function (e) {
                        return e.bg_id;
                    });
                }
                if (tempBgs && tempBgs.length > 0) {
                    if (bgIds.includes(null)) {
                        filteredLe = commonData.le_did_infos.filter(function (currentElement) {
                            return currentElement.bg_id === null || currentElement.bg_id === Number(tempBgs[0].bg_id);
                        });
                    }
                    else {
                        filteredLe = _.filter(commonData.le_did_infos, { bg_id: Number(tempBgs[0].bg_id) })
                    }
                }
                else if (commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0) {
                    tempLe = _.filter(commonData.le_did_infos, { c_id: Number(filterData.country[i]) })
                    filteredLe.push(...tempLe)
                }

            }

            setFilteredCountries(tempCountries)
            console.log(tempCountries, 'tempCountries');
            console.log(tempBgs, 'tempBgs');
            setfield({
                ...field,
                business: tempBgs,
                legalEntity: filteredLe
            })
            // setLegalEntityArray(filteredEntity)
        }

    }, [filterData.country, uniq_country_data])

    //Domain onChange

    useEffect(() => {
        if (fieldTypeClick === 'Domain') {
            if (filterData.domainIds) {
                let tempBgs = []
                let tempLe = []
                let filteredLe = []
                for (let i in filterData.domainIds) {
                    if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                        let filteredBg = _.filter(commonData.bg_groups, { d_id: Number(filterData.domainIds[i]) })
                        tempBgs.push(...filteredBg)
                    }
                    console.log(tempBgs, 'tempBgs');
                    if (tempBgs && tempBgs.length > 0) {
                        if (bgIds.includes(null)) {
                            filteredLe = commonData.le_did_infos.filter(function (currentElement) {
                                return currentElement.bg_id === null || currentElement.bg_id === Number(tempBgs[0].bg_id);
                            });
                        }
                        else {
                            filteredLe = _.filter(commonData.le_did_infos, { bg_id: Number(tempBgs[0].bg_id) })
                        }
                    }
                    else if (commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0) {
                        filteredLe = commonData && commonData.le_did_infos.filter(item => item.d_ids.includes(Number(filterData.domainIds[i])))
                    }
                }
                console.log(filteredLe, 'filteredLe');
                setfield({
                    ...field,
                    business: tempBgs,
                    legalEntity: filteredLe
                })
            }
        }

    }, [filterData.domainIds, fieldTypeClick])


    useEffect(() => {
        if (field && field.legalEntity && field.legalEntity.length > 0) {
            setLegalEntityArray([field.legalEntity[0]])
        }

    }, [field.legalEntity])

    console.log(unit, 'unitttt');

    // Legal entity onChange

    useEffect(() => {
        if (((commonData) && (legalEntityArray && legalEntityArray.length > 0))) {
            console.log(field.legalEntity, 'field.legalEntity');
            if (commonData && commonData.div_infos && commonData.div_infos.length > 0) {
                let filteredDiv = [];
                let Divisions = _.filter(commonData.div_infos, { le_id: legalEntityArray[0].le_id })
                if (Divisions && Divisions.length > 0) {
                    filteredDiv = _.uniqBy(Divisions, function (e) {
                        return e.div_id;
                    });
                }
                console.log(filteredDiv, 'filteredDiv');
                if (filteredDiv && filteredDiv.length > 0) {
                    setDivision(filteredDiv)
                    let filteredCat = _.filter(commonData.cat_info, { div_id: Number(filteredDiv[0].div_id) })
                    let temp = []
                    if (filteredCat && filteredCat.length > 0) {
                        temp.push({
                            'bg_id': filteredCat[0].bg_id,
                            'c_id': filteredCat[0].c_id,
                            'cat_id': filteredCat[0].cat_id,
                            'cat_name': filteredCat[0].cat_name,
                            'd_id': filteredCat[0].d_id,
                            'div_id': filteredCat[0].div_id,
                            'le_id': filteredCat[0].le_id,
                            'div_name': filteredDiv[0].div_name
                        })
                        setCategory(temp)
                    }
                    // if (commonData && commonData.chart_units && commonData.chart_units.length > 0) {
                    //     let filteredUnit = _.filter(commonData.chart_units, { division_id: Number(filteredDiv[0].div_id) })
                    //     console.log(filteredUnit, 'filteredUnit');
                    //     setUnit(filteredUnit)
                    // }
                }
                // else {
                //     console.log('elsepart');
                //     if (commonData && commonData.chart_units && commonData.chart_units.length > 0) {
                //         console.log('trueee');
                //         let filteredUnit = _.filter(commonData.chart_units, { le_id: legalEntityArray[0].le_id })
                //         console.log(filteredUnit, 'filteredDivision');
                //         setUnit(filteredUnit)
                //     }
                // }
                if (commonData && commonData.chart_units && commonData.chart_units.length > 0) {
                    console.log('trueee');
                    let filteredUnit = _.filter(commonData.chart_units, { le_id: legalEntityArray[0].le_id })
                    console.log(filteredUnit, 'filteredDivision');
                    setUnit(filteredUnit)
                }
            }
        }

    }, [legalEntityArray, commonData])

    // Division,Category and Unit

    useEffect(() => {
        if (legalEntityArray && legalEntityArray.length > 0) {
            if (division) {
                let totalListunit = []
                let countrylisttempdataunit = [];
                let entityArrayunit = []
                let tempArrayunit = []
                division && division.length > 0 && division.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist');

                    if (legalEntityArray && legalEntityArray[0].le_id == subunitlist.le_id) {

                        entityArrayunit = {

                            'bg_id': subunitlist.bg_id,
                            'c_id': subunitlist.c_id,
                            'd_id': subunitlist.d_id,
                            'div_id': subunitlist.div_id,
                            'div_name': subunitlist.div_name,
                            'le_id': subunitlist.le_id,
                            'label': subunitlist.div_name,
                            'values': subunitlist.div_id

                        }
                        tempArrayunit.push(entityArrayunit)
                        console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                    }

                })
                totalListunit = {
                    'label': legalEntityArray && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray[0].le_name,
                    'options': tempArrayunit
                }

                console.log(totalListunit, 'totalListunit');

                countrylisttempdataunit.push(totalListunit)
                console.log(countrylisttempdataunit, 'countrylisttempdataunit');
                setDivisionListData(countrylisttempdataunit)
            }
            if (category) {
                let totalListunit = []
                let countrylisttempdataunit = [];
                let entityArrayunit = []
                let tempArrayunit = []
                category && category.length > 0 && category.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist-category');
                    if (legalEntityArray && legalEntityArray[0].le_id == subunitlist.le_id) {
                        entityArrayunit = {

                            'bg_id': subunitlist.bg_id,
                            'c_id': subunitlist.c_id,
                            'cat_id': subunitlist.cat_id,
                            'cat_name': subunitlist.cat_name,
                            'd_id': subunitlist.d_id,
                            'div_id': subunitlist.div_id,
                            'div_name': subunitlist.div_name,
                            'le_id': subunitlist.le_id,
                            'label': subunitlist.cat_name,
                            'values': subunitlist.cat_id

                        }
                        tempArrayunit.push(entityArrayunit)
                        console.log(entityArrayunit, 'entityArrayunit');
                    }

                })
                totalListunit = {
                    'label': legalEntityArray && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray[0].le_name,
                    'options': tempArrayunit
                }
                countrylisttempdataunit.push(totalListunit)
                setCategoryListData(countrylisttempdataunit)
            }
            if (unit) {
                let totalListunit = []
                let countrylisttempdataunit = [];
                let entityArrayunit = []
                let tempArrayunit = []
                unit && unit.length > 0 && unit.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist-unit');
                    if (legalEntityArray && legalEntityArray[0].le_id == subunitlist.le_id) {
                        entityArrayunit = {

                            'address': subunitlist.address,
                            'business_group_id': subunitlist.business_group_id,
                            'category_id': subunitlist.category_id,
                            'country_id': subunitlist.country_id,
                            'd_ids': subunitlist.d_ids,
                            'division_id': subunitlist.division_id,
                            'le_id': subunitlist.le_id,
                            'postal_code': subunitlist.postal_code,
                            'u_id': subunitlist.u_id,
                            'u_name': subunitlist.u_name,
                            'label': subunitlist.u_name,
                            'values': subunitlist.u_id

                        }
                        tempArrayunit.push(entityArrayunit)
                        console.log(entityArrayunit, 'entityArrayunit');
                    }

                })
                console.log(tempArrayunit, 'tempArrayunit');
                totalListunit = {
                    'label': legalEntityArray && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray[0].le_name,
                    'options': tempArrayunit
                }

                countrylisttempdataunit.push(totalListunit)
                setUnitListData(countrylisttempdataunit)
            }
        }
    }, [legalEntityArray, division, category, unit])


    useEffect(() => {
        if (type === 'Group')
            setGroupCheckBox(checkBox)
    }, [type, checkBox])

    const homescreen = () => {
        historynew.push(homepage)
        // window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }

    useEffect(() => {
        if (field.legalEntity && field.legalEntity.length > 0) {
            if (division) {
                let totalListunit = []
                let countrylisttempdataunit = [];
                let entityArrayunit = []
                let tempArrayunit = []
                division && division.length > 0 && division.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist');

                    if (field && field.legalEntity[0].le_id == subunitlist.le_id) {

                        entityArrayunit = {

                            'bg_id': subunitlist.bg_id,
                            'c_id': subunitlist.c_id,
                            'd_id': subunitlist.d_id,
                            'div_id': subunitlist.div_id,
                            'div_name': subunitlist.div_name,
                            'le_id': subunitlist.le_id,
                            'label': subunitlist.div_name,
                            'values': subunitlist.div_id

                        }
                        tempArrayunit.push(entityArrayunit)
                        console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                    }

                })
                totalListunit = {
                    'label': field && field.legalEntity && field.legalEntity[0].le_id == null ? 'Others' : field.legalEntity[0].le_name,
                    'options': tempArrayunit
                }

                console.log(totalListunit, 'totalListunit');

                countrylisttempdataunit.push(totalListunit)
                console.log(countrylisttempdataunit, 'countrylisttempdataunit');
                setDivisionListData(countrylisttempdataunit)
            }
            if (category) {
                let totalListunit = []
                let countrylisttempdataunit = [];
                let entityArrayunit = []
                let tempArrayunit = []
                category && category.length > 0 && category.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist-category');
                    if (field && field.legalEntity[0].le_id == subunitlist.le_id) {
                        entityArrayunit = {

                            'bg_id': subunitlist.bg_id,
                            'c_id': subunitlist.c_id,
                            'cat_id': subunitlist.cat_id,
                            'cat_name': subunitlist.cat_name,
                            'd_id': subunitlist.d_id,
                            'div_id': subunitlist.div_id,
                            'div_name': subunitlist.div_name,
                            'le_id': subunitlist.le_id,
                            'label': subunitlist.cat_name,
                            'values': subunitlist.cat_id

                        }
                        tempArrayunit.push(entityArrayunit)
                        console.log(entityArrayunit, 'entityArrayunit');
                    }

                })
                totalListunit = {
                    'label': field && field.legalEntity && field.legalEntity[0].le_id == null ? 'Others' : field.legalEntity[0].le_name,
                    'options': tempArrayunit
                }
                countrylisttempdataunit.push(totalListunit)
                setCategoryListData(countrylisttempdataunit)
            }
            if (unit) {
                let totalListunit = []
                let countrylisttempdataunit = [];
                let entityArrayunit = []
                let tempArrayunit = []
                unit && unit.length > 0 && unit.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist-unit');
                    if (field && field.legalEntity[0].le_id == subunitlist.le_id) {
                        entityArrayunit = {

                            'address': subunitlist.address,
                            'business_group_id': subunitlist.business_group_id,
                            'category_id': subunitlist.category_id,
                            'country_id': subunitlist.country_id,
                            'd_ids': subunitlist.d_ids,
                            'division_id': subunitlist.division_id,
                            'le_id': subunitlist.le_id,
                            'postal_code': subunitlist.postal_code,
                            'u_id': subunitlist.u_id,
                            'u_name': subunitlist.u_name,
                            'label': subunitlist.u_name,
                            'values': subunitlist.u_id

                        }
                        tempArrayunit.push(entityArrayunit)
                        console.log(entityArrayunit, 'entityArrayunit');
                    }

                })
                totalListunit = {
                    'label': field && field.legalEntity && field.legalEntity[0].le_id == null ? 'Others' : field.legalEntity[0].le_name,
                    'options': tempArrayunit
                }

                countrylisttempdataunit.push(totalListunit)
                setUnitListData(countrylisttempdataunit)
            }
        }
    }, [field.legalEntity, division, category, unit])

    useEffect(() => {
        if ((commonData) && (category && category.length > 0)) {
            let totalListunit = []
            let countrylisttempdataunit = [];
            let entityArrayunit = []
            let tempArrayunit = []
            category && category.length > 0 && category.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist-category');
                if (commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_id == subunitlist.le_id) {
                    entityArrayunit = {

                        'bg_id': subunitlist.bg_id,
                        'c_id': subunitlist.c_id,
                        'cat_id': subunitlist.cat_id,
                        'cat_name': subunitlist.cat_name,
                        'd_id': subunitlist.d_id,
                        'div_id': subunitlist.div_id,
                        'div_name': subunitlist.div_name,
                        'le_id': subunitlist.le_id,
                        'label': subunitlist.cat_name,
                        'values': subunitlist.cat_id

                    }
                    tempArrayunit.push(entityArrayunit)
                    console.log(entityArrayunit, 'entityArrayunit');
                }

            })
            totalListunit = {
                'label': commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_id == null ? 'Others' : commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_name,
                'options': tempArrayunit
            }
            countrylisttempdataunit.push(totalListunit)
            setCategoryListData(countrylisttempdataunit)
        }

    }, [commonData, category && category.length > 0])

    useEffect(() => {
        if ((commonData) && (unit && unit.length > 0)) {
            let totalListunit = []
            let countrylisttempdataunit = [];
            let entityArrayunit = []
            let tempArrayunit = []
            unit && unit.length > 0 && unit.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist-unit');
                if (commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_id == subunitlist.le_id) {
                    entityArrayunit = {

                        'address': subunitlist.address,
                        'business_group_id': subunitlist.business_group_id,
                        'category_id': subunitlist.category_id,
                        'country_id': subunitlist.country_id,
                        'd_ids': subunitlist.d_ids,
                        'division_id': subunitlist.division_id,
                        'le_id': subunitlist.le_id,
                        'postal_code': subunitlist.postal_code,
                        'u_id': subunitlist.u_id,
                        'u_name': subunitlist.u_name,
                        'label': subunitlist.u_name,
                        'values': subunitlist.u_id

                    }
                    tempArrayunit.push(entityArrayunit)
                    console.log(entityArrayunit, 'entityArrayunit');
                }

            })
            totalListunit = {
                'label': commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_id == null ? 'Others' : commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_name,
                'options': tempArrayunit
            }

            countrylisttempdataunit.push(totalListunit)
            setUnitListData(countrylisttempdataunit)
        }
    }, [commonData, unit && unit.length > 0])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === "All Legal Entity") {
            console.log('hiii');
            if ((commonData && commonData.bg_groups && commonData.bg_groups.length === 0) && (legalEntityArray && legalEntityArray.length > 0)) {
                console.log('hello');
                if (unit) {
                    let totalListunit = []
                    let countrylisttempdataunit = [];
                    let entityArrayunit = []
                    let tempArrayunit = []
                    unit && unit.length > 0 && unit.map((subunitlist) => {
                        console.log(subunitlist, 'subunitlist-lee');

                        if (legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == subunitlist.le_id) {
                            entityArrayunit = {

                                'address': subunitlist.address,
                                'business_group_id': subunitlist.business_group_id,
                                'category_id': subunitlist.category_id,
                                'country_id': subunitlist.country_id,
                                'd_ids': subunitlist.d_ids,
                                'division_id': subunitlist.division_id,
                                'le_id': subunitlist.le_id,
                                'postal_code': subunitlist.postal_code,
                                'u_id': subunitlist.u_id,
                                'u_name': subunitlist.u_name,
                                'label': subunitlist.u_name,
                                'values': subunitlist.u_id

                            }
                            tempArrayunit.push(entityArrayunit)
                            console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                        }

                    })
                    totalListunit = {
                        'label': legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_name,
                        'options': tempArrayunit
                    }

                    console.log(totalListunit, 'totalListunitleee');

                    countrylisttempdataunit.push(totalListunit)
                    console.log(countrylisttempdataunit, 'countrylisttempdataunit');
                    setUnitListData(countrylisttempdataunit)
                }
                if (division) {
                    let totalListunit = []
                    let countrylisttempdataunit = [];
                    let entityArrayunit = []
                    let tempArrayunit = []
                    division && division.length > 0 && division.map((subunitlist) => {
                        console.log(subunitlist, 'subunitlist');

                        if (legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == subunitlist.le_id) {

                            entityArrayunit = {

                                'bg_id': subunitlist.bg_id,
                                'c_id': subunitlist.c_id,
                                'd_id': subunitlist.d_id,
                                'div_id': subunitlist.div_id,
                                'div_name': subunitlist.div_name,
                                'le_id': subunitlist.le_id,
                                'label': subunitlist.div_name,
                                'values': subunitlist.div_id

                            }
                            tempArrayunit.push(entityArrayunit)
                            console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                        }

                    })
                    totalListunit = {
                        'label': legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_name,
                        'options': tempArrayunit
                    }

                    console.log(totalListunit, 'totalListunit');

                    countrylisttempdataunit.push(totalListunit)
                    console.log(countrylisttempdataunit, 'countrylisttempdataunit');
                    setDivisionListData(countrylisttempdataunit)
                }
                if (category) {
                    let totalListunit = []
                    let countrylisttempdataunit = [];
                    let entityArrayunit = []
                    let tempArrayunit = []
                    category && category.length > 0 && category.map((subunitlist) => {
                        console.log(subunitlist, 'subunitlist-category');
                        if (legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == subunitlist.le_id) {
                            entityArrayunit = {

                                'bg_id': subunitlist.bg_id,
                                'c_id': subunitlist.c_id,
                                'cat_id': subunitlist.cat_id,
                                'cat_name': subunitlist.cat_name,
                                'd_id': subunitlist.d_id,
                                'div_id': subunitlist.div_id,
                                'div_name': subunitlist.div_name,
                                'le_id': subunitlist.le_id,
                                'label': subunitlist.cat_name,
                                'values': subunitlist.cat_id

                            }
                            tempArrayunit.push(entityArrayunit)
                            console.log(entityArrayunit, 'entityArrayunit');
                        }

                    })
                    totalListunit = {
                        'label': legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_name,
                        'options': tempArrayunit
                    }
                    countrylisttempdataunit.push(totalListunit)
                    setCategoryListData(countrylisttempdataunit)
                }
            }
        }

    }, [commonData, legalEntityArray, unit, division, category, localStorage.getItem('SelectedEntity') === "All Legal Entity"])



    console.log(checkBox, 'checkBox');


    const showmore = () => {
        setCountValue(countValue + 100)
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request":
                    ["GetTskDistributionStatusDrillDown",
                        {
                            "c_ids": [
                                commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                            ],
                            "d_ids": dIds,
                            "filter_type": "Group",
                            "filter_ids": [
                                1
                            ],
                            "record_count": countValue + 100,
                            "applicability_status": criticalityValue,
                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                            "country_domains": countryDomain,
                            "chart_year": year
                        }
                    ]
            }
        ]
        if (paramid != "") {
            getTaskDistributionShowMoreTableData({
                payload: payload,
                paramid: paramid
            })
        }
    }



    const onClose = () => {
        setOpen(false);
    };

    console.log(groupCheckBox, 'groupCheckBox');
    console.log(checkBox, 'checkBox');


    const filterChange = () => {
        setGo(true)
        setGeneratedOn(false)
        if (groupCheckBox === true && errorField === '') {
            let obj = {
                "c_ids": fieldTypeClick === "Country" ? filterData.countryArr : cIds,
                "d_ids": fieldTypeClick === "Domain" ? filterData.domainIds : dIds,
                "filter_type": "Group",
                "filter_ids": fieldTypeClick === "Country" ? filterData.countryArr : cIds,
                "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                "country_domains": newCountryDomain,
            }
            localStorage.setItem('filterState', fieldTypeClick === "Country" || fieldTypeClick === "Domain" ? true : false)
            localStorage.setItem('filterChange', JSON.stringify(obj))
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetTskDistributionStatusChart",
                        {
                            "c_ids": filterData.countryArr,
                            "d_ids": filterData.domainIds,
                            "filter_type": ((fieldType != "") && (type === '')) ? fieldType : "Group",
                            "filter_ids":
                                (fieldType == "LegalEntity" && type === '') ? [filterData.legalentity] :
                                    (fieldType == 'Division' && type === '') ? [Number(filterData.division)] :
                                        (fieldType == 'Category' && type === '') ? [Number(filterData.category)] :
                                            (fieldType == 'Unit' && type === '') ? [Number(filterData.unit)] :
                                                (fieldType == 'BusinessGroup' && type === '') ? [Number(filterData.business)] :
                                                    (fieldType != "" && type === 'Group') ? [1] :
                                                        (fieldTypeClick === "Country") ? filterData.countryArr :
                                                            (fieldTypeClick === "Domain") ? filterData.domainIds :
                                                                [1]
                            ,
                            "le_ids":
                                filterData && filterData.leIds ? [filterData.leIds] : filterData.legalentity ? filterData.legalentity : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                            ,
                            "country_domains": newCountryDomain,
                            "chart_year": year
                        }
                    ]
                }
            ]
            if (paramid != "") {
                getTaskDistributionchart({
                    payload: payload,
                    paramid: paramid
                })
            }
            setOpen(false);

        }
        else {
            // toast.error('Please select the filter')
            toast.error(errorField ? errorField : 'Please select the filter')
        }
    }

    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        if (e) {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        } else {
            setSelectedKeys([])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        }
    };

    const handleReset = (clearFilters, handleSearch, confirm, dataIndex, setSelectedKeys) => {
        handleSearch('', confirm, dataIndex, setSelectedKeys)
        clearFilters();
        setSearchText('');
    };

    const clearFocus = (searchInput) => {
        setTimeout(() => searchInput.current?.select(), 100);
    }


    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
            >
                <Input className='form-control'
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    autoFocus={'on'}
                    value={selectedKeys[0]}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}

                />
                <CloseCircleOutlined className='table-filter-reset'
                    onClick={() => {
                        handleReset(clearFilters,
                            handleSearch, confirm, dataIndex, setSelectedKeys)
                        clearFocus(searchInput)
                    }
                    } />
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    //     // color: filtered ? '#1890ff' : undefined,
                    //     fontWeight: 'bold',
                    //     // backgroundColor:'blue',
                    //     fontSize: '15px',
                    //     color: 'black'
                }}
            />
            // <FileSearchOutlined 
            // style={{
            //     // color: filtered ? '#1890ff' : undefined,
            //     fontWeight:'bold',
            //     // backgroundColor:'blue',
            //     // fontSize:'20px',
            //     color:'black'
            // }}
            // />
            // <i class="bi bi-search"></i>
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});

    const handleChange = (pagination, filters, sorter) => {

        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };


    const columns = [
        {
            title: "#",
            filterable: true,
            dataIndex: 'index',
            key: 'index',
            width: 5,
            align: 'center'
        },

        {
            title: "Statutory",
            ...getColumnSearchProps('s_prov', 'Statutory'),
            dataIndex: false,
            key: 's_prov',
            width: 10,
            filteredValue: filteredInfo.s_prov || null,
            render: (record) => {
                console.log(record, 'recordrecord');
                return <p title={record.s_prov} className='cut-text'>
                    {record.s_prov}</p>
            }
        },
        {
            title: 'Compliance Task',
            ...getColumnSearchProps('comp_name', 'Compliance Task'),
            dataIndex: false,
            key: 'comp_name',
            ellipsis: false,
            width: 10,
            render: (row, record) => {
                if (record.download_url_list) {
                    return <p title={record.comp_name} className='cut-text'><a href={`${urls + record.download_url_list}`} target='_blank'>{record.comp_name}</a></p>
                }
                else {
                    return <p title={record.comp_name} className='cut-text'>{record.comp_name}</p>
                }
            },
            filteredValue: filteredInfo.comp_name || null,

        },
        {
            title: 'Criticality',
            ...getColumnSearchProps('criticality', 'Criticality'),
            dataIndex: 'criticality',
            key: 'criticality',
            ellipsis: false,
            width: 10,
            align: 'center',
            filteredValue: filteredInfo.criticality || null,

        },
        {
            title: 'Compliance Description',
            ...getColumnSearchProps('descp', 'Description'),
            dataIndex: false,
            key: 'descp',
            ellipsis: false,
            width: 10,
            filteredValue: filteredInfo.descp || null,
            render: (record) => {
                return <p title={record.descp} className='cut-text'>{record.descp}</p>
            }

        },
        {
            title: 'Penal Conseqeunces',
            ...getColumnSearchProps('p_cons', 'Penal Conseqeunces'),
            dataIndex: false,
            key: 'p_cons',
            ellipsis: false,
            width: 10,
            filteredValue: filteredInfo.p_cons || null,
            render: (record) => {
                return <p title={record.p_cons} className='cut-text'>{record.p_cons}</p>
            }

        },
        {
            title: "Compliance Frequency",
            ...getColumnSearchProps('frequency', 'Compliance Frequency'),
            dataIndex: 'frequency',
            key: 'frequency',
            ellipsis: false,
            width: 10,
            filteredValue: filteredInfo.frequency || null,

        },
        {
            title: 'Repeats',
            ...getColumnSearchProps('summary', 'Repeats'),
            dataIndex: false,
            key: 'summary',
            ellipsis: false,
            width: 10,
            filteredValue: filteredInfo.summary || null,
            render: (record) => {
                return <p title={record.summary} className='cut-text'>{record.summary}</p>
            }

        },

        {
            title: 'Trigger Before',
            ...getColumnSearchProps('trigger_before_days', 'Trigger Before'),
            dataIndex: false,
            key: 'trigger_before_days',
            ellipsis: false,
            width: 10,
            dataIndex: false,
            key: 'trigger_before_days',
            render: (record) => {
                console.log(record && record.length > 0 && record.statu_dates && record.statu_dates[0].trigger_before_days, 'record && record.statu_dates && record.statu_dates.length > 0 && record.statu_dates[0].trigger_before_days');
                if (record && record.statu_dates && record.statu_dates.length > 0 && record.statu_dates[0].trigger_before_days) {
                    return <span title={record.trigger_before_days} className='cut-text'>{record && record.trigger_before_days}</span>
                }
                else {
                    return null
                }

            },
            filteredValue: filteredInfo.trigger_before_days || null,
        },
    ];



    // const columns = [
    //     {
    //         title: 'Statutory',
    //         dataIndex: 's_prov',
    //         key: 's_prov',
    //     },
    //     {
    //         title: 'Compliance Task',
    //         dataIndex: 'comp_name',
    //         key: 'comp_name',
    //     },
    //     {
    //         title: 'Criticality',
    //         dataIndex: 'criticality',
    //         key: 'criticality',
    //     },
    //     {
    //         title: 'Compliance Description',
    //         dataIndex: 'descp',
    //         key: 'descp',
    //     },
    //     {
    //         title: 'Panel Conseqeunces',
    //         dataIndex: 'p_cons',
    //         key: 'p_cons',
    //     },
    //     {
    //         title: 'Compliance Frequency',
    //         dataIndex: 'frequency',
    //         key: 'frequency',
    //     },
    //     {
    //         title: 'Repeats',
    //         dataIndex: 'summary',
    //         key: 'summary',
    //     },
    //     {
    //         title: 'Trigger Before',
    //         dataIndex: 'statu_dates',
    //         key: 'statu_dates',
    //         render: (record) => {
    //             console.log(record && record.length > 0 && record[0].trigger_before_days, 'record && record.statu_dates && record.statu_dates.length > 0 && record.statu_dates[0].trigger_before_days');
    //             return <p>{record && record.length > 0 && record[0].trigger_before_days
    //             }</p>
    //         }
    //     },
    // ];

    // useEffect(() => {
    //     if (data && data.length > 0) {
    //         console.log('test');
    //         let total = ((data && data.length > 0 && data[0].assigned_count) + (data && data.length > 0 && data[0].not_opted_count) + (data && data.length > 0 && data[0].unassign_count) + (data && data.length > 0 && data[0].not_applicable_count))
    //         if (table == false) {

    //             let x = am4core.create("chartdiv", am4charts.PieChart3D);
    //             am4core.useTheme(am4themes_animated);
    //             x.paddingRight = 20;
    //             x.data = [{
    //                 "task": "Assigned",
    //                 "count": data && data.length > 0 && data[0].assigned_count,
    //                 "color": am4core.color("#00a3a3")
    //             }, {
    //                 "task": "Not Opted",
    //                 "count": data && data.length > 0 && data[0].not_opted_count,
    //                 "color": am4core.color("#dd070c")
    //             },
    //             {
    //                 "task": "Unassigned",
    //                 "count": data && data.length > 0 && data[0].unassign_count,
    //                 "color": am4core.color("#ff9c80")
    //             },
    //             {
    //                 "task": "Not Applicable",
    //                 "count": data && data.length > 0 && data[0].not_applicable_count,
    //                 "color": am4core.color("#808080")
    //             },
    //             ];

    //             var pieSeries = x.series.push(new am4charts.PieSeries3D());
    //             pieSeries.dataFields.value = "count";
    //             console.log(pieSeries.dataFields.value, ' pieSeries.dataFields.value');
    //             pieSeries.dataFields.category = "task";
    //             pieSeries.slices.template.propertyFields.fill = "color";
    //             pieSeries.calculatePercent = true;
    //             pieSeries.calculateTotals = true;


    //             pieSeries.slices.template.tooltipText = `{category}: {value.percent.formatNumber('#.00')}[/]% : ({value.value} out of ${total})`;
    //             chart.current = x;
    //             x.legend = new am4charts.Legend();
    //             x.legend.valueLabels.template.disabled = true;
    //             x.legend.readerValueText = "{category}";
    //             x.legend.position = "right";
    //             x.legend.useDefaultMarker = true;
    //             var marker = x.legend.markers.template.children.getIndex(0);
    //             marker.cornerRadius(12, 12, 12, 12);
    //             marker.strokeWidth = 1;
    //             marker.strokeOpacity = 0.5;
    //             pieSeries.slices.template.events.on("hit", function (ev) {
    //                 setTable(true)
    //                 const payload = [
    //                     authtoken,
    //                     {
    //                         "session_token": authtoken,
    //                         "request":
    //                             ["GetTskDistributionStatusDrillDown",
    //                                 {
    //                                     "c_ids": [
    //                                         commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
    //                                     ],
    //                                     "d_ids": [
    //                                         commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
    //                                     ],
    //                                     "filter_type": "Group",
    //                                     "filter_ids": [
    //                                         1
    //                                     ],
    //                                     "record_count": 0,
    //                                     "applicability_status": ev.target.dataItem.category,
    //                                     "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [
    //                                         commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_id
    //                                     ],
    //                                     "country_domains": [
    //                                         {
    //                                             "d_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id,
    //                                             "c_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].c_id,
    //                                             "month_from": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_from,
    //                                             "month_to": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_to
    //                                         }
    //                                     ],
    //                                     "chart_year": year
    //                                 }
    //                             ]
    //                     }
    //                 ]
    //                 getTaskDistributionTable({
    //                     payload: payload,
    //                     paramid: paramid
    //                 })
    //             });

    //         }
    //     }
    // }, [data, table, localStorage.getItem('chart')])

    console.log(data, 'data');

    // useEffect(() => {
    //     if (data && data.length > 0) {
    //         console.log('test');
    //         let total = ((data && data.length > 0 && data[0].assigned_count) + (data && data.length > 0 && data[0].not_opted_count) + (data && data.length > 0 && data[0].unassign_count) + (data && data.length > 0 && data[0].not_applicable_count))
    //         if (table == false) {
    //             var container = am4core.create("container", am4core.Container);
    //             container.width = am4core.percent(100);
    //             container.height = am4core.percent(100);
    //             container.layout = "vertical";
    //             container.background.fill = am4core.color("#000");
    //             container.background.fillOpacity = 0.1;
    //             container.background.stroke = am4core.color("#000");
    //             container.background.strokeOpacity = 0.2;
    //             container.background.strokeWidth = 2;

    //             if ((data && data.length > 0) && ((data && data.length && data[0].assigned_count == 0) && (data && data.length && data[0].not_applicable_count == 0) && (data && data.length && data[0].not_opted_count == 0) && (data && data.length && data[0].unassign_count == 0))) {
    //                 var title3 = container.createChild(am4core.Label)
    //                 title3.text = "No Data to Display"
    //                 title3.disabled = false;
    //                 title3.align = 'left'
    //                 title3.paddingTop = '45%'
    //                 title3.paddingLeft = '43%'
    //                 // title3.fontWeight = '500'
    //                 title3.fontSize = '16'
    //             }

    //             else {

    //                 function addChart(data) {

    //                     let x = container.createChild(am4charts.PieChart3D);
    //                     am4core.useTheme(am4themes_animated);
    //                     x.paddingRight = 20;
    //                     x.data = data

    //                     var pieSeries = x.series.push(new am4charts.PieSeries3D());
    //                     pieSeries.dataFields.value = "Count";
    //                     console.log(pieSeries.dataFields.value, ' pieSeries.dataFields.value');
    //                     pieSeries.dataFields.category = "Status";
    //                     pieSeries.slices.template.propertyFields.fill = "color";
    //                     pieSeries.calculatePercent = true;
    //                     pieSeries.calculateTotals = true;

    //                     pieSeries.labels.template.text = "{category}: {value}";
    //                     pieSeries.slices.template.tooltipText = `{category}: {value} out of ${total} ({value.percent.formatNumber('#.00')}[/]%)`;
    //                     chart.current = x;
    //                     x.legend = new am4charts.Legend();
    //                     x.legend.valueLabels.template.disabled = true;
    //                     x.legend.valueLabels.template.text = `{value}`;
    //                     // x.legend.readerValueText = "{category}";
    //                     x.legend.position = "right";
    //                     x.legend.useDefaultMarker = true;
    //                     var marker = x.legend.markers.template.children.getIndex(0);
    //                     marker.cornerRadius(12, 12, 12, 12);
    //                     marker.strokeWidth = 1;
    //                     marker.strokeOpacity = 0.5;
    //                     pieSeries.slices.template.events.on("hit", function (ev) {
    //                         setCountValue(0)
    //                         setShowMoreCount(0)
    //                         setDataCount(ev.target.dataItem._dataContext.Count)
    //                         setCriticalityValue(ev.target.dataItem.category)

    //                         setTable(true)
    //                         const payload = [
    //                             authtoken,
    //                             {
    //                                 "session_token": authtoken,
    //                                 "request":
    //                                     ["GetTskDistributionStatusDrillDown",
    //                                         {
    //                                             "c_ids": [
    //                                                 commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
    //                                             ],
    //                                             "d_ids": dIds,
    //                                             "filter_type": fieldType != "" ? fieldType : "Group",
    //                                             "filter_ids": [
    //                                                 fieldType == "LegalEntity" ? filterData.legalentity :
    //                                                     fieldType == 'Division' ? Number(filterData.division) :
    //                                                         fieldType == 'Category' ? Number(filterData.category) :
    //                                                             fieldType == 'Unit' ? Number(filterData.unit) :
    //                                                                 fieldType == 'BusinessGroup' ? Number(filterData.business) :
    //                                                                     1
    //                                             ],
    //                                             "record_count": 0,
    //                                             "applicability_status": ev.target.dataItem.category,
    //                                             "le_ids":
    //                                                 filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
    //                                             ,
    //                                             "country_domains": countryDomain,
    //                                             "chart_year": year
    //                                         }
    //                                     ]
    //                             }
    //                         ]
    //                         getTaskDistributionTable({
    //                             payload: payload,
    //                             paramid: paramid
    //                         })
    //                     });

    //                     x.exporting.menu = new am4core.ExportMenu();
    //                     x.exporting.dataFields = {
    //                         "Status": "Status",
    //                         "Count": "Count"
    //                     }
    //                     x.exporting.menu.items = [{
    //                         "label": "...",
    //                         "menu": [
    //                             {
    //                                 "label": "Image",
    //                                 "menu": [
    //                                     { "type": "png", "label": "PNG" },
    //                                     { "type": "jpg", "label": "JPG" },
    //                                     { "type": "svg", "label": "SVG" },
    //                                     { "type": "pdf", "label": "PDF" },
    //                                     { "type": "gif", "label": "GIF" }
    //                                 ]
    //                             }, {
    //                                 "label": "Data",
    //                                 "menu": [
    //                                     { "type": "csv", "label": "CSV" },
    //                                     { "type": "xlsx", "label": "XLSX" },
    //                                 ]
    //                             }, {
    //                                 "label": "Print", "type": "print"
    //                             }
    //                         ]
    //                     }];
    //                     x.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";



    //                     var title = x.titles.create();
    //                     title.text = generatedOn === true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} (UTC)`
    //                     title.disabled = false;
    //                     title.align = 'left'
    //                     title.marginTop = '2%'
    //                     title.marginLeft = '2%'

    //                     var title1 = x.titles.create();
    //                     title1.text =
    //                         ((fieldType == "LegalEntity" && go == true && type === '') ? `Task Distribution of Legal Entity [bold]${filterDataNames.legalentity} (${year})[/]` : (
    //                             (fieldType == "Division" && go == true && type === '') ? `Task Distribution of Division [bold]${filterDataNames.division} (${year})[/]` : (
    //                                 (fieldType == "Category" && go == true && type === '') ? `Task Distribution of Category [bold]${filterDataNames.category} (${year})[/]` : (
    //                                     (fieldType == "BusinessGroup" && go == true && type === '') ? `Task Distribution of Business Group [bold]${filterDataNames.business} (${year})[/]` : (
    //                                         (fieldType == "Unit" && go == true && type === '') ? `Task Distribution of Unit [bold]${filterDataNames.unit} (${year})[/]` : `Task Distribution of ${commonData && commonData.g_name} (${year})`
    //                                     )))
    //                         ))
    //                     title1.disabled = false;
    //                     title1.align = 'center'
    //                     title1.marginTop = '5%'
    //                     title1.marginLeft = '2%'
    //                     title1.fontSize = '17px'
    //                     title1.fontWeight = '400'
    //                     // title1.fontWeight = 'bold'

    //                     // <b><p>Country-Domain</p></b>
    //                     // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

    //                     var label = x.createChild(am4core.Label);
    //                     label.text = "Country-Domain";
    //                     label.fontSize = 14;
    //                     label.align = "left";
    //                     label.marginLeft = '2%';
    //                     label.marginBottom = '2%'
    //                     label.fontWeight = 'bold'

    //                     var label1 = x.createChild(am4core.Label);
    //                     label1.text = `${textArray}`
    //                     label1.fontSize = 14;
    //                     label1.align = "left";
    //                     label1.marginLeft = '2%';
    //                     label1.marginBottom = '5%'

    //                     var label2 = x.createChild(am4core.Label);
    //                     label2.text = `Total Task : ${total}`;
    //                     label2.fontSize = 14;
    //                     label2.align = "center";
    //                     label2.marginLeft = '2%';
    //                     label2.marginBottom = '4%'
    //                     label2.fontWeight = 'bold'

    //                 }

    //                 addChart([
    //                     (data && data.length > 0 && data[0].assigned_count > 0 ?
    //                         {
    //                             "Status": "Assigned",
    //                             "Count": data && data.length > 0 && data[0].assigned_count,
    //                             "color": am4core.color("#00a3a3")
    //                         } : {})
    //                     , (data && data.length > 0 && data[0].not_opted_count > 0 ?
    //                         {
    //                             "Status": "Not Opted",
    //                             "Count": data && data.length > 0 && data[0].not_opted_count,
    //                             "color": am4core.color("#dd070c")
    //                         } : {}),
    //                     (data && data.length > 0 && data[0].unassign_count > 0 ?
    //                         {
    //                             "Status": "Unassigned",
    //                             "Count": data && data.length > 0 && data[0].unassign_count,
    //                             "color": am4core.color("#ff9c80")
    //                         } : {}),
    //                     (data && data.length > 0 && data[0].not_applicable_count > 0 ?
    //                         {
    //                             "Status": "Not Applicable",
    //                             "Count": data && data.length > 0 && data[0].not_applicable_count,
    //                             "color": am4core.color("#808080")
    //                         } : {})]);
    //             }

    //         }
    //     }

    // }, [data, timeZone, timeZoneData, table, localStorage.getItem('chart')])


    useEffect(() => {
        if (data) {
            am4core.useTheme(am4themes_animated);
            var taskchart = am4core.create("taskchartdiv", am4charts.XYChart);
            if (table == false) {
                // let totalTask = taskDistributionWidget && taskDistributionWidget.Tot_count;
                // let totalNumber;
                // if(totalTask){
                // let totalValue = totalTask.split(':')
                //  totalNumber = Number(totalValue[1])
                // }
                if ((data && data.length > 0) && ((data && data.length && data[0].assigned_count == 0) && (data && data.length && data[0].not_applicable_count == 0) && (data && data.length && data[0].not_opted_count == 0) && (data && data.length && data[0].unassign_count == 0))) {
                    var title = taskchart.titles.create();
                    title.text = "No Data to Display"
                    title.disabled = false;
                    title.align = 'left'
                    title.marginTop = '45%'
                    title.marginLeft = '43%'
                }

                else {

                    let total = ((data && data.length > 0 && data[0].assigned_count) + (data && data.length > 0 && data[0].not_opted_count) + (data && data.length > 0 && data[0].unassign_count) + (data && data.length > 0 && data[0].not_applicable_count))
                    taskchart.responsive.enabled = 'true'
                    taskchart.data = [
                        (data && data.length > 0 && data[0].assigned_count > 0 ?
                            {
                                "Status": "Assigned",
                                "Count": data && data.length > 0 && data[0].assigned_count,
                                "percent": (Math.round(data && data.length > 0 && data[0].assigned_count) / (total) * 100).toFixed(2),
                                color: taskchart.colors.getIndex(3),
                            } : {})
                        , (data && data.length > 0 && data[0].not_opted_count > 0 ?
                            {
                                "Status": "Not Opted",
                                "Count": data && data.length > 0 && data[0].not_opted_count,
                                "percent": (Math.round(data && data.length > 0 && data[0].not_opted_count) / (total) * 100).toFixed(2),
                                color: taskchart.colors.getIndex(5),
                            } : {}),
                        (data && data.length > 0 && data[0].unassign_count > 0 ?
                            {
                                "Status": "Unassigned",
                                "Count": data && data.length > 0 && data[0].unassign_count,
                                "percent": (Math.round(data && data.length > 0 && data[0].unassign_count) / (total) * 100).toFixed(2),
                                color: taskchart.colors.getIndex(9),
                            } : {}),
                        (data && data.length > 0 && data[0].not_applicable_count > 0 ?
                            {
                                "Status": "Not Applicable",
                                "Count": data && data.length > 0 && data[0].not_applicable_count,
                                "percent": (Math.round(data && data.length > 0 && data[0].not_applicable_count) / (total) * 100).toFixed(2),
                                color: taskchart.colors.getIndex(11),
                            } : {})
                    ]
                    taskchart.padding(30, 30, 30, 30);
                    taskchart.colors.list = [am4core.color("#004D47"), am4core.color("#128277"), am4core.color("#52958B")];
                    var categoryAxis = taskchart.xAxes.push(new am4charts.CategoryAxis());
                    categoryAxis.renderer.grid.template.location = 0;
                    categoryAxis.dataFields.category = "Status";
                    categoryAxis.renderer.minGridDistance = 60;
                    categoryAxis.renderer.inversed = true;
                    categoryAxis.renderer.grid.template.disabled = true;
                    var valueAxis = taskchart.yAxes.push(new am4charts.ValueAxis());
                    valueAxis.min = 0;
                    valueAxis.extraMax = 0.1;
                    // categoryAxis.title.text = `[bold]Total Task : ${total}[/]`;
                    //valueAxis.rangeChangeEasing = am4core.ease.linear;
                    //valueAxis.rangeChangeDuration = 1500;
                    var taskseries = taskchart.series.push(new am4charts.ColumnSeries());
                    taskseries.dataFields.categoryX = "Status";
                    taskseries.dataFields.valueY = "Count";
                    // taskseries.tooltipText = "{valueY.value}"
                    // taskseries.tooltipText = `[bold]{visits}[/]`;
                    taskseries.columns.template.strokeOpacity = 0;
                    taskseries.columns.template.column.cornerRadiusTopRight = 10;
                    taskseries.columns.template.column.cornerRadiusTopLeft = 10;
                    taskseries.columns.template.propertyFields.fill = 'color';
                    // taskseries.columns.template.tooltipText = "Hiiiii"
                    taskseries.columns.template.tooltipText = `{Status}: {Count} out of ${total} ({percent}%)`;
                    taskseries.columns.template.events.on("hit", function (ev) {
                        setCountValue(0)
                        setShowMoreCount(0)
                        setDataCount(ev.target.dataItem._dataContext.Count)
                        setCriticalityValue(ev.target.dataItem._dataContext.Status)
                        setTable(true)
                        const payload = [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request":
                                    ["GetTskDistributionStatusDrillDown",
                                        {
                                            "c_ids": [
                                                commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                            ],
                                            "d_ids": dIds,
                                            "filter_type": fieldType != "" ? fieldType : "Group",
                                            "filter_ids": [
                                                fieldType == "LegalEntity" ? filterData.legalentity :
                                                    fieldType == 'Division' ? Number(filterData.division) :
                                                        fieldType == 'Category' ? Number(filterData.category) :
                                                            fieldType == 'Unit' ? Number(filterData.unit) :
                                                                fieldType == 'BusinessGroup' ? Number(filterData.business) :
                                                                    1
                                            ],
                                            "record_count": 0,
                                            "applicability_status": ev.target.dataItem._dataContext.Status,
                                            "le_ids":
                                                filterData && filterData.leIds ? [filterData.leIds] : filterData.legalentity ? filterData.legalentity : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                                            ,
                                            "country_domains": countryDomain,
                                            "chart_year": year
                                        }
                                    ]
                            }
                        ]
                        getTaskDistributionTable({
                            payload: payload,
                            paramid: paramid
                        })

                    })

                    //series.interpolationDuration = 1500;
                    //series.interpolationEasing = am4core.ease.linear;
                    var labelBullet = taskseries.bullets.push(new am4charts.LabelBullet());
                    labelBullet.label.verticalCenter = "bottom";
                    labelBullet.label.dy = -10;
                    labelBullet.label.text = "{values.valueY.workingValue.formatNumber('#.')}";
                    taskchart.zoomOutButton.disabled = true;
                    // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
                    taskseries.columns.template.adapter.add("fill", function (fill, target) {
                        return taskchart.colors.getIndex(target.dataItem.index);
                    });

                    taskchart.exporting.menu = new am4core.ExportMenu();
                    taskchart.exporting.dataFields = {
                        "Status": "Status",
                        "Count": "Count"
                    }
                    taskchart.exporting.menu.items = [{
                        "label": "...",
                        "menu": [
                            {
                                "label": "Image",
                                "menu": [
                                    { "type": "png", "label": "PNG" },
                                    { "type": "jpg", "label": "JPG" },
                                    { "type": "svg", "label": "SVG" },
                                    { "type": "pdf", "label": "PDF" },
                                    { "type": "gif", "label": "GIF" }
                                ]
                            }, {
                                "label": "Data",
                                "menu": [
                                    { "type": "csv", "label": "CSV" },
                                    { "type": "xlsx", "label": "XLSX" },
                                ]
                            }, {
                                "label": "Print", "type": "print"
                            }
                        ]
                    }];
                    taskchart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";



                    var title = taskchart.titles.create();
                    title.text = generatedOn === true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} (UTC)`
                    title.disabled = false;
                    title.align = 'left'
                    // title.marginTop = '2%'
                    // title.marginLeft = '2%'
                    title.marginBottom = '5%'

                    var title1 = taskchart.titles.create();
                    title1.text =
                        ((fieldType == "LegalEntity" && go == true && type === '') ? `Task Distribution of Legal Entity [bold]${filterDataNames.legalentity} (${year})[/]` : (
                            (fieldType == "Division" && go == true && type === '') ? `Task Distribution of Division [bold]${filterDataNames.division} (${year})[/]` : (
                                (fieldType == "Category" && go == true && type === '') ? `Task Distribution of Category [bold]${filterDataNames.category} (${year})[/]` : (
                                    (fieldType == "BusinessGroup" && go == true && type === '') ? `Task Distribution of Business Group [bold]${filterDataNames.business} (${year})[/]` : (
                                        (fieldType == "Unit" && go == true && type === '') ? `Task Distribution of Unit [bold]${filterDataNames.unit} (${year})[/]` : `Task Distribution of ${commonData && commonData.g_name} (${year})`
                                    )))
                        ))
                    title1.disabled = false;
                    title1.align = 'center'
                    // title1.marginTop = '5%'
                    title1.marginLeft = '2%'
                    title1.fontSize = '17px'
                    title1.fontWeight = '400'
                    // title1.fontWeight = 'bold'

                    // <b><p>Country-Domain</p></b>
                    // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                    var label = taskchart.createChild(am4core.Label);
                    label.text = "Country-Domain";
                    label.fontSize = 14;
                    label.align = "left";
                    // label.marginLeft = '2%';
                    label.marginBottom = '2%'
                    label.fontWeight = 'bold'

                    var label1 = taskchart.createChild(am4core.Label);
                    label1.text = `${textArray}`
                    label1.fontSize = 14;
                    label1.align = "left";
                    // label1.marginLeft = '2%';
                    label1.marginBottom = '5%'

                    var label2 = taskchart.createChild(am4core.Label);
                    label2.text = `Total Task : ${total}`;
                    label2.fontSize = 14;
                    label2.align = "center";
                    label2.marginLeft = '2%';
                    label2.marginBottom = '4%'
                    label2.fontWeight = 'bold'

                    // setInterval(function () {
                    //     am4core.array.each(taskchart.data, function (item) {
                    //         item.visits += Math.round(Math.random() * 200 - 100);
                    //         item.visits = Math.abs(item.visits);
                    //     })
                    //     taskchart.invalidateRawData();
                    // }, 2000)
                    // categoryAxis.sortBySeries = taskseries;
                }
            }
        }

    }, [data, timeZone, timeZoneData, table, localStorage.getItem('chart'), sideFilterState])

    useEffect(() => {
        if (JSON.parse(sideFilterState) === true && localStorage.getItem('filterChange') && go === false) {
            if (JSON.parse(isCountryValueTrue) === false) {
                let temp = []
                for (let i in parsedSideFilterData.c_ids) {
                    temp.push(`${parsedSideFilterData.c_ids[i]}`)
                }
                console.log(temp, 'temp');
                setFilterData({
                    ...filterData,
                    country: temp,
                    // countryArr:[`${parsedSideFilterData && parsedSideFilterData.c_ids}`],
                    domain: parsedSideFilterData && parsedSideFilterData.cd_ids,
                    domainIds: parsedSideFilterData && parsedSideFilterData.d_ids,
                })
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetTskDistributionStatusChart",
                            {
                                "c_ids": parsedSideFilterData && parsedSideFilterData.c_ids,
                                "d_ids": parsedSideFilterData && parsedSideFilterData.d_ids,
                                "filter_type": parsedSideFilterData && parsedSideFilterData.filter_type,
                                "filter_ids": parsedSideFilterData && parsedSideFilterData.filter_ids,
                                "le_ids": parsedSideFilterData && parsedSideFilterData.le_ids,
                                "country_domains": parsedSideFilterData && parsedSideFilterData.country_domains,
                                "chart_year": year
                            }
                        ]
                    }
                ]
                console.log(payload, 'payload');
                if (paramid != "") {
                    getTaskDistributionchart({
                        payload: payload,
                        paramid: paramid
                    })
                }
            }
            else {
                setFilterData({
                    ...filterData,
                    country: [],
                    countryArr: [],
                    domain: [],
                    domainIds: [],
                })
            }
        }

    }, [go, paramid, localStorage.getItem('filterState'), localStorage.getItem('filterChange')])

    console.log(filterData, 'aishuuu');

    const [fullscreen, setfullscreen] = useState(false);
    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);




    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }

    useEffect(() => {
        if (taskDistributionTable && taskDistributionTable.length > 0) {
            let temp = []
            let temp1 = []
            {
                taskDistributionTable && taskDistributionTable.length > 0 &&
                    taskDistributionTable.map((item, i) => {
                        console.log(item, 'item');
                        let childDatas = []
                        let unitName = item.ap_compliances
                        let keys = Object.keys(unitName);
                        let values = Object.values(unitName);
                        if (keys && keys.length > 0) {
                            {
                                keys && keys.map((items, j) => {
                                    console.log(items, 'itemsTas')
                                    let tempArr = []
                                    if (values && values.length > 0) {
                                        for (let m in values[j]) {
                                            tempArr.push({
                                                'index': count,
                                                'ageing': values[j][m].ageing,
                                                'assignee_name': values[j][m].assignee_name,
                                                'bg_name': values[j][m].bg_name,
                                                'comp_name': values[j][m].comp_name,
                                                'comp_id': values[j][m].comp_id,
                                                'frequency': values[j][m].frequency,
                                                'compfielaw_url': values[j][m].compfielaw_url,
                                                'criticality': values[j][m].criticality,
                                                'descp': values[j][m].descp,
                                                'is_active': values[j][m].is_active,
                                                'div_name': values[j][m].div_name,
                                                'p_cons': values[j][m].p_cons,
                                                'remarks': values[j][m].remarks,
                                                's_prov': values[j][m].s_prov,
                                                'statu_dates': values[j][m].statu_dates,
                                                'trigger_before_days': values[j][m].statu_dates && values[j][m].statu_dates.length > 0 && `${values[j][m].statu_dates[0].trigger_before_days} Days`,
                                                'summary': values[j][m].summary,
                                                // 'index': values[j][m].,
                                                'le_name': values[j][m].le_name,
                                                'download_url_list': values[j][m].download_url_list,
                                                'level1_statutory_name': item.level1_statutory_name,
                                                'leUname': items
                                            })
                                            { <span hidden>{count = count + 1}</span> }
                                            // childDatas[i] = []
                                        }
                                        Array.prototype.push.apply(temp1, tempArr)
                                        childDatas.push(tempArr)
                                        console.log(tempArr, 'tempArr');
                                        // newCustomisedValueArray = tempArr
                                        // setNewCustomisedValueArray([...newCustomisedValueArray,...tempArr])
                                    }
                                    // values[j] = tempArr

                                })
                                temp.push(childDatas)

                            }
                            setCustomTaskDis(temp)
                        }
                    })
            }
            setSortingpurpose(temp1)

        }

    }, [taskDistributionTable])

    console.log(sortingpurpose, 'sortingpurpose');


    //Search

    const compliancetaskfilter = (e, type, searchedArray) => {
        let filterTable = sortingpurpose

        if (searchingValue['statutory']) {
            filterTable = filterTable.filter((item) => {
                if ((item["s_prov"].toLowerCase().includes(searchingValue['statutory'].toLowerCase())) || (item["s_prov"].toLowerCase().includes(searchingValue['statutory'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['compliance_task']) {
            filterTable = filterTable.filter((item) => {
                if ((item["comp_name"].toLowerCase().includes(searchingValue['compliance_task'].toLowerCase())) || (item["comp_name"].toLowerCase().includes(searchingValue['compliance_task'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['criticality']) {
            filterTable = filterTable.filter((item) => {
                if ((item["criticality"].toLowerCase().includes(searchingValue['criticality'].toLowerCase())) || (item["criticality"].toLowerCase().includes(searchingValue['criticality'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['descp']) {
            filterTable = filterTable.filter((item) => {
                if ((item["descp"].toLowerCase().includes(searchingValue['descp'].toLowerCase())) || (item["comp_name"].toLowerCase().includes(searchingValue['descp'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['p_cons']) {
            filterTable = filterTable.filter((item) => {
                if ((item["p_cons"].toLowerCase().includes(searchingValue['p_cons'].toLowerCase())) || (item["p_cons"].toLowerCase().includes(searchingValue['p_cons'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['comp_frequency']) {
            filterTable = filterTable.filter((item) => {
                if ((item["frequency"].toLowerCase().includes(searchingValue['comp_frequency'].toLowerCase())) || (item["frequency"].toLowerCase().includes(searchingValue['comp_frequency'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['summary']) {
            filterTable = filterTable.filter((item) => {
                if ((item["summary"].toLowerCase().includes(searchingValue['repeats'].toLowerCase())) || (item["summary"].toLowerCase().includes(searchingValue['repeats'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['trigger']) {
            filterTable = filterTable.filter((item) => {
                if ((item["trigger_before_days"].toString().toLowerCase().includes(searchingValue['trigger'].toLowerCase())) || (item["trigger_before_days"].toString().toLowerCase().includes(searchingValue['trigger'].toLowerCase()))) {
                    return true;
                }
            })
        }

        let arr = [];
        let filteredtemp = []
        let fiteredunitname = []

        if (filterTable && filterTable.length > 0) {
            filterTable &&
                filterTable.length > 0 &&
                filterTable.map(function (user) {
                    if (arr.indexOf(user.level1_statutory_name) < 0)
                        arr.push({ level1_statutory_name: user.level1_statutory_name });
                });
            let uniqle = _.uniqBy(arr, function (e) {
                return e.level1_statutory_name;
            });
            for (let k in uniqle) {
                let filteredObject = _.filter(filterTable, { level1_statutory_name: uniqle[k].level1_statutory_name })
                filteredtemp.push(filteredObject)
            }
            for (let k in filteredtemp) {
                let uniqle1 = _.uniqBy(filteredtemp[k], function (e) {
                    return e.level1_statutory_name;
                });
                let uniq_unitname = uniqle1.map((item) => { return item.level1_statutory_name })
                fiteredunitname.push(uniq_unitname)
            }
            let FinalfilteredObject = []
            for (let k in fiteredunitname) {
                let temp = []
                for (let j in fiteredunitname[k]) {
                    let filteredObject = _.filter(filteredtemp[k], { level1_statutory_name: fiteredunitname[k][j] })
                    temp.push(filteredObject)
                }
                FinalfilteredObject.push(temp)
            }
            setCustomTaskDis(FinalfilteredObject)
            console.log(FinalfilteredObject, 'FinalfilteredObject');
        } else {
            setCustomTaskDis([])
        }
    };

    return (
        <div>
            <div className="back-to-top">
                <ScrollButton />
            </div>
            <div id='page-wrapper' className="page-wrapper">
                <div className="page-titles pb-0 pt-1 page-title-sticky">

                    <div className="row">

                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                    </li>
                                    <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                        <span style={{ fontSize: '16px' }}>Dashboard</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <span style={{ fontSize: '16px' }}>Task Distribution</span>
                                    </li>
                                </ol>
                            </nav>
                        </div>

                        <div
                            className="col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex align-items-center justify-content-md-end justify-content-sm-start">
                            {/* <div> <Tooltip title="" color='#1890ff'>
                                <Button type="primary" shape="round" className='addbutton'

                                    icon={<PlusCircleOutlined />} size='default' onClick={() => {
                                        setShowList(true)
                                        setAddType('add')
                                    }}>
                                    Add
                                </Button>

                            </Tooltip></div> */}


                            {table == false ? <Button icon={<FilterOutlined />} type="primary" className='addbutton' style={{ background: "#0096FF", marginRight: '3%', borderRadius: '15px' }} onClick={() => {
                                setOpen(true)
                            }}
                                size='default' >
                                <Tooltip placement='top' title='Filter'>Filter</Tooltip>
                            </Button> : <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", marginRight: '3%' }}
                                icon={<ArrowLeftOutlined />} size='default' onClick={() => {
                                    setTable(false)
                                    setSearchingValue([])
                                }}>
                                <Tooltip placement='top' title='Back'>Back</Tooltip>
                            </Button>}




                            <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black  full-mode fullscreenradius" >
                                <Tooltip placement='left' title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                            </button>

                            {/* <button onClick={() => {
                                    setShowList(true)
                                    setAddType('add')
                                }} className="icons twitter" style={{ border: 'none', margin: '0px' }}>
                                    <span className="tooltips" style={{ right: '105%' }}>Add</span>
                                    <span><i id={localStorage.getItem('currentTheme')}><PlusCircleOutlined /></i></span>
                                </button> */}


                        </div>
                    </div>

                </div>
                {
                    table == true ?
                        <div className="container-fluid pt-1">
                            <p style={{ fontSize: '16px' }}><b>{`${commonData && commonData.g_name} - ${criticalityValue} Compliance`}</b></p>
                            <div
                                className={
                                    localStorage.getItem("currentTheme") +
                                    " ant-table-wrapper"
                                }
                                style={{ width: '100%' }}
                            >
                                <div className="row">

                                    <div className="ant-table ant-table-bordered ant-table-fixed-header service-provider-table"
                                        //  id='service-table-action'
                                        style={{ width: '100%', height: '100%' }}>
                                        <div className="ant-table-container">
                                            <div className="ant-table-content taskrecalltable service-table">

                                                <table className="table-fixed" >
                                                    <thead className="ant-table-thead" id='comp-approv-thead'
                                                    // style={{ top: is_Fullscreen ? (showValueModal == true ? 0 : '35px') : (showValueModal == true ? 0 : '107px') }}
                                                    >
                                                        <tr>
                                                            <th
                                                                className="ant-table-cell"
                                                                style={{ width: "42px" }}
                                                            >
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <p
                                                                        className={"p-0 mb-0"}
                                                                        style={{
                                                                            // marginLeft: "36%",
                                                                            display: "inline",
                                                                            // color: searchingValue['compliance_task'] ? 'red' : ''
                                                                        }}
                                                                    >
                                                                        #
                                                                    </p>{" "}
                                                                </div>

                                                            </th>
                                                            <th
                                                                className="ant-table-cell"
                                                                style={{ width: "89px" }}
                                                            >
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <p
                                                                        className={"p-0 mb-0"}
                                                                        style={{
                                                                            // marginLeft: "36%",
                                                                            display: "inline",
                                                                            color: searchingValue['statutory'] ? 'red' : ''
                                                                        }}
                                                                    >
                                                                        Statutory
                                                                    </p>{" "}
                                                                    <Popconfirm
                                                                        afterVisibleChange={() => {
                                                                            setFocus(myRef1);
                                                                        }}
                                                                        placement="bottom"
                                                                        title={
                                                                            // <Input
                                                                            //     ref={myRef1}
                                                                            //     autoFocus={'on'}
                                                                            //     placeholder="Compliance Tasks"
                                                                            //     onKeyUp={(e) => {
                                                                            //         compliancetaskfilter(
                                                                            //             e.target.value,
                                                                            //             "Compliance_Task"
                                                                            //         );
                                                                            //     }}
                                                                            // />
                                                                            <div className='position-relative'>
                                                                                <Input
                                                                                    ref={myRef1}
                                                                                    placeholder="Statutory"
                                                                                    autoFocus={'on'}
                                                                                    onChange={(e) => {
                                                                                        setSearchedValue({
                                                                                            ...searchedValue,
                                                                                            statutory: e.target.value
                                                                                        });
                                                                                        searchingValue['statutory'] = e.target.value
                                                                                    }}
                                                                                    value={searchedValue.statutory || undefined}
                                                                                    onKeyUp={(e) => {
                                                                                        compliancetaskfilter(
                                                                                            e.target.value,
                                                                                            "statutory",
                                                                                            "statutory"
                                                                                        );

                                                                                    }}
                                                                                />
                                                                                <CloseCircleOutlined onClick={() => {
                                                                                    myRef1.current.value = "";
                                                                                    setSearchedValue({
                                                                                        ...searchedValue,
                                                                                        statutory: ''
                                                                                    });
                                                                                    searchingValue['statutory'] = ''
                                                                                    compliancetaskfilter(
                                                                                        '',
                                                                                        "statutory",
                                                                                        "statutory"

                                                                                    );

                                                                                }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <SearchOutlined
                                                                            style={{ float: 'right', marginTop: '4px' }}
                                                                        />
                                                                    </Popconfirm>
                                                                </div>

                                                            </th>
                                                            <th
                                                                className="ant-table-cell"
                                                                style={{ width: "180px" }}
                                                            >
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <p
                                                                        className={"p-0 mb-0"}
                                                                        style={{
                                                                            // marginLeft: "36%",
                                                                            display: "inline",
                                                                            color: searchingValue['compliance_task'] ? 'red' : ''
                                                                        }}
                                                                    >
                                                                        Compliance Task
                                                                    </p>{" "}
                                                                    <Popconfirm
                                                                        afterVisibleChange={() => {
                                                                            setFocus(myRef2);
                                                                        }}
                                                                        placement="bottom"
                                                                        title={
                                                                            // <Input
                                                                            //     ref={myRef1}
                                                                            //     autoFocus={'on'}
                                                                            //     placeholder="Compliance Tasks"
                                                                            //     onKeyUp={(e) => {
                                                                            //         compliancetaskfilter(
                                                                            //             e.target.value,
                                                                            //             "Compliance_Task"
                                                                            //         );
                                                                            //     }}
                                                                            // />
                                                                            <div className='position-relative'>
                                                                                <Input
                                                                                    ref={myRef2}
                                                                                    placeholder="Compliance Task"
                                                                                    autoFocus={'on'}
                                                                                    onChange={(e) => {
                                                                                        setSearchedValue({
                                                                                            ...searchedValue,
                                                                                            compliance_task: e.target.value
                                                                                        });
                                                                                        searchingValue['compliance_task'] = e.target.value
                                                                                    }}
                                                                                    value={searchedValue.compliance_task || undefined}
                                                                                    onKeyUp={(e) => {
                                                                                        compliancetaskfilter(
                                                                                            e.target.value,
                                                                                            "compliance_task",
                                                                                            "compliance_task"
                                                                                        );

                                                                                    }}
                                                                                />
                                                                                <CloseCircleOutlined onClick={() => {
                                                                                    myRef2.current.value = "";
                                                                                    setSearchedValue({
                                                                                        ...searchedValue,
                                                                                        compliance_task: ''
                                                                                    });
                                                                                    searchingValue['compliance_task'] = ''
                                                                                    compliancetaskfilter(
                                                                                        '',
                                                                                        "compliance_task",
                                                                                        "compliance_task"

                                                                                    );

                                                                                }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <SearchOutlined
                                                                            style={{ float: 'right', marginTop: '4px' }}
                                                                        />
                                                                    </Popconfirm>
                                                                </div>

                                                            </th>
                                                            <th
                                                                className="ant-table-cell"
                                                                style={{ width: "80px" }}
                                                            >
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <p
                                                                        className={"p-0 mb-0"}
                                                                        style={{
                                                                            // marginLeft: "36%",
                                                                            display: "inline",
                                                                            color: searchingValue['criticality'] ? 'red' : ''
                                                                        }}
                                                                    >
                                                                        Criticality
                                                                    </p>{" "}
                                                                    <Popconfirm
                                                                        afterVisibleChange={() => {
                                                                            setFocus(myRef3);
                                                                        }}
                                                                        placement="bottom"
                                                                        title={
                                                                            // <Input
                                                                            //     ref={myRef1}
                                                                            //     autoFocus={'on'}
                                                                            //     placeholder="Compliance Tasks"
                                                                            //     onKeyUp={(e) => {
                                                                            //         compliancetaskfilter(
                                                                            //             e.target.value,
                                                                            //             "Compliance_Task"
                                                                            //         );
                                                                            //     }}
                                                                            // />
                                                                            <div className='position-relative'>
                                                                                <Input
                                                                                    ref={myRef3}
                                                                                    placeholder="Criticality"
                                                                                    autoFocus={'on'}
                                                                                    onChange={(e) => {
                                                                                        setSearchedValue({
                                                                                            ...searchedValue,
                                                                                            criticality: e.target.value
                                                                                        });
                                                                                        searchingValue['criticality'] = e.target.value
                                                                                    }}
                                                                                    value={searchedValue.criticality || undefined}
                                                                                    onKeyUp={(e) => {
                                                                                        compliancetaskfilter(
                                                                                            e.target.value,
                                                                                            "criticality",
                                                                                            "criticality"
                                                                                        );

                                                                                    }}
                                                                                />
                                                                                <CloseCircleOutlined onClick={() => {
                                                                                    myRef3.current.value = "";
                                                                                    setSearchedValue({
                                                                                        ...searchedValue,
                                                                                        criticality: ''
                                                                                    });
                                                                                    searchingValue['criticality'] = ''
                                                                                    compliancetaskfilter(
                                                                                        '',
                                                                                        "criticality",
                                                                                        "criticality"

                                                                                    );

                                                                                }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <SearchOutlined
                                                                            style={{ float: 'right', marginTop: '4px' }}
                                                                        />
                                                                    </Popconfirm>
                                                                </div>

                                                            </th>
                                                            <th
                                                                className="ant-table-cell"
                                                                style={{ width: "180px" }}
                                                            >
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <p
                                                                        className={"p-0 mb-0"}
                                                                        style={{
                                                                            // marginLeft: "36%",
                                                                            display: "inline",
                                                                            color: searchingValue['descp'] ? 'red' : ''
                                                                        }}
                                                                    >
                                                                        Compliance Description
                                                                    </p>{" "}
                                                                    <Popconfirm
                                                                        afterVisibleChange={() => {
                                                                            setFocus(myRef4);
                                                                        }}
                                                                        placement="bottom"
                                                                        title={
                                                                            // <Input
                                                                            //     ref={myRef1}
                                                                            //     autoFocus={'on'}
                                                                            //     placeholder="Compliance Tasks"
                                                                            //     onKeyUp={(e) => {
                                                                            //         compliancetaskfilter(
                                                                            //             e.target.value,
                                                                            //             "Compliance_Task"
                                                                            //         );
                                                                            //     }}
                                                                            // />
                                                                            <div className='position-relative'>
                                                                                <Input
                                                                                    ref={myRef4}
                                                                                    placeholder="Compliance Description"
                                                                                    autoFocus={'on'}
                                                                                    onChange={(e) => {
                                                                                        setSearchedValue({
                                                                                            ...searchedValue,
                                                                                            descp: e.target.value
                                                                                        });
                                                                                        searchingValue['descp'] = e.target.value
                                                                                    }}
                                                                                    value={searchedValue.descp || undefined}
                                                                                    onKeyUp={(e) => {
                                                                                        compliancetaskfilter(
                                                                                            e.target.value,
                                                                                            "descp",
                                                                                            "descp"
                                                                                        );

                                                                                    }}
                                                                                />
                                                                                <CloseCircleOutlined onClick={() => {
                                                                                    myRef4.current.value = "";
                                                                                    setSearchedValue({
                                                                                        ...searchedValue,
                                                                                        descp: ''
                                                                                    });
                                                                                    searchingValue['descp'] = ''
                                                                                    compliancetaskfilter(
                                                                                        '',
                                                                                        "descp",
                                                                                        "descp"

                                                                                    );

                                                                                }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <SearchOutlined
                                                                            style={{ float: 'right', marginTop: '4px' }}
                                                                        />
                                                                    </Popconfirm>
                                                                </div>

                                                            </th>
                                                            <th
                                                                className="ant-table-cell"
                                                                style={{ width: "80px" }}
                                                            >
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <p
                                                                        className={"p-0 mb-0"}
                                                                        style={{
                                                                            // marginLeft: "36%",
                                                                            display: "inline",
                                                                            color: searchingValue['p_cons'] ? 'red' : ''
                                                                        }}
                                                                    >
                                                                        Penal Consequences
                                                                    </p>{" "}
                                                                    <Popconfirm
                                                                        afterVisibleChange={() => {
                                                                            setFocus(myRef5);
                                                                        }}
                                                                        placement="bottom"
                                                                        title={
                                                                            // <Input
                                                                            //     ref={myRef1}
                                                                            //     autoFocus={'on'}
                                                                            //     placeholder="Compliance Tasks"
                                                                            //     onKeyUp={(e) => {
                                                                            //         compliancetaskfilter(
                                                                            //             e.target.value,
                                                                            //             "Compliance_Task"
                                                                            //         );
                                                                            //     }}
                                                                            // />
                                                                            <div className='position-relative'>
                                                                                <Input
                                                                                    ref={myRef5}
                                                                                    placeholder="Penal Consequences"
                                                                                    autoFocus={'on'}
                                                                                    onChange={(e) => {
                                                                                        setSearchedValue({
                                                                                            ...searchedValue,
                                                                                            p_cons: e.target.value
                                                                                        });
                                                                                        searchingValue['p_cons'] = e.target.value
                                                                                    }}
                                                                                    value={searchedValue.p_cons || undefined}
                                                                                    onKeyUp={(e) => {
                                                                                        compliancetaskfilter(
                                                                                            e.target.value,
                                                                                            "p_cons",
                                                                                            "p_cons"
                                                                                        );

                                                                                    }}
                                                                                />
                                                                                <CloseCircleOutlined onClick={() => {
                                                                                    myRef5.current.value = "";
                                                                                    setSearchedValue({
                                                                                        ...searchedValue,
                                                                                        p_cons: ''
                                                                                    });
                                                                                    searchingValue['p_cons'] = ''
                                                                                    compliancetaskfilter(
                                                                                        '',
                                                                                        "p_cons",
                                                                                        "p_cons"

                                                                                    );

                                                                                }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <SearchOutlined
                                                                            style={{ float: 'right', marginTop: '4px' }}
                                                                        />
                                                                    </Popconfirm>
                                                                </div>

                                                            </th>
                                                            <th
                                                                className="ant-table-cell"
                                                                style={{ width: "80px" }}
                                                            >
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <p
                                                                        className={"p-0 mb-0"}
                                                                        style={{
                                                                            // marginLeft: "36%",
                                                                            display: "inline",
                                                                            color: searchingValue['comp_frequency'] ? 'red' : ''
                                                                        }}
                                                                    >
                                                                        Compliance Frequency
                                                                    </p>{" "}
                                                                    <Popconfirm
                                                                        afterVisibleChange={() => {
                                                                            setFocus(myRef6);
                                                                        }}
                                                                        placement="bottom"
                                                                        title={
                                                                            // <Input
                                                                            //     ref={myRef1}
                                                                            //     autoFocus={'on'}
                                                                            //     placeholder="Compliance Tasks"
                                                                            //     onKeyUp={(e) => {
                                                                            //         compliancetaskfilter(
                                                                            //             e.target.value,
                                                                            //             "Compliance_Task"
                                                                            //         );
                                                                            //     }}
                                                                            // />
                                                                            <div className='position-relative'>
                                                                                <Input
                                                                                    ref={myRef6}
                                                                                    placeholder="Compliance Frequency"
                                                                                    autoFocus={'on'}
                                                                                    onChange={(e) => {
                                                                                        setSearchedValue({
                                                                                            ...searchedValue,
                                                                                            comp_frequency: e.target.value
                                                                                        });
                                                                                        searchingValue['comp_frequency'] = e.target.value
                                                                                    }}
                                                                                    value={searchedValue.comp_frequency || undefined}
                                                                                    onKeyUp={(e) => {
                                                                                        compliancetaskfilter(
                                                                                            e.target.value,
                                                                                            "comp_frequency",
                                                                                            "comp_frequency"
                                                                                        );

                                                                                    }}
                                                                                />
                                                                                <CloseCircleOutlined onClick={() => {
                                                                                    myRef6.current.value = "";
                                                                                    setSearchedValue({
                                                                                        ...searchedValue,
                                                                                        comp_frequency: ''
                                                                                    });
                                                                                    searchingValue['comp_frequency'] = ''
                                                                                    compliancetaskfilter(
                                                                                        '',
                                                                                        "comp_frequency",
                                                                                        "comp_frequency"

                                                                                    );

                                                                                }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <SearchOutlined
                                                                            style={{ float: 'right', marginTop: '4px' }}
                                                                        />
                                                                    </Popconfirm>
                                                                </div>

                                                            </th>
                                                            <th
                                                                className="ant-table-cell"
                                                                style={{ width: "80px" }}
                                                            >
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <p
                                                                        className={"p-0 mb-0"}
                                                                        style={{
                                                                            // marginLeft: "36%",
                                                                            display: "inline",
                                                                            color: searchingValue['repeats'] ? 'red' : ''
                                                                        }}
                                                                    >
                                                                        Repeats
                                                                    </p>{" "}
                                                                    <Popconfirm
                                                                        afterVisibleChange={() => {
                                                                            setFocus(myRef7);
                                                                        }}
                                                                        placement="bottom"
                                                                        title={
                                                                            // <Input
                                                                            //     ref={myRef1}
                                                                            //     autoFocus={'on'}
                                                                            //     placeholder="Compliance Tasks"
                                                                            //     onKeyUp={(e) => {
                                                                            //         compliancetaskfilter(
                                                                            //             e.target.value,
                                                                            //             "Compliance_Task"
                                                                            //         );
                                                                            //     }}
                                                                            // />
                                                                            <div className='position-relative'>
                                                                                <Input
                                                                                    ref={myRef7}
                                                                                    placeholder="Repeats"
                                                                                    autoFocus={'on'}
                                                                                    onChange={(e) => {
                                                                                        setSearchedValue({
                                                                                            ...searchedValue,
                                                                                            repeats: e.target.value
                                                                                        });
                                                                                        searchingValue['repeats'] = e.target.value
                                                                                    }}
                                                                                    value={searchedValue.repeats || undefined}
                                                                                    onKeyUp={(e) => {
                                                                                        compliancetaskfilter(
                                                                                            e.target.value,
                                                                                            "repeats",
                                                                                            "repeats"
                                                                                        );

                                                                                    }}
                                                                                />
                                                                                <CloseCircleOutlined onClick={() => {
                                                                                    myRef7.current.value = "";
                                                                                    setSearchedValue({
                                                                                        ...searchedValue,
                                                                                        repeats: ''
                                                                                    });
                                                                                    searchingValue['repeats'] = ''
                                                                                    compliancetaskfilter(
                                                                                        '',
                                                                                        "repeats",
                                                                                        "repeats"

                                                                                    );

                                                                                }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <SearchOutlined
                                                                            style={{ float: 'right', marginTop: '4px' }}
                                                                        />
                                                                    </Popconfirm>
                                                                </div>

                                                            </th>
                                                            <th
                                                                className="ant-table-cell"
                                                                style={{ width: "80px" }}
                                                            >
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <p
                                                                        className={"p-0 mb-0"}
                                                                        style={{
                                                                            // marginLeft: "36%",
                                                                            display: "inline",
                                                                            // color: searchingValue['trigger'] ? 'red' : ''
                                                                        }}
                                                                    >
                                                                        Trigger Before
                                                                    </p>{" "}
                                                                    <Popconfirm
                                                                        afterVisibleChange={() => {
                                                                            setFocus(myRef8);
                                                                        }}
                                                                        placement="bottomLeft"
                                                                        title={
                                                                            // <Input
                                                                            //     ref={myRef1}
                                                                            //     autoFocus={'on'}
                                                                            //     placeholder="Trigger Before"
                                                                            //     onKeyUp={(e) => {
                                                                            //         compliancetaskfilter(
                                                                            //             e.target.value,
                                                                            //             "Compliance_Task"
                                                                            //         );
                                                                            //     }}
                                                                            // />
                                                                            <div className='position-relative'>
                                                                                <Input
                                                                                    ref={myRef8}
                                                                                    placeholder="Trigger Before"
                                                                                    autoFocus={'on'}
                                                                                    onChange={(e) => {
                                                                                        setSearchedValue({
                                                                                            ...searchedValue,
                                                                                            trigger: e.target.value
                                                                                        });
                                                                                        searchingValue['trigger'] = e.target.value
                                                                                    }}
                                                                                    value={searchedValue.trigger || undefined}
                                                                                    onKeyUp={(e) => {
                                                                                        compliancetaskfilter(
                                                                                            e.target.value,
                                                                                            "trigger",
                                                                                            "trigger"
                                                                                        );

                                                                                    }}
                                                                                />
                                                                                <CloseCircleOutlined onClick={() => {
                                                                                    myRef8.current.value = "";
                                                                                    setSearchedValue({
                                                                                        ...searchedValue,
                                                                                        trigger: ''
                                                                                    });
                                                                                    searchingValue['trigger'] = ''
                                                                                    compliancetaskfilter(
                                                                                        '',
                                                                                        "trigger",
                                                                                        "trigger"

                                                                                    );

                                                                                }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <SearchOutlined
                                                                            style={{ float: 'right', marginTop: '4px' }}
                                                                        />
                                                                    </Popconfirm>
                                                                </div>

                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tr>
                                                        <td colSpan={9}>
                                                            <Collapse className='cric mt-2' defaultActiveKey={['0']}>
                                                                {customTaskDisTable && customTaskDisTable.length > 0 &&
                                                                    customTaskDisTable.map((item, i) => {
                                                                        console.log(item.level1_statutory_name, 'item');
                                                                        // let unitName = item.ap_compliances
                                                                        // let keys = Object.keys(unitName);
                                                                        // let values = Object.values(unitName);
                                                                        return (
                                                                            <Panel style={{ marginBottom: '5px' }} header={item[0][0].level1_statutory_name}>
                                                                                <table>
                                                                                    <tbody className="ant-table-tbody">
                                                                                        {item && item.length > 0 && item.map((items, m) => {
                                                                                            console.log(items, 'items');
                                                                                            return (<>
                                                                                                <tr className="ant-table-row ant-table-row-level-0">
                                                                                                    <td colSpan={8}>
                                                                                                        <h6 className='m-2 ml-2 mb-2'>{items[0].leUname}</h6>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                {items.map((childItems, s) => {
                                                                                                    return (
                                                                                                        <tr className="ant-table-row ant-table-row-level-0">
                                                                                                            <td style={{ width: '50px' }}
                                                                                                                className="ant-table-cell border-end">{childItems.index}</td>
                                                                                                            <td
                                                                                                                className="ant-table-cell border-end">{childItems.s_prov}</td>
                                                                                                            <td style={{ width: '225px' }}
                                                                                                                className="ant-table-cell border-end">
                                                                                                                {
                                                                                                                    childItems.download_url_list ?
                                                                                                                        <a href={`${urls + childItems.download_url_list}`} target='_blank'>{childItems.comp_name}</a> :
                                                                                                                        <span>{childItems.comp_name}</span>
                                                                                                                }</td>
                                                                                                            <td style={{ width: '107px' }}
                                                                                                                className="ant-table-cell border-end">{childItems.criticality}</td>
                                                                                                            <td style={{ width: '225px' }}
                                                                                                                className="ant-table-cell border-end ">{childItems.descp}</td>
                                                                                                            {/* <td style={{ width: '299px' }}
                                                                                                                            className="ant-table-cell border-end">
                                                                                                                            {childItems.compfielaw_url != '' ?
                                                                                                                                <>
                                                                                                                                    <Popover content={content} title="">
                                                                                                                                        <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
                                                                                                                                    </Popover>
                                                                                                                                    <Tooltip style={{ marginRight: '20%' }} title='Compfie Law Knowlegde Portal'>
                                                                                                                                        <a href={`${childItems.compfielaw_url}`}>
                                                                                                                                            <BookTwoTone />
                                                                                                                                        </a>
                                                                                                                                    </Tooltip>
                                                                                                                                </> :
                                                                                                                                <Popover content={content} title="">
                                                                                                                                    <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
                                                                                                                                </Popover>
                                                                                                                            }
                                                                                                                            {childItems.comp_name}</td> */}
                                                                                                            <td style={{ width: '147px' }}
                                                                                                                className="ant-table-cell border-end">{childItems.p_cons}</td>
                                                                                                            <td style={{ width: '123px' }}
                                                                                                                className="ant-table-cell border-end">{childItems.frequency}</td>
                                                                                                            <td style={{ width: '101px' }}
                                                                                                                className="ant-table-cell border-end">{childItems.summary}</td>
                                                                                                            <td style={{ width: '98px' }}
                                                                                                                className="ant-table-cell border-end">{childItems.trigger_before_days != 'null Days' ? childItems.trigger_before_days : ''}</td>
                                                                                                        </tr>
                                                                                                    )
                                                                                                })}
                                                                                            </>)
                                                                                        })}
                                                                                    </tbody >
                                                                                </table>

                                                                                {/* <Table
                                                                                                scroll={{ x: 1200 }}
                                                                                                size={'small'}
                                                                                                columns={columns}
                                                                                                dataSource={values[j]}
                                                                                                bordered
                                                                                                id='service-user-priv'
                                                                                                className={localStorage.getItem("currentTheme")}
                                                                                                pagination={false}
                                                                                                onChange={handleChange}


                                                                                            /> */}

                                                                            </Panel>
                                                                        )
                                                                    })
                                                                }
                                                            </Collapse>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {tableData && tableData.r_drill_down_data && tableData.r_drill_down_data.length > 0 ?
                                dataCount == showmoreCount ? false : <div className='col-md-12 mt-2'>
                                    <div className='row'>
                                        <div className='col-md-4'></div>
                                        <div className='col-md-4'>
                                            <button className="btn btn-success" style={{ float: "center", marginLeft: "28%", borderRadius: '15px' }}
                                                onClick={showmore}
                                            >Show more records<i> <DownOutlined /></i></button>
                                        </div>
                                        <div className='col-md-4'></div>
                                    </div>
                                </div> : ''
                            }


                            {/* <Table dataSource={dataSource} columns={columns} pagination={false}
                    className='userprivclass' /> */}
                        </div> :
                        <div className="container-fluid pt-1">
                            <div className="row" >
                                <div className="col-md-12">

                                </div>
                                {/* {(data && data.length > 0) && ((data && data.length && data[0].assigned_count == 0) && (data && data.length && data[0].not_applicable_count == 0) && (data && data.length && data[0].not_opted_count == 0) && (data && data.length && data[0].unassign_count == 0)) ? */}

                                {/* <p style={{ marginTop: '18%', marginLeft: '35%', marginBottom: '18%' }}>No Data to Display </p> : */}
                                <>
                                    <div className="col-md-12 mt-0">
                                        <>
                                            {/* <Card style={{ borderRadius: '15px' }}>
                                                <div id="taskchartdiv" style={{ width: "100%", marginTop: '10px', height: "500px" }}></div>
                                            </Card> */}
                                            {((open === true) && (fieldTypeClick != '') && (go === false)) ? <Spin size='large' style={{ marginTop: '17%', marginLeft: '45%', marginBottom: '10%' }} /> :
                                                <Card style={{ borderRadius: '15px' }}>
                                                    {JSON.parse(isCountryValueTrue) === true ?
                                                        <p style={{ width: "100%", marginTop: '20%', marginLeft: '43%', height: "250px" }}>No Data to Display</p>
                                                        :
                                                        <div id="taskchartdiv" style={{ width: "100%", marginTop: '10px', height: "500px" }}></div>
                                                    }
                                                </Card>}
                                            {(data && data.length > 0) && ((data && data.length && data[0].assigned_count == 0) && (data && data.length && data[0].not_applicable_count == 0) && (data && data.length && data[0].not_opted_count == 0) && (data && data.length && data[0].unassign_count == 0)) ? '' :
                                                <div className="col-md-12 mt-3">
                                                    <div className="row">
                                                        <div className="col-md-3">

                                                        </div>
                                                        <div className="col-md-3">
                                                        </div>
                                                        <div className="col-md-6  text-md-end text-lg-end " >
                                                            <label htmlFor=""><b>Time Zone :</b></label>
                                                            <Select
                                                                allowClear={false}
                                                                size="default"
                                                                onChange={(data) => {
                                                                    setTimeZone(data)
                                                                    setGeneratedOn(true)
                                                                    const payload = [
                                                                        authtoken,
                                                                        {
                                                                            "session_token": authtoken,
                                                                            "request": [
                                                                                "GetDateTime_ByZone",
                                                                                {
                                                                                    "tz_name": data
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                    getTimeZoneData({
                                                                        payload: payload,
                                                                        paramid: paramid
                                                                    })

                                                                }}
                                                                placeholder="Enter Time Zone"
                                                                style={{ marginLeft: '5%' }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            // value={compfie.user_Group}

                                                            >

                                                                {TimeZoneValue && TimeZoneValue.tz_list.length > 0 && TimeZoneValue.tz_list.map((item, i) => {

                                                                    return (

                                                                        <Option key={item.tz_name}>

                                                                            {item.tz_name}

                                                                        </Option>

                                                                    );

                                                                })}
                                                                {/* );
                                                })} */}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                </div>}

                                        </>
                                    </div>

                                </>
                                {/* } */}
                            </div>
                        </div>
                }

                <Drawer title="FILTER BY" className={localStorage.getItem("currentTheme") + ' resize-drawer'} placement="right" closable={false} onClose={false} open={open}
                    extra={
                        <Space>
                            <CloseCircleOutlined style={{ marginTop: '2%', marginLeft: '75', fontSize: '140%' }} onClick={onClose} />
                        </Space>
                    }
                >
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4">
                                <label><b>Country</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Country"
                                    mode='multiple'
                                    maxTagCount='responsive'
                                    value={filterData.country ? filterData.country : undefined}
                                    defaultValue={filterData.country ? filterData.country : undefined}
                                    key={filterData.country ? filterData.country : undefined}
                                    // onChange={(data, values) => {
                                    //     setFieldTypeClick('Country')
                                    //     setFilterData({
                                    //         ...filterData,
                                    //         country: data,
                                    //         domain: ''
                                    //     })
                                    //     setFilterDataNames({
                                    //         ...filterDataNames,
                                    //         country: values.children,
                                    //         domain: '',
                                    //         legalentity: '',
                                    //         division: '',
                                    //         unit: '',
                                    //         business: '',
                                    //         category: '',
                                    //         unit: ''
                                    //     })
                                    //     setType('')

                                    // }
                                    // }
                                    onChange={(option) => HandelCountryChange(option)}
                                    onDeselect={(data) => {

                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {field && field.country &&
                                        field.country.length > 0 &&
                                        field.country.map((item, i) => {
                                            return (
                                                <Option key={item.c_id}>
                                                    {item.c_name}
                                                </Option>
                                            );
                                        })}

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label><b>Domain</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Domain"
                                    mode='multiple'
                                    maxTagCount='responsive'
                                    value={filterData.domain ? filterData.domain : undefined}
                                    defaultValue={filterData.domain ? filterData.domain : undefined}
                                    key={filterData.domain ? filterData.domain : undefined}
                                    // onChange={(data, values) => {
                                    //     console.log(data,'data-domain');
                                    //     let filteredBusinessGroup = _.filter(commonData && commonData.bg_groups, { d_id: Number(data) })
                                    //     let filteredDivision = _.filter(commonData && commonData.div_infos, { d_id: Number(data) })
                                    //     // let filteredCategory = _.filter(commonData && commonData.bg_groups,{d_id:Number(data)})
                                    //     // let filteredUnit = _.filter(commonData && commonData.bg_groups,{d_id:Number(data)})
                                    //     let filteredLegalEntity = commonData && commonData.le_did_infos.filter(item => item.d_ids.includes(Number(data)))
                                    //     let filteredCategory = _.filter(commonData.cat_info, { div_id: Number(filteredDivision[0].div_id) })
                                    //     let filteredUnit = _.filter(commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
                                    //     let temp = []
                                    //     temp.push({
                                    //         'bg_id': filteredCategory[0].bg_id,
                                    //         'c_id': filteredCategory[0].c_id,
                                    //         'cat_id': filteredCategory[0].cat_id,
                                    //         'cat_name': filteredCategory[0].cat_name,
                                    //         'd_id': filteredCategory[0].d_id,
                                    //         'div_id': filteredCategory[0].div_id,
                                    //         'le_id': filteredCategory[0].le_id,
                                    //         'div_name': division[0].div_name
                                    //     })
                                    //     setCategory(temp)
                                    //     setUnit(filteredUnit)
                                    //     setfield({
                                    //         ...field,
                                    //         legalEntity: filteredLegalEntity,
                                    //         business: filteredBusinessGroup
                                    //     })
                                    //     let temp1=[]
                                    //     for(let i in commonData.d_info){
                                    //         temp1.push(commonData.d_info[i].d_id)
                                    //     }
                                    //     setFilterData({
                                    //         ...filterData,
                                    //         domain: temp1
                                    //     })
                                    //     setFilterDataNames({
                                    //         ...filterDataNames,
                                    //         domain: values.children,
                                    //         legalentity: '',
                                    //         division: '',
                                    //         unit: '',
                                    //         business: '',
                                    //         category: '',
                                    //         unit: ''
                                    //     })
                                    //     setDivision(filteredDivision)
                                    //     // let temp = []
                                    //     // temp.push({
                                    //     //     'bg_id': filteredCategory[0].bg_id,
                                    //     //     'c_id': filteredCategory[0].c_id,
                                    //     //     'cat_id': filteredCategory[0].cat_id,
                                    //     //     'cat_name': filteredCategory[0].cat_name,
                                    //     //     'd_id': filteredCategory[0].d_id,
                                    //     //     'div_id': filteredCategory[0].div_id,
                                    //     //     'le_id': filteredCategory[0].le_id,
                                    //     //     'div_name': filteredDivision[0].div_name
                                    //     // })
                                    //     // setCategory(temp)
                                    //     // setUnit(filteredUnit)
                                    // }
                                    // }
                                    onChange={(option) => HandelChange(option)}
                                    // onDeselect={(data) => {
                                    //     console.log(data,'data-deselect');
                                    //     filterData && filterData.domain.splice(data,1)
                                    // }}
                                    style={{ width: '100%' }}
                                    disabled={filterData.country == '' ? true : false}
                                >
                                    {domainListData && domainListData.length > 0 &&
                                        domainListData.map((finalList_unitlist) => (
                                            <OptGroup label={finalList_unitlist.label}>
                                                {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                    <Option value={subUnitData.d_id}>{subUnitData.d_name}</Option>

                                                ))}
                                            </OptGroup>
                                        ))}

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-1">
                                <input type='checkbox' checked={checkBox === true ? true : false} onChange={() => {
                                    setType('Group')
                                    setCheckBox(!checkBox)
                                }}></input>
                            </div>
                            <div className="col-md-4">
                                <label><b>Group</b></label>
                            </div>
                            {/* <div className="col-md-2">

                        </div> */}
                            <div className="col-md-1">
                                {/* <input type='checkbox' onChange={(e) => {
                                if (e.target.value) {
                                    setFilterData({
                                        ...filterData,
                                        chartType: 'Consolidated'
                                    })
                                }
                            }}></input> */}
                            </div>
                            <div className="col-md-4">
                                {/* <label><b>Consolidated</b></label> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-6">

                                <label><b>From Date</b></label>
                                <DatePicker
                                    disabled={true}
                                    allowClear={() => {
                                        setFilterData({
                                            ...filterData,
                                            from_date: null,
                                        })
                                    }}
                                    // value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                    // value={consolidatedData.from_date}
                                    // disabled={consolidatedData.domainy == '' ? true : false}
                                    onChange={(date, dateString) => {

                                        console.log(dateString, 'dateString');
                                        setFilterData({
                                            ...filterData,
                                            from_date: dateString,
                                        })

                                    }}

                                />

                            </div>
                            <div className="col-md-6">

                                <label><b>To Date</b></label>

                                <DatePicker
                                    // value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                    // value={consolidatedData.from_date}
                                    // disabled={consolidatedData.domainy == '' ? true : false}
                                    // onChange={(date, dateString) => {

                                    //     console.log(dateString, 'dateString');
                                    //     setConsolidatedData({
                                    //         ...consolidatedData,
                                    //         from_date: dateString,
                                    //         to_date: moment(dateString).add(3, 'months')
                                    //     })

                                    // }}
                                    disabled={true}
                                    onChange={(date, dateString) => {

                                        console.log(dateString, 'dateString');
                                        if (dateString != undefined) {
                                            setFilterData({
                                                ...filterData,
                                                to_date: dateString,
                                            })
                                        }
                                        else {
                                            setFilterData({
                                                ...filterData,
                                                to_date: null,
                                            })
                                        }


                                    }}

                                />

                            </div>
                        </div>
                    </div>

                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldTypeClick == 'BusinessGroup' ? 'blue' : 'black' }}><b>Business Group</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Business Group"
                                    value={filterDataNames.business ? filterDataNames.business : undefined}
                                    defaultValue={filterDataNames.business ? filterDataNames.business : undefined}
                                    key={filterDataNames.business ? filterDataNames.business : undefined}
                                    onClick={() => {
                                        setFieldTypeClick('BusinessGroup')
                                    }}
                                    onChange={(data, values) => {
                                        setType('')
                                        setErrorField('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        let filteredEntity = _.filter(commonData && commonData.le_did_infos, { bg_id: Number(data) })
                                        // let filteredDivision = _.filter(commonData && commonData.div_infos, { bg_id: Number(data) })
                                        // console.log(filteredDivision, 'filteredDivision');
                                        // let uniqueDiv = []
                                        // if (filteredDivision && filteredDivision.length > 0) {
                                        //     uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                                        //         return e.div_id;
                                        //     });
                                        // }
                                        // setDivision(uniqueDiv)
                                        // let filteredCategory = _.filter(commonData && commonData.cat_info, { bg_id: Number(data) })
                                        // let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
                                        setfield({
                                            ...field,
                                            legalEntity: filteredEntity
                                        })
                                        setLegalEntityArray(filteredEntity)
                                        setFieldType('BusinessGroup')
                                        setFilterData({
                                            ...filterData,
                                            business: data,
                                        })
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            business: values.children,
                                            legalentity: '',
                                            division: '',
                                            unit: '',
                                            category: '',
                                            unit: ''
                                        })
                                        // let temp = []
                                        // temp.push({
                                        //     'bg_id': filteredCategory[0].bg_id,
                                        //     'c_id': filteredCategory[0].c_id,
                                        //     'cat_id': filteredCategory[0].cat_id,
                                        //     'cat_name': filteredCategory[0].cat_name,
                                        //     'd_id': filteredCategory[0].d_id,
                                        //     'div_id': filteredCategory[0].div_id,
                                        //     'le_id': filteredCategory[0].le_id,
                                        //     'div_name': filteredDivision[0].div_name
                                        // })
                                        // setCategory(temp)
                                        // setUnit(filteredUnit)

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}
                                >
                                    {field && field.business && field.business.length && field.business.map((item, i) => {
                                        return (
                                            <Option key={item.bg_id}>
                                                {item.bg_name}
                                            </Option>
                                        );
                                    })}


                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldTypeClick == 'LegalEntity' ? 'blue' : 'black' }}><b>Legal Entity</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    defaultValue={filterDataNames.legalentity ? filterDataNames.legalentity : undefined}
                                    key={filterDataNames.legalentity ? filterDataNames.legalentity : undefined}
                                    allowClear={false}
                                    size="default"
                                    value={filterDataNames.legalentity ? filterDataNames.legalentity : undefined}
                                    placeholder="Enter Legal Entity"
                                    onClick={() => {
                                        setFieldTypeClick('LegalEntity')
                                    }}
                                    onChange={(data, values) => {
                                        setType('')
                                        setErrorField('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        // let filteredLegalEntity = _.filter(commonData && commonData.le_did_infos, { le_id: data })
                                        // let filteredDivision = _.filter(commonData && commonData.div_infos, { le_id: data })
                                        // let filteredCategory = _.filter(commonData && commonData.cat_info, { le_id: data })
                                        let filteredLegalEntity = _.filter(commonData && commonData.le_did_infos, { le_id: data })
                                        setLegalEntityArray(filteredLegalEntity)
                                        // // setfield({
                                        // //     ...field,
                                        // //     legalEntity: filteredLegalEntity
                                        // // })
                                        // if (filteredDivision && filteredDivision.length > 0) {
                                        //     let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
                                        //     setUnit(filteredUnit)
                                        // }
                                        // else {
                                        //     let filteredUnit = _.filter(commonData && commonData.chart_units, { le_id: data })
                                        //     console.log(filteredUnit, 'filteredUnit555');
                                        //     setUnit(filteredUnit)
                                        // }

                                        // console.log(filteredDivision, 'filteredDivisionLeg');
                                        setFilterData({
                                            ...filterData,
                                            legalentity: data,
                                            leIds: data
                                        })
                                        setFieldType('LegalEntity')
                                        setGo(false)
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            legalentity: values.children,
                                            division: '',
                                            unit: '',
                                            category: '',
                                            unit: ''
                                        })
                                        // let uniqueDiv = []
                                        // if (filteredDivision && filteredDivision.length > 0) {
                                        //     uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                                        //         return e.div_id;
                                        //     });
                                        // }
                                        // setDivision(uniqueDiv)
                                        // let temp = []
                                        // temp.push({
                                        //     'bg_id': filteredCategory[0].bg_id,
                                        //     'c_id': filteredCategory[0].c_id,
                                        //     'cat_id': filteredCategory[0].cat_id,
                                        //     'cat_name': filteredCategory[0].cat_name,
                                        //     'd_id': filteredCategory[0].d_id,
                                        //     'div_id': filteredCategory[0].div_id,
                                        //     'le_id': filteredCategory[0].le_id,
                                        //     'div_name': filteredDivision[0].div_name
                                        // })
                                        // setCategory(temp)
                                        // setUnit(filteredUnit)
                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >

                                    {field && field.legalEntity && field.legalEntity.length && field.legalEntity.map((item, i) => {
                                        return (
                                            <Option key={item.le_id}>
                                                {item.le_name}
                                            </Option>
                                        );
                                    })}

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldTypeClick == 'Division' ? 'blue' : 'black' }}><b>Division</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Division"
                                    value={filterDataNames.division ? filterDataNames.division : undefined}
                                    defaultValue={filterData.division ? filterData.division : undefined}
                                    key={filterData.division ? filterData.division : undefined}
                                    onClick={() => {
                                        setFieldTypeClick('Division')
                                    }}
                                    onChange={(data, values) => {
                                        setType('')
                                        setErrorField('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        let filteredDivision = _.find(commonData && commonData.div_infos, { div_id: Number(data) })
                                        let getLEname = _.find(commonData && commonData.le_did_infos, { le_id: filteredDivision.le_id })
                                        let filteredCategory = _.filter(commonData && commonData.cat_info, { div_id: Number(data) })
                                        let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(data) })
                                        setFilterData({
                                            ...filterData,
                                            division: data
                                        })

                                        // let subData = _.filter(commonData && commonData.cat_info, { div_id: Number(data) })
                                        console.log(filteredDivision, 'filteredDivision');
                                        // setDivisionObj(filteredDivision)
                                        // setLEObj(getLEname)
                                        // setCategory(subData)
                                        setFieldType('Division')
                                        setGo(false)
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            division: values.children,
                                            unit: '',
                                            category: '',
                                        })
                                        setUnit(filteredUnit)
                                        let temp = []
                                        temp.push({
                                            'bg_id': filteredCategory[0].bg_id,
                                            'c_id': filteredCategory[0].c_id,
                                            'cat_id': filteredCategory[0].cat_id,
                                            'cat_name': filteredCategory[0].cat_name,
                                            'd_id': filteredCategory[0].d_id,
                                            'div_id': filteredCategory[0].div_id,
                                            'le_id': filteredCategory[0].le_id,
                                            'div_name': filteredDivision.div_name
                                        })
                                        setCategory(temp)

                                        console.log(category, 'catStateeeee');

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >

                                    {divisionListData && divisionListData.length > 0 &&
                                        divisionListData.map((finalList_unitlist) => {
                                            if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist.options.length > 0) {
                                                return (
                                                    <OptGroup label={finalList_unitlist.label}>
                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                            <Option value={subUnitData.div_id}>{subUnitData.div_name}</Option>

                                                        ))}
                                                    </OptGroup>
                                                )
                                            }
                                            else {
                                                return null
                                            }

                                        }
                                        )}


                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldTypeClick == 'Category' ? 'blue' : 'black' }}><b>Category</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Category"
                                    value={filterDataNames.category ? filterDataNames.category : undefined}
                                    defaultValue={filterData.category ? filterData.category : undefined}
                                    key={filterData.category ? filterData.category : undefined}
                                    onClick={() => {
                                        setFieldTypeClick('Category')
                                    }}
                                    onChange={(data, values) => {
                                        setType('')
                                        setErrorField('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        setFilterData({
                                            ...filterData,
                                            category: data,
                                            unit: '',
                                        })
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            category: values.children
                                        })
                                        let subData = _.filter(commonData && commonData.chart_units, { category_id: Number(data) })
                                        setUnit(subData)
                                        setFieldType('Category')
                                        setGo(false)

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >
                                    {categoryListData && categoryListData.length > 0 &&
                                        categoryListData.map((finalList_unitlist) => {
                                            console.log(finalList_unitlist, 'finalList_unitlist');
                                            if (finalList_unitlist.options && finalList_unitlist.options.length > 0) {
                                                return (
                                                    <OptGroup label={finalList_unitlist.label}>
                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                            <Option value={subUnitData.cat_id}>{`${subUnitData.div_name}-${subUnitData.cat_name}`}</Option>

                                                        ))}
                                                    </OptGroup>
                                                )
                                            }
                                            else {
                                                return null
                                            }
                                        }

                                        )}



                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldTypeClick == 'Unit' ? 'blue' : 'black' }}><b>Unit</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Unit"
                                    value={filterDataNames.unit ? filterDataNames.unit : undefined}
                                    defaultValue={filterDataNames.unit ? filterDataNames.unit : undefined}
                                    key={filterDataNames.unit ? filterDataNames.unit : undefined}
                                    onClick={() => {
                                        setFieldTypeClick('Unit')
                                    }}
                                    onChange={(data, values) => {
                                        setType('')
                                        setErrorField('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        setFilterData({
                                            ...filterData,
                                            unit: data
                                        })
                                        setFieldType('Unit')
                                        setGo(false)
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            unit: values.children
                                        })

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >
                                    {unitListData && unitListData.length > 0 &&
                                        unitListData.map((finalList_unitlist) => {
                                            if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist.options.length > 0) {
                                                return (
                                                    <OptGroup label={finalList_unitlist.label}>
                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                            <Option value={subUnitData.u_id}>{subUnitData.u_name}</Option>

                                                        ))}
                                                    </OptGroup>
                                                )
                                            }
                                            else {
                                                return null
                                            }
                                        }

                                        )}



                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className={'popupbtncolour text-center ' + localStorage.getItem('currentTheme')}>
                        <Button shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ marginLeft: '15%', marginTop: '10%' }}
                            onClick={filterChange} icon={<ArrowRightOutlined />}
                            size='default' >
                            Go
                        </Button>
                    </div>
                    {/* <Button type="primary" className='addbutton' style={{ background: "#0096FF", marginLeft: '25%', marginTop: '10%' }}
                        onClick={() => {
                            const payload = [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request":
                                        ["GetTskDistributionStatusChart",
                                            {
                                                "c_ids": [
                                                    commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                                ],
                                                "d_ids": [
                                                    commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                                                ],
                                                "filter_type": "Group",
                                                "filter_ids": [
                                                    1
                                                ],
                                                "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [
                                                    commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_id
                                                ],
                                                "country_domains": [
                                                    {
                                                        "d_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id,
                                                        "c_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].c_id,
                                                        "month_from": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_from,
                                                        "month_to": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_to
                                                    }
                                                ],
                                                "chart_year": year
                                            }
                                        ]
                                }
                            ]
                            getCriticalitychart({
                                payload: payload,
                                paramid: paramid
                            })
                            setOpen(false)

                            setFieldType("")
                            setFilterData({
                                ...filterData,
                                country: '',
                                domain: '',
                                legalentity: '',
                                division: '',
                                unit: '',
                                business: '',
                                category: '',
                                unit: ''
                            })
                        }}
                        size='default' >
                        Reset
                    </Button> */}
                </Drawer>

            </div>
        </div>

    )


}

export default connect(mapStateToProps, {
    getCommonData,
    getTaskDistributionchart,
    getTaskDistributionTable,
    getTimeZoneData,
    getTaskDistributionShowMoreTableData
})(TaskDistribution);

