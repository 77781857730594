import { Collapse, Select, Card, Drawer, Button, DatePicker, Tooltip, Modal, Table, Pagination } from 'antd';
import { useState, useEffect, useRef } from 'react';
import { useLocation, Link, useHistory } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { MonthReportExport } from "../../../src/Store/Action/Report/CV_MonthWiseStatusReport"
import { CertificateComplianceWiseReportFilters, ComplianceCertificateWiseReport, CertificatePdf, ComplianceCertificateExport, DownloadFile, DownloadFiledocument, getdownloadpath, getdownloadpath1 } from "../../../src/Store/Action/Report/CV_ComplianceCertificate"
import { connect, useDispatch, useSelector } from 'react-redux';
import { BookTwoTone, ExportOutlined, EyeOutlined, FilterOutlined, FullscreenOutlined, InfoCircleOutlined, UpOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { PlusCircleOutlined, FileTextOutlined, PlayCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import ScrollToTop from "react-scroll-to-top";
import { PDF_RESET, FILE_DOWNLOAD, CV_COMPLIANCE_CERTIFICATE_EXPORT_RESET } from './../../../src/Store/types/index'
import { fullscreenexit, fullscreenmode, handleKeyDown } from '../../Libs/fullscreenmode';
import { validationType, validateSelect, onPasteToaster } from './../../Libs/inputValidation';
import useStickyHeight from "../../Libs/useStickyHeight";
import ScrollButton from '../Scrollbar';
import { Toaster } from '../../Libs/Toaster';
import url, { getUrl } from './../../Libs/URL'
import { CountrySplit, HomeRedirect } from './../../Libs/country'
import { historyrecord, interimrecord } from '../../Store/Action/Report/TaskCategoryReport';
import { Downloadfile } from '../../Store/Action/Transcations/ComplianceApproval';
// import { getdownloadpath } from '../../Store/Action/Transcations/ComplianceApproval';
const homepage = HomeRedirect()
const urls = getUrl()
const uniq_country_data = CountrySplit()
const finalURL = urls && urls != null ? urls.replace('/cv', '') : false;


const mapStateToProps = (state) => ({
    reportlist: state.CV_MonthWiseStatusReport.monthwisestatusreport,
    compliancecertificate: state.CV_ComplianceCertificateReport.certificatecompliancereport,
    history_record: state.TaskCategoryReport.taskCategoryReport.history_data,
    interim_record: state.TaskCategoryReport.taskCategoryReport.interim_data,
})

const CV_ComplianceCertificateReport = ({
    CertificateComplianceWiseReportFilters,
    reportlist,
    CertificatePdf,
    DownloadFile,
    DownloadFiledocument,
    ComplianceCertificateWiseReport,
    MonthReportExport,
    ComplianceCertificateExport,
    compliancecertificate: { certificatecompliancereportfilter, uploadeddocumentdownload, filedownload, certificatecompliancereportfiltershow, pdf, certificatecomplianceexport },
    getdownloadpath,
    getdownloadpath1,
    historyrecord,
    interimrecord,
    history_record,
    interim_record,
    Downloadfile
}) => {
    useStickyHeight();
    const historynew = useHistory();
    const { Panel } = Collapse;
    const { Option } = Select;
    const location = useLocation();
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const paramid = location.pathname;
    const _ = require("lodash");
    const sessionValue = localStorage.getItem('sessionValue')
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');

    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArray = sessionParsedValue.login_response
    const [current, setCurrent] = useState(1);
    console.log(current, "currentcurrent");
    const [pageState, setpageState] = useState(false);
    const [documentmodalview, setdocumentmodalview] = useState(false);
    const [updateddocumentdata, setupdateddocumentdata] = useState()
    const [domainValue, setDomainValue] = useState(false)
    const [domainFilter, setDomainFilter] = useState([])
    const [domain, setDomain] = useState([])
    const [unit, setUnit] = useState([])
    const [check, setCheck] = useState([])
    const [entity, setEntity] = useState([])
    const [currentYear, setCurrentYear] = useState(moment().format('YYYY'))
    const [categorylist, setCategoryList] = useState([])
    const [division, setDivision] = useState([])
    const [pageSize, setPageSize] = useState(25)
    console.log(pageSize, "pageSizepageSize");
    const [paginationArray, setPaginationArray] = useState([])
    const sessionArr = sessionParsedValue.entity_info
    const validator = useRef(new SimpleReactValidator());
    const [open, setOpen] = useState(false);
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [tableshow, setTableShow] = useState(false)
    const [invoicemodal, setInvoiceModal] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [Documentmodal, setDocumentModal] = useState(false)
    const [invoicedata, setInvoiceData] = useState([])
    const [documentview, setDocumentView] = useState([])
    var [page, setPage] = useState(0);
    const [key, setKey] = useState("1")
    const [countrydata, setCountryData] = useState(null)
    const dispatch = useDispatch();
    const [titledata, Settitledata] = useState(undefined)
    const [childState, setChildState] = useState([])
    const [childValue, setChildValue] = useState([])
    let [index, setindex] = useState(0)
    console.log(index, 'nidexxxxxx');
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setfilteredArrayState] = useState([])
    const [getreportlist, setGetReportList] = useState([])
    const [checkboxValue, setCheckBoxValue] = useState([1, 2, 7, 8, 9, 10, 31, 34])
    const [tabledata, setTableData] = useState([])
    const [totaldata, setTotalData] = useState({
        domainlist: [],
        divisionlist: [],
        categorylist: [],
        unitlist: []
    })
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [year, setYear] = useState()
    console.log(year, 'year');
    const [exitCollapse, setExitCollapse] = useState(false);
    const [compliancecertificate, setComplianceCertificate] = useState({
        country: "",
        countryName: '',
        legel_entity: "",
        domain: "",
        division: "",
        category: "",
        unit: "",
        period: "",
        domain_id: null,
        division_id: null,
        category_id: null,
        unit_id: null,
        from_month: '',
        to_month: '',
        legal_entity_id: '',
    })
    const [due, setDue] = useState(false);
    const [dueTo, setDueTo] = useState(false);

    console.log(sessionArray, 'sessionArray')
    const [monthlyArray, setMonthlyArray] = useState([]);
    const [selectSearchValue, setSelectSearchValue] = useState({
        country: '',
        legalEntity: '',
        domain: '',
        actRules: '',
        complianceTask: '',
        division: '',
        category: '',
        vendor: '',
    });

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        if (certificatecompliancereportfiltershow) {
            setTableData(certificatecompliancereportfiltershow && certificatecompliancereportfiltershow.certification_compliances)
        }
    }, [certificatecompliancereportfiltershow])

    useEffect(() => {
        if (certificatecompliancereportfiltershow && certificatecompliancereportfiltershow.certification_compliances) {
            var array = certificatecompliancereportfiltershow && certificatecompliancereportfiltershow.certification_compliances.slice((current - 1) * pageSize, (current * pageSize))
            setPaginationArray(array)
        }

    }, [certificatecompliancereportfiltershow && certificatecompliancereportfiltershow.certification_compliances, pageSize, current])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
            const country = sessionParsedValue.country_info
            setCountryData(country)
        }
    }, [localStorage.getItem('SelectedEntityid')])
    console.log(sessionArray, 'sessionArray');

    useEffect(() => {
        if (compliancecertificate.division_id) {
            const divid = totaldata.divisionlist.filter((item) => {
                return item.div_id === compliancecertificate.division_id
            })
        }
    }, [compliancecertificate])

    useEffect(() => {
        if (isAuth && entityid != "null" && entityid != '') {
            // if (isAuth && entityid != "null" && countrydata != null) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetCertificateComplianceWiseReportFilters",
                        {
                            "country_id": Number(compliancecertificate.country),
                            "legal_entity_id": entityid
                        }
                    ]
                }
            ]
            if (entityid) {
                CertificateComplianceWiseReportFilters({
                    payload: payload,
                    paramid: paramid
                })
            }
        }
    }, [isAuth, authtoken, paramid, entityid, countrydata])

    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };

    useEffect(() => {
        let temp = localStorage.getItem("SelectedEntity")
        let tempcountry = _.filter(sessionArr, { le_name: temp })
        if (reportlist.compliancewisereport) {
            setGetReportList(reportlist && reportlist.compliancewisereport)
            let Domaindata = _.filter(reportlist && reportlist.compliancewisereport.domains, { le_id: entityid })
            setTotalData({
                ...totaldata,
                domainlist: Domaindata
            })
        }
        if (localStorage.getItem('SelectedEntity') !== "All Legal Entity") {

            setComplianceCertificate({
                ...compliancecertificate,
                country: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_id : '',
                countryName: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_name : '',
                legel_entity: localStorage.getItem('SelectedEntity'),
                legal_entity_id: localStorage.getItem('SelectedEntityid')
            })
            let year = _.filter(sessionArray.domain_le_dt, { legal_entity_id: localStorage.getItem('SelectedEntityid') })
            console.log(year, 'yearyearyear')
            setYear(year)
        }
        if (compliancecertificate.period === "") {
            setComplianceCertificate({
                ...compliancecertificate,
                period: "All",
            })
        }
    }, [reportlist && reportlist.compliancewisereport, entityid, countrydata])

    useEffect(() => {
        if (certificatecompliancereportfilter) {
            setCategoryList(certificatecompliancereportfilter && certificatecompliancereportfilter.categories_list)
            setDivision(certificatecompliancereportfilter && certificatecompliancereportfilter.divisions_list)
            setDomain(certificatecompliancereportfilter && certificatecompliancereportfilter.domains)
            setUnit(certificatecompliancereportfilter && certificatecompliancereportfilter.unit_legal_entity)
        }
    }, [certificatecompliancereportfilter])

    useEffect(() => {
        // setTempeditdata(editdata)
        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (tabledata) {
            const ab = paginationArray && paginationArray.map(function (item) {
                if ((array.indexOf(item.period) < 0))
                    array.push(item.period)
            })
            array.map((data, i) => {
                let temp = [];
                let entityArray = [];
                let datavalue = [];
                if (data) {
                    paginationArray && paginationArray.map((child, childIndex) => {
                        if (data === child.period) {
                            entityArray = {
                                "ac_id": child.ac_id,
                                "actity_date": child.actity_date,
                                "activity_status": child.activity_status,
                                "approval_document_names": child.approval_document_names,
                                "assignee_name": child.assignee_name,
                                "category_name": child.category_name,
                                "completion_date": child.completion_date,
                                "compliance_activity_id": child.compliance_activity_id,
                                "compliance_description": child.compliance_description,
                                "compliance_history_id": child.compliance_history_id,
                                "compliance_id": child.compliance_id,
                                "compliance_task": child.compliance_task,
                                "concur_document_name": child.concur_document_name,
                                "country_id": child.country_id,
                                "criticality_name": child.criticality_name,
                                "current_status": child.current_status,
                                "division_name": child.division_name,
                                "doc_ref_num": child.doc_ref_num,
                                "document_name": child.document_name,
                                "domain_id": child.domain_id,
                                "due_date": child.due_date,
                                "extend_due_date": child.extend_due_date,
                                "history_count": child.history_count,
                                "interim_count": child.interim_count,
                                "legal_entity_id": child.legal_entity_id,
                                "logo_url": child.logo_url,
                                "period": child.period,
                                "remarks_count": child.remarks_count,
                                "start_date": child.start_date,
                                "statutory_mapping": child.statutory_mapping,
                                "task_status": child.task_status,
                                "tz_name": child.tz_name,
                                "unit_address": child.unit_address,
                                "unit_id": child.unit_id,
                                "unit_name": child.unit_name,
                                "url": child.url,
                                "user_name": child.user_name,
                                'childId': childIndex,
                                'parentId': i
                            }
                            temp.push(entityArray)
                        }
                    })
                }

                datavalue = {
                    index: i,
                    parent: data,
                    child: temp
                }
                finalarray.push(datavalue)
            })
            Settitledata(finalarray)

        }

    }, [paginationArray])

    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };
    const [fullscreen, setfullscreen] = useState(false);
    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }


    useEffect(() => {
        if (tabledata) {
            let uniqueObjArray = [...new Map(tabledata && tabledata.map((item) => [item["unit_id"], item])).values()];
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.unit_id
            })

            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(tabledata && tabledata, { unit_id: uniqueIds[i] })

                tempArr.push(filteredArr)
            }
            setFilteredArray([...filteredArray, tempArr])

        }

    }, [tabledata])

    useEffect(() => {
        if (filteredArray && filteredArray.length > 0) {
            filteredArray && filteredArray.map((item, i) => {
                return (
                    setfilteredArrayState(item)
                )
            })
        }
    }, [filteredArray])

    useEffect(() => {
        let monthArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        if (moment().format('MMM') == "Jan") {
            const index = monthArray.findIndex(element => {
                if (element == moment().format('MMM')) {
                    return true;
                }
            })
            let currentYear = moment().format('YYYY') - 1
            monthArray = monthArray.filter((month, idx) => idx < index)
            let tempMonthArray = [];
            for (let i in monthArray) {
                tempMonthArray.push(monthArray[i] + '-' + currentYear)
            }
            setMonthlyArray(tempMonthArray)
        } else {
            let temp = false
            let temparray = []
            for (let i in monthArray) {
                if (temp) {
                    temparray.push(monthArray[i])
                } else {
                    if (monthArray[i] == moment(compliancecertificate.from_month).format('MMM')) {
                        temparray.push(monthArray[i])
                        temp = true
                    }
                }
            }
            let check = []
            const index = temparray.findIndex(element => {
                check.push(element)
                // if (element == moment(compliancecertificate.from_month).format('MMM')) {
                if (element == moment(compliancecertificate.to_month).format('MMM')) {
                    return true;
                }
                // }
            })
            setCheck(check)
            let currentYear = moment().format('YYYY');
            temparray = temparray.filter((month, idx) => idx <= index)
            let tempMonthArray = [];
            for (let i in temparray) {
                tempMonthArray.push(temparray[i] + '-' + currentYear)
            }
            setMonthlyArray(tempMonthArray)
            // let check = tempMonthArray.split('-')
        }

    }, [compliancecertificate.to_month])

    useEffect(() => {
        if (pdf && pdf.length > 0) {
            window.open(finalURL + '/' + pdf);
            dispatch({
                type: PDF_RESET
            });
        }
    }, [pdf])

    useEffect(() => {
        if (certificatecomplianceexport && certificatecomplianceexport.length > 0) {
            window.open(finalURL + '/' + certificatecomplianceexport);
            dispatch({
                type: CV_COMPLIANCE_CERTIFICATE_EXPORT_RESET
            });
        }
    }, [certificatecomplianceexport])

    useEffect(() => {
        if (filedownload && filedownload.length > 0) {
            console.log(filedownload, "filedownloadfiledownload");
            window.open(finalURL + '/' + filedownload);
            dispatch({
                type: FILE_DOWNLOAD
            });
        }
    }, [filedownload])

    const columnsdocument = [
        {
            title: '#',
            dataIndex: 'false',
            key: 'false',
            width: "30px",
            align: "center",
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <>
                        <span>{index + 1}</span>
                    </>
                )
            }
        },
        {
            title: 'Assignee',
            dataIndex: 'Assignee',
            key: 'Assignee',
            width: "100px",
            align: "center",
            ellipsis: true,
            render: (text, record) => {
                return (
                    <>
                        <a href="javascript:;"
                            onClick={() => { downloaddocument(record,'assign') }}
                        >{record.Assignee}</a>
                    </>
                )
            }
        },
        {
            title: 'Concurrer',
            dataIndex: 'Concurrer',
            key: 'Concurrer',
            width: "80px",
            align: "center",
            ellipsis: true,
            render: (text, record) => {
                return (
                    <>
                        <a href="javascript:;"
                            onClick={() => { downloaddocument(record,'concurr') }}
                        >{record.Concurrer}</a>
                    </>
                )
            }
        },
        {
            title: 'Approver',
            dataIndex: 'Approver',
            key: 'Approver',
            align: "center",
            width: "100px",
            ellipsis: true,
            render: (text, record) => {
                return (
                    <>
                        <a href="javascript:;"
                            onClick={() => { downloaddocument(record,'approve') }}
                        >{record.Approver}</a>
                    </>
                )
            }
        }
    ]

    const columns = [
        {
            title: 'Invoice Number',
            dataIndex: 'false',
            key: 'false',
            width: "100px",
            align: "center",
            ellipsis: true,
            render: (text, record) => {
                return (
                    <>
                        <p>{record && record.inv_no ? record.inv_no : "-"}</p>
                    </>
                )
            }
        },
        {
            title: 'Certificate',
            dataIndex: 'certificate',
            key: 'certificate',
            width: "80px",
            align: "center",
            ellipsis: true,
            render: (text, record) => {
                return (
                    <>
                        <a href="javascript:;" onClick={() => { certificatedownload(record) }} >{record && record.certificate}</a>
                    </>
                )
            }
            // render: (record) => record && record.months === 'January' ? record &&record.task_status : "-"
        },
        {
            title: 'Uploaded document',
            dataIndex: 'false',
            key: 'false',
            align: "center",
            width: "100px",
            ellipsis: true,
            render: (text, record) => {
                return (<>{record.certificate_doc_name == null ? '' : <FileTextOutlined onClick={() => { uploadeddocument(record) }} />}</>)
            }
        }
    ]

    const column = [
        {
            title: '#',
            dataIndex: 'false',
            key: 'false',
            width: "30px",
            align: "center",
            ellipsis: true,
            render: (text, record, index) => {
                // return (
                //     <>
                //         <p>{record && record.inv_no ? record.inv_no : "-"}</p>
                //     </>
                // )
                // render: (text, record, index) => {
                return (
                    <>
                        <span>{page + 1}</span>
                        <span hidden>{page = page + 1}</span>
                    </>
                )
            }
        },
        {
            title: 'User',
            dataIndex: 'false',
            key: 'false',
            width: "80px",
            align: "center",
            ellipsis: true,
            render: (text, record) => {
                return (
                    <>
                        <span>{record && record.doc_upload_role}</span>
                    </>
                )
            }
            // render: (record) => record && record.months === 'January' ? record &&record.task_status : "-"
        },
        {
            title: 'Attachment',
            dataIndex: 'false',
            key: 'false',
            align: "center",
            width: "100px",
            ellipsis: true,
            render: (text, record) => {
                console.log(record, 'record');
                return (<>{record.certificate_doc_name == null ? '' : <a href="javascript:;" onClick={() => { downloadattachment(record) }} >{record.certificate_doc_name}</a>}</>)
            }
        }
    ]

    const downloaddocument = (record,role) => {
        let filename =''
        if(role == 'assign'){
            filename = record.Assignee
        }
        else if(role == 'concurr'){
            filename = record.Concurrer
        }else{
            filename = record.Approver
        }
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "DownloadFile",
                    {
                        "le_id": entityid,
                        "c_id": Number(compliancecertificate.country),
                        "d_id": Number(compliancecertificate.domain_id),
                        "u_id": record.unit_id,
                        "start_date": record.start_date,
                        "file_name": filename
                    }
                ]
            }
        ]
        getdownloadpath1({
            payload: payload,
            paramid: paramid
        })

    }

    const onChange = (key) => {
        // console.log(key);
    };

    const onClose = () => {
        setOpen(false);
    };

    const handleOk = () => {
        const remarks = checkboxValue.includes(34);
        const myArray = checkboxValue;
        let status = myArray.includes(0);
        if (!status) {
            myArray.unshift(0);
        }

        if (remarks) {
            const index = myArray.indexOf(34);
            const x = myArray.splice(index, 1);
        }
        setCheckBoxValue(myArray);

        setIsModalOpen(false);
        setAddFormSubmit(true)
        setTableShow(true)
        setKey(0)
        // setCheckBoxValue([1, 2, 7, 8, 9, 10, 31, 34]);
        const payload1 = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetComplianceCertificateWiseReport",
                    {
                        "country_id": Number(compliancecertificate.country),
                        "legal_entity_id": entityid,
                        "domain_id": Number(compliancecertificate.domain_id),
                        "division_id": compliancecertificate.division_id ? Number(compliancecertificate.division_id) : 0,
                        "category_id": compliancecertificate.category_id ? Number(compliancecertificate.category_id) : 0,
                        "unit_id": compliancecertificate.unit_id ? Number(compliancecertificate.unit_id) : 0,
                        "from_date": moment(compliancecertificate.from_month).format("MMMM-YYYY"),
                        "to_date": moment(compliancecertificate.to_month).format("MMMM-YYYY"),
                        "csv": true,
                        "from_count": 0,
                        "page_count": 0,
                        "list_check": checkboxValue
                    }
                ]
            }
        ]
        console.log('PAyLoad', payload1);
        ComplianceCertificateExport({
            payload: payload1,
            paramid: paramid
        })

    };

    const documentdata = (data) => {
        const myArray = data.document_name.split("||");
        let array = []
        for (let i in myArray) {
            array.push({
                Assignee: myArray[i],
                Concurrer: data.concur_document_name,
                Approver: data.approval_document_names,
                unit_id: data.unit_id,
                start_date: data.start_date
            })
            // myArray[i])
        }
        setupdateddocumentdata(array)
    }

    const certificatedownload = (record) => {
        const myArray = record.certificate.split("-");
        let temp = myArray[1] + " - " + myArray[2]

        CertificatePdf({
            payload: [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetCertificatePdf",
                        {
                            "country_id": Number(compliancecertificate.country),
                            "legal_entity_id": entityid,
                            "domain_id": Number(compliancecertificate.domain_id),
                            "cert_id": record.cert_id,
                            "unit_id": record.vendor_id,
                            "random_number": temp
                        }
                    ]
                }
            ],
            paramid: paramid
        })
    }

    const downloadattachment = (record) => {
        // DownloadFile({
        //     payload: [
        //         authtoken,
        //         {
        //             "session_token": authtoken,
        //             "request": [
        //                 "DownloadFileCert",
        //                 {
        //                     "le_id": entityid,
        //                     "upload_type": 1,
        //                     "u_id": record.vendor_id,
        //                     "file_value": record.certificate_doc_name,
        //                     "c_id": Number(compliancecertificate.country)
        //                 }
        //             ]
        //         }
        //     ],
        //     paramid: paramid
        // })
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "DownloadFileCert",
                    {
                        "le_id": entityid,
                        "upload_type": 1,
                        "u_id": record.vendor_id,
                        "file_value": record.certificate_doc_name,
                        "c_id": Number(compliancecertificate.country)
                    }
                ]
            }
        ]
        getdownloadpath({
            payload: payload,
            paramid: paramid
        })
    }

    const uploadeddocument = (record) => {
        let entityArray = [];
        let temp = []
        setDocumentModal(true)
        setInvoiceModal(false)
        const myArray = record.doc_upload_role.split(",");
        const myArray1 = record.certificate_doc_name.split("|");
        for (let i in myArray) {
            entityArray = {
                "cert_id": record.cert_id,
                "certificate_doc_name": myArray1[i],
                "date": record.date,
                "doc_upload_role": myArray[i],
                "inv_no": record.inv_no,
                "vendor_id": record.vendor_id,
            }
            temp.push(entityArray)
        }
        setDocumentView(temp)
    }

    const invoice = (period) => {
        let temp = []
        let entityArray = [];
        let count = 1
        {
            certificatecompliancereportfiltershow.certification_details.map((item) => {
                if (item.date == period) {
                    const myArray = item.date.split("-");
                    entityArray = {
                        "cert_id": item.cert_id,
                        "certificate_doc_name": item.certificate_doc_name,
                        "date": item.date,
                        "doc_upload_role": item.doc_upload_role,
                        "inv_no": item.inv_no,
                        "vendor_id": item.vendor_id,
                        "certificate": "Certificate-" + myArray[0] + '-' + count
                    }
                    temp.push(entityArray)
                    count = count + 1
                    // temp.push(item)
                }
            })
        }
        setInvoiceData(temp)
    }

    const selectAllCheckBox = (checked) => {
        var ele = document.getElementsByName('chk');
        let tempAllIds = []
        if (checked == true) {
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox') {
                    ele[i].checked = true;
                    tempAllIds.push(Number(ele[i].value))
                }
            }
            setCheckBoxValue(tempAllIds)
        }
        else {
            var ele = document.getElementsByName('chk');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = false;

            }
            setCheckBoxValue([1, 2, 7, 8, 9, 10, 31, 34])
        }
    }

    const checkBoxOnChange = (e) => {
        let tempIds = []
        let temp = checkboxValue
        if (temp.includes(Number(e.target.value)) == true) {
            for (let i in temp) {
                if (temp[i] == Number(e.target.value)) {
                    temp.splice(i, 1)
                }
            }
            setCheckBoxValue([...temp])
        } else {
            tempIds.push(Number(e.target.value))
            setCheckBoxValue([...checkboxValue, ...tempIds])
        }
    }

    useEffect(() => {
        if (pageState === true) {
            setindex(pageSize * (current - 1))
        }
    }, [pageState, pageSize, current])

    const onshow = () => {
        setAddFormSubmit(true)
        setFilterModalVisible(false);
        if (validator.current.allValid() || (compliancecertificate.domain_id != '' && compliancecertificate.from_month != '' && compliancecertificate.to_month != '')) {
            Settitledata([])
            setfilteredArrayState([])
            setTableShow(true)
            setExitCollapse(true);
            setFilterTaskAcc(false);
            setKey(0)
            setindex(0);
            setpageState(false)
            setPageSize(25)
            const payload1 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetComplianceCertificateWiseReport",
                        {
                            "country_id": Number(compliancecertificate.country),
                            "legal_entity_id": entityid,
                            "domain_id": Number(compliancecertificate.domain_id),
                            "division_id": compliancecertificate.division_id ? Number(compliancecertificate.division_id) : 0,
                            "category_id": compliancecertificate.category_id ? Number(compliancecertificate.category_id) : 0,
                            "unit_id": compliancecertificate.unit_id ? Number(compliancecertificate.unit_id) : 0,
                            // "months": compliancecertificate.period,
                            "from_date": moment(compliancecertificate.from_month).format("MMMM-YYYY"),
                            "to_date": moment(compliancecertificate.to_month).format("MMMM-YYYY"),
                            "csv": false,
                            "from_count": 0,
                            "page_count": 0
                        }
                    ]
                }
            ]
            ComplianceCertificateWiseReport({
                payload: payload1,
                paramid: paramid
            })
        }
    }

    const showModal = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid() || (compliancecertificate.domain_id != '' && compliancecertificate.from_month != '' && compliancecertificate.to_month != '')) {
            setIsModalOpen(true);
            setTableShow(true)
            setFilterTaskAcc(false);
            setFilterModalVisible(false);
        }
    };

    const onexport = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid() || (compliancecertificate.domain_id != '' && compliancecertificate.from_month != '' && compliancecertificate.to_month != '')) {
            setTableShow(false)
            setKey(0)
            const payload2 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetMonthlyComplianceWiseReport",
                        {
                            "country_id": Number(compliancecertificate.country),
                            "legal_entity_id": entityid,
                            "domain_id": Number(compliancecertificate.domain_id),
                            "division_id": compliancecertificate.division_id ? Number(compliancecertificate.division_id) : 0,
                            "category_id": compliancecertificate.category_id ? Number(compliancecertificate.category_id) : 0,
                            "unit_id": compliancecertificate.unit_id ? Number(compliancecertificate.unit_id) : 0,
                            // "months": compliancecertificate.period,
                            "from_date": moment(compliancecertificate.from_month).format("MMMM-YYYY"),
                            "to_date": moment(compliancecertificate.to_month).format("MMMM-YYYY"),
                            "csv": true,
                            "from_count": 0,
                            "page_count": 0
                        }
                    ]
                }
            ]
            MonthReportExport({
                payload: payload2,
                paramid: paramid
            })
        }
    }

    const handleCancel = () => {
        setInvoiceModal(false);
        setIsModalOpen(false);
        setdocumentmodalview(false)
        setCheckBoxValue([1, 2, 7, 8, 9, 10, 31, 34]);
    };

    console.log(checkboxValue, 'checkboooo')
    const handledocumentCancel = () => {
        setDocumentModal(false)
    }

    const genextra = () => {
        return <div onClick={(event) => {
            // If you don't want click extra trigger collapse, you can prevent this:
            event.stopPropagation();
        }}>
            {/* <Button type='primary' onClick={() => {
                setOpen(true)
            }}> */}
            {/* <EyeOutlined size='default' onClick={() => {
                setOpen(true)
            }} /> */}
            {/* </Button> */}
        </div>
    }

    const homescreen = () => {
        let getUrlDetails = homepage;
        let tempval = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_url;
        let tempkey = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_key;
        localStorage.setItem('formkey', tempkey);
        historynew.push(tempval);
        // window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }
    const handlePasting = (e, regex) => {

        var regex = regex;
        var key = e.clipboardData.getData('text')
        if (!regex.test(key)) {
            e.preventDefault();
            Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
            return false;
        }
        // e.clipboardData.getData('text')
        // Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
        // e.preventDefault();
        // return false;
    }

    useEffect(() => {
        if (compliancecertificate.countryName != '' && compliancecertificate.legel_entity != '' && domain && domain.length == 1) {
            setDomainValue(true)
            setComplianceCertificate({
                ...compliancecertificate,
                domain_id: domain[0].d_id,
                domain: domain[0].d_name,
            })
            let division = _.filter(certificatecompliancereportfilter && certificatecompliancereportfilter.divisions_list, { le_id: entityid })
            setDivision(division)

        } else {
            setDomainValue(false)
            setDomainFilter(domain)

        }
    }, [domain])
    const [datas, setDatas] = useState({
        le_id: '',
        start_date: '',
        country_id: '',
        domain_id: '',
        unit_id: ''
    })

    const history_columns = [
        {
            title: '#',
            key: 'index',
            align: "center",
            width: "30px",
            render: (text, record, index) => {
                return (
                    index + 1
                )
            },
        },
        {
            title: 'Activity Date',
            key: 'activity_on',
            align: "center",
            width: "50px",
            dataIndex: 'activity_on',
        },
        {
            title: 'User Type',
            key: 'action',
            align: "center",
            width: "50px",
            dataIndex: 'action',
            render: (text, record) => {
                if (record.user_category_id == 5 || record.user_category_id == 6) {
                    return 'Assignee'
                } else if ((record.user_category_id == 3 || record.user_category_id == 4) && (record.action == 'Rectified' || record.action == 'Concurrence Rejected' || record.action == 'Concurred')) {
                    return 'Concurrer'
                } else if (record.user_category_id == 1 && (record.action == 'RectifyApproval' || record.action == 'Approval Rejected' || record.action == 'Approved') ||
                    (record.user_category_id == 3 || record.user_category_id == 4) && (record.action == 'Approval Rejected' || record.action == 'RectifyApproval' || record.action == 'Approved')) {
                    return 'Approver'
                }

            }
        },
        {
            title: 'Reviewer Report',
            key: 'documents',
            align: "center",
            width: "50px",
            dataIndex: 'documents',
        },
        {
            title: 'Remarks',
            key: 'remarks',
            align: "center",
            width: "50px",
            dataIndex: 'remarks',
        },
    ]

    const interim_columns = [
        {
            title: '#',
            key: 'index',
            align: "center",
            width: "30px",
            render: (text, record, index) => {
                return (
                    index + 1
                )
            },
        },
        {
            title: 'Documents',
            key: 'documents',
            align: "center",
            width: "50px",
            dataIndex: 'documents',
            render: (data, record) => {
                let file_name = data.split('||')
                return file_name && file_name.map((item) => {
                    return <><span onClick={() => {
                        const downloadpayload =
                            [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request": [
                                        "DownloadInterimFile",
                                        {
                                            "le_id": entityid,
                                            "c_id": datas.country_id,
                                            "d_id": datas.domain_id,
                                            "u_id": datas.unit_id,
                                            "start_date": datas.start_date,
                                            "file_name": item
                                        }
                                    ]
                                }
                            ]
                        Downloadfile({
                            payload: downloadpayload,
                            paramid: paramid
                        });
                    }}><Link>{item}</Link><br /></span></>
                })
            }
        },
        {
            title: 'Remarks',
            key: 'remarks',
            align: "center",
            width: "50px",
            dataIndex: 'remarks',
        },
        {
            title: 'Updated On',
            key: 'activity_on',
            align: "center",
            width: "50px",
            dataIndex: 'activity_on',
        },
    ]


    const [historymodal, sethistorymodal] = useState(false);
    const [interimmodal, setinterimmodal] = useState(false);

    const viewactivity_histroy = (his_data) => {
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetComplianceRemarksActionHistoryData",
                    {
                        "legal_entity_id": compliancecertificate.legal_entity_id,
                        "unit_id": his_data.unit_id,
                        "compliance_history_id": his_data.compliance_history_id
                    }
                ]
            }
        ]
        historyrecord({
            payload: payload,
            paramid: paramid
        })
        sethistorymodal(true);
    }



    const view_interim = (int_details) => {
        setDatas({
            le_id: int_details.le_id,
            start_date: int_details.start_date,
            country_id: int_details.country_id,
            domain_id: int_details.domain_id,
            unit_id: int_details.unit_id
        })
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetInterimComplianceDetails",
                    {
                        "legal_entity_id": compliancecertificate.legal_entity_id,
                        "compliance_history_id": int_details.compliance_history_id
                    }
                ]
            }
        ]
        interimrecord({
            payload: payload,
            paramid: paramid
        })
        setinterimmodal(true)
    }

    const historycancel = () => {
        sethistorymodal(false);
    }

    const interimcancel = () => {
        setinterimmodal(false);
    }

    return (
        <>
            <div className="page-wrapper" id='page-wrapper'>
                {document.body.scrollHeight > document.body.clientHeight ?
                    <div className='back-to-top'>
                        <ScrollButton />
                    </div> : ''}
                <div className="page-titles pb-1 pt-2 page-title-sticky">

                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <span style={{ fontSize: '14px', fontWeight: "bold" }}>Report</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                        <span style={{ fontSize: "15px", fontWeight: "bold" }}>Compliance Certificate Report</span>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-6 text-end">
                            <Tooltip title='Advance Filter'> <Button
                                className="mx-1"
                                type="primary"
                                shape="round"
                                style={{
                                    display: filterTaskAcc ? "none" : "initial",
                                }}
                                onClick={filterHandle}
                            >
                                <FilterOutlined /> Filter
                            </Button></Tooltip>
                            <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                                <Tooltip placement="left" title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                            </button>
                        </div>
                    </div>
                </div>
                <Drawer title="Select Fields" className={'add-service-prv export-checkbox resize-drawer ' + localStorage.getItem('currentTheme')} open={isModalOpen} footer={null} onCancel={handleCancel} onClose={handleCancel} width={600} maskClosable={false}>
                    <div className='d-flex flex-wrap'>
                        <input id="select_all" width="100%" type="checkbox" autocomplete="off"
                            checked={checkboxValue.length === 34 ? true : false}
                            onClick={(e) => {
                                let checked = e.target.checked
                                selectAllCheckBox(checked)
                            }} />  <label for='select_all'>Select All</label>

                        <input id="column_1" name='chk' type="checkbox" checked disabled value='1' onChange={(e) => (e) => checkBoxOnChange(e)} /> <label className='cursornotallow' for='column_1'>Vendor Code</label>
                        <input id="column_2" name='chk' type="checkbox" checked disabled value='2' onChange={(e) => checkBoxOnChange(e)} /> <label className='cursornotallow' for='column_2'> Vendor Name</label>
                        <input id="column_7" name='chk' type="checkbox" checked disabled value='7' onChange={(e) => (e) => checkBoxOnChange(e)} /> <label className='cursornotallow' for='column_7'>Location</label>
                        <input id="column_8" name='chk' type="checkbox" checked disabled value='8' onChange={(e) => (e) => checkBoxOnChange(e)} /> <label className='cursornotallow' for='column_8'>Act / Rules</label>
                        <input id="column_9" name='chk' type="checkbox" checked disabled value='9' onChange={(e) => checkBoxOnChange(e)} /> <label className='cursornotallow' for='column_9'> Compliance Task</label>
                        <input id="column_10" name='chk' type="checkbox" checked disabled value='10' onChange={(e) => checkBoxOnChange(e)} /> <label className='cursornotallow' for='column_10'> Frequency</label>
                        <input id="column_34" name='chk' type="checkbox" value='34' checked disabled onChange={(e) => checkBoxOnChange(e)} />  <label className='cursornotallow' for='column_34'>Remarks </label>
                        <input id="column_31" name='chk' type="checkbox" value='31' checked disabled onChange={(e) => checkBoxOnChange(e)} />  <label className='cursornotallow' for='column_31'>Statutory Status </label>
                        <input id="column_3" name='chk' type="checkbox" value='3' checked={checkboxValue.includes(3)} onChange={(e) => checkBoxOnChange(e)} /> <label for='column_3'> Vendor Code 1</label>
                        <input id="column_4" name='chk' type="checkbox" value='4' checked={checkboxValue.includes(4)} onChange={(e) => checkBoxOnChange(e)} /> <label for='column_4'> Vendor Code 2</label>
                        <input id="column_5" name='chk' type="checkbox" value='5' checked={checkboxValue.includes(5)} onChange={(e) => checkBoxOnChange(e)} /> <label for='column_5'> Vendor Code 3</label>
                        <input id="column_6" name='chk' type="checkbox" value='6' checked={checkboxValue.includes(6)} onChange={(e) => checkBoxOnChange(e)} /> <label for='column_6'> Vendor Code 4</label>
                        <input id="column_11" name='chk' type="checkbox" value='11' checked={checkboxValue.includes(11)} onChange={(e) => checkBoxOnChange(e)} /> <label for='column_11'> Periodicity</label>
                        <input id="column_12" name='chk' type="checkbox" value='12' checked={checkboxValue.includes(12)} onChange={(e) => checkBoxOnChange(e)} /> <label for='column_12'> Criticality</label>
                        <input id="column_13" name='chk' type="checkbox" value='13' checked={checkboxValue.includes(13)} onChange={(e) => checkBoxOnChange(e)} /> <label for='column_13'> Division</label>
                        <input id="column_14" name='chk' type="checkbox" value='14' checked={checkboxValue.includes(14)} onChange={(e) => checkBoxOnChange(e)} /> <label for='column_14'>Category</label>
                        <input id="column_15" name='chk' type="checkbox" value='15' checked={checkboxValue.includes(15)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_15'>Assigned By</label>
                        <input id="column_16" name='chk' type="checkbox" value='16' checked={checkboxValue.includes(16)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_16'>Assigned To</label>
                        <input id="column_17" name='chk' type="checkbox" value='17' checked={checkboxValue.includes(17)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_17'> Assigned Date</label>
                        <input id="column_18" name='chk' type="checkbox" value='18' checked={checkboxValue.includes(18)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_18'>Assignee</label>
                        <input id="column_19" name='chk' type="checkbox" value='19' checked={checkboxValue.includes(19)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_19'>Completed On</label>
                        <input id="column_20" name='chk' type="checkbox" value='20' checked={checkboxValue.includes(20)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_20'>Concurrer</label>
                        <input id="column_21" name='chk' type="checkbox" value='21' checked={checkboxValue.includes(21)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_21'>Concurrer On</label>
                        <input id="column_22" name='chk' type="checkbox" value='22' checked={checkboxValue.includes(22)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_22'>Approver</label>
                        <input id="column_23" name='chk' type="checkbox" value='23' checked={checkboxValue.includes(23)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_23'>Approved On</label>
                        <input id="column_24" name='chk' type="checkbox" value='24' checked={checkboxValue.includes(24)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_24'>Activity Status</label>
                        <input id="column_25" name='chk' type="checkbox" value='25' checked={checkboxValue.includes(25)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_25'>Start Date</label>
                        <input id="column_26" name='chk' type="checkbox" value='26' checked={checkboxValue.includes(26)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_26'>Due Date</label>
                        <input id="column_27" name='chk' type="checkbox" value='27' checked={checkboxValue.includes(27)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_27'>Extend Due Date</label>
                        <input id="column_28" name='chk' type="checkbox" value='28' checked={checkboxValue.includes(28)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_28'>Task Completion Date / Document Issued Date</label>
                        <input id="column_29" name='chk' type="checkbox" value='29' checked={checkboxValue.includes(29)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_29'>Month</label>
                        <input id="column_30" name='chk' type="checkbox" value='30' checked={checkboxValue.includes(30)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_30'>Validity Date</label>
                        <input id="column_32" name='chk' type="checkbox" value='32' checked={checkboxValue.includes(32)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_32'>Duration</label>
                        <input id="column_33" name='chk' type="checkbox" value='33' checked={checkboxValue.includes(33)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_33'>Document Reference Number</label>
                    </div>

                    <div className="form-actions popupbtncolour text-center">
                        <Button type="primary" shape="round" className={'addbutton mt-3 ' + localStorage.getItem('currentTheme')}
                            icon={<PlayCircleOutlined />} size='default' onClick={handleOk}> Submit
                        </Button>
                    </div>

                </Drawer>

                <div className="container-fluid pt-0">
                    <div className="row">
                        <div className="col-12">
                            <div className={"card-body " + localStorage.getItem('currentTheme')} style={{ padding: " 5px" }}>
                                {/* <div className="card-body" style={{ padding: 0 }}> */}
                                <Collapse defaultActiveKey={["1"]} onChange={onChange} style={{ display: exitCollapse ? "none" : "block" }} >
                                    <Panel header="Compliance Certificate Report" key={'1'} extra={tableshow == true ? genextra() : false}>
                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Country : </b> <span style={{ color: "red" }}>*</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <Select
                                                            showSearch
                                                            allowClear={true}
                                                            placeholder="Enter Country"
                                                            onChange={(value, data) => {
                                                                setEntity([])
                                                                setDomain([])
                                                                setDomainFilter([])
                                                                setDomainValue(false)
                                                                setCurrentEntity('')
                                                                if (value != undefined) {
                                                                    let le = _.filter(sessionArr, { c_id: Number(value) })
                                                                    setEntity(le)
                                                                }
                                                                setComplianceCertificate({
                                                                    ...compliancecertificate,
                                                                    country: value ? value : '',
                                                                    countryName: data ? data.children : '',
                                                                    legel_entity: '',
                                                                    domain: "",
                                                                    division: "",
                                                                    category: "",
                                                                    unit: '',
                                                                    domain_id: '',
                                                                    division_id: null,
                                                                    category_id: null,
                                                                    unit_id: null,
                                                                    from_month: '',
                                                                    to_month: '',
                                                                    legal_entity_id: ''
                                                                })
                                                            }}
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            // onPaste={(e) => {
                                                            //     handlePasting(e, /^[a-z]+$/gi)
                                                            // }}
                                                            onPaste={onPasteToaster}
                                                            searchValue={selectSearchValue.country}
                                                            onSearch={(value) => {
                                                                let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                setSelectSearchValue({
                                                                    ...selectSearchValue,
                                                                    country: result
                                                                });
                                                            }}
                                                            style={{ width: '100%', marginTop: '5px' }}
                                                            value={compliancecertificate.countryName || undefined}
                                                        >
                                                            {uniq_country_data && uniq_country_data.length > 0 && uniq_country_data.map((item, i) => {
                                                                return (
                                                                    <Option key={item.c_id}>
                                                                        {item.c_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        : <p>{compliancecertificate.countryName}</p>}
                                                    {validator.current.message(
                                                        'country',
                                                        compliancecertificate.country,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Country Required',
                                                            }
                                                        })}

                                                </div>
                                                <div className='col-md-4 text-wrap-report' >
                                                    <label><b>Legal Entity  : </b><span style={{ color: "red" }}>*</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <Select
                                                            allowClear={true}
                                                            showSearch

                                                            placeholder='Enter Legal Entity'
                                                            onChange={(value, data) => {
                                                                setDomain([])
                                                                setDomainFilter([])
                                                                setDomainValue(false)
                                                                setCurrentEntity(value)
                                                                let year = _.filter(sessionArray.domain_le_dt, { legal_entity_id: value })
                                                                setYear(year)
                                                                setComplianceCertificate({
                                                                    ...compliancecertificate,
                                                                    legel_entity: value != undefined ? data.children : '',
                                                                    domain: "",
                                                                    division: "",
                                                                    category: "",
                                                                    unit: '',
                                                                    domain_id: '',
                                                                    division_id: null,
                                                                    category_id: null,
                                                                    unit_id: null,
                                                                    from_month: '',
                                                                    to_month: '',
                                                                    legal_entity_id: value != undefined ? value : ''
                                                                })
                                                            }}
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            // onPaste={(e) => {
                                                            //     handlePasting(e, /^[a-z]+$/gi)
                                                            // }}
                                                            onPaste={onPasteToaster}
                                                            searchValue={selectSearchValue.legalEntity}
                                                            onSearch={(value) => {
                                                                let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                setSelectSearchValue({
                                                                    ...selectSearchValue,
                                                                    legalEntity: result
                                                                });
                                                            }}
                                                            style={{ width: '100%', marginTop: '5px' }}
                                                            value={compliancecertificate.legel_entity || undefined}
                                                            disabled={compliancecertificate.country == "" ? true : false}
                                                        >
                                                            {entity && entity.length > 0 && entity.map((item, i) => {
                                                                return (
                                                                    <Option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </Option>
                                                                );
                                                            })}</Select>
                                                        : <p>{localStorage.getItem('SelectedEntity')}</p>}

                                                    {validator.current.message(
                                                        'legalentity',
                                                        compliancecertificate.legel_entity,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Legal Entiy Required',
                                                            }
                                                        })}
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Domain : </b> <span style={{ color: "red" }}>*</span></label>
                                                    {domainValue == false || domainFilter && domainFilter.length > 1 ?
                                                        <>
                                                            <Select
                                                                allowClear={true}
                                                                showSearch

                                                                onChange={(value, data) => {
                                                                    let division = _.filter(certificatecompliancereportfilter && certificatecompliancereportfilter.divisions_list, { le_id: entityid })
                                                                    setDivision(division)
                                                                    setComplianceCertificate({
                                                                        ...compliancecertificate,
                                                                        domain_id: value ? value : '',
                                                                        domain: data ? data.children : '',
                                                                        from_month: '',
                                                                        to_month: ''
                                                                    })
                                                                }}
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                // onPaste={(e) => {
                                                                //     handlePasting(e, /^[a-z]+$/gi)
                                                                // }}
                                                                onPaste={onPasteToaster}
                                                                searchValue={selectSearchValue.domain}
                                                                onSearch={(value) => {
                                                                    let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                    setSelectSearchValue({
                                                                        ...selectSearchValue,
                                                                        domain: result
                                                                    });
                                                                }}
                                                                placeholder="Enter Domain Name"
                                                                style={{ width: '100%', marginTop: '5px' }}
                                                                value={compliancecertificate.domain || undefined}
                                                                disabled={((localStorage.getItem('SelectedEntity') === "All Legal Entity") && compliancecertificate.legel_entity == '') ? true : false}
                                                            >
                                                                {domainFilter && domainFilter.length > 0 && domainFilter.map((item, i) => {
                                                                    return (
                                                                        // data.unit ?
                                                                        <Option key={item.d_id}>
                                                                            {item.d_name}
                                                                        </Option>
                                                                        // :""
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'domain',
                                                                compliancecertificate.domain,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Domain Required',
                                                                    }
                                                                })}
                                                        </>
                                                        : <p className='cut-text-word' style={{ width: '100%', marginTop: "5px" }}>{domain && domain.length > 0 && domain[0].d_name}</p>}
                                                </div>
                                            </div>
                                            <div className="row mt-1">
                                                <div className='col-md-4'>
                                                    <label style={{ "fontWeight": "bold" }}>From Month: <span style={{ color: "red" }} >*</span></label>
                                                    {due === false ?
                                                        <>
                                                            <DatePicker format={'MMMM-YYYY'} style={{ width: '100%', marginTop: '5px' }}
                                                                disabled={compliancecertificate.domain == "" ? true : false}
                                                                allowClear={false}
                                                                 picker="month"
                                                                value={compliancecertificate.from_month ? moment(compliancecertificate.from_month) : ''}
                                                                onChange={(date, dateString) => {
                                                                    setDue(true);
                                                                    setComplianceCertificate({
                                                                        ...compliancecertificate,
                                                                        from_month: dateString ? dateString : '',
                                                                        to_month: dateString ? moment(dateString).add(2, 'months') : ''
                                                                    })
                                                                }}
                                                                disabledDate={(value) => {
                                                                    let text = year && year.length > 0 && year[0].le_st_dmn_dt
                                                                    let splitValue = text.split('-')
                                                                    console.log(Number(splitValue[2]), 'splitValue');
                                                                    console.log(Number(splitValue[2]) == moment().year(), 'splitValue == moment().year()');
                                                                    if (Number(splitValue[2]) == moment().year()) {
                                                                        return moment().startOf('year') >= value || moment().add(0, 'years') <= value
                                                                    }
                                                                    else {
                                                                        return moment().add(-1, 'years').startOf('year') >= value || moment().add(0, 'years') <= value
                                                                    }
                                                                }}
                                                                monthCellRender={(current) => {
                                                                    const style = {};
                                                                    if (
                                                                        moment(current).format("MMM-YYYY") ===
                                                                        moment().format("MMM-YYYY")
                                                                    ) {
                                                                        // style.border = '1px solid #1890ff';
                                                                        style.borderRadius = "2px";
                                                                        style.lineHeight = "24px";
                                                                        style.minWidth = "24px";
                                                                        style.position = "relative";
                                                                        style.display = "inlineBlock";
                                                                        style.backgroundColor = "#1890ff";
                                                                        style.color = "white";
                                                                        style.marginTop = "5px";
                                                                        style.fontSize = "15px"
                                                                    }
                                                                    return (
                                                                        <div
                                                                            className="ant-picker-cell-inner"
                                                                            style={style}
                                                                        >
                                                                            {moment(current).format("MMM")}
                                                                        </div>
                                                                    );
                                                                }}

                                                            />
                                                            {validator.current.message(
                                                                'from_month',
                                                                compliancecertificate.from_month,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'From Month Required',
                                                                    }
                                                                })}
                                                        </> :
                                                        <>
                                                            <DatePicker format={'MMMM-YYYY'} style={{ width: '100%', marginTop: '5px' }}
                                                                disabled={compliancecertificate.domain == "" ? true : false}
                                                                allowClear={false} picker="month"
                                                                value={compliancecertificate.from_month ? moment(compliancecertificate.from_month) : ''}
                                                                onChange={(date, dateString) => {
                                                                    setDue(true);
                                                                    setComplianceCertificate({
                                                                        ...compliancecertificate,
                                                                        from_month: dateString ? dateString : '',
                                                                        to_month: dateString ? moment(dateString).add(2, 'months') : ''
                                                                    })
                                                                }}
                                                                disabledDate={(value) => {
                                                                    let text = year && year.length > 0 && year[0].le_st_dmn_dt
                                                                    let splitValue = text.split('-')
                                                                    console.log(Number(splitValue[2]), 'splitValue');
                                                                    console.log(Number(splitValue[2]) == moment().year(), 'splitValue == moment().year()');
                                                                    if (Number(splitValue[2]) == moment().year()) {
                                                                        return moment().startOf('year') >= value || moment().add(0, 'years') <= value
                                                                    }
                                                                    else {
                                                                        return moment().add(-1, 'years').startOf('year') >= value || moment().add(0, 'years') <= value
                                                                    }
                                                                }}
                                                            />
                                                            {validator.current.message(
                                                                'from_month',
                                                                compliancecertificate.from_month,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'From Month Required',
                                                                    }
                                                                })}
                                                        </>}

                                                </div>
                                                <div className='col-md-4'>
                                                    <label style={{ "fontWeight": "bold" }}>To Month: <span style={{ color: "red" }} >*</span></label>
                                                    <DatePicker style={{ width: '100%', marginTop: '5px' }}
                                                        format={'MMMM-YYYY'}
                                                        disabled={compliancecertificate.from_month == "" ? true : false}
                                                        allowClear={false} picker="month"
                                                        value={compliancecertificate.to_month ? moment(compliancecertificate.to_month) : ''}
                                                        onChange={(date, dateString) => {
                                                            setComplianceCertificate({
                                                                ...compliancecertificate,
                                                                to_month: dateString ? dateString : ''
                                                            })
                                                        }}
                                                        disabledDate={(value) => {
                                                            return moment(compliancecertificate.from_month) >= value || moment(compliancecertificate.from_month).add(1, 'years') <= value;
                                                        }}
                                                    />
                                                    {validator.current.message(
                                                        'to_month',
                                                        compliancecertificate.to_month,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'To Month Required',
                                                            }
                                                        })}
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Division : </b></label>
                                                    <Select
                                                        allowClear={true}
                                                        showSearch

                                                        disabled={compliancecertificate.domain == "" ? true : false}
                                                        onChange={(value, data) => {
                                                            let category = _.filter(certificatecompliancereportfilter && certificatecompliancereportfilter.categories_list, { div_id: Number(value) })
                                                            setCategoryList(category)
                                                            let unit = _.filter(certificatecompliancereportfilter && certificatecompliancereportfilter.unit_legal_entity, { division_id: Number(value) })
                                                            setUnit(unit)
                                                            setComplianceCertificate({
                                                                ...compliancecertificate,
                                                                division_id: value ? value : '',
                                                                division: data ? data.children : '',
                                                                category_id: null,
                                                                category: '',
                                                                unit: '',
                                                                unit_id: null
                                                            })
                                                        }}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z]+$/gi)
                                                        // }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.division}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                division: result
                                                            });
                                                        }}
                                                        placeholder="Enter Division Name"
                                                        style={{ width: '100%', marginTop: '5px' }}

                                                        value={compliancecertificate.division || undefined}
                                                    >
                                                        {division && division.length > 0 && division.map((item, i) => {
                                                            return (
                                                                <Option key={item.div_id}>
                                                                    {item.div_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="row mt-1">

                                                <div className='col-md-4'>
                                                    <label><b>Category : </b></label>
                                                    <Select
                                                        allowClear={true}
                                                        showSearch

                                                        disabled={compliancecertificate.domain == "" ? true : false}
                                                        onChange={(value, data) => {
                                                            let unit = _.filter(certificatecompliancereportfilter && certificatecompliancereportfilter.unit_legal_entity, { category_id: Number(value) })
                                                            setUnit(unit)
                                                            setComplianceCertificate({
                                                                ...compliancecertificate,
                                                                category_id: value ? value : '',
                                                                category: data ? data.children : '',
                                                                unit: '',
                                                                unit_id: null
                                                            })
                                                        }}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z]+$/gi)
                                                        // }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.category}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                category: result
                                                            });
                                                        }}
                                                        placeholder="Enter Category Name"
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                        value={compliancecertificate.category || undefined}
                                                    >
                                                        {categorylist && categorylist.length > 0 && categorylist.map((item, i) => {
                                                            return (
                                                                <Option key={item.cat_id}>
                                                                    {item.cat_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Vendor : </b></label>
                                                    <Select
                                                        allowClear={true}
                                                        showSearch
                                                        disabled={compliancecertificate.domain == "" ? true : false}
                                                        onChange={(value, data) => {
                                                            setComplianceCertificate({
                                                                ...compliancecertificate,
                                                                unit_id: value ? value : '',
                                                                unit: data ? `${data.children[0]}-${data.children[2]}` : ''
                                                            })
                                                        }}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z]+$/gi)
                                                        // }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.vendor}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                vendor: result
                                                            });
                                                        }}
                                                        placeholder="Enter Unit"
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                        value={compliancecertificate.unit || undefined}
                                                    >
                                                        {unit && unit.length > 0 && unit.map((item, i) => {
                                                            return (
                                                                <Option key={item.unit_id}>
                                                                    {item.unit_code} - {item.unit_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'></div>
                                                <div className='col-md-4 popupbtncolour text-center'>
                                                    <Button type="primary" shape="round" icon={<EyeOutlined />} className={'addbutton mx-1 ' + localStorage.getItem('currentTheme')} onClick={onshow}
                                                    >
                                                        Show
                                                    </Button>
                                                    <Button type="primary" shape="round" icon={<ExportOutlined />} className={'addbutton mx-1 ' + localStorage.getItem('currentTheme')} onClick={showModal}
                                                    >
                                                        Export
                                                    </Button>
                                                    {/* <button className="btn btn-secondary" style={{ marginLeft: "59px", marginRight: "10px", width: "100px" }} onClick={onshow}>Show</button>
                                                        <button className="btn btn-success" style={{ width: "100px" }} >Export</button> */}
                                                </div>
                                                <div className='col-md-4'></div>
                                            </div>
                                        </div>
                                    </Panel>
                                </Collapse>
                                <Modal
                                    onCancel={setcancelFilter}
                                    footer={false}
                                    title="Compliance Certificate Report"
                                    className={
                                        "add-service-prv cons-report " +
                                        localStorage.getItem("currentTheme")
                                    }
                                    open={filterModalVisible}
                                    maskClosable={false}
                                >
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Country : </b> <span style={{ color: "red" }}>*</span></label>
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <Select
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        showSearch
                                                        allowClear={true}
                                                        placeholder="Enter Country"
                                                        onChange={(value, data) => {
                                                            setEntity([])
                                                            setDomain([])
                                                            setDomainFilter([])
                                                            setDomainValue(false)
                                                            setCurrentEntity('')
                                                            if (value != undefined) {
                                                                let le = _.filter(sessionArr, { c_id: Number(value) })
                                                                setEntity(le)
                                                            }
                                                            setComplianceCertificate({
                                                                ...compliancecertificate,
                                                                country: value ? value : '',
                                                                countryName: data ? data.children : '',
                                                                legel_entity: '',
                                                                domain: "",
                                                                division: "",
                                                                category: "",
                                                                unit: '',
                                                                domain_id: '',
                                                                division_id: null,
                                                                category_id: null,
                                                                unit_id: null,
                                                                from_month: '',
                                                                to_month: '',
                                                                legal_entity_id: ''
                                                            })
                                                        }}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z]+$/gi)
                                                        // }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.country}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                country: result
                                                            });
                                                        }}
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                        value={compliancecertificate.countryName || undefined}
                                                    >
                                                        {uniq_country_data && uniq_country_data.length > 0 && uniq_country_data.map((item, i) => {
                                                            return (
                                                                <Option key={item.c_id}>
                                                                    {item.c_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    : <p>{compliancecertificate.countryName}</p>}
                                                {validator.current.message(
                                                    'country',
                                                    compliancecertificate.country,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Country Required',
                                                        }
                                                    })}

                                            </div>
                                            <div className='col-md-4 text-wrap-report' >
                                                <label><b>Legal Entity  : </b><span style={{ color: "red" }}>*</span></label>
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <Select
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        showSearch
                                                        allowClear={true}

                                                        placeholder='Enter Legal Entity'
                                                        onChange={(value, data) => {
                                                            setDomain([])
                                                            setDomainFilter([])
                                                            setDomainValue(false)
                                                            setCurrentEntity(value)
                                                            setComplianceCertificate({
                                                                ...compliancecertificate,
                                                                legel_entity: data ? data.children : '',
                                                                domain: "",
                                                                division: "",
                                                                category: "",
                                                                unit: '',
                                                                domain_id: '',
                                                                division_id: null,
                                                                category_id: null,
                                                                unit_id: null,
                                                                from_month: '',
                                                                to_month: ''
                                                            })
                                                        }}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z]+$/gi)
                                                        // }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.legalEntity}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                legalEntity: result
                                                            });
                                                        }}
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                        value={compliancecertificate.legel_entity || undefined}
                                                        disabled={compliancecertificate.country == "" ? true : false}
                                                    >
                                                        {entity && entity.length > 0 && entity.map((item, i) => {
                                                            return (
                                                                <Option key={item.le_id}>
                                                                    {item.le_name}
                                                                </Option>
                                                            );
                                                        })}</Select>
                                                    : <p>{localStorage.getItem('SelectedEntity')}</p>}

                                                {validator.current.message(
                                                    'legalentity',
                                                    compliancecertificate.legel_entity,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Legal Entiy Required',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Domain : </b> <span style={{ color: "red" }}>*</span></label>
                                                {domainValue == false || domainFilter && domainFilter.length > 1 ?
                                                    <>
                                                        <Select
                                                            getPopupContainer={trigger => trigger.parentNode}
                                                            showSearch

                                                            allowClear={true}

                                                            onChange={(value, data) => {
                                                                let division = _.filter(certificatecompliancereportfilter && certificatecompliancereportfilter.divisions_list, { le_id: entityid })
                                                                setDivision(division)
                                                                setComplianceCertificate({
                                                                    ...compliancecertificate,
                                                                    domain_id: value ? value : '',
                                                                    domain: data ? data.children : '',
                                                                    from_month: '',
                                                                    to_month: ''
                                                                })
                                                            }}
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            // onPaste={(e) => {
                                                            //     handlePasting(e, /^[a-z]+$/gi)
                                                            // }}
                                                            onPaste={onPasteToaster}
                                                            searchValue={selectSearchValue.domain}
                                                            onSearch={(value) => {
                                                                let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                setSelectSearchValue({
                                                                    ...selectSearchValue,
                                                                    domain: result
                                                                });
                                                            }}
                                                            placeholder="Enter Domain Name"
                                                            style={{ width: '100%', marginTop: '5px' }}
                                                            value={compliancecertificate.domain || undefined}
                                                            disabled={((localStorage.getItem('SelectedEntity') === "All Legal Entity") && compliancecertificate.legel_entity == '') ? true : false}
                                                        >
                                                            {domainFilter && domainFilter.length > 0 && domainFilter.map((item, i) => {
                                                                return (
                                                                    // data.unit ?
                                                                    <Option key={item.d_id}>
                                                                        {item.d_name}
                                                                    </Option>
                                                                    // :""
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'domain',
                                                            compliancecertificate.domain,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Domain Required',
                                                                }
                                                            })}
                                                    </>
                                                    : <p className='cut-text-word' style={{ width: '100%', marginTop: "5px" }}>{domain && domain.length > 0 && domain[0].d_name}</p>}
                                            </div>
                                        </div>
                                        <div className="row mt-1">
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>From Month: <span style={{ color: "red" }} >*</span></label>
                                                {due === false ?
                                                    <>
                                                        <DatePicker format={'MMMM-YYYY'} style={{ width: '100%', marginTop: '5px' }}
                                                            getPopupContainer={trigger => trigger.parentNode}
                                                            disabled={compliancecertificate.domain == "" ? true : false}
                                                            allowClear={false} picker="month"
                                                            value={compliancecertificate.from_month ? moment(compliancecertificate.from_month) : ''}
                                                            onChange={(date, dateString) => {
                                                                setDue(true);
                                                                setComplianceCertificate({
                                                                    ...compliancecertificate,
                                                                    from_month: dateString ? dateString : '',
                                                                    to_month: dateString ? moment(dateString).add(2, 'months') : ''
                                                                })
                                                            }}
                                                            disabledDate={(value) => {
                                                                let text = year && year.length > 0 && year[0].le_st_dmn_dt
                                                                let splitValue = text.split('-')
                                                                console.log(Number(splitValue[2]), 'splitValue');
                                                                console.log(Number(splitValue[2]) == moment().year(), 'splitValue == moment().year()');
                                                                if (Number(splitValue[2]) == moment().year()) {
                                                                    return moment().startOf('year') >= value || moment().add(0, 'years') <= value
                                                                }
                                                                else {
                                                                    return moment().add(-1, 'years').startOf('year') >= value || moment().add(0, 'years') <= value
                                                                }
                                                            }}
                                                            monthCellRender={(current) => {
                                                                const style = {};
                                                                if (
                                                                    moment(current).format("MMM-YYYY") ===
                                                                    moment().format("MMM-YYYY")
                                                                ) {
                                                                    // style.border = '1px solid #1890ff';
                                                                    style.borderRadius = "2px";
                                                                    style.lineHeight = "24px";
                                                                    style.minWidth = "24px";
                                                                    style.position = "relative";
                                                                    style.display = "inlineBlock";
                                                                    style.backgroundColor = "#1890ff";
                                                                    style.color = "white";
                                                                    style.marginTop = "5px";
                                                                    style.fontSize = "15px"
                                                                }
                                                                return (
                                                                    <div
                                                                        className="ant-picker-cell-inner"
                                                                        style={style}
                                                                    >
                                                                        {moment(current).format("MMM")}
                                                                    </div>
                                                                );
                                                            }}

                                                        />
                                                        {validator.current.message(
                                                            'from_month',
                                                            compliancecertificate.from_month,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'From Month Required',
                                                                }
                                                            })}
                                                    </> :
                                                    <>
                                                        <DatePicker format={'MMMM-YYYY'} style={{ width: '100%', marginTop: '5px' }}
                                                            getPopupContainer={trigger => trigger.parentNode}
                                                            disabled={compliancecertificate.domain == "" ? true : false}
                                                            allowClear={false} picker="month"
                                                            value={compliancecertificate.from_month ? moment(compliancecertificate.from_month) : ''}
                                                            onChange={(date, dateString) => {
                                                                setDue(true);
                                                                setComplianceCertificate({
                                                                    ...compliancecertificate,
                                                                    from_month: dateString ? dateString : '',
                                                                    to_month: dateString ? moment(dateString).add(2, 'months') : ''
                                                                })
                                                            }}
                                                            disabledDate={(value) => {
                                                                let text = year && year.length > 0 && year[0].le_st_dmn_dt
                                                                let splitValue = text.split('-')
                                                                console.log(Number(splitValue[2]), 'splitValue');
                                                                console.log(Number(splitValue[2]) == moment().year(), 'splitValue == moment().year()');
                                                                if (Number(splitValue[2]) == moment().year()) {
                                                                    return moment().startOf('year') >= value || moment().add(0, 'years') <= value
                                                                }
                                                                else {
                                                                    return moment().add(-1, 'years').startOf('year') >= value || moment().add(0, 'years') <= value
                                                                }
                                                            }}
                                                        />
                                                        {validator.current.message(
                                                            'from_month',
                                                            compliancecertificate.from_month,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'From Month Required',
                                                                }
                                                            })}
                                                    </>}
                                            </div>
                                            <div className='col-md-4'>
                                                <label style={{ "fontWeight": "bold" }}>To Month: <span style={{ color: "red" }} >*</span></label>

                                                <DatePicker style={{ width: '100%', marginTop: '5px' }}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    format={'MMMM-YYYY'}
                                                    disabled={compliancecertificate.from_month == "" ? true : false}
                                                    allowClear={false} picker="month"
                                                    value={compliancecertificate.to_month ? moment(compliancecertificate.to_month) : ''}
                                                    onChange={(date, dateString) => {
                                                        setDueTo(true)
                                                        setComplianceCertificate({
                                                            ...compliancecertificate,
                                                            to_month: dateString ? dateString : ''
                                                        })
                                                    }}
                                                    disabledDate={(value) => {
                                                        return moment(compliancecertificate.from_month) >= value || moment().add(0, 'years') <= value;
                                                    }}
                                                />
                                                {validator.current.message(
                                                    'to_month',
                                                    compliancecertificate.to_month,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'To Month Required',
                                                        }
                                                    })}


                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Division : </b></label>
                                                <Select
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    showSearch

                                                    allowClear={true}

                                                    disabled={compliancecertificate.domain == "" ? true : false}
                                                    onChange={(value, data) => {
                                                        let category = _.filter(certificatecompliancereportfilter && certificatecompliancereportfilter.categories_list, { div_id: Number(value) })
                                                        setCategoryList(category)
                                                        let unit = _.filter(certificatecompliancereportfilter && certificatecompliancereportfilter.unit_legal_entity, { division_id: Number(value) })
                                                        setUnit(unit)
                                                        setComplianceCertificate({
                                                            ...compliancecertificate,
                                                            division_id: value ? value : '',
                                                            division: data ? data.children : '',
                                                            category_id: null,
                                                            category: '',
                                                            unit: '',
                                                            unit_id: null
                                                        })
                                                    }}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z]+$/gi)
                                                    // }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.division}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            division: result
                                                        });
                                                    }}
                                                    placeholder="Enter Division Name"
                                                    style={{ width: '100%', marginTop: '5px' }}

                                                    value={compliancecertificate.division || undefined}
                                                >
                                                    {division && division.length > 0 && division.map((item, i) => {
                                                        return (
                                                            <Option key={item.div_id}>
                                                                {item.div_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="row mt-1">

                                            <div className='col-md-4'>
                                                <label><b>Category : </b></label>
                                                <Select
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    showSearch

                                                    allowClear={true}

                                                    disabled={compliancecertificate.domain == "" ? true : false}
                                                    onChange={(value, data) => {
                                                        let unit = _.filter(certificatecompliancereportfilter && certificatecompliancereportfilter.unit_legal_entity, { category_id: Number(value) })
                                                        setUnit(unit)
                                                        setComplianceCertificate({
                                                            ...compliancecertificate,
                                                            category_id: value ? value : '',
                                                            category: data ? data.children : '',
                                                            unit: '',
                                                            unit_id: null
                                                        })
                                                    }}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z]+$/gi)
                                                    // }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.category}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            category: result
                                                        });
                                                    }}
                                                    placeholder="Enter Category Name"
                                                    style={{ width: '100%', marginTop: '5px' }}
                                                    value={compliancecertificate.category || undefined}
                                                >
                                                    {categorylist && categorylist.length > 0 && categorylist.map((item, i) => {
                                                        return (
                                                            <Option key={item.cat_id}>
                                                                {item.cat_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Vendor : </b></label>
                                                <Select
                                                    getPopupContainer={trigger => trigger.parentNode}

                                                    allowClear={true}
                                                    showSearch
                                                    disabled={compliancecertificate.domain == "" ? true : false}
                                                    onChange={(value, data) => {
                                                        setComplianceCertificate({
                                                            ...compliancecertificate,
                                                            unit_id: value ? value : '',
                                                            unit: data ? `${data.children[0]}-${data.children[2]}` : ''
                                                        })
                                                    }}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z]+$/gi)
                                                    // }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.vendor}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            vendor: result
                                                        });
                                                    }}
                                                    placeholder="Enter Unit"
                                                    style={{ width: '100%', marginTop: '5px' }}
                                                    value={compliancecertificate.unit || undefined}
                                                >
                                                    {unit && unit.length > 0 && unit.map((item, i) => {
                                                        return (
                                                            <Option key={item.unit_id}>
                                                                {item.unit_code} - {item.unit_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'></div>
                                            <div className='col-md-4 popupbtncolour text-center'>
                                                <Button type="primary" shape="round" icon={<EyeOutlined />} className={'addbutton mx-1 ' + localStorage.getItem('currentTheme')} onClick={onshow}
                                                >
                                                    Show
                                                </Button>
                                                <Button type="primary" shape="round" icon={<ExportOutlined />} className={'addbutton mx-1 ' + localStorage.getItem('currentTheme')} onClick={showModal}
                                                >
                                                    Export
                                                </Button>
                                                {/* <button className="btn btn-secondary" style={{ marginLeft: "59px", marginRight: "10px", width: "100px" }} onClick={onshow}>Show</button>
                                                        <button className="btn btn-success" style={{ width: "100px" }} >Export</button> */}
                                            </div>
                                            <div className='col-md-4'></div>
                                        </div>
                                    </div>
                                </Modal>
                                <Drawer title="Compliance Certificate Report" width={400} placement="right" open={open} onClose={onClose}>
                                    <Card style={{ padding: 0 }}>
                                        <div className='col-md-12 p-0'>
                                            <div className='row'>
                                                <div className='col-md-12 p-0'>
                                                    <label>Country : <span style={{ fontWeight: 'bold' }}> {compliancecertificate.countryName}</span> </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 p-0'>
                                            <div className='row'>
                                                <div className='col-md-12 p-0 text-wrap-report'>
                                                    <label>Legal Entity : <span style={{ fontWeight: 'bold' }}>{compliancecertificate.legel_entity}</span></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 p-0'>
                                            <div className='row'>
                                                <div className='col-md-12 p-0'>
                                                    <label>Domain : <span style={{ fontWeight: 'bold' }}> {compliancecertificate.domain}</span> </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 p-0'>
                                            <div className='row'>
                                                <div className='col-md-12 p-0'>
                                                    <label>Division : <span style={{ fontWeight: 'bold' }}>{compliancecertificate.division}</span></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 p-0' >
                                            <div className='row'>
                                                <div className='col-md-12 p-0'>
                                                    <label>Category : <span style={{ fontWeight: 'bold' }}> {compliancecertificate.category}</span> </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 p-0'>
                                            <div className='row'>
                                                <div className='col-md-12 p-0'>
                                                    <label>Unit : <span style={{ fontWeight: 'bold' }}>{compliancecertificate.unit}</span></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 p-0'>
                                            <div className='row'>
                                                <div className='col-md-12 p-0'>
                                                    <label>Period : <span style={{ fontWeight: 'bold' }}> {compliancecertificate.period}</span> </label>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </Drawer>
                                {tableshow == true ?
                                    // <Card>
                                    <Card className='rounded-3'>
                                        <>
                                            <div className='filter-detial-sticky'>
                                                {/* <h2 style={{ textAlign: 'center' }}>Month Wise Status Report</h2>
                                                <div className='col-md-12' style={{ marginTop: "30px", marginBottom: "15px" }}>
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                            <label>Legal Entity : <span style={{ fontWeight: 'bold' }}>{compliancecertificate.legel_entity}</span></label>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <label>Country : <span style={{ fontWeight: 'bold' }}>{compliancecertificate.country}</span></label>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <label>Domain : <span style={{ fontWeight: 'bold' }}>{compliancecertificate.domain}</span></label>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                            <div className='ant-table-wrapper'>
                                                <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                    <div className='ant-table-container'>
                                                        <div className='ant-table-content userprivclass1'>
                                                            <table className='table-fixed'>
                                                                <colgroup>
                                                                    <col style={{ width: '10px' }}></col>
                                                                </colgroup>
                                                                <thead className='ant-table-thead table-head-stick' >
                                                                    <tr>
                                                                        <th className='ant-table-cell' style={{ width: '10px' }}> # </th>
                                                                        <th title='Compliance Task' className='ant-table-cell' style={{ width: "90px" }}>Month</th>
                                                                        <th className='ant-table-cell' style={{ width: "90px" }}><center>Invoice Number</center></th>
                                                                        {/* {monthlyArray && monthlyArray.length > 0 && monthlyArray.map((item) => {
                                                                            return (
                                                                                <th className='ant-table-cell' style={{ width: "90px" }}><center>{item}</center></th>
                                                                            )
                                                                        })} */}
                                                                        <th className='ant-table-cell' style={{ width: "100px" }}><center>Act</center></th>
                                                                        <th className='ant-table-cell' style={{ width: "110px" }}><center>Compliance Task</center></th>
                                                                        <th className='ant-table-cell' style={{ width: "90px" }}><center>Criticality</center></th>
                                                                        <th className='ant-table-cell' style={{ width: "90px" }}><center>User Name</center></th>
                                                                        <th className='ant-table-cell' style={{ width: "90px" }}><center>Activity Status</center></th>
                                                                        <th className='ant-table-cell' style={{ width: "90px" }}><center>Due Date</center></th>
                                                                        <th className='ant-table-cell' style={{ width: "90px" }} ><center>Compliance Status</center></th>
                                                                        {/* <th className='ant-table-cell' style={{ width: "90px" }}><center>Certificate</center></th> */}
                                                                        <th className='ant-table-cell' style={{ width: "90px" }}><center>Uploaded Document</center></th>
                                                                    </tr>
                                                                </thead>
                                                                {filteredArrayState && filteredArrayState.length > 0 ?
                                                                    filteredArrayState.map((item) => {
                                                                        return (
                                                                            <tbody className='ant-table-tbody' style={{ padding: 0 }}>
                                                                                <tr className="bg-lightdark">
                                                                                    <td style={{ paddingLeft: '0 !important', paddingRight: '0 !important' }} colSpan="100%">
                                                                                        <div className='bg-white shadow-sm border-radius-reports'>
                                                                                            <div className="col-md-12">
                                                                                                <div className='row m-2'>
                                                                                                    <div className='col-md-3' >
                                                                                                        <label><b>Vendor :</b> {item[0].unit_name}</label>
                                                                                                    </div>
                                                                                                    <div className='col-md-3' >
                                                                                                        <label><b>Division :</b> {item[0].division_name}</label>
                                                                                                    </div>
                                                                                                    <div className='col-md-3' >
                                                                                                        <label><b>Category :</b> {item[0].category_name}</label>
                                                                                                    </div>
                                                                                                    <div className='col-md-3' >
                                                                                                        <label><b>Time Zone :</b> {item[0].tz_name}</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                {titledata && titledata.map((itemdata, i) => {
                                                                                    let data = _.find(itemdata.child, { unit_id: Number(item[0].unit_id) })
                                                                                    if (data != undefined) {
                                                                                        if (data != undefined) {
                                                                                            if (data.period == itemdata.parent) {
                                                                                                let temparray = []
                                                                                                let temp = childState
                                                                                                let temparr = {}
                                                                                                let array = childValue
                                                                                                return <>
                                                                                                    <tr >
                                                                                                        <td colSpan={'100%'} >
                                                                                                            <b style={{ cursor: 'pointer' }} onClick={() => {
                                                                                                                console.log(itemdata.child[0].period, 'periodperiod')
                                                                                                                const index = childValue.indexOf(itemdata.child[0].period);
                                                                                                                if (index > -1) {
                                                                                                                    array.splice(index, 1);
                                                                                                                } else {
                                                                                                                    array.push(itemdata.child[0].period)
                                                                                                                }
                                                                                                                setChildValue([...array])
                                                                                                                if (itemdata.child.length > 0) {
                                                                                                                    if (childState[i] == true) {
                                                                                                                        temp[i] = false;
                                                                                                                    } else { temp[i] = true; }
                                                                                                                    setChildState(temp)
                                                                                                                }
                                                                                                            }}>
                                                                                                                {itemdata.child[0].period}
                                                                                                            </b>
                                                                                                            <span className='ant-table-cell mx-2'
                                                                                                                style={{ cursor: 'pointer', display: 'inline' }}
                                                                                                                onClick={() => {
                                                                                                                    invoice(itemdata.child[0].period)
                                                                                                                    setInvoiceModal(true)
                                                                                                                }}
                                                                                                            ><PlusCircleOutlined /></span></td>
                                                                                                    </tr>
                                                                                                    {itemdata.child.map((items, i) => {
                                                                                                        // if (childValue.indexOf(items.period) > -1 ? true : false) {
                                                                                                        return <tr>
                                                                                                            <td className='ant-table-cell'><center>{index + 1}</center></td>
                                                                                                            <td className='ant-table-cell'><center>{ }</center></td>
                                                                                                            <td className='ant-table-cell'><center>{ }</center></td>
                                                                                                            <td className='ant-table-cell'>{items.statutory_mapping}</td>
                                                                                                            <td className='ant-table-cell'>
                                                                                                                <div >
                                                                                                                    <Tooltip title={items.compliance_description} >
                                                                                                                        <span className='text-primary ms-1'>
                                                                                                                            <i className="ri-information-fill" /></span>
                                                                                                                    </Tooltip>
                                                                                                                    <span style={{ whiteSpace: 'unset' }}>&nbsp;{items.compliance_task}</span>
                                                                                                                </div></td>
                                                                                                            <td className='ant-table-cell'>{items.criticality_name}</td>
                                                                                                            <td className='ant-table-cell'>{items.user_name}</td>
                                                                                                            <td className='ant-table-cell'>
                                                                                                                <span className='ms-3'>{items.activity_status}</span>
                                                                                                                {items.history_count != 0 && items.remarks_count != 0 ? <span className="text-primary ms-1">
                                                                                                                    <Tooltip title='Click to View Activity History'><i className="ri-information-fill" onClick={() => { viewactivity_histroy(items) }} />
                                                                                                                    </Tooltip></span> : ''}
                                                                                                                {items.interim_count != 0 ?
                                                                                                                    <span className="text-primary ms-1">
                                                                                                                        <Tooltip title='Click to view Interim Details'>
                                                                                                                            <i className='ri-information-fill' style={{ color: '#05A4FA' }} onClick={() => { view_interim(items) }} /></Tooltip></span> : ''}
                                                                                                            </td>
                                                                                                            <td className='ant-table-cell'><center>{items.due_date}</center></td>
                                                                                                            <td className='ant-table-cell'><center>{items.current_status}</center></td>

                                                                                                            <td className='ant-table-cell'><center>
                                                                                                                {items.document_name != '' && items.document_name != "-" && items.document_name != null ||
                                                                                                                    items.concur_document_name != '' && items.concur_document_name != "-" && items.concur_document_name != null ||
                                                                                                                    items.approval_document_names != '' && items.approval_document_names != "-" && items.approval_document_names != null ?
                                                                                                                    <Link
                                                                                                                        onClick={() => {
                                                                                                                            setdocumentmodalview(true)
                                                                                                                            documentdata(items)
                                                                                                                        }}><Tooltip title={'Click here to View Uploaded Documents'}><FileTextOutlined /></Tooltip></Link> : ''}</center></td>
                                                                                                            <span hidden>{index = index + 1}</span>
                                                                                                        </tr>
                                                                                                        // }
                                                                                                    })}


                                                                                                </>
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                })
                                                                                }
                                                                            </tbody>
                                                                        )
                                                                    }) :
                                                                    <tr>
                                                                        <td colSpan="100%" style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                                    </tr>
                                                                }
                                                            </table>

                                                            <div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </>
                                        {/* <Pagination
                                                showTotal={(total) => `Showing ${reportlist && reportlist.tabledata && reportlist.tabledata.total_count} entries`}
                                                defaultPageSize={25}
                                                defaultCurrent={1}
                                            /> */}
                                        <Modal title="Certificate" className={"add-service-prv remarksClass " + localStorage.getItem('currentTheme')} open={invoicemodal} onCancel={handleCancel}
                                            onOk={() => { setInvoiceModal(false) }} footer={null} maskClosable={false}>
                                            <Table
                                                className='userprivclass'
                                                columns={columns}
                                                dataSource={invoicedata}
                                                bordered
                                                pagination={false}
                                            />
                                        </Modal>
                                        <Modal title="Uploaded Documents" className={"add-service-prv cons-report-certi remarksClass " + localStorage.getItem('currentTheme')} open={documentmodalview} onCancel={handleCancel}
                                            onOk={() => { setdocumentmodalview(false) }} footer={null} maskClosable={false}>
                                            <Table
                                                className='userprivclass'
                                                columns={columnsdocument}
                                                dataSource={updateddocumentdata}
                                                bordered
                                                pagination={false}
                                            />
                                        </Modal>

                                        <Modal open={historymodal}
                                            title="Remarks History"
                                            className={" add-service-prv " + localStorage.getItem('currentTheme')}
                                            footer={null}
                                            onCancel={historycancel} maskClosable={false}>
                                            <Table className={`stats ${localStorage.getItem("currentTheme")}`}
                                                columns={history_columns}
                                                dataSource={history_record && history_record.remarks_history}
                                                pagination={false}
                                            />
                                        </Modal>

                                        <Modal open={interimmodal}
                                            title="Interim Status History"
                                            className={" add-service-prv " + localStorage.getItem('currentTheme')}
                                            footer={null}
                                            onCancel={interimcancel} maskClosable={false}>
                                            <Table className={`stats ${localStorage.getItem("currentTheme")}`}
                                                columns={interim_columns}
                                                dataSource={interim_record && interim_record}
                                                pagination={false}
                                            />
                                        </Modal>


                                        {<Modal title="Attachment" className={"remarksClass add-service-prv " + localStorage.getItem('currentTheme')}
                                            open={Documentmodal}
                                            onCancel={handledocumentCancel} onOk={() => {
                                                setInvoiceModal(false)
                                            }} footer={null} maskClosable={false}>
                                            <Table
                                                className='userprivclass'
                                                columns={column}
                                                dataSource={documentview}
                                                bordered
                                                pagination={false} />
                                        </Modal>}
                                        <div className="row mt-3">
                                            {certificatecompliancereportfiltershow && certificatecompliancereportfiltershow.total_count > 0 ? (
                                                <div className="col-md-6">
                                                    <label>
                                                        Showing {pageSize * (current - 1) + 1} to {index} of {' '}
                                                        {certificatecompliancereportfiltershow && certificatecompliancereportfiltershow.total_count} entries
                                                    </label>

                                                </div>
                                            ) : (
                                                ""
                                            )}
                                            {certificatecompliancereportfiltershow && certificatecompliancereportfiltershow.total_count > 0 ?
                                                <div className="col-md-6 text-right">
                                                    <Pagination className='d-flex justify-content-end mt-1'
                                                        current={current}
                                                        showSizeChanger={true}
                                                        // showQuickJumper={true}
                                                        onShowSizeChange={() => {
                                                            setCurrent(1)
                                                            // setpageState(true)
                                                        }}
                                                        pageSizeOptions={[25, 50, 100]}
                                                        hideOnSinglePage={false}
                                                        defaultPageSize={25}
                                                        onChange={(page, pageSizes) => {
                                                            setpageState(true)
                                                            setCurrent(pageSize !== pageSizes ? 1 : page);
                                                            setPageSize(pageSizes)
                                                        }}
                                                        total={certificatecompliancereportfiltershow && certificatecompliancereportfiltershow.total_count}
                                                    //             showTotal={(total) => ` Showing ${pageSize * (current - 1) + 1} to ${index} of 
                                                    // ${certificatecompliancereportfiltershow && certificatecompliancereportfiltershow.total_count} entries`}
                                                    // showTotal={(total) => `Showing 1 to 25 of ${certificatecompliancereportfiltershow.total_count} entries`}
                                                    />
                                                </div> : false}
                                        </div>

                                        {/* <div className='col-md-12' style={{ marginTop: "20px" }}>
                                                <div className='row'>

                                                    <div className='col-md-3' >
                                                        <label style={{ marginLeft: "28px" }}><b>Showing  </b><a href='javascript:;' style={{ fontSize: "18px" }}>{reportlist && reportlist.tabledata && reportlist.tabledata.total_count}</a>  entries</label>
                                                    </div>
                                                    <div className='col-md-3'></div>
                                                    <div className='col-md-3'></div>
                                                    <div className='col-md-3'>
                                                    </div>

                                                </div>
                                            </div> */}
                                    </Card>
                                    // </Card>
                                    : ""}
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
export default connect(mapStateToProps, {
    CertificateComplianceWiseReportFilters,
    ComplianceCertificateWiseReport,
    MonthReportExport,
    CertificatePdf,
    DownloadFile,
    ComplianceCertificateExport,
    DownloadFiledocument,
    getdownloadpath,
    getdownloadpath1,
    historyrecord,
    interimrecord,
    Downloadfile
})(CV_ComplianceCertificateReport);