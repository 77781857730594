import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Full from './Template/Full'
import { useSelector } from 'react-redux';
import PrivateRoute from './Template/PrivateRoute';

import Login from './Components/Login';
import Forgotpassword from './Components/Forgotpassword';
import Resetpassword from './Components/Resetpassword'
import { Fragment } from 'react';
import "antd/dist/antd.min.css";
import { ToastContainer } from 'react-toastify';
import { Spin } from 'antd';
import RegisterUser from './Components/RegisterUser';

const App = () => {
    // const stateAuthToken = useSelector((state) => state.auth.token);
  
    const commonLoading = useSelector((state) => state.Login.commonLoading);
    const ab = useSelector((state) => state.Login);

    return (
        <Fragment>
            <ToastContainer />
            <Spin size='large' spinning={commonLoading}>
                <Router>
                    <Switch>
                        <Route exact path="/login"><Login /></Route>
                        <Route exact path="/Forgotpassword"><Forgotpassword /></Route>
                        <Route exact path="/reset_password/:type/:id"><Resetpassword /></Route>
                        <Route exact path="/userregistration/:type/:id"><RegisterUser /></Route>
                        <PrivateRoute path="/" component={Full} />

                        <App />
                    </Switch>
                </Router>
            </Spin>
        </Fragment>
    );
};

export default App;
