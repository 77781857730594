import React from 'react'
import { Collapse, Select, Input, Card, Drawer, Button, Table, Modal, Pagination, Tooltip, Progress } from 'antd';
import { useState, useEffect, useRef, Fragment } from 'react';
import { useLocation, Link, useHistory } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { connect, useDispatch, useSelector } from 'react-redux';
import { UnitListFilters, UnitListshow, UnitListexport, } from '../../Store/Action/Report/UnitlistReport';
import { toast } from 'react-toastify';
import { PlayCircleOutlined, FullscreenOutlined, UpOutlined, ExportOutlined, EyeOutlined, FilterOutlined } from '@ant-design/icons';
import { fullscreenmode, fullscreenexit, handleKeyDown } from './../../Libs/fullscreenmode';
import useStickyHeight from "../../Libs/useStickyHeight";
import { validationType, validateSelect, onPasteToaster } from './../../Libs/inputValidation';
import ScrollToTop from "react-scroll-to-top";
import ScrollButton from '../Scrollbar';
import { Toaster } from '../../Libs/Toaster';
import { CountrySplit, HomeRedirect } from './../../Libs/country'
const uniq_country_data = CountrySplit()
const homepage = HomeRedirect()

const mapStateToProps = (state) => ({
    filerunitdatas: state.UnitlistReport.unitlistreports.unitlist,
    tabledatas: state.UnitlistReport.unitlistreports.tabledata
})

const UnitlistReport = ({
    UnitListFilters,
    UnitListshow,
    UnitListexport,
    filerunitdatas,
    tabledatas
}) => {
    console.log(filerunitdatas, 'filerunitdatas')
    useStickyHeight();
    const historynew = useHistory();
    const { Panel } = Collapse;
    const { Option } = Select;
    const location = useLocation();
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const dispatch = useDispatch();
    const paramid = location.pathname;
    const _ = require("lodash");
    const sessionValue = localStorage.getItem('sessionValue')
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    console.log(sessionArr, "sessionArrsessionArr");
    const country_info = sessionParsedValue.country_info
    const [entity, setEntity] = useState([])
    const [productfilter, setProductfilter] = useState([])
    const validator = useRef(new SimpleReactValidator());
    const [open, setOpen] = useState(false);
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [tableshow, setTableShow] = useState(false)
    const [statkey, setStatKey] = useState("0")
    const [key, setKey] = useState("1")
    const [countrydata, setCountryData] = useState(null)
    const [legalstate, Setlegalstate] = useState(false)
    const [filerdatas, setfilerdatas] = useState([])
    const [checkboxValue, setCheckBoxValue] = useState([
        1,
        2,
        3,
        4,
        5,
        6,
        11])
        console.log(checkboxValue,checkboxValue.length,'checkboxValue');
    const [tabledata, setTableData] = useState([])
    const [legalEntity, setLegalEntity] = useState([])
    const [finaldata, Setfinaldata] = useState([])
    const [titledata, Settitledata] = useState()
    console.log(titledata, 'titledata');
    const [pagecount, setPagecount] = useState(false)
    const [show, Setshow] = useState(0)
    let [count, setCount] = useState(0)
    const [fcount, setfcount] = useState(0)
    const [tablecount, setTablecount] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setfilteredArrayState] = useState([])
    const [current, setCurrent] = useState(1);
    const [pageState, setpageState] = useState(false);
    const [pageSize, setPageSize] = useState(25)
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);
    const [unitlist, setUnitlist] = useState({
        country: "",
        country_id: '',
        legal_entity_id: "",
        legal_entity_name: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
        domain: "",
        division: "",
        category: "",
        unit: "",
        business_group_id: '',
        domain_id: null,
        division_id: null,
        category_id: null,
        unit_id: null,
        unit_data: '',
        domain_org_id: '',
        domain_org_name: '',
        product_status: '',
        product_status_id: '',
        unit_status_id: '',
        unit_status: '',
        bg_id: '',
        bg_name: '',
        legalEntityLabel: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
        domainLabel: '',
        countryLabel: ''
    })
    console.log(unitlist, "unitlist123");
    const [category, setCategoryList] = useState([]);
    console.log(category, "category");
    const [organizationList, setOrganisationList] = useState([])
    const [domainList, setDomainList] = useState([])
    console.log(domainList, "domainList");
    const [businessGroup, setBusinessGroup] = useState([])
    const [bggroup, setBggroup] = useState([]);
    const [selectSearchValue, setSelectSearchValue] = useState({
        country: '',
        legalEntity: '',
        BusinessGroup: '',
        organizationType: '',
        domain: '',
        actRules: '',
        complianceTask: '',
        user: '',
        division: '',
        category: '',
        unit: '',
    });

    useEffect(() => {
        if (sessionArr && sessionArr.length > 0) {
            let unique = _.uniqBy(sessionArr, function (e) {
                return e.bg_id;
            });
            setBggroup(unique)
        }
    }, [])

    const onChange = (key) => {
        console.log(key);
    };

    const onStatChange = (key) => {
        console.log(key);
    }
    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };
    const onClose = () => {
        setOpen(false);
    };


    useEffect(() => {
        if (finaldata && finaldata && finaldata.length > 0) {
            let uniqueObjArray = [...new Map(finaldata && finaldata.length && finaldata.map((item) => [item["category_name"], item])).values()];
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.category_name
            })
            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(finaldata && finaldata && finaldata.length && finaldata, { category_name: uniqueIds[i] })
                tempArr.push(filteredArr)
            }
            setFilteredArray([...filteredArray, tempArr])
        }
    }, [finaldata])

    useEffect(() => {
        if (unitlist.legal_entity_name == localStorage.getItem('SelectedEntity')) {
            Setlegalstate(true)
        }
    }, [unitlist.legal_entity_name])

    useEffect(() => {
        if (unitlist.domain) {
            let domain = _.filter(filerunitdatas && filerunitdatas.domains_organisations_list, { domain_id: parseInt(unitlist.domain_id) })
            setOrganisationList(domain)
        }
    }, [unitlist.domain])

    useEffect(() => {
        if (filerunitdatas.domains_organisations_list) {
            let uniqDomain = _.uniqBy(filerunitdatas && filerunitdatas.domains_organisations_list, function (e) {
                return e.domain_id;
            });
            console.log(uniqDomain, "uniqDomainuniqDomain");
            setDomainList(uniqDomain)
        }
        if (filerunitdatas) {
            // let unitlistdata = _.filter(filerunitdatas && filerunitdatas.units_list, { category_id: parseInt(unitlist.category_id) })
            setUnitlist({
                ...unitlist,
                unit_data: filerunitdatas && filerunitdatas.units_list
            })
            setCategoryList(filerunitdatas && filerunitdatas.categories);
        }
    }, [filerunitdatas])

    useEffect(() => {
        if (unitlist.category_id) {
            let unitlistdata = _.filter(filerunitdatas && filerunitdatas.units_list, { category_id: parseInt(unitlist.category_id) })
            setUnitlist({
                ...unitlist,
                unit_data: unitlistdata
            })
        }

    }, [unitlist.category_id])

    useEffect(() => {
        if (filerunitdatas && unitlist.legal_entity_id) {
            let temp = []
            let temparr = []
            if (sessionParsedValue.login_response.cf_access == true) {
                temp.push(1)
            }
            if (sessionParsedValue.login_response.ltmg_access == true) {
                temp.push(2)
            }
            if (sessionParsedValue.login_response.vndr_access == true) {
                temp.push(3)
            }
            for (let i in temp) {
                let unitlistdata = _.find(filerunitdatas && filerunitdatas.product_status_list, { product_status_id: temp[i] })
                if (unitlistdata) {
                    temparr.push(unitlistdata)
                }
            }
            setProductfilter(temparr)
        } else {
            setProductfilter([])
        }

    }, [filerunitdatas, unitlist.legal_entity_id])

    useEffect(() => {
        if (legalEntity && legalEntity.length === 0) {
            setLegalEntity(sessionArr)
        }
    }, [legalEntity && legalEntity.length === 0])

    useEffect(() => {
        if (filteredArray && filteredArray.length > 0) {
            filteredArray && filteredArray.map((item, i) => {
                return (
                    setfilteredArrayState(item)
                )
            })
        }
    }, [filteredArray])

    console.log(organizationList, 'organizationList');

    useEffect(() => {
        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (finaldata && finaldata.length > 0) {
            const ab = finaldata && finaldata.length && finaldata.map(function (item) {
                if ((array.indexOf(item.category_name) < 0))
                    if (item.category_name != '') {
                        array.push(item.category_name)
                    } else {
                        if (array.indexOf('-') < 0)
                            array.push('-')
                    }
            })
            array.map((data, i) => {
                let temp = [];
                let entityArray = [];
                let datavalue = [];
                if (data) {
                    finaldata && finaldata.length && finaldata.map((child, childIndex) => {
                        if (data == child.category_name) {
                            entityArray = {
                                "address": child.address,
                                "category_name": child.category_name,
                                "closed_date": child.closed_date,
                                "d_i_names": child.d_i_names,
                                "division_name": child.division_name,
                                "geography_name": child.geography_name,
                                "logo_url": child.logo_url,
                                "postal_code": child.postal_code,
                                "product_status": child.product_status,
                                "tz_name": child.tz_name,
                                "unit_code": child.unit_code,
                                "unit_id": child.unit_id,
                                "unit_name": child.unit_name,
                                "unit_status": child.unit_status,
                                'childId': childIndex,
                                'parentId': i
                            }
                            temp.push(entityArray)
                        } else if (data == '-') {
                            if (child.category_name == '') {
                                entityArray = {
                                    "address": child.address,
                                    "category_name": child.category_name,
                                    "closed_date": child.closed_date,
                                    "d_i_names": child.d_i_names,
                                    "division_name": child.division_name,
                                    "geography_name": child.geography_name,
                                    "logo_url": child.logo_url,
                                    "postal_code": child.postal_code,
                                    "product_status": child.product_status,
                                    "tz_name": child.tz_name,
                                    "unit_code": child.unit_code,
                                    "unit_id": child.unit_id,
                                    "unit_name": child.unit_name,
                                    "unit_status": child.unit_status,
                                    'childId': childIndex,
                                    'parentId': i
                                }
                                temp.push(entityArray)
                            }
                        }
                    })
                }
                datavalue = {
                    index: i,
                    parent: data,
                    child: temp
                }
                finalarray.push(datavalue)
            })
            Settitledata(finalarray)
        }
    }, [finaldata])

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        if (tabledatas) {
            setTableData(tabledatas && tabledatas.unit_list_report)
        }
    }, [tabledatas && tabledatas.unit_list_report])

    // useEffect(() => {
    //     if (entityid != null) {
    //         setUnitlist({
    //             ...unitlist,
    //             country_id: sessionArr[0].c_id
    //         })
    //     }
    // }, [entityid != null])

    useEffect(() => {
        if (localStorage.getItem("SelectedEntity") !== "All Legal Entity") {
            let temp = localStorage.getItem("SelectedEntity")
            let tempcountry = _.filter(sessionArr, { le_name: temp })
            setUnitlist({
                ...unitlist,
                country_id: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_id : '',
                country: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_name : ''
            })
            let businessName = _.filter(sessionArr, { le_name: temp })
            setBusinessGroup({
                ...businessGroup,
                bg_id: businessName[0].bg_id,
                bg_name: businessName[0].bg_name
            })
        }
    }, [localStorage.getItem("SelectedEntity") !== "All Legal Entity"])

    useEffect(() => {
        if (tabledata && tabledata.length > 0) {
            let data = tabledata[0]
            if (data) {
                let data1 = Object.values(data)
                let data2 = Object.keys(data)
                let temp = []
                data1 && data1.length > 0 && data1.map((item) => {
                    temp.push(...item)
                })
                Setfinaldata(temp)
            }
        }
    }, [tabledata])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
            const country = sessionParsedValue.country_info
            setCountryData(country)
        }
    }, [localStorage.getItem('SelectedEntityid')])

    useEffect(() => {
        if (isAuth) {
            if (legalstate === true) {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            'GetUnitListReportFilters', {
                                'country_id': Number(unitlist.country_id),
                                'business_group_id': unitlist.bg_id ? Number(unitlist.bg_id) : null,
                                'legal_entity_id': entityid
                            }
                        ]
                    }
                ]
                if (entityid != "null") {
                    UnitListFilters({
                        payload: payload,
                        paramid: paramid
                    })
                }
                Setlegalstate(false)
            }
        }
    }, [isAuth, entityid, legalstate])

    const onshow = () => {

        setAddFormSubmit(true)

        if (validator.current.allValid()) {
            setfilteredArrayState([])
            Settitledata([])
            setPagecount(false)
            setCurrent(1);
            setpageState(false)
            setPageSize(25)
            setCount(0)
            setTableShow(true)
            setKey(0)
            setStatKey(1)
            setExitCollapse(true);
            setFilterTaskAcc(false);

            setFilterModalVisible(false);
            setUnitlist({
                ...unitlist,
                countryLabel: unitlist.country,
                domainLabel: unitlist.domain,
                legalEntityLabel: unitlist.legal_entity_name
            })
            const payload1 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetUnitListReport",
                        {
                            "country_id": Number(unitlist.country_id),
                            "business_group_id": unitlist.bg_id ? Number(unitlist.bg_id) : null,
                            "legal_entity_id": entityid,
                            "division_id": unitlist.division_id ? Number(unitlist.division_id) : 0,
                            "category_id": unitlist.category_id ? Number(unitlist.category_id) : 0,
                            "unit_id": unitlist.unit_id ? Number(unitlist.unit_id) : 0,
                            "domain_id": unitlist.domain_id ? Number(unitlist.domain_id) : 0,
                            "organisation_id": unitlist.domain_org_id ? Number(unitlist.domain_org_id) : 0,
                            "product_status": unitlist.product_status ? unitlist.product_status : "All",
                            "unit_status": unitlist.unit_status ? unitlist.unit_status : "All",
                            "csv": false,
                            "from_count": 0,
                            "page_count": 25,
                            "list_check": null
                        }
                    ]
                }
            ]
            UnitListshow({
                payload: payload1,
                paramid: paramid
            })
            Setshow(fcount + 1)
        }
    }

    useEffect(() => {
        if (pageState == true) {
            const payload1 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetUnitListReport",
                        {
                            "country_id": Number(unitlist.country_id),
                            "business_group_id": unitlist.bg_id ? Number(unitlist.bg_id) : null,
                            "legal_entity_id": entityid,
                            "division_id": unitlist.division_id ? Number(unitlist.division_id) : 0,
                            "category_id": unitlist.category_id ? Number(unitlist.category_id) : 0,
                            "unit_id": unitlist.unit_id ? Number(unitlist.unit_id) : 0,
                            "domain_id": unitlist.domain_id ? Number(unitlist.domain_id) : 0,
                            "organisation_id": unitlist.domain_org_id ? Number(unitlist.domain_org_id) : 0,
                            "product_status": unitlist.product_status ? unitlist.product_status : "All",
                            "unit_status": unitlist.unit_status ? unitlist.unit_status : "All",
                            "csv": false,
                            "from_count": Math.max(((current - 1) * pageSize), 0) + 1,
                            "page_count": current != 0 ? current * pageSize : pageSize,
                            "list_check": null
                        }
                    ]
                }
            ]
            UnitListshow({
                payload: payload1,
                paramid: paramid
            })
        }
    }, [current, pageSize, pageState])

    useEffect(() => {
        if (pageState === true) {
            setCount(pageSize * (current - 1))
        }
    }, [pageState, pageSize, current])


    const showModal = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setIsModalOpen(true);
        }
    };

    const handleOk = () => {
        const myArray = checkboxValue;
        let status = myArray.includes(0);
        if (!status) {
            myArray.unshift(0);
        }

        setCheckBoxValue(myArray);
        setIsModalOpen(true);
        // setCheckBoxValue([1,2,3,4,5,6])
        if (validator.current.allValid()) {
            const payload2 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetUnitListReport",
                        {
                            "country_id": Number(unitlist.country_id),
                            "business_group_id": null,
                            "legal_entity_id": entityid,
                            "division_id": unitlist.division_id ? Number(unitlist.division_id) : 0,
                            "category_id": unitlist.category_id ? Number(unitlist.category_id) : 0,
                            "unit_id": unitlist.unit_id ? Number(unitlist.unit_id) : 0,
                            "domain_id": unitlist.domain_id ? Number(unitlist.domain_id) : 0,
                            "organisation_id": unitlist.domain_org_id ? Number(unitlist.domain_org_id) : 0,
                            "product_status": unitlist.product_status ? unitlist.product_status : "All",
                            "unit_status": unitlist.unit_status ? unitlist.unit_status : "All",
                            "csv": true,
                            "from_count": 0,
                            "page_count": 25,
                            "list_check": checkboxValue
                        }
                    ]
                }
            ]
            UnitListexport({
                payload: payload2,
                paramid: paramid
            })
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setCheckBoxValue([
            1,
            2,
            3,
            4,
            5,
            6,
            11])
    };

    const checkBoxOnChange = (e) => {
        let tempIds = []
        let temp = checkboxValue
        if (temp.includes(Number(e.target.value)) == true) {
            for (let i in temp) {
                if (temp[i] == Number(e.target.value)) {
                    temp.splice(i, 1)
                }
            }
            setCheckBoxValue([...temp])
        } else {
            tempIds.push(Number(e.target.value))
            setCheckBoxValue([...checkboxValue, ...tempIds])
        }
    }

    const selectAllCheckBox = (checked) => {
        var ele = document.getElementsByName('chk');
        let tempAllIds = []
        if (checked == true) {
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox') {
                    ele[i].checked = true;
                    tempAllIds.push(Number(ele[i].value))
                }
            }
            setCheckBoxValue(tempAllIds)
        }
        else {
            var ele = document.getElementsByName('chk');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = false;
            }
            setCheckBoxValue([
                1,
                2,
                3,
                4,
                5,
                6,
                11])
        }
    }

    const homescreen = () => {
        let getUrlDetails = homepage;
        let tempval = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_url;
        let tempkey = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_key;
        localStorage.setItem('formkey', tempkey);
        historynew.push(tempval);
        // window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }


    const handlePasting = (e, regex) => {

        var regex = regex;
        var key = e.clipboardData.getData('text')
        if (!regex.test(key)) {
            e.preventDefault();
            Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
            return false;
        }
        // e.clipboardData.getData('text')
        // Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
        // e.preventDefault();
        // return false;
    }

    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };

    const [fullscreen, setfullscreen] = useState(false);

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }

    const [selectAllText, setSelectAllText] = useState('Select All');

    useEffect(() => {
      if (checkboxValue.length == 17) {
        setSelectAllText('Unselect All');
      } else {
        setSelectAllText('Select All');
      }
    }, [checkboxValue]);

    return (
        <>
            <div id='page-wrapper' className="page-wrapper">
                {/* <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
                {document.body.scrollHeight > document.body.clientHeight ?
                    <div className='back-to-top'>
                        <ScrollButton />
                    </div> : ''} */}
                {tableshow == true ?
                    <div className='back-to-top'>
                        <ScrollButton />
                    </div> : ''}
                <div className="page-titles pb-2 pt-1 page-title-sticky">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                    </li>
                                    <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                        <span style={{ fontSize: '16px' }}>Report</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <span>Unit List</span>
                                    </li>
                                </ol>
                            </nav>

                        </div>
                        <div className="col-lg-4 text-end">
                            <Tooltip title='Advance Filter'> <Button
                                className="mx-1"
                                type="primary"
                                shape="round"
                                style={{
                                    display: filterTaskAcc ? "none" : "initial",
                                }}
                                onClick={filterHandle}
                            >
                                <FilterOutlined /> Filter
                            </Button></Tooltip>
                            <button style={{ float: 'right' }} onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                                <Tooltip placement="left" title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                            </button>
                        </div>
                    </div>
                </div>

                <Drawer title="Select Fields" className={'add-service-prv export-checkbox resize-drawer ' + localStorage.getItem('currentTheme')} open={isModalOpen} footer={null} onCancel={handleCancel} onClose={handleCancel} width={600} maskClosable={false}>
                    <div className='d-flex flex-wrap'>
                        <input id="select_all" width="100%" type="checkbox" autocomplete="off"
                            checked={checkboxValue.length === 17 ? true : false}
                            onClick={(e) => {
                                let checked = e.target.checked
                                selectAllCheckBox(checked)
                            }} /><label for='select_all'>  {selectAllText}</label>
                        <input id="column_1" name='chk' type="checkbox" checked disabled="disabled" value='1' onChange={(e) => (e) => checkBoxOnChange(e)} /><label for='column_1'>  Business Group</label>
                        <input id="column_2" name='chk' type="checkbox" checked disabled="disabled" value='2' onChange={(e) => checkBoxOnChange(e)} /><label for='column_2'> Legal Entity</label>
                        <input id="column_3" name='chk' type="checkbox" value='3' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_3'>  Division</label>
                        <input id="column_4" name='chk' type="checkbox" value='4' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_4'>  Category</label>
                        <input id="column_5" name='chk' type="checkbox" value='5' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_5'>  Unit Code</label>
                        <input id="column_6" name='chk' type="checkbox" value='6' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_6'>  Unit Name</label>
                        <input id="column_11" name='chk' type="checkbox" value='11' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_11'>  Location</label>

                        <input id="column_7" name='chk' type="checkbox" value='7' checked={checkboxValue.includes(7)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_7'> Unit Code 1</label>
                        <input id="column_8" name='chk' type="checkbox" value='8' checked={checkboxValue.includes(8)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_8'>  Unit Code 2</label>

                        <input id="column_9" name='chk' type="checkbox" value='9' checked={checkboxValue.includes(9)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_9'>  Unit Code 3</label>
                        <input id="column_10" name='chk' type="checkbox" value='10' checked={checkboxValue.includes(10)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_10'>  Unit Code 4</label>
                        <input id="column_12" name='chk' type="checkbox" value='12' checked={checkboxValue.includes(12)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_12'> Domain</label>

                        <input id="column_13" name='chk' type="checkbox" value='13' checked={checkboxValue.includes(13)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_13'>  Organization Type</label>
                        <input id="column_14" name='chk' type="checkbox" value='14' checked={checkboxValue.includes(14)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_14'>  Postal Code</label>
                        <input id="column_15" name='chk' type="checkbox" value='15' checked={checkboxValue.includes(15)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_15'>  Product</label>
                        <input id="column_16" name='chk' type="checkbox" value='16' checked={checkboxValue.includes(16)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_16'>  Status</label>
                        <input id="column_17" name='chk' type="checkbox" value='17' checked={checkboxValue.includes(17)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_17'>  Date </label>

                    </div>

                    <div className="form-actions popupbtncolour text-center">
                        <Button type="primary" shape="round" className={'addbutton mt-3 ' + localStorage.getItem('currentTheme')}
                            icon={<PlayCircleOutlined />} size='default' onClick={handleOk}> Submit
                        </Button>
                    </div>


                </Drawer>
                <div className="container-fluid pt-1">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-body" id='only-restricted' style={{ position: 'relative', padding: "10px 3px" }}>
                                <div className={localStorage.getItem('currentTheme')}>
                                    <Collapse style={{ display: exitCollapse ? "none" : "block" }} defaultActiveKey={["1"]} className='report-collapse' onChange={onChange}>
                                        <Panel header="Unit List" key={key} extra={tableshow == true ? '' : false}>
                                            <div className='col-md-12' >
                                                <div className='row'  >
                                                    <div className='col-md-4'>
                                                        <label><b>Country:</b> <span style={{ color: "red" }}>*</span></label>
                                                        <div className="form-group">
                                                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                                <div className="form-group">
                                                                    <Select
                                                                        placeholder='Enter Country'
                                                                        allowClear={true}
                                                                        size="default"
                                                                        onDrop={(e) => {
                                                                            e.preventDefault();
                                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                            return false;
                                                                        }}
                                                                        // onPaste={(e) => {
                                                                        //     handlePasting(e, /^[a-z]+$/gi)
                                                                        // }}
                                                                        onPaste={onPasteToaster}
                                                                        searchValue={selectSearchValue.country}
                                                                        onSearch={(value) => {
                                                                            let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                            setSelectSearchValue({
                                                                                ...selectSearchValue,
                                                                                country: result
                                                                            });
                                                                        }}
                                                                        onChange={(value, data) => {
                                                                            if (data !== undefined) {
                                                                                let le = _.filter(sessionArr, { c_id: Number(value) })
                                                                                setEntity(le)
                                                                            }
                                                                            setUnitlist({
                                                                                ...unitlist,
                                                                                country_id: value != undefined ? value : '',
                                                                                country: data != undefined ? data.children : '',
                                                                                legal_entity_id: "",
                                                                                legal_entity_name: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
                                                                                domain: "",
                                                                                division: "",
                                                                                category: "",
                                                                                unit: "",
                                                                                business_group_id: '',
                                                                                domain_id: null,
                                                                                division_id: null,
                                                                                category_id: null,
                                                                                unit_id: null,
                                                                                domain_org_id: '',
                                                                                domain_org_name: '',
                                                                                product_status: '',
                                                                                product_status_id: '',
                                                                                unit_status_id: '',
                                                                                unit_status: '',
                                                                                bg_id: '',
                                                                                bg_name: ''
                                                                            })
                                                                        }}
                                                                        showSearch
                                                                        optionFilterProp="children"
                                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                        style={{ width: '100%', marginTop: '3px' }}
                                                                        value={unitlist.country || undefined}
                                                                        getPopupContainer={trigger => trigger.parentNode}

                                                                    >
                                                                        {uniq_country_data && uniq_country_data.length && uniq_country_data.map((item, i) => {
                                                                            return (
                                                                                <Option key={item.c_id}>
                                                                                    {item.c_name}
                                                                                </Option>
                                                                            );
                                                                        })}
                                                                    </Select>
                                                                    {validator.current.message(
                                                                        'country',
                                                                        unitlist.country,
                                                                        'required',
                                                                        {
                                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                            messages: {
                                                                                required: 'Country Required',
                                                                            }
                                                                        })}
                                                                </div> :
                                                                <p>{unitlist.country}</p>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4 text-wrap-report'>
                                                        <label><b>Legal Entity: </b><span style={{ color: "red" }}>*</span></label>
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <div className="form-group">
                                                                <Select
                                                                    allowClear={true}
                                                                    size="default"
                                                                    placeholder="Enter Legal Entity"
                                                                    onDrop={(e) => {
                                                                        e.preventDefault();
                                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                        return false;
                                                                    }}
                                                                    // onPaste={(e) => {
                                                                    //     handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                                    // }}
                                                                    onPaste={onPasteToaster}
                                                                    searchValue={selectSearchValue.legalEntity}
                                                                    onSearch={(value) => {
                                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                        setSelectSearchValue({
                                                                            ...selectSearchValue,
                                                                            legalEntity: result
                                                                        });
                                                                    }}
                                                                    onChange={(data, value) => {
                                                                        Setlegalstate(true)
                                                                        setCurrentEntity(data != undefined ? data : localStorage.getItem('SelectedEntityid'))
                                                                        setUnitlist({
                                                                            ...unitlist,
                                                                            legal_entity_id: data != undefined ? data : '',
                                                                            legal_entity_name: value != undefined ? value.children : '',
                                                                            domain: "",
                                                                            division: "",
                                                                            category: "",
                                                                            unit: "",
                                                                            // business_group_id: '',
                                                                            domain_id: null,
                                                                            division_id: null,
                                                                            category_id: null,
                                                                            unit_id: null,
                                                                            domain_org_id: '',
                                                                            domain_org_name: '',
                                                                            product_status: '',
                                                                            product_status_id: '',
                                                                            unit_status_id: '',
                                                                            unit_status: '',
                                                                            // bg_id: '',
                                                                            // bg_name: ''
                                                                        })
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    style={{ width: '100%', marginTop: '3px' }}
                                                                    getPopupContainer={trigger => trigger.parentNode}

                                                                    value={unitlist.legal_entity_name || undefined}
                                                                    disabled={unitlist.country == '' ? true : false}
                                                                >
                                                                    {entity && entity.length > 0 && entity.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.le_id}>
                                                                                {item.le_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                                {validator.current.message(
                                                                    'legal_entity_id',
                                                                    unitlist.legal_entity_id,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Legal Entity Required',
                                                                        }
                                                                    })}
                                                            </div> :
                                                            <p>{localStorage.getItem('SelectedEntity')}</p>
                                                        }
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Domain:</b> </label>
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Domain"
                                                            value={unitlist.domain || undefined}
                                                            disabled={unitlist.legal_entity_name == '' ? true : false}
                                                            style={{ width: '100%', marginTop: '3px' }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                            getPopupContainer={trigger => trigger.parentNode}
                                                            // onPaste={(e) => {
                                                            //     handlePasting(e, /^[a-z - .]+$/gi)
                                                            // }}
                                                            onPaste={onPasteToaster}
                                                            searchValue={selectSearchValue.domain}
                                                            onSearch={(value) => {
                                                                let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                setSelectSearchValue({
                                                                    ...selectSearchValue,
                                                                    domain: result
                                                                });
                                                            }}
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            onChange={(data, value) => {
                                                                setUnitlist({
                                                                    ...unitlist,
                                                                    domain_id: data != undefined ? data : '',
                                                                    domain: value != undefined ? value.children : '',
                                                                    division: "",
                                                                    category: "",
                                                                    business_group_id: '',
                                                                    division_id: null,
                                                                    category_id: null,
                                                                    unit_id: null,
                                                                    domain_org_id: '',
                                                                    domain_org_name: '',
                                                                    product_status: '',
                                                                    product_status_id: '',
                                                                    unit_status_id: '',
                                                                    unit_status: ''
                                                                })
                                                            }}>
                                                            {domainList && domainList.length > 0 &&
                                                                domainList.map((item) => (

                                                                    <Option key={item.domain_id}>{item.domain_name}</Option>
                                                                ))}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-12 ' style={{ marginTop: "5px" }}>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <label><b>Business Group: </b>  </label>
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <div className="form-group">
                                                                <Select
                                                                    allowClear={true}
                                                                    size="default"
                                                                    placeholder="Enter Business Group"

                                                                    getPopupContainer={trigger => trigger.parentNode}
                                                                    onDrop={(e) => {
                                                                        e.preventDefault();
                                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                        return false;
                                                                    }}
                                                                    // onPaste={(e) => {
                                                                    //     handlePasting(e, /^[a-z 0-9]+$/gi)
                                                                    // }}
                                                                    onPaste={onPasteToaster}
                                                                    searchValue={selectSearchValue.BusinessGroup}
                                                                    onSearch={(value) => {
                                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                        setSelectSearchValue({
                                                                            ...selectSearchValue,
                                                                            BusinessGroup: result
                                                                        });
                                                                    }}
                                                                    onChange={(data, value) => {
                                                                        if (data == undefined) {
                                                                            setUnitlist({
                                                                                ...unitlist,
                                                                                bg_id: '',
                                                                                bg_name: '',
                                                                                legal_entity_id: "",
                                                                                legal_entity_name: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
                                                                                domain: "",
                                                                                division: "",
                                                                                category: "",
                                                                                unit: "",
                                                                                business_group_id: '',
                                                                                domain_id: null,
                                                                                division_id: null,
                                                                                category_id: null,
                                                                                unit_id: null,
                                                                                domain_org_id: '',
                                                                                domain_org_name: '',
                                                                                product_status: '',
                                                                                product_status_id: '',
                                                                                unit_status_id: '',
                                                                                unit_status: '',
                                                                            })
                                                                        } else {
                                                                            setUnitlist({
                                                                                ...unitlist,
                                                                                bg_id: data,
                                                                                bg_name: value.children,
                                                                                legal_entity_id: "",
                                                                                legal_entity_name: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
                                                                                domain: "",
                                                                                division: "",
                                                                                category: "",
                                                                                unit: "",
                                                                                business_group_id: '',
                                                                                domain_id: null,
                                                                                division_id: null,
                                                                                category_id: null,
                                                                                unit_id: null,
                                                                                domain_org_id: '',
                                                                                domain_org_name: '',
                                                                                product_status: '',
                                                                                product_status_id: '',
                                                                                unit_status_id: '',
                                                                                unit_status: ''
                                                                            })
                                                                            let filteredLegalEntity = _.filter(legalEntity, { bg_id: Number(data) })
                                                                            setLegalEntity(filteredLegalEntity)
                                                                            let le = _.filter(sessionArr, { bg_id: Number(data) })
                                                                            setEntity(le)
                                                                        }
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    value={unitlist.bg_name || undefined}
                                                                    style={{ width: '100%', marginTop: '3px' }}
                                                                >
                                                                    {bggroup && bggroup.length > 0 && bggroup.map((item, i) => {

                                                                        if (item.bg_name !== null) {
                                                                            return (
                                                                                <Option key={item.bg_id}>
                                                                                    {item.bg_name}
                                                                                </Option>
                                                                            );
                                                                        }
                                                                    })}
                                                                </Select>
                                                            </div> :
                                                            <p>{businessGroup.bg_name ? businessGroup.bg_name : '-'}</p>
                                                        }
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Division:</b></label>
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Division"
                                                            disabled={unitlist.legal_entity_name == '' ? true : false}

                                                            getPopupContainer={trigger => trigger.parentNode}
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            // onPaste={(e) => {
                                                            //     handlePasting(e, /^[a-z 0-9]+$/gi)
                                                            // }}
                                                            onPaste={onPasteToaster}
                                                            searchValue={selectSearchValue.division}
                                                            onSearch={(value) => {
                                                                let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                setSelectSearchValue({
                                                                    ...selectSearchValue,
                                                                    division: result
                                                                });
                                                            }}
                                                            onChange={(data, value) => {
                                                                console.log(data, "datadata");
                                                                if (data !== undefined) {
                                                                    let unitlistdata = _.filter(filerunitdatas && filerunitdatas.units_list, { division_id: Number(data) })
                                                                    setUnitlist({
                                                                        ...unitlist,
                                                                        division_id: data,
                                                                        division: value.children,
                                                                        category: '',
                                                                        category_id: '',
                                                                        unit_data: unitlistdata
                                                                    })
                                                                    let categorydata = _.filter(filerunitdatas && filerunitdatas.categories, { division_id: Number(data) });
                                                                    setCategoryList(categorydata);
                                                                    // setUnitlist({
                                                                    //     ...unitlist,
                                                                    //     unit_data: unitlistdata
                                                                    // })
                                                                } else {
                                                                    setUnitlist({
                                                                        ...unitlist,
                                                                        division_id: '',
                                                                        division: '',
                                                                        category: '',
                                                                        category_id: ''

                                                                    })
                                                                }
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%', marginTop: '3px' }}
                                                            value={unitlist.division || undefined}
                                                        >
                                                            {filerunitdatas.divisions && filerunitdatas.divisions.length > 0 &&
                                                                filerunitdatas.divisions.map((item) => (
                                                                    <Option key={item.division_id}>{item.division_name}</Option>
                                                                ))}
                                                        </Select>
                                                    </div>

                                                    <div className='col-md-4'>
                                                        <label><b>Category:</b></label>
                                                        <Select
                                                            onChange={(data, value) => {
                                                                if (data == undefined) {
                                                                    setUnitlist({
                                                                        ...unitlist,
                                                                        category_id: '',
                                                                        category: '',
                                                                        unit_id: '',
                                                                        unit: '',

                                                                    })
                                                                } else {
                                                                    setUnitlist({
                                                                        ...unitlist,
                                                                        category_id: data != undefined ? data : '',
                                                                        category: value != undefined ? value.children : '',
                                                                        unit_id: '',
                                                                        unit: '',
                                                                    })
                                                                }
                                                            }}
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            // onPaste={(e) => {
                                                            //     handlePasting(e, /^[a-z 0-9]+$/gi)
                                                            // }}
                                                            onPaste={onPasteToaster}
                                                            searchValue={selectSearchValue.category}
                                                            onSearch={(value) => {
                                                                let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                setSelectSearchValue({
                                                                    ...selectSearchValue,
                                                                    category: result
                                                                });
                                                            }}
                                                            allowClear={true}
                                                            placeholder="Enter Category"

                                                            getPopupContainer={trigger => trigger.parentNode}
                                                            disabled={unitlist.legal_entity_name == '' ? true : false}
                                                            style={{ width: '100%', marginTop: '3px' }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={unitlist.category || undefined}
                                                        >
                                                            {category && category.length > 0 &&
                                                                category.map((item) => (

                                                                    <Option key={item.category_id}>{item.category_name}</Option>
                                                                ))}                                                       </Select>
                                                    </div>


                                                </div>
                                            </div>
                                            <div className='col-md-12' style={{ marginTop: "5px" }}>
                                                <div className='row'>

                                                    <div className='col-md-4'>
                                                        <label><b>Organization Type:</b></label>
                                                        <Select
                                                            onChange={(data, value) => {
                                                                if (data == undefined) {
                                                                    setUnitlist({
                                                                        ...unitlist,
                                                                        domain_org_id: '',
                                                                        domain_org_name: ''

                                                                    })
                                                                } else {
                                                                    setUnitlist({
                                                                        ...unitlist,
                                                                        domain_org_id: data != undefined ? data : '',
                                                                        domain_org_name: value != undefined ? value.children : ''
                                                                    })
                                                                }
                                                            }}
                                                            onPaste={onPasteToaster}
                                                            searchValue={selectSearchValue.organizationType}
                                                            onSearch={(value) => {
                                                                let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                setSelectSearchValue({
                                                                    ...selectSearchValue,
                                                                    organizationType: result
                                                                });
                                                            }}
                                                            allowClear={true}
                                                            placeholder="Enter Organization Type"
                                                            style={{ width: '100%', marginTop: '3px' }}
                                                            getPopupContainer={trigger => trigger.parentNode}
                                                            showSearch
                                                            disabled={unitlist.domain == '' ? true : false}
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={unitlist.domain_org_name || undefined}
                                                        >
                                                            {organizationList && organizationList.length > 0 && organizationList.map((item) => {
                                                                return <Option key={item.organisation_id}>{item.organisation_name}</Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Unit: </b></label>
                                                        <Select
                                                            allowClear={true}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            placeholder='Enter Unit'

                                                            getPopupContainer={trigger => trigger.parentNode}
                                                            disabled={unitlist.legal_entity_name == '' ? true : false}
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            // onPaste={(e) => {
                                                            //     handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                                            // }}
                                                            onPaste={onPasteToaster}
                                                            searchValue={selectSearchValue.unit}
                                                            onSearch={(value) => {
                                                                let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                setSelectSearchValue({
                                                                    ...selectSearchValue,
                                                                    unit: result
                                                                });
                                                            }}
                                                            onChange={(data, value) => {
                                                                if (data == undefined) {
                                                                    setUnitlist({
                                                                        ...unitlist,
                                                                        unit_id: '',
                                                                        unit: '',

                                                                    })
                                                                } else {
                                                                    setUnitlist({
                                                                        ...unitlist,
                                                                        unit_id: data != undefined ? data : '',
                                                                        unit: value != undefined ? value.children : ''
                                                                    })
                                                                }
                                                            }}
                                                            style={{ width: '100%', marginTop: '3px' }}
                                                            value={unitlist.unit || undefined}
                                                        >
                                                            {unitlist && unitlist.unit_data && unitlist.unit_data.length > 0 &&
                                                                unitlist.unit_data.map((item) => {
                                                                    return <Option key={item.unit_id}>{`${item.unit_code}-${item.unit_name}`}</Option>
                                                                })}
                                                        </Select>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Product:</b></label>
                                                        <Select
                                                            // allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Product"
                                                            defaultValue="All"
                                                            // showSearch
                                                            optionFilterProp="children"

                                                            getPopupContainer={trigger => trigger.parentNode}
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            onChange={(data, value) => {
                                                                setUnitlist({
                                                                    ...unitlist,
                                                                    product_status_id: data != undefined ? data : '',
                                                                    product_status: value != undefined ? value.children : ''
                                                                })
                                                            }}
                                                            value={unitlist.product_status || undefined}
                                                            style={{ width: '100%', marginTop: '3px' }}
                                                        >
                                                            <Option key="All">All</Option>
                                                            {productfilter && productfilter.length > 0 && productfilter.map((item) => {
                                                                return <Option key={item.product_status_id}>{item.product_status}</Option>
                                                            })}

                                                        </Select>

                                                    </div>


                                                </div>
                                            </div>
                                            <div className='col-md-12' style={{ marginTop: "5px" }}>
                                                <div className='row'>

                                                    <div className='col-md-4'>
                                                        <label><b>Status:</b> </label>
                                                        <Select
                                                            // allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Status"
                                                            defaultValue="All"

                                                            getPopupContainer={trigger => trigger.parentNode}
                                                            onChange={(data, value) => {
                                                                setUnitlist({
                                                                    ...unitlist,
                                                                    unit_status_id: data != undefined ? data : '',
                                                                    unit_status: value != undefined ? value.children : ''
                                                                })
                                                            }}
                                                            // showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%', marginTop: '3px' }}
                                                            value={unitlist.unit_status || undefined}
                                                        >
                                                            <Option key="All">All</Option>
                                                            {filerunitdatas && filerunitdatas.unit_status_list && filerunitdatas.unit_status_list.length > 0 &&
                                                                filerunitdatas.unit_status_list.map((item) => {

                                                                    return <Option key={item.unit_status_id}>{item.unit_status}</Option>
                                                                })}

                                                        </Select>
                                                        <div className='col-md-4'></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='text-center popupbtncolour' style={{ marginTop: "5px" }}>
                                                <Button type="primary" icon={<EyeOutlined />} shape="round" className={'addbutton  me-1 ' + localStorage.getItem('currentTheme')} onClick={onshow}
                                                >
                                                    Show
                                                </Button>
                                                <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} icon={<ExportOutlined />} style={{ background: "#32a852", borderColor: "#32a852" }} onClick={showModal}
                                                >
                                                    Export
                                                </Button>
                                            </div>
                                        </Panel>
                                    </Collapse>
                                    <Modal
                                        onCancel={setcancelFilter}
                                        footer={false}
                                        title="Unit List"
                                        className={
                                            "add-service-prv cons-report " +
                                            localStorage.getItem("currentTheme")
                                        }
                                        open={filterModalVisible}
                                        maskClosable={false}
                                    >
                                        <div className='col-md-12' >
                                            <div className='row'  >
                                                <div className='col-md-4'>
                                                    <label><b>Country:</b> <span style={{ color: "red" }}>*</span></label>
                                                    <div className="form-group">
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <div className="form-group">
                                                                <Select
                                                                    placeholder='Enter Country'
                                                                    allowClear={true}
                                                                    size="default"
                                                                    onDrop={(e) => {
                                                                        e.preventDefault();
                                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                        return false;
                                                                    }}
                                                                    // onPaste={(e) => {
                                                                    //     handlePasting(e, /^[a-z]+$/gi)
                                                                    // }}
                                                                    onPaste={onPasteToaster}
                                                                    searchValue={selectSearchValue.country}
                                                                    onSearch={(value) => {
                                                                        let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                        setSelectSearchValue({
                                                                            ...selectSearchValue,
                                                                            country: result
                                                                        });
                                                                    }}
                                                                    onChange={(value, data) => {
                                                                        if (data !== undefined) {
                                                                            let le = _.filter(sessionArr, { c_id: Number(value) })
                                                                            setEntity(le)
                                                                        }
                                                                        setUnitlist({
                                                                            ...unitlist,
                                                                            country_id: value != undefined ? value : '',
                                                                            country: data != undefined ? data.children : '',
                                                                            legal_entity_id: "",
                                                                            legal_entity_name: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
                                                                            domain: "",
                                                                            division: "",
                                                                            category: "",
                                                                            unit: "",
                                                                            business_group_id: '',
                                                                            domain_id: null,
                                                                            division_id: null,
                                                                            category_id: null,
                                                                            unit_id: null,
                                                                            domain_org_id: '',
                                                                            domain_org_name: '',
                                                                            product_status: '',
                                                                            product_status_id: '',
                                                                            unit_status_id: '',
                                                                            unit_status: '',
                                                                            bg_id: '',
                                                                            bg_name: ''
                                                                        })
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    style={{ width: '100%', marginTop: '3px' }}
                                                                    value={unitlist.country || undefined}
                                                                    getPopupContainer={trigger => trigger.parentNode}

                                                                >
                                                                    {uniq_country_data && uniq_country_data.length && uniq_country_data.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.c_id}>
                                                                                {item.c_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                                {validator.current.message(
                                                                    'country',
                                                                    unitlist.country,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Country Required',
                                                                        }
                                                                    })}
                                                            </div> :
                                                            <p>{unitlist.country}</p>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='col-md-4 text-wrap-report'>
                                                    <label><b>Legal Entity: </b><span style={{ color: "red" }}>*</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Enter Legal Entity"
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                // onPaste={(e) => {
                                                                //     handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                                // }}
                                                                onPaste={onPasteToaster}
                                                                searchValue={selectSearchValue.legalEntity}
                                                                onSearch={(value) => {
                                                                    let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                    setSelectSearchValue({
                                                                        ...selectSearchValue,
                                                                        legalEntity: result
                                                                    });
                                                                }}
                                                                onChange={(data, value) => {
                                                                    Setlegalstate(true)
                                                                    setCurrentEntity(data != undefined ? data : localStorage.getItem('SelectedEntityid'))
                                                                    setUnitlist({
                                                                        ...unitlist,
                                                                        legal_entity_id: data != undefined ? data : '',
                                                                        legal_entity_name: value != undefined ? value.children : '',
                                                                        domain: "",
                                                                        division: "",
                                                                        category: "",
                                                                        unit: "",
                                                                        // business_group_id: '',
                                                                        domain_id: null,
                                                                        division_id: null,
                                                                        category_id: null,
                                                                        unit_id: null,
                                                                        domain_org_id: '',
                                                                        domain_org_name: '',
                                                                        product_status: '',
                                                                        product_status_id: '',
                                                                        unit_status_id: '',
                                                                        unit_status: '',
                                                                        // bg_id: '',
                                                                        // bg_name: ''
                                                                    })
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                style={{ width: '100%', marginTop: '3px' }}
                                                                getPopupContainer={trigger => trigger.parentNode}

                                                                value={unitlist.legal_entity_name || undefined}
                                                                disabled={unitlist.country == '' ? true : false}
                                                            >
                                                                {entity && entity.length > 0 && entity.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.le_id}>
                                                                            {item.le_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'legal_entity_id',
                                                                unitlist.legal_entity_id,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Legal Entity Required',
                                                                    }
                                                                })}
                                                        </div> :
                                                        <p>{localStorage.getItem('SelectedEntity')}</p>
                                                    }
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Domain:</b> </label>
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Domain"
                                                        value={unitlist.domain || undefined}
                                                        disabled={unitlist.legal_entity_name == '' ? true : false}
                                                        style={{ width: '100%', marginTop: '3px' }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z - .]+$/gi)
                                                        // }}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.domain}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                domain: result
                                                            });
                                                        }}
                                                        onChange={(data, value) => {
                                                            setUnitlist({
                                                                ...unitlist,
                                                                domain_id: data != undefined ? data : '',
                                                                domain: value != undefined ? value.children : '',
                                                                division: "",
                                                                category: "",
                                                                business_group_id: '',
                                                                division_id: null,
                                                                category_id: null,
                                                                unit_id: null,
                                                                domain_org_id: '',
                                                                domain_org_name: '',
                                                                product_status: '',
                                                                product_status_id: '',
                                                                unit_status_id: '',
                                                                unit_status: ''
                                                            })
                                                        }}>
                                                        {domainList && domainList.length > 0 &&
                                                            domainList.map((item) => (

                                                                <Option key={item.domain_id}>{item.domain_name}</Option>
                                                            ))}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 ' style={{ marginTop: "5px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Business Group: </b>  </label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Enter Business Group"

                                                                getPopupContainer={trigger => trigger.parentNode}
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                // onPaste={(e) => {
                                                                //     handlePasting(e, /^[a-z 0-9]+$/gi)
                                                                // }}
                                                                onPaste={onPasteToaster}
                                                                searchValue={selectSearchValue.BusinessGroup}
                                                                onSearch={(value) => {
                                                                    let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                    setSelectSearchValue({
                                                                        ...selectSearchValue,
                                                                        BusinessGroup: result
                                                                    });
                                                                }}
                                                                onChange={(data, value) => {
                                                                    if (data == undefined) {
                                                                        setUnitlist({
                                                                            ...unitlist,
                                                                            bg_id: '',
                                                                            bg_name: '',
                                                                            legal_entity_id: "",
                                                                            legal_entity_name: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
                                                                            domain: "",
                                                                            division: "",
                                                                            category: "",
                                                                            unit: "",
                                                                            business_group_id: '',
                                                                            domain_id: null,
                                                                            division_id: null,
                                                                            category_id: null,
                                                                            unit_id: null,
                                                                            domain_org_id: '',
                                                                            domain_org_name: '',
                                                                            product_status: '',
                                                                            product_status_id: '',
                                                                            unit_status_id: '',
                                                                            unit_status: '',
                                                                        })
                                                                    } else {
                                                                        setUnitlist({
                                                                            ...unitlist,
                                                                            bg_id: data,
                                                                            bg_name: value.children,
                                                                            legal_entity_id: "",
                                                                            legal_entity_name: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
                                                                            domain: "",
                                                                            division: "",
                                                                            category: "",
                                                                            unit: "",
                                                                            business_group_id: '',
                                                                            domain_id: null,
                                                                            division_id: null,
                                                                            category_id: null,
                                                                            unit_id: null,
                                                                            domain_org_id: '',
                                                                            domain_org_name: '',
                                                                            product_status: '',
                                                                            product_status_id: '',
                                                                            unit_status_id: '',
                                                                            unit_status: ''
                                                                        })
                                                                        let filteredLegalEntity = _.filter(legalEntity, { bg_id: Number(data) })
                                                                        setLegalEntity(filteredLegalEntity)
                                                                        let le = _.filter(sessionArr, { bg_id: Number(data) })
                                                                        setEntity(le)
                                                                    }
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={unitlist.bg_name || undefined}
                                                                style={{ width: '100%', marginTop: '3px' }}
                                                            >
                                                                {bggroup && bggroup.length > 0 && bggroup.map((item, i) => {

                                                                    if (item.bg_name !== null) {
                                                                        return (
                                                                            <Option key={item.bg_id}>
                                                                                {item.bg_name}
                                                                            </Option>
                                                                        );
                                                                    }
                                                                })}
                                                            </Select>
                                                        </div> :
                                                        <p>{businessGroup.bg_name ? businessGroup.bg_name : '-'}</p>
                                                    }
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Division:</b></label>
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Division"
                                                        disabled={unitlist.legal_entity_name == '' ? true : false}

                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z 0-9]+$/gi)
                                                        // }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.division}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                division: result
                                                            });
                                                        }}
                                                        onChange={(data, value) => {
                                                            console.log(data, "datadata");
                                                            if (data !== undefined) {
                                                                let unitlistdata = _.filter(filerunitdatas && filerunitdatas.units_list, { division_id: Number(data) })
                                                                setUnitlist({
                                                                    ...unitlist,
                                                                    division_id: data,
                                                                    division: value.children,
                                                                    category: '',
                                                                    category_id: '',
                                                                    unit_data: unitlistdata
                                                                })
                                                                let categorydata = _.filter(filerunitdatas && filerunitdatas.categories, { division_id: Number(data) });
                                                                setCategoryList(categorydata);
                                                                // setUnitlist({
                                                                //     ...unitlist,
                                                                //     unit_data: unitlistdata
                                                                // })
                                                            } else {
                                                                setUnitlist({
                                                                    ...unitlist,
                                                                    division_id: '',
                                                                    division: '',
                                                                    category: '',
                                                                    category_id: ''

                                                                })
                                                            }
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%', marginTop: '3px' }}
                                                        value={unitlist.division || undefined}
                                                    >
                                                        {filerunitdatas.divisions && filerunitdatas.divisions.length > 0 &&
                                                            filerunitdatas.divisions.map((item) => (
                                                                <Option key={item.division_id}>{item.division_name}</Option>
                                                            ))}
                                                    </Select>
                                                </div>

                                                <div className='col-md-4'>
                                                    <label><b>Category:</b></label>
                                                    <Select
                                                        onChange={(data, value) => {
                                                            if (data == undefined) {
                                                                setUnitlist({
                                                                    ...unitlist,
                                                                    category_id: '',
                                                                    category: '',
                                                                    unit_id: '',
                                                                    unit: '',

                                                                })
                                                            } else {
                                                                setUnitlist({
                                                                    ...unitlist,
                                                                    category_id: data != undefined ? data : '',
                                                                    category: value != undefined ? value.children : '',
                                                                    unit_id: '',
                                                                    unit: '',
                                                                })
                                                            }
                                                        }}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z 0-9]+$/gi)
                                                        // }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.category}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                category: result
                                                            });
                                                        }}
                                                        allowClear={true}
                                                        placeholder="Enter Category"

                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        disabled={unitlist.legal_entity_name == '' ? true : false}
                                                        style={{ width: '100%', marginTop: '3px' }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={unitlist.category || undefined}
                                                    >
                                                        {category && category.length > 0 &&
                                                            category.map((item) => (

                                                                <Option key={item.category_id}>{item.category_name}</Option>
                                                            ))}                                                       </Select>
                                                </div>


                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "5px" }}>
                                            <div className='row'>

                                                <div className='col-md-4'>
                                                    <label><b>Organization Type:</b></label>
                                                    <Select
                                                        onChange={(data, value) => {
                                                            if (data == undefined) {
                                                                setUnitlist({
                                                                    ...unitlist,
                                                                    domain_org_id: '',
                                                                    domain_org_name: ''

                                                                })
                                                            } else {
                                                                setUnitlist({
                                                                    ...unitlist,
                                                                    domain_org_id: data != undefined ? data : '',
                                                                    domain_org_name: value != undefined ? value.children : ''
                                                                })
                                                            }
                                                        }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.organizationType}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                organizationType: result
                                                            });
                                                        }}
                                                        allowClear={true}
                                                        placeholder="Enter Organization Type"
                                                        style={{ width: '100%', marginTop: '3px' }}
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        showSearch
                                                        disabled={unitlist.domain == '' ? true : false}
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={unitlist.domain_org_name || undefined}
                                                    >
                                                        {organizationList && organizationList.length > 0 && organizationList.map((item) => {
                                                            return <Option key={item.organisation_id}>{item.organisation_name}</Option>
                                                        })}
                                                    </Select>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Unit: </b></label>
                                                    <Select
                                                        allowClear={true}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        placeholder='Enter Unit'

                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        disabled={unitlist.legal_entity_name == '' ? true : false}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                                        // }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.unit}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                unit: result
                                                            });
                                                        }}
                                                        onChange={(data, value) => {
                                                            if (data == undefined) {
                                                                setUnitlist({
                                                                    ...unitlist,
                                                                    unit_id: '',
                                                                    unit: '',

                                                                })
                                                            } else {
                                                                setUnitlist({
                                                                    ...unitlist,
                                                                    unit_id: data != undefined ? data : '',
                                                                    unit: value != undefined ? value.children : ''
                                                                })
                                                            }
                                                        }}
                                                        style={{ width: '100%', marginTop: '3px' }}
                                                        value={unitlist.unit || undefined}
                                                    >
                                                        {unitlist && unitlist.unit_data && unitlist.unit_data.length > 0 &&
                                                            unitlist.unit_data.map((item) => {
                                                                return <Option key={item.unit_id}>{`${item.unit_code}-${item.unit_name}`}</Option>
                                                            })}
                                                    </Select>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Product:</b></label>
                                                    <Select
                                                        // allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Product"
                                                        defaultValue="All"
                                                        // showSearch
                                                        optionFilterProp="children"

                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        onChange={(data, value) => {
                                                            setUnitlist({
                                                                ...unitlist,
                                                                product_status_id: data != undefined ? data : '',
                                                                product_status: value != undefined ? value.children : ''
                                                            })
                                                        }}
                                                        value={unitlist.product_status || undefined}
                                                        style={{ width: '100%', marginTop: '3px' }}
                                                    >
                                                        <Option key="All">All</Option>
                                                        {productfilter && productfilter.length > 0 && productfilter.map((item) => {
                                                            return <Option key={item.product_status_id}>{item.product_status}</Option>
                                                        })}

                                                    </Select>

                                                </div>


                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "5px" }}>
                                            <div className='row'>

                                                <div className='col-md-4'>
                                                    <label><b>Status:</b> </label>
                                                    <Select
                                                        // allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Status"
                                                        defaultValue="All"

                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        onChange={(data, value) => {
                                                            setUnitlist({
                                                                ...unitlist,
                                                                unit_status_id: data != undefined ? data : '',
                                                                unit_status: value != undefined ? value.children : ''
                                                            })
                                                        }}
                                                        // showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%', marginTop: '3px' }}
                                                        value={unitlist.unit_status || undefined}
                                                    >
                                                        <Option key="All">All</Option>
                                                        {filerunitdatas && filerunitdatas.unit_status_list && filerunitdatas.unit_status_list.length > 0 &&
                                                            filerunitdatas.unit_status_list.map((item) => {

                                                                return <Option key={item.unit_status_id}>{item.unit_status}</Option>
                                                            })}

                                                    </Select>
                                                    <div className='col-md-4'></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='text-center popupbtncolour' style={{ marginTop: "5px" }}>
                                            <Button type="primary" icon={<EyeOutlined />} shape="round" className={'addbutton  me-1 ' + localStorage.getItem('currentTheme')} onClick={onshow}
                                            >
                                                Show
                                            </Button>
                                            <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} icon={<ExportOutlined />} style={{ background: "#32a852", borderColor: "#32a852" }} onClick={showModal}
                                            >
                                                Export
                                            </Button>
                                        </div>
                                    </Modal>
                                </div>

                                {tableshow == true ?
                                    <Card className='rounded-3'>

                                        <div className='col-md-12 mt-0 mb-2 filter-detial-sticky' >
                                            <div className='row'>
                                                <div className='col-md-4 text-wrap-report'>
                                                    <label><b>Legal Entity : {unitlist.legalEntityLabel}</b></label>
                                                </div>
                                                <div className='col-md-4 text-center'>
                                                    <label><b>Country : {unitlist.country ? unitlist.countryLabel : sessionArr[0].c_name}</b> </label>
                                                </div>
                                                <div className='col-md-4 text-center'>
                                                    <label><b>Domain : {unitlist.domainLabel}</b></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='ant-table-wrapper'>
                                            <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper'}>
                                                <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                    <div className='ant-table-container'>
                                                        <div className='ant-table-content userprivclass1'>
                                                            <table>
                                                                <colgroup>
                                                                    <col style={{ width: '10px' }}></col>
                                                                </colgroup>
                                                                <thead className="ant-table-thead table-head-stick">
                                                                    <tr>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '10px' }}> # </th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '80px' }}>Unit Code</th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '120px' }}>Unit Name</th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '100px' }}>Time Zone</th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '220px' }}><center>Domain-Organization Type</center></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '120px' }}>Location</th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '120px' }}><center>Address</center></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '120px' }}><center>Product</center></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '120px' }}><center>Status</center></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '120px' }}><center>Date</center></th>

                                                                    </tr>
                                                                </thead>
                                                                {filteredArrayState && filteredArrayState.length ? filteredArrayState.map((item, i) => {

                                                                    return (
                                                                        <tbody className='ant-table-tbody' key={i}>

                                                                            {titledata && titledata.map((itemdata, i) => {

                                                                                let data = _.find(itemdata.child, { category_name: item[0].category_name })

                                                                                if (data != undefined) {

                                                                                    if (data.category_name == itemdata.parent) {
                                                                                        return <Fragment key={i}>
                                                                                            <tr className='ant-table-row ant-table-row-level-0'>
                                                                                                <td colSpan={5} className='ant-table-cell' ><b style={{ backgroundColor: "lightyellow", marginLeft: "23px" }}>{"Division:" + itemdata.child[0].division_name}</b></td>
                                                                                                <td colSpan={5} className='ant-table-cell' ><b style={{ backgroundColor: "lightyellow" }}>{"Category:" + itemdata.parent}</b></td>
                                                                                            </tr>
                                                                                            {itemdata.child.map((items, itemskj) => {

                                                                                                if (item[0].category_name == items.category_name) {

                                                                                                    return <tr key={itemskj}>
                                                                                                        <td className='ant-table-cell'><center>{count + 1}</center></td>
                                                                                                        <td className='ant-table-cell'>{items.unit_code}</td>
                                                                                                        <td className='ant-table-cell'>{items.unit_name}</td>
                                                                                                        <td className='ant-table-cell'>{items.tz_name}</td>
                                                                                                        <td className='ant-table-cell'>{items.d_i_names.join(", ")}</td>
                                                                                                        <td className='ant-table-cell'>{items.geography_name}</td>
                                                                                                        <td className='ant-table-cell'>{items.address}{items.postal_code ? "," + items.postal_code : ''}</td>
                                                                                                        <td className='ant-table-cell'>{items.product_status}</td>
                                                                                                        <td className='ant-table-cell'>{items.unit_status}</td>
                                                                                                        <td className='ant-table-cell'><center>{items.closed_date}</center></td>
                                                                                                        <span hidden>{count = count + 1}</span>
                                                                                                    </tr>
                                                                                                }
                                                                                            })
                                                                                            }
                                                                                        </Fragment>
                                                                                    }
                                                                                    else if (itemdata.parent == '-') {

                                                                                        if (data.category_name == '') {
                                                                                            return <Fragment key={i}>
                                                                                                <tr className='ant-table-row ant-table-row-level-0'>
                                                                                                    <td colSpan={5} className='ant-table-cell'><b style={{ backgroundColor: "lightyellow", marginLeft: "23px" }}>{"Division:" + itemdata.child[0].division_name}</b></td>
                                                                                                    <td colSpan={5} className='ant-table-cell'><b style={{ backgroundColor: "lightyellow" }}>{"Category:" + itemdata.parent}</b></td>
                                                                                                </tr>
                                                                                                {itemdata.child.map((items, ite) => {
                                                                                                    if (item[0].category_name == items.category_name) {
                                                                                                        console.log(items, items.d_i_names, 'itemskkkk');
                                                                                                        return <tr key={ite}>
                                                                                                            <td className='ant-table-cell' style={{ width: '10px' }}>{count + 1}</td>
                                                                                                            <td className='ant-table-cell' style={{ width: '120px' }}>{items.unit_code}</td>
                                                                                                            <td className='ant-table-cell' style={{ width: '120px' }}>{items.unit_name}</td>
                                                                                                            <td className='ant-table-cell' style={{ width: '120px' }}>{items.tz_name}</td>
                                                                                                            <td className='ant-table-cell' style={{ width: '200px' }}>{items.d_i_names.join(",")}</td>
                                                                                                            <td className='ant-table-cell' style={{ width: '120px' }}>{items.geography_name}</td>
                                                                                                            <td className='ant-table-cell' style={{ width: '120px' }}>{items.address}{items.postal_code ? "," + items.postal_code : ''}</td>
                                                                                                            <td className='ant-table-cell' style={{ width: '120px' }}>{items.product_status}</td>
                                                                                                            <td className='ant-table-cell' style={{ width: '120px' }}>{items.unit_status}</td>
                                                                                                            <td className='ant-table-cell'>{items.closed_date}</td>

                                                                                                            <span hidden>{count = count + 1}</span>
                                                                                                        </tr>
                                                                                                    }
                                                                                                })
                                                                                                }
                                                                                            </Fragment>
                                                                                        }
                                                                                    }
                                                                                }
                                                                            })
                                                                            }
                                                                        </tbody>
                                                                    )
                                                                }) :
                                                                    <tr>
                                                                        <td colSpan="100%" style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                                    </tr>
                                                                }
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            {count != 0 ?
                                                <div className='col-md-6'>
                                                    <label>Showing {pageSize * (current - 1) + 1} to {count} of {tabledatas && tabledatas.total_count} entries</label>
                                                </div> : ''}
                                            <div className='col-md-6 text-right'>

                                                <Pagination
                                                    current={current}
                                                    showSizeChanger={true}
                                                    onShowSizeChange={() => {
                                                        setCurrent(1)

                                                    }}
                                                    pageSizeOptions={[25, 50, 100]} hideOnSinglePage={tabledatas && tabledatas.total_count > 25 ? false : true} defaultPageSize={25}
                                                    onChange={(page, pageSizes) => {
                                                        setpageState(true)
                                                        setCurrent(pageSize !== pageSizes ? 1 : page);
                                                        setPageSize(pageSizes)
                                                    }} total={tabledatas.total_count}
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                    : ''
                                    //  <h4 style={{ textAlign: 'center', marginTop: '5%' }}>No Records Found</h4>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default connect(mapStateToProps, {
    UnitListFilters,
    UnitListshow,
    UnitListexport

})(UnitlistReport);