import { IPGROUPNAMELIST, IPGROUPNAMETABLELIST, IPSTATUS } from '../types/index'

const initialState = {
    list: '',
    tabledata: '',
    ipstastus:''

}

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case IPGROUPNAMELIST:
            {
                return {
                    ...state,
                    list: payload[1]
                };
            }
        case IPGROUPNAMETABLELIST:
            {
                return {
                    ...state,
                    tabledata: payload[1],
                };
            }
            case IPSTATUS:
                {
                    return {
                        ...state,
                        ipstastus: payload[1],
                    };
                }
        default:
            return state;
    }
}