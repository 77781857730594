import React, { Fragment } from 'react'
import { Collapse, Select, Input, DatePicker, Card, Modal, Table, Button, Tooltip, Pagination } from 'antd';
import { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { LegalEntityScoreCardData, GetLEScoreReportData } from '../../../Store/Action/Report/LegalEntityScoreCard';
import { connect, useDispatch, useSelector } from 'react-redux';
import { filter } from 'lodash';
import { FullscreenOutlined, UpOutlined, EyeOutlined, FilterOutlined } from '@ant-design/icons';
import ScrollToTop from "react-scroll-to-top";
import { fullscreenmode, fullscreenexit, handleKeyDown } from './../../../Libs/fullscreenmode';
import { validationType, validateSelect, onPasteToaster } from './../../../Libs/inputValidation';
import useStickyHeight from "../../../Libs/useStickyHeight";
import ScrollButton from '../../Scrollbar';
import { Toaster } from '../../../Libs/Toaster';
import { CountrySplit, HomeRedirect } from './../../../Libs/country'
const homepage = HomeRedirect()
const uniq_country_data = CountrySplit()

const mapStateToProps = (state) => ({
    domain_data: state.LegalEntityScoreCard.legalEntityReport.domain_data.domains,
    tableData: state.LegalEntityScoreCard.legalEntityReport.getTableData
})


const LegalEntityScoreCard = ({
    LegalEntityScoreCardData,
    GetLEScoreReportData,
    domain_data,
    tableData
}) => {
    useStickyHeight()
    const _ = require("lodash");
    const historynew = useHistory();
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const country_info = sessionParsedValue.country_info
    const sessionArr = sessionParsedValue.entity_info
    const [getreportlist, setGetReportList] = useState([])
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const location = useLocation();
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const dispatch = useDispatch();
    const paramid = location.pathname;
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [tableshow, setTableShow] = useState(false)
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [key, setKey] = useState("1")
    const [entity, setEntity] = useState([])
    let [index, setIndex] = useState(0)
    const [type, setType] = useState('')
    const [filteredArray, setFilteredArray] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [completedModal, setCompletedModal] = useState(false)
    const [dataSource, setDataSource] = useState([])
    const [count, setCount] = useState(2);
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);
    const [domainValue, setDomainValue] = useState(false)
    const [domainFilter, setDomainFilter] = useState([])

    const [legalEntityScoreCard, setLegalEntityScoreCard] = useState({
        country: '',
        countryName: '',
        legel_entity: '',
        le_id: '',
        domain: '',
        domainName: '',
        domainLabel: '',
        legalEntityLabel: '',
        countryLabel: ''
    })
    
    const [selectSearchValue, setSelectSearchValue] = useState({
        country: '',
        legalEntity: '',
        domain: '',
    });
    const validator = useRef(new SimpleReactValidator());
    const { Panel } = Collapse;
    const { Option } = Select;
    let completedInProgressSum;
    let toConcurInProgressSum;
    let toApproveInProgressSum;
    let completedUserInProgress;
    let concurUserInProgress;
    let approveUserInProgress;
    let delayedSum;
    let compiledSum;
    let delayedUserSum;
    let compiledUserSum;
    let completedOverdueSum;
    let toConcurOverdueSum;
    let toApproveOverdueSum;
    let completedUserOverdue;
    let concurUserOverdue;
    let approveUserOverdue;



    // const onshow = () => {
    //   console.log("hii123");
    //   setAddFormSubmit(true)
    //   if (validator.current.allValid()) {
    //     [
    //       "164-d2aac8fe3c84455788998f05af741d21",
    //       {
    //         "session_token": "164-d2aac8fe3c84455788998f05af741d21",
    //         "request": [
    //           "GetLEWiseScoreCard",
    //           {
    //             "c_id": 1,
    //             "le_id": "zppd",
    //             "d_id": 1,
    //             "csv": false
    //           }
    //         ]
    //       }
    //     ]
    //     setTableShow(true)
    //     setKey(0)
    //   }
    // }

    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };

    const columns = [
        {
            title: type === 'Unit_inProgress' || type === 'unit_overdue' ? 'Vendors' : 'Users',
            ellipsis: true,
            width: '50px',
            dataIndex: type === 'Unit_inProgress' || type === 'unit_overdue' ? 'unit' : 'user_name',
            key: type === 'Unit_inProgress' || type === 'unit_overdue' ? 'unit' : 'user_name',
        },
        {
            title: 'To Complete',
            ellipsis: true,
            align: 'center',
            // selector: (row) => row.compliance_task,
            width: '60px',
            dataIndex: 'to_complete',
            key: 'to_complete',
        },
        {
            title: "To Concur",
            ellipsis: true,
            width: '50px',
            align: 'center',
            dataIndex: 'to_concur',
            key: 'to_concur',
        },
        {
            title: "To Approve",
            width: '50px',
            ellipsis: true,
            align: 'center',
            dataIndex: 'to_approve',
            key: 'to_approve',
        },
        {
            title: type === 'Unit_inProgress' || type === 'User_inProgress' ? 'Total(Task)' : '',
            width: type === 'Unit_inProgress' || type === 'User_inProgress' ? '50px' : '0px',
            ellipsis: true,
            render: (row) => type === 'Unit_inProgress' || type === 'User_inProgress' ? <b>{Number(row.to_complete) + Number(row.to_concur) + Number(row.to_approve)}</b> : '',
            align: 'center'
        },
    ];

    const completedColumns = [
        {
            title: type === 'unit_completed' ? 'Vendors' : 'Users',
            ellipsis: true,
            width: '50px',
            dataIndex: type === 'unit_completed' ? 'unit' : 'user_name',
            key: type === 'unit_completed' ? 'unit' : 'user_name',
        },
        {
            title: 'Delayed Count',
            ellipsis: true,
            // selector: (row) => row.compliance_task,
            align: 'center',
            width: '60px',
            dataIndex: 'delayed_count',
            key: 'delayed_count',
        },
        {
            title: "Complied Count",
            width: '50px',
            ellipsis: true,
            align: 'center',
            dataIndex: 'complied_count',
            key: 'to_approve',
        },
        {
            title: "Total(Task)",
            width: '50px',
            ellipsis: true,
            align: 'center',
            render: (row) => <b>{Number(row.delayed_count) + Number(row.complied_count)}</b>,
            align: 'center'

        },
    ];

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showCompletedModal = () => {
        setCompletedModal(true);
    };
    const handleCompletedOk = () => {
        setCompletedModal(false);
    };
    const handleCompletedCancel = () => {
        setCompletedModal(false);
    };

    const onshow = () => {
        setAddFormSubmit(true)

        if (validator.current.allValid() || legalEntityScoreCard.domain != '') {
            setTableShow(true)
            setExitCollapse(true);
            setFilterModalVisible(false);
            setFilterTaskAcc(false);
            setKey(0)
            setLegalEntityScoreCard({
                ...legalEntityScoreCard,
                countryLabel: legalEntityScoreCard.countryName,
                domainLabel: legalEntityScoreCard.domainName,
                legalEntityLabel: legalEntityScoreCard.legel_entity
            })
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetLEWiseScoreCard",
                        {
                            "c_id": Number(legalEntityScoreCard.country),
                            "le_id": entityid,
                            "d_id": Number(legalEntityScoreCard.domain),
                            "csv": false
                        }
                    ]
                }
            ]
            GetLEScoreReportData({
                payload: payload,
                paramid: paramid
            })
        }
    }

    const onChange = (key) => {
        console.log(key);
    };

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        console.log(entityid,'entityidentityid')
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetLEWiseScoreCardFilters",
                        {
                            "le_id": entityid
                        }
                    ]
                }
            ]
            if (entityid != 'null' && entityid != '' && entityid != undefined) {
                LegalEntityScoreCardData({
                    payload: payload,
                    paramid: paramid
                })
            }
        }

    }, [isAuth, entityid])

    useEffect(() => {
        if (entityid === null) {
            setCurrentEntity(legalEntityScoreCard.le_id)
        }
    }, [entityid])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != 'All Legal Entity') {
            let temp = localStorage.getItem("SelectedEntity")
            let tempcountry = _.filter(sessionArr, { le_name: temp })
            setLegalEntityScoreCard({
                ...legalEntityScoreCard,
                country: tempcountry && tempcountry.length > 0 ? Number(tempcountry[0].c_id) : '',
                countryName: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_name : '',
                le_id: localStorage.getItem('SelectedEntityid'),
                legel_entity: localStorage.getItem('SelectedEntity')
            })
        }
    }, [localStorage.getItem('SelectedEntity') != 'All Legal Entity'])

    useEffect(() => {
        if (tableData && tableData.le_wise_score_card_list && tableData.le_wise_score_card_list.length > 0) {
            if (type != '') {
                if (type === 'Unit_inProgress') {
                    setFilteredArray(tableData && tableData.le_wise_score_card_list && tableData.le_wise_score_card_list[0] && tableData.le_wise_score_card_list[0].inprogress_unit_wise)
                }
                else if (type === 'User_inProgress') {
                    setFilteredArray(tableData && tableData.le_wise_score_card_list && tableData.le_wise_score_card_list[0] && tableData.le_wise_score_card_list[0].inprogress_user_wise)
                }
                else if (type === 'unit_completed') {
                    setFilteredArray(tableData && tableData.le_wise_score_card_list && tableData.le_wise_score_card_list[0] && tableData.le_wise_score_card_list[0].completed_unit_wise)
                }
                else if (type === 'user_completed') {
                    setFilteredArray(tableData && tableData.le_wise_score_card_list && tableData.le_wise_score_card_list[0] && tableData.le_wise_score_card_list[0].completed_user_wise)
                }
                else if (type === 'unit_overdue') {
                    setFilteredArray(tableData && tableData.le_wise_score_card_list && tableData.le_wise_score_card_list[0] && tableData.le_wise_score_card_list[0].overdue_unit_wise)
                }
                else if (type === 'user_overdue') {
                    setFilteredArray(tableData && tableData.le_wise_score_card_list && tableData.le_wise_score_card_list[0] && tableData.le_wise_score_card_list[0].overdue_user_wise)
                }
            }
        }

    }, [type])


    useEffect(() => {
        if (filteredArray.length > 0) {
            setDataSource(filteredArray)
            if (type === 'Unit_inProgress') {
                let temp = []
                let temp1 = []
                let temp2 = []
                for (let i in filteredArray) {
                    temp.push(Number(filteredArray[i].to_complete))
                    temp1.push(Number(filteredArray[i].to_concur))
                    temp2.push(Number(filteredArray[i].to_approve))
                }
                completedInProgressSum = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                toConcurInProgressSum = temp1.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                toApproveInProgressSum = temp2.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
            }
            else if (type === 'User_inProgress') {
                let temp = []
                let temp1 = []
                let temp2 = []
                for (let i in filteredArray) {
                    temp.push(Number(filteredArray[i].to_complete))
                    temp1.push(Number(filteredArray[i].to_concur))
                    temp2.push(Number(filteredArray[i].to_approve))
                }
                completedUserInProgress = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                concurUserInProgress = temp1.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                approveUserInProgress = temp2.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
            }
            else if (type === 'unit_completed') {
                let temp = []
                let temp1 = []
                for (let i in filteredArray) {
                    temp.push(Number(filteredArray[i].delayed_count))
                    temp1.push(Number(filteredArray[i].complied_count))
                }
                delayedSum = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                compiledSum = temp1.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
            }
            else if (type === 'user_completed') {
                let temp = []
                let temp1 = []
                for (let i in filteredArray) {
                    temp.push(Number(filteredArray[i].delayed_count))
                    temp1.push(Number(filteredArray[i].complied_count))
                }
                delayedUserSum = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                compiledUserSum = temp1.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
            }
            else if (type === 'unit_overdue') {
                let temp = []
                let temp1 = []
                let temp2 = []
                for (let i in filteredArray) {
                    temp.push(Number(filteredArray[i].to_complete))
                    temp1.push(Number(filteredArray[i].to_concur))
                    temp2.push(Number(filteredArray[i].to_approve))
                }
                completedOverdueSum = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                toConcurOverdueSum = temp1.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                toApproveOverdueSum = temp2.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
            }
            else if (type === 'user_overdue') {
                let temp = []
                let temp1 = []
                let temp2 = []
                for (let i in filteredArray) {
                    temp.push(Number(filteredArray[i].to_complete))
                    temp1.push(Number(filteredArray[i].to_concur))
                    temp2.push(Number(filteredArray[i].to_approve))
                }
                completedUserOverdue = temp.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                concurUserOverdue = temp1.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                approveUserOverdue = temp2.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
            }
        }
    }, [filteredArray])

    useEffect(() => {
        if (filteredArray) {
            if (type === 'Unit_inProgress') {
                const newData = {
                    // key: count
                    unit: <b>Total</b>,
                    to_complete: completedInProgressSum,
                    to_concur: toConcurInProgressSum,
                    to_approve: toApproveInProgressSum,
                    total: '0'
                }
                setDataSource([...filteredArray, newData])
                setCount(count + 1)
            }
            else if (type === 'User_inProgress') {
                const newData = {
                    // key: count
                    user_name: <b>Total</b>,
                    to_complete: completedUserInProgress,
                    to_concur: concurUserInProgress,
                    to_approve: approveUserInProgress,
                    total: '0'
                }
                setDataSource([...filteredArray, newData])
                setCount(count + 1)
            }
            else if (type === 'unit_completed') {
                const newData = {
                    // key: count
                    unit: <b>Total</b>,
                    delayed_count: delayedSum,
                    complied_count: compiledSum,
                    total: '0'
                }
                setDataSource([...filteredArray, newData])
                setCount(count + 1)
            }
            else if (type === 'user_completed') {
                const newData = {
                    // key: count
                    user_name: <b>Total</b>,
                    delayed_count: delayedUserSum,
                    complied_count: compiledUserSum,
                    total: '0'
                }
                setDataSource([...filteredArray, newData])
                setCount(count + 1)
            }
            else if (type === 'unit_overdue') {
                const newData = {
                    // key: count
                    unit: <b>Total</b>,
                    to_complete: completedOverdueSum,
                    to_concur: toConcurOverdueSum,
                    to_approve: toApproveOverdueSum,
                    total: '0'
                }
                setDataSource([...filteredArray, newData])
                setCount(count + 1)
            }
            else if (type === 'user_overdue') {
                const newData = {
                    // key: count
                    user_name: <b>Total</b>,
                    to_complete: completedUserOverdue,
                    to_concur: concurUserOverdue,
                    to_approve: approveUserOverdue,
                    total: '0'
                }
                setDataSource([...filteredArray, newData])
                setCount(count + 1)
            }



        }
    }, [filteredArray, type])

    const homescreen = () => {
        let getUrlDetails = homepage;
        let tempval = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_url;
        let tempkey = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_key;
        localStorage.setItem('formkey', tempkey);
        historynew.push(tempval);
        // window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }

    const handlePasting = (e, regex) => {

        var regex = regex;
        var key = e.clipboardData.getData('text')
        if (!regex.test(key)) {
            e.preventDefault();
            Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
            return false;
        }
        // e.clipboardData.getData('text')
        // Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
        // e.preventDefault();
        // return false;
    }

    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };
    const [fullscreen, setfullscreen] = useState(false);

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange',() => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }

    useEffect(() => {
        if (legalEntityScoreCard.country != '' && legalEntityScoreCard.le_id != '' && domain_data && domain_data.length == 1) {
            setDomainValue(true)
            setLegalEntityScoreCard({
                ...legalEntityScoreCard,
                domain: domain_data[0].d_id,
                domainName: domain_data[0].d_name,
            })

        } else {
            setDomainValue(false)
            setDomainFilter(domain_data)

        }
    }, [domain_data])

    return (
        <Fragment>
            <div id='page-wrapper' className="page-wrapper">
                {/* <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} /> */}
                {/* {document.body.scrollHeight > document.body.clientHeight ?
                    <div className='back-to-top'>
                        <ScrollButton />
                    </div> : ''} */}
                {tableshow == true ?
                    <div className='back-to-top'>
                        <ScrollButton />
                    </div> : ''}
                <div className="page-titles pb-0 pt-2 page-title-sticky">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <span style={{ fontSize: '14px', fontWeight: "bold" }}>Report</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                        <span style={{ fontSize: "15px", fontWeight: "bold" }}>Score Cards</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <span>Legal Entity Wise Score Card</span>
                                    </li>
                                </ol>
                            </nav>

                        </div>
                        <div className="col-lg-4 text-end">
                            <Tooltip title="Advance Filter"><Button
                                className="mx-1"
                                type="primary"
                                shape="round"
                                style={{
                                    display: filterTaskAcc ? "none" : "initial",
                                }}
                                onClick={filterHandle}
                            >
                                <FilterOutlined /> Filter
                            </Button></Tooltip>
                            <button style={{ float: 'right' }} onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                                <Tooltip placement="left" title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                            </button>
                        </div>
                    </div>
                </div>
                <Modal className={localStorage.getItem('currentTheme') + ' add-service-prv'} title={(type === 'Unit_inProgress' ? 'Vendor Wise Inprogress Task Count' : (type === 'User_inProgress' ? 'User Wise Inprogress Task Count' : (type === 'unit_overdue' ? 'Vendor Wise Overdue Task Count' : 'User Wise Overdue Task Count')))} open={isModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel} maskClosable={false}>
                    <div className={localStorage.getItem('currentTheme')}>
                        <Table
                            className='audittrailtable'
                            columns={columns}
                            dataSource={dataSource}
                            bordered
                            // scroll={{ x: 1500 }}
                            pagination={false} />
                    </div>

                </Modal>
                <Modal className={localStorage.getItem('currentTheme') + ' add-service-prv'} title={type === 'unit_completed' ? 'Vendor Wise Completed Task Count' : 'User Wise Completed Task Count'} open={completedModal} footer={null} onCancel={handleCompletedCancel} maskClosable={false}>
                    <div className={localStorage.getItem('currentTheme')}>
                        <Table
                            className='audittrailtable'
                            columns={completedColumns}
                            dataSource={dataSource}
                            bordered
                            // scroll={{ x: 1500 }}
                            pagination={false} />
                    </div>

                </Modal>
                <div className="container-fluid pt-1">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-body" id='only-restricted' style={{ padding: "8px 1px" }}>
                                <div className={localStorage.getItem('currentTheme')}>

                                    <Collapse defaultActiveKey={["1"]} className='report-collapse' onChange={onChange} style={{ display: exitCollapse ? "none" : "block" }}>
                                        <Panel header="Legal Entity Wise Score Card" key={key}>
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className='col-md-4'>
                                                        <label><b>Country : </b> <span style={{ color: "red" }}>*</span></label>
                                                        <div className="form-group">
                                                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                                <div className="form-group">
                                                                    <Select
                                                                        allowClear={true}
                                                                        size="default"
                                                                        placeholder="Enter Country"
                                                                        onDrop={(e) => {
                                                                            e.preventDefault();
                                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                            return false;
                                                                        }}
                                                                        // onPaste={(e) => {
                                                                        //     handlePasting(e, /^[a-z]+$/gi)
                                                                        // }}
                                                                        onPaste={onPasteToaster}
                                                                        searchValue={selectSearchValue.country}
                                                                        onSearch={(value) => {
                                                                            let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                            setSelectSearchValue({
                                                                                ...selectSearchValue,
                                                                                country: result
                                                                            });
                                                                        }}
                                                                        onChange={(value, data) => {
                                                                            setDomainFilter([])
                                                                            setDomainValue(false)
                                                                            setCurrentEntity('')
                                                                            if (value !== undefined) {
                                                                                let le = _.filter(sessionArr, { c_id: Number(value) })
                                                                                setEntity(le)
                                                                            }
                                                                            setLegalEntityScoreCard({
                                                                                ...legalEntityScoreCard,
                                                                                country: value ? value : '',
                                                                                countryName: data ? data.children : '',
                                                                                legel_entity: '',
                                                                                le_id: '',
                                                                                domain: '',
                                                                                domainName: ''
                                                                            })
                                                                        }}
                                                                        showSearch
                                                                        optionFilterProp="children"
                                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                        //value={Settings.le_id}
                                                                        style={{ width: '100%', marginTop: '3px' }}
                                                                        value={legalEntityScoreCard.countryName || undefined}
                                                                    >
                                                                        {uniq_country_data && uniq_country_data.length > 0 && uniq_country_data.map((item, i) => {
                                                                            return (
                                                                                <Option key={item.c_id}>
                                                                                    {item.c_name}
                                                                                </Option>
                                                                            );
                                                                        })}
                                                                    </Select>
                                                                    {validator.current.message(
                                                                        'legalentity',
                                                                        legalEntityScoreCard.countryName,
                                                                        'required',
                                                                        {
                                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                            messages: {
                                                                                required: ' Country Required',
                                                                            }
                                                                        })}
                                                                </div> :
                                                                <p className='cut-text-word' style={{ width: '100%', marginTop: "5px" }}>{legalEntityScoreCard.countryName}</p>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4 text-wrap-report'>
                                                        <label><b>Legal Entity : </b>  <span style={{ color: "red" }}>*</span></label>
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <div className="form-group">
                                                                <Select
                                                                    allowClear={true}
                                                                    size="default"
                                                                    placeholder="Enter Legal Entity"
                                                                    onDrop={(e) => {
                                                                        e.preventDefault();
                                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                        return false;
                                                                    }}
                                                                    // onPaste={(e) => {
                                                                    //     handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                                    // }}
                                                                    onPaste={onPasteToaster}
                                                                    searchValue={selectSearchValue.legalEntity}
                                                                    onSearch={(value) => {
                                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                        setSelectSearchValue({
                                                                            ...selectSearchValue,
                                                                            legalEntity: result
                                                                        });
                                                                    }}
                                                                    onChange={(data, value) => {
                                                                        setDomainFilter([])
                                                                        setDomainValue(false)
                                                                        setCurrentEntity(data)
                                                                        setLegalEntityScoreCard({
                                                                            ...legalEntityScoreCard,
                                                                            le_id: data ? data : '',
                                                                            legel_entity: value ? value.children : '',
                                                                            domain: '',
                                                                            domainName: ''
                                                                        })
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    //value={Settings.le_id}
                                                                    style={{ width: '100%', marginTop: '3px' }}

                                                                    value={legalEntityScoreCard.legel_entity || undefined}
                                                                    disabled={legalEntityScoreCard.country == '' ? true : false}
                                                                >
                                                                    {entity && entity.length > 0 && entity.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.le_id}>
                                                                                {item.le_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                                {validator.current.message(
                                                                    'legalentity',
                                                                    legalEntityScoreCard.legel_entity,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Legal Entity Required',
                                                                        }
                                                                    })}
                                                            </div> :
                                                            <p className='cut-text-word' style={{ width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>
                                                        }
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Domain : </b>  <span style={{ color: "red" }}>*</span></label>
                                                        <div className="form-group">
                                                            {domainValue == false || domainFilter && domainFilter.length > 1 ?
                                                                <>
                                                                    <Select allowClear={true}
                                                                        onDrop={(e) => {
                                                                            e.preventDefault();
                                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                            return false;
                                                                        }}
                                                                        // onPaste={(e) => {
                                                                        //     handlePasting(e, /^[a-z - .]+$/gi)
                                                                        // }}
                                                                        onPaste={onPasteToaster}
                                                                        searchValue={selectSearchValue.domain}
                                                                        onSearch={(value) => {
                                                                            let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                            setSelectSearchValue({
                                                                                ...selectSearchValue,
                                                                                domain: result
                                                                            });
                                                                        }}
                                                                        onChange={(value, data) => {
                                                                            setLegalEntityScoreCard({
                                                                                ...legalEntityScoreCard,
                                                                                domain: value ? value : '',
                                                                                domainName: data ? data.children : ''
                                                                            })
                                                                        }}
                                                                        placeholder="Enter Domain"
                                                                        style={{ width: '100%', marginTop: '3px' }}

                                                                        value={legalEntityScoreCard.domainName || undefined}
                                                                        disabled={legalEntityScoreCard.le_id == '' ? true : false}
                                                                        showSearch
                                                                        optionFilterProp="children"
                                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                                    >
                                                                        {domainFilter && domainFilter.length > 0 && domainFilter.map((item, i) => {
                                                                            return (
                                                                                <Option key={item.d_id}>
                                                                                    {item.d_name}
                                                                                </Option>
                                                                            );
                                                                        })}
                                                                    </Select>
                                                                    {validator.current.message(
                                                                        'domain',
                                                                        legalEntityScoreCard.domain,
                                                                        'required',
                                                                        {
                                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                            messages: {
                                                                                required: 'Domain Required',
                                                                            }
                                                                        })}
                                                                </>
                                                                : <p className='cut-text-word' style={{ width: '100%', marginTop: "5px" }}>{domain_data && domain_data.length > 0 && domain_data[0].d_name}</p>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className='col-md-4'></div>
                        <div className='col-md-4'></div> */}
                                            <div className={'text-center mt-2 popupbtncolour ' + localStorage.getItem('currentTheme')} >
                                                <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                                    icon={<EyeOutlined />} onClick={onshow}
                                                >
                                                    Show
                                                </Button>
                                                {/* <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ background: "#32a852", borderColor: "#32a852", width: "100px" }}
                          // onClick={showModal}
                          >
                            Export
                          </Button> */}
                                            </div>


                                        </Panel>
                                    </Collapse>
                                    <Modal
                                        onCancel={setcancelFilter}
                                        footer={false}
                                        title="Legal Entity Wise Score Card"
                                        className={
                                            "add-service-prv modal-service-priv " +
                                            localStorage.getItem("currentTheme")
                                        }
                                        open={filterModalVisible}
                                        maskClosable={false}
                                    >
                                        <div className="row">
                                            <div className='col-md-6'>
                                                <label><b>Country : </b> <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Enter Country"
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                // onPaste={(e) => {
                                                                //     handlePasting(e, /^[a-z]+$/gi)
                                                                // }}
                                                                onPaste={onPasteToaster}
                                                                searchValue={selectSearchValue.country}
                                                                onSearch={(value) => {
                                                                    let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                    setSelectSearchValue({
                                                                        ...selectSearchValue,
                                                                        country: result
                                                                    });
                                                                }}
                                                                onChange={(value, data) => {
                                                                    setDomainFilter([])
                                                                    setDomainValue(false)
                                                                    setCurrentEntity('')
                                                                    if (value !== undefined) {
                                                                        let le = _.filter(sessionArr, { c_id: Number(value) })
                                                                        setEntity(le)
                                                                    }
                                                                    setLegalEntityScoreCard({
                                                                        ...legalEntityScoreCard,
                                                                        country: value ? value : '',
                                                                        countryName: data ? data.children : '',
                                                                        legel_entity: '',
                                                                        le_id: '',
                                                                        domain: '',
                                                                        domainName: ''
                                                                    })
                                                                }}
                                                                getPopupContainer={trigger => trigger.parentNode}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                //value={Settings.le_id}
                                                                style={{ width: '100%', marginTop: '3px' }}
                                                                value={legalEntityScoreCard.countryName || undefined}
                                                            >
                                                                {uniq_country_data && uniq_country_data.length > 0 && uniq_country_data.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.c_id}>
                                                                            {item.c_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'legalentity',
                                                                legalEntityScoreCard.legel_entity,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: ' Country Required',
                                                                    }
                                                                })}
                                                        </div> :
                                                        <p className='cut-text-word' style={{ width: '100%', marginTop: "5px" }}>{legalEntityScoreCard.countryName}</p>
                                                    }
                                                </div>
                                            </div>
                                            <div className='col-md-6 text-wrap-report'>
                                                <label><b>Legal Entity : </b>  <span style={{ color: "red" }}>*</span></label>
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Legal Entity"
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            // onPaste={(e) => {
                                                            //     handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                            // }}
                                                            onPaste={onPasteToaster}
                                                            searchValue={selectSearchValue.legalEntity}
                                                            onSearch={(value) => {
                                                                let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                setSelectSearchValue({
                                                                    ...selectSearchValue,
                                                                    legalEntity: result
                                                                });
                                                            }}
                                                            onChange={(data, value) => {
                                                                setDomainFilter([])
                                                                setDomainValue(false)
                                                                setCurrentEntity(data)
                                                                setLegalEntityScoreCard({
                                                                    ...legalEntityScoreCard,
                                                                    le_id: data ? data : '',
                                                                    legel_entity: value ? value.children : '',
                                                                    domain: '',
                                                                    domainName: ''
                                                                })
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            //value={Settings.le_id}
                                                            style={{ width: '100%', marginTop: '3px' }}
                                                            getPopupContainer={trigger => trigger.parentNode}
                                                            value={legalEntityScoreCard.legel_entity || undefined}
                                                            disabled={legalEntityScoreCard.country == '' ? true : false}
                                                        >
                                                            {entity && entity.length > 0 && entity.map((item, i) => {
                                                                return (
                                                                    <Option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'legalentity',
                                                            legalEntityScoreCard.legel_entity,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Required',
                                                                }
                                                            })}
                                                    </div> :
                                                    <p className='cut-text-word' style={{ width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>
                                                }
                                            </div>
                                            <div className='col-md-6 mt-1' >
                                                <label><b>Domain : </b>  <span style={{ color: "red" }}>*</span></label>
                                                {domainValue == false || domainFilter && domainFilter.length > 1 ?
                                                    <>
                                                        <div className="form-group">
                                                            <Select allowClear={true}
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                // onPaste={(e) => {
                                                                //     handlePasting(e, /^[a-z - .]+$/gi)
                                                                // }}
                                                                onPaste={onPasteToaster}
                                                                searchValue={selectSearchValue.domain}
                                                                onSearch={(value) => {
                                                                    let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                    setSelectSearchValue({
                                                                        ...selectSearchValue,
                                                                        domain: result
                                                                    });
                                                                }}
                                                                onChange={(value, data) => {
                                                                    setLegalEntityScoreCard({
                                                                        ...legalEntityScoreCard,
                                                                        domain: value ? value : '',
                                                                        domainName: data ? data.children : ''
                                                                    })
                                                                }}
                                                                placeholder="Enter Domain"
                                                                style={{ width: '100%', marginTop: '3px' }}
                                                                getPopupContainer={trigger => trigger.parentNode}
                                                                value={legalEntityScoreCard.domainName || undefined}
                                                                disabled={legalEntityScoreCard.le_id == '' ? true : false}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                            >
                                                                {domainFilter && domainFilter.length > 0 && domainFilter.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.d_id}>
                                                                            {item.d_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'domain',
                                                                legalEntityScoreCard.domain,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Domain Required',
                                                                    }
                                                                })}
                                                        </div>

                                                    </>
                                                    : <p className='cut-text-word' style={{ width: '100%', marginTop: "5px" }}>{domain_data && domain_data.length > 0 && domain_data[0].d_name}</p>}
                                            </div>
                                        </div>
                                        {/* <div className='col-md-4'></div>
<div className='col-md-4'></div> */}
                                        <div className={'text-center mt-2 popupbtncolour ' + localStorage.getItem('currentTheme')}>
                                            <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                                icon={<EyeOutlined />} onClick={onshow}
                                            >
                                                Show
                                            </Button>
                                            {/* <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ background: "#32a852", borderColor: "#32a852", width: "100px" }}
// onClick={showModal}
>
Export
</Button> */}
                                        </div>


                                    </Modal>

                                </div>
                                {tableshow == true ?
                                    <Card className='rounded-3'>

                                        <div >
                                            <div className='col-md-12 mt-1 mb-1 filter-detial-sticky' style={{ marginLeft: '1%' }} >
                                                <div className='row'>
                                                    <div className='col-md-4 text-wrap-report'>
                                                        <label ><b>Legal Entity :</b> {legalEntityScoreCard.legalEntityLabel}</label>
                                                    </div>
                                                    <div className='col-md-4 text-center'>
                                                        <label ><b>Country :</b> {legalEntityScoreCard.countryLabel}</label>
                                                    </div>
                                                    <div className='col-md-4 text-end mr-1' >
                                                        <label style={{ marginRight: '5%' }}><b>Domain :</b> {legalEntityScoreCard.domainLabel}</label>
                                                    </div>


                                                </div>
                                            </div>
                                            <div className={localStorage.getItem('currentTheme')}>
                                            <div className='ant-table-wrapper domaincardtotal'>

                                                    <div className='ant-table ant-table-bordered'>
                                                        <div className='ant-table-container'>
                                                            <div className='ant-table-content scorecardtable'>
                                                                <table>
                                                                    <colgroup>
                                                                        <col style={{ width: '10px' }}></col>
                                                                    </colgroup>
                                                                    <thead className="ant-table-thead table-head-stick">
                                                                        <tr>
                                                                            <th className='ant-table-cell w10px'>#</th>
                                                                            <th title='Compliance Task' className='ant-table-cell custom-width1'>Task Status</th>
                                                                            <th className='ant-table-cell custom-width1'>Count</th>
                                                                            <th className='ant-table-cell custom-width1'><center>Vendor Wise</center></th>
                                                                            <th className='ant-table-cell custom-width1'>User Wise</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className='ant-table-tbody'>
                                                                        <tr>
                                                                            <td className='ant-table-cell'><center>1</center></td>
                                                                            <td className='ant-table-cell'><center>Inprogress</center></td>
                                                                            <td className='ant-table-cell'><center>{tableData && tableData.le_wise_score_card_list && tableData.le_wise_score_card_list[0].inprogress_count}</center></td>
                                                                            <td className
                                                                                onCl='ant-table-cell'><center>
                                                                                    <Button type="primary" shape='round'
                                                                                        disabled={(tableData && tableData.le_wise_score_card_list && tableData.le_wise_score_card_list[0].inprogress_count > 0) ? false : true}
                                                                                        onClick={() => {
                                                                                            setType('Unit_inProgress')
                                                                                            showModal()
                                                                                        }}
                                                                                    >View</Button>
                                                                                </center>
                                                                            </td>
                                                                            <td className='ant-table-cell'><center><Button type="primary" shape='round' disabled={(tableData && tableData.le_wise_score_card_list && tableData.le_wise_score_card_list[0].inprogress_count > 0) ? false : true}
                                                                                onClick={() => {
                                                                                    setType('User_inProgress')
                                                                                    showModal()
                                                                                }}>View</Button></center></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='ant-table-cell'><center>2</center></td>
                                                                            <td className='ant-table-cell'><center>Completed</center></td>
                                                                            <td className='ant-table-cell'><center>{tableData && tableData.le_wise_score_card_list && tableData.le_wise_score_card_list[0].completed_count}</center></td>
                                                                            <td className='ant-table-cell'><center><Button type="primary" shape='round' disabled={(tableData && tableData.le_wise_score_card_list && tableData.le_wise_score_card_list[0].completed_count) > 0 ? false : true}
                                                                                onClick={() => {
                                                                                    setType('unit_completed')
                                                                                    showCompletedModal()
                                                                                }}
                                                                            >View</Button></center></td>
                                                                            <td className='ant-table-cell'><center><Button type="primary" shape='round' disabled={(tableData && tableData.le_wise_score_card_list && tableData.le_wise_score_card_list[0].completed_count) > 0 ? false : true}
                                                                                onClick={() => {
                                                                                    setType('user_completed')
                                                                                    showCompletedModal()
                                                                                }}
                                                                            >View</Button></center></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='ant-table-cell'><center>3</center></td>
                                                                            <td className='ant-table-cell'><center>Over due</center></td>
                                                                            <td className='ant-table-cell'><center>{tableData && tableData.le_wise_score_card_list && tableData.le_wise_score_card_list[0].overdue_count}</center></td>
                                                                            <td className='ant-table-cell'><center><Button type="primary" shape='round' disabled={(tableData && tableData.le_wise_score_card_list && tableData.le_wise_score_card_list[0].overdue_count) > 0 ? false : true}
                                                                                onClick={() => {
                                                                                    setType('unit_overdue')
                                                                                    showModal()
                                                                                }}
                                                                            >View</Button></center></td>
                                                                            <td className='ant-table-cell'><center><Button type="primary" shape='round' disabled={(tableData && tableData.le_wise_score_card_list && tableData.le_wise_score_card_list[0].overdue_count) > 0 ? false : true}
                                                                                onClick={() => {
                                                                                    setType('user_overdue')
                                                                                    showModal()
                                                                                }}>View</Button></center></td>
                                                                        </tr>
                                                                        <tr >
                                                                            <td className='ant-table-cell'><center></center></td>
                                                                            <td className='ant-table-cell'><center><b>Total</b></center></td>
                                                                            <td className='ant-table-cell'><center><b>{tableData && tableData.le_wise_score_card_list && tableData.le_wise_score_card_list[0].overdue_count + tableData.le_wise_score_card_list[0].completed_count + tableData.le_wise_score_card_list[0].inprogress_count}</b></center></td>
                                                                            <td className='ant-table-cell'><center></center></td>
                                                                            <td className='ant-table-cell'><center></center></td>
                                                                        </tr>
                                                                    </tbody>

                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </Card>
                                    : false}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}


export default connect(mapStateToProps, {
    LegalEntityScoreCardData,
    GetLEScoreReportData
})(LegalEntityScoreCard);