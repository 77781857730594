import React, { Fragment } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux';
import { Collapse, Select, Input, DatePicker, Card, Modal, Button, Table, Tooltip, Pagination } from 'antd';
import moment from 'moment';
import { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { ExclamationCircleTwoTone, ExclamationCircleOutlined, EyeOutlined, ExportOutlined, FullscreenOutlined, UpOutlined, FilterOutlined, } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { ReassignedHistoryReportFilters, ReassignedHistoryReport } from './../../../Store/Action/Report/ReassignReport';
import ScrollToTop from "react-scroll-to-top";
import { fullscreenmode, fullscreenexit, handleKeyDown } from './../../../Libs/fullscreenmode';
import { validationType, validateSelect, onPasteToaster } from './../../../Libs/inputValidation';
import useStickyHeight from "../../../Libs/useStickyHeight"
import ScrollButton from '../../Scrollbar';
import { Toaster } from '../../../Libs/Toaster';
import { CountrySplit, HomeRedirect } from './../../../Libs/country'
const uniq_country_data = CountrySplit()
const homepage = HomeRedirect()

const mapStateToProps = (state) => ({
    reassignreports: state.ReassignReport.reassignreport,
})

const ReassignReports = ({
    ReassignedHistoryReportFilters,
    ReassignedHistoryReport,
    reassignreports: { reassignreports, Reassign_history_show }
}) => {
    useStickyHeight();
    const historynew = useHistory();
    console.log(reassignreports, "reassignreportsreassignreports");
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const dispatch = useDispatch();
    const location = useLocation();
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    // const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const country_info = sessionParsedValue.country_info
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid').replaceAll('"', ''));
    const paramid = location.pathname;
    const _ = require("lodash");
    const [entity, setEntity] = useState([])
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [legalstate, Setlegalstate] = useState(false)
    const [tableshow, setTableShow] = useState(false)
    const [categoryList, setCategoryList] = useState([])
    const [act, setAct] = useState([])
    const [division, setDivision] = useState([])
    const [domain, setDomain] = useState([])
    const [users, setUsers] = useState([])
    const [unit, setUnit] = useState([])
    const [titledata, Settitledata] = useState([])
    console.log(titledata, 'titledata');
    const [key, setKey] = useState("1")
    let [count, setcount] = useState(0)
    const [current, setCurrent] = useState(1);
    const [pageState, setpageState] = useState(false);
    const [pageSize, setPageSize] = useState(25)
    const [paginationarray, setPaginationArray] = useState([])
    const [filteredArrayState, setfilteredArrayState] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [childCompliceId, setChildComplinaceId] = useState([])
    console.log(childCompliceId, "childCompliceId");



    const [unitdata, setUnitData] = useState({
        legal_entity_id: "",
        legal_entity_name: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
        country: '',
        countryName: '',
        domain: 0,
        domain_name: '',
        user: null,
        user_name: '',
        act: 0,
        act_name: '',
        from_date: "",
        division: 0,
        division_name: '',
        compliance_task: null,
        to_date: "",
        category: 0,
        category_name: '',
        unit: 0,
        unit_name: '',
        legalEntityLabel: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
        countryLabel: '',
        domainLabel: ''
    })
    const [disableButton, setDisableButton] = useState(false)

    const validator = useRef(new SimpleReactValidator());
    const { Panel } = Collapse;
    const { Option } = Select;
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);
    const [selectSearchValue, setSelectSearchValue] = useState({
        country: '',
        legalEntity: '',
        BusinessGroup: '',
        domain: '',
        actRules: '',
        complianceTask: '',
        user: '',
        division: '',
        category: '',
        unit: '',
    });

    const ComplianceTaskName = e => {
        console.log(e, 'eeeeeeee')
        const { value } = e.target;
        // onChange={(e) => {
        let chk_valid = ''

        for (let i = 0; i < value.length; i++) {
            if (value[i] == '<' || value[i] == '>') {
                chk_valid += value[i]
            }
        }
        if (chk_valid.match('<>')) {
            //   UpdatePannelRemarks[collapseId] = ''
            setUnitData({
                ...unitdata,
                compliance_task: ''
            })
            Toaster.error('Please check the input, it having some invalid characters')
        } else {

            const re = /[^|~^]+$/;
            if (value === "" || re.test(value)) {
                if (value.includes('~') || value.includes('^') || value.includes('|')) {
                    return false
                } else {
                    setUnitData({
                        ...unitdata,
                        compliance_task: e.target.value
                    })
                }
            }
        }
    }


    useEffect(() => {
        if (isAuth) {
            if (legalstate === true) {
                if (entityid != "null") {
                    ReassignedHistoryReportFilters({
                        payload: [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request": [
                                    "GetReassignedHistoryReportFilters",
                                    {
                                        "le_id": entityid
                                    }
                                ]
                            }
                        ],
                        paramid: paramid
                    })
                }
                Setlegalstate(false)
            }
        }
    }, [isAuth, entityid, legalstate])

    // useEffect(() => {
    //     if (entityid != null) {
    //         setUnitData({
    //             ...unitdata,
    //             country: sessionArr[0].c_id
    //         })
    //     }
    // }, [entityid != null])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != 'All Legal Entity') {
            let temp = localStorage.getItem("SelectedEntity")
            let tempcountry = _.filter(sessionArr, { le_name: temp })
            setUnitData({
                ...unitdata,
                legal_entity_id: localStorage.getItem('SelectedEntityid'),
                country: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_id : '',
                countryName: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_name : ''
            })
        }
    }, [localStorage.getItem('SelectedEntity') != 'All Legal Entity'])

    useEffect(() => {

        if (Reassign_history_show && Reassign_history_show.reassigned_history_list && Reassign_history_show.reassigned_history_list.length > 0) {

            var array = Reassign_history_show.reassigned_history_list.slice((current - 1) * pageSize, (current * pageSize))

            setPaginationArray(array)

        }

    }, [Reassign_history_show.reassigned_history_list, pageSize, current])
    useEffect(() => {
        if (pageState == true) {
            ReassignedHistoryReport({
                payload: [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetReassignedHistoryReport",
                            {
                                "c_id": Number(unitdata.country),
                                "le_id": entityid,
                                "division_id": Number(unitdata.division),
                                "category_id": Number(unitdata.category),
                                "unit_id": Number(unitdata.unit),
                                "d_id": Number(unitdata.domain),
                                "parent_id": Number(unitdata.act),
                                "compliance_task": unitdata.compliance_task,
                                // "usr_id": Number(unitdata.user),
                                "usr_id": null,
                                "from_date": moment(new Date(unitdata.from_date)).format('DD-MMM-YYYY'),
                                "to_date": moment(new Date(unitdata.to_date)).format('DD-MMM-YYYY'),
                                "csv": false,
                                "f_count": Math.max(((current - 1) * pageSize), 0) + 1,
                                "t_count": current != 0 ? current * pageSize : pageSize,
                                "count_qry": true
                            }
                        ]
                    }
                ],
                paramid: paramid
            })
        }
    }, [current, pageSize, pageState])

    useEffect(() => {
        if (reassignreports) {
            setAct(reassignreports && reassignreports.acts)
            setCategoryList(reassignreports && reassignreports.categories_list)
            setUsers(reassignreports && reassignreports.legal_entity_users)
            setDivision(reassignreports && reassignreports.divisions_list)
            setDomain(reassignreports && reassignreports.domains)

        }
    }, [reassignreports])

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    // useEffect(() => {
    //     if (Reassign_history_show && Reassign_history_show.reassigned_history_list && Reassign_history_show.reassigned_history_list.length > 0) {
    //         let uniqueObjArray = [...new Map(Reassign_history_show && Reassign_history_show.reassigned_history_list && Reassign_history_show.reassigned_history_list.length && userwisefilter.user_compliances.map((item) => [item["unit_id"], item])).values()];
    //         const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
    //             return item.unit_id
    //         })

    //         let tempArr = []
    //         for (let i in uniqueIds) {
    //             let filteredArr = _.filter(userwisefilter && userwisefilter.user_compliances && userwisefilter.user_compliances.length && userwisefilter.user_compliances, { unit_id: uniqueIds[i] })
    //             tempArr.push(filteredArr)
    //         }
    //         setFilteredArray([...filteredArray, tempArr])
    //     }

    // }, [userwisefilter && userwisefilter.user_compliances])

    // useEffect(() => {
    //     if (filteredArray.length) {
    //         // let temp = []
    //         filteredArray && filteredArray.map((item, i) => {
    //             return (
    //                 setfilteredArrayState(item)
    //             )
    //         })
    //     }
    // }, [filteredArray])

    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };

    useEffect(() => {
        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (paginationarray && paginationarray.length) {
            const ab = paginationarray && paginationarray.length && paginationarray.map(function (item) {
                if ((array.indexOf(item.act_name) < 0))
                    array.push(item.act_name)
            })
            array.map((data, i) => {
                let temp = [];
                let entityArray = [];
                let datavalue = [];
                if (data) {
                    paginationarray && paginationarray.length && paginationarray.map((child, childIndex) => {
                        if (data === child.act_name) {
                            entityArray = {
                                "ac_id": child.ac_id,
                                "act_name": child.act_name,
                                "assigned_on": child.assigned_on,
                                "c_opt_status": child.c_opt_status,
                                "category_name": child.category_name,
                                "compliance_description": child.compliance_description,
                                "compliance_id": child.compliance_id,
                                "compliance_task": child.compliance_task,
                                "country_id": child.country_id,
                                "division_name": child.division_name,
                                "domain_id": child.domain_id,
                                "due_date": child.due_date,
                                "extend_due_date": child.extend_due_date,
                                "legal_entity_id": child.legal_entity_id,
                                "new_user": child.new_user,
                                "o_date": child.o_date,
                                "old_user": child.old_user,
                                "remarks": child.remarks,
                                "tz_name": child.tz_name,
                                "unit_address": child.unit_address,
                                "unit_id": child.unit_id,
                                "unit": child.unit,
                            }
                            temp.push(entityArray)
                        }
                    })
                }

                datavalue = {
                    index: i,
                    parent: data,
                    child: temp
                }
                finalarray.push(datavalue)
            })
            Settitledata(finalarray)

        }

    }, [paginationarray])

    useEffect(() => {
        if (unitdata.legal_entity_name == localStorage.getItem('SelectedEntity')) {
            Setlegalstate(true)
        }
    }, [unitdata.legal_entity_name])

    const onshow = () => {
       
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            Settitledata([])
            setfilteredArrayState([])
            setExitCollapse(true);
            setFilterModalVisible(false);
            setFilterTaskAcc(false);
            setTableShow(true)
            setKey(0)
            setcount(0);
            setCurrent(1);
            setpageState(false)
            setPageSize(25)
            setUnitData({
                ...unitdata,
                legalEntityLabel: unitdata.legal_entity_name,
                countryLabel: unitdata.countryName,
                domainLabel: unitdata.domain_name
            })
            ReassignedHistoryReport({
                payload: [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetReassignedHistoryReport",
                            {
                                "c_id": Number(unitdata.country),
                                "le_id": entityid,
                                "division_id": Number(unitdata.division),
                                "category_id": Number(unitdata.category),
                                "unit_id": Number(unitdata.unit),
                                "d_id": Number(unitdata.domain),
                                "parent_id": Number(unitdata.act),
                                "compliance_task": unitdata.compliance_task,
                                // "usr_id": Number(unitdata.user),
                                "usr_id": Number(unitdata.user) ? Number(unitdata.user) : null,
                                "from_date": moment(new Date(unitdata.from_date)).format('DD-MMM-YYYY'),
                                "to_date": moment(new Date(unitdata.to_date)).format('DD-MMM-YYYY'),
                                "csv": false,
                                "f_count": 1,
                                "t_count": 25,
                                "count_qry": true
                            }
                        ]
                    }
                ],
                paramid: paramid
            })
        }
    }

    useEffect(() => {
        if (pageState === true) {
            setcount(pageSize * (current - 1))
        }
    }, [pageState, pageSize, current])

    const disablecondition = (e) => {
        var currentToDate = moment(unitdata.from_date).add(3, 'months')
        if (currentToDate < moment(e)) {
            setDisableButton(true)
        } else {
            setDisableButton(false)
        }
    }

    const onChange = (key) => {
        // console.log(key);
    };

    const homescreen = () => {
        let getUrlDetails = homepage;
        let tempval = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_url;
        let tempkey = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_key;
        localStorage.setItem('formkey', tempkey);
        historynew.push(tempval);
        // window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }

    const handlePasting = (e, regex) => {

        var regex = regex;
        var key = e.clipboardData.getData('text')
        if (!regex.test(key)) {
            e.preventDefault();
            Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
            return false;
        }
        // e.clipboardData.getData('text')
        // Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
        // e.preventDefault();
        // return false;
    }

    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };
    const [fullscreen, setfullscreen] = useState(false);

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }
    const handleCancel = () => {
        setIsModalOpen(false);
        setIsModalVisible(false)
    };
    const chilData = (assignedon, user) => {
        console.log(assignedon, user, "useruseruser");
        setIsModalVisible(true)
        if (Reassign_history_show && Reassign_history_show.reassigned_history_list && Reassign_history_show.reassigned_history_list.length > 0) {
            let tempArr = []
            for (let i in Reassign_history_show && Reassign_history_show.reassigned_history_list && Reassign_history_show.reassigned_history_list.length && Reassign_history_show.reassigned_history_list) {
                if (Reassign_history_show.reassigned_history_list && Reassign_history_show.reassigned_history_list.length && Reassign_history_show.reassigned_history_list[i].assigned_on == assignedon) {
                    if (Reassign_history_show.reassigned_history_list && Reassign_history_show.reassigned_history_list.length && Reassign_history_show.reassigned_history_list[i].old_user == user) {
                        tempArr.push(Reassign_history_show.reassigned_history_list && Reassign_history_show.reassigned_history_list.length && Reassign_history_show.reassigned_history_list[i])
                    }
                }
            }
            setChildComplinaceId(tempArr)

        }
    }

    const columns = [
        {
            title: 'Assigned Date',
            ellipsis: true,
            // width: '30px',
            dataIndex: 'assigned_on',
            key: 'assigned_on',
            align: 'center'
        },
        {
            title: 'Assigned',
            ellipsis: true,
            // width: '30px',
            dataIndex: 'old_user',
            key: 'old_user',
            align: 'center'

        },


    ];

    const unitfilter = (did) => {
        if (did != undefined) {
            let domainArray = [];
            let tempArray = [];
            reassignreports.units && reassignreports.units.length > 0 && reassignreports.units.map((unitsublist) => {
                console.log(unitdata.domain, 'unitdata.domain')
                const checkUnitExists = unitsublist.d_ids.includes(parseInt(did));
                if (checkUnitExists) {
                    tempArray = {
                        'unit_id': unitsublist.unit_id,
                        'unit_name': unitsublist.unit_name,
                        'unit_code': unitsublist.unit_code
                    }
                    domainArray.push(tempArray)
                }
            })
            setUnit(domainArray)
            let act = _.filter(reassignreports.acts, { d_id: Number(did) });
            setAct(act)
        } else {
            setUnit([])
            setAct([])
        }

    }

    const getDivisionBasedRecords = (divisionId) => {
        let domainArray = [];
        let tempArray = [];
        if (divisionId != undefined) {
            reassignreports.units && reassignreports.units.length > 0 && reassignreports.units.map((unitsublist) => {
                console.log(unitdata.domain, 'unitdata.domain')
                const checkUnitExists = unitsublist.d_ids.includes(parseInt(unitdata.domain));
                if (checkUnitExists && unitsublist.division_id == divisionId) {
                    tempArray = {
                        'unit_id': unitsublist.unit_id,
                        'unit_name': unitsublist.unit_name,
                        'unit_code': unitsublist.unit_code
                    }
                    domainArray.push(tempArray)
                }
            })
            setUnit(domainArray)
            let category = _.filter(reassignreports.categories_list, { div_id: Number(divisionId) })
            setCategoryList(category)
        } else {
            reassignreports.units && reassignreports.units.length > 0 && reassignreports.units.map((unitsublist) => {
                console.log(unitdata.domain, 'unitdata.domain')
                const checkUnitExists = unitsublist.d_ids.includes(parseInt(unitdata.domain));
                if (checkUnitExists) {
                    tempArray = {
                        'unit_id': unitsublist.unit_id,
                        'unit_name': unitsublist.unit_name,
                        'unit_code': unitsublist.unit_code
                    }
                    domainArray.push(tempArray)
                }
            })
            setUnit(domainArray)
            setCategoryList(reassignreports.categories_list && reassignreports.categories_list.length > 0 ? reassignreports.categories_list : '')
        }

    }

    const getCategoryBasedRecords = (categoryId) => {
        let domainArray = [];
        let tempArray = [];
        if (categoryId != undefined) {
            reassignreports.units && reassignreports.units.length > 0 && reassignreports.units.map((unitsublist) => {
                console.log(unitdata.domain, 'unitdata.domain')
                const checkUnitExists = unitsublist.d_ids.includes(parseInt(unitdata.domain));
                if (checkUnitExists && unitsublist.category_id == parseInt(categoryId)) {
                    tempArray = {
                        'unit_id': unitsublist.unit_id,
                        'unit_name': unitsublist.unit_name,
                        'unit_code': unitsublist.unit_code
                    }
                    domainArray.push(tempArray)
                }
            })
            setUnit(domainArray)
        } else {
            reassignreports.units && reassignreports.units.length > 0 && reassignreports.units.map((unitsublist) => {
                console.log(unitdata.domain, 'unitdata.domain')
                const checkUnitExists = unitsublist.d_ids.includes(parseInt(unitdata.domain));
                if (checkUnitExists) {
                    tempArray = {
                        'unit_id': unitsublist.unit_id,
                        'unit_name': unitsublist.unit_name,
                        'unit_code': unitsublist.unit_code
                    }
                    domainArray.push(tempArray)
                }
            })
            setUnit(domainArray)
        }
    }

    return (
        <div id='page-wrapper' className="page-wrapper">
            {tableshow == true ?
                <div className='back-to-top'>
                    <ScrollButton />
                </div> : ''}
            <div className="page-titles pb-2 pt-1 page-title-sticky">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <span style={{ fontSize: '14px', fontWeight: "bold" }}>Report</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                    <span style={{ fontSize: "15px", fontWeight: "bold" }}>Compliance Reports</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                    <span>Reassign Report</span>
                                </li>
                            </ol>
                        </nav>

                    </div>
                    <div className="col-lg-6 text-end">
                        <Tooltip title={'Advance Filter'}><Button
                            className="mx-1"
                            type="primary"
                            shape="round"
                            style={{
                                display: filterTaskAcc ? "none" : "initial",
                            }}
                            onClick={filterHandle}
                        >
                            <FilterOutlined /> Filter
                        </Button></Tooltip>
                        <div className='comparative'>
                            <button style={{ float: 'right' }} onClick={(e) => { fullscreenMode() }} className="bg-transparent check text-black ms-2 full-mode fullscreenradius" >
                                <Tooltip placement="left" title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Modal title="Reassign Report  Details" open={isModalVisible} footer={null} className={"add-service-prv modal-service-priv " + localStorage.getItem("currentTheme")}
                onCancel={handleCancel} onOk={() => {
                    setIsModalVisible(false)
                }} maskClosable={false}>
                <Table
                    className='userprivclass'
                    columns={columns}
                    dataSource={childCompliceId}
                    bordered
                    pagination={false} />

            </Modal>
            <div className="container-fluid pt-0">
                <div className="row">
                    <div className="col-lg-12">
                        <div className={localStorage.getItem("currentTheme") + ' card-body1-statuatory'} style={{ position: 'relative', padding: "10px 3px" }}>
                            <Collapse className='report-collapse' style={{ display: exitCollapse ? "none" : "block" }}
                                defaultActiveKey={["1"]} onChange={onChange} >
                                <Panel header="Reassign Report" key={key}>
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Country:</b> <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select allowClear={true}
                                                                placeholder='Enter Country'
                                                                size="default"
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                // onPaste={(e) => {
                                                                //     handlePasting(e, /^[a-z]+$/gi)
                                                                // }}
                                                                onPaste={onPasteToaster}
                                                                searchValue={selectSearchValue.country}
                                                                onSearch={(value) => {
                                                                    let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                    setSelectSearchValue({
                                                                        ...selectSearchValue,
                                                                        country: result
                                                                    });
                                                                }}
                                                                onChange={(value, data) => {
                                                                    if (value !== undefined) {
                                                                        let le = _.filter(sessionArr, { c_id: Number(value) })
                                                                        setEntity(le)
                                                                    }
                                                                    setUnitData({
                                                                        ...unitdata,
                                                                        country: value ? value : '',
                                                                        countryName: data ? data.children : '',
                                                                        legal_entity_id: "",
                                                                        legal_entity_name: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
                                                                        domain: 0,
                                                                        domain_name: '',
                                                                        user: null,
                                                                        user_name: '',
                                                                        act: 0,
                                                                        act_name: '',
                                                                        from_date: "",
                                                                        division: 0,
                                                                        division_name: '',
                                                                        compliance_task: null,
                                                                        to_date: "",
                                                                        category: 0,
                                                                        category_name: '',
                                                                        unit: 0,
                                                                        unit_name: '',
                                                                    })
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                //value={Settings.le_id}
                                                                style={{ width: '100%', marginTop: '2px' }}
                                                                value={unitdata.countryName || undefined}
                                                            >
                                                                {uniq_country_data && uniq_country_data.length > 0 && uniq_country_data.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.c_id}>
                                                                            {item.c_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'country',
                                                                unitdata.countryName,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Country Required',
                                                                    }
                                                                })}
                                                        </div> :
                                                        <p>{unitdata.countryName}</p>
                                                    }
                                                </div>
                                            </div>

                                            <div className='col-md-4 text-wrap-report'>
                                                <label><b>Legal Entity: </b><span style={{ color: "red" }}>*</span></label>
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Legal Entity"
                                                            disabled={unitdata.countryName === '' ? true : false}
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            // onPaste={(e) => {
                                                            //     handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                            // }}
                                                            onPaste={onPasteToaster}
                                                            searchValue={selectSearchValue.legalEntity}
                                                            onSearch={(value) => {
                                                                let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                setSelectSearchValue({
                                                                    ...selectSearchValue,
                                                                    legalEntity: result
                                                                });
                                                            }}
                                                            onChange={(data, value) => {
                                                                Setlegalstate(true)
                                                                data && setCurrentEntity(data)
                                                                setUnitData({
                                                                    ...unitdata,
                                                                    legal_entity_id: data ? data : '',
                                                                    legal_entity_name: value ? value.children : '',
                                                                    domain: 0,
                                                                    domain_name: '',
                                                                    user: null,
                                                                    user_name: '',
                                                                    act: 0,
                                                                    act_name: '',
                                                                    from_date: "",
                                                                    division: 0,
                                                                    division_name: '',
                                                                    compliance_task: null,
                                                                    to_date: "",
                                                                    category: 0,
                                                                    category_name: '',
                                                                    unit: 0,
                                                                    unit_name: '',
                                                                })
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%', marginTop: '2px' }}
                                                            value={unitdata.legal_entity_name || undefined}
                                                        >
                                                            {entity && entity.length > 0 && entity.map((item, i) => {
                                                                return (
                                                                    <Option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'legal_entity_id',
                                                            unitdata.legal_entity_name,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Required',
                                                                }
                                                            })}
                                                    </div> :
                                                    <p>{localStorage.getItem('SelectedEntity')}</p>
                                                }
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Domain:</b><span style={{ color: "red" }}>*</span></label>
                                                <Select allowClear={true}
                                                    value={unitdata.domain_name || undefined}
                                                    disabled={unitdata.legal_entity_id === '' ? true : false}
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z - .]+$/gi)
                                                    // }}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.domain}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            domain: result
                                                        });
                                                    }}
                                                    onChange={(value, data) => {

                                                        setUnitData({
                                                            ...unitdata,
                                                            domain: value ? value : '',
                                                            domain_name: data ? data.children : '',
                                                            user: null,
                                                            user_name: '',
                                                            act: 0,
                                                            act_name: '',
                                                            from_date: "",
                                                            division: 0,
                                                            division_name: '',
                                                            compliance_task: null,
                                                            to_date: "",
                                                            category: 0,
                                                            category_name: '',
                                                            unit: 0,
                                                            unit_name: '',
                                                        })
                                                        unitfilter(value)
                                                    }}
                                                    placeholder="Enter Domain"

                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    {domain && domain.length > 0 && domain.map((item, i) => {
                                                        return (
                                                            <Option key={item.d_id}>
                                                                {item.d_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                                {validator.current.message(
                                                    'domain_name',
                                                    unitdata.domain_name,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Domain Required',
                                                        }
                                                    })}
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "5px" }}>
                                        <div className='row'>

                                            <div className='col-md-4'>
                                                <label><b>Unit:</b> <span style={{ color: "red" }}>*</span></label>
                                                <Select allowClear={true}
                                                    value={unitdata.unit_name || undefined}
                                                    disabled={unitdata.domain == 0 ? true : false}
                                                    // onClick={() => {
                                                    //     if (unitdata.domain == 0) {
                                                    //         toast.warning("Domain Required")
                                                    //     }
                                                    // }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                                    // }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.unit}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            unit: result
                                                        });
                                                    }}
                                                    onChange={(value, data) => {
                                                        // let user = _.filter(unitreport.compliance_users, { unit_id: Number(value) })
                                                        // setUsers(user)
                                                        setUnitData({
                                                            ...unitdata,
                                                            unit: value ? value : '',
                                                            unit_name: data ? data.children : '',
                                                            user: null,
                                                            user_name: '',
                                                            act: 0,
                                                            act_name: '',
                                                            from_date: "",
                                                            compliance_task: null,
                                                            to_date: "",
                                                        })
                                                    }}
                                                    placeholder="Enter Unit"
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    {unit && unit.length > 0 && unit.map((item, i) => {
                                                        console.log(item, 'itemss')
                                                        return (
                                                            <Option key={item.unit_id}>
                                                                {item.unit_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                                {validator.current.message(
                                                    'unit_name',
                                                    unitdata.unit_name,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Unit Required ',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>From Date:</b> <span style={{ color: "red" }} value={unitdata.from_date ? moment(unitdata.from_date) : ''}>*</span></label>
                                                <DatePicker allowClear={true} format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '2px' }}
                                                    value={unitdata.from_date ? moment(unitdata.from_date) : ''}
                                                    onKeyDown={(event) => {
                                                        event.preventDefault()
                                                    }}
                                                    onChange={(date, dateString) => {
                                                        if (dateString == '') {
                                                            setUnitData({
                                                                ...unitdata,
                                                                from_date: '',
                                                                to_date: ''
                                                            })
                                                        } else {
                                                            let from_date = moment(dateString).add(+3, 'months')
                                                            setUnitData({
                                                                ...unitdata,
                                                                from_date: dateString,
                                                                to_date: moment(from_date).subtract(1, 'days')
                                                            })
                                                        }
                                                    }}
                                                />

                                                {validator.current.message(
                                                    'from_date',
                                                    unitdata.from_date,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'From Date Required',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>To Date:</b> <span style={{ color: "red" }}>*</span></label>
                                                <DatePicker allowClear={true} format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '2px' }}
                                                    value={unitdata.to_date ? moment(unitdata.to_date) : ''}
                                                    onKeyDown={(event) => {
                                                        event.preventDefault()
                                                    }}
                                                    onChange={(date, dateString) => {
                                                        disablecondition(dateString)
                                                        setUnitData({
                                                            ...unitdata,
                                                            to_date: dateString._i
                                                        })
                                                    }}
                                                    disabledDate={(current) => {
                                                        return moment(unitdata.from_date).add(1, 'days') >= current ||
                                                            moment(unitdata.from_date).add(1, 'year') < current;
                                                    }}
                                                />

                                                {validator.current.message(
                                                    'to_date',
                                                    unitdata.to_date,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'To Date Required',
                                                        }
                                                    })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "5px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                {/* <div className='col-md-4'> */}
                                                <label><b>Division:</b></label>
                                                <Select allowClear={true}
                                                    value={unitdata.division_name || undefined}
                                                    disabled={unitdata.domain == 0 ? true : false}
                                                    // onClick={() => {
                                                    //     if (unitdata.domain == 0) {
                                                    //         toast.warning("Domain Required")
                                                    //     }
                                                    // }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z 0-9]+$/gi)
                                                    // }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.division}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            division: result
                                                        });
                                                    }}
                                                    onChange={(value, data) => {
                                                        getDivisionBasedRecords(value)
                                                        setUnitData({
                                                            ...unitdata,
                                                            division: value ? value : '',
                                                            division_name: data ? data.children : '',
                                                            category: '',
                                                            category_name: '',
                                                            unit: '',
                                                            unit_name: '',

                                                        })
                                                    }}
                                                    placeholder="Enter Division"
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    {division && division.length > 0 && division.map((item, i) => {
                                                        return (
                                                            <Option key={item.div_id}>
                                                                {item.div_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Category:</b></label>
                                                <Select allowClear={true}
                                                    disabled={unitdata.domain == 0 ? true : false}
                                                    value={unitdata.category_name || undefined}

                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z 0-9]+$/gi)
                                                    // }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.category}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            category: result
                                                        });
                                                    }}
                                                    onChange={(value, data) => {
                                                        getCategoryBasedRecords(value)
                                                        setUnitData({
                                                            ...unitdata,
                                                            category: value ? value : '',
                                                            category_name: data ? data.children : '',
                                                            unit: '',
                                                            unit_name: ''
                                                        })
                                                    }}
                                                    placeholder="Enter Category"
                                                    style={{ width: '100%', marginTop: '2px' }}
                                                >
                                                    {categoryList && categoryList.length > 0 && categoryList.map((item, i) => {
                                                        return (
                                                            <Option key={item.cat_id}>
                                                                {item.cat_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>User:</b> </label>
                                                <Select allowClear={true}
                                                    value={unitdata.user_name || undefined}
                                                    disabled={unitdata.domain == 0 ? true : (unitdata.unit == 0 ? true : false)}
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                                    // }}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.user}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            user: result
                                                        });
                                                    }}
                                                    onChange={(value, data) => {

                                                        setUnitData({
                                                            ...unitdata,
                                                            user: value ? value : '',
                                                            user_name: data ? data.children : ''
                                                        })
                                                    }}
                                                    placeholder="Enter User"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    style={{ width: '100%', marginTop: '2px' }}
                                                >
                                                    <Option key='Administartor'>Administrator</Option>
                                                    {users && users.length > 0 && users.map((item, i) => {
                                                        return (
                                                            <Option key={item.employee_code}>
                                                                {item.employee_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>


                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "5px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Act:</b></label>
                                                <Select allowClear={true}
                                                    value={unitdata.act_name || undefined}
                                                    disabled={unitdata.domain == 0 ? true : (unitdata.unit == 0 ? true : false)}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z 0-9 - . ()]+$/gi)
                                                    // }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.actRules}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            actRules: result
                                                        });
                                                    }}
                                                    onChange={(value, data) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            act: value ? value : '',
                                                            act_name: data ? data.children : ''
                                                        })
                                                    }}
                                                    placeholder="Enter Act"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    {act && act.length > 0 && act.map((item, i) => {
                                                        return (
                                                            <Option key={item.parent_id}>
                                                                {item.act}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>

                                            <div className='col-md-4'>
                                                <label><b>Compliance Task:</b></label>
                                                <input allowClear={true} placeholder="Enter Compliance Task"
                                                    className='ant-input'
                                                    value={unitdata.compliance_task}
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                    onChange={ComplianceTaskName}

                                                />
                                            </div>
                                            <div className='col-md-4'>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-center mt-2 popupbtncolour'>
                                        <Button disabled={disableButton} type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                            icon={<EyeOutlined />} onClick={onshow}
                                        >
                                            Show
                                        </Button>
                                    </div>
                                </Panel>
                            </Collapse>
                            <Modal
                                onCancel={setcancelFilter}
                                footer={false}
                                title="Reassign Report"
                                className={
                                    "add-service-prv cons-report " +
                                    localStorage.getItem("currentTheme")
                                }
                                open={filterModalVisible}
                                maskClosable={false}
                            >
                                <div className='col-md-12'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <label><b>Country:</b> <span style={{ color: "red" }}>*</span></label>
                                            <div className="form-group">
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <div className="form-group">
                                                        <Select allowClear={true}
                                                            getPopupContainer={trigger => trigger.parentNode}
                                                            placeholder='Enter Country'
                                                            size="default"
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            // onPaste={(e) => {
                                                            //     handlePasting(e, /^[a-z]+$/gi)
                                                            // }}
                                                            onPaste={onPasteToaster}
                                                            searchValue={selectSearchValue.country}
                                                            onSearch={(value) => {
                                                                let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                setSelectSearchValue({
                                                                    ...selectSearchValue,
                                                                    country: result
                                                                });
                                                            }}
                                                            onChange={(value, data) => {
                                                                if (value !== undefined) {
                                                                    let le = _.filter(sessionArr, { c_id: Number(value) })
                                                                    setEntity(le)
                                                                }
                                                                setUnitData({
                                                                    ...unitdata,
                                                                    country: value ? value : '',
                                                                    countryName: data ? data.children : '',
                                                                    legal_entity_id: "",
                                                                    legal_entity_name: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
                                                                    domain: 0,
                                                                    domain_name: '',
                                                                    user: null,
                                                                    user_name: '',
                                                                    act: 0,
                                                                    act_name: '',
                                                                    from_date: "",
                                                                    division: 0,
                                                                    division_name: '',
                                                                    compliance_task: null,
                                                                    to_date: "",
                                                                    category: 0,
                                                                    category_name: '',
                                                                    unit: 0,
                                                                    unit_name: '',
                                                                })
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            //value={Settings.le_id}
                                                            style={{ width: '100%', marginTop: '2px' }}
                                                            value={unitdata.countryName || undefined}
                                                        >
                                                            {uniq_country_data && uniq_country_data.length > 0 && uniq_country_data.map((item, i) => {
                                                                return (
                                                                    <Option key={item.c_id}>
                                                                        {item.c_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'country',
                                                            unitdata.countryName,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Country Required',
                                                                }
                                                            })}
                                                    </div> :
                                                    <p>{unitdata.countryName}</p>
                                                }
                                            </div>
                                        </div>
                                        <div className='col-md-4 text-wrap-report'>
                                            <label><b>Legal Entity: </b><span style={{ color: "red" }}>*</span></label>
                                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                <div className="form-group">
                                                    <Select
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Legal Entity"
                                                        disabled={unitdata.countryName === '' ? true : false}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                        // }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.legalEntity}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                legalEntity: result
                                                            });
                                                        }}
                                                        onChange={(data, value) => {
                                                            Setlegalstate(true)
                                                            data && setCurrentEntity(data)
                                                            setUnitData({
                                                                ...unitdata,
                                                                legal_entity_id: data ? data : '',
                                                                legal_entity_name: value ? value.children : '',
                                                                domain: 0,
                                                                domain_name: '',
                                                                user: null,
                                                                user_name: '',
                                                                act: 0,
                                                                act_name: '',
                                                                from_date: "",
                                                                division: 0,
                                                                division_name: '',
                                                                compliance_task: null,
                                                                to_date: "",
                                                                category: 0,
                                                                category_name: '',
                                                                unit: 0,
                                                                unit_name: '',
                                                            })
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%', marginTop: '2px' }}
                                                        value={unitdata.legal_entity_name || undefined}
                                                    >
                                                        {entity && entity.length > 0 && entity.map((item, i) => {
                                                            return (
                                                                <Option key={item.le_id}>
                                                                    {item.le_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    {validator.current.message(
                                                        'legal_entity_id',
                                                        unitdata.legal_entity_name,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Legal Entity Required',
                                                            }
                                                        })}
                                                </div> :
                                                <p>{localStorage.getItem('SelectedEntity')}</p>
                                            }
                                        </div>
                                        <div className='col-md-4'>
                                            <label><b>Domain:</b><span style={{ color: "red" }}>*</span></label>
                                            <Select allowClear={true}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                value={unitdata.domain_name || undefined}
                                                disabled={unitdata.legal_entity_id === '' ? true : false}
                                                // onPaste={(e) => {
                                                //     handlePasting(e, /^[a-z - .]+$/gi)
                                                // }}
                                                onDrop={(e) => {
                                                    e.preventDefault();
                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                    return false;
                                                }}
                                                onPaste={onPasteToaster}
                                                searchValue={selectSearchValue.domain}
                                                onSearch={(value) => {
                                                    let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                    setSelectSearchValue({
                                                        ...selectSearchValue,
                                                        domain: result
                                                    });
                                                }}
                                                onChange={(value, data) => {
                                                    setUnitData({
                                                        ...unitdata,
                                                        domain: value ? value : '',
                                                        domain_name: data ? data.children : '',
                                                        user: null,
                                                        user_name: '',
                                                        act: 0,
                                                        act_name: '',
                                                        from_date: "",
                                                        division: 0,
                                                        division_name: '',
                                                        compliance_task: null,
                                                        to_date: "",
                                                        category: 0,
                                                        category_name: '',
                                                        unit: 0,
                                                        unit_name: '',
                                                    })
                                                    unitfilter(value)
                                                }}
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                placeholder="Enter Domain"

                                                style={{ width: '100%', marginTop: '2px' }}

                                            >
                                                {domain && domain.length > 0 && domain.map((item, i) => {
                                                    return (
                                                        <Option key={item.d_id}>
                                                            {item.d_name}
                                                        </Option>
                                                    )
                                                })}
                                            </Select>
                                            {validator.current.message(
                                                'domain_name',
                                                unitdata.domain_name,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Domain Required',
                                                    }
                                                })}
                                        </div>

                                    </div>
                                </div>
                                <div className='col-md-12' style={{ marginTop: "5px" }}>
                                    <div className='row'>

                                        <div className='col-md-4'>
                                            <label><b>Unit:</b> <span style={{ color: "red" }}>*</span></label>
                                            <Select allowClear={true}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                value={unitdata.unit_name || undefined}
                                                disabled={unitdata.domain == 0 ? true : false}
                                                // onClick={() => {
                                                //     if (unitdata.domain == 0) {
                                                //         toast.warning("Domain Required")
                                                //     }
                                                // }}
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                onDrop={(e) => {
                                                    e.preventDefault();
                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                    return false;
                                                }}
                                                // onPaste={(e) => {
                                                //     handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                                // }}
                                                onPaste={onPasteToaster}
                                                searchValue={selectSearchValue.unit}
                                                onSearch={(value) => {
                                                    let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                    setSelectSearchValue({
                                                        ...selectSearchValue,
                                                        unit: result
                                                    });
                                                }}
                                                onChange={(value, data) => {
                                                    // let user = _.filter(unitreport.compliance_users, { unit_id: Number(value) })
                                                    // setUsers(user)
                                                    setUnitData({
                                                        ...unitdata,
                                                        unit: value ? value : '',
                                                        unit_name: data ? data.children : '',
                                                        user: null,
                                                        user_name: '',
                                                        act: 0,
                                                        act_name: '',
                                                        from_date: "",
                                                        compliance_task: null,
                                                        to_date: "",
                                                    })
                                                }}
                                                placeholder="Enter Unit"
                                                style={{ width: '100%', marginTop: '2px' }}

                                            >
                                                {unit && unit.length > 0 && unit.map((item, i) => {
                                                    return (
                                                        <Option key={item.unit_id}>
                                                            {item.unit_name}
                                                        </Option>
                                                    )
                                                })}
                                            </Select>
                                            {validator.current.message(
                                                'unit_name',
                                                unitdata.unit_name,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Unit Required ',
                                                    }
                                                })}
                                        </div>
                                        <div className='col-md-4'>
                                            <label><b>From Date:</b> <span style={{ color: "red" }} value={unitdata.from_date ? moment(unitdata.from_date) : ''}>*</span></label>
                                            <DatePicker allowClear={true} format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '2px' }}
                                                value={unitdata.from_date ? moment(unitdata.from_date) : ''}
                                                onKeyDown={(event) => {
                                                    event.preventDefault()
                                                }}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                onChange={(date, dateString) => {
                                                    if (dateString == '') {
                                                        setUnitData({
                                                            ...unitdata,
                                                            from_date: '',
                                                            to_date: ''
                                                        })
                                                    } else {
                                                        let from_date = moment(dateString).add(+3, 'months')
                                                        setUnitData({
                                                            ...unitdata,
                                                            from_date: dateString,
                                                            to_date: moment(from_date).subtract(1, 'days')
                                                        })
                                                    }
                                                }}
                                            />

                                            {validator.current.message(
                                                'from_date',
                                                unitdata.from_date,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'From Date Required',
                                                    }
                                                })}
                                        </div>
                                        <div className='col-md-4'>
                                            <label><b>To Date:</b> <span style={{ color: "red" }}>*</span></label>
                                            <DatePicker allowClear={true} format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '2px' }}
                                                value={unitdata.to_date ? moment(unitdata.to_date) : ''}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                onKeyDown={(event) => {
                                                    event.preventDefault()
                                                }}
                                                onChange={(date, dateString) => {
                                                    disablecondition(dateString)
                                                    setUnitData({
                                                        ...unitdata,
                                                        to_date: dateString ? dateString._i : ''
                                                    })
                                                }}
                                                disabledDate={(current) => {
                                                    return moment(unitdata.from_date).add(1, 'days') >= current ||
                                                        moment(unitdata.from_date).add(1, 'year') < current;
                                                }}
                                            />

                                            {validator.current.message(
                                                'to_date',
                                                unitdata.to_date,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'To Date Required',
                                                    }
                                                })}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12' style={{ marginTop: "5px" }}>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <label><b>Division:</b></label>
                                            <Select allowClear={true}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                value={unitdata.division_name || undefined}
                                                disabled={unitdata.domain == 0 ? true : false}
                                                // onClick={() => {
                                                //     if (unitdata.domain == 0) {
                                                //         toast.warning("Domain Required")
                                                //     }
                                                // }}
                                                onDrop={(e) => {
                                                    e.preventDefault();
                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                    return false;
                                                }}
                                                // onPaste={(e) => {
                                                //     handlePasting(e, /^[a-z 0-9]+$/gi)
                                                // }}
                                                onPaste={onPasteToaster}
                                                searchValue={selectSearchValue.division}
                                                onSearch={(value) => {
                                                    let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                    setSelectSearchValue({
                                                        ...selectSearchValue,
                                                        division: result
                                                    });
                                                }}
                                                onChange={(value, data) => {
                                                    getDivisionBasedRecords(value)
                                                    setUnitData({
                                                        ...unitdata,
                                                        division: value ? value : '',
                                                        division_name: data ? data.children : '',
                                                        category: '',
                                                        category_name: '',
                                                        unit: '',
                                                        unit_name: '',
                                                    })
                                                }}
                                                placeholder="Enter Division"
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                style={{ width: '100%', marginTop: '2px' }}

                                            >
                                                {division && division.length > 0 && division.map((item, i) => {
                                                    return (
                                                        <Option key={item.div_id}>
                                                            {item.div_name}
                                                        </Option>
                                                    )
                                                })}
                                            </Select>
                                        </div>
                                        <div className='col-md-4'>
                                            <label><b>Category:</b></label>
                                            <Select allowClear={true}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                disabled={unitdata.domain == 0 ? true : false}
                                                value={unitdata.category_name || undefined}
                                                onDrop={(e) => {
                                                    e.preventDefault();
                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                    return false;
                                                }}
                                                // onPaste={(e) => {
                                                //     handlePasting(e, /^[a-z 0-9]+$/gi)
                                                // }}
                                                onPaste={onPasteToaster}
                                                searchValue={selectSearchValue.category}
                                                onSearch={(value) => {
                                                    let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                    setSelectSearchValue({
                                                        ...selectSearchValue,
                                                        category: result
                                                    });
                                                }}
                                                onChange={(value, data) => {
                                                    getCategoryBasedRecords(value)
                                                    setUnitData({
                                                        ...unitdata,
                                                        category: value ? value : '',
                                                        category_name: data ? data.children : '',
                                                        unit: '',
                                                        unit_name: ''
                                                    })
                                                }}
                                                placeholder="Enter Category"
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                style={{ width: '100%', marginTop: '2px' }}
                                            >
                                                {categoryList && categoryList.length > 0 && categoryList.map((item, i) => {
                                                    return (
                                                        <Option key={item.cat_id}>
                                                            {item.cat_name}
                                                        </Option>
                                                    )
                                                })}
                                            </Select>
                                        </div>
                                        <div className='col-md-4'>
                                            <label><b>User:</b> </label>
                                            <Select allowClear={true}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                value={unitdata.user_name || undefined}
                                                disabled={unitdata.domain == 0 ? true : (unitdata.unit == 0 ? true : false)}
                                                // onPaste={(e) => {
                                                //     handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                                // }}
                                                onDrop={(e) => {
                                                    e.preventDefault();
                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                    return false;
                                                }}
                                                onPaste={onPasteToaster}
                                                searchValue={selectSearchValue.user}
                                                onSearch={(value) => {
                                                    let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                    setSelectSearchValue({
                                                        ...selectSearchValue,
                                                        user: result
                                                    });
                                                }}
                                                onChange={(value, data) => {

                                                    setUnitData({
                                                        ...unitdata,
                                                        user: value ? value : '',
                                                        user_name: data ? data.children : ''
                                                    })
                                                }}
                                                placeholder="Enter User"
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                style={{ width: '100%', marginTop: '2px' }}
                                            >
                                                <Option key='Administartor'>Administrator</Option>
                                                {users && users.length > 0 && users.map((item, i) => {
                                                    return (
                                                        <Option key={item.employee_code}>
                                                            {item.employee_name}
                                                        </Option>
                                                    )
                                                })}
                                            </Select>
                                        </div>

                                    </div>
                                </div>
                                <div className='col-md-12' style={{ marginTop: "5px" }}>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <label><b>Act:</b></label>
                                            <Select allowClear={true}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                value={unitdata.act_name || undefined}
                                                disabled={unitdata.domain == 0 ? true : (unitdata.unit == 0 ? true : false)}
                                                onDrop={(e) => {
                                                    e.preventDefault();
                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                    return false;
                                                }}
                                                // onPaste={(e) => {
                                                //     handlePasting(e, /^[a-z 0-9 - . ()]+$/gi)
                                                // }}
                                                onPaste={onPasteToaster}
                                                searchValue={selectSearchValue.actRules}
                                                onSearch={(value) => {
                                                    let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                    setSelectSearchValue({
                                                        ...selectSearchValue,
                                                        actRules: result
                                                    });
                                                }}
                                                onChange={(value, data) => {
                                                    setUnitData({
                                                        ...unitdata,
                                                        act: value ? value : '',
                                                        act_name: data ? data.children : ''
                                                    })
                                                }}
                                                placeholder="Enter Act"
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                style={{ width: '100%', marginTop: '2px' }}

                                            >
                                                {act && act.length > 0 && act.map((item, i) => {
                                                    return (
                                                        <Option key={item.parent_id}>
                                                            {item.act}
                                                        </Option>
                                                    )
                                                })}
                                            </Select>
                                        </div>

                                        <div className='col-md-4'>
                                            <label><b>Compliance Task:</b></label>
                                            <input allowClear={true} placeholder="Enter Compliance Task"
                                                className='ant-input'
                                                value={unitdata.compliance_task}
                                                style={{ width: '100%', marginTop: '2px' }}

                                                onChange={ComplianceTaskName}

                                            />
                                        </div>
                                        <div className='col-md-4'>
                                        </div>
                                    </div>
                                </div>
                                <div className='text-center mt-2 popupbtncolour'>
                                    <Button disabled={disableButton} type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}

                                        icon={<EyeOutlined />} onClick={onshow}
                                    >
                                        Show
                                    </Button>
                                </div>
                            </Modal>

                            {tableshow == true ?
                                <Card className='rounded-3'>

                                    <div className='col-md-12 mt-0 mb-2 filter-detial-sticky' >
                                        <div className='row'>
                                            <div className='col-md-4 text-wrap-report'>
                                                <label><b>Legal Entity :</b> {unitdata.legalEntityLabel}</label>
                                            </div>
                                            <div className='col-md-4 text-center'>
                                                <label><b>Country :</b> {unitdata.countryLabel}</label>
                                            </div>
                                            <div className='col-md-4 text-end'>
                                                <label><b>Domain :</b> {unitdata.domainLabel}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper'}>
                                        <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                            <div className='ant-table-container'>
                                                <div className='ant-table-content userprivclass1'>
                                                    <table>
                                                        <colgroup>
                                                            <col style={{ width: '10px' }}></col>
                                                        </colgroup>
                                                        <thead className="ant-table-thead table-head-stick" >
                                                            <tr>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '10px' }}> # </th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '300px' }} >Compliance Task</th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '200px' }}>Opted Status</th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '200px' }}>Updated on</th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '200px' }}><center>Last Due Date</center></th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '200px' }} >Assigned Date</th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '200px' }}><center>Assigned</center></th>
                                                                <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '200px' }}><center>Reason</center></th>
                                                            </tr>
                                                        </thead>
                                                        {titledata && titledata.length > 0 ?
                                                            titledata.map((itemdata, i) => {
                                                                return (<tbody className='ant-table-tbody' key={i}>
                                                                    <tr className="bg-lightdark">
                                                                        <td colSpan="100%">
                                                                            <div className='bg-white shadow-sm border-radius-reports'>
                                                                                <div className="col-md-12">
                                                                                    <div className='row m-2'>
                                                                                        <div className='col-md-3' >
                                                                                            <label><b>Unit :</b> {itemdata.child[0].unit}</label>
                                                                                        </div>
                                                                                        <div className='col-md-3' >
                                                                                            <label><b>Division :</b> {itemdata.child[0].division_name}</label>
                                                                                        </div>
                                                                                        <div className='col-md-3' >
                                                                                            <label><b>Category :</b> {itemdata.child[0].category_name}</label>
                                                                                        </div>
                                                                                        <div className='col-md-3' >
                                                                                            <label><b>Time Zone :</b> {itemdata.child[0].tz_name}</label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr className='ant-table-row ant-table-row-level-0'>
                                                                        <td colSpan={12} className='ant-table-cell'><b>{itemdata.parent}</b></td>
                                                                    </tr>
                                                                    {itemdata.child.map((items) => {
                                                                        console.log(items, 'itens')
                                                                        // if (item[0].unit_id == items.unit_id) {
                                                                        let array = []

                                                                        for (let i in Reassign_history_show && Reassign_history_show.reassigned_history_list && Reassign_history_show.reassigned_history_list.length && Reassign_history_show.reassigned_history_list) {
                                                                            if (Reassign_history_show.reassigned_history_list && Reassign_history_show.reassigned_history_list.length && Reassign_history_show.reassigned_history_list[i].compliance_id == items.compliance_id) {
                                                                                if (Reassign_history_show.reassigned_history_list && Reassign_history_show.reassigned_history_list.length && Reassign_history_show.reassigned_history_list[i].unit_id == items.unit_id) {
                                                                                    array.push(Reassign_history_show.reassigned_history_list && Reassign_history_show.reassigned_history_list.length && Reassign_history_show.reassigned_history_list[i])
                                                                                }
                                                                                console.log(array, "arrayarray");
                                                                            }
                                                                        }
                                                                        return <Fragment>

                                                                            <tr>
                                                                                <td className='ant-table-cell'><center>{count + 1}</center></td>
                                                                                <td title={items.compliance_task} className='ant-table-cell'>
                                                                                    <span className="d-flex justify-content-start align-items-center">
                                                                                        <Tooltip title={items.compliance_description}>
                                                                                            <span className='mx-1 text-primary'>
                                                                                                <i className="ri-information-fill" /></span>
                                                                                        </Tooltip> &nbsp;
                                                                                        {
                                                                                            // array.length == 1 ? <a>{items.compliance_task}</a> :
                                                                                            <a href='javascript:;' onClick={() => {
                                                                                                chilData(items.assigned_on, items.old_user)
                                                                                            }}>{items.compliance_task}</a>
                                                                                        }
                                                                                    </span>
                                                                                </td>
                                                                                <td className='ant-table-cell'>{items.c_opt_status}</td>
                                                                                <td className='ant-table-cell'>{items.o_date}</td>
                                                                                <td className='ant-table-cell'>
                                                                                    {items.extend_due_date != null ?
                                                                                        <span className="ms-1 d-flex justify-content-start align-items-center">{items.due_date}<Tooltip title={"The Due Date has been extended to " + items.extend_due_date}>
                                                                                            &nbsp;<i className='ri-information-fill' style={{ color: 'orange', fontSize: '17px' }} /></Tooltip></span> :
                                                                                        <span className="ms-1 d-flex justify-content-start align-items-center">
                                                                                            {items.due_date}
                                                                                        </span>
                                                                                    }

                                                                                </td>
                                                                                <td className='ant-table-cell'><center>

                                                                                    {items.assigned_on}</center></td>
                                                                                <td className='ant-table-cell'>
                                                                                    {items.new_user}</td>
                                                                                <td className='ant-table-cell'>{items.remarks}</td>
                                                                                <span hidden>{count = count + 1}</span>
                                                                            </tr>
                                                                        </Fragment>
                                                                        // }
                                                                    })
                                                                    }
                                                                </tbody>)
                                                            })
                                                            : <tr>
                                                                <td colSpan="100%" style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                            </tr>}
                                                    </table>
                                                    {/* <br /> */}
                                                    <div>
                                                        {/* <button
                                                    className="btn btn-light-success rounded-pill px-4 text-success"
                                                    onClick={submitrec}>Submit</button> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {count != 0 ?
                                        <div className='col-md-12 mt-2' style={{ position: 'absolute', bottom: '-25px', left: 0 }}>
                                            <label style={{ marginLeft: "28px" }}>Showing {pageSize * (current - 1) + 1} to {count} of {Reassign_history_show.total_count} entries</label>
                                        </div> : ''}
                                    <div
                                        // className="btn btn-light-success rounded-pill px-4 text-success"
                                        style={{ position: 'absolute', bottom: '-25px', right: 0 }}
                                    // onClick={submitrec}
                                    >

                                        <Pagination
                                            current={current}
                                            showSizeChanger={true}
                                            // showQuickJumper={true}
                                            onShowSizeChange={() => {
                                                setCurrent(1)
                                                // setpageState(true)
                                            }}
                                            pageSizeOptions={[25, 50, 100]}
                                            hideOnSinglePage={Reassign_history_show.total_count > 25 ? false : true} defaultPageSize={25}
                                            onChange={(page, pageSizes) => {
                                                setpageState(true)
                                                setCurrent(pageSize !== pageSizes ? 1 : page);
                                                setPageSize(pageSizes)
                                            }} total={Reassign_history_show.total_count}
                                        // showTotal={(total) => `Showing 1 to ${count} of ${Reassign_history_show.total_count} entries`}
                                        />


                                    </div>
                                </Card> : false}
                        </div>
                    </div>
                </div>
            </div >
        </div >

    )
}
export default connect(mapStateToProps, {
    ReassignedHistoryReportFilters,
    ReassignedHistoryReport
})(ReassignReports);