import { CERTIFICATE_COMPLIANCE_WISE_REPORT, DOWNLOAD_DOCUMENT_FILE, FILE_DOWNLOAD, PDF_RESET, CV_COMPLIANCE_CERTIFICATE_EXPORT_RESET, CV_COMPLIANCE_CERTIFICATE_EXPORT, CERTIFICATE_COMPLIANCE_WISE_REPORT_SHOW, CERTIFICATE_PDF, DOWNLOAD_FILE } from './../../types/index'
const initialState = {
    certificatecompliancereport: {
        certificatecompliancereportfilter: [],
        certificatecompliancereportfiltershow: [],
        pdf: [],
        filedownload: [],
        certificatecomplianceexport: [],
        uploadeddocumentdownload: []
    }
}
export default function(state = initialState, action) {

    const { type, payload } = action;
    switch (type) {
        case CERTIFICATE_COMPLIANCE_WISE_REPORT:
            {
                return {
                    ...state,
                    certificatecompliancereport: {
                        ...state.certificatecompliancereport,
                        certificatecompliancereportfilter: payload,
                    },
                };
            }
        case CERTIFICATE_COMPLIANCE_WISE_REPORT_SHOW:
            {
                return {
                    ...state,
                    certificatecompliancereport: {
                        ...state.certificatecompliancereport,
                        certificatecompliancereportfiltershow: payload,
                    },
                };
            }
        case CERTIFICATE_PDF:
            {
                return {
                    ...state,
                    certificatecompliancereport: {
                        ...state.certificatecompliancereport,
                        pdf: payload,
                    },
                };
            }
        case PDF_RESET:
            {
                return {
                    ...state,
                    certificatecompliancereport: {
                        ...state.certificatecompliancereport,
                        pdf: [],
                    },
                };
            }
        case DOWNLOAD_FILE:
            {
                return {
                    ...state,
                    certificatecompliancereport: {
                        ...state.certificatecompliancereport,
                        filedownload: payload,
                    },
                };
            }
        case DOWNLOAD_DOCUMENT_FILE:
            {
                return {
                    ...state,
                    certificatecompliancereport: {
                        ...state.certificatecompliancereport,
                        uploadeddocumentdownload: payload,
                    },
                };
            }
        case FILE_DOWNLOAD:
            {
                return {
                    ...state,
                    certificatecompliancereport: {
                        ...state.certificatecompliancereport,
                        filedownload: [],
                    },
                };
            }
        case CV_COMPLIANCE_CERTIFICATE_EXPORT:
            {
                return {
                    ...state,
                    certificatecompliancereport: {
                        ...state.certificatecompliancereport,
                        export: payload,
                    },
                };
            }
        case CV_COMPLIANCE_CERTIFICATE_EXPORT_RESET:
            {
                return {
                    ...state,
                    certificatecompliancereport: {
                        ...state.certificatecompliancereport,
                        certificatecomplianceexport: [],
                    },
                };
            }

        default:
            return state;
    }
}