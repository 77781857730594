import { useLocation, Link, useHistory } from 'react-router-dom';
import { Collapse, Select, Card, Drawer, Button, Table, Tooltip, Pagination, Modal, Progress } from 'antd';
import { useState, useEffect, useRef } from 'react';
import SimpleReactValidator from "simple-react-validator";
import { connect, useDispatch, useSelector } from 'react-redux';
import { getdata, tabledata } from './../../../Store/Action/Report/Serviceproviderdetails'
import { fullscreenmode, fullscreenexit, handleKeyDown } from './../../../Libs/fullscreenmode';
import { validationType, validateSelect, onPasteToaster } from './../../../Libs/inputValidation';
import useStickyHeight from "../../../Libs/useStickyHeight";
import { ExclamationCircleTwoTone, InfoCircleOutlined, EyeOutlined, ExportOutlined, ArrowRightOutlined, ArrowLeftOutlined, PlayCircleOutlined, DownloadOutlined, UpOutlined, FullscreenOutlined, FilterOutlined } from '@ant-design/icons';
import ScrollToTop from "react-scroll-to-top";
import ScrollButton from '../../Scrollbar';
import { Toaster } from '../../../Libs/Toaster';
import { HomeRedirect } from './../../../Libs/country'
const homepage = HomeRedirect()

const mapStateToProps = (state) => ({
    reportlist: state.ServiceProviderDetails.serviceproviderdetails
})

const ServiceProviderDetails = ({ getdata, reportlist, tabledata }) => {
    useStickyHeight();
    const historynew = useHistory();
    const { Panel } = Collapse;
    const { Option } = Select;
    const location = useLocation();
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const dispatch = useDispatch();
    const paramid = location.pathname;
    const _ = require("lodash");
    const sessionValue = localStorage.getItem('sessionValue')
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const validator = useRef(new SimpleReactValidator());
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [tablelist, setTableList] = useState([])
    const [childdata, setChilddata] = useState([])
    const [statkey, setStatKey] = useState("0")
    const [key, setKey] = useState("1")
    const [tableshow, setTableShow] = useState(false)
    let [index, setindex] = useState(0)
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setfilteredArrayState] = useState([])
    const [pagecount, setPagecount] = useState(false)
    const [childState, setChildState] = useState(false)
    const [titledata, Settitledata] = useState(undefined)
    const [paginationArray, setPaginationArray] = useState([])
    const [temppaginationArray, settemppaginationArray] = useState([])
    const [currentpaginationArray, setcurrentpaginationArray] = useState([])
    const [childCompliceId, setChildComplinaceId] = useState([])
    const [pageSize, setPageSize] = useState(25)
    const [current, setCurrent] = useState(1)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);
    const [serviceproviderdetails, setServiceProviderDetails] = useState({
        service_provider: "",
        sp_id: null,
        user: "",
        user_id: null,
        status: ""
    })
    const [pageState, setpageState] = useState(false);
    const [datalist, setDataList] = useState({
        service_provider: [],
        user: [],
        status: []
    })

    const [selectSearchValue, setSelectSearchValue] = useState({
        serviceProvider: '',
        user: ''
    });

    const handleCancel = () => {
        setIsModalVisible(false)
    };
    const onshow = () => {
        setfilteredArrayState([])
        Settitledata([])
        setExitCollapse(true);
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
        setTableShow(true)
        setKey(0)
        setStatKey(1)
        setindex(0);
        setCurrent(1);
        setpageState(false)
        setPageSize(25)
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetServiceProviderDetailsReport",
                    {
                        "sp_id": serviceproviderdetails.sp_id ? Number(serviceproviderdetails.sp_id) : 0,
                        "user_id": serviceproviderdetails.user_id ? Number(serviceproviderdetails.user_id) : 0,
                        "s_p_status": serviceproviderdetails.status ? serviceproviderdetails.status : "All",
                        "from_count": 0,
                        "page_count": 25
                    }
                ]
            }
        ]
        tabledata({
            payload: payload,
            paramid: paramid
        })
    }


    const showmore = ((value) => {
        setTableShow(true)
        setKey(0)
        setStatKey(1)
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetServiceProviderDetailsReport",
                    {
                        "sp_id": serviceproviderdetails.sp_id ? Number(serviceproviderdetails.sp_id) : 0,
                        "user_id": serviceproviderdetails.user_id ? Number(serviceproviderdetails.user_id) : 0,
                        "s_p_status": serviceproviderdetails.status ? serviceproviderdetails.status : "All",
                        "from_count": 0,
                        "page_count": Number(value)
                    }
                ]
            }
        ]
        tabledata({
            payload: payload,
            paramid: paramid
        })

    })
    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetServiceProviderDetailsReportFilters",
                        {}
                    ]
                }
            ]
            getdata({
                payload: payload,
                paramid: paramid
            })
        }
    }, [authtoken, isAuth])
    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        if (reportlist && reportlist.getdata) {
            let userdata = _.filter(reportlist && reportlist.getdata && reportlist.getdata.sp_user_list, { sp_id_optional: Number(serviceproviderdetails.sp_id) })
            setDataList({
                ...datalist,
                service_provider: reportlist && reportlist.getdata && reportlist.getdata.sp_list,
                user: userdata,
                status: reportlist && reportlist.getdata && reportlist.getdata.sp_status_list
            })
        }
    }, [reportlist && reportlist.getdata, serviceproviderdetails])

    useEffect(() => {
        if (reportlist && reportlist.tabledata) {
            setTableList(reportlist && reportlist.tabledata)
        }
    }, [reportlist && reportlist.tabledata])

    useEffect(() => {
        // setTempeditdata(editdata)

        if (temppaginationArray && temppaginationArray.length > 0) {
            let expected_arr = [];
            for (let i = 0; i < temppaginationArray.length; i++) {
                let check_child = _.findIndex(expected_arr, { "sp_id": temppaginationArray[i].sp_id });
                if (check_child >= 0) {
                    expected_arr[check_child].child.push(temppaginationArray[i]);
                } else {
                    temppaginationArray[i].child = [];
                    expected_arr.push(temppaginationArray[i])
                }
            }
            console.log(expected_arr, 'expected_arr');
            Settitledata(expected_arr)
            // setcurrentpaginationArray(expected_arr)


        }

    }, [temppaginationArray])

    useEffect(() => {
        if (reportlist && reportlist.tabledata) {
            // var array = reportlist && reportlist.tabledata && reportlist && reportlist.tabledata.sp_details_list && reportlist.tabledata.sp_details_list.length > 0 && reportlist.tabledata.sp_details_list.slice((current - 1) * pageSize, (current * pageSize))
            settemppaginationArray(reportlist.tabledata.sp_details_list)
        }
    }, [reportlist && reportlist.tabledata, pageSize, current])

    useEffect(() => {
        if (currentpaginationArray && currentpaginationArray.length > 0) {
            var array = currentpaginationArray && currentpaginationArray.length > 0 && currentpaginationArray.slice((current - 1) * pageSize, (current * pageSize))
            setPaginationArray(array)
        }
    }, [currentpaginationArray, pageSize, current])

    useEffect(() => {
        // setTempeditdata(editdata)
        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (paginationArray) {
            const ab = paginationArray && paginationArray.length > 0 && paginationArray.map(function (item) {
                if ((array.indexOf(item.sp_id) < 0))
                    array.push(item.sp_id)
            })
            array.map((data, i) => {
                let temp = [];
                let entityArray = [];
                let temp1 = [];
                let entityArray1 = [];
                let datavalue = [];
                if (data) {
                    paginationArray && paginationArray.length > 0 && paginationArray.map((child, childIndex) => {
                        if (data === child.sp_id && child.contract_period === null) {
                            entityArray = {
                                "address": child.address,
                                "con_no": child.con_no,
                                "contract_period": child.contract_period,
                                "email_id": child.email_id,
                                "s_p_status": child.s_p_status,
                                "sp_id": child.sp_id,
                                "sp_name": child.sp_name,
                                "sp_status_date": child.sp_status_date,
                                "unit_count": child.unit_count,
                                "child": child.child,
                                'childId': childIndex,
                                'parentId': child.contract_period ? child : ""
                            }
                            temp.push(entityArray)
                        }
                        if (data === child.sp_id && child.contract_period !== null) {
                            entityArray1 = {
                                "address": child.address,
                                "con_no": child.con_no,
                                "contract_period": child.contract_period,
                                "email_id": child.email_id,
                                "s_p_status": child.s_p_status,
                                "sp_id": child.sp_id,
                                "sp_name": child.sp_name,
                                "child": child.child,
                                "sp_status_date": child.sp_status_date,
                                "unit_count": child.unit_count,
                                'childId': childIndex,
                                'parentId': i
                            }
                            temp1.push(entityArray1)
                        }
                    })
                }

                datavalue = {

                    parentid: data,
                    parent: temp1,
                    child: temp
                }
                finalarray.push(datavalue)
            })
            Settitledata(finalarray)
        }

    }, [paginationArray])


    useEffect(() => {
        if (paginationArray && paginationArray.length > 0) {
            let uniqueObjArray = [...new Map(paginationArray.map((item) => [item["sp_id"], item])).values()];
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.sp_id
            })

            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(paginationArray && paginationArray.length > 0 && paginationArray, { sp_id: uniqueIds[i] })
                tempArr.push(filteredArr)
            }
            setFilteredArray([...filteredArray, tempArr])

        }

    }, [paginationArray])



    useEffect(() => {
        if (filteredArray && filteredArray.length > 0) {
            filteredArray && filteredArray.map((item, i) => {
                return (
                    setfilteredArrayState(item)
                )
            })
        }
    }, [filteredArray])

    const homescreen = () => {
        let getUrlDetails = homepage;
        let tempval = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_url;
        let tempkey = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_key;
        localStorage.setItem('formkey', tempkey);
        historynew.push(tempval);
        // window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }

    const handlePasting = (e, regex) => {

        var regex = regex;
        var key = e.clipboardData.getData('text')
        if (!regex.test(key)) {
            e.preventDefault();
            Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
            return false;
        }
        // e.clipboardData.getData('text')
        // Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
        // e.preventDefault();
        // return false;
    }

    const onChange = (key) => {
        console.log(key);
    };

    const onStatChange = (key) => {
        console.log(key);
    }

    const columns = [
        {
            title: 'Service Provider',
            ellipsis: true,
            width: '50px',
            dataIndex: 'sp_name',
            key: 'sp_name',
        },
        {
            title: 'Contact Number',
            ellipsis: true,
            width: '50px',
            dataIndex: 'con_no',
            key: 'con_no',
        },
        {
            title: 'Email-ID',
            ellipsis: true,
            width: '70px',
            dataIndex: 'email_id',
            key: 'email_id',
        },
        {
            title: 'Status',
            ellipsis: true,
            width: '30px',
            dataIndex: 's_p_status',
            key: 's_p_status',
        },
        // {
        //     title: 'Date',
        //     ellipsis: true,
        //     width: '50px',
        //     dataIndex: 'sp_status_date',
        //     key: 'sp_status_date',
        // },
        {
            title: 'Address',
            ellipsis: false,
            width: '50px',
            dataIndex: 'address',
            key: 'address',
        },
    ];
    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };
    const [fullscreen, setfullscreen] = useState(false);

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }
    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };
    return (
        <>
            <div className="page-wrapper" id="page-wrapper">
                {/* <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
                {document.body.scrollHeight > document.body.clientHeight ?
                    <div className='back-to-top'>
                        <ScrollButton />
                    </div> : ''} */}
                {tableshow == true ?
                    <div className='back-to-top'>
                        <ScrollButton />
                    </div> : ''}
                <div className="page-titles pb-2 pt-1 page-title-sticky">
                    <div className='row'>
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <span style={{ fontSize: '14px', fontWeight: "bold" }}>Report</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                        <span style={{ fontSize: "15px", fontWeight: "bold" }}>Service Provider Reports</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <span>Service Provider Details</span>
                                    </li>
                                </ol>
                            </nav>

                        </div>
                        <div className="col-lg-4 text-end">
                            <Tooltip title={'Advance Filter'}><Button
                                className="mx-1"
                                type="primary"
                                shape="round"
                                style={{
                                    display: filterTaskAcc ? "none" : "initial",
                                }}
                                onClick={filterHandle}
                            >
                                <FilterOutlined /> Filter
                            </Button></Tooltip>
                            <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                                <Tooltip placement="left" title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pt-0">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className={localStorage.getItem('currentTheme')} id='only-restricted' style={{ position: 'relative', padding: "15px 5px" }}>

                                <Collapse defaultActiveKey={["1"]} onChange={onChange}

                                    style={{ display: exitCollapse ? "none" : "block" }}
                                    className='report-collapse'>
                                    <Panel header="Service Provider Details" key={key}>
                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Service Provider : </b> </label>
                                                    <Select
                                                        allowClear={true}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                                        // }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.serviceProvider}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                serviceProvider: result
                                                            });
                                                        }}
                                                        onChange={(value, data) => {
                                                            setServiceProviderDetails({
                                                                ...serviceproviderdetails,
                                                                service_provider: data != undefined ? data.children : '',
                                                                sp_id: value != undefined ? value : ''
                                                            })
                                                        }}
                                                        style={{ width: "100%", marginTop: '5px' }}
                                                        placeholder="Enter Service Provider"
                                                        value={serviceproviderdetails.service_provider || undefined}
                                                    >
                                                        {datalist.service_provider && datalist.service_provider.length > 0 && datalist.service_provider.map((item, i) => {
                                                            return (
                                                                <Option key={item.sp_id}>
                                                                    {item.sp_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>


                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>User : </b></label>

                                                    <Select allowClear={true}
                                                        placeholder='Enter User'
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        onChange={(value, data) => {
                                                            setServiceProviderDetails({
                                                                ...serviceproviderdetails,
                                                                user: data != undefined ? data.children : '',
                                                                user_id: value != undefined ? value : ''
                                                            })
                                                        }}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                                        // }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.user}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                user: result
                                                            });
                                                        }}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        style={{ width: "100%", marginTop: '5px' }}
                                                        value={serviceproviderdetails.user || undefined}
                                                    >
                                                        {datalist.user && datalist.user.length > 0 && datalist.user.map((item, i) => {
                                                            return (
                                                                <Option key={item.user_id}>
                                                                    {item.user_name}
                                                                </Option>
                                                            )
                                                        })}
                                                    </Select>


                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Status : </b> </label>
                                                    <Select allowClear={true}
                                                        // showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        defaultValue="ALL"
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onChange={(value, data) => {
                                                            setServiceProviderDetails({
                                                                ...serviceproviderdetails,
                                                                status: data != undefined ? data.children : ''
                                                            })

                                                        }}
                                                        style={{ width: "100%", marginTop: '5px' }}

                                                        value={serviceproviderdetails.status || undefined}

                                                    >
                                                        <Option key="All">All</Option>
                                                        {datalist.status && datalist.status.length > 0 && datalist.status.map((item, i) => {
                                                            return (
                                                                // data.unit ?
                                                                <Option key={item.s_p_status_id}>
                                                                    {item.s_p_status}
                                                                </Option>
                                                                // :""
                                                            );
                                                        })}
                                                    </Select>

                                                </div>
                                            </div>
                                        </div>
                                        <div className={'popupbtncolour text-center ' + localStorage.getItem('currentTheme')} style={{ marginTop: "5px" }}>
                                            <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} onClick={onshow}
                                                icon={<EyeOutlined />}    >
                                                Show
                                            </Button>
                                        </div>
                                    </Panel>
                                </Collapse>
                                <Modal
                                    onCancel={setcancelFilter}
                                    footer={false}
                                    title="Service provider details"
                                    className={
                                        "add-service-prv cons-report " +
                                        localStorage.getItem("currentTheme")
                                    }
                                    open={filterModalVisible}
                                    maskClosable={false}
                                >
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Service Provider : </b> </label>
                                                <Select
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    allowClear={true}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                                    // }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.serviceProvider}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            serviceProvider: result
                                                        });
                                                    }}
                                                    onChange={(value, data) => {
                                                        setServiceProviderDetails({
                                                            ...serviceproviderdetails,
                                                            service_provider: data != undefined ? data.children : '',
                                                            sp_id: value != undefined ? value : ''
                                                        })
                                                    }}
                                                    style={{ width: "100%", marginTop: '5px' }}
                                                    placeholder="Enter Service Provider"
                                                    value={serviceproviderdetails.service_provider || undefined}
                                                >
                                                    {datalist.service_provider && datalist.service_provider.length > 0 && datalist.service_provider.map((item, i) => {
                                                        return (
                                                            <Option key={item.sp_id}>
                                                                {item.sp_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>


                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>User : </b></label>

                                                <Select allowClear={true}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    placeholder='Enter User'
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                                    // }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.user}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            user: result
                                                        });
                                                    }}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onChange={(value, data) => {
                                                        setServiceProviderDetails({
                                                            ...serviceproviderdetails,
                                                            user: data != undefined ? data.children : '',
                                                            user_id: value != undefined ? value : ''
                                                        })
                                                    }}
                                                    style={{ width: "100%", marginTop: '5px' }}
                                                    value={serviceproviderdetails.user || undefined}
                                                >
                                                    {datalist.user && datalist.user.length > 0 && datalist.user.map((item, i) => {
                                                        return (
                                                            <Option key={item.user_id}>
                                                                {item.user_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>


                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Status : </b> </label>
                                                <Select allowClear={true}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    // showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    defaultValue="ALL"
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onChange={(value, data) => {
                                                        setServiceProviderDetails({
                                                            ...serviceproviderdetails,
                                                            status: data != undefined ? data.children : ''
                                                        })

                                                    }}
                                                    style={{ width: "100%", marginTop: '5px' }}

                                                    value={serviceproviderdetails.status || undefined}

                                                >
                                                    <Option key="All">All</Option>
                                                    {datalist.status && datalist.status.length > 0 && datalist.status.map((item, i) => {
                                                        return (
                                                            // data.unit ?
                                                            <Option key={item.s_p_status_id}>
                                                                {item.s_p_status}
                                                            </Option>
                                                            // :""
                                                        );
                                                    })}
                                                </Select>

                                            </div>
                                        </div>
                                    </div>
                                    <div className={'popupbtncolour text-center ' + localStorage.getItem('currentTheme')} style={{ marginTop: "5px" }}>
                                        <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} onClick={onshow}
                                            icon={<EyeOutlined />}    >
                                            Show
                                        </Button>
                                    </div>
                                </Modal>
                                {/* {tableshow === true ?
                                    <Collapse defaultActiveKey={["1"]} className='report-collapse' onChange={onStatChange} style={{ marginTop: '1%', backgroundColor: '#5daae3' }} >
                                        <Panel header="Overall Statistics" key={statkey}>
                                            <div className="col-md-12" style={{ marginLeft: '5%', marginRight: '5%' }}>
                                                <div className="row m-2">
                                                    <div className="col-md-4">
                                                        <Progress type="circle" percent={100} width={90} format={(percent) => `${percent} `} />
                                                        <h4 style={{ marginLeft: '5%' }}>Active</h4>

                                                    </div>
                                                    <div className="col-md-4">
                                                        <Progress type="circle" percent={10} width={90} status="exception" format={(percent) => `${percent} `} />
                                                        <h4 style={{ marginLeft: '5%' }}>Inactive</h4>

                                                    </div>
                                                    <div className="col-md-4">
                                                        <Progress type="circle" percent={70} width={90} strokeColor={{ '0%': '#f5b342', '100%': '#f5b342' }} format={(percent) => `${percent} `} />
                                                        <h4 style={{ marginLeft: '5%' }}>Blocked</h4>

                                                    </div>
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse> : ''
                                } */}
                                {tableshow == true ?

                                    <div>

                                        <Card className='rounded-3 conditionClass'>
                                            <div className='ant-table-wrapper' style={{ marginTop: '1%' }}>
                                                <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                    <div className='ant-table-container'>
                                                        <div className='ant-table-content userprivclass1'>
                                                            <table>
                                                                <colgroup>
                                                                    <col style={{ width: '10px' }}></col>
                                                                </colgroup>
                                                                <thead className="ant-table-thead table-head-stick table-head-stick-update ">
                                                                    <tr>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '50px' }}> # </th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '230px' }}><center>Service Provider</center></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '120px' }}><center>No of Units</center></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '190px' }}><center>Contact Number</center></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '250px' }}><center>Email-ID</center></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '170px' }}><center>Address</center></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '140px' }}><center>Contract Period</center></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '110px' }}><center>Status</center></th>
                                                                        <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '140px' }}><center>Date</center></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className='ant-table-tbody'>
                                                                    {/* {filteredArrayState && filteredArrayState.length > 0 ?
                                                                        filteredArrayState.map((item) => {
                                                                            return ( */}
                                                                    {/* <> */}
                                                                    {titledata && titledata.length > 0 ? titledata.map((itemdata, i) => {
                                                                        // let data = _.find(itemdata.parent, { sp_id: Number(item[0].sp_id) })
                                                                        console.log(itemdata, 'itemdataitemdataitemdata');
                                                                        // if (data != undefined) {
                                                                        //     if (data.sp_id == itemdata.parentid) {
                                                                        let temparray = []
                                                                        // itemdata.child.map((items)=>{


                                                                        return <>
                                                                            <tr>
                                                                                <td className='ant-table-cell'
                                                                                    style={{ cursor: 'pointer' }}
                                                                                    onClick={() => {
                                                                                        if (itemdata.child.length > 0) {
                                                                                            setChildComplinaceId(itemdata.child)
                                                                                            setIsModalVisible(true)
                                                                                        }
                                                                                        // if (itemdata.parent[0].unit_count > 0) {
                                                                                        //     setChildState(!childState)
                                                                                        // }
                                                                                    }}
                                                                                ><center>{index + 1}</center></td>
                                                                                <td className='ant-table-cell'
                                                                                    style={{ cursor: 'pointer' }}
                                                                                    onClick={() => {
                                                                                        if (itemdata.child.length > 0) {
                                                                                            setChildComplinaceId(itemdata.child)
                                                                                            setIsModalVisible(true)
                                                                                        }
                                                                                        // if (itemdata.parent[0].unit_count > 0) {
                                                                                        //     setChildState(!childState)
                                                                                        // }
                                                                                    }}
                                                                                >{itemdata.sp_name}</td>
                                                                                <td className='ant-table-cell'
                                                                                    style={{ cursor: 'pointer' }}
                                                                                    onClick={() => {
                                                                                        if (itemdata.child.length > 0) {
                                                                                            setChildComplinaceId(itemdata.child)
                                                                                            setIsModalVisible(true)
                                                                                        }
                                                                                        // if (itemdata.parent[0].unit_count > 0) {
                                                                                        //     setChildState(!childState)
                                                                                        // }
                                                                                    }}
                                                                                ><center>{itemdata.unit_count}</center></td>
                                                                                <td className='ant-table-cell'
                                                                                    style={{ cursor: 'pointer' }}
                                                                                    onClick={() => {
                                                                                        if (itemdata.child.length > 0) {
                                                                                            setChildComplinaceId(itemdata.child)
                                                                                            setIsModalVisible(true)
                                                                                        }
                                                                                        // if (itemdata.parent[0].unit_count > 0) {
                                                                                        //     setChildState(!childState)
                                                                                        // }
                                                                                    }}
                                                                                >{itemdata.con_no}</td>
                                                                                <td className='ant-table-cell'
                                                                                    style={{ cursor: 'pointer' }}
                                                                                    onClick={() => {
                                                                                        if (itemdata.child.length > 0) {
                                                                                            setChildComplinaceId(itemdata.child)
                                                                                            setIsModalVisible(true)
                                                                                        }
                                                                                        // if (itemdata.parent[0].unit_count > 0) {
                                                                                        //     setChildState(!childState)
                                                                                        // }
                                                                                    }}
                                                                                >{itemdata.email_id}</td>
                                                                                <td className='ant-table-cell ellipsis'
                                                                                    title={itemdata.address}
                                                                                    style={{ cursor: 'pointer' }}
                                                                                    onClick={() => {
                                                                                        if (itemdata.child.length > 0) {
                                                                                            setChildComplinaceId(itemdata.child)
                                                                                            setIsModalVisible(true)
                                                                                        }
                                                                                        // if (itemdata.parent[0].unit_count > 0) {
                                                                                        //     setChildState(!childState)
                                                                                        // }
                                                                                    }}
                                                                                >{itemdata.address}</td>
                                                                                <td className='ant-table-cell'
                                                                                    style={{ cursor: 'pointer' }}
                                                                                    onClick={() => {
                                                                                        if (itemdata.child.length > 0) {
                                                                                            setChildComplinaceId(itemdata.child)
                                                                                            setIsModalVisible(true)
                                                                                        }
                                                                                        // if (itemdata.parent[0].unit_count > 0) {
                                                                                        //     setChildState(!childState)
                                                                                        // }
                                                                                    }}
                                                                                >{itemdata.contract_period}</td>
                                                                                <td className='ant-table-cell'
                                                                                    style={{ cursor: 'pointer' }}
                                                                                    onClick={() => {
                                                                                        if (itemdata.child.length > 0) {
                                                                                            setChildComplinaceId(itemdata.child)
                                                                                            setIsModalVisible(true)
                                                                                        }
                                                                                        // if (itemdata.parent[0].unit_count > 0) {
                                                                                        //     setChildState(!childState)
                                                                                        // }
                                                                                    }}
                                                                                >{itemdata.s_p_status}</td>
                                                                                {itemdata.s_p_status === 'Active' ? <td className='ant-table-cell'></td> :
                                                                                    <td className='ant-table-cell'
                                                                                        style={{ cursor: 'pointer' }}
                                                                                        onClick={() => {
                                                                                            if (itemdata.child.length > 0) {
                                                                                                setChildComplinaceId(itemdata.child)
                                                                                                setIsModalVisible(true)
                                                                                            }
                                                                                            // if (itemdata.parent[0].unit_count > 0) {
                                                                                            //     setChildState(!childState)
                                                                                            // }
                                                                                        }}
                                                                                    ><center>{itemdata.sp_status_date}</center></td>}
                                                                                <span hidden>{index = index + 1}</span>
                                                                            </tr>

                                                                            {/* {childState === true ?
                                                                                                        itemdata.child.map((items, i) => {

                                                                                                            if (Number(item[0].sp_id) == items.sp_id) {

                                                                                                                return <tr>
                                                                                                                    <td className='ant-table-cell'><center>#</center></td>
                                                                                                                    <td className='ant-table-cell'>{items.sp_name}</td>
                                                                                                                    <td className='ant-table-cell'><center>{items.contract_period ? items.unit_count : ""}</center></td>
                                                                                                                    <td className='ant-table-cell'>{items.con_no == '--' ? <center>{items.con_no}</center> : items.con_no}</td>
                                                                                                                    <td className='ant-table-cell'>{items.email_id}</td>
                                                                                                                    <td className='ant-table-cell'>{items.address}</td>
                                                                                                                    <td className='ant-table-cell'>{items.contract_period}</td>
                                                                                                                    <td className='ant-table-cell'>{items.s_p_status}</td>
                                                                                                                    <td className='ant-table-cell'><center>{items.sp_status_date}</center></td>
                                                                                                                    <span hidden>{index1 = index1 + 1}</span>
                                                                                                                </tr>

                                                                                                            }


                                                                                                        })
                                                                                                        : ''} */}


                                                                        </>
                                                                        // })
                                                                        // }
                                                                        // }
                                                                    })
                                                                        : <tr>
                                                                            <td colSpan="100%" style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                                        </tr>}
                                                                    {/* // </> */}
                                                                    {/* )
                                                                        }) */}
                                                                    {/* : */}
                                                                    {/* <tr>
                                                                            <td colSpan="100%" style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                                        </tr> */}
                                                                    {/* } */}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Modal footer={null} open={isModalVisible} onCancel={handleCancel}
                                                className={" add-service-prv cons-report " + localStorage.getItem("currentTheme")}
                                                title="Task Details"
                                                onOk={() => {
                                                    setIsModalVisible(false)
                                                }} maskClosable={false}>
                                                <Table style={{ marginTop: '25px' }}
                                                    className={localStorage.getItem('currentTheme')}
                                                    columns={columns}
                                                    dataSource={childCompliceId}
                                                    bordered
                                                    pagination={false} />
                                            </Modal>
                                            <div className='row mt-3'>
                                                {index != 0 ?
                                                    <>
                                                        <div className='col-md-6'>
                                                            <label>Showing {pageSize * (current - 1) + 1} to {index} of {tablelist && tablelist.total_count} entries</label>
                                                        </div>
                                                        <div className='col-md-6 text-right'>
                                                            <Pagination
                                                                current={current}
                                                                showSizeChanger={true}
                                                                // showQuickJumper={true}
                                                                onShowSizeChange={() => {
                                                                    setCurrent(1)
                                                                    // setpageState(true)
                                                                }}
                                                                pageSizeOptions={[25, 50, 100]} hideOnSinglePage={tablelist && tablelist.total_count > 25 ? false : true} defaultPageSize={25}
                                                                onChange={(page, pageSizes) => {
                                                                    setpageState(true)
                                                                    setCurrent(pageSize !== pageSizes ? 1 : page);
                                                                    setPageSize(pageSizes)
                                                                }} total={tablelist && tablelist.total_count}
                                                            // showTotal={(total) => `Showing 1 to ${index} of ${tableData.total_count} entries`}
                                                            />
                                                        </div>
                                                    </> : ''}
                                            </div>
                                        </Card>
                                    </div>
                                    : ""}


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default connect(mapStateToProps, {
    getdata,
    tabledata
})(ServiceProviderDetails);