import { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import Swal from 'sweetalert2';
import { FullscreenOutlined, PlusCircleOutlined, CheckCircleTwoTone, EditTwoTone, PlayCircleOutlined, CloseCircleOutlined, CheckOutlined, StopOutlined, DeleteOutlined } from '@ant-design/icons';
import { Modal, Input, Table, Button, Space, Tooltip, Collapse, Select, Card } from 'antd';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../src/Libs/fullscreenmode";
import { HomeRedirect } from './../Libs/country';
import { IPfilterdata, IPtabledata, IPSubmitAdd, IPSubmitUpdate, updatestatus } from './../../src/Store/Action/IPsettings';
import { Toaster } from './../Libs/Toaster';
import { onPasteToaster, validateInput, validationType } from '../Libs/inputValidation';


const mapStateToProps = (state) => ({
    lists: state.IPSETTINGS.list,
    Tabledata: state.IPSETTINGS.tabledata,
    Ipgroupstatus: state.IPSETTINGS.ipstastus


})

const IPSettings = ({
    IPfilterdata,
    lists,
    IPtabledata,
    Tabledata,
    IPSubmitAdd,
    IPSubmitUpdate,
    updatestatus,
    Ipgroupstatus,



}) => {
    console.log(Ipgroupstatus.mnu_res, "TabledataTabledata");
    const dispatch = useDispatch();
    const { Panel } = Collapse;

    const { Option } = Select;
    const homepage = HomeRedirect()
    const [container, setContainer] = useState([])
    console.log(container, "containercontainer");
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    console.log(AddFormSubmit1, 'AddFormSubmit1');
    const formValidator = useRef(new SimpleReactValidator());
    const formValidator1 = useRef(new SimpleReactValidator());
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const _ = require("lodash");
    const location = useLocation();
    const paramid = location.pathname;
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const [view, Setview] = useState('list')
    console.log(Tabledata, 'Tabledata');
    const [show, setShow] = useState(false)
    const [page, setPage] = useState(1);
    let [counts, setcounts] = useState(0);
    const [Add, SetAdd] = useState(false)
    const [changestatus, setChangestatus] = useState()
    console.log(changestatus, "changestatuschangestatus");
    const [Addnewlist, SetAddnewlist] = useState([{
        ipaddress: '',
        status: 1,
        is_delete: ''
    }])

    console.log(Addnewlist, "Addnewlist");


    const [AddIpGrouplist, setAddIpGrouplist] = useState([{
        ip_group_id: '',
        ipaddress: [{
            ipaddress: '',
            status: 1,
            is_delete: ''
        }],
    }])
    console.log(AddIpGrouplist, "AddIpGrouplist");


    const [paginationSize, setPaginationSize] = useState(25);
    const [Ipsettingsdata, SetIpsettingsdata] = useState({
        ipgroupname: '',
        ip_group: '',
        ip_group_id: '',
        ip_inputname: '',
        ip_address: ''


    })
    useEffect(() => {
        if (sessionStorage.getItem('isIpSetting') == 'true') {
            localStorage.setItem('formkey', sessionStorage.getItem('isIpSettingKey'))
        }
    }, [])


    console.log(Ipsettingsdata.ip_address, "IpsettingsdataIpsettingsdata");
    const [editIP, setEditIp] = useState()
    console.log(editIP, "editIPeditIPeditIP")

    // Home Screen Navigate 
    const homescreen = () => {
        if (sessionStorage.getItem('isIpSetting') == 'false') {
        window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
        }
    }

    // Full Screen Mode
    const [fullscreen, setfullscreen] = useState(false);

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    // On Paste toast
    const handlePasting = (e, regex) => {

        var regex = regex;
        var key = e.clipboardData.getData('text')
        if (!regex.test(key)) {
            e.preventDefault();
            Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
            return false;
        }
        // e.clipboardData.getData('text')
        // Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
        // e.preventDefault();
        // return false;
    }


    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }

    // Validation 
    useEffect(() => {
        formValidator.current.showMessages()
        formValidator1.current.showMessages()
    }, [])


    // Enabled / Disabled Status change
    // useEffect(() => {
    //     if (Ipgroupstatus) {
    //         console.log("trueeeeeeeeeeee00000");

    //         if (Ipgroupstatus.mnu_res === false) {
    //             console.log("trueeeeeeeeeeee");
    //             setChangestatus(lists && lists.ip_groups && lists.ip_groups[0].ip_settings_status)
    //         }
    //     }

    // }, [Ipgroupstatus, lists])

    // On keypress Validation
    const onInputChange = (e, ID) => {
        console.log(e, ID, "iPGROUPINPUT");
        const { value } = e.target;
        const res = validateInput(e,validationType.textWithNumbersNoSpChr)
        const re = /^[a-zA-Z0-9,.-_]*$/;
        // if (re.test(value) === true) {
            console.log(res,"trueeeeeeee");
            // SetIpsettingsdata({
            //     ...Ipsettingsdata,
            //     ip_inputname: e.target.value
            // })
            AddIpGrouplist[ID].ip_group_id = res
            // let temp = AddIpGrouplist
            // temp.push({
            //     ip_group_id: '',
            //     ip_status: '',
            //     ipaddress: '',
            //     is_delete: ''
            // })

            setAddIpGrouplist([...AddIpGrouplist])
        // }
    }

    const onInputChange1 = (e, ID, i) => {
        console.log(editIP, 'editIP')
        const { value } = e.target;
        let temp = editIP
        const re = /^[0-9.]*$/;
        if (re.test(value) === true) {
            console.log("trueeeeeeee");
            // for (let i = 0; i < temp.length; i++) {
            //     if (temp[i].ip_address_id == ID) {
            //         temp[i].ipaddress = value
            //     }
            // }


            editIP[i].ipaddress = value
            setEditIp(
                [...editIP])
            SetIpsettingsdata({
                ...Ipsettingsdata,
                ip_address: e.target.value
            })
        }
        setEditIp([...editIP])
    }


    // Addtablerow
    const Addtablerow = (index) => {
        // let temp = Addnewlist
        // temp.push({
        //     ipaddress: '',
        //     status: 1,
        //     is_delete: ''
        // })
        // SetAddnewlist([...temp])
        console.log(index, 'indexxx');
        let temp1 = AddIpGrouplist
        temp1.map((item, i) => {
            if (i == index) {
                item.ipaddress.push({
                    ipaddress: '',
                    status: 1,
                    is_delete: ''
                })
            }

        })
        setAddIpGrouplist([...temp1])
    }

    //  Edittablerow
    const Edittablerow = () => {
        let temp = editIP
        temp.push({
            ip_address_id: '',
            ip_group_id: '',
            ip_status: 1,
            ipaddress: ''

        })
        setEditIp([...temp])
    }

    // AddIPGroupList
    const ADDIPgrouplist = () => {
        // let rowDuplicate = document.getElementById('duplicaterow')
        // console.log(rowDuplicate,'rowDuplicate');
        // let clone = rowDuplicate.cloneNode(true)
        // let parentEle = document.getElementById('parentDup')
        // console.log(parentEle,'parentEle');
        // let parentEle1 = parentEle.appendChild(clone)
        // console.log(parentEle1,'parentEle1');

        let temp = AddIpGrouplist
        temp.push({
            ip_group_id: '',
            ipaddress: [{
                ipaddress: '',
                status: 1,
                is_delete: ''
            }],

        })

        setAddIpGrouplist([...temp])
    }


    // Close Modal
    const CloseIcon = () => {
        Setview('list')
        SetAdd(false)
        setAddFormSubmit(false)
        setAddFormSubmit1(false)
        setAddIpGrouplist([])
        setEditIp([])
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetIPGroupList",
                    {
                        "ip_group_id": Number(Ipsettingsdata.ip_group_id)
                    }
                ]
            }
        ]
        IPtabledata({
            payload: payload,
            paramid: paramid
        })

    };

    // Add IP GroupName
    const AddIPgroup = () => {
        Setview('add')
        SetAdd(true)
        if (AddIpGrouplist && (AddIpGrouplist.length == 0)) {
            setAddIpGrouplist([{
                ip_group_id: '',
                ipaddress: [{
                    ipaddress: '',
                    status: 1,
                    is_delete: ''
                }],
            }])
        }
    };

    //Edit IP GroupName
    const EditIPgroup = () => {
        Setview('edit')
        SetAdd(true)

        setEditIp(Tabledata.ip_group_list)

    };



    // EnabledFunc / DisabledFunc
    const EnableStatus = () => {
        const pay = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "SaveGroupLevelIPSettingsStatus",
                    {
                        "ip_status": lists && lists.ip_groups && lists.ip_groups[0].ip_settings_status == '1' ? 0 : 1
                    }
                ]
            }
        ]
        const refreshpayload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetIPGroupSettingsFilter",
                    {}
                ]
            }
        ]
        updatestatus({
            payload: pay,
            paramid: paramid,
            payload1: refreshpayload

        })

    }

    const onInputChange2 = (e, rowid, id) => {
        console.log(id, rowid, "idddddddddddd");
        const { value } = e.target;
        const re = /^[a-zA-Z0-9,.-_]*$/;
        if (re.test(value) === true) {
            console.log("trueeeeeeee");
            AddIpGrouplist[rowid].ipaddress[id] = {
                ipaddress: value,
                status: AddIpGrouplist[rowid].ipaddress[id].status,
                is_delete: AddIpGrouplist[rowid].ipaddress[id].is_delete
            }
            setAddIpGrouplist([...AddIpGrouplist])
        }
    }

    const statuschangeAdd = (e, rowid, id) => {
        console.log(e, id, "tesrresrrrrrrrrr");
        // let temp = Addnewlist
        // for (let i = 0; i < temp && temp.length; i++) {
        //     if (temp[i].id == id) {
        //         temp[i].id = e
        //     }
        // }
        // AddIpGrouplist[id].ip_status = e
        // setAddIpGrouplist(
        //     [...AddIpGrouplist])
        // SetIpsettingsdata({
        //     ...Ipsettingsdata,
        // })
        // let temp1 = AddIpGrouplist
        // temp1.map((item, i) => {
        //     if (i == index) {
        //         item.ipaddress.push({
        //             ipaddress: AddIpGrouplist[rowid].ipaddress[id].ipaddress,
        //             status: e,
        //             is_delete: AddIpGrouplist[rowid].ipaddress[id].is_delete
        //         })
        //     }

        // })
        AddIpGrouplist[rowid].ipaddress[id] = {
            ipaddress: AddIpGrouplist[rowid].ipaddress[id].ipaddress,
            status: e,
            is_delete: AddIpGrouplist[rowid].ipaddress[id].is_delete
        }
        setAddIpGrouplist([...AddIpGrouplist])

    }
    // const statuschange = (e, ID) => {
    //     let temp = editIP
    //     console.log("trueeeeeeee");
    //     for (let i = 0; i < temp.length; i++) {
    //         if (temp[i].ip_address_id == ID) {
    //             temp[i].ip_status = e
    //         }
    //     }
    //     // editIP[ID].ip_status = e
    //     // setEditIp(
    //     //     [...editIP])
    //     SetIpsettingsdata({
    //         ...Ipsettingsdata,

    //     })
    //     setEditIp(temp)
    // }

    const statuschange = (e, ID, i) => {
        let temp = editIP
        // console.log("trueeeeeeee");
        // for (let i = 0; i < temp.length; i++) {
        //     if (temp[i].ip_address_id == ID) {
        //         temp[i].ip_status = e
        //     }
        //     else {
        //         temp[i].ip_status=e
        //     }
        // }
        // editIP[ID].ip_status = e
        // setEditIp(
        //     [...editIP])
        editIP[i].ip_status = e
        SetIpsettingsdata({
            ...Ipsettingsdata,

        })
        setEditIp([...editIP])
    }


    //Submit API 
    const saveRecords = (e) => {
        console.log(AddIpGrouplist,"truAddIpGrouplisteeeeeeeee11");
        e.preventDefault();
        let temp = true
        let temp1 = []
        let count = 0
        let temparr = []
        console.log(temparr, "temparr69333");
        for (let i in AddIpGrouplist) {
            if (AddIpGrouplist[i].ip_group_id == '') {
                temp = false
                // Toaster.error('Enter IP Group')
                count = count + 1
                // break
            }
        }
        // if (temp) {
        //     for (let i in AddIpGrouplist) {
        //         for (let j in AddIpGrouplist[i].ipaddress) {
        //             if (AddIpGrouplist[i].ipaddress[j].ipaddress == '') {
        //                 count = count + 1
        //                 Toaster.error('Enter IP Address')
        //                 // break
        //             }
        //         }
        //         // break
        //     }
        // }
        if (temp) {
            // for (let i in AddIpGrouplist) {

            const re = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

            for (let i in AddIpGrouplist) {
                let sample = []
                let isTrue = []
                for (let j in AddIpGrouplist[i].ipaddress) {
                    sample.push(AddIpGrouplist[i].ipaddress[j].ipaddress)
                    console.log(AddIpGrouplist[i].ipaddress[j].ipaddress, 'AddIpGrouplist[i].ipaddress[j].ipaddress');
                    if (AddIpGrouplist[i].ipaddress[j].ipaddress == '') {
                        count = count + 1

                        temparr = []
                        // break
                    }
                    else {
                        if (re.test(AddIpGrouplist[i].ipaddress[j].ipaddress)) {
                            // count = count + 1
                            console.log('ppppppppppppp');

                            temparr.push(true)
                            // break
                        }
                        else {
                            console.log('12345661122');
                            temparr.push(false)
                            // Toaster.error('Enter IP Address5555555')
                        }
                    }
                    
                }
                isTrue = _.uniqBy(sample)
                var isDuplicate = sample.some(function (item, idx) {
                    return sample.indexOf(item) != idx
                });
                temp1.push(isDuplicate)
                console.log(sample,isTrue,isDuplicate,'samplesamplesamplesample');
            }
            // break
        }
        // }


        // setAddFormSubmit(true);
        console.log(temparr, 'temparr');
        if (temp == true) {
            if (temparr.length == 0) {
                Toaster.error('Enter IP Address')
            }
            else if (temparr.includes(false)) {
                Toaster.error('Enter Valid IP Address')
            }
            else if(temp1.includes(true)){
                Toaster.error('IP Address Already exists')
            }
            else if ((count == 0) && (temparr.includes(false) == false)) {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "SaveNewGroupIPSettings",
                            {
                                "data":
                                    AddIpGrouplist.map((item, i) => {
                                        return {
                                            "ip_group_name": item.ip_group_id,
                                            "ip_group_address":
                                                item.ipaddress.map((items) => {
                                                    return {
                                                        "ip_address": items.ipaddress,
                                                        "ip_status": items.status
                                                    }
                                                })
                                            ,
                                            "ip_group_id": null
                                        }
                                    })
                            }
                        ]
                    }
                ]
                IPSubmitAdd({
                    payload: payload,
                    paramid: paramid
                })

            }
        }
        else {
            Toaster.error('Enter IP Group')
        }
    }

    const UpdateRecords = (e) => {
        console.log(formValidator1.current.allValid(), "trueeeeeeeee");
        e.preventDefault();
        let temp = true
        let temparr = []
        let sample = []
        let temp1 = []
        // setAddFormSubmit1(true);
        let count = 0
        if (temp) {
            // for (let i in AddIpGrouplist) {
            
            const re =/^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
            for (let j in editIP) {
              sample.push(editIP[j].ipaddress)
                if (editIP[j].ipaddress
                    == '') {
                    count = count + 1

                    temparr = []
                    // break
                }
                else {
                    console.log(editIP[j].ipaddress, 'editIP[j].ipaddress');
                    if (!re.test(editIP[j].ipaddress
                    )) {
                        // count = count + 1
                        console.log('ppppppppppppp');

                        temparr.push(false)
                        // break
                    }
                    else {
                        console.log(sample,'edit12345661122');
                        temparr.push(true)
                        // Toaster.error('Enter IP Address5555555')
                    }
                }
            }
            // isTrue = _.uniqBy(sample)
            var isDuplicate = sample.some(function (item, idx) {
                return sample.indexOf(item) != idx
            });
            temp1.push(isDuplicate)
            // break
        }
        // }


        // setAddFormSubmit(true);
        console.log(temparr, 'temparr');
        if (temp == true) {
            if (temparr.length == 0) {
                Toaster.error('Enter IP Address')
            }
            else if (temparr.includes(false)) {
                Toaster.error('Enter Valid IP Address')
            }else if(temp1.includes(true)){
                Toaster.error('IP Address Already Exists')
            }
            else if ((count == 0) && (temparr.includes(false) == false)) {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "UpdateNewGroupIPSettings",
                            {
                                "data": [
                                    {
                                        "ip_group_name": Ipsettingsdata.ip_group,
                                        "ip_group_address":
                                            editIP.map((items) => {
                                                return {
                                                    "ip_address": items.ipaddress,
                                                    "ip_status": items.ip_status
                                                }
                                            })
                                        ,
                                        "ip_group_id": editIP[0].ip_group_id
                                    }
                                ]
                            }
                        ]
                    }
                ]


                IPSubmitUpdate({
                    payload: payload,
                    paramid: paramid
                })

            }
        }
        else {
            Toaster.error('Enter IP Group')
        }

    }



    // Table for IP Address List
    const columns = [
        {
            title: '#',
            key: 'index',
            align: "center",
            width: '20px',
            render: (text, record, index) => {
                return (
                    (page - 1) * paginationSize + index + 1
                )
            },
        },
        {
            title: "IP Address",
            dataIndex: 'ipaddress',
            key: 'ipaddress',
            align: "center",
            ellipsis: true,
            width: '192px',


        },
        {
            title: 'Status',
            dataIndex: 'false',
            key: 'false',
            align: "center",
            width: '50px',
            ellipsis: false,
            render: (text, record) => {
                return (
                    <div style={{ marginLeft: '45%' }}> {record.ip_status == 1 ?
                        <span
                            className="social-link btn btn-sm btn-light-success
  text-success btn-circle d-flex align-items-center justify-content-center"
                        >
                            <Tooltip title="Enabled"><CheckOutlined /></Tooltip>
                        </span>
                        :
                        <span
                            className="social-link btn btn-sm btn-light-danger
                              text-danger btn-circle d-flex align-items-center justify-content-center"
                        >
                            <Tooltip title='Disabled' ><StopOutlined /></Tooltip>
                        </span>
                    }
                    </div>
                )
            }
        },
    ];

    // Initail Filter API 
    useEffect(() => {
        if (localStorage.getItem('isAuthenticated') == 'true') {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetIPGroupSettingsFilter",
                        {}
                    ]
                }
            ]

            IPfilterdata({
                payload: payload,
                paramid: paramid
            })
        }

    }, [])

    // IP Group API
    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetIPGroupList",
                        {
                            "ip_group_id": Number(Ipsettingsdata.ip_group_id)
                        }
                    ]
                }
            ]
            IPtabledata({
                payload: payload,
                paramid: paramid
            })

        }
    }, [isAuth, show, Ipsettingsdata.ip_group_id])



    return (
        <div id='page-wrapper' className="page-wrapper">
            <div className="page-titles pb-2 pt-4 page-title-sticky">
                <div className="row">
                    <nav className="col-lg-6 col-md-6 col-12 align-self-center" aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 d-flex align-items-center p-0">
                            <li className="breadcrumb-item">
                                <span className="pointer" id='homehover' onClick={homescreen}>
                                    <i className="ri-home-3-line fs-5"></i>
                                </span>
                            </li>
                            <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                <span style={{ fontSize: '16px' }}> IP Settings</span>
                            </li>

                        </ol>
                    </nav>
                    {Add == false ?
                        <div className="col-lg-6 text-end">
                            <Tooltip title="Click here to Add IP Group and IP Address" placement='bottom' ><Button
                                disabled={(lists && lists.ip_groups && lists.ip_groups[0].ip_settings_status == '0') ? true : false}
                                type="primary"
                                shape="round"
                                className='addbutton me-2'
                                icon={<PlusCircleOutlined />} size='default' onClick={AddIPgroup}>
                                Add
                            </Button></Tooltip>
                            {lists && lists.ip_groups && lists.ip_groups[0].ip_settings_status == '1' ?
                                <Tooltip title="Click here to Disable." placement='bottom' >
                                    <Button type="primary" shape="round" className='addbutton me-2 '
                                        icon={<CheckCircleTwoTone />} size='default' style={{ marginLeft: '5px' }} onClick={(e) => { EnableStatus() }}>
                                        Enabled
                                    </Button></Tooltip>
                                :
                                <Tooltip title="Click here to Enable." placement='bottom' >
                                    <Button type="primary" shape="round" className='addbutton me-2 '
                                        icon={<StopOutlined style={{ color: 'red' }} />} size='default' onClick={(e) => { EnableStatus() }}>
                                        Disabled
                                    </Button></Tooltip>
                            }
                            <button onClick={(e) => {
                                fullscreenMode();
                            }} className="bg-transparent text-black mx-1 full-mode fullscreenradius">
                                <Tooltip placement="left" title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"} >
                                    <FullscreenOutlined />
                                </Tooltip>
                            </button>
                        </div>
                        : ''}
                </div>
                {view == 'list' ? <>
                    <div className="row" aria-disabled="true">
                        <div className="col-md-12">
                            <div className="card" style={{ opacity: (lists && lists.ip_groups && lists.ip_groups[0].ip_settings_status == '0') ? 0.5 : 1 }}>
                                <div className='card-body' style={{ padding: '0px' }}>
                                    <div className='col-md-12'>
                                        <div className='row p-1 mb-1'>
                                            <div className='d-flex justify-content-evenly' >
                                                <div className='d-flex' style={{ marginTop: '5px', width: '350px' }}>
                                                    <label style={{ width: '110px' }}><b>IP Group :</b><span style={{ color: "red" }}> * </span> </label>
                                                    <Select style={{ width: "100%" }}
                                                        disabled={(lists && lists.ip_groups && lists.ip_groups[0].ip_settings_status == '0') ? true : false}
                                                        type="text"
                                                        id="ip_group"
                                                        name='ip_group'
                                                        placeholder="Select"
                                                        autoComplete={'off'}
                                                        defaultValue={"Select"}
                                                        allowClear={true}
                                                        onKeyPress={(event) => {
                                                            if (!/^[A-Za-z 0-9]+$/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        onChange={(value, data) => {

                                                            if (value == undefined) {
                                                                SetIpsettingsdata({
                                                                    ip_group_id: '',
                                                                    ip_group: ''
                                                                })
                                                            }
                                                            else {
                                                                SetIpsettingsdata({
                                                                    ip_group_id: value ? value : '',
                                                                    ip_group: data.children ? data.children : ''
                                                                })
                                                            }
                                                            setShow(true)

                                                        }}
                                                        value={Ipsettingsdata.ip_group || undefined}
                                                        maxLength={50}
                                                    >
                                                        <Option> Select</Option>
                                                        {lists && lists.ip_groups && lists.ip_groups.length && lists.ip_groups.map((item) => {
                                                            console.log(item, "itemitemmmmm");

                                                            return (
                                                                <Option title="" key={item.ip_group_id}>
                                                                    {item.ip_group_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>

                                                </div>


                                                {Ipsettingsdata && Ipsettingsdata.ip_group_id ?
                                                    <Button
                                                        disabled={(lists && lists.ip_groups && lists.ip_groups[0].ip_settings_status == '0') ? true : false}
                                                        type="primary" shape="round" className='addbutton align-self-center'
                                                        icon={<EditTwoTone />} size='default' onClick={(e) => { EditIPgroup() }}  >
                                                        Edit
                                                    </Button>
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='service-provider-table'> */}
                                    <Table
                                        className={localStorage.getItem("currentTheme")}
                                        columns={columns}
                                        dataSource={Tabledata && Tabledata.ip_group_list}
                                        showSorterTooltip={false}
                                        bordered
                                        pagination={false}
                                    />
                                    {/* </div> */}
                                </div>

                            </div>
                        </div>
                    </div>
                </> : ''}
                {view == 'add' ? <>

                    <div className={localStorage.getItem('currentTheme')}>
                        <Collapse className={' report-collapse mb-1  ' + localStorage.getItem('currentTheme')} accordion defaultActiveKey={["1"]}>
                            <Panel header={"IP Settings"} key="1">
                                <Card style={{ marginTop: '10px' }}>
                                    <div className='text-end'>

                                        <Tooltip title="" placement='left'> <Button
                                            type="primary"

                                            shape="round"
                                            className='addbutton'
                                            style={{ marginTop: "5px", marginBottom: "5px" }}
                                            icon={<PlusCircleOutlined />}
                                            onClick={ADDIPgrouplist}
                                        >
                                            Add IP Group
                                        </Button>
                                        </Tooltip>
                                    </div>
                                    {AddIpGrouplist && AddIpGrouplist.length > 0 && AddIpGrouplist.map((item, i) => {
                                        console.log(item, 'itemitem');
                                        return <div id='duplicaterow'>
                                            <div className='row p-1'>
                                                <div className='col-md-6'>
                                                    <label><b>IP Group Name:</b><span style={{ color: "red" }}> * </span> </label>
                                                    <Input style={{ marginTop: "5px", width: "100%" }}
                                                        type="text"
                                                        id="ip_inputname"
                                                        name='ip_inputname'
                                                        placeholder="Enter IP Group Name"
                                                        autoComplete={'off'}
                                                        // onChange={onInputChang}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
                                                            return false;
                                                        }}
                                                        onPaste={onPasteToaster}
                                                        onChange={(e) => { onInputChange(e, i) }}
                                                        onKeyPress={(event) => {
                                                            if (!/^[A-Za-z 0-9]+$/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        // value={item.ip_group_id || undefined}
                                                        value={AddIpGrouplist[i].ip_group_id || undefined}
                                                        maxLength={50}
                                                    />
                                                    {formValidator.current.message(
                                                        'ip_group_id',
                                                        AddIpGrouplist[i].ip_group_id,
                                                        [
                                                            '',
                                                            { regex: /^[A-Za-z 0-9]+$/ },

                                                            `max:50`
                                                        ],
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {

                                                                regex: 'Enter Valid Name',
                                                                max: 'Maximum character is 50 only'
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                            <div className="ant-table-wrapper">
                                                <div className='ant-table ant-table-bordered'>
                                                    <div className='ant-table-container'>
                                                        <div className={'ant-table-content userprivclass1 ' + localStorage.getItem('currentTheme')}>
                                                            <table>
                                                                <colgroup>
                                                                    <col style={{ width: '10px' }}></col>
                                                                </colgroup>
                                                                <thead className='ant-table-thead '>
                                                                    <tr>
                                                                        <th className='ant-table-cell' style={{ width: '192px' }}>IP Address <span style={{ color: 'red' }}> * </span></th>
                                                                        <th className='ant-table-cell' style={{ width: '100px' }}>Status <span style={{ color: 'red' }}> * </span></th>
                                                                        <th className='ant-table-cell' style={{ width: '100px' }}><center>Remove</center></th>
                                                                    </tr>
                                                                </thead>
                                                                {item.ipaddress && item.ipaddress.map((items, j) => {
                                                                    console.log(items, "itemsitems12345");
                                                                    return <>
                                                                        <tbody className='ant-table-tbody'>
                                                                            <tr>
                                                                                <td className='ant-table-cell'><center>
                                                                                    {<div className="position-relative">
                                                                                        <Input

                                                                                            type='text'
                                                                                            placeholder='Enter IP Address'
                                                                                            maxLength={50}
                                                                                            value={items.ipaddress || undefined}
                                                                                            // onChange={onInputChange2}
                                                                                            onChange={(e) => {
                                                                                                console.log(items, 'iytemsd')
                                                                                                onInputChange2(e, i, j)
                                                                                            }}
                                                                                            onDrop={(e) => {
                                                                                                e.preventDefault();
                                                                                                Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
                                                                                                return false;
                                                                                            }}
                                                                                            onPaste={(e) => {
                                                                                                e.preventDefault();
                                                                                                Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
                                                                                                return false;
                                                                                            }}
                                                                                            onKeyPress={(event) => {
                                                                                                if (!/^[0-9.]+$/.test(event.key)) {
                                                                                                    event.preventDefault();
                                                                                                }
                                                                                            }}

                                                                                        />

                                                                                    </div>}</center>
                                                                                    {/* {formValidator.current.message(
                                                                                        'ipaddress',
                                                                                        AddIpGrouplist[i].ipaddress[j].ipaddress,
                                                                                        [

                                                                                            { regex: /(([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])/ },
                                                                                            `max:50`
                                                                                        ],
                                                                                        {
                                                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                            messages: {
                                                                                                required: 'Enter Valid IP Address',
                                                                                                regex: 'Enter Valid Name',
                                                                                                max: 'Maximum character is 50 only'
                                                                                            }
                                                                                        })} */}
                                                                                </td>
                                                                                <td>
                                                                                    <div style={{ marginLeft: '45%' }}> {items.status == 1 ?
                                                                                        <span
                                                                                            className="social-link btn btn-sm btn-light-success
text-success btn-circle d-flex align-items-center justify-content-center"onClick={(e) => { statuschangeAdd(0, i, j) }}
                                                                                        >
                                                                                            <Tooltip title="Enabled" ><CheckOutlined /></Tooltip>
                                                                                        </span>
                                                                                        :
                                                                                        <span
                                                                                            className="social-link btn btn-sm btn-light-danger
text-danger btn-circle d-flex align-items-center justify-content-center"
                                                                                            onClick={(e) => { statuschangeAdd(1, i, j) }}    >
                                                                                            <Tooltip title='Disabled'  ><StopOutlined /></Tooltip>
                                                                                        </span>
                                                                                    }
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div style={{ marginLeft: '45%' }}>
                                                                                        {<Tooltip title={'Remove'} >
                                                                                            <span className="social-link btn btn-sm btn-light-danger
                              text-danger btn-circle d-flex align-items-center justify-content-center"
                                                                                                onClick={() => {
                                                                                                    let array = AddIpGrouplist[i].ipaddress
                                                                                                    if (array && array.length > 0) {
                                                                                                        console.log(array, "arrayy56577");
                                                                                                        for (let k in array) {
                                                                                                            if (j == k) {
                                                                                                                if (array[j].ipaddress !== '') {
                                                                                                                    Swal.fire({
                                                                                                                        title: 'Are You Sure?',
                                                                                                                        icon: 'success',
                                                                                                                        showCancelButton: true,
                                                                                                                        customClass: localStorage.getItem("currentTheme") == 'theme_four' ? "customSWal" : 'customSWal2',
                                                                                                                        confirmButtonColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 '
                                                                                                                    }).then((result) => {
                                                                                                                        if (result.isConfirmed) {
                                                                                                                            if (array.length == 1) {
                                                                                                                                AddIpGrouplist[i].ipaddress[0].ipaddress = ''
                                                                                                                                setAddIpGrouplist([...AddIpGrouplist])
                                                                                                                            } else {
                                                                                                                                array.splice(j, 1);
                                                                                                                                AddIpGrouplist[i].ipaddress = array
                                                                                                                                setAddIpGrouplist([...AddIpGrouplist])
                                                                                                                            }
                                                                                                                        }
                                                                                                                    })
                                                                                                                } else if (array.length > 1) {
                                                                                                                    array.splice(j, 1);
                                                                                                                    AddIpGrouplist[i].ipaddress = array
                                                                                                                    setAddIpGrouplist([...AddIpGrouplist])
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                    // }
                                                                                                    AddIpGrouplist[i].ipaddress = array
                                                                                                    setAddIpGrouplist([...AddIpGrouplist])
                                                                                                    // })
                                                                                                    // } else {
                                                                                                    //     if (array && array.length > 1) {
                                                                                                    //         console.log(array, "arrayy56577");
                                                                                                    //         for (let k in array) {
                                                                                                    //             if (j == k) {
                                                                                                    //                 array.splice(j, 1);
                                                                                                    //             }
                                                                                                    //         }
                                                                                                    //     }
                                                                                                    AddIpGrouplist[i].ipaddress = array
                                                                                                    setAddIpGrouplist([...AddIpGrouplist])

                                                                                                    // }
                                                                                                    // }

                                                                                                }}><DeleteOutlined style={{ color: "red" }}
                                                                                                /></span></Tooltip>}
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </>
                                                                })}
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row' style={{ textAlign: 'end' }}>
                                                <div>
                                                    <Tooltip title="" placement='left'> <Button
                                                        type="primary"
                                                        shape="round"
                                                        className='addbutton'
                                                        style={{ marginTop: "5px", marginBottom: "5px" }}
                                                        icon={<PlusCircleOutlined />}
                                                        onClick={() => { Addtablerow(i) }}
                                                    >
                                                        Add IP Address
                                                    </Button>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                    {/* {container} */}
                                    <div id='sample'></div>
                                    <div id="parentDup">

                                    </div>
                                </Card>


                                <div className="row mb-2">
                                    <div className="col-md-12">
                                        <div className="col-md-6" style={{ marginLeft: '42%', marginTop: '10px' }}>
                                            <Button type="primary" shape="round" className={'addbutton me-2 ' + localStorage.getItem('currentTheme')}
                                                icon={<PlayCircleOutlined />} size='default'
                                                onClick={saveRecords}
                                            >
                                                Submit
                                            </Button>
                                            <Button type="primary"
                                                shape="round"
                                                onClick={CloseIcon}
                                                icon={<CloseCircleOutlined />} className={'addbutton me-2 ' + localStorage.getItem('currentTheme')} >
                                                Cancel
                                            </Button>
                                        </div>
                                    </div>

                                </div>

                            </Panel>
                        </Collapse>
                    </div>
                </>
                    : ""}
                {view == 'edit' ? <>
                    <div className={localStorage.getItem('currentTheme')}>
                        <Collapse className={' report-collapse mb-1 mt-1 ' + localStorage.getItem('currentTheme')} accordion defaultActiveKey={["1"]}>
                            <Panel header={"IP Settings"} key="1">
                                <div className='row p-1 mb-1'>
                                    <div className='col-md-6'>
                                        <label><b>IP Group Name :</b><span style={{ color: "red" }}> * </span> </label>
                                        <Input style={{ marginTop: "5px", width: "100%" }}
                                            type="text"
                                            id="ip_group"
                                            name='ip_group'
                                            disabled
                                            autoComplete={'off'}
                                            value={Ipsettingsdata.ip_group || undefined}
                                            maxLength={50}
                                        />
                                    </div>
                                </div>
                                <div className="ant-table-wrapper">
                                    <div className='ant-table ant-table-bordered'>
                                        <div className='ant-table-container'>
                                            <div className={'ant-table-content userprivclass1 ' + localStorage.getItem('currentTheme')}>
                                                <table>
                                                    <colgroup>
                                                        <col style={{ width: '10px' }}></col>
                                                    </colgroup>
                                                    <thead className='ant-table-thead'>
                                                        <tr>
                                                            <th className='ant-table-cell' style={{ width: '192px' }}>IP Address <span style={{ color: 'red' }}> * </span></th>
                                                            <th className='ant-table-cell' style={{ width: '100px' }}>Status <span style={{ color: 'red' }}> * </span> </th>
                                                            <th className='ant-table-cell' style={{ width: '100px' }}><center>Remove</center></th>
                                                        </tr>
                                                    </thead>
                                                    {editIP && editIP.length > 0 ? editIP.map((items, i) => {
                                                        return <>
                                                            <tbody className='ant-table-tbody'>
                                                                <tr>
                                                                    <td className='ant-table-cell'><center>{<div className="position-relative">
                                                                        <Input
                                                                            disabled={items.value == 0 ? true : false}
                                                                            type='text'
                                                                            placeholder='Enter IP Address'
                                                                            maxLength={50}
                                                                            value={items.ipaddress || undefined}
                                                                            onChange={(e) => {
                                                                                console.log(items, 'iytemsd')
                                                                                onInputChange1(e, items.ip_address_id, i)
                                                                            }}
                                                                            onDrop={(e) => {
                                                                                e.preventDefault();
                                                                                Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
                                                                                return false;
                                                                            }}
                                                                            onPaste={(e) => {
                                                                                e.preventDefault();
                                                                                Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
                                                                                return false;
                                                                            }}
                                                                            onKeyPress={(event) => {
                                                                                if (!/^[0-9.]+$/.test(event.key)) {
                                                                                    event.preventDefault();
                                                                                }
                                                                            }}
                                                                        />
                                                                        {/* {formValidator1.current.message(
                                                                            'ipaddress',
                                                                            editIP[i].ipaddress,
                                                                            [
                                                                                'required',
                                                                                { regex: /(([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])/ },

                                                                                `max:50`
                                                                            ],
                                                                            {
                                                                                className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                                messages: {
                                                                                    required: 'Enter Valid IP Address',
                                                                                    regex: 'Enter Valid Name',
                                                                                    max: 'Maximum character is 50 only'
                                                                                }
                                                                            })} */}

                                                                    </div>}</center>

                                                                    </td>
                                                                    <td>
                                                                        <div style={{ marginLeft: '45%' }}> {items.ip_status == 1 ?
                                                                            <span
                                                                                className="social-link btn btn-sm btn-light-success
  text-success btn-circle d-flex align-items-center justify-content-center" onClick={(e) => { statuschange(0, items.ip_address_id, i) }}
                                                                            >
                                                                                <Tooltip title="Enabled" ><CheckOutlined /></Tooltip>
                                                                            </span>
                                                                            :
                                                                            <span
                                                                                className="social-link btn btn-sm btn-light-danger
                              text-danger btn-circle d-flex align-items-center justify-content-center"  onClick={(e) => { statuschange(1, items.ip_address_id, i) }}
                                                                            >
                                                                                <Tooltip title='Disabled' ><StopOutlined /></Tooltip>
                                                                            </span>
                                                                        }
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div style={{ marginLeft: '45%' }}>
                                                                            {<Tooltip title={'Remove'} >
                                                                                <span className="social-link btn btn-sm btn-light-danger
                                                               text-danger btn-circle d-flex align-items-center justify-content-center"
                                                                                    // onClick={() => {
                                                                                    //     let array = editIP
                                                                                    //     console.log(array, 'array123');
                                                                                    //     if (array && array.length > 1) {
                                                                                    //         for (let j in array) {
                                                                                    //             if (i == j) {
                                                                                    //                 array.splice(i, 1);
                                                                                    //             }
                                                                                    //         }
                                                                                    //     }
                                                                                    //     setEditIp([...array])
                                                                                    // }}
                                                                                    onClick={() => {
                                                                                        let array = editIP
                                                                                        if (array && array.length > 0) {
                                                                                            console.log(array, "arrayy56577");
                                                                                            for (let k in array) {
                                                                                                if (i == k) {

                                                                                                    if (array[i].ipaddress !== '') {
                                                                                                        Swal.fire({
                                                                                                            title: 'Are You Sure?',
                                                                                                            icon: 'success',
                                                                                                            showCancelButton: true,
                                                                                                            customClass: localStorage.getItem("currentTheme") == 'theme_four' ? "customSWal" : 'customSWal2',
                                                                                                            confirmButtonColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 '
                                                                                                        }).then((result) => {
                                                                                                            if (result.isConfirmed) {
                                                                                                                if (array.length == 1) {
                                                                                                                    array[k].ipaddress
                                                                                                                        = ''
                                                                                                                    setEditIp([...array])
                                                                                                                }
                                                                                                                else {
                                                                                                                    array.splice(k, 1)
                                                                                                                    setEditIp([...array])
                                                                                                                }
                                                                                                            }
                                                                                                        })
                                                                                                    } else if (array.length > 1) {
                                                                                                        array.splice(k, 1)
                                                                                                        setEditIp([...array])
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                        // }
                                                                                        // AddIpGrouplist[i].ipaddress = array
                                                                                        // setAddIpGrouplist([...AddIpGrouplist])
                                                                                        // })
                                                                                        // } else {
                                                                                        //     if (array && array.length > 1) {
                                                                                        //         console.log(array, "arrayy56577");
                                                                                        //         for (let k in array) {
                                                                                        //             if (j == k) {
                                                                                        //                 array.splice(j, 1);
                                                                                        //             }
                                                                                        //         }
                                                                                        //     }
                                                                                        // AddIpGrouplist[i].ipaddress = array
                                                                                        // setAddIpGrouplist([...AddIpGrouplist])

                                                                                        // }
                                                                                        // }

                                                                                    }}
                                                                                ><DeleteOutlined style={{ color: "red" }}
                                                                                    /></span></Tooltip>}
                                                                        </div>
                                                                    </td>



                                                                </tr>
                                                            </tbody>
                                                        </>
                                                    }) : ''}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row' style={{ textAlign: 'end' }}>
                                    <div>
                                        <Tooltip title="" placement='left'> <Button
                                            type="primary"
                                            shape="round"
                                            className='addbutton'
                                            style={{ marginTop: "5px", marginBottom: "5px" }}
                                            icon={<PlusCircleOutlined />}
                                            onClick={(e) => { Edittablerow() }}
                                        >
                                            Add IP Address
                                        </Button>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-md-12">
                                        <div className="col-md-6" style={{ marginLeft: '42%', marginTop: '10px' }}>
                                            <Button type="primary" shape="round" className={'addbutton me-2 ' + localStorage.getItem('currentTheme')}
                                                icon={<PlayCircleOutlined />} size='default'
                                                onClick={UpdateRecords}
                                            >
                                                Submit
                                            </Button>
                                            <Button type="primary"
                                                shape="round"
                                                onClick={CloseIcon}
                                                icon={<CloseCircleOutlined />} className={'addbutton me-2 ' + localStorage.getItem('currentTheme')} >
                                                Cancel
                                            </Button>
                                        </div>
                                    </div>

                                </div>

                            </Panel>
                        </Collapse>
                    </div>
                </>
                    : ''
                }
            </div>
        </div >
    )
}
export default connect(mapStateToProps, {
    IPfilterdata,
    IPtabledata,
    IPSubmitAdd,
    IPSubmitUpdate,
    updatestatus,

})(IPSettings);