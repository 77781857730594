import React, { Fragment, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
    loadCaptchaEnginge,
    LoadCanvasTemplate,
    validateCaptcha
} from "react-simple-captcha";
import SimpleReactValidator from "simple-react-validator";
import { Link, Redirect, useHistory } from 'react-router-dom';
import { login } from './../Store/Action/Login'
import { FastForwardOutlined } from '@ant-design/icons';
const mapStateToProps = (state) => ({
    loginstatus: state.Login.success
})

const Login3 = ({
    login,
    loginstatus
}) => {
    const history = useHistory()
    const [Auth, SetAuth] = useState(false);
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [username, setUsername] = useState('')
    const [captchaError, SetcaptchaError] = useState(false);
    const [LoginData, setLoginData] = useState({
        userName: '',
        password: '',
        groupName: '',
        captcha: '',
    });
    const [message, setMessage] = useState('');
    const [txt, setTxt] = useState('');
    const validator = useRef(new SimpleReactValidator());
    const [pwd, setPwd] = useState('');
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [captacha, setCaptacha] = useState('')
    const [captcha, setCaptcha] = useState('');

    useEffect(() => {
        loadCaptchaEnginge(4, 'white', 'black', 'numbers');

    }, [])

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        SetAuth(localStorage.getItem('isAuthenticated'));
    }, [])

    useEffect(() => {
        if (loginstatus == false) {
            setAddFormSubmit(false);
            SetcaptchaError(false);
            setPwd('')
            setUsername('')
            setTxt('')
            setCaptacha('')
            setLoginData({
                ...LoginData,
                userName: '',
                password: '',
                groupName: '',
                captcha: '',

            })
        }
    }, [loginstatus])

    const submitLogin = (e) => {
        e.preventDefault();
        setAddFormSubmit(true);
        SetcaptchaError(false);
        if (validateCaptcha(LoginData.captcha) === true) {
            if (validator.current.allValid()) {
                login({
                    payload: LoginData,
                    navigate: history,
                    short: txt
                })

                // e.target.reset();
                // setTxt(e.target.reset())
                setUsername('')
                setPwd('')
                setCaptcha('')
                setTxt('')
            }
        } else {
            SetcaptchaError(true);
        }
    }

    const onInputChange = e => {
        const { value } = e.target;

        const re = /^[0-9a-z]+$/;
        if (value === "" || re.test(value)) {
            setLoginData({
                ...LoginData,
                groupName: e.target.value
            })
            setTxt(value);
        }
    }

    const onInputChanges = e => {
        const { value } = e.target;
        SetcaptchaError(false);

        const re = /^[0-9]+$/;
        if (value === "" || re.test(value)) {
            setLoginData({
                ...LoginData, captcha: e.target.value
            })
            setCaptcha(value);
        }
    }

    return (

        (Auth && Auth ? <Redirect to='/home' /> : <Fragment>

            <body>
                <div style={{ backgroundImage: 'url(login3-bg.png)', height: '100vh', backgroundSize: 'cover' }} className="main-wrapper-bg auth-wrapper d-flex no-block justify-content-center 
                align-items-end flex-column">
                    <div style={{ borderRadius: '2rem' }} className="auth-box p-3 bg-white m-0 me-5">
                        <center><img src="logo-icon.png" height="50px" alt='Logo' /></center>
                        <div id="loginform">
                            <div className="logo">
                                <center>
                                    <h3 className="box-title mb-1">Login</h3>
                                </center>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <form className="commentForm mt-2" onSubmit={submitLogin}>
                                        <div className="form-group mb-3 position-relative">

                                            <input className="form-control" maxLength={50} value={username} autoFocus type="text" required="" placeholder="Username" onChange={(e) => {
                                                setUsername(e.target.value)
                                                setLoginData({
                                                    ...LoginData, userName: e.target.value
                                                })
                                            }}
                                                onKeyPress={(event) => {
                                                    if (!/[a-zA-Z0-9 ]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                onPaste={(e) => {
                                                    e.preventDefault()
                                                }}
                                            />
                                            <i style={{ position: 'absolute', top: '6px', right: '7px', cursor: 'text', fontSize: '1.3rem' }} className="ri-user-line icon-override"></i>
                                            {validator.current.message(
                                                'userName',
                                                LoginData.userName,
                                                ['required',
                                                    `max:50`
                                                ],
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Enter Username',
                                                        max: 'Maximum character is 50 only'

                                                    }
                                                })}

                                        </div>

                                        <div className="form-group mb-3 position-relative">
                                            <input className="form-control"
                                                type={isRevealPwd ? "text" : "password"}
                                                value={pwd} required="" placeholder="Password"
                                                maxLength='20'
                                                onChange={(e) => {
                                                    setPwd(e.target.value)
                                                    setLoginData({
                                                        ...LoginData, password: e.target.value
                                                    })
                                                }}
                                                onPaste={(e) => {
                                                    e.preventDefault()
                                                }} />
                                            <i onClick={() => setIsRevealPwd(prevState => !prevState)} style={{ position: 'absolute', cursor: 'text', top: '6px', right: '7px', fontSize: '1.3rem' }} className={(isRevealPwd && pwd) ? "ri-eye-line icon-override" : "ri-eye-off-line icon-override"}></i>
                                            {validator.current.message(
                                                'password',
                                                LoginData.password,
                                                ['required',
                                                    `max:20`
                                                ],
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Enter Password',
                                                        max: 'Maximum character is 20 only'

                                                    }
                                                })}


                                        </div>

                                        <div className="form-group mb-3 position-relative">



                                            <input className="form-control" type="text"
                                                placeholder='Group Short Name'
                                                value={txt}
                                                maxLength='50'
                                                onChange={onInputChange}
                                                required=""
                                                onPaste={(e) => {
                                                    e.preventDefault()
                                                }}
                                                onKeyDown={(event) => {
                                                    if (!/[a-z0-9 ]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                            />
                                            <i style={{ position: 'absolute', cursor: 'text', top: '6px', right: '7px', fontSize: '1.3rem' }} className="ri-home-2-line icon-override"></i>

                                            {validator.current.message(
                                                'groupName',
                                                LoginData.groupName,
                                                ['required',
                                                    `max:50`
                                                ],
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Enter Group Short Name',
                                                        max: 'Maximum character is 50 only'

                                                    }
                                                })}


                                        </div>

                                        <div className="form-group mb-3 row">
                                            <div className="form-floating mb-2 d-flex col-4">
                                                <div className="col mt-6">
                                                    <LoadCanvasTemplate />
                                                </div>
                                            </div>
                                            <div className="col-8">
                                                <input className="form-control"
                                                    placeholder='Enter Captcha' maxLength={4}
                                                    onChange={onInputChanges}
                                                    value={captcha}
                                                    onPaste={(e) => {
                                                        e.preventDefault()
                                                    }}
                                                />
                                                {validator.current.message(
                                                    'groupName',
                                                    LoginData.captcha,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Enter Captcha',

                                                        }
                                                    })}
                                                {
                                                    LoginData.captcha != "" && captchaError == true ?
                                                        <span style={{ "color": "red" }}>Invalid Captcha</span> : null
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group mb-3 d-flex justify-content-between">

                                            <button style={{ backgroundColor: '#8572ed', borderRadius: '10px' }} type='submit' className="btn btn-primary border-0 w-50 login3-btn" onClick={submitLogin}>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <span className='fs-5'> Login</span>
                                                    <i className="ri-arrow-right-line arrow-btn-hover fs-5"></i>
                                                </div>
                                            </button>




                                            <Link style={{ borderRadius: '10px' }} to="/Forgotpassword" id="to" className="

                                                    d-flex
                                                    btn btn-primary
                                                    bg-light
                                                    border-0
                                                    align-items-center

                                                    link

                                                    font-weight-medium

                                                "><i className="ri-lock-line me-1 fs-4"></i> Forgot Password?</Link>

                                        </div>


                                        {/* </div> */}
                                    </form>

                                </div>

                            </div>

                        </div>

                    </div>


                    <div className='d-flex  align-items-end justify-content-end w-100 mt-5'>
                        <footer className="footer bg-transparent text-white  pb-0 shadow-sm" >2022© Aparajitha Software Services Private Limited <span className="ms-4">Privacy Policy · Terms & Condition </span> </footer>

                    </div>



                </div>
            </body>

        </Fragment>)


    )
}

export default connect(mapStateToProps, {
    login
})(Login3);