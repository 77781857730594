import HTTP from '../../../Libs/http'
import { Toaster } from '../../../Libs/Toaster'
import url, { getUrl } from './../../../Libs/URL'
import EncryptDecrypt from './../../../Libs/Encrypt&Decrypt'
import { decode as base64_decode } from 'base-64';
import { COMPLIANCE_FILTER, COMPLIANCE_FILTER_SHOW, COMPLIANCE_VIEW, SUCCUESS_COMPLIANCE_RESET, PASSWRD_MODAL_HANDLE, TOGGLE_COMMON_LOADER } from './../../types/index';
import alertmessage from './../../../Libs/Altermessage';
const urls = getUrl()

export const compliancefilters = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_user`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            if (decryptData[0] == 'ComplianceFiltersSuccess') {
                dispatch({
                    type: COMPLIANCE_FILTER,
                    payload: decryptData,
                });
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error(decryptData[0])
            }

        }

    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err)
    }
};

export const GetOnOccurrenceCompliances = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_user`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            if (decryptData[0] == 'GetOnOccurrenceCompliancesSuccess') {
                dispatch({
                    type: COMPLIANCE_FILTER_SHOW,
                    payload: decryptData,
                });
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error(decryptData[0])
            }

        }

    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err)
    }
};

export const onoccurrencetransaction = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_user`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            if (decryptData[0] == "OnOccurrenceLastTransactionSuccess") {
                dispatch({
                    type: COMPLIANCE_VIEW,
                    payload: decryptData,
                });
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error(decryptData[0])
            }

        }

    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err)
    }
};

export const startonoccurrencecompliance = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_user`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            console.log(decryptData, 'decryptData');
            if (decryptData[0] == "StartOnOccurrenceComplianceSuccess") {
                dispatch({
                    type: SUCCUESS_COMPLIANCE_RESET,
                    payload: decryptData[0],
                });
                dispatch({
                    type: PASSWRD_MODAL_HANDLE,
                    payload: false
                })
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.success("Compliance Task Started Successfully")
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();
            } else if (decryptData[0] === "InvalidPassword") {
                dispatch({
                    type: PASSWRD_MODAL_HANDLE,
                    payload: true
                })
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error("Invalid Password")
            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error(decryptData[0])
            }

        }

    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err)
    }
};