import Swal from 'sweetalert2';


export default class alertmessage {
    static sweetalert() {
        Swal.fire({
            title: "User Session Expired..!",
            text: "Re-Login Again",
            type: "danger",
            icon: 'error',
        }).then(function () {
            localStorage.clear();
            window.location.reload(true);
        });
    }

    static sweetalertPassword() {
        Swal.fire({
            title: "Password Changed Successfully",
            text: "",
            type: "success",
            icon: 'success',
        }).then(function () {
            localStorage.clear();
            window.location.reload(true);
        });
    }

    static sweetalertReset() {
        Swal.fire({
            title: "Password Reset Successfully",
            text: "",
            type: "success",
            icon: 'success',
        }).then(function () {
            localStorage.clear();
            window.location.href = "/login";
        });
    }

    static getapproval() {
        const count = localStorage.getItem('duplicatecount')
        Swal.fire({
            title: 'Are You Sure',
            icon: 'info',
            html:
                count +
                'Compliance declined,Do you want to continue?',
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Ok',
            cancelButtonText:
                'Cancel',
        })
    }
}
