import {TASKWISE_REPORT_DATA,UNIT_DATA,TASKWISE_REPORT,TOGGLE_COMMON_LOADER ,TASKWISE_EXPORT_DATA, GETREMARKSFILTER} from './../../types/index'
const initialState = {
    TaskWiseReports: {
        Data: [],
        TaskWiseReportList:[],
        unitData:[],
        downloadPath:[],
        count:0,
        remarks: []

    }
}
export default function (state = initialState, action) {

    const { type, payload } = action;
    switch (type) {
        // case CATEGORY_DATA: {
        //     console.log("HIIIIII");
        //     console.log(payload, "payload777");
        //     return {
        //         ...state,
        //         ConsolidatedReports: {
        //             ...state.ConsolidatedReports,
        //             Data: payload,
        //         },
        //     };
        // }
        case UNIT_DATA: {
            console.log("HIIIIII");
            console.log(payload, "avvvvvv");
            return {
                ...state,
                TaskWiseReports: {
                    ...state.TaskWiseReports,
                    unitData: payload,
                },
            };
        }
        case TASKWISE_REPORT_DATA: {
            console.log("HIIIIII");
            console.log(payload, "avvvvvv");
            return {
                ...state,
                TaskWiseReports: {
                    ...state.TaskWiseReports,
                    Data: payload,
                
                },
            };
        }
        case TASKWISE_REPORT: {
            console.log("HIIIIII");
            return {
                ...state,
                TaskWiseReports: {
                    ...state.TaskWiseReports,
                    TaskWiseReportList: payload,
                    count:payload.total_count
                },
            };
        }
         case TASKWISE_EXPORT_DATA: {
            return {
                ...state,
                TaskWiseReports: {
                    ...state.TaskWiseReports,
                    downloadPath:payload
                },
            };
        }
        case GETREMARKSFILTER: {
            return {
                ...state,
                TaskWiseReports: {
                    ...state.TaskWiseReports,
                    remarks:payload
                },
            }
        }
        default:
            return state;
    }
}
