import HTTP from "../../Libs/http";
import { Toaster } from "../../Libs/Toaster";
import url from "../../Libs/URL";
import EncryptDecrypt from "../../Libs/Encrypt&Decrypt";
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { TOGGLE_COMMON_LOADER, CURRENTSTATUSCOMMON } from "../types";


export const forgotPassword = ({ payload, executeCancel }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const forgotpayload = [
            payload.groupName, [
                'ForgotPassword', {
                    'username': payload.userId,
                    'short_name': payload.groupName,
                    'login_type': 'Web'
                }
            ]
        ]
        let encoded = base64_encode(url.encryptionKey);
        const head = {
            headers: {
                'Compfie-Info': encoded
            },
        }
        const payloadData = EncryptDecrypt.encryptdata(forgotpayload,url.encryptionKey)
        const createRequest = HTTP.post({
            url: `${url.Login}/api/login`,
            headers: head,
            executeCancel,
            body: payloadData
        })

        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        }
        else if ((executeRequest && executeRequest.response && executeRequest.response.status === 0) ||

            (executeRequest.status === 0)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Your search did not return any results. Please try again.")
            dispatch({
                type: CURRENTSTATUSCOMMON,
                payload: 'failure'
            })
        }
        else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response,url.encryptionKey))
           
            if (decryptData[0] === 'ForgotPasswordSuccess') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                dispatch({
                    type: CURRENTSTATUSCOMMON,
                    payload: 'success'
                })               
                Toaster.success("Password reset link has been sent to your email id")
            }
            else if (decryptData[0] == 'ForgotLinkHistory') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                dispatch({
                    type: CURRENTSTATUSCOMMON,
                    payload: 'failure'
                })
                Toaster.error('Forgot Password Request Limit Exceed')
            }else if(decryptData[0] == 'client not found' || decryptData[0] == 'InvalidUserName'){
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                dispatch({
                    type: CURRENTSTATUSCOMMON,
                    payload: 'failure'
                })
                Toaster.error('Your search did not return any results. Please try again')
            }else if(decryptData[0] == 'UserLocked'){
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                dispatch({
                    type: CURRENTSTATUSCOMMON,
                    payload: 'failure'
                })
                Toaster.error('Your Account is Locked. Please Contact to Administrator')
            }else if(decryptData[0] == 'ClientIpAddressNotAllowed'){
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                dispatch({
                    type: CURRENTSTATUSCOMMON,
                    payload: 'failure'
                })
                Toaster.error('Unable to process due to access from unauthenticated IP')
            }

            else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error(decryptData[0])
            }
        }



    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};