import { SERVICEPROVIDERLIST, INVALID_PASS, INVALID_PASS_RESET, HAVE_COMPLIANCE } from '../../types/index'


const initialState = {
    list: '',
    invaliderror: '',
    haveerror: ''

}
export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SERVICEPROVIDERLIST:
            {
                return {
                    ...state,
                    list: payload[1],
                };
            }
        case INVALID_PASS:
            {
                return {
                    ...state,
                    invaliderror: payload[0],
                };
            }
        case INVALID_PASS_RESET:
            {
                return {
                    ...state,
                    invaliderror: '',
                };
            }
        case HAVE_COMPLIANCE:
            {
                console.log(payload, 'payloadkkkkkk');
                return {
                    ...state,
                    haveerror: payload[0],
                };
            }
        default:
            return state;
    }
}