import React from 'react'
import { FilterOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Collapse, Select, Input, DatePicker, Card, Modal, Table, Pagination, Progress, Button, Drawer, Descriptions, Tooltip, Popover } from 'antd';
import DataTable from "react-data-table-component";
import { useState, useRef, useEffect, Fragment } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { connect, useDispatch, useSelector } from 'react-redux';
import { ExclamationCircleTwoTone, UpOutlined, FullscreenOutlined, PlayCircleOutlined, ExportOutlined, EyeOutlined } from '@ant-design/icons';
import moment from 'moment';
import { getfilterData, getTaskhistoryExport, getTaskhistoryReport } from '../../../Store/Action/Report/TaskHistoryReport';
import ScrollToTop from "react-scroll-to-top";
import { fullscreenmode, fullscreenexit, handleKeyDown } from './../../../Libs/fullscreenmode';
import useStickyHeight from "../../../Libs/useStickyHeight";
import { validationType, validateSelect, onPasteToaster } from './../../../Libs/inputValidation';
import ScrollButton from '../../Scrollbar';
import { Toaster } from '../../../Libs/Toaster';
import { getUrl } from './../../../Libs/URL'
import { CountrySplit, HomeRedirect } from './../../../Libs/country'
const uniq_country_data = CountrySplit()
const urls = getUrl()
const homepage = HomeRedirect()

const mapStateToProps = (state) => ({
    allData: state.TaskHistoryReport.taskHistoryReport.data,
    tableData: state.TaskHistoryReport.taskHistoryReport.getTableData,
    // downloadedPath:state.RiskReport.riskReport.downloadPath
})

const TaskHistoryReport = ({
    getfilterData,
    getTaskhistoryExport,
    getTaskhistoryReport,
    allData,
    tableData,
    downloadedPath,
}) => {
    useStickyHeight();
    const historynew = useHistory();
    const _ = require("lodash");
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    console.log(sessionArr, "sessionArr");
    const [getreportlist, setGetReportList] = useState([])
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [current, setCurrent] = useState(1);
    const [pageState, setpageState] = useState(false);
    const [pageSize, setPageSize] = useState(25)
    const location = useLocation();
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const dispatch = useDispatch();
    const paramid = location.pathname;
    const [entity, setEntity] = useState([])
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [tableshow, setTableShow] = useState(false)
    const [key, setKey] = useState("1")
    const [titledata, Settitledata] = useState()
    const [childCompliceId, setChildComplinaceId] = useState([])
    let [index, setIndex] = useState(0)
    const [uniqueData, setUniqueData] = useState([])
    const [valueModal, setValueModal] = useState({
        concurrer_name: '',
        Approver: ''
    })
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setfilteredArrayState] = useState([])
    const [consolidateddata, setConsolidatedDate] = useState({
        country: "",
        category: "",
        user_type: "",
        legel_entity: "",
        legel_entity_name: "",
        unit: '',
        user: "",
        domain: "",
        act: "",
        from_date: "",
        task_category: "",
        compliance_task: "",
        to_date: "",
        task_sub_category: "",
        compliance_frequency: "All",
        compliance_task_status: "All",
        division: "",
        domainName: '',
        countryName: '',
        unit_name: '',
        user_name: '',
        act_name: '',
        division_name: '',
        compliance_frequency_name: '',
        compliance_task_status_name: '',
        category_name: '',
        user_type_name: '',
        task_status: '',
        businessGroup: '',
        businessGroupName: '',
        legalEntityLabel: '',
        domainLabel: '',
        countryLabel: ''

    })
    const validator = useRef(new SimpleReactValidator());
    const [exportButton, setExportButton] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [domainList, setDomainList] = useState([])
    const [divisionList, setDivisionList] = useState([])
    const [unitCodeLabelList, setUnitCodeLabelList] = useState([])
    const [unitLegalEntity, setUnitLegalEntity] = useState([])
    const [complianceUser, setComplianceUser] = useState([])
    const [complianceUserType, setComplianceUserType] = useState([])
    const [complianceTaskStatus, setComplianceTaskStatus] = useState([])
    const [complianceFrequencyList, setComplianceFrequencyList] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [actLegalEntity, setActLegalEntity] = useState([])
    const [actName, setActName] = useState([])
    const [actNameFilteredState, setActNameFilteredState] = useState([])
    const [finalResult, SetfinalResult] = useState({
        Resultdata: []
    })

    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);
    const [showValueModal, setShowValueModal] = useState(false)
    const country_info = sessionParsedValue.country_info
    const SelectedEntity = localStorage.getItem('SelectedEntity')
    const [businessGroup, setBusinessGroup] = useState({
        bg_id: '',
        bg_name: ''
    })
    const [checkboxValue, setCheckBoxValue] = useState([2, 3, 10, 11, 13, 14])
    const [statkey, setStatKey] = useState("0")
    const [bggroup, setBggroup] = useState([]);
    const [selectSearchValue, setSelectSearchValue] = useState({
        country: '',
        legalEntity: '',
        BusinessGroup: '',
        organizationType: '',
        domain: '',
        actRules: '',
        complianceTask: '',
        user: '',
        division: '',
        category: '',
        unit: '',
    });

    const [selectAllText, setSelectAllText] = useState('Select All')

    useEffect(() => {
        if (checkboxValue.length == 15) {
            setSelectAllText('Unselect All')
        } else {
            setSelectAllText('Select All')
        }
    }, [checkboxValue])

    const ComplianceTaskName = e => {
        console.log(e, 'eeeeeeee')
        const { value } = e.target;
        // onChange={(e) => {
        let chk_valid = ''

        for (let i = 0; i < value.length; i++) {
            if (value[i] == '<' || value[i] == '>') {
                chk_valid += value[i]
            }
        }
        if (chk_valid.match('<>')) {
            //   UpdatePannelRemarks[collapseId] = ''
            setConsolidatedDate({
                ...consolidateddata,
                compliance_task: ''
            })
            Toaster.error('Please check the input, it having some invalid characters')
        } else {

            const re = /[^|~^]+$/;
            if (value === "" || re.test(value)) {
                if (value.includes('~') || value.includes('^') || value.includes('|')) {
                    return false
                } else {
                    setConsolidatedDate({
                        ...consolidateddata,
                        compliance_task: e.target.value
                    })
                }
            }
        }
    }

    useEffect(() => {
        if (sessionArr && sessionArr.length > 0) {
            let unique = _.uniqBy(sessionArr, function (e) {
                return e.bg_id;
            });
            setBggroup(unique)
        }
    }, [])

    const { Panel } = Collapse;
    const { Option } = Select;


    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };
    useEffect(() => {
        if (pageState == true) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutorySettingsHistoryWiseReport",
                        {
                            "c_id": Number(consolidateddata.country),
                            "bg_id": businessGroup.bg_id ? Number(businessGroup.bg_id) : null,
                            "le_id": entityid,
                            "d_id": Number(consolidateddata.domain),
                            "unit_id": consolidateddata.unit ? Number(consolidateddata.unit) : null,
                            "div_id": consolidateddata.division ? Number(consolidateddata.division) : null,
                            "cat_id": consolidateddata.category ? Number(consolidateddata.category) : null,
                            "parent_id": consolidateddata.act ? Number(consolidateddata.act) : 0,
                            "compliance_task": consolidateddata.compliance_task ? Number(consolidateddata.compliance_task) : null,
                            "frequency_id": consolidateddata.compliance_frequency ? Number(consolidateddata.compliance_frequency) : 0,
                            "status_name": consolidateddata.compliance_task_status,
                            "csv": false,
                            "f_count": Math.max(((current - 1) * pageSize), 0) + 1,
                            "t_count": current != 0 ? current * pageSize : pageSize,
                            "count_qry": true,
                            "list_check": null
                        }
                    ]


                }

            ]
            getTaskhistoryReport({
                payload: payload,
                paramid: paramid
            })
        }
    }, [current, pageSize, pageState])
    const handlePasting = (e, regex) => {
        var regex = regex;
        var key = e.clipboardData.getData('text')
        if (!regex.test(key)) {
            e.preventDefault();
            Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
            return false;
        }
    }
    useEffect(() => {
        let tempArr = []
        console.log(tableData, 'tableData')
        if (tableData && tableData.stat_setting_history_wise_list && tableData.stat_setting_history_wise_list.length > 25) {
            filteredArrayState && filteredArrayState.length > 0 && filteredArrayState.map((item, i) => {
                let uniqueObjArray = [...new Map(item && item.length && item.map((items) => [items["compliance_id"], items])).values()];
                const uniqueIds = uniqueObjArray && uniqueObjArray.map((itemData) => {
                    return itemData.compliance_id
                })

                for (let i in uniqueIds) {
                    let filteredArr = _.find(item && item.length && item, { compliance_id: uniqueIds[i] })
                    tempArr.push(filteredArr)
                }
            })

            setUniqueData(tempArr)

        }
    }, [filteredArrayState])
    useEffect(() => {
        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (tableData && tableData.stat_setting_history_wise_list && tableData.stat_setting_history_wise_list.length > 25) {
            // setTempeditdata(editdata)
            if (uniqueData && uniqueData.length > 0) {
                const ab = uniqueData && uniqueData.length && uniqueData.map(function (item) {
                    if ((array.indexOf(item.act_name) < 0))
                        array.push(item.act_name)
                })

                array.map((data, i) => {
                    let temp = [];
                    let entityArray = [];
                    let datavalue = [];
                    if (data) {
                        uniqueData && uniqueData.length && uniqueData.map((child, childIndex) => {
                            if (data === child.act_name) {
                                entityArray = {
                                    "act_name": child.act_name,
                                    // "approver_name": child.approver_name,
                                    // "assignee_name": child.assignee_name,
                                    "category_name": child.category_name,
                                    "compliance_description": child.compliance_description,
                                    "compliance_id": child.compliance_id,
                                    "history_id": child.history_id,
                                    "compliance_task": child.compliance_task,
                                    // "concurrer_name": child.concurrer_name,
                                    // " criticality_name": child.criticality_name,
                                    "division_name": child.division_name,
                                    "document_name": child.document_name,
                                    "download_url": child.download_url,
                                    "date": child.date,
                                    " effective_date": child.effective_date,
                                    "frequency": child.frequency,
                                    "task_status": child.task_status,
                                    " tz_name": child.tz_name,
                                    "unit": child.unit,
                                    "unit_address": child.unit_address,
                                    "unit_id": child.unit_id,
                                    "user_name": child.user_name,
                                    'childId': childIndex,
                                    'parentId': i
                                }
                                temp.push(entityArray)
                            }
                        })
                    }

                    datavalue = {
                        index: i,
                        parent: data,
                        child: temp
                    }
                    finalarray.push(datavalue)
                })
                Settitledata(finalarray)

            }
        }
        else {

            if (tableData && tableData.stat_setting_history_wise_list && tableData.stat_setting_history_wise_list.length > 0) {
                const ab = tableData && tableData.stat_setting_history_wise_list && tableData.stat_setting_history_wise_list.length && tableData.stat_setting_history_wise_list.map(function (item) {
                    if ((array.indexOf(item.act_name) < 0))
                        array.push(item.act_name)
                })

                array.map((data, i) => {
                    let temp = [];
                    let entityArray = [];
                    let datavalue = [];
                    if (data) {
                        tableData && tableData.stat_setting_history_wise_list && tableData.stat_setting_history_wise_list.length && tableData.stat_setting_history_wise_list.map((child, childIndex) => {
                            if (data === child.act_name) {
                                entityArray = {
                                    "act_name": child.act_name,
                                    // "approver_name": child.approver_name,
                                    // "assignee_name": child.assignee_name,
                                    "category_name": child.category_name,
                                    "compliance_description": child.compliance_description,
                                    "compliance_id": child.compliance_id,
                                    "history_id": child.history_id,
                                    "compliance_task": child.compliance_task,
                                    // "concurrer_name": child.concurrer_name,
                                    // " criticality_name": child.criticality_name,
                                    "division_name": child.division_name,
                                    "document_name": child.document_name,
                                    "download_url": child.download_url,
                                    "date": child.date,
                                    " effective_date": child.effective_date,
                                    "frequency": child.frequency,
                                    "task_status": child.task_status,
                                    " tz_name": child.tz_name,
                                    "unit": child.unit,
                                    "unit_address": child.unit_address,
                                    "unit_id": child.unit_id,
                                    "user_name": child.user_name,
                                    'childId': childIndex,
                                    'parentId': i
                                }
                                temp.push(entityArray)
                            }
                        })
                    }

                    datavalue = {
                        index: i,
                        parent: data,
                        child: temp
                    }
                    finalarray.push(datavalue)
                })
                Settitledata(finalarray)

            }

        }

    }, [tableData && tableData.stat_setting_history_wise_list, uniqueData])
    useEffect(() => {
        if (tableData && tableData.stat_setting_history_wise_list && tableData.stat_setting_history_wise_list.length > 0) {
            let uniqueObjArray = [...new Map(tableData && tableData.stat_setting_history_wise_list && tableData.stat_setting_history_wise_list.length && tableData.stat_setting_history_wise_list.map((item) => [item["unit_id"], item])).values()];
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.unit_id
            })

            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(tableData && tableData.stat_setting_history_wise_list && tableData.stat_setting_history_wise_list.length && tableData.stat_setting_history_wise_list, { unit_id: uniqueIds[i] })

                tempArr.push(filteredArr)
            }
            setFilteredArray([...filteredArray, tempArr])

        }

    }, [tableData && tableData.stat_setting_history_wise_list])
    useEffect(() => {
        if (filteredArray.length) {
            let temp = []
            for (let i in filteredArray) {
                temp = filteredArray[i]
            }
            setfilteredArrayState([...temp])
        }

    }, [filteredArray])

    useEffect(() => {
        if (entityid === null) {

            setCurrentEntity(consolidateddata.legel_entity)
        }
    }, [entityid])

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        if (entityid === null) {

            setCurrentEntity(consolidateddata.legel_entity)
        }
    }, [entityid])
    useEffect(() => {
        if (SelectedEntity != 'All Legal Entity') {
            let businessName = _.filter(sessionArr, { le_name: SelectedEntity })
            setBusinessGroup({
                ...businessGroup,
                bg_id: businessName && businessName.length > 0 && businessName[0].bg_id,
                bg_name: businessName && businessName.length > 0 && businessName[0].bg_name
            })
        }
    }, [SelectedEntity])

    useEffect(() => {
        if (localStorage.getItem("SelectedEntity") !== "All Legal Entity") {
            let temp = localStorage.getItem("SelectedEntity")
            let tempcountry = _.filter(sessionArr, { le_name: temp })
            setConsolidatedDate({
                ...consolidateddata,
                country: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_id : '',
                countryName: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_name : ''
            })
        }
    }, [localStorage.getItem("SelectedEntity") !== "All Legal Entity"])

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutorySettingsHistoryWiseFilters",
                        {
                            "le_id": entityid
                        }
                    ]
                }
            ]
            if (entityid != 'null') {
                getfilterData({
                    payload: payload,
                    paramid: paramid
                })
            }
        }
    }, [isAuth, authtoken, businessGroup, paramid, entityid])

    useEffect(() => {
        if (allData) {
            setActLegalEntity(allData && allData.acts)
            setCategoryList(allData && allData.cat_infos)
            setComplianceFrequencyList(allData && allData.compliance_frequency)
            setUnitLegalEntity(allData && allData.units)
            setDivisionList(allData && allData.div_infos)
            setDomainList(allData && allData.domains)
        }

    }, [allData])

    const onshow = () => {
        setAddFormSubmit(true)

        if (validator.current.allValid()) {
            setfilteredArrayState([])
            Settitledata([])
            setCurrent(1);
            setpageState(false)
            setPageSize(25)
            setIndex(0)
            setExitCollapse(true);
            setFilterModalVisible(false);
            setFilterTaskAcc(false);
            setTableShow(true)
            setKey(0)
            setStatKey(1)
            setConsolidatedDate({
                ...consolidateddata,
                countryLabel: consolidateddata.countryName,
                legalEntityLabel: consolidateddata.legel_entity_name,
                domainLabel: consolidateddata.domainName
            })
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutorySettingsHistoryWiseReport",
                        {
                            "c_id": Number(consolidateddata.country),
                            "bg_id": businessGroup.bg_id ? Number(businessGroup.bg_id) : null,
                            "le_id": entityid,
                            "d_id": Number(consolidateddata.domain),
                            "unit_id": consolidateddata.unit ? Number(consolidateddata.unit) : null,
                            "div_id": consolidateddata.division ? Number(consolidateddata.division) : null,
                            "cat_id": consolidateddata.category ? Number(consolidateddata.category) : null,
                            "parent_id": consolidateddata.act ? Number(consolidateddata.act) : 0,
                            "compliance_task": consolidateddata.compliance_task ? Number(consolidateddata.compliance_task) : null,
                            "frequency_id": consolidateddata.compliance_frequency ? Number(consolidateddata.compliance_frequency) : 0,
                            "status_name": consolidateddata.compliance_task_status,
                            "csv": false,
                            "f_count": 1,
                            "t_count": 25,
                            "count_qry": true,
                            "list_check": null
                        }
                    ]

                }
            ]
            getTaskhistoryReport({
                payload: payload,
                paramid: paramid
            })
        }
    }

    useEffect(() => {
        if (pageState === true) {
            setIndex(pageSize * (current - 1))
        }
    }, [pageState, pageSize, current])

    const showModal = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setIsModalOpen(true);
        }
    };
    const handleOk = () => {
        const myArray = checkboxValue.sort((a, b) => a - b)
        let status = myArray.includes(0);
        if (!status) {
            myArray.unshift(0);
        }

        setAddFormSubmit(true)
        setExportButton(true)
        // setCheckBoxValue([2, 3, 10, 11, 13, 14])
        if (validator.current.allValid()) {
            // setTableShow(true)
            setKey(0)
            setStatKey(1)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutorySettingsHistoryWiseReport",
                        {
                            "c_id": Number(consolidateddata.country),
                            "bg_id": businessGroup.bg_id ? Number(businessGroup.bg_id) : null,
                            "le_id": entityid,
                            "d_id": Number(consolidateddata.domain),
                            "unit_id": consolidateddata.unit ? Number(consolidateddata.unit) : null,
                            "div_id": consolidateddata.division ? Number(consolidateddata.division) : null,
                            "cat_id": consolidateddata.category ? Number(consolidateddata.category) : null,
                            "parent_id": consolidateddata.act ? Number(consolidateddata.act) : 0,
                            "compliance_task": consolidateddata.compliance_task ? Number(consolidateddata.compliance_task) : null,
                            "frequency_id": consolidateddata.compliance_frequency ? Number(consolidateddata.compliance_frequency) : 0,
                            "status_name": consolidateddata.compliance_task_status,
                            "csv": true,
                            "f_count": 1,
                            "t_count": 25,
                            "count_qry": true,
                            "list_check": checkboxValue
                        }
                    ]

                }
            ]
            getTaskhistoryExport({
                payload: payload,
                paramid: paramid
            })
        }
        setIsModalOpen(false);
    };
    const chilData = (id, unitId) => {
        setIsModalVisible(true)
        if (tableData && tableData.statutory_settings_unit_wise_list && tableData.statutory_settings_unit_wise_list.length > 25) {
            let tempArr = []
            for (let i in tableData && tableData.statutory_settings_unit_wise_list && tableData.statutory_settings_unit_wise_list.length && tableData.statutory_settings_unit_wise_list) {
                if (tableData.statutory_settings_unit_wise_list && tableData.statutory_settings_unit_wise_list.length && tableData.statutory_settings_unit_wise_list[i].compliance_id == id) {
                    if (tableData.statutory_settings_unit_wise_list && tableData.statutory_settings_unit_wise_list.length && tableData.statutory_settings_unit_wise_list[i].unit_id == unitId) {
                        tempArr.push(tableData.statutory_settings_unit_wise_list && tableData.statutory_settings_unit_wise_list.length && tableData.statutory_settings_unit_wise_list[i])
                    }
                }
            }
            setChildComplinaceId(tempArr)

        }
    }
    const handleCancel = () => {
        setIsModalOpen(false);
        setShowValueModal(false)
        setIsModalVisible(false)
        setCheckBoxValue([2, 3, 10, 11, 13, 14])
    };
    const showValues = () => {
        setShowValueModal(true);
    };
    const onChange = (key) => {
        console.log(key);
    };

    const onStatChange = (key) => {
        console.log(key);
    }

    const checkBoxOnChange = (e) => {
        let tempIds = []
        let temp = checkboxValue
        if (temp.includes(Number(e.target.value)) == true) {
            for (let i in temp) {
                if (temp[i] == Number(e.target.value)) {
                    temp.splice(i, 1)
                }
            }
            setCheckBoxValue([...temp])
        } else {
            tempIds.push(Number(e.target.value))
            setCheckBoxValue([...checkboxValue, ...tempIds])
        }
    }

    const selectAllCheckBox = (checked) => {
        var ele = document.getElementsByName('chk');
        let tempAllIds = []
        if (checked == true) {
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox') {
                    ele[i].checked = true;
                    tempAllIds.push(Number(ele[i].value))
                }
            }
            setCheckBoxValue(tempAllIds)
        } else {
            var ele = document.getElementsByName('chk');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = false;

            }
            setCheckBoxValue([2, 3, 10, 11, 13, 14])
        }
        console.log('checkbox', checkboxValue);
    }

    const homescreen = () => {
        let getUrlDetails = homepage;
        let tempval = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_url;
        let tempkey = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_key;
        localStorage.setItem('formkey', tempkey);
        historynew.push(tempval);
        // window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }

    const [fullscreen, setfullscreen] = useState(false);

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }
    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };

    console.log(divisionList, 'divisionList');
    console.log(allData, 'allData');

    return (
        <div id='page-wrapper' className="page-wrapper">
            {/* <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
            {document.body.scrollHeight > document.body.clientHeight ?
                <div className='back-to-top'>
                    <ScrollButton />
                </div> : ''} */}
            {tableshow == true ?
                <div >
                    <ScrollButton />
                </div> : ''}
            <div className="page-titles pb-2 pt-1 page-title-sticky">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <span style={{ fontSize: '14px', fontWeight: "bold" }}>Report</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                    <span style={{ fontSize: "15px", fontWeight: "bold" }}>Statutory Setting Reports</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                    <span>Task History Report</span>
                                </li>
                            </ol>
                        </nav>

                    </div>
                    <div className="col-lg-4 text-end">
                        <Tooltip title="Advance Filter"> <Button
                            className="mx-1"
                            type="primary"
                            shape="round"
                            style={{
                                display: filterTaskAcc ? "none" : "initial",
                            }}
                            onClick={filterHandle}
                        >
                            <FilterOutlined /> Filter
                        </Button></Tooltip>
                        <button style={{ float: 'right' }} onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                            <Tooltip placement="left" title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                        </button>
                    </div>
                </div>
            </div>
            <Drawer title="Select Fields"
                className={'add-service-prv export-checkbox resize-drawer ' + localStorage.getItem('currentTheme')}
                open={isModalOpen} footer={null} onOk={handleOk} onClose={handleCancel} onCancel={handleCancel} width={500}
                maskClosable={false}>
                <div className='d-flex flex-wrap'>

                    <input id="select_all" width="100%" type="checkbox" autocomplete="off"
                        checked={checkboxValue.length === 15 ? true : false}
                        onClick={(e) => {
                            let checked = e.target.checked
                            selectAllCheckBox(checked)
                        }} /> <label for='select_all'>{selectAllText}</label>

                    <input id="column_2" name='chk' type="checkbox" checked disabled="disabled" value='2' onChange={(e) => checkBoxOnChange(e)} /><label for='column_2'> Legal Entity</label>
                    <input id="column_3" name='chk' type="checkbox" value='3' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_3'>Unit Name</label>
                    <input id="column_10" name='chk' type="checkbox" value='10' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> <label for='column_10'>Act</label>
                    <input id="column_11" name='chk' type="checkbox" value='11' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_11'>Compliance Task</label>
                    <input id="column_13" name='chk' type="checkbox" value='13' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_13'>Task Status</label>
                    <input id="column_14" name='chk' type="checkbox" value='14' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_14'>Date</label>
                    <input id="column_1" name='chk' type="checkbox" checked={checkboxValue.includes(1)} value='1' onChange={(e) => checkBoxOnChange(e)} /><label for='column_1'>Business Group</label>
                    <input id="column_4" name='chk' type="checkbox" checked={checkboxValue.includes(4)} value='4' onChange={(e) => checkBoxOnChange(e)} /><label for='column_4'>Unit Code 1</label>
                    <input id="column_5" name='chk' type="checkbox" checked={checkboxValue.includes(5)} value='5' onChange={(e) => checkBoxOnChange(e)} /><label for='column_5'>Unit Code 2 </label>
                    <input id="column_6" name='chk' type="checkbox" checked={checkboxValue.includes(6)} value='6' onChange={(e) => checkBoxOnChange(e)} /><label for='column_6'>Unit Code 3</label>
                    <input id="column_7" name='chk' type="checkbox" checked={checkboxValue.includes(7)} value='7' onChange={(e) => checkBoxOnChange(e)} /><label for='column_7'>Unit Code 4</label>
                    <input id="column_8" name='chk' type="checkbox" checked={checkboxValue.includes(8)} value='8' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_8'>Division Name</label>
                    <input id="column_9" name='chk' type="checkbox" checked={checkboxValue.includes(9)} value='9' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_9'>Category Name</label>
                    <input id="column_12" name='chk' type="checkbox" checked={checkboxValue.includes(12)} value='12' onChange={(e) => checkBoxOnChange(e)} /><label for='column_12'>Frequency</label>
                    <input id="column_15" name='chk' type="checkbox" checked={checkboxValue.includes(15)} value='15' onChange={(e) => checkBoxOnChange(e)} /><label for='column_15'>User Name</label>


                </div>
                <div className={"form-actions popupbtncolour text-center " + localStorage.getItem('currentTheme')}>
                    <Button type="primary" shape="round" className={'addbutton mt-3 ' + localStorage.getItem('currentTheme')}
                        icon={<PlayCircleOutlined />} size='default' onClick={handleOk}> Submit
                    </Button>

                </div>

            </Drawer>


            {/* <Drawer title="Select Fields"
                className={'add-service-prv resize-drawer ' + localStorage.getItem('currentTheme')}
                visible={isModalOpen} footer={null} onOk={handleOk} onClose={handleCancel} onCancel={handleCancel} width={500}>
                <div className='d-flex flex-wrap'>
                    <input id="select_all" width="100%" type="checkbox" autocomplete="off"
                        checked={checkboxValue.length === 25 ? true : false}
                        onClick={(e) => {
                            setAllCheckBoxState(true)
                            let checked = e.target.checked
                            selectAllCheckBox(checked)
                            // if(allCheckBoxState && checkboxValue === 17){

                            // }
                        }} /> <label for='select_all'>Select All</label>

                    <input id="column_1" name='chk' type="checkbox" checked disabled="disabled" value='1' onChange={(e) => (e) => checkBoxOnChange(e)} /> <label for='column_1'>Business Group</label>
                    <input id="column_2" name='chk' type="checkbox" checked disabled="disabled" value='2' onChange={(e) => checkBoxOnChange(e)} /><label for='column_2'>Legal Entity</label>
                    <input id="column_7" name='chk' type="checkbox" value='3' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_7'>Unit Code</label>
                    <input id="column_6" name='chk' type="checkbox" value='4' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_6'>Unit Name</label>
                    <input id="column_8" name='chk' type="checkbox" value='5' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_8'>Unit Code 1</label>
                    <input id="column_9" name='chk' type="checkbox" value='6' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_9'>Unit Code 2</label>
                    <input id="column_10" name='chk' type="checkbox" value='7' onChange={(e) => checkBoxOnChange(e)} /><label for='column_10'> Unit Code 3</label>
                    <input id="column_29" name='chk' type="checkbox" value='8' onChange={(e) => checkBoxOnChange(e)} /><label for='column_29'>Unit Code 4</label>

                    <input id="column_32" name='chk' type="checkbox" value='9' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_32'>Location</label>
                    <input id="column_3" name='chk' type="checkbox" value='10' onChange={(e) => checkBoxOnChange(e)} /><label for='column_3'>Division</label>
                    <input id="column_4" name='chk' type="checkbox" value='11' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_4'>Category</label>
                    <input id="column_5" name='chk' type="checkbox" value='12' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_5'>Act</label>

                    <input id="column_14" name='chk' type="checkbox" value='13' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_14'>Status</label>
                    <input id="column_15" name='chk' type="checkbox" value='14' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_15'>Compliance Task</label>
                    <input id="column_16" name='chk' type="checkbox" value='15' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_16'>Frequency</label>
                    <input id="column_17" name='chk' type="checkbox" value='16' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_17'>Periodicity</label>

                    <input id="column_18" name='chk' type="checkbox" value='17' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_18'>Criticality</label>
                    <input id="column_19" name='chk' type="checkbox" value='18' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_19'>Assignee</label>
                    <input id="column_20" name='chk' type="checkbox" value='19' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_20'>Concurrer</label>
                    <input id="column_21" name='chk' type="checkbox" value='20' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_21'>Approver</label>

                    <input id="column_22" name='chk' type="checkbox" value='21' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_22'>Start Date</label>
                    <input id="column_23" name='chk' type="checkbox" value='22' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_23'>Due Date</label>
                    <input id="column_24" name='chk' type="checkbox" value='23' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_24'>Extend Due Date</label>
                    <input id="column_25" name='chk' type="checkbox" value='24' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> <label for='column_25'>Month</label>


                    <input id="column_26" name='chk' type="checkbox" value='25' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_26'>Task Completion Date / Document Issued Date</label>
                </div>


                <div className="form-actions popupbtncolour text-center">
                    <Button type="primary" shape="round" className={'addbutton mt-3 ' + localStorage.getItem('currentTheme')}
                        icon={<PlayCircleOutlined />} size='default' onClick={handleOk}> Submit
                    </Button>

                </div>

            </Drawer> */}
            <Modal open={isModalVisible} onCancel={handleCancel} onOk={() => {
                setIsModalVisible(false)
            }} maskClosable={false}>
                <table  >
                    <tbody className='ant-table-tbody'>
                        {
                            childCompliceId && childCompliceId.length > 0 && childCompliceId.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{item.user_name}</td>
                                        <td>{item.due_date}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </Modal>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-12">
                        <div className="card-body" id='only-restricted' style={{ position: 'relative', padding: "10px 3px" }}>
                            <div className={localStorage.getItem('currentTheme')}>

                                <Collapse defaultActiveKey={["1"]} onChange={onChange} className='report-collapse' style={{ display: exitCollapse ? "none" : "block" }}>


                                    <Panel
                                        header="Task History Report"
                                        key={key}
                                    >
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-4">
                                                    <label><b>Country:  </b><span style={{ color: "red" }}>*</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                showSearch
                                                                placeholder="Enter Country"
                                                                // onPaste={(e) => {
                                                                //     handlePasting(e, /^[a-z ]+$/gi)
                                                                // }}
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                onPaste={onPasteToaster}
                                                                searchValue={selectSearchValue.country}
                                                                onSearch={(value) => {
                                                                    let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                    setSelectSearchValue({
                                                                        ...selectSearchValue,
                                                                        country: result
                                                                    });
                                                                }}
                                                                value={consolidateddata.country || undefined}
                                                                onChange={(data, value) => {
                                                                    if (data !== undefined) {
                                                                        let le = _.filter(sessionArr, { c_id: Number(data) })
                                                                        setEntity(le)
                                                                    }
                                                                    setConsolidatedDate({
                                                                        ...consolidateddata,
                                                                        country: data ? data : '',
                                                                        countryName: value ? value.children : '',
                                                                        user_type: "",
                                                                        legel_entity: "",
                                                                        legel_entity_name: "",
                                                                        unit: '',
                                                                        user: "",
                                                                        domain: "",
                                                                        act: "",
                                                                        from_date: "",
                                                                        task_category: "",
                                                                        compliance_task: "",
                                                                        to_date: "",
                                                                        task_sub_category: "",
                                                                        compliance_frequency: "All",
                                                                        compliance_task_status: "All",
                                                                        division: "",
                                                                        domainName: '',
                                                                        unit_name: '',
                                                                        user_name: '',
                                                                        act_name: '',
                                                                        division_name: '',
                                                                        compliance_frequency_name: '',
                                                                        compliance_task_status_name: '',
                                                                        category_name: '',
                                                                        user_type_name: '',
                                                                        task_status: '',
                                                                        businessGroup: '',
                                                                        businessGroupName: ''
                                                                    })

                                                                }
                                                                }
                                                                style={{ width: '100%', marginToip: '5px' }}
                                                            >
                                                                {uniq_country_data && uniq_country_data.length > 0 && uniq_country_data.map((item, i) => {

                                                                    return (

                                                                        <Option key={item.c_id}>

                                                                            {item.c_name}

                                                                        </Option>

                                                                    );

                                                                })}

                                                            </Select>

                                                            {validator.current.message(
                                                                'Country',
                                                                consolidateddata.country,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Country Required',
                                                                    }
                                                                })}

                                                        </div> :
                                                        <p>{consolidateddata.countryName}</p>
                                                    }

                                                </div>
                                                <div className='col-md-4 text-wrap-report'>
                                                    <label><b>Legal Entity: </b> <span style={{ color: "red" }}>*</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                disabled={consolidateddata.country == "" ? true : false}
                                                                placeholder="Enter Legal Entity"
                                                                // onPaste={(e) => {
                                                                //     handlePasting(e, /^[a-z 0-9 ( )]+$/gi)
                                                                // }}
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                onPaste={onPasteToaster}
                                                                searchValue={selectSearchValue.legalEntity}
                                                                onSearch={(value) => {
                                                                    let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                    setSelectSearchValue({
                                                                        ...selectSearchValue,
                                                                        legalEntity: result
                                                                    });
                                                                }}
                                                                onChange={(data, value) => {
                                                                    data && setCurrentEntity(data)
                                                                    setConsolidatedDate({
                                                                        ...consolidateddata,
                                                                        legel_entity: data ? data : '',
                                                                        legel_entity_name: value ? value.children : '',
                                                                        user_type: "",
                                                                        unit: '',
                                                                        user: "",
                                                                        domain: "",
                                                                        act: "",
                                                                        from_date: "",
                                                                        task_category: "",
                                                                        compliance_task: "",
                                                                        to_date: "",
                                                                        task_sub_category: "",
                                                                        compliance_frequency: "All",
                                                                        compliance_task_status: "All",
                                                                        division: "",
                                                                        domainName: '',
                                                                        unit_name: '',
                                                                        user_name: '',
                                                                        act_name: '',
                                                                        division_name: '',
                                                                        compliance_frequency_name: '',
                                                                        compliance_task_status_name: '',
                                                                        category_name: '',
                                                                        user_type_name: '',
                                                                        task_status: '',
                                                                    })
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                //value={Settings.le_id}
                                                                style={{ width: '100%', marginToip: '5px' }}

                                                                value={consolidateddata.legel_entity || undefined}
                                                            >
                                                                {entity && entity.length > 0 && entity.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.le_id}>
                                                                            {item.le_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'legalentity',
                                                                consolidateddata.legel_entity,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Legal Entity Required',
                                                                    }
                                                                })}
                                                        </div> :
                                                        <p>{localStorage.getItem('SelectedEntity')}</p>
                                                    }
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Domain: </b> <span style={{ color: "red" }}>*</span></label><br />
                                                    <Select
                                                        allowClear={true}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z -]+$/gi)
                                                        // }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.domain}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                domain: result
                                                            });
                                                        }}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onChange={(value, data) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                domain: value ? value : '',
                                                                domainName: data ? data.children : '',
                                                                user_type: "",
                                                                unit: '',
                                                                user: "",
                                                                act: "",
                                                                from_date: "",
                                                                task_category: "",
                                                                compliance_task: "",
                                                                to_date: "",
                                                                task_sub_category: "",
                                                                compliance_frequency: "All",
                                                                compliance_task_status: "All",
                                                                division: "",
                                                                unit_name: '',
                                                                user_name: '',
                                                                act_name: '',
                                                                division_name: '',
                                                                compliance_frequency_name: '',
                                                                compliance_task_status_name: '',
                                                                category_name: '',
                                                                user_type_name: '',
                                                                task_status: '',
                                                            })
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        disabled={((localStorage.getItem('SelectedEntity') === "All Legal Entity") && consolidateddata.legel_entity == '') ? true : false}
                                                        placeholder="Enter Domain"
                                                        style={{ width: '100%', marginToip: '5px' }}

                                                        value={consolidateddata.domainName || undefined}
                                                    >
                                                        {domainList && domainList.length > 0 && domainList.map((item, i) => {
                                                            return (
                                                                <Option key={item.d_id}>
                                                                    {item.d_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    {validator.current.message(
                                                        'domain',
                                                        consolidateddata.domain,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Domain Required',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label><b>Business Group:</b></label><br />
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                // onPaste={(e) => {
                                                                //     handlePasting(e, /^[a-z 0-9 ]+$/gi)
                                                                // }}
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                onPaste={onPasteToaster}
                                                                searchValue={selectSearchValue.BusinessGroup}
                                                                onSearch={(value) => {
                                                                    let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                    setSelectSearchValue({
                                                                        ...selectSearchValue,
                                                                        BusinessGroup: result
                                                                    });
                                                                }}
                                                                size="default"
                                                                placeholder="Enter Business Group"
                                                                onChange={(data, value) => {
                                                                    if (data == undefined) {
                                                                        setConsolidatedDate({
                                                                            ...consolidateddata,
                                                                            businessGroup: '',
                                                                            businessGroupName: '',
                                                                            legel_entity: '',
                                                                            legel_entity_name: '',
                                                                            domain: '',
                                                                            domainName: ''
                                                                        })

                                                                        let le = _.filter(sessionArr, { c_id: Number(consolidateddata.country) })
                                                                        setEntity(le)
                                                                    } else {
                                                                        setConsolidatedDate({
                                                                            ...consolidateddata,
                                                                            businessGroup: value ? value : '',
                                                                            businessGroupName: data ? data.children : '',
                                                                            legel_entity: '',
                                                                            legel_entity_name: '',
                                                                            domain: '',
                                                                            domainName: ''
                                                                        })
                                                                        let le = _.filter(sessionArr, { bg_id: Number(data) })
                                                                        setEntity(le)
                                                                    }
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={consolidateddata.businessGroupName || undefined}
                                                                style={{ width: '100%', marginToip: '5px' }}

                                                            >
                                                                {bggroup && bggroup.length > 0 && bggroup.map((item, i) => {
                                                                    if (item.bg_id != null) {
                                                                        return (
                                                                            <Option key={item.bg_id}>
                                                                                {item.bg_name}
                                                                            </Option>
                                                                        );
                                                                    }
                                                                })}
                                                            </Select>
                                                        </div> :
                                                        <p>{businessGroup.bg_name ? businessGroup.bg_name : '-'}</p>
                                                    }
                                                </div>
                                                <div className="col-4">
                                                    <label htmlFor=""><b>Division: </b></label><br />
                                                    <Select
                                                        allowClear={true}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z 0-9 ]+$/gi)
                                                        // }}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.division}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                division: result
                                                            });
                                                        }}
                                                        onChange={(value, data) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                division: value ? value : '',
                                                                division_name: data ? data.children : ''
                                                            })
                                                            let filteredCategory = _.filter(allData && allData.cat_infos, { div_id: Number(value) })
                                                            console.log(filteredCategory, 'filteredCategory');
                                                            console.log(value, 'valllll');
                                                            setCategoryList(filteredCategory)
                                                            let filteredUnit = _.filter(allData && allData.units, { division_id: Number(value) })
                                                            setUnitLegalEntity(filteredUnit)
                                                        }}
                                                        placeholder="Enter Division"
                                                        style={{ width: '100%', marginToip: '5px' }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                        value={consolidateddata.division_name || undefined}
                                                        disabled={consolidateddata.domain == '' ? true : false}
                                                    >
                                                        {divisionList && divisionList.length > 0 && divisionList.map((item, i) => {
                                                            return (
                                                                <Option key={item.div_id}>
                                                                    {item.div_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                                <div className="col-md-4">
                                                    <label><b>Category: </b></label><br />
                                                    <Select
                                                        allowClear={true}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z 0-9 ]+$/gi)
                                                        // }}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.category}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                category: result
                                                            });
                                                        }}
                                                        onChange={(value, data) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                category: value ? value : '',
                                                                category_name: data ? data.children : ''
                                                            })
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        placeholder="Enter Category"
                                                        style={{ width: '100%', marginToip: '5px' }}

                                                        value={consolidateddata.category_name || undefined}
                                                        disabled={consolidateddata.domain == '' ? true : false}
                                                    >
                                                        {categoryList && categoryList.length > 0 && categoryList.map((item, i) => {
                                                            return (
                                                                <Option key={item.cat_id}>
                                                                    {item.cat_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label htmlFor=""><b>Compliance frequency: </b></label>
                                                    <Select
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        allowClear={false}
                                                        onChange={(value, data) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                compliance_frequency: value ? value : '',
                                                                // compliance_task_status_name: data.children
                                                            })
                                                        }}
                                                        disabled={((localStorage.getItem('SelectedEntity') === "All Legal Entity") && consolidateddata.legel_entity == '') ? true : false}

                                                        // placeholder="Enter Domain Name"
                                                        style={{ width: '100%', marginToip: '5px' }}

                                                        value={consolidateddata.compliance_frequency || undefined}
                                                    >

                                                        <Option key='0'>All</Option>
                                                        {complianceFrequencyList && complianceFrequencyList.length > 0 && complianceFrequencyList.map((item, i) => {
                                                            return (

                                                                <Option key={item.frequency_id}>
                                                                    {item.frequency_name}
                                                                </Option>
                                                            );
                                                        })}

                                                    </Select>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Unit: </b></label><br />
                                                    <Select
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z 0-9 - _ . ,]+$/gi)
                                                        // }}
                                                        showSearch
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.unit}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                unit: result
                                                            });
                                                        }}
                                                        allowClear={true}
                                                        onChange={(value, data) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                unit: value ? value : '',
                                                                unit_name: data ? data.children : ''
                                                            })
                                                        }}
                                                        placeholder="Enter Unit"
                                                        style={{ width: '100%', marginToip: '5px' }}

                                                        value={consolidateddata.unit_name || undefined}
                                                        disabled={consolidateddata.domain == '' ? true : false}
                                                    >
                                                        {unitLegalEntity && unitLegalEntity.length > 0 && unitLegalEntity.map((item, i) => {
                                                            return (
                                                                <Option key={item.unit_id}>
                                                                    {item.unit_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                                <div className="col-4">
                                                    <label htmlFor=""><b>Compliance Task: </b></label>
                                                    <input placeholder="Enter Compliance Task" style={{ width: '100%', marginToip: '5px' }}
                                                        allowClear={true}
                                                        onPaste={(e) => {
                                                            handlePasting(e, /^[a-z 0-9 ( ) - _ , . / @ # &]+$/gi)
                                                        }}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onChange={ComplianceTaskName}
                                                        value={consolidateddata.compliance_task}
                                                        className='ant-input'

                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label><b>Task Status: </b></label><br />
                                                    <Select
                                                        allowClear={false}
                                                        // disabled={consolidatedData.domain == '' ? true : false}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onChange={(value) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                compliance_task_status: value ? value : ''
                                                            })
                                                        }}
                                                        // placeholder="Enter Domain Name"
                                                        style={{ width: '100%', marginToip: '5px' }}

                                                        value={consolidateddata.compliance_task_status || undefined}
                                                    >
                                                        <Option key="All">All</Option>
                                                        <Option key="Opted">Opted</Option>
                                                        <Option key="Not Opted">Not Opted</Option>
                                                        <Option key="Not Applicable">Not Applicable</Option>
                                                    </Select>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Act: </b></label><br />
                                                    <Select
                                                        showSearch
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        // onPaste={(e) => {
                                                        //     handlePasting(e, /^[a-z 0-9 ( ) - _ , .]+$/gi)
                                                        // }}
                                                        onPaste={onPasteToaster}
                                                        searchValue={selectSearchValue.actRules}
                                                        onSearch={(value) => {
                                                            let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                            setSelectSearchValue({
                                                                ...selectSearchValue,
                                                                actRules: result
                                                            });
                                                        }}
                                                        allowClear={true}
                                                        onChange={(value, data) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                act: value ? value : '',
                                                                act_name: data ? data.children : ''
                                                            })
                                                        }}
                                                        placeholder="Enter Act"
                                                        style={{ width: '100%', marginToip: '5px' }}

                                                        value={consolidateddata.act_name || undefined}
                                                        disabled={consolidateddata.domain == '' ? true : false}
                                                    >
                                                        {actLegalEntity && actLegalEntity.length > 0 && actLegalEntity.map((item, i) => {
                                                            return (
                                                                <Option key={item.parent_id}>
                                                                    {item.act}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'text-center mt-2 popupbtncolour ' + localStorage.getItem('currentTheme')} >
                                            <Button type="primary" shape="round" className={'addbutton me-1 ' + localStorage.getItem('currentTheme')}
                                                onClick={onshow} icon={<EyeOutlined />}
                                            >
                                                Show
                                            </Button>
                                            <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} onClick={showModal} icon={<ExportOutlined />}
                                            >
                                                Export
                                            </Button>
                                        </div>
                                    </Panel>
                                </Collapse>
                                <Modal
                                    onCancel={setcancelFilter}
                                    footer={false}
                                    title="Task History Report"
                                    className={
                                        "add-service-prv cons-report " +
                                        localStorage.getItem("currentTheme")
                                    }
                                    open={filterModalVisible}
                                    maskClosable={false}
                                >
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-4">
                                                <label><b>Country:  </b><span style={{ color: "red" }}>*</span></label>
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            showSearch
                                                            // onPaste={(e) => {
                                                            //     handlePasting(e, /^[a-z ]+$/gi)
                                                            // }}
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            onPaste={onPasteToaster}
                                                            searchValue={selectSearchValue.country}
                                                            onSearch={(value) => {
                                                                let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                                setSelectSearchValue({
                                                                    ...selectSearchValue,
                                                                    country: result
                                                                });
                                                            }}
                                                            placeholder="Enter Country"
                                                            value={consolidateddata.country || undefined}
                                                            onChange={(data, value) => {
                                                                if (data !== undefined) {
                                                                    let le = _.filter(sessionArr, { c_id: Number(data) })
                                                                    setEntity(le)
                                                                }
                                                                setConsolidatedDate({
                                                                    ...consolidateddata,
                                                                    country: data ? data : '',
                                                                    countryName: value ? value.children : '',
                                                                    user_type: "",
                                                                    legel_entity: "",
                                                                    legel_entity_name: "",
                                                                    unit: '',
                                                                    user: "",
                                                                    domain: "",
                                                                    act: "",
                                                                    from_date: "",
                                                                    task_category: "",
                                                                    compliance_task: "",
                                                                    to_date: "",
                                                                    task_sub_category: "",
                                                                    compliance_frequency: "All",
                                                                    compliance_task_status: "All",
                                                                    division: "",
                                                                    domainName: '',
                                                                    unit_name: '',
                                                                    user_name: '',
                                                                    act_name: '',
                                                                    division_name: '',
                                                                    compliance_frequency_name: '',
                                                                    compliance_task_status_name: '',
                                                                    category_name: '',
                                                                    user_type_name: '',
                                                                    task_status: '',
                                                                    businessGroup: '',
                                                                    businessGroupName: ''
                                                                })

                                                            }
                                                            }
                                                            style={{ width: '100%', marginToip: '5px' }}
                                                            getPopupContainer={trigger => trigger.parentNode}
                                                        >
                                                            {uniq_country_data && uniq_country_data.length > 0 && uniq_country_data.map((item, i) => {

                                                                return (

                                                                    <Option key={item.c_id}>

                                                                        {item.c_name}

                                                                    </Option>

                                                                );

                                                            })}

                                                        </Select>

                                                        {validator.current.message(
                                                            'Country',
                                                            consolidateddata.country,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Country Required',
                                                                }
                                                            })}

                                                    </div> :
                                                    <p>{consolidateddata.countryName}</p>
                                                }

                                            </div>
                                            <div className='col-md-4 text-wrap-report'>
                                                <label><b>Legal Entity: </b> <span style={{ color: "red" }}>*</span></label>
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            // onPaste={(e) => {
                                                            //     handlePasting(e, /^[a-z 0-9 ( ) ]+$/gi)
                                                            // }}
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            onPaste={onPasteToaster}
                                                            searchValue={selectSearchValue.legalEntity}
                                                            onSearch={(value) => {
                                                                let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                setSelectSearchValue({
                                                                    ...selectSearchValue,
                                                                    legalEntity: result
                                                                });
                                                            }}
                                                            disabled={consolidateddata.country == "" ? true : false}
                                                            placeholder="Enter Legal Entity"
                                                            onChange={(data, value) => {
                                                                data && setCurrentEntity(data)
                                                                setConsolidatedDate({
                                                                    ...consolidateddata,
                                                                    legel_entity: data ? data : '',
                                                                    legel_entity_name: value ? value.children : '',
                                                                    user_type: "",
                                                                    unit: '',
                                                                    user: "",
                                                                    domain: "",
                                                                    act: "",
                                                                    from_date: "",
                                                                    task_category: "",
                                                                    compliance_task: "",
                                                                    to_date: "",
                                                                    task_sub_category: "",
                                                                    compliance_frequency: "All",
                                                                    compliance_task_status: "All",
                                                                    division: "",
                                                                    domainName: '',
                                                                    unit_name: '',
                                                                    user_name: '',
                                                                    act_name: '',
                                                                    division_name: '',
                                                                    compliance_frequency_name: '',
                                                                    compliance_task_status_name: '',
                                                                    category_name: '',
                                                                    user_type_name: '',
                                                                    task_status: '',
                                                                })
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            //value={Settings.le_id}
                                                            style={{ width: '100%', marginToip: '5px' }}
                                                            getPopupContainer={trigger => trigger.parentNode}
                                                            value={consolidateddata.legel_entity || undefined}
                                                        >
                                                            {entity && entity.length > 0 && entity.map((item, i) => {
                                                                return (
                                                                    <Option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'legalentity',
                                                            consolidateddata.legel_entity,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Required',
                                                                }
                                                            })}
                                                    </div> :
                                                    <p>{localStorage.getItem('SelectedEntity')}</p>
                                                }
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Domain: </b> <span style={{ color: "red" }}>*</span></label><br />
                                                <Select
                                                    allowClear={true}
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z - ]+$/gi)
                                                    // }}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.domain}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithoutNumberSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            domain: result
                                                        });
                                                    }}
                                                    onChange={(value, data) => {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            domain: value ? value : '',
                                                            domainName: data ? data.children : '',
                                                            user_type: "",
                                                            unit: '',
                                                            user: "",
                                                            act: "",
                                                            from_date: "",
                                                            task_category: "",
                                                            compliance_task: "",
                                                            to_date: "",
                                                            task_sub_category: "",
                                                            compliance_frequency: "All",
                                                            compliance_task_status: "All",
                                                            division: "",
                                                            unit_name: '',
                                                            user_name: '',
                                                            act_name: '',
                                                            division_name: '',
                                                            compliance_frequency_name: '',
                                                            compliance_task_status_name: '',
                                                            category_name: '',
                                                            user_type_name: '',
                                                            task_status: '',
                                                        })
                                                    }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    disabled={((localStorage.getItem('SelectedEntity') === "All Legal Entity") && consolidateddata.legel_entity == '') ? true : false}
                                                    placeholder="Enter Domain"
                                                    style={{ width: '100%', marginToip: '5px' }}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    value={consolidateddata.domainName || undefined}
                                                >
                                                    {domainList && domainList.length > 0 && domainList.map((item, i) => {
                                                        return (
                                                            <Option key={item.d_id}>
                                                                {item.d_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                                {validator.current.message(
                                                    'domain',
                                                    consolidateddata.domain,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Domain Required',
                                                        }
                                                    })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label><b>Business Group:</b></label><br />
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            // onPaste={(e) => {
                                                            //     handlePasting(e, /^[a-z 0-9 ]+$/gi)
                                                            // }}
                                                            onPaste={onPasteToaster}
                                                            searchValue={selectSearchValue.BusinessGroup}
                                                            onSearch={(value) => {
                                                                let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                                setSelectSearchValue({
                                                                    ...selectSearchValue,
                                                                    BusinessGroup: result
                                                                });
                                                            }}
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            placeholder="Enter Legal Entity"
                                                            onChange={(data, value) => {
                                                                if (data == undefined) {
                                                                    setConsolidatedDate({
                                                                        ...consolidateddata,
                                                                        businessGroup: '',
                                                                        businessGroupName: '',
                                                                        legel_entity: '',
                                                                        legel_entity_name: '',
                                                                        domain: '',
                                                                        domainName: ''
                                                                    })
                                                                    let le = _.filter(sessionArr, { c_id: Number(consolidateddata.country) })
                                                                    setEntity(le)
                                                                } else {
                                                                    setConsolidatedDate({
                                                                        ...consolidateddata,
                                                                        businessGroup: value ? value : '',
                                                                        businessGroupName: data ? data.children : '',
                                                                        legel_entity: '',
                                                                        legel_entity_name: '',
                                                                        domain: '',
                                                                        domainName: ''
                                                                    })
                                                                    let le = _.filter(sessionArr, { bg_id: Number(data) })
                                                                    setEntity(le)
                                                                }
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={consolidateddata.businessGroup.value || undefined}
                                                            style={{ width: '100%', marginToip: '5px' }}
                                                            getPopupContainer={trigger => trigger.parentNode}
                                                        >
                                                            {console.log(consolidateddata.businessGroup.value, 'vvvvv')}
                                                            {bggroup && bggroup.length > 0 && bggroup.map((item, i) => {
                                                                if (item.bg_id != null) {
                                                                    return (
                                                                        <Option key={item.bg_id}>
                                                                            {item.bg_name}
                                                                        </Option>
                                                                    );
                                                                }
                                                            })}
                                                        </Select>
                                                    </div> :
                                                    <p>{businessGroup.bg_name ? businessGroup.bg_name : '-'}</p>
                                                }
                                            </div>
                                            <div className="col-4">
                                                <label htmlFor=""><b>Division: </b></label><br />
                                                <Select
                                                    allowClear={true}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z 0-9 ]+$/gi)
                                                    // }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.division}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            division: result
                                                        });
                                                    }}
                                                    onChange={(value, data) => {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            division: value ? value : '',
                                                            division_name: data ? data.children : ''
                                                        })
                                                        let filteredCategory = _.filter(allData && allData.cat_infos, { div_id: Number(value) })
                                                        setCategoryList(filteredCategory)
                                                        let filteredUnit = _.filter(allData && allData.units, { division_id: Number(value) })
                                                        setUnitLegalEntity(filteredUnit)
                                                    }}
                                                    placeholder="Enter Division"
                                                    style={{ width: '100%', marginToip: '5px' }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    value={consolidateddata.division_name || undefined}
                                                    disabled={consolidateddata.domain == '' ? true : false}
                                                >
                                                    {divisionList && divisionList.length > 0 && divisionList.map((item, i) => {
                                                        return (
                                                            <Option key={i}>
                                                                {item.div_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                            <div className="col-md-4">
                                                <label><b>Category: </b></label><br />
                                                <Select
                                                    allowClear={true}
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z 0-9 ]+$/gi)
                                                    // }}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.category}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            category: result
                                                        });
                                                    }}
                                                    onChange={(value, data) => {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            category: value ? value : '',
                                                            category_name: data ? data.children : ''
                                                        })
                                                    }}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    placeholder="Enter Category"
                                                    style={{ width: '100%', marginToip: '5px' }}

                                                    value={consolidateddata.category_name || undefined}
                                                    disabled={consolidateddata.domain == '' ? true : false}
                                                >
                                                    {categoryList && categoryList.length > 0 && categoryList.map((item, i) => {
                                                        return (
                                                            <Option key={item.cat_id}>
                                                                {item.cat_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label htmlFor=""><b>Compliance frequency: </b></label>
                                                <Select
                                                    allowClear={false}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onChange={(value, data) => {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            compliance_frequency: value ? value : '',
                                                            // compliance_task_status_name: data.children
                                                        })
                                                    }}
                                                    // placeholder="Enter Domain Name"
                                                    style={{ width: '100%', marginToip: '5px' }}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    value={consolidateddata.compliance_frequency || undefined}
                                                >

                                                    <Option key='0'>All</Option>
                                                    {complianceFrequencyList && complianceFrequencyList.length > 0 && complianceFrequencyList.map((item, i) => {
                                                        return (

                                                            <Option key={item.frequency_id}>
                                                                {item.frequency_name}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Unit: </b></label><br />
                                                <Select
                                                    allowClear={true}
                                                    showSearch
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z 0-9 , . - _ ]+$/gi)
                                                    // }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.unit}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            unit: result
                                                        });
                                                    }}
                                                    onChange={(value, data) => {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            unit: value ? value : '',
                                                            unit_name: data ? data.children : ''
                                                        })
                                                    }}
                                                    placeholder="Enter Unit"
                                                    style={{ width: '100%', marginToip: '5px' }}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    value={consolidateddata.unit_name || undefined}
                                                    disabled={consolidateddata.domain == '' ? true : false}
                                                >
                                                    {unitLegalEntity && unitLegalEntity.length > 0 && unitLegalEntity.map((item, i) => {
                                                        return (
                                                            <Option key={item.unit_id}>
                                                                {item.unit_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                            <div className="col-4">
                                                <label htmlFor=""><b>Compliance Task: </b></label>
                                                <input placeholder="Enter Compliance Task" style={{ width: '100%', marginToip: '5px' }}
                                                    allowClear={true}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z 0-9 ( ) - _ , . / @ # &]+$/gi)
                                                    }}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onChange={ComplianceTaskName}
                                                    value={consolidateddata.compliance_task}
                                                    className='ant-input'

                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label><b>Task Status: </b></label><br />
                                                <Select
                                                    allowClear={false}
                                                    // disabled={consolidatedData.domain == '' ? true : false}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onChange={(value) => {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            compliance_task_status: value ? value : ''
                                                        })
                                                    }}
                                                    // placeholder="Enter Domain Name"
                                                    style={{ width: '100%', marginToip: '5px' }}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    value={consolidateddata.compliance_task_status || undefined}
                                                >
                                                    <Option key="All">All</Option>
                                                    <Option key="Opted">Opted</Option>
                                                    <Option key="Not Opted">Not Opted</Option>
                                                    <Option key="Not Applicable">Not Applicable</Option>
                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Act: </b></label><br />
                                                <Select
                                                    showSearch
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    // onPaste={(e) => {
                                                    //     handlePasting(e, /^[a-z 0-9 ( ) - _ , .]+$/gi)
                                                    // }}
                                                    onPaste={onPasteToaster}
                                                    searchValue={selectSearchValue.actRules}
                                                    onSearch={(value) => {
                                                        let result = validateSelect(value, validationType.textWithNumbersNoSpChr);
                                                        setSelectSearchValue({
                                                            ...selectSearchValue,
                                                            actRules: result
                                                        });
                                                    }}
                                                    allowClear={true}
                                                    onChange={(value, data) => {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            act: value ? value : '',
                                                            act_name: data ? data.children : ''
                                                        })
                                                    }}
                                                    placeholder="Enter Act"
                                                    style={{ width: '100%', marginToip: '5px' }}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    value={consolidateddata.act_name || undefined}
                                                    disabled={consolidateddata.domain == '' ? true : false}
                                                >
                                                    {actLegalEntity && actLegalEntity.length > 0 && actLegalEntity.map((item, i) => {
                                                        return (
                                                            <Option key={item.parent_id}>
                                                                {item.act}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'text-center mt-2 popupbtncolour ' + localStorage.getItem('currentTheme')} >
                                        <Button type="primary" shape="round" className={'addbutton me-1 ' + localStorage.getItem('currentTheme')}
                                            onClick={onshow} icon={<EyeOutlined />}
                                        >
                                            Show
                                        </Button>
                                        <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} onClick={showModal} icon={<ExportOutlined />}
                                        >
                                            Export
                                        </Button>
                                    </div>
                                </Modal>
                            </div>
                            {/* <div className={localStorage.getItem('currentTheme')}>
                                {tableshow == true ? <Collapse defaultActiveKey={["1"]} onChange={onStatChange} className='report-collapse' style={{ marginTop: '1%' }} >
                                    <Panel header="Overall Statistics" key={statkey}>
                                        <div className="col-md-12" style={{ marginLeft: '5%', marginRight: '5%' }}>
                                            <div className="row m-2">
                                                <div className="col-md-4">
                                                    <Progress type="circle" percent={100} width={90} format={(percent) => `${250}`} />
                                                    <h4 style={{ marginLeft: '5%' }}>Opted</h4>

                                                </div>
                                                <div className="col-md-4">
                                                    <Progress type="circle" percent={10} width={90} status="exception" format={(percent) => `${25}`} />
                                                    <h4>Not Opted</h4>

                                                </div>
                                                <div className="col-md-4">
                                                    <Progress type="circle" percent={70} width={90} strokeColor={{ '0%': '#f5b342', '100%': '#f5b342' }} format={(percent) => `${225}`} />
                                                    <h4 style={{ marginRight: '5%' }}>Not Applicable</h4>

                                                </div>
                                            </div>
                                        </div>
                                    </Panel>
                                </Collapse> : false}
                            </div> */}
                            {tableshow == true ?
                                <Card className='rounded-3'>

                                    <div className='col-md-12 mt-0 mb-2 filter-detial-sticky' >
                                        <div className='row'>
                                            <div className='col-md-4 text-wrap-report'>
                                                <label><b>Legal Entity : </b> {localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? consolidateddata.legalEntityLabel : localStorage.getItem('SelectedEntity')}</label>
                                            </div>
                                            <div className='col-md-4 text-center'>
                                                <label><b>Country : </b> {consolidateddata.countryLabel}</label>
                                            </div>
                                            <div className='col-md-4 text-end'>
                                                <label><b>Domain : </b> {consolidateddata.domainLabel}</label>
                                            </div>

                                        </div>
                                    </div>
                                    <div>
                                        <div className={localStorage.getItem('currentTheme')}>
                                            <div className='ant-table ant-table-bordered ant-table-scroll-horizontal'>
                                                <div className='ant-table-wrapper'>
                                                    <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                        <div className='ant-table-container'>
                                                            <div className='ant-table-content audittrailtable'>
                                                                <table>
                                                                    <colgroup>
                                                                        <col style={{ width: '10px' }}></col>
                                                                    </colgroup>
                                                                    <thead className="ant-table-thead table-head-stick">
                                                                        <tr>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '5px' }}>#</th>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '200px' }}>Compliance Task</th>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '80px' }}>Compliance Frequency</th>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '80px' }}>Task Status</th>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '60px' }}><center>Date</center></th>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '90px' }}><center>User Name</center></th>
                                                                            <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '60px' }}><center>Format</center></th>
                                                                            {/* <th className='ant-table-cell custom-width1'><center>Activity Date</center></th>
                                                            <th className='ant-table-cell custom-width1'><center>Uploaded Document</center></th>
                                                            <th className='ant-table-cell custom-width1'><center>Task Completion Date/Document issued Date</center></th> */}
                                                                        </tr>
                                                                    </thead>
                                                                    {filteredArrayState && filteredArrayState.length > 0 ?
                                                                        filteredArrayState.map((item, i) => {

                                                                            return (
                                                                                <tbody className='ant-table-tbody' key={i}>

                                                                                    <tr className="bg-lightdark">
                                                                                        <td style={{ paddingLeft: '0 !important', paddingRight: '0 !important' }} colSpan="100%">
                                                                                            <div className='bg-white shadow-sm border-radius-reports'>
                                                                                                <div className="col-md-12">
                                                                                                    <div className='row m-2'>
                                                                                                        <div className='col-md-3' >
                                                                                                            <label><b>Unit :</b> {item[0].unit}</label>
                                                                                                        </div>
                                                                                                        <div className='col-md-3' >
                                                                                                            <label><b>Division :</b> {item[0].division_name}</label>
                                                                                                        </div>
                                                                                                        <div className='col-md-3' >
                                                                                                            <label><b>Category :</b> {item[0].category_name}</label>
                                                                                                        </div>
                                                                                                        <div className='col-md-3' >
                                                                                                            <label><b>Time Zone :</b> {item[0].tz_name}</label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    {titledata && titledata.map((itemdata, i) => {
                                                                                        let data = _.find(itemdata.child, { unit_id: item[0].unit_id })

                                                                                        if (data != undefined) {
                                                                                            if (data.act_name == itemdata.parent) {


                                                                                                return <Fragment key={i}>

                                                                                                    <tr className='ant-table-row ant-table-row-level-0'>

                                                                                                        <td colSpan={12} className='ant-table-cell'><b>{itemdata.parent}</b></td>
                                                                                                    </tr>

                                                                                                    {itemdata.child.map((items, itemskj) => {
                                                                                                        const content = (

                                                                                                            <table style={{ width: "300px", display: 'block', height: '100px' }} className="table table-striped" >
                                                                                                                <tbody>
                                                                                                                    <tr>
                                                                                                                        <th>Concurrer</th>
                                                                                                                        <th>Approver</th>

                                                                                                                    </tr>
                                                                                                                    <tr>
                                                                                                                        <td>{items.concurrer_name}</td>
                                                                                                                        <td>{items.assignee_name}</td>
                                                                                                                    </tr>


                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                        )
                                                                                                        if (item[0].unit_id == items.unit_id) {
                                                                                                            let array = []

                                                                                                            return <tr key={itemskj}>
                                                                                                                <td className='ant-table-cell'><center>{index + 1}</center></td>

                                                                                                                <td title={items.compliance_task} className='ant-table-cell'><Tooltip title={items.compliance_description}>
                                                                                                                    <span className='text-primary'>
                                                                                                                        <i className="ri-information-fill me-1" /></span>
                                                                                                                </Tooltip> {items.compliance_task}</td>
                                                                                                                <td className='ant-table-cell'>{items.frequency}</td>
                                                                                                                <td className='ant-table-cell'>{items.task_status}</td>
                                                                                                                <td className='ant-table-cell'><center>{items.date}</center></td>

                                                                                                                <td className='ant-table-cell'><>
                                                                                                                    {items.user_name}

                                                                                                                </></td>
                                                                                                                <td className='ant-table-cell'>{
                                                                                                                    <a href={`${urls + '/compliance_format/' + items.download_url}`} target="_blank" >
                                                                                                                        {items.document_name}
                                                                                                                    </a>}</td>
                                                                                                                {/* </tr> */}
                                                                                                                <span hidden>{index = index + 1}</span>

                                                                                                            </tr>
                                                                                                        }

                                                                                                    })

                                                                                                    }
                                                                                                </Fragment>
                                                                                            }
                                                                                        }
                                                                                    })
                                                                                    }
                                                                                </tbody>
                                                                            )
                                                                        }) :
                                                                        <tr>
                                                                            <td colSpan="100%" style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                                        </tr>
                                                                    }
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='row mt-3'>
                                        {index != 0 ? <>
                                            <div className='col-md-6'>
                                                <label>Showing {pageSize * (current - 1) + 1} to {index} of {tableData.total_count && tableData.total_count[0]} entries</label>
                                            </div>
                                            <div className='col-md-6 text-right'>

                                                <Pagination
                                                    current={current}
                                                    showSizeChanger={true}
                                                    onShowSizeChange={() => {
                                                        setCurrent(1)
                                                    }}
                                                    pageSizeOptions={[25, 50, 100]}
                                                    hideOnSinglePage={tableData.total_count && tableData.total_count[0] > 25 ? false : true}
                                                    defaultPageSize={25}
                                                    onChange={(page, pageSizes) => {
                                                        setpageState(true)
                                                        setCurrent(pageSize !== pageSizes ? 1 : page);
                                                        setPageSize(pageSizes)
                                                    }} total={tableData.total_count && tableData.total_count[0]}

                                                />


                                            </div>
                                        </> : ''}
                                    </div>

                                </Card>
                                : ""}
                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}

export default connect(mapStateToProps, {
    getfilterData,
    getTaskhistoryExport,
    getTaskhistoryReport,
})(TaskHistoryReport);