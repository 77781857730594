import HTTP from "../../Libs/http";
import { Toaster } from "../../Libs/Toaster";
import url from "../../Libs/URL";
import EncryptDecrypt from "../../Libs/Encrypt&Decrypt";
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { TOGGLE_COMMON_LOADER, CURRENTSTATUSCOMMON } from "../types";


export const Currentstatus = (initial) => async dispatch => {
    dispatch({
        type: CURRENTSTATUSCOMMON,
        payload: initial
    })  
};