import HTTP from '../../Libs/http';
import { REGISTER_LINK, CAPTCHA_VALUE } from '../types/index'
import { TOGGLE_COMMON_LOADER } from "../types/index";


const initialState = {
    link_status: '',
    captchvalue: ''

}

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case REGISTER_LINK:
            {
                return {
                    ...state,
                    link_status: payload,
                };
            }
        case CAPTCHA_VALUE:
            {
                return {
                    ...state,
                    captchvalue: payload,
                };
            }
        default:
            return state;
    }
}