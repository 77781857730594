import { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams, useHistory } from 'react-router-dom'
import { Modal, Input, Table, Button, Space, Tooltip, Select, DatePicker, Avatar, Segmented, Collapse, Card, Checkbox } from 'antd';
import moment from 'moment';
import Swal from 'sweetalert2';
import { getVendorClosureList, saveVendorClosureData, saveVendorTempClosureData, getPausedRecords, savePauseTask } from './../../Store/Action/Master/CV_VendorClosure';
import { InfoCircleOutlined, PlayCircleOutlined, PlusOutlined, ClockCircleOutlined, DownOutlined, ArrowLeftOutlined, SaveOutlined, SearchOutlined, PlusCircleOutlined, AudioOutlined, FilterFilled, ExclamationCircleTwoTone, UpOutlined, FullscreenOutlined, InfoCircleTwoTone, CloseCircleOutlined } from '@ant-design/icons';
import { GetRemarksData } from './../../Store/Action/Transcations/Taskrecall'
import SimpleReactValidator from 'simple-react-validator';
import { UNIT_CLOSURE_LIST, VENDOR_CLOSURE_LIST_RESET, REMARKS_MODAL_RESET } from '../../Store/types';
import ScrollToTop from 'react-scroll-to-top';
import { fullscreenmode, fullscreenexit, handleKeyDown, setFocus } from "../../Libs/fullscreenmode";
import { HomeRedirect } from './../../Libs/country'
import ScrollButton from '../Scrollbar';
import { Toaster } from '../../Libs/Toaster';

const homepage = HomeRedirect()

const mapStateToProps = (state) => ({
    unitClosureList: state.VendorClosure.vendorClosure.VendorClosureList,
    filterrecall: state.recallfilters.recallfilters,
    getpaused: state.VendorClosure.vendorClosure.getpausedrecords

});
const VendorClosure = ({
    saveVendorClosureData,
    saveVendorTempClosureData,
    getVendorClosureList,
    unitClosureList,
    filterrecall: { recallremarks },
    GetRemarksData,
    getPausedRecords,
    getpaused,
    savePauseTask

}) => {
    const historynew = useHistory();
    console.log(unitClosureList, "unitClosureList")
    const is_modal_true = useSelector((state) => state.recallfilters.is_modal_true)
    const { Option } = Select;
    const serviceProviderRef1 = useRef(null);
    const _ = require("lodash");
    const location = useLocation();
    const dispatch = useDispatch();
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const { Panel } = Collapse;
    const paramid = location.pathname;
    const formValidator = useRef(new SimpleReactValidator());
    const formValidator1 = useRef(new SimpleReactValidator());
    const formValidator2 = useRef(new SimpleReactValidator());
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [activedata, setActivedata] = useState('0')
    const [inactivedata, setInactivedata] = useState('0')
    const [closeddata, setCloseddata] = useState('0')
    console.log(closeddata, 'closeddata');
    const [remarks, setRemarks] = useState('')
    const [type, setType] = useState('active')
    //const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const [count, setCount] = useState(0)
    const [show, setShow] = useState(false)
    const [show1, setShow1] = useState(false)
    const [filteredArray, setFilteredArray] = useState([])
    console.log(filteredArray, "filteredArray123");
    const [baseData, setBaseData] = useState([]);
    console.log(baseData, "baseDatabaseData");
    const [baseData1, setBaseData1] = useState([])
    const [finalBaseData, setFinalBaseData] = useState([])
    console.log(baseData1, "baseDattaaaa")
    console.log(baseData, 'baseDatabaseData');
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [AddFormSubmit2, setAddFormSubmit2] = useState(false);
    const [FormSubmit, setFormSubmit] = useState(false);
    const [remarksModal, setRemarksModal] = useState(false);
    const [tempremarksModal, setTempremarksModal] = useState(false);
    const [remarksModal1, setRemarksModal1] = useState(false);
    const [reactivatedArray, setReActivatedArray] = useState([])
    const [activeArray, setActiveArray] = useState([])
    const [inActiveArray, setInActiveArray] = useState([])
    const [dataList, setDataList] = useState([])
    console.log(dataList, 'dataList');
    const [blockedArray, setBlockedArray] = useState([])
    const [unBlockedArray, setUnBlockedArray] = useState([])
    const [reactiveArray, setReactiveArray] = useState([])
    const [reactiveArraylength, setreactiveArraylength] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    console.log(reactiveArray, 'reactiveArray');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(
        false
    );
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    const [tempselectedunit, setTempseletedunit] = useState([]);
    console.log(tempselectedunit, 'tempselectedunit');
    const [modalData, setModalData] = useState({
        password: '',
        reason: '',
        unit_id: '',
        legal_entity: '',
        selectedtype: '',
        previousdate: '',
        date: '',

    })
    console.log(modalData, 'modalDataaaaa')
    const [modalData1, setModalData1] = useState({
        password: '',
        reason: '',
        unit_id: '',
        legal_entity: '',
        selectedtype: '',
    })
    console.log(modalData1, 'modalDataaaaa1')
    const [remarksvalue, Setremarksvalue] = useState();
    const [page, setPage] = useState(1);
    const [modalVisible, setModaVisible] = useState(false)
    const [modalVisible1, setModaVisible1] = useState(false)
    const [entityid, setCurrentEntity] = useState("");
    const [pendingTask, setPendingTask] = useState(false)
    const [selectedList, setSelectedList] = useState({
        unit: '',
        entity: ''
    })
    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != "All Legal Entity") {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
        }
    }, [localStorage.getItem('SelectedEntity')])
    useEffect(() => {
        if (entityid == '') {
            setBaseData([])
            dispatch({
                type: VENDOR_CLOSURE_LIST_RESET,
            })
        }
    }, [entityid])
    const { Search } = Input;
    const suffix = (
        <AudioOutlined
            style={{
                fontSize: 16,
                color: '#1890ff',
            }}
        />
    );
    const [searchText, setSearchText] = useState({
        data: '',
        index: 0
    });

    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        if (e) {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        } else {
            setSelectedKeys([])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        }
    };
    const [iconEnable, setIconEnable] = useState(false);
    const [clearText, setClearText] = useState('');
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const tablehandleChange = (pagination, filters, sorter) => {
        console.log(pagination, filters, sorter, 'pagination, filters, sorter')
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const onInputChange = e => {
        const { value } = e.target;
        // onChange={(e) => {
        let chk_valid = ''

        for (let i = 0; i < value.length; i++) {
            if (value[i] == '<' || value[i] == '>') {
                chk_valid += value[i]
            }
        }
        if (chk_valid.match('<>')) {
            //   UpdatePannelRemarks[collapseId] = ''
            setRemarks('')
            Toaster.error('Please check the input, it having some invalid characters')
        } else {

            const re = /[^|~\^]+$/;
            if (value === "" || re.test(value)) {
                if (value.includes('~') || value.includes('^') || value.includes('|')) {
                    return false
                } else {
                    setRemarks(e.target.value)
                }
            }
        }
    }
    const onInputChange1 = e => {
        const { value } = e.target;
        // onChange={(e) => {
        let chk_valid = ''

        for (let i = 0; i < value.length; i++) {
            if (value[i] == '<' || value[i] == '>') {
                chk_valid += value[i]
            }
        }
        if (chk_valid.match('<>')) {
            //   UpdatePannelRemarks[collapseId] = ''
            setRemarks('')
            setModalData({
                ...modalData,
                reason: ''
            })
            Toaster.error('Please check the input, it having some invalid characters')
        } else {

            const re = /[^|~\^]+$/;
            if (value === "" || re.test(value)) {
                if (value.includes('~') || value.includes('^') || value.includes('|')) {
                    return false
                } else {
                    setRemarks(e.target.value)
                    setModalData({
                        ...modalData,
                        reason: e.target.value
                    })
                }
            }
        }
    }


    /* Action Needed */
    const [filteredInfo1, setFilteredInfo1] = useState({});
    const [sortedInfo1, setSortedInfo1] = useState({});
    const tablehandleChange1 = (pagination, filters, sorter) => {
        setFilteredInfo1(filters);
        setSortedInfo1(sorter);
    };
    /* Action Needed */
    const handleReset = (clearFilters, handleSearch, confirm, dataIndex, setSelectedKeys) => {
        handleSearch('', confirm, dataIndex, setSelectedKeys)
        clearFilters();
        setSearchText('');
    };
    const columns1 = [
        {
            title: '#',
            dataIndex: false,
            align: 'center',
            width: '50px',
            // ellipsis: true,
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        {
            title: <span className='' style={{ cursor: 'default' }} onClick={(e) => { e.stopPropagation(); }}>Vendor</span>,
            dataIndex: 'unit_code',
            key: 'unit_code',
            // width:''
            ellipsis: false,
            showSorterTooltip: true,
            align: 'left',
            // ...getColumnSearchProps('unit_code', 'Unit'),
            filteredValue: filteredInfo1.unit_code || null,
            sorter: (a, b) => a.unit_code.localeCompare(b.unit_code),
            sortOrder: sortedInfo1.columnKey === 'unit_code' ? sortedInfo1.order : null,
            // width: '200px',
            render: (text, record) => {
                console.log(record, "recordrecord");
                return (
                    <Fragment>
                        <p className='mb-0' style={{ whiteSpace: 'unset' }}><span><span title={` ${record.address} , ${record.postal_code} `}><InfoCircleTwoTone style={{ fontSize: "13px", marginRight: "5px", marginTop: "5px" }} /></span>&nbsp;</span>
                            <span title={` ${text} - ${record.unit_name} `}>{text}-{record.unit_name}</span></p>
                    </Fragment>
                )
            }
        },
        {
            title: 'TimeLine',
            key: 'status',
            align: "center",
            width: "170px",
            dataIndex: 'status',
            render: (text, record, index) => {
                // return <Alert message={`${record.unblock_days} - Days left`} type="error" showIcon={<ClockCircleOutlined />} />
                // return <p style={{color:'#fc4b6c'}}><ClockCircleOutlined /> - {`${record.validity_days} - Days left`}</p>
                // if ((30 - record.validity_days) <= 1) {
                //     return <p className='mb-0' style={{ color: '#fc4b6c' }}><ClockCircleOutlined className='I' id='I' />  {`${record.validity_days} - Days left`}</p>
                // }
                // else {
                //     return <p className='mb-0' style={{ color: '#fc4b6c' }}><ClockCircleOutlined />  {`${(30 - record.validity_days)} - Days left`}</p>
                // }
                console.log(record, 'recordrecord');
                if (record.vendorclosure) {
                    console.log('jhdjfhsdjhf');
                    // (365 - record.validity_days) + '-' + 'Days Left'
                    return <p className='mb-0 text-success'><ClockCircleOutlined />  {`${(365 - record.validity_days_main) + '-' + 'Days Left'} - Days left`}</p>
                } else {
                    console.log('jhdjfhsdjhf11111');
                    if ((30 - record.validity_days_main) >= 20) {
                        return <p className='mb-0 text-success'><ClockCircleOutlined />  {`${(30 - record.validity_days_main)} - Days left`}</p>
                    }
                    else if ((30 - record.validity_days_main) >= 6) {
                        return <p className='mb-0 text-primary'><ClockCircleOutlined />  {`${(30 - record.validity_days_main)} - Days left`}</p>
                    }
                    else if ((30 - record.validity_days_main) <= 5) {
                        return <p className='mb-0 text-danger'><ClockCircleOutlined className='I' id='I' /> &nbsp; {`${(30 - record.validity_days_main)} - Days left`}</p>
                    }
                }
            },
        },
        {
            // title: <><label>Actions</label><Tooltip className='serviveproidertooltipclass' style={{ marginBotton: '5px', marginleft: '5px' }} placement="topRight" title={"Actions for Close/Closed/Reactive"}><InfoCircleOutlined /></Tooltip></>,
            title: 'Closure',
            dataIndex: 'currentStatus',
            // className: 'actiontd',
            width: '120px',
            align: 'center',
            // width:"100px",
            render: (text, record) => {
                return (
                    <Fragment>
                        {record.is_active == 0 ?
                            <Button
                                type="primary"
                                style={{ padding: "5px", width: "80px", textAlign: "center", borderRadius: '15px' }}
                                onClick={() => {
                                    setShow(true)
                                    closeById(record.unit_id, "closed");
                                }}
                                title=""
                            ><Avatar
                                    style={{
                                        backgroundColor: '#F32013',
                                    }}
                                    icon={<i className="fas fa-ban"></i>}
                                />
                            </Button> : record.is_active == 1 && record.validity_days_main > 30 ?
                                <span>Closed</span>
                                :
                                <span title={(30 - record.validity_days_main) + '-' + 'Days Left'} >
                                    {/* <Button
                                        type="primary"
                                        style={{ padding: "5px", width: "80px", textAlign: "center", borderRadius: '15px' }}
                                        onClick={() => {
                                            setModalData({
                                                password: '',
                                                reason: ''
                                            })
                                            setShow(true)
                                            closeById(record.unit_id, "reactive");
                                        }}
                                        title=""
                                    >Reactivate
                                    </Button> */}
                                    <div className="avtr">
                                        <Avatar
                                            className='pointer'
                                            style={{
                                                backgroundColor: '#F32013',
                                                fontSize: '13px',
                                                width: '28px',
                                                height: '29px'
                                            }}
                                            icon={<i className="fas fa-sync"></i>}
                                            onClick={() => {
                                                setModalData({
                                                    ...modalData,
                                                    password: '',
                                                    reason: ''
                                                })
                                                setShow(true)
                                                closeById(record.unit_id, "reactive");
                                            }}
                                        />
                                    </div>
                                </span>

                        }
                    </Fragment>
                )
            }
        }
    ];

    const clearFocus = (searchInput) => {
        setTimeout(() => searchInput.current?.select(), 100);
    }

    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div className='d-flex' style={{ position: 'relative' }}>
                <Input className='form-control'
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    value={selectedKeys[0]}
                    // autoFocus={'on'}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}
                />
                <CloseCircleOutlined className='table-filter-reset'
                    onClick={() => {
                        handleReset(clearFilters,
                            handleSearch, confirm, dataIndex, setSelectedKeys)
                        clearFocus(searchInput)
                    }
                    } />

            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    //     // color: filtered ? '#1890ff' : undefined,
                    //     fontWeight: 'bold',
                    //     // backgroundColor:'blue',
                    //     fontSize: '15px',
                    //     color: 'black'
                }}
            />
            // <FileSearchOutlined 
            // style={{
            //     // color: filtered ? '#1890ff' : undefined,
            //     fontWeight:'bold',
            //     // backgroundColor:'blue',
            //     // fontSize:'20px',
            //     color:'black'
            // }}
            // />
            // <i class="bi bi-search"></i>
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });
    useEffect(() => {
        if ((dataList && dataList.length > 0) && (show1 == true)) {
            let tempArr = dataList.concat(getpaused.paused_data)
            setDataList(tempArr)
        } else {
            let data = getpaused.paused_data && getpaused.paused_data.length > 0 && getpaused.paused_data.map((filterpaused) => {
                filterpaused.key = filterpaused.compliance_pending_id
                return filterpaused
            })
            setDataList(data)
        }
    }, [getpaused.paused_data])

    useEffect(() => {
        // if (type === 'active' && baseData.length > 0) {
        let activeData = _.filter(baseData, { is_active: 1 })
        setActiveArray(activeData)
        // }
        // else if (type === 'inactive' && baseData.length > 0) {
        let inactiveData = _.filter(baseData, { is_active: 0 })
        setInActiveArray(inactiveData)
        // }
        // else if (type === 'unblock' && baseData.length > 0) {
        let unblockData = _.filter(baseData, { currentStatus: 1 })
        setUnBlockedArray(unblockData)
        // }
        // else {
        let block = _.filter(baseData, { currentStatus: 0 })
        setBlockedArray(block)
        // }
        let reactive = _.filter(baseData1, { currentStatus: 2 })
        // console.log(reactive,'reactivereactivereactive');
        // let temp = _.filter(baseData, { is_vendor_closed: 1 })
        // setReactiveArray([...reactive, ...temp])
        // let check = temp.map((item, i) => {
        //     item.vendorclosure = true
        //     return item
        // })
        // console.log(check, 'checkcheck');
        // setreactiveArraylength([...reactive, ...check])
        // setReactiveArray([...reactive, ...check])
        setReactiveArray(reactive)
    }, [baseData])

    const getPausedTask = (record) => {
        setPendingTask(true)
        setSelectedRowKeys([])
        setSelectedList({
            ...selectedList,
            unit: parseInt(record.unit_id),
            entity: record.legal_entity_id
        })
        const pausedPayload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetPausedTask",
                    {
                        "unit_id": parseInt(record.unit_id),
                        "legal_entity_id": record.legal_entity_id,
                        "start_count": 0

                    }
                ]
            }
        ]

        getPausedRecords({
            payload: pausedPayload,
            paramid: paramid
        })
    }

    const getPausedTaskShowmore = () => {
        const pausedPayload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetPausedTask",
                    {
                        "unit_id": selectedList.unit,
                        "legal_entity_id": selectedList.entity,
                        "start_count": 100

                    }
                ]
            }
        ]

        getPausedRecords({
            payload: pausedPayload,
            paramid: paramid
        })
    }

    const columns = [
        {
            title: '#',
            dataIndex: false,
            align: 'center',
            width: '50px',
            // ellipsis: true,
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },

        {

            title: <span className='' style={{ cursor: 'default' }} onClick={(e) => { e.stopPropagation(); }}>Division</span>,
            dataIndex: 'division_name',
            key: 'division_name',
            align: 'left',
            // ellipsis: true,
            width: '120px',
            ...getColumnSearchProps('division_name', 'Division'),
            // sorter: (a, b) => a.division_name.localeCompare(b.division_name),
            filteredValue: filteredInfo.division_name || null,
            sorter: (a, b) => {
                if (a && a.division_name && a.division_name.length && b && b.division_name && b.division_name.length) {
                    return a.division_name.localeCompare(b.division_name);
                } else if (a && a.division_name && a.division_name.length) {
                    // That means be has null division_name, so a will come first.
                    return -1;
                } else if (b && b.division_name && b.division_name.length) {
                    // That means a has null division_name so b will come first.
                    return 1;
                }

                // Both division_name has null value so there will be no order change.
                return 0;
            },//a.division_name && b.division_name != null ? a.division_name.localeCompare(b.division_name) : "z",
            sortOrder: sortedInfo.columnKey === 'division_name' ? sortedInfo.order : null,
            render: (text, record) => {
                return (
                    <Fragment>
                        {text != null ?
                            <span title={text}>{text}</span> : '-'}
                    </Fragment>
                )
            }
        },
        {
            title: <span className='' style={{ cursor: 'default' }} onClick={(e) => { e.stopPropagation(); }}>Category</span>,
            dataIndex: 'category_name',
            key: 'category_name',
            width: '140px',
            align: 'left',
            // ellipsis: true,
            ...getColumnSearchProps('category_name', 'Category'),
            filteredValue: filteredInfo.category_name || null,
            sorter: (a, b) => {
                if (a && a.category_name && a.category_name.length && b && b.category_name && b.category_name.length) {
                    return a.category_name.localeCompare(b.category_name);
                } else if (a && a.category_name && a.category_name.length) {
                    // That means be has null division_name, so a will come first.
                    return -1;
                } else if (b && b.category_name && b.category_name.length) {
                    // That means a has null division_name so b will come first.
                    return 1;
                }

                // Both division_name has null value so there will be no order change.
                return 0;
            },//a.division_name && b.division_name != null ? a.division_name.localeCompare(b.division_name) : "z",
            // sorter: (a, b) => a.category_name && b.category_name != null ? a.category_name.localeCompare(b.category_name) : "",
            sortOrder: sortedInfo.columnKey === 'category_name' ? sortedInfo.order : null,
            // sorter: (a, b) => a.category_name.localeCompare(b.category_name),
            render: (text, record) => {
                return (
                    <Fragment>
                        {text != null ?
                            <span title={text}>{text}</span> : '-'}
                    </Fragment>
                )
            }
        },
        {
            title: <span className='' style={{ cursor: 'default' }} onClick={(e) => { e.stopPropagation(); }}>Vendor</span>,
            dataIndex: 'unit',
            key: 'unit',
            align: 'left',
            // ellipsis: true,
            // align: 'left',
            ...getColumnSearchProps('unit', 'Vendor'),
            filteredValue: filteredInfo.unit || null,
            sorter: (a, b) => a.unit.localeCompare(b.unit),
            sortOrder: sortedInfo.columnKey === 'unit' ? sortedInfo.order : null,
            // sorter: (a, b) => a.unit_code.localeCompare(b.unit_code),
            width: '170px',
            render: (text, record) => {
                return (
                    <Fragment>
                        <p className='mb-0' style={{ whiteSpace: 'unset' }}><span><span title={` ${record.address} , ${record.postal_code} `}> <InfoCircleTwoTone style={{ fontSize: "13px", marginRight: "5px", marginTop: "5px" }} /> </span>&nbsp;</span>
                            <span title={record.unit}>{record.unit}</span></p>
                    </Fragment>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'currentStatusNew',
            key: 'currentStatusNew',
            ellipsis: true,
            width: '80px',
            align: 'center',
            ...getColumnSearchProps('currentStatusNew', 'Status'),
            filteredValue: filteredInfo.currentStatusNew || null,

        },

        {
            title: 'Closure',
            dataIndex: false,
            width: '80px',
            align: 'center',
            filterSearch: false,
            render: (text, record) => {
                console.log(record, "recordddddd")
                return (
                    <Fragment>
                        {record.is_active == 0 ?
                            <Button
                                type="primary"
                                style={{ padding: "5px", width: "80px", textAlign: "center", borderRadius: '15px' }}
                                onClick={() => {
                                    setShow(true)
                                    closeById(record.unit_id, "closed");
                                }}
                                title=""
                            >Close
                            </Button> : record.is_active == 1 && record.validity_days_main > 30 ?
                                <span>Closed</span>
                                :
                                <span title={(30 - record.validity_days_main) + '-' + 'Days Left'}>
                                    <Button
                                        type="primary"
                                        style={{ padding: "5px", width: "80px", textAlign: "center", borderRadius: '15px' }}
                                        onClick={() => {
                                            setModalData({
                                                ...modalData,
                                                password: '',
                                                reason: ''
                                            })
                                            setShow(true)
                                            closeById(record.unit_id, "reactive");
                                        }}
                                        title=""
                                    >Reactivate
                                    </Button>
                                </span>

                        }
                    </Fragment>
                )
            }
        },

        {
            title: 'Temporary Closure',
            dataIndex: 'is_vendor_closed',
            // className: 'actiontd',
            width: '120px',
            align: 'center',
            // width:"100px",
            filters: [
                {
                    text: 'Close',
                    value: 0,
                },
                {
                    text: 'Closed',
                    value: 2,
                },
                {
                    text: 'Reactivate',
                    value: 1,
                },
            ],
            filteredValue: filteredInfo.is_vendor_closed,
            onFilter: (value, record) => record.is_vendor_closed === value,
            filterSearch: false,


            render: (text, record) => {
                return (
                    <Fragment>
                        {text == 0 ? <>
                            {record.vendor_closed_date ?
                                <Tooltip mouseLeaveDelay={0} overlayStyle={{ position: 'fixed' }} title={
                                    <table style={{ width: "100%", borderCollapse: 'collapse', border: '1px solid #636262', tableLayout: 'auto' }}>
                                        <tbody>
                                            <tr>
                                                <th style={{ border: '1px solid #636262', marginLeft: '5%', marginRight: '5%' }}><center>Closed On</center></th>
                                                <th style={{ border: '1px solid #636262' }}><center>Reactivated On</center></th>

                                            </tr>
                                            <tr>
                                                <td style={{ border: '1px solid #636262' }}><center>{record.vendor_closed_date ? record.vendor_closed_date : '-'}</center></td>
                                                <td style={{ border: '1px solid #636262' }}><center>{record.vendor_activation_date ? record.vendor_activation_date : '-'}</center></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                }> <i className="bi-info-circle-fill"></i>
                                </Tooltip> : ''}
                            <Button disabled={record.is_active == 1 ? true : false}
                                className={(record.vendor_closed_date) ? '' : 'ms-3'}
                                type="primary"
                                style={{ padding: "5px", width: "80px", textAlign: "center", marginLeft: '4px', borderRadius: '15px' }}
                                onClick={() => {
                                    setShow(true)
                                    setTempseletedunit([])
                                    closeById1(record.unit_id, "closed", record.vendor_closed_date);
                                }}
                                title=""
                            >Close
                            </Button> </> : <>
                            <Tooltip mouseLeaveDelay={0} overlayStyle={{ position: 'fixed' }} title={
                                <table style={{ width: "100%", borderCollapse: 'collapse', border: '1px solid #636262', tableLayout: 'auto' }}>
                                    <tbody>
                                        <tr>
                                            <th style={{ border: '1px solid #636262', marginLeft: '5%', marginRight: '5%' }}><center>Closed On</center></th>
                                            <th style={{ border: '1px solid #636262' }}><center>Reactivated On</center></th>

                                        </tr>
                                        <tr>
                                            <td style={{ border: '1px solid #636262' }}><center>{record.vendor_closed_date ? record.vendor_closed_date : '-'}</center></td>
                                            <td style={{ border: '1px solid #636262' }}><center>{record.vendor_activation_date ? record.vendor_activation_date : '-'}</center></td>
                                        </tr>


                                    </tbody>
                                </table>
                            }> <i className="bi-info-circle-fill"></i>&nbsp;
                            </Tooltip>

                            <span title={(365 - record.validity_days) + '-' + 'Days Left'} >
                                <Button disabled={record.is_active == 1 ? true : false}
                                    type="primary"
                                    style={{ padding: "5px", width: "80px", textAlign: "center", borderRadius: '15px' }}
                                    onClick={() => {
                                        setModalData({
                                            ...modalData,
                                            password: '',
                                            reason: ''
                                        })
                                        setShow(true)
                                        setTempseletedunit(record)
                                        closeById1(record.unit_id, "reactive", record.vendor_closed_date);
                                    }}
                                    title=""
                                >Reactivate
                                </Button>
                            </span>
                        </>
                        }
                    </Fragment>
                )
            }
        },
        {
            title: 'Pending Tasks',
            dataIndex: 'pending_count',
            key: 'pending_count',
            width: '100px',
            align: 'center',
            ellipsis: true,
            render: (text, record) => {
                if (text > 0) {
                    return <Button
                        type="primary"
                        style={{ padding: "5px", width: "80px", textAlign: "center", borderRadius: '15px' }}
                        onClick={() => {
                            getPausedTask(record)
                            setShow1(false)
                            setCount(0)
                            setDataList([])
                        }}
                        title=""
                    >Show
                    </Button>
                } else {
                    return false;
                }
            }

            // sorter: (a, b) => a.category_name.localeCompare(b.category_name),
        },

    ];

    const RemarksColumn = [
        {
            title: 'S.No',
            dataIndex: false,
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        {
            title: 'Description',
            dataIndex: 'r_description',
            key: 'r_description',
            width: '200px',
            ellipsis: true,
            render: (text, record) => <a onClick={() => {
                // setModalData({
                //     ...modalData,
                //     reason: text
                // })
                dispatch({
                    type: REMARKS_MODAL_RESET,
                    payload: false
                })
                setRemarks(text)
                setRemarksModal(false);
            }}>{record.r_description}</a>,
        },
    ]

    const RemarksColumns = [
        {
            title: 'S.No',
            dataIndex: false,
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        {
            title: 'Description',
            dataIndex: 'r_description',
            key: 'r_description',
            width: '200px',
            ellipsis: true,
            render: (text, record) => <a onClick={() => {
                setModalData({
                    ...modalData,
                    reason: text
                })
                dispatch({
                    type: REMARKS_MODAL_RESET,
                    payload: false
                })
                // setRemarks(text)
                setRemarksModal1(false);
            }}>{record.r_description}</a>,
        },
    ]

    useEffect(() => {
        if (isAuth) {
            if (entityid != '') {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetVendorClosureData",
                            {
                                "legal_entity_id": entityid,

                            }
                        ]
                    }
                ]
                if (entityid != "null") {
                    if (entityid != undefined) {
                        getVendorClosureList({
                            payload: payload,
                            paramid: paramid
                        })
                    } else {
                        setBaseData('')
                    }
                } else {
                    setBaseData('')
                }
            }

        }
    }, [isAuth, entityid])
    useEffect(() => {
        if (type === 'inactive') {
            let inactive = _.filter(baseData, { is_active: Number(1) })
            setFilteredArray(inactive)
        }
        if (type === 'activeData') {
            let inactive = _.filter(baseData, { is_active: Number(0) })
            setFilteredArray(inactive)
        }
        if (type === 'close') {
            let inactive = _.filter(baseData, { currentStatus: Number(0) })
            setFilteredArray(inactive)
        }
        if (type === 'closed') {
            let inactive = _.filter(baseData, { currentStatus: Number(1) })
            setFilteredArray(inactive)
        }
        if (type === 'reactivate') {
            let inactive = _.filter(baseData, { currentStatus: Number(2) })
            setFilteredArray(inactive)
        }

    }, [baseData, type])
    const onSubmitTable = () => {
        setFormSubmit(true)

        if (formValidator1.current.allValid()) {

            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetVendorClosureData",
                        {
                            "legal_entity_id": entityid,

                        }
                    ]
                }
            ]
            if (entityid != "null") {
                getVendorClosureList({
                    payload: payload,
                    paramid: paramid
                })
            } else {
                setBaseData('')
            }
        }
    }

    const OnSubmittemp = () => {
        setAddFormSubmit2(true)
        if (formValidator2.current.allValid()) {
            setModaVisible(false)
            setModaVisible1(false)
            //fetch pay
            const payloadInitial = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetVendorClosureData",
                        {
                            "legal_entity_id": entityid,

                        }
                    ]
                }
            ]
            //fetch pay
            saveVendorClosureData({
                payload:
                    [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "SaveUnitClosureData",
                                {
                                    "password": modalData1.password,
                                    "closed_remarks": remarks,
                                    "unit_id": Number(modalData1.unit_id),
                                    "grp_mode": modalData1.selectedtype == 'closed' ? "close" : "reactive",
                                    "legal_entity_id": entityid
                                }
                            ]
                        }
                    ],
                paramid: paramid,
                entityid: entityid,
                type: modalData1.selectedtype,
                payloadInitial: payloadInitial
            })
            setModalData1({
                password: "",
            })
            setRemarks("")
            // setModalData({
            //     ...modalData,
            //     password: ""
            // })
            // setModalData({
            //     ...modalData,
            //     date: ''
            // })
            setRemarks("")
            setAddFormSubmit(false)
        }
    }
    const OnSubmited = () => {
        setAddFormSubmit(true)
        if (formValidator.current.allValid()) {
            setModaVisible(false)
            setModaVisible1(false)
            //fetch pay
            const payloadInitial = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetVendorClosureData",
                        {
                            "legal_entity_id": entityid,

                        }
                    ]
                }
            ]
            //fetch pay
            saveVendorTempClosureData({
                payload:
                    [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "SaveVendorClosureData",
                                {
                                    "password": modalData.password,
                                    "closed_remarks": modalData.reason,
                                    "unit_id": Number(modalData.unit_id),
                                    "grp_mode": modalData.selectedtype == 'closed' ? "close" : "reactive",
                                    "date": modalData.date,
                                    "legal_entity_id": entityid,
                                }
                            ]

                        }
                    ],
                paramid: paramid,
                entityid: entityid,
                type: modalData.selectedtype,
                payloadInitial: payloadInitial
            })

            setModalData1({
                ...modalData1,
                password: ""
            })
            setModalData({
                ...modalData,
                password: "",
                reason: "",
                date: "",
                unit_id: "",
                legal_entity: "",
                selectedtype: "",
                previousdate: "",
            })
            setRemarks("")
            setAddFormSubmit2(false)
        }
    }

    const pausedColumns = [
        {
            title: '#',
            dataIndex: false,
            align: 'center',
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        Table.SELECTION_COLUMN,

        {
            title: 'Vendor',
            dataIndex: false,
            render: (text, record) => {
                return record.unit_code + ' - ' + record.unit_name
            }
        },
        {
            title: 'Domain',
            dataIndex: 'domain_name',
            key: 'domain_name',
        },
        {
            title: 'Statutory Provision',
            dataIndex: 'statutory_provision',
            key: 'statutory_provision',
            width: '23%'
        },
        {
            title: 'Compliance Task',
            dataIndex: false,
            key: 'compliance_task',
            render: (text, record) => {
                if (record.vndr_clsur_submit == 1) {
                    return <Fragment>
                        <span>{record.compliance_task} <Tooltip placement='topRight' title="compliance already submitted"><InfoCircleTwoTone /></Tooltip>&nbsp;</span>
                    </Fragment>
                } else {
                    return record.compliance_task
                }
            }
        },
        {
            title: 'Due Date',
            dataIndex: 'due_date',
            key: 'due_date',
        },
    ];


    useEffect(() => {
        if (recallremarks) {
            let temp = [];
            for (let i = 0; i < recallremarks.length; i++) {
                if (recallremarks[i].r_description != '') {
                    temp.push(recallremarks[i])
                }
                Setremarksvalue(temp)
            }
            if (temp.length == 0) {
                Toaster.error('No Remarks Available')
                setModaVisible(false)
                dispatch({
                    type: REMARKS_MODAL_RESET,
                    payload: false
                })
            } else {
                if (tempremarksModal) {
                    setRemarksModal1(true);
                } else {
                    setRemarksModal(true);
                }
            }
        }

    }, [recallremarks])

    useEffect(() => {
        formValidator.current.showMessages()
        formValidator1.current.showMessages()
        formValidator2.current.showMessages()
    }, [])
    const passwordcancel = () => {
        setAddFormSubmit(false)
        setAddFormSubmit1(false)
        setAddFormSubmit2(false)
        setModaVisible(false)
        setModaVisible1(false)
        setModalData({
            ...modalData,
            password: "",
            reason: "",
            date: "",
            unit_id: "",
            legal_entity: "",
            selectedtype: "",
            previousdate: "",
        })
        setRemarks("")
    }
    const passwordcancel1 = () => {
        setModaVisible(false)
        setModaVisible1(false)
        setModalData1({
            ...modalData1,
            password: ""
        })
        dispatch({
            type: REMARKS_MODAL_RESET,
            payload: false
        })
        setRemarks("")
        setAddFormSubmit(false)
        setAddFormSubmit1(false)
        setAddFormSubmit2(false)
    }
    const handleOk = () => {
        // setIsModalVisible(false);
        setRemarksModal(false)
        setRemarksModal1(false)
    };

    const handleCancel = () => {
        // setIsModalVisible(false);
        setRemarksModal(false)
        setRemarksModal1(false)
    };

    const showRemarksModal = () => {
        GetRemarksData({
            payload:
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetRemarksData",
                            {}
                        ]
                    }
                ],
            paramid: paramid
        })
        // setRemarksModal(true);
    };


    const showRemarksModal1 = () => {
        GetRemarksData({
            payload:
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetRemarksData",
                            {}
                        ]
                    }
                ],
            paramid: paramid
        })
        // setRemarksModal1(true);
    };
    const closeById = (unit_id, type) => {
        type == "closed" ?
            Swal.fire({
                title: 'Are you sure?',
                icon: 'success',
                text: "You want to Close?",
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: localStorage.getItem("currentTheme") == 'theme_four' ? "customSWal" : 'customSWal2',
                confirmButtonColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 '
            }).then((result) => {
                if (result.isConfirmed) {
                    setModaVisible1(true)
                    setAddFormSubmit(false)
                    setAddFormSubmit1(false)
                    setAddFormSubmit2(false)
                    setModalData1({
                        ...modalData1,
                        unit_id: unit_id,
                        selectedtype: 'closed'
                    })
                    setTimeout(() => {
                        setFocus(serviceProviderRef1)
                    }, 500);
                }
            }) :
            Swal.fire({
                title: 'Are you sure?',
                icon: 'success',
                text: "You want to Activate?",
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: localStorage.getItem("currentTheme") == 'theme_four' ? "customSWal" : 'customSWal2',
                confirmButtonColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 '
            }).then((result) => {
                if (result.isConfirmed) {
                    setAddFormSubmit(false)
                    setAddFormSubmit1(false)
                    setAddFormSubmit2(false)
                    setModaVisible1(true)
                    setModalData1({
                        ...modalData1,
                        unit_id: unit_id,
                        selectedtype: 'activate'
                    })
                    setTimeout(() => {
                        setFocus(serviceProviderRef1)
                    }, 500);
                }
            })
    };

    const closeById1 = (unit_id, type, closedate) => {
        type == "closed" ?
            Swal.fire({
                title: 'Are you sure?',
                icon: 'success',
                text: "You want to Close?",
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: localStorage.getItem("currentTheme") == 'theme_four' ? "customSWal" : 'customSWal2',
                confirmButtonColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 '
            }).then((result) => {
                if (result.isConfirmed) {
                    setModaVisible(true)
                    setAddFormSubmit(false)
                    setAddFormSubmit1(false)
                    setAddFormSubmit2(false)
                    setModalData({
                        ...modalData,
                        unit_id: unit_id,
                        selectedtype: 'closed',
                        previousdate: closedate
                    })
                    setTimeout(() => {
                        setFocus(serviceProviderRef1)
                    }, 500);
                }
            }) :
            Swal.fire({
                title: 'Are you sure?',
                icon: 'success',
                text: "You want to Activate?",
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                customClass: localStorage.getItem("currentTheme") == 'theme_four' ? "customSWal" : 'customSWal2',
                confirmButtonColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 '
            }).then((result) => {
                if (result.isConfirmed) {
                    setModaVisible(true)
                    setAddFormSubmit(false)
                    setAddFormSubmit1(false)
                    setAddFormSubmit2(false)
                    setModalData({
                        ...modalData,
                        unit_id: unit_id,
                        selectedtype: 'activate',
                        previousdate: closedate
                    })
                    setTimeout(() => {
                        setFocus(serviceProviderRef1)
                    }, 500);
                }
            })
    };

    useEffect(() => {
        if (modalData.legal_entity == '' || null || undefined) {
            setModalData({
                ...modalData,
                legal_entity: entityid
            })

        }
    }, [modalData.legal_entity])

    useEffect(() => {
        if (modalData1.legal_entity == '' || null || undefined) {
            setModalData1({
                ...modalData1,
                legal_entity: entityid
            })

        }
    }, [modalData1.legal_entity])



    // useEffect(() => {
    //     if (unitClosureList && unitClosureList.vendor_closure_units && unitClosureList.vendor_closure_units.length > 0) {
    //         setBaseData(unitClosureList.vendor_closure_units);
    //     }
    // }, [unitClosureList.vendor_closure_units])

    useEffect(() => {
        let entityArray = []
        let tempArraryNew = []
        if (unitClosureList && unitClosureList.vendor_closure_units && unitClosureList.vendor_closure_units.length > 0) {
            let array1 = _.filter(unitClosureList.vendor_closure_units, { is_active: 0 });
            setActivedata(array1.length)
            let array2 = _.filter(unitClosureList.vendor_closure_units, { is_active: 1 });
            setInactivedata(array2.length)

        }
        if (unitClosureList && unitClosureList.vendor_closure_units && unitClosureList.vendor_closure_units.length > 0) {
            unitClosureList.vendor_closure_units.map((subList) => {
                let currentStatus = ''
                if (subList.is_active == 0) {
                    currentStatus = 0 //close
                } else if (subList.is_active == 1 && subList.validity_days_main > 30) {
                    currentStatus = 1 // closed
                    let tempclosed = Number(closeddata) + 1
                    setCloseddata(tempclosed)
                } else if (subList.validity_days_main <= 30) {
                    currentStatus = 2 //reactivate
                }
                //  else {
                //     currentStatus = subList.is_active //default is_active
                // }
                console.log(currentStatus, 'currentstatus')
                entityArray = {
                    'address': subList.address,
                    'business_group_name': subList.business_group_name,
                    'category_name': subList.category_name,
                    'closed_on': subList.closed_on,
                    'division_name': subList.division_name,
                    'is_active': subList.is_active,
                    'is_vendor_closed': subList.is_vendor_closed,
                    'legal_entity_id': subList.legal_entity_id,
                    'legal_entity_name': subList.legal_entity_name,
                    'pending_count': subList.pending_count,
                    'postal_code': subList.postal_code,
                    'unit_code': subList.unit_code,
                    'unit_id': subList.unit_id,
                    'unit_name': subList.unit_name,
                    'validity_days': subList.validity_days,
                    'validity_days_main': subList.validity_days_main,
                    'vendor_activation_date': subList.vendor_activation_date,
                    'vendor_closed_date': subList.vendor_closed_date,
                    'currentStatus': currentStatus,
                    'unit': `${subList.unit_code}-${subList.unit_name}`,

                }
                tempArraryNew.push(entityArray)
            })
            //setBaseData(unitClosureList.UnitClosureList);
            setBaseData(tempArraryNew)
            setBaseData1(tempArraryNew)
            setFinalBaseData(tempArraryNew)
        } else {
            setBaseData([])
            setBaseData1([])
            setFinalBaseData([])
        }
    }, [unitClosureList.vendor_closure_units])

    // useEffect(() => {
    //     setBaseData([])
    // },[])

    useEffect(() => {
        return () => {
            dispatch({
                type: UNIT_CLOSURE_LIST,
                payload: ['', { unit_closure_units: [] }]
            })
        }
    }, [])

    const searchRecords = (e) => {
        if (e == 'clear') {
            setClearText('')
            setBaseData(finalBaseData)
            setIconEnable(false)
        } else {
            setClearText(e.target.value)
            if (e.target.value.length > 0) {
                setIconEnable(true)
            } else {
                setIconEnable(false)
            }
            const filterTable = finalBaseData.filter(o =>
                Object.keys(o).some(k =>
                    String(o[k])
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            );
            setBaseData(filterTable)
        }

    };

    const homescreen = () => {
        let getUrlDetails = homepage;
        let tempval = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_url;
        let tempkey = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_key;
        localStorage.setItem('formkey', tempkey);
        historynew.push(tempval);
        // window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }

    const [fullscreen, setfullscreen] = useState(false);
    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }

    const handleSelect = (record, selected) => {
        console.log(record, selected, 'selectedRowKeysselectedRowKeys')

        if (record.vndr_clsur_submit == 0) {
            if (selected) {
                setSelectedRowKeys((keys) => [...keys, record.key]);
            } else {
                setSelectedRowKeys((keys) => {
                    const index = keys.indexOf(record.key);
                    return [...keys.slice(0, index), ...keys.slice(index + 1)];
                });
            }
        }


    };

    const toggleSelectAll = () => {
        let pausedArray = _.filter(getpaused.paused_data, { vndr_clsur_submit: 0 })
        setSelectedRowKeys((keys) =>
            keys.length === pausedArray.length ? [] : pausedArray.map((r) => r.key)
        );
    };
    let pausedArrayNew = _.filter(getpaused.paused_data && getpaused.paused_data.length > 0 && getpaused.paused_data, { vndr_clsur_submit: 0 })
    const headerCheckbox = (
        <Checkbox
            checked={selectedRowKeys.length}
            indeterminate={
                selectedRowKeys.length > 0 && selectedRowKeys.length < pausedArrayNew.length
            }
            onChange={toggleSelectAll}
        />
    );
    const rowSelection = {
        selectedRowKeys,
        type: "checkbox",
        onSelect: handleSelect,
        columnTitle: headerCheckbox,
        getCheckboxProps: (record) => {
            if (record.vndr_clsur_submit == 1) {
                return {
                    disabled: true
                }
            }
        }
    }

    const submittedPaused = () => {
        console.log(selectedRowKeys, 'selectedRowKeysselectedRowKeysselectedRowKeys')
        const pausedSubmitted = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "SavePauseTask",
                    {
                        "vndr_pnd_compliance": selectedRowKeys,
                        "legal_entity_id": entityid,
                    }
                ]
            }
        ]
        if (selectedRowKeys && selectedRowKeys.length > 0) {
            savePauseTask({
                payload: pausedSubmitted,
                paramid: paramid,
            })
        } else {
            Toaster.error("Please select Paused compliance to submit")
        }

        setPendingTask(true)

    }

    const handlePasting = (e, regex) => {
        var regex = regex;
        var key = e.clipboardData.getData('text')
        if (!regex.test(key)) {
            e.preventDefault();
            Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
            return false;
        }
    }

    return (
        <Fragment>
            <div>
                <div className='back-to-top'>
                    <ScrollButton />
                </div>
                <div className="page-wrapper" id="page-wrapper">
                    <div className="page-titles py-0 page-title-sticky">
                        {/* <div className="row">
                            <div className="col-lg-5 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <Link className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            <span style={{ color: '#1890ff' }}>Master</span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ color: '#1890ff' }}>Vendor Closure</span>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="col-lg-7" style={{ textAlign: 'end' }}>
                                <button onClick={(e) => {
                                    fullscreenMode();
                                }} className="bg-transparent text-black ms-1 full-mode fullscreenradius">
                                    <Tooltip placement="left" title={sessionStorage.getItem('isFullScreen') == 'true' ? "Exit Full Screen Mode" : "Full Screen Mode"}>
                                        <FullscreenOutlined />
                                    </Tooltip>
                                </button>
                            </div>
                        </div> */}
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-0 d-flex align-items-center">
                                            <li className="breadcrumb-item">
                                                <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                            </li>
                                            <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                                <span style={{ fontSize: '16px' }}>Master</span>
                                            </li>
                                            <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                                <span>Vendor Closure </span>
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                                <div className="col-lg-4" style={{ textAlign: 'end' }}>
                                    {pendingTask == true ? <Button shape="round" className='addbutton' style={{ marginLeft: '50%' }}
                                        icon={<ArrowLeftOutlined />} size='default' onClick={(e) => { setPendingTask(false) }}>
                                        Back
                                    </Button> :
                                        <div className="search-box" style={{ position: 'absolute', right: '20px', marginRight: '50px', width: '240px', boxShadow: ' 0 5px 5px rgb(0 0 0 / 10%)' }} >

                                            <input className="search-txt" type="text" value={clearText} onChange={searchRecords} placeholder="Type to Search" />
                                            <a className="search-btn" style={{ position: 'relative', right: 1 }}>
                                                {iconEnable == true ?
                                                    <i className="fas fa-close" onClick={(e) => { searchRecords('clear') }}></i>
                                                    : <i className="fas fa-search"></i>}
                                            </a>
                                        </div>}
                                    <button style={{ marginTop: '3px' }} onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-1 full-mode fullscreenradius">
                                        <Tooltip placement="left" title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"} >
                                            <FullscreenOutlined />
                                        </Tooltip>
                                    </button>

                                </div>
                            </div>

                        </div>
                        <div className='row' style={{ "margin-top": "10px" }}>
                            {pendingTask == false ? <div className='col-5' >
                                <label style={{ "font-weight": 'bold' }}>Legal Entity : <span style={{ "color": "red" }}>*</span></label>
                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                    <div className="form-group" style={{ "margin-top": '-24px', "margin-left": '90px' }}>
                                        <Select
                                            allowClear={true}
                                            size="default"
                                            style={{ width: '100%', marginLeft: "20px" }}
                                            placeholder="Select Legal Entity"
                                            value={entityid || undefined}
                                            onChange={(data, value) => {
                                                if (data == undefined) {
                                                    setCurrentEntity("")
                                                    setBaseData([])
                                                    setClearText('')

                                                }
                                                else {
                                                    setCurrentEntity(data)
                                                    setBaseData([])
                                                    setSortedInfo({})
                                                    setFilteredInfo({})
                                                    setCloseddata('0')
                                                    setClearText('')
                                                    setSearchText({
                                                        data: '',
                                                        index: 0
                                                    })
                                                }
                                            }}
                                            // showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        >
                                            {sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
                                                return (
                                                    <Option key={item.le_id}>
                                                        {item.le_name}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                        {formValidator1.current.message(
                                            'Legal Entity',
                                            entityid,
                                            'required',
                                            {
                                                className: `invalid-feedback ${FormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Legal Entity Required',
                                                }
                                            })}
                                    </div> :
                                    <p style={{ width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>

                                }
                            </div> : ''}
                            <div className="col-7">
                                {/* <div className="search-box-vendor" style={{ position: 'relative', marginRight: '0px', width: '240px', boxShadow: '0 5px 5px rgb(0 0 0 / 10%)' }}>
                                    <input className="search-txt" type="text" value={clearText} onChange={searchRecords} placeholder="Type to Search" />
                                    <a className="search-btn" style={{ position: 'relative', right: 1 }}>
                                        {iconEnable == true ?
                                            <i className="fas fa-close" onClick={(e) => { searchRecords('clear') }}></i>
                                            : <i className="fas fa-search"></i>}
                                    </a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    {pendingTask == false ?
                        <div className="container-fluid pt-1">
                            <div className={localStorage.getItem("currentTheme") + ' card-body1-statuatory'}>
                                <Collapse className='report-collapse' defaultActiveKey={["1"]} style={{ display: entityid ? "block" : "none" }}  >
                                    <Panel header="Vendor Closure Statistics" key={baseData && baseData.length > 0 ? 1 : 0}>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <Card className='rounded-3 unitClosure'>
                                                        <h6>Overall Statistics</h6>
                                                        <div className="service-provider-segment">
                                                            <Space direction="vertical">
                                                                <Segmented size='small'
                                                                    onChange={(value) => {
                                                                        setType(value)

                                                                    }}
                                                                    options={[
                                                                        {
                                                                            label: (
                                                                                <div onChange={() => {
                                                                                }}
                                                                                    style={{
                                                                                        padding: 4,
                                                                                    }}
                                                                                >
                                                                                    <Avatar
                                                                                        style={{
                                                                                            backgroundColor: '#87d068',
                                                                                            fontSize: '15px'
                                                                                        }}
                                                                                        icon={<i className="ri-book-mark-fill"></i>}
                                                                                    />
                                                                                    <div>All Data</div>
                                                                                    <b>{baseData && baseData.length}</b>
                                                                                </div>
                                                                            ),
                                                                            value: 'active',
                                                                        },
                                                                        {
                                                                            label: (
                                                                                <div onChange={() => {
                                                                                }}
                                                                                    style={{
                                                                                        padding: 4,
                                                                                    }}
                                                                                >
                                                                                    <Avatar
                                                                                        style={{
                                                                                            backgroundColor: '#F32013',
                                                                                            fontSize: '15px'
                                                                                        }}
                                                                                        icon={<i className="ri-user-unfollow-fill"></i>}
                                                                                    />
                                                                                    <div>Inactive</div>
                                                                                    <b>{activeArray && activeArray.length}</b>


                                                                                </div>
                                                                            ),
                                                                            value: 'inactive',
                                                                        },
                                                                        {
                                                                            label: (
                                                                                <div onChange={() => {
                                                                                }}
                                                                                    activeData style={{
                                                                                        padding: 4,
                                                                                    }}
                                                                                >
                                                                                    <Avatar
                                                                                        style={{
                                                                                            backgroundColor: '#87d068',
                                                                                            fontSize: '15px'
                                                                                        }}
                                                                                        icon={<i className="ri-user-follow-fill"></i>}
                                                                                    />
                                                                                    <div>Active</div>
                                                                                    <b>{inActiveArray && inActiveArray.length}</b>



                                                                                </div>
                                                                            ),
                                                                            value: 'activeData',
                                                                        },
                                                                        {
                                                                            label: (
                                                                                <div onChange={() => {
                                                                                }}
                                                                                    style={{
                                                                                        padding: 4,
                                                                                    }}
                                                                                >
                                                                                    <Avatar
                                                                                        style={{
                                                                                            backgroundColor: '#F32013',
                                                                                            fontSize: '15px'
                                                                                        }}
                                                                                        icon={<i className="fas fa-ban"></i>}
                                                                                    />
                                                                                    <div>Closed</div>
                                                                                    <b>{unBlockedArray && unBlockedArray.length}</b>

                                                                                </div>
                                                                            ),
                                                                            value: 'closed',
                                                                        },
                                                                        {
                                                                            label: (
                                                                                <div onChange={() => {
                                                                                }}
                                                                                    style={{
                                                                                        padding: 4,
                                                                                    }}
                                                                                >
                                                                                    <Avatar
                                                                                        style={{
                                                                                            backgroundColor: '#F32013',
                                                                                            fontSize: '15px'
                                                                                        }}
                                                                                        icon={<i className="fas fa-sync"></i>}
                                                                                    />
                                                                                    <div>Reactivate</div>
                                                                                    <b>{reactiveArray && reactiveArray.length}</b>


                                                                                </div>
                                                                            ),
                                                                            value: 'reactivate',
                                                                        },
                                                                    ]}
                                                                />
                                                            </Space>
                                                        </div>
                                                    </Card>
                                                </div>
                                                <div className="col-md-6 userCard userCard1" style={{ height: '219px', width: '50%' }}>
                                                    <Card className='rounded-3' style={{ minHeight: '172px' }}>
                                                        <h6>Action Needed</h6>
                                                        <div id='service-table-action' className='service-table'>
                                                            <Table id="actionneeded" className={`stats ${localStorage.getItem("currentTheme")}`}
                                                                columns={columns1}
                                                                dataSource={reactiveArray}
                                                                pagination={false}
                                                                onChange={tablehandleChange1}
                                                                showSorterTooltip={true}
                                                                sortDirections={["ascend", "descend", "ascend"]}
                                                            />
                                                        </div>
                                                    </Card>
                                                </div>
                                            </div>
                                        </div>
                                    </Panel>
                                </Collapse>
                                <div className="row mt-2">
                                    <div className="col-12">
                                        <div className="card-body p-0 pt-2 service-provider-table">
                                            <Table
                                                key={type === 'active' ? baseData : filteredArray}
                                                id={'unitclosuretableview'}
                                                sortDirections={["ascend", "descend", "ascend"]}
                                                columns={columns}
                                                className={'tablecolorchanges service-provider-table ' + localStorage.getItem("currentTheme")}
                                                size={'small'}
                                                dataSource={type === 'active' ? baseData && baseData.length > 0 && baseData.map((filterBase) => {
                                                    if (filterBase.is_active == 0) {
                                                        filterBase.currentStatusNew = 'Active'
                                                    } else {
                                                        filterBase.currentStatusNew = 'In-Active'
                                                    }
                                                    return filterBase
                                                })

                                                    : filteredArray && filteredArray.length > 0 && filteredArray.map((filterBased) => {
                                                        if (filterBased.is_active == 0) {
                                                            filterBased.currentStatusNew = 'Active'
                                                        } else {
                                                            filterBased.currentStatusNew = 'In-Active'
                                                        }
                                                        return filterBased
                                                    })}
                                                onChange={tablehandleChange}
                                                bordered
                                                pagination={false}
                                                // scroll={{ x: 1000 }}
                                                showSorterTooltip={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* </div> */}
                                {/* <div className="container-fluid pt-1">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body" style={{ padding: '0px' }}>
                                        <Table
                                            columns={columns}
                                            // className='userprivclass'
                                            id={'unitclosuretableview'}
                                            className={'tablecolorchanges ' + localStorage.getItem("currentTheme")}
                                            dataSource={baseData}
                                            bordered
                                            // scroll={{ x: 1200 }}
                                            pagination={baseData.length > 10 ? {
                                                defaultPageSize: dataTableProperties.pagesize,
                                                showSizeChanger: dataTableProperties.sizechanger
                                            } : false}
                                        // onChange={handleChange} 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                            </div>
                        </div>
                        :
                        <div className="container-fluid pt-1">
                            <div className={localStorage.getItem("currentTheme") + ' card-body1-statuatory'}>
                                <Collapse className='report-collapse' defaultActiveKey={["1"]} style={{ display: entityid ? "block" : "none" }}  >
                                    <Panel header="Pending Task Lists" key={1}>
                                        <Table
                                            columns={pausedColumns}
                                            id={'unitclosuretableview'}
                                            className={'tablecolorchanges  service-provider-table ' + localStorage.getItem("currentTheme")}
                                            dataSource={dataList}

                                            bordered
                                            pagination={false}
                                            rowSelection={rowSelection}
                                        />
                                        <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row'>


                                                <div className='col-md-4'>
                                                    <span style={{ marginLeft: "5px", float: 'left' }}>Total Compliance :<span style={{ marginLeft: "5px" }}>{getpaused.total_count
                                                    }</span></span>

                                                </div>
                                                <div className='col-md-4 text-center'  >

                                                    {dataList && dataList.length == getpaused.total_count ? '' :
                                                        <Button type="primary" shape="round" className='showbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                            size='default'
                                                            onClick={(e) => {
                                                                setShow1(true)
                                                                setCount(count + 100)
                                                                const pausedPayload = [
                                                                    authtoken,
                                                                    {
                                                                        "session_token": authtoken,
                                                                        "request": [
                                                                            "GetPausedTask",
                                                                            {
                                                                                "unit_id": parseInt(selectedList.unit),
                                                                                "legal_entity_id": selectedList.entity,
                                                                                "start_count": count + 100

                                                                            }
                                                                        ]
                                                                    }
                                                                ]

                                                                getPausedRecords({
                                                                    payload: pausedPayload,
                                                                    paramid: paramid
                                                                })

                                                            }}
                                                        >Show More <DownOutlined />
                                                        </Button>

                                                    }
                                                    {/* } */}
                                                    <Button type="primary" shape="round" className='addbutton' style={{ marginLeft: '2%' }}
                                                        size='default'
                                                        // onClick={(e) => {
                                                        //     savestatutorysettings(e)
                                                        // }}
                                                        onClick={submittedPaused}
                                                    >Submit <SaveOutlined />
                                                    </Button>
                                                </div>

                                            </div>

                                        </div>
                                        {/* <div style={{ textAlign: 'center' }}>
                                            <Button type="primary" shape="round" className='addbutton'
                                                style={{ background: "#198754", borderColor: "#198754" }}
                                                icon={<PlayCircleOutlined />} size='default'
                                                onClick={submittedPaused}
                                            >
                                                Submit
                                            </Button>
                                        </div> */}
                                    </Panel>


                                </Collapse>
                            </div>
                        </div>}
                </div >

            </div>
            {/* <Modal visible={modalVisible} footer={null} className='usr_modal_class'
                onCancel={passwordcancel}>
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor=""><b>Password:</b> <span style={{ "color": "red" }}>*</span></label>
                        <input
                            type="password"
                            id="password"
                            name='password'
                            className="form-control"
                            placeholder="Enter password"
                            value={modalData.password}
                            onChange={(e) => {
                                setModalData({
                                    ...modalData,
                                    password: e.target.value
                                })
                            }} />
                        {formValidator.current.message(
                            'password',
                            modalData.password,
                            'required',
                            {
                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                messages: {
                                    required: 'Password Required',
                                }
                            })}
                    </div>
                </div>
                <br />
                <div className="col-md-12">
                    <label htmlFor=""><b>Remarks:</b> <span style={{ "color": "red" }}>*</span></label>

                    <textarea className='form-control'
                        placeholder="Enter Reason"
                        row='1' cols='4' 
                        onChange={(e) => {
                            setRemarks(e.target.value)
                        }}
                        value={remarks}

                    ></textarea>
                    <span className="btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center" style={{ "float": "right" }} onClick={() => { showRemarksModal() }}>
                        <PlusOutlined />
                    </span>

                    {formValidator.current.message(
                        'reason',
                        remarks,
                        'required',
                        {
                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                            messages: {
                                required: 'Reason Required',
                            }
                        })}

                </div>
                <br />

                <div className="form-actions">
                    <Button type="primary" shape="round" className='addbutton'
                        style={{ background: "#198754", borderColor: "#198754", marginLeft: '33%' }}
                        icon={<PlayCircleOutlined />} size='default'
                        onClick={OnSubmited}
                    >
                        Submit
                    </Button>
                </div>
            </Modal> */}
            {/* className={'usr_modal_class add-service-prv modal-service-priv ' + localStorage.getItem('currentTheme')} */}
            <Modal title=''
                style={{
                    top: 165,
                }}
                destroyOnClose
                autoFocus={false}
                open={modalVisible} footer={null} className={'usr_modal_class usr_modal_class_submit user-priv-modal' + localStorage.getItem('currentTheme')}
                onCancel={passwordcancel} maskClosable={false}>
                <div className='col-md-12'>
                    <div className='row'>
                        <div className='col-12'>
                            <label htmlFor=""><b>Password:</b> <span style={{ "color": "red" }}>*</span></label>
                            <div className="form-group">
                                <input
                                    ref={serviceProviderRef1}
                                    autoFocus={true}
                                    type="password"
                                    id="password"
                                    name='password'
                                    maxLength={'20'}
                                    className="form-control"
                                    placeholder="Enter password"
                                    style={{ width: '95%', marginTop: '5px' }}
                                    value={modalData.password}
                                    onChange={(e) => {
                                        setModalData({
                                            ...modalData,
                                            password: e.target.value
                                        })
                                    }} />
                            </div>
                            {formValidator.current.message(
                                'password',
                                modalData.password,
                                'required',
                                {
                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Password Required',
                                    }
                                })}
                        </div>
                        <div className='col-12'>
                            <label htmlFor=""><b>Reason:</b> <span style={{ "color": "red" }}>*</span></label>
                            <div className='d-flex align-items-end'>
                                <textarea cols="3" rows="1" name='remarks' id="remarks" className='form-control passwrd-remark '
                                    placeholder="Enter Reason"
                                    maxLength={'500'}
                                    // onChange={(e) => {
                                    //     setRemarks(e.target.value)
                                    //     setModalData({
                                    //         ...modalData,
                                    //         reason: e.target.value
                                    //     })
                                    // }}
                                    onChange={onInputChange1}
                                    value={modalData.reason}
                                >
                                </textarea>
                                <span className="text-info ms-1 d-flex 
    align-items-center justify-content-center" style={{ marginTop: '30px' }} onClick={() => {
                                        showRemarksModal1()
                                        setTempremarksModal(true)
                                    }}>
                                    <PlusCircleOutlined />
                                </span>
                            </div>
                            {formValidator.current.message(
                                'reason',
                                modalData.reason,
                                'required',
                                {
                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Reason Required',
                                    }
                                })}
                        </div>
                        <div className='col-12'>
                            <label><b> Date: </b> <span style={{ color: "red" }}>*</span></label>
                            <div style={{ marginTop: '5px' }} className="form-group">
                                <DatePicker
                                    placement={"bottomRight"} format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY'
                                    getPopupContainer={trigger => trigger.parentNode}
                                    style={{ width: '95%' }}
                                    allowClear={true}
                                    value={modalData.date ? moment(modalData.date) : ''}
                                    onChange={(date, dateString) => {
                                        setModalData({
                                            ...modalData,
                                            date: dateString
                                        })
                                    }}
                                    onDrop={(e) => {
                                        e.preventDefault();
                                        return false;
                                    }}
                                    onPaste={(e) => {
                                        handlePasting(e, /^[a-z ]+$/gi)
                                    }}
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                        return false
                                    }}
                                    disabledDate={(value) => {
                                        const date = new Date();
                                        console.log(moment(tempselectedunit.vendor_closed_date) <= date, tempselectedunit.vendor_closed_date, date, 'pppppppppp');
                                        if (tempselectedunit.vendor_closed_date != undefined) {
                                            console.log(moment(tempselectedunit.vendor_closed_date) <= date, tempselectedunit.vendor_closed_date, date, 'pppppppppp123');
                                            if (moment(tempselectedunit.vendor_closed_date) <= date) {
                                                if (tempselectedunit.vendor_closed_date < value.format("YYYY-MM-DD")) {
                                                    if (modalData.previousdate == '' || modalData.previousdate == null) {
                                                        return moment() >= value || value.format("YYYY-MM-DD") !== moment(value).startOf('month').format("YYYY-MM-DD");
                                                    } else {
                                                        return moment() >= value || moment(modalData.previousdate) >= value || value.format("YYYY-MM-DD") !== moment(value).startOf('month').format("YYYY-MM-DD");
                                                    }
                                                }
                                            } else {
                                                return true
                                            }
                                        } else {
                                            console.log(moment(tempselectedunit.vendor_closed_date) <= date, tempselectedunit.vendor_closed_date, date, 'pppppppppp321');
                                            // if (tempselectedunit.vendor_closed_date < value.format("YYYY-MM-DD")) {
                                            if (modalData.previousdate == '' || modalData.previousdate == null) {
                                                return moment() >= value || value.format("YYYY-MM-DD") !== moment(value).startOf('month').format("YYYY-MM-DD");
                                            } else {
                                                return moment() >= value || moment(modalData.previousdate) >= value || value.format("YYYY-MM-DD") !== moment(value).startOf('month').format("YYYY-MM-DD");
                                            }
                                            // }
                                        }

                                    }}
                                />
                            </div>
                            {formValidator.current.message(
                                'date',
                                modalData.date,
                                'required',
                                {
                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Date Required',
                                    }
                                })}
                        </div>
                    </div>
                </div>
                <div className={'form-actions popupbtncolour text-center ' + localStorage.getItem('currentTheme')} style={{ marginTop: '12px' }}>
                    <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                        style={{ background: "#198754", borderColor: "#198754", marginLeft: '-4%' }}
                        icon={<PlayCircleOutlined />} size='default'
                        onClick={OnSubmited}
                    >
                        Submit
                    </Button>
                </div>
                {/* </div> */}
            </Modal>


            <Modal title='' open={modalVisible1} footer={null} className={'usr_modal_class usr_modal_class_submit user-priv-modal' + localStorage.getItem('currentTheme')}
                onCancel={passwordcancel1} autoFocus={false} maskClosable={false}>
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor=""><b>Password:</b> <span style={{ "color": "red" }}>*</span></label>
                        <input
                            ref={serviceProviderRef1}
                            autoFocus={true}
                            type="password"
                            id="password"
                            name='password'
                            maxLength={'20'}
                            className="form-control"
                            style={{ width: '95%', marginTop: '5px' }}
                            placeholder="Enter password"
                            value={modalData1.password}
                            onChange={(e) => {
                                setModalData1({
                                    ...modalData1,
                                    password: e.target.value
                                })
                            }} />
                        {formValidator2.current.message(
                            'password',
                            modalData1.password,
                            'required',
                            {
                                className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                messages: {
                                    required: 'Password Required',
                                }
                            })}
                    </div>
                </div>
                <div className="col-md-12" style={{ marginTop: '10px' }}>
                    <label htmlFor=""><b>Reason:</b> <span style={{ "color": "red" }}>*</span></label>
                    <div style={{ marginTop: '5px' }} className='d-flex align-items-end'>
                        <textarea className='form-control'
                            placeholder="Enter Reason"
                            maxLength={'500'}
                            rows={'1'} cols='4'
                            // onChange={(e) => {
                            //     setRemarks(e.target.value)
                            // }}
                            onChange={onInputChange}
                            value={remarks}

                        ></textarea>
                        <span className="text-info ms-1 d-flex 
                                align-items-center justify-content-center" style={{ marginTop: '30px' }} onClick={() => {
                                showRemarksModal()
                                setTempremarksModal(false)
                            }}>
                            <PlusCircleOutlined />
                        </span>
                    </div>
                    {formValidator2.current.message(
                        'reason',
                        remarks,
                        'required',
                        {
                            className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                            messages: {
                                required: 'Reason Required',
                            }
                        })}
                </div>
                <div className={"form-actions text-center popupbtncolour " + localStorage.getItem('currentTheme')} style={{ marginTop: '12px' }}>
                    <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                        style={{ background: "#198754", borderColor: "#198754", marginLeft: '-4%' }}
                        icon={<PlayCircleOutlined />} size='default'
                        onClick={OnSubmittemp}
                    >
                        Submit
                    </Button>
                </div>
            </Modal>


            <Modal title="Remarks List" open={remarksModal}
                onOk={handleOk}
                onCancel={handleCancel} className={"remark-header add-service-prv zindex-modal-front " + localStorage.getItem('currentTheme')} footer={false} maskClosable={false}>
                <Table
                    className='userprivclass'
                    columns={RemarksColumn}
                    dataSource={remarksvalue && remarksvalue.length > 0 && remarksvalue}
                    bordered
                    // pagination={{
                    //     pageSizeOptions: ['10', '30', '50'],
                    //     defaultPageSize: dataTableProperties.pagesize,
                    //     showSizeChanger: dataTableProperties.sizechanger,
                    //     hideOnSinglePage: true
                    // }}
                    pagination={false}

                />
            </Modal>
            <Modal title="Remarks Lists" open={remarksModal1}
                onOk={handleOk}
                onCancel={handleCancel} className={"remark-header add-service-prv zindex-modal-front " + localStorage.getItem('currentTheme')} footer={false} maskClosable={false}>
                <Table
                    className='userprivclass'
                    columns={RemarksColumns}
                    dataSource={remarksvalue && remarksvalue.length > 0 && remarksvalue}
                    bordered
                    // pagination={{
                    //     pageSizeOptions: ['10', '30', '50'],
                    //     defaultPageSize: dataTableProperties.pagesize,
                    //     showSizeChanger: dataTableProperties.sizechanger,
                    //     hideOnSinglePage: true
                    // }}
                    pagination={false}

                />
            </Modal>
        </Fragment >
    )
}
export default connect(mapStateToProps, {
    getVendorClosureList,
    GetRemarksData,
    saveVendorClosureData,
    saveVendorTempClosureData,
    getPausedRecords,
    savePauseTask
})(VendorClosure);