import { MONTHWISEREPORT, TOGGLE_COMMON_LOADER,MONTHWISEREPORTTABLE, PERIODDATA } from './../../types/index'
const initialState = {
    monthwisestatusreport: {
        compliancewisereport: [],
        tabledata:[],
        period: []
    }
}
export default function (state = initialState, action) {

    const { type, payload } = action;
    switch (type) {
        case MONTHWISEREPORT: {
            return {
                ...state,
                monthwisestatusreport: {
                    ...state.monthwisestatusreport,
                    compliancewisereport: payload,
                },
            };
        }
        case MONTHWISEREPORTTABLE: {
            return {
                ...state,
                monthwisestatusreport: {
                    ...state.monthwisestatusreport,
                    tabledata: payload,
                },
            };
        }
        case PERIODDATA: {
            return {
                ...state,
                monthwisestatusreport: {
                    ...state.monthwisestatusreport,
                    period: payload
                }
            }
        }
        default:
            return state;
    }
}
