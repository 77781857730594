import { ConsoleSqlOutlined, ContactsFilled } from '@ant-design/icons';
import HTTP from '../../../Libs/http';
import { COMPLIANCE_FILTER, COMPLIANCE_FILTER_SHOW, SUCCUESS_COMPLIANCE_RESET, COMPLIANCE_VIEW, PASSWRD_MODAL_HANDLE, RESET_VIEW_HISTORY } from '../../types/index'


const initialState = {
    onoccurrencecompliance: {
        compliancefilter: [],
        compliancefiltershow: [],
        complianceview: [],
        modalVisible: false,
        successreset: ''
    }
}
export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case COMPLIANCE_FILTER:
            {
                return {
                    ...state,
                    onoccurrencecompliance: {
                        ...state.onoccurrencecompliance,
                        compliancefilter: payload[1],
                    },
                };
            }
        case PASSWRD_MODAL_HANDLE:
            {
                return {
                    ...state,
                    onoccurrencecompliance: {
                        ...state.onoccurrencecompliance,
                        modalVisible: payload
                    },
                }
            };


        case COMPLIANCE_FILTER_SHOW:
            {
                return {
                    ...state,
                    onoccurrencecompliance: {
                        ...state.onoccurrencecompliance,
                        compliancefiltershow: payload[1],
                    },
                };
            }
        case COMPLIANCE_VIEW:
            {
                return {
                    ...state,
                    onoccurrencecompliance: {
                        ...state.onoccurrencecompliance,
                        complianceview: payload[1],
                    },
                };
            }
        case RESET_VIEW_HISTORY:
            {
                return {
                    ...state,
                    onoccurrencecompliance: {
                        ...state.onoccurrencecompliance,
                        complianceview: [],
                    },
                };
            }

        case SUCCUESS_COMPLIANCE_RESET:
            {
                return {
                    ...state,
                    onoccurrencecompliance: {
                        ...state.onoccurrencecompliance,
                        successreset: payload,
                    },
                };
            }
        default:
            return state;
    }
}