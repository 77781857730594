import {
    TASK_CATEGORY_REPORT, GET_TABLE_DATA, TOGGLE_COMMON_LOADER, TASK_CAT_HISTORY,
    TASK_CAT_INTERIM,TASK_CAT_DATA
} from '../../types/index'

const initialState = {
    taskCategoryReport: {
        data: [],
        getTableData: [],
        history_data: '',
        interim_data: '',
        collapse_data:''
    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case TASK_CATEGORY_REPORT: {
            return {
                ...state,
                taskCategoryReport: {
                    ...state.taskCategoryReport,
                    data: payload
                },
            };
        }
        case GET_TABLE_DATA: {
            return {
                ...state,
                taskCategoryReport: {
                    ...state.taskCategoryReport,
                    getTableData: payload
                },
            };
        }
        case TASK_CAT_HISTORY: {
            return {
                ...state,
                taskCategoryReport: {
                    ...state.taskCategoryReport,
                    history_data: payload
                },
            };
        }
        case TASK_CAT_INTERIM: {
            return {
                ...state,
                taskCategoryReport: {
                    ...state.taskCategoryReport,
                    interim_data: payload
                },
            };
        }
        case TASK_CAT_DATA: {
            return {
                ...state,
                taskCategoryReport: {
                    ...state.taskCategoryReport,
                    collapse_data: payload
                },
            };
        }
        default:
            return state;
    }
}
