let url
if (process.env.REACT_APP_ENV == 'production') {
    url = {
        Login: '',
        CommonURL: '',
        VendorURL: '',
        encryptionKey: process.env.REACT_APP_SECRET_KEY
    }

} else {
    url = {
        Login: process.env.REACT_APP_API_URL,
        CommonURL: process.env.REACT_APP_API_URL,
        VendorURL: process.env.REACT_APP_API_URL ,
        encryptionKey: process.env.REACT_APP_SECRET_KEY
    }
}


export const getUrl = () => {
    const sessionValue = JSON.parse(localStorage.getItem('sessionValue'))
    //  && sessionValue.login_response.cf_access == 'false' && sessionValue.login_response.ltmg_access == 'false' && sessionValue.login_response.vndr_access == 'true'
    if (localStorage.getItem('sessionValue')) {
        if (localStorage.getItem('isVendor') == "true") {
            const vendorurl = url.VendorURL+'/cv'
            return vendorurl
        } else {
            if (sessionValue.login_response.cf_access == true && sessionValue.login_response.ltmg_access == true && sessionValue.login_response.vndr_access == true) {
                return url.CommonURL
            } else if (sessionValue.login_response.cf_access == false && sessionValue.login_response.ltmg_access == true && sessionValue.login_response.vndr_access == false) {
                return url.CommonURL
            } else if (sessionValue.login_response.cf_access == false && sessionValue.login_response.ltmg_access == false && sessionValue.login_response.vndr_access == true) {
                const vendorurl = url.VendorURL+'/cv'
                return vendorurl
            } else if (sessionValue.login_response.cf_access == true && sessionValue.login_response.ltmg_access == false && sessionValue.login_response.vndr_access == false) {
                return url.CommonURL
            }
            else {
                return url.CommonURL
            }

        }
    }

}

export default url
