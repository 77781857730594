import { APPROVERSCORECARDDATA, ASSIGNEE_TABLE_VALUE, APPROVERSCORECARDTABLEDATA, TOGGLE_COMMON_LOADER } from './../../types/index'
const initialState = {
    approverdata: {
        getapproverdata: [],
        tabledata: [],
       assigneetabledata: []
    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case APPROVERSCORECARDDATA: {
            console.log(payload, "payload4444");
            return {
                ...state,
                approverdata: {
                    ...state.approverdata,
                    getapproverdata: payload,
                },
            };
        }
        case APPROVERSCORECARDTABLEDATA: {
            console.log(payload, "payload4444");
            return {
                ...state,
                approverdata: {
                    ...state.approverdata,
                    tabledata: payload,
                },
            };
        }
        case ASSIGNEE_TABLE_VALUE: {
            console.log(payload, "payload4658525555");
            return {
                ...state,
                approverdata: {
                    ...state.approverdata,
                    assigneetabledata: payload,
                },
            };
        }

        default:
            return state;
    }
}
