import HTTP from "../../Libs/http";
import { Toaster } from "../../Libs/Toaster";
import url, { getUrl } from "../../Libs/URL";
import EncryptDecrypt from "../../Libs/Encrypt&Decrypt";
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { TOGGLE_COMMON_LOADER, CURRENTSTATUSCOMMON, REGISTER_LINK, CAPTCHA_VALUE } from "../types";
import alertmessage from '../../../src/Libs/Altermessage';
const urls = getUrl()


export const SaveRegistration = ({ payload, executeCancel, callername, key,navigate }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });

        let encoded = base64_encode(key);
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'Caller-Name': callername
            },
        }
        const payloadData = EncryptDecrypt.encryptdata(payload, key)
        const createRequest = HTTP.post({
            url: `${url.Login}/api/login`,
            headers: head,
            executeCancel,
            body: payloadData
        })

        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 0) ||

            (executeRequest.status === 0)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Your search did not return any results. Please try again.")
            dispatch({
                type: CURRENTSTATUSCOMMON,
                payload: 'failure'
            })
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, key))

            if (decryptData[0] === 'UsernameAlreadyExists') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error("User Name Already Exists")
            } else if (decryptData[0] === 'DuplicateClientUserCreation') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error("Client User already Exists")
            }else if(decryptData[0] === 'SaveRegistrationSuccess'){ 
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });               
                navigate.push('/login')
            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                }); 
                Toaster.error(decryptData[0]);
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const CheckuserName = ({ payload, executeCancel, callername, key, savepay,navigate }) => async dispatch => {
    try {
        // dispatch({
        //     type: TOGGLE_COMMON_LOADER
        // });

        let encoded = base64_encode(key);
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'Caller-Name': callername
            },
        }
        const payloadData = EncryptDecrypt.encryptdata(payload, key)
        const createRequest = HTTP.post({
            url: `${url.Login}/api/login`,
            headers: head,
            executeCancel,
            body: payloadData
        })

        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 0) ||
            (executeRequest.status === 0)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Your search did not return any results. Please try again.")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, key))
            if (decryptData[0] === 'UsernameAlreadyExists') {              
                Toaster.error("User Name Already Exists")
            }else{
                dispatch(
                    SaveRegistration({
                        payload: savepay,
                        callername: callername,
                        key: key,
                        navigate: navigate
    
                    })
                )
    
            }
           
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const CheckuserNameBlur = ({ payload, executeCancel, callername, key }) => async dispatch => {
    try {
        // dispatch({
        //     type: TOGGLE_COMMON_LOADER
        // });

        let encoded = base64_encode(key);
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'Caller-Name': callername
            },
        }
        const payloadData = EncryptDecrypt.encryptdata(payload, key)
        const createRequest = HTTP.post({
            url: `${url.Login}/api/login`,
            headers: head,
            executeCancel,
            body: payloadData
        })

        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 0) ||
            (executeRequest.status === 0)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Your search did not return any results. Please try again.")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, key))
            if (decryptData[0] === 'UsernameAlreadyExists') {              
                Toaster.error("User Name Already Exists")
            }else if(decryptData[0] === 'CheckUsernameSuccess'){
                Toaster.success('User Name Available')
    
            }else{
                Toaster.error(decryptData[0])
            }
           
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};


export const CheckLink = ({ payload, executeCancel, callername, key }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });

        let encoded = base64_encode(key);
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'Caller-Name': callername
            },
        }
        const payloadData = EncryptDecrypt.encryptdata(payload, key)
        const createRequest = HTTP.post({
            url: `${url.Login}/api/login`,
            headers: head,
            executeCancel,
            body: payloadData
        })

        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 0) ||
            (executeRequest.status === 0)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Your search did not return any results. Please try again.")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, key))

            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                dispatch({
                    type: REGISTER_LINK,
                    payload: 'expired'
                })
                Toaster.error("Registration link expired.Please contact the Administrator")
            } else if (decryptData[0] == 'CheckRegistrationTokenSuccess') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                dispatch({
                    type: REGISTER_LINK,
                    payload: 'success'
                })
                dispatch({
                    type: CAPTCHA_VALUE,
                    payload: decryptData[1].captcha
                })
            }
            else {
                Toaster.error(decryptData[0])
            }
        }



    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};


export const resetNewPassword = ({ payload, executeCancel, callername, key }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });

        let encoded = base64_encode(url.encryptionKey);
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'Caller-Name': callername
            },
        }
        const payloadData = EncryptDecrypt.encryptdata(payload, url.encryptionKey)
        const createRequest = HTTP.post({
            url: `${url.Login}/api/login`,
            headers: head,
            executeCancel,
            body: payloadData
        })

        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, url.encryptionKey))

            if (decryptData[0] === 'Your password matches with history of last five records, please use different one') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error("Your password matches with history of last five records, please use different one")
            }else if(decryptData[0] == 'EnterDifferentPassword'){
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error("Password already used. Enter different password")
            }
            else if(decryptData[0] == 'InvalidResetToken'){
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error("Invalid Reset Token")
            } else if(decryptData[0] === 'ResetSessionTokenValidationSuccess') {
                Toaster.success(decryptData[0]);
            }else if(decryptData[0] === 'ResetPasswordSuccess'){
                alertmessage.sweetalertReset();
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const resetTokenValidation = ({ payload, executeCancel, callername, key }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        let encoded = base64_encode(url.encryptionKey);
        const head = {
            headers: {
                'Compfie-Info': encoded,
            },
        }
        const payloadData = EncryptDecrypt.encryptdata(payload, url.encryptionKey)
        const createRequest = HTTP.post({
            url: `${url.Login}/api/login`,
            headers: head,
            executeCancel,
            body: payloadData
        })

        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 0) ||

            (executeRequest.status === 0)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Your search did not return any results. Please try again.")
            dispatch({
                type: CURRENTSTATUSCOMMON,
                payload: 'failure'
            })
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, url.encryptionKey))
            console.log(decryptData[0],'decryptData')
            if (decryptData[0] === 'Your password matches with history of last five records, please use different one') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error("Your password matches with history of last five records, please use different one")
            }else if(decryptData[0] === 'EnterDifferentPassword'){
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error("Password already used. Enter different password")
            }
            else if(decryptData[0] === 'InvalidResetToken'){
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error("Session Expired")
            }else if(decryptData[0] === 'ResetSessionTokenValidationSuccess') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.success(decryptData[0]);
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};