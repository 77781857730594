import { useState, useRef, Fragment } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { Modal, Card, Collapse, Tooltip, Input, Table, Button } from 'antd';
import DataTable from "react-data-table-component";
import FeatherIcon from 'feather-icons-react';
import { useEffect } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { GetRemarksData } from './../../Store/Action/Transcations/Taskrecall';
import { statutorylist, editstatutory, filterdata, locksettings, SaveStatutorySettings, UpdateStatutorySettings } from './../../Store/Action/Transcations/Statutorysettings'
import { EditOutlined, CheckOutlined, CloseOutlined, UserAddOutlined, FullscreenOutlined, SaveOutlined, ArrowLeftOutlined, UpCircleOutlined, PlusCircleOutlined, DownOutlined, PlayCircleOutlined } from '@ant-design/icons';
import Statutorysettings from '../../Store/Reducer/Transactions/statutorysettings';
import TextArea from 'antd/lib/input/TextArea';
// import { GiCheckMark } from "react-icons/gi";
import Stickicon from "./Stickyicon";
import ScrollButton from '../Scrollbar';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import { HomeRedirect } from './../../Libs/country'
import { Toaster } from '../../Libs/Toaster';

const homepage = HomeRedirect()

const mapStateToProps = (state) => ({
    list: state.Statutorysetting.statutorysettings.statutorylist,
    editdata: state.Statutorysetting.statutorysettings.editdatas,
    filterdatas: state.Statutorysetting.statutorysettings.filterdata,
    filterrecall: state.recallfilters.recallfilters,
    totaldatas: state.Statutorysetting.statutorysettings.totaldata,

})
const MultiUnitStatutorySettings = ({
    statutorylist,
    editstatutory,
    list,
    editdata,
    filterdata,
    locksettings,
    selectedRows,
    setlistshow,
    seteditshow,
    totaldatas,
    filterdatas,
    GetRemarksData,
    filterrecall: { recallremarks },
    SaveStatutorySettings,
    UpdateStatutorySettings,
    entityid,
    filtervalue1,
    setSelectedRows

}) => {
    console.log(editdata, 'editdata');
    console.log(filtervalue1, 'filtervalue1');
    console.log(selectedRows, '555');
    const location = useLocation();
    const formValidator = useRef(new SimpleReactValidator());
    const saveformValidator = useRef(new SimpleReactValidator());
    const paramid = location.pathname;
    const [scroll, setScroll] = useState(false)
    const _ = require("lodash");
    const { Panel } = Collapse;
    const history = useHistory();
    const historynew = useHistory();
    const dispatch = useDispatch();
    const [remarksFormSubmit, setRemarksFormSubmit] = useState(false)
    const [data, setData] = useState();
    const [showmoredataadded, setShowmoredataadded] = useState(false)
    const [tempstatutorypayload, setTempstatutorypayload] = useState([]);
    const [checkedRow, setCheckedRow] = useState(false);
    const [startData, setStartData] = useState([])
    const [isNewData, setIsNewData] = useState([])
    console.log(startData, 'startData');
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    // const entityid = localStorage.getItem('SelectedEntityid');
    const [childValue, setChildValue] = useState()
    const [isModalVisible, SetisModalVisible] = useState(false);
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [passwordmodal, Setpasswordmodal] = useState(false);
    const [submitpasswordmodal, Setsubmitpasswordmodal] = useState(false);
    const [page, setPage] = useState(1);
    const [editDatas, setEditDatas] = useState([])
    console.log(editDatas, 'editDatas');
    const [viewstatus, setViewstatus] = useState([]);
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true,
        pageSizeOptions: ['10', '30', '50']
    })
    var [count1, setCount1] = useState(1);
    const [uiddomian, setUiddomain] = useState([]);
    const [newData, setNewData] = useState([])
    console.log(newData, 'newData');
    const [show, setshow] = useState(false)
    const [statutorypayload, setStatutorypayload] = useState([]);
    console.log(statutorypayload, 'statutorypayload');
    const [unitWise, setUnitWise] = useState([]);
    const [unitWiseData, setUnitWiseData] = useState([]);
    console.log(unitWiseData, 'unitWiseData');
    const [compId, setCompId] = useState([]);
    const [UpdataCheck, setUpdateCheck] = useState([]);
    const [tempeditdata, setTempeditdata] = useState();
    const [ExtraCollapseId, setExtraCollapseId] = useState()
    const [CollapseId, setCollapseId] = useState()
    const [updateRemarks, setUpdateRemarks] = useState([])
    console.log(updateRemarks, 'updateRemarks');
    const [collapseRowStatus, setcollapseRowStatus] = useState([])
    const [collapseRowRemarks, setcollapseRowRemarks] = useState([])
    const [check, setCheck] = useState()
    const [RowRemarks, setRowRemark] = useState([])
    const [RowStatus, setRowStatus] = useState([])
    const [checkdatas, setcheckDatas] = useState([])
    const [updateStatus, setUpdateStatus] = useState([])
    const [remarksModal, setRemarksModal] = useState(false);
    const [PannelremarksModal, setPannelRemarksModal] = useState(false)
    const [collapseremarksModal, setCollapseremarksModal] = useState(false);
    const [PanelRemarkChange, setpanelRemarkChange] = useState(false)
    // const [remarksPanel, setRemarksPanel] = useState(true)
    const [minuscircleoutlined, setMinuscircleoutlined] = useState([]);
    const [closeoutlined, setCloseoutlined] = useState([]);
    const [checkoutlined, setCheckoutlined] = useState([]);
    const [UpdatePannelRemarks, setUpdatePannelRemarks] = useState([]);
    console.log(UpdatePannelRemarks, 'UpdatePannelRemarks');
    const [CheckStatus, setCheckStatus] = useState([])
    const [CollapseStatus, setCollapseStatus] = useState([]);
    console.log(CollapseStatus, 'CollapseStatus');
    const [CId, setCid] = useState([]);
    const [ChildId, setChildId] = useState([]);
    const [ComplianceID, setComplianceID] = useState([]);
    const [UnitName, setUnitName] = useState([]);
    const [CAstatus, setCAstatus] = useState([])
    const [unitId, setunitId] = useState([]);
    const [childData, setChildData] = useState([])
    const [collapsemessages, setCollapseMessages] = useState([])
    const [remarkscount, setRemarkscount] = useState(0)
    const [remarksvalue, Setremarksvalue] = useState();
    const [checkdata, setCheckdata] = useState([])
    const [password, Setpassword] = useState({
        passwordvalue: '',
        le_id: '',
        d_id: '',
        u_id: '',
        lock: '',
    })
    const [titledata, Settitledata] = useState()
    const [rowRemarks, setRowRemarks] = useState(false)
    const [filtervalue, Setfiltervalue] = useState({
        entity: '',
        division: '',
        category: ''
    })
    const column = [
        {
            title: '#',
            dataIndex: 'indexNo',
            key: 'indexNo',
            width: "20px",
            align: "center",
            render: (row, record, index) => {
                console.log(record, row, 'recordrecord')
                return (
                    <>
                        <span>{record.indexNo}</span>
                        {record.frequency_name == 'Periodical' ?
                            <Tooltip title={record && record.frequency_name}><p><i style={{ color: "green" }} className="bi bi-square-fill"></i></p></Tooltip> :
                            record.frequency_name == 'FlexiReview' ?
                                <Tooltip title={record && record.frequency_name}><p><i style={{ color: "pink" }} className="bi bi-square-fill"></i></p></Tooltip> :
                                record.frequency_name == 'Review' ?
                                    <Tooltip title={record && record.frequency_name}><p><i style={{ color: "orange" }} className="bi bi-square-fill"></i></p></Tooltip> :
                                    record.frequency_name == 'On Occurrence' ?
                                        <Tooltip title={record && record.frequency_name}><p><i style={{ color: "yellow" }} className="bi bi-square-fill"></i></p></Tooltip> :
                                        <Tooltip title={record && record.frequency_name}><p><i style={{ color: "red" }} className="bi bi-square-fill"></i></p></Tooltip>
                        }
                    </>
                )
            }, filters: [
                {
                    text: <span><i style={{ color: "green" }} class="bi bi-square-fill"></i> Periodical</span>,
                    value: 'Periodical',
                },
                {
                    text: <span><i style={{ color: "pink" }} class="bi bi-square-fill"></i> Flexi Review</span>,
                    value: 'FlexiReview',
                },
                {
                    text: <span><i style={{ color: "orange" }} class="bi bi-square-fill"></i> Review</span>,
                    value: 'Review',
                },
                {
                    text: <span><i style={{ color: "yellow" }} class="bi bi-square-fill"></i> On Occurrence</span>,
                    value: 'On Occurrence',
                },
                {
                    text: <span><i style={{ color: "red" }} class="bi bi-square-fill"></i> One Time</span>,
                    value: 'One Time',
                },
            ],
            onFilter: (value, record) => record.frequency_name == value,
        },

        {
            title: "Statutory Provision",
            render: (record) => record.s_prov,
            sortable: true,
            width: '155px',
            reorder: true,
            ellipsis: false,
            align: "left"
        },
        {
            title: "Compliance Task",
            render: (record) => record.comp_name,
            sortable: true,
            width: '130px',
            reorder: true,
            ellipsis: false,
        },
        // {
        //     title: "Compliance Frequency",
        //     render: (record) => record.frequency_name,
        //     sortable: true,
        //     width: '100px',
        //     ellipsis: true,
        //     reorder: true,
        //     align: "center"
        // },
        {
            title: "Compliance Description",
            render: (record) => record.descp,
            sortable: true,
            reorder: true,
            ellipsis: false,
            width: '130px',
        },
        {
            title: "Criticality",
            // render: (record) => record.criticality_know,
            sortable: true,
            reorder: true,
            ellipsis: true,
            width: '50px',
            align: "center",
            render: (record) => {
                return (record.criticality_client === null ? record.criticality_know :
                    (record.criticality_client !== record.criticality_know ?
                        <span style={{ color: 'red', cursor: 'pointer' }} title={record.criticality_know} >
                            {record.criticality_client}</span> : <span title={''}>{record.criticality_client}</span>))
                // return <span title={record.criticality_know} >{record.criticality_client}</span>
            }

        },
        {
            title: "Applicable Units",
            // render: (record) => record.criticality_know,
            sortable: true,
            reorder: true,
            ellipsis: true,
            width: '70px',
            align: "center",
            render: (record) => {
                return (`${record.unit_wise_status.length}/${selectedRows.length}`)
            }
        },
    ];
    const remarkscolumn = [

        {
            title: 'S.No',
            dataIndex: false,
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        {
            title: "Description",
            render: (text, row) => <a href={() => false}
                onClick={(e) => {
                    setRowRemarks(true)
                    let rowId = childValue
                    let temp = row.r_description
                    let temp1 = remarkscount + 1
                    setRemarkscount(temp1)
                    // setRemarkmessage(temp)
                    if (rowId in updateRemarks) {
                        // let index = updateRemarks.indexOf(rowId)
                        updateRemarks[rowId] = [];
                        if (updateRemarks.indexOf(rowId) == -1) {
                            updateRemarks[rowId] = row.r_description;
                        }

                    } else {
                        updateRemarks[rowId] = [];
                        updateRemarks[rowId] = row.r_description
                    }
                    setUpdateRemarks([...updateRemarks])
                    setRemarksModal(false)
                    setPannelRemarksModal(false)
                }}>{row.r_description}</a>,
            sortable: true,
            width: '250px',
            reorder: true,
            ellipsis: true
        }
    ]
    useEffect(() => {

        formValidator.current.showMessages()

    }, [])
    //  Full screen mode //
    //  Full screen mode //
    const [fullscreen, setfullscreen] = useState(false);
    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', handleKeyDown, false);
            document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
            return () => {
                document.addEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('webkitfullscreenchange', handleKeyDown);
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }

    const scrollup = () => {
        window.scrollTo(0, 0)
    }

    const Pannelremarkscolumn = [
        {
            title: 'S.No',
            dataIndex: false,
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        {
            title: 'Description',
            dataIndex: 'r_description',
            key: 'r_description',
            width: '250px',
            ellipsis: true,
            render: (text, row) => <a href={() => false}
                onClick={(e) => {
                    setRowRemarks(true)
                    let rowId = ExtraCollapseId
                    let temp1 = remarkscount + 1
                    setRemarkscount(temp1)
                    // setRemarkmessage(temp)
                    if (rowId in UpdatePannelRemarks) {
                        // let index = updateRemarks.indexOf(rowId)
                        UpdatePannelRemarks[rowId] = [];
                        if (UpdatePannelRemarks.indexOf(rowId) == -1) {
                            UpdatePannelRemarks[rowId] = row.r_description;
                        }
                    } else {
                        UpdatePannelRemarks[rowId] = [];
                        UpdatePannelRemarks[rowId] = row.r_description
                    }
                    setUpdatePannelRemarks([...UpdatePannelRemarks])
                    // setRemarksModal(false)
                    setPannelRemarksModal(false)
                    let temp = []
                    let tempArray = []
                    for (let i in childData) {
                        temp.push(childData[i].unit_wise_status)
                    }
                    for (let j in temp) {
                        for (let k in temp[j]) {
                            tempArray.push(temp[j][k].c_comp_id)
                        }
                    }
                    for (let i in unitWiseData) {
                        for (let k in unitWiseData[i]) {
                            if (tempArray.indexOf(unitWiseData[i][k].c_comp_id) >= 0) {
                                // updateRemarks[unitWiseData[i][k].childId] = null
                                collapseRowRemarks[unitWiseData[i][k].childId] = row.r_description;
                            }
                        }
                    }
                    // for (let i in child) {
                    //     console.log('pppp');
                    //     if (child[i].childId in RowRemarks) {
                    //         let index = RowRemarks.indexOf(child[i].childId)
                    //         console.log(index, 'index');
                    //         RowRemarks[child[i].childId] = [];
                    //         console.log(index == -1, 'index == -1');
                    //         if (index == -1) {
                    //             RowRemarks[child[i].childId] = e.target.value
                    //         }
                    //         else {
                    //             RowRemarks[child[i].childId] = [];
                    //             RowRemarks[child[i].childId] = e.target.value
                    //         }
                    //     }
                    // }
                    setcollapseRowRemarks([...collapseRowRemarks])
                    if (PanelRemarkChange == true) {
                        // for (let i in child) {
                        //     if (child[i].childId in updateRemarks) {
                        //         let index = updateRemarks.indexOf(child[i].childId)
                        //         console.log(index, 'index');
                        //         updateRemarks[child[i].childId] = [];
                        //         console.log(index == -1, 'index == -1');
                        //         if (index == -1) {
                        //             updateRemarks[child[i].childId] = e.target.value
                        //         }
                        //         else {
                        //             updateRemarks[child[i].childId] = [];
                        //             updateRemarks[child[i].childId] = e.target.value
                        //         }
                        //     }
                        // }
                        for (let i in unitWiseData) {
                            for (let k in unitWiseData[i]) {
                                if (tempArray.indexOf(unitWiseData[i][k].c_comp_id) >= 0) {
                                    updateRemarks[unitWiseData[i][k].childId] = row.r_description;
                                }
                            }
                        }
                        setUpdateRemarks([...updateRemarks])
                    }

                    // for (let i in childData) {
                    //     if (childData[i].childId in RowRemarks) {
                    //         let index = RowRemarks.indexOf(childData[i].childId)
                    //         console.log(index, 'index');
                    //         RowRemarks[childData[i].childId] = [];
                    //         console.log(index == -1, 'index == -1');
                    //         if (index == -1) {
                    //             RowRemarks[childData[i].childId] = row.r_description
                    //         }
                    //         else {
                    //             RowRemarks[childData[i].childId] = [];
                    //             RowRemarks[childData[i].childId] = row.r_description
                    //         }
                    //     }
                    // }
                    // setRowRemark([...RowRemarks])


                    if (collapseremarksModal === true) {
                        //  setCollapseremarkmessage(temp)
                        let temp = []



                        // setCollapseMessages(temp)
                        setCollapseremarksModal(false)
                    }
                }}
            >{row.r_description}</a>,
        },

    ]

    useEffect(() => {
        saveformValidator.current.showMessages()
    }, [])

    useEffect(() => {
        let id = selectedRows && selectedRows.length > 0 && selectedRows.map((item) => {
            return item.u_id
        })
        setUiddomain(id)
        let data = selectedRows && selectedRows.length > 0 && selectedRows.map((item) => {
            return item.is_new
        })
        setNewData(data)
    }, [selectedRows])
    // useEffect(() => {
    //     if (editdata.length > 0) {
    //         let temp = checkdata
    //         if (temp.length === 0) {
    //             for (let i in editdata) {
    //                 temp.push(editdata[i])
    //             }
    //             setCheckdata([...temp])
    //         }
    //         else {
    //             for (let i in editdata) {
    //                 let exist = _.find(checkdata, { comp_id: editdata[i].comp_id })
    //                 if (!exist) {
    //                     temp.push(editdata[i])
    //                 }
    //             }
    //             setCheckdata([...temp])
    //         }
    //     }
    // }, [editdata])
    useEffect(() => {
        let temp = _.find(titledata, { index: check })
        let temp1 = []
        let tempArray = []
        for (let i in temp && temp.child) {

            temp1.push(temp && temp.child[i].unit_wise_status)
        }
        for (let j in temp1) {
            for (let k in temp1[j]) {
                tempArray.push(temp1[j][k].c_comp_id)
            }
        }
        setcheckDatas(tempArray)
    }, [check])


    useEffect(() => {
        if (isAuth) {

            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutorySettings",
                        {
                            "le_id": entityid,
                            "div_id": filtervalue1.division ? parseInt(filtervalue1.division) : null,
                            "cat_id": filtervalue1.category ? parseInt(filtervalue1.category) : null,
                        }
                    ]
                }
            ]

            const filterAPI = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutorySettingsFilters",
                        {}
                    ]
                }
            ]
            if (entityid != 'null') {
                statutorylist({
                    payload: payload,
                    paramid: paramid
                })
            }
            filterdata({
                payload: filterAPI,
                paramid: paramid
            })
        }
    }, [isAuth])

    useEffect(() => {
        setTempeditdata(editdata)
        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (checkdata) {
            const ab = checkdata.map(function (item) {
                if ((array.indexOf(item.lone_statu_name) < 0))
                    array.push(item.lone_statu_name)
            })
            array.map((data, i) => {
                // let array2 = [];
                let temp = [];
                let entityArray = [];
                if (data) {
                    checkdata.map((child, childIndex) => {
                        if (data === child.lone_statu_name) {
                            entityArray = {
                                'app_status': child.app_status,
                                'comp_id': child.comp_id,
                                'comp_name': child.comp_name,
                                'compfielaw_url': child.compfielaw_url,
                                'criticality_client': child.criticality_client,
                                'criticality_know': child.criticality_know,
                                'descp': child.descp,
                                'frequency_name': child.frequency_name,
                                'h_id': child.h_id,
                                'is_assigned': child.is_assigned,
                                'lone_statu_name': child.lone_statu_name,
                                'mapping_provision': child.mapping_provision,
                                'not_app_remarks': child.not_app_remarks,
                                'opt_status': child.opt_status,
                                's_prov': child.s_prov,
                                'unit_wise_status': child.unit_wise_status,
                                'collapseId': i,
                                'childId': childIndex,
                                'parentId': i,
                                'indexNo': count1
                            }
                            temp.push(entityArray)
                            { <span hidden>{count1 = count1 + 1}</span> }
                        }
                    })
                }
                datavalue = {
                    index: i,
                    parent: data,
                    child: temp
                }
                finalarray.push(datavalue)
            })
            Settitledata(finalarray)
            getData()
        }

    }, [checkdata])

    useEffect(() => {
        titledata && titledata.length && titledata.map((item, i) => {
            let temp = [];
            UpdatePannelRemarks[i] = item.child[0].not_app_remarks
            item && item.child.length > 0 && item.child.map((itemdata, j) => {
                temp.push(itemdata.unit_wise_status[0].comp_opt_status)
            })
            if (temp.includes(1) === true) {
                CollapseStatus[i] = 1
            } else {
                let count = 0
                for (let k in temp) {
                    if (temp[k] === 2) {
                        count = count + 1
                        if (count === temp.length) {
                            CollapseStatus[i] = 2
                        }
                    }
                }
                if (count !== temp.length) {
                    CollapseStatus[i] = 0
                }
            }
            // CollapseStatus[i] = item.child[0].app_status == true ? 1 : 0
        })
        setUpdatePannelRemarks([...UpdatePannelRemarks])
        setCollapseStatus([...CollapseStatus])

    }, [titledata])

    console.log(updateStatus, 'updateStatus');
    console.log(unitWise, 'unitWise');
    useEffect(() => {
        setTempeditdata(editdata)
        let array = [];
        let finalarray = [];
        let datavalue = [];
        let entityArray = []
        let count = 0
        if (unitWise) {
            for (let i in unitWise) {
                if (unitWise[i].length === 1) {
                    let unitname = _.find(list, { u_id: unitWise[i][0].unit_id })

                    entityArray.push([{
                        "act_status": unitWise[i][0].act_status,
                        "c_comp_id": unitWise[i][0].c_comp_id,
                        "comp_opt_status": unitWise[i][0].comp_opt_status,
                        "comp_app_status": unitWise[i][0].comp_app_status,
                        "comp_remarks": unitWise[i][0].comp_remarks,
                        "compfielaw_url": unitWise[i][0].compfielaw_url,
                        "h_id": unitWise[i][0].h_id,
                        "is_new": unitWise[i][0].is_new,
                        "is_saved": unitWise[i][0].is_saved,
                        "is_submitted": unitWise[i][0].is_submitted,
                        "unit_id": unitWise[i][0].unit_id,
                        "parentId": Number(i),
                        "childId": count,
                        "comp_id": compId[i],
                        "opt_status": RowStatus[i],
                        "n_a_remarks": RowRemarks[i]
                    }]);
                    updateStatus[count] = unitWise[i][0].comp_opt_status
                    startData[count] = unitWise[i][0].comp_opt_status
                    updateRemarks[count] = unitWise[i][0].comp_remarks
                    collapseRowStatus[count] = RowStatus[i]
                    collapseRowRemarks[count] = RowRemarks[i]
                    CId[count] = unitWise[i][0].c_comp_id
                    ChildId[count] = count
                    UnitName[count] = unitname.u_name
                    ComplianceID[count] = compId[i]
                    unitId[count] = unitWise[i][0].unit_id
                    viewstatus[count] = unitWise[i][0].is_new
                    CAstatus[count] = unitWise[i][0].comp_app_status
                    count = count + 1
                } else {
                    let temp = [];
                    for (let j in unitWise[i]) {
                        let unitname = _.find(list, { u_id: unitWise[i][j].unit_id })
                        temp.push({
                            "act_status": unitWise[i][j].act_status,
                            "c_comp_id": unitWise[i][j].c_comp_id,
                            "comp_opt_status": unitWise[i][j].comp_opt_status,
                            "comp_app_status": unitWise[i][j].comp_app_status,
                            "comp_remarks": unitWise[i][j].comp_remarks,
                            "compfielaw_url": unitWise[i][j].compfielaw_url,
                            "h_id": unitWise[i][j].h_id,
                            "is_new": unitWise[i][j].is_new,
                            "is_saved": unitWise[i][j].is_saved,
                            "is_submitted": unitWise[i][j].is_submitted,
                            "unit_id": unitWise[i][j].unit_id,
                            "parentId": Number(i),
                            "childId": count,
                            "comp_id": compId[i],
                            "opt_status": RowStatus[i],
                            "n_a_remarks": RowRemarks[i]
                        })
                        updateStatus[count] = unitWise[i][j].comp_opt_status
                        startData[count] = unitWise[i][j].comp_opt_status
                        updateRemarks[count] = unitWise[i][j].comp_remarks
                        collapseRowStatus[count] = RowStatus[i]
                        collapseRowRemarks[count] = RowRemarks[i]
                        CId[count] = unitWise[i][j].c_comp_id
                        UnitName[count] = unitname.u_name
                        unitId[count] = unitWise[i][j].unit_id
                        viewstatus[count] = unitWise[i][j].is_new

                        ComplianceID[count] = compId[i]
                        ChildId[count] = count
                        CAstatus[count] = unitWise[i][j].comp_app_status
                        count = count + 1
                        // CAstatus[count]=unitWise[i][j].comp_opt_status
                    }
                    entityArray.push(temp)



                }
            }

            // temp.push(entityArray)

            // updateRemarks[j] = unitWise[i][j].comp_remarks
            // datavalue = {
            //     index: i,
            //     // parent: data,
            //     child: temp
            // }
            // finalarray.push(datavalue)
            setUnitWiseData(entityArray)
            setUpdateStatus([...updateStatus])
            setViewstatus([...viewstatus])
            setStartData([...startData])
            setUpdateRemarks([...updateRemarks])
            setcollapseRowStatus([...collapseRowStatus])
            setcollapseRowRemarks([...collapseRowRemarks])
            setCid([...CId])
            setUnitName([...UnitName])
            setComplianceID([...ComplianceID])
            setunitId([...unitId])
            setCAstatus([...CAstatus])
            setChildId([...ChildId])
        }

        // if (unitWise) {
        //     for (let i in unitWise) {
        //         for (let j in unitWise[i]) {
        //             let temp = [];
        //             let entityArray = [];
        //             entityArray = {
        //                 "act_status": unitWise[i][j].act_status,
        //                 "c_comp_id": unitWise[i][j].c_comp_id,
        //                 "comp_opt_status": unitWise[i][j].comp_opt_status,
        //                 "comp_remarks": unitWise[i][j].comp_remarks,
        //                 "compfielaw_url": unitWise[i][j].compfielaw_url,
        //                 "h_id": unitWise[i][j].h_id,
        //                 "is_new": unitWise[i][j].is_new,
        //                 "is_saved": unitWise[i][j].is_saved,
        //                 "is_submitted": unitWise[i][j].is_submitted,
        //                 "unit_id": unitWise[i][j].unit_id,
        //                 "parentId": i,
        //                 "childId": j
        //             }
        //             temp.push(entityArray)
        //             updateStatus[j] = unitWise[i][j].comp_opt_status
        //             updateRemarks[j] = unitWise[i][j].comp_remarks
        //             datavalue = {
        //                 index: i,
        //                 // parent: data,
        //                 child: temp
        //             }
        //             finalarray.push(datavalue)
        //         }
        //     }
        //     //             unitWise.map(function (item, k) {
        //     // console.log(k,'kiiiiiiiiiiiiiiii');


        //     //                 item.map((child, childIndex) => {
        //     //                     console.log(childIndex,'');
        //     //                     // let array2 = [];
        //     //                     let temp = [];
        //     //                     let entityArray = [];

        //     //                     entityArray = {
        //     //                         "act_status": child.act_status,
        //     //                         "c_comp_id": child.c_comp_id,
        //     //                         "comp_opt_status": child.comp_opt_status,
        //     //                         "comp_remarks": child.comp_remarks,
        //     //                         "compfielaw_url": child.compfielaw_url,
        //     //                         "h_id": child.h_id,
        //     //                         "is_new": child.is_new,
        //     //                         "is_saved": child.is_saved,
        //     //                         "is_submitted": child.is_submitted,
        //     //                         "unit_id": child.unit_id,
        //     //                         "parentId": k,
        //     //                         "childId": childIndex
        //     //                     }
        //     //                     temp.push(entityArray)
        //     //                     updateStatus[childIndex] = child.comp_opt_status
        //     //                     updateRemarks[childIndex] = child.comp_remarks
        //     //                     datavalue = {
        //     //                         index: childIndex,
        //     //                         // parent: data,
        //     //                         child: temp
        //     //                     }
        //     //                     finalarray.push(datavalue)
        //     //                 })
        //     //             })
        //     setUnitWiseData(finalarray)
        //     setUpdateStatus([...updateStatus])
        //     setUpdateRemarks([...updateRemarks])

        // }
        // getUnitValue()

    }, [unitWise])
    console.log(checkdata, 'checkdata');
    useEffect(() => {
        let tempArray = []
        let tempArray1 = []
        for (let i in ComplianceID) {
            tempArray.push({
                "c_c_id": CId[i],
                "a_status": collapseRowStatus[i],
                "n_a_remarks": collapseRowRemarks[i],
                "comp_id": ComplianceID[i],
                "c_o_status": updateStatus[i],
                "c_remarks": updateRemarks[i],
                "u_name": UnitName[i],
                "u_id": unitId[i],
                "c_a_status": CAstatus[i]
            })
            tempArray1.push({
                "c_c_id": CId[i],
                "a_status": collapseRowStatus[i],
                "n_a_remarks": collapseRowRemarks[i],
                "comp_id": ComplianceID[i],
                "c_o_status": updateStatus[i],
                "c_remarks": updateRemarks[i],
                "u_name": UnitName[i],
                "u_id": unitId[i],
                "c_a_status": CAstatus[i],
                'childId': ChildId[i]
            })
        }
        setTempstatutorypayload(tempArray1)
        setStatutorypayload(tempArray)
    }, [updateStatus, updateRemarks, collapseRowRemarks, collapseRowStatus])

    const handleCancel = () => {
        setRemarksModal(false)
        SetisModalVisible(false);
        setPannelRemarksModal(false)
    }

    const passwordcancel = () => {
        Setpasswordmodal(false);
    }

    const showmore = () => {
        editscreen(statutorypayload.length)
        if (unitWiseData.length > 0) {
            let temp = []
            let datas = []
            for (let i in unitWiseData) {
                for (let j in unitWiseData[i]) {
                    let temp1 = {
                        act_status: unitWiseData[i][j].act_status,
                        c_comp_id: unitWiseData[i][j].c_comp_id,
                        childId: unitWiseData[i][j].childId,
                        comp_app_status: CAstatus[unitWiseData[i][j].childId],
                        comp_id: unitWiseData[i][j].comp_id,
                        comp_opt_status: updateStatus[unitWiseData[i][j].childId],
                        comp_remarks: updateRemarks[unitWiseData[i][j].childId],
                        compfielaw_url: unitWiseData[i][j].compfielaw_url,
                        h_id: unitWiseData[i][j].h_id,
                        is_new: unitWiseData[i][j].is_new,
                        is_saved: unitWiseData[i][j].is_saved,
                        is_submitted: unitWiseData[i][j].is_submitted,
                        n_a_remarks: collapseRowRemarks[unitWiseData[i][j].childId],
                        // opt_status
                        //     :
                        //     updateStatus[unitWiseData[i][j].childId],
                        parentId: unitWiseData[i][j].parentId,
                        unit_id: unitWiseData[i][j].unit_id
                    }
                    temp.push(temp1)
                }
            }
            console.log(temp, 'vAAAAAAAAAV');
            // let uniqueObjArray = [...new Map(temp && temp.length && temp.map((item) => [item["comp_id"], item])).values()];
            // const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
            //     return item
            // })
            // console.log(uniqueIds, 'uniqueIds');
            for (let i in checkdata) {
                let data = _.filter(temp, { comp_id: checkdata[i].comp_id })
                let entity = {
                    app_status: checkdata[i].app_status,
                    comp_id: checkdata[i].comp_id,
                    comp_name: checkdata[i].comp_name,
                    compfielaw_url: checkdata[i].compfielaw_url,
                    criticality_client: checkdata[i].criticality_client,
                    criticality_know: checkdata[i].criticality_know,
                    descp: checkdata[i].descp,
                    frequency_name: checkdata[i].frequency_name,
                    h_id: checkdata[i].h_id,
                    is_assigned: checkdata[i].is_assigned,
                    lone_statu_name: checkdata[i].lone_statu_name,
                    mapping_provision: checkdata[i].mapping_provision,
                    not_app_remarks: checkdata[i].not_app_remarks,
                    opt_status: checkdata[i].opt_status,
                    s_prov: checkdata[i].s_prov,
                    unit_wise_status: data

                }
                datas.push(entity)
            }
            setEditDatas(datas)

            setShowmoredataadded(true)
        }
    }

    const unlock = (e_data) => {
        Setpasswordmodal(true)
        Setsubmitpasswordmodal(true)
        Setpassword({
            ...password,
            le_id: entityid,
            d_id: e_data.d_id,
            u_id: e_data.u_id,
            lock: e_data.is_locked
        })
    }

    const handleOk = () => {
        setRemarksModal(false)
        setPannelRemarksModal(false)
    };

    const getData = () => {
        let array = [];
        let countrylisttempdataNew = [];
        let arrNew = [];
        const ab = checkdata.map(function (item) {
            if ((array.indexOf(item.lone_statu_name) < 0))
                arrNew.push(item.lone_statu_name)
        })

        arrNew.map((data, i) => {
            let entityArrayNew = [];
            let tempArraryNew = [];
            if (data) {
                checkdata.map((child, childIndex) => {
                    if (data === child.lone_statu_name) {
                        entityArrayNew = {
                            'app_status': child.app_status,
                            'comp_id': child.comp_id,
                            'comp_name': child.comp_name,
                            'compfielaw_url': child.compfielaw_url,
                            'criticality_client': child.criticality_client,
                            'criticality_know': child.criticality_know,
                            'descp': child.descp,
                            'frequency_name': child.frequency_name,
                            'h_id': child.h_id,
                            'is_assigned': child.is_assigned,
                            'lone_statu_name': child.lone_statu_name,
                            'mapping_provision': child.mapping_provision,
                            'not_app_remarks': child.not_app_remarks,
                            'opt_status': child.opt_status,
                            's_prov': child.s_prov,
                            'unit_wise_status': child.unit_wise_status,
                            'collapseId': i,
                            'childId': childIndex,
                            'parentId': i
                        }
                        tempArraryNew.push(entityArrayNew)
                        unitWise[childIndex] = child.unit_wise_status
                        RowRemarks[childIndex] = child.not_app_remarks
                        RowStatus[childIndex] = child.opt_status
                        compId[childIndex] = child.comp_id
                    }
                })


            }
            countrylisttempdataNew.push(tempArraryNew)
        })
        var merged = [].concat.apply([], countrylisttempdataNew);
        setData(merged)
        setUpdateRemarks([...updateRemarks])
        setRowStatus([...RowStatus])
        setUnitWise([...unitWise])
        setCompId([...compId])
    }

    const genExtra = (val, collapseId, parent, child) => {
        return panel_data(val, collapseId, parent, child);
    };
    // const updatestatutorySettings = () => {
    //     if (newData && newData.length > 0 && newData.includes(true)) {
    //         let temp = []
    //         for (let i in updateStatus) {
    //             if (updateStatus[i] !== startData[i]) {
    //                 temp.push(true)
    //             }
    //         }
    //         if (temp && temp.length > 0 && temp.includes(true)) {
    //             Setsubmitpasswordmodal(false)
    //             Setpasswordmodal(true)
    //         }
    //         else {
    //             Toaster.error("All Compliance should be select while submit")
    //         }
    //     }
    //     else {
    //         Setsubmitpasswordmodal(false)
    //         Setpasswordmodal(true)
    //     }
    // }

    const updatestatutorySettings = () => {
        setRemarksFormSubmit(true)
        let temp = []
        let temp2 = []
        let newval = viewstatus.includes(true)
        // if (UpdatePannelRemarks.includes('')) {
        //     for (let i in UpdatePannelRemarks) {
        //         if (UpdatePannelRemarks[i] == '') {
        //             if (CollapseStatus[i] == 1) {
        //                 Toaster.error('Remarks required for Opt in act')
        //                 break
        //             } else if (CollapseStatus[i] == 0) {
        //                 Toaster.error('Remarks required for Not opted act')
        //                 break
        //             } else if (CollapseStatus[i] == 2) {
        //                 Toaster.error('Remarks required for Not applicable act')
        //                 break
        //             }
        //         }
        //     }
        // }
        // else {
        //   if (updateChildRemarks.includes('')) {
        //     Toaster.error('Remarks Required')
        //   } 
        if (newval) {
            Toaster.error('All compliance should select while submit')
        }
        else {
            let temp = []
            let temp2 = []
            console.log(newval, 'newval');
            // if (newval) {
            //     Toaster.error('Atleast one compliance should be select')
            // }
            // else {
            for (let i in CollapseStatus) {
                if (CollapseStatus[i] == 1) {
                    temp.push(null)
                }
                else {
                    temp.push(UpdatePannelRemarks[i])
                }
            }
            console.log(temp, 'temp56665');

            if (temp.includes('')) {
                for (let i in temp) {
                    if (temp[i] == '') {

                        if (CollapseStatus[i] == 1) {
                            Toaster.error('Remarks required for Opt in act')
                            break
                        } else if (CollapseStatus[i] == 0) {
                            Toaster.error('Remarks required for Not opted act')
                            break
                        } else if (CollapseStatus[i] == 2) {
                            Toaster.error('Remarks required for Not applicable act')
                            break
                        }
                    }
                }
            }
            else if (updateRemarks.includes('')) {
                Toaster.error('Remarks Required')
            }
            else {
                Setsubmitpasswordmodal(false)
                Setpasswordmodal(true)
                Setpassword({
                    ...password,
                    passwordvalue: ''
                })
            }
        }

        // }

    }
    console.log(viewstatus, 'viewstatus');
    const savestatutorysettings = () => {
        let newval = viewstatus.includes(true)
        let temp = []
        let temp2 = []
        console.log(newval, 'newval');
        // if (newval) {
        //     Toaster.error('Atleast one compliance should be select')
        // }
        // else {
        for (let i in CollapseStatus) {
            if (CollapseStatus[i] == 1) {
                temp.push(null)
            }
            else {
                temp.push(UpdatePannelRemarks[i])
            }
        }
        console.log(temp, 'temp56665');

        if (temp.includes('')) {
            for (let i in temp) {
                if (temp[i] == '') {

                    if (CollapseStatus[i] == 1) {
                        Toaster.error('Remarks required for Opt in act')
                        break
                    } else if (CollapseStatus[i] == 0) {
                        Toaster.error('Remarks required for Not opted act')
                        break
                    } else if (CollapseStatus[i] == 2) {
                        Toaster.error('Remarks required for Not applicable act')
                        break
                    }
                }
            }
        }
        else if (updateRemarks.includes('')) {
            Toaster.error('Remarks Required')
        }
        else {
            SaveStatutorySettings({
                payload:
                    [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "SaveStatutorySettings",
                                {
                                    "update_statutories": statutorypayload,
                                    "le_id": entityid,
                                    "s_s": 1,
                                    "d_id": selectedRows[0].d_id,
                                    "u_ids": uiddomian
                                },
                            ]
                        }
                    ],
                paramid: paramid
            })
        }
        // }
        // if (newData && newData.length > 0 && newData.includes(true)) {
        //     let temp = []
        //     for (let i in updateStatus) {
        //         if (updateStatus[i] !== startData[i]) {
        //             temp.push(true)
        //         }
        //     }
        //     if (temp && temp.length > 0 && temp.includes(true)) {
        //         if (saveformValidator.current.allValid()) {
        //             SaveStatutorySettings({
        //                 payload:
        //                     [
        //                         authtoken,
        //                         {
        //                             "session_token": authtoken,
        //                             "request": [
        //                                 "SaveStatutorySettings",
        //                                 {
        //                                     "update_statutories": statutorypayload,
        //                                     "le_id": entityid,
        //                                     "s_s": 1,
        //                                     "d_id": selectedRows[0].d_id,
        //                                     "u_ids": uiddomian
        //                                 },
        //                             ]
        //                         }
        //                     ],
        //                 paramid: paramid
        //             })
        //         }
        //     }
        //     else {
        //         Toaster.error("Atleast one compliance should be select")
        //     }
        // }
        // else {
        //     console.log(saveformValidator.current.allValid(), 'saveformValidator.current.allValid()');
        //     // if (saveformValidator.current.allValid()) {
        //     SaveStatutorySettings({
        //         payload:
        //             [
        //                 authtoken,
        //                 {
        //                     "session_token": authtoken,
        //                     "request": [
        //                         "SaveStatutorySettings",
        //                         {
        //                             "update_statutories": statutorypayload,
        //                             "le_id": entityid,
        //                             "s_s": 1,
        //                             "d_id": selectedRows[0].d_id,
        //                             "u_ids": uiddomian
        //                         },
        //                     ]
        //                 }
        //             ],
        //         paramid: paramid
        //     })
        //     // }
        // }

    }

    const panel_data = (val, collapseId, parent, child) => {
        // }
        if (CollapseStatus[collapseId] != 1) {
            return <div
                onClick={(event) => {
                    // If you don't want click extra trigger collapse, you can prevent this:
                    event.stopPropagation();
                }}>
                <label style={{ "paddingRight": "15px" }}>Remarks :</label>
                <TextArea style={{ width: "120px" }} cols="5" rows="1" type={"text"} defaultValue={UpdatePannelRemarks[collapseId]} key={UpdatePannelRemarks[collapseId]}
                    onKeyPress={(event) => {
                        if (! /^[A-Z0-9a-z ! @ < > # $ % & * ( + = ) _ .,-]+$/.test(event.key)) {
                            event.preventDefault();
                        }
                    }}
                    onChange={(e) => {
                        let chk_valid = ''

                        for (let i = 0; i < e.target.value.length; i++) {

                            console.log(e.target.value[i], 'chk pannuvo')
                            if (e.target.value[i] == '<' || e.target.value[i] == '>') {
                                chk_valid += e.target.value[i]
                            }
                        }
                        if (chk_valid.match('<>')) {
                            UpdatePannelRemarks[collapseId] = ''
                            setUpdatePannelRemarks([...UpdatePannelRemarks])
                            Toaster.error('Please check the input, it having some invalid characters')
                        }
                    }}
                    onBlur={(e) => {
                        if (collapseId in UpdatePannelRemarks) {
                            // let index = updateRemarks.indexOf(rowId)
                            UpdatePannelRemarks[collapseId] = [];
                            if (UpdatePannelRemarks.indexOf(collapseId) == -1) {
                                UpdatePannelRemarks[collapseId] = e.target.value
                            }
                        } else {
                            UpdatePannelRemarks[collapseId] = [];
                            UpdatePannelRemarks[collapseId] = e.target.value
                        }
                        // setRemarksModal(false)
                        setPannelRemarksModal(false)
                        let temp = []
                        let tempArray = []
                        for (let i in child) {
                            temp.push(child[i].unit_wise_status)
                        }
                        for (let j in temp) {
                            for (let k in temp[j]) {
                                tempArray.push(temp[j][k].c_comp_id)
                            }
                        }
                        for (let i in unitWiseData) {
                            for (let k in unitWiseData[i]) {
                                if (tempArray.indexOf(unitWiseData[i][k].c_comp_id) >= 0) {
                                    // updateRemarks[unitWiseData[i][k].childId] = null
                                    collapseRowRemarks[unitWiseData[i][k].childId] = UpdatePannelRemarks[collapseId]
                                }
                            }
                        }
                        // for (let i in child) {
                        //     console.log('pppp');
                        //     if (child[i].childId in RowRemarks) {
                        //         let index = RowRemarks.indexOf(child[i].childId)
                        //         console.log(index, 'index');
                        //         RowRemarks[child[i].childId] = [];
                        //         console.log(index == -1, 'index == -1');
                        //         if (index == -1) {
                        //             RowRemarks[child[i].childId] = e.target.value
                        //         }
                        //         else {
                        //             RowRemarks[child[i].childId] = [];
                        //             RowRemarks[child[i].childId] = e.target.value
                        //         }
                        //     }
                        // }
                        setcollapseRowRemarks([...collapseRowRemarks])
                        if (PanelRemarkChange == true) {
                            // for (let i in child) {
                            //     if (child[i].childId in updateRemarks) {
                            //         let index = updateRemarks.indexOf(child[i].childId)
                            //         console.log(index, 'index');
                            //         updateRemarks[child[i].childId] = [];
                            //         console.log(index == -1, 'index == -1');
                            //         if (index == -1) {
                            //             updateRemarks[child[i].childId] = e.target.value
                            //         }
                            //         else {
                            //             updateRemarks[child[i].childId] = [];
                            //             updateRemarks[child[i].childId] = e.target.value
                            //         }
                            //     }
                            // }
                            for (let i in unitWiseData) {
                                for (let k in unitWiseData[i]) {
                                    if (tempArray.indexOf(unitWiseData[i][k].c_comp_id) >= 0) {
                                        updateRemarks[unitWiseData[i][k].childId] = e.target.value
                                    }
                                }
                            }
                            setUpdateRemarks([...updateRemarks])
                        }
                    }
                    }
                />
                {/* {saveformValidator.current.message(
                    'remarks',
                    UpdatePannelRemarks[collapseId],
                    'required',
                    {
                        className: `invalid-feedback ${remarksFormSubmit ? 'show' : 'hide'}`,
                        messages: {
                            required: 'Remarks Required',

                        }
                    })
                } */}
                <PlusCircleOutlined className='text-info' style={{ "paddingLeft": "5px" }} onClick={() => { showRemarksModall(collapseId, parent, child) }} />
            </div>
        }
        else {
            return null
        }
    }
    const editscreen = (len) => {
        let uid = []
        setshow(true)
        seteditshow(true);
        setlistshow(false);
        for (let i = 0; i < selectedRows.length; i++) {
            uid.push(selectedRows[i].u_id)
        }
        const editvalue = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetSettingsCompliances",
                    {
                        "le_id": entityid,
                        "u_ids": uid,
                        "r_count": len,
                        "d_id": selectedRows[0].d_id
                    }
                ]
            }
        ]
        console.log(len, 'len555555');
        editstatutory({
            payload: editvalue,
            paramid: paramid
        })
    }

    const previous = () => {
        seteditshow(false);
        setlistshow(true);
        // setCheckedRow(false)
        setSelectedRows([])
        var ele = document.getElementsByName('allchecked');
        ele[0].checked = false;
        var ele1 = document.getElementsByName('checked');
        // for (var j = 0; j < ele1.length; j++) {
        //     if (ele1[j].type === 'checkbox')
        //         ele1[j].checked = false;
        // }
        for (var j = 0; j < ele1.length; j++) {
            if (ele1[j].type === 'checkbox')
                ele1[j].checked = false;
            let a = document.getElementsByClassName(ele1[j].className)
            // a[0].removeAttribute("hidden")
            for (let j = 0; j < a.length; j++) {
                a[j].removeAttribute("disabled")
            }
        }
        setCheckdata([])
        // setSelectedRows([])
        return (
            <>
                <Statutorysettings />
            </>

        )
    }
    useEffect(() => {
        if (editdata.length > 0) {
            if (showmoredataadded == true) {
                let temp = editDatas
                let temp2 = editDatas && editDatas.length > 0 && editDatas.map((item) => {
                    return item.comp_id
                })
                console.log(temp2, 'temp2');
                console.log(temp[temp.length - 1].comp_id, 'temp[temp.length - 1].comp_id');
                let lastIndex = temp[temp.length - 1].comp_id
                let lastData = temp[temp.length - 1].unit_wise_status
                console.log(editdata, 'editdata[ZA');
                // for (let i in editdata) {
                //     console.log();
                //     console.log(editdata[i].comp_id, 'editdata[0].comp_id');

                //     if (editdata[i].comp_id == lastIndex) {
                //         console.log(editdata[i].comp_id, 'editdata[0].comp_id12235');
                //         let data = lastData.concat(editdata[i].unit_wise_status)
                //         lastData = data
                //     }
                //     else {
                //         temp.push(editdata[i])
                //     }
                //     console.log(temp, 'temp66633');
                // }
                // setCheckdata([...temp])
                for (let j in temp) {
                    for (let i in editdata) {
                        if (editdata[i].comp_id == temp[j].comp_id) {
                            let type = temp[j].unit_wise_status
                            let type2 = editdata[i].unit_wise_status
                            console.log(temp[j].unit_wise_status, 'temp[j]');

                            type = type.concat(type2)
                            editdata.splice(i, 1)

                        }
                    }

                    // if (editdata[i].comp_id == lastIndex) {
                    //     console.log(editdata[i].comp_id, 'editdata[0].comp_id12235');
                    //     let data = lastData.concat(editdata[i].unit_wise_status)
                    //     lastData = data
                    // }
                    // else {
                    //     temp.push(editdata[i])
                    // }

                }
                for (let i in editdata) {
                    temp.push(editdata[i])
                }
                console.log(temp, 'temp66633');
                setCheckdata([...temp])


                // if (editdata[0].comp_id == lastIndex) {
                //     console.log(editdata[0].comp_id, 'editdata[0].comp_id12235');
                //     let data = lastData.concat(editdata[0].unit_wise_status)
                //     lastData = data
                //     let filterValue = _.reject(editdata, { comp_id: editdata[0].comp_id })
                //     for (let i in filterValue) {
                //         temp.push(filterValue[i])
                //     }
                //     setCheckdata([...temp])
                // }
                // else {

                //     for (let i in editdata) {
                //         temp.push(editdata[i])
                //     }
                //     setCheckdata([...temp])
                // }
                // else {
                //     for (let i in editdata) {
                //         temp.push(editdata[i])
                //     }
                //     setCheckdata([...temp])
                // }

                // let filterValue = _.reject(editdata, { comp_id: editdata[0].comp_id })
                // for (let i in filterValue) {
                //     temp.push(filterValue[i])
                // }
                // console.log(filterValue, 'filterValue');
                // // temp.concat(filterValue)
                // console.log(temp, 'temp');


                // else {
                //     for (let i in editdata) {
                //         temp.push(editdata[i])
                //     }
                //     setCheckdata([...temp])
                // }
            }
            else {
                let temp = []
                for (let i in editdata) {
                    temp.push(editdata[i])
                }
                setCheckdata([...temp])
            }
        }
    }, [editdata])
    const showRemarksModal = (comp_id, collapseId, childId) => {
        setChildValue(childId)
        GetRemarksData({
            payload:
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetRemarksData",
                            {}
                        ]
                    }
                ],
            paramid: paramid
        })
        setRemarksModal(true);
    };
    useEffect(() => {
        if (recallremarks) {
            let temp = [];
            for (let i = 0; i < recallremarks.length; i++) {
                if (recallremarks[i].r_description != '') {
                    temp.push(recallremarks[i])
                }
                Setremarksvalue(temp)
            }
        }

    }, [recallremarks])
    const showRemarksModall = (collapseId, parent, child) => {
        setExtraCollapseId(collapseId)
        setChildData(child)
        let temp = []
        temp.push(collapseId)
        setCollapseMessages([...collapsemessages, ...temp])
        GetRemarksData({
            payload:
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetRemarksData",
                            {}
                        ]
                    }
                ],
            paramid: paramid
        })
        setPannelRemarksModal(true)
        setCollapseremarksModal(true);
    };

    const filterapply = (e) => {
        let payloaddata;
        if (filtervalue.entity === '') {
            payloaddata = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutorySettings",
                        {
                            "le_id": entityid,
                            "div_id": parseInt(filtervalue.division),
                            "cat_id": parseInt(filtervalue.category),
                        }
                    ]
                }
            ]
        } else {
            payloaddata = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutorySettings",
                        {
                            "le_id": filtervalue.entity,
                            "div_id": parseInt(filtervalue.division),
                            "cat_id": parseInt(filtervalue.category),
                        }
                    ]
                }
            ]
        }
        statutorylist({
            payload: payloaddata,
            paramid: paramid
        })
        SetisModalVisible(false);
    }

    // const submitpassword = (e) => {
    //     e.preventDefault();
    //     if (submitpasswordmodal === true) {
    //         const payload = [
    //             authtoken,
    //             {
    //                 "session_token": authtoken,
    //                 "request": [
    //                     "ChangeStatutorySettingsLock",
    //                     {
    //                         "le_id": password.le_id,
    //                         "d_id": password.d_id,
    //                         "u_id": password.u_id,
    //                         "lock": password.lock,
    //                         "password": password.passwordvalue
    //                     }
    //                 ]
    //             }
    //         ]

    //         locksettings({
    //             payload: payload,
    //             paramid: paramid,
    //             history: history
    //         })
    //         Setpasswordmodal(false);
    //     }
    //     // else {

    //     // }
    // }

    const submitpassword = (e) => {
        setAddFormSubmit1(true)
        e.preventDefault();
        if (formValidator.current.allValid()) {
            if (submitpasswordmodal === true) {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "ChangeStatutorySettingsLock",
                            {
                                "le_id": password.le_id,
                                "d_id": password.d_id,
                                "u_id": password.u_id,
                                "lock": password.lock,
                                "password": password.passwordvalue
                            }
                        ]
                    }
                ]
                let payloaddata = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetStatutorySettings",
                            {
                                "le_id": entityid,
                                "div_id": parseInt(filtervalue.division),
                                "cat_id": parseInt(filtervalue.category),
                            }
                        ]
                    }
                ]
                locksettings({
                    payload: payload,
                    paramid: paramid,
                    history: history,
                    payloaddata: payloaddata
                })
            }
            else {
                let temp = []
                for (let i in statutorypayload) {
                    if (statutorypayload[i].a_status != statutorypayload[i].c_o_status) {
                        if (statutorypayload[i].c_remarks != null) {
                            temp.push(true)
                        } else {
                            temp.push(false)
                        }
                    }
                }
                if (updateRemarks.includes('') && UpdatePannelRemarks.includes('')) {
                    // Toaster.error('Toast2 submit Required')
                    Toaster.error('Password Required')
                } else {
                    UpdateStatutorySettings({
                        payload:
                            [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request": [
                                        "UpdateStatutorySettings",
                                        {
                                            "password": password.passwordvalue,
                                            "update_statutories": statutorypayload,
                                            "le_id": entityid,
                                            "s_s": 2,
                                            "d_id": selectedRows[0].d_id,
                                            "u_ids": uiddomian
                                        },

                                    ]
                                }
                            ],
                        paramid: paramid
                    })
                    // SetPassworddisable(true)
                    // Setpasswordmodal(false)
                }
            }
        }
    }
    const homescreen = () => {
        let getUrlDetails = homepage;
        let tempval = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_url;
        let tempkey = getUrlDetails && getUrlDetails.length > 0 && getUrlDetails[0].form_key;
        localStorage.setItem('formkey', tempkey);
        historynew.push(tempval);
        // window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }


    return (
        <>
            <div className='back-to-top'>
                <ScrollButton />
            </div>
            <div className="container-fluid pt-1">
                <div className={"page-titles py-1 page-title-sticky1"} style={{ paddingRight: '25px' }}>
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 align-self-center" style={{ padding: '0px' }}>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <span style={{ fontSize: '16px' }}>Transaction</span>
                                    </li>
                                    <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                        <span >Statutory Settings</span>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end" style={{ paddingRight: 0, paddingBottom: '5px' }}>
                            {/* <button
                            className="btn btn-light-success rounded-pill px-4 text-success"
                            onClick={(e) => {
                                savestatutorysettings(e)
                            }}
                        >
                            Save
                        </button> */}
                            <Tooltip title='Back' placement='top'><Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                icon={<ArrowLeftOutlined />} size='default' onClick={(e) => { previous(e) }}>
                                Back
                            </Button></Tooltip>
                            <button style={{ marginLeft: '3px' }} onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                                <Tooltip placement="left" title={sessionStorage.getItem('isFullScreen') == 'true' ? "Exit Full Screen Mode" : "Full Screen Mode"} ><FullscreenOutlined /></Tooltip>
                            </button>
                        </div>
                    </div>
                </div>
                {/* <Card> */}
                <div className="statutorycontainer">
                    <div className="col-md-12 mt-1">
                        <div className="row ">
                            {/* <div className={localStorage.getItem("currentTheme")}> */}
                            {titledata &&
                                titledata.map((itemdata, i) => {
                                    return (
                                        <Collapse defaultActiveKey={['0']}
                                            onChange={(e) => { setCollapseId(i) }}
                                            expandIcon={() => CollapseStatus[itemdata.index] == 0 ?
                                                <FeatherIcon icon="x-circle" className={'pointer'} size={20}
                                                    onClick={(event) => {
                                                        let temp = []
                                                        let tempArray = []
                                                        for (let i in itemdata.child) {
                                                            temp.push(itemdata.child[i].unit_wise_status)
                                                        }
                                                        for (let j in temp) {
                                                            for (let k in temp[j]) {
                                                                tempArray.push(temp[j][k].c_comp_id)
                                                            }
                                                        }
                                                        event.stopPropagation();
                                                        setpanelRemarkChange(true)
                                                        if (itemdata.index in CollapseStatus) {
                                                            let index = CollapseStatus.indexOf(itemdata)
                                                            CollapseStatus[itemdata.index] = [];
                                                            if (index == -1) {
                                                                CollapseStatus[itemdata.index] = 2
                                                            }
                                                        } else {
                                                            CollapseStatus[itemdata.index] = [];
                                                            CollapseStatus[itemdata.index] = 2
                                                        }
                                                        setCollapseStatus([...CollapseStatus])
                                                        for (let i in unitWiseData) {
                                                            for (let k in unitWiseData[i]) {
                                                                if (tempArray.indexOf(unitWiseData[i][k].c_comp_id) >= 0) {
                                                                    updateStatus[unitWiseData[i][k].childId] = 2
                                                                    collapseRowStatus[unitWiseData[i][k].childId] = 2
                                                                    updateRemarks[unitWiseData[i][k].childId] = null
                                                                    collapseRowRemarks[unitWiseData[i][k].childId] = UpdatePannelRemarks[itemdata.index]
                                                                    viewstatus[unitWiseData[i][k].childId] = false
                                                                }
                                                            }
                                                        }
                                                        setcollapseRowStatus([...collapseRowStatus])
                                                        setUpdateStatus([...updateStatus])
                                                        setUpdateRemarks([...updateRemarks])
                                                        setcollapseRowRemarks([...collapseRowRemarks])
                                                        setViewstatus([...viewstatus])

                                                    }
                                                    } /> :
                                                CollapseStatus[i] == 1 ? <FeatherIcon icon="check-circle" className={'pointer'} size="20" style={{ color: '#318CE7' }} onClick={(event) => {
                                                    setpanelRemarkChange(true)
                                                    event.stopPropagation();
                                                    let temp = []
                                                    let tempArray = []
                                                    for (let i in itemdata.child) {
                                                        temp.push(itemdata.child[i].unit_wise_status)
                                                    }
                                                    for (let j in temp) {
                                                        for (let k in temp[j]) {
                                                            tempArray.push(temp[j][k].c_comp_id)
                                                        }
                                                    }
                                                    if (itemdata.index in CollapseStatus) {
                                                        let index = CollapseStatus.indexOf(itemdata)
                                                        CollapseStatus[itemdata.index] = [];
                                                        if (index == -1) {
                                                            CollapseStatus[itemdata.index] = 0

                                                        }

                                                    } else {
                                                        CollapseStatus[itemdata.index] = [];
                                                        CollapseStatus[itemdata.index] = 0
                                                    }
                                                    setCollapseStatus([...CollapseStatus])
                                                    for (let i in unitWiseData) {
                                                        for (let k in unitWiseData[i]) {
                                                            // if (unitWiseData[i][k].c_comp_id == itemdata.child.c_comp_id) {
                                                            if (tempArray.indexOf(unitWiseData[i][k].c_comp_id) >= 0) {
                                                                updateStatus[unitWiseData[i][k].childId] = 0
                                                                updateRemarks[unitWiseData[i][k].childId] = null
                                                                collapseRowStatus[unitWiseData[i][k].childId] = 0
                                                                collapseRowRemarks[unitWiseData[i][k].childId] = UpdatePannelRemarks[itemdata.index]
                                                                viewstatus[unitWiseData[i][k].childId] = false
                                                            }


                                                        }
                                                    }

                                                    setUpdateStatus([...updateStatus])
                                                    setcollapseRowStatus([...collapseRowStatus])
                                                    setUpdateRemarks([...updateRemarks])
                                                    setViewstatus([...viewstatus])
                                                    setcollapseRowRemarks([...collapseRowRemarks])
                                                }} /> : <FeatherIcon icon="minus-circle" className={'pointer'} size={20} style={{ color: '#FF0000' }} onClick={(event) => {
                                                    setpanelRemarkChange(true)
                                                    event.stopPropagation();
                                                    let temp = []
                                                    let tempArray = []
                                                    for (let i in itemdata.child) {
                                                        temp.push(itemdata.child[i].unit_wise_status)
                                                    }
                                                    for (let j in temp) {
                                                        for (let k in temp[j]) {
                                                            tempArray.push(temp[j][k].c_comp_id)
                                                        }
                                                    }
                                                    if (itemdata.index in CollapseStatus) {
                                                        let index = CollapseStatus.indexOf(itemdata)
                                                        CollapseStatus[itemdata.index] = [];
                                                        if (index == -1) {
                                                            CollapseStatus[itemdata.index] = 1
                                                        }
                                                        for (let i in itemdata.child) {
                                                            temp.push(itemdata.child[i].comp_id
                                                            )
                                                        }
                                                        setCheckoutlined([...checkoutlined, ...temp])
                                                    } else {
                                                        CollapseStatus[itemdata.index] = [];
                                                        CollapseStatus[itemdata.index] = 1
                                                    }
                                                    setCollapseStatus([...CollapseStatus])
                                                    for (let i in unitWiseData) {
                                                        for (let k in unitWiseData[i]) {
                                                            // if (unitWiseData[i][k].c_comp_id == itemdata.child.c_comp_id) {
                                                            if (tempArray.indexOf(unitWiseData[i][k].c_comp_id) >= 0) {
                                                                updateStatus[unitWiseData[i][k].childId] = 1
                                                                collapseRowStatus[unitWiseData[i][k].childId] = 1
                                                                updateRemarks[unitWiseData[i][k].childId] = null
                                                                viewstatus[unitWiseData[i][k].childId] = false
                                                                collapseRowRemarks[unitWiseData[i][k].childId] = UpdatePannelRemarks[itemdata.index]
                                                            }
                                                        }
                                                    }
                                                    setcollapseRowStatus([...collapseRowStatus])
                                                    setUpdateStatus([...updateStatus])
                                                    setRowStatus([...RowStatus])
                                                    setUpdateRemarks([...updateRemarks])
                                                    setcollapseRowRemarks([...collapseRowRemarks])
                                                    setViewstatus([...viewstatus])
                                                }} />
                                            }
                                            className="site-collapse-custom-collapse multistatutory"
                                        >
                                            <Panel header={itemdata.parent} showArrow={<p>ssss</p>} key={itemdata.index}
                                                extra={genExtra(itemdata.child[0].not_app_remarks, itemdata.index, itemdata.parent, itemdata.child)}>
                                                <div className={localStorage.getItem('currentTheme')}>
                                                    <Table
                                                        className={'multistatutoryset'}
                                                        size={'small'}
                                                        columns={column}
                                                        dataSource={itemdata.child}
                                                        pagination={false}
                                                        expandable={{
                                                            expandedRowRender: (records) => {
                                                                const columns = [
                                                                    {
                                                                        title: 'Location',
                                                                        dataIndex: 'unit_id',
                                                                        key: 'unit_id',
                                                                        width: '70px',
                                                                        ellipsis: true,
                                                                        render: (text, record) => {
                                                                            let array = _.find(list, { u_id: record.unit_id })

                                                                            return (
                                                                                <p>{array && array.location}</p>
                                                                            )
                                                                        }
                                                                    },
                                                                    {
                                                                        title: 'Unit Name',
                                                                        dataIndex: 'unit_id',
                                                                        key: 'unit_id',
                                                                        width: '70px',
                                                                        ellipsis: true,
                                                                        render: (text, record) => {
                                                                            let array = _.find(list, { u_id: record.unit_id })
                                                                            return (
                                                                                <span>{array && array.u_name}</span>
                                                                            )

                                                                        }
                                                                    },
                                                                    {
                                                                        title:

                                                                            <Fragment>
                                                                                <EditOutlined title='Applicable' />
                                                                            </Fragment>,
                                                                        align: 'center',
                                                                        render: (record) => record.comp_app_status === true ? <FeatherIcon icon="check-circle" className={'pointer'} size="20" /> : <FeatherIcon icon="x-circle" className={'pointer'} size={20} />,
                                                                        // sortable: true,
                                                                        // right: true,
                                                                        reorder: true,
                                                                        width: '30px',
                                                                        ellipsis: true
                                                                    },

                                                                    {
                                                                        title: <Fragment>
                                                                            <UserAddOutlined title='Opted' />
                                                                        </Fragment>,
                                                                        align: 'center',
                                                                        render: (record, index, i) => {
                                                                            if (updateStatus[record.childId] === 1) {
                                                                                console.log(record, 'record999999');
                                                                                return <FeatherIcon icon="check-circle" className={'pointer'} size="20" style={{ color: 'orange' }} defaultValue={updateStatus[record.childId]} key={updateStatus[record.childId]}
                                                                                    onClick={(e) => {
                                                                                        setpanelRemarkChange(false)
                                                                                        CheckStatus.map((item, i) => {
                                                                                            if (item == record.c_comp_id) {
                                                                                                CheckStatus.splice(i, 1)
                                                                                            }
                                                                                        })
                                                                                        if (record.childId in updateStatus) {
                                                                                            let index = updateStatus.indexOf(record)
                                                                                            updateStatus[record.childId] = [];
                                                                                            if (index == -1) {
                                                                                                updateStatus[record.childId] = 0
                                                                                            }
                                                                                            else {
                                                                                                updateStatus[record.childId] = [];
                                                                                                updateStatus[record.childId] = 0
                                                                                            }
                                                                                        }
                                                                                        setUpdateStatus([...updateStatus])
                                                                                        if (record.childId in collapseRowStatus) {
                                                                                            let index = collapseRowStatus.indexOf(record)
                                                                                            collapseRowStatus[record.childId] = [];
                                                                                            if (index == -1) {
                                                                                                collapseRowStatus[record.childId] = 0
                                                                                            }
                                                                                            else {
                                                                                                collapseRowStatus[record.childId] = [];
                                                                                                collapseRowStatus[record.childId] = 0
                                                                                            }
                                                                                        }
                                                                                        setcollapseRowStatus([...collapseRowStatus])

                                                                                        if (CheckStatus.includes(record.c_comp_id)) {
                                                                                            if (records.parentId in CollapseStatus) {
                                                                                                RowStatus[records.childId] = [];
                                                                                                CollapseStatus[records.parentId] = 1
                                                                                                setCollapseStatus([...CollapseStatus])
                                                                                            }
                                                                                        } else {
                                                                                            // let temp1 = []
                                                                                            // for (let i in unitWiseData) {
                                                                                            //     for (let k in unitWiseData[i]) {
                                                                                            //         if (checkdatas.indexOf(unitWiseData[i][k].c_comp_id) >= 0) {
                                                                                            //             if (unitWiseData[i][k].c_comp_id == record.c_comp_id) {
                                                                                            //                 temp1.push(0)
                                                                                            //             }
                                                                                            //             else {
                                                                                            //                 let a = unitWiseData[i][k].childId
                                                                                            //                 temp1.push(updateStatus[a])
                                                                                            //             }
                                                                                            //         }
                                                                                            //     }
                                                                                            // }
                                                                                            // if (temp1.includes(1) != true) {
                                                                                            //     CollapseStatus[records.parentId] = 0
                                                                                            // }
                                                                                            // setCollapseStatus([...CollapseStatus])
                                                                                            let temp2 = []
                                                                                            if (itemdata.child.length > 0) {
                                                                                                for (let i in itemdata.child) {
                                                                                                    if (updateStatus[itemdata.child[i].childId] == 1) {
                                                                                                        temp2.push(true)
                                                                                                    }
                                                                                                    else {
                                                                                                        temp2.push(false)
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                            let data2 = temp2 && temp2.length > 0 && temp2.filter((currentElement) => currentElement == true).length;

                                                                                            console.log(data2, 'data2');
                                                                                            if (itemdata.child.length > 1) {
                                                                                                if (data2 == 1) {
                                                                                                    CollapseStatus[itemdata.index] = 0
                                                                                                    setCollapseId([...CollapseStatus])
                                                                                                }
                                                                                            }
                                                                                            else {
                                                                                                if (data2 == 0) {
                                                                                                    CollapseStatus[itemdata.index] = 0
                                                                                                    setCollapseId([...CollapseStatus])
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                        CheckStatus.map((item, i) => {
                                                                                            if (item == record.c_comp_id) {
                                                                                                CheckStatus.splice(i, 1)
                                                                                            }
                                                                                        })
                                                                                        setCheckStatus([...CheckStatus])
                                                                                        if (record.comp_remarks == null || record.comp_remarks == '') {
                                                                                            if (record.childId in updateRemarks) {
                                                                                                let index = updateRemarks.indexOf(record)
                                                                                                updateRemarks[record.childId] = [];
                                                                                                if (index == -1) {
                                                                                                    updateRemarks[record.childId] = null;
                                                                                                }

                                                                                            } else {
                                                                                                updateRemarks[record.childId] = [];
                                                                                                updateRemarks[record.childId] = null
                                                                                            }
                                                                                            setUpdateRemarks([...updateRemarks])
                                                                                        } else {
                                                                                            if (record.childId in updateRemarks) {
                                                                                                let index = updateRemarks.indexOf(record)
                                                                                                updateRemarks[record.childId] = [];
                                                                                                if (index == -1) {
                                                                                                    updateRemarks[record.childId] = record.comp_remarks;
                                                                                                }

                                                                                            } else {
                                                                                                updateRemarks[record.childId] = [];
                                                                                                updateRemarks[record.childId] = record.comp_remarks
                                                                                            }
                                                                                            setUpdateRemarks([...updateRemarks])
                                                                                        }
                                                                                        viewstatus[record.childId] = false
                                                                                        setViewstatus([...viewstatus])
                                                                                        // CheckStatus.map((item, i) => {
                                                                                        //     if (item == record.comp_id) {
                                                                                        //         CheckStatus.splice(i, 1)
                                                                                        //     }
                                                                                        // })
                                                                                        // setCheckStatus([...CheckStatus])
                                                                                        // let tempArray = []
                                                                                    }}
                                                                                />
                                                                            }
                                                                            else if (updateStatus[record.childId] === 2) {
                                                                                return <FeatherIcon icon="minus-circle" className={'pointer'} size={20} style={{ color: '#FF0000' }}
                                                                                    onClick={(e) => {
                                                                                        setpanelRemarkChange(false)
                                                                                        setCheck(records.parentId)
                                                                                        let tempArray = []
                                                                                        if (record.childId in updateStatus) {
                                                                                            let index = updateStatus.indexOf(record)
                                                                                            updateStatus[record.childId] = [];
                                                                                            if (index == -1) {
                                                                                                updateStatus[record.childId] = 1
                                                                                            }
                                                                                            else {
                                                                                                updateStatus[record.childId] = [];
                                                                                                updateStatus[record.childId] = 1
                                                                                            }
                                                                                        }
                                                                                        setUpdateStatus([...updateStatus])
                                                                                        let temp = []
                                                                                        temp.push(record.c_comp_id)
                                                                                        setCheckStatus([...CheckStatus, ...temp])
                                                                                        if (record.childId in collapseRowStatus) {
                                                                                            let index = collapseRowStatus.indexOf(record)
                                                                                            collapseRowStatus[record.childId] = [];
                                                                                            if (index == -1) {
                                                                                                collapseRowStatus[record.childId] = 0
                                                                                            }
                                                                                            else {
                                                                                                collapseRowStatus[record.childId] = [];
                                                                                                collapseRowStatus[record.childId] = 0
                                                                                            }
                                                                                        }
                                                                                        setcollapseRowStatus([...collapseRowStatus])
                                                                                        CollapseStatus[records.parentId] = 1
                                                                                        setCollapseStatus([...CollapseStatus])
                                                                                        UpdatePannelRemarks[record.childId] = null;
                                                                                        setUpdatePannelRemarks([...UpdatePannelRemarks])
                                                                                        viewstatus[record.childId] = false
                                                                                        setViewstatus([...viewstatus])
                                                                                    }
                                                                                    }
                                                                                    defaultValue={updateStatus[record.childId]} key={updateStatus[record.childId]} />

                                                                            }
                                                                            else {
                                                                                return <FeatherIcon icon="x-circle" className={'pointer'} size={20} defaultValue={updateStatus[record.childId]} key={updateStatus[record.childId]}
                                                                                    onClick={(e) => {
                                                                                        // changeremarks(e, record)
                                                                                        setpanelRemarkChange(false)
                                                                                        setCheck(records.parentId)   // setCheck(false)
                                                                                        let tempArray = []
                                                                                        if (record.childId in updateStatus) {
                                                                                            let index = updateStatus.indexOf(record)
                                                                                            updateStatus[record.childId] = [];
                                                                                            if (index == -1) {
                                                                                                updateStatus[record.childId] = 2
                                                                                            }
                                                                                            else {
                                                                                                updateStatus[record.childId] = [];
                                                                                                updateStatus[record.childId] = 2
                                                                                            }
                                                                                        }
                                                                                        setUpdateStatus([...updateStatus])
                                                                                        if (record.childId in collapseRowStatus) {
                                                                                            let index = collapseRowStatus.indexOf(record)
                                                                                            collapseRowStatus[record.childId] = [];
                                                                                            if (index == -1) {
                                                                                                collapseRowStatus[record.childId] = 0
                                                                                            }
                                                                                            else {
                                                                                                collapseRowStatus[record.childId] = [];
                                                                                                collapseRowStatus[record.childId] = 0
                                                                                            }
                                                                                        }
                                                                                        setcollapseRowStatus([...collapseRowStatus])
                                                                                        let temp = []
                                                                                        if (itemdata.child.length > 0) {
                                                                                            for (let i in itemdata.child) {
                                                                                                console.log(updateStatus[itemdata.child[i].childId], 'updateStatus[itemdata.child[i].childId] ');
                                                                                                if (updateStatus[itemdata.child[i].childId] == 2) {
                                                                                                    temp.push(true)
                                                                                                }
                                                                                                else {
                                                                                                    temp.push(false)
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                        console.log(temp, 'temp9999');
                                                                                        if (temp && temp.length > 0) {
                                                                                            if (temp.includes(false) == false) {
                                                                                                CollapseStatus[itemdata.index] = 2
                                                                                                setCollapseStatus([...CollapseStatus])
                                                                                            }
                                                                                        }
                                                                                        // CheckStatus.map((item, i) => {
                                                                                        //     if (item == record.comp_id) {
                                                                                        //         CheckStatus.splice(i, 1)
                                                                                        //     }
                                                                                        // })
                                                                                        // setCheckStatus([...CheckStatus])
                                                                                        // CheckStatus.map((item, i) => {
                                                                                        //     if (item == record.c_comp_id) {
                                                                                        //         CheckStatus.splice(i, 1)
                                                                                        //     }
                                                                                        // })
                                                                                        // setCheckStatus([...CheckStatus])
                                                                                        viewstatus[record.childId] = false
                                                                                        setViewstatus([...viewstatus])

                                                                                    }}

                                                                                />
                                                                            }

                                                                        },
                                                                        reorder: true,
                                                                        ellipsis: true,
                                                                        width: '30px'
                                                                    },
                                                                    {
                                                                        title: "Remarks",
                                                                        ellipsis: true,
                                                                        width: '50px',
                                                                        render: (record) => {
                                                                            console.log(itemdata.index, 'itemdata.parent');
                                                                            console.log(Number(record.comp_app_status) != updateStatus[record.childId], 'Number(record.comp_app_status) != updateStatus[record.childId]');

                                                                            // if (CheckStatus.includes(record.c_comp_id) == false) {
                                                                            //     if ((updateRemarks[record.childId] === null) && (CollapseStatus[itemdata.index] === 1) && (updateStatus[record.childId] != 2) && (updateStatus[record.childId] != 1)) {
                                                                            //         return <p>{record.n_a_remarks}</p>
                                                                            //     }
                                                                            // }
                                                                            if (itemdata.child.length > 1) {
                                                                                if ((Number(record.comp_app_status) != updateStatus[record.childId]) && (CollapseStatus[itemdata.index] !== updateStatus[record.childId])) {
                                                                                    console.log('aishuuuu');
                                                                                    return <>
                                                                                        <TextArea class="form-control" cols="5" rows="1" placeholder='Enter remarks' style={{ "width": "90%" }}
                                                                                            // value={row.unit_wise_status[0].comp_remarks}
                                                                                            defaultValue={updateRemarks[record.childId] || undefined}
                                                                                            key={updateRemarks[record.childId]}
                                                                                            onKeyPress={(event) => {
                                                                                                if (! /^[A-Z0-9a-z ! @  # $ % & < > ?/, * ( + = ) _ .,-]+$/.test(event.key)) {
                                                                                                    event.preventDefault();
                                                                                                }
                                                                                            }}
                                                                                            onChange={(e) => {
                                                                                                console.log(CollapseStatus[record.parentId], 'CollapseStatus[record.parentId]');
                                                                                                console.log(updateStatus[record.childId], 'updateStatus[record.childId]');
                                                                                                let chk_valid = ''
                                                                                                for (let i = 0; i < e.target.value.length; i++) {
                                                                                                    console.log(e.target.value[i], 'chk pannuvo')
                                                                                                    if (e.target.value[i] == '<' || e.target.value[i] == '>') {
                                                                                                        chk_valid += e.target.value[i]
                                                                                                    }
                                                                                                }
                                                                                                if (chk_valid.match('<>')) {
                                                                                                    //   UpdatePannelRemarks[collapseId] = ''
                                                                                                    //   setUpdatePannelRemarks([...UpdatePannelRemarks])

                                                                                                    updateRemarks[record.childId] = ''
                                                                                                    setUpdateRemarks([...updateRemarks])
                                                                                                    Toaster.error('Please check the input, it having some invalid characters')
                                                                                                }
                                                                                            }}
                                                                                            onBlur={(e) => {
                                                                                                let tempArray = []
                                                                                                let temp = []
                                                                                                temp.push(e.target.value)
                                                                                                if (record.childId in updateRemarks) {
                                                                                                    let index = updateRemarks.indexOf(record)
                                                                                                    updateRemarks[record.childId] = [];
                                                                                                    if (index == -1) {
                                                                                                        updateRemarks[record.childId] = e.target.value;
                                                                                                    }

                                                                                                } else {
                                                                                                    updateRemarks[record.childId] = [];
                                                                                                    updateRemarks[record.childId] = e.target.value
                                                                                                }
                                                                                                setUpdateRemarks([...updateRemarks])
                                                                                            }}

                                                                                            id="remarks" type={"text"}
                                                                                        // hidden={PanelRemarkChange == true || updateRemarks[record.childId] === RowRemarks[record.childId] ? true : false}
                                                                                        />
                                                                                        {saveformValidator.current.message(
                                                                                            'remarks',
                                                                                            updateRemarks[record.childId],
                                                                                            'required',
                                                                                            {
                                                                                                className: `invalid-feedback ${remarksFormSubmit ? 'show' : 'hide'}`,
                                                                                                messages: {
                                                                                                    required: 'Remarks required',

                                                                                                }
                                                                                            })
                                                                                        }

                                                                                        <PlusCircleOutlined className='text-info ms-1' onClick={() => { showRemarksModal(records.comp_id, records.collapseId, record.childId) }}
                                                                                        // hidden={PanelRemarkChange == true || updateRemarks[record.childId] === RowRemarks[record.childId] ? true : false}
                                                                                        />




                                                                                    </>
                                                                                }
                                                                                else if ((Number(record.comp_app_status) == updateStatus[record.childId]) && (CollapseStatus[itemdata.index] !== updateStatus[record.childId])) {
                                                                                    return <p>{UpdatePannelRemarks[itemdata.index]}</p>
                                                                                }
                                                                            }
                                                                            else {
                                                                                if ((Number(record.comp_app_status) != updateStatus[record.childId])) {
                                                                                    console.log('aishuuuu');
                                                                                    return <>
                                                                                        <TextArea class="form-control" cols="5" rows="1" placeholder='Enter remarks' style={{ "width": "90%" }}
                                                                                            // value={row.unit_wise_status[0].comp_remarks}
                                                                                            defaultValue={updateRemarks[record.childId] || undefined}
                                                                                            key={updateRemarks[record.childId]}
                                                                                            onKeyPress={(event) => {
                                                                                                if (! /^[A-Z0-9a-z ! @  # $ % & < > ?/, * ( + = ) _ .,-]+$/.test(event.key)) {
                                                                                                    event.preventDefault();
                                                                                                }
                                                                                            }}
                                                                                            onChange={(e) => {
                                                                                                console.log(CollapseStatus[record.parentId], 'CollapseStatus[record.parentId]');
                                                                                                console.log(updateStatus[record.childId], 'updateStatus[record.childId]');
                                                                                                let chk_valid = ''
                                                                                                for (let i = 0; i < e.target.value.length; i++) {
                                                                                                    console.log(e.target.value[i], 'chk pannuvo')
                                                                                                    if (e.target.value[i] == '<' || e.target.value[i] == '>') {
                                                                                                        chk_valid += e.target.value[i]
                                                                                                    }
                                                                                                }
                                                                                                if (chk_valid.match('<>')) {
                                                                                                    //   UpdatePannelRemarks[collapseId] = ''
                                                                                                    //   setUpdatePannelRemarks([...UpdatePannelRemarks])

                                                                                                    updateRemarks[record.childId] = ''
                                                                                                    setUpdateRemarks([...updateRemarks])
                                                                                                    Toaster.error('Please check the input, it having some invalid characters')
                                                                                                }
                                                                                            }}
                                                                                            onBlur={(e) => {
                                                                                                let tempArray = []
                                                                                                let temp = []
                                                                                                temp.push(e.target.value)
                                                                                                if (record.childId in updateRemarks) {
                                                                                                    let index = updateRemarks.indexOf(record)
                                                                                                    updateRemarks[record.childId] = [];
                                                                                                    if (index == -1) {
                                                                                                        updateRemarks[record.childId] = e.target.value;
                                                                                                    }

                                                                                                } else {
                                                                                                    updateRemarks[record.childId] = [];
                                                                                                    updateRemarks[record.childId] = e.target.value
                                                                                                }
                                                                                                setUpdateRemarks([...updateRemarks])
                                                                                            }}

                                                                                            id="remarks" type={"text"}
                                                                                        // hidden={updateStatus[record.childId] !== 1}
                                                                                        />
                                                                                        {saveformValidator.current.message(
                                                                                            'remarks',
                                                                                            updateRemarks[record.childId],
                                                                                            'required',
                                                                                            {
                                                                                                className: `invalid-feedback ${remarksFormSubmit ? 'show' : 'hide'}`,
                                                                                                messages: {
                                                                                                    required: 'Remarks required',

                                                                                                }
                                                                                            })
                                                                                        }

                                                                                        <PlusCircleOutlined className='text-info ms-1' onClick={() => { showRemarksModal(records.comp_id, records.collapseId, record.childId) }}
                                                                                        // hidden={PanelRemarkChange == true || updateRemarks[record.childId] === RowRemarks[record.childId] ? true : false}
                                                                                        // hidden={updateStatus[record.childId] !== 1}
                                                                                        />




                                                                                    </>
                                                                                }

                                                                            }
                                                                            //                                                                             else if ((Number(record.comp_app_status) == updateStatus[record.childId]) && (CollapseStatus[itemdata.index] !== updateStatus[record.childId])) {
                                                                            // return <p>{UpdatePannelRemarks[itemdata.index]}</p>
                                                                            //                                                                             }
                                                                        },
                                                                    },
                                                                    {
                                                                        title: "Viewed",
                                                                        ellipsis: true,
                                                                        align: 'center',
                                                                        render: (record) => {

                                                                            return (
                                                                                <>
                                                                                    {viewstatus[record.childId] == false ?

                                                                                        <Tooltip title={record.compliance_frequency}><p><i style={{ color: "orange" }} class="bi bi-square-fill"></i></p></Tooltip> : ''
                                                                                    }
                                                                                </>
                                                                            )


                                                                        },
                                                                        sortable: true,
                                                                        reorder: true,
                                                                        width: '30px',
                                                                    }
                                                                ];
                                                                return <Table columns={columns}
                                                                    className='multistatutoryset1'
                                                                    size={'small'}
                                                                    dataSource={unitWiseData[records.childId]} pagination={false} bordered />;

                                                            },
                                                            // expandRowByClick: true,
                                                            showExpandColumn: false,
                                                            defaultExpandAllRows: true
                                                        }}
                                                    />
                                                </div>
                                            </Panel>
                                        </Collapse>
                                    )
                                })}


                            {/* </div>s */}
                        </div>
                    </div>
                    {/* <div className="col-md-12 mt-3">
                    <div className="row">
                        <label style={{ paddingLeft: '0px' }} >Showing 1 to {checkdata.length} of  {totaldatas}</label>
                        <label style={{ paddingLeft: '0px' }} >Note : Maximum 500 character allowed for each remarks</label>
                    </div>
                </div> */}
                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className='col-md-4'  >
                                {/* <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", float: 'left' }}
                                icon={<ArrowLeftOutlined />} size='default' onClick={(e) => { previous(e) }}>
                                Previous
                            </Button> */}
                                <label style={{ paddingLeft: '0px' }} >Showing 1 to {checkdata.length} of  {totaldatas}</label>
                                <label style={{ paddingLeft: '0px' }} >Note : Maximum 500 character allowed for each remarks</label>
                            </div>
                            <div className='col-md-4 text-center'  >
                                {checkdata.length === totaldatas ?
                                    // <Button type="primary" shape="round" className='addbutton' style={{ background: "#198754", borderColor: "#00FF7F" }}
                                    //      icon={<PlayCircleOutlined />} size='default'>
                                    //     Submit
                                    // </Button> 
                                    <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                        onClick={(e) => {
                                            updatestatutorySettings(e)
                                        }} icon={<PlayCircleOutlined />} size='default'>
                                        Submit
                                    </Button>
                                    :
                                    <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                        size='default'
                                        onClick={(e) => { showmore(e) }}
                                    >Show More <DownOutlined />
                                    </Button>
                                }
                            </div>
                            <div className='col-md-4' style={{ textAlign: 'right', paddingRight: 0 }} >
                                {/* {checkdata.length === totaldatas ?
                                <Button type="primary" shape="round" className='addbutton' style={{ background: "#198754", borderColor: "#00FF7F", marginLeft: '50%' }}
                                    onClick={(e) => {
                                        updatestatutorySettings(e)
                                    }} icon={<PlayCircleOutlined />} size='default'>
                                    Submit
                                </Button> : ''
                            } */}
                                <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                    size='default'
                                    onClick={(e) => {
                                        savestatutorysettings(e)
                                    }}
                                >Save <SaveOutlined />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </Card> */}
                {/* AdvanceSearch Modal popup */}
                <Modal title="Statutory Settings" open={isModalVisible} footer={null} onCancel={handleCancel} maskClosable={false}>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label>Business Group:</label>
                                    &nbsp;&nbsp;-
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label>Legal Entity:</label>
                                    {localStorage.getItem('SelectedEntity') === "All" ?
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <select
                                                    className="select2 form-control custom-select bg-white"
                                                    style={{ "width": "100%", "height": "38px" }} onChange={(e) => {
                                                        Setfiltervalue({
                                                            ...filtervalue,
                                                            entity: e.target.value
                                                        })
                                                    }}>
                                                    <option>--Select--</option>
                                                    {filterdatas && filterdatas.length > 0 && filterdatas.le_did_infos.map((item) => {
                                                        return <option value={item.le_id}>{item.le_name}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div> : <p className='text-wrap-report' style={{ "color": "#6666ff", "fontWeight": "bold", width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label>Division</label>
                                    <select
                                        className="select2 form-control custom-select bg-white"
                                        style={{ "width": "100%", "height": "38px" }} onChange={(e) => {
                                            Setfiltervalue({
                                                ...filtervalue,
                                                division: e.target.value
                                            })
                                        }}>
                                        <option>--Select--</option>
                                        {filterdatas && filterdatas.length > 0 && filterdatas.div_infos.map((item) => {
                                            return <option value={item.div_id}>{item.div_name}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label>Category</label>
                                    <select
                                        className="select2 form-control custom-select bg-white"
                                        style={{ "width": "100%", "height": "38px" }} onChange={(e) => {
                                            Setfiltervalue({
                                                ...filtervalue,
                                                category: e.target.value
                                            })
                                        }}>
                                        <option>--Select--</option>
                                        {filterdatas && filterdatas.length > 0 && filterdatas.cat_info.map((item) => {
                                            return <option value={item.cat_id}>{item.cat_name}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        {/* <button
                        type="button"
                        className="btn btn-light-primary text-primary font-weight-medium 
            waves-effect text-start" onClick={filterapply}>
                        Submit
                    </button> */}
                        {/* <div class="button2" id={localStorage.getItem('currentTheme')} onClick={filterapply}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        Submit
                    </div> */}
                        <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ marginTop: '10px' }}
                            onClick={filterapply} icon={<PlayCircleOutlined />} size='default'>
                            Submit
                        </Button>
                        {/* <button
                        type="button"
                        className="btn btn-light-danger text-danger font-weight-medium
            waves-effect text-start" onClick={handleCancel}>
                        Close
                    </button> */}
                    </div>
                </Modal>

                {/* Remarks Modal popup */}
                <Modal title="Remarks List" footer={null} className={"remark-header add-service-prv modelradius " + localStorage.getItem('currentTheme')} open={remarksModal} onOk={handleOk} onCancel={handleCancel} maskClosable={false}>
                    {/* <DataTable columns={remarkscolumn}
                    data={recallremarks}
                    // defaultSortFieldId={1}
                    pagination /> */}
                    <Table
                        // className='userprivclass'
                        className={localStorage.getItem("currentTheme")}
                        size={'small'}
                        columns={remarkscolumn}
                        dataSource={remarksvalue && remarksvalue.length > 0 && remarksvalue}
                        bordered
                        // scroll={{ y: 400 }}
                        pagination={false}
                    />
                </Modal>
                <Modal title="Remarks List" footer={null} className={"remark-header add-service-prv modelradius " + localStorage.getItem('currentTheme')} open={PannelremarksModal} onOk={handleOk} onCancel={handleCancel} maskClosable={false}>
                    {/* <DataTable columns={Pannelremarkscolumn}
                    data={recallremarks}
                    // defaultSortFieldId={1}
                    pagination /> */}
                    <Table
                        // className='userprivclass'
                        className={localStorage.getItem("currentTheme")}
                        size={'small'}
                        columns={Pannelremarkscolumn}
                        dataSource={remarksvalue && remarksvalue.length > 0 && remarksvalue}
                        bordered
                        // scroll={{ y: 400 }}
                        pagination={false}
                    // pagination={{
                    //     defaultPageSize: dataTableProperties.pagesize,
                    //     showSizeChanger: dataTableProperties.sizechanger,
                    //     pageSizeOptions: dataTableProperties.pageSizeOptions
                    // }}
                    />
                </Modal>
                <Stickicon />
                {/* Password Modal popup */}
                <Modal autoFocus={false} open={passwordmodal} footer={null} onCancel={passwordcancel} className="newStyleModalPassword" maskClosable={false}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-6">
                                <label className="control-label">Password:</label>
                                <span style={{ "color": "red" }}>*</span>
                                <input
                                    autoFocus={true}
                                    type="password"
                                    id="password"
                                    name='passwordvalue'
                                    className="form-control"
                                    placeholder="Enter password"
                                    autoComplete='off'
                                    onChange={(e) => {
                                        Setpassword({
                                            ...password,
                                            passwordvalue: e.target.value
                                        })
                                    }}
                                    value={password.passwordvalue}
                                />
                                {formValidator.current.message(
                                    'Password',
                                    password.passwordvalue,
                                    ['required'],
                                    {
                                        className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                        messages: {
                                            required: 'Password Required'
                                        }
                                    })}
                            </div>
                        </div>
                    </div>
                    <div className={"form-actions text-center popupbtncolour " + localStorage.getItem('currentTheme')}>
                        <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ marginTop: '10px' }}
                            onClick={(e) => {
                                submitpassword(e)
                            }} icon={<PlayCircleOutlined />} size='default'>
                            Submit
                        </Button>
                        {/* <button
                  className="btn btn-light-danger rounded-pill px-4 ms-2 text-danger waves-effect text-start" onClick={passwordcancel}>

                  Cancel
                </button> */}
                    </div>
                </Modal>
            </div>
        </>
    )
}

export default connect(mapStateToProps, {
    statutorylist,
    editstatutory,
    filterdata,
    locksettings,
    GetRemarksData,
    SaveStatutorySettings,
    UpdateStatutorySettings
})(MultiUnitStatutorySettings);