import { ConsoleSqlOutlined, ContactsFilled } from '@ant-design/icons';
import HTTP from '../../../Libs/http';
import { EASY_UPLOAD_FILTERDATA, COMPLIANCE_UPCOMING_TASK, SHOW_DATA_RESET, COMPLIANCE_TASK_CALENDER_VIEW, COMPLIANCE_CURRENT_TASK, COMPLIANCE_FILTER_SHOW, COMPLIANCE_VIEW, EASY_UPLOAD_SHOWDATA, EASY_UPLOAD_TABLEDATA, IS_INVALID, REMARKS_HISTORY_TABLE, EASY_FILTER_MODAl, FILEUPLOAD, IND_FILE } from '../../types/index'


const initialState = {
    complianceTaskDetails: {
        compliancefilter: [],
        compliancefiltershow: [],
        currentCompliance: [],
        upcomingTask: [],
        complianceshowdata: [],
        compliancetabledata: [],
        is_invalid: false,
        remarks_history_data: []
    },
    easymodal_filter: false,
    fileupload: [],
    // indiv_file: [{
    //     id: null,
    //     files: []
    // }]
}
export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case EASY_UPLOAD_FILTERDATA:
            {
                return {
                    ...state,
                    complianceTaskDetails: {
                        ...state.complianceTaskDetails,
                        compliancefilter: payload[1],
                    },
                };
            }
        case IS_INVALID:
            {
                return {
                    ...state,
                    is_invalid: payload
                }
            }
        // case IND_FILE: {
        //     console.log(action, 'InVidu=')
        //     return {
        //         ...state,
        //         indiv_file: state.indiv_file[0].files.length > 0 ? [{
        //             id: payload,
        //             files: [...state.indiv_file[0].files.concat(action.fileContent)]
        //         }]
        //             : [{
        //                 id: payload,
        //                 files:action.fileContent]
        //             }]
        //     }
        // }
        case FILEUPLOAD:
            {
                return {
                    ...state,
                    fileupload: payload
                }
            }
        case REMARKS_HISTORY_TABLE: {
            console.log(payload[1].remarks_history, 'payloaddddd')
            return {
                ...state,
                complianceTaskDetails: {
                    ...state.complianceTaskDetails,
                    remarks_history_data: payload[1].remarks_history,
                },
            }
        }
        case EASY_UPLOAD_SHOWDATA:
            {
                return {
                    ...state,
                    complianceTaskDetails: {
                        ...state.complianceTaskDetails,
                        complianceshowdata: payload[1],
                    },
                };
            }
        case EASY_FILTER_MODAl: {
            return {
                ...state,
                easymodal_filter: payload
            }
        }
        // case SHOW_DATA_RESET:
        //     {
        //         return {
        //             ...state,
        //             complianceTaskDetails: {
        //                 ...state.complianceTaskDetails,
        //                 complianceshowdata: [],
        //             },
        //         };
        //     }
        case EASY_UPLOAD_TABLEDATA:
            {
                return {
                    ...state,
                    complianceTaskDetails: {
                        ...state.complianceTaskDetails,
                        compliancetabledata: payload[1],
                    },
                };
            }
        case COMPLIANCE_TASK_CALENDER_VIEW:
            {
                return {
                    ...state,
                    complianceTaskDetails: {
                        ...state.complianceTaskDetails,
                        compliancefiltershow: payload[1],
                    },
                };
            }
        case COMPLIANCE_CURRENT_TASK:
            {
                return {
                    ...state,
                    complianceTaskDetails: {
                        ...state.complianceTaskDetails,
                        currentCompliance: payload[1],
                    },
                };
            }
        case COMPLIANCE_UPCOMING_TASK:
            {
                return {
                    ...state,
                    complianceTaskDetails: {
                        ...state.complianceTaskDetails,
                        upcomingTask: payload[1],
                    },
                };
            }
        default:
            return state;
    }
}