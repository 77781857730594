import { STATOTORYLIST, STATUTORYSEDIT,STATUTORY_SAVED_SUCCESS,STATUTORY_SAVED_SUCCESS_RESET, STATUTORYSEDIT_RESET, STATUTORY_INVALID_PASSWORD_RESET, STATUTORY_INVALID_PASSWORD, STATUTORYFILTERDATA } from '../../types/index'


const initialState = {
    statutorysettings: {
        statutorylist: '',
        editdatas: '',
        filterdata: '',
        totaldata: '',
        invalidpass: '',
        savedstatutory:''
    }
}
export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case STATOTORYLIST:
            {
                return {
                    ...state,
                    statutorysettings: {
                        ...state.statutorysettings,
                        statutorylist: payload[1].statutories,
                    },
                };
            }

        case STATUTORYSEDIT:
            {
                return {
                    ...state,
                    statutorysettings: {
                        ...state.statutorysettings,
                        editdatas: payload[1].applicable_statu,
                        totaldata: payload[1].r_count
                    },
                };
            }
        case STATUTORYSEDIT_RESET:
            {
                return {
                    ...state,
                    statutorysettings: {
                        ...state.statutorysettings,
                        editdatas: '',
                        totaldata: 0
                    },
                };
            }

        case STATUTORYFILTERDATA:
            {
                return {
                    ...state,
                    statutorysettings: {
                        ...state.statutorysettings,
                        filterdata: payload[1],
                    },
                };
            }
        case STATUTORY_INVALID_PASSWORD:
            {
                return {
                    ...state,
                    statutorysettings: {
                        ...state.statutorysettings,
                        invalidpass: payload[0],
                    },
                };
            }
        case STATUTORY_INVALID_PASSWORD_RESET:
            {
                return {
                    ...state,
                    statutorysettings: {
                        ...state.statutorysettings,
                        invalidpass: '',
                    },
                };
            }
            case STATUTORY_SAVED_SUCCESS:
            {
                return {
                    ...state,
                    statutorysettings: {
                        ...state.statutorysettings,
                        savedstatutory: payload[0],
                    },
                };
            }
            case STATUTORY_SAVED_SUCCESS_RESET:
                {
                    return {
                        ...state,
                        statutorysettings: {
                            ...state.statutorysettings,
                            savedstatutory: '',
                        },
                    };
                }

        default:
            return state;
    }
}