import { toast } from "react-toastify";

export const blockInvalidChar = e =>
  ["e", "E", "-"].includes(e.key) && e.preventDefault();

export const allowAlpha = e => {
  var k = e.which;
  const ok = k >= 65 && k <= 90 || // A-Z
    k >= 96 && k <= 105 || // a-z
    k == 8 || k == 9 || k == 32 ||
    k >= 35 && k <= 40; // arrows
  if (!ok) {
    e.preventDefault();
  }
  // regex.includes(e.key) && e.preventDefault();
}
export const allowNum = e => {
  var key = window.event ? e.keyCode : e.which;
  if (e.keyCode === 8 || e.keyCode === 46) {
    e.preventDefault();
  } else if (key < 48 || key > 57) {
    e.preventDefault();
  } else {
    e.preventDefault();
  }

}

export const isTagFound = (value) => {
  let inputVal = value
  let pattern = /(<([^>]+)>)/gi;
  if (pattern.test(inputVal)) {
    value = inputVal.match(pattern)
    toast.error("Please check the input, it having some invalid characters");

    return true;
  }else{
    return false;
  }
}

export const isAllowSpecialChar = (inputElm, event) => {
  var isPasted = false;
  if (event.type == 'paste') {
      isPasted = true;
  } else {
      var start = inputElm.selectionStart,
          end = inputElm.selectionEnd;
      var content = inputElm
      var pattern = /[^ 0-9A-Za-z_\n.,@#&*()=;:/<\>?-]/gi;
      if (isPasted == true) {
          isPasted = false;
          validateValue(content, pattern);
      }
      inputElm.value = content.replace(pattern, '');
      inputElm.setSelectionRange(start, end);
  }
}

export const validateValue = (value,validRegx) => {
  var isValid = (value.trim().match(validRegx) !== null);
  if (isValid) {
      toast.warning("System autocorrected and removed the invalid characters entered, Please validate the data.");
  }
}

