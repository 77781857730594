import { PRIVILEGES, CURRENTSTATUS,RESET_INVALID } from '../../types/index'


const initialState = {
    privileges: '',
    currentstatus: '',
    invalidpass:''

}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case PRIVILEGES: {
            return {
                ...state,
                privileges:  payload[1],
            };
        }
        case CURRENTSTATUS: {
            return {
                ...state,
                currentstatus: payload
            }
        }
        case RESET_INVALID:
            {
                return {
                    ...state,
                    invalidpass: '',
                };
            }
        default:
            return state;
    }
}
