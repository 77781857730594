import { ConsoleSqlOutlined, ContactsFilled } from '@ant-design/icons';
import HTTP from '../../../Libs/http';
import { USERMANAGEMENT_LIST, USERMANAGEMENT_CATEGORY_LIST, USERMANAGEMENT_ADMIN, GET_BY_UNIT, USERMANAGEMENT_HAVE_COMPLIANCE } from '../../types/index'


const initialState = {
    userManagement: {
        adminDetails: '',
        userManagementList: '',
        UserLegalDetails: '',
        EntityDetails: '',
        userManagementDetails: '',
        GetUserDetails: '',
        haveCompliance: '',
        count: 0

    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case USERMANAGEMENT_LIST: {
            return {
                ...state,
                userManagement: {
                    ...state.userManagement,
                    userManagementList: payload[1].ul_users,
                    UserLegalDetails: payload[1].ul_legal_entity,
                    EntityDetails: payload[1]

                },
            };
        }
        case USERMANAGEMENT_ADMIN: {
            return {
                ...state,
                userManagement: {
                    ...state.userManagement,
                    adminDetails: payload,


                },
            };
        }

        case USERMANAGEMENT_HAVE_COMPLIANCE: {

            return {
                ...state,
                userManagement: {
                    ...state.userManagement,
                    haveCompliance: payload,
                    count: state.userManagement.count + 1

                },
            };
        }
        case USERMANAGEMENT_CATEGORY_LIST: {
            return {
                ...state,
                userManagement: {
                    ...state.userManagement,
                    userManagementDetails: payload[1]

                },
            };
        }
        case GET_BY_UNIT: {
            return {
                ...state,
                userManagement: {
                    ...state.userManagement,
                    GetUserDetails: payload[1]

                },
            };
        }
        default:
            return state;
    }
}