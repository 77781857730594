import { useEffect } from "react"

const useStickyHeight = () => {
    const stickyDetail = document.getElementsByClassName('filter-detial-sticky')[0]
    const topNav = document.getElementById('top-nav')

    const resizeObserver = new ResizeObserver((elem) => {
        const tableHead = document.getElementsByClassName('table-head-stick')[0]
        const targetElement = elem?.[0].target
        const top = targetElement.clientHeight + topNav.clientHeight + 40
        tableHead.style.top = `${top}px`
    })
    
    useEffect(() => {
        if (!stickyDetail) return
        resizeObserver.observe(stickyDetail)
        return () => resizeObserver.disconnect()
    }, [stickyDetail])
}

export default useStickyHeight
