import React, { Fragment, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { Toaster } from './../Libs/Toaster';
import ReactTooltip from "react-tooltip";
import { resetNewPassword, resetTokenValidation } from '../Store/Action/RegisterUser';


const mapStateToProps = (state) => ({

})
const Resetpassword = ({
    resetNewPassword,
    resetTokenValidation
}) => {
    const location = useLocation();
    const paramid = location.pathname;
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [ResetData, setResetData] = useState({
        newPassword: '',
        confirmPassword: ''
    })
    const validator = useRef(new SimpleReactValidator());

    useEffect(() => {
        validator.current.showMessages()
        let groupvalue = paramid.split('/');
        const payload = [
            groupvalue[2],
            [
                "ResetTokenValidation",
                {
                    "reset_token": groupvalue[3],
                    "short_name": groupvalue[2]
                }
            ]

        ]
        resetTokenValidation({
            payload: payload,
            callername: paramid,
        })
    }, [])

    const submitReset = (e) => {
        e.preventDefault();
        setAddFormSubmit(true);
        if (validator.current.allValid()) {
            if (ResetData.newPassword === ResetData.confirmPassword) {
                let groupvalue = paramid.split('/');
                const payload = [
                    groupvalue[2],
                    [
                        "ResetPassword",
                        {
                            "reset_token": groupvalue[3],
                            "new_password": ResetData.newPassword,
                            "short_name": groupvalue[2]
                        }
                    ]

                ]
                resetNewPassword({
                    payload: payload,
                    callername: paramid,
                })
            } else {
                Toaster.error('Password Mismatched');
            }
        }
    }
    return (
        <Fragment>
            <div style={{ backgroundImage: 'url(login3-bg.png)', height: '100vh', backgroundSize: 'cover' }} className="main-wrapper-bg auth-wrapper d-flex no-block justify-content-center 
                align-items-end flex-column">
                {/* <div className="auth-wrapper d-flex no-block justify-content-center  */}
                {/* align-items-center"> */}
                <div style={{ borderRadius: '2rem' }} className="auth-box p-3 bg-white m-0 me-5">
                    <center><img src="logo-icon.png" height="50px" alt='Logo' /></center>
                    <div id="loginform">
                        <div className="logo">
                            <center>
                                <h3 className="box-title mb-3">Reset Password</h3>
                            </center>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <form className="form-horizontal mt-3 form-material" id="loginform">
                                    <div className="form-group mb-3">
                                        <div className="">

                                            <input className="form-control" maxLength='20' type="password" required="" placeholder="Enter New Password" maxLength='20' onChange={(e) => {
                                                setResetData({
                                                    ...ResetData, newPassword: e.target.value
                                                })
                                            }} data-tip data-for="Resetpass" />
                                            <ReactTooltip id="Resetpass" place="right" effect="solid">
                                                <ul><li>Password must have atleast 8 characters</li>
                                                    <li>Atleast one uppercase and one lowercase Letter</li>
                                                    <li>Password must have one number and one special character</li></ul>
                                            </ReactTooltip>
                                            {validator.current.message(
                                                'newPassword',
                                                ResetData.newPassword,
                                                ['required',
                                                    {
                                                        regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
                                                    }],
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'New Password is Required',
                                                        regex: 'Invalid Format'
                                                    }
                                                })}
                                        </div>

                                    </div>

                                    <div className="form-group mb-4">

                                        <div className="">

                                            <input className="form-control" maxLength='20' type="password" required="" placeholder="Enter Confirm Password" onChange={(e) => {
                                                setResetData({
                                                    ...ResetData, confirmPassword: e.target.value
                                                })
                                            }} />
                                            {validator.current.message(
                                                'confirmPassword',
                                                ResetData.confirmPassword,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Confirm Password is Required',
                                                    }
                                                })}
                                        </div>

                                    </div>




                                    <div className="form-group text-center mt-4 mb-3">

                                        <div className="col-xs-12">

                                            <button type='button' className="

                                                        btn btn-primary

                                                        d-block

                                                        w-100

                                                        waves-effect waves-light

                                                        "
                                                onClick={submitReset}
                                            >

                                                Submit

                                            </button>

                                        </div>

                                    </div>
                                </form>

                            </div>

                        </div>

                    </div>

                </div>

                {/* </div> */}

                <footer className="footer bg-transparent text-white">2022© Aparajitha Software Services Private Limited</footer>

            </div>

        </Fragment>
    );
}


export default connect(mapStateToProps, {
    resetNewPassword,
    resetTokenValidation
})(Resetpassword);
