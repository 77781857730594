import React from 'react'
import { Collapse, Select, Input, DatePicker, Card, Popconfirm, Pagination, Popover, Spin, Drawer, Button, Table, Tooltip, Modal, Descriptions, Empty, Space } from 'antd';
import DataTable from "react-data-table-component";
import moment from 'moment';
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { ArrowRightOutlined, CheckOutlined, CloseOutlined, ArrowLeftOutlined, BookTwoTone, FilterOutlined, ExclamationCircleFilled, UpOutlined, DownOutlined, FullscreenOutlined, CloseCircleOutlined, SearchOutlined, FilterFilled, PlayCircleOutlined, InfoCircleFilled } from '@ant-design/icons';
import ScrollToTop from 'react-scroll-to-top';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation, Link,useHistory } from 'react-router-dom';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { getCommonData, getRiskchart, getHiddenRiskShowMore, getRiskChartTableData, getRiskChartDelayedTableData, getTimeZoneData, getRemarksHistoryData, getRiskExposedTrendShowMoreTableData } from '../../Store/Action/Dashboard/RiskChart';
import { toast } from 'react-toastify';
import url, { getUrl } from './../../Libs/URL'
import { HomeRedirect, CountrySplit } from './../../Libs/country'
import { getdownloadpath } from '../../Store/Action/Transcations/ComplianceApproval';
import ScrollButton from '../Scrollbar';
const homepage = HomeRedirect()
const uniq_country_data = CountrySplit()
const urls = getUrl()
// import "core-js/stable";
// import "regenerator-runtime/runtime";

const mapStateToProps = (state) => ({
    data: state.RiskChart.riskChart.chartData,
    time: state.RiskChart.riskChart.time,
    commonData: state.RiskChart.riskChart.Data,
    tableData: state.RiskChart.riskChart.tableData,
    tableData1: state.RiskChart.riskChart.delayedTableData,
    timeZoneData: state.RiskChart.riskChart.timeZoneData,
    remarksHistoryData: state.RiskChart.riskChart.remarksHistoryData,
    showMoreData: state.RiskChart.riskChart.showMoreData



})

const RiskChart = ({
    // getFilterValue,
    getRiskchart,
    getCommonData,
    data,
    commonData,
    getRiskChartTableData,
    getHiddenRiskShowMore,
    getRiskChartDelayedTableData,
    time,
    // getCriticalityTableData,
    tableData,
    tableData1,
    getTimeZoneData,
    timeZoneData,
    remarksHistoryData,
    getRemarksHistoryData,
    getRiskExposedTrendShowMoreTableData,
    showMoreData,
    getdownloadpath

}) => {
    const _ = require("lodash");
    const [open, setOpen] = useState(false)
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [chartId, setChartId] = useState()
    const [table, setTable] = useState(false)
    const chart = useRef(null);
    const { Option } = Select;
    const { Panel } = Collapse;
    const is_Fullscreen = useSelector((state) => state.Login.is_fullscreen);
    const dispatch = useDispatch();
    const [timeZone, setTimeZone] = useState("Afica/Accara")
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const sessionvalue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionvalue)
    const [entityData, setEntityData] = useState([])
    const [legalEntityArray, setLegalEntityArray] = useState([])
    const [showmoreCount, setShowMoreCount] = useState(0)
    const [dataCount, setDataCount] = useState()
    const [criticalityTable, setCriticalityTable] = useState([])
    const [delayedChart, setDelayedChart] = useState([])
    const [tempcriticalityTable, setTempcriticalityTable] = useState([])
    console.log(tempcriticalityTable, 'tempcriticalityTable');
    const [sortingpurpose, setSortingpurpose] = useState([])
    const [criticalityValue, setCriticalityValue] = useState()
    const [year, setYear] = useState(moment().year())
    const [prevYear, setPrevYear] = useState(moment().year() - 4)
    const searchInput = useRef(null);
    const [searchText, setSearchText] = useState({
        data: '',
        index: 0
    });
    const [searchedColumn, setSearchedColumn] = useState('');
    const [generatedOn, setGeneratedOn] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const TimeZoneValue = sessionParsedValue.login_response
    const sessionArr = sessionParsedValue.entity_info
    const location = useLocation();
    // const paramid = location.pathname;
    const [paramid, setParamId] = useState("")
    const [filterData, setFilterData] = useState({
        country: '',
        domain: '',
        domainIds: '',
        countryArr: [],
        legalentity: '',
        division: '',
        unit: '',
        business: '',
        category: '',
        unit: '',
        leIds: ''
    })
    const [field, setfield] = useState({
        country: [],
        domain: [],
        legalEntity: [],
        business: [],

    })
    const [filterDataNames, setFilterDataNames] = useState({
        country: '',
        domain: '',
        legalentity: '',
        division: '',
        unit: '',
        business: '',
        category: '',
        unit: ''
    })
    const [historymodal, sethistorymodal] = useState(false)
    const [remarksModalData, setRemarksModalData] = useState({
        le_id: '',
        c_id: '',
        d_id: '',
        u_id: '',
        start_date: '',
    })
    const [searchingValue, setSearchingValue] = useState([])
    const [searchedValue, setSearchedValue] = useState({
        bgGroup: '',
        legalEntity: '',
        division: '',
        compliance: '',
        criticality: '',
        assignedTo: '',
        overdue: ''
    })
    const myRef1 = useRef(null);
    const myRef2 = useRef(null);
    const myRef3 = useRef(null);
    const myRef4 = useRef(null);
    const myRef5 = useRef(null);
    const myRef6 = useRef(null);
    const myRef7 = useRef(null);
    var [count, setCount] = useState(1)
    const [go, setGo] = useState(false)
    const [countValue, setCountValue] = useState(0)
    const [division, setDivision] = useState([])
    const [category, setCategory] = useState([])
    const [unit, setUnit] = useState([])
    const [hiddenRiskData, setHiddenRiskData] = useState([])
    const [exposedRiskData, setExposedRiskData] = useState([])
    const [chartValue, setChartValue] = useState('')
    const [reviewerReportValue, setReviewerReportValue] = useState([])
    const [show, setShow] = useState(false)
    const [domainName, setDomainName] = useState()
    const [fieldType, setFieldType] = useState("")
    const { OptGroup } = Select;
    const [domainListData, setDomainListData] = useState([])
    const [divisionListData, setDivisionListData] = useState([])
    const [categoryListData, setCategoryListData] = useState([])
    const [unitListData, setUnitListData] = useState([])
    const [bgIds, setBgIds] = useState([])
    const [groupCheckBox, setGroupCheckBox] = useState(true)
    const [checkBox, setCheckBox] = useState(true)
    const [countryDomain, setCountryDomain] = useState([])
    const [columnState, setCoulmnState] = useState(false)
    const [dIds, setDIds] = useState([])
    const [cIds, setCIds] = useState([])
    const country_info = sessionParsedValue.country_info
    const [type, setType] = useState('')
    const [errorField, setErrorField] = useState('')
    const [fieldTypeClick, setFieldTypeClick] = useState("")
    const [textArray, setTextArray] = useState([])
    const [noDataState, setNoDataState] = useState(false)
    const [newCountryDomain, setNewCountryDomain] = useState([])
    const [countryData, setCountryData] = useState([])
    const [domainData, setDomainData] = useState([])
    const [filteredCountries, setFilteredCountries] = useState([])
    const sidefilterData = localStorage.getItem('filterChange')
    const parsedSideFilterData = JSON.parse(sidefilterData)
    const sideFilterState = localStorage.getItem('filterState')
    const isCountryValueTrue = localStorage.getItem('countryState')
    console.log(noDataState, 'noDataState');
    const [customTaskDisTable, setCustomTaskDis] = useState([])
    const [searchedValue1, setSearchedValue1] = useState({
        statutory: '',
        criticality: '',
        compliance_task: '',
        descp: '',
        p_cons: '',
        comp_frequency: '',
        repeats: '',
        trigger: ''
    })
    const myRef11 = useRef(null);
    const myRef12 = useRef(null);
    const myRef13 = useRef(null);
    const myRef14 = useRef(null);
    const myRef15 = useRef(null);
    const myRef16 = useRef(null);
    const myRef17 = useRef(null);
    const myRef18 = useRef(null);
    const [sortingpurpose1, setSortingpurpose1] = useState([])
    const [searchingValue1, setSearchingValue1] = useState([])
    const historynew = useHistory()

    const historycancel = () => {
        sethistorymodal(false);
    }

    useEffect(() => {
        if (commonData && commonData.d_info && commonData.d_info.length > 0) {
            let tempArr = []
            let tempIds = []
            let textArr = []
            let cNames = []
            let uniqueCountry = []
            let countryName;
            // setDomainData(commonData.d_info)
            for (let i in commonData.d_info) {
                tempIds.push(commonData.d_info[i].d_id)
                tempArr.push({
                    'd_id': commonData.d_info[i].d_id,
                    'c_id': commonData.d_info[i].c_id,
                    "month_from": commonData.d_info[i].month_from,
                    "month_to": commonData.d_info[i].month_to
                })
                uniqueCountry = _.uniqBy(commonData && commonData.countries, function (e) {
                    return e.c_id;
                });
                setCountryData(uniqueCountry)
                let id = uniqueCountry && uniqueCountry.length > 0 && uniqueCountry.map((item, i) => {
                    return item.c_id
                })
                // setCIds(id)
                console.log(uniqueCountry, 'uniqueCountry');
                countryName = _.find(uniqueCountry, { c_id: Number(commonData.d_info[i].c_id) })

                cNames.push(commonData.d_info[i].d_name)

            }
            // var arr = []
            var obj = {}
            uniqueCountry.forEach(function (a) {
                var arr2 = []
                commonData.d_info.forEach(function (b) {
                    var key = a.c_name
                    if (a.c_id == b.c_id) {
                        arr2.push(b.d_name)
                        obj[key] = arr2.join()
                    }
                });
            });
            let temp = []
            for (let m in obj) {
                console.log(obj[m], 'objmm');
                // let sm = `${m} - ${obj[m]}`
                let expectedCountryDomain = `[bold]${m}[/] - ${obj[m]}`
                temp.push(expectedCountryDomain)
            }
            let newCountryDomain = temp.join("[bold] | [/]")
            // setTextArray(newCountryDomain)
            setDIds(tempIds)
            setCountryDomain(tempArr)
        }


    }, [commonData])
    const setFocus = (ref) => {
        setTimeout(() => {
            ref.current.focus();
        });
    };
    const compliancetaskfilter = (e, type, searchedArray) => {
        let filterTable = sortingpurpose

        if (searchingValue['business_group']) {
            filterTable = filterTable.filter((item) => {
                if ((item["bg_name"].toLowerCase().includes(searchingValue['business_group'].toLowerCase())) || (item["bg_name"].toLowerCase().includes(searchingValue['business_group'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['legal_entity']) {
            filterTable = filterTable.filter((item) => {
                if ((item["le_name"].toLowerCase().includes(searchingValue['legal_entity'].toLowerCase())) || (item["le_name"].toLowerCase().includes(searchingValue['legal_entity'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['division']) {
            filterTable = filterTable.filter((item) => {
                if ((item["div_name"].toLowerCase().includes(searchingValue['division'].toLowerCase())) || (item["div_name"].toLowerCase().includes(searchingValue['division'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['compliance']) {
            filterTable = filterTable.filter((item) => {
                if ((item["comp_name"].toLowerCase().includes(searchingValue['compliance'].toLowerCase())) || (item["comp_name"].toLowerCase().includes(searchingValue['compliance'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['criticality']) {
            filterTable = filterTable.filter((item) => {
                if ((item["criticality"].toLowerCase().includes(searchingValue['criticality'].toLowerCase())) || (item["criticality"].toLowerCase().includes(searchingValue['criticality'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['assignedTo']) {
            filterTable = filterTable.filter((item) => {
                if ((item["assignee_name"].toLowerCase().includes(searchingValue['assignedTo'].toLowerCase())) || (item["assignee_name"].toLowerCase().includes(searchingValue['assignedTo'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue['overdue']) {
            filterTable = filterTable.filter((item) => {
                if ((item["ageing"].toLowerCase().includes(searchingValue['overdue'].toLowerCase())) || (item["ageing"].toLowerCase().includes(searchingValue['overdue'].toLowerCase()))) {
                    return true;
                }
            })
        }

        let arr = [];
        let filteredtemp = []
        let fiteredunitname = []

        if (filterTable && filterTable.length > 0) {
            filterTable &&
                filterTable.length > 0 &&
                filterTable.map(function (user) {
                    if (arr.indexOf(user.u_name) < 0)
                        arr.push({ u_name: user.u_name });
                });
            let uniqle = _.uniqBy(arr, function (e) {
                return e.u_name;
            });
            for (let k in uniqle) {
                let filteredObject = _.filter(filterTable, { u_name: uniqle[k].u_name })
                filteredtemp.push(filteredObject)
            }
            for (let k in filteredtemp) {
                let uniqle1 = _.uniqBy(filteredtemp[k], function (e) {
                    return e.unitname;
                });
                let uniq_unitname = uniqle1.map((item) => { return item.unitname })
                fiteredunitname.push(uniq_unitname)
            }
            let FinalfilteredObject = []
            for (let k in fiteredunitname) {
                let temp = []
                for (let j in fiteredunitname[k]) {
                    let filteredObject = _.filter(filteredtemp[k], { unitname: fiteredunitname[k][j] })
                    temp.push(filteredObject)
                }
                FinalfilteredObject.push(temp)
            }
            setTempcriticalityTable(FinalfilteredObject)
            console.log(FinalfilteredObject, 'FinalfilteredObject');
        } else {
            setTempcriticalityTable([])
        }
    };
    useEffect(() => {
        let newDom = []
        for (let i in commonData.d_info) {
            newDom.push({
                "d_id": commonData.d_info[i].d_id,
                "d_name": commonData.d_info[i].d_name,
                "is_active": commonData.d_info[i].is_active,
                "c_id": commonData.d_info[i].c_id,
                "month_from": commonData.d_info[i].month_from,
                "month_to": commonData.d_info[i].month_to,
                "cd_id": `${commonData.d_info[i].c_id}-${commonData.d_info[i].d_id}`
            })
        }

        let newDomain = []
        if (newDom && newDom.length > 0) {
            for (let k in filterData.domain) {
                console.log(filterData.domain[k], 'sssssss');
                let filterDomain = _.filter(newDom, { cd_id: `${filterData.domain[k]}` })
                console.log(filterDomain, 'filterDomain');
                // newDomain.push(filterDomain)
                for (let l in filterDomain) {
                    newDomain.push(filterDomain[l])
                }

            }
        }

        let newDomain1 = []
        if (newDomain && newDomain.length > 0) {
            for (let k in filterData.domain) {
                let filterDomain = _.filter(newDomain, { cd_id: `${filterData.domain[k]}` })
                // newDomain.push(filterDomain)
                for (let l in filterDomain) {
                    newDomain1.push(filterDomain[l])
                }

            }
            console.log(newDomain1, 'newDomain1');
        }
        setDomainData(newDomain)

        console.log(newDomain, 'newDomain');
        console.log(newDom, 'newDom');
    }, [commonData.d_info, filterData.domain])

    useEffect(() => {
        var obj = {}
        countryData.forEach(function (a) {
            var arr2 = []
            domainData.forEach(function (b) {
                var key = a.c_name
                if (a.c_id == b.c_id) {
                    arr2.push(b.d_name)
                    obj[key] = arr2.join()
                }
            });
        });
        let temp = []
        for (let m in obj) {
            console.log(obj[m], 'objmm');
            // let sm = `${m} - ${obj[m]}`
            let expectedCountryDomain = `[bold]${m}[/] - ${obj[m]}`
            temp.push(expectedCountryDomain)
        }
        let newCountryDomain = temp.join("[bold] | [/]")
        setTextArray(newCountryDomain)

    }, [countryData, domainData])
    useEffect(() => {
        if (delayedChart && delayedChart.length > 0) {
            let count1 = []
            for (let i in delayedChart) {
                for (let j in delayedChart[i].drill_compliances) {
                    let a = delayedChart[i].drill_compliances[j]
                    let data = Object.values(a)
                    count1.push(data.length)
                    // count1.push(data[i])
                    console.log(data, ' taskDistributionTable[i].ap_compliances');
                }
            }
            console.log(count1, 'count1');
            let countValues = count1.reduce(function (accumulator, curValue) {
                return accumulator + curValue
            }, 0)
            setShowMoreCount(countValues)
            console.log(countValues, 'countValue9999');

            // search option customize
            let temp = []
            let temp1 = []
            {
                delayedChart && delayedChart.length > 0 &&
                    delayedChart.map((items) => {
                        let childDatas = []
                        let unitName = items.drill_compliances
                        console.log(unitName, 'unitName');
                        let keys = Object.keys(unitName);
                        let values = Object.values(unitName);
                        console.log(keys, 'keys');
                        console.log(values, 'values');
                        {
                            keys && keys.length > 0 && keys.map((item, i) => {
                                console.log(item, 'itemitemitemitemitemitemitem');
                                let tempArr = []
                                if (values && values.length > 0) {
                                    for (let m in values[i]) {
                                        console.log(m, 'inndexxx');
                                        console.log(values[i][m].comp_name, 'childDatas');
                                        tempArr.push({
                                            'u_name': items.u_name,
                                            'bg_name': items.bg_name,
                                            'le_name': items.le_name,
                                            'legal_entity_id': items.legal_entity_id,
                                            'div_name': items.div_name,
                                            'comp_name': values && values[i][m].comp_name,
                                            'criticality': values && values[i][m].criticality,
                                            'assignee_name': values && values[i][m].assignee_name,
                                            'descp': values && values[i][m].descp,
                                            'compfielaw_url': values && values[i][m].compfielaw_url,
                                            'ageing': values && values[i][m].ageing,
                                            'unitname': item,
                                            'index': count,
                                            'le_id': items.legal_entity_id,
                                            'c_id': items.country_id,
                                            'd_id': items.domain_id,
                                            'u_id': items.unit_id,
                                            'start_date': items.start_date,
                                            'remarks_count': values && values[i][m].remarks_count,
                                            'compliance_history_id': values && values[i][m].compliance_history_id
                                        })
                                        count = count + 1
                                        console.log(count, 'countcountcountcount');
                                    }
                                    // temp1.concat(tempArr)
                                    Array.prototype.push.apply(temp1, tempArr)
                                    childDatas.push(tempArr)
                                }
                                console.log(childDatas, 'tempArr5557');
                                console.log(tempArr, 'tempArr5556666');
                                // temp.push([childDatas[i]])
                                // temp.push(childDatas[i])
                                // log
                            })
                            temp.push(childDatas)
                            console.log(temp, 'llllllllll');
                        }
                        setTempcriticalityTable(temp)
                    })
            }
            setSortingpurpose(temp1)
            console.log(temp1, 'temptemp');

            // criticalityTable.drill_compliances
        }

    }, [delayedChart])
    useEffect(() => {
        let newCountry = []
        let newDomain = []

        for (let j in filterData.country) {
            let filterCountry = _.filter(commonData.countries, { c_id: Number(filterData.country[j]) })
            // temp2.push(filterDomain)
            for (let i in filterCountry) {
                newCountry.push(filterCountry[i])
            }

        }
        // for (let k in filterData.domain) {
        //     let filterDomain = _.filter(commonData.d_info, { d_id: Number(filterData.domain[k]) })
        //     // temp2.push(filterDomain)
        //     for (let l in filterDomain) {
        //         newDomain.push(filterDomain[l])
        //     }

        // }
        const uniqueCountry = _.uniqBy(newCountry && newCountry, function (e) {
            return e.c_id;
        });
        setCountryData(uniqueCountry)
        // setDomainData(newDomain)
        console.log(newCountry, 'newCountry');
        console.log(newDomain, 'newDomain');
        console.log(filterData.country, 'filterData.country');
        console.log(filterData.domain, 'filterData.domain');

    }, [filterData.country, filterData.domain])



    console.log(noDataState, 'noDataState');

    useEffect(() => {
        if (fieldTypeClick === 'BusinessGroup') {
            if (filterDataNames.business === '') {
                setErrorField('Business Group Required')
            }
        }

        if (fieldTypeClick === 'LegalEntity') {
            if (filterDataNames.legalentity === '') {
                setErrorField('Legal Entity Required')
            }
        }
        if (fieldTypeClick === 'Division') {
            if (filterDataNames.division === '') {
                setErrorField('Division Required')
            }
        }
        if (fieldTypeClick === 'Category') {
            if (filterDataNames.category === '') {
                setErrorField('Category Required')
            }
        }
        if (fieldTypeClick === 'Unit') {
            if (filterDataNames.unit === '') {
                setErrorField('Unit Required')
            }
        }

    }, [fieldTypeClick])

    useEffect(() => {
        if (fieldTypeClick === 'Country') {
            if (filterData.country.length === 0) {
                setErrorField('Country Required')
            }
            else {
                setErrorField('')

            }
        }
        if (fieldTypeClick === 'Domain') {
            if (filterData.domain.length === 0) {
                setErrorField('Domain Required')
            }
            else {
                setErrorField('')

            }
        }

    }, [fieldTypeClick, filterData])

    console.log(showMoreData.r_drill_down_data, 'showMoreData');
    console.log(criticalityTable, 'criticalityTable');
    useEffect(() => {
        {
            var the_arr = location.pathname.split('/');
            the_arr.pop();
            let a = the_arr.join('/')
            setParamId(a)
        }

    }, []);

    useEffect(() => {
        if (data) {
            setHiddenRiskData(data.risk_chart_data_nu)
            setExposedRiskData(data.risk_chart_data_dn)
        }
    }, [data])

    useEffect(() => {
        if (((hiddenRiskData && hiddenRiskData.length > 0) && (exposedRiskData && exposedRiskData.length > 0))) {
            if (exposedRiskData[0].delayed_compliance_count === 0 && exposedRiskData[0].not_complied_count === 0 && hiddenRiskData[0].unassign_count === 0 &&
                hiddenRiskData[0].not_opted_count === 0 && hiddenRiskData[0].not_applicable_count === 0) {
                setNoDataState(true)
            }
            else {
                setNoDataState(false)
            }
        }

    }, [hiddenRiskData, exposedRiskData, year])

    console.log(data, 'data12345');

    useEffect(() => {
        if (commonData && commonData.d_info && commonData.d_info.length > 0) {
            let domainNameArray = commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info.map((item, i) => {
                return item.d_name
            })
            setDomainName(domainNameArray)
            console.log(domainName, 'domainName');

        }

    }, [commonData])

    useEffect(() => {
        if (JSON.parse(sideFilterState) === false && JSON.parse(isCountryValueTrue) === false) {
            if ((entityData && entityData.length > 0) && (commonData) && (dIds && dIds.length > 0) && (countryDomain && countryDomain.length > 0)) {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetComplianceApplicabilityStatusChart",
                            {
                                "c_ids": filterData.countryArr,
                                "d_ids": dIds,
                                "filter_type": "Group",
                                "filter_ids": [
                                    1
                                ],
                                "le_ids": localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? entityData : [entityid],
                                "country_domains": countryDomain,
                                "chart_year": year
                            }
                        ]
                    }
                ]

                if (paramid != "") {
                    getRiskchart({
                        payload: payload,
                        paramid: paramid
                    })
                }

            }
        }

    }, [entityid, entityData, countryDomain, year, dIds, commonData, localStorage.getItem('filterState'), localStorage.getItem('countryState')])






    useEffect(() => {
        if (chartValue == 'hiddenRisk') {
            if (criticalityTable && criticalityTable.length > 0) {
                let count = []
                for (let i in criticalityTable) {
                    for (let j in criticalityTable[i].ap_compliances) {
                        let a = criticalityTable[i].ap_compliances[j]
                        let data = Object.values(a)


                        count.push(data.length)

                        // count.push(data[i])
                        console.log(data, ' taskDistributionTable[i].ap_compliances');

                    }
                }
                console.log(count, 'count');
                let countValues = count.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                setShowMoreCount(countValues)
                console.log(countValues, 'countValue9999');
            }
        }
        else {
            if (delayedChart && delayedChart.length > 0) {
                let count = []
                for (let i in delayedChart) {
                    for (let j in delayedChart[i].drill_compliances) {
                        let a = delayedChart[i].drill_compliances[j]
                        let data = Object.values(a)


                        count.push(data.length)

                        // count.push(data[i])
                        console.log(data, ' taskDistributionTable[i].ap_compliances');

                    }
                }
                console.log(count, 'count');
                let countValues = count.reduce(function (accumulator, curValue) {
                    return accumulator + curValue
                }, 0)
                setShowMoreCount(countValues)
                console.log(countValues, 'countValue9999');
            }
        }

    }, [criticalityTable, delayedChart])



    useEffect(() => {
        if (chartValue == 'hiddenRisk') {
            // if (showMoreData && showMoreData.r_drill_down_data && showMoreData.r_drill_down_data.length > 0) {
            //     let temp = []
            //     let temp2 = []
            //     console.log(showMoreData.r_drill_down_data, 'showMoreData.r_drill_down_data');
            //     for (let i in criticalityTable) {
            //         for (let j in showMoreData.r_drill_down_data) {

            //             // console.log(taskDistributionTable[i].level1_statutory_name == showMoreData.r_drill_down_data[j].level1_statutory_name, 'taskDistributionTable[i].level1_statutory_name == showMoreData.r_drill_down_data[j].level1_statutory_name');
            //             if (criticalityTable[i].level1_statutory_name == showMoreData.r_drill_down_data[j].level1_statutory_name) {
            //                 let obj = criticalityTable[i].ap_compliances
            //                 let objdata = showMoreData.r_drill_down_data[j].ap_compliances
            //                 let newArray = { ...obj, ...objdata }
            //                 // var newArray = Object.assign({}, taskDistributionTable[i].ap_compliances, showMoreData.r_drill_down_data[j].ap_compliances);
            //                 criticalityTable[i].ap_compliances = newArray
            //                 temp2.push(criticalityTable[i].level1_statutory_name)
            //                 // setTaskDistributionTable(showMoreData.r_drill_down_data)
            //             }

            //         }
            //     }
            //     console.log(temp2, 'temp2');
            //     for (let i in showMoreData.r_drill_down_data) {

            //         if ((temp2.includes(showMoreData.r_drill_down_data[i].level1_statutory_name)) == false) {
            //             // let data = _.filter(showMoreData.r_drill_down_data, { level1_statutory_name: taskDistributionTable[j].level1_statutory_name })
            //             console.log(showMoreData.r_drill_down_data[i], 'datadata');
            //             temp.push(showMoreData.r_drill_down_data[i])
            //         }



            //     }

            //     setCriticalityTable([...criticalityTable, ...temp])



            // }

            if (showMoreData && showMoreData.r_drill_down_data && showMoreData.r_drill_down_data.length > 0) {
                let temp = []
                let temp2 = []
                console.log(showMoreData.r_drill_down_data, 'showMoreData.r_drill_down_data');
                for (let i in criticalityTable) {
                    for (let j in showMoreData.r_drill_down_data) {
                        if (criticalityTable[i].level1_statutory_name == showMoreData.r_drill_down_data[j].level1_statutory_name) {
                            for (let s in criticalityTable[i].ap_compliances) {
                                for (let a in showMoreData.r_drill_down_data[j].ap_compliances) {
                                    console.log(s, 'taskDistributionTable[i].ap_compliances[s]');
                                    console.log(showMoreData.r_drill_down_data[j].ap_compliances[a], 'showMoreData.r_drill_down_data[j].ap_compliances[a]');
                                    if (s == a) {
                                        let arr1 = criticalityTable[i].ap_compliances[s]
                                        let arr2 = showMoreData.r_drill_down_data[j].ap_compliances[a]
                                        let arr3 = arr1.concat(arr2)
                                        console.log(arr3, 'arr3');
                                        criticalityTable[i].ap_compliances[s] = arr3
                                        console.log(s, 'sneha');
                                    }
                                    else {
                                        criticalityTable[i].ap_compliances[a] = showMoreData.r_drill_down_data[j].ap_compliances[a]
                                    }
                                }
                            }
                            temp2.push(criticalityTable[i].level1_statutory_name)
                        }
                    }
                }
                console.log(temp2, 'temp2');
                for (let i in showMoreData.r_drill_down_data) {

                    if ((temp2.includes(showMoreData.r_drill_down_data[i].level1_statutory_name)) == false) {
                        // let data = _.filter(showMoreData.r_drill_down_data, { level1_statutory_name: taskDistributionTable[j].level1_statutory_name })
                        console.log(showMoreData.r_drill_down_data[i], 'datadata');
                        temp.push(showMoreData.r_drill_down_data[i])
                    }
                }

                setCriticalityTable([...criticalityTable, ...temp2])
            }
        }
    }, [showMoreData])
    // useEffect(() => {
    //     if (chartValue == 'exposedRisk') {
    //         let temp = []
    //         let temp2 = []
    //         // if (showMoreData && showMoreData.drill_down_data && showMoreData.drill_down_data.length > 0) {
    //         //     for (let i in delayedChart) {
    //         //         for (let j in showMoreData.drill_down_data) {
    //         //             // if (delayedChart[i].u_name == showMoreData.drill_down_data[j].u_name) {
    //         //             //     var newArray = Object.assign({}, delayedChart[i].drill_compliances, showMoreData.drill_down_data[j].drill_compliances);
    //         //             //     console.log(newArray, 'newArray');
    //         //             //     delayedChart[i].drill_compliances = newArray
    //         //             // }
    //         //             if (delayedChart[i].u_name == showMoreData.drill_down_data[j].u_name) {
    //         //                 let array = delayedChart[i].drill_compliances
    //         //                 var newArray = Object.assign({}, delayedChart[i].drill_compliances, showMoreData.drill_down_data[j].drill_compliances);
    //         //                 delayedChart[i].drill_compliances = newArray
    //         //                 temp2.push(delayedChart[i].u_name)
    //         //             }
    //         //             for (let i in showMoreData.drill_down_data) {

    //         //                 if ((temp2.includes(showMoreData.drill_down_data[i].u_name)) == false) {
    //         //                     // let data = _.filter(showMoreData.r_drill_down_data, { level1_statutory_name: taskDistributionTable[j].level1_statutory_name })
    //         //                     console.log(showMoreData.drill_down_data[i], 'datadata');
    //         //                     temp.push(showMoreData.drill_down_data[i])
    //         //                 }



    //         //             }

    //         //             setDelayedChart([...delayedChart, ...temp])

    //         //             // else if (delayedChart[i].u_name != showMoreData.drill_down_data[j].u_name) {
    //         //             //     let temp = []
    //         //             //     temp.push(delayedChart[i], showMoreData.drill_down_data[j])
    //         //             // }
    //         //         }
    //         //     }
    //         //     // setDelayedChart(delayedChart)
    //         // }
    //         if (showMoreData && showMoreData.drill_down_data && showMoreData.drill_down_data.length > 0) {
    //             let temp = []
    //             let temp2 = []
    //             for (let i in delayedChart) {
    //                 for (let j in showMoreData.drill_down_data) {
    //                     if (delayedChart[i].u_name == showMoreData.drill_down_data[j].u_name) {
    //                         for (let s in delayedChart[i].drill_compliances) {
    //                             for (let a in showMoreData.drill_down_data[j].drill_compliances) {
    //                                 console.log(s, 'taskDistributionTable[i].ap_compliances[s]');
    //                                 // console.log(showMoreData.r_drill_down_data[j].ap_compliances[a], 'showMoreData.r_drill_down_data[j].ap_compliances[a]');
    //                                 if (s == a) {
    //                                     let arr1 = delayedChart[i].drill_compliances[s]
    //                                     let arr2 = showMoreData.drill_down_data[j].drill_compliances[a]
    //                                     let arr3 = arr1.concat(arr2)
    //                                     console.log(arr3, 'arr3');
    //                                     delayedChart[i].drill_compliances[s] = arr3
    //                                     console.log(s, 'sneha');
    //                                 }
    //                                 else {
    //                                     delayedChart[i].drill_compliances[a] = showMoreData.drill_down_data[j].drill_compliances[a]
    //                                 }
    //                             }
    //                         }
    //                         // let newArray = { ...obj, ...objdata }

    //                         // var newArray = Object.assign({}, taskDistributionTable[i].ap_compliances, showMoreData.r_drill_down_data[j].ap_compliances);
    //                         // taskDistributionTable[i].ap_compliances = newArray
    //                         temp2.push(delayedChart[i].u_name)
    //                         // setTaskDistributionTable(showMoreData.r_drill_down_data)
    //                     }

    //                 }
    //             }
    //             console.log(temp2, 'temp2');
    //             for (let i in showMoreData.drill_down_data) {

    //                 if ((temp2.includes(showMoreData.drill_down_data[i].u_name)) == false) {
    //                     // let data = _.filter(showMoreData.r_drill_down_data, { level1_statutory_name: taskDistributionTable[j].level1_statutory_name })
    //                     console.log(showMoreData.drill_down_data[i], 'datadata');
    //                     temp.push(showMoreData.drill_down_data[i])
    //                 }



    //             }
    //             setDelayedChart([...delayedChart, ...temp])



    //         }

    //     }

    // }, [showMoreData, show, year])

    console.log(showMoreData.drill_down_data, 'showMoreData.drill_down_data');

    useEffect(() => {
        if (chartValue == 'exposedRisk' && show === true) {
            if (showMoreData && showMoreData.drill_down_data && showMoreData.drill_down_data.length > 0) {
                if (delayedChart && delayedChart.length > 0) {
                    let newTable = delayedChart
                    let mergedArray = []
                    let tableDataLastObj = newTable[newTable.length - 1]
                    let showMoreDataFirstObj = showMoreData.drill_down_data[0]
                    console.log(tableDataLastObj, 'tableDataLastObj');
                    console.log(showMoreDataFirstObj, 'showMoreDataFirstObj');
                    // if (`${tableDataLastObj && tableDataLastObj.le_name}-${tableDataLastObj && tableDataLastObj.le_name}` === `${showMoreDataFirstObj && showMoreDataFirstObj.le_name}-${showMoreDataFirstObj && showMoreDataFirstObj.le_name}`) {
                    //     let showMoreNew = _.filter(showMoreData.n_drill_down_data, { le_name: tableDataLastObj.le_name })
                    //     let mergedArray = newTable.concat(showMoreNew)
                    //     setCriticalityTable(mergedArray)
                    // }
                    // else {
                    //     let rejectedData = _.reject(showMoreData.n_drill_down_data, { le_name: tableDataLastObj.le_name })
                    //     let mergedArray = newTable.concat(rejectedData)
                    //     setCriticalityTable(mergedArray)
                    // }
                    if (tableDataLastObj && showMoreDataFirstObj) {
                        let previousLEUnit = tableDataLastObj.le_name + '-' + tableDataLastObj.u_name
                        let currentLEUnit = showMoreDataFirstObj.le_name + '-' + showMoreDataFirstObj.u_name
                        if (previousLEUnit == currentLEUnit) {
                            // let showMoreNew = _.filter(showMoreData.not_complied, { le_name: tableDataLastObj.le_name, u_name: tableDataLastObj.u_name })                   
                            let existDrill = tableDataLastObj.drill_compliances
                            let newDrill = showMoreDataFirstObj.drill_compliances
                            let exisObjKeys = _.keys(existDrill)
                            let newKeys = _.keys(newDrill)
                            let matchedArrayFilter = _.filter(showMoreData.drill_down_data, { u_name: tableDataLastObj.le_name })
                            let unMatchedArrayFilter = _.reject(showMoreData.drill_down_data, { u_name: tableDataLastObj.u_name })

                            if (exisObjKeys[exisObjKeys.length - 1] == newKeys[0]) {
                                let temp = {
                                    [exisObjKeys[exisObjKeys.length - 1]]: existDrill[exisObjKeys[exisObjKeys.length - 1]].concat(newDrill[exisObjKeys[exisObjKeys.length - 1]])
                                }
                                delete newDrill[exisObjKeys[exisObjKeys.length - 1]]
                                existDrill = Object.assign(existDrill, temp)
                                console.log(existDrill, newDrill, 'vibinnnn');
                            } else {
                                console.log(existDrill, newDrill, 'vibinnnnelse');
                            }
                            let newAssign = Object.assign(existDrill, newDrill);
                            console.log(newAssign, 'newAssign');
                            delayedChart[delayedChart.length - 1].drill_compliances = newAssign
                            let merge = delayedChart.concat(unMatchedArrayFilter)
                            console.log(merge, 'merge');
                            setDelayedChart(merge)

                        } else {
                            console.log('mismatchhh');
                            let rejectedData = _.reject(showMoreData.drill_down_data, { le_name: tableDataLastObj.le_name })
                            let mergedArray = newTable.concat(rejectedData)
                            setDelayedChart(mergedArray)
                        }
                    }
                }
            }
        }

    }, [showMoreData, show])


    console.log(criticalityTable, 'criticalityTable');

    console.log(showMoreData, 'showMoreData');
    console.log(delayedChart, 'delayedChart');

    console.log(sessionArr, 'commonDatacommonData');
    useEffect(() => {
        // if (localStorage.getItem('SelectedEntity') == "All Legal Entity") {
        let id = sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {

            return item.le_id
        })
        setEntityData(id)

        // }
    }, [])

    useEffect(() => {
        if (criticalityTable && criticalityTable.length > 0) {
            let temp = []
            let temp1 = []
            {
                criticalityTable && criticalityTable.length > 0 &&
                    criticalityTable.map((item, i) => {
                        console.log(item, 'item');
                        let childDatas = []
                        let unitName = item.ap_compliances
                        let keys = Object.keys(unitName);
                        let values = Object.values(unitName);
                        if (keys && keys.length > 0) {
                            {
                                keys && keys.map((items, j) => {
                                    console.log(items, 'itemsTas')
                                    let tempArr = []
                                    if (values && values.length > 0) {
                                        for (let m in values[j]) {
                                            tempArr.push({
                                                'index': count,
                                                'ageing': values[j][m].ageing,
                                                'assignee_name': values[j][m].assignee_name,
                                                'bg_name': values[j][m].bg_name,
                                                'comp_name': values[j][m].comp_name,
                                                'comp_id': values[j][m].comp_id,
                                                'frequency': values[j][m].frequency,
                                                'compfielaw_url': values[j][m].compfielaw_url,
                                                'criticality': values[j][m].criticality,
                                                'descp': values[j][m].descp,
                                                'is_active': values[j][m].is_active,
                                                'div_name': values[j][m].div_name,
                                                'p_cons': values[j][m].p_cons,
                                                'remarks': values[j][m].remarks,
                                                's_prov': values[j][m].s_prov,
                                                'statu_dates': values[j][m].statu_dates,
                                                'trigger_before_days': values[j][m].statu_dates && values[j][m].statu_dates.length > 0 && `${values[j][m].statu_dates[0].trigger_before_days} Days`,
                                                'summary': values[j][m].summary,
                                                // 'index': values[j][m].,
                                                'le_name': values[j][m].le_name,
                                                'download_url_list': values[j][m].download_url_list,
                                                'level1_statutory_name': item.level1_statutory_name,
                                                'leUname': items
                                            })
                                            { <span hidden>{count = count + 1}</span> }
                                            // childDatas[i] = []
                                        }
                                        Array.prototype.push.apply(temp1, tempArr)
                                        childDatas.push(tempArr)
                                        console.log(tempArr, 'tempArr');
                                        // newCustomisedValueArray = tempArr
                                        // setNewCustomisedValueArray([...newCustomisedValueArray,...tempArr])
                                    }
                                    // values[j] = tempArr

                                })
                                temp.push(childDatas)

                            }
                            setCustomTaskDis(temp)
                        }
                    })
            }
            setSortingpurpose1(temp1)

        }

    }, [criticalityTable])

    //Search

    const compliancetaskfilter1 = (e, type, searchedArray) => {
        let filterTable = sortingpurpose1

        if (searchingValue1['statutory']) {
            filterTable = filterTable.filter((item) => {
                if ((item["s_prov"].toLowerCase().includes(searchingValue1['statutory'].toLowerCase())) || (item["s_prov"].toLowerCase().includes(searchingValue1['statutory'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue1['compliance_task']) {
            filterTable = filterTable.filter((item) => {
                if ((item["comp_name"].toLowerCase().includes(searchingValue1['compliance_task'].toLowerCase())) || (item["comp_name"].toLowerCase().includes(searchingValue1['compliance_task'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue1['criticality']) {
            filterTable = filterTable.filter((item) => {
                if ((item["criticality"].toLowerCase().includes(searchingValue1['criticality'].toLowerCase())) || (item["criticality"].toLowerCase().includes(searchingValue1['criticality'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue1['descp']) {
            filterTable = filterTable.filter((item) => {
                if ((item["descp"].toLowerCase().includes(searchingValue1['descp'].toLowerCase())) || (item["comp_name"].toLowerCase().includes(searchingValue1['descp'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue1['p_cons']) {
            filterTable = filterTable.filter((item) => {
                if ((item["p_cons"].toLowerCase().includes(searchingValue1['p_cons'].toLowerCase())) || (item["p_cons"].toLowerCase().includes(searchingValue1['p_cons'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue1['comp_frequency']) {
            filterTable = filterTable.filter((item) => {
                if ((item["frequency"].toLowerCase().includes(searchingValue1['comp_frequency'].toLowerCase())) || (item["frequency"].toLowerCase().includes(searchingValue1['comp_frequency'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue1['summary']) {
            filterTable = filterTable.filter((item) => {
                if ((item["summary"].toLowerCase().includes(searchingValue1['repeats'].toLowerCase())) || (item["summary"].toLowerCase().includes(searchingValue1['repeats'].toLowerCase()))) {
                    return true;
                }
            })
        }
        if (searchingValue1['trigger']) {
            filterTable = filterTable.filter((item) => {
                if ((item["trigger_before_days"].toString().toLowerCase().includes(searchingValue1['trigger'].toLowerCase())) || (item["trigger_before_days"].toString().toLowerCase().includes(searchingValue1['trigger'].toLowerCase()))) {
                    return true;
                }
            })
        }

        let arr = [];
        let filteredtemp = []
        let fiteredunitname = []

        if (filterTable && filterTable.length > 0) {
            filterTable &&
                filterTable.length > 0 &&
                filterTable.map(function (user) {
                    if (arr.indexOf(user.level1_statutory_name) < 0)
                        arr.push({ level1_statutory_name: user.level1_statutory_name });
                });
            let uniqle = _.uniqBy(arr, function (e) {
                return e.level1_statutory_name;
            });
            for (let k in uniqle) {
                let filteredObject = _.filter(filterTable, { level1_statutory_name: uniqle[k].level1_statutory_name })
                filteredtemp.push(filteredObject)
            }
            for (let k in filteredtemp) {
                let uniqle1 = _.uniqBy(filteredtemp[k], function (e) {
                    return e.level1_statutory_name;
                });
                let uniq_unitname = uniqle1.map((item) => { return item.level1_statutory_name })
                fiteredunitname.push(uniq_unitname)
            }
            let FinalfilteredObject = []
            for (let k in fiteredunitname) {
                let temp = []
                for (let j in fiteredunitname[k]) {
                    let filteredObject = _.filter(filteredtemp[k], { level1_statutory_name: fiteredunitname[k][j] })
                    temp.push(filteredObject)
                }
                FinalfilteredObject.push(temp)
            }
            setCustomTaskDis(FinalfilteredObject)
            console.log(FinalfilteredObject, 'FinalfilteredObject');
        } else {
            setCustomTaskDis([])
        }
    };

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetChartFilters",
                        {
                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                        }
                    ]
                }
            ]
            if (paramid != "") {
                getCommonData({
                    payload: payload,
                    paramid: paramid
                })
            }


        }
    }, [isAuth, authtoken, paramid, entityid])

    // useEffect(() => {
    //     am4core.useTheme(am4themes_animated);
    //     // Themes end

    //     // Create chart instance
    //     var chart = am4core.create("chartdiv", am4charts.PieChart3D);
    //     // chart.legend = new am4charts.Legend();

    //     let total = (hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_applicable_count) + (hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_opted_count) + (hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].unassign_count)

    //     // Add data
    //     chart.data = [
    //         {
    //             "country": "Not Applicable",
    //             "Count": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_applicable_count,
    //             "color": am4core.color("#808080")
    //         },
    //         {
    //             "country": "Not Opted",
    //             "Count": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_opted_count,
    //             "color": am4core.color("#dd070c")
    //         },
    //         {
    //             "country": "Unassigned",
    //             "Count": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].unassign_count,
    //             "color": am4core.color("#ff9c80")
    //         },
    //     ];
    //     // Set inner radius
    //     chart.innerRadius = am4core.percent(35);
    //     // chart.height=200
    //     // chart.legend = new am4charts.Legend();
    //     // chart.current = x;
    //     // x.legend = new am4charts.Legend();

    //     // Add and configure Series
    //     var pieSeries = chart.series.push(new am4charts.PieSeries3D());
    //     pieSeries.dataFields.value = "Count";
    //     pieSeries.dataFields.category = "country";
    //     pieSeries.slices.template.propertyFields.fill = "color";
    //     chart.current = chart;
    //     chart.legend = new am4charts.Legend();
    //     pieSeries.slices.template.tooltipText = `{category}: {value} out of ${total} ({value.percent.formatNumber('#.00')}[/]%)`;



    //     chart.legend = new am4charts.Legend();
    //     chart.legend.useDefaultMarker = true;
    //     chart.legend.valueLabels.template.disabled = true;
    //     var marker = chart.legend.markers.template.children.getIndex(0);
    //     marker.cornerRadius(12, 12, 12, 12);
    //     marker.strokeWidth = 2;
    //     marker.strokeOpacity = 1;
    //     marker.stroke = am4core.color("#ccc");
    //     var label = chart.createChild(am4core.Label);
    //     label.text = "Country-Domain";
    //     label.fontSize = 14;
    //     label.paddingTop = '10%';
    //     label.align = "left";
    //     label.paddingLeft = '10%';
    //     label.marginBottom = '2%'
    //     label.fontWeight = 'bold'

    //     // pieSeries.slices.template.stroke = am4core.color("#fff");
    //     // pieSeries.slices.template.strokeWidth = 2;
    //     // pieSeries.slices.template.strokeOpacity = 1;

    //     // This creates initial animation
    //     pieSeries.hiddenState.properties.opacity = 1;
    //     pieSeries.hiddenState.properties.endAngle = -90;
    //     pieSeries.hiddenState.properties.startAngle = -90;


    //     pieSeries.slices.template.events.on("hit", function (ev) {
    //         setCountValue(0)
    //         setCriticalityValue(ev.target.dataItem.category)
    //         setShowMoreCount(0)
    //         setDataCount(ev.target.dataItem._dataContext.Count
    //         )
    //         setTable(true)
    //         setChartValue('hiddenRisk')
    //         const payload = [
    //             authtoken,
    //             {
    //                 "session_token": authtoken,
    //                 "request": [
    //                     "GetComplianceApplicabilityStatusDrillDown",
    //                     {
    //                         "c_ids": [
    //                             commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
    //                         ],
    //                         "d_ids": [
    //                             commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
    //                         ],
    //                         "filter_type": "Group",
    //                         "filter_ids": [
    //                             1
    //                         ],
    //                         "chart_year": 2022,
    //                         "applicability_status": ev.target.dataItem.category,
    //                         "record_count": 0,
    //                         "le_ids": [
    //                             commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_id
    //                         ],
    //                         "country_domains": [
    //                             {
    //                                 "d_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id,
    //                                 "c_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].c_id,
    //                                 "month_from": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_from,
    //                                 "month_to": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_to
    //                             }
    //                         ]
    //                     }
    //                 ]
    //             }
    //         ]
    //         if (paramid != "") {
    //             getRiskChartTableData({
    //                 payload: payload,
    //                 paramid: paramid
    //             })
    //         }
    //     })

    //     chart.exporting.menu = new am4core.ExportMenu();
    //     chart.exporting.dataFields = {
    //         "Status": "Status",
    //         "Count": "Count"
    //     }
    //     chart.exporting.menu.items = [{
    //         "label": "...",
    //         "menu": [
    //             {
    //                 "label": "Image",
    //                 "menu": [
    //                     { "type": "png", "label": "PNG" },
    //                     { "type": "jpg", "label": "JPG" },
    //                     { "type": "svg", "label": "SVG" },
    //                     { "type": "pdf", "label": "PDF" },
    //                     { "type": "gif", "label": "GIF" }
    //                 ]
    //             }, {
    //                 "label": "Data",
    //                 "menu": [
    //                     { "type": "csv", "label": "CSV" },
    //                     { "type": "xlsx", "label": "XLSX" },
    //                 ]
    //             }, {
    //                 "label": "Print", "type": "print"
    //             }
    //         ]
    //     }];
    //     chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";

    // });



    // useEffect(() => {
    //     if ((hiddenRiskData && hiddenRiskData.length > 0) && (exposedRiskData && exposedRiskData.length > 0)) {
    //         am4core.useTheme(am4themes_animated);
    //         // Create a container
    //         var container = am4core.create("container", am4core.Container);
    //         container.width = am4core.percent(100);
    //         container.height = am4core.percent(100);
    //         container.layout = "horizontal";
    //         container.background.fill = am4core.color("#000");
    //         container.background.fillOpacity = 0.1;
    //         container.background.stroke = am4core.color("#000");
    //         container.background.strokeOpacity = 0.2;
    //         container.background.strokeWidth = 2;





    //         // title.marginTop = '5%'
    //         // title.marginLeft = '40%'
    //         // title.fontWeight = 'bold'


    //         function addChart(data) {

    //             let total = (hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_applicable_count) + (hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_opted_count) + (hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].unassign_count)


    //             var chart = container.createChild(am4charts.PieChart3D);

    //             // Add data
    //             chart.data = data;
    //             chart.width = am4core.percent(50);

    //             chart.innerRadius = am4core.percent(35);
    //             // chart.legend = new am4charts.Legend();
    //             // chart.current = x;
    //             // x.legend = new am4charts.Legend();
    //             chart.height = 325
    //             chart.paddingTop = '50px'

    //             // Add and configure Series
    //             var pieSeries = chart.series.push(new am4charts.PieSeries3D());
    //             pieSeries.dataFields.value = "Count";
    //             pieSeries.dataFields.category = "country";
    //             pieSeries.slices.template.propertyFields.fill = "color";
    //             chart.current = chart;
    //             // chart.height= 250
    //             // chart.paddingTop = '20%'
    //             chart.legend = new am4charts.Legend();
    //             pieSeries.slices.template.tooltipText = `{category}: {value} out of ${total} ({value.percent.formatNumber('#.00')}[/]%)`;

    //             chart.legend = new am4charts.Legend();
    //             chart.legend.useDefaultMarker = true;
    //             chart.legend.valueLabels.template.disabled = true;
    //             var marker = chart.legend.markers.template.children.getIndex(0);
    //             marker.cornerRadius(12, 12, 12, 12);
    //             marker.strokeWidth = 2;
    //             marker.strokeOpacity = 1;
    //             marker.stroke = am4core.color("#ccc");


    //             // pieSeries.slices.template.stroke = am4core.color("#fff");
    //             // pieSeries.slices.template.strokeWidth = 2;
    //             // pieSeries.slices.template.strokeOpacity = 1;

    //             // This creates initial animation
    //             pieSeries.hiddenState.properties.opacity = 1;
    //             pieSeries.hiddenState.properties.endAngle = -90;
    //             pieSeries.hiddenState.properties.startAngle = -90;


    //             pieSeries.slices.template.events.on("hit", function (ev) {
    //                 setCountValue(0)
    //                 setCriticalityValue(ev.target.dataItem.category)
    //                 setDataCount(ev.target.dataItem._dataContext.Count
    //                 )
    //                 setTable(true)
    //                 setChartValue('hiddenRisk')
    //                 const payload = [
    //                     authtoken,
    //                     {
    //                         "session_token": authtoken,
    //                         "request": [
    //                             "GetComplianceApplicabilityStatusDrillDown",
    //                             {
    //                                 "c_ids": [
    //                                     commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
    //                                 ],
    //                                 "d_ids": dIds,
    //                                 "filter_type": fieldType != "" ? fieldType : "Group",
    //                                 "filter_ids": [
    //                                     fieldType == "LegalEntity" ? filterData.legalentity :
    //                                         fieldType == 'Division' ? Number(filterData.division) :
    //                                             fieldType == 'Category' ? Number(filterData.category) :
    //                                                 fieldType == 'Unit' ? Number(filterData.unit) :
    //                                                     fieldType == 'BusinessGroup' ? Number(filterData.business) :
    //                                                         1
    //                                 ],
    //                                 "chart_year": year,
    //                                 "applicability_status": ev.target.dataItem.category,
    //                                 "record_count": 0,
    //                                 "le_ids":
    //                                     filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
    //                                 ,
    //                                 "country_domains": countryDomain
    //                             }
    //                         ]
    //                     }
    //                 ]
    //                 if (paramid != "") {
    //                     getRiskChartTableData({
    //                         payload: payload,
    //                         paramid: paramid
    //                     })
    //                 }
    //             })


    //             var label = chart.createChild(am4core.Label);
    //             label.text = "Country-Domain";
    //             label.fontSize = 14;
    //             label.align = "left";
    //             label.marginLeft = '2%';
    //             label.marginBottom = '2%'
    //             label.fontWeight = 'bold'
    //             var label1 = chart.createChild(am4core.Label);
    //             label1.text = `[bold]${commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}[/]-${domainName}`
    //             label1.fontSize = 14;
    //             label1.align = "left";
    //             label1.marginLeft = '2%';
    //             label1.marginBottom = '5%'

    //             // var title = chart.titles.create();
    //             // title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time}${timeZone}`
    //             // title.disabled = false;
    //             // title.align = 'left'
    //             // title.marginTop = '2%'
    //             // title.marginLeft = '2%'

    //             // var title1 = chart.titles.create();
    //             // title1.text = 'Over Due Compliance of Lulu Groups'
    //             // title1.disabled = false;
    //             // title1.align = 'center'
    //             // title1.marginTop = '5%'
    //             // title1.marginLeft = '2%'
    //             // title1.fontWeight = 'bold'

    //             // // <b><p>Country-Domain</p></b>
    //             // // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

    //             // var label = chart.createChild(am4core.Label);
    //             // label.text = "Country-Domain";
    //             // label.fontSize = 14;
    //             // label.align = "left";
    //             // label.marginLeft = '2%';
    //             // label.marginBottom = '2%'
    //             // label.fontWeight = 'bold'

    //             // var label1 = chart.createChild(am4core.Label);
    //             // label1.text = `${commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}-${commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}`
    //             // label1.fontSize = 14;
    //             // label1.align = "left";
    //             // label1.marginLeft = '2%';
    //             // label1.marginBottom = '5%'



    //             // var title2 = chart.titles.create();
    //             // title2.text = 'Country-Domain'
    //             // title2.disabled = false;
    //             // title2.align = 'bottom'
    //             // title2.marginTop = '2%'
    //             // title2.marginLeft = '2%'

    //             // title2.fontWeight = 'bold'

    //             // Enable title on export
    //             // chart.exporting.events.on("exportstarted", function (ev) {
    //             //     title.disabled = false;
    //             //     title.parent.invalidate();
    //             // });

    //             // // Disable title when export finishes
    //             // chart.exporting.events.on("exportfinished", function (ev) {
    //             //     title.disabled = true;
    //             // });
    //         }

    //         addChart([
    //             (hiddenRiskData && hiddenRiskData[0].not_applicable_count > 0 ?
    //                 {
    //                     "country": "Not Applicable",
    //                     "Count": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_applicable_count,
    //                     "color": am4core.color("#808080")
    //                 } : {}),
    //             (hiddenRiskData && hiddenRiskData[0].not_opted_count > 0 ?
    //                 {
    //                     "country": "Not Opted",
    //                     "Count": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_opted_count,
    //                     "color": am4core.color("#dd070c")
    //                 } : {}),
    //             (hiddenRiskData && hiddenRiskData[0].unassign_count > 0 ?
    //                 {
    //                     "country": "Unassigned",
    //                     "Count": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].unassign_count,
    //                     "color": am4core.color("#ff9c80")
    //                 } : {})]);

    //         var title = container.createChild(am4core.Label)
    //         // title.text = 'Risk Chart'
    //         title.text =
    //             ((fieldType == "LegalEntity" && go == true) ? `Risk Chart of Legal Entity ${filterDataNames.legalentity}` : (
    //                 (fieldType == "Division" && go == true) ? `Risk Chart of Division ${filterDataNames.division}` : (
    //                     (fieldType == "Category" && go == true) ? `Risk Chart of Category ${filterDataNames.category}` : (
    //                         (fieldType == "Unit" && go == true) ? `Risk Chart of Unit ${filterDataNames.unit}` : `Risk Chart of ${commonData && commonData.g_name} ${year}`
    //                     ))
    //             ))
    //         title.disabled = false;
    //         title.align = 'center'
    //         title.fontWeight = 'bold'
    //         title.marginLeft = '1%'
    //         title.paddingTop = '20px'
    //         function addChart1(data1) {

    //             let total = (exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].delayed_compliance_count) + (exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].not_complied_count)

    //             var chart = container.createChild(am4charts.PieChart3D);

    //             // Add data
    //             chart.data = data1;
    //             chart.height = 225
    //             chart.width = am4core.percent(50);

    //             chart.paddingTop = '50px'
    //             chart.innerRadius = am4core.percent(35);

    //             // Add and configure Series
    //             var pieSeries = chart.series.push(new am4charts.PieSeries3D());
    //             pieSeries.dataFields.value = "Count";
    //             pieSeries.dataFields.category = "country";
    //             pieSeries.slices.template.propertyFields.fill = "color";
    //             chart.current = chart;
    //             chart.legend = new am4charts.Legend();
    //             // pieSeries.slices.template.tooltipText = `{category}: {value} out of ${total}`;
    //             pieSeries.slices.template.tooltipText = `{category}: {value} out of ${total}`;

    //             // pieSeries.slices.template.stroke = am4core.color("#fff");
    //             // pieSeries.slices.template.strokeWidth = 2;
    //             // pieSeries.slices.template.strokeOpacity = 1;

    //             chart.legend = new am4charts.Legend();
    //             chart.legend.useDefaultMarker = true;
    //             chart.legend.valueLabels.template.disabled = true;
    //             var marker = chart.legend.markers.template.children.getIndex(0);
    //             marker.cornerRadius(12, 12, 12, 12);
    //             marker.strokeWidth = 2;
    //             marker.strokeOpacity = 1;
    //             marker.stroke = am4core.color("#ccc");

    //             // pieSeries.slices.template.stroke = am4core.color("#fff");
    //             // pieSeries.slices.template.strokeWidth = 2;
    //             // pieSeries.slices.template.strokeOpacity = 1;

    //             // This creates initial animation
    //             pieSeries.hiddenState.properties.opacity = 1;
    //             pieSeries.hiddenState.properties.endAngle = -90;
    //             pieSeries.hiddenState.properties.startAngle = -90;

    //             pieSeries.slices.template.events.on("hit", function (ev) {
    //                 setCountValue(0)
    //                 setCriticalityValue(ev.target.dataItem.category)
    //                 setDataCount(ev.target.dataItem._dataContext.Count
    //                 )
    //                 setTable(true)
    //                 setChartValue('exposedRisk')
    //                 const payload = [
    //                     authtoken,
    //                     {
    //                         "session_token": authtoken,
    //                         "request": [
    //                             "GetComplianceApplicabilityStatusDrillDown",
    //                             {
    //                                 "c_ids": [
    //                                     commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
    //                                 ],
    //                                 "d_ids": dIds,
    //                                 "filter_type": fieldType != "" ? fieldType : "Group",
    //                                 "filter_ids": [
    //                                     fieldType == "LegalEntity" ? filterData.legalentity :
    //                                         fieldType == 'Division' ? Number(filterData.division) :
    //                                             fieldType == 'Category' ? Number(filterData.category) :
    //                                                 fieldType == 'Unit' ? Number(filterData.unit) :
    //                                                     fieldType == 'BusinessGroup' ? Number(filterData.business) :
    //                                                         1
    //                                 ],
    //                                 "chart_year": year,
    //                                 "applicability_status": ev.target.dataItem.category,
    //                                 "record_count": 0,
    //                                 "le_ids":
    //                                     filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
    //                                 ,
    //                                 "country_domains": countryDomain
    //                             }
    //                         ]
    //                     }
    //                 ]
    //                 if (paramid != "") {
    //                     getRiskChartDelayedTableData({
    //                         payload: payload,
    //                         paramid: paramid
    //                     })
    //                 }

    //             })

    //             // var title = chart.titles.create();
    //             // title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time}${timeZone}`
    //             // title.disabled = false;
    //             // title.align = 'left'
    //             // title.marginTop = '2%'
    //             // title.marginLeft = '2%'

    //             // var title1 = chart.titles.create();
    //             // title1.text = 'Over Due Compliance of Lulu Groups'
    //             // title1.disabled = false;
    //             // title1.align = 'left'
    //             // title1.marginTop = '5%'
    //             // title1.marginLeft = '2%'
    //             // title1.fontWeight = 'bold'

    //             // // <b><p>Country-Domain</p></b>
    //             // // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

    //             // var label = chart.createChild(am4core.Label);
    //             // label.text = "Country-Domain";
    //             // label.fontSize = 14;
    //             // label.align = "left";
    //             // label.marginLeft = '2%';
    //             // label.marginBottom = '2%'
    //             // label.fontWeight = 'bold'

    //             // var label1 = chart.createChild(am4core.Label);
    //             // label1.text = `${commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}-${commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}`
    //             // label1.fontSize = 14;
    //             // label1.align = "left";
    //             // label1.marginLeft = '2%';
    //             // label1.marginBottom = '5%'

    //             // title2.text = 'Country-Domain'
    //             // title2.disabled = false;
    //             // title2.align = 'bottom'
    //             // title2.marginTop = '2%'
    //             // title2.marginLeft = '2%'

    //             // title2.fontWeight = 'bold'

    //             // Enable title on export
    //             // chart.exporting.events.on("exportstarted", function (ev) {
    //             //     title.disabled = false;
    //             //     title.parent.invalidate();
    //             // });

    //             // // Disable title when export finishes
    //             // chart.exporting.events.on("exportfinished", function (ev) {
    //             //     title.disabled = true;
    //             // });
    //         }

    //         addChart1([
    //             (exposedRiskData && exposedRiskData[0].delayed_compliance_count > 0 ?
    //                 {
    //                     "country": "Delayed Compliance",
    //                     "Count": exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].delayed_compliance_count,
    //                     "color": am4core.color("#3c78d8")
    //                 } : {}),
    //             (exposedRiskData && exposedRiskData[0].not_complied_count > 0 ?
    //                 {
    //                     "country": "Not Complied",
    //                     "Count": exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].not_complied_count,
    //                     "color": am4core.color("#fe4365")
    //                 } : {}
    //             )]);

    //     }

    // }, [exposedRiskData, hiddenRiskData, table, timeZone, timeZoneData, localStorage.getItem('chart')])

    console.log(exposedRiskData, 'exposedRiskData');
    console.log(hiddenRiskData, 'hiddenRiskData');

    // useEffect(() => {
    //     if ((hiddenRiskData && hiddenRiskData.length > 0) && (exposedRiskData && exposedRiskData.length > 0)) {
    //         am4core.useTheme(am4themes_animated);
    //         // Create a container
    //         var container = am4core.create("container", am4core.Container);
    //         container.width = am4core.percent(100);
    //         container.height = am4core.percent(100);
    //         container.layout = "horizontal";
    //         container.background.fill = am4core.color("#000");
    //         container.background.fillOpacity = 0.1;
    //         container.background.stroke = am4core.color("#000");
    //         container.background.strokeOpacity = 0.2;
    //         container.background.strokeWidth = 2;

    //         // var title = container.createChild(am4core.Label)
    //         // title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} (UTC)`
    //         // title.disabled = false;
    //         // title.align = 'left'
    //         // title.paddingTop = '13%'
    //         // title.paddingLeft = generatedOn == true ? '3%' : '2%'

    //         var title3 = container.createChild(am4core.Label)
    //         title3.text = "Hidden Risk"
    //         title3.disabled = false;
    //         title3.align = 'left'
    //         title3.paddingTop = '22%'
    //         title3.paddingLeft = '-9%'
    //         title3.fontWeight = 'bold'
    //         title3.fontSize = '14'


    //         var title4 = container.createChild(am4core.Label)
    //         title4.text = "Exposed Risk"
    //         title4.disabled = false;
    //         title4.align = 'right'
    //         title4.paddingTop = '22%'
    //         title4.paddingRight = '-49%'
    //         title4.paddingLeft = '37%'
    //         title4.fontWeight = 'bold'
    //         title4.fontSize = '14'



    //         var title2 = container.createChild(am4core.Label)
    //         title2.text = "Country-Domain"
    //         title2.disabled = false;
    //         title2.align = 'left'
    //         title2.paddingTop = '85%'
    //         title2.paddingLeft = '-53%'
    //         title2.fontWeight = 'bold'

    //         var title2 = container.createChild(am4core.Label)
    //         title2.text = `[bold]${commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}[/]-${domainName}`
    //         title2.disabled = false;
    //         title2.align = 'left'
    //         title2.paddingTop = '90%'
    //         title2.paddingLeft = '-53%'

    //         function addChart(data) {

    //             let total = (hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_applicable_count) + (hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_opted_count) + (hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].unassign_count)


    //             var chart = container.createChild(am4charts.PieChart3D);

    //             // Add data
    //             chart.data = data;
    //             chart.width = am4core.percent(50);

    //             chart.innerRadius = am4core.percent(35);
    //             // chart.legend = new am4charts.Legend();
    //             // chart.current = x;
    //             // x.legend = new am4charts.Legend();
    //             chart.height = 375
    //             chart.paddingTop = '29%'
    //             chart.paddingLeft = '-83%'

    //             // Add and configure Series
    //             var pieSeries = chart.series.push(new am4charts.PieSeries3D());
    //             pieSeries.dataFields.value = "Count";
    //             pieSeries.dataFields.category = "country";
    //             pieSeries.slices.template.propertyFields.fill = "color";
    //             chart.current = chart;
    //             // chart.height= 250
    //             // chart.paddingTop = '20%'
    //             chart.legend = new am4charts.Legend();
    //             pieSeries.slices.template.tooltipText = `{category}: {value} out of ${total} ({value.percent.formatNumber('#.00')}[/]%)`;

    //             chart.legend = new am4charts.Legend();
    //             chart.legend.useDefaultMarker = true;
    //             chart.legend.valueLabels.template.disabled = true;
    //             var marker = chart.legend.markers.template.children.getIndex(0);
    //             marker.cornerRadius(12, 12, 12, 12);
    //             marker.strokeWidth = 2;
    //             marker.strokeOpacity = 1;
    //             marker.stroke = am4core.color("#ccc");


    //             // pieSeries.slices.template.stroke = am4core.color("#fff");
    //             // pieSeries.slices.template.strokeWidth = 2;
    //             // pieSeries.slices.template.strokeOpacity = 1;

    //             // This creates initial animation
    //             pieSeries.hiddenState.properties.opacity = 1;
    //             pieSeries.hiddenState.properties.endAngle = -90;
    //             pieSeries.hiddenState.properties.startAngle = -90;


    //             pieSeries.slices.template.events.on("hit", function (ev) {
    //                 setCountValue(0)
    //                 setCriticalityValue(ev.target.dataItem.category)
    //                 setDataCount(ev.target.dataItem._dataContext.litres
    //                 )
    //                 setTable(true)
    //                 setChartValue('hiddenRisk')
    //                 const payload = [
    //                     authtoken,
    //                     {
    //                         "session_token": authtoken,
    //                         "request": [
    //                             "GetComplianceApplicabilityStatusDrillDown",
    //                             {
    //                                 "c_ids": [
    //                                     commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
    //                                 ],
    //                                 "d_ids": dIds,
    //                                 "filter_type": fieldType != "" ? fieldType : "Group",
    //                                 "filter_ids": [
    //                                     fieldType == "LegalEntity" ? filterData.legalentity :
    //                                         fieldType == 'Division' ? Number(filterData.division) :
    //                                             fieldType == 'Category' ? Number(filterData.category) :
    //                                                 fieldType == 'Unit' ? Number(filterData.unit) :
    //                                                     fieldType == 'BusinessGroup' ? Number(filterData.business) :
    //                                                         1
    //                                 ],
    //                                 "chart_year": year,
    //                                 "applicability_status": ev.target.dataItem.category,
    //                                 "record_count": 0,
    //                                 "le_ids":
    //                                     filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
    //                                 ,
    //                                 "country_domains": countryDomain
    //                             }
    //                         ]
    //                     }
    //                 ]
    //                 if (paramid != "") {
    //                     getRiskChartTableData({
    //                         payload: payload,
    //                         paramid: paramid
    //                     })
    //                 }
    //             })
    //         }

    //         addChart([
    //             (hiddenRiskData && hiddenRiskData[0].not_applicable_count > 0 ?
    //                 {
    //                     "country": "Not Applicable",
    //                     "litres": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_applicable_count,
    //                     "color": am4core.color("#808080")
    //                 } : {}),
    //             (hiddenRiskData && hiddenRiskData[0].not_opted_count > 0 ?
    //                 {
    //                     "country": "Not Opted",
    //                     "litres": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_opted_count,
    //                     "color": am4core.color("#dd070c")
    //                 } : {}),
    //             (hiddenRiskData && hiddenRiskData[0].unassign_count > 0 ?
    //                 {
    //                     "country": "Unassigned",
    //                     "litres": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].unassign_count,
    //                     "color": am4core.color("#ff9c80")
    //                 } : {})]);


    //         function addChart1(data1) {

    //             let total = (exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].delayed_compliance_count) + (exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].not_complied_count)

    //             var chart = container.createChild(am4charts.PieChart3D);

    //             // Add data
    //             chart.data = data1;
    //             chart.height = 375
    //             chart.paddingTop = '29%'
    //             chart.width = am4core.percent(50);

    //             chart.paddingLeft = '-33%'
    //             chart.innerRadius = am4core.percent(35);

    //             // Add and configure Series
    //             var pieSeries = chart.series.push(new am4charts.PieSeries3D());
    //             pieSeries.dataFields.value = "litres";
    //             pieSeries.dataFields.category = "country";
    //             pieSeries.slices.template.propertyFields.fill = "color";
    //             chart.current = chart;
    //             chart.legend = new am4charts.Legend();
    //             // pieSeries.slices.template.tooltipText = `{category}: {value} out of ${total}`;
    //             pieSeries.slices.template.tooltipText = `{category}: {value} out of ${total}`;

    //             // pieSeries.slices.template.stroke = am4core.color("#fff");
    //             // pieSeries.slices.template.strokeWidth = 2;
    //             // pieSeries.slices.template.strokeOpacity = 1;

    //             chart.legend = new am4charts.Legend();
    //             chart.legend.useDefaultMarker = true;
    //             chart.legend.valueLabels.template.disabled = true;
    //             var marker = chart.legend.markers.template.children.getIndex(0);
    //             marker.cornerRadius(12, 12, 12, 12);
    //             marker.strokeWidth = 2;
    //             marker.strokeOpacity = 1;
    //             marker.stroke = am4core.color("#ccc");

    //             // pieSeries.slices.template.stroke = am4core.color("#fff");
    //             // pieSeries.slices.template.strokeWidth = 2;
    //             // pieSeries.slices.template.strokeOpacity = 1;

    //             // This creates initial animation
    //             pieSeries.hiddenState.properties.opacity = 1;
    //             pieSeries.hiddenState.properties.endAngle = -90;
    //             pieSeries.hiddenState.properties.startAngle = -90;

    //             pieSeries.slices.template.events.on("hit", function (ev) {
    //                 setCountValue(0)
    //                 setCriticalityValue(ev.target.dataItem.category)
    //                 setDataCount(ev.target.dataItem._dataContext.litres
    //                 )
    //                 setTable(true)
    //                 setChartValue('exposedRisk')
    //                 const payload = [
    //                     authtoken,
    //                     {
    //                         "session_token": authtoken,
    //                         "request": [
    //                             "GetComplianceApplicabilityStatusDrillDown",
    //                             {
    //                                 "c_ids": [
    //                                     commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
    //                                 ],
    //                                 "d_ids": dIds,
    //                                 "filter_type": fieldType != "" ? fieldType : "Group",
    //                                 "filter_ids": [
    //                                     fieldType == "LegalEntity" ? filterData.legalentity :
    //                                         fieldType == 'Division' ? Number(filterData.division) :
    //                                             fieldType == 'Category' ? Number(filterData.category) :
    //                                                 fieldType == 'Unit' ? Number(filterData.unit) :
    //                                                     fieldType == 'BusinessGroup' ? Number(filterData.business) :
    //                                                         1
    //                                 ],
    //                                 "chart_year": year,
    //                                 "applicability_status": ev.target.dataItem.category,
    //                                 "record_count": 0,
    //                                 "le_ids":
    //                                     filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
    //                                 ,
    //                                 "country_domains": countryDomain
    //                             }
    //                         ]
    //                     }
    //                 ]
    //                 if (paramid != "") {
    //                     getRiskChartDelayedTableData({
    //                         payload: payload,
    //                         paramid: paramid
    //                     })
    //                 }

    //             })

    //         }


    //         addChart1([
    //             (exposedRiskData && exposedRiskData[0].delayed_compliance_count > 0 ?
    //                 {
    //                     "country": "Delayed Compliance",
    //                     "litres": exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].delayed_compliance_count,
    //                     "color": am4core.color("#3c78d8")
    //                 } : {}),
    //             (exposedRiskData && exposedRiskData[0].not_complied_count > 0 ?
    //                 {
    //                     "country": "Not Complied",
    //                     "litres": exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].not_complied_count,
    //                     "color": am4core.color("#fe4365")
    //                 } : {}
    //             )]);

    //         var title1 = container.createChild(am4core.Label)
    //         title1.text =
    //             ((fieldType == "LegalEntity" && go == true && type === '') ? `Risk Chart of Legal Entity [bold]${filterDataNames.legalentity}[/]` : (
    //                 (fieldType == "Division" && go == true && type === '') ? `Risk Chart of Division [bold]${filterDataNames.division}[/]` : (
    //                     (fieldType == "Category" && go == true && type === '') ? `Risk Chart of Category [bold]${filterDataNames.category}[/]` : (
    //                         (fieldType == "BusinessGroup" && go == true && type === '') ? `Risk Chart of Business Group [bold]${filterDataNames.business}[/]` : (
    //                             (fieldType == "Unit" && go == true && type === '') ? `Risk Chart of Unit [bold]${filterDataNames.unit}[/]` : `Risk Chart of ${commonData && commonData.g_name}`
    //                         )))
    //             ))
    //         title1.disabled = false;
    //         title1.paddingLeft = (fieldType == "LegalEntity" && go == true && type === '') ? '-9%' : '10%'
    //         // title1.align = 'center'
    //         title1.paddingTop = '3%'
    //         title1.paddingLeft = '5%'
    //         // title1.align = 'right'
    //         // // title1.align = 'center'


    //     }

    // }, [exposedRiskData, hiddenRiskData, table, timeZone, timeZoneData, localStorage.getItem('chart')])

    // useEffect(() => {
    //     if ((hiddenRiskData && hiddenRiskData.length > 0) && (exposedRiskData && exposedRiskData.length > 0)) {
    //         am4core.useTheme(am4themes_animated);
    //         // Create a container
    //         var container = am4core.create("container", am4core.Container);
    //         container.width = am4core.percent(100);
    //         container.height = am4core.percent(100);
    //         container.layout = "horizontal";
    //         container.background.fill = am4core.color("#000");
    //         container.background.fillOpacity = 0.1;
    //         container.background.stroke = am4core.color("#000");
    //         container.background.strokeOpacity = 0.2;
    //         container.background.strokeWidth = 2;





    //         var title3 = container.createChild(am4core.Label)
    //         title3.text = "Hidden Risk"
    //         title3.disabled = false;
    //         title3.align = 'left'
    //         title3.paddingTop = '22%'
    //         title3.paddingLeft = '19%'
    //         title3.fontWeight = 'bold'
    //         title3.fontSize = '14'


    //         var title4 = container.createChild(am4core.Label)
    //         title4.text = "Exposed Risk"
    //         title4.disabled = false;
    //         title4.align = 'right'
    //         title4.paddingTop = '22%'
    //         title4.paddingRight = '-69%'
    //         title4.paddingLeft = '37%'
    //         title4.fontWeight = 'bold'
    //         title4.fontSize = '14'


    //         var title2 = container.createChild(am4core.Label)
    //         title2.text = "Country-Domain"
    //         title2.disabled = false;
    //         title2.align = 'left'
    //         title2.paddingTop = '85%'
    //         title2.paddingLeft = '-25%'
    //         title2.fontWeight = 'bold'

    //         var title2 = container.createChild(am4core.Label)
    //         title2.text = `[bold]${commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}[/]-${domainName}`
    //         title2.disabled = false;
    //         title2.align = 'left'
    //         title2.paddingTop = '90%'
    //         title2.paddingLeft = '-25%'

    //         var title = container.createChild(am4core.Label)
    //         title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} (UTC)`
    //         title.disabled = ((fieldType != '') && (go == true)) ? false : true;
    //         title.align = 'left'
    //         title.paddingTop = '13%'
    //         title.height = '2%'
    //         title.paddingLeft = '2%'


    //         if (fieldType == '') {
    //             console.log('Mukilan');
    //             var title1 = container.createChild(am4core.Label)
    //             title1.text =
    //                 ((fieldType == "LegalEntity" && go == true && type === '') ? `Risk Chart of Legal Entity [bold]${filterDataNames.legalentity}[/]` : (
    //                     (fieldType == "Division" && go == true && type === '') ? `Risk Chart of Division [bold]${filterDataNames.division}[/]` : (
    //                         (fieldType == "Category" && go == true && type === '') ? `Risk Chart of Category [bold]${filterDataNames.category}[/]` : (
    //                             (fieldType == "BusinessGroup" && go == true && type === '') ? `Risk Chart of Business Group [bold]${filterDataNames.business}[/]` : (
    //                                 (fieldType == "Unit" && go == true && type === '') ? `Risk Chart of Unit [bold]${filterDataNames.unit}[/]` : `Risk Chart of ${commonData && commonData.g_name}`
    //                             )))
    //                 ))
    //             title1.disabled = ((fieldType != '') && (go == true)) ? true : false;
    //             // title1.align = 'center'
    //             title1.paddingTop = '3%'
    //             title1.paddingLeft = ((fieldType != '') && (go == true)) ? '3%' : '8%'
    //             // title1.align = 'right'
    //             // // title1.align = 'center'
    //         }
    //         function addChart(data) {

    //             let total = (hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_applicable_count) + (hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_opted_count) + (hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].unassign_count)


    //             var chart = container.createChild(am4charts.PieChart3D);

    //             // Add data
    //             chart.data = data;
    //             chart.width = am4core.percent(50);

    //             chart.innerRadius = am4core.percent(35);
    //             // chart.legend = new am4charts.Legend();
    //             // chart.current = x;
    //             // x.legend = new am4charts.Legend();
    //             chart.height = 375
    //             chart.paddingTop = '29%'
    //             chart.paddingLeft = '-83%'

    //             // Add and configure Series
    //             var pieSeries = chart.series.push(new am4charts.PieSeries3D());
    //             pieSeries.dataFields.value = "litres";
    //             pieSeries.dataFields.category = "country";
    //             pieSeries.slices.template.propertyFields.fill = "color";
    //             chart.current = chart;
    //             // chart.height= 250
    //             // chart.paddingTop = '20%'
    //             chart.legend = new am4charts.Legend();
    //             pieSeries.slices.template.tooltipText = `{category}: {value} out of ${total} ({value.percent.formatNumber('#.00')}[/]%)`;

    //             chart.legend = new am4charts.Legend();
    //             chart.legend.useDefaultMarker = true;
    //             chart.legend.valueLabels.template.disabled = true;
    //             var marker = chart.legend.markers.template.children.getIndex(0);
    //             marker.cornerRadius(12, 12, 12, 12);
    //             marker.strokeWidth = 2;
    //             marker.strokeOpacity = 1;
    //             marker.stroke = am4core.color("#ccc");


    //             // pieSeries.slices.template.stroke = am4core.color("#fff");
    //             // pieSeries.slices.template.strokeWidth = 2;
    //             // pieSeries.slices.template.strokeOpacity = 1;

    //             // This creates initial animation
    //             pieSeries.hiddenState.properties.opacity = 1;
    //             pieSeries.hiddenState.properties.endAngle = -90;
    //             pieSeries.hiddenState.properties.startAngle = -90;


    //             pieSeries.slices.template.events.on("hit", function (ev) {
    //                 setCountValue(0)
    //                 setCriticalityValue(ev.target.dataItem.category)
    //                 setDataCount(ev.target.dataItem._dataContext.litres
    //                 )
    //                 setTable(true)
    //                 setChartValue('hiddenRisk')
    //                 const payload = [
    //                     authtoken,
    //                     {
    //                         "session_token": authtoken,
    //                         "request": [
    //                             "GetComplianceApplicabilityStatusDrillDown",
    //                             {
    //                                 "c_ids": [
    //                                     commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
    //                                 ],
    //                                 "d_ids": dIds,
    //                                 "filter_type": fieldType != "" ? fieldType : "Group",
    //                                 "filter_ids": [
    //                                     fieldType == "LegalEntity" ? filterData.legalentity :
    //                                         fieldType == 'Division' ? Number(filterData.division) :
    //                                             fieldType == 'Category' ? Number(filterData.category) :
    //                                                 fieldType == 'Unit' ? Number(filterData.unit) :
    //                                                     fieldType == 'BusinessGroup' ? Number(filterData.business) :
    //                                                         1
    //                                 ],
    //                                 "chart_year": year,
    //                                 "applicability_status": ev.target.dataItem.category,
    //                                 "record_count": 0,
    //                                 "le_ids":
    //                                     filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
    //                                 ,
    //                                 "country_domains": countryDomain
    //                             }
    //                         ]
    //                     }
    //                 ]
    //                 if (paramid != "") {
    //                     getRiskChartTableData({
    //                         payload: payload,
    //                         paramid: paramid
    //                     })
    //                 }
    //             })
    //         }

    //         addChart([
    //             (hiddenRiskData && hiddenRiskData[0].not_applicable_count > 0 ?
    //                 {
    //                     "country": "Not Applicable",
    //                     "litres": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_applicable_count,
    //                     "color": am4core.color("#808080")
    //                 } : {}),
    //             (hiddenRiskData && hiddenRiskData[0].not_opted_count > 0 ?
    //                 {
    //                     "country": "Not Opted",
    //                     "litres": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_opted_count,
    //                     "color": am4core.color("#dd070c")
    //                 } : {}),
    //             (hiddenRiskData && hiddenRiskData[0].unassign_count > 0 ?
    //                 {
    //                     "country": "Unassigned",
    //                     "litres": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].unassign_count,
    //                     "color": am4core.color("#ff9c80")
    //                 } : {})]);

    //         function addChart1(data1) {

    //             let total = (exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].delayed_compliance_count) + (exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].not_complied_count)

    //             var chart = container.createChild(am4charts.PieChart3D);

    //             // Add data
    //             chart.data = data1;
    //             chart.height = 375
    //             chart.paddingTop = '29%'
    //             chart.width = am4core.percent(50);

    //             chart.paddingLeft = '-33%'
    //             chart.innerRadius = am4core.percent(35);

    //             // Add and configure Series
    //             var pieSeries = chart.series.push(new am4charts.PieSeries3D());
    //             pieSeries.dataFields.value = "litres";
    //             pieSeries.dataFields.category = "country";
    //             pieSeries.slices.template.propertyFields.fill = "color";
    //             chart.current = chart;
    //             chart.legend = new am4charts.Legend();
    //             // pieSeries.slices.template.tooltipText = `{category}: {value} out of ${total}`;
    //             pieSeries.slices.template.tooltipText = `{category}: {value} out of ${total}`;

    //             // pieSeries.slices.template.stroke = am4core.color("#fff");
    //             // pieSeries.slices.template.strokeWidth = 2;
    //             // pieSeries.slices.template.strokeOpacity = 1;

    //             chart.legend = new am4charts.Legend();
    //             chart.legend.useDefaultMarker = true;
    //             chart.legend.valueLabels.template.disabled = true;
    //             var marker = chart.legend.markers.template.children.getIndex(0);
    //             marker.cornerRadius(12, 12, 12, 12);
    //             marker.strokeWidth = 2;
    //             marker.strokeOpacity = 1;
    //             marker.stroke = am4core.color("#ccc");

    //             // pieSeries.slices.template.stroke = am4core.color("#fff");
    //             // pieSeries.slices.template.strokeWidth = 2;
    //             // pieSeries.slices.template.strokeOpacity = 1;

    //             // This creates initial animation
    //             pieSeries.hiddenState.properties.opacity = 1;
    //             pieSeries.hiddenState.properties.endAngle = -90;
    //             pieSeries.hiddenState.properties.startAngle = -90;

    //             pieSeries.slices.template.events.on("hit", function (ev) {
    //                 setCountValue(0)
    //                 setCriticalityValue(ev.target.dataItem.category)
    //                 setDataCount(ev.target.dataItem._dataContext.litres
    //                 )
    //                 setTable(true)
    //                 setChartValue('exposedRisk')
    //                 const payload = [
    //                     authtoken,
    //                     {
    //                         "session_token": authtoken,
    //                         "request": [
    //                             "GetComplianceApplicabilityStatusDrillDown",
    //                             {
    //                                 "c_ids": [
    //                                     commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
    //                                 ],
    //                                 "d_ids": dIds,
    //                                 "filter_type": fieldType != "" ? fieldType : "Group",
    //                                 "filter_ids": [
    //                                     fieldType == "LegalEntity" ? filterData.legalentity :
    //                                         fieldType == 'Division' ? Number(filterData.division) :
    //                                             fieldType == 'Category' ? Number(filterData.category) :
    //                                                 fieldType == 'Unit' ? Number(filterData.unit) :
    //                                                     fieldType == 'BusinessGroup' ? Number(filterData.business) :
    //                                                         1
    //                                 ],
    //                                 "chart_year": year,
    //                                 "applicability_status": ev.target.dataItem.category,
    //                                 "record_count": 0,
    //                                 "le_ids":
    //                                     filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
    //                                 ,
    //                                 "country_domains": countryDomain
    //                             }
    //                         ]
    //                     }
    //                 ]
    //                 if (paramid != "") {
    //                     getRiskChartDelayedTableData({
    //                         payload: payload,
    //                         paramid: paramid
    //                     })
    //                 }

    //             })

    //         }

    //         addChart1([
    //             (exposedRiskData && exposedRiskData[0].delayed_compliance_count > 0 ?
    //                 {
    //                     "country": "Delayed Compliance",
    //                     "litres": exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].delayed_compliance_count,
    //                     "color": am4core.color("#3c78d8")
    //                 } : {}),
    //             (exposedRiskData && exposedRiskData[0].not_complied_count > 0 ?
    //                 {
    //                     "country": "Not Complied",
    //                     "litres": exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].not_complied_count,
    //                     "color": am4core.color("#fe4365")
    //                 } : {}
    //             )]);

    //         var title = container.createChild(am4core.Label)
    //         title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} (UTC)`
    //         title.disabled = ((fieldType != '') && (go == true)) ? true : false;
    //         title.align = 'left'
    //         title.paddingTop = '13%'
    //         title.height = '2%'
    //         title.paddingLeft = '-95%'

    //         // var title1 = container.createChild(am4core.Label)
    //         // title1.text =
    //         //     ((fieldType == "LegalEntity" && go == true && type === '') ? `Risk Chart of Legal Entity [bold]${filterDataNames.legalentity}[/]` : (
    //         //         (fieldType == "Division" && go == true && type === '') ? `Risk Chart of Division [bold]${filterDataNames.division}[/]` : (
    //         //             (fieldType == "Category" && go == true && type === '') ? `Risk Chart of Category [bold]${filterDataNames.category}[/]` : (
    //         //                 (fieldType == "BusinessGroup" && go == true && type === '') ? `Risk Chart of Business Group [bold]${filterDataNames.business}[/]` : (
    //         //                     (fieldType == "Unit" && go == true && type === '') ? `Risk Chart of Unit [bold]${filterDataNames.unit}[/]` : `Risk Chart of ${commonData && commonData.g_name}`
    //         //                 )))
    //         //     ))
    //         // title1.disabled = ((fieldType != '') && (go == true)) ? false : true;
    //         // // title1.align = 'center'
    //         // title1.paddingTop = '3%'
    //         // title1.paddingLeft = ((fieldType != '') && (go == true)) ? '3%' : '8%'
    //         var title1
    //         if (fieldType != '') {
    //             console.log('Mukilan222222');

    //             title1 = container.createChild(am4core.Label)
    //             title1.text =
    //                 ((fieldType == "LegalEntity" && go == true && type === '') ? `Risk Chart of Legal Entity [bold]${filterDataNames.legalentity}[/]` : (
    //                     (fieldType == "Division" && go == true && type === '') ? `Risk Chart of Division [bold]${filterDataNames.division}[/]` : (
    //                         (fieldType == "Category" && go == true && type === '') ? `Risk Chart of Category [bold]${filterDataNames.category}[/]` : (
    //                             (fieldType == "BusinessGroup" && go == true && type === '') ? `Risk Chart of Business Group [bold]${filterDataNames.business}[/]` : (
    //                                 (fieldType == "Unit" && go == true && type === '') ? `Risk Chart of Unit [bold]${filterDataNames.unit}[/]` : `Risk Chart of ${commonData && commonData.g_name}`
    //                             )))
    //                 ))
    //             title1.disabled = ((fieldType != '') && (go == true)) ? true : false;
    //             // title1.align = 'center'
    //             title1.paddingTop = '3%'
    //             title1.paddingLeft = ((fieldType != '') && (go == true)) ? '3%' : '8%'
    //             // title1.align = 'right'
    //             // // title1.align = 'center'
    //         }
    //     }

    // }, [exposedRiskData, hiddenRiskData, table, timeZone, timeZoneData, localStorage.getItem('chart')])

    useEffect(() => {
        if ((hiddenRiskData && hiddenRiskData.length > 0) && (exposedRiskData && exposedRiskData.length > 0)) {
            var container = am4core.create("container", am4core.Container);
            container.width = am4core.percent(100);
            container.height = am4core.percent(100);
            container.layout = "horizontal";
            container.background.fill = am4core.color("#000");
            container.background.fillOpacity = 0.1;
            container.background.stroke = am4core.color("#000");
            container.background.strokeOpacity = 0.2;
            container.background.strokeWidth = 2;


            if (noDataState === false) {

                var title3 = container.createChild(am4core.Label)
                title3.text = "Hidden Risk"
                title3.disabled = false;
                title3.align = 'left'
                title3.paddingTop = '22%'
                title3.paddingLeft = '19%'
                title3.fontWeight = 'bold'
                title3.fontSize = '14'


                var title4 = container.createChild(am4core.Label)
                title4.text = "Exposed Risk"
                title4.disabled = false;
                title4.align = 'right'
                title4.paddingTop = '22%'
                title4.paddingRight = '-69%'
                title4.paddingLeft = '35%'
                title4.fontWeight = 'bold'
                title4.fontSize = '14'


                var title2 = container.createChild(am4core.Label)
                title2.text = "Country-Domain"
                title2.disabled = false;
                title2.align = 'left'
                title2.paddingTop = '85%'
                title2.paddingLeft = '-25%'
                title2.fontWeight = 'bold'

                var title2 = container.createChild(am4core.Label)
                title2.text = `${textArray}`
                title2.disabled = false;
                title2.align = 'left'
                title2.paddingTop = '90%'
                title2.paddingLeft = '-25%'

                if (generatedOn === false) {
                    var title = container.createChild(am4core.Label)
                    title.text = `Generated on : ${time} (UTC)`
                    title.disabled = false;
                    title.align = 'left'
                    title.paddingTop = '13%'
                    title.paddingLeft = '-25%'
                }

                function addChart(data) {

                    let total = (hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_applicable_count) + (hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_opted_count) + (hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].unassign_count)


                    var chart = container.createChild(am4charts.PieChart3D);

                    // Add data
                    chart.data = data;
                    chart.width = am4core.percent(50);

                    chart.innerRadius = am4core.percent(35);
                    // chart.legend = new am4charts.Legend();
                    // chart.current = x;
                    // x.legend = new am4charts.Legend();
                    chart.height = 375
                    chart.paddingTop = '29%'
                    chart.paddingLeft = '-46%'

                    // Add and configure Series
                    var pieSeries = chart.series.push(new am4charts.PieSeries3D());
                    pieSeries.dataFields.value = "Count";
                    pieSeries.dataFields.category = "Status";
                    pieSeries.slices.template.propertyFields.fill = "color";
                    chart.current = chart;
                    // chart.height= 250
                    // chart.paddingTop = '20%'
                    chart.legend = new am4charts.Legend();
                    pieSeries.slices.template.tooltipText = `{category}: [bold]{value}[/] out of ${total} ({value.percent.formatNumber('#.00')}[/]%)`;
                    pieSeries.labels.template.text = "{category}: {value}";
                    chart.legend = new am4charts.Legend();
                    chart.legend.useDefaultMarker = true;
                    chart.legend.valueLabels.template.disabled = true;
                    var marker = chart.legend.markers.template.children.getIndex(0);
                    marker.cornerRadius(12, 12, 12, 12);
                    marker.strokeWidth = 2;
                    marker.strokeOpacity = 1;
                    marker.stroke = am4core.color("#ccc");


                    // pieSeries.slices.template.stroke = am4core.color("#fff");
                    // pieSeries.slices.template.strokeWidth = 2;
                    // pieSeries.slices.template.strokeOpacity = 1;

                    // This creates initial animation
                    pieSeries.hiddenState.properties.opacity = 1;
                    pieSeries.hiddenState.properties.endAngle = -90;
                    pieSeries.hiddenState.properties.startAngle = -90;


                    pieSeries.slices.template.events.on("hit", function (ev) {
                        setCountValue(0)
                        setCriticalityValue(ev.target.dataItem.category)
                        setDataCount(ev.target.dataItem._dataContext.Count
                        )
                        setTable(true)
                        setChartValue('hiddenRisk')
                        const payload = [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request": [
                                    "GetComplianceApplicabilityStatusDrillDown",
                                    {
                                        "c_ids": [
                                            commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                        ],
                                        "d_ids": dIds,
                                        "filter_type": fieldType != "" ? fieldType : "Group",
                                        "filter_ids": [
                                            fieldType == "LegalEntity" ? filterData.legalentity :
                                                fieldType == 'Division' ? Number(filterData.division) :
                                                    fieldType == 'Category' ? Number(filterData.category) :
                                                        fieldType == 'Unit' ? Number(filterData.unit) :
                                                            fieldType == 'BusinessGroup' ? Number(filterData.business) :
                                                                1
                                        ],
                                        "chart_year": year,
                                        "applicability_status": ev.target.dataItem.category,
                                        "record_count": 0,
                                        "le_ids":
                                            filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                                        ,
                                        "country_domains": countryDomain
                                    }
                                ]
                            }
                        ]
                        if (paramid != "") {
                            getRiskChartTableData({
                                payload: payload,
                                paramid: paramid
                            })
                        }
                    })

                    // chart.exporting.menu = new am4core.ExportMenu();
                    // chart.exporting.dataFields = {
                    //     "Status": "Status",
                    //     "Count": "Count"
                    // }
                    // chart.exporting.menu.items = [{
                    //     "label": "...",
                    //     "menu": [
                    //         {
                    //             "label": "Image",
                    //             "menu": [
                    //                 { "type": "png", "label": "PNG" },
                    //                 { "type": "jpg", "label": "JPG" },
                    //                 { "type": "svg", "label": "SVG" },
                    //                 { "type": "pdf", "label": "PDF" },
                    //                 { "type": "gif", "label": "GIF" }
                    //             ]
                    //         }, {
                    //             "label": "Data",
                    //             "menu": [
                    //                 { "type": "csv", "label": "CSV" },
                    //                 { "type": "xlsx", "label": "XLSX" },
                    //             ]
                    //         }, {
                    //             "label": "Print", "type": "print"
                    //         }
                    //     ]
                    // }];
                    // chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";


                }




                addChart([
                    (hiddenRiskData && hiddenRiskData[0].not_applicable_count > 0 ?
                        {
                            "Status": "Not Applicable",
                            "Count": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_applicable_count,
                            "color": am4core.color("#808080")
                        } : {}),
                    (hiddenRiskData && hiddenRiskData[0].not_opted_count > 0 ?
                        {
                            "Status": "Not Opted",
                            "Count": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_opted_count,
                            "color": am4core.color("#dd070c")
                        } : {}),
                    (hiddenRiskData && hiddenRiskData[0].unassign_count > 0 ?
                        {
                            "Status": "Unassigned",
                            "Count": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].unassign_count,
                            "color": am4core.color("#ff9c80")
                        } : {})]);

                function addChart1(data1) {

                    let total = (exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].delayed_compliance_count) + (exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].not_complied_count)

                    var chart = container.createChild(am4charts.PieChart3D);

                    // Add data
                    chart.data = data1;
                    chart.height = 375
                    chart.paddingTop = '29%'
                    chart.width = am4core.percent(50);

                    chart.paddingLeft = '-33%'
                    chart.innerRadius = am4core.percent(35);

                    // Add and configure Series
                    var pieSeries = chart.series.push(new am4charts.PieSeries3D());
                    pieSeries.dataFields.value = "Count";
                    pieSeries.dataFields.category = "Status";
                    pieSeries.slices.template.propertyFields.fill = "color";
                    chart.current = chart;
                    chart.legend = new am4charts.Legend();
                    // pieSeries.slices.template.tooltipText = `{category}: {value} out of ${total}`;
                    pieSeries.slices.template.tooltipText = `{category}: [bold]{value}[/] out of ${total} ({value.percent.formatNumber('#.00')}[/]%)`;
                    pieSeries.labels.template.text = "{category}: {value}";
                    // pieSeries.slices.template.stroke = am4core.color("#fff");
                    // pieSeries.slices.template.strokeWidth = 2;
                    // pieSeries.slices.template.strokeOpacity = 1;

                    chart.legend = new am4charts.Legend();
                    chart.legend.useDefaultMarker = true;
                    chart.legend.valueLabels.template.disabled = true;
                    var marker = chart.legend.markers.template.children.getIndex(0);
                    marker.cornerRadius(12, 12, 12, 12);
                    marker.strokeWidth = 2;
                    marker.strokeOpacity = 1;
                    marker.stroke = am4core.color("#ccc");

                    // pieSeries.slices.template.stroke = am4core.color("#fff");
                    // pieSeries.slices.template.strokeWidth = 2;
                    // pieSeries.slices.template.strokeOpacity = 1;

                    // This creates initial animation
                    pieSeries.hiddenState.properties.opacity = 1;
                    pieSeries.hiddenState.properties.endAngle = -90;
                    pieSeries.hiddenState.properties.startAngle = -90;

                    pieSeries.slices.template.events.on("hit", function (ev) {
                        setCountValue(0)
                        setCriticalityValue(ev.target.dataItem.category)
                        setDataCount(ev.target.dataItem._dataContext.Count
                        )
                        setTable(true)
                        setChartValue('exposedRisk')
                        const payload = [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request": [
                                    "GetComplianceApplicabilityStatusDrillDown",
                                    {
                                        "c_ids": [
                                            commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                        ],
                                        "d_ids": dIds,
                                        "filter_type": fieldType != "" ? fieldType : "Group",
                                        "filter_ids": [
                                            fieldType == "LegalEntity" ? filterData.legalentity :
                                                fieldType == 'Division' ? Number(filterData.division) :
                                                    fieldType == 'Category' ? Number(filterData.category) :
                                                        fieldType == 'Unit' ? Number(filterData.unit) :
                                                            fieldType == 'BusinessGroup' ? Number(filterData.business) :
                                                                1
                                        ],
                                        "chart_year": year,
                                        "applicability_status": ev.target.dataItem.category,
                                        "record_count": 0,
                                        "le_ids":
                                            filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                                        ,
                                        "country_domains": countryDomain
                                    }
                                ]
                            }
                        ]
                        if (paramid != "") {
                            getRiskChartDelayedTableData({
                                payload: payload,
                                paramid: paramid
                            })
                        }

                    })


                    // chart.exporting.menu = new am4core.ExportMenu();
                    // chart.exporting.extraSprites.push({
                    //     // "marginLeft": 20,
                    //     // "position": "right",
                    //     "sprite": hiddenRiskData
                    //   });
                    // chart.exporting.dataFields = {
                    //     "Status": "Status",
                    //     "Count": "Count"
                    // }
                    // chart.exporting.menu.items = [{
                    //     "label": "...",
                    //     "menu": [
                    //         {
                    //             "label": "Image",
                    //             "menu": [
                    //                 { "type": "png", "label": "PNG" },
                    //                 { "type": "jpg", "label": "JPG" },
                    //                 { "type": "svg", "label": "SVG" },
                    //                 { "type": "pdf", "label": "PDF" },
                    //                 { "type": "gif", "label": "GIF" }
                    //             ]
                    //         }, {
                    //             "label": "Data",
                    //             "menu": [
                    //                 { "type": "csv", "label": "CSV" },
                    //                 { "type": "xlsx", "label": "XLSX" },
                    //             ]
                    //         }, {
                    //             "label": "Print", "type": "print"
                    //         }
                    //     ]
                    // }];
                    // chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";

                }


                addChart1([
                    (exposedRiskData && exposedRiskData[0].delayed_compliance_count > 0 ?
                        {
                            "Status": "Delayed Compliance",
                            "Count": exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].delayed_compliance_count,
                            "color": am4core.color("#3c78d8")
                        } : {}),
                    (exposedRiskData && exposedRiskData[0].not_complied_count > 0 ?
                        {
                            "Status": "Not Complied",
                            "Count": exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].not_complied_count,
                            "color": am4core.color("#fe4365")
                        } : {}
                    )]);

                // container.dataItem = hiddenRiskData
                // container.exporting.menu = new am4core.ExportMenu();
                // // container.exporting.data = hiddenRiskData
                // container.exporting.validateSprites = hiddenRiskData
                // container.exporting.dataFields = {
                //     "Status": "Status",
                //     "Count": "Count"
                // }
                // container.exporting.menu.items = [{
                //     "label": "...",
                //     "menu": [
                //         {
                //             "label": "Image",
                //             "menu": [
                //                 { "type": "png", "label": "PNG" },
                //                 { "type": "jpg", "label": "JPG" },
                //                 { "type": "svg", "label": "SVG" },
                //                 { "type": "pdf", "label": "PDF" },
                //                 { "type": "gif", "label": "GIF" }
                //             ]
                //         }, {
                //             "label": "Data",
                //             "menu": [
                //                 { "type": "csv", "label": "CSV" },
                //                 { "type": "xlsx", "label": "XLSX" },
                //             ]
                //         }, {
                //             "label": "Print", "type": "print"
                //         }
                //     ]
                // }];
                // container.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";

                if (generatedOn === false) {

                    var title1 = container.createChild(am4core.Label)
                    title1.text =
                        ((fieldType == "LegalEntity" && go == true && type === '') ? `Risk Chart of Legal Entity [bold]${filterDataNames.legalentity}(${year})[/]` : (
                            (fieldType == "Division" && go == true && type === '') ? `Risk Chart of Division [bold]${filterDataNames.division}(${year})[/]` : (
                                (fieldType == "Category" && go == true && type === '') ? `Risk Chart of Category [bold]${filterDataNames.category}(${year})[/]` : (
                                    (fieldType == "BusinessGroup" && go == true && type === '') ? `Risk Chart of Business Group [bold]${filterDataNames.business}(${year})[/]` : (
                                        (fieldType == "Unit" && go == true && type === '') ? `Risk Chart of Unit [bold]${filterDataNames.unit}(${year})[/]` : `Risk Chart of ${commonData && commonData.g_name} (${year})`
                                    )))
                        ))
                    title1.disabled = false;
                    title1.paddingLeft = (fieldType == "LegalEntity" && go == true && type === '') ? '-9%' : '10%'
                    // title1.align = 'center'
                    title1.paddingTop = '3%'
                    title1.fontSize = '17px'
                    title1.fontWeight = '400'
                    title1.paddingLeft = ((fieldType != '') && (go === true)) ? '-73%' : '-60%'
                    // title1.align = 'right'
                    // // title1.align = 'center'
                }

                if (generatedOn === true) {
                    var title = container.createChild(am4core.Label)
                    title.text = `Generated on : ${timeZoneData.tz_name} ${timeZone}`
                    title.disabled = false;
                    title.align = 'left'
                    title.paddingTop = '13%'
                    title.paddingLeft = '-97%'
                }

                if (generatedOn === true) {

                    var title1 = container.createChild(am4core.Label)
                    title1.text =
                        ((fieldType == "LegalEntity" && go == true && type === '') ? `Risk Chart of Legal Entity [bold]${filterDataNames.legalentity}[/]` : (
                            (fieldType == "Division" && go == true && type === '') ? `Risk Chart of Division [bold]${filterDataNames.division}[/]` : (
                                (fieldType == "Category" && go == true && type === '') ? `Risk Chart of Category [bold]${filterDataNames.category}[/]` : (
                                    (fieldType == "BusinessGroup" && go == true && type === '') ? `Risk Chart of Business Group [bold]${filterDataNames.business}[/]` : (
                                        (fieldType == "Unit" && go == true && type === '') ? `Risk Chart of Unit [bold]${filterDataNames.unit}[/]` : `Risk Chart of ${commonData && commonData.g_name}`
                                    )))
                        ))
                    title1.disabled = false;
                    title1.paddingLeft = (fieldType == "LegalEntity" && go == true && type === '') ? '-9%' : '10%'
                    // title1.align = 'center'
                    title1.paddingTop = '3%'
                    title1.fontSize = '17px'
                    title1.fontWeight = '400'
                    title1.paddingLeft = ((fieldType != '') && (go === true)) ? '-65%' : '-60%'
                }

            }
            else {
                var titleData = container.createChild(am4core.Label)
                titleData.text = "No Data to Display"
                titleData.disabled = false;
                titleData.align = 'left'
                titleData.paddingTop = '45%'
                titleData.paddingLeft = '43%'
                // title3.fontWeight = '500'
                titleData.fontSize = '16'
            }



        }
    }, [exposedRiskData, hiddenRiskData, noDataState, table, timeZone, timeZoneData, localStorage.getItem('chart')])

    console.log(noDataState, 'noDataState');


    //Risk Chart New 

    useEffect(() => {
        if ((hiddenRiskData && hiddenRiskData.length > 0) && (exposedRiskData && exposedRiskData.length > 0)) {
            if (noDataState === false) {

                am4core.useTheme(am4themes_animated);

                let container = am4core.create("chartdiv", am4core.Container);
                container.width = am4core.percent(100);
                container.height = am4core.percent(100);
                container.layout = "horizontal";



                // Create the charts in it
                let chart1 = container.createChild(am4charts.PieChart3D);

                chart1.data = [
                    (hiddenRiskData && hiddenRiskData[0].not_applicable_count > 0 ?
                        {
                            "Status": "Not Applicable",
                            "Count": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_applicable_count,
                            "color": am4core.color("#808080")
                        } : {}),
                    (hiddenRiskData && hiddenRiskData[0].not_opted_count > 0 ?
                        {
                            "Status": "Not Opted",
                            "Count": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_opted_count,
                            "color": am4core.color("#dd070c")
                        } : {}),
                    (hiddenRiskData && hiddenRiskData[0].unassign_count > 0 ?
                        {
                            "Status": "Unassigned",
                            "Count": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].unassign_count,
                            "color": am4core.color("#ff9c80")
                        } : {})];
                // Set inner radius
                chart1.innerRadius = am4core.percent(35);
                // chart.height=200
                // chart.legend = new am4charts.Legend();
                // chart.current = x;
                // x.legend = new am4charts.Legend();

                // Add and configure Series
                var pieSeries = chart1.series.push(new am4charts.PieSeries3D());
                pieSeries.dataFields.value = "Count";
                pieSeries.dataFields.category = "Status";
                pieSeries.slices.template.propertyFields.fill = "color";
                chart1.current = chart1;
                chart1.height = 320
                // chart.paddingTop = '20%'
                chart1.legend = new am4charts.Legend();
                // pieSeries.slices.template.tooltipText = `{category}: [bold]{value}[/] out of ${total} ({value.percent.formatNumber('#.00')}[/]%)`;
                pieSeries.labels.template.text = "{category}: {value}";
                chart1.legend = new am4charts.Legend();
                chart1.legend.useDefaultMarker = true;
                chart1.legend.valueLabels.template.disabled = true;
                var marker = chart1.legend.markers.template.children.getIndex(0);
                marker.cornerRadius(12, 12, 12, 12);
                marker.strokeWidth = 2;
                marker.strokeOpacity = 1;
                marker.stroke = am4core.color("#ccc");

                pieSeries.slices.template.events.on("hit", function (ev) {
                    setCountValue(0)
                    setCriticalityValue(ev.target.dataItem.category)
                    setDataCount(ev.target.dataItem._dataContext.Count
                    )
                    setTable(true)
                    setChartValue('hiddenRisk')
                    const payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetComplianceApplicabilityStatusDrillDown",
                                {
                                    "c_ids": [
                                        commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                    ],
                                    "d_ids": dIds,
                                    "filter_type": fieldType != "" ? fieldType : "Group",
                                    "filter_ids": [
                                        fieldType == "LegalEntity" ? filterData.legalentity :
                                            fieldType == 'Division' ? Number(filterData.division) :
                                                fieldType == 'Category' ? Number(filterData.category) :
                                                    fieldType == 'Unit' ? Number(filterData.unit) :
                                                        fieldType == 'BusinessGroup' ? Number(filterData.business) :
                                                            1
                                    ],
                                    "chart_year": year,
                                    "applicability_status": ev.target.dataItem.category,
                                    "record_count": 0,
                                    "le_ids":
                                        filterData && filterData.leIds ? [filterData.leIds] : filterData.legalentity ? filterData.legalentity : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                                    ,
                                    "country_domains": countryDomain
                                }
                            ]
                        }
                    ]
                    if (paramid != "") {
                        getRiskChartTableData({
                            payload: payload,
                            paramid: paramid
                        })
                    }
                })


                // pieSeries.slices.template.stroke = am4core.color("#fff");
                // pieSeries.slices.template.strokeWidth = 2;
                // pieSeries.slices.template.strokeOpacity = 1;

                // This creates initial animation
                pieSeries.hiddenState.properties.opacity = 1;
                pieSeries.hiddenState.properties.endAngle = -90;
                pieSeries.hiddenState.properties.startAngle = -90;



                var label = chart1.createChild(am4core.Label);
                label.text = "Country-Domain";
                label.fontSize = 14;
                label.align = "left";
                label.marginLeft = '5%';
                label.marginBottom = '2%'
                label.fontWeight = 'bold'
                label.marginTop = '5%'




                var label1 = chart1.createChild(am4core.Label);
                label1.text = `${textArray}`
                // label1.text = ['hiii','hello']
                label1.fontSize = 14;
                label1.align = "left";
                label1.marginLeft = '5%';
                label1.marginBottom = '5%';
                // label1.marginTop = '25%'

                let chart = container.createChild(am4charts.PieChart3D);

                // var title1 = chart.createChild(am4core.Label);
                // title1.text =
                //     ((fieldType == "LegalEntity" && go == true && type === '') ? `Over due Compliance of Legal Entity [bold]${filterDataNames.legalentity}[/]` : (
                //         (fieldType == "Division" && go == true && type === '') ? `Over due Compliance of Division [bold]${filterDataNames.division}[/]` : (
                //             (fieldType == "Category" && go == true && type === '') ? `Over due Compliance of Category [bold]${filterDataNames.category}[/]` : (
                //                 (fieldType == "BusinessGroup" && go == true && type === '') ? `Over due Compliance of Category [bold]${filterDataNames.business}[/]` : (
                //                     (fieldType == "Unit" && go == true && type === '') ? `Over due Compliance of Unit [bold]${filterDataNames.unit}[/]` : `Over due Compliance of ${commonData && commonData.g_name}`
                //                 )))
                //     ))
                // title1.disabled = false;
                // title1.align = 'to'
                // // title1.marginBottom = '90%'
                // title1.marginLeft = '2%'
                // title1.fontSize = '17px'
                // title1.fontWeight = '400'

                if ((exposedRiskData && exposedRiskData[0].delayed_compliance_count === 0) && (exposedRiskData && exposedRiskData[0].not_complied_count === 0)) {
                    console.log('helloooo');
                    var noData = chart.createChild(am4core.Label);
                    noData.text = "No Data to Display"
                    // label1.text = ['hiii','hello']
                    noData.fontSize = 14;
                    noData.align = "left";
                    noData.marginLeft = '37%';
                    noData.marginBottom = '75%';
                }
                else {

                    chart.data = [
                        (exposedRiskData && exposedRiskData[0].delayed_compliance_count > 0 ?
                            {
                                "Status": "Delayed Compliance",
                                "Count": exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].delayed_compliance_count,
                                "color": am4core.color("#3c78d8")
                            } : {}),
                        (exposedRiskData && exposedRiskData[0].not_complied_count > 0 ?
                            {
                                "Status": "Not Complied",
                                "Count": exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].not_complied_count,
                                "color": am4core.color("#fe4365")
                            } : {}
                        )

                    ];
                    // Set inner radius
                    chart.innerRadius = am4core.percent(35);
                    chart.height = 250
                    chart.marginRight = '5%'

                    // Add and configure Series
                    var pieSeries = chart.series.push(new am4charts.PieSeries3D());
                    pieSeries.dataFields.value = "Count";
                    pieSeries.dataFields.category = "Status";
                    pieSeries.slices.template.propertyFields.fill = "color";
                    chart.current = chart;
                    chart.legend = new am4charts.Legend();
                    // pieSeries.slices.template.tooltipText = `{category}: {value} out of ${total}`;
                    // pieSeries.slices.template.tooltipText = `{category}: [bold]{value}[/] out of ${total} ({value.percent.formatNumber('#.00')}[/]%)`;
                    pieSeries.labels.template.text = "{category}: {value}";
                    // pieSeries.slices.template.stroke = am4core.color("#fff");
                    // pieSeries.slices.template.strokeWidth = 2;
                    // pieSeries.slices.template.strokeOpacity = 1;

                    pieSeries.slices.template.events.on("hit", function (ev) {
                        setCountValue(0)
                        setCriticalityValue(ev.target.dataItem.category)
                        setDataCount(ev.target.dataItem._dataContext.Count
                        )
                        setTable(true)
                        setChartValue('exposedRisk')
                        const payload = [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request": [
                                    "GetComplianceApplicabilityStatusDrillDown",
                                    {
                                        "c_ids": [
                                            commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                        ],
                                        "d_ids": dIds,
                                        "filter_type": fieldType != "" ? fieldType : "Group",
                                        "filter_ids": [
                                            fieldType == "LegalEntity" ? filterData.legalentity :
                                                fieldType == 'Division' ? Number(filterData.division) :
                                                    fieldType == 'Category' ? Number(filterData.category) :
                                                        fieldType == 'Unit' ? Number(filterData.unit) :
                                                            fieldType == 'BusinessGroup' ? Number(filterData.business) :
                                                                1
                                        ],
                                        "chart_year": year,
                                        "applicability_status": ev.target.dataItem.category,
                                        "record_count": 0,
                                        "le_ids":
                                            filterData && filterData.leIds ? [filterData.leIds] : filterData.legalentity ? filterData.legalentity : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                                        ,
                                        "country_domains": countryDomain
                                    }
                                ]
                            }
                        ]
                        if (paramid != "") {
                            getRiskChartDelayedTableData({
                                payload: payload,
                                paramid: paramid
                            })
                        }

                    })

                    chart.legend = new am4charts.Legend();
                    chart.legend.useDefaultMarker = true;
                    chart.legend.valueLabels.template.disabled = true;
                    var marker = chart.legend.markers.template.children.getIndex(0);
                    marker.cornerRadius(12, 12, 12, 12);
                    marker.strokeWidth = 2;
                    marker.strokeOpacity = 1;
                    marker.stroke = am4core.color("#ccc");

                    // pieSeries.slices.template.stroke = am4core.color("#fff");
                    // pieSeries.slices.template.strokeWidth = 2;
                    // pieSeries.slices.template.strokeOpacity = 1;

                    // This creates initial animation
                    pieSeries.hiddenState.properties.opacity = 1;
                    pieSeries.hiddenState.properties.endAngle = -90;
                    pieSeries.hiddenState.properties.startAngle = -90;

                }


                // chart.exporting.menu = new am4core.ExportMenu();
                // chart.exporting.adapter.add("data", function (data, target) {
                //     // Assemble data from series
                //     var data = [];
                //     chart.series.each(function (series) {
                //         for (var i = 0; i < series.data.length; i++) {
                //             series.data[i].name = series.name;
                //             data.push(series.data[i]);
                //         }
                //     });
                //     console.log(data);
                //     return { data: data };
                // });



                container.exporting.menu = new am4core.ExportMenu();
                container.exporting.filePrefix = "Exported Data";

                container.exporting.data = [
                    (exposedRiskData && exposedRiskData[0].delayed_compliance_count > 0 ?
                        {
                            "Status": "Delayed Compliance",
                            "Count": exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].delayed_compliance_count,
                            "color": am4core.color("#3c78d8")
                        } : {}),
                    (exposedRiskData && exposedRiskData[0].not_complied_count > 0 ?
                        {
                            "Status": "Not Complied",
                            "Count": exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].not_complied_count,
                            "color": am4core.color("#fe4365")
                        } : {}
                    ),
                    (hiddenRiskData && hiddenRiskData[0].not_opted_count > 0 ?
                        {
                            "Status": "Not Opted",
                            "Count": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_opted_count,
                            "color": am4core.color("#dd070c")
                        } : {}),
                    (hiddenRiskData && hiddenRiskData[0].unassign_count > 0 ?
                        {
                            "Status": "Unassigned",
                            "Count": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].unassign_count,
                            "color": am4core.color("#ff9c80")
                        } : {}),
                    (hiddenRiskData && hiddenRiskData[0].not_applicable_count > 0 ?
                        {
                            "Status": "Not Applicable",
                            "Count": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_applicable_count,
                            "color": am4core.color("#808080")
                        } : {})
                ]
                container.exporting.timeoutDelay = 10000;


                // chart.exporting.extraSprites.push({
                //     "sprite":chart1
                // });

                console.log(container.exporting.menu.labelTag, 'container.exporting.menu.labelTag');

                container.exporting.dataFields = {
                    "Status": "Status",
                    "Count": "Count",
                    // "sprite": chart1
                }
                container.exporting.menu.items = [{
                    "label": "...",
                    "menu": [
                        {
                            "label": "Image",
                            "menu": [
                                { "type": "png", "label": "PNG" },
                                { "type": "jpg", "label": "JPG" },
                                { "type": "svg", "label": "SVG" },
                                { "type": "pdf", "label": "PDF" },
                                { "type": "gif", "label": "GIF" }
                            ]
                        }, {
                            "label": "Data",
                            "menuReviver": function (cfg, li) {
                                console.log(cfg, 'cfg');
                                console.log(li, 'li');
                            },
                            "menu": [
                                { "type": "csv", "label": "CSV" },
                                { "type": "xlsx", "label": "XLSX" },
                            ]
                        }, {
                            "label": "Print", "type": "print"
                        }
                    ],

                }];


                container.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";

                // var title = container.createChild(am4core.Label);
                // title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} (UTC)`
                // title.disabled = false;
                // title.align = 'left'
                // title.marginTop = '2%'
                // // title.marginLeft = '1%'

                // var title1 = container.createChild(am4core.Label);
                // title1.text =
                //     ((fieldType == "LegalEntity" && go == true && type === '') ? `Over due Compliance of Legal Entity [bold]${filterDataNames.legalentity}[/]` : (
                //         (fieldType == "Division" && go == true && type === '') ? `Over due Compliance of Division [bold]${filterDataNames.division}[/]` : (
                //             (fieldType == "Category" && go == true && type === '') ? `Over due Compliance of Category [bold]${filterDataNames.category}[/]` : (
                //                 (fieldType == "BusinessGroup" && go == true && type === '') ? `Over due Compliance of Category [bold]${filterDataNames.business}[/]` : (
                //                     (fieldType == "Unit" && go == true && type === '') ? `Over due Compliance of Unit [bold]${filterDataNames.unit}[/]` : `Over due Compliance of ${commonData && commonData.g_name}`
                //                 )))
                //     ))
                // title1.disabled = false;
                // title1.align = 'center'
                // // title1.marginTop = '2%'
                // title1.marginLeft = '2%'
                // title1.fontSize = '17px'
                // title1.fontWeight = '400'

                // var label = container.createChild(am4core.Label);
                // label.text = "Country-Domain";
                // label.fontSize = 14;
                // label.align = "left";
                // // label.marginLeft = '1%';
                // label.marginBottom = '2%'
                // label.fontWeight = 'bold'

                // var label1 = container.createChild(am4core.Label);
                // label1.text = `${textArray}`
                // // label1.text = ['hiii','hello']
                // label1.fontSize = 14;
                // label1.align = "left";
                // // label1.marginLeft = '1%';
                // label1.marginBottom = '5%'
            }


        }

    }, [exposedRiskData, hiddenRiskData, noDataState, table, timeZone, timeZoneData, localStorage.getItem('chart')])

    useEffect(() => {
        if (noDataState === true) {
            am4core.useTheme(am4themes_animated);
            var Compliancechart = am4core.create("nodata", am4charts.XYChart);
            var title3 = Compliancechart.createChild(am4core.Label)
            title3.text = "No Data to Display"
            title3.disabled = false;
            title3.align = 'left'
            title3.marginTop = '10%'
            title3.paddingLeft = '43%'
            title3.marginBottom = '50%'
            // title3.fontWeight = '500'
            title3.fontSize = '16'
        }

    }, [noDataState])

    useEffect(() => {
        if ((hiddenRiskData && hiddenRiskData.length > 0) && (exposedRiskData && exposedRiskData.length > 0)) {
            am4core.useTheme(am4themes_animated);

            //Hidden Risk

            var chart1 = am4core.create("Riskchartdiv", am4charts.PieChart3D);
            let total = (hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_applicable_count) + (hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_opted_count) + (hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].unassign_count)


            var title = chart1.titles.create();
            title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} (UTC)`
            title.disabled = false;
            title.align = 'left'
            title.marginTop = '-5%'
            // Add data
            chart1.data = [
                (hiddenRiskData && hiddenRiskData[0].not_applicable_count > 0 ?
                    {
                        "Status": "Not Applicable",
                        "Count": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_applicable_count,
                        "color": am4core.color("#808080")
                    } : {}),
                (hiddenRiskData && hiddenRiskData[0].not_opted_count > 0 ?
                    {
                        "Status": "Not Opted",
                        "Count": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].not_opted_count,
                        "color": am4core.color("#dd070c")
                    } : {}),
                (hiddenRiskData && hiddenRiskData[0].unassign_count > 0 ?
                    {
                        "Status": "Unassigned",
                        "Count": hiddenRiskData && hiddenRiskData.length > 0 && hiddenRiskData[0].unassign_count,
                        "color": am4core.color("#ff9c80")
                    } : {})];
            // Set inner radius
            chart1.innerRadius = am4core.percent(35);
            // chart.height=200
            // chart.legend = new am4charts.Legend();
            // chart.current = x;
            // x.legend = new am4charts.Legend();

            // Add and configure Series
            var pieSeries = chart1.series.push(new am4charts.PieSeries3D());
            pieSeries.dataFields.value = "Count";
            pieSeries.dataFields.category = "Status";
            pieSeries.slices.template.propertyFields.fill = "color";
            chart1.current = chart1;
            // chart.height= 250
            // chart.paddingTop = '20%'
            chart1.legend = new am4charts.Legend();
            pieSeries.slices.template.tooltipText = `{category}: [bold]{value}[/] out of ${total} ({value.percent.formatNumber('#.00')}[/]%)`;
            pieSeries.labels.template.text = "{category}: {value}";
            chart1.legend = new am4charts.Legend();
            chart1.legend.useDefaultMarker = true;
            chart1.legend.valueLabels.template.disabled = true;
            var marker = chart1.legend.markers.template.children.getIndex(0);
            marker.cornerRadius(12, 12, 12, 12);
            marker.strokeWidth = 2;
            marker.strokeOpacity = 1;
            marker.stroke = am4core.color("#ccc");


            // pieSeries.slices.template.stroke = am4core.color("#fff");
            // pieSeries.slices.template.strokeWidth = 2;
            // pieSeries.slices.template.strokeOpacity = 1;

            // This creates initial animation
            pieSeries.hiddenState.properties.opacity = 1;
            pieSeries.hiddenState.properties.endAngle = -90;
            pieSeries.hiddenState.properties.startAngle = -90;


            pieSeries.slices.template.events.on("hit", function (ev) {
                setCountValue(0)
                setCriticalityValue(ev.target.dataItem.category)
                setDataCount(ev.target.dataItem._dataContext.Count
                )
                setTable(true)
                setChartValue('hiddenRisk')
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetComplianceApplicabilityStatusDrillDown",
                            {
                                "c_ids": [
                                    commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                ],
                                "d_ids": dIds,
                                "filter_type": fieldType != "" ? fieldType : "Group",
                                "filter_ids": [
                                    fieldType == "LegalEntity" ? filterData.legalentity :
                                        fieldType == 'Division' ? Number(filterData.division) :
                                            fieldType == 'Category' ? Number(filterData.category) :
                                                fieldType == 'Unit' ? Number(filterData.unit) :
                                                    fieldType == 'BusinessGroup' ? Number(filterData.business) :
                                                        1
                                ],
                                "chart_year": year,
                                "applicability_status": ev.target.dataItem.category,
                                "record_count": 0,
                                "le_ids":
                                    filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                                ,
                                "country_domains": countryDomain
                            }
                        ]
                    }
                ]
                if (paramid != "") {
                    getRiskChartTableData({
                        payload: payload,
                        paramid: paramid
                    })
                }
            })

            // title.marginLeft = '1%'

            // var title1 = chart1.titles.create();
            // title1.text =
            //     ((fieldType == "LegalEntity" && go == true && type === '') ? `Over due Compliance of Legal Entity [bold]${filterDataNames.legalentity}[/]` : (
            //         (fieldType == "Division" && go == true && type === '') ? `Over due Compliance of Division [bold]${filterDataNames.division}[/]` : (
            //             (fieldType == "Category" && go == true && type === '') ? `Over due Compliance of Category [bold]${filterDataNames.category}[/]` : (
            //                 (fieldType == "BusinessGroup" && go == true && type === '') ? `Over due Compliance of Category [bold]${filterDataNames.business}[/]` : (
            //                     (fieldType == "Unit" && go == true && type === '') ? `Over due Compliance of Unit [bold]${filterDataNames.unit}[/]` : `Over due Compliance of ${commonData && commonData.g_name}`
            //                 )))
            //     ))
            // title1.disabled = false;
            // title1.align = 'center'
            // // title1.marginTop = '2%'
            // title1.marginLeft = '90%'
            // title1.fontSize = '17px'
            // title1.fontWeight = '400'

            var title = chart1.titles.create();
            title.text = generatedOn == true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} (UTC)`
            title.disabled = false;
            title.align = 'left'
            title.marginTop = '2%'

            var label = chart1.createChild(am4core.Label);
            label.text = "Country-Domain";
            label.fontSize = 14;
            label.align = "left";
            // label.marginLeft = '1%';
            label.marginBottom = '2%'
            label.fontWeight = 'bold'
            label.marginTop = '8%'




            var label1 = chart1.createChild(am4core.Label);
            label1.text = `${textArray}`
            // label1.text = ['hiii','hello']
            label1.fontSize = 14;
            label1.align = "left";
            // label1.marginLeft = '1%';
            label1.marginBottom = '5%'

            //Exposed Risk

            var chart = am4core.create("ExposedRiskchartdiv", am4charts.PieChart3D);
            let total1 = (exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].delayed_compliance_count) + (exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].not_complied_count)
            // Add data
            chart.data = [
                (exposedRiskData && exposedRiskData[0].delayed_compliance_count > 0 ?
                    {
                        "Status": "Delayed Compliance",
                        "Count": exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].delayed_compliance_count,
                        "color": am4core.color("#3c78d8")
                    } : {}),
                (exposedRiskData && exposedRiskData[0].not_complied_count > 0 ?
                    {
                        "Status": "Not Complied",
                        "Count": exposedRiskData && exposedRiskData.length > 0 && exposedRiskData[0].not_complied_count,
                        "color": am4core.color("#fe4365")
                    } : {}
                )];
            // Set inner radius
            chart.innerRadius = am4core.percent(35);

            // Add and configure Series
            var pieSeries = chart.series.push(new am4charts.PieSeries3D());
            pieSeries.dataFields.value = "Count";
            pieSeries.dataFields.category = "Status";
            pieSeries.slices.template.propertyFields.fill = "color";
            chart.current = chart;
            chart.legend = new am4charts.Legend();
            // pieSeries.slices.template.tooltipText = `{category}: {value} out of ${total}`;
            pieSeries.slices.template.tooltipText = `{category}: [bold]{value}[/] out of ${total} ({value.percent.formatNumber('#.00')}[/]%)`;
            pieSeries.labels.template.text = "{category}: {value}";
            // pieSeries.slices.template.stroke = am4core.color("#fff");
            // pieSeries.slices.template.strokeWidth = 2;
            // pieSeries.slices.template.strokeOpacity = 1;

            chart.legend = new am4charts.Legend();
            chart.legend.useDefaultMarker = true;
            chart.legend.valueLabels.template.disabled = true;
            var marker = chart.legend.markers.template.children.getIndex(0);
            marker.cornerRadius(12, 12, 12, 12);
            marker.strokeWidth = 2;
            marker.strokeOpacity = 1;
            marker.stroke = am4core.color("#ccc");

            // pieSeries.slices.template.stroke = am4core.color("#fff");
            // pieSeries.slices.template.strokeWidth = 2;
            // pieSeries.slices.template.strokeOpacity = 1;

            // This creates initial animation
            pieSeries.hiddenState.properties.opacity = 1;
            pieSeries.hiddenState.properties.endAngle = -90;
            pieSeries.hiddenState.properties.startAngle = -90;

            pieSeries.slices.template.events.on("hit", function (ev) {
                setCountValue(0)
                setCriticalityValue(ev.target.dataItem.category)
                setDataCount(ev.target.dataItem._dataContext.Count
                )
                setTable(true)
                setChartValue('exposedRisk')
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetComplianceApplicabilityStatusDrillDown",
                            {
                                "c_ids": [
                                    commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                ],
                                "d_ids": dIds,
                                "filter_type": fieldType != "" ? fieldType : "Group",
                                "filter_ids": [
                                    fieldType == "LegalEntity" ? filterData.legalentity :
                                        fieldType == 'Division' ? Number(filterData.division) :
                                            fieldType == 'Category' ? Number(filterData.category) :
                                                fieldType == 'Unit' ? Number(filterData.unit) :
                                                    fieldType == 'BusinessGroup' ? Number(filterData.business) :
                                                        1
                                ],
                                "chart_year": year,
                                "applicability_status": ev.target.dataItem.category,
                                "record_count": 0,
                                "le_ids":
                                    filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                                ,
                                "country_domains": countryDomain
                            }
                        ]
                    }
                ]
                if (paramid != "") {
                    getRiskChartDelayedTableData({
                        payload: payload,
                        paramid: paramid
                    })
                }

            })
        }

    }, [exposedRiskData, hiddenRiskData, noDataState, table, timeZone, timeZoneData, localStorage.getItem('chart')])

    const homescreen = () => {
        historynew.push(homepage)
        // window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }

    useEffect(() => {
        if (type === 'Group')
            setGroupCheckBox(checkBox)
    }, [type, checkBox])

    useEffect(() => {
        if (tableData) {
            setCriticalityTable(tableData.r_drill_down_data)
        }

    }, [tableData])

    useEffect(() => {
        if (tableData1 && tableData1.length > 0) {
            let sortedArray = _.orderBy(tableData1, ['le_name'], ['asc'])
            setDelayedChart(sortedArray)
        }
    }, [tableData1])
    console.log(criticalityTable, 'criticalityTable');
    console.log(showMoreData.drill_down_data, ' showMoreData.drill_down_data ');
    // useEffect(() => {
    //     if (chartValue == 'exposedRisk') {
    //         let temp = []
    //         let temp2 = []
    //         // if (showMoreData && showMoreData.drill_down_data && showMoreData.drill_down_data.length > 0) {
    //         //     for (let i in delayedChart) {
    //         //         for (let j in showMoreData.drill_down_data) {
    //         //             // if (delayedChart[i].u_name == showMoreData.drill_down_data[j].u_name) {
    //         //             //     var newArray = Object.assign({}, delayedChart[i].drill_compliances, showMoreData.drill_down_data[j].drill_compliances);
    //         //             //     console.log(newArray, 'newArray');
    //         //             //     delayedChart[i].drill_compliances = newArray
    //         //             // }
    //         //             if (delayedChart[i].u_name == showMoreData.drill_down_data[j].u_name) {
    //         //                 let array = delayedChart[i].drill_compliances
    //         //                 var newArray = Object.assign({}, delayedChart[i].drill_compliances, showMoreData.drill_down_data[j].drill_compliances);
    //         //                 delayedChart[i].drill_compliances = newArray
    //         //                 temp2.push(delayedChart[i].u_name)
    //         //             }
    //         //             for (let i in showMoreData.drill_down_data) {

    //         //                 if ((temp2.includes(showMoreData.drill_down_data[i].u_name)) == false) {
    //         //                     // let data = _.filter(showMoreData.r_drill_down_data, { level1_statutory_name: taskDistributionTable[j].level1_statutory_name })
    //         //                     console.log(showMoreData.drill_down_data[i], 'datadata');
    //         //                     temp.push(showMoreData.drill_down_data[i])
    //         //                 }



    //         //             }

    //         //             setDelayedChart([...delayedChart, ...temp])

    //         //             // else if (delayedChart[i].u_name != showMoreData.drill_down_data[j].u_name) {
    //         //             //     let temp = []
    //         //             //     temp.push(delayedChart[i], showMoreData.drill_down_data[j])
    //         //             // }
    //         //         }
    //         //     }
    //         //     // setDelayedChart(delayedChart)
    //         // }
    //         if (showMoreData && showMoreData.drill_down_data && showMoreData.drill_down_data.length > 0) {
    //             let temp = []
    //             let temp2 = []
    //             for (let i in delayedChart) {
    //                 for (let j in showMoreData.drill_down_data) {
    //                     if (delayedChart[i].u_name == showMoreData.drill_down_data[j].u_name) {
    //                         for (let s in delayedChart[i].drill_compliances) {
    //                             for (let a in showMoreData.drill_down_data[j].drill_compliances) {
    //                                 console.log(s, 'taskDistributionTable[i].ap_compliances[s]');
    //                                 // console.log(showMoreData.r_drill_down_data[j].ap_compliances[a], 'showMoreData.r_drill_down_data[j].ap_compliances[a]');
    //                                 if (s == a) {
    //                                     let arr1 = delayedChart[i].drill_compliances[s]
    //                                     let arr2 = showMoreData.drill_down_data[j].drill_compliances[a]
    //                                     let arr3 = arr1.concat(arr2)
    //                                     console.log(arr3, 'arr3');
    //                                     delayedChart[i].drill_compliances[s] = arr3
    //                                     console.log(s, 'sneha');
    //                                 }
    //                                 else {
    //                                     delayedChart[i].drill_compliances[a] = showMoreData.drill_down_data[j].drill_compliances[a]
    //                                 }
    //                             }
    //                         }
    //                         // let newArray = { ...obj, ...objdata }

    //                         // var newArray = Object.assign({}, taskDistributionTable[i].ap_compliances, showMoreData.r_drill_down_data[j].ap_compliances);
    //                         // taskDistributionTable[i].ap_compliances = newArray
    //                         temp2.push(delayedChart[i].u_name)
    //                         // setTaskDistributionTable(showMoreData.r_drill_down_data)
    //                     }

    //                 }
    //             }
    //             console.log(temp2, 'temp2');
    //             for (let i in showMoreData.drill_down_data) {

    //                 if ((temp2.includes(showMoreData.drill_down_data[i].u_name)) == false) {
    //                     // let data = _.filter(showMoreData.r_drill_down_data, { level1_statutory_name: taskDistributionTable[j].level1_statutory_name })
    //                     console.log(showMoreData.drill_down_data[i], 'datadata');
    //                     temp.push(showMoreData.drill_down_data[i])
    //                 }



    //             }
    //             setDelayedChart([...delayedChart, ...temp])



    //         }

    //     }

    // }, [showMoreData, show])

    // useEffect(() => {
    //     if (localStorage.getItem('SelectedEntity') != "All Legal Entity" && commonData) {
    //         let arrunit = [];
    //         let totalListunit = []
    //         let countrylisttempdataunit = [];
    //         let entityArrayunit = []
    //         let tempArrayunit = []
    //         commonData && commonData.d_info && commonData.d_info.length > 0 && commonData && commonData.d_info && commonData.d_info.map((subunitlist) => {
    //             console.log(subunitlist, 'subunitlist-domain');
    //             if (country_info && country_info[0].c_id == subunitlist.c_id) {
    //                 entityArrayunit = {

    //                     'c_id': subunitlist.c_id,
    //                     'd_id': subunitlist.d_id,
    //                     'd_name': subunitlist.d_name,
    //                     'is_active': subunitlist.is_active,
    //                     'month_from': subunitlist.month_from,
    //                     'month_to': subunitlist.month_to,
    //                     'label': subunitlist.d_name,
    //                     'values': subunitlist.d_id

    //                 }
    //                 tempArrayunit.push(entityArrayunit)
    //                 console.log(entityArrayunit, 'entityArrayunit');
    //             }

    //         })
    //         totalListunit = {
    //             'label': country_info && country_info && country_info[0].c_id == null ? 'Others' : country_info && country_info[0].c_name,
    //             'options': tempArrayunit
    //         }

    //         countrylisttempdataunit.push(totalListunit)
    //         setDomainListData(countrylisttempdataunit)
    //     }
    // }, [commonData, localStorage.getItem('SelectedEntity') != "All Legal Entity"])



    useEffect(() => {
        if (filterData.domain) {
            let tempDom = []
            for (let i in filterData.domain) {
                let filteredDomain = _.find(domainData, { cd_id: `${filterData.domain[i]}` })
                if (filteredDomain) {
                    tempDom.push({
                        "d_id": filteredDomain.d_id,
                        "c_id": filteredDomain.c_id,
                        "month_from": filteredDomain.month_from,
                        "month_to": filteredDomain.month_to
                    })
                }
                console.log(filteredDomain, 'filteredDomain');
            }
            setNewCountryDomain(tempDom)
            console.log(tempDom, 'tempDom');
        }

    }, [domainData, filterData.domain])

    // // UseEffect for filters

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === 'All Legal Entity') {
            if (uniq_country_data && uniq_country_data.length > 0) {
                console.log('hii');
                console.log(uniq_country_data, 'uniq_country_data');
                setfield({
                    ...field,
                    country: uniq_country_data
                })
                if (JSON.parse(isCountryValueTrue) === false) {
                    let temp = []
                    let temp1 = []
                    let temp2 = []
                    let temp3 = []
                    for (let i in commonData.d_info) {
                        temp3.push(commonData.d_info[i].d_id)
                        temp.push(`${commonData.d_info[i].c_id}-${commonData.d_info[i].d_id}`)
                    }
                    for (let j in uniq_country_data) {
                        temp1.push(`${uniq_country_data[j].c_id}`)
                        temp2.push(uniq_country_data[j].c_id)
                    }
                    console.log(temp, 'temp');
                    console.log(temp3, 'temp3');
                    setFilterData({
                        ...filterData,
                        country: temp1,
                        countryArr: temp2,
                        domain: temp,
                        domainIds: temp3
                    })
                }
                else {
                    setFilterData({
                        ...filterData,
                        country: [],
                        countryArr: [],
                        domain: [],
                        domainIds: []
                    })
                }
            }
        }
        else {
            let filteredEntity = _.filter(commonData.le_did_infos, { le_id: localStorage.getItem('SelectedEntityid') })
            console.log(filteredEntity, 'filteredEntity');
            if (filteredEntity && filteredEntity.length > 0) {
                let filteredCountry = _.filter(uniq_country_data, { c_id: filteredEntity[0].c_id })

                setfield({
                    ...field,
                    country: filteredCountry
                })
                if (JSON.parse(isCountryValueTrue) === false) {
                    let temp = []
                    let temp1 = []
                    let temp2 = []
                    let temp3 = []
                    for (let i in commonData.d_info) {
                        temp3.push(commonData.d_info[i].d_id)
                        temp.push(`${commonData.d_info[i].c_id}-${commonData.d_info[i].d_id}`)
                    }
                    for (let j in filteredCountry) {
                        temp1.push(`${filteredCountry[j].c_id}`)
                        temp2.push(filteredCountry[j].c_id)
                    }
                    console.log(temp, 'temp');
                    console.log(temp3, 'temp3');
                    setFilterData({
                        ...filterData,
                        country: temp1,
                        countryArr: temp2,
                        domain: temp,
                        domainIds: temp3
                    })
                }
                else {
                    setFilterData({
                        ...filterData,
                        country: [],
                        countryArr: [],
                        domain: [],
                        domainIds: []
                    })
                }
            }
        }
    }, [commonData, uniq_country_data, localStorage.getItem('SelectedEntityid'), localStorage.getItem('SelectedEntity')])



    // useEffect(() => {
    //     if (commonData && JSON.parse(sideFilterState) === false) {
    //         let temp = []
    //         let temp1 = []
    //         let temp2 = []
    //         let temp3 = []
    //         if (JSON.parse(isCountryValueTrue) === false) {
    //             for (let i in commonData.d_info) {
    //                 temp3.push(commonData.d_info[i].d_id)
    //                 temp.push(`${commonData.d_info[i].c_id}-${commonData.d_info[i].d_id}`)
    //             }
    //             for (let j in field.country) {
    //                 temp1.push(`${field.country[j].c_id}`)
    //                 temp2.push(field.country[j].c_id)
    //             }
    //             setFilterData({
    //                 ...filterData,
    //                 country: temp1,
    //                 countryArr: temp2,
    //                 domain: temp,
    //                 domainIds: temp3
    //             })
    //         }
    //         else {
    //             setFilterData({
    //                 ...filterData,
    //                 country: [],
    //                 countryArr: [],
    //                 domain: [],
    //                 domainIds: []
    //             })
    //         }
    //     }
    // }, [commonData, field.country, localStorage.getItem('countryState'), localStorage.getItem('filterState')])

    useEffect(() => {
        if (commonData) {
            if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                const uniqueIds = commonData.le_did_infos && commonData.le_did_infos.map((item) => {
                    return item.bg_id
                })
                setBgIds(uniqueIds)
            }
        }

    }, [commonData])

    useEffect(() => {
        if (filterData.country && filterData.country.length === 0) {
            localStorage.setItem('countryState', true)
        }
        else {
            localStorage.setItem('countryState', false)
        }
    }, [filterData.country])

    //Country onChange

    console.log(filterData.country, 'counnnn');

    useEffect(() => {
        if (filterData.country) {
            let tempCountries = []
            let bgs = []
            let tempBgs = []
            let tempLe = []
            let filteredLe = []
            for (let i in filterData.country) {
                let filteredCountries = _.filter(uniq_country_data, { c_id: Number(filterData.country[i]) })
                console.log(filteredCountries, 'filteredCountries');
                tempCountries.push(...filteredCountries)
                if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                    let filteredBg = _.filter(commonData.bg_groups, { c_id: Number(filterData.country[i]) })
                    bgs.push(...filteredBg)
                }
                if (bgs && bgs.length > 0) {
                    tempBgs = _.uniqBy(bgs, function (e) {
                        return e.bg_id;
                    });
                }
                if (tempBgs && tempBgs.length > 0) {
                    if (bgIds.includes(null)) {
                        filteredLe = commonData.le_did_infos.filter(function (currentElement) {
                            return currentElement.bg_id === null || currentElement.bg_id === Number(tempBgs[0].bg_id);
                        });
                    }
                    else {
                        filteredLe = _.filter(commonData.le_did_infos, { bg_id: Number(tempBgs[0].bg_id) })
                    }
                }
                else if (commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0) {
                    tempLe = _.filter(commonData.le_did_infos, { c_id: Number(filterData.country[i]) })
                    filteredLe.push(...tempLe)
                }

            }

            if (tempBgs && tempBgs.length > 0) {

            }

            setFilteredCountries(tempCountries)
            console.log(tempCountries, 'tempCountries');
            console.log(tempBgs, 'tempBgs');
            setfield({
                ...field,
                business: tempBgs,
                legalEntity: filteredLe
            })
            // setLegalEntityArray(filteredEntity)
        }

    }, [filterData.country, uniq_country_data])

    //Domain onChange

    useEffect(() => {
        if (fieldTypeClick === 'Domain') {
            if (filterData.domainIds) {
                let tempBgs = []
                let tempLe = []
                let filteredLe = []
                for (let i in filterData.domainIds) {
                    if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                        let filteredBg = _.filter(commonData.bg_groups, { d_id: Number(filterData.domainIds[i]) })
                        tempBgs.push(...filteredBg)
                    }
                    console.log(tempBgs, 'tempBgs');
                    if (tempBgs && tempBgs.length > 0) {
                        if (bgIds.includes(null)) {
                            filteredLe = commonData.le_did_infos.filter(function (currentElement) {
                                return currentElement.bg_id === null || currentElement.bg_id === Number(tempBgs[0].bg_id);
                            });
                        }
                        else {
                            filteredLe = _.filter(commonData.le_did_infos, { bg_id: Number(tempBgs[0].bg_id) })
                        }
                    }
                    else if (commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0) {
                        filteredLe = commonData && commonData.le_did_infos.filter(item => item.d_ids.includes(Number(filterData.domainIds[i])))
                    }
                }
                console.log(filteredLe, 'filteredLe');
                setfield({
                    ...field,
                    business: tempBgs,
                    legalEntity: filteredLe
                })
            }
        }

    }, [filterData.domainIds, fieldTypeClick])


    useEffect(() => {
        if (field && field.legalEntity && field.legalEntity.length > 0) {
            setLegalEntityArray([field.legalEntity[0]])
        }

    }, [field.legalEntity])

    console.log(unit, 'unitttt');

    // Legal entity onChange

    useEffect(() => {
        if (((commonData) && (legalEntityArray && legalEntityArray.length > 0))) {
            console.log(field.legalEntity, 'field.legalEntity');
            if (commonData && commonData.div_infos && commonData.div_infos.length > 0) {
                let filteredDiv = [];
                let Divisions = _.filter(commonData.div_infos, { le_id: legalEntityArray[0].le_id })
                if (Divisions && Divisions.length > 0) {
                    filteredDiv = _.uniqBy(Divisions, function (e) {
                        return e.div_id;
                    });
                }
                console.log(filteredDiv, 'filteredDiv');
                if (filteredDiv && filteredDiv.length > 0) {
                    setDivision(filteredDiv)
                    let filteredCat = _.filter(commonData.cat_info, { div_id: Number(filteredDiv[0].div_id) })
                    let temp = []
                    if (filteredCat && filteredCat.length > 0) {
                        temp.push({
                            'bg_id': filteredCat[0].bg_id,
                            'c_id': filteredCat[0].c_id,
                            'cat_id': filteredCat[0].cat_id,
                            'cat_name': filteredCat[0].cat_name,
                            'd_id': filteredCat[0].d_id,
                            'div_id': filteredCat[0].div_id,
                            'le_id': filteredCat[0].le_id,
                            'div_name': filteredDiv[0].div_name
                        })
                        setCategory(temp)
                    }
                    // if (commonData && commonData.chart_units && commonData.chart_units.length > 0) {
                    //     let filteredUnit = _.filter(commonData.chart_units, { division_id: Number(filteredDiv[0].div_id) })
                    //     console.log(filteredUnit, 'filteredUnit');
                    //     setUnit(filteredUnit)
                    // }
                }
                // else {
                //     console.log('elsepart');
                //     if (commonData && commonData.chart_units && commonData.chart_units.length > 0) {
                //         console.log('trueee');
                //         let filteredUnit = _.filter(commonData.chart_units, { le_id: legalEntityArray[0].le_id })
                //         console.log(filteredUnit, 'filteredDivision');
                //         setUnit(filteredUnit)
                //     }
                // }
                if (commonData && commonData.chart_units && commonData.chart_units.length > 0) {
                    console.log('trueee');
                    let filteredUnit = _.filter(commonData.chart_units, { le_id: legalEntityArray[0].le_id })
                    console.log(filteredUnit, 'filteredDivision');
                    setUnit(filteredUnit)
                }
            }
        }

    }, [legalEntityArray, commonData])

    // Division,Category and Unit

    useEffect(() => {
        if (legalEntityArray && legalEntityArray.length > 0) {
            if (division) {
                let totalListunit = []
                let countrylisttempdataunit = [];
                let entityArrayunit = []
                let tempArrayunit = []
                division && division.length > 0 && division.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist');

                    if (legalEntityArray && legalEntityArray[0].le_id == subunitlist.le_id) {

                        entityArrayunit = {

                            'bg_id': subunitlist.bg_id,
                            'c_id': subunitlist.c_id,
                            'd_id': subunitlist.d_id,
                            'div_id': subunitlist.div_id,
                            'div_name': subunitlist.div_name,
                            'le_id': subunitlist.le_id,
                            'label': subunitlist.div_name,
                            'values': subunitlist.div_id

                        }
                        tempArrayunit.push(entityArrayunit)
                        console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                    }

                })
                totalListunit = {
                    'label': legalEntityArray && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray[0].le_name,
                    'options': tempArrayunit
                }

                console.log(totalListunit, 'totalListunit');

                countrylisttempdataunit.push(totalListunit)
                console.log(countrylisttempdataunit, 'countrylisttempdataunit');
                setDivisionListData(countrylisttempdataunit)
            }
            if (category) {
                let totalListunit = []
                let countrylisttempdataunit = [];
                let entityArrayunit = []
                let tempArrayunit = []
                category && category.length > 0 && category.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist-category');
                    if (legalEntityArray && legalEntityArray[0].le_id == subunitlist.le_id) {
                        entityArrayunit = {

                            'bg_id': subunitlist.bg_id,
                            'c_id': subunitlist.c_id,
                            'cat_id': subunitlist.cat_id,
                            'cat_name': subunitlist.cat_name,
                            'd_id': subunitlist.d_id,
                            'div_id': subunitlist.div_id,
                            'div_name': subunitlist.div_name,
                            'le_id': subunitlist.le_id,
                            'label': subunitlist.cat_name,
                            'values': subunitlist.cat_id

                        }
                        tempArrayunit.push(entityArrayunit)
                        console.log(entityArrayunit, 'entityArrayunit');
                    }

                })
                totalListunit = {
                    'label': legalEntityArray && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray[0].le_name,
                    'options': tempArrayunit
                }
                countrylisttempdataunit.push(totalListunit)
                setCategoryListData(countrylisttempdataunit)
            }
            if (unit) {
                let totalListunit = []
                let countrylisttempdataunit = [];
                let entityArrayunit = []
                let tempArrayunit = []
                unit && unit.length > 0 && unit.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist-unit');
                    if (legalEntityArray && legalEntityArray[0].le_id == subunitlist.le_id) {
                        entityArrayunit = {

                            'address': subunitlist.address,
                            'business_group_id': subunitlist.business_group_id,
                            'category_id': subunitlist.category_id,
                            'country_id': subunitlist.country_id,
                            'd_ids': subunitlist.d_ids,
                            'division_id': subunitlist.division_id,
                            'le_id': subunitlist.le_id,
                            'postal_code': subunitlist.postal_code,
                            'u_id': subunitlist.u_id,
                            'u_name': subunitlist.u_name,
                            'label': subunitlist.u_name,
                            'values': subunitlist.u_id

                        }
                        tempArrayunit.push(entityArrayunit)
                        console.log(entityArrayunit, 'entityArrayunit');
                    }

                })
                console.log(tempArrayunit, 'tempArrayunit');
                totalListunit = {
                    'label': legalEntityArray && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray[0].le_name,
                    'options': tempArrayunit
                }

                countrylisttempdataunit.push(totalListunit)
                setUnitListData(countrylisttempdataunit)
            }
        }
    }, [legalEntityArray, division, category, unit])

    //Domain Data 

    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        filteredCountries && filteredCountries.length > 0 && filteredCountries.map(function (user) {
            // console.log(list.unitlist, 'list.unitlist');
            if ((arrunit.indexOf(user.c_id) < 0))
                arrunit.push(user.c_id);
        });

        arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []
            if (pmenu || pmenu == null) {
                commonData.d_info && commonData.d_info.length > 0 && commonData.d_info.map((subunitlist) => {

                    if (pmenu == subunitlist.c_id) {
                        entityArrayunit = {

                            'c_id': subunitlist.c_id,
                            'd_id': `${subunitlist.c_id}-${subunitlist.d_id}`,
                            'd_name': subunitlist.d_name,
                            'is_active': subunitlist.is_active,
                            'month_from': subunitlist.month_from,
                            'month_to': subunitlist.month_to,
                            'label': subunitlist.d_name,
                            'values': subunitlist.d_id

                        }
                        tempArrayunit.push(entityArrayunit)
                    }
                })
            }
            let countryName = _.find(filteredCountries, { c_id: pmenu })
            console.log(countryName, 'countryName');
            totalListunit = {
                'label': countryName.c_name == null ? 'Others' : countryName.c_name,
                'options': tempArrayunit
            }
            console.log(totalListunit, 'unitListData')
            countrylisttempdataunit.push(totalListunit)
        })
        setDomainListData(countrylisttempdataunit)


    }, [filteredCountries, commonData])


    const HandelCountryChange = (obj) => {
        console.log(obj, 'objj');
        var numberArray = obj.map(Number);
        console.log(numberArray, 'numberArray');
        let temp5 = []
        let filterDomain = []
        let filteredBg = []
        let filteredLE = []
        let leTemp = []
        let bgTemp = []
        for (let j in obj) {
            filterDomain = _.filter(commonData.d_info, { c_id: Number(obj[j]) })
            // temp2.push(filterDomain)
            for (let i in filterDomain) {
                temp5.push(filterDomain[i])
            }

            if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                filteredBg = _.filter(commonData && commonData.bg_groups, { c_id: Number(obj[j]) })
                console.log(filteredBg, 'filteredBg');
                if (filteredBg && filteredBg.length > 0) {
                    for (let b in filteredBg) {
                        bgTemp.push(filteredBg[b])
                    }
                }
                else {
                    filteredLE = _.filter(commonData && commonData.le_did_infos, { c_id: Number(obj[j]) })
                    if (filteredLE && filteredLE.length > 0) {
                        for (let l in filteredLE) {
                            leTemp.push(filteredLE[l])
                        }
                    }
                }
                // if (bgIds.includes(null) === true) {
                //     filteredBg = _.filter(commonData && commonData.bg_groups, { c_id: Number(obj[j]) })
                //     console.log(filteredBg,'filteredBg1111');
                // }
                // else {
                //     filteredBg = _.filter(commonData && commonData.bg_groups, { c_id: Number(obj[j]) })
                //     console.log(filteredBg, 'filteredBg');
                //     for (let b in filteredBg) {
                //         bgTemp.push(filteredBg[b])
                //     }
                // }
            }

            if (commonData && commonData.le_did_infos) {
                filteredLE = _.filter(commonData && commonData.le_did_infos, { c_id: Number(obj[j]) })
                if (filteredLE && filteredLE.length > 0) {
                    for (let l in filteredLE) {
                        leTemp.push(filteredLE[l])
                    }
                    // leTemp = _.uniq(leTemp)
                }
            }

        }
        let id = []
        for (let i in temp5) {
            id.push(`${temp5[i].c_id}-${temp5[i].d_id}`)
        }
        let dIds = []
        for (let i in temp5) {
            dIds.push(temp5[i].d_id)
        }
        let bgIds1 = bgTemp && bgTemp.length > 0 && bgTemp.map((item, i) => {
            return item.bg_id
        })
        let leIds = leTemp && leTemp.length > 0 && leTemp.map((item, i) => {
            return item.le_id
        })
        let uniqueLe = _.uniq(leIds)
        console.log(uniqueLe, 'uniqueLe');
        console.log(leTemp, 'leTemp');
        console.log(bgTemp, 'bggg');
        console.log(bgIds, 'bgIds');
        setFieldTypeClick('Country')
        setType('')
        setFilterData({
            ...filterData,
            country: obj,
            countryArr: numberArray,
            domain: id,
            business: `${bgIds1}`,
            legalentity: leIds ? uniqueLe : [],
            domainIds: dIds
        })
        setFieldType('')
        setFilterDataNames({
            ...filterDataNames,
            country: obj,
            legalentity: '',
            division: '',
            unit: '',
            business: '',
            category: '',
            unit: ''
        })
    }

    const HandelChange = (obj) => {
        console.log(obj, 'objj');
        let tempObj = []
        if (obj && obj.length) {
            for (let i in obj) {
                let splitData = obj[i].split("-")
                console.log(splitData[1], 'splitData');
                // tempObj = [...tempObj,...splitData[1]]
                tempObj.push(Number(splitData[1]))
            }
        }
        console.log(tempObj, 'tempObj');
        setFieldTypeClick('Domain')
        setType('')
        setFilterData({
            ...filterData,
            domain: obj,
            domainIds: tempObj
        })
        setFieldType('')
        setFilterDataNames({
            ...filterDataNames,
            domain: obj,
            legalentity: '',
            division: '',
            unit: '',
            business: '',
            category: '',
            unit: ''
        })
        let temp = []
        let bg = []
        let tempDiv = []
        let tempCat = []
        let tempUnit = []
        console.log(tempCat, 'tempCat');
        console.log(bg, 'Aishuuuu');
        console.log(temp, 'SnehaaaaaaaaAsss');

    }


    const showmore = () => {
        setShow(true)
        setCountValue(countValue + 100)
        const payload =
            [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetComplianceApplicabilityStatusDrillDown",
                        {
                            "c_ids": [
                                commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                            ],
                            "d_ids": [
                                commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                            ],
                            "filter_type": "Group",
                            "filter_ids": [
                                1
                            ],
                            "chart_year": year,
                            "applicability_status": criticalityValue,
                            "record_count": countValue + 100,
                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                            "country_domains": countryDomain,
                        }
                    ]
                }
            ]

        if (paramid != "") {
            getRiskExposedTrendShowMoreTableData({
                payload: payload,
                paramid: paramid
            })
        }
    }

    const hiddenRiskShowmore = () => {
        setShow(true)
        setCountValue(countValue + 100)
        const payload =
            [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetComplianceApplicabilityStatusDrillDown",
                        {
                            "c_ids": [
                                commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                            ],
                            "d_ids": [
                                commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                            ],
                            "filter_type": "Group",
                            "filter_ids": [
                                1
                            ],
                            "chart_year": 2022,
                            "applicability_status": criticalityValue,
                            "record_count": countValue + 100,
                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                            "country_domains": [
                                {
                                    "d_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id,
                                    "c_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].c_id,
                                    "month_from": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_from,
                                    "month_to": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_to
                                }
                            ],
                        }
                    ]
                }
            ]

        if (paramid != "") {
            getHiddenRiskShowMore({
                payload: payload,
                paramid: paramid
            })
        }
    }
    const onClose = () => {
        setOpen(false);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (JSON.parse(sideFilterState) === true && localStorage.getItem('filterChange') && go === false) {
            if (JSON.parse(isCountryValueTrue) === false) {
                let temp = []
                for (let i in parsedSideFilterData.c_ids) {
                    temp.push(`${parsedSideFilterData.c_ids[i]}`)
                }
                console.log(temp, 'temp');
                setFilterData({
                    ...filterData,
                    country: temp,
                    // countryArr:[`${parsedSideFilterData && parsedSideFilterData.c_ids}`],
                    domain: parsedSideFilterData && parsedSideFilterData.cd_ids,
                    domainIds: parsedSideFilterData && parsedSideFilterData.d_ids,
                })
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request":
                            ["GetComplianceApplicabilityStatusChart",
                                {
                                    "c_ids": parsedSideFilterData && parsedSideFilterData.c_ids,
                                    "d_ids": parsedSideFilterData && parsedSideFilterData.d_ids,
                                    "filter_type": parsedSideFilterData && parsedSideFilterData.filter_type,
                                    "filter_ids": parsedSideFilterData && parsedSideFilterData.filter_ids,
                                    "le_ids": parsedSideFilterData && parsedSideFilterData.le_ids,
                                    "country_domains": parsedSideFilterData && parsedSideFilterData.country_domains,
                                    "chart_year": year
                                }
                            ]
                    }
                ]
                if (paramid != "") {
                    getRiskchart({
                        payload: payload,
                        paramid: paramid
                    })
                }
            }
            else {
                setFilterData({
                    ...filterData,
                    country: [],
                    countryArr: [],
                    domain: [],
                    domainIds: [],
                })
            }
        }

    }, [go, paramid, localStorage.getItem('filterState'), localStorage.getItem('filterChange'), localStorage.getItem('countryState')])

    const filterChange = () => {
        setGeneratedOn(false)
        setGo(true)
        setYear(moment().year())
        // setCriticalityTable([])
        // setDelayedChart([])
        // setExposedRiskData([])
        // setHiddenRiskData([])
        // setExposedRiskData([])
        if (groupCheckBox === true && errorField === '') {
            if (fieldTypeClick === 'Country' || fieldTypeClick === 'Domain') {
                let obj = {
                    "c_ids": filterData.countryArr,
                    "cd_ids": filterData.domain,
                    "d_ids": filterData.domainIds,
                    "filter_type": "Group",
                    "filter_ids": fieldTypeClick === "Country" ? filterData.countryArr : cIds,
                    "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                    "country_domains": newCountryDomain,
                }
                localStorage.setItem('filterState', true)
                localStorage.setItem('filterChange', JSON.stringify(obj))
            }
            else {
                let obj = {}
                localStorage.setItem('filterState', false)
                localStorage.setItem('filterChange', JSON.stringify(obj))
            }
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetComplianceApplicabilityStatusChart",
                        {
                            "c_ids": filterData.countryArr,
                            "d_ids": filterData.domainIds,
                            "filter_type": ((fieldType != "") && (type === '')) ? fieldType : "Group",
                            "filter_ids": [
                                (fieldType == "LegalEntity" && type === '') ? filterData.legalentity :
                                    (fieldType == 'Division' && type === '') ? Number(filterData.division) :
                                        (fieldType == 'Category' && type === '') ? Number(filterData.category) :
                                            (fieldType == 'Unit' && type === '') ? Number(filterData.unit) :
                                                (fieldType == 'BusinessGroup' && type === '') ? Number(filterData.business) :
                                                    (fieldType != "" && type === 'Group') ? 1 :
                                                        1
                            ],
                            "le_ids":
                                filterData && filterData.leIds ? [filterData.leIds] : filterData.legalentity ? filterData.legalentity : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                            ,
                            "country_domains": newCountryDomain,
                            "chart_year": moment().year()
                        }
                    ]
                }
            ]

            getRiskchart({
                payload: payload,
                paramid: paramid
            })
            setOpen(false);

        }
        else {
            // toast.error('Please select the filter')
            toast.error(errorField ? errorField : 'Please select the filter')
        }
    }

    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        if (e) {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        } else {
            setSelectedKeys([])
            confirm({ closeDropdown: false });
            // setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex)
        }
    };

    const handleReset = (clearFilters, handleSearch, confirm, dataIndex, setSelectedKeys) => {
        handleSearch('', confirm, dataIndex, setSelectedKeys)
        clearFilters();
        setSearchText('');
    };

    const clearFocus = (searchInput) => {
        setTimeout(() => searchInput.current?.select(), 100);
    }


    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
            >
                <Input className='form-control'
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    autoFocus={'on'}
                    value={selectedKeys[0]}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}

                />
                <CloseCircleOutlined className='table-filter-reset'
                    onClick={() => {
                        handleReset(clearFilters,
                            handleSearch, confirm, dataIndex, setSelectedKeys)
                        clearFocus(searchInput)
                    }
                    } />
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    //     // color: filtered ? '#1890ff' : undefined,
                    //     fontWeight: 'bold',
                    //     // backgroundColor:'blue',
                    //     fontSize: '15px',
                    //     color: 'black'
                }}
            />
            // <FileSearchOutlined 
            // style={{
            //     // color: filtered ? '#1890ff' : undefined,
            //     fontWeight:'bold',
            //     // backgroundColor:'blue',
            //     // fontSize:'20px',
            //     color:'black'
            // }}
            // />
            // <i class="bi bi-search"></i>
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });

    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});

    const handleChange = (pagination, filters, sorter) => {

        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };


    const columns = [
        {
            title: "#",
            filterable: true,
            dataIndex: 'index',
            key: 'index',
            width: 10,
            align: 'center'
        },

        {
            title: "Statutory",
            ...getColumnSearchProps('s_prov', 'Statutory'),
            dataIndex: false,
            key: 's_prov',
            width: 20,
            filteredValue: filteredInfo.s_prov || null,
            render: (record) => {
                return <p title={record.s_prov} className='cut-text'>
                    {record.s_prov}</p>
            }
        },
        {
            title: 'Compliance Task',
            ...getColumnSearchProps('comp_name', 'Compliance Task'),
            dataIndex: false,
            key: 'comp_name',
            ellipsis: false,
            width: 30,
            render: (row, record) => {
                if (record.download_url_list) {
                    return <p title={record.comp_name} className='cut-text'><a href={`${urls + record.download_url_list}`} target="_blank">{record.comp_name}</a></p>
                }
                else {
                    return <p title={record.comp_name} className='cut-text'>{record.comp_name}</p>
                }
            },
            filteredValue: filteredInfo.comp_name || null,

        },
        {
            title: 'Criticality',
            ...getColumnSearchProps('criticality', 'Criticality'),
            dataIndex: 'criticality',
            key: 'criticality',
            ellipsis: false,
            width: 17,
            align: 'center',
            filteredValue: filteredInfo.criticality || null,

        },
        {
            title: 'Compliance Description',
            ...getColumnSearchProps('descp', 'Compliance Description'),
            dataIndex: false,
            key: 'descp',
            ellipsis: false,
            width: 30,
            filteredValue: filteredInfo.descp || null,
            render: (record) => {
                return <p title={record.descp} className='cut-text'>{record.descp}</p>
            }

        },
        {
            title: 'Penal Conseqeunces',
            ...getColumnSearchProps('p_cons', 'Penal Conseqeunces'),
            dataIndex: false,
            key: 'p_cons',
            ellipsis: false,
            width: 30,
            filteredValue: filteredInfo.p_cons || null,
            render: (record) => {
                return <p title={record.p_cons} className='cut-text'>{record.p_cons}</p>
            }

        },
        {
            title: "Compliance Frequency",
            ...getColumnSearchProps('frequency', 'Compliance Frequency'),
            dataIndex: 'frequency',
            key: 'frequency',
            ellipsis: false,
            width: 30,
            filteredValue: filteredInfo.frequency || null,

        },
        {
            title: 'Repeats',
            ...getColumnSearchProps('summary', 'Repeats'),
            dataIndex: false,
            key: 'summary',
            ellipsis: false,
            width: 20,
            filteredValue: filteredInfo.summary || null,
            render: (record) => {
                return <p title={record.summary} className='cut-text'>{record.summary}</p>
            }

        },
        {
            title: 'Trigger Before',
            ...getColumnSearchProps('trigger_before_days', 'Trigger Before'),
            dataIndex: false,
            key: 'trigger_before_days',
            ellipsis: false,
            width: 10,
            dataIndex: false,
            key: 'trigger_before_days',
            render: (record) => {
                console.log(record && record.length > 0 && record.statu_dates && record.statu_dates[0].trigger_before_days, 'record && record.statu_dates && record.statu_dates.length > 0 && record.statu_dates[0].trigger_before_days');
                if (record && record.statu_dates && record.statu_dates.length > 0 && record.statu_dates[0].trigger_before_days) {
                    return <p title={record.trigger_before_days} className='cut-text'>{record && record.trigger_before_days}</p>
                }
                else {
                    return null
                }

            },
            filteredValue: filteredInfo.trigger_before_days || null,
        },
    ];


    // const columns = [
    //     {
    //         title: 'Statutory',
    //         dataIndex: 's_prov',
    //         key: 's_prov',
    //     },
    //     {
    //         title: 'Compliance Task',
    //         dataIndex: false,
    //         key: false,
    //         render: (row, record) => {
    //             if (record.download_url_list === null) {
    //                 if (record.remarks) {
    //                     return <p><Tooltip title={record.remarks}><ExclamationCircleTwoTone /></Tooltip>&nbsp;{record.comp_name}</p>
    //                 } else {
    //                     return <p>{record.comp_name}</p>
    //                 }
    //             }
    //             else {

    //             }
    //         }
    //     },
    //     {
    //         title: 'Criticality',
    //         dataIndex: 'criticality',
    //         key: 'criticality',
    //     },
    //     {
    //         title: 'Compliance Description',
    //         dataIndex: 'descp',
    //         key: 'descp',
    //     },
    //     {
    //         title: 'Penal Conseqeunces',
    //         dataIndex: 'p_cons',
    //         key: 'p_cons',
    //     },
    //     {
    //         title: 'Compliance Frequency',
    //         dataIndex: 'frequency',
    //         key: 'frequency',
    //     },
    //     {
    //         title: 'Repeats',
    //         dataIndex: 'summary',
    //         key: 'summary',
    //     },
    //     {
    //         title: 'Trigger Before',
    //         dataIndex: false,
    //         key: false,
    //         render: (row) => {
    //             // return <p>{row.statu_dates && row.statu_dates[0].trigger_before_days}</p>
    //             return <p>{row.statu_dates && row.statu_dates.length > 0 ? `${row.statu_dates && row.statu_dates[0].trigger_before_days} days` : ''}</p>

    //         }
    //     },
    // ];
    // const columns1 = [
    //     {
    //         title: 'Business Group',
    //         // dataIndex: 'name',
    //         // key: 'name',
    //         width: '50px'

    //     },
    //     {
    //         title: 'Legal Entity',
    //         // dataIndex: 'legal_entity_id',
    //         // key: 'legal_entity_id',
    //         render: () => {
    //             return (<p>{localStorage.getItem('SelectedEntity')}</p>)
    //         },
    //         width: '120px'
    //     },
    //     {
    //         title: 'Division',
    //         dataIndex: 'description',
    //         key: 'description',
    //         width: '50px'

    //     },
    //     {
    //         title: "Compliance",
    //         dataIndex: 'comp_name',
    //         key: 'comp_name',

    //         // selector: (row) => <><p>{row.statu}</p><br></br><p>{row.compliance_name}</p></>,
    //         render: (row, record) => {
    //             console.log(record, 'record');
    //             if (record.remarks_count == 1) {
    //                 const content = (
    //                     <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
    //                         <tbody>
    //                             <tr>
    //                                 <th>Compliance Description</th>
    //                                 <td>{record.descp}</td>
    //                             </tr>
    //                             <tr>
    //                                 <th>Document Reference Number</th>
    //                                 <td>{record.doc_ref_num}</td>
    //                             </tr>
    //                         </tbody>
    //                     </table>
    //                 );
    //                 return (
    //                     <>
    //                         <div>
    //                             <div className="col-md-12">
    //                                 <div className="row">
    //                                     <div className="col-md-2">
    //                                         <Popover content={content} title="">
    //                                             <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
    //                                         </Popover>
    //                                     </div>
    //                                     <div className="col-md-10" style={{ marginTop: '2%' }}>
    //                                         <p>
    //                                             <Tooltip style={{ marginRight: '20%' }} title='Click here to View Remarks History' onClick={() => {
    //                                                 showModal()
    //                                                 const payload = [
    //                                                     authtoken,
    //                                                     {
    //                                                         "session_token": authtoken,
    //                                                         "request": [
    //                                                             "GetComplianceRemarksHistoryData",
    //                                                             {
    //                                                                 "legal_entity_id": record.legal_entity_id,
    //                                                                 "unit_id": record.unit_id,
    //                                                                 "compliance_history_id": record.compliance_history_id
    //                                                             }
    //                                                         ]
    //                                                     }
    //                                                 ]
    //                                                 getRemarksHistoryData({
    //                                                     payload: payload,
    //                                                     paramid: paramid
    //                                                 })
    //                                             }}><ExclamationCircleTwoTone /></Tooltip>&nbsp;{record.comp_name}</p>
    //                                     </div>
    //                                 </div>
    //                             </div>

    //                         </div>

    //                     </>
    //                 )
    //             }
    //             const content = (
    //                 <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
    //                     <tbody>
    //                         <tr>
    //                             <th>Compliance Description</th>
    //                             <td>{record.descp}</td>
    //                         </tr>
    //                         <tr>
    //                             <th>Document Reference Number</th>
    //                             <td>{record.doc_ref_num}</td>
    //                         </tr>
    //                     </tbody>
    //                 </table>
    //             );
    //             return (
    //                 <>
    //                     <div>
    //                         <div className="mb-3">
    //                             <Popover content={content} title="">
    //                                 <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
    //                             </Popover>{record.comp_name}
    //                         </div>
    //                     </div>

    //                 </>
    //             )
    //         },
    //         width: '180px',
    //         // render: (compliance_task) => <a href="javascript:;" onClick={() => { showModal() }}>{compliance_task}</a>,
    //         // sorter: (a, b) => { return a.statu.localeCompare(b.statu) },
    //     },
    //     {
    //         title: 'Criticality',
    //         dataIndex: 'criticality',
    //         key: 'criticality',
    //         width: '80px'

    //     },
    //     {
    //         title: 'Assigned To',
    //         dataIndex: 'assignee_name',
    //         key: 'assignee_name',
    //         width: '80px'

    //     },
    //     {
    //         title: 'Over Due',
    //         dataIndex: 'ageing',
    //         key: 'ageing',
    //         width: '80px'

    //     },

    // ];

    const columns2 = [
        {
            title: "#",
            filterable: true,
            dataIndex: 'index',
            key: 'index',
            // render: (row, index, i) => {
            //     return (
            //         <><p>{(counts + 1)}</p>
            //             <span hidden>{counts = counts + 1}</span>
            //         </>)
            // },
            width: '10%',
            align: 'center'
        },
        ...((fieldType === 'LegalEntity' && go === true) || (fieldType === 'Category' && go === true) || (fieldType === 'Unit' && go === true) ?
            [] :
            [{
                title: "Business Group",
                ...getColumnSearchProps('bg_name', "Business Group"),
                dataIndex: 'bg_name',
                key: 'bg_name',
                ellipsis: false,
                width: '25%',
                filteredValue: filteredInfo.bg_name || null,
            },
            {
                title: 'Legal Entity',
                ...getColumnSearchProps('le_name', 'Legal Entity'),
                dataIndex: false,
                key: 'le_name',
                ellipse: false,
                width: '25%',
                filteredValue: filteredInfo.le_name || null,
                render: (record) => {
                    return <p title={record.le_name} className='cut-text'>
                        {record.le_name}</p>
                }
            }
            ]
        ),
        {
            title: 'Division',
            ...getColumnSearchProps('div_name', 'Division'),
            dataIndex: false,
            key: 'div_name',
            width: '20%',
            ellipsis: false,
            filteredValue: filteredInfo.div_name || null,
            render: (record) => {
                return <p title={record.div_name} className='cut-text'>
                    {record.div_name}</p>
            }

        },
        {
            title: 'Compliance',
            ...getColumnSearchProps('comp_name', 'Compliance'),
            dataIndex: false,
            key: 'comp_name',
            width: '90%',
            ellipse: false,
            render: (row, record) => {
                if (record.compfielaw_url != '') {
                    const content = (
                        <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                            <tbody>
                                <tr>
                                    <th>Compliance Description</th>
                                    <td>{record.descp}</td>
                                </tr>
                                <tr>
                                    <th>Document Reference Number</th>
                                    <td>{record.doc_ref_num}</td>
                                </tr>
                            </tbody>
                        </table>
                    );
                    return (
                        <>
                            <div>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <Tooltip title={
                                                <table style={{ width: "100%", borderCollapse: 'collapse', border: '1px solid #636262', tableLayout: 'auto' }}>
                                                    <tbody>
                                                        <tr>
                                                            <th style={{ border: '1px solid #636262', marginLeft: '5%', marginRight: '5%' }}><center>Compliance Description</center></th>
                                                            <th style={{ border: '1px solid #636262' }}><center>Document Reference Number</center></th>

                                                        </tr>
                                                        <tr>
                                                            <td style={{ border: '1px solid #636262' }}><center>{record.descp ? record.descp : '-'}</center></td>
                                                            <td style={{ border: '1px solid #636262' }}><center>{record.doc_ref_num ? record.doc_ref_num : '-'}</center></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            }></Tooltip><i class="bi-info-circle-fill"></i>
                                            {/* <Popover content={content} title="">
                                                <Button style={{ border: 0 }}>
                                                <i class="bi-info-circle-fill"></i>
                                                </Button>
                                                <ExclamationCircleFilled className='me-1' />
                                            </Popover> */}
                                        </div>
                                        <div className="col-md-10" style={{ marginTop: '2%' }}>
                                            <p title={record.div_name} className='cut-text'>
                                                <Tooltip style={{ marginRight: '20%' }} title='Compfie Law Knowlegde Portal'>
                                                    <a href={`${record.compfielaw_url}`}>
                                                        <BookTwoTone />
                                                    </a>
                                                </Tooltip>&nbsp;{record.comp_name}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </>
                    )
                }
                const content = (
                    <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                        <tbody>
                            <tr>
                                <th>Compliance Description</th>
                                <td>{record.descp}</td>
                            </tr>
                            <tr>
                                <th>Document Reference Number</th>
                                <td>{record.doc_ref_num}</td>
                            </tr>
                        </tbody>
                    </table>
                );
                return (
                    <>
                        <div>
                            <div>
                                {/* <Popover content={content} title="">
                                    <Button style={{ border: 0 }}>
                                    <i class="bi-info-circle-fill"></i>
                                    <ExclamationCircleFilled className='me-1' />
                                    </Button>
                                </Popover> */}
                                <Tooltip title={
                                    <table style={{ width: "100%", borderCollapse: 'collapse', border: '1px solid #636262', tableLayout: 'auto' }}>
                                        <tbody>
                                            <tr>
                                                <th style={{ border: '1px solid #636262', marginLeft: '5%', marginRight: '5%' }}><center>Compliance Description</center></th>
                                                <th style={{ border: '1px solid #636262' }}><center>Document Reference Number</center></th>

                                            </tr>
                                            <tr>
                                                <td style={{ border: '1px solid #636262' }}><center>{record.descp ? record.descp : '-'}</center></td>
                                                <td style={{ border: '1px solid #636262' }}><center>{record.doc_ref_num ? record.doc_ref_num : '-'}</center></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                }><i class="bi-info-circle-fill"></i>&nbsp;</Tooltip>
                                {record.comp_name}f
                            </div>
                        </div>

                    </>
                )
            },
            filteredValue: filteredInfo.comp_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        {
            title: "Criticality",
            ...getColumnSearchProps('criticality', "Criticality"),
            dataIndex: 'criticality',
            key: 'criticality',
            ellipsis: false,
            // render: (row, record) => {
            //     return (
            //         <>
            //             <p>{record.criticality}</p>
            //         </>
            //     )
            // },
            width: '100%',
            align: 'center',
            filteredValue: filteredInfo.criticality || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,


        },
        {
            title: "Assigned To",
            ...getColumnSearchProps('assignee_name', "Assigned To"),
            dataIndex: 'assignee_name',
            key: 'assignee_name',
            ellipsis: false,
            // render: (row, record) => {
            //     return (
            //         <>
            //             <p>{record.assignee_name}</p>
            //         </>
            //     )
            // },
            width: '100%',
            filteredValue: filteredInfo.assignee_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        {
            title: (criticalityValue === "Complied" ? "Assigned to" : (criticalityValue === "Delayed Compliance" ? "Delayed By" : (criticalityValue === "Inprogress" ? "Days Left to Complete" : "Over Due"))),
            ...getColumnSearchProps('ageing', 'Over Due'),
            dataIndex: 'ageing',
            key: 'ageing',
            width: 12,

            ellipsis: false,
            render: (row, record) => {
                return (
                    <>
                        <p>{record.ageing}</p>
                    </>
                )
            },
            width: 13,
            align: 'center',
            filteredValue: filteredInfo.ageing || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,


        }
    ];

    const columns3 = [
        {
            title: "#",
            filterable: true,
            dataIndex: 'index',
            key: 'index',
            // render: (row, index, i) => {
            //     return (
            //         <><p>{(counts + 1)}</p>
            //             <span hidden>{counts = counts + 1}</span>
            //         </>)
            // },
            width: 7,
            align: 'center'
        },
        {
            title: 'Compliance',
            ...getColumnSearchProps('comp_name', 'Compliance'),
            dataIndex: false,
            key: 'comp_name',
            ellipse: true,
            render: (row, record) => {
                console.log(record, 'record');
                if (record.compfielaw_url != '') {
                    const content = (
                        <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                            <tbody>
                                <tr>
                                    <th>Compliance Description</th>
                                    <td>{record.descp}</td>
                                </tr>
                                <tr>
                                    <th>Document Reference Number</th>
                                    <td>{record.doc_ref_num}</td>
                                </tr>
                            </tbody>
                        </table>
                    );
                    return (
                        <>
                            <div>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-2">
                                            {/* <Popover content={content} title="">
                                                <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
                                            </Popover> */}
                                            <div className="mb-3">
                                                {/* <Popover content={content} title="">
                                    <span style={{ border: 0 }}><i class="bi-info-circle-fill"></i></span>
                                </Popover>&nbsp;{record.comp_name} */}

                                                <Tooltip title={
                                                    <table style={{ borderCollapse: 'collapse', border: '1px solid #636262', tableLayout: 'auto' }}>
                                                        <tr>
                                                            <th>Compliance Description</th>
                                                            <td>{record.descp}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Document Reference Number</th>
                                                            <td>{record.doc_ref_num}</td>
                                                        </tr>



                                                    </table>
                                                }> <i class="bi-info-circle-fill"></i>&nbsp;</Tooltip>

                                            </div>

                                        </div>
                                        <div className="col-md-10" style={{ marginTop: '2%' }}>
                                            <p title={record.comp_name} className='cut-text'>
                                                <Tooltip style={{ marginRight: '20%' }} title='Compfie Law Knowlegde Portal'>
                                                    <a href={`${record.compfielaw_url}`}>
                                                        <BookTwoTone />
                                                    </a>
                                                </Tooltip>&nbsp;{record.comp_name}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </>
                    )
                }
                const content = (
                    <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                        <tbody>
                            <tr>
                                <th>Compliance Description</th>
                                <td>{record.descp}</td>
                            </tr>
                            <tr>
                                <th>Document Reference Number</th>
                                <td>{record.doc_ref_num}</td>
                            </tr>
                        </tbody>
                    </table>
                );
                return (
                    <>
                        <div>
                            <div className="mb-3">
                                {/* <Popover content={content} title="">
                                    <span style={{ border: 0 }}><i class="bi-info-circle-fill"></i></span>
                                </Popover>&nbsp;{record.comp_name} */}

                                <Tooltip title={
                                    <table style={{ borderCollapse: 'collapse', border: '1px solid #636262', tableLayout: 'auto' }}>
                                        <tr>
                                            <th>Compliance Description</th>
                                            <td>{record.descp}</td>
                                        </tr>
                                        <tr>
                                            <th>Document Reference Number</th>
                                            <td>{record.doc_ref_num}</td>
                                        </tr>



                                    </table>
                                }> <i class="bi-info-circle-fill"></i>&nbsp;</Tooltip>{record.comp_name}

                            </div>

                        </div>

                    </>
                )
            },
            width: 25,
            filteredValue: filteredInfo.comp_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        {
            title: "Criticality",
            ...getColumnSearchProps('criticality', 'Criticality'),
            dataIndex: 'criticality',
            key: 'criticality',
            ellipsis: false,
            // render: (row, record) => {
            //     return (
            //         <>
            //             <p>{record.criticality}</p>
            //         </>
            //     )
            // },
            width: 13,
            align: 'center',
            filteredValue: filteredInfo.criticality || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,


        },
        {
            title: "Assigned To",
            ...getColumnSearchProps('assignee_name', 'Assigned To'),
            dataIndex: false,
            key: 'assignee_name',
            ellipsis: false,
            render: (record) => {
                return <p title={record.assignee_name} className='cut-text'>
                    {record.assignee_name}</p>
            },
            // render: (row, record) => {
            //     return (
            //         <>
            //             <p>{record.assignee_name}</p>
            //         </>
            //     )
            // },
            width: 13,
            filteredValue: filteredInfo.assignee_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        {
            title: (criticalityValue === "Complied" ? "Assigned to" : (criticalityValue === "Delayed Compliance" ? "Delayed By" : (criticalityValue === "Inprogress" ? "Days Left to Complete" : "Over Due"))),
            ...getColumnSearchProps('ageing', 'Over Due'),
            dataIndex: 'ageing',
            key: 'ageing',
            width: 12,

            ellipsis: false,
            render: (row, record) => {
                return (
                    <>
                        <p>{record.ageing}</p>
                    </>
                )
            },
            width: 13,
            align: 'center',
            filteredValue: filteredInfo.ageing || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,


        }
    ]


    const RemarksColumn = [
        {
            title: '#',
            dataIndex: 's_prov',
            key: 's_prov',
        },
        {
            title: 'Activity Date',
            dataIndex: 'activity_on',
            key: 'activity_on',
        },
        {
            title: 'User Type',
            dataIndex: 'action',
            key: 'action',
        },
        {
            title: 'Reviewer Report',
            dataIndex: 'documents',
            key: 'documents',
            render: (row, record) => {
                console.log(record.documents, 'record.documents');
                let string = record.documents
                var fields = string.split('||');
                console.log(fields.toString(), 'fields');
                var newString = fields.toString()
                // var splitedWord = newString.split(',')
                let values = []
                return <a href="">{newString}</a>
                // if (fields && fields.length > 0) {
                //     for (let i in fields) {
                //         // console.log(i, 'iiiii');
                //         values = fields[i]
                //         // console.log(values, 'values');
                //         // return (
                //         //     <li>{fields[i]}</li>
                //         // )
                //     }
                //     return (
                //         <li>{values}</li>
                //     )
                // }

            }
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
            key: 'remarks',
            // render: (row, record) => {
            //     return <textarea rows={3} cols={25}>{record.remarks}</textarea>
            // },
            width: '100px'
        },
    ];


    // useEffect(() => {
    //     if (data.length > 0) {
    //         console.log('test');
    //         if (table == false) {

    //             let x = am4core.create("chartdiv", am4charts.PieChart3D);
    //             am4core.useTheme(am4themes_animated);
    //             x.paddingRight = 20;
    //             x.data = [{
    //                 "criticality": "(A) High",
    //                 "count": data && data.length > 0 && data[0].a_count,
    //                 "color": am4core.color("#fe4365")
    //             }, {
    //                 "criticality": "(B) Medium",
    //                 "count": data && data.length > 0 && data[0].b_count,
    //                 "color": am4core.color("#ff6054")
    //             },
    //             {
    //                 "criticality": "(C) Low",
    //                 "count": data && data.length > 0 && data[0].c_count,
    //                 "color": am4core.color("#f2746b")
    //             },
    //             ];

    //             var pieSeries = x.series.push(new am4charts.PieSeries3D());
    //             pieSeries.dataFields.value = "count";
    //             pieSeries.dataFields.category = "criticality";
    //             pieSeries.slices.template.propertyFields.fill = "color";
    //             // x.background.fill = '#d1d1d1'
    //             // x.background.width = '100%'
    //             // x.background.height = '100%'
    //             chart.current = x;
    //             x.legend = new am4charts.Legend();

    //             pieSeries.slices.template.events.on("hit", function (ev) {
    //                 console.log(ev.target.dataItem.category, 'eventttt');
    //                 setCriticalityValue(ev.target.dataItem.category)
    //                 setTable(true)
    //                 const payload = [

    //                     authtoken,
    //                     {
    //                         "session_token": authtoken,
    //                         "request": [
    //                             "GetCriticalityStatusDrillDown",
    //                             {
    //                                 "c_ids": [
    //                                     commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
    //                                 ],
    //                                 "d_ids": [
    //                                     commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
    //                                 ],
    //                                 "filter_type": "Group",
    //                                 "filter_ids": [
    //                                     1
    //                                 ],
    //                                 "le_ids": [
    //                                     commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_id
    //                                 ],
    //                                 "country_domains": [
    //                                     {
    //                                         "d_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id,
    //                                         "c_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].c_id,
    //                                         "month_from": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_from,
    //                                         "month_to": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_to
    //                                     }
    //                                 ],
    //                                 "chart_year": 2022,
    //                                 "record_count": 0,
    //                                 "criticality_applicability_status": "(B) Medium",
    //                             }
    //                         ]
    //                     }

    //                 ]

    //                 const payloads = [
    //                     authtoken,
    //                     {
    //                         "session_token": authtoken,
    //                         "request": [
    //                             "GetCriticalityStatusDrillDown",
    //                             {
    //                                 "c_ids": [
    //                                     commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
    //                                 ],
    //                                 "d_ids": [
    //                                     commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
    //                                 ],
    //                                 "filter_type": "Group",
    //                                 "filter_ids": [
    //                                     1
    //                                 ],
    //                                 "chart_year": 2022,
    //                                 "criticality_applicability_status": ev.target.dataItem.category,
    //                                 "record_count": 0,
    //                                 "le_ids": [
    //                                     commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_id
    //                                 ],
    //                                 "country_domains": [
    //                                     {
    //                                         "d_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id,
    //                                         "c_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].c_id,
    //                                         "month_from": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_from,
    //                                         "month_to": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_to
    //                                     }
    //                                 ]
    //                             }
    //                         ]
    //                     }
    //                 ]
    //                 if (paramid != "") {
    //                     getCriticalityTableData({
    //                         payload: payloads,
    //                         paramid: paramid
    //                     })
    //                 }

    //             });

    //         }
    //     }
    // }, [data, table, localStorage.getItem('chart')])

    console.log(commonData, 'commonData');
    console.log(tableData, 'tableData');
    console.log(criticalityTable, 'cricTable');
    console.log(data, 'datadata');
    console.log(tableData1, 'tableData1');
    console.log(chartValue, 'chartValue');

    // return (
    //     <div>
    //         <div className="page-wrapper">
    //             <div className="container-fluid pt-1">
    //                 <div className="col-md-12" style={{ marginBottom: '1%', marginTop: '2%' }}>
    //                     <div className="row">
    //                         <div className="col-md-6">
    //                             <h4><b>Risk Chart</b></h4>
    //                         </div>
    //                         <div className="col-md-6">
    //                             <Button type="primary" className='addbutton' style={{ background: "#0096FF", marginLeft: '80%' }} onClick={() => {
    //                                 setOpen(true)
    //                             }}
    //                                 size='default' >
    //                                 Filter
    //                             </Button>
    //                         </div>
    //                     </div>
    //                 </div>
    //                 {/* {data && data.length > 0 ? */}
    //                 <>
    //                     <div className="row" >
    //                         <div className="col-12">
    //                             <div className="card">
    //                                 <div className='card-body' style={{height:'100%',width:'100%' }}>
    //                                     {table == false ?
    //                                         <>
    //                                             <div style={{ marginLeft: '2%', marginTop: '2%',height:'100%',width:'100%' }}>
    //                                                 <b> <p style={{ textAlign: 'center' }}>Risk Chart of Lulu Groups(2022)</p></b>
    //                                                 {/* <p>{`Generated on :${time}${timeZone}`}</p> */}
    //                                                 <div className="col-md-12">
    //                                                     <div className="row">
    //                                                         <div className="col-md-6">
    //                                                             <div id="chartdiv" style={{marginTop:'10%', width: "220px", height: "200px" }}></div>
    //                                                         </div>
    //                                                         <div className="col-md-6">
    //                                                             <div id="chartdiv1" style={{ width: "220px", height: "200px" }}></div>
    //                                                         </div>
    //                                                     </div>
    //                                                 </div>
    //                                                 <div className="col-md-12">
    //                                                     <div className="row">
    //                                                         <div className="col-md-6">
    //                                                             <b><p>Country-Domain</p></b>
    //                                                             <b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>
    //                                                         </div>
    //                                                         <div className="col-md-6">
    //                                                             <Select
    //                                                                 allowClear={true}
    //                                                                 size="default"
    //                                                                 onChange={(data) => {
    //                                                                     setTimeZone(data)
    //                                                                 }}
    //                                                                 placeholder="Enter Time Zone"
    //                                                                 style={{ marginLeft: '50%' }}
    //                                                             // value={compfie.user_Group}

    //                                                             >

    //                                                                 {TimeZoneValue && TimeZoneValue.tz_list.length > 0 && TimeZoneValue.tz_list.map((item, i) => {

    //                                                                     return (

    //                                                                         <Option key={item.tz_name}>

    //                                                                             {item.tz_name}

    //                                                                         </Option>

    //                                                                     );

    //                                                                 })}
    //                                                                 {/* );
    //                                                         })} */}
    //                                                             </Select>
    //                                                         </div>
    //                                                     </div>
    //                                                 </div>
    //                                             </div>

    //                                         </> :
    //                                         // <div >
    //                                         //     <div className='col-md-12'>
    //                                         //         <div className='row'>
    //                                         //             <div className="col-md-6">
    //                                         //                 <p>Lulu Groups-(A)High Criticaltity</p>
    //                                         //             </div>
    //                                         //             <div className="col-md-2" style={{ marginLeft: '85%' }}>
    //                                         //                 <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#00FF7F" }}
    //                                         //                     icon={<ArrowLeftOutlined />} size='default' onClick={() => {
    //                                         //                         setTable(false)
    //                                         //                     }}>
    //                                         //                     Back
    //                                         //                 </Button>
    //                                         //             </div>
    //                                         //         </div>
    //                                         //     </div><br />

    //                                         //     {/* <div className="col-md-12 mt-3">
    //                                         //         <div className="row"> */}
    //                                         //             <Collapse defaultActiveKey={['0']}>
    //                                         //                 {criticalityTable && criticalityTable.length > 0 &&
    //                                         //                     criticalityTable.map((item) => {
    //                                         //                         let unitName = item.ap_compliances
    //                                         //                         let keys = Object.keys(unitName);
    //                                         //                         let values = Object.values(unitName);
    //                                         //                         let childData = values[0]
    //                                         //                         console.log(values[0],'values');
    //                                         //                         return (
    //                                         //                             <Panel header={item.level1_statutory_name}>
    //                                         //                                 <h5>{keys[0]}</h5>
    //                                         //                                 <Table
    //                                         //                                     columns={columns}
    //                                         //                                     dataSource={childData}
    //                                         //                                     bordered
    //                                         //                                     scroll={{ x: 1500 }}
    //                                         //                                     pagination={false}


    //                                         //                                 />

    //                                         //                             </Panel>
    //                                         //                         )
    //                                         //                     })
    //                                         //                 }
    //                                         //             </Collapse>

    //                                         //         {/* </div>
    //                                         //     </div> */}
    //                                         //     {/* <Table dataSource={dataSource} columns={columns} pagination={false}
    //                                         //         className='userprivclass' /> */}
    //                                         // </div>

    //                                         <Card>
    //                                             <div className="col-md-12">
    //                                                 <div className="row">
    //                                                     <div className="col-md-4">
    //                                                         <b>Lulu Groups-{criticalityValue} Criticaltity</b>
    //                                                     </div>
    //                                                     <div className="col-md-4">

    //                                                     </div>
    //                                                     <div className="col-md-4">
    //                                                         <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#00FF7F", marginLeft: '70%', marginBottom: '2%' }}
    //                                                             icon={<ArrowLeftOutlined />} size='default' onClick={() => {
    //                                                                 setTable(false)
    //                                                             }}>
    //                                                             Back
    //                                                         </Button>
    //                                                     </div>
    //                                                 </div>
    //                                             </div>
    //                                             <div className="col-md-12">
    //                                                 <Collapse defaultActiveKey={['0']}>
    //                                                     {criticalityTable && criticalityTable.length > 0 &&
    //                                                         criticalityTable.map((item) => {
    //                                                             let unitName = item.ap_compliances
    //                                                             let keys = Object.keys(unitName);
    //                                                             let values = Object.values(unitName);
    //                                                             let childData = values[0]
    //                                                             console.log(values[0], 'values');
    //                                                             return (
    //                                                                 <Panel header={item.level1_statutory_name}>
    //                                                                     <p><b>{keys[0]}</b></p>
    //                                                                     <Table
    //                                                                         columns={columns}
    //                                                                         dataSource={childData}
    //                                                                         bordered
    //                                                                         scroll={{ x: 1500 }}
    //                                                                         pagination={false}


    //                                                                     />

    //                                                                 </Panel>
    //                                                             )
    //                                                         })
    //                                                     }
    //                                                 </Collapse>
    //                                             </div>
    //                                         </Card>
    //                                     }
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                     <Drawer title="FILTER BY" placement="right" onClose={onClose} visible={open}>
    //                         <div className="col-md-12">
    //                             <div className="row">
    //                                 <div className="col-md-4">
    //                                     <label><b>Country</b></label>&nbsp;
    //                                 </div>
    //                                 <div className="col-md-8">
    //                                     <Select
    //                                         allowClear={true}
    //                                         size="default"
    //                                         placeholder="Enter Country"
    //                                         onChange={(data) => {
    //                                             setFilterData({
    //                                                 ...filterData,
    //                                                 country: data
    //                                             })
    //                                         }
    //                                         }
    //                                         style={{ width: '100%' }}
    //                                     >
    //                                         {field && field.country && field.country.length && field.country.map((item, i) => {
    //                                             return (
    //                                                 <Option key={item.c_id}>
    //                                                     {item.c_name}
    //                                                 </Option>
    //                                             );
    //                                         })}

    //                                     </Select>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div className="col-md-12 mt-3">
    //                             <div className="row">
    //                                 <div className="col-md-4">
    //                                     <label><b>Domain</b></label>&nbsp;
    //                                 </div>
    //                                 <div className="col-md-8">
    //                                     <Select
    //                                         allowClear={true}
    //                                         size="default"
    //                                         placeholder="Enter Domain"
    //                                         onChange={(data) => {
    //                                             setFilterData({
    //                                                 ...filterData,
    //                                                 domain: data
    //                                             })
    //                                         }
    //                                         }
    //                                         style={{ width: '100%' }}
    //                                     >
    //                                         {field && field.domain && field.domain.length && field.domain.map((item, i) => {
    //                                             return (
    //                                                 <Option key={item.d_id}>
    //                                                     {item.d_name}
    //                                                 </Option>
    //                                             );
    //                                         })}

    //                                     </Select>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div className="col-md-12 mt-3">
    //                             <div className="row">
    //                                 <div className="col-md-1">
    //                                     <input type='checkbox'></input>
    //                                 </div>
    //                                 <div className="col-md-4">
    //                                     <label><b>Group</b></label>
    //                                 </div>

    //                             </div>
    //                         </div>

    //                         <div className="col-md-12 mt-3">
    //                             <div className="row">
    //                                 <div className="col-md-4">
    //                                     <label><b>Business Group</b></label>&nbsp;
    //                                 </div>
    //                                 <div className="col-md-8">
    //                                     <Select
    //                                         allowClear={true}
    //                                         size="default"
    //                                         placeholder="Enter Business Group"
    //                                         onChange={(data) => {
    //                                             setFilterData({
    //                                                 ...filterData,
    //                                                 business: data
    //                                             })
    //                                         }
    //                                         }
    //                                         style={{ width: '100%' }}
    //                                     >
    //                                         {field && field.business && field.business.length && field.business.map((item, i) => {
    //                                             return (
    //                                                 <Option key={item.bg_id}>
    //                                                     {item.bg_name}
    //                                                 </Option>
    //                                             );
    //                                         })}


    //                                     </Select>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div className="col-md-12 mt-3">
    //                             <div className="row">
    //                                 <div className="col-md-4">
    //                                     <label style={{ color: fieldType == 'LegalEntity' ? 'blue' : 'black' }}><b>Legal Entity</b></label>&nbsp;
    //                                 </div>
    //                                 <div className="col-md-8">
    //                                     <Select
    //                                         allowClear={true}
    //                                         size="default"
    //                                         placeholder="Enter Legal Entity"
    //                                         onChange={(data) => {
    //                                             setFilterData({
    //                                                 ...filterData,
    //                                                 legalentity: data
    //                                             })
    //                                             setFieldType('LegalEntity')
    //                                         }
    //                                         }
    //                                         style={{ width: '100%' }}
    //                                     >

    //                                         {field && field.legalEntity && field.legalEntity.length && field.legalEntity.map((item, i) => {
    //                                             return (
    //                                                 <Option key={item.le_id}>
    //                                                     {item.le_name}
    //                                                 </Option>
    //                                             );
    //                                         })}

    //                                     </Select>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div className="col-md-12 mt-3">
    //                             <div className="row">
    //                                 <div className="col-md-4">
    //                                     <label style={{ color: fieldType == 'Division' ? 'blue' : 'black' }}><b>Division</b></label>&nbsp;
    //                                 </div>
    //                                 <div className="col-md-8">
    //                                     <Select
    //                                         allowClear={true}
    //                                         size="default"
    //                                         placeholder="Enter Division"
    //                                         onChange={(data) => {
    //                                             setFilterData({
    //                                                 ...filterData,
    //                                                 division: data
    //                                             })
    //                                             let subData = _.filter(commonData && commonData.cat_info, { div_id: Number(data) })
    //                                             console.log(subData, 'subDatasubData');
    //                                             setCategory(subData)
    //                                             setFieldType('Division')
    //                                         }
    //                                         }
    //                                         style={{ width: '100%' }}
    //                                     >
    //                                         {division && division.length && division.map((item, i) => {
    //                                             return (
    //                                                 <Option key={item.div_id}>
    //                                                     {item.div_name}
    //                                                 </Option>
    //                                             );
    //                                         })}


    //                                     </Select>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div className="col-md-12 mt-3">
    //                             <div className="row">
    //                                 <div className="col-md-4">
    //                                     <label style={{ color: fieldType == 'Category' ? 'blue' : 'black' }}><b>Category</b></label>&nbsp;
    //                                 </div>
    //                                 <div className="col-md-8">
    //                                     <Select
    //                                         allowClear={true}
    //                                         size="default"
    //                                         placeholder="Enter Category"
    //                                         onChange={(data) => {
    //                                             setFilterData({
    //                                                 ...filterData,
    //                                                 category: data
    //                                             })
    //                                             let subData = _.filter(commonData && commonData.chart_units, { category_id: Number(data) })
    //                                             setUnit(subData)
    //                                             setFieldType('Category')
    //                                         }
    //                                         }
    //                                         style={{ width: '100%' }}
    //                                     >
    //                                         {category && category.length && category.map((item, i) => {
    //                                             return (
    //                                                 <Option key={item.cat_id}>
    //                                                     {item.cat_name}
    //                                                 </Option>
    //                                             );
    //                                         })}


    //                                     </Select>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div className="col-md-12 mt-3">
    //                             <div className="row">
    //                                 <div className="col-md-4">
    //                                     <label style={{ color: fieldType == 'Unit' ? 'blue' : 'black' }}><b>Unit</b></label>&nbsp;
    //                                 </div>
    //                                 <div className="col-md-8">
    //                                     <Select
    //                                         allowClear={true}
    //                                         size="default"
    //                                         placeholder="Enter Unit"
    //                                         onChange={(data) => {
    //                                             setFilterData({
    //                                                 ...filterData,
    //                                                 unit: data
    //                                             })
    //                                             setFieldType('Unit')
    //                                         }
    //                                         }
    //                                         style={{ width: '100%' }}
    //                                     >
    //                                         {unit && unit.length > 0 && unit.map((item, i) => {
    //                                             return (
    //                                                 <Option key={item.u_id}>
    //                                                     {item.u_name}
    //                                                 </Option>
    //                                             );
    //                                         })}


    //                                     </Select>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <Button type="primary" className='addbutton' style={{ background: "#0096FF", marginLeft: '25%', marginTop: '10%' }}
    //                             onClick={filterChange}
    //                             size='default' >
    //                             Go
    //                         </Button>
    //                     </Drawer>
    //                 </>
    //                 {/* : <p style={{ marginTop: '18%', marginLeft: '35%' }}>No Data to Display </p>} */}
    //             </div>
    //         </div>
    //     </div>
    // )

    console.log(timeZoneData, 'timeZoneData');
    console.log(remarksHistoryData, 'remarksHistoryData');
    const [fullscreen, setfullscreen] = useState(false);
    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
            document.addEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch), false);
            return () => {
                document.addEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('fullscreenchange', () => handleKeyDown(dispatch), false);
                document.removeEventListener('webkitfullscreenchange', () => handleKeyDown(dispatch));
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode(dispatch);
            setfullscreen(true);
        } else {
            fullscreenexit(dispatch);
            setfullscreen(false);
        }
    }

    return (
        <div>
            <div className="back-to-top">
                <ScrollButton />
            </div>
            <div id='page-wrapper' className="page-wrapper">
                <div className="page-titles py-1 page-title-sticky">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                    </li>
                                    <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                        <span style={{ fontSize: '16px' }}>Dashboard</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <span style={{ fontSize: '16px' }}>Risk Chart</span>
                                    </li>
                                </ol>
                            </nav>
                        </div>

                        <div
                            className="col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex align-items-center justify-content-md-end justify-content-sm-start">
                            {table == false ? <Button icon={<FilterOutlined />} type="primary" className='addbutton' style={{ background: "#0096FF", marginRight: '3%', borderRadius: '15px' }} onClick={() => {
                                setOpen(true)
                            }}
                                size='default' >
                                <Tooltip placement='top' title='Filter'>Filter</Tooltip>
                            </Button> : <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", marginRight: '3%' }}
                                icon={<ArrowLeftOutlined />} size='default' onClick={() => {
                                    setTable(false)
                                    setDelayedChart([])
                                    setCriticalityTable([])
                                    setSearchingValue([])
                                }}>
                                <Tooltip placement='top'>Back</Tooltip>
                            </Button>}




                            <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black  full-mode fullscreenradius" >
                                <Tooltip placement='left' title={is_Fullscreen == true ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                            </button>

                            {/* <button onClick={() => {
            setShowList(true)
            setAddType('add')
        }} className="icons twitter" style={{ border: 'none', margin: '0px' }}>
            <span className="tooltips" style={{ right: '105%' }}>Add</span>
            <span><i id={localStorage.getItem('currentTheme')}><PlusCircleOutlined /></i></span>
        </button> */}


                        </div>
                    </div>
                </div>
                {
                    table == true ?
                        <div className="container-fluid pt-1">
                            {/* <div className='col-md-12'>
                                <div className='row'>
                                    <div className="col-md-4"> */}
                            <p><b>{commonData && commonData.g_name} - {criticalityValue} Compliance</b></p>
                            {/* </div>
                                    <div className="col-md-4">

                                    </div>
                                    <div className="col-md-4">
                                        <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", marginLeft: '70%' }}
                                            icon={<ArrowLeftOutlined />} size='default' onClick={() => {
                                                setTable(false)
                                            }}>
                                            <Tooltip title='Back'>Back</Tooltip>
                                        </Button>
                                    </div>
                                </div>
                            </div><br /> */}
                            {chartValue === 'hiddenRisk' ?
                                <div className="col-md-12">
                                    <div
                                        className={
                                            localStorage.getItem("currentTheme") +
                                            " ant-table-wrapper"
                                        }
                                        style={{ width: '100%' }}
                                    >
                                        <div className="row">

                                            <div className="ant-table ant-table-bordered ant-table-fixed-header service-provider-table"
                                                //  id='service-table-action'
                                                style={{ width: '100%', height: '100%' }}>
                                                <div className="ant-table-container">
                                                    <div className="ant-table-content taskrecalltable service-table">

                                                        <table className="table-fixed" >
                                                            <thead className="ant-table-thead" id='comp-approv-thead'
                                                            // style={{ top: is_Fullscreen ? (showValueModal == true ? 0 : '35px') : (showValueModal == true ? 0 : '107px') }}
                                                            >
                                                                <tr>
                                                                    <th
                                                                        className="ant-table-cell"
                                                                        style={{ width: "42px" }}
                                                                    >
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <p
                                                                                className={"p-0 mb-0"}
                                                                                style={{
                                                                                    // marginLeft: "36%",
                                                                                    display: "inline",
                                                                                    // color: searchingValue1['compliance_task'] ? 'red' : ''
                                                                                }}
                                                                            >
                                                                                #
                                                                            </p>{" "}
                                                                        </div>

                                                                    </th>
                                                                    <th
                                                                        className="ant-table-cell"
                                                                        style={{ width: "89px" }}
                                                                    >
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <p
                                                                                className={"p-0 mb-0"}
                                                                                style={{
                                                                                    // marginLeft: "36%",
                                                                                    display: "inline",
                                                                                    color: searchingValue1['statutory'] ? 'red' : ''
                                                                                }}
                                                                            >
                                                                                Statutory
                                                                            </p>{" "}
                                                                            <Popconfirm
                                                                                afterVisibleChange={() => {
                                                                                    setFocus(myRef11);
                                                                                }}
                                                                                placement="bottom"
                                                                                title={
                                                                                    // <Input
                                                                                    //     ref={myRef1}
                                                                                    //     autoFocus={'on'}
                                                                                    //     placeholder="Compliance Tasks"
                                                                                    //     onKeyUp={(e) => {
                                                                                    //         compliancetaskfilter1(
                                                                                    //             e.target.value,
                                                                                    //             "Compliance_Task"
                                                                                    //         );
                                                                                    //     }}
                                                                                    // />
                                                                                    <div className='position-relative'>
                                                                                        <Input
                                                                                            ref={myRef11}
                                                                                            placeholder="Statutory"
                                                                                            autoFocus={'on'}
                                                                                            onChange={(e) => {
                                                                                                setSearchedValue1({
                                                                                                    ...searchedValue1,
                                                                                                    statutory: e.target.value
                                                                                                });
                                                                                                searchingValue1['statutory'] = e.target.value
                                                                                            }}
                                                                                            value={searchedValue1.statutory || undefined}
                                                                                            onKeyUp={(e) => {
                                                                                                compliancetaskfilter1(
                                                                                                    e.target.value,
                                                                                                    "statutory",
                                                                                                    "statutory"
                                                                                                );

                                                                                            }}
                                                                                        />
                                                                                        <CloseCircleOutlined onClick={() => {
                                                                                            myRef11.current.value = "";
                                                                                            setSearchedValue1({
                                                                                                ...searchedValue1,
                                                                                                statutory: ''
                                                                                            });
                                                                                            searchingValue1['statutory'] = ''
                                                                                            compliancetaskfilter1(
                                                                                                '',
                                                                                                "statutory",
                                                                                                "statutory"

                                                                                            );

                                                                                        }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                    </div>
                                                                                }
                                                                            >
                                                                                <SearchOutlined
                                                                                    style={{ float: 'right', marginTop: '4px' }}
                                                                                />
                                                                            </Popconfirm>
                                                                        </div>

                                                                    </th>
                                                                    <th
                                                                        className="ant-table-cell"
                                                                        style={{ width: "180px" }}
                                                                    >
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <p
                                                                                className={"p-0 mb-0"}
                                                                                style={{
                                                                                    // marginLeft: "36%",
                                                                                    display: "inline",
                                                                                    color: searchingValue1['compliance_task'] ? 'red' : ''
                                                                                }}
                                                                            >
                                                                                Compliance Task
                                                                            </p>{" "}
                                                                            <Popconfirm
                                                                                afterVisibleChange={() => {
                                                                                    setFocus(myRef12);
                                                                                }}
                                                                                placement="bottom"
                                                                                title={
                                                                                    // <Input
                                                                                    //     ref={myRef1}
                                                                                    //     autoFocus={'on'}
                                                                                    //     placeholder="Compliance Tasks"
                                                                                    //     onKeyUp={(e) => {
                                                                                    //         compliancetaskfilter1(
                                                                                    //             e.target.value,
                                                                                    //             "Compliance_Task"
                                                                                    //         );
                                                                                    //     }}
                                                                                    // />
                                                                                    <div className='position-relative'>
                                                                                        <Input
                                                                                            ref={myRef12}
                                                                                            placeholder="Compliance Task"
                                                                                            autoFocus={'on'}
                                                                                            onChange={(e) => {
                                                                                                setSearchedValue1({
                                                                                                    ...searchedValue1,
                                                                                                    compliance_task: e.target.value
                                                                                                });
                                                                                                searchingValue1['compliance_task'] = e.target.value
                                                                                            }}
                                                                                            value={searchedValue1.compliance_task || undefined}
                                                                                            onKeyUp={(e) => {
                                                                                                compliancetaskfilter1(
                                                                                                    e.target.value,
                                                                                                    "compliance_task",
                                                                                                    "compliance_task"
                                                                                                );

                                                                                            }}
                                                                                        />
                                                                                        <CloseCircleOutlined onClick={() => {
                                                                                            myRef12.current.value = "";
                                                                                            setSearchedValue1({
                                                                                                ...searchedValue1,
                                                                                                compliance_task: ''
                                                                                            });
                                                                                            searchingValue1['compliance_task'] = ''
                                                                                            compliancetaskfilter1(
                                                                                                '',
                                                                                                "compliance_task",
                                                                                                "compliance_task"

                                                                                            );

                                                                                        }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                    </div>
                                                                                }
                                                                            >
                                                                                <SearchOutlined
                                                                                    style={{ float: 'right', marginTop: '4px' }}
                                                                                />
                                                                            </Popconfirm>
                                                                        </div>

                                                                    </th>
                                                                    <th
                                                                        className="ant-table-cell"
                                                                        style={{ width: "80px" }}
                                                                    >
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <p
                                                                                className={"p-0 mb-0"}
                                                                                style={{
                                                                                    // marginLeft: "36%",
                                                                                    display: "inline",
                                                                                    color: searchingValue1['criticality'] ? 'red' : ''
                                                                                }}
                                                                            >
                                                                                Criticality
                                                                            </p>{" "}
                                                                            <Popconfirm
                                                                                afterVisibleChange={() => {
                                                                                    setFocus(myRef13);
                                                                                }}
                                                                                placement="bottom"
                                                                                title={
                                                                                    // <Input
                                                                                    //     ref={myRef1}
                                                                                    //     autoFocus={'on'}
                                                                                    //     placeholder="Compliance Tasks"
                                                                                    //     onKeyUp={(e) => {
                                                                                    //         compliancetaskfilter1(
                                                                                    //             e.target.value,
                                                                                    //             "Compliance_Task"
                                                                                    //         );
                                                                                    //     }}
                                                                                    // />
                                                                                    <div className='position-relative'>
                                                                                        <Input
                                                                                            ref={myRef13}
                                                                                            placeholder="Criticality"
                                                                                            autoFocus={'on'}
                                                                                            onChange={(e) => {
                                                                                                setSearchedValue1({
                                                                                                    ...searchedValue1,
                                                                                                    criticality: e.target.value
                                                                                                });
                                                                                                searchingValue1['criticality'] = e.target.value
                                                                                            }}
                                                                                            value={searchedValue1.criticality || undefined}
                                                                                            onKeyUp={(e) => {
                                                                                                compliancetaskfilter1(
                                                                                                    e.target.value,
                                                                                                    "criticality",
                                                                                                    "criticality"
                                                                                                );

                                                                                            }}
                                                                                        />
                                                                                        <CloseCircleOutlined onClick={() => {
                                                                                            myRef13.current.value = "";
                                                                                            setSearchedValue1({
                                                                                                ...searchedValue1,
                                                                                                criticality: ''
                                                                                            });
                                                                                            searchingValue1['criticality'] = ''
                                                                                            compliancetaskfilter1(
                                                                                                '',
                                                                                                "criticality",
                                                                                                "criticality"

                                                                                            );

                                                                                        }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                    </div>
                                                                                }
                                                                            >
                                                                                <SearchOutlined
                                                                                    style={{ float: 'right', marginTop: '4px' }}
                                                                                />
                                                                            </Popconfirm>
                                                                        </div>

                                                                    </th>
                                                                    <th
                                                                        className="ant-table-cell"
                                                                        style={{ width: "180px" }}
                                                                    >
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <p
                                                                                className={"p-0 mb-0"}
                                                                                style={{
                                                                                    // marginLeft: "36%",
                                                                                    display: "inline",
                                                                                    color: searchingValue1['descp'] ? 'red' : ''
                                                                                }}
                                                                            >
                                                                                Compliance Description
                                                                            </p>{" "}
                                                                            <Popconfirm
                                                                                afterVisibleChange={() => {
                                                                                    setFocus(myRef14);
                                                                                }}
                                                                                placement="bottom"
                                                                                title={
                                                                                    // <Input
                                                                                    //     ref={myRef1}
                                                                                    //     autoFocus={'on'}
                                                                                    //     placeholder="Compliance Tasks"
                                                                                    //     onKeyUp={(e) => {
                                                                                    //         compliancetaskfilter1(
                                                                                    //             e.target.value,
                                                                                    //             "Compliance_Task"
                                                                                    //         );
                                                                                    //     }}
                                                                                    // />
                                                                                    <div className='position-relative'>
                                                                                        <Input
                                                                                            ref={myRef14}
                                                                                            placeholder="Compliance Description"
                                                                                            autoFocus={'on'}
                                                                                            onChange={(e) => {
                                                                                                setSearchedValue1({
                                                                                                    ...searchedValue1,
                                                                                                    descp: e.target.value
                                                                                                });
                                                                                                searchingValue1['descp'] = e.target.value
                                                                                            }}
                                                                                            value={searchedValue1.descp || undefined}
                                                                                            onKeyUp={(e) => {
                                                                                                compliancetaskfilter1(
                                                                                                    e.target.value,
                                                                                                    "descp",
                                                                                                    "descp"
                                                                                                );

                                                                                            }}
                                                                                        />
                                                                                        <CloseCircleOutlined onClick={() => {
                                                                                            myRef14.current.value = "";
                                                                                            setSearchedValue1({
                                                                                                ...searchedValue1,
                                                                                                descp: ''
                                                                                            });
                                                                                            searchingValue1['descp'] = ''
                                                                                            compliancetaskfilter1(
                                                                                                '',
                                                                                                "descp",
                                                                                                "descp"

                                                                                            );

                                                                                        }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                    </div>
                                                                                }
                                                                            >
                                                                                <SearchOutlined
                                                                                    style={{ float: 'right', marginTop: '4px' }}
                                                                                />
                                                                            </Popconfirm>
                                                                        </div>

                                                                    </th>
                                                                    <th
                                                                        className="ant-table-cell"
                                                                        style={{ width: "80px" }}
                                                                    >
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <p
                                                                                className={"p-0 mb-0"}
                                                                                style={{
                                                                                    // marginLeft: "36%",
                                                                                    display: "inline",
                                                                                    color: searchingValue1['p_cons'] ? 'red' : ''
                                                                                }}
                                                                            >
                                                                                Penal Consequences
                                                                            </p>{" "}
                                                                            <Popconfirm
                                                                                afterVisibleChange={() => {
                                                                                    setFocus(myRef15);
                                                                                }}
                                                                                placement="bottom"
                                                                                title={
                                                                                    // <Input
                                                                                    //     ref={myRef1}
                                                                                    //     autoFocus={'on'}
                                                                                    //     placeholder="Compliance Tasks"
                                                                                    //     onKeyUp={(e) => {
                                                                                    //         compliancetaskfilter1(
                                                                                    //             e.target.value,
                                                                                    //             "Compliance_Task"
                                                                                    //         );
                                                                                    //     }}
                                                                                    // />
                                                                                    <div className='position-relative'>
                                                                                        <Input
                                                                                            ref={myRef15}
                                                                                            placeholder="Penal Consequences"
                                                                                            autoFocus={'on'}
                                                                                            onChange={(e) => {
                                                                                                setSearchedValue1({
                                                                                                    ...searchedValue1,
                                                                                                    p_cons: e.target.value
                                                                                                });
                                                                                                searchingValue1['p_cons'] = e.target.value
                                                                                            }}
                                                                                            value={searchedValue1.p_cons || undefined}
                                                                                            onKeyUp={(e) => {
                                                                                                compliancetaskfilter1(
                                                                                                    e.target.value,
                                                                                                    "p_cons",
                                                                                                    "p_cons"
                                                                                                );

                                                                                            }}
                                                                                        />
                                                                                        <CloseCircleOutlined onClick={() => {
                                                                                            myRef15.current.value = "";
                                                                                            setSearchedValue1({
                                                                                                ...searchedValue1,
                                                                                                p_cons: ''
                                                                                            });
                                                                                            searchingValue1['p_cons'] = ''
                                                                                            compliancetaskfilter1(
                                                                                                '',
                                                                                                "p_cons",
                                                                                                "p_cons"

                                                                                            );

                                                                                        }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                    </div>
                                                                                }
                                                                            >
                                                                                <SearchOutlined
                                                                                    style={{ float: 'right', marginTop: '4px' }}
                                                                                />
                                                                            </Popconfirm>
                                                                        </div>

                                                                    </th>
                                                                    <th
                                                                        className="ant-table-cell"
                                                                        style={{ width: "80px" }}
                                                                    >
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <p
                                                                                className={"p-0 mb-0"}
                                                                                style={{
                                                                                    // marginLeft: "36%",
                                                                                    display: "inline",
                                                                                    color: searchingValue1['comp_frequency'] ? 'red' : ''
                                                                                }}
                                                                            >
                                                                                Compliance Frequency
                                                                            </p>{" "}
                                                                            <Popconfirm
                                                                                afterVisibleChange={() => {
                                                                                    setFocus(myRef16);
                                                                                }}
                                                                                placement="bottom"
                                                                                title={
                                                                                    // <Input
                                                                                    //     ref={myRef1}
                                                                                    //     autoFocus={'on'}
                                                                                    //     placeholder="Compliance Tasks"
                                                                                    //     onKeyUp={(e) => {
                                                                                    //         compliancetaskfilter1(
                                                                                    //             e.target.value,
                                                                                    //             "Compliance_Task"
                                                                                    //         );
                                                                                    //     }}
                                                                                    // />
                                                                                    <div className='position-relative'>
                                                                                        <Input
                                                                                            ref={myRef16}
                                                                                            placeholder="Compliance Frequency"
                                                                                            autoFocus={'on'}
                                                                                            onChange={(e) => {
                                                                                                setSearchedValue1({
                                                                                                    ...searchedValue1,
                                                                                                    comp_frequency: e.target.value
                                                                                                });
                                                                                                searchingValue1['comp_frequency'] = e.target.value
                                                                                            }}
                                                                                            value={searchedValue1.comp_frequency || undefined}
                                                                                            onKeyUp={(e) => {
                                                                                                compliancetaskfilter1(
                                                                                                    e.target.value,
                                                                                                    "comp_frequency",
                                                                                                    "comp_frequency"
                                                                                                );

                                                                                            }}
                                                                                        />
                                                                                        <CloseCircleOutlined onClick={() => {
                                                                                            myRef16.current.value = "";
                                                                                            setSearchedValue1({
                                                                                                ...searchedValue1,
                                                                                                comp_frequency: ''
                                                                                            });
                                                                                            searchingValue1['comp_frequency'] = ''
                                                                                            compliancetaskfilter1(
                                                                                                '',
                                                                                                "comp_frequency",
                                                                                                "comp_frequency"

                                                                                            );

                                                                                        }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                    </div>
                                                                                }
                                                                            >
                                                                                <SearchOutlined
                                                                                    style={{ float: 'right', marginTop: '4px' }}
                                                                                />
                                                                            </Popconfirm>
                                                                        </div>

                                                                    </th>
                                                                    <th
                                                                        className="ant-table-cell"
                                                                        style={{ width: "80px" }}
                                                                    >
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <p
                                                                                className={"p-0 mb-0"}
                                                                                style={{
                                                                                    // marginLeft: "36%",
                                                                                    display: "inline",
                                                                                    color: searchingValue1['repeats'] ? 'red' : ''
                                                                                }}
                                                                            >
                                                                                Repeats
                                                                            </p>{" "}
                                                                            <Popconfirm
                                                                                afterVisibleChange={() => {
                                                                                    setFocus(myRef17);
                                                                                }}
                                                                                placement="bottom"
                                                                                title={
                                                                                    // <Input
                                                                                    //     ref={myRef1}
                                                                                    //     autoFocus={'on'}
                                                                                    //     placeholder="Compliance Tasks"
                                                                                    //     onKeyUp={(e) => {
                                                                                    //         compliancetaskfilter1(
                                                                                    //             e.target.value,
                                                                                    //             "Compliance_Task"
                                                                                    //         );
                                                                                    //     }}
                                                                                    // />
                                                                                    <div className='position-relative'>
                                                                                        <Input
                                                                                            ref={myRef17}
                                                                                            placeholder="Repeats"
                                                                                            autoFocus={'on'}
                                                                                            onChange={(e) => {
                                                                                                setSearchedValue1({
                                                                                                    ...searchedValue1,
                                                                                                    repeats: e.target.value
                                                                                                });
                                                                                                searchingValue1['repeats'] = e.target.value
                                                                                            }}
                                                                                            value={searchedValue1.repeats || undefined}
                                                                                            onKeyUp={(e) => {
                                                                                                compliancetaskfilter1(
                                                                                                    e.target.value,
                                                                                                    "repeats",
                                                                                                    "repeats"
                                                                                                );

                                                                                            }}
                                                                                        />
                                                                                        <CloseCircleOutlined onClick={() => {
                                                                                            myRef17.current.value = "";
                                                                                            setSearchedValue1({
                                                                                                ...searchedValue1,
                                                                                                repeats: ''
                                                                                            });
                                                                                            searchingValue1['repeats'] = ''
                                                                                            compliancetaskfilter1(
                                                                                                '',
                                                                                                "repeats",
                                                                                                "repeats"

                                                                                            );

                                                                                        }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                    </div>
                                                                                }
                                                                            >
                                                                                <SearchOutlined
                                                                                    style={{ float: 'right', marginTop: '4px' }}
                                                                                />
                                                                            </Popconfirm>
                                                                        </div>

                                                                    </th>
                                                                    <th
                                                                        className="ant-table-cell"
                                                                        style={{ width: "80px" }}
                                                                    >
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <p
                                                                                className={"p-0 mb-0"}
                                                                                style={{
                                                                                    // marginLeft: "36%",
                                                                                    display: "inline",
                                                                                    // color: searchingValue1['trigger'] ? 'red' : ''
                                                                                }}
                                                                            >
                                                                                Trigger Before
                                                                            </p>{" "}
                                                                            <Popconfirm
                                                                                afterVisibleChange={() => {
                                                                                    setFocus(myRef18);
                                                                                }}
                                                                                placement="bottomLeft"
                                                                                title={
                                                                                    // <Input
                                                                                    //     ref={myRef1}
                                                                                    //     autoFocus={'on'}
                                                                                    //     placeholder="Trigger Before"
                                                                                    //     onKeyUp={(e) => {
                                                                                    //         compliancetaskfilter1(
                                                                                    //             e.target.value,
                                                                                    //             "Compliance_Task"
                                                                                    //         );
                                                                                    //     }}
                                                                                    // />
                                                                                    <div className='position-relative'>
                                                                                        <Input
                                                                                            ref={myRef18}
                                                                                            placeholder="Trigger Before"
                                                                                            autoFocus={'on'}
                                                                                            onChange={(e) => {
                                                                                                setSearchedValue1({
                                                                                                    ...searchedValue1,
                                                                                                    trigger: e.target.value
                                                                                                });
                                                                                                searchingValue1['trigger'] = e.target.value
                                                                                            }}
                                                                                            value={searchedValue1.trigger || undefined}
                                                                                            onKeyUp={(e) => {
                                                                                                compliancetaskfilter1(
                                                                                                    e.target.value,
                                                                                                    "trigger",
                                                                                                    "trigger"
                                                                                                );

                                                                                            }}
                                                                                        />
                                                                                        <CloseCircleOutlined onClick={() => {
                                                                                            myRef18.current.value = "";
                                                                                            setSearchedValue1({
                                                                                                ...searchedValue1,
                                                                                                trigger: ''
                                                                                            });
                                                                                            searchingValue1['trigger'] = ''
                                                                                            compliancetaskfilter1(
                                                                                                '',
                                                                                                "trigger",
                                                                                                "trigger"

                                                                                            );

                                                                                        }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                    </div>
                                                                                }
                                                                            >
                                                                                <SearchOutlined
                                                                                    style={{ float: 'right', marginTop: '4px' }}
                                                                                />
                                                                            </Popconfirm>
                                                                        </div>

                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tr>
                                                                <td colSpan={9}>
                                                                    <Collapse className='cric mt-2' defaultActiveKey={['0']}>
                                                                        {customTaskDisTable && customTaskDisTable.length > 0 &&
                                                                            customTaskDisTable.map((item, i) => {
                                                                                console.log(item.level1_statutory_name, 'item');
                                                                                // let unitName = item.ap_compliances
                                                                                // let keys = Object.keys(unitName);
                                                                                // let values = Object.values(unitName);
                                                                                return (
                                                                                    <Panel style={{ marginBottom: '5px' }} header={item[0][0].level1_statutory_name}>
                                                                                        <table>
                                                                                            <tbody className="ant-table-tbody">
                                                                                                {item && item.length > 0 && item.map((items, m) => {
                                                                                                    console.log(items, 'items');
                                                                                                    return (<>
                                                                                                        <tr className="ant-table-row ant-table-row-level-0">
                                                                                                            <td colSpan={8}>
                                                                                                                <h6 className='m-2 ml-2 mb-2'>{items[0].leUname}</h6>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        {items.map((childItems, s) => {
                                                                                                            return (
                                                                                                                <tr className="ant-table-row ant-table-row-level-0">
                                                                                                                    <td style={{ width: '50px' }}
                                                                                                                        className="ant-table-cell border-end">{childItems.index}</td>
                                                                                                                    <td
                                                                                                                        className="ant-table-cell border-end">{childItems.s_prov}</td>
                                                                                                                    <td style={{ width: '225px' }}
                                                                                                                        className="ant-table-cell border-end">
                                                                                                                        {
                                                                                                                            childItems.download_url_list ?
                                                                                                                                <a href={`${urls + childItems.download_url_list}`} target='_blank'>{childItems.comp_name}</a> :
                                                                                                                                <span>{childItems.comp_name}</span>
                                                                                                                        }</td>
                                                                                                                    <td style={{ width: '107px' }}
                                                                                                                        className="ant-table-cell border-end">{childItems.criticality}</td>
                                                                                                                    <td style={{ width: '225px' }}
                                                                                                                        className="ant-table-cell border-end">{childItems.descp}</td>
                                                                                                                    {/* <td style={{ width: '299px' }}
                                                                                                                         className="ant-table-cell border-end">
                                                                                                                         {childItems.compfielaw_url != '' ?
                                                                                                                             <>
                                                                                                                                 <Popover content={content} title="">
                                                                                                                                     <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
                                                                                                                                 </Popover>
                                                                                                                                 <Tooltip style={{ marginRight: '20%' }} title='Compfie Law Knowlegde Portal'>
                                                                                                                                     <a href={`${childItems.compfielaw_url}`}>
                                                                                                                                         <BookTwoTone />
                                                                                                                                     </a>
                                                                                                                                 </Tooltip>
                                                                                                                             </> :
                                                                                                                             <Popover content={content} title="">
                                                                                                                                 <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
                                                                                                                             </Popover>
                                                                                                                         }
                                                                                                                         {childItems.comp_name}</td> */}
                                                                                                                    <td style={{ width: '147px' }}
                                                                                                                        className="ant-table-cell border-end">{childItems.p_cons}</td>
                                                                                                                    <td style={{ width: '123px' }}
                                                                                                                        className="ant-table-cell border-end">{childItems.frequency}</td>
                                                                                                                    <td style={{ width: '101px' }}
                                                                                                                        className="ant-table-cell border-end">{childItems.summary}</td>
                                                                                                                    <td style={{ width: '98px' }}
                                                                                                                        className="ant-table-cell border-end">{childItems.trigger_before_days != 'null Days' ? childItems.trigger_before_days : ''}</td>
                                                                                                                </tr>
                                                                                                            )
                                                                                                        })}
                                                                                                    </>)
                                                                                                })}
                                                                                            </tbody >
                                                                                        </table>

                                                                                        {/* <Table
                                                                                             scroll={{ x: 1200 }}
                                                                                             size={'small'}
                                                                                             columns={columns}
                                                                                             dataSource={values[j]}
                                                                                             bordered
                                                                                             id='service-user-priv'
                                                                                             className={localStorage.getItem("currentTheme")}
                                                                                             pagination={false}
                                                                                             onChange={handleChange}


                                                                                         /> */}

                                                                                    </Panel>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Collapse>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="col-md-12">
                                    <div
                                        className={
                                            localStorage.getItem("currentTheme") +
                                            " ant-table-wrapper"
                                        }
                                        style={{ width: '100%' }}
                                    >
                                        <div className="row">

                                            <div className="ant-table ant-table-bordered ant-table-fixed-header service-provider-table"
                                                //  id='service-table-action'
                                                style={{ width: '100%', height: '100%' }}>
                                                <div className="ant-table-container">
                                                    <div className="ant-table-content taskrecalltable service-table">

                                                        <table className="table-fixed" >
                                                            <thead className="ant-table-thead" id='comp-approv-thead'
                                                            // style={{ top: is_Fullscreen ? (showValueModal == true ? 0 : '35px') : (showValueModal == true ? 0 : '107px') }}
                                                            >
                                                                <tr>
                                                                    <th
                                                                        className="ant-table-cell"
                                                                        style={{ width: "32px" }}
                                                                    >
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <p
                                                                                className={"p-0 mb-0"}
                                                                                style={{
                                                                                    // marginLeft: "36%",
                                                                                    display: "inline",
                                                                                    // color: searchingValue['compliance_task'] ? 'red' : ''
                                                                                }}
                                                                            >
                                                                                #
                                                                            </p>{" "}
                                                                        </div>

                                                                    </th>
                                                                    {(fieldType === 'BusinessGroup') || (fieldType === 'LegalEntity') || (fieldType === 'Division') || (fieldType === 'Category') || (fieldType === 'Unit') ? '' :
                                                                        <th
                                                                            className="ant-table-cell"
                                                                            style={{ width: "100px" }}
                                                                        >
                                                                            <div className="d-flex justify-content-between align-items-center">
                                                                                <p
                                                                                    className={"p-0 mb-0"}
                                                                                    style={{
                                                                                        // marginLeft: "36%",
                                                                                        display: "inline",
                                                                                        color: searchingValue['business_group'] ? 'red' : ''
                                                                                    }}
                                                                                >
                                                                                    Business Group
                                                                                </p>{" "}
                                                                                <Popconfirm
                                                                                    afterVisibleChange={() => {
                                                                                        setFocus(myRef1);
                                                                                    }}
                                                                                    placement="bottom"
                                                                                    title={
                                                                                        // <Input
                                                                                        //     ref={myRef1}
                                                                                        //     autoFocus={'on'}
                                                                                        //     placeholder="Compliance Tasks"
                                                                                        //     onKeyUp={(e) => {
                                                                                        //         compliancetaskfilter(
                                                                                        //             e.target.value,
                                                                                        //             "Compliance_Task"
                                                                                        //         );
                                                                                        //     }}
                                                                                        // />
                                                                                        <div className='position-relative'>
                                                                                            <Input
                                                                                                ref={myRef1}
                                                                                                placeholder="Business Group"
                                                                                                autoFocus={'on'}
                                                                                                onChange={(e) => {
                                                                                                    setSearchedValue({
                                                                                                        ...searchedValue,
                                                                                                        bgGroup: e.target.value
                                                                                                    });
                                                                                                    searchingValue['business_group'] = e.target.value
                                                                                                }}
                                                                                                value={searchedValue.bgGroup || undefined}
                                                                                                onKeyUp={(e) => {
                                                                                                    compliancetaskfilter(
                                                                                                        e.target.value,
                                                                                                        "business_group",
                                                                                                        "business_group"
                                                                                                    );

                                                                                                }}
                                                                                            />
                                                                                            <CloseCircleOutlined onClick={() => {
                                                                                                myRef1.current.value = "";
                                                                                                setSearchedValue({
                                                                                                    ...searchedValue,
                                                                                                    complianceTasks: ''
                                                                                                });
                                                                                                searchingValue['business_group'] = ''
                                                                                                compliancetaskfilter(
                                                                                                    '',
                                                                                                    "business_group",
                                                                                                    "business_group"

                                                                                                );

                                                                                            }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                        </div>
                                                                                    }
                                                                                >
                                                                                    <SearchOutlined
                                                                                        style={{ float: 'right', marginTop: '4px' }}
                                                                                    />
                                                                                </Popconfirm>
                                                                            </div>

                                                                        </th>
                                                                    }
                                                                    {(fieldType === 'LegalEntity') || (fieldType === 'Division') || (fieldType === 'Category') || (fieldType === 'Unit') ? '' :
                                                                        <th
                                                                            className="ant-table-cell"
                                                                            style={{ width: "80px" }}
                                                                        >
                                                                            <div className="d-flex justify-content-between align-items-center">
                                                                                <p
                                                                                    className={"p-0 mb-0"}
                                                                                    style={{
                                                                                        // marginLeft: "36%",
                                                                                        display: "inline",
                                                                                        color: searchingValue['legal_entity'] ? 'red' : ''
                                                                                    }}
                                                                                >
                                                                                    Legal Entity
                                                                                </p>{" "}
                                                                                <Popconfirm
                                                                                    afterVisibleChange={() => {
                                                                                        setFocus(myRef2);
                                                                                    }}
                                                                                    placement="bottom"
                                                                                    title={
                                                                                        // <Input
                                                                                        //     ref={myRef1}
                                                                                        //     autoFocus={'on'}
                                                                                        //     placeholder="Compliance Tasks"
                                                                                        //     onKeyUp={(e) => {
                                                                                        //         compliancetaskfilter(
                                                                                        //             e.target.value,
                                                                                        //             "Compliance_Task"
                                                                                        //         );
                                                                                        //     }}
                                                                                        // />
                                                                                        <div className='position-relative'>
                                                                                            <Input
                                                                                                ref={myRef2}
                                                                                                placeholder="Legal Entity"
                                                                                                autoFocus={'on'}
                                                                                                onChange={(e) => {
                                                                                                    setSearchedValue({
                                                                                                        ...searchedValue,
                                                                                                        legal_entity: e.target.value
                                                                                                    });
                                                                                                    searchingValue['legal_entity'] = e.target.value
                                                                                                }}
                                                                                                value={searchedValue.legal_entity || undefined}
                                                                                                onKeyUp={(e) => {
                                                                                                    compliancetaskfilter(
                                                                                                        e.target.value,
                                                                                                        "legal_entity",
                                                                                                        "legal_entity"
                                                                                                    );

                                                                                                }}
                                                                                            />
                                                                                            <CloseCircleOutlined onClick={() => {
                                                                                                myRef2.current.value = "";
                                                                                                setSearchedValue({
                                                                                                    ...searchedValue,
                                                                                                    legal_entity: ''
                                                                                                });
                                                                                                searchingValue['legal_entity'] = ''
                                                                                                compliancetaskfilter(
                                                                                                    '',
                                                                                                    "legal_entity",
                                                                                                    "legal_entity"

                                                                                                );

                                                                                            }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                        </div>
                                                                                    }
                                                                                >
                                                                                    <SearchOutlined
                                                                                        style={{ float: 'right', marginTop: '4px' }}
                                                                                    />
                                                                                </Popconfirm>
                                                                            </div>

                                                                        </th>
                                                                    }
                                                                    {(fieldType === 'Division') || (fieldType === 'Category') || (fieldType === 'Unit') ? '' :
                                                                        <th
                                                                            className="ant-table-cell"
                                                                            style={{ width: "80px" }}
                                                                        >
                                                                            <div className="d-flex justify-content-between align-items-center">
                                                                                <p
                                                                                    className={"p-0 mb-0"}
                                                                                    style={{
                                                                                        // marginLeft: "36%",
                                                                                        display: "inline",
                                                                                        color: searchingValue['division'] ? 'red' : ''
                                                                                    }}
                                                                                >
                                                                                    Division
                                                                                </p>{" "}
                                                                                <Popconfirm
                                                                                    afterVisibleChange={() => {
                                                                                        setFocus(myRef3);
                                                                                    }}
                                                                                    placement="bottom"
                                                                                    title={
                                                                                        // <Input
                                                                                        //     ref={myRef1}
                                                                                        //     autoFocus={'on'}
                                                                                        //     placeholder="Compliance Tasks"
                                                                                        //     onKeyUp={(e) => {
                                                                                        //         compliancetaskfilter(
                                                                                        //             e.target.value,
                                                                                        //             "Compliance_Task"
                                                                                        //         );
                                                                                        //     }}
                                                                                        // />
                                                                                        <div className='position-relative'>
                                                                                            <Input
                                                                                                ref={myRef3}
                                                                                                placeholder="Division"
                                                                                                autoFocus={'on'}
                                                                                                onChange={(e) => {
                                                                                                    setSearchedValue({
                                                                                                        ...searchedValue,
                                                                                                        division: e.target.value
                                                                                                    });
                                                                                                    searchingValue['division'] = e.target.value
                                                                                                }}
                                                                                                value={searchedValue.division || undefined}
                                                                                                onKeyUp={(e) => {
                                                                                                    compliancetaskfilter(
                                                                                                        e.target.value,
                                                                                                        "division",
                                                                                                        "division"
                                                                                                    );

                                                                                                }}
                                                                                            />
                                                                                            <CloseCircleOutlined onClick={() => {
                                                                                                myRef3.current.value = "";
                                                                                                setSearchedValue({
                                                                                                    ...searchedValue,
                                                                                                    division: ''
                                                                                                });
                                                                                                searchingValue['division'] = ''
                                                                                                compliancetaskfilter(
                                                                                                    '',
                                                                                                    "division",
                                                                                                    "division"

                                                                                                );

                                                                                            }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                        </div>
                                                                                    }
                                                                                >
                                                                                    <SearchOutlined
                                                                                        style={{ float: 'right', marginTop: '4px' }}
                                                                                    />
                                                                                </Popconfirm>
                                                                            </div>

                                                                        </th>}

                                                                    <th
                                                                        className="ant-table-cell"
                                                                        style={{ width: "180px" }}
                                                                    >
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <p
                                                                                className={"p-0 mb-0"}
                                                                                style={{
                                                                                    // marginLeft: "36%",
                                                                                    display: "inline",
                                                                                    color: searchingValue['compliance'] ? 'red' : ''
                                                                                }}
                                                                            >
                                                                                Compliance
                                                                            </p>{" "}
                                                                            <Popconfirm
                                                                                afterVisibleChange={() => {
                                                                                    setFocus(myRef4);
                                                                                }}
                                                                                placement="bottom"
                                                                                title={
                                                                                    // <Input
                                                                                    //     ref={myRef1}
                                                                                    //     autoFocus={'on'}
                                                                                    //     placeholder="Compliance Tasks"
                                                                                    //     onKeyUp={(e) => {
                                                                                    //         compliancetaskfilter(
                                                                                    //             e.target.value,
                                                                                    //             "Compliance_Task"
                                                                                    //         );
                                                                                    //     }}
                                                                                    // />
                                                                                    <div className='position-relative'>
                                                                                        <Input
                                                                                            ref={myRef4}
                                                                                            placeholder="Compliance"
                                                                                            autoFocus={'on'}
                                                                                            onChange={(e) => {
                                                                                                setSearchedValue({
                                                                                                    ...searchedValue,
                                                                                                    compliance: e.target.value
                                                                                                });
                                                                                                searchingValue['compliance'] = e.target.value
                                                                                            }}
                                                                                            value={searchedValue.compliance || undefined}
                                                                                            onKeyUp={(e) => {
                                                                                                compliancetaskfilter(
                                                                                                    e.target.value,
                                                                                                    "compliance",
                                                                                                    "compliance"
                                                                                                );

                                                                                            }}
                                                                                        />
                                                                                        <CloseCircleOutlined onClick={() => {
                                                                                            myRef4.current.value = "";
                                                                                            setSearchedValue({
                                                                                                ...searchedValue,
                                                                                                compliance: ''
                                                                                            });
                                                                                            searchingValue['compliance'] = ''
                                                                                            compliancetaskfilter(
                                                                                                '',
                                                                                                "compliance",
                                                                                                "compliance"

                                                                                            );

                                                                                        }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                    </div>
                                                                                }
                                                                            >
                                                                                <SearchOutlined
                                                                                    style={{ float: 'right', marginTop: '4px' }}
                                                                                />
                                                                            </Popconfirm>
                                                                        </div>

                                                                    </th>
                                                                    <th
                                                                        className="ant-table-cell"
                                                                        style={{ width: "80px" }}
                                                                    >
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <p
                                                                                className={"p-0 mb-0"}
                                                                                style={{
                                                                                    // marginLeft: "36%",
                                                                                    display: "inline",
                                                                                    color: searchingValue['criticality'] ? 'red' : ''
                                                                                }}
                                                                            >
                                                                                Criticality
                                                                            </p>{" "}
                                                                            <Popconfirm
                                                                                afterVisibleChange={() => {
                                                                                    setFocus(myRef5);
                                                                                }}
                                                                                placement="bottom"
                                                                                title={
                                                                                    // <Input
                                                                                    //     ref={myRef1}
                                                                                    //     autoFocus={'on'}
                                                                                    //     placeholder="Compliance Tasks"
                                                                                    //     onKeyUp={(e) => {
                                                                                    //         compliancetaskfilter(
                                                                                    //             e.target.value,
                                                                                    //             "Compliance_Task"
                                                                                    //         );
                                                                                    //     }}
                                                                                    // />
                                                                                    <div className='position-relative'>
                                                                                        <Input
                                                                                            ref={myRef5}
                                                                                            placeholder="Criticality"
                                                                                            autoFocus={'on'}
                                                                                            onChange={(e) => {
                                                                                                setSearchedValue({
                                                                                                    ...searchedValue,
                                                                                                    criticality: e.target.value
                                                                                                });
                                                                                                searchingValue['criticality'] = e.target.value
                                                                                            }}
                                                                                            value={searchedValue.criticality || undefined}
                                                                                            onKeyUp={(e) => {
                                                                                                compliancetaskfilter(
                                                                                                    e.target.value,
                                                                                                    "criticality",
                                                                                                    "criticality"
                                                                                                );

                                                                                            }}
                                                                                        />
                                                                                        <CloseCircleOutlined onClick={() => {
                                                                                            myRef5.current.value = "";
                                                                                            setSearchedValue({
                                                                                                ...searchedValue,
                                                                                                criticality: ''
                                                                                            });
                                                                                            searchingValue['criticality'] = ''
                                                                                            compliancetaskfilter(
                                                                                                '',
                                                                                                "criticality",
                                                                                                "criticality"

                                                                                            );

                                                                                        }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                    </div>
                                                                                }
                                                                            >
                                                                                <SearchOutlined
                                                                                    style={{ float: 'right', marginTop: '4px' }}
                                                                                />
                                                                            </Popconfirm>
                                                                        </div>

                                                                    </th>
                                                                    <th
                                                                        className="ant-table-cell"
                                                                        style={{ width: "80px" }}
                                                                    >
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <p
                                                                                className={"p-0 mb-0"}
                                                                                style={{
                                                                                    // marginLeft: "36%",
                                                                                    display: "inline",
                                                                                    color: searchingValue['assignedTo'] ? 'red' : ''
                                                                                }}
                                                                            >
                                                                                Assigned To
                                                                            </p>{" "}
                                                                            <Popconfirm
                                                                                afterVisibleChange={() => {
                                                                                    setFocus(myRef6);
                                                                                }}
                                                                                placement="bottom"
                                                                                title={
                                                                                    // <Input
                                                                                    //     ref={myRef1}
                                                                                    //     autoFocus={'on'}
                                                                                    //     placeholder="Compliance Tasks"
                                                                                    //     onKeyUp={(e) => {
                                                                                    //         compliancetaskfilter(
                                                                                    //             e.target.value,
                                                                                    //             "Compliance_Task"
                                                                                    //         );
                                                                                    //     }}
                                                                                    // />
                                                                                    <div className='position-relative'>
                                                                                        <Input
                                                                                            ref={myRef6}
                                                                                            placeholder="Assigned To"
                                                                                            autoFocus={'on'}
                                                                                            onChange={(e) => {
                                                                                                setSearchedValue({
                                                                                                    ...searchedValue,
                                                                                                    assignedTo: e.target.value
                                                                                                });
                                                                                                searchingValue['assignedTo'] = e.target.value
                                                                                            }}
                                                                                            value={searchedValue.assignedTo || undefined}
                                                                                            onKeyUp={(e) => {
                                                                                                compliancetaskfilter(
                                                                                                    e.target.value,
                                                                                                    "assignedTo",
                                                                                                    "assignedTo"
                                                                                                );

                                                                                            }}
                                                                                        />
                                                                                        <CloseCircleOutlined onClick={() => {
                                                                                            myRef6.current.value = "";
                                                                                            setSearchedValue({
                                                                                                ...searchedValue,
                                                                                                assignedTo: ''
                                                                                            });
                                                                                            searchingValue['assignedTo'] = ''
                                                                                            compliancetaskfilter(
                                                                                                '',
                                                                                                "assignedTo",
                                                                                                "assignedTo"

                                                                                            );

                                                                                        }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                    </div>
                                                                                }
                                                                            >
                                                                                <SearchOutlined
                                                                                    style={{ float: 'right', marginTop: '4px' }}
                                                                                />
                                                                            </Popconfirm>
                                                                        </div>

                                                                    </th>
                                                                    {criticalityValue === 'Complied' ? '' :
                                                                        <th
                                                                            className="ant-table-cell"
                                                                            style={{ width: "80px" }}
                                                                        >
                                                                            <div className="d-flex justify-content-between align-items-center">
                                                                                <p
                                                                                    className={"p-0 mb-0"}
                                                                                    style={{
                                                                                        // marginLeft: "36%",
                                                                                        display: "inline",
                                                                                        color: searchingValue['overdue'] ? 'red' : ''
                                                                                    }}
                                                                                >
                                                                                    {criticalityValue === 'Delayed Compliance' ? 'Delayed By' : 'Over Due'}
                                                                                </p>{" "}
                                                                                <Popconfirm
                                                                                    afterVisibleChange={() => {
                                                                                        setFocus(myRef7);
                                                                                    }}
                                                                                    placement="bottomLeft"
                                                                                    title={
                                                                                        // <Input
                                                                                        //     ref={myRef1}
                                                                                        //     autoFocus={'on'}
                                                                                        //     placeholder="Compliance Tasks"
                                                                                        //     onKeyUp={(e) => {
                                                                                        //         compliancetaskfilter(
                                                                                        //             e.target.value,
                                                                                        //             "Compliance_Task"
                                                                                        //         );
                                                                                        //     }}
                                                                                        // />
                                                                                        <div className='position-relative'>
                                                                                            <Input
                                                                                                ref={myRef7}
                                                                                                placeholder="Over Due"
                                                                                                autoFocus={'on'}
                                                                                                onChange={(e) => {
                                                                                                    setSearchedValue({
                                                                                                        ...searchedValue,
                                                                                                        overdue: e.target.value
                                                                                                    });
                                                                                                    searchingValue['overdue'] = e.target.value
                                                                                                }}
                                                                                                value={searchedValue.overdue || undefined}
                                                                                                onKeyUp={(e) => {
                                                                                                    compliancetaskfilter(
                                                                                                        e.target.value,
                                                                                                        "overdue",
                                                                                                        "overdue"
                                                                                                    );

                                                                                                }}
                                                                                            />
                                                                                            <CloseCircleOutlined onClick={() => {
                                                                                                myRef7.current.value = "";
                                                                                                setSearchedValue({
                                                                                                    ...searchedValue,
                                                                                                    overdue: ''
                                                                                                });
                                                                                                searchingValue['overdue'] = ''
                                                                                                compliancetaskfilter(
                                                                                                    '',
                                                                                                    "overdue",
                                                                                                    "overdue"

                                                                                                );

                                                                                            }} style={{ top: '3px', right: '3px', borderRadius: '8px', padding: '6px', background: 'white' }} className='position-absolute' />
                                                                                        </div>
                                                                                    }
                                                                                >
                                                                                    <SearchOutlined
                                                                                        style={{ float: 'right', marginTop: '4px' }}
                                                                                    />
                                                                                </Popconfirm>
                                                                            </div>

                                                                        </th>
                                                                    }
                                                                </tr>
                                                            </thead>
                                                            <tr>
                                                                <td colSpan={8}>
                                                                    <Collapse className='cric' defaultActiveKey={['0']}>

                                                                        {tempcriticalityTable && tempcriticalityTable.length > 0 &&
                                                                            tempcriticalityTable.map((items, i) => {
                                                                                // let unitName = items.drill_compliances
                                                                                // let keys = Object.keys(unitName);
                                                                                console.log(items, 'items5599');
                                                                                return (
                                                                                    <Panel style={{ marginBottom: '5px' }} header={`${items[0][0].le_name}-${items[0][0].u_name}`}>
                                                                                        <table >
                                                                                            <tbody className="ant-table-tbody">
                                                                                                {items.map((item, p) => {
                                                                                                    return (<>
                                                                                                        <tr className="ant-table-row ant-table-row-level-0">
                                                                                                            <td colSpan={8}>
                                                                                                                <h6 className='m-2 ml-2 mb-2'>{item[0].unitname}</h6>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        {item.map((childitem, m) => {
                                                                                                            return <tr className="ant-table-row ant-table-row-level-0">
                                                                                                                <td style={{ width: '50px' }} className="ant-table-cell border-end"><center>{childitem.index}</center></td>
                                                                                                                {(fieldType === 'BusinessGroup') || (fieldType === 'LegalEntity') || (fieldType === 'Division') || (fieldType === 'Category') || (fieldType === 'Unit') ? '' :
                                                                                                                    <td style={{ width: '160px' }} className="ant-table-cell border-end">{childitem.bg_name}</td>}
                                                                                                                {(fieldType === 'LegalEntity') || (fieldType === 'Division') || (fieldType === 'Category') || (fieldType === 'Unit') ? '' :
                                                                                                                    <td style={{ width: '133px' }}
                                                                                                                        className="ant-table-cell border-end">{childitem.le_name}</td>}
                                                                                                                {(fieldType === 'Division') || (fieldType === 'Category') || (fieldType === 'Unit') ? '' :
                                                                                                                    <td style={{ width: '133px' }}
                                                                                                                        className="ant-table-cell border-end">{childitem.div_name}</td>}
                                                                                                                <td style={{ width: '299px' }}
                                                                                                                    className="ant-table-cell border-end">
                                                                                                                    <Tooltip title={
                                                                                                                        <table style={{ width: "100%", borderCollapse: 'collapse', border: '1px solid #636262', tableLayout: 'auto' }}>
                                                                                                                            <tbody>
                                                                                                                                <tr>
                                                                                                                                    <th style={{ border: '1px solid #636262', marginLeft: '5%', marginRight: '5%' }}><center>Compliance Description</center></th>
                                                                                                                                    <th style={{ border: '1px solid #636262' }}><center>Document Reference Number</center></th>

                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <td style={{ border: '1px solid #636262' }}><center>{childitem.descp ? childitem.descp : '-'}</center></td>
                                                                                                                                    <td style={{ border: '1px solid #636262' }}><center>{childitem.doc_ref_num ? childitem.doc_ref_num : '-'}</center></td>
                                                                                                                                </tr>
                                                                                                                            </tbody>
                                                                                                                        </table>
                                                                                                                    }><InfoCircleFilled /></Tooltip>{childitem.remarks_count > 0 ? <a style={{ marginLeft: '5px' }} href="javascript:;"
                                                                                                                        onClick={() => {

                                                                                                                            setRemarksModalData({
                                                                                                                                ...remarksModalData,
                                                                                                                                le_id: childitem.le_id,
                                                                                                                                c_id: Number(childitem.c_id),
                                                                                                                                d_id: Number(childitem.d_id),
                                                                                                                                u_id: Number(childitem.u_id),
                                                                                                                                start_date: childitem.start_date
                                                                                                                            })
                                                                                                                            const payload = [
                                                                                                                                authtoken,
                                                                                                                                {
                                                                                                                                    "session_token": authtoken,
                                                                                                                                    "request": [
                                                                                                                                        "GetComplianceRemarksHistoryData",
                                                                                                                                        {
                                                                                                                                            "legal_entity_id": childitem.le_id,
                                                                                                                                            "unit_id": Number(childitem.u_id),
                                                                                                                                            "compliance_history_id": Number(childitem.compliance_history_id)
                                                                                                                                        }
                                                                                                                                    ]
                                                                                                                                }
                                                                                                                            ]
                                                                                                                            getRemarksHistoryData({
                                                                                                                                payload: payload,
                                                                                                                                paramid: paramid
                                                                                                                            })
                                                                                                                            sethistorymodal(true);

                                                                                                                        }}
                                                                                                                    ><Tooltip title='Click to View Remarks History'><InfoCircleFilled color='blue' /></Tooltip></a> : ''}
                                                                                                                    {childitem.compfielaw_url != '' ? <Tooltip style={{ marginRight: '20%' }} title='Compfie Law Knowlegde Portal'>
                                                                                                                        <a className='ml-3' style={{ marginLeft: '5px' }} href={`${childitem.compfielaw_url}`}>
                                                                                                                            <BookTwoTone />
                                                                                                                        </a>
                                                                                                                    </Tooltip> : ''}
                                                                                                                    <span style={{ marginLeft: '5px' }}>{childitem.comp_name}</span> </td>
                                                                                                                <td style={{ width: '143px' }} className="ant-table-cell border-end">{childitem.criticality}</td>
                                                                                                                <td style={{ width: '134px' }} className="ant-table-cell border-end">{childitem.assignee_name}</td>
                                                                                                                {criticalityValue === 'Complied' ? '' :
                                                                                                                    <td style={{ width: '127px' }} className="ant-table-cell border-end">{childitem.ageing}</td>}
                                                                                                            </tr>
                                                                                                        })}</>)
                                                                                                })}
                                                                                            </tbody>
                                                                                        </table>
                                                                                        {/* <Table
                                                                size={'small'} className='remark-header add-service-prv'
                                                                columns={columnState === true ? columns1 : columns}
                                                                dataSource={childDatas[i]}
                                                                bordered
                                                                scroll={{ x: 1000 }}
                                                                pagination={false}
                                                                onChange={handleChange}
                                                            /> */}

                                                                                        {/* </> */}
                                                                                        {/* ) */}
                                                                                        {/* // })} */}

                                                                                    </Panel>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Collapse>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {/* chartValue == 'exposedRisk' ?
                                            delayedChart && delayedChart.length > 0 &&
                                            delayedChart.map((items) => {
                                        console.log('exposedRiskkkk');
                                    let unitName = items && items.drill_compliances
                                    console.log(unitName, 'drill');
                                    let keys = Object.keys(unitName);
                                    // let childData = values[]
                                    console.log(keys, 'keys');
                                    // console.log(childData,);
                                    return (
                                    <Panel style={{ marginBottom: '5px' }} header={`${items.le_name}-${items.u_name}`}>
                                        {keys && keys.length > 0 && keys.map((item, i) => {
                                            let values = Object.values(unitName);
                                            console.log(values[i], 'values');
                                            let childDatas = values
                                            let tempArr = []
                                            if (childDatas && childDatas.length > 0) {
                                                for (let m in childDatas[i]) {
                                                    console.log(m, 'inndexxx');
                                                    console.log(childDatas[i][m].comp_name, 'childDatas');
                                                    tempArr.push({
                                                        'bg_name': items.bg_name,
                                                        'le_name': items.le_name,
                                                        'legal_entity_id': items.legal_entity_id,
                                                        'div_name': items.div_name,
                                                        'comp_name': childDatas && childDatas[i][m].comp_name,
                                                        'criticality': childDatas && childDatas[i][m].criticality,
                                                        'assignee_name': childDatas && childDatas[i][m].assignee_name,
                                                        'descp': childDatas && childDatas[i][m].descp,
                                                        'compfielaw_url': childDatas && childDatas[i][m].compfielaw_url,
                                                        'ageing': childDatas && childDatas[i][m].ageing,
                                                        'index': count

                                                    })
                                                    { <span hidden>{count = count + 1}</span> }
                                                    // childDatas[i] = []
                                                }
                                                console.log(tempArr, 'tempArr');
                                            }
                                            childDatas[i] = tempArr


                                            return (
                                                <>
                                                    <h6 className='m-2 ml-2 mb-2'>{keys[i]}</h6>

                                                    <Table
                                                        id='service-user-priv'
                                                        className={localStorage.getItem("currentTheme")}
                                                        size={'small'}
                                                        columns={columnState === true ? columns3 : columns2}
                                                        dataSource={childDatas[i]}
                                                        bordered
                                                        scroll={{ x: 1500 }}
                                                        pagination={false}
                                                        onChange={handleChange}

                                                    />
                                                </>
                                            )

                                        })}

                                    </Panel>
                                    )
                                            
                                    }
                                </Collapse> */}


                            {/* {(criticalityTable && criticalityTable.length <= 0) || (delayedChart && delayedChart.length <= 0) ? '' : */}
                            {dataCount == showmoreCount ? false :
                                <div className='col-md-12 mt-2'>
                                    <div className='row'>
                                        <div className='col-md-4'></div>
                                        <div className='col-md-4'>
                                            {chartValue == 'hiddenRisk' ?
                                                <button className="btn btn-success" style={{ float: "center", marginLeft: "50px", borderRadius: '15px' }}
                                                    onClick={hiddenRiskShowmore}
                                                >Show more records<i> <DownOutlined /></i></button> :
                                                chartValue == 'exposedRisk' ?
                                                    <button className="btn btn-success" style={{ float: "center", marginLeft: "20%", borderRadius: '15px' }}
                                                        onClick={showmore}
                                                    >Show more records<i> <DownOutlined /></i></button> : ''}
                                        </div>
                                        <div className='col-md-4'></div>
                                    </div>
                                </div>}
                            {/* } */}
                            {/* <Table dataSource={dataSource} columns={columns} pagination={false}
              className='userprivclass' /> */}
                        </div> :
                        <div className="container-fluid pt-1">
                            <div className="row" >
                                {/* {(data && data.length > 0) && ((data && data.length && data[0].assigned_count == 0) && (data && data.length && data[0].not_applicable_count == 0) && (data && data.length && data[0].not_opted_count == 0) && (data && data.length && data[0].unassign_count == 0)) ? */}

                                {/* <p style={{ marginTop: '18%', marginLeft: '35%' }}>No Data to Display </p> : */}
                                <>
                                    <div className="col-md-12 mt-0">

                                        {/* <div className="card">
                                            <div className='card-body'> */}

                                        <>
                                            {/* <b> <p style={{ textAlign: 'center' }}>Risk Chart of Lulu Groups</p></b>
                                            {generatedOn == true ?
                                                <p>{`Generated on :${timeZoneData.tz_name} ${timeZone}`}</p>
                                                :
                                                <p>{`Generated on :${time} (UTC)`}</p>
                                            } */}
                                            <div className="col-md-12">
                                                {/* <Card style={{ borderRadius: '15px', height: '500px' }}> */}
                                                {/* <div className="row" style={{ marginTop: '7%' }}>
                                                        <div className="col-md-6">
                                                            <b style={{ marginLeft: '42%' }}>Hidden Risk</b>
                                                            <div id="Riskchartdiv" style={{ width: "100%", marginTop: '10px', height: "330px" }}></div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <b style={{ marginLeft: '42%' }}>Exposed Risk</b>
                                                            <div id="ExposedRiskchartdiv" style={{ width: "100%", marginTop: '10px', marginRight: '20%', height: "280px" }}></div>
                                                        </div></div> */}
                                                {/* {noDataState === false ?
                                                        <>
                                                            <center><p style={{ fontSize: '17px' }}>      {((fieldType == "LegalEntity" && go == true && type === '') ? `Risk Chart of Legal Entity [bold]${filterDataNames.legalentity}[/]` : (
                                                                (fieldType == "Division" && go == true && type === '') ? `Risk Chart of Division [bold]${filterDataNames.division}[/]` : (
                                                                    (fieldType == "Category" && go == true && type === '') ? `Risk Chart of Category [bold]${filterDataNames.category}[/]` : (
                                                                        (fieldType == "BusinessGroup" && go == true && type === '') ? `Risk Chart of Category [bold]${filterDataNames.business}[/]` : (
                                                                            (fieldType == "Unit" && go == true && type === '') ? `Risk Chart of Unit [bold]${filterDataNames.unit}[/]` : `Risk Chart of ${commonData && commonData.g_name}`
                                                                        )))
                                                            ))}</p></center>
                                                            <span>{generatedOn === true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time}${timeZone}`}</span>
                                                            <div id="chartdiv" style={{ width: "100%", marginTop: '10px', height: "350px", marginTop: '5%', marginLeft: '-2%' }}></div>
                                                        </> :
                                                        <div id="nodata" style={{ width: "100%", marginTop: '10px', height: "350px", marginTop: '5%', marginLeft: '-2%' }}></div>
                                                    } */}
                                                {((open === true) && (fieldTypeClick != '') && (go === false)) ? <Spin size='large' style={{ marginTop: '17%', marginLeft: '45%', marginBottom: '10%' }} /> :
                                                    <Card style={{ borderRadius: '15px' }}>
                                                        {JSON.parse(isCountryValueTrue) === true || noDataState === true ?
                                                            <p style={{ width: "100%", marginTop: '20%', marginLeft: '43%', height: "250px" }}>No Data to Display</p>
                                                            :
                                                            <>
                                                                <center><p style={{ fontSize: '17px' }}>      {((fieldType == "LegalEntity" && go == true && type === '') ? <><span>Risk Chart of Legal Entity</span> <b>{filterDataNames.legalentity} ({year})</b></> : (
                                                                    (fieldType == "Division" && go == true && type === '') ? <><span>Risk Chart of Division</span> <b>{filterDataNames.division} ({year})</b></> : (
                                                                        (fieldType == "Category" && go == true && type === '') ? <><span>Risk Chart of Category</span> <b> {filterDataNames.category} ({year})</b></> : (
                                                                            (fieldType == "BusinessGroup" && go == true && type === '') ? <><span>Risk Chart of Category</span> <b>{filterDataNames.business} ({year})</b></> : (
                                                                                (fieldType == "Unit" && go == true && type === '') ? <><span>Risk Chart of Unit</span> <b>{filterDataNames.unit} ({year})</b></> : `Risk Chart of ${commonData && commonData.g_name} (${year})`
                                                                            )))
                                                                ))}</p></center>
                                                                <span className='mb-3'>{generatedOn === true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time}${timeZone}`}</span><br />
                                                                <p style={{ marginLeft: '20%', marginTop: '2%' }}><b>Hidden Risk</b><b style={{ marginLeft: '50%' }}>Exposed Risk</b></p>
                                                                <div id="chartdiv" style={{ width: "100%", marginTop: '10px', height: "350px", marginTop: '5%', marginLeft: '-2%' }}></div>
                                                            </>
                                                        }
                                                    </Card>}
                                                {/* <span><b>Country - Domain</b></span><br />
                                                    <span>{`${textArray}`}</span> */}
                                                {/* </Card> */}
                                                {/* <div className="row">
                                                            <div className="col-md-6">
                                                                <b style={{ marginLeft: '42%' }}>Hidden Risk</b>
                                                                <div id="chartdiv" style={{ width: "100%", marginTop: '10px', height: "220px" }}></div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <b style={{ marginLeft: '42%' }}>Exposed Risk</b>
                                                                <div id="chartdiv1" style={{ width: "100%", marginTop: '10px', marginRight: '20%', height: "220px" }}></div>
                                                            </div>
                                                        </div> */}
                                            </div>
                                            <div className="col-md-12 mt-3">
                                                <div className="row">
                                                    {/* <div className="col-md-3">
                                                        <b><p>Country-Domain</p></b>
                                                        <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>
                                                    </div> */}
                                                    <div className="col-md-3">
                                                        {/* <b><p>{`Total Task:${(data && data.length > 0 && data[0].assigned_count) + (data && data.length > 0 && data[0].not_opted_count) + (data && data.length > 0 && data[0].unassign_count) + (data && data.length > 0 && data[0].not_applicable_count)}`}</p></b> */}
                                                    </div>
                                                    <div className="col-md-3">
                                                        {/* <b><p>{`Total Task:${(data && data.length > 0 && data[0].assigned_count) + (data && data.length > 0 && data[0].not_opted_count) + (data && data.length > 0 && data[0].unassign_count) + (data && data.length > 0 && data[0].not_applicable_count)}`}</p></b> */}
                                                    </div>
                                                    {noDataState === false ?
                                                        <div className="col-md-6  text-md-end text-lg-end">
                                                            <label htmlFor=""><b>Time Zone :</b></label>
                                                            <Select
                                                                allowClear={false}
                                                                size="default"
                                                                onChange={(data) => {
                                                                    setTimeZone(data)
                                                                    setGeneratedOn(true)
                                                                    const payload = [
                                                                        authtoken,
                                                                        {
                                                                            "session_token": authtoken,
                                                                            "request": [
                                                                                "GetDateTime_ByZone",
                                                                                {
                                                                                    "tz_name": data
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                    getTimeZoneData({
                                                                        payload: payload,
                                                                        paramid: paramid
                                                                    })

                                                                }}
                                                                placeholder="Enter Time Zone"
                                                                style={{ marginLeft: '5%' }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            // value={compfie.user_Group}

                                                            >

                                                                {TimeZoneValue && TimeZoneValue.tz_list.length > 0 && TimeZoneValue.tz_list.map((item, i) => {

                                                                    return (

                                                                        <Option key={item.tz_name}>

                                                                            {item.tz_name}

                                                                        </Option>

                                                                    );

                                                                })}
                                                                {/* );
                                                })} */}
                                                            </Select>
                                                        </div> : ''}
                                                    <div className="col-md-12 mt-3">
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                {year === prevYear ? '' :
                                                                    <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                                        icon={<ArrowLeftOutlined />} size='default'
                                                                        onClick={() => {
                                                                            setYear(year - 1)
                                                                        }}
                                                                    >
                                                                        Previous Year
                                                                    </Button>}
                                                            </div>
                                                            <div className="col-md-8"></div>
                                                            <div className="col-md-2 d-none d-md-flex
align-items-center
justify-content-end">
                                                                {year != moment().year() ?
                                                                    <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                                        size='default'
                                                                        onClick={() => {
                                                                            setYear(year + 1)
                                                                        }}
                                                                    >
                                                                        Next Year<ArrowRightOutlined />
                                                                    </Button>
                                                                    : false}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </>



                                        {/* </div>
                                        </div> */}
                                    </div>

                                </>
                                {/* } */}
                            </div>
                        </div>
                }
                <Drawer title="FILTER BY" className={localStorage.getItem("currentTheme") + ' resize-drawer'} placement="right" closable={false} onClose={false} open={open}
                    extra={
                        <Space>
                            <CloseCircleOutlined style={{ marginTop: '2%', marginLeft: '75', fontSize: '140%' }} onClick={onClose} />
                        </Space>
                    }
                >
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4">
                                <label><b>Country</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Country"
                                    mode='multiple'
                                    maxTagCount='responsive'
                                    value={filterData.country ? filterData.country : undefined}
                                    defaultValue={filterData.country ? filterData.country : undefined}
                                    key={filterData.country ? filterData.country : undefined}
                                    // onChange={(data, values) => {
                                    //     setFieldTypeClick('Country')
                                    //     setFilterData({
                                    //         ...filterData,
                                    //         country: data,
                                    //         domain: ''
                                    //     })
                                    //     setFilterDataNames({
                                    //         ...filterDataNames,
                                    //         country: values.children,
                                    //         domain: '',
                                    //         legalentity: '',
                                    //         division: '',
                                    //         unit: '',
                                    //         business: '',
                                    //         category: '',
                                    //         unit: ''
                                    //     })
                                    //     setType('')

                                    // }
                                    // }
                                    onChange={(option) => HandelCountryChange(option)}
                                    onDeselect={(data) => {

                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {field && field.country &&
                                        field.country.length > 0 &&
                                        field.country.map((item, i) => {
                                            return (
                                                <Option key={item.c_id}>
                                                    {item.c_name}
                                                </Option>
                                            );
                                        })}

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label><b>Domain</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Domain"
                                    mode='multiple'
                                    maxTagCount='responsive'
                                    value={filterData.domain ? filterData.domain : undefined}
                                    defaultValue={filterData.domain ? filterData.domain : undefined}
                                    key={filterData.domain ? filterData.domain : undefined}
                                    // onChange={(data, values) => {
                                    //     console.log(data,'data-domain');
                                    //     let filteredBusinessGroup = _.filter(commonData && commonData.bg_groups, { d_id: Number(data) })
                                    //     let filteredDivision = _.filter(commonData && commonData.div_infos, { d_id: Number(data) })
                                    //     // let filteredCategory = _.filter(commonData && commonData.bg_groups,{d_id:Number(data)})
                                    //     // let filteredUnit = _.filter(commonData && commonData.bg_groups,{d_id:Number(data)})
                                    //     let filteredLegalEntity = commonData && commonData.le_did_infos.filter(item => item.d_ids.includes(Number(data)))
                                    //     let filteredCategory = _.filter(commonData.cat_info, { div_id: Number(filteredDivision[0].div_id) })
                                    //     let filteredUnit = _.filter(commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
                                    //     let temp = []
                                    //     temp.push({
                                    //         'bg_id': filteredCategory[0].bg_id,
                                    //         'c_id': filteredCategory[0].c_id,
                                    //         'cat_id': filteredCategory[0].cat_id,
                                    //         'cat_name': filteredCategory[0].cat_name,
                                    //         'd_id': filteredCategory[0].d_id,
                                    //         'div_id': filteredCategory[0].div_id,
                                    //         'le_id': filteredCategory[0].le_id,
                                    //         'div_name': division[0].div_name
                                    //     })
                                    //     setCategory(temp)
                                    //     setUnit(filteredUnit)
                                    //     setfield({
                                    //         ...field,
                                    //         legalEntity: filteredLegalEntity,
                                    //         business: filteredBusinessGroup
                                    //     })
                                    //     let temp1=[]
                                    //     for(let i in commonData.d_info){
                                    //         temp1.push(commonData.d_info[i].d_id)
                                    //     }
                                    //     setFilterData({
                                    //         ...filterData,
                                    //         domain: temp1
                                    //     })
                                    //     setFilterDataNames({
                                    //         ...filterDataNames,
                                    //         domain: values.children,
                                    //         legalentity: '',
                                    //         division: '',
                                    //         unit: '',
                                    //         business: '',
                                    //         category: '',
                                    //         unit: ''
                                    //     })
                                    //     setDivision(filteredDivision)
                                    //     // let temp = []
                                    //     // temp.push({
                                    //     //     'bg_id': filteredCategory[0].bg_id,
                                    //     //     'c_id': filteredCategory[0].c_id,
                                    //     //     'cat_id': filteredCategory[0].cat_id,
                                    //     //     'cat_name': filteredCategory[0].cat_name,
                                    //     //     'd_id': filteredCategory[0].d_id,
                                    //     //     'div_id': filteredCategory[0].div_id,
                                    //     //     'le_id': filteredCategory[0].le_id,
                                    //     //     'div_name': filteredDivision[0].div_name
                                    //     // })
                                    //     // setCategory(temp)
                                    //     // setUnit(filteredUnit)
                                    // }
                                    // }
                                    onChange={(option) => HandelChange(option)}
                                    // onDeselect={(data) => {
                                    //     console.log(data,'data-deselect');
                                    //     filterData && filterData.domain.splice(data,1)
                                    // }}
                                    style={{ width: '100%' }}
                                    disabled={filterData.country == '' ? true : false}
                                >
                                    {domainListData && domainListData.length > 0 &&
                                        domainListData.map((finalList_unitlist) => (
                                            <OptGroup label={finalList_unitlist.label}>
                                                {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                    <Option value={subUnitData.d_id}>{subUnitData.d_name}</Option>

                                                ))}
                                            </OptGroup>
                                        ))}

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-1">
                                <input type='checkbox' checked={checkBox === true ? true : false} onChange={() => {
                                    setType('Group')
                                    setCheckBox(!checkBox)
                                }}></input>
                            </div>
                            <div className="col-md-4">
                                <label><b>Group</b></label>
                            </div>
                            {/* <div className="col-md-2">

                        </div> */}
                            <div className="col-md-1">
                                {/* <input type='checkbox' onChange={(e) => {
                                if (e.target.value) {
                                    setFilterData({
                                        ...filterData,
                                        chartType: 'Consolidated'
                                    })
                                }
                            }}></input> */}
                            </div>
                            <div className="col-md-4">
                                {/* <label><b>Consolidated</b></label> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-6">

                                <label><b>From Date</b></label>
                                <DatePicker
                                    disabled={true}
                                    allowClear={() => {
                                        setFilterData({
                                            ...filterData,
                                            from_date: null,
                                        })
                                    }}
                                    // value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                    // value={consolidatedData.from_date}
                                    // disabled={consolidatedData.domainy == '' ? true : false}
                                    onChange={(date, dateString) => {

                                        console.log(dateString, 'dateString');
                                        setFilterData({
                                            ...filterData,
                                            from_date: dateString,
                                        })

                                    }}

                                />

                            </div>
                            <div className="col-md-6">

                                <label><b>To Date</b></label>

                                <DatePicker
                                    // value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                    // value={consolidatedData.from_date}
                                    // disabled={consolidatedData.domainy == '' ? true : false}
                                    // onChange={(date, dateString) => {

                                    //     console.log(dateString, 'dateString');
                                    //     setConsolidatedData({
                                    //         ...consolidatedData,
                                    //         from_date: dateString,
                                    //         to_date: moment(dateString).add(3, 'months')
                                    //     })

                                    // }}
                                    disabled={true}
                                    onChange={(date, dateString) => {

                                        console.log(dateString, 'dateString');
                                        if (dateString != undefined) {
                                            setFilterData({
                                                ...filterData,
                                                to_date: dateString,
                                            })
                                        }
                                        else {
                                            setFilterData({
                                                ...filterData,
                                                to_date: null,
                                            })
                                        }


                                    }}

                                />

                            </div>
                        </div>
                    </div>

                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldTypeClick == 'BusinessGroup' ? 'blue' : 'black' }}><b>Business Group</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Business Group"
                                    value={filterDataNames.business ? filterDataNames.business : undefined}
                                    defaultValue={filterDataNames.business ? filterDataNames.business : undefined}
                                    key={filterDataNames.business ? filterDataNames.business : undefined}
                                    onClick={() => {
                                        setFieldTypeClick('BusinessGroup')
                                    }}
                                    onChange={(data, values) => {
                                        setType('')
                                        setErrorField('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        let filteredEntity = _.filter(commonData && commonData.le_did_infos, { bg_id: Number(data) })
                                        // let filteredDivision = _.filter(commonData && commonData.div_infos, { bg_id: Number(data) })
                                        // console.log(filteredDivision, 'filteredDivision');
                                        // let uniqueDiv = []
                                        // if (filteredDivision && filteredDivision.length > 0) {
                                        //     uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                                        //         return e.div_id;
                                        //     });
                                        // }
                                        // setDivision(uniqueDiv)
                                        // let filteredCategory = _.filter(commonData && commonData.cat_info, { bg_id: Number(data) })
                                        // let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
                                        setfield({
                                            ...field,
                                            legalEntity: filteredEntity
                                        })
                                        setLegalEntityArray(filteredEntity)
                                        setFieldType('BusinessGroup')
                                        setFilterData({
                                            ...filterData,
                                            business: data,
                                        })
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            business: values.children,
                                            legalentity: '',
                                            division: '',
                                            unit: '',
                                            category: '',
                                            unit: ''
                                        })
                                        // let temp = []
                                        // temp.push({
                                        //     'bg_id': filteredCategory[0].bg_id,
                                        //     'c_id': filteredCategory[0].c_id,
                                        //     'cat_id': filteredCategory[0].cat_id,
                                        //     'cat_name': filteredCategory[0].cat_name,
                                        //     'd_id': filteredCategory[0].d_id,
                                        //     'div_id': filteredCategory[0].div_id,
                                        //     'le_id': filteredCategory[0].le_id,
                                        //     'div_name': filteredDivision[0].div_name
                                        // })
                                        // setCategory(temp)
                                        // setUnit(filteredUnit)

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}
                                >
                                    {field && field.business && field.business.length && field.business.map((item, i) => {
                                        return (
                                            <Option key={item.bg_id}>
                                                {item.bg_name}
                                            </Option>
                                        );
                                    })}


                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldTypeClick == 'LegalEntity' ? 'blue' : 'black' }}><b>Legal Entity</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    defaultValue={filterDataNames.legalentity ? filterDataNames.legalentity : undefined}
                                    key={filterDataNames.legalentity ? filterDataNames.legalentity : undefined}
                                    allowClear={false}
                                    size="default"
                                    value={filterDataNames.legalentity ? filterDataNames.legalentity : undefined}
                                    placeholder="Enter Legal Entity"
                                    onClick={() => {
                                        setFieldTypeClick('LegalEntity')
                                    }}
                                    onChange={(data, values) => {
                                        setType('')
                                        setErrorField('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        // let filteredLegalEntity = _.filter(commonData && commonData.le_did_infos, { le_id: data })
                                        // let filteredDivision = _.filter(commonData && commonData.div_infos, { le_id: data })
                                        // let filteredCategory = _.filter(commonData && commonData.cat_info, { le_id: data })
                                        let filteredLegalEntity = _.filter(commonData && commonData.le_did_infos, { le_id: data })
                                        setLegalEntityArray(filteredLegalEntity)
                                        // // setfield({
                                        // //     ...field,
                                        // //     legalEntity: filteredLegalEntity
                                        // // })
                                        // if (filteredDivision && filteredDivision.length > 0) {
                                        //     let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
                                        //     setUnit(filteredUnit)
                                        // }
                                        // else {
                                        //     let filteredUnit = _.filter(commonData && commonData.chart_units, { le_id: data })
                                        //     console.log(filteredUnit, 'filteredUnit555');
                                        //     setUnit(filteredUnit)
                                        // }

                                        // console.log(filteredDivision, 'filteredDivisionLeg');
                                        setFilterData({
                                            ...filterData,
                                            legalentity: data,
                                            leIds: data
                                        })
                                        setFieldType('LegalEntity')
                                        setGo(false)
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            legalentity: values.children,
                                            division: '',
                                            unit: '',
                                            category: '',
                                            unit: ''
                                        })
                                        // let uniqueDiv = []
                                        // if (filteredDivision && filteredDivision.length > 0) {
                                        //     uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                                        //         return e.div_id;
                                        //     });
                                        // }
                                        // setDivision(uniqueDiv)
                                        // let temp = []
                                        // temp.push({
                                        //     'bg_id': filteredCategory[0].bg_id,
                                        //     'c_id': filteredCategory[0].c_id,
                                        //     'cat_id': filteredCategory[0].cat_id,
                                        //     'cat_name': filteredCategory[0].cat_name,
                                        //     'd_id': filteredCategory[0].d_id,
                                        //     'div_id': filteredCategory[0].div_id,
                                        //     'le_id': filteredCategory[0].le_id,
                                        //     'div_name': filteredDivision[0].div_name
                                        // })
                                        // setCategory(temp)
                                        // setUnit(filteredUnit)
                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >

                                    {field && field.legalEntity && field.legalEntity.length && field.legalEntity.map((item, i) => {
                                        return (
                                            <Option key={item.le_id}>
                                                {item.le_name}
                                            </Option>
                                        );
                                    })}

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldTypeClick == 'Division' ? 'blue' : 'black' }}><b>Division</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Division"
                                    value={filterDataNames.division ? filterDataNames.division : undefined}
                                    defaultValue={filterData.division ? filterData.division : undefined}
                                    key={filterData.division ? filterData.division : undefined}
                                    onClick={() => {
                                        setFieldTypeClick('Division')
                                    }}
                                    onChange={(data, values) => {
                                        setType('')
                                        setErrorField('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        let filteredDivision = _.find(commonData && commonData.div_infos, { div_id: Number(data) })
                                        let getLEname = _.find(commonData && commonData.le_did_infos, { le_id: filteredDivision.le_id })
                                        let filteredCategory = _.filter(commonData && commonData.cat_info, { div_id: Number(data) })
                                        let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(data) })
                                        setFilterData({
                                            ...filterData,
                                            division: data
                                        })

                                        // let subData = _.filter(commonData && commonData.cat_info, { div_id: Number(data) })
                                        console.log(filteredDivision, 'filteredDivision');
                                        // setDivisionObj(filteredDivision)
                                        // setLEObj(getLEname)
                                        // setCategory(subData)
                                        setFieldType('Division')
                                        setGo(false)
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            division: values.children,
                                            unit: '',
                                            category: '',
                                        })
                                        setUnit(filteredUnit)
                                        let temp = []
                                        temp.push({
                                            'bg_id': filteredCategory[0].bg_id,
                                            'c_id': filteredCategory[0].c_id,
                                            'cat_id': filteredCategory[0].cat_id,
                                            'cat_name': filteredCategory[0].cat_name,
                                            'd_id': filteredCategory[0].d_id,
                                            'div_id': filteredCategory[0].div_id,
                                            'le_id': filteredCategory[0].le_id,
                                            'div_name': filteredDivision.div_name
                                        })
                                        setCategory(temp)

                                        console.log(category, 'catStateeeee');

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >

                                    {divisionListData && divisionListData.length > 0 &&
                                        divisionListData.map((finalList_unitlist) => {
                                            if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist.options.length > 0) {
                                                return (
                                                    <OptGroup label={finalList_unitlist.label}>
                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                            <Option value={subUnitData.div_id}>{subUnitData.div_name}</Option>

                                                        ))}
                                                    </OptGroup>
                                                )
                                            }
                                            else {
                                                return null
                                            }

                                        }
                                        )}


                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldTypeClick == 'Category' ? 'blue' : 'black' }}><b>Category</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Category"
                                    value={filterDataNames.category ? filterDataNames.category : undefined}
                                    defaultValue={filterData.category ? filterData.category : undefined}
                                    key={filterData.category ? filterData.category : undefined}
                                    onClick={() => {
                                        setFieldTypeClick('Category')
                                    }}
                                    onChange={(data, values) => {
                                        setType('')
                                        setErrorField('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        setFilterData({
                                            ...filterData,
                                            category: data,
                                            unit: '',
                                        })
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            category: values.children
                                        })
                                        let subData = _.filter(commonData && commonData.chart_units, { category_id: Number(data) })
                                        setUnit(subData)
                                        setFieldType('Category')
                                        setGo(false)

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >
                                    {categoryListData && categoryListData.length > 0 &&
                                        categoryListData.map((finalList_unitlist) => {
                                            console.log(finalList_unitlist, 'finalList_unitlist');
                                            if (finalList_unitlist.options && finalList_unitlist.options.length > 0) {
                                                return (
                                                    <OptGroup label={finalList_unitlist.label}>
                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                            <Option value={subUnitData.cat_id}>{`${subUnitData.div_name}-${subUnitData.cat_name}`}</Option>

                                                        ))}
                                                    </OptGroup>
                                                )
                                            }
                                            else {
                                                return null
                                            }
                                        }

                                        )}



                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldTypeClick == 'Unit' ? 'blue' : 'black' }}><b>Unit</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Unit"
                                    value={filterDataNames.unit ? filterDataNames.unit : undefined}
                                    defaultValue={filterDataNames.unit ? filterDataNames.unit : undefined}
                                    key={filterDataNames.unit ? filterDataNames.unit : undefined}
                                    onClick={() => {
                                        setFieldTypeClick('Unit')
                                    }}
                                    onChange={(data, values) => {
                                        setType('')
                                        setErrorField('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        setFilterData({
                                            ...filterData,
                                            unit: data
                                        })
                                        setFieldType('Unit')
                                        setGo(false)
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            unit: values.children
                                        })

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >
                                    {unitListData && unitListData.length > 0 &&
                                        unitListData.map((finalList_unitlist) => {
                                            if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist.options.length > 0) {
                                                return (
                                                    <OptGroup label={finalList_unitlist.label}>
                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                            <Option value={subUnitData.u_id}>{subUnitData.u_name}</Option>

                                                        ))}
                                                    </OptGroup>
                                                )
                                            }
                                            else {
                                                return null
                                            }
                                        }

                                        )}



                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className={'popupbtncolour text-center ' + localStorage.getItem('currentTheme')}>
                        <Button shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ marginLeft: '15%', marginTop: '10%' }}
                            onClick={filterChange} icon={<ArrowRightOutlined />}
                            size='default' >
                            Go
                        </Button>
                    </div>
                    {/* <Button type="primary" className='addbutton' style={{ background: "#0096FF", marginLeft: '25%', marginTop: '10%' }}
                        onClick={() => {
                            const payload = [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request":
                                        ["GetTskDistributionStatusChart",
                                            {
                                                "c_ids": [
                                                    commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                                ],
                                                "d_ids": [
                                                    commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                                                ],
                                                "filter_type": "Group",
                                                "filter_ids": [
                                                    1
                                                ],
                                                "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [
                                                    commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_id
                                                ],
                                                "country_domains": [
                                                    {
                                                        "d_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id,
                                                        "c_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].c_id,
                                                        "month_from": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_from,
                                                        "month_to": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_to
                                                    }
                                                ],
                                                "chart_year": year
                                            }
                                        ]
                                }
                            ]
                            getCriticalitychart({
                                payload: payload,
                                paramid: paramid
                            })
                            setOpen(false)

                            setFieldType("")
                            setFilterData({
                                ...filterData,
                                country: '',
                                domain: '',
                                legalentity: '',
                                division: '',
                                unit: '',
                                business: '',
                                category: '',
                                unit: ''
                            })
                        }}
                        size='default' >
                        Reset
                    </Button> */}
                </Drawer>
                <Modal open={historymodal}
                    title="Remarks History"
                    className={" add-service-prv " + localStorage.getItem('currentTheme')}
                    footer={null}
                    onCancel={historycancel}
                    maskClosable={false}
                >
                    {/* <Table className={`stats ${localStorage.getItem("currentTheme")}`}
                  columns={history_columns}
                  dataSource={historydata && historydata.remarks_history}
                  pagination={false}
                /> */}
                    <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper'}>
                        <div className='ant-table ant-table-bordered'>
                            <div className='ant-table-container'>
                                <table>
                                    <colgroup>
                                        <col style={{ width: '10px' }}></col>
                                    </colgroup>
                                    <thead className="ant-table-thead " >
                                        <tr>
                                            <th className={'ant-table-cell'} style={{ width: '5px' }}>#</th>
                                            <th className={'ant-table-cell'} style={{ width: '10px' }}>Activity Date</th>
                                            <th className={'ant-table-cell'} style={{ width: '30px' }}>User Type</th>
                                            <th className={'ant-table-cell'} style={{ width: '200px' }}>Reviewer Report</th>
                                            <th className={'ant-table-cell'} style={{ width: '30px' }}>Remarks</th>
                                        </tr>
                                    </thead>
                                    <tbody className='ant-table-tbody'>
                                        {remarksHistoryData && remarksHistoryData.remarks_history && remarksHistoryData.remarks_history.length > 0 && remarksHistoryData.remarks_history.map((item, i) => {
                                            console.log(item, 'itemitemitem');
                                            let splittedDocs;
                                            if (item.documents != null) {
                                                let doc = item.documents;
                                                splittedDocs = doc.split('||')
                                            }
                                            console.log(splittedDocs, 'splittedDocs');
                                            return <tr>
                                                <td className='ant-table-cell'>
                                                    <center>
                                                        {i + 1}
                                                    </center>
                                                </td>
                                                <td><center>{item.activity_on}</center></td>
                                                <td><center>{((item.user_category_id == 5 || item.user_category_id == 6) ? 'Assignee' : ((item.user_category_id == 3 || item.user_category_id == 4) && (item.action == 'Rectified' || item.action == 'Concurrence Rejected' || item.action == 'Concurred')) ? 'Concurrer' : (item.user_category_id == 1 && (item.action == 'RectifyApproval' || item.action == 'Approval Rejected' || item.action == 'Approved') ||
                                                    (item.user_category_id == 3 || item.user_category_id == 4) && (item.action == 'Approval Rejected' || item.action == 'RectifyApproval' || item.action == 'Approved') ? 'Approver' : ''))}</center></td>
                                                <td>{splittedDocs && splittedDocs.length > 0 ?
                                                    splittedDocs.map((items, l) => {
                                                        return <tr><a href="javascript:;" style={{ wordBreak: 'break-all' }} onClick={() => {
                                                            const remarkapi = [
                                                                authtoken,
                                                                {
                                                                    "session_token": authtoken,
                                                                    "request": [
                                                                        "DownloadFile",

                                                                        {
                                                                            remarksModalData,
                                                                            "file_name": items
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                            console.log(remarkapi, "trueeee123");
                                                            if (entityid != "null" && entityid !== null) {
                                                                console.log("trueeee123");
                                                                getdownloadpath({
                                                                    payload: remarkapi,
                                                                    paramid: paramid,
                                                                })
                                                            }
                                                        }}>{items}</a></tr>
                                                    }) : ''}</td>
                                                <td><center>{item.remarks}</center></td>


                                            </tr>
                                        })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Modal>

            </div>
        </div>
    )


}

export default connect(mapStateToProps, {
    getRiskchart,
    getCommonData,
    getRiskChartTableData,
    getRiskChartDelayedTableData,
    getTimeZoneData,
    getRemarksHistoryData,
    getRiskExposedTrendShowMoreTableData,
    getHiddenRiskShowMore,
    getdownloadpath
})(RiskChart);