import React, { Fragment, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import SimpleReactValidator from "simple-react-validator";
import { forgotPassword } from '../Store/Action/Forgotpassword';
import { Currentstatus } from '../Store/Action/Currentstatus';
import { useLocation, useHistory, Link } from 'react-router-dom';
const mapStateToProps = (state) => ({
    currentstatus: state.Currentstatus.currentstatus
})

const Forgotpassword3 = ({
    forgotPassword,
    currentstatus,
    Currentstatus
}) => {
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const history = useHistory();
    const [ForgotData, setForgotData] = useState({
        userId: '',
        groupName: ''
    })
    const validator = useRef(new SimpleReactValidator());

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        if(currentstatus == 'failure'){           
            setAddFormSubmit(false)
            Currentstatus('initial')
        }else if(currentstatus == 'success'){
            setForgotData({
                ...ForgotData,
                userId: '',
                groupName: ''
            })
            setAddFormSubmit(false)
            Currentstatus('initial')
            console.log(ForgotData,'dfgfggf')

        }
    },[currentstatus])

    const submitForgot = (e) => {
        console.log(ForgotData,'ForgotData')
        e.preventDefault();
        setAddFormSubmit(true);
        if (validator.current.allValid()) {
            forgotPassword({
                payload: ForgotData
            })            
        }
    }
    return (
        <Fragment>
            <div style={{ backgroundImage: 'url(login3-bg.png)', height: '100vh', backgroundSize: 'cover' }} className="main-wrapper-bg auth-wrapper d-flex no-block justify-content-center 
                align-items-end flex-column">

                <div style={{ borderRadius: '2rem' }} className="auth-box p-3 bg-white m-0 me-5">
                    <center><img src="logo-icon.png" height="50px" alt='Logo' /></center>
                    <div >
                        <div className="logo">
                            <center>
                                <h3 className="box-title mb-3">Forgot Password</h3>
                            </center>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group mb-3 position-relative">

                                    <input id="userId" name="userId" value={ForgotData.userId} className="form-control" type="text" required="" maxLength='50' autoFocus={true} placeholder="Enter User ID" onChange={(e) => {
                                        setForgotData({
                                            ...ForgotData, userId: e.target.value
                                        })
                                    }}
                                        onPaste={(e) => {
                                            e.preventDefault()
                                        }}
                                        onKeyDown={(event) => {
                                            if (!/[a-zA-Z0-9 ]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                    <i style={{ position: 'absolute', top: '6px', cursor: 'text', right: '7px', fontSize: '1.3rem' }} className="ri-user-line icon-override"></i>

                                    {validator.current.message(
                                        'userId',
                                        ForgotData.userId,
                                        'required',
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'User Id Required',
                                            }
                                        })}


                                </div>

                                <div className="form-group mb-4 position-relative">



                                    <input id="groupName" name="groupName" value={ForgotData.groupName} autoComplete='off' className="form-control" type="text" required="" maxLength='50' placeholder="Group Short Name" onChange={(e) => {
                                        setForgotData({
                                            ...ForgotData, groupName: e.target.value
                                        })
                                    }}
                                        onPaste={(e) => {
                                            e.preventDefault()
                                        }}
                                        onKeyDown={(event) => {
                                            if (!/[a-z0-9 ]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                    <i style={{ position: 'absolute', top: '6px', right: '7px', cursor: 'text', fontSize: '1.3rem' }} className="ri-home-2-line icon-override"></i>

                                    {validator.current.message(
                                        'groupName',
                                        ForgotData.groupName,
                                        'required',
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Group Short Name Required',
                                            }
                                        })}


                                </div>



                                <div className="col-md-12">
                                    <div className='row'>


                                        <div className="col-md-6">

                                            <button style={{ backgroundColor: '#8572ed', borderRadius: '10px' }} type='button' className="

                                                        btn btn-primary

                                                        d-block

                                                        w-100
                                                        login3-btn
                                                        border-0
                                                        waves-effect waves-light

                                                        "
                                                onClick={submitForgot}
                                            >

                                                Submit

                                            </button>

                                        </div>

                                        <div className="col-md-6">

                                            <button style={{ backgroundColor: 'skyblue', borderRadius: '10px' }} type='button' className="

                                                        btn btn-light

                                                        d-block

                                                        w-100
                                                        forgothover-btn
                                                        waves-effect waves-light

                                                        "
                                                onClick={() => {
                                                    history.push('/login3')
                                                }}
                                            >

                                                Cancel

                                            </button>

                                        </div>

                                    </div>
                                </div>


                            </div>

                        </div>

                    </div>

                </div>



                <div className='d-flex  align-items-end justify-content-end w-100 mt-5'>
                    <footer className="footer bg-transparent text-white  pb-0 shadow-sm" >2022© Aparajitha Software Services Private Limited <span className="ms-4">Privacy Policy · Terms & Condition </span> </footer>

                </div>
            </div>

        </Fragment>
    );
}

export default connect(mapStateToProps, {
    forgotPassword,
    Currentstatus
})(Forgotpassword3);