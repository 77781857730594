import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Header from './header';
import Sidenav from './sidenav';
import RoutesLink from '../Components/Routes';
import { useHistory, Link, useLocation, Redirect } from "react-router-dom";

const mapStateToProps = (state) => ({

});
const Full = () => {
    const location = useLocation();
    console.log(location,'location')
    useEffect(() => {
        localStorage.setItem('CurrentPath', location && location.pathname)
    }, [location])
    return (
        <Fragment>
            <div id="main-wrapper">
                <Header></Header>
                <Sidenav></Sidenav>
                <RoutesLink></RoutesLink>
                <footer className="footer d-flex justify-content-center">
                    2022© Aparajitha Software Services Private Limited
                    <span className="ms-2" style={{ color: "black" }}>Privacy · Terms of Use </span>
                </footer>
            </div>
        </Fragment>
    );
};

export default connect(mapStateToProps, {})(Full);

