import { logDOM } from '@testing-library/react';
import {
    DOMAINDATA, DOWNLOADLIST, DOWNLOADPATH, UPLOADCONTENT, UPLOADERROR, DATALIST,
    QUEUED, QUEUEDPATH,UPLOADSUCCESS,QUEUEDSTATUS
} from '../../types/index'

const initialState = {
    bulkuploads: {
        unitlist: '',
        datalist: '',
        downlist: '',
        downpath: '',
        uploadcontent: '',
        errorlog: '',
        uploadfile:"",
        queuedpath: '',
        queued: '',
        queuestatus:''

    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case DOMAINDATA:
            {
                return {
                    ...state,
                    bulkuploads: {
                        ...state.bulkuploads,
                        unitlist: payload[1],
                    },
                };
            }
        case DATALIST:
            {
                return {
                    ...state,
                    bulkuploads: {
                        ...state.bulkuploads,
                        datalist: payload[1],
                    },
                };
            }
        case DOWNLOADLIST:
            {
                return {
                    ...state,
                    bulkuploads: {
                        ...state.bulkuploads,
                        downlist: payload[1],
                    },
                };
            }
        case DOWNLOADPATH:
            {
                return {
                    ...state,
                    bulkuploads: {
                        ...state.bulkuploads,
                        downpath: payload[1],
                    },
                };
            }
        case UPLOADCONTENT:
            {
                return {
                    ...state,
                    bulkuploads: {
                        ...state.bulkuploads,
                        uploadcontent: payload[1],
                    },
                };
            }
        case UPLOADERROR:
            {
                return {
                    ...state,
                    bulkuploads: {
                        ...state.bulkuploads,
                        errorlog: payload[1],
                    },
                };
            }
        case UPLOADSUCCESS:
            {
                return {
                    ...state,
                    bulkuploads: {
                        ...state.bulkuploads,
                        uploadfile: payload[1],
                    },
                };
            }
        case QUEUEDPATH:
            {
                return {
                    ...state,
                    bulkuploads: {
                        ...state.bulkuploads,
                        queuedpath: payload[1],
                    },
                };
            }
            case QUEUEDSTATUS:
                {
                    return {
                        ...state,
                        bulkuploads: {
                            ...state.bulkuploads,
                            queuestatus: payload,
                        },
                    };
                }
        default:
            return state;
    }
}