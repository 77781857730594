import { ConsoleSqlOutlined, ContactsFilled } from '@ant-design/icons';
import HTTP from '../../../Libs/http';
import { VENDOR_CLOSURE_LIST, VENDOR_CLOSURE_LIST_RESET, GETPAUSEDRECORDS } from '../../types/index'


const initialState = {

    vendorClosure: {
        VendorClosureList: '',
        getpausedrecords: []

    },
}
export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case VENDOR_CLOSURE_LIST:
            {
                return {
                    ...state,
                    vendorClosure: {
                        ...state.vendorClosure,
                        VendorClosureList: payload[1]
                    },
                };
            }

        case VENDOR_CLOSURE_LIST_RESET:
            {
                return {
                    ...state,
                    vendorClosure: {
                        ...state.vendorClosure,
                        VendorClosureList: []
                    },
                };
            }

        case GETPAUSEDRECORDS: {
            return {
                ...state,
                vendorClosure: {
                    ...state.vendorClosure,
                    getpausedrecords: payload[1]
                },
            }
        }

        default:
            return state;
    }
}