import HTTP from '../../../Libs/http'
import { Toaster } from '../../../Libs/Toaster'
import url, { getUrl } from './../../../Libs/URL'
import EncryptDecrypt from './../../../Libs/Encrypt&Decrypt'
import { decode as base64_decode } from 'base-64';
import { USERMANAGEMENT_LIST, TOGGLE_COMMON_LOADER, USERMANAGEMENT_ADMIN, USERMANAGEMENT_HAVE_COMPLIANCE, USERMANAGEMENT_CATEGORY_LIST, GET_BY_UNIT } from './../../types/index';
import alertmessage from './../../../Libs/Altermessage';
const urls = getUrl()


export const getUsermanagementList = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();

        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));

            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: USERMANAGEMENT_LIST,
                    payload: decryptData,
                });
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });

            }
            console.log(decryptData, 'decryptData55555');
        }



    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err)
    }
};
export const getAdminDetails = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();

        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));

            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: USERMANAGEMENT_ADMIN,
                    payload: decryptData,
                });
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });

            }
            console.log(decryptData, 'decryptData55555');
        }



    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err)
    }
};
export const getUsermanagementDetails = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();

        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            console.log(decryptData, 'decryptDataAV');
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: USERMANAGEMENT_CATEGORY_LIST,
                    payload: decryptData,
                });
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });

            }
        }


    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err)
    }
};

export const selectHaveCompliance = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        console.log(payload, 'payload1236555');
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            // alertmessage.sweetalert();

        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            console.log(decryptData, 'decryptDatadecryptData');
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            }
            if (decryptData[0] === 'HaveComplianceFailed') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error("User have Compliance.Reassign Compliance")
                dispatch({
                    type: USERMANAGEMENT_HAVE_COMPLIANCE,
                    payload: decryptData[0],
                });

            }

            else {
                dispatch({
                    type: USERMANAGEMENT_HAVE_COMPLIANCE,
                    payload: decryptData[0],
                });
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });

            }
        }


    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err)
    }
};
export const updatestatus = ({ payload, executeCancel, paramid, types, payloads, Setpassvisible }) => async dispatch => {
    try {
        console.log(payload, 'payload1');
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const authtoken = localStorage.getItem('authToken');
        const payload1 = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "UserManagementList",
                    {}
                ]
            }
        ]

        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();

        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            console.log(decryptData[0], 'decryptDatadecryptDatadecryptData');
            if (decryptData[0] == "HaveComplianceSuccess") {
                // Toaster.success("User Deactivated Successfully")

                dispatch(
                    activeStatus({
                        types: types,
                        payload: payloads,
                        paramid: paramid
                    })
                )
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            }
            else if (decryptData[0] == "InvalidPassword") {
                // dispatch({
                //     type: TOGGLE_COMMON_LOADER
                // });
                Toaster.error("Invalid Password")
                Setpassvisible(true)
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            } else if (decryptData[0] == "HaveComplianceFailed") {

                Toaster.error("User have Compliance.Reassign Compliance")
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            }
            else if (decryptData[0] == "HaveComplianceFailed") {

                Toaster.error("User have Compliance.Reassign Compliance")
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            }
            else if (decryptData[0] == 'LegalEntityAdminAlreadyExist') {
                Toaster.error('Legal Entity Admin already exist for these legal entity')
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            }

            else if (decryptData[0] == "InvalidSessionToken") {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();
            } else if (decryptData[0] == 'UserLimitExceeds') {
                Toaster.error('User Licence Count exceeds.')
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            }
            else {
                // dispatch({
                //     type: TOGGLE_COMMON_LOADER
                // });
                Toaster.error(decryptData[0])
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            }
        }


    } catch (err) {
        Toaster.error(err);
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const activeStatus = ({ payload, executeCancel, paramid, types, Setpassvisible }) => async dispatch => {
    console.log(types, "typestypes");
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const authtoken = localStorage.getItem('authToken');
        const payload1 = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "UserManagementList",
                    {}
                ]
            }
        ]
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            console.log(decryptData, "decryptData");
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            if (decryptData[0] == "ChangeClientUserStatusSuccess") {
                if (types === "active") {
                    Toaster.success("User Deactivated Successfully")
                    dispatch({
                        type: TOGGLE_COMMON_LOADER
                    });
                }
                else {
                    Toaster.success("User Activated Successfully")
                    dispatch({
                        type: TOGGLE_COMMON_LOADER
                    });
                }
                // setTimeout(() => {
                //     window.location.reload(false);
                // }, 2000);
                dispatch(
                    getUsermanagementList({
                        payload: payload1,
                        paramid: paramid
                    })
                )
            } else if (decryptData[0] == "InvalidPassword") {

                Toaster.error("Invalid Password")
                Setpassvisible(true)
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            } else if (decryptData[0] == "HaveComplianceFailed") {

                Toaster.error("User have Compliance.Reassign Compliance")
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            } else if (decryptData[0] == "InvalidSessionToken") {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();
            } else if (decryptData[0] == 'UserLimitExceeds') {
                Toaster.error('User License Count exceeds')
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            }
            else if (decryptData[0] == 'LegalEntityAdminAlreadyExist') {
                Toaster.error('Legal Entity Admin already exist for these legal entity')
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            }
            else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error(decryptData[0])
            }
        }

    } catch (err) {
        Toaster.error(err);
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const haveCompliance = ({ payload, executeCancel, paramid, authtoken, user_id, remarks, password, type, setModaVisible }) => async dispatch => {
    try {
        console.log(payload, 'payload');
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const payload1 = [

            authtoken,

            {

                "session_token": authtoken,

                "request": [

                    "BlockUser",

                    {

                        "user_id": user_id,

                        "is_blocked": type == 'disable' ? true : false,

                        "active_status": false,

                        "remarks": remarks,

                        "password": password



                    }

                ]

            }

        ]

        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();

        }

        else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            console.log(decryptData, 'decryptData122555');
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else if (decryptData[0] == "HaveComplianceSuccess") {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                dispatch(
                    disableStatusChange({
                        payload: payload1,
                        paramid: paramid,
                        type: type,
                        setModaVisible: setModaVisible
                    })
                )
            }
            else if (decryptData[0] == "HaveComplianceFailed") {

                Toaster.error("User have Compliance.Reassign Compliance")
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            }
            else if (decryptData[0] == "HaveLitigationFailed") {

                Toaster.error("User having litigation.Please reassign litigation")
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            }
            else if (decryptData[0] == 'UserLimitExceeds') {
                Toaster.error('User License Count exceeds')
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            } else if (decryptData[0] == 'InvalidPassword') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error('Invalid Password')
                setModaVisible(true)


            }
            else if (decryptData[0] == 'LegalEntityAdminAlreadyExist') {
                Toaster.error('Legal Entity Admin already exist for these legal entity')
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            }
            else {
                Toaster.error(decryptData[0])
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            }


        }


    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err)
    }
};
export const EdithaveCompliance = ({ payload, executeCancel, paramid, setTabValue, tabValue, authtoken, user_id, setEntityValue, setdomainValue, setCategoryValues, setDivisionValue, remarks, password, type }) => async dispatch => {
    try {
        console.log(payload, 'payloadpayloadpayload');
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        // const payload1 = [

        //     authtoken,

        //     {

        //         "session_token": authtoken,

        //         "request": [

        //             "BlockUser",

        //             {

        //                 "user_id": user_id,

        //                 "is_blocked": type == 'disable' ? true : false,

        //                 "active_status": false,

        //                 "remarks": remarks,

        //                 "password": password



        //             }

        //         ]

        //     }

        // ]

        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            // alertmessage.sweetalert();

        }

        else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            console.log(decryptData, 'decryptDatadecryptData');
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else if (decryptData[0] == "HaveComplianceSuccess") {
                if (type == 'compfie') {
                    setTabValue({
                        ...tabValue,
                        compfie: !tabValue.compfie
                    })
                    setDivisionValue([])
                    setCategoryValues([])
                    setdomainValue([])
                    setEntityValue([])
                }
                else if (type == 'litigation') {
                    setTabValue({
                        ...tabValue,
                        compfie_litigation: !tabValue.compfie
                    })
                }
                else if (type == 'vendor') {
                    setTabValue({
                        ...tabValue,
                        compfie_vendor: !tabValue.compfie
                    })
                }
                // dispatch(
                //     disableStatusChange({
                //         payload: payload1,
                //         paramid: paramid,
                //         type: type
                //     })
                // )
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            }
            else if (decryptData[0] == "HaveComplianceFailed") {

                Toaster.error("User have Compliance.Reassign Compliance")
                if (type == 'compfie') {
                    setTabValue({
                        ...tabValue,
                        compfie: tabValue.compfie
                    })
                }
                else if (type == 'litigation') {
                    setTabValue({
                        ...tabValue,
                        compfie_litigation: tabValue.compfie
                    })
                }
                else if (type == 'vendor') {
                    setTabValue({
                        ...tabValue,
                        compfie_vendor: tabValue.compfie
                    })
                }
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            }
            else if (decryptData[0] == "HaveLitigationFailed") {

                Toaster.error("User having litigation.Please reassign litigation")
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            }
            else if (decryptData[0] == 'HaveVendorComplianceFailed') {
                Toaster.error("User have Compliance.Reassign Compliance")
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            }
            else if (decryptData[0] == "HaveCertificateLevelFailed") {

                Toaster.error("User have certificate level mapping.Reassign certificate Level Mapping")
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });

            }
            else if (decryptData[0] == 'UserLimitExceeds') {
                Toaster.error('User License Count exceeds')
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            } else if (decryptData[0] == 'InvalidPassword') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error('Invalid Password')

            }
            else if (decryptData[0] == 'LegalEntityAdminAlreadyExist') {
                Toaster.error('Legal Entity Admin already exist for these legal entity')
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            }
            else {
                Toaster.error(decryptData[0])
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            }


        }


    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err)
    }
};
export const disableStatusChange = ({ payload, executeCancel, paramid, type, setModaVisible }) => async dispatch => {
    try {
        console.log(payload, 'payloadpayloadpayload');
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const authtoken = localStorage.getItem('authToken');
        const payload1 = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "UserManagementList",
                    {}
                ]
            }
        ]
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))

            console.log(decryptData[0], 'decryptDatadecryptData');
            if (decryptData[0] == "BlockUserSuccess") {

                type === "disable" ?
                    Toaster.success("User Disabled Successfully") :
                    Toaster.success("User Enabled Successfully")
                // dispatch(
                //     getUsermanagementList({
                //         payload: payload1,
                //         paramid: paramid
                //     })
                // )
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                setTimeout(() => {
                    window.location.reload(false);
                }, 2000);
            }
            //   else  if (decryptData[0] == "BlockUserSuccess") {
            //         Toaster.success("User Disabled Successfully!!")
            //         setTimeout(() => {
            //             window.location.reload(false);
            //         }, 2000);
            //     }
            else if (decryptData[0] == "InvalidPassword") {
                // dispatch({
                //     type: TOGGLE_COMMON_LOADER
                // });
                Toaster.error("Invalid Password")
                setModaVisible(true)
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            }
            else if (decryptData[0] == "UserLimitExceeds") {
                // dispatch({
                //     type: TOGGLE_COMMON_LOADER
                // });
                Toaster.error('User License Count exceeds')
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            }
            else if (decryptData[0] == 'LegalEntityAdminAlreadyExist') {
                Toaster.error('Legal Entity Admin already exist for these legal entity')
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            }
            else {
                console.log(decryptData[0], 'decryptData');
                Toaster.error(decryptData[0])
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            }
        }

    } catch (err) {
        Toaster.error(err);
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const LegalAdmindisableStatusChange = ({ payload, executeCancel, paramid, type, setModaVisible }) => async dispatch => {
    try {
        console.log(payload, 'payloadpayloadpayload');
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const authtoken = localStorage.getItem('authToken');
        const payload1 = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "UserManagementList",
                    {}
                ]
            }
        ]
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))

            console.log(decryptData[0], 'decryptDatadecryptData');
            if (decryptData[0] == "BlockUserSuccess") {

                type === "disable" ?
                    Toaster.success("User Disabled Successfully") :
                    Toaster.success("User Enabled Successfully")
                // dispatch(
                //     getUsermanagementList({
                //         payload: payload1,
                //         paramid: paramid
                //     })
                // )
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                setTimeout(() => {
                    window.location.reload(false);
                }, 2000);
            }
            //   else  if (decryptData[0] == "BlockUserSuccess") {
            //         Toaster.success("User Disabled Successfully!!")
            //         setTimeout(() => {
            //             window.location.reload(false);
            //         }, 2000);
            //     }
            else if (decryptData[0] == "InvalidPassword") {
                // dispatch({
                //     type: TOGGLE_COMMON_LOADER
                // });
                Toaster.error("Invalid Password")
                setModaVisible(true)
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            }
            else if (decryptData[0] == "UserLimitExceeds") {
                // dispatch({
                //     type: TOGGLE_COMMON_LOADER
                // });
                Toaster.error('User License Count exceeds')
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            }
            else if (decryptData[0] == 'LegalEntityAdminAlreadyExist') {
                setModaVisible(true)
                Toaster.error('Legal Entity Admin already exist for these legal entity')
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            }
            else {
                console.log(decryptData[0], 'decryptData');
                Toaster.error(decryptData[0])
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            }
        }

    } catch (err) {
        Toaster.error(err);
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const addUserDetails = ({ payload, executeCancel, paramid, setActive, setSelectUnit, setActiveUnit, setCollapseAssignee }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        console.log(payload, 'payload123456667');
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: urls + `/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            }

            else if (decryptData[0] == 'EmployeeCodeSuccess') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                //Toaster.success("Employee Code Added")
                setActive("2")
                setSelectUnit(true)
                setActiveUnit(true)
                setCollapseAssignee('1')
            }
            else if (decryptData[0] == 'EmployeeCodeAlreadyExists') {
                Toaster.error("Employee ID Already Exists")
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            }
            else {
                Toaster.error(decryptData[0])
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            }
            // dispatch({
            //     type: USERMANAGEMENT_LIST,
            //     payload: decryptData,
            // });
        }

    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err)
    }
};
export const addUserManagementDetails = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        console.log(payload, 'payload');
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            if (decryptData[0] == 'LegalEntityAdminAlreadyExist') {
                Toaster.error('Legal Entity Admin already exist for these legal entity')
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                setTimeout(() => {
                    window.location.reload(false);
                }, 2000);
            } else if (decryptData[0] == 'UserLimitExceeds') {
                Toaster.error('User License Count exceeds')
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                setTimeout(() => {
                    window.location.reload(false);
                }, 2000);
            } else if (decryptData[0] == 'SaveClientUserSuccess') {
                Toaster.success('User Added successfully'
                )
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                setTimeout(() => {
                    window.location.reload(false);
                }, 2000);
            }
            else {
                Toaster.success(decryptData[0])
                setTimeout(() => {
                    window.location.reload(false);
                }, 2000);
            }

            // dispatch({
            //     type: USERMANAGEMENT_LIST,
            //     payload: decryptData,
            // });
        }


    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err)
    }
};


export const getById = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            console.log(decryptData, 'decryptData');
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: GET_BY_UNIT,
                    payload: decryptData,
                });
            }

            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
        }


    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err)
    }
};

export const edituserManagementDetails = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            if (decryptData[0] == 'UpdateClientUserSuccess') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.success("User updated successfully")
                setTimeout(() => {
                    window.location.reload(false);
                }, 2000);

            }
            else if (decryptData[0] == "LegalEntityAdminAlreadyExist") {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.success("Legal Entity Admin already exist for these legal entity")
                setTimeout(() => {
                    window.location.reload(false);
                }, 2000);
            }
            else if (decryptData[0] == 'UserLimitExceeds') {
                Toaster.error('User Licence Count exceeds.')
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            }
            else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.success(decryptData[0])
                setTimeout(() => {
                    window.location.reload(false);
                }, 2000);
            }
            console.log(decryptData, 'decryptData555555');
            // setTimeout(() => {
            //     window.location.reload(false);
            // }, 2000);
            // dispatch({
            //     type: USERMANAGEMENT_LIST,
            //     payload: decryptData,
            // });
        }


    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err)
    }
};
export const replaceNewUser = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            if (decryptData[0] == 'UpdateClientUserSuccess') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.success("User updated successfully")

            }
            if (decryptData[0] == 'ReplaceClientUserSuccess') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.success("Replaced User Saved Successfully")

            }
            else if (decryptData[0] == "LegalEntityAdminAlreadyExist") {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.success("Legal Entity Admin already exist for these legal entity")
            }

            else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.success(decryptData[0])

            }
            console.log(decryptData, 'decryptData');
            setTimeout(() => {
                window.location.reload(false);
            }, 2000);
            // dispatch({
            //     type: USERMANAGEMENT_LIST,
            //     payload: decryptData,
            // });
        }


    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err)
    }
};
export const replaceExistingUser = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            if (decryptData[0] == 'UpdateClientUserSuccess') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.success("User updated successfully")

            }
            if (decryptData[0] == 'SaveReplaceClientUserSuccess') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.success("Replaced User Saved Successfully")

            }
            else if (decryptData[0] == "LegalEntityAdminAlreadyExist") {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.success("Legal Entity Admin already exist for these legal entity")
            }

            else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.success(decryptData[0])

            }
            console.log(decryptData[0], 'decryptData');
            setTimeout(() => {
                window.location.reload(false);
            }, 2000);
            // dispatch({
            //     type: USERMANAGEMENT_LIST,
            //     payload: decryptData,
            // });
        }


    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err)
    }
};
export const resendEmail = ({ payload, executeCancel, paramid }) => async dispatch => {

    console.log('snehaaaaaaaa');
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));

            if (decryptData[0] == "ResendRegistrationEmailSuccess") {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.success("Registration Mail Resent Successfully")
            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error(decryptData[0])
            }
            // setTimeout(() => {
            //     window.location.reload(false);
            // }, 2000);
            // dispatch({
            //     type: USERMANAGEMENT_LIST,
            //     payload: decryptData,
            // });
        }


    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err)
    }
};