import HTTP from './../../Libs//http'
import { Toaster } from './../../Libs//Toaster'
import url, { getUrl } from './../../Libs/URL'
import EncryptDecrypt from './../../Libs/Encrypt&Decrypt'
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { TOGGLE_COMMON_LOADER, SETTINGSDATA, SETTINGSDATALIST, SETTINGSDATATABLE,SETTINGAPPROVERDATA } from './../types/index'
import { toast } from 'react-toastify';
const urls = getUrl()

export const getdata = ({ payload, executeCancel, paramid, formkey }) => async dispatch => {
    console.log(payload, "payload8888");
    console.log(formkey, "formkey555");
    try {

        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = formkey
        // const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_user`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 401) ||
            (executeRequest.status === 400 || executeRequest.status === 401)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            console.log(decryptData, "decryptData");
            // if(decryptData[0] === 'GetReviewSettingsFiltersSuccess'){
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            dispatch({
                type: SETTINGSDATA,
                payload: decryptData[1],
            });

            // }
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const getdatalist = ({ payload, executeCancel, paramid, formkey }) => async dispatch => {
    try {

        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = formkey
        // const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 401) ||
            (executeRequest.status === 400 || executeRequest.status === 401)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            console.log(decryptData, "decryptData");
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            dispatch({
                type: SETTINGSDATALIST,
                payload: decryptData[1],
            });
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};


export const getapprovallist = ({ payload, executeCancel, paramid, formkey,approvekey }) => async dispatch => {
    console.log(payload,"payload22");
    try {

        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = formkey
        // const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        }else if (executeRequest.code == 'ERR_NETWORK') {
            dispatch({
                type: TOGGLE_COMMON_LOADER,
                payload: true
            });
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            if(decryptData[0] == "HaveComplianceFailed"){
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error('Legal Entity have Pending Compliance')
            }else if(decryptData[0] == "HaveVendorComplianceFailed"){
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error('Legal Entity have Pending Compliance')
            }else{
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                dispatch({
                    type: SETTINGAPPROVERDATA,
                    payload: approvekey,
                });
            }
           
        }
    } catch (err) {
        console.log(err,"err1111");
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const getdatatable = ({ payload, executeCancel, paramid, formkey }) => async dispatch => {
    try {

        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = formkey
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_user`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 401) ||
            (executeRequest.status === 400 || executeRequest.status === 401)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            if (decryptData[0] === "SaveSettingsFormDetailsSuccess") {
                toast.success("Selected action has been saved successfully")
            }
            console.log(decryptData, "decryptData");
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            dispatch({
                type: SETTINGSDATATABLE,
                payload: decryptData[1],
            });
            setTimeout(() => {
                window.location.reload(false);
            }, 2000);
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};