import React, { Fragment, useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({

});
const PDFViewer = () => {
    const iframeRef = useRef();
    const [iframeHeight, setIframeHeight] = useState('600');

    useEffect(() => {
        setIframeHeight(`${iframeRef.current.contentWindow.document.documentElement.scrollHeight}px`);
    }, [])

    return (
        <>
            <div id='page-wrapper' className="page-wrapper">
                <div className="page-titles pb-0 pt-2" style={{ overflow: 'hidden' }}>
                    <iframe
                        ref={iframeRef}
                        id="widget"
                        name="widget"
                        title="widget"
                        seamless="seamless"
                        frameBorder="0"
                        
                        width="100%"
                        height={iframeHeight}
                        src="./quick_help.pdf"
                        style={{ position: "relative", height: iframeHeight, zIndex:'0' }}
                    ></iframe>
                    {/* <iframe
                        ref={ref}
                        onLoad={onLoad}
                        id="myFrame"
                        src="./quick_help.pdf"
                        width="100%"
                        height={height}
                        scrolling="no"
                        frameBorder="0"
                        style={{
                            maxWidth: 640,
                            width: "100%",
                            overflow: "auto",
                        }}
                    >

                    </iframe> */}
                </div>

            </div></>

    );
};

export default connect(mapStateToProps, {})(PDFViewer);