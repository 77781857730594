import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React, { useState } from 'react';

const ScrollButton = () => {

	const [visible, setVisible] = useState(false)
	const [isTop, setIsTop] = useState(false)

	const toggleVisible = () => {
		const scrolled = document.documentElement.scrollTop;

		if (document.documentElement.scrollHeight >= window.innerHeight * 1.4) {
			if (scrolled >= document.body.scrollHeight / 3) {
				setIsTop(true)
			}
			if (scrolled <= document.body.scrollHeight / 3) {
				setVisible(true)
				setIsTop(false)
			}
			if (scrolled === 0) {
				setVisible(false)
			}
		} else {
			setVisible(false)
		}

	};

	const scrollToBottom = () => {
		window.scrollTo({
			top: isTop ? 0 : document.documentElement.scrollHeight,
			behavior: 'auto'

		});
	};

	window.addEventListener('scroll', toggleVisible);



	return (
		<div className='Button-btm'>
			{isTop ?
				// <Tooltip getPopupContainer={(triggerNode) => {

				// 	return triggerNode.parentNode;

				// }} className='gotop' title='Go to Top' placement={'left'}><Button className=' btn btn-circle btn-sm rounded ' style={{ display: visible ? 'inline' : 'none' }} onClick={scrollToBottom}><UpOutlined /></Button></Tooltip>
				// : <Tooltip style={{ position: 'fixed' }} title='Go to Bottom' placement={'left'}><Button className='btn btn-circle btn-sm rounded ' style={{ display: visible ? 'inline' : 'none' }} onClick={scrollToBottom}><DownOutlined /></Button></Tooltip>}
				// <Tooltip
				// 	getPopupContainer={trigger => trigger.parentNode}
				// 	className='gotop'
				// 	title='Go to Top'
				// 	placement={'left'}>
				// 	<Button className=' btn btn-circle btn-sm rounded '
				// 		style={{ display: visible ? 'inline' : 'none' }}
				// 		onClick={scrollToBottom}>
				// 		<UpOutlined />
				// 	</Button>
				// </Tooltip>
				// : <Tooltip
				// 	getPopupContainer={trigger => trigger.parentNode}
				// 	style={{ position: 'fixed' }}
				// 	title='Go to Bottom'
				// 	placement={'left'}>
				// 	<Button className='btn btn-circle btn-sm rounded '
				// 		style={{ display: visible ? 'inline' : 'none' }}
				// 		onClick={scrollToBottom}>
				// 		<DownOutlined />
				// 	</Button>
				// </Tooltip>
				<Button title='Go to Top' className=' btn btn-circle btn-sm rounded '
					style={{ display: visible ? 'inline' : 'none' }}
					onClick={scrollToBottom}>
					<UpOutlined />
				</Button> :

				<Button title='Go to Bottom' className='btn btn-circle btn-sm rounded '
					style={{ display: visible ? 'inline' : 'none' }}
					onClick={scrollToBottom}>
					<DownOutlined />
				</Button>
			}

		</div>
	);
}


export default ScrollButton;
