import { useLocation, Link } from 'react-router-dom';
import { Collapse, Select, Modal, Button, Table, Tabs, Progress, Input, Checkbox, Divider, Space, Switch, Tree, Tooltip, Pagination } from 'antd';
import { useState, useEffect, useRef } from 'react';
import SimpleReactValidator from "simple-react-validator";
import { connect } from 'react-redux';
import { getdata, getdatalist, getdatatable, getapprovallist } from '../Store/Action/Settings'
import { toast } from 'react-toastify';
import { PlusOutlined, ExclamationCircleTwoTone, BookTwoTone, ArrowRightOutlined, ArrowLeftOutlined, PlayCircleOutlined, SearchOutlined, FilterFilled, CheckOutlined, CloseOutlined, SettingOutlined } from '@ant-design/icons';


const mapStateToProps = (state) => ({
    settingslist: state.Settings.settingsdata
})
const Settings = ({ getdata, getdatalist, getdatatable, settingslist, getapprovallist }) => {


    const { TabPane } = Tabs;
    const { Panel } = Collapse;
    const { Option } = Select;
    const location = useLocation();
    const paramid = location.pathname;
    const _ = require("lodash");
    const [pageSize, setPageSize] = useState(25);
    const [current, setCurrent] = useState(1);
    const [pageState, setpageState] = useState(false);
    const sessionValue = localStorage.getItem('sessionValue')
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const validator = useRef(new SimpleReactValidator());
    const validator1 = useRef(new SimpleReactValidator());
    const [countrydata, setCountryData] = useState(null)
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [key, setKey] = useState("1")
    const [days, setDays] = useState({
        sp_compliance: null,
        after_trigger: null,
        after_due_date: null
    })
    const [monthlydata, setMonthlyData] = useState({
        assignee: "",
        approver: ""
    })
    const [settingsdata, setSettingsData] = useState({
        legel_entity: "",
        legalEntityId: ""
    })
    const [datalist, setDataList] = useState()
    console.log(datalist, "datalist111");
    const callername = JSON.parse(localStorage.getItem('sessionValue'));
    const [formdata, Setformdata] = useState({
        form_url: '',
        form_key: ''
    });
    const [totaldata, setTotalData] = useState({
        domain: [],
        settings_details: [],
        settings_users: []
    })
    // Rewrite Code
    const [settingsArray, setSettingsArray] = useState({
        rectifyArray: [],
        rejectedArray: [],
        reassignedArray: [],
        approvedArray: [],
        rectifyCertificateArray: []
    })

    const [rectifyArray, setRectifyArray] = useState([])
    const [rejectedArray, setRejectedArray] = useState([])
    const [reassignedArray, setReassignedArray] = useState([])
    const [approvedArray, setApprovedArray] = useState([])
    const [rectifyCertificateArray, setRectifyCertificateArray] = useState([])
    console.log(reassignedArray, 'rectifyArrayrectifyArray')

    const [requiredFieldValidation, setRequiredFieldValidation] = useState([])
    const [mailSettings, setMailSettings] = useState({
        rectifyTask: '',
        rejectedTask: '',
        reassignedTask: '',
        assigneeTaskCalendar: '',
        approverTaskCalendar: '',
        viewOnlyUserCalendar: '',
        assigneeTaskValue: '',
        approverTaskValue: '',
        viewOnlyUserValue: '',
        remainderForSp: '',
        assigneeBeforeDueDate: '',
        assigneeBeforeDueDateValue: '',
        assigneeEscalationAfterDueDate: '',
        assigneeEscalationAfterDueDateValue: '',
        assigneeRemainder: '',
        advanceEscalationRemainder: '',
        escalationRemainde: '',
        twoLevelApprove: '',
        countryName: '',
        contractFrom: '',
        contractTo: '',
        businessGroupName: '',
        legalEntityName: '',
        fileSpaceLimit: '',
        usedSpaceLimit: '',
        approvedCertificate: '',
        rectifyCertificate: '',
        unitsSelectedReject: '',
        unitsSelectedRectify: '',
        unitsSelectedReassign: '',
        unitsSelectedApproved: '',
        unitsSelectedRectifyCertificate: ''
    })
    console.log(requiredFieldValidation, 'mailSettings')

    //  Rewrite Code
    console.log(totaldata, "totaldata");
    const [task, setTask] = useState({
        rectify_task: "1",
        rejected_task: "1",
        reassigned_task: "1",
        assignee_task: "1",
        approver_task: "1",
        after_trigger: "1",
        after_due_date: "1"
    })
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [iconEnable, setIconEnable] = useState(false);
    const [clearText, setClearText] = useState('');
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setRectifyArray([])
        setIsModalOpen(false);
    };


    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const showModal1 = () => {
        setIsModalOpen1(true);
    };
    const handleOk1 = () => {
        setIsModalOpen1(false);
    };
    const handleCancel1 = () => {
        setIsModalOpen1(false);
    };

    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const showModal2 = () => {
        setIsModalOpen2(true);
    };
    const handleOk2 = () => {
        setIsModalOpen2(false);
    };
    const handleCancel2 = () => {
        setIsModalOpen2(false);
    };

    const [isModalOpen3, setIsModalOpen3] = useState(false);
    const showModal3 = () => {
        setIsModalOpen3(true);
    };
    const handleOk3 = () => {
        setIsModalOpen3(false);
    };
    const handleCancel3 = () => {
        setIsModalOpen3(false);
    };

    const [isModalOpen4, setIsModalOpen4] = useState(false);
    const showModal4 = () => {
        setIsModalOpen4(true);
    };
    const handleOk4 = () => {
        setIsModalOpen4(false);
    };
    const handleCancel4 = () => {
        setIsModalOpen4(false);
    };
    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
            const country = sessionParsedValue.country_info
            setCountryData(country)
        }
    }, [localStorage.getItem('SelectedEntityid')])
    useEffect(() => {
        validator1.current.showMessages()
        if (isAuth) {
            const getUrl = callername.login_response.menu['My Accounts']
            const settings = _.filter(getUrl, { form_name: "Settings" })
            Setformdata({
                ...formdata,
                form_url: settings && settings[0] && settings[0].form_url,
                form_key: settings && settings[0] && settings[0].form_key
            })
        }
    }, [isAuth])
    useEffect(() => {
        if (settingslist && settingslist.getdata && localStorage.getItem('isVendor') == "false") {
            setTotalData({
                ...totaldata,
                domain: settingslist && settingslist.getdata && settingslist.getdata.dmn_unt_lst,
                settings_details: settingslist && settingslist.getdata && settingslist.getdata.settings_details,
                settings_users: settingslist && settingslist.getdata && settingslist.getdata.settings_users,
            })
        } else {
            setTotalData({
                ...totaldata,
                domain: settingslist && settingslist.getdata && settingslist.getdata.domain_unit_list,
                settings_details: settingslist && settingslist.getdata && settingslist.getdata.settings_details,
                settings_users: settingslist && settingslist.getdata && settingslist.getdata.settings_users,
            })
        }
    }, [settingslist && settingslist.getdata])
    useEffect(() => {
        if (settingslist && settingslist.getdatalist) {
            setDataList(settingslist && settingslist.getdatalist && settingslist.getdatalist.settings_domains)
        }
    }, [settingslist && settingslist.getdatalist])

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        setSelectedKeys(e.target.value ? [e.target.value] : [])
        confirm({ closeDropdown: false });
        // setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
            >
                <Input className='form-control'
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    value={selectedKeys[0]}
                    autoFocus={'on'}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}

                />
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    //     // color: filtered ? '#1890ff' : undefined,
                    //     fontWeight: 'bold',
                    //     // backgroundColor:'blue',
                    //     fontSize: '15px',
                    //     color: 'black'
                }}
            />
            // <FileSearchOutlined 
            // style={{
            //     // color: filtered ? '#1890ff' : undefined,
            //     fontWeight:'bold',
            //     // backgroundColor:'blue',
            //     // fontSize:'20px',
            //     color:'black'
            // }}
            // />
            // <i class="bi bi-search"></i>
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });

    // const [existingChecked, setExistingChecked] = useState({
    //     checkedRectify: '',
    //     checkedRejected: '',
    //     checkedReassigned: '',
    //     checkedApproved: '',
    //     checkedRectifyCertificate: ''
    // });   
    const [existingChecked, setExistingChecked] = useState([])
    const [existingChecked2, setExistingChecked2] = useState([])
    const [existingChecked3, setExistingChecked3] = useState([])
    const [existingChecked4, setExistingChecked4] = useState([])
    const [existingChecked5, setExistingChecked5] = useState([])
    console.log(existingChecked, 'existingCheckec')


    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});

    const handleChange = (pagination, filters, sorter) => {
        console.log(sorter, "sorterrrrr");
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const columns_old = [
        {
            title: '#',
            align: "center",
            render: (row, record, index) => index + 1
        },
        {
            title: 'Employee',
            dataIndex: 'employee_name',
            align: 'left',
            key: "employee_name",
            ...getColumnSearchProps('employee_name', 'Employee'),
            filteredValue: filteredInfo.employee_name || null,
            sorter: (a, b) => a.employee_name.localeCompare(b.employee_name),
            sortOrder: sortedInfo.columnKey === 'employee_name' ? sortedInfo.order : null,
        },
        {
            title: 'User Level',
            ...getColumnSearchProps('user_level_name', 'User Level'),
            dataIndex: 'user_level_name',
            key: "user_level_name",
            align: "left",
            filteredValue: filteredInfo.user_level_name || null,
            sorter: (a, b) => {
                if (a && a.user_level_name && a.user_level_name.length && b && b.user_level_name && b.user_level_name.length) {
                    return a.user_level_name.localeCompare(b.user_level_name);
                } else if (a && a.user_level_name && a.user_level_name.length) {
                    return -1;
                } else if (b && b.user_level_name && b.user_level_name.length) {
                    return 1;
                }

                return 0;
            },
            sortOrder: sortedInfo.columnKey === 'user_level_name' ? sortedInfo.order : null,


        },
        {
            title: 'User Category',
            ...getColumnSearchProps('category_name', 'User Category'),
            dataIndex: 'category_name',
            align: "left",
            key: "category_name",
            filteredValue: filteredInfo.category_name || null,
            sorter: (a, b) => {
                if (a && a.category_name && a.category_name.length && b && b.category_name && b.category_name.length) {
                    return a.category_name.localeCompare(b.category_name);
                } else if (a && a.category_name && a.category_name.length) {
                    return -1;
                } else if (b && b.category_name && b.category_name.length) {
                    return 1;
                }

                return 0;
            },
            sortOrder: sortedInfo.columnKey === 'category_name' ? sortedInfo.order : null,
        },
    ];


    const columns = [
        {
            title: '#',
            align: "center",
            render: (value, item, index) => (current - 1) * 10 + index + 1,
        },
        {
            title: 'Employee',
            dataIndex: 'employee_name',
            align: 'left',
            key: "employee_name",
            ...getColumnSearchProps('employee_name', 'Employee Name'),
            filteredValue: filteredInfo.employee_name || null,
            sorter: (a, b) => a.employee_name.localeCompare(b.employee_name),
            sortOrder: sortedInfo.columnKey === 'employee_name' ? sortedInfo.order : null,
        },
        {
            title: 'User Level',
            dataIndex: 'user_level_name',
            key: "user_level_name",
            align: "left",
            ...getColumnSearchProps('user_level_name', 'User Level'),
            filteredValue: filteredInfo.user_level_name || null,
            sorter: (a, b) => {
                if (a && a.user_level_name && a.user_level_name.length && b && b.user_level_name && b.user_level_name.length) {
                    return a.user_level_name.localeCompare(b.user_level_name);
                } else if (a && a.user_level_name && a.user_level_name.length) {
                    return -1;
                } else if (b && b.user_level_name && b.user_level_name.length) {
                    return 1;
                }

                return 0;
            },
            sortOrder: sortedInfo.columnKey === 'user_level_name' ? sortedInfo.order : null,

        },
        {
            title: 'User Category',
            dataIndex: 'category_name',
            align: "left",
            key: "category_name",
            ...getColumnSearchProps('category_name', 'User Category'),
            filteredValue: filteredInfo.category_name || null,
            sorter: (a, b) => {
                if (a && a.category_name && a.category_name.length && b && b.category_name && b.category_name.length) {
                    return a.category_name.localeCompare(b.category_name);
                } else if (a && a.category_name && a.category_name.length) {
                    return -1;
                } else if (b && b.category_name && b.category_name.length) {
                    return 1;
                }

                return 0;
            },
            sortOrder: sortedInfo.columnKey === 'category_name' ? sortedInfo.order : null,
        },
    ]

    // const columns1 = [
    //     {
    //         title: '#',
    //         align: "center",
    //         render: (row, record, index) => index + 1
    //     },
    //     {
    //         title: 'Employee',
    //         ...getColumnSearchProps('employee_name'),
    //         dataIndex: 'employee_name',
    //         align: "center",
    //         key: "employee_name",
    //         filteredValue: filteredInfo.employee_name || null,
    //         sorter: (a, b) => a.employee_name.localeCompare(b.employee_name),
    //         sortOrder: sortedInfo.columnKey === 'employee_name' ? sortedInfo.order : null,
    //     },
    //     {
    //         title: 'User Level',
    //         ...getColumnSearchProps('user_level_name'),
    //         dataIndex: 'user_level_name',
    //         key: "user_level_name",
    //         // defaultSortOrder: 'descend',
    //         align: "center",
    //         filteredValue: filteredInfo.user_level_name || null,
    //         sorter: (a, b) => {
    //             if (a && a.user_level_name && a.user_level_name.length && b && b.user_level_name && b.user_level_name.length) {
    //                 return a.user_level_name.localeCompare(b.user_level_name);
    //             } else if (a && a.user_level_name && a.user_level_name.length) {
    //                 return -1;
    //             } else if (b && b.user_level_name && b.user_level_name.length) {
    //                 return 1;
    //             }

    //             return 0;
    //         },
    //         sortOrder: sortedInfo.columnKey === 'user_level_name' ? sortedInfo.order : null,

    //     },
    //     {
    //         title: 'User Category',
    //         ...getColumnSearchProps('category_name'),
    //         dataIndex: 'category_name',
    //         align: "center",
    //         key: "category_name",
    //         filteredValue: filteredInfo.category_name || null,
    //         sorter: (a, b) => {
    //             if (a && a.category_name && a.category_name.length && b && b.category_name && b.category_name.length) {
    //                 return a.category_name.localeCompare(b.category_name);
    //             } else if (a && a.category_name && a.category_name.length) {
    //                 return -1;
    //             } else if (b && b.category_name && b.category_name.length) {
    //                 return 1;
    //             }

    //             return 0;
    //         },
    //         sortOrder: sortedInfo.columnKey === 'category_name' ? sortedInfo.order : null,
    //     },
    // ];

    const onChange1 = (pagination, filters, sorter, extra) => {
        // console.log('params', pagination, filters, sorter, extra);
    };

    useEffect(() => {
        if (isAuth && entityid !== "null") {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetSettingsFormDetails",
                        {
                            "legal_entity_id": entityid
                        }
                    ]
                }
            ]
            getdata({
                payload: payload,
                paramid: paramid,
                formkey: formdata.form_key
            })
            const payload1 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetLegalEntityDomains",
                        {
                            "legal_entity_id": entityid
                        }
                    ]
                }
            ]
            getdatalist({
                payload: payload1,
                paramid: paramid,
                formkey: formdata.form_key
            })
        }
    }, [authtoken, isAuth, entityid, formdata])

    const onsubmit = () => {
        console.log(mailSettings.viewOnlyUserValue, 'mailSettings.viewOnlyUserValue')
        if (mailSettings.viewOnlyUserCalendar == 1 && (mailSettings.viewOnlyUserValue == '' || mailSettings.viewOnlyUserValue == null)) {
            toast.error('Select Date Value')
        } else {
            if (localStorage.getItem('isVendor') == 'true') {
                if ((mailSettings.rectifyTask == 0 || mailSettings.rectifyTask == 1 && Object.values(rectifyArray).length > 0) && (mailSettings.rejectedTask == false || mailSettings.rejectedTask == true && Object.values(rejectedArray).length > 0) && (mailSettings.reassignedTask == false || mailSettings.reassignedTask == true && Object.values(reassignedArray).length > 0) && (mailSettings.approvedCertificate == false || mailSettings.approvedCertificate == true && Object.values(approvedArray).length > 0) && (mailSettings.rectifyCertificate == 0 || mailSettings.rectifyCertificate == 1 && Object.values(rectifyCertificateArray).length > 0)) {
                    if (requiredFieldValidation.filter(Boolean).length == 0) {
                        const payload2 = [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request": [
                                    "SaveSettingsFormDetails",
                                    {
                                        "legal_entity_id": entityid,
                                        "two_level_approve": false,
                                        "assignee_reminder": mailSettings.assigneeRemainder ? parseInt(mailSettings.assigneeRemainder) : null,
                                        "advance_escalation_reminder": mailSettings.advanceEscalationRemainder ? parseInt(mailSettings.advanceEscalationRemainder) : null,
                                        "escalation_reminder": mailSettings.escalationRemainde ? parseInt(mailSettings.escalationRemainde) : null,
                                        "mail_trigger": false,
                                        "assignee_mail_reminder_val": mailSettings.assigneeTaskCalendar == "W" ? parseInt(mailSettings.assigneeTaskValue) : mailSettings.assigneeTaskCalendar == "M" ? parseInt(mailSettings.assigneeTaskValue) : mailSettings.assigneeTaskCalendar == "D" ? parseInt(mailSettings.assigneeTaskValue) : 0,
                                        "assignee_mail_reminder": mailSettings.assigneeTaskCalendar,
                                        "approve_mail_reminder_val": mailSettings.approverTaskCalendar == "W" ? parseInt(mailSettings.approverTaskValue) : mailSettings.approverTaskCalendar == "M" ? parseInt(mailSettings.approverTaskValue) : mailSettings.approverTaskCalendar == "D" ? parseInt(mailSettings.approverTaskValue) : 0,
                                        "approve_mail_reminder": mailSettings.approverTaskCalendar,
                                        "reassign_sp": mailSettings.remainderForSp,
                                        "selected_units": "{}",
                                        "mail_status_reject": mailSettings.rejectedTask,
                                        "mail_status_reassign": mailSettings.reassignedTask,
                                        "units_selected_reject": JSON.stringify(rejectedArray),//temp2,
                                        "units_selected_reassign": JSON.stringify(reassignedArray),//temp3,
                                        "reminder_assig_val": parseInt(mailSettings.assigneeBeforeDueDateValue),
                                        "reminder_assig": parseInt(mailSettings.assigneeBeforeDueDate),
                                        "reminder_esc_val": parseInt(mailSettings.assigneeEscalationAfterDueDateValue),
                                        "reminder_esc": parseInt(mailSettings.assigneeEscalationAfterDueDate),
                                        "units_selected_rectify": JSON.stringify(rectifyArray),//JSON.stringify(settingsArray.rectifyArray),
                                        "mail_status_rectify": mailSettings.rectifyTask == 1 ? true : false,
                                        "vor_mail_status": mailSettings.viewOnlyUserCalendar,
                                        "vor_mail_date": mailSettings.viewOnlyUserCalendar == 1 ? parseInt(mailSettings.viewOnlyUserValue) : 0,
                                        "units_selected_certificate": JSON.stringify(approvedArray),//temp4,
                                        "units_selected_cer_rectify": JSON.stringify(rectifyCertificateArray),//temp5,
                                        "mail_status_cer_rectify": mailSettings.rectifyCertificate == "0" ? false : true,
                                        "mail_status_certificate": mailSettings.approvedCertificate

                                    }
                                ]
                            }
                        ]
                        getdatatable({
                            payload: payload2,
                            paramid: paramid,
                            formkey: formdata.form_key
                        })
                    } else {
                        toast.error(requiredFieldValidation.filter(Boolean)[requiredFieldValidation.filter(Boolean).length - 1]);
                    }
                } else {
                    toast.error('Select Atleast One Unit')
                }
            } else {
                console.log(mailSettings.rejectedTask, 'mailSettings.rejectedTask')
                if ((mailSettings.rectifyTask == 0 || mailSettings.rectifyTask == 1 && Object.values(rectifyArray).length > 0) && (mailSettings.rejectedTask == false || mailSettings.rejectedTask == true && Object.values(rejectedArray).length > 0) && (mailSettings.reassignedTask == false || mailSettings.reassignedTask == true && Object.values(reassignedArray).length > 0)) {
                    if (requiredFieldValidation.filter(Boolean).length == 0) {
                        const payload2 = [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request": [
                                    "SaveSettingsFormDetails",
                                    {
                                        "legal_entity_id": entityid,
                                        "two_level_approve": false,
                                        "assignee_reminder": mailSettings.assigneeRemainder ? parseInt(mailSettings.assigneeRemainder) : null,
                                        "advance_escalation_reminder": mailSettings.advanceEscalationRemainder ? parseInt(mailSettings.advanceEscalationRemainder) : null,
                                        "escalation_reminder": mailSettings.escalationRemainde ? parseInt(mailSettings.escalationRemainde) : null,
                                        "mail_trigger": false,
                                        "assignee_mail_reminder_val": mailSettings.assigneeTaskCalendar == "W" ? parseInt(mailSettings.assigneeTaskValue) : mailSettings.assigneeTaskCalendar == "M" ? parseInt(mailSettings.assigneeTaskValue) : mailSettings.assigneeTaskCalendar == "D" ? parseInt(mailSettings.assigneeTaskValue) : 0,
                                        "assignee_mail_reminder": mailSettings.assigneeTaskCalendar,
                                        "approve_mail_reminder_val": mailSettings.approverTaskCalendar == "W" ? parseInt(mailSettings.approverTaskValue) : mailSettings.approverTaskCalendar == "M" ? parseInt(mailSettings.approverTaskValue) : mailSettings.approverTaskCalendar == "D" ? parseInt(mailSettings.approverTaskValue) : 0,
                                        "approve_mail_reminder": mailSettings.approverTaskCalendar,
                                        "reassign_sp": mailSettings.remainderForSp,
                                        "selected_units": "{}",
                                        "mail_status_reject": mailSettings.rejectedTask,
                                        "mail_status_reassign": mailSettings.reassignedTask,
                                        "units_selected_reject": JSON.stringify(rejectedArray),//temp2,
                                        "units_selected_reassign": JSON.stringify(reassignedArray),//temp3,
                                        "reminder_assig_val": parseInt(mailSettings.assigneeBeforeDueDateValue),
                                        "reminder_assig": parseInt(mailSettings.assigneeBeforeDueDate),
                                        "reminder_esc_val": parseInt(mailSettings.assigneeEscalationAfterDueDateValue),
                                        "reminder_esc": parseInt(mailSettings.assigneeEscalationAfterDueDate),
                                        "units_selected_rectify": JSON.stringify(rectifyArray),
                                        "mail_status_rectify": mailSettings.rectifyTask == 1 ? true : false,
                                        "vor_mail_status": mailSettings.viewOnlyUserCalendar,
                                        "vor_mail_date": mailSettings.viewOnlyUserCalendar == 1 ? parseInt(mailSettings.viewOnlyUserValue) : 0,
                                        // "units_selected_certificate": JSON.stringify(settingsArray.approvedArray),//temp4,
                                        // "units_selected_cer_rectify": JSON.stringify(settingsArray.rectifyCertificateArray),//temp5,
                                        // "mail_status_cer_rectify": mailSettings.rectifyCertificate == "0" ? false : true,
                                        // "mail_status_certificate": mailSettings.approvedCertificate

                                    }
                                ]
                            }
                        ]
                        console.log(payload2, 'payload2')
                        getdatatable({
                            payload: payload2,
                            paramid: paramid,
                            formkey: formdata.form_key
                        })
                    } else {
                        toast.error(requiredFieldValidation.filter(Boolean)[requiredFieldValidation.filter(Boolean).length - 1]);
                    }

                } else {
                    toast.error('Select Atleast One Unit')
                }
            }
        }

    }

    const [settingsDomain, setSettingsDomain] = useState({
        rectifyDomain: [],
        rejectedDomain: [],
        reassignedDomain: [],
        approvedDomain: [],
        rectifyCertificateDomain: []
    })

    useEffect(() => {
        if (totaldata.domain && totaldata.domain.length > 0) {
            let finalText = []
            totaldata.domain.map((totaldatalist) => {
                let childrenArray = []
                totaldatalist.children.length > 0 && totaldatalist.children.map((totaldatalistchild) => {
                    childrenArray.push({
                        "title": totaldatalistchild.text,
                        "key": totaldatalistchild.id
                    })
                })
                finalText.push({
                    "title": totaldatalist.text,
                    "key": totaldatalist.id,
                    "children": childrenArray
                })
            })

            setSettingsDomain({
                ...settingsDomain,
                rectifyDomain: finalText,
                rejectedDomain: finalText,
                reassignedDomain: finalText,
                approvedDomain: finalText,
                rectifyCertificateDomain: finalText
            })
        }
    }, [totaldata.domain])

    console.log(settingsDomain, 'settingsDomain')

    const CheckboxGroup = Checkbox.Group;
    const filterdata = totaldata && totaldata.domain && totaldata.domain[0] && totaldata.domain[0].children.map((item) => {
        return item.text
    })

    const plainOptions = filterdata
    const defaultCheckedList = filterdata
    const [unitid, setUnitId] = useState([])
    const [checkedList, setCheckedList] = useState(defaultCheckedList);
    const [checkAll, setCheckAll] = useState(false);

    const onChange2 = (list) => {
        let temp = []
        let filterdata1
        for (let i in list) {
            filterdata1 = totaldata && totaldata.domain && totaldata.domain[0] && totaldata.domain[0].children && totaldata.domain[0].children.filter((item) => {
                return item.text === list[i]
            })
        }
        temp.push(filterdata1 && filterdata1[0] && filterdata1[0].data && filterdata1[0].data.unit_id.toString())
        // setUnitId(Object.assign({},[[...unitid, ...temp]]))
        setUnitId([...unitid, ...temp])
        setCheckedList(list);
        setCheckAll(list.length === plainOptions.length);
    };
    const onCheckAllChange = (e) => {
        let temp = []
        const filterdata1 = totaldata && totaldata.domain && totaldata.domain[0] && totaldata.domain[0].children && totaldata.domain[0].children.map((item) => {
            return item && item.data && item.data.unit_id
        })
        temp.push(...filterdata1.toString())
        setUnitId([...unitid, ...temp])
        setCheckedList(e.target.checked ? plainOptions : []);
        setCheckAll(e.target.checked);
    };

    const plainOptions1 = filterdata
    const defaultCheckedLis1 = filterdata
    const [unitid1, setUnitId1] = useState([])
    const [checkedList1, setCheckedList1] = useState(defaultCheckedLis1);
    const [checkAll1, setCheckAll1] = useState(false);
    const onChange21 = (list) => {
        let temp = []
        let filterdata1
        for (let i in list) {
            filterdata1 = totaldata && totaldata.domain && totaldata.domain[0] && totaldata.domain[0].children && totaldata.domain[0].children.filter((item) => {
                return item.text === list[i]
            })
        }
        temp.push(filterdata1 && filterdata1[0] && filterdata1[0].data && filterdata1[0].data.unit_id.toString())
        setUnitId1([...unitid1, ...temp])
        setCheckedList1(list);
        setCheckAll1(list.length === plainOptions1.length);
    };
    const onCheckAllChange1 = (e) => {
        let temp = []
        const filterdata1 = totaldata && totaldata.domain && totaldata.domain[0] && totaldata.domain[0].children && totaldata.domain[0].children.map((item) => {
            return item && item.data && item.data.unit_id
        })
        temp.push(...filterdata1.toString())
        setUnitId1([...unitid1, ...temp])
        setCheckedList1(e.target.checked ? plainOptions1 : []);
        setCheckAll1(e.target.checked);
    };

    const plainOptions2 = filterdata
    const defaultCheckedList2 = filterdata
    const [unitid2, setUnitId2] = useState([])
    const [checkedList2, setCheckedList2] = useState(defaultCheckedList2);
    const [checkAll2, setCheckAll2] = useState(false);
    const onChange22 = (list) => {
        let temp = []
        let filterdata1
        for (let i in list) {
            filterdata1 = totaldata && totaldata.domain && totaldata.domain[0] && totaldata.domain[0].children && totaldata.domain[0].children.filter((item) => {
                return item.text === list[i]
            })
        }
        temp.push(filterdata1 && filterdata1[0] && filterdata1[0].data && filterdata1[0].data.unit_id.toString())
        setUnitId2([...unitid2, ...temp])
        setCheckedList2(list);
        setCheckAll2(list.length === plainOptions2.length);
    };
    const onCheckAllChange2 = (e) => {
        let temp = []
        const filterdata1 = totaldata && totaldata.domain && totaldata.domain[0] && totaldata.domain[0].children && totaldata.domain[0].children.map((item) => {
            return item && item.data && item.data.unit_id
        })
        temp.push(...filterdata1.toString())
        setUnitId2([...unitid2, ...temp])
        setCheckedList2(e.target.checked ? plainOptions2 : []);
        setCheckAll2(e.target.checked);
    };

    const plainOptions3 = filterdata
    const defaultCheckedList3 = filterdata
    const [unitid3, setUnitId3] = useState([])
    const [checkedList3, setCheckedList3] = useState(defaultCheckedList3);
    const [checkAll3, setCheckAll3] = useState(false);
    const onChange23 = (list) => {
        let temp = []
        let filterdata1
        for (let i in list) {
            filterdata1 = totaldata && totaldata.domain && totaldata.domain[0] && totaldata.domain[0].children && totaldata.domain[0].children.filter((item) => {
                return item.text === list[i]
            })
        }
        temp.push(filterdata1 && filterdata1[0] && filterdata1[0].data && filterdata1[0].data.unit_id.toString())
        setUnitId3([...unitid2, ...temp])
        setCheckedList3(list);
        setCheckAll3(list.length === plainOptions3.length);
    };
    const onCheckAllChange3 = (e) => {
        let temp = []
        const filterdata1 = totaldata && totaldata.domain && totaldata.domain[0] && totaldata.domain[0].children && totaldata.domain[0].children.map((item) => {
            return item && item.data && item.data.unit_id
        })
        temp.push(...filterdata1.toString())
        setUnitId3([...unitid3, ...temp])
        setCheckedList3(e.target.checked ? plainOptions3 : []);
        setCheckAll3(e.target.checked);
    };

    const plainOptions4 = filterdata
    const defaultCheckedList4 = filterdata
    const [unitid4, setUnitId4] = useState([])
    const [checkedList4, setCheckedList4] = useState(defaultCheckedList4);
    const [checkAll4, setCheckAll4] = useState(false);
    const onChange24 = (list) => {
        let temp = []
        let filterdata1
        for (let i in list) {
            filterdata1 = totaldata && totaldata.domain && totaldata.domain[0] && totaldata.domain[0].children && totaldata.domain[0].children.filter((item) => {
                return item.text === list[i]
            })
        }
        temp.push(filterdata1 && filterdata1[0] && filterdata1[0].data && filterdata1[0].data.unit_id.toString())
        setUnitId4([...unitid4, ...temp])
        setCheckedList4(list);
        setCheckAll4(list.length === plainOptions4.length);
    };
    const onCheckAllChange4 = (e) => {
        let temp = []
        const filterdata1 = totaldata && totaldata.domain && totaldata.domain[0] && totaldata.domain[0].children && totaldata.domain[0].children.map((item) => {
            return item && item.data && item.data.unit_id
        })
        temp.push(...filterdata1.toString())
        setUnitId4([...unitid4, ...temp])
        setCheckedList4(e.target.checked ? plainOptions4 : []);
        setCheckAll4(e.target.checked);
    };

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') !== "All Legal Entity") {
            setSettingsData({
                ...settingsdata,
                legel_entity: sessionArr[0].le_name
            })
        }
    }, [localStorage.getItem('SelectedEntity') !== "All Legal Entity"])
    useEffect(() => {
        if (totaldata && totaldata.settings_details && totaldata.settings_details) {
            setDays({
                ...days,
                sp_compliance: totaldata && totaldata.settings_details && totaldata.settings_details[0] && totaldata.settings_details[0].reassign_sp,
                after_trigger: totaldata && totaldata.settings_details && totaldata.settings_details[0] && totaldata.settings_details[0].assignee_reminder,
                after_due_date: totaldata && totaldata.settings_details && totaldata.settings_details[0] && totaldata.settings_details[0].escalation_reminder
            })
        }
    }, [totaldata && totaldata.settings_details && totaldata.settings_details])

    const [baseData, setBaseData] = useState([]);

    useEffect(() => {
        if (totaldata && totaldata.settings_users) {
            setBaseData(totaldata && totaldata.settings_users);
        }
    }, [totaldata && totaldata.settings_users])

    const namef = totaldata && totaldata.domain && totaldata.domain[0] && totaldata.domain[0].children && totaldata.domain[0].children.map((item) => {
        return item
    })

    const homescreen = () => {
        window.location.href = '/dashboard'
    }
    // Rewrite Code
    useEffect(() => {
        if (totaldata && totaldata.settings_details && totaldata.settings_details.length > 0) {
            setMailSettings({
                ...mailSettings,
                assigneeTaskCalendar: totaldata.settings_details[0].assignee_mail_reminder,
                assigneeTaskValue: totaldata.settings_details[0].assignee_mail_reminder_val,
                approverTaskCalendar: totaldata.settings_details[0].approve_mail_reminder,
                approverTaskValue: totaldata.settings_details[0].approve_mail_reminder_val,
                viewOnlyUserCalendar: totaldata.settings_details[0].vor_mail_status,
                viewOnlyUserValue: totaldata.settings_details[0].vor_mail_date,
                rectifyTask: totaldata.settings_details[0].mail_status_rectify,
                rejectedTask: totaldata.settings_details[0].mail_status_reject,
                reassignedTask: totaldata.settings_details[0].mail_status_reassign,
                remainderForSp: totaldata.settings_details[0].reassign_sp,
                assigneeBeforeDueDate: totaldata.settings_details[0].mail_status_asgne_remind,
                assigneeBeforeDueDateValue: totaldata.settings_details[0].assignee_reminder,
                assigneeEscalationAfterDueDate: totaldata.settings_details[0].mail_status_esclt_remind,
                assigneeEscalationAfterDueDateValue: totaldata.settings_details[0].escalation_reminder,
                twoLevelApprove: totaldata.settings_details[0].two_level_approve,
                assigneeRemainder: totaldata.settings_details[0].assignee_reminder,
                advanceEscalationRemainder: totaldata.settings_details[0].advance_escalation_reminder,
                escalationRemainde: totaldata.settings_details[0].escalation_reminder,
                countryName: totaldata.settings_details[0].country_name,
                businessGroupName: totaldata.settings_details[0].business_group_name,
                legalEntityName: totaldata.settings_details[0].legal_entity_name,
                contractFrom: totaldata.settings_details[0].contract_from,
                contractTo: totaldata.settings_details[0].contract_to,
                fileSpaceLimit: totaldata.settings_details[0].file_space_limit,
                usedSpaceLimit: totaldata.settings_details[0].used_file_space,
                approvedCertificate: totaldata.settings_details[0].mail_status_certificate,
                rectifyCertificate: totaldata.settings_details[0].mail_status_cer_rectify,
                unitsSelectedReject: totaldata.settings_details[0].units_selected_reject,
                unitsSelectedRectify: totaldata.settings_details[0].units_selected_rectify,
                unitsSelectedReassign: totaldata.settings_details[0].units_selected_reassign,
                unitsSelectedRectifyCertificate: totaldata.settings_details[0].units_selected_cer_rectify,
                unitsSelectedApproved: totaldata.settings_details[0].certification_mail



            })
        }
    }, [totaldata.settings_details])

    const options = [];
    for (let i = 1; i <= 31; i++) {
        options.push(i)
    }

    useEffect(() => {
        if (mailSettings.unitsSelectedRectify != '') {
            let keys1;
            let tempAccessInfo = []
            let finalKeyWithValue = [];
            let convertToArray1 = JSON.parse(mailSettings.unitsSelectedRectify)
            if (convertToArray1) {
                keys1 = Object.keys(convertToArray1)
                Object.values(convertToArray1).map((convertToArray1list, index) => {
                    convertToArray1list.map((convertToArray1Childlist) => {
                        finalKeyWithValue = keys1[index] + "-" + convertToArray1Childlist
                        tempAccessInfo.push(finalKeyWithValue)
                    })
                })
                setExistingChecked(tempAccessInfo)

                setRectifyArray(JSON.parse(mailSettings.unitsSelectedRectify))

            }

        }

    }, [mailSettings.unitsSelectedRectify])

    useEffect(() => {
        if (mailSettings.unitsSelectedReject != '') {
            let keys2;
            let tempAccessInfo2 = []
            let finalKeyWithValue2 = [];
            let convertToArray2 = JSON.parse(mailSettings.unitsSelectedReject)
            if (convertToArray2) {
                keys2 = Object.keys(convertToArray2)

                Object.values(convertToArray2).map((convertToArray2list, index2) => {
                    convertToArray2list.map((convertToArray2Childlist) => {
                        finalKeyWithValue2 = keys2[index2] + "-" + convertToArray2Childlist
                        tempAccessInfo2.push(finalKeyWithValue2)
                    })
                })
                setExistingChecked2(tempAccessInfo2)

                setRejectedArray(JSON.parse(mailSettings.unitsSelectedReject))
            }
        }
    }, [mailSettings.unitsSelectedReject])

    useEffect(() => {
        if (mailSettings.unitsSelectedReassign != '') {
            let keys3;
            let tempAccessInfo3 = []
            let finalKeyWithValue3 = [];
            let convertToArray3 = JSON.parse(mailSettings.unitsSelectedReassign)
            if (convertToArray3) {
                keys3 = Object.keys(convertToArray3)

                Object.values(convertToArray3).map((convertToArray3list, index3) => {
                    convertToArray3list.map((convertToArray3Childlist) => {
                        finalKeyWithValue3 = keys3[index3] + "-" + convertToArray3Childlist
                        tempAccessInfo3.push(finalKeyWithValue3)
                    })
                })
                setExistingChecked3(tempAccessInfo3)

                setReassignedArray(JSON.parse(mailSettings.unitsSelectedReassign))
            }
        }
    }, [mailSettings.unitsSelectedReassign])

    useEffect(() => {
        if (mailSettings.unitsSelectedApproved != '' && localStorage.getItem('isVendor') == "true") {
            let keys4;
            let tempAccessInfo4 = []
            let finalKeyWithValue4 = [];
            let convertToArray4 = JSON.parse(mailSettings.unitsSelectedApproved)
            if (convertToArray4) {
                keys4 = Object.keys(convertToArray4)

                Object.values(convertToArray4).map((convertToArray4list, index4) => {
                    convertToArray4list.map((convertToArray4Childlist) => {
                        finalKeyWithValue4 = keys4[index4] + "-" + convertToArray4Childlist
                        tempAccessInfo4.push(finalKeyWithValue4)
                    })
                })
                setExistingChecked4(tempAccessInfo4)

                setApprovedArray(JSON.parse(mailSettings.unitsSelectedApproved))
            }
        }
    }, [mailSettings.unitsSelectedApproved])

    useEffect(() => {
        if (mailSettings.unitsSelectedRectifyCertificate != '' && localStorage.getItem('isVendor') == "true") {
            let keys5;
            let tempAccessInfo5 = []
            let finalKeyWithValue5 = [];
            let convertToArray5 = JSON.parse(mailSettings.unitsSelectedRectifyCertificate)
            if (convertToArray5) {
                keys5 = Object.keys(convertToArray5)

                Object.values(convertToArray5).map((convertToArray5list, index5) => {
                    convertToArray5list.map((convertToArray5Childlist) => {
                        finalKeyWithValue5 = keys5[index5] + "-" + convertToArray5Childlist
                        tempAccessInfo5.push(finalKeyWithValue5)
                    })
                })
                setExistingChecked5(tempAccessInfo5)

                setRectifyCertificateArray(JSON.parse(mailSettings.unitsSelectedRectifyCertificate))
            }
        }
    }, [mailSettings.unitsSelectedRectifyCertificate])


    const onCheck = (checkedKeys, info) => {
        let myArray = [];
        let domainId = '';
        let yArray = {};
        let x = [];
        let k = {}
        console.log('onCheck', checkedKeys, info);
        for (let i = 0; i < checkedKeys.length; i++) {
            let str = checkedKeys[i];
            console.log(str, 'checkedKeys')
            if (/[,\-]/.test(str)) {
                let arr1 = str.split('-')
                domainId = arr1[0]
                if (!yArray[domainId])
                    yArray[domainId] = [];

                if (yArray[domainId].indexOf(arr1[1]) < 0)
                    yArray[domainId].push(arr1[1]) //compliance_id.push(arr1[1])                   
                x.push(arr1[1])

            }
        }

        setRectifyArray(yArray)
    };

    const onCheck1 = (checkedKeys, info) => {
        let myArray = [];
        let domainId = '';
        let yArray = {};
        let x = [];
        let k = {}
        console.log('onCheck', checkedKeys, info);
        for (let i = 0; i < checkedKeys.length; i++) {
            let str = checkedKeys[i];
            console.log(str, 'checkedKeys')
            if (/[,\-]/.test(str)) {
                let arr1 = str.split('-')
                domainId = arr1[0]
                if (!yArray[domainId])
                    yArray[domainId] = [];

                if (yArray[domainId].indexOf(arr1[1]) < 0)
                    yArray[domainId].push(arr1[1]) //compliance_id.push(arr1[1])                   
                x.push(arr1[1])

            }
        }

        setRejectedArray(yArray)
    };

    const onCheck2 = (checkedKeys, info) => {
        let myArray = [];
        let domainId = '';
        let yArray = {};
        let x = [];
        let k = {}
        console.log('onCheck', checkedKeys, info);
        for (let i = 0; i < checkedKeys.length; i++) {
            let str = checkedKeys[i];
            console.log(str, 'checkedKeys')
            if (/[,\-]/.test(str)) {
                let arr1 = str.split('-')
                domainId = arr1[0]
                if (!yArray[domainId])
                    yArray[domainId] = [];

                if (yArray[domainId].indexOf(arr1[1]) < 0)
                    yArray[domainId].push(arr1[1]) //compliance_id.push(arr1[1])                   
                x.push(arr1[1])

            }
        }

        setReassignedArray(yArray)
    };

    const onCheck3 = (checkedKeys, info) => {
        let myArray = [];
        let domainId = '';
        let yArray = {};
        let x = [];
        let k = {}
        console.log('onCheck', checkedKeys, info);
        for (let i = 0; i < checkedKeys.length; i++) {
            let str = checkedKeys[i];
            console.log(str, 'checkedKeys')
            if (/[,\-]/.test(str)) {
                let arr1 = str.split('-')
                domainId = arr1[0]
                if (!yArray[domainId])
                    yArray[domainId] = [];

                if (yArray[domainId].indexOf(arr1[1]) < 0)
                    yArray[domainId].push(arr1[1]) //compliance_id.push(arr1[1])                   
                x.push(arr1[1])

            }
        }

        setApprovedArray(yArray)
    };

    const onCheck4 = (checkedKeys, info) => {
        let myArray = [];
        let domainId = '';
        let yArray = {};
        let x = [];
        let k = {}
        console.log('onCheck', checkedKeys, info);
        for (let i = 0; i < checkedKeys.length; i++) {
            let str = checkedKeys[i];
            console.log(str, 'checkedKeys')
            if (/[,\-]/.test(str)) {
                let arr1 = str.split('-')
                domainId = arr1[0]
                if (!yArray[domainId])
                    yArray[domainId] = [];

                if (yArray[domainId].indexOf(arr1[1]) < 0)
                    yArray[domainId].push(arr1[1]) //compliance_id.push(arr1[1])                   
                x.push(arr1[1])

            }
        }
        setRectifyCertificateArray(yArray)
    };

    useEffect(() => {
        if (settingslist.twolevelapproval == true) {
            setMailSettings({
                ...mailSettings,
                twoLevelApprove: false
            })
        } else {
            setMailSettings({
                ...mailSettings,
                twoLevelApprove: true
            })
        }


    }, [settingslist.twolevelapproval])

    // Rewrite Code
    return (
        <>
            <div className="page-wrapper">
                <div className="page-titles pb-2 pt-2">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                    </li>
                                    <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                        <span style={{ fontSize: '16px' }}>My Accounts</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <span>Settings</span>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <Modal maskClosable={false} title={localStorage.getItem('isVendor') == 'false' ? "Unit Wise Status" : "Vendor Wise Status"} footer={[
                    <Button key="submit" shape="round" type="primary" onClick={handleOk}>
                        Ok
                    </Button>,
                    <Button key="back" shape="round" onClick={handleCancel}>
                        Cancel
                    </Button>,

                ]} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} className={"newStyleModalPassword-settings add-service-prv " + localStorage.getItem('currentTheme')}>
                    <Tree
                        checkable
                        defaultCheckedKeys={existingChecked}
                        onCheck={onCheck}
                        treeData={settingsDomain.rectifyDomain}
                        height={250}
                    />

                    {/* <Checkbox onChange={onCheckAllChange} checked={checkAll}>
                        {totaldata && totaldata.domain && totaldata.domain[0] && totaldata.domain[0].text}
                    </Checkbox>
                    <Divider />
                    <CheckboxGroup className="checkboxgroup" options={plainOptions} value={checkedList} onChange={onChange2} /> */}
                </Modal>
                <Modal maskClosable={false} title={localStorage.getItem('isVendor') == 'false' ? "Unit Wise Status" : "Vendor Wise Status"} open={isModalOpen1} onOk={handleOk1} footer={[
                    <Button key="submit" shape="round" type="primary" onClick={handleOk1}>
                        Ok
                    </Button>,
                    <Button key="back" shape="round" onClick={handleCancel1}>
                        Cancel
                    </Button>,

                ]} onCancel={handleCancel1} className={"newStyleModalPassword-settings add-service-prv " + localStorage.getItem('currentTheme')}>
                    <Tree
                        checkable
                        defaultCheckedKeys={existingChecked2}
                        onCheck={onCheck1}
                        treeData={settingsDomain.rejectedDomain}
                        height={250}
                    />
                    {/* <Checkbox onChange={onCheckAllChange1} checked={checkAll1}>
                        {totaldata && totaldata.domain && totaldata.domain[0] && totaldata.domain[0].text}
                    </Checkbox>
                    <Divider />
                    <CheckboxGroup className="checkboxgroup" options={plainOptions1} value={checkedList1} onChange={onChange21} /> */}
                </Modal>
                <Modal title={localStorage.getItem('isVendor') == 'false' ? "Unit Wise Status" : "Vendor Wise Status"} open={isModalOpen2} onOk={handleOk2} footer={[
                    <Button key="submit" shape="round" type="primary" onClick={handleOk2}>
                        Ok
                    </Button>,
                    <Button key="back" shape="round" onClick={handleCancel2}>
                        Cancel
                    </Button>,

                ]} onCancel={handleCancel2} className={"newStyleModalPassword-settings add-service-prv " + localStorage.getItem('currentTheme')}>
                    <Tree
                        checkable
                        defaultCheckedKeys={existingChecked3}
                        onCheck={onCheck2}
                        treeData={settingsDomain.reassignedDomain}
                        height={250}
                    />
                    {/* <Checkbox onChange={onCheckAllChange2} checked={checkAll2}>
                        {totaldata && totaldata.domain && totaldata.domain[0] && totaldata.domain[0].text}
                    </Checkbox>
                    <Divider />
                    <CheckboxGroup className="checkboxgroup" options={plainOptions2} value={checkedList2} onChange={onChange22} /> */}
                </Modal>
                <Modal title={localStorage.getItem('isVendor') == 'false' ? "Unit Wise Status" : "Vendor Wise Status"} open={isModalOpen3} onOk={handleOk3} footer={[
                    <Button key="submit" shape="round" type="primary" onClick={handleOk3}>
                        Ok
                    </Button>,
                    <Button key="back" shape="round" onClick={handleCancel3}>
                        Cancel
                    </Button>,

                ]} onCancel={handleCancel3} className={"newStyleModalPassword-settings add-service-prv " + localStorage.getItem('currentTheme')}>
                    <Tree
                        checkable
                        defaultCheckedKeys={existingChecked4}
                        onCheck={onCheck3}
                        treeData={settingsDomain.approvedDomain}
                        height={250}
                    />
                    {/* <Checkbox onChange={onCheckAllChange3} checked={checkAll3}>
                        {totaldata && totaldata.domain && totaldata.domain[0] && totaldata.domain[0].text}
                    </Checkbox>
                    <Divider />
                    <CheckboxGroup className="checkboxgroup" options={plainOptions3} value={checkedList3} onChange={onChange23} /> */}
                </Modal>
                <Modal title={localStorage.getItem('isVendor') == 'false' ? "Unit Wise Status" : "Vendor Wise Status"} open={isModalOpen4} onOk={handleOk4} footer={[
                    <Button key="submit" shape="round" type="primary" onClick={handleOk4}>
                        Ok
                    </Button>,
                    <Button key="back" shape="round" onClick={handleCancel4}>
                        Cancel
                    </Button>,

                ]} onCancel={handleCancel4} className={"newStyleModalPassword-settings add-service-prv " + localStorage.getItem('currentTheme')}>
                    <Tree
                        checkable
                        defaultCheckedKeys={existingChecked5}
                        onCheck={onCheck4}
                        treeData={settingsDomain.rectifyCertificateDomain}
                        height={250}
                    />
                    {/* <Checkbox onChange={onCheckAllChange4} checked={checkAll4}>
                        {totaldata && totaldata.domain && totaldata.domain[0] && totaldata.domain[0].text}
                    </Checkbox>
                    <Divider />
                    <CheckboxGroup className="checkboxgroup" options={plainOptions4} value={checkedList4} onChange={onChange24} /> */}
                </Modal>
                <div className="container-fluid pt-1">
                    <div className="row">
                        <div className={"col-12 " + localStorage.getItem('currentTheme')}>
                            <div className="card settings-card">
                                <div className="card-body" style={{ padding: "10px" }}>
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-6 d-flex align-items-center'>
                                                <label className='me-3'><b>Legal Entity </b><span style={{ color: "red" }}>*</span></label>
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?

                                                    <Select
                                                        onChange={(value, data) => {
                                                            setCurrentEntity(value)
                                                            setSettingsData({
                                                                ...settingsdata,
                                                                legel_entity: data.children,
                                                                legalEntityId: value
                                                            })
                                                        }}
                                                        style={{ width: '50%' }}
                                                        value={settingsdata.legel_entity || undefined}
                                                        placeholder="Enter Legal Entity"

                                                    >
                                                        {sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
                                                            return (
                                                                <Option key={item.le_id}>
                                                                    {item.le_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    : <p className='mb-0' >{localStorage.getItem('SelectedEntity')}</p>}
                                                {validator.current.message(
                                                    'legalentity',
                                                    settingsdata.legel_entity,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Required Field Missing',
                                                        }
                                                    })}
                                            </div>
                                            {entityid != "null" ?
                                                <>
                                                    <div className='col-md-6  d-flex align-items-center justify-content-end'>
                                                        <label className='me-3'> <b>Enable 2 levels of approval
                                                            <span style={{ color: "red" }}>*</span></b> </label>

                                                        <div className='serv-switch'>
                                                            <Switch
                                                                checked={mailSettings.twoLevelApprove == false ? true : false}
                                                                checkedChildren={<CheckOutlined style={{ color: localStorage.getItem('currentTheme') == 'theme_four' ? 'black' : 'white' }} />}
                                                                unCheckedChildren={<CloseOutlined style={{ color: localStorage.getItem('currentTheme') == 'theme_four' ? 'black' : 'white' }} />}
                                                                onClick={() => {
                                                                    const payloads = [
                                                                        authtoken,
                                                                        {
                                                                            "session_token": authtoken,
                                                                            "request": [
                                                                                "HaveCompliancesLELevel",
                                                                                {
                                                                                    "le_ids": [
                                                                                        settingsdata.legalEntityId ? settingsdata.legalEntityId : localStorage.getItem('SelectedEntityid')
                                                                                    ],
                                                                                    "product_id": localStorage.getItem('isVendor') == "false" ? "1" : "3"
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                    getapprovallist({
                                                                        payload: payloads,
                                                                        paramid: paramid,
                                                                        formkey: formdata.form_key,
                                                                        approvekey: mailSettings.twoLevelApprove
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                                : false}
                                        </div>
                                    </div>
                                    {entityid != "null" ?
                                        <>
                                            <Tabs type="card" style={{ marginTop: "20px" }}>
                                                <TabPane tab="Task Mail Settings" key="1" >
                                                    <div className='row'>
                                                        <div className='col-auto card card-hover ms-3 mb-0 py-2 mb-2' style={{ border: ' 2px solid #ecf0f2', marginLeft: ' 15px !important' }}>
                                                            <div className="d-flex align-items-center">
                                                                <label ><b>Rectify Task :</b><span style={{ color: "red" }}>*</span> </label>
                                                                <div className="d-flex align-items-center ms-2">
                                                                    <div className="serv-switch mb-1">
                                                                        <Switch
                                                                            checked={mailSettings.rectifyTask == "1" ? true : false}
                                                                            checkedChildren={<CheckOutlined style={{ color: localStorage.getItem('currentTheme') == 'theme_four' ? 'black' : 'white' }} />}
                                                                            unCheckedChildren={<CloseOutlined style={{ color: localStorage.getItem('currentTheme') == 'theme_four' ? 'black' : 'white' }} />}
                                                                            defaultChecked={mailSettings.rectifyTask == "1" ? true : false}
                                                                            onChange={(checked) => {
                                                                                console.log(checked, 'checked')
                                                                                if (checked == false) {
                                                                                    var convert = {}
                                                                                    setRectifyArray(convert)
                                                                                }
                                                                                setMailSettings({
                                                                                    ...mailSettings,
                                                                                    rectifyTask: checked ? 1 : 0
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    {mailSettings.rectifyTask == "1" ?
                                                                        <Tooltip title={'Settings'}><Button className='ms-2' shape="circle" type="primary" icon={<SettingOutlined />} onClick={showModal}></Button></Tooltip>
                                                                        : ""}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-auto  card card-hover ms-3 mb-0 py-2 mb-2' style={{ border: ' 2px solid #ecf0f2', marginLeft: ' 15px !important' }}>
                                                            <div className="d-flex align-items-center">
                                                                <label ><b>Rejected Task :</b><span style={{ color: "red" }}>*</span> </label>
                                                                <div className="d-flex justify-content-between ms-2">
                                                                    <div className="serv-switch mb-1">
                                                                        <Switch
                                                                            checked={mailSettings.rejectedTask}
                                                                            checkedChildren={<CheckOutlined style={{ color: localStorage.getItem('currentTheme') == 'theme_four' ? 'black' : 'white' }} />}
                                                                            unCheckedChildren={<CloseOutlined style={{ color: localStorage.getItem('currentTheme') == 'theme_four' ? 'black' : 'white' }} />}
                                                                            defaultChecked={mailSettings.rejectedTask}
                                                                            onChange={(checked) => {
                                                                                console.log(checked, 'checked')
                                                                                if (checked == false) {
                                                                                    var convert = {}
                                                                                    setRejectedArray(convert)
                                                                                }
                                                                                setMailSettings({
                                                                                    ...mailSettings,
                                                                                    rejectedTask: checked
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    {mailSettings.rejectedTask === true ?
                                                                        <Tooltip title={'Settings'}><Button className='ms-2' shape="circle" style={{ float: "right" }} icon={<SettingOutlined />} type="primary" onClick={showModal1}></Button></Tooltip>
                                                                        : ""}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-auto card card-hover ms-3 mb-0 py-2 me-3 mb-2' style={{ border: ' 2px solid #ecf0f2', marginLeft: ' 15px !important' }}>
                                                            <div className="d-flex align-items-center">
                                                                <label ><b>Reassigned Task :</b><span style={{ color: "red" }}>*</span> </label>
                                                                <div className="d-flex justify-content-between ms-2">
                                                                    <div className="serv-switch mb-1">
                                                                        <Switch
                                                                            checked={mailSettings.reassignedTask}
                                                                            checkedChildren={<CheckOutlined style={{ color: localStorage.getItem('currentTheme') == 'theme_four' ? 'black' : 'white' }} />}
                                                                            unCheckedChildren={<CloseOutlined style={{ color: localStorage.getItem('currentTheme') == 'theme_four' ? 'black' : 'white' }} />}
                                                                            defaultChecked={mailSettings.reassignedTask}
                                                                            onChange={(checked) => {
                                                                                if (checked == false) {
                                                                                    var convert = {}
                                                                                    setReassignedArray(convert)
                                                                                }
                                                                                setMailSettings({
                                                                                    ...mailSettings,
                                                                                    reassignedTask: checked
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    {mailSettings.reassignedTask === true ?
                                                                        <Tooltip title={'Settings'}><Button className='ms-2' shape="circle" icon={<SettingOutlined />} type="primary" onClick={showModal2}></Button></Tooltip>
                                                                        : ""}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-auto card card-hover ms-2 mb-0 py-2 me-5 mb-2' style={{ border: ' 2px solid #ecf0f2', marginLeft: ' 15px !important' }}>
                                                            <div className="d-flex align-items-center">
                                                                <label ><b>View Only User Calendar:</b><span style={{ color: "red" }}>*</span> </label>
                                                                <div className="d-flex justify-content-between ms-2">
                                                                    <div className="serv-switch mb-1">
                                                                        <Switch
                                                                            checked={mailSettings.viewOnlyUserCalendar == "1" ? true : false}
                                                                            checkedChildren={<CheckOutlined style={{ color: localStorage.getItem('currentTheme') == 'theme_four' ? 'black' : 'white' }} />}
                                                                            unCheckedChildren={<CloseOutlined style={{ color: localStorage.getItem('currentTheme') == 'theme_four' ? 'black' : 'white' }} />}
                                                                            defaultChecked={mailSettings.viewOnlyUserCalendar == "1" ? true : false}
                                                                            onChange={(checked) => {
                                                                                if (checked == false) {
                                                                                    requiredFieldValidation[2] = undefined
                                                                                    setRequiredFieldValidation([...requiredFieldValidation])
                                                                                }
                                                                                setMailSettings({
                                                                                    ...mailSettings,
                                                                                    viewOnlyUserCalendar: checked ? 1 : 0
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    {mailSettings.viewOnlyUserCalendar == "1" ?
                                                                        <Select className='ms-2' style={{ width: "90px" }}
                                                                            defaultValue={mailSettings.viewOnlyUserValue || "Select"}
                                                                            onChange={(data, value) => {
                                                                                console.log(data, 'datadata')
                                                                                if (data != '') {
                                                                                    setMailSettings({
                                                                                        ...mailSettings,
                                                                                        viewOnlyUserValue: data
                                                                                    })
                                                                                    requiredFieldValidation[2] = undefined
                                                                                    setRequiredFieldValidation([...requiredFieldValidation])
                                                                                } else {
                                                                                    setMailSettings({
                                                                                        ...mailSettings,
                                                                                        viewOnlyUserValue: ''
                                                                                    })
                                                                                    requiredFieldValidation[2] = "Select Date Value"
                                                                                    setRequiredFieldValidation([...requiredFieldValidation])
                                                                                }

                                                                            }}
                                                                        >
                                                                            <Option value=""> Select </Option>
                                                                            {options.map((listOptions) => {
                                                                                return <Option key={listOptions}>{listOptions}</Option>
                                                                            })}
                                                                        </Select> : ""}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row pt-2'>
                                                        <div className='col-6 mb-2 pe-1'>
                                                            <div className="card p-2 mb-1" style={{ border: ' 2px solid #ecf0f2', minHeight: '75px', marginLeft: ' 15px !important' }}>
                                                                <label ><b>Assignee Task Calendar :</b><span style={{ color: "red" }}>*</span> </label>
                                                                <div className="d-flex justify-content-between">
                                                                    <div>
                                                                        <input id='assignee-weekly' type="radio" value={mailSettings.assigneeTaskCalendar} checked={mailSettings.assigneeTaskCalendar == "W"} onClick={(e) => {
                                                                            setMailSettings({
                                                                                ...mailSettings,
                                                                                assigneeTaskCalendar: "W"
                                                                            })
                                                                        }} />
                                                                        <label htmlFor='assignee-weekly' style={{ marginTop: "10px" }}>Weekly <span style={{ display: mailSettings.assigneeTaskCalendar == "W" ? 'inline' : 'none' }}>(Every Sunday)</span></label>
                                                                    </div>
                                                                    <div>
                                                                        <input id='assignee-monthly' type="radio" value={mailSettings.assigneeTaskCalendar} checked={mailSettings.assigneeTaskCalendar == "M"} onClick={(e) => {
                                                                            setMailSettings({
                                                                                ...mailSettings,
                                                                                assigneeTaskCalendar: "M"
                                                                            })
                                                                        }} />
                                                                        <label htmlFor='assignee-monthly' style={{ marginTop: "10px" }}>Monthly</label>
                                                                        {mailSettings.assigneeTaskCalendar == "M" ?
                                                                            <Select style={{ marginLeft: "20px", width: "100px" }}
                                                                                defaultValue={mailSettings.assigneeTaskValue || "Select"}
                                                                                onChange={(data, value) => {
                                                                                    if (data != '') {
                                                                                        setMailSettings({
                                                                                            ...mailSettings,
                                                                                            assigneeTaskValue: data
                                                                                        })
                                                                                        requiredFieldValidation[0] = undefined
                                                                                        setRequiredFieldValidation([...requiredFieldValidation])
                                                                                    } else {
                                                                                        setMailSettings({
                                                                                            ...mailSettings,
                                                                                            assigneeTaskValue: ''
                                                                                        })
                                                                                        requiredFieldValidation[0] = "Select Date Value"
                                                                                        setRequiredFieldValidation([...requiredFieldValidation])
                                                                                    }

                                                                                }}
                                                                            >
                                                                                <Option value=""> Select </Option>
                                                                                {options.map((listOptions) => {
                                                                                    return <Option key={listOptions}>{listOptions}</Option>
                                                                                })}
                                                                            </Select> : ""}
                                                                    </div>
                                                                    <div>
                                                                        <input id='assignee-daily' type="radio" value={mailSettings.assigneeTaskCalendar} checked={mailSettings.assigneeTaskCalendar == "D"} onClick={(e) => {
                                                                            setMailSettings({
                                                                                ...mailSettings,
                                                                                assigneeTaskCalendar: "D"
                                                                            })
                                                                        }} />
                                                                        <label htmlFor='assignee-daily' style={{ marginTop: "10px" }}>Daily</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-6 mb-2 ps-1'  >
                                                            <div className="card p-2 mb-1" style={{ border: ' 2px solid #ecf0f2', minHeight: '75px', marginLeft: ' 15px !important' }}>
                                                                <label ><b>Approver Task Calendar:</b><span style={{ color: "red" }}>*</span> </label>
                                                                <div className="d-flex justify-content-between">
                                                                    <div>
                                                                        <input id='approver-weekly' type="radio" value={mailSettings.approverTaskCalendar} checked={mailSettings.approverTaskCalendar == "W"} onClick={(e) => {
                                                                            setMailSettings({
                                                                                ...mailSettings,
                                                                                approverTaskCalendar: "W"
                                                                            })
                                                                        }} />
                                                                        <label htmlFor='approver-weekly' style={{ marginTop: "10px" }}>Weekly <span style={{ display: mailSettings.approverTaskCalendar == "W" ? 'inline' : 'none' }}>(Every Sunday)</span></label>

                                                                    </div>

                                                                    <div>
                                                                        <input id='approver-monthly' type="radio" value={mailSettings.approverTaskCalendar} checked={mailSettings.approverTaskCalendar == "M"} onClick={(e) => {
                                                                            setMailSettings({
                                                                                ...mailSettings,
                                                                                approverTaskCalendar: "M"
                                                                            })
                                                                        }} />
                                                                        <label htmlFor='approver-monthly' style={{ marginTop: "10px" }}>Monthly</label>
                                                                        {mailSettings.approverTaskCalendar == "M" ?
                                                                            <Select style={{ marginLeft: "20px", width: "100px" }}
                                                                                defaultValue={mailSettings.approverTaskValue || "Select"}
                                                                                onChange={(data, value) => {
                                                                                    if (data != '') {
                                                                                        setMailSettings({
                                                                                            ...mailSettings,
                                                                                            approverTaskValue: data
                                                                                        })
                                                                                        requiredFieldValidation[1] = undefined
                                                                                        setRequiredFieldValidation([...requiredFieldValidation])
                                                                                    } else {
                                                                                        setMailSettings({
                                                                                            ...mailSettings,
                                                                                            approverTaskValue: ''
                                                                                        })
                                                                                        requiredFieldValidation[1] = "Select Date Value"
                                                                                        setRequiredFieldValidation([...requiredFieldValidation])
                                                                                    }

                                                                                }}
                                                                            >
                                                                                <Option value=""> Select </Option>
                                                                                {options.map((listOptions) => {
                                                                                    return <Option key={listOptions}>{listOptions}</Option>
                                                                                })}
                                                                            </Select> : ""}
                                                                    </div>
                                                                    <div>
                                                                        <input id='approver-daily' type="radio" value={mailSettings.approverTaskCalendar} checked={mailSettings.approverTaskCalendar == "D"} onClick={(e) => {
                                                                            setMailSettings({
                                                                                ...mailSettings,
                                                                                approverTaskCalendar: "D"
                                                                            })
                                                                        }} />
                                                                        <label htmlFor='approver-daily' style={{ marginTop: "10px" }}>Daily</label>

                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-12 text-center mt-2'>
                                                        <Button style={{ alignItems: "center" }} onClick={onsubmit} type="primary" shape="round" icon={<PlayCircleOutlined />}>Submit</Button>
                                                    </div>

                                                </TabPane>
                                                <TabPane tab="Reminders/Escalation Settings" key="2">
                                                    <div className='row pb-2'>
                                                        <div className='col-4 border-end'>
                                                            <label ><b>Reassign service provider compliance reminder to all :</b><span style={{ color: "red" }}>*</span> </label>
                                                            <div className="d-flex justify-content-between me-4">
                                                                <div>
                                                                    <label>For Every</label>

                                                                    <Input type='text' className='ms-2' style={{ width: "50px" }}
                                                                        maxLength={2}
                                                                        autoFocus={true}
                                                                        defaultValue={mailSettings.remainderForSp} key={mailSettings.remainderForSp}
                                                                        onChange={(e) => {
                                                                            console.log(e.target.value, 'e.target.value')
                                                                            if (e.target.value != '') {
                                                                                if (e.target.value == '0' || e.target.value == '00') {
                                                                                    setMailSettings({
                                                                                        ...mailSettings,
                                                                                        remainderForSp: ''
                                                                                    })
                                                                                    requiredFieldValidation[3] = 'Invalid Duration Value for service providers reminder'
                                                                                    setRequiredFieldValidation([...requiredFieldValidation])
                                                                                } else {
                                                                                    setMailSettings({
                                                                                        ...mailSettings,
                                                                                        remainderForSp: parseInt(e.target.value)
                                                                                    })
                                                                                    requiredFieldValidation[3] = undefined
                                                                                    setRequiredFieldValidation([...requiredFieldValidation])
                                                                                }

                                                                            } else {
                                                                                setMailSettings({
                                                                                    ...mailSettings,
                                                                                    remainderForSp: ''
                                                                                })
                                                                                requiredFieldValidation[3] = 'Reassign service provider compliance reminder to all is Required'
                                                                                setRequiredFieldValidation([...requiredFieldValidation])
                                                                            }

                                                                        }}
                                                                    ></Input>

                                                                    <label className='ms-2' >Days</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-4 border-end'>
                                                            <label ><b>Reminder to assignee before due date after trigger :</b><span style={{ color: "red" }}>*</span> </label>
                                                            <div className="d-flex justify-content-start">
                                                                <div className="serv-switch mb-1">
                                                                    <Switch
                                                                        checked={mailSettings.assigneeBeforeDueDate == "1" ? true : false}
                                                                        checkedChildren={<CheckOutlined style={{ color: localStorage.getItem('currentTheme') == 'theme_four' ? 'black' : 'white' }} />}
                                                                        unCheckedChildren={<CloseOutlined style={{ color: localStorage.getItem('currentTheme') == 'theme_four' ? 'black' : 'white' }} />}
                                                                        defaultChecked={mailSettings.assigneeBeforeDueDate == "1" ? true : false}
                                                                        onChange={(checked) => {
                                                                            setMailSettings({
                                                                                ...mailSettings,
                                                                                assigneeBeforeDueDate: checked ? 1 : 0
                                                                            })
                                                                        }}
                                                                    />
                                                                </div>
                                                                <label className='ms-1 mt-2'>For Every</label>
                                                                <Input type='text' className='ms-2' style={{ width: "50px" }}
                                                                    maxLength={2}
                                                                    autoFocus={true}
                                                                    disabled={mailSettings.assigneeBeforeDueDate == "0"}
                                                                    defaultValue={mailSettings.assigneeBeforeDueDateValue} key={mailSettings.assigneeBeforeDueDateValue}
                                                                    onChange={(e) => {
                                                                        if (e.target.value != '') {
                                                                            setMailSettings({
                                                                                ...mailSettings,
                                                                                assigneeBeforeDueDateValue: parseInt(e.target.value)
                                                                            })
                                                                            requiredFieldValidation[4] = undefined
                                                                            setRequiredFieldValidation([...requiredFieldValidation])
                                                                        } else {
                                                                            setMailSettings({
                                                                                ...mailSettings,
                                                                                assigneeBeforeDueDateValue: ''
                                                                            })
                                                                            requiredFieldValidation[4] = 'Reminder to assignee before due date after trigger is Required'
                                                                            setRequiredFieldValidation([...requiredFieldValidation])
                                                                        }

                                                                    }}
                                                                // value={days.after_trigger} 
                                                                ></Input>
                                                                <label className='ms-2 mt-2' >Days</label>
                                                            </div>
                                                        </div>
                                                        <div className='col-4 border-end'>
                                                            <label ><b>Reminder to assignee with Escalation after due date :</b><span style={{ color: "red" }}>*</span> </label>
                                                            <div className="d-flex justify-content-start">
                                                                <div className="serv-switch mb-1">
                                                                    <Switch
                                                                        checked={mailSettings.assigneeEscalationAfterDueDate == "1" ? true : false}
                                                                        checkedChildren={<CheckOutlined style={{ color: localStorage.getItem('currentTheme') == 'theme_four' ? 'black' : 'white' }} />}
                                                                        unCheckedChildren={<CloseOutlined style={{ color: localStorage.getItem('currentTheme') == 'theme_four' ? 'black' : 'white' }} />}
                                                                        defaultChecked={mailSettings.assigneeEscalationAfterDueDate == "1" ? true : false}
                                                                        onChange={(checked) => {
                                                                            setMailSettings({
                                                                                ...mailSettings,
                                                                                assigneeEscalationAfterDueDate: checked ? 1 : 0
                                                                            })
                                                                        }}
                                                                    />
                                                                </div>
                                                                <label className='ms-1 mt-2'>For Every</label>
                                                                <Input type='text' className='ms-2' style={{ width: "50px" }}
                                                                    maxLength={2}
                                                                    autoFocus={true}
                                                                    disabled={mailSettings.assigneeEscalationAfterDueDate == "0"}
                                                                    defaultValue={mailSettings.assigneeEscalationAfterDueDateValue} key={mailSettings.assigneeEscalationAfterDueDateValue}
                                                                    onChange={(e) => {
                                                                        if (e.target.value != '') {
                                                                            setMailSettings({
                                                                                ...mailSettings,
                                                                                assigneeEscalationAfterDueDateValue: parseInt(e.target.value)
                                                                            })
                                                                            requiredFieldValidation[5] = undefined
                                                                            setRequiredFieldValidation([...requiredFieldValidation])
                                                                        } else {
                                                                            setMailSettings({
                                                                                ...mailSettings,
                                                                                assigneeEscalationAfterDueDateValue: ''
                                                                            })
                                                                            requiredFieldValidation[5] = 'Reminder to assignee with Escalation after due date is required'
                                                                            setRequiredFieldValidation([...requiredFieldValidation])
                                                                        }

                                                                    }}
                                                                // value={days.after_trigger} 
                                                                ></Input>
                                                                <label className='ms-2 mt-2' >Days</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-12' style={{ marginTop: "30px" }}>
                                                        <div className='row'>
                                                            <div className='col-md-5'></div>
                                                            <div className='col-md-2'>
                                                                <Button style={{ alignItems: "center" }} onClick={onsubmit} type="primary" shape="round" icon={<PlayCircleOutlined />} >Submit</Button>
                                                            </div>
                                                            <div className='col-md-5'></div>
                                                        </div>
                                                    </div>
                                                </TabPane>
                                                <TabPane tab="Information Settings" key="3">
                                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                                        <div className='row'>
                                                            <div className='col-3'>
                                                                <label><b>Country : </b><span className='selected'>{mailSettings.countryName}</span></label>
                                                            </div>
                                                            <div className='col-4'>
                                                                <label><b>Business Group : </b><span className='selected'>{mailSettings.businessGroupName != null ? mailSettings.businessGroupName : '-'}</span></label>
                                                            </div>
                                                            <div className='col-5'>
                                                                <label><b>Legal Entity : </b><span className='selected'>{mailSettings.legalEntityName}</span></label>
                                                            </div>
                                                            <div className='col-3 mt-2'>
                                                                <label><b>Contract From : </b><span className='selected'>{mailSettings.contractFrom}</span></label>
                                                            </div>
                                                            <div className='col-3 mt-2'>
                                                                <label><b>Contract To : </b><span className='selected'>{mailSettings.contractTo}</span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-12' style={{ marginTop: "20px" }}>
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <label><b>Domain / Organization Details</b></label>
                                                                <div className='ant-table ant-table-bordered ant-table-scroll-horizontal'>
                                                                    <div className='ant-table-container'>
                                                                        <div className='ant-table-content'>
                                                                            <table  >
                                                                                <thead className='ant-table-thead'>
                                                                                    <tr>
                                                                                        <th style={{ width: "30px" }}>Domain</th>
                                                                                        <th style={{ width: "30px" }}><center>Activation Date</center></th>
                                                                                        <th style={{ width: "30px" }}>Organization</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody className='ant-table-tbody'>
                                                                                    {datalist && datalist.length > 0 && datalist.map((domainList, i) => {
                                                                                        return (<tr key={i}>
                                                                                            <td className='ant-table-cell'>{domainList.domain_name}</td>
                                                                                            <td className='ant-table-cell'>{domainList.activity_date}</td>
                                                                                            <td className='ant-table-cell'>{domainList.organisation_name}</td>
                                                                                        </tr>)
                                                                                    })}

                                                                                </tbody>
                                                                            </table>
                                                                            <br />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <label className='mb-2'><b>File Space </b></label>
                                                                {/* <Progress className='setting-progress' percent={(((mailSettings.usedSpaceLimit) / (mailSettings.fileSpaceLimit)) * 100).toFixed(2)} status="active" showInfo={false} /> */}
                                                                <Progress className='setting-progress' status="active" percent={(((mailSettings.usedSpaceLimit) / (mailSettings.fileSpaceLimit)) * 100).toFixed(2)} showInfo={false} />
                                                                <div className="d-flex mt-3">
                                                                    <span className='d-flex align-items-center'> <span className='progress-used me-2'></span><span>Used | <b>{(((mailSettings.usedSpaceLimit) / (mailSettings.fileSpaceLimit)) * 100).toFixed(2)}%</b></span></span>
                                                                    <span className='d-flex align-items-center ms-4'> <span className='progress-rem  me-2'></span><span >Remaining | <b>{100 - (((mailSettings.usedSpaceLimit) / (mailSettings.fileSpaceLimit)) * 100).toFixed(2)}%</b></span></span>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <>
                                                        <div className='col-md-12' style={{ marginTop: "20px", backgroundColor: 'lightgray', borderRadius: '10px' }}>
                                                            <div className='row'>
                                                                <div className='col-md-4'>
                                                                    <label className='ms-1'><b>Licence Holders List</b></label>
                                                                </div>
                                                                <div className='col-md-4'></div>
                                                                <div className='col-md-4 pe-4' style={{ textAlign: 'end' }}>
                                                                    <label>
                                                                        <span><b>Total Licence(s): </b><span style={{ marginLeft: "5px", marginRight: "5px" }}>{totaldata && totaldata.settings_details && totaldata.settings_details[0] && totaldata.settings_details[0].total_licence}</span></span>-
                                                                        <span style={{ marginLeft: "10px" }}><b> Used: </b><span style={{ marginLeft: "5px", marginRight: "5px" }}>{totaldata && totaldata.settings_details && totaldata.settings_details[0] && totaldata.settings_details[0].used_licence}</span></span>|
                                                                        <span style={{ marginLeft: "10px" }}><b>Remaining: </b><span style={{ marginLeft: "5px" }}>{(totaldata && totaldata.settings_details && totaldata.settings_details[0] && totaldata.settings_details[0].total_licence) - (totaldata && totaldata.settings_details && totaldata.settings_details[0] && totaldata.settings_details[0].used_licence)}</span></span> </label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <Table className={localStorage.getItem("currentTheme")}
                                                            size={'small'}
                                                            bordered
                                                            showSorterTooltip={false}
                                                            columns={columns}
                                                            dataSource={baseData}
                                                            onChange={handleChange}
                                                            pagination={{
                                                                onChange(current) {
                                                                    setCurrent(current);
                                                                }
                                                            }}
                                                            rowKey={(record, index) => index} />
                                                        {/* <Pagination
                                                            style={{ "margin-top": "10px", float: " RIGHT" }}
                                                            current={current}
                                                            showSizeChanger={true}
                                                            showQuickJumper={false}
                                                            onShowSizeChange={() => {
                                                                setCurrent(1);
                                                            }}
                                                            pageSizeOptions={[25, 50, 100]}
                                                            defaultPageSize={10}
                                                            onChange={(page, pageSizes) => {
                                                                setpageState(true);
                                                                setCurrent(pageSize !== pageSizes ? 1 : page);
                                                                setPageSize(pageSizes);
                                                            }}
                                                            total={totaldata.settings_users && totaldata.settings_users > 0 && totaldata.settings_users.length}
                                                        /> */}
                                                    </>
                                                </TabPane>
                                                {localStorage.getItem('isVendor') == "true" ?
                                                    <TabPane tab="Certification Mail Settings" key={4}>
                                                        <div className='row'>
                                                            <div className='col-auto card card-hover ms-3 mb-0 py-2 mb-2'>
                                                                <div className="d-flex align-items-center">
                                                                    <label ><b>Approved Certificate :</b><span style={{ color: "red" }}>*</span> </label>
                                                                    <div className="d-flex align-items-center ms-2">
                                                                        <div className="serv-switch mb-1">
                                                                            <Switch
                                                                                checked={mailSettings.approvedCertificate}
                                                                                checkedChildren={<CheckOutlined style={{ color: localStorage.getItem('currentTheme') == 'theme_four' ? 'black' : 'white' }} />}
                                                                                unCheckedChildren={<CloseOutlined style={{ color: localStorage.getItem('currentTheme') == 'theme_four' ? 'black' : 'white' }} />}
                                                                                defaultChecked={mailSettings.approvedCertificate}
                                                                                onChange={(checked) => {
                                                                                    if (checked == false) {
                                                                                        var convert = {}
                                                                                        setApprovedArray(convert)
                                                                                    }
                                                                                    setMailSettings({
                                                                                        ...mailSettings,
                                                                                        approvedCertificate: checked
                                                                                    })
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        {mailSettings.approvedCertificate ?
                                                                            <Tooltip title={'Settings'}><Button className='ms-2' shape="circle" type="primary" icon={<SettingOutlined />} onClick={showModal3}></Button></Tooltip>
                                                                            : ""}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-auto  card card-hover ms-3 mb-0 py-2 mb-2'>
                                                                <div className="d-flex justify-content-between">
                                                                    <label ><b>Rectify Certificate :</b><span style={{ color: "red" }}>*</span> </label>
                                                                    <div className="d-flex justify-content-between ms-2">
                                                                        <div className="serv-switch mb-1">
                                                                            <Switch
                                                                                checked={mailSettings.rectifyCertificate == "0" ? false : true}
                                                                                checkedChildren={<CheckOutlined style={{ color: localStorage.getItem('currentTheme') == 'theme_four' ? 'black' : 'white' }} />}
                                                                                unCheckedChildren={<CloseOutlined style={{ color: localStorage.getItem('currentTheme') == 'theme_four' ? 'black' : 'white' }} />}
                                                                                defaultChecked={mailSettings.rectifyCertificate == "0" ? false : true}
                                                                                onChange={(checked) => {
                                                                                    if (checked == false) {
                                                                                        var convert = {}
                                                                                        setRectifyCertificateArray(convert)
                                                                                    }
                                                                                    setMailSettings({
                                                                                        ...mailSettings,
                                                                                        rectifyCertificate: checked ? 1 : 0
                                                                                    })
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        {mailSettings.rectifyCertificate == "1" ?
                                                                            <Tooltip title={'Settings'}><Button className='ms-2' shape="circle" icon={<SettingOutlined />} style={{ float: "right" }} type="primary" onClick={showModal4}></Button></Tooltip>
                                                                            : ""}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className='col-md-12' style={{ marginTop: "30px" }}>
                                                            <div className='row'>
                                                                <div className='col-md-5'></div>
                                                                <div className='col-md-2'>
                                                                    <Button style={{ alignItems: "center" }} onClick={onsubmit} type="primary" shape="round" icon={<PlayCircleOutlined />} >Submit</Button>
                                                                </div>
                                                                <div className='col-md-5'></div>
                                                            </div>
                                                        </div>
                                                    </TabPane>
                                                    : false}
                                            </Tabs>


                                        </> : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default connect(mapStateToProps, {
    getdata,
    getdatalist,
    getdatatable,
    getapprovallist
})(Settings);

{/*
Backup Code
<div className='col-md-12'>
<div className='row'>
    <div className='col-md-2'></div>
    <div className='col-md-3'>
        <label style={{ float: "right", marginTop: "10px" }}><b>Rectify Task :</b><span style={{ color: "red" }}>*</span> </label>
    </div>
    <div className='col-md-1'>
        <input type="radio" value={mailSettings.rectifyTask} checked={mailSettings.rectifyTask == "1"} onClick={(e) => {
            setMailSettings({
                ...mailSettings,
                rectifyTask: 1
            })
        }} />
        <label style={{ marginLeft: "10px", marginTop: "10px" }}>On</label>
    </div>
    <div className='col-md-1'>
        <input type="radio" value={mailSettings.rectifyTask} checked={mailSettings.rectifyTask == "0"} onClick={(e) => {
            setMailSettings({
                ...mailSettings,
                rectifyTask: 0
            })
        }} />
        <label style={{ marginLeft: "10px", marginTop: "10px" }}>Off</label>
    </div>
    <div className='col-md-2'>
        {mailSettings.rectifyTask == "1" ?
            <Button shape="round" style={{ float: "right" }} type="primary" onClick={showModal}>Settings</Button>
            : ""}
    </div>
    <div className='col-md-3'></div>
</div>
</div>
<div className='col-md-12' style={{ marginTop: "20px" }}>
<div className='row'>
    <div className='col-md-2'></div>
    <div className='col-md-3'>
        <label style={{ float: "right", marginTop: "10px" }}><b>Rejected Task :</b><span style={{ color: "red" }}>*</span> </label>
    </div>
    <div className='col-md-1'>
        <input type="radio" value={mailSettings.rejectedTask} checked={mailSettings.rejectedTask == true} onClick={(e) => {
            setMailSettings({
                ...mailSettings,
                rejectedTask: true
            })
        }} />
        <label style={{ marginLeft: "10px", marginTop: "10px" }}>On</label>
    </div>
    <div className='col-md-1'>
        <input type="radio" value={mailSettings.rejectedTask} checked={mailSettings.rejectedTask === false} onClick={(e) => {
            setMailSettings({
                ...mailSettings,
                rejectedTask: false
            })
        }} />
        <label style={{ marginLeft: "10px", marginTop: "10px" }}>Off</label>
    </div>
    <div className='col-md-2'>
        {mailSettings.rejectedTask === true ?
            <Button shape="round" style={{ float: "right" }} type="primary" onClick={showModal1}>Settings</Button>
            : ""}
    </div>
    <div className='col-md-3'></div>
</div>
</div>
<div className='col-md-12' style={{ marginTop: "20px" }}>
<div className='row'>
    <div className='col-md-2'></div>
    <div className='col-md-3'>
        <label style={{ float: "right", marginTop: "10px" }}><b>Reassigned Task :</b><span style={{ color: "red" }}>*</span> </label>
    </div>
    <div className='col-md-1'>
        <input type="radio" value={mailSettings.reassignedTask} checked={mailSettings.reassignedTask == true} onClick={(e) => {
            setMailSettings({
                ...mailSettings,
                reassignedTask: true
            })
        }} />
        <label style={{ marginLeft: "10px", marginTop: "10px" }}>On</label>
    </div>
    <div className='col-md-1'>
        <input type="radio" value={mailSettings.reassignedTask} checked={mailSettings.reassignedTask == false} onClick={(e) => {
            setMailSettings({
                ...mailSettings,
                reassignedTask: false
            })
        }} />
        <label style={{ marginLeft: "10px", marginTop: "10px" }}>Off</label>
    </div>
    <div className='col-md-2'>
        {mailSettings.reassignedTask === true ?
            <Button shape="round" style={{ float: "right" }} type="primary" onClick={showModal2}>Settings</Button>
            : ""}
    </div>
    <div className='col-md-3'></div>
</div>
</div>
<div className='col-md-12' style={{ marginTop: "20px" }}>
<div className='row'>
    <div className='col-md-2'></div>
    <div className='col-md-3'>
        <label style={{ float: "right", marginTop: "10px" }}><b>Assignee Task Calendar :</b><span style={{ color: "red" }}>*</span> </label>
    </div>
    <div className='col-md-1'>
        <input type="radio" value={mailSettings.assigneeTaskCalendar} checked={mailSettings.assigneeTaskCalendar == "W"} onClick={(e) => {
            setMailSettings({
                ...mailSettings,
                assigneeTaskCalendar: "W"
            })
        }} />
        <label style={{ marginLeft: "10px", marginTop: "10px" }}>Weekly</label>
    </div>
    <div className='col-md-1'>
        <input type="radio" value={mailSettings.assigneeTaskCalendar} checked={mailSettings.assigneeTaskCalendar == "M"} onClick={(e) => {
            setMailSettings({
                ...mailSettings,
                assigneeTaskCalendar: "M"
            })
        }} />
        <label style={{ marginLeft: "10px", marginTop: "10px" }}>Monthly</label>
        {mailSettings.assigneeTaskCalendar == "M" ?
            <Select style={{ marginLeft: "40px", width: "100px" }}
                defaultValue={mailSettings.assigneeTaskValue || undefined}
                onChange={(data, value) => {
                    if (data != '') {
                        setMailSettings({
                            ...mailSettings,
                            assigneeTaskValue: data
                        })
                        requiredFieldValidation[0] = undefined
                        setRequiredFieldValidation([...requiredFieldValidation])
                    } else {
                        setMailSettings({
                            ...mailSettings,
                            assigneeTaskValue: ''
                        })
                        requiredFieldValidation[0] = "Select Date Value"
                        setRequiredFieldValidation([...requiredFieldValidation])
                    }

                }}
            >
                <Option key={''}>Select</Option>
                {options.map((listOptions) => {
                    return <Option key={listOptions}>{listOptions}</Option>
                })}

            </Select> : false}

    </div>
    <div className='col-md-1'>
        <input type="radio" value={mailSettings.assigneeTaskCalendar} checked={mailSettings.assigneeTaskCalendar == "D"} onClick={(e) => {
            setMailSettings({
                ...mailSettings,
                assigneeTaskCalendar: "D"
            })
        }} />
        <label style={{ marginLeft: "10px", marginTop: "10px" }}>Daily</label>
    </div>
</div>
</div>
<div className='col-md-12'>
<div className='row'>
    <div className='col-md-5'></div>
    <div className='col-md-2'>
        <label>(Every Sunday)</label>
    </div>
    <div className='col-md-5'></div>
</div>
</div>
<div className='col-md-12' style={{ marginTop: "20px" }}>
<div className='row'>
    <div className='col-md-2'></div>
    <div className='col-md-3'>
        <label style={{ float: "right", marginTop: "10px" }}><b>Approver Task Calendar :</b><span style={{ color: "red" }}>*</span> </label>
    </div>
    <div className='col-md-1'>
        <input type="radio" value={mailSettings.approverTaskCalendar} checked={mailSettings.approverTaskCalendar == "W"} onClick={(e) => {
            setMailSettings({
                ...mailSettings,
                approverTaskCalendar: "W"
            })
        }} />
        <label style={{ marginLeft: "10px", marginTop: "10px" }}>Weekly</label>
    </div>
    <div className='col-md-1'>
        <input type="radio" value={mailSettings.approverTaskCalendar} checked={mailSettings.approverTaskCalendar == "M"} onClick={(e) => {
            setMailSettings({
                ...mailSettings,
                approverTaskCalendar: "M"
            })
        }} />
        <label style={{ marginLeft: "10px", marginTop: "10px" }}>Monthly</label>
        {mailSettings.approverTaskCalendar == "M" ?
            <Select style={{ marginLeft: "40px", width: "100px" }}
                defaultValue={mailSettings.approverTaskValue || undefined}
                onChange={(data, value) => {
                    if (data != '') {
                        setMailSettings({
                            ...mailSettings,
                            approverTaskValue: data
                        })
                        requiredFieldValidation[1] = undefined
                        setRequiredFieldValidation([...requiredFieldValidation])
                    } else {
                        setMailSettings({
                            ...mailSettings,
                            approverTaskValue: ''
                        })
                        requiredFieldValidation[1] = "Select Date Value"
                        setRequiredFieldValidation([...requiredFieldValidation])
                    }

                }}
            >
                <Option key={''}>Select</Option>
                {options.map((listOptions) => {
                    return <Option key={listOptions}>{listOptions}</Option>
                })}
            </Select> : ""}
    </div>
    <div className='col-md-1'>
        <input type="radio" value={mailSettings.approverTaskCalendar} checked={mailSettings.approverTaskCalendar == "D"} onClick={(e) => {
            setMailSettings({
                ...mailSettings,
                approverTaskCalendar: "D"
            })
        }} />
        <label style={{ marginLeft: "10px", marginTop: "10px" }}>Daily</label>
    </div>
</div>
</div>
<div className='col-md-12'>
<div className='row'>
    <div className='col-md-5'></div>
    <div className='col-md-2'>
        <label>(Every Sunday)</label>
    </div>
    <div className='col-md-5'></div>
</div>
</div>
<div className='col-md-12' style={{ marginTop: "20px" }}>
<div className='row'>
    <div className='col-md-2'></div>
    <div className='col-md-3'>
        <label style={{ float: "right", marginTop: "10px" }}><b>View Only User Calendar :</b><span style={{ color: "red" }}>*</span> </label>
    </div>
    <div className='col-md-1'>
        <input type="radio" value={mailSettings.viewOnlyUserCalendar} checked={mailSettings.viewOnlyUserCalendar == "1"} onClick={(e) => {
            setMailSettings({
                ...mailSettings,
                viewOnlyUserCalendar: 1
            })
        }} />
        <label style={{ marginLeft: "10px", marginTop: "10px" }}>On</label>
    </div>
    <div className={'col-md-1'}>
        <input type="radio" value={mailSettings.viewOnlyUserCalendar} checked={mailSettings.viewOnlyUserCalendar == "0"} onClick={(e) => {
            setMailSettings({
                ...mailSettings,
                viewOnlyUserCalendar: 0
            })
        }} />
        <label style={{ marginLeft: "10px", marginTop: "10px" }}>Off</label>
        {mailSettings.viewOnlyUserCalendar == "1" ?
            <Select style={{ marginLeft: "40px", width: "100px" }}
                defaultValue={mailSettings.viewOnlyUserValue || undefined}
                onChange={(data, value) => {
                    console.log(data, 'datadata')
                    if (data != '') {
                        setMailSettings({
                            ...mailSettings,
                            viewOnlyUserValue: data
                        })
                        requiredFieldValidation[2] = undefined
                        setRequiredFieldValidation([...requiredFieldValidation])
                    } else {
                        setMailSettings({
                            ...mailSettings,
                            viewOnlyUserValue: ''
                        })
                        requiredFieldValidation[2] = "Select Date Value"
                        setRequiredFieldValidation([...requiredFieldValidation])
                    }

                }}
            >
                <Option value=""> Select </Option>
                {options.map((listOptions) => {
                    return <Option key={listOptions}>{listOptions}</Option>
                })}
            </Select>

            : false}
    </div>
</div>
</div> 
<div className='col-md-12'>
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <label style={{ float: "right" }}><b>Reassign service provider compliance remainder to all:</b> <span style={{ color: "red" }}>*</span></label>
                                                        </div>
                                                        <div className='col-md-1' style={{ paddingRight: 0 }}>
                                                            <label>For Every</label>
                                                        </div>
                                                        <div className='col-md-1'>

                                                        </div>
                                                        <div className='col-md-1'>
                                                            <label style={{ marginTop: "5px" }}>Days</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12' style={{ marginTop: "20px" }}>
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <label style={{ float: "right" }}><b>Remainder to assignee before due date after trigger:</b> <span style={{ color: "red" }}>*</span></label>
                                                        </div>
                                                        <div className='col-md-1'>
                                                            <input type="radio" value={mailSettings.assigneeBeforeDueDate} checked={mailSettings.assigneeBeforeDueDate == "1"} onClick={(e) => {
                                                                setMailSettings({
                                                                    ...mailSettings,
                                                                    assigneeBeforeDueDate: 1
                                                                })

                                                            }} />
                                                            <label style={{ marginLeft: "10px", marginTop: "5px" }}>On</label>
                                                        </div>
                                                        <div className='col-md-2'>
                                                            <input type="radio" value={mailSettings.assigneeBeforeDueDate} checked={mailSettings.assigneeBeforeDueDate == "0"} onClick={(e) => {
                                                                setMailSettings({
                                                                    ...mailSettings,
                                                                    assigneeBeforeDueDate: 0
                                                                })
                                                            }} />
                                                            <label style={{ marginLeft: "10px", marginTop: "5px" }}>Off For Every</label>
                                                        </div>
                                                        <div className='col-md-1'>
                                                            <Input type='text' style={{ width: "50px" }}
                                                                maxLength={2}
                                                                autoFocus={true}
                                                                disabled={mailSettings.assigneeBeforeDueDate == "0"}
                                                                defaultValue={mailSettings.assigneeBeforeDueDateValue} key={mailSettings.assigneeBeforeDueDateValue}
                                                                onChange={(e) => {
                                                                    if (e.target.value != '') {
                                                                        setMailSettings({
                                                                            ...mailSettings,
                                                                            assigneeBeforeDueDateValue: parseInt(e.target.value)
                                                                        })
                                                                        requiredFieldValidation[4] = undefined
                                                                        setRequiredFieldValidation([...requiredFieldValidation])
                                                                    } else {
                                                                        setMailSettings({
                                                                            ...mailSettings,
                                                                            assigneeBeforeDueDateValue: ''
                                                                        })
                                                                        requiredFieldValidation[4] = 'Remainder to assignee before due date after trigger is Required'
                                                                        setRequiredFieldValidation([...requiredFieldValidation])
                                                                    }

                                                                }}
                                                            // value={days.after_trigger} 
                                                            ></Input>
                                                        </div>
                                                        <div className='col-md-1'>
                                                            <label style={{ marginTop: "5px" }}>Days</label>
                                                        </div>
                                                        <div className='col-md-1'></div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12' style={{ marginTop: "20px" }}>
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <label style={{ float: "right" }}><b>Remainder to assignee with Escalation after due date:</b> <span style={{ color: "red" }}>*</span></label>
                                                        </div>
                                                        <div className='col-md-1'>
                                                            <input type="radio" value={mailSettings.assigneeEscalationAfterDueDate} checked={mailSettings.assigneeEscalationAfterDueDate == "1"} onClick={(e) => {
                                                                setMailSettings({
                                                                    ...mailSettings,
                                                                    assigneeEscalationAfterDueDate: 1
                                                                })
                                                            }} />
                                                            <label style={{ marginLeft: "10px", marginTop: "5px" }}>On</label>
                                                        </div>
                                                        <div className='col-md-2'>
                                                            <input type="radio" value={mailSettings.assigneeEscalationAfterDueDate} checked={mailSettings.assigneeEscalationAfterDueDate == "0"} onClick={(e) => {
                                                                setMailSettings({
                                                                    ...mailSettings,
                                                                    assigneeEscalationAfterDueDate: 0
                                                                })
                                                            }} />
                                                            <label style={{ marginLeft: "10px", marginTop: "5px" }}>Off For Every</label>
                                                        </div>
                                                        <div className='col-md-1'>
                                                            <Input type='text' style={{ width: "50px" }}
                                                                maxLength={2}
                                                                autoFocus={true}
                                                                disabled={mailSettings.assigneeEscalationAfterDueDate == "0"}
                                                                defaultValue={mailSettings.assigneeEscalationAfterDueDateValue} key={mailSettings.assigneeEscalationAfterDueDateValue}
                                                                onChange={(e) => {
                                                                    if (e.target.value != '') {
                                                                        setMailSettings({
                                                                            ...mailSettings,
                                                                            assigneeEscalationAfterDueDateValue: parseInt(e.target.value)
                                                                        })
                                                                        requiredFieldValidation[5] = undefined
                                                                        setRequiredFieldValidation([...requiredFieldValidation])
                                                                    } else {
                                                                        setMailSettings({
                                                                            ...mailSettings,
                                                                            assigneeEscalationAfterDueDateValue: ''
                                                                        })
                                                                        requiredFieldValidation[5] = 'Remainder to assignee with Escalation after due date is Required'
                                                                        setRequiredFieldValidation([...requiredFieldValidation])
                                                                    }

                                                                }}
                                                            // value={days.after_due_date || undefined }
                                                            ></Input>
                                                        </div>
                                                        <div className='col-md-1'>
                                                            <label style={{ marginTop: "5px" }}>Days</label>
                                                        </div>
                                                        <div className='col-md-1'></div>
                                                    </div>
                                                </div>


                                                 <div className='col-md-12' style={{ marginTop: "30px" }}>
                                                    <div className='row'>
                                                        <div className='col-md-1'></div>
                                                        <div className='col-md-4'>
                                                            <label>File Space Used</label> <Progress
                                                                percent={(((totaldata.settings_details && totaldata.settings_details.length > 0 && totaldata.settings_details[0].used_file_space) / (totaldata.settings_details && totaldata.settings_details.length > 0 && totaldata.settings_details[0].file_space_limit)) * 100).toFixed(2)}  />

                                                        </div>
                                                        <div className='col-md-2'></div>
                                                        <div className='col-md-4'>
                                                            <label>File Space Available</label><Progress percent={(100 - (totaldata.settings_details && totaldata.settings_details.length > 0 && totaldata.settings_details[0].used_file_space) / (totaldata.settings_details && totaldata.settings_details.length > 0 && totaldata.settings_details[0].file_space_limit) * 100).toFixed(2)} status="active" />

                                                        </div>
                                                        <div className='col-md-1'></div>
                                                    </div>
                                                </div>

                                                  /* const onsubmit_old = () => {
        if ((mailSettings.rectifyTask == 1 && Object.values(rectifyArray).length > 0) && (mailSettings.rejectedTask == 1 && Object.values(rejectedArray).length > 0) && (mailSettings.reassignedTask == 1 && Object.values(reassignedArray).length > 0)) {
            if (localStorage.getItem('isVendor') == 'true') {
                if ((mailSettings.approvedCertificate == 1 && Object.values(approvedArray).length > 0) && (mailSettings.rectifyCertificate == 1 && Object.values(rectifyCertificateArray).length > 0)) {
                    if (requiredFieldValidation.filter(Boolean).length == 0) {
                        const obj1 = {
                            1: unitid
                        }
                        const myJSON1 = JSON.stringify(obj1);
                        const temp1 = JSON.stringify(myJSON1);

                        const obj2 = {
                            1: unitid1
                        }
                        const myJSON2 = JSON.stringify(obj2);
                        const temp2 = JSON.stringify(myJSON2);

                        const obj3 = {
                            1: unitid2
                        }
                        const myJSON3 = JSON.stringify(obj3);
                        const temp3 = JSON.stringify(myJSON3);

                        const obj4 = {
                            1: unitid3
                        }
                        const myJSON4 = JSON.stringify(obj4);
                        const temp4 = JSON.stringify(myJSON4);

                        const obj5 = {
                            1: unitid4
                        }
                        const myJSON5 = JSON.stringify(obj5);
                        const temp5 = JSON.stringify(myJSON5);

                        if (localStorage.getItem('isVendor') == "false") {
                            const payload2 = [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request": [
                                        "SaveSettingsFormDetails",
                                        {
                                            "legal_entity_id": entityid,
                                            "two_level_approve": false,
                                            "assignee_reminder": mailSettings.assigneeRemainder ? parseInt(mailSettings.assigneeRemainder) : null,
                                            "advance_escalation_reminder": mailSettings.advanceEscalationRemainder ? parseInt(mailSettings.advanceEscalationRemainder) : null,
                                            "escalation_reminder": mailSettings.escalationRemainde ? parseInt(mailSettings.escalationRemainde) : null,
                                            "mail_trigger": false,
                                            "assignee_mail_reminder_val": mailSettings.assigneeTaskCalendar == "W" ? parseInt(mailSettings.assigneeTaskValue) : mailSettings.assigneeTaskCalendar == "M" ? parseInt(mailSettings.assigneeTaskValue) : mailSettings.assigneeTaskCalendar == "D" ? parseInt(mailSettings.assigneeTaskValue) : 0,
                                            "assignee_mail_reminder": mailSettings.assigneeTaskCalendar,
                                            "approve_mail_reminder_val": mailSettings.approverTaskCalendar == "W" ? parseInt(mailSettings.approverTaskValue) : mailSettings.approverTaskCalendar == "M" ? parseInt(mailSettings.approverTaskValue) : mailSettings.approverTaskCalendar == "D" ? parseInt(mailSettings.approverTaskValue) : 0,
                                            "approve_mail_reminder": mailSettings.approverTaskCalendar,
                                            "reassign_sp": mailSettings.remainderForSp,
                                            "selected_units": "{}",
                                            "mail_status_reject": mailSettings.rejectedTask,
                                            "mail_status_reassign": mailSettings.reassignedTask,
                                            "units_selected_reject": JSON.stringify(rejectedArray),//temp2,
                                            "units_selected_reassign": JSON.stringify(reassignedArray),//temp3,
                                            "reminder_assig_val": parseInt(mailSettings.assigneeBeforeDueDateValue),
                                            "reminder_assig": parseInt(mailSettings.assigneeBeforeDueDate),
                                            "reminder_esc_val": parseInt(mailSettings.assigneeEscalationAfterDueDateValue),
                                            "reminder_esc": parseInt(mailSettings.assigneeEscalationAfterDueDate),
                                            "units_selected_rectify": JSON.stringify(rectifyArray),
                                            "mail_status_rectify": mailSettings.rectifyTask == 1 ? true : false,
                                            "vor_mail_status": mailSettings.viewOnlyUserCalendar,
                                            "vor_mail_date": mailSettings.viewOnlyUserCalendar == 1 ? parseInt(mailSettings.viewOnlyUserValue) : 0,
                                            // "units_selected_certificate": JSON.stringify(settingsArray.approvedArray),//temp4,
                                            // "units_selected_cer_rectify": JSON.stringify(settingsArray.rectifyCertificateArray),//temp5,
                                            // "mail_status_cer_rectify": mailSettings.rectifyCertificate == "0" ? false : true,
                                            // "mail_status_certificate": mailSettings.approvedCertificate

                                        }
                                    ]
                                }
                            ]
                            getdatatable({
                                payload: payload2,
                                paramid: paramid,
                                formkey: formdata.form_key
                            })
                        } else {
                            const payload2 = [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request": [
                                        "SaveSettingsFormDetails",
                                        {
                                            "legal_entity_id": entityid,
                                            "two_level_approve": false,
                                            "assignee_reminder": mailSettings.assigneeRemainder ? parseInt(mailSettings.assigneeRemainder) : null,
                                            "advance_escalation_reminder": mailSettings.advanceEscalationRemainder ? parseInt(mailSettings.advanceEscalationRemainder) : null,
                                            "escalation_reminder": mailSettings.escalationRemainde ? parseInt(mailSettings.escalationRemainde) : null,
                                            "mail_trigger": false,
                                            "assignee_mail_reminder_val": mailSettings.assigneeTaskCalendar == "W" ? 6 : mailSettings.assigneeTaskCalendar == "M" ? parseInt(mailSettings.assigneeTaskValue) : mailSettings.assigneeTaskCalendar == "D" ? 1 : 0,
                                            "assignee_mail_reminder": mailSettings.assigneeTaskCalendar,
                                            "approve_mail_reminder_val": mailSettings.approverTaskCalendar == "W" ? 6 : mailSettings.approverTaskCalendar == "M" ? parseInt(mailSettings.approverTaskValue) : mailSettings.approverTaskCalendar == "D" ? 1 : 0,
                                            "approve_mail_reminder": mailSettings.approverTaskCalendar,
                                            "reassign_sp": mailSettings.remainderForSp,
                                            "selected_units": "{}",
                                            "mail_status_reject": mailSettings.rejectedTask,
                                            "mail_status_reassign": mailSettings.reassignedTask,
                                            "units_selected_reject": JSON.stringify(rejectedArray),//temp2,
                                            "units_selected_reassign": JSON.stringify(reassignedArray),//temp3,
                                            "reminder_assig_val": parseInt(mailSettings.assigneeBeforeDueDateValue),
                                            "reminder_assig": parseInt(mailSettings.assigneeBeforeDueDate),
                                            "reminder_esc_val": parseInt(mailSettings.assigneeEscalationAfterDueDateValue),
                                            "reminder_esc": parseInt(mailSettings.assigneeEscalationAfterDueDate),
                                            "units_selected_rectify": JSON.stringify(rectifyArray),//JSON.stringify(settingsArray.rectifyArray),
                                            "mail_status_rectify": mailSettings.rectifyTask == 1 ? true : false,
                                            "vor_mail_status": mailSettings.viewOnlyUserCalendar,
                                            "vor_mail_date": mailSettings.viewOnlyUserCalendar == 1 ? parseInt(mailSettings.viewOnlyUserValue) : 0,
                                            "units_selected_certificate": JSON.stringify(approvedArray),//temp4,
                                            "units_selected_cer_rectify": JSON.stringify(rectifyCertificateArray),//temp5,
                                            "mail_status_cer_rectify": mailSettings.rectifyCertificate == "0" ? false : true,
                                            "mail_status_certificate": mailSettings.approvedCertificate

                                        }
                                    ]
                                }
                            ]
                            getdatatable({
                                payload: payload2,
                                paramid: paramid,
                                formkey: formdata.form_key
                            })
                        }


                    } else {
                        toast.error(requiredFieldValidation.filter(Boolean)[requiredFieldValidation.filter(Boolean).length - 1]);
                    }
                } else {
                    toast.error('Select Atleast One Unit')
                }
            } else {
                if (requiredFieldValidation.filter(Boolean).length == 0) {
                    const obj1 = {
                        1: unitid
                    }
                    const myJSON1 = JSON.stringify(obj1);
                    const temp1 = JSON.stringify(myJSON1);

                    const obj2 = {
                        1: unitid1
                    }
                    const myJSON2 = JSON.stringify(obj2);
                    const temp2 = JSON.stringify(myJSON2);

                    const obj3 = {
                        1: unitid2
                    }
                    const myJSON3 = JSON.stringify(obj3);
                    const temp3 = JSON.stringify(myJSON3);

                    const obj4 = {
                        1: unitid3
                    }
                    const myJSON4 = JSON.stringify(obj4);
                    const temp4 = JSON.stringify(myJSON4);

                    const obj5 = {
                        1: unitid4
                    }
                    const myJSON5 = JSON.stringify(obj5);
                    const temp5 = JSON.stringify(myJSON5);

                    if (localStorage.getItem('isVendor') == "false") {
                        const payload2 = [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request": [
                                    "SaveSettingsFormDetails",
                                    {
                                        "legal_entity_id": entityid,
                                        "two_level_approve": false,
                                        "assignee_reminder": mailSettings.assigneeRemainder ? parseInt(mailSettings.assigneeRemainder) : null,
                                        "advance_escalation_reminder": mailSettings.advanceEscalationRemainder ? parseInt(mailSettings.advanceEscalationRemainder) : null,
                                        "escalation_reminder": mailSettings.escalationRemainde ? parseInt(mailSettings.escalationRemainde) : null,
                                        "mail_trigger": false,
                                        "assignee_mail_reminder_val": mailSettings.assigneeTaskCalendar == "W" ? parseInt(mailSettings.assigneeTaskValue) : mailSettings.assigneeTaskCalendar == "M" ? parseInt(mailSettings.assigneeTaskValue) : mailSettings.assigneeTaskCalendar == "D" ? parseInt(mailSettings.assigneeTaskValue) : 0,
                                        "assignee_mail_reminder": mailSettings.assigneeTaskCalendar,
                                        "approve_mail_reminder_val": mailSettings.approverTaskCalendar == "W" ? parseInt(mailSettings.approverTaskValue) : mailSettings.approverTaskCalendar == "M" ? parseInt(mailSettings.approverTaskValue) : mailSettings.approverTaskCalendar == "D" ? parseInt(mailSettings.approverTaskValue) : 0,
                                        "approve_mail_reminder": mailSettings.approverTaskCalendar,
                                        "reassign_sp": mailSettings.remainderForSp,
                                        "selected_units": "{}",
                                        "mail_status_reject": mailSettings.rejectedTask,
                                        "mail_status_reassign": mailSettings.reassignedTask,
                                        "units_selected_reject": JSON.stringify(rejectedArray),//temp2,
                                        "units_selected_reassign": JSON.stringify(reassignedArray),//temp3,
                                        "reminder_assig_val": parseInt(mailSettings.assigneeBeforeDueDateValue),
                                        "reminder_assig": parseInt(mailSettings.assigneeBeforeDueDate),
                                        "reminder_esc_val": parseInt(mailSettings.assigneeEscalationAfterDueDateValue),
                                        "reminder_esc": parseInt(mailSettings.assigneeEscalationAfterDueDate),
                                        "units_selected_rectify": JSON.stringify(rectifyArray),
                                        "mail_status_rectify": mailSettings.rectifyTask == 1 ? true : false,
                                        "vor_mail_status": mailSettings.viewOnlyUserCalendar,
                                        "vor_mail_date": mailSettings.viewOnlyUserCalendar == 1 ? parseInt(mailSettings.viewOnlyUserValue) : 0,
                                        // "units_selected_certificate": JSON.stringify(settingsArray.approvedArray),//temp4,
                                        // "units_selected_cer_rectify": JSON.stringify(settingsArray.rectifyCertificateArray),//temp5,
                                        // "mail_status_cer_rectify": mailSettings.rectifyCertificate == "0" ? false : true,
                                        // "mail_status_certificate": mailSettings.approvedCertificate

                                    }
                                ]
                            }
                        ]
                        getdatatable({
                            payload: payload2,
                            paramid: paramid,
                            formkey: formdata.form_key
                        })
                    } else {
                        const payload2 = [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request": [
                                    "SaveSettingsFormDetails",
                                    {
                                        "legal_entity_id": entityid,
                                        "two_level_approve": false,
                                        "assignee_reminder": mailSettings.assigneeRemainder ? parseInt(mailSettings.assigneeRemainder) : null,
                                        "advance_escalation_reminder": mailSettings.advanceEscalationRemainder ? parseInt(mailSettings.advanceEscalationRemainder) : null,
                                        "escalation_reminder": mailSettings.escalationRemainde ? parseInt(mailSettings.escalationRemainde) : null,
                                        "mail_trigger": false,
                                        "assignee_mail_reminder_val": mailSettings.assigneeTaskCalendar == "W" ? 6 : mailSettings.assigneeTaskCalendar == "M" ? parseInt(mailSettings.assigneeTaskValue) : mailSettings.assigneeTaskCalendar == "D" ? 1 : 0,
                                        "assignee_mail_reminder": mailSettings.assigneeTaskCalendar,
                                        "approve_mail_reminder_val": mailSettings.approverTaskCalendar == "W" ? 6 : mailSettings.approverTaskCalendar == "M" ? parseInt(mailSettings.approverTaskValue) : mailSettings.approverTaskCalendar == "D" ? 1 : 0,
                                        "approve_mail_reminder": mailSettings.approverTaskCalendar,
                                        "reassign_sp": mailSettings.remainderForSp,
                                        "selected_units": "{}",
                                        "mail_status_reject": mailSettings.rejectedTask,
                                        "mail_status_reassign": mailSettings.reassignedTask,
                                        "units_selected_reject": JSON.stringify(rejectedArray),//temp2,
                                        "units_selected_reassign": JSON.stringify(reassignedArray),//temp3,
                                        "reminder_assig_val": parseInt(mailSettings.assigneeBeforeDueDateValue),
                                        "reminder_assig": parseInt(mailSettings.assigneeBeforeDueDate),
                                        "reminder_esc_val": parseInt(mailSettings.assigneeEscalationAfterDueDateValue),
                                        "reminder_esc": parseInt(mailSettings.assigneeEscalationAfterDueDate),
                                        "units_selected_rectify": JSON.stringify(rectifyArray),//JSON.stringify(settingsArray.rectifyArray),
                                        "mail_status_rectify": mailSettings.rectifyTask == 1 ? true : false,
                                        "vor_mail_status": mailSettings.viewOnlyUserCalendar,
                                        "vor_mail_date": mailSettings.viewOnlyUserCalendar == 1 ? parseInt(mailSettings.viewOnlyUserValue) : 0,
                                        "units_selected_certificate": JSON.stringify(approvedArray),//temp4,
                                        "units_selected_cer_rectify": JSON.stringify(rectifyCertificateArray),//temp5,
                                        "mail_status_cer_rectify": mailSettings.rectifyCertificate == "0" ? false : true,
                                        "mail_status_certificate": mailSettings.approvedCertificate

                                    }
                                ]
                            }
                        ]
                        getdatatable({
                            payload: payload2,
                            paramid: paramid,
                            formkey: formdata.form_key
                        })
                    }


                } else {
                    toast.error(requiredFieldValidation.filter(Boolean)[requiredFieldValidation.filter(Boolean).length - 1]);
                }
            }

        } else {
            toast.error('Select Atleast One Unit')
        }


    }*/


}

