import { CERTIFICATELABELLIST, ONOCCURRENCE_INVALID, RESET_INVALID } from '../../types/index'


const initialState = {
    list: '',
    invalidpass: '',
    passwordreset: ''

}
export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case CERTIFICATELABELLIST:
            {
                return {
                    ...state,
                    list: payload[1],
                };
            }
        case ONOCCURRENCE_INVALID:
            {
                return {
                    ...state,
                    invalidpass: payload,
                };
            }
        case RESET_INVALID:
            {
                return {
                    ...state,
                    invalidpass: '',
                };
            }
        default:
            return state;
    }
}